import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../rootReducer';
import store from '../../store';

export const getModalsState = (state: RootState) => state.modals;

export const isAddToRouteVisible = createSelector(
  getModalsState,
  ({addToRoute}) => addToRoute,
);

export default class modals {
  get addNewMemberModal() {
    return getModalsState(store.getState()).addNewMemberModal;
  }

  get addEditPopup() {
    return getModalsState(store.getState()).addEditPopup;
  }

  get addToRoute() {
    return getModalsState(store.getState()).addToRoute;
  }

  get recordLimitWarningModal() {
    return getModalsState(store.getState()).recordLimitWarningModal;
  }

  get quickCreateDeal() {
    return getModalsState(store.getState()).quickCreateDeal;
  }

  get quickCreateContact() {
    return getModalsState(store.getState()).quickCreateContact;
  }

  get quickCreateAccount() {
    return getModalsState(store.getState()).quickCreateAccount;
  }

  get addExistingMembersModal() {
    return getModalsState(store.getState()).addExistingMembersModal;
  }

  get manageMembersModal() {
    return getModalsState(store.getState()).manageMembersModal;
  }

  get selectFieldsModal() {
    return getModalsState(store.getState()).selectFieldsModal;
  }

  get upgradeModal() {
    return getModalsState(store.getState()).upgradeModal;
  }

  get talkToSales() {
    return getModalsState(store.getState()).talkToSales;
  }

  get featuresModal() {
    return getModalsState(store.getState()).featuresModal;
  }

  get addCfModal() {
    return getModalsState(store.getState()).addCfModal;
  }

  get manageGroupsModal() {
    return getModalsState(store.getState()).manageGroupsModal;
  }

  get noAccountsModal() {
    const state = getModalsState(store.getState());
    return state.noAccountsModal.visible && !state.noAccountsModal.prevent;
  }

  get noContactsModal() {
    const state = getModalsState(store.getState());
    return state.noContactsModal.visible && !state.noContactsModal.prevent;
  }

  get noDealsModal() {
    const state = getModalsState(store.getState());
    return state.noDealsModal.visible && !state.noDealsModal.prevent;
  }

  get noRoutesModal() {
    const state = getModalsState(store.getState());
    return state.noRoutesModal.visible && !state.noRoutesModal.prevent;
  }

  get noGroupsModal() {
    const state = getModalsState(store.getState());
    return state.noGroupsModal.visible && !state.noGroupsModal.prevent;
  }

  get noTerritoriesModal() {
    const state = getModalsState(store.getState());
    return state.noTerritoriesModal.visible && !state.noTerritoriesModal.prevent;
  }

  get noReportsModal() {
    const state = getModalsState(store.getState());
    return state.noReportsModal.visible && !state.noReportsModal.prevent;
  }

  get noActivitiesModal() {
    const state = getModalsState(store.getState());
    return state.noActivitiesModal.visible && !state.noActivitiesModal.prevent;
  }

  get conversionModal() {
    return getModalsState(store.getState()).conversionModal;
  }

}
