import get from 'lodash/get';

export default class LassoService {
  constructor($window, $route, $timeout) {
    Object.assign(this, {
      $route,
      $window,
      $timeout,
      UTILS: $window.mmcUtils,
      MODEL: $window.DataModel,
    });
    this.$window.clearLasso = this.clearLasso;
  }

  // init lasso creation
  init(options) {
    this.MODEL.MappingService.lassoOptions = options;
  }

  // lasso building starts and init of lasso takes place
  setupLasso() {
    // init lasso
    this.init({
      map: this.MODEL.map, // the map to be initiallised
      strokeWeight: 4, // the weight of the stroke
      strokeColor: '#3063E4', // the colour of the stroke
      strokeOpacity: 0.75, // the opacity of the stroke
      fillColor: '#1eb4fa', // the fill colour of the polygon
      fillOpacity: 0.15, // the fill opacity of the polygon
      maxPoints: 175, // maximum co-ordinates for the lasso
      minPoints: 10, // minimum co-ordinates for the lasso
    });
  }

  // starts the lasso pencil
  startLasso() {
    // create a polyline path to follow the cursor
    const lassoPathOptions = {
      strokeColor: this.MODEL.MappingService.lassoOptions.strokeColor,
      strokeOpacity: this.MODEL.MappingService.lassoOptions.strokeOpacity,
      clickable: false,
      strokeWeight: this.MODEL.MappingService.lassoOptions.strokeWeight,
    };

    // add the polyline to the map
    this.lassoPath = new google.maps.Polyline(lassoPathOptions);
    this.lassoPath.setMap(this.MODEL.MappingService.lassoOptions.map);

    // disable scrollzoom and map dragging while lassoing
    this.MODEL.MappingService.lassoOptions.map.setOptions({
      draggable: false,
    });

    // remove scrolling from the view when lassoing only on mobile
    if (this.UTILS.isMobile.any()) {
      $('html , body').bind('touchmove', (e) => {
        e.preventDefault();
      });
    }

    // assign a handler to cancel the lasso on keyup
    $('body').keyup(this.lassoEscHandler).css('cursor', 'crosshair');

    // add the mouse down handler
    google.maps.event.addListenerOnce(this.MODEL.MappingService.lassoOptions.map, 'mousedown', (/* event */) => {
      // variables used for panning the map
      // var panTolerance = 40; // tolerance from edge of container in pixels
      // var panSpeed = 5; // distance to pan in pixels
      // var panning = false;
      const path = this.lassoPath.getPath();

      // var containerOffset = 10;
      // var rightExit = mapOptions.map.innerWidth();
      // var bottomExit = mapOptions.map.height();

      // // fire a custom event if needed
      // if (mapOptions.onStart !== null) {
      //   mapOptions.onStart(event);
      // }

      // start tracking cursor
      google.maps.event.addListener(this.MODEL.MappingService.lassoOptions.map, 'mousemove', (event) => {
        // var panDirection;

        // used for panning the map, commented until we can get height, width and offset of the map dynamically
        // // monitor the pixel of the cursor, and pan the map if needed
        // if (event.pixel.x >= rightExit - panTolerance) {
        //   mapOptions.map.panBy(panSpeed, 0)
        // }
        // else if (event.pixel.x <= containerOffset.left + panTolerance){
        //   mapOptions.map.panBy(-panSpeed, 0);
        // }
        // else if (event.pixel.y >= bottomExit - panTolerance) {
        //   mapOptions.map.panBy(0, panSpeed);
        // }
        // else if (event.pixel.y <= panTolerance) {
        //   mapOptions.map.panBy(0, -panSpeed);
        // }

        // create the cursor position
        const cursorPosition = new google.maps.LatLng(event.latLng.lat(), event.latLng.lng());

        // add marker position to lassoLatLng array
        this.MODEL.MappingService.lassoLatLng.push(cursorPosition);

        // update the polyline path, keeps line going behind cursor
        path.push(cursorPosition);
      });
    });

    // end the lasso once the polygon is coming to close
    google.maps.event.addListenerOnce(this.MODEL.MappingService.lassoOptions.map, 'mouseup', (/* event */) => {
      const {length} = this.MODEL.MappingService.lassoLatLng;

      // if there's a reasonable amount
      if (length < this.MODEL.MappingService.lassoOptions.maxPoints && length > this.MODEL.MappingService.lassoOptions.minPoints) {
        this.endLasso(); // do nothing, end lasso
        // if there's too many, thin down
      } else if (length > this.MODEL.MappingService.lassoOptions.minPoints) {
        const factor = parseInt((length / this.MODEL.MappingService.lassoOptions.maxPoints) + 1, 10);
        const output = [];
        let i = 0;

        for (i = 0; i < length; i += factor) {
          output.push(this.MODEL.MappingService.lassoLatLng[i]);
        }

        // dump unfiltered latlong
        this.MODEL.MappingService.lassoLatLng = [];
        this.MODEL.MappingService.lassoLatLng = output;
        this.endLasso();

        // if there's too little, reset
      } else {
        this.clearLasso();
      }
    });
  }

  // end lasso drawing and reset map listeners
  endLasso() {
    $('html, body').css('position', 'relative');
    // clear listeners
    $('body').unbind('keyup', this.lassoEscHandler).css('cursor', 'default');
    google.maps.event.clearListeners(this.MODEL.MappingService.lassoOptions.map, 'mousemove'); // stop tracking cursor

    // add the lassoed polygon
    this.lassoPolygon = new google.maps.Polygon({
      path: this.MODEL.MappingService.lassoLatLng,
      clickable: false,
      strokeColor: this.MODEL.MappingService.lassoOptions.strokeColor,
      strokeOpacity: this.MODEL.MappingService.lassoOptions.strokeOpacity,
      strokeWeight: this.MODEL.MappingService.lassoOptions.strokeWeight,
      fillColor: this.MODEL.MappingService.lassoOptions.fillColor,
      fillOpacity: this.MODEL.MappingService.lassoOptions.fillOpacity,
    });

    this.lassoPolygon.setMap(this.MODEL.MappingService.lassoOptions.map);

    if (this.lassoPath) {
      this.lassoPath.setMap(null); // remove traced lasso path
      this.highlightRoutePins();
    }

    this.lassoListener = google.maps.event.addListener(
      this.MODEL.map,
      'zoom_changed',
      this.highlightRoutePins.bind(this),
    );

    setTimeout(() => {
      // restore defaults
      this.MODEL.MappingService.lassoOptions.map.setOptions({
        draggable: true,
      });
    }, 20);

    // add scrolling back again
    if (this.UTILS.isMobile.any()) {
      $('html, body').unbind('touchmove');
    }
  }

  // clear lasso
  clearLasso() {
    $('html, body').css('position', 'relative');
    this.MODEL.MappingService.lassoedPins = [];
    // clear the listeners
    if (this.MODEL.MappingService.lassoOptions.map) {
      google.maps.event.clearListeners(this.MODEL.map, 'mousedown');
      google.maps.event.clearListeners(this.MODEL.map, 'mousemove');
      google.maps.event.clearListeners(this.MODEL.map, 'mouseup');
      google.maps.event.removeListener(this.lassoListener);

      // restore defaults
      this.MODEL.MappingService.lassoOptions.map.setOptions({
        draggable: true,
      });

      if (this.lassoPolygon) {
        this.lassoPolygon.setMap(null);
        this.lassoPolygon = null;
      }

      if (this.lassoPath) {
        this.lassoPath.setMap(null);
        this.lassoPath = null;
      }
    }

    // add scrolling back again
    if (this.UTILS.isMobile.any()) {
      $('html, body').unbind('touchmove');
    }

    this.MODEL.MappingService.lassoLatLng = [];
  }

  // completely stops lasso
  cancelLassoCompletely() {
    $('#lassoSaveButton').hide();
    $('#lassoDeleteButton').hide();
    $('#cancelLassoTool').hide();
    this.MODEL.LassoService.lassoApplied = false;
    this.MODEL.MappingService.toggleLassoRouteOn = true;
    // clear lasso and remove any lat/lng
    this.clearLasso();
    $('body').unbind('keyup', this.lassoEscHandler).css('cursor', 'default');
  }

  setLassoedPins(pins) {
    this.MODEL.MappingService.lassoedPins = pins;
  }

  // finish looping thru pins inside lasso and saving into groups selected
  saveLassoedPins(keepLasso = false) {
    if (!keepLasso) {
      $('html, body').css('position', 'relative');
      $('#lassoSaveButton').hide();
      $('#lassoDeleteButton').hide();
      $('#cancelLassoTool').hide();
    }

    // add polygon (auto-filled) to map
    this.MODEL.LassoService.lassoPolygonContainer = new google.maps.Polygon({
      paths: this.MODEL.MappingService.lassoLatLng,
      strokeColor: '#3063E4',
      strokeOpacity: 1.0,
      strokeWeight: 3,
      fillColor: '#3063E4',
      fillOpacity: 0.35,
      clickable: false,
    });

    // for when map refreshes
    this.MODEL.previousCenter = this.MODEL.map.getCenter();
    this.MODEL.previousZoom = this.MODEL.map.getZoom();

    let entities = [];

    if (['contactsMapPage', 'contactsGroupsMapPage'].includes(this.$route.current.id)) {
      entities = this.MODEL.contacts;
    } else if (['accountsMapPage', 'accountsGroupsMapPage'].includes(this.$route.current.id)) {
      entities = this.MODEL.accounts;
    }

    this.MODEL.selectedEntities = entities.filter(entity => {
      if (!entity.geoPoint) {
        return false;
      }

      const [longitude, latitude] = entity.geoPoint.coordinates;
      const currentPinLatLng = new google.maps.LatLng(latitude, longitude);
      return google.maps.geometry.poly.containsLocation(currentPinLatLng, this.MODEL.LassoService.lassoPolygonContainer);
    });

    return this.MODEL.selectedEntities;
  }

  highlightPin(pin, lassoPolygon, showingObjectIds = []) {
    if (!pin.latitude) return;

    const currentPinLatLng = new google.maps.LatLng(pin.latitude, pin.longitude);
    const isWithinPolygon = google.maps.geometry.poly.containsLocation(currentPinLatLng, lassoPolygon);

    // pin is inside curent territory polygon && is showing on the map, add to new route
    if (isWithinPolygon && showingObjectIds.indexOf(pin.id) > -1) {
      this.MODEL.MappingService.lassoedPins.push(pin);
      this.$timeout(() => {
        const marker = this.MODEL.customersOverlay.find(({args}) => args.marker_id.includes(pin.id));

        if (marker) {
          marker.modified = true;

          if (get(marker, 'div.style')) {
            marker.div.style.backgroundImage = "url('./images/pins-large/marker_home.png')";
          }
        }
      }, 500);
    }
  }

  highlightRoutePins() {
    // get coords from lasso tool
    const lassoCoords = this.MODEL.MappingService.lassoLatLng;

    // add polygon (auto-filled) to map
    const lassoPolygon = new google.maps.Polygon({
      paths: lassoCoords,
    });

    const showingObjectIds = [];
    const objects = window.isOnPage('accounts') ? this.MODEL.accounts : this.MODEL.contacts;
    //  add to route if pin is inside the current polygon
    objects.forEach((currentPin) => {
      showingObjectIds.push(currentPin.id);

      this.highlightPin(currentPin, lassoPolygon, showingObjectIds);
    });
  }
}

LassoService.$inject = ['$window', '$route', '$timeout'];
