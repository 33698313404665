import React, {useCallback, useMemo} from 'react';
import styled from 'styled-components';
import {Col, Radio, Row, Space, Switch} from 'antd';
import {RadioChangeEvent} from 'antd/es/radio/interface';
import entityDisplayName from '../../../util/formatter/entityDisplayName';
import EntityType from '../../../type/EntityType';
import SyncDirection from '../../../type/enum/SyncDirection';

const Option = styled(Radio)`
  display: block;
  height: 1.5rem;
  line-height: 1.5rem;
  font-weight: normal;
`;

interface Props {
  entityType: EntityType,
  incoming: boolean,
  onChange?: (entityType: EntityType, incoming: boolean, outgoing: boolean) => void,
  outgoing: boolean,
}

const ObjectSyncSettings: React.FC<Props> = ({entityType, incoming, onChange, outgoing}) => {
  const handleChangeEnabled = useCallback(
    // we only allow incoming syncing at the moment, that's why outgoing option is always false
    (enabled: boolean) => onChange && onChange(entityType, enabled, false),
    [entityType, onChange],
  );

  return (
    <div>
      <Row>
        <Col span={6}>
          <Space>
            <Switch
              checked={incoming || outgoing}
              onChange={handleChangeEnabled}
            />
            {entityDisplayName(entityType)}
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default ObjectSyncSettings;
