import L from 'leaflet';
import get from 'lodash/get';
import Colored, {Color} from '../../type/base/Colored';
import UserSetting, {MarkerIcon, MarkerSize} from '../../util/settings/UserSetting';
import pinBlack from '../../../../images/pins-large/marker_black.png';
import pinBlue from '../../../../images/pins-large/marker_blue.png';
import pinBrown from '../../../../images/pins-large/marker_brown.png';
import pinCyan from '../../../../images/pins-large/marker_cyan.png';
import pinGold from '../../../../images/pins-large/marker_gold.png';
import pinGreen from '../../../../images/pins-large/marker_green.png';
import pinGrey from '../../../../images/pins-large/marker_grey.png';
import pinLavender from '../../../../images/pins-large/marker_lavender.png';
import pinMaroon from '../../../../images/pins-large/marker_maroon.png';
import pinNavy from '../../../../images/pins-large/marker_navy.png';
import pinNeonBlue from '../../../../images/pins-large/marker_neon_blue.png';
import pinNeonGreen from '../../../../images/pins-large/marker_neon_green.png';
import pinNeonPurple from '../../../../images/pins-large/marker_neon_purple.png';
import pinNeonRed from '../../../../images/pins-large/marker_neon_red.png';
import pinNeonYellow from '../../../../images/pins-large/marker_neon_yellow.png';
import pinOlive from '../../../../images/pins-large/marker_olive.png';
import pinOrange from '../../../../images/pins-large/marker_orange.png';
import pinPeach from '../../../../images/pins-large/marker_peach.png';
import pinPink from '../../../../images/pins-large/marker_pink.png';
import pinPurple from '../../../../images/pins-large/marker_purple.png';
import pinRed from '../../../../images/pins-large/marker_red.png';
import pinTeal from '../../../../images/pins-large/marker_teal.png';
import pinTurquoise from '../../../../images/pins-large/marker_turquoise.png';
import pinViolet from '../../../../images/pins-large/marker_violet.png';
import pinWhite from '../../../../images/pins-large/marker_white.png';
import pinYellow from '../../../../images/pins-large/marker_yellow.png';
import circleBlack from '../../../../images/circles-large/circle_black.png';
import circleBlue from '../../../../images/circles-large/circle_blue.png';
import circleBrown from '../../../../images/circles-large/circle_brown.png';
import circleCyan from '../../../../images/circles-large/circle_cyan.png';
import circleGold from '../../../../images/circles-large/circle_gold.png';
import circleGreen from '../../../../images/circles-large/circle_green.png';
import circleGrey from '../../../../images/circles-large/circle_grey.png';
import circleLavender from '../../../../images/circles-large/circle_lavender.png';
import circleMaroon from '../../../../images/circles-large/circle_maroon.png';
import circleNavy from '../../../../images/circles-large/circle_navy.png';
import circleNeonBlue from '../../../../images/circles-large/circle_neon_blue.png';
import circleNeonGreen from '../../../../images/circles-large/circle_neon_green.png';
import circleNeonPurple from '../../../../images/circles-large/circle_neon_purple.png';
import circleNeonRed from '../../../../images/circles-large/circle_neon_red.png';
import circleNeonYellow from '../../../../images/circles-large/circle_neon_yellow.png';
import circleOlive from '../../../../images/circles-large/circle_olive.png';
import circleOrange from '../../../../images/circles-large/circle_orange.png';
import circlePeach from '../../../../images/circles-large/circle_peach.png';
import circlePink from '../../../../images/circles-large/circle_pink.png';
import circlePurple from '../../../../images/circles-large/circle_purple.png';
import circleRed from '../../../../images/circles-large/circle_red.png';
import circleTeal from '../../../../images/circles-large/circle_teal.png';
import circleTurquoise from '../../../../images/circles-large/circle_turquoise.png';
import circleViolet from '../../../../images/circles-large/circle_violet.png';
import circleWhite from '../../../../images/circles-large/circle_white.png';
import circleYellow from '../../../../images/circles-large/circle_yellow.png';

export const userIcon = new L.Icon({
  iconUrl: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png',
  iconRetinaUrl: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png',
  iconSize: new L.Point(27, 43),
});

const pinSmallSize = new L.Point(18, 23);
const pinLargeSize = new L.Point(36, 46);
const circleSmallSize = new L.Point(18, 18);
const circleLargeSize = new L.Point(36, 36);

export const markerIcon = {
  [MarkerIcon.PIN]: {
    [MarkerSize.SMALL]: {
      [Color.BLACK]: new L.Icon({iconUrl: pinBlack, iconSize: pinSmallSize}),
      [Color.BLUE]: new L.Icon({iconUrl: pinBlue, iconSize: pinSmallSize}),
      [Color.BROWN]: new L.Icon({iconUrl: pinBrown, iconSize: pinSmallSize}),
      [Color.CYAN]: new L.Icon({iconUrl: pinCyan, iconSize: pinSmallSize}),
      [Color.GOLD]: new L.Icon({iconUrl: pinGold, iconSize: pinSmallSize}),
      [Color.GREEN]: new L.Icon({iconUrl: pinGreen, iconSize: pinSmallSize}),
      [Color.GREY]: new L.Icon({iconUrl: pinGrey, iconSize: pinSmallSize}),
      [Color.LAVENDER]: new L.Icon({iconUrl: pinLavender, iconSize: pinSmallSize}),
      [Color.MAROON]: new L.Icon({iconUrl: pinMaroon, iconSize: pinSmallSize}),
      [Color.NAVY]: new L.Icon({iconUrl: pinNavy, iconSize: pinSmallSize}),
      [Color.NEON_BLUE]: new L.Icon({iconUrl: pinNeonBlue, iconSize: pinSmallSize}),
      [Color.NEON_GREEN]: new L.Icon({iconUrl: pinNeonGreen, iconSize: pinSmallSize}),
      [Color.NEON_PURPLE]: new L.Icon({iconUrl: pinNeonPurple, iconSize: pinSmallSize}),
      [Color.NEON_RED]: new L.Icon({iconUrl: pinNeonRed, iconSize: pinSmallSize}),
      [Color.NEON_YELLOW]: new L.Icon({iconUrl: pinNeonYellow, iconSize: pinSmallSize}),
      [Color.OLIVE]: new L.Icon({iconUrl: pinOlive, iconSize: pinSmallSize}),
      [Color.ORANGE]: new L.Icon({iconUrl: pinOrange, iconSize: pinSmallSize}),
      [Color.PEACH]: new L.Icon({iconUrl: pinPeach, iconSize: pinSmallSize}),
      [Color.PINK]: new L.Icon({iconUrl: pinPink, iconSize: pinSmallSize}),
      [Color.PURPLE]: new L.Icon({iconUrl: pinPurple, iconSize: pinSmallSize}),
      [Color.RED]: new L.Icon({iconUrl: pinRed, iconSize: pinSmallSize}),
      [Color.TEAL]: new L.Icon({iconUrl: pinTeal, iconSize: pinSmallSize}),
      [Color.TURQUOISE]: new L.Icon({iconUrl: pinTurquoise, iconSize: pinSmallSize}),
      [Color.VIOLET]: new L.Icon({iconUrl: pinViolet, iconSize: pinSmallSize}),
      [Color.WHITE]: new L.Icon({iconUrl: pinWhite, iconSize: pinSmallSize}),
      [Color.YELLOW]: new L.Icon({iconUrl: pinYellow, iconSize: pinSmallSize}),
    },
    [MarkerSize.LARGE]: {
      [Color.BLACK]: new L.Icon({iconUrl: pinBlack, iconSize: pinLargeSize}),
      [Color.BLUE]: new L.Icon({iconUrl: pinBlue, iconSize: pinLargeSize}),
      [Color.BROWN]: new L.Icon({iconUrl: pinBrown, iconSize: pinLargeSize}),
      [Color.CYAN]: new L.Icon({iconUrl: pinCyan, iconSize: pinLargeSize}),
      [Color.GOLD]: new L.Icon({iconUrl: pinGold, iconSize: pinLargeSize}),
      [Color.GREEN]: new L.Icon({iconUrl: pinGreen, iconSize: pinLargeSize}),
      [Color.GREY]: new L.Icon({iconUrl: pinGrey, iconSize: pinLargeSize}),
      [Color.LAVENDER]: new L.Icon({iconUrl: pinLavender, iconSize: pinLargeSize}),
      [Color.MAROON]: new L.Icon({iconUrl: pinMaroon, iconSize: pinLargeSize}),
      [Color.NAVY]: new L.Icon({iconUrl: pinNavy, iconSize: pinLargeSize}),
      [Color.NEON_BLUE]: new L.Icon({iconUrl: pinNeonBlue, iconSize: pinLargeSize}),
      [Color.NEON_GREEN]: new L.Icon({iconUrl: pinNeonGreen, iconSize: pinLargeSize}),
      [Color.NEON_PURPLE]: new L.Icon({iconUrl: pinNeonPurple, iconSize: pinLargeSize}),
      [Color.NEON_RED]: new L.Icon({iconUrl: pinNeonRed, iconSize: pinLargeSize}),
      [Color.NEON_YELLOW]: new L.Icon({iconUrl: pinNeonYellow, iconSize: pinLargeSize}),
      [Color.OLIVE]: new L.Icon({iconUrl: pinOlive, iconSize: pinLargeSize}),
      [Color.ORANGE]: new L.Icon({iconUrl: pinOrange, iconSize: pinLargeSize}),
      [Color.PEACH]: new L.Icon({iconUrl: pinPeach, iconSize: pinLargeSize}),
      [Color.PINK]: new L.Icon({iconUrl: pinPink, iconSize: pinLargeSize}),
      [Color.PURPLE]: new L.Icon({iconUrl: pinPurple, iconSize: pinLargeSize}),
      [Color.RED]: new L.Icon({iconUrl: pinRed, iconSize: pinLargeSize}),
      [Color.TEAL]: new L.Icon({iconUrl: pinTeal, iconSize: pinLargeSize}),
      [Color.TURQUOISE]: new L.Icon({iconUrl: pinTurquoise, iconSize: pinLargeSize}),
      [Color.VIOLET]: new L.Icon({iconUrl: pinViolet, iconSize: pinLargeSize}),
      [Color.WHITE]: new L.Icon({iconUrl: pinWhite, iconSize: pinLargeSize}),
      [Color.YELLOW]: new L.Icon({iconUrl: pinYellow, iconSize: pinLargeSize}),
    },
  },
  [MarkerIcon.CIRCLE]: {
    [MarkerSize.SMALL]: {
      [Color.BLACK]: new L.Icon({iconUrl: circleBlack, iconSize: circleSmallSize}),
      [Color.BLUE]: new L.Icon({iconUrl: circleBlue, iconSize: circleSmallSize}),
      [Color.BROWN]: new L.Icon({iconUrl: circleBrown, iconSize: circleSmallSize}),
      [Color.CYAN]: new L.Icon({iconUrl: circleCyan, iconSize: circleSmallSize}),
      [Color.GOLD]: new L.Icon({iconUrl: circleGold, iconSize: circleSmallSize}),
      [Color.GREEN]: new L.Icon({iconUrl: circleGreen, iconSize: circleSmallSize}),
      [Color.GREY]: new L.Icon({iconUrl: circleGrey, iconSize: circleSmallSize}),
      [Color.LAVENDER]: new L.Icon({iconUrl: circleLavender, iconSize: circleSmallSize}),
      [Color.MAROON]: new L.Icon({iconUrl: circleMaroon, iconSize: circleSmallSize}),
      [Color.NAVY]: new L.Icon({iconUrl: circleNavy, iconSize: circleSmallSize}),
      [Color.NEON_BLUE]: new L.Icon({iconUrl: circleNeonBlue, iconSize: circleSmallSize}),
      [Color.NEON_GREEN]: new L.Icon({iconUrl: circleNeonGreen, iconSize: circleSmallSize}),
      [Color.NEON_PURPLE]: new L.Icon({iconUrl: circleNeonPurple, iconSize: circleSmallSize}),
      [Color.NEON_RED]: new L.Icon({iconUrl: circleNeonRed, iconSize: circleSmallSize}),
      [Color.NEON_YELLOW]: new L.Icon({iconUrl: circleNeonYellow, iconSize: circleSmallSize}),
      [Color.OLIVE]: new L.Icon({iconUrl: circleOlive, iconSize: circleSmallSize}),
      [Color.ORANGE]: new L.Icon({iconUrl: circleOrange, iconSize: circleSmallSize}),
      [Color.PEACH]: new L.Icon({iconUrl: circlePeach, iconSize: circleSmallSize}),
      [Color.PINK]: new L.Icon({iconUrl: circlePink, iconSize: circleSmallSize}),
      [Color.PURPLE]: new L.Icon({iconUrl: circlePurple, iconSize: circleSmallSize}),
      [Color.RED]: new L.Icon({iconUrl: circleRed, iconSize: circleSmallSize}),
      [Color.TEAL]: new L.Icon({iconUrl: circleTeal, iconSize: circleSmallSize}),
      [Color.TURQUOISE]: new L.Icon({iconUrl: circleTurquoise, iconSize: circleSmallSize}),
      [Color.VIOLET]: new L.Icon({iconUrl: circleViolet, iconSize: circleSmallSize}),
      [Color.WHITE]: new L.Icon({iconUrl: circleWhite, iconSize: circleSmallSize}),
      [Color.YELLOW]: new L.Icon({iconUrl: circleYellow, iconSize: circleSmallSize}),
    },
    [MarkerSize.LARGE]: {
      [Color.BLACK]: new L.Icon({iconUrl: circleBlack, iconSize: circleLargeSize}),
      [Color.BLUE]: new L.Icon({iconUrl: circleBlue, iconSize: circleLargeSize}),
      [Color.BROWN]: new L.Icon({iconUrl: circleBrown, iconSize: circleLargeSize}),
      [Color.CYAN]: new L.Icon({iconUrl: circleCyan, iconSize: circleLargeSize}),
      [Color.GOLD]: new L.Icon({iconUrl: circleGold, iconSize: circleLargeSize}),
      [Color.GREEN]: new L.Icon({iconUrl: circleGreen, iconSize: circleLargeSize}),
      [Color.GREY]: new L.Icon({iconUrl: circleGrey, iconSize: circleLargeSize}),
      [Color.LAVENDER]: new L.Icon({iconUrl: circleLavender, iconSize: circleLargeSize}),
      [Color.MAROON]: new L.Icon({iconUrl: circleMaroon, iconSize: circleLargeSize}),
      [Color.NAVY]: new L.Icon({iconUrl: circleNavy, iconSize: circleLargeSize}),
      [Color.NEON_BLUE]: new L.Icon({iconUrl: circleNeonBlue, iconSize: circleLargeSize}),
      [Color.NEON_GREEN]: new L.Icon({iconUrl: circleNeonGreen, iconSize: circleLargeSize}),
      [Color.NEON_PURPLE]: new L.Icon({iconUrl: circleNeonPurple, iconSize: circleLargeSize}),
      [Color.NEON_RED]: new L.Icon({iconUrl: circleNeonRed, iconSize: circleLargeSize}),
      [Color.NEON_YELLOW]: new L.Icon({iconUrl: circleNeonYellow, iconSize: circleLargeSize}),
      [Color.OLIVE]: new L.Icon({iconUrl: circleOlive, iconSize: circleLargeSize}),
      [Color.ORANGE]: new L.Icon({iconUrl: circleOrange, iconSize: circleLargeSize}),
      [Color.PEACH]: new L.Icon({iconUrl: circlePeach, iconSize: circleLargeSize}),
      [Color.PINK]: new L.Icon({iconUrl: circlePink, iconSize: circleLargeSize}),
      [Color.PURPLE]: new L.Icon({iconUrl: circlePurple, iconSize: circleLargeSize}),
      [Color.RED]: new L.Icon({iconUrl: circleRed, iconSize: circleLargeSize}),
      [Color.TEAL]: new L.Icon({iconUrl: circleTeal, iconSize: circleLargeSize}),
      [Color.TURQUOISE]: new L.Icon({iconUrl: circleTurquoise, iconSize: circleLargeSize}),
      [Color.VIOLET]: new L.Icon({iconUrl: circleViolet, iconSize: circleLargeSize}),
      [Color.WHITE]: new L.Icon({iconUrl: circleWhite, iconSize: circleLargeSize}),
      [Color.YELLOW]: new L.Icon({iconUrl: circleYellow, iconSize: circleLargeSize}),
    },
  },
};

export const getMarkerIcon = (entity: Colored): L.Icon => get(
  markerIcon,
  [UserSetting.markerIcon, UserSetting.markerSize, entity.color || Color.BLACK],
  markerIcon[MarkerIcon.PIN][MarkerSize.LARGE].black,
);
