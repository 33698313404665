import React, {ReactNode, ReactText} from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px 30px;
    min-height: 150px;
    margin-bottom: 20px;
    background-color: white;
    border: 1px solid #e1e4e8;
    overflow: hidden;
    &:not(:last-child) {
      margin-right: 20px;
    }
`;

export const Title = styled.p`
  font-size: 14px;
`;

export const Text = styled.span`
  font-size: 20px;
  color: #000;
  display: block;
  margin-top: 10px;
`;

interface Props {
  children?: ReactNode | ReactNode[],
  className?: string,
  title?: ReactNode,
  text?: ReactNode,
}

const BillingInfo: React.FC<Props> = ({children, className, text, title}) => {
  return (
    <Container className={className}>
      {title && <Title>{title}</Title>}
      {text && <Text>{text}</Text>}
      {children}
    </Container>
  );
};

export default BillingInfo;
