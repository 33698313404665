import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{global?: boolean}>`
  margin: auto;
  min-height: ${props => (props.global ? 'calc(100vh - 60px)' : 'inherit')};
  position: relative;
  background-color: transparent;
  top: ${props => (props.global ? 'inherit' : '0')}
`;

interface Props {
  global?: boolean,
}

const LoadingProgress: React.FC<Props> = ({global = true}) => (
  <Container className="loading in-progress" global={global}>
    <div className="loading-pin" />
  </Container>
);

export default LoadingProgress;
