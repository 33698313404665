import {Token} from '@stripe/stripe-js';
import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';

export class UpgradeNetworkService {
  getPlanData = async () => (await BaseNetworkServiceWithPromises.read('plans')).data;

  upgradeOrg = (token: Token, planId: number, quantity: number) => {
    const orgId = safeLocalStorage.currentUser!.organization.id;

    return BaseNetworkServiceWithPromises.create(
      `organizations/${orgId}/upgrade`,
      {cardToken: token.id, plan: {id: planId}, quantity},
    );
  }
}

export default new UpgradeNetworkService();
