import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';

export default function DealLossReasonsNetworkService() {
  /**
     * Returns all deal loss reasons for current organization
     * @returns {Promise<{}[]>}
     */
  const fetchAll = () => BaseNetworkServiceWithPromises.read(`organizations/${safeLocalStorage.currentUser.organization.id}/deal-loss-reasons`);

  /**
     * Creates new loss reason
     * @param name
     */
  const create = (name) => BaseNetworkServiceWithPromises.create(
    `organizations/${safeLocalStorage.currentUser.organization.id}/deal-loss-reasons`,
    {name},
  );

  /**
     * Removes loss reason
     * @param id
     */
  const remove = (id) => BaseNetworkServiceWithPromises.delete(`organizations/${safeLocalStorage.currentUser.organization.id}/deal-loss-reasons/${id}`);

  return {
    fetchAll,
    create,
    remove,
  };
}
