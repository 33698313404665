import template from './multi-select-table.html';

class MultiSelectTableController {
  constructor($window) {
    Object.assign(this, {
      $window,
      MODEL: $window.DataModel,
    });
  }

  $onChanges(changesObj) {
    const {items} = changesObj;

    if (items.currentValue) {
      this.displayItems = this.filterItems ? this.removeFilterItems(items.currentValue) : items.currentValue;
    }
  }

  removeFilterItems(items) {
    const filteredItems = items.filter((item) => this.MODEL[this.filterItems][item.id] === undefined);

    return filteredItems;
  }

  toggleIndividualItem() {
    this.allItems = this.items.reduce((allSelected, item) => allSelected && this.selectedItems[item.id], true);
  }

  toggleAllItems() {
    if (this.allItems) {
      this.items.forEach((item) => {
        this.selectedItems[item.id] = true;
      });
    } else {
      this.selectedItems = {};
    }
  }
}

MultiSelectTableController.$inject = ['$window'];

const multiSelectTable = {
  bindings: {
    items: '<',
    columns: '<',
    selectedItems: '=',
    filterItems: '<',
  },
  controller: 'MultiSelectTableController',
  template,
};

export {MultiSelectTableController, multiSelectTable};
