import get from 'lodash/get';
import EntityFieldModel from './entity-field-model';
import DateField from './field/date-field';
import {locationFormatter, listFormatter} from './formatter';
import {createField} from './utils';
import countryList from './country-list.json';
import FieldFeature from './field/FieldFeature';
import {defaultFilterParams} from './column-types';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';
import EntityType from '../../react/type/EntityType';

const getAllUsers = () => [...window.DataModel.TeamsService.subTeamUsers, window.DataModel.owner];

const leadFieldModel = new EntityFieldModel(EntityType.lead, [
  {
    name: 'company',
    displayName: 'Company name',
    editFormDetails: {
      label: 'Company Name',
      model: 'company',
      type: 'text',
      id: 'company',
      name: 'company',
      columnWidth: '12',
    },
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.TEXT],
  },
  {
    name: 'firstName',
    displayName: 'First name',
    editFormDetails: {
      label: 'First Name',
      model: 'firstName',
      type: 'text',
      id: 'firstName',
      name: 'firstName',
      columnWidth: '6',
    },
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.TEXT],
  },
  {
    name: 'lastName',
    displayName: 'Last name',
    editFormDetails: {
      label: 'Last Name',
      model: 'lastName',
      type: 'text',
      id: 'lastName',
      name: 'lastName',
      columnWidth: '6',
    },
    filter: {type: 'type1'},
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.TEXT],
  },
  {
    name: 'address',
    displayName: 'Street',
    displayOrder: 1,
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.ADDRESS],
    editFormDetails: {
      label: 'Street Address',
      model: 'address',
      type: 'text',
      id: 'address',
      name: 'address',
      columnWidth: '12',
      linkId: 'gpsBox',
    },
    filter: {type: 'type1'},
  },
  {
    name: 'city',
    displayName: 'City',
    displayOrder: 2,
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.ADDRESS],
    editFormDetails: {
      label: 'City',
      model: 'city',
      type: 'text',
      id: 'city',
      name: 'city',
      columnWidth: '6',
    },
    filter: {type: 'type1'},
  },
  {
    name: 'region',
    displayName: 'State',
    displayOrder: 3,
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.ADDRESS],
    editFormDetails: {
      label: 'State/Province',
      model: 'state',
      type: 'text',
      id: 'state',
      name: 'state',
      columnWidth: '6',
    },
    filter: {type: 'type1'},
  },
  {
    name: 'postalCode',
    displayName: 'Postal Code',
    displayOrder: 5,
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.ADDRESS],
    editFormDetails: {
      label: 'Postal Code',
      model: 'zip',
      type: 'text',
      id: 'zip',
      name: 'zip',
      columnWidth: '6',
    },
    filter: {type: 'type1'},
  },
  {
    name: 'country',
    displayName: 'Country',
    displayOrder: 4,
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.ADDRESS],
    editFormDetails: {
      label: 'Country',
      model: 'country',
      type: 'select',
      id: 'country',
      name: 'country',
      selectName: 'country',
      columnWidth: '6',
      options: countryList,
    },
    filter: {type: 'type1'},
  },
  {
    name: 'groups',
    displayName: 'Groups',
    features: [FieldFeature.LIST, FieldFeature.NON_INTEGRATION],
    valueFormatter: listFormatter('name'),
  },
  {
    name: 'phone',
    displayName: 'Phone number',
    filter: {type: 'type1'},
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.TEXT],
  },
  {
    name: 'website',
    displayName: 'Website',
    editFormDetails: {
      label: 'Website',
      model: 'website',
      type: 'text',
      id: 'website',
      name: '',
      columnWidth: '6',
      formGroupClass: 'form-group--relative',
      formControlClass: 'form-control--website',
      linkImg: './images/websiteImage.svg',
      linkId: 'websiteBox',
    },
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.TEXT],
  },
  {
    name: 'color',
    displayName: 'Color',
    editFormDetails: {
      label: 'Pin Color',
      model: 'color',
      type: 'select',
      id: 'color',
      name: '',
      selectName: 'color',
      columnWidth: '6',
    },
    filter: {type: 'type1'},
    customGridProperties: {
      filterParams: {
        ...defaultFilterParams,
        values: (params) => params.success(get(safeLocalStorage, 'currentUser.organization.plan.colors', [])),
      },
    },
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.LIST],
  },
  {
    name: 'email',
    displayName: 'E-mail',
    filter: {type: 'type1'},
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.TEXT],
  },
  {
    name: 'annualRevenue',
    displayName: 'Annual Revenue',
    filter: {type: 'type2'},
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.NUMERIC],
  },
  {
    name: 'numEmployees',
    displayName: 'Number of Employees',
    editFormDetails: {
      label: '# Employees',
      model: 'numEmployees',
      type: 'number',
      id: 'numEmployees',
      name: '',
      columnWidth: '12',
    },
    filter: {type: 'type2'},
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.NUMERIC],
  },
  {
    name: 'user',
    displayName: 'Owner',
    value: ['user', 'username'],
    filter: {
      type: 'type_singleselect',
      name: 'userId',
      nameField: 'username',
      getValues: getAllUsers,
      mapFieldValueToId: (values) => {
        const username2id = {};
        getAllUsers().forEach((user) => {
          username2id[user.username] = user.id;
        });
        return values.map((value) => username2id[value]);
      },
      mapIdToFieldValue: (values) => {
        const id2username = {};
        getAllUsers().forEach((user) => {
          id2username[user.id] = user.username;
        });
        return values.map((value) => id2username[value]);
      },
    },
    features: [FieldFeature.FILTERABLE, FieldFeature.LIST],
    customGridProperties: {
      filterParams: {
        ...defaultFilterParams,
        values: (params) => {
          params.success(getAllUsers().map(user => user.username));
        },
      },
    },
  },
  {
    name: 'notes',
    displayName: 'Notes',
    valueFormatter: listFormatter('note'),
    features: [FieldFeature.LIST, FieldFeature.NON_INTEGRATION],
  },
  {
    name: 'importId',
    displayName: 'Import Id',
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.TEXT, FieldFeature.NON_INTEGRATION],
  },
  {
    name: 'geoPoint',
    displayName: 'Location',
    displayOrder: 6,
    features: [FieldFeature.ADDRESS, FieldFeature.NON_INTEGRATION],
    value: ['geoPoint', 'coordinates'],
    valueFormatter: locationFormatter,
  },
  {
    name: 'leadSource',
    displayName: 'Lead source',
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.TEXT],
  },
  {
    name: 'parentlead',
    displayName: 'Parent lead',
    value: ['parentlead', 'name'],
    features: [],
  },
  {
    name: 'leadStatus',
    displayName: 'Lead status',
    editFormDetails: {
      isLeadDetails: true,
      label: 'Lead status',
      model: 'leadStatus',
      type: 'text',
      id: 'leadStatus',
      name: 'leadStatus',
      columnWidth: '12',
      disabled: true,
    },
    filter: {type: 'type1'},
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.TEXT],
  },
  {
    name: 'amount',
    displayName: 'Amount',
    editFormDetails: {
      isLeadDetails: true,
      label: 'Amount',
      model: 'amount',
      type: 'text',
      id: 'amount',
      name: 'amount',
      columnWidth: '12',
    },
    filter: {type: 'type1'},
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.TEXT],
  },
  {
    name: 'leadSource',
    displayName: 'Lead source',
    editFormDetails: {
      isLeadDetails: true,
      label: 'Lead source',
      model: 'leadSource',
      type: 'text',
      id: 'leadSource',
      name: 'leadSource',
      columnWidth: '12',
    },
    filter: {type: 'type1'},
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.TEXT],
  },
  {
    name: 'industry',
    displayName: 'Industry',
    editFormDetails: {
      isLeadDetails: true,
      label: 'Industry',
      model: 'industry',
      type: 'text',
      id: 'industry',
      name: 'industry',
      columnWidth: '12',
    },
    filter: {type: 'type1'},
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.TEXT],
  },
  new DateField({name: 'createdAt', displayName: 'Created At'}),
  new DateField({name: 'updatedAt', displayName: 'Modified At', customGridProperties: {sort: 'desc'}}),
].map(createField));

export default leadFieldModel;
