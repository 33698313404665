import React from 'react';
import styled from 'styled-components';
import LoadingProgress from '../../../../components/LoadingProgress/LoadingProgress';

const Container = styled.div`
  margin-top: 6rem;
`;

interface Props {}

const StepDataLoadingProgress: React.FC<Props> = () => (
  <Container>
    <LoadingProgress global={false} />;
  </Container>
);

export default StepDataLoadingProgress;
