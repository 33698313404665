import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import {AnyEntityId} from '../react/type/AnyEntity';
import EntityType from '../react/type/EntityType';
import Note from '../react/type/Note';
import ListResponse from '../react/type/service/ListResponse';

type CompanyPersonOrDeal = EntityType.COMPANY | EntityType.PERSON | EntityType.DEAL;

export class NotesNetworkService {
  public fetchNotes(entityType: CompanyPersonOrDeal, entityId: AnyEntityId): Promise<ListResponse<Note>> {
    return BaseNetworkServiceWithPromises.read(`${entityType}/${entityId}/notes?$order=-updatedAt`);
  }

  public createNote(entityType: CompanyPersonOrDeal, entityId: AnyEntityId, note: Partial<Note>): Promise<Note> {
    return BaseNetworkServiceWithPromises.create(`${entityType}/${entityId}/notes`, note);
  }

  public deleteNote(entityType: CompanyPersonOrDeal, entityId: AnyEntityId, noteId: Note['id']): Promise<any> {
    return BaseNetworkServiceWithPromises.delete(`${entityType}/${entityId}/notes/${noteId}`);
  }
}

export default new NotesNetworkService();
