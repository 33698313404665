import {useMemo} from 'react';
import {SelectFieldOption} from '../type/react-md/SelectFieldOption';

export type OptionMapper<V, L> = (item: any) => SelectFieldOption<V, L>;
export const defaultMapper = <V, L>(item: {id: V, name: L}) => ({label: item.name, value: item.id});
const useSelectFieldOptions = <V=number, L=string>(
  list: any[],
  mapper: OptionMapper<V, L> = defaultMapper,
) => useMemo(() => list.map(mapper), [list, mapper]);

export default useSelectFieldOptions;
