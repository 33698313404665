import template from './reports-list.html';
import './reports-list.scss';
import ReportActionsCellRenderer from '../../common/grid/ReportActionsCellRenderer';

class ReportsListController {
  constructor($scope, $timeout, ReportsService, BaseController) {
    Object.assign(this, {
      $scope,
      $timeout,
      MODEL: window.DataModel,
      ReportsService,
      toggleAllReports: ReportsService.toggleAllReports,
      toggleReportSelection: ReportsService.toggleReportSelection,
      openSelectedReport: ReportsService.openSelectedReport,
      sortTable: BaseController.sortTable,
      setGridRowData: BaseController.setGridRowData,
      updateColumnDefinitions: BaseController.updateColumnDefinitions,
      initializeGrid: BaseController.initializeGrid,
      getGridApi: BaseController.getGridApi,
    });

    this.initializeGrid(
      document.querySelector('#data-grid'),
      {
        onSelectionChanged: this.handleRowSelectionChanged.bind(this),
        onRowClicked: ({data}) => this.openSelectedReport(data),
        rowClass: 'mmc-collection-grid__row cursor-pointer',
      },
    );

    const fields = this.MODEL.reportFieldModel.fields;

    const actionsColumn = {
      headerName: 'Actions',
      lockVisible: true,
      lockPinned: true,
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      suppressAutoSize: true,
      suppressSizeToFit: true,
      cellRenderer: ReportActionsCellRenderer,
      cellRendererParams: {
        onEdit: this.openSelectedReport.bind(this),
        onDownload: this.downloadReport.bind(this),
        onDelete: this.sendToTrash.bind(this),
      },
      width: 200,
    };

    this.updateColumnDefinitions(fields, true, false, [actionsColumn]);
    this.$scope.$watchCollection('$ctrl.MODEL.ReportsService.currentReports', (rows) => {
      this.$timeout(() => {
        this.setGridRowData(rows);
      });
    });
  }

  $onInit() {
    this.highlightSelectedReportView(this.MODEL.ReportsService.selectedReportView, this.MODEL.ReportsService.selectedFolder);
  }

  handleRowSelectionChanged({api}) {
    const selectedRows = api.getSelectedNodes();
    this.MODEL.ReportsService.selectedReportIds = selectedRows.map(({data}) => data.id);
    window.refreshDom({selectedReportIds: this.MODEL.ReportsService.selectedReportIds}, 'ReportsService');
  }

  highlightSelectedReportView(folderView, selectedFolder) {
    this.ReportsService.highlightSelectedReportView(folderView, selectedFolder);
  }

  updateCurrentReports(folderView, selectedFolder) {
    this.ReportsService.updateCurrentReports(folderView, selectedFolder);
  }

  async deleteAllSelected() {
    try {
      await swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover these reports!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!',
      });
      this.MODEL.show.loader = true;
      await this.ReportsService.deleteAllSelected(this.MODEL.ReportsService.selectedReportIds);
      this.ReportsService.clearSelectedReportIds();
      this.getGridApi().deselectAll();
      await this.ReportsService.fetchReports();

      window.refreshDom({loader: false}, 'show');
      swal('Deleted', 'These reports have been deleted.', 'success');
    } catch (e) {
      if (e === 'cancel') {
        return;
      }

      window.refreshDom({loader: false}, 'show');
      swal('Uh-oh', 'Failed to delete some reports. Please try again later.', 'error');
    }
  }

  async downloadAllSelected() {
    try {
      await this.ReportsService.downloadAllSelected(this.MODEL.ReportsService.selectedReportIds);
      swal('Generated', 'These reports will be sent to your email shortly.', 'success');
    } catch (e) {
      console.error(e);
      swal('Uh-oh', 'Failed to generate some reports. Please try again later.', 'error');
    }
  }

  async clickStar(report) {
    try {
      await this.ReportsService.clickStar(report);
    } catch (e) {
      console.error('failed to star');
    }
  }

  async downloadReport(report) {
    if (report.status && ['queued', 'running'].includes(report.status)) {
      swal('Generating', "This report is being generated already. Please wait, it'll be delivered to your email.", 'success');
      return;
    }

    this.MODEL.show.loader = true;

    try {
      await this.ReportsService.downloadReport(report);
      swal('Generated', 'This report will be sent to your email shortly.', 'success');
    } catch (e) {
      let errMsg = 'Failed to generate this report. Please try again later.';
      if (e && Array.isArray(e.validationErrors) && e.validationErrors.length) {
        errMsg = e.validationErrors.reduce((msg, err) => `${msg}${err.message} `, '');
      }
      swal('Uh-oh', errMsg, 'error');
    }

    window.refreshDom({loader: false}, 'show');
    await this.ReportsService.fetchReports();
  }

  async sendToTrash(report) {
    try {
      await swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this report!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!',
      });

      this.MODEL.show.loader = true;
      await this.ReportsService.sendToTrash(report);
      await this.ReportsService.fetchReports();

      window.refreshDom({loader: false}, 'show');
      swal('Deleted', 'This report has been deleted.', 'success');
    } catch (e) {
      if (e === 'cancel') {
        return;
      }

      window.refreshDom({loader: false}, 'show');
      console.error(e);
      swal('Oops...', 'There was a problem deleting this report.', 'error');
    }
  }
}

ReportsListController.$inject = ['$scope', '$timeout', 'ReportsService', 'BaseController'];

const reportsList = {
  bindings: {},
  controller: 'ReportsListController',
  template,
};

export {ReportsListController, reportsList};
