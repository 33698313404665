import BaseNetworkService from './base-network-service/base-network-service';
import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import Company from '../react/type/Company';
import ListResponse from '../react/type/service/ListResponse';
import DataModel from '../common/data-model';

type Params = {
  $order?: string,
  $limit?: number,
  $offset?: number,
  $filters?: string,
  $columns?: string,
}

type CompanyId = Company['id'];

class AccountsNetworkService {
  fetchAllAccounts(
    map: boolean,
    filters: object,
    page: number = 1,
    column: string = 'updatedAt',
    ascending: boolean = false,
    limit?: number,
    columns?: string,
  ): Promise<ListResponse<Company>> {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    let params: Params = {
      $order: ascending ? column : `-${column}`,
    };

    if (map) {
      filters = {...filters, mapped: true};
      params.$limit = limit ?? DataModel.recordsUpperLimit;
    } else {
      if (page) {
        params.$offset = (page - 1) * (limit ?? DataModel.pageSize);
      }

      params.$limit = limit ?? DataModel.pageSize;
    }

    if (Object.keys(filters).length) {
      params = Object.assign(params, {$filters: JSON.stringify(filters)});
    }
    if (columns) {
      params.$columns = columns;
    }
    return BaseNetworkService.read(`organizations/${orgId}/accounts`, params);
  }

  createAccount(payload: any) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/accounts`;
    return BaseNetworkService.create(endPoint, payload);
  }

  /**
     * Bulk-create accounts
     * @param {{name: string, address: string, city: string, color: string}[]} accounts accounts to create
     * @returns {Promise}
     */
  createAccounts(accounts: Partial<Company>[], leadGen: boolean = false) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/accounts/bulk-create`;
    const options: any = {headers: {}};
    if (leadGen) {
      options.headers['x-mmc-source'] = 'lead-gen';
    }
    return BaseNetworkServiceWithPromises.create(endPoint, {accountIds: accounts}, options);
  }

  updateAccount(id: CompanyId, payload: Partial<Company>) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/accounts/${id}`;
    return BaseNetworkService.update(endPoint, payload);
  }

  fetchAccount(id: CompanyId) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    return BaseNetworkService.read(`organizations/${orgId}/accounts/${id}`);
  }

  /**
   * Bulk update accounts
   * @param {{id: number, color: string}[]} accounts accounts to update
   * @returns {*}
   */
  updateAccounts(accounts: Array<Pick<Company, 'id' | 'color'>>) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/accounts/bulk-update`;
    return BaseNetworkServiceWithPromises.update(endPoint, {accountIds: accounts});
  }

  deleteAccount(id: CompanyId) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    return BaseNetworkServiceWithPromises.delete(`organizations/${orgId}/accounts/${id}`);
  }

  /**
     * Bulk-deletes given accounts by their ids.
     * @param {number[]} ids
     * @returns {*}
     */
  deleteAccounts(ids: CompanyId[]) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    return BaseNetworkServiceWithPromises.delete(
      `organizations/${orgId}/accounts/bulk-delete`,
      {accountIds: ids.map(id => ({id}))},
    );
  }

  async fetchChildAccounts(id: CompanyId) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const filters = {
      parentAccountId: id,
    };
    const endPoint = `organizations/${orgId}/accounts?$filters=${encodeURIComponent(JSON.stringify(filters))}`;
    return BaseNetworkService.read(endPoint);
  }

  // fetches all account groups
  fetchAllAccountGroups(
    filters?: object,
    page: number = 1,
    column: string = 'displayOrder',
    ascending: boolean = false,
    limit: number = 0,
  ) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const {pageSize} = window.DataModel;
    const params = {
      $order: ascending ? column : `-${column}`,
      $filters: filters ? JSON.stringify(filters) : undefined,
      $limit: limit === 0 ? pageSize : limit,
      $offset: page ? Math.max(0, page - 1) * pageSize : undefined,
    };

    return BaseNetworkService.read(`organizations/${orgId}/account-groups`, params);
  }

  async fetchAccountGroupsCount() {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/account-groups?$limit=0`;

    return (await BaseNetworkServiceWithPromises.read(endPoint)).total;
  }

  /**
     * Bulk update account groups. Will only update fields that are given in the groupsData array. Originally
     * created to bulk update group's displayOrder field.
     *
     * @param {{id: number}[]} groupsData groups data to update, must include group id and any other fields that
     * need to be updated
     * @returns {Promise<*>}
     */
  bulkUpdateAccountGroups(groupsData: Array<any>) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/account-groups/bulk`;
    const payload = {accountGroups: groupsData.map(data => ({...data, _action_: 'update'}))};
    return BaseNetworkServiceWithPromises.create(endPoint, payload);
  }

  // get accounts count
  async getAccountsCount(): Promise<number> {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    return (await BaseNetworkService.read(`organizations/${orgId}/accounts`, {$limit: 0})).total;
  }
}

export default new AccountsNetworkService();
