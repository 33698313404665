class ReportActionsCellRenderer {
  init(agParams) {
    this.agParams = agParams;
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = `
      <div class="reportHoverOptions">
        <span class="hideDisplay reportHoverChildOptions">
            <img
                class="reportIcon reportHoverChildOptions"
                src="./images/reports/editR.svg"
                ng-click="$ctrl.openSelectedReport(report)"
                alt="Edit"
            />
            <img
                class="reportIcon reportHoverChildOptions"
                src="./images/reports/downloadR.svg"
                ng-click="$ctrl.downloadReport(report)"
                alt="Download"
            />
            <img
                class="reportIcon reportHoverChildOptions"
                src="./images/reports/binR.svg"
                ng-click="$ctrl.sendToTrash(report)"
                alt="Delete"
            />
        </span>
        <i
            class="fa fa-ellipsis-h marginRight reportHoverChildOptions"
            aria-hidden="true"
        ></i>
    </div>
    `;

    this.editButton = this.eGui.querySelector('img[alt="Edit"]');
    this.onEditButtonClickListener = this.onEditButtonClick.bind(this);
    this.editButton.addEventListener('click', this.onEditButtonClickListener);

    this.downloadButton = this.eGui.querySelector('img[alt="Download"]');
    this.onDownloadButtonClickListener = this.onDownloadButtonClick.bind(this);
    this.downloadButton.addEventListener('click', this.onDownloadButtonClickListener);

    this.deleteButton = this.eGui.querySelector('img[alt="Delete"]');
    this.onDeleteButtonClickListener = this.onDeleteButtonClick.bind(this);
    this.deleteButton.addEventListener('click', this.onDeleteButtonClickListener);
  }

  getGui() {
    return this.eGui;
  }

  onEditButtonClick() {
    console.log('edit clicked', this.agParams);
    this.agParams.onEdit(this.agParams.data);
  }

  onDownloadButtonClick() {
    this.agParams.onDownload(this.agParams.data);
  }

  onDeleteButtonClick() {
    this.agParams.onDelete(this.agParams.data);
  }

  destroy() {
    if (this.onEditButtonClickListener) {
      this.editButton.removeEventListener('click', this.onEditButtonClickListener);
    }
    if (this.onDownloadButtonClickListener) {
      this.downloadButton.removeEventListener('click', this.onDownloadButtonClickListener);
    }
    if (this.onDeleteButtonClickListener) {
      this.deleteButton.removeEventListener('click', this.onDeleteButtonClickListener);
    }
  }
}

export default ReportActionsCellRenderer;
