export default function CacheService() {
  // main utility functions
  const MODEL = window.DataModel;

  // service to return
  const service = {};

  // check for filters
  service.checkForFilters = () => {
    if (MODEL.FilterService) {
      const crm = service.getCrmType();
      // check for cached filters and apply them
      if (crm) {
        const currentFilters = MODEL.cachedState[crm].filters;
        if (currentFilters && currentFilters.length) {
          MODEL.FilterService.filtersSelected = currentFilters;
          MODEL.FilterService.filterActive = true;
        } else {
          MODEL.FilterService.filtersSelected = [];
          MODEL.FilterService.filterActive = false;
        }
      } else {
        MODEL.FilterService.filtersSelected = [];
        MODEL.FilterService.filterActive = false;
      }
    }
  };

  service.getCrmType = () => {
    let crm = '';
    if (window.isOnPage('accounts')) {
      crm = window.isOnPage('routing') ? 'routes' : 'accounts';
      if (window.isOnPage('territories')) {
        crm = 'territories';
      }
    } else if (window.isOnPage('contacts')) {
      crm = 'contacts';
      if (window.isOnPage('routing')) {
        crm = 'routes';
      }
    } else if (window.isOnPage('deals')) {
      crm = 'deals';
    } else if (window.isOnPage('activities')) {
      crm = 'crmActivities';
    } else if (window.isOnPage('reports')) {
      crm = 'reports';
    }
    return crm;
  };

  service.cacheCurrentView = (userView, pageNum, options) => {
    const crm = service.getCrmType();
    if (crm) {
      if (userView === MODEL.cachedState[crm].user) {
        MODEL.cachedState[crm].page = pageNum;
      } else {
        MODEL.cachedState[crm].page = 1;
      }
      MODEL.cachedState[crm].user = userView;

      service.addDealParam(crm, options);
      service.addCrmActivitiesParam(crm, options);
    } else {
      console.error(new Error('oops... Blunder -> no crm was present but cache was called. Please check'));
    }
  };

  // add deal params
  service.addDealParam = (crm, options = {}) => {
    if (crm !== 'deals') return;

    Object.assign(MODEL.cachedState.deals, options);
  };

  // add deal params
  service.addCrmActivitiesParam = (crm, options = {}) => {
    if (crm !== 'crmActivities') return;

    if (options.crmActivityTypeId) {
      if (MODEL.cachedState.crmActivities.crmActivityTypeIds.includes(options.crmActivityTypeId)) {
        MODEL.cachedState.crmActivities.crmActivityTypeIds = MODEL.cachedState.crmActivities.crmActivityTypeIds.filter(id => id !== options.crmActivityTypeId);
      } else {
        MODEL.cachedState.crmActivities.crmActivityTypeIds.push(options.crmActivityTypeId);
      }
    }

    if (options.crmObjectType) {
      MODEL.cachedState.crmActivities.crmObjectType = options.crmObjectType;
    }

    if (options.status) {
      MODEL.cachedState.crmActivities.status = options.status;
    }
  };

  service.setFilterCache = (reset = false) => {
    const crm = service.getCrmType();
    if (crm) {
      MODEL.cachedState[crm].filters = reset ? [] : MODEL.FilterService.filtersSelected;
      MODEL.cachedState[crm].page = 1;
    }
  };

  // set the deals cache and send the stage -> stage is required
  service.setDealsCacheVar = () => {
    const {
      page, user, dealType, dealStage,
    } = MODEL.cachedState.deals;

    if (page) {
      MODEL.currentPageNum = page;
    }

    if (user) {
      MODEL.currentLeadsView = user;
    }

    if (dealType) {
      MODEL.FunnelService.currentDealTypeId = dealType;
    }

    service.setFilterCache();

    return dealStage || '';
  };

  // reset general cache variables
  service.resetCacheVar = (crm) => {
    MODEL.cachedState[crm].filters = [];
    MODEL.cachedState[crm].page = 1;
    MODEL.cachedState[crm].column = 'updatedAt';
    MODEL.cachedState[crm].ascending = false;
  };

  return service;
}
