import React, {useMemo} from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import CircularProgress from 'react-md/lib/Progress/CircularProgress';
import Company from '../../type/Company';
import CompanyConnections from './component/CompanyConnections';
import CompanyDetails from './component/CompanyDetails';
import EntityFieldModel from '../../../common/field-model/entity-field-model';
import CompanyMainDetails from './component/CompanyMainDetails';
import Activity from '../../type/Activity';
import ActivityType from '../../type/ActivityType';
import Person from '../../type/Person';
import Deal from '../../type/Deal';
import File from '../../type/File';
import Route from '../../type/Route';
import EntityType from '../../type/EntityType';
import {maxWidth, minWidth} from '../../style/media';
import Centered from '../../style/Centered';
import theme from '../../style/theme';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative; 
  ${minWidth.wideDesktop`
    flex-direction: column;
  `}; 
  ${maxWidth.wideDesktop`
    flex-direction: row;
  `};
`;

const ContentContainer = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  max-height: 100%;
  overflow: scroll;
  flex: 1 1 100%;
  width: calc(100vw - 400px);
  
  ${minWidth.wideDesktop`
    margin: 0 400px;
    width: calc(100vw - 800px);
  `};
`;

const Sidebars = styled.div`
  ${minWidth.wideDesktop`
    pointer-events: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    // to make it overlap the ContentContainer
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
  `};
  ${maxWidth.wideDesktop`
    pointer-events: auto;
    display: block;
    overflow: auto;
    min-width: 400px;
    max-width: 400px;
    border-right: 1px solid ${theme.color.border};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  `};
`;

const LeftPanel = styled.div`
  background-color: white;
  pointer-events: auto;
  padding: 32px 8px;
  min-width: 400px;
  max-width: 400px;
  ${minWidth.wideDesktop`
    border-right: 1px solid ${theme.color.border};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  `};
  ${maxWidth.wideDesktop`
    padding: 32px 8px 0;
  `};
`;

const RightPanel = styled.div`
  background-color: white;
  pointer-events: auto;
  padding: 32px 8px;
  min-width: 400px;
  max-width: 400px;
  ${minWidth.wideDesktop`
    border-left: 1px solid ${theme.color.border};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  `};
  ${maxWidth.wideDesktop`
    padding: 0 8px 32px;
  `};
`;

interface Props {
  AddEditService: {
    fetchCrmActivities: (entityType: string, entityId: number, type: 'upcoming' | 'overdue' | 'completed') => void,
  },
  activities: Activity[],
  activityTypes?: ActivityType[],
  childCompanies: Company[],
  company?: Company,
  className?: string,
  fieldModel: EntityFieldModel,
  files: File[],
  getDirectionsToAddress: (company: Company) => void,
  goToEntity: (entityType: EntityType, entityId: number) => void,
  relatedDeals: Deal[],
  relatedPeople: Person[],
  relatedRoutes: Route[],
}

const CompanyRecordView: React.FC<Props> = (
  props,
) => {
  const {
    AddEditService,
    activities,
    activityTypes,
    childCompanies: rawChildCompanies,
    className,
    company,
    fieldModel,
    files,
    getDirectionsToAddress,
    goToEntity,
    relatedDeals,
    relatedPeople,
    relatedRoutes,
  } = props;

  // TODO: fetch
  // 1. activity types
  // 2. /me
  // 3. currencies (limit 500)
  // 4. account custom fields
  // 5. account groups (limit 10k)
  // 6. account itself with {"includeGroups":true,"includeCustomFields":true,"includeNotes":true,"includeRoutes":true,"includeAccessStatus":true}
  // 7. files for account
  // 8. custom field values for account

  const childCompanies = useMemo(() => Object.values(rawChildCompanies), [rawChildCompanies]);
  const deals = useMemo(() => Object.values(relatedDeals), [relatedDeals]);
  const people = useMemo(() => Object.values(relatedPeople), [relatedPeople]);

  if (!company) {
    return (
      <Centered>
        <CircularProgress id="company-edit-loading" centered scale={2} />
      </Centered>
    );
  }

  return (
    <Container>
      <Sidebars>
        <LeftPanel
          id="company-edit-details-drawer"
        >
          <CompanyDetails company={company} fieldModel={fieldModel} />
        </LeftPanel>
        <RightPanel
          id="company-edit-connections-drawer"
        >
          <CompanyConnections
            childCompanies={childCompanies}
            company={company}
            deals={deals}
            files={files}
            goToEntity={goToEntity}
            people={people}
            routes={relatedRoutes}
          />
        </RightPanel>
      </Sidebars>
      <ContentContainer className={cn('mmc-record-view', className)}>
        <CompanyMainDetails
          activities={activities}
          activityTypes={activityTypes}
          company={company}
          fetchCrmActivities={AddEditService.fetchCrmActivities}
          getDirectionsToAddress={getDirectionsToAddress}
          goToEntity={goToEntity}
        />
      </ContentContainer>
    </Container>
  );
};

export default CompanyRecordView;
