import {AnyEntity} from '../react/type/AnyEntity';

export const entityToRecordNameString = (entityName: string, longitude: number, latitude: number): string =>
  `${entityName.replace(/\\s/g, '')}_${longitude.toFixed(6)}_${latitude.toFixed(6)}`;

const convertEntitiesToRecordName = (entities: AnyEntity[]): {[key: string]: boolean} => {
  const response: {[key: string]: boolean} = {};
  entities.forEach(entity => {
    if (entity.geoPoint) {
      const [longitude, latitude] = entity?.geoPoint.coordinates;
      response[entityToRecordNameString(entity.name, longitude, latitude)] = true;
    }
  });
  return response;
};

export default convertEntitiesToRecordName;
