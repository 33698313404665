import React from 'react';
import styles from './SearchButton.module.scss';
import FontAwesomeIcon from '../FontAwesomeIcon';

interface Props {
  onClick: () => void;
}

const SearchButton: React.FC<Props> = ({onClick}) => (
  <nav>
    <FontAwesomeIcon onClick={onClick} className={styles.container} icon="fa-search" />
  </nav>
);

export default SearchButton;
