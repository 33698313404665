import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {PlanCard, PlanDescription, PlanPrice, PlanText} from './PlanCard';
import Tier from '../../../type/plans/Tier';
import {calculatePrices, calculateSubscriptionPrice, getProrateRatioDetails} from '../util/util';
import Addon from '../../../type/plans/Addon';
import Service from '../../../type/plans/Service';
import CurrentPlan from '../../../type/plans/CurrentPlan';
import TooltipIcon from '../../../components/TooltipIcon/TooltipIcon';

const PlanSummary = styled.div`
  font-size: 14px;
  display: inline-flex;
  align-items: center;
`;

interface Props {
  addons: Addon[],
  currentPlan: CurrentPlan,
  licenses: number,
  selectedAddons: Addon[],
  selectedServices: Service[],
  selectedTier: Tier,
}

const SummaryItem: React.FC<Props> = ({
  currentPlan,
  licenses,
  selectedAddons,
  selectedTier,
}) => {
  const {t} = useTranslation(['billing']);

  const {remainingDays, daysInCurrentBillingCycle, ratio} = getProrateRatioDetails(currentPlan);
  const ratioText = `(${remainingDays} / ${daysInCurrentBillingCycle})`;
  const totalPrice = calculateSubscriptionPrice(selectedTier, selectedAddons, licenses);

  const {adjustedPrice, proratedPrice, dueTodayPrice, switchingToAnnual} =
    calculatePrices(currentPlan, selectedTier, licenses, ratio);

  const showAdjustedPrice = !currentPlan.tier.isTrial && adjustedPrice.value !== 0;
  const showProratedPrice = !currentPlan.tier.isTrial && proratedPrice.value !== 0;

  return (
    <>
      <PlanCard>
        <PlanText>
          <PlanSummary>
            {t('total_text', {interval: selectedTier.interval})}
          </PlanSummary>
          <PlanDescription>
            {t('licenses', {count: licenses})}
            &nbsp;×&nbsp;
            {t('price', {price: selectedTier.price})}
          </PlanDescription>
        </PlanText>
        <PlanPrice>{t('price_precise', {price: totalPrice})}</PlanPrice>
      </PlanCard>

      {showAdjustedPrice && (
        <PlanCard>
          <PlanText>
            <PlanSummary>
              {t('Adjusted Total for Existing Users')}
              <TooltipIcon tooltipLabel={t('Adjusted Total Hint')} />
            </PlanSummary>
            <PlanDescription>
              {t('licenses', {count: currentPlan.licenses})}
              &nbsp;×&nbsp;
              {switchingToAnnual
                ? t('price', {price: currentPlan.tier.price})
                : `(${t('price', {price: selectedTier.price})} - ${t('price', {price: currentPlan.tier.price})})`
              }
              &nbsp;×&nbsp;{ratioText}
            </PlanDescription>
          </PlanText>
          <PlanPrice>{t('price_precise', {price: adjustedPrice})}</PlanPrice>
        </PlanCard>
      )}

      {showProratedPrice && !switchingToAnnual && (
        <PlanCard>
          <PlanText>
            <PlanSummary>
              {t('Prorated Price for New Users')}
              <TooltipIcon tooltipLabel={t('Prorated Price Hint')} />
            </PlanSummary>
            <PlanDescription>
              {t('licenses', {count: switchingToAnnual ? licenses : licenses - currentPlan.licenses})}
              &nbsp;×&nbsp;
              {t('price', {price: selectedTier.price})}
              &nbsp;×&nbsp;{ratioText}
            </PlanDescription>
          </PlanText>
          <PlanPrice>{t('price_precise', {price: proratedPrice})}</PlanPrice>
        </PlanCard>
      )}

      <PlanCard total>
        <PlanText>
          <PlanSummary>{t('Due Today')}</PlanSummary>
          {!currentPlan.tier.isTrial && !switchingToAnnual && (
            <PlanDescription>
              {showAdjustedPrice ? t('Adjusted Total') : ''}
              {showAdjustedPrice && showProratedPrice && ' + '}
              {showProratedPrice ? t('Prorated Price') : ''}
            </PlanDescription>
          )}
        </PlanText>
        <PlanPrice>{t('price_precise', {price: dueTodayPrice})}</PlanPrice>
      </PlanCard>
    </>
  );
};

export default SummaryItem;
