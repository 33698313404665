import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {maxWidth} from '../../../../style/media';

const CardCtaContainer = styled.div`
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
  
  & > .ant-btn {
    margin-top: 1rem;
  }
  
  ${maxWidth.tablet`
    font-size: 18px;
  `};
`;

const Children = styled.div`
  margin-top: 1rem;
`;

interface Props {
  buttonCaption: string,
  children?: React.ReactNode,
  href?: string,
  onClick?: () => void,
  text?: string,
}

const CardCta: React.FC<Props> = ({buttonCaption, children, href, onClick, text}) => (
  <CardCtaContainer>
    {text}
    <Button
      href={href}
      onClick={onClick}
      type="primary"
    >
      {buttonCaption}
    </Button>
    {children && <Children>{children}</Children>}
  </CardCtaContainer>
);

export default CardCta;
