import get from 'lodash/get';
import helperService from './helper-service';
import GeocodingNetworkService from '../network-services/geocoding-network-service';
import isDefinedAndNotNull from '../react/util/isDefinedAndNotNull';

// eslint-disable-next-line import/prefer-default-export
export const getUserLocationAddress = async () => {
  navigator.permissions.query({name: 'geolocation'})
    .then((result) => {
      if (result.state === 'denied') {
        throw new Error('Please ensure location permissions are enabled and try again.');
      }
    })
    .catch((error) => {
      helperService.logError(error);
    });

  const latlng = {
    lat: get(window.mmcUtils.MAN, 'userPosition.lat'),
    lng: get(window.mmcUtils.MAN, 'userPosition.lng'),
  };

  const response = await GeocodingNetworkService.reverseGeocodeAddress(latlng.lat, latlng.lng);
  if (!response.address) {
    throw new Error('Failed to find your address');
  }

  const geocodedArray = response.address;
  const {address, city, country, postalCode} = geocodedArray;
  return [address, city, country, postalCode].filter(isDefinedAndNotNull).join(' ');
};
