import User from './User';
import Dated from './base/Dated';
import Role from './Role';
import Organization from './Organization';
import Setting from './Setting';

export const isOwner = ({role}: Me) => role.key === 'OWNER';
export const isManager = ({role}: Me) => role.key === 'MANAGER';
export const isMember = ({role}: Me) => role.key === 'MEMBER';

export default interface Me extends User, Dated<string> {
  phone: string,
  lastActivity: string,
  role: Role,
  organization: Organization,
  teams: [],
  accessRights: [],
  settings: Setting[],
  emailVerified: boolean,
};
