import React, {ReactNode, ReactNodeArray} from 'react';
import BaseCard from '../BaseCard';
import CardContainer from './CardContainer';
import CardHeader from './CardHeader';
import CardBody from './CardBody';
import CardTitle, {CardTitleProps} from './CardTitle';
import CardActions from './CardActions';

interface Props extends CardTitleProps {
  actions?: ReactNode | ReactNodeArray,
  centerAlignContent?: boolean,
  children: ReactNode,
  className?: string,
  title: string,
  titleIcon?: ReactNode,
  titleIconClassName?: string,
  span?: number,
}

const DashCard: React.FC<Props> = (props) => {
  const {actions, centerAlignContent = false, children, className, title, titleIcon, titleIconClassName, span} = props;
  return (
    <BaseCard className={className} span={span}>
      <CardContainer>
        <CardHeader>
          <CardTitle
            title={title}
            titleIcon={titleIcon}
            titleIconClassName={titleIconClassName}
          />
          {actions && (
            <CardActions>
              {actions}
            </CardActions>
          )}
        </CardHeader>
        <CardBody centerAlignContent={centerAlignContent}>
          {children}
        </CardBody>
      </CardContainer>
    </BaseCard>
  );
};

export default DashCard;
