import find from 'lodash/find';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import dealsNetworkService from '../network-services/deals-network-service';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import mapActions from '../store/store-helpers';

export default function FunnelService(
  $route, DefaultDataService, FetchCRMDataService,
) {
  // main utility functions
  const {MAN} = window.mmcUtils;
  const MODEL = window.DataModel;

  // service to return
  const service = {};
  mapActions(service, ['modals']);

  // set the funnel chart
  service.setFunnelView = async funnelId => {
    const filters = {};
    if (MODEL.currentLeadsView) {
      filters.viewAs = MODEL.currentLeadsView;
    }
    const funnelData = await dealsNetworkService.fetchFunnelSummary(funnelId, filters);
    MODEL.FunnelService.funnelData = funnelData.data;
    service.showFunnelChart(funnelData.data, funnelId);
  };

  // set the list view for the funnel view
  service.fetchDeals = async function (map, filters, page, column, ascending, stages) {
    // set default current values if not sent
    page = page || MODEL.cachedState.deals.page;
    column = column || MODEL.cachedState.deals.column;
    ascending = ascending || MODEL.cachedState.deals.ascending;
    MODEL.selectedRecords = {};
    MODEL.selectedEntities = [];
    MODEL.allRecordsSelected = false;
    window.refreshDom({selectedRecords: MODEL.selectedRecords});

    filters.funnelId = MODEL.cachedState.deals.funnel;

    if (MODEL.cachedState.deals.dealStage) {
      filters.dealStageId = MODEL.cachedState.deals.dealStage;
    } else {
      delete filters.dealStageId;
    }

    const {data, total} = await dealsNetworkService.fetchAllDeals(map, filters, page, column, ascending);
    MODEL.dealsCount = total;

    if (!total && !MODEL.currentLeadsView && !MODEL.FilterService.filtersSelected.length) {
      service.modalsActions.showModal('noDealsModal');
    } else {
      service.modalsActions.resetVisibility('noDealsModal');
    }

    if (stages) {
      const visibleStageIds = new Set(stages.filter(({hidden}) => !hidden).map((stage) => stage.id));

      MODEL.deals = data.filter(({stage}) => stage && visibleStageIds.has(stage.id));
    } else {
      MODEL.deals = data;
    }
  };

  service.refreshDealsTotalCount = async () => {
    const dealsTotalCount = await dealsNetworkService.getDealsCount();
    window.refreshDom({dealsTotalCount});
  };

  service.setViewHeaders = () => {
    window.refreshDom({deals: MODEL.deals});
    const title = 'Deals';
    const count = MODEL.dealsCount;
    const btn = 'Add Deal';
    const showFilter = true;
    const showAdd = false;
    FetchCRMDataService.setCurrentPageVariable(title, count, btn, showFilter, showAdd);
    window.refreshDom({loader: false}, 'show');
  };


  // show the leads heat map
  service.leadsHeatMap = function () {
    MODEL.show.loader = true;
    $('#mapLegend').show();
    $('#stages').hide();
    MODEL.FunnelService.mapName = 'heat';
    $('#heatMap').removeClass('highlightedBtn').addClass('highlightedBtn');
    $('#circleMap').removeClass('highlightedBtn');
    $('#pinMap').removeClass('highlightedBtn');

    service.resetDealsMapPage();
    // get heatmap points
    const allCustomerCoords = MODEL.deals.map(item => ({
      location: new google.maps.LatLng(item.account.geoPoint.coordinates[1], item.account.geoPoint.coordinates[0]),
      weight: item.amount || 0,
    }));

    // apply new heatmap
    if (MODEL.FunnelService.heatmap) {
      MODEL.FunnelService.heatmap.setMap(null);
    }

    MODEL.FunnelService.heatmap = new google.maps.visualization.HeatmapLayer({
      data: allCustomerCoords,
      map: MODEL.FunnelService.leadsMap,
      radius: 20,
    });

    // hide spinner
    MODEL.show.loader = false;
  };

  // if filter is active, run all applied filters
  service.filterDealsData = (data) => {
    const customFields = safeLocalStorage.currentUser.dealsCustomFields;

    data = window.processCustomFilter(data, customFields);
    data = window.processGroupsFilter(data);

    if ($route.current.id === 'dealsMapPage') {
      data = window.processRadiusFilter(data);
    }

    return data;
  };

  // show funnel chart
  service.showFunnelChart = async (funnelData, funnelId) => {
    const selectedTab = MODEL.FunnelService.currentStage;
    const dataSections = [];
    const funnelCounts = MODEL.FunnelService.funnelData.map(({totalDeals}) => totalDeals)
      .reduceRight((res, total) => [total + res[0]].concat(res), [0]).slice(0, -1);

    let
      totalDealAmount = 0;
    let adjustedDealAmount = 0;
    let totalDealCount = 0;

    const stages = await dealsNetworkService.fetchStagesForFunnel(funnelId);
    funnelData.forEach((funnelDatum) => {
      const matchedStage = find(stages.data, {id: funnelDatum.stage.id});
      funnelDatum.stage.hidden = matchedStage ? matchedStage.hidden : undefined;
    });

    funnelData = funnelData.filter((funnelDatum) => !funnelDatum.stage.hidden);

    funnelData.forEach((stages, index) => {
      const stageName = stages.stage.name;
      let stageColor = MODEL.colorsToHex[stages.stage.color];

      stageColor = (selectedTab && selectedTab !== stages.stage.id)
        ? `${stageColor}5e` : stageColor;

      const funnelCount = funnelCounts[index];
      const dealConversion = stages.totalValue * stages.stage.weight;
      const dealInfo = {
        stageName,
        dealAmount: stages.totalValue,
        dealConversion,
        stageCount: stages.totalDeals,
        funnelCount,
        stageWeight: stages.stage.weight,
      };

      if (!selectedTab || selectedTab === stages.stage.id) {
        totalDealAmount += stages.totalValue;
        adjustedDealAmount += dealConversion;
        totalDealCount += stages.totalDeals;
      }

      const dealText = service.getFunnelCategoryText(dealInfo);
      dataSections.push({
        category: dealText,
        value: funnelCount,
        value2: -(funnelCount),
        color: stageColor,
        alpha: 1.0,
      });
    });

    // blank sections needs to be pushed on last for some reason
    dataSections.push({
      category: '',
      value: 0,
      value2: 0,
      color: 'green',
      alpha: 0.0,
    });
    service.initFunnelChart(dataSections);
    window.refreshDom({totalDealAmount, adjustedDealAmount, totalDealCount}, 'FunnelService');
  };

  // init chart
  service.initFunnelChart = (dataSections) => {
    if (MODEL.FunnelService.funnelChart) {
      MODEL.FunnelService.funnelChart.validateData();
    }
    // var chart
    MODEL.FunnelService.funnelChart = AmCharts.makeChart('funnel-chart', {
      type: 'serial',
      theme: 'none',
      fontSize: 14,
      fontFamily: 'Helvetica',
      dataProvider: dataSections,
      valueAxes: [{
        axisAlpha: 0,
        labelsEnabled: false,
        gridAlpha: 0,
      }],
      graphs: [{
        id: 'fromGraph',
        lineAlpha: 0,
        showBalloon: false,
        valueField: 'value2',
        fillAlphas: 0,
      }, {
        fillAlphas: 1,
        fillToGraph: 'fromGraph',
        lineAlpha: 0,
        lineColorField: 'color',
        fillColorsField: 'color',
        showBalloon: false,
        valueField: 'value',
      }],
      categoryField: 'category',
      categoryAxis: {
        startOnAxis: true,
        axisAlpha: 0.1,
        gridPosition: 'left',
        gridAlpha: 0.1,
        tickLength: 20,
        tickPosition: 'start',
        showLastLabel: false,
      },
    });
  };

  service.getFunnelCategoryText = ({
    stageName, dealAmount, stageCount, stageWeight,
  }) => {
    let formatter;

    if (Intl) {
      formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
    } else {
      formatter = {
        format(value) {
          return value;
        },
      };
    }

    return `${stageName}
                # Deals: ${stageCount}
                Total value: ${formatter.format(dealAmount)}
                Weight: ${stageWeight || '0'}
                Adjusted Value: ${formatter.format(stageWeight ? dealAmount * stageWeight : '0')}`;
  };

  // show the leads page heat map
  service.showLeadsMap = function (firstTime = false) {
    MODEL.FunnelService.mapName = 'circle';

    // build view based on all the filters in the view
    service.resetDealsMapPage();

    $('#heatMap').removeClass('highlightedBtn');
    $('#circleMap').removeClass('highlightedBtn').addClass('highlightedBtn');
    $('#pinMap').removeClass('highlightedBtn');

    MODEL.FunnelService.showingLeadsMap = true;

    // map settings
    const minMag = 1.0;
    let maxMag = 5.0;
    MODEL.deals.forEach((item) => {
      if (item.amount > maxMag) {
        maxMag = item.amount;
      }
    });

    MODEL.FunnelService.leadCirclesMax = maxMag;
    const bounds = new google.maps.LatLngBounds();

    // show circles on map
    MODEL.deals.forEach((item) => {
      let min;
      if (item.amount) {
        min = Math.min(item.amount, maxMag);
      } else {
        min = 1.1;
      }

      const fraction = (min - minMag) / (maxMag - minMag);
      const latlng = new google.maps.LatLng(item.account.geoPoint.coordinates[1], item.account.geoPoint.coordinates[0]);
      bounds.extend(latlng);
      // figure out color
      // dealColorMapping
      const color = MODEL.colorsToHex[item.stage.color];

      const newCircle = new google.maps.Circle({
        path: google.maps.SymbolPath.CIRCLE,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 0,
        fillColor: color,
        fillOpacity: 1.2 - Math.max(fraction, 0.1),
        map: MODEL.FunnelService.leadsMap,
        center: latlng,
        radius: Math.max(fraction, 0.1) * 150000,
        zIndex: 0,
      });
      MODEL.FunnelService.allCircles.push(newCircle);
    });

    if (firstTime) {
      service.setMapBoundsForTheView(bounds);
      if (MODEL.FunnelService.leadsMap.getZoom() >= 9) {
        MODEL.FunnelService.leadsMap.setZoom(7);
      }
    }
    // hide spinner
    MODEL.show.loader = false;
  };

  // check current lead stage is hidden or not
  service.checkDealStage = (stage) => {
    const actualStageNames = ['leadName', 'pitchedName', 'qualifiedName', 'lostName', 'wonName', 'stage6', 'stage7', 'stage8'];
    const index = MODEL.allLeadStagesInOrder.indexOf(stage);
    const hidden = safeLocalStorage.currentUser.hiddenLeadStages.indexOf(actualStageNames[index]);
    //
    const lostStageFlag = $route.current.id === 'dealsPage' && stage === MODEL.lostName;
    // stage is hidden return false
    return hidden < 0 || lostStageFlag;
  };

  // edit if lead shows/hides in funnel
  service.editLeadCheck = async function (stage) {
    const funnelId = MODEL.FunnelService.modalFunnelId;

    stage.hidden = !stage.hidden;
    const result = await service.updateStageForPayload(funnelId, stage);
    // revert if not a success
    if (!result) {
      stage.hidden = !stage.hidden;
    }

    return result;
  };

  // new query
  service.searchFromLeads = function () {
    MODEL.show.loader = true;
    MODEL.allLeads = MODEL.dealsRaw;

    // search has query param
    let searchString;
    if (window.getParameterByName('q')) {
      searchString = window.getParameterByName('q');
    } else {
      searchString = $('#search-form').val();
    }

    // filter results with function
    MODEL.allLeads = MODEL.allLeads.filter((obj) => {
      let normalName = '';
      let normalCompany = '';
      let normalAdd = '';
      let normalCity = '';
      let normalState = '';
      let normalZip = '';
      let normalCountry = '';
      let normalNotes = '';
      if (obj.name !== undefined) {
        normalName = obj.name.toLowerCase();
      }
      if (obj.company !== undefined) {
        normalCompany = obj.company.toLowerCase();
      }
      if (obj.address !== undefined) {
        normalAdd = obj.address.toLowerCase();
      }
      if (obj.city !== undefined) {
        normalCity = obj.city.toLowerCase();
      }
      if (obj.state !== undefined) {
        normalState = obj.state.toLowerCase();
      }
      if (obj.zip !== undefined) {
        normalZip = obj.zip.toLowerCase();
      }
      if (obj.country !== undefined) {
        normalCountry = obj.country.toLowerCase();
      }
      if (obj.notes !== undefined) {
        normalNotes = obj.notes.toLowerCase();
      }
      const normalSearchName = searchString.toLowerCase();
      return (normalName.indexOf(normalSearchName) > -1 || normalAdd.indexOf(normalSearchName) > -1 || normalCity.indexOf(normalSearchName) > -1 ||
            normalState.indexOf(normalSearchName) > -1 || normalZip.indexOf(normalSearchName) > -1 || normalCountry.indexOf(normalSearchName) > -1 || normalCompany.indexOf(normalSearchName) > -1 ||
            normalNotes.indexOf(normalSearchName) > -1 || obj.objectId === searchString);
    });

    window.currentCustomerSegment(0);
    MODEL.show.loader = false;
  };

  // popup html for pin map
  service.getPinPopupHTML = (deal) => {
    MODEL.currentCrmObjectId = deal.id;
    let popupHTML = "<div class='popupContent'><p class='popupHeader'>";
    popupHTML += `${deal.name}</p>`;
    popupHTML += `<p class='popupTitle'>Stage:</p>&nbsp;&nbsp; <p class='popupValue'> ${deal.stage.name}</p><br />`;

    if (deal.score) {
      popupHTML += `<p class='popupTitle'>Lead Score:</p>&nbsp;&nbsp; <p class='popupValue'>${deal.score}</p><br />`;
    }

    if (isNumber(deal.amount)) {
      popupHTML += `<p class='popupTitle'>Deal Amount: </p>&nbsp;&nbsp; <p class='popupValue'>$ ${deal.amount}</p>`;
    }
    popupHTML += `<button 
         ng-click="previewEntity($event, 'deals', ${deal.id})" 
         class="btn btn-primary btn-fill popUpButton"
         style="width:100%!important;height:32px;line-height: 2px;margin: -16px 0 12px 0;"
       >
        Preview Deal
      </button>`;
    popupHTML += '</div>';
    return popupHTML;
  };


  // show manage stages
  service.showManageStages = () => {
    const fixHelperModified = function (e, tr) {
      const $originals = tr.children();
      const $helper = tr.clone();
      $helper.children().each(function (index) {
        $(this).width($originals.eq(index).width());
      });
      return $helper;
    };

    // Make desired tables sortable
    $('#stage-table tbody').sortable({
      helper: fixHelperModified,
      stop() {
        return service.updateCurrentStageOrder();
      },
    }).disableSelection();
  };

  // update current stage order
  service.updateCurrentStageOrder = async () => {
    // get updated stageId:displayOrder map
    const currentStagesMap = service
      .getCurrentDealStages()
      .reduce((result, item) => Object.assign(result, {[item.id]: item.displayOrder}), {});

    const funnelId = MODEL.FunnelService.modalFunnelId;
    const updatedStages = MODEL.FunnelService.modalStages
      .filter(({type}) => type !== 'lost' && type !== 'won') // you can't reorder lost or won stage
      .filter(({id, displayOrder}) => currentStagesMap[id] !== displayOrder) // take those whose displayOrder changed
      .map(stage => ({

        ...stage,
        weight: parseFloat(stage.weight),
        displayOrder: currentStagesMap[stage.id],
      })); // update their displayOrder and fix weight;

    await Promise.all(
      updatedStages.map(stage => dealsNetworkService.updateDealStage(funnelId, stage.id, stage)),
    );

    window.refreshDom({modalStages: (await dealsNetworkService.fetchStagesForFunnel(funnelId)).data}, 'FunnelService');
  };

  // add stage according to type
  service.addStage = () => {
    const type = MODEL.FunnelService.editDealType;

    // initialize array if type is not defined
    if (!MODEL.FunnelService.selectedFunnels[type].stages) {
      MODEL.FunnelService.selectedFunnels[type].stages = [];
    }

    // condition for adding empty stage
    const numStages = MODEL.FunnelService.selectedFunnels[type].stages.length;
    const currentStages = service.getCurrentDealStages();
    const haveEmptyString = currentStages.includes('');
    const canAddStage = numStages <= 8 && !haveEmptyString;

    // add empty stage
    if (canAddStage) {
      MODEL.FunnelService.selectedFunnels[type].stages.push('');
      service.addUpdateDealStatus();
    }

    // scroll to the newly added stage
    $('.stageTabView').animate({
      scrollTop: $('#scrollDealStage').offset().top,
    }, 400);
  };

  service.getEditFunnelNames = () => $('[name="dealType"]').map(function () {
    return this.value;
  }).get();

  service.initNewFunnel = (name, funnelOwnedByTeamId) => {
    const stagesToColors = {
      Lead: 'firstStage',
      Pitched: 'secondStage',
      Qualified: 'thirdStage',
      'Closed Won': 'fourthStage',
      'Closed Lost': 'fifthStage',
    };

    // determine the subTeam funnel is going to be owned by
    const teamObj = service.determineSubTeamForFunnel(funnelOwnedByTeamId);

    return {
      name,
      stages: ['Lead', 'Pitched', 'Qualified', 'Closed Won', 'Closed Lost'],
      stagesToColors,
      stagesToWeights: {},
      hiddenStages: [],
      team: teamObj,
      username: safeLocalStorage.currentUser.username,
    };
  };

  // get current deal stages
  service.getCurrentDealStages = () => $('.dealStageNames').map((index, td) => ({id: $(td).data('id'), displayOrder: index})).get();

  // get deal color
  service.getDealColor = (leadType) => {
    const typeId = MODEL.FunnelService.currentDealTypeId;
    const index = MODEL.FunnelService.selectedFunnels[typeId].stagesToColors[leadType];

    return MODEL.dealColorMapping[index];
  };

  // get lead Color
  service.getDealColorLabel = (leadType) => {
    const typeId = MODEL.FunnelService.currentDealTypeId;
    return MODEL.FunnelService.selectedFunnels[typeId].stagesToColors[leadType];
  };

  service.setMapView = () => {
    service.showPreservedLeadsView();
  };

  service.parseDeals = deals => deals
    .filter(deal => (deal.account && deal.account.geoPoint) || (deal.contact && deal.contact.geoPoint))
    .map(deal => ({...deal, geoPoint: deal.account ? deal.account.geoPoint : deal.contact.geoPoint}));

  // show the last shown view when performed other operations
  service.showPreservedLeadsView = () => {
    switch (MODEL.FunnelService.mapName) {
      case 'circle':
        service.showLeadsMap(true);
        break;
      case 'heat':
        service.leadsHeatMap(true);
        break;
        // default to pin map ->always
      default:
        window.showPinMap(true);
        break;
    }
  };

  // init map if not done already
  service.initGoogleMap = () => {
    // new map
    const mapOptions = {
      scrollwheel: MAN.savedOptions.scrollwheel || false,
    };

    window.mmcUtils.tk('dmodfdL2XA');
    MODEL.FunnelService.leadsMap = new google.maps.Map(document.getElementById('leadsHeatMap'), mapOptions);
    MAN.addAllEventListeners(MODEL.FunnelService.leadsMap);

    // set map center
    if (MAN.hasSavedZoomOrCenter()) {
      MAN.goToSavedZoomAndCenter(MODEL.FunnelService.leadsMap);
    } else {
      const latlng = new google.maps.LatLng(MAN.userPosition || MAN.lastResortPosition);
      MODEL.FunnelService.leadsMap.setCenter(latlng);
    }
  };

  // check current lead stage is hidden or not
  service.applyHiddenDealCheck = (deals) => {
    const typeId = MODEL.FunnelService.currentDealTypeId;
    const funnel = MODEL.FunnelService.selectedFunnels[typeId];
    // stage is hidden return false
    return deals.filter(deal => !funnel.hiddenStages.includes(deal.stage));
  };

  // apply lead tab
  service.applyTabView = (deals) => {
    if ($route.current.id === 'dealsPage') return deals;

    const tab = MODEL.FunnelService.currentLeadsTab;
    return deals.filter(lead => lead.stage === tab || !tab);
  };


  service.filterFunnelOptions = (userView = '') => {
    // as array size is small -> below op will not be very compute intensive
    const userFunnels = MODEL.FunnelService.funnelArray.filter(funnel => funnel.username === userView || !userView);
    MODEL.FunnelService.selectedFunnels = keyBy(userFunnels, 'objectId');
    service.initFunnelNames();

    if (!MODEL.FunnelService.funnelObjectIds.includes(MODEL.FunnelService.currentDealTypeId)) {
      service.initFunnelModelVar();
    }
  };

  service.resetDealsMapPage = () => {
    // clear pin map
    MODEL.FunnelService.allPins.forEach(pin => {
      pin.setMap(null);
    });

    // clear heatmap
    if (MODEL.FunnelService.heatmap) {
      MODEL.FunnelService.heatmap.setMap(null);
    }

    // clear starting pin
    if (MODEL.startingLocationPin) {
      MODEL.startingLocationPin.setMap(null);
      MODEL.teamMemberMarkers.forEach((pin) => {
        pin.setMap(null);
      });
    }

    // clear circles from map
    MODEL.FunnelService.allCircles.forEach((pin) => {
      pin.setMap(null);
    });
  };

  service.setMapBoundsForTheView = (bounds) => {
    MODEL.MappingService.localCurrentLatLng = MAN.userPosition || MAN.lastResortPosition;
    // no mapped leads
    if (MODEL.deals.length < 1) {
      if (MODEL.MappingService.localCurrentLatLng) bounds.extend(new google.maps.LatLng(MODEL.MappingService.localCurrentLatLng));
      MODEL.startingLocationPin = new google.maps.Marker({
        position: MODEL.MappingService.localCurrentLatLng,
        title: 'new marker - no mapped leads',
        draggable: false,
        map: MODEL.FunnelService.leadsMap,
      });
      $('#noMappedLeadsAlert').show();
    }

    if (MODEL.localCurrentMarker) {
      MODEL.localCurrentMarker.setMap(null);
    }


    MODEL.localCurrentMarker = new google.maps.Marker({
      position: MODEL.MappingService.localCurrentLatLng,
      map: MODEL.FunnelService.leadsMap,
    });
    MAN.allMapContent.push(MODEL.localCurrentMarker);

    // fit map to bounds
    MODEL.FunnelService.leadsMap.fitBounds(bounds);
  };

  // get funnel data
  service.getFunnelData = () => dealsNetworkService.getFunnels()
    .then((data) => {
      MODEL.FunnelService.funnelArray = data.data.results;
      MODEL.FunnelService.selectedFunnels = keyBy(data.data.results, 'objectId');
      service.initFunnelModelVar();
    });

  // set funnel names
  service.initFunnelNames = () => {
    MODEL.FunnelService.funnelNames = Object.values(MODEL.FunnelService.selectedFunnels).map(funnel => funnel.name);
    MODEL.FunnelService.funnelObjectIds = Object.keys(MODEL.FunnelService.selectedFunnels);
    window.refreshDom({'FunnelService.funnelObjectIds': MODEL.FunnelService.funnelObjectIds});
  };

  // change funnel view
  service.changeFunnelView = (funnelName, deals) => deals.filter(deal => deal.type === funnelName);

  // change user view
  service.changeUserView = (userView, deals) => deals.filter(deal => deal.username === userView || !userView);

  // return only if latitude exists
  service.applyLatCheck = (deals) => {
    if ($route.current.id === 'dealsPage') return deals;

    return deals.filter(deal => deal.latitude && deal.longitude);
  };

  // change key of object
  service.renameObjectKeys = (oldProp, newProp, data) => {
    const {[oldProp]: old, ...others} = data;

    return {
      [newProp]: old,
      ...others,
    };
  };

  service.addUpdateDealStatus = () => {
    const type = MODEL.FunnelService.editDealType;
    MODEL.FunnelService.selectedFunnels[type].status = 'new';
  };

  // create a new funnel
  service.createNewFunnel = async function (funnelName) {
    if (!funnelName) {
      return swal('Uh-oh!', 'Funnel name cannot be empty.', 'error');
    }

    const payload = {name: funnelName};
    const results = await dealsNetworkService.createFunnel(payload);
    // server returned something
    if (results) {
      if (results.id) {
        swal('Hooray!', 'New funnel successfully added.', 'success');
        return results;
      }

      if (results.code) {
        DefaultDataService.getErrorSwal(results);

        if (get(results, 'validationErrors[0].code') === 'v0079') {
          throw results;
        }
      }
    } else {
      // some server error

      swal('Uh-oh!', 'There was some issue in creating deal funnel.', 'error');
    }
  };

  // create new stage
  service.createNewStage = async function (currentStagesLength) {
    const funnelId = MODEL.FunnelService.modalFunnelId;
    const stageName = MODEL.FunnelService.addEditStageName;
    const stageWeight = MODEL.FunnelService.addEditStageProb;
    const {stageColor} = MODEL.FunnelService;

    if (!stageName) {
      return swal('Uh-oh!', "Stage name can't be empty.", 'error');
    }

    if (!stageColor) {
      return swal('Uh-oh!', "Stage color can't be empty.", 'error');
    }

    const payload = {
      name: stageName,
      displayOrder: currentStagesLength + 1,
      color: stageColor,
      weight: stageWeight / 100,
      hidden: false,
    };

    const results = await dealsNetworkService.createDealStage(funnelId, payload);
    if (results) {
      if (results.id) {
        swal('Hooray!', 'Deal stage successfully created', 'success');
        return results;
      }

      if (results.code) {
        DefaultDataService.getErrorSwal(results);
      }
    } else {
      // some server error

      swal('Uh-oh!', 'There was some issue in creating deal stage.', 'error');
    }
  };

  // update deal stage
  service.updateStage = async function (stage) {
    const funnelId = MODEL.FunnelService.modalFunnelId;
    const stageName = MODEL.FunnelService.addEditStageName;

    stage.name = stageName;
    stage.weight = MODEL.FunnelService.addEditStageProb / 100;
    stage.color = MODEL.FunnelService.stageColor;

    return service.updateStageForPayload(funnelId, stage);
  };

  // network call and swal for the response
  service.updateStageForPayload = async function (funnelId, stage) {
    stage.weight = parseFloat(stage.weight);
    const results = await dealsNetworkService.updateDealStage(funnelId, stage.id, stage);

    if (results) {
      if (results.id) {
        swal('Hooray!', 'Deal stage updated successfully', 'success');
        return results;
      }

      if (results.code) {
        DefaultDataService.getErrorSwal(results);
      }
    } else {
      swal('Uh-oh!', 'There was some issue in updating deal stage.', 'error');
    }
  };

  // returns a subTeam object used for updating/creating the subTeam of a funnel
  service.determineSubTeamForFunnel = (subTeamId) => {
    const subTeam = MODEL.TeamsService.subTeams.find(obj => obj.id === subTeamId);
    return subTeam ? {id: subTeam.id} : null;
  };

  // update existing funnel
  service.updateFunnel = async function (funnel) {
    // must have a funnel name
    if (!MODEL.FunnelService.addEditFunnelName) {
      return swal('Uh-oh!', "Funnel name can't be empty.", 'error');
    }

    const newTeamId = funnel.team ? funnel.team.id : null;

    // must have changed either name or team before saving
    if (MODEL.FunnelService.addEditFunnelName === funnel.name
      && MODEL.FunnelService.addEditFunnelOwnedByTeamId === newTeamId) {
      return swal('Uh-oh!', 'Try changing the funnel name or team before saving.', 'error');
    }

    funnel.name = MODEL.FunnelService.addEditFunnelName;

    if (MODEL.FunnelService.addEditFunnelOwnedByTeamId) {
      funnel.team = {id: MODEL.FunnelService.addEditFunnelOwnedByTeamId};
    } else {
      delete funnel.team;
    }

    const results = await dealsNetworkService.updateFunnel(funnel.id, funnel);
    if (results) {
      if (results.id) {
        swal('Hooray!', 'Funnel updated successfully.', 'success');
        return results;
      }

      if (results.code) {
        DefaultDataService.getErrorSwal(results);
      }
    } else {
      // some server error
      swal('Uh-oh!', 'There was some issue in updating funnel details.', 'error');
    }
  };

  // update respective column in allLeads and deals raw
  service.updateSingleColForDealsLocally = (colName, oldFieldName, newFieldName) => {
    MODEL.allLeads.forEach((lead, index, arr) => {
      if (lead[colName] === oldFieldName) {
        arr[index][colName] = newFieldName;
      }
    });
    // update respective type in dealsRaw
    MODEL.dealsRaw.forEach((lead, index, arr) => {
      if (lead[colName] === oldFieldName) {
        arr[index][colName] = newFieldName;
      }
    });
  };

  // update stage related objects
  service.updateAllStageObjects = (funnel, oldStage, newStage) => {
    const stageIndex = funnel.stages.indexOf(oldStage);
    funnel.stages[stageIndex] = newStage;

    // stagesToColors
    if (oldStage in funnel.stagesToColors) {
      funnel.stagesToColors = service.renameObjectKeys(oldStage, newStage, funnel.stagesToColors);
    }

    // stagesToWeights
    if (oldStage in funnel.stagesToWeights) {
      funnel.stagesToWeights = service.renameObjectKeys(oldStage, newStage, funnel.stagesToWeights);
    }

    return {
      stages: funnel.stages,
      stagesToColors: funnel.stagesToColors,
      stagesToWeights: funnel.stagesToWeights,
    };
  };

  // add to selected funnels
  service.addToSelectedFunnels = (newType) => {
    MODEL.FunnelService.selectedFunnels[newType.objectId] = newType;
  };

  // check type name if it exists already or not
  service.checkFunnelName = (funnelName) => Object.values(MODEL.FunnelService.selectedFunnels).some(funnel => funnel.name === funnelName);

  // check stage name if it exists already or not
  service.checkStageName = (stageName, currentStages) => currentStages.some(stage => stage.name.includes(stageName));

  // get selected stage or type count
  service.getTypeCount = (type, typeName) => MODEL.dealsRaw.reduce((sum, deal) => {
    if (deal[type] === typeName) sum += 1;
    return sum;
  }, 0);

  // open delete modal for funnel and stage
  service.openDelFunnelStageModal = (type, funnelId, typeName) => {
    MODEL.FunnelService.delStageFunnel = type;
    MODEL.FunnelService.delStageFunnelId = funnelId;
    MODEL.FunnelService.delStageFunnelName = typeName;

    // init delete funnel and stage dropdowns
    service.initDeleteStage(funnelId);

    // don't allow to delete stage if stage count is 2
    if (MODEL.FunnelService.selectedFunnels[funnelId].stages.length === 2) {
      return swal('Uh-oh!', "Funnel can't have less than 2 deal stages.", 'error');
    }

    if (Object.keys(MODEL.FunnelService.selectedFunnels).length === 1 && type === 'Funnel') {
      return swal('Uh-Oh', 'There should be at least one funnel.', 'error');
    }

    MODEL.FunnelService.delStageTypeCount = type === 'Funnel'
      ? service.getTypeCount('type', typeName)
      : service.getTypeCount('stage', typeName);

    MODEL.FunnelService.delFunnelViewIds = MODEL.FunnelService.funnelObjectIds.filter(id => type !== 'Funnel' || (type === 'Funnel' && id !== funnelId));
    MODEL.FunnelService.delFunnelId = MODEL.FunnelService.delFunnelViewIds[0];

    MODEL.FunnelService.delStage = MODEL.FunnelService.selectedFunnels[MODEL.FunnelService.delFunnelId].stages.find(stage => type !== 'Stage' || (type === 'Stage' && stage !== typeName));

    window.refreshDom({'FunnelService.delStage': MODEL.FunnelService.delStage});
    window.refreshDom({'FunnelService.delFunnelId': MODEL.FunnelService.delFunnelId});
    $('#edit-stages').hide();
    $('#delete-stage-funnel-modal').show();
  };

  // move deals to selected funnel and stage
  service.moveFunnelAndStage = () => {
    const funnelId = MODEL.FunnelService.delStageFunnelId;
    const newFunnelId = MODEL.FunnelService.delFunnelId;
    const newFunnelName = MODEL.FunnelService.selectedFunnels[newFunnelId].name;
    const newStageName = MODEL.FunnelService.delStage;
    const funnelOrStage = MODEL.FunnelService.delStageFunnel;
    const updateFields = {type: newFunnelName, stage: newStageName};

    const getFields = funnelOrStage === 'Funnel'
      ? {type: MODEL.FunnelService.delStageFunnelName}
      : {stage: MODEL.FunnelService.delStageFunnelName};

    const data = funnelOrStage === 'Funnel'
      ? {delete: true}
      : service.getDeletedStageFields(funnelId, MODEL.FunnelService.delStageFunnelName);
    const updateDealsProm = dealsNetworkService.updateAllDeals(getFields, updateFields);
    const updateFunnelsProm = dealsNetworkService.updateSingleFunnel(funnelId, data);
    $('#delete-stage-funnel-modal').hide();
    return Promise.all([updateDealsProm, updateFunnelsProm])
      .then(() => service.getFunnelData())
      .then(() => swal('Hooray!', `Deal ${funnelOrStage} deleted successfully.
        All deals are moved to ${newFunnelName} Funnel and ${newStageName} stage.`, 'success'))
      .then(() => {
        const stageName = funnelOrStage === 'Funnel' ? 'type' : 'stage';
        service.updateDealsLocallyAfterDel(stageName);
        service.initFunnelAndMap(MODEL.FunnelService.currentLeadsTab);
      });
  };

  service.updateDealsLocallyAfterDel = (typeName) => {
    const newFunnelId = MODEL.FunnelService.delFunnelId;
    MODEL.allLeads.forEach((deal, index, arr) => {
      if (deal[typeName] === MODEL.FunnelService.delStageFunnelName) {
        arr[index].type = MODEL.FunnelService.selectedFunnels[newFunnelId].name;
        arr[index].stage = MODEL.FunnelService.delStage;
      }
    });

    MODEL.dealsRaw.forEach((deal, index, arr) => {
      if (deal[typeName] === MODEL.FunnelService.delStageFunnelName) {
        arr[index].type = MODEL.FunnelService.selectedFunnels[newFunnelId].name;
        arr[index].stage = MODEL.FunnelService.delStage;
      }
    });
  };

  // delete stage Fields
  service.getDeletedStageFields = (funnelId, stageName) => {
    const funnel = MODEL.FunnelService.selectedFunnels[funnelId];

    funnel.stages = funnel.stages.filter(stage => stage !== stageName);
    MODEL.FunnelService.selectedFunnels[funnelId].stages = funnel.stages;
    delete funnel.stagesToColors[stageName];
    delete funnel.stagesToWeights[stageName];
    funnel.hiddenStages = funnel.hiddenStages.filter(hs => hs !== stageName);
    MODEL.FunnelService.selectedFunnels[funnelId].hiddenStages = funnel.hiddenStages;

    return {
      stages: funnel.stages,
      stagesToColors: funnel.stagesToColors,
      stagesToWeights: funnel.stagesToWeights,
      hiddenStages: funnel.hiddenStages,
    };
  };

  service.initDeleteStage = (funnelId) => {
    const stageOrFunnel = MODEL.FunnelService.delStageFunnel;
    const stageName = MODEL.FunnelService.delStageFunnelName;

    MODEL.FunnelService.delStageOptions = MODEL.FunnelService.selectedFunnels[funnelId].stages.filter(stage => stageOrFunnel !== 'Stage' || (stageOrFunnel === 'Stage' && stageName !== stage));
    MODEL.FunnelService.delStage = MODEL.FunnelService.delStageOptions[0];
    window.refreshDom({'FunnelService.delStageOptions': MODEL.FunnelService.delStageOptions});
    window.refreshDom({'FunnelService.delStage': MODEL.FunnelService.delStage});
  };

  return service;
}

FunnelService.$inject = [
  '$route', 'DefaultDataService', 'FetchCRMDataService',
];
