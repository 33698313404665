import React, {useCallback, useEffect, useState} from 'react';
import {Button, InputNumber, Slider, Radio} from 'antd';
import {Trans, useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {EntityFilter, FilteredField} from '../Grid/ListViewGrid';
import Group from '../../type/Group';
import useBoolean from '../../hook/useBoolean';
import defaultNodeIdGetter from '../../util/defaultNodeIdGetter';
import SelectGroupsDialog from '../SelectEntityDialog/SelecGroupsDialog';
import filterComparator, {groupComparator, platformComparator} from '../../type/network/filterComparator';
import RadioGroup from '../RadioGroup/RadioGroup';
import TextField from '../Input/TextField';
import useInitializer from '../../hook/useInitializer';
import geocodingNetworkService from '../../../network-services/geocoding-network-service';
import isDefinedAndNotNull from '../../util/isDefinedAndNotNull';
import {doesUseMiles} from '../../../common/settings';

const {mmcUtils} = window;
const {MAN} = mmcUtils;

const Chip = styled.div`
  padding: 5px;
  display: inline-block;
  background-color: #ddd;
  border-radius: 3px;
  margin: 3px;
  font-size: 0.9em;
`;

const FilterContainer = styled.div`
  margin-right: 5px;
  padding: 10px;
  overflow-y: auto;
  height: 100%;
  background-color: #fff;
  border: 1px solid #E7EAF3;
  border-radius: 4px;
  font-weight: 400;
  & > h3 {
    margin-top: 10px;
    font-weight: 700;
  }
`;

interface ChipProps {
  name: string,
}

const GroupChip: React.FC<ChipProps> = ({name}) => (
  <Chip>
    {name}
  </Chip>
);

const GroupsContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

const RadiusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  & > .ant-input-number {
    width: 60px;
    margin: 0px 5px;
  }
  & > .ant-slider {
    flex: 3;
  }
`;

const RadiusSlider = styled(Slider)`
  width: 100%;
`;

const ButtonWithMargin = styled(Button)`
  margin: 10px 0;
`;

export interface FilterPaneProps{
  groups: Group[],
  filteredFields: FilteredField[],
  onFilterChanged: (filter: EntityFilter) => void,
}

const FilterPane: React.FC<FilterPaneProps> = ({onFilterChanged, groups, filteredFields}) => {
  const {t} = useTranslation(['translation', 'filter']);

  const [location, setLocation] = useState<string>('');
  const [radius, setRadius] = useState<number>(0);
  const [radiusFilterApplied, applyRadiusFilter, resetRadiusFilter] = useBoolean();
  const [selectGroupsVisible, showSelectGroups, hideSelectGroups] = useBoolean();
  const [groupFilterComparator, setGroupFilterComparator] = useState<groupComparator>(groupComparator.ANY);
  const [groupsFilterApplied, applyGroupsFilter, resetGroupsFilter] = useBoolean();
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);

  const initializer = useCallback(
    async () => {
      if (MAN.userPosition) {
        const response = await geocodingNetworkService.reverseGeocodeAddress(MAN.userPosition.lat, MAN.userPosition.lng);
        if (response.address && response.address.geocodedArray) {
          const geocodedArray = response.address;
          const {address, city, country, postalCode} = geocodedArray;
          setLocation([address, city, country, postalCode].filter(isDefinedAndNotNull).join(' '));
        }
      }
    },
    [],
  );
  useInitializer(initializer);

  useEffect(
    () => {
      const areaFilter: FilteredField | undefined = filteredFields.find(filteredField => filteredField.name === 'area');
      if (!areaFilter && radiusFilterApplied) {
        resetRadiusFilter();
        setRadius(0);
        setLocation('');
      } else if (areaFilter && !radiusFilterApplied) {
        applyRadiusFilter();
        setRadius(areaFilter.filter.radius);
        setLocation(areaFilter.filter.location);
      }
      const groupsFilter: FilteredField | undefined = filteredFields.find(filteredField => filteredField.name === 'groups');
      if (!groupsFilter && groupsFilterApplied) {
        setSelectedGroups([]);
      } else if (groupsFilter && !groupsFilterApplied) {
        let filterComparator: groupComparator | undefined;
        let selectedGroupIds: number[] = [];
        if (groupsFilter.filter[platformComparator.ANY]) {
          filterComparator = groupComparator.ANY;
          selectedGroupIds = groupsFilter.filter[platformComparator.ANY];
        } else if (groupsFilter.filter[platformComparator.ALL]) {
          filterComparator = groupComparator.ALL;
          selectedGroupIds = groupsFilter.filter[platformComparator.ALL];
        }
        if (filterComparator) {
          setGroupFilterComparator(filterComparator);
          setSelectedGroups(groups.filter((group: Group) => selectedGroupIds.includes(group.id)));
        }
      }
    },
    [
      filteredFields,
      radiusFilterApplied,
      resetRadiusFilter,
      applyRadiusFilter,
      setRadius,
      setLocation,
      groupsFilterApplied,
      resetGroupsFilter,
      setSelectedGroups,
      setGroupFilterComparator,
      groups,
    ],
  );

  const handleApplyRadius = useCallback(
    async () => {
      const apply = (point: number[], location: string = '') => {
        onFilterChanged({area: {radius: radius * (doesUseMiles() ? 1609.334 : 1000.0), uom: 'meter', point, location}});
        applyRadiusFilter();
      };
      let lat;
      let lng;
      if (location.trim().length) {
        const {lng, lat} = await mmcUtils.geoCodeAddress(location);
        apply([lng, lat], location);
      } else {
        const filterNearbyLatLng = MAN.userPosition || MAN.lastResortPosition;
        if (filterNearbyLatLng.coords) {
          lat = filterNearbyLatLng.coords.latitude;
          lng = filterNearbyLatLng.coords.longitude;
        } else {
          lat = filterNearbyLatLng.lat;
          lng = filterNearbyLatLng.lng;
        }
        apply([lng, lat]);
      }

    },
    [onFilterChanged, location, radius, applyRadiusFilter],
  );

  const handleApplyGroups = useCallback(
    async () => {
      onFilterChanged({groups: {[filterComparator[groupFilterComparator]]: selectedGroups.map(group => group.id)}});
      applyGroupsFilter();
    },
    [selectedGroups, onFilterChanged, location, radius, groupFilterComparator, applyGroupsFilter],
  );

  const handleSetRadius = useCallback(
    (value: number | string | undefined) => setRadius(value as number),
    [setRadius],
  );

  return (
    <FilterContainer>
      <h3>{t('filters')}:</h3>
      <Trans i18nKey="filterDescription" t={t}>
        To apply filters to columns, mouse over the desired column and click the.
        <i className="fa fa-filter" />
        . Click the column name to sort the list by the column. Applied filters will appear across the top of the list.
      </Trans>
      <h3>{t('radius')}:</h3>
      <h5>{t('radiusDescription')}:</h5>
      <TextField
        id="filter-pane__location"
        placeholder={t('location')}
        value={location}
        onChange={setLocation}
        suffix={<i className="fa fa-search" />}
      />
      <RadiusContainer>
        <RadiusSlider
          max={1000}
          value={radius}
          onChange={value => setRadius(value as number)}
        />
        <InputNumber
          value={radius}
          onChange={handleSetRadius}
        />
        <div>{doesUseMiles() ? ' miles' : 'km'}</div>
      </RadiusContainer>
      <ButtonWithMargin type="primary" onClick={handleApplyRadius}>{t('apply')}</ButtonWithMargin>
      <hr />
      <h3>{t('groups')}:</h3>
      <h5>{t('groupsDescription')}:</h5>
      <div>
        <RadioGroup
          onChange={setGroupFilterComparator}
          value={groupFilterComparator}
        >
          <Radio value={groupComparator.ALL}>{t('pinInAll')}</Radio>
          <Radio value={groupComparator.ANY}>{t('pinInAny')}</Radio>
        </RadioGroup>
      </div>
      <GroupsContainer>
        {selectedGroups.map(group => (
          <GroupChip key={group.id} name={group.name} />
        ))}
      </GroupsContainer>
      <div>
        <ButtonWithMargin onClick={showSelectGroups}>{t('selectGroups')}</ButtonWithMargin>
      </div>
      <SelectGroupsDialog
        entities={groups}
        getDisplayName={({name}) => name}
        getKey={defaultNodeIdGetter}
        id="filter-filter__select-groups"
        onConfirm={setSelectedGroups}
        onHide={hideSelectGroups}
        selectedEntities={selectedGroups}
        title={t('selectGroups')}
        visible={selectGroupsVisible}
      />
      <ButtonWithMargin type="primary" onClick={handleApplyGroups}>{t('apply')}</ButtonWithMargin>
    </FilterContainer>
  );
};

export default FilterPane;
