import React, {useCallback} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import SmallPreviewContainer from './SmallPreviewContainer';
import Deal from '../../../../type/Deal';
import EntityType from '../../../../type/EntityType';

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const StageTag = styled.div`
  margin-left: 8px;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  border: none;
  border-radius: 2px;
  padding: 2px 8px;
`;

const ExpectedCloseDate = styled.div`
  color: #aaa;
  font-size: 0.75rem;
`;

interface Props {
  deal: Deal,
  goToEntity: (entityType: EntityType, entityId: number) => void,
}

const BRIGHT_COLORS = ['#fff', '#ffffff', 'white', 'peach', 'yellow', 'neon yellow', 'neon green', 'cyan', '#f3f315',
  '#ffff00', '#00ffff', '#ffdab9', '#aeff14'];

const DealPreviewContainer: React.FC<Props> = ({deal, goToEntity}) => {
  const handleClick = useCallback(() => goToEntity(EntityType.DEAL, deal.id), [deal.id]);
  const color = BRIGHT_COLORS.includes(deal.stage.color) ? 'black' : 'white'; // choose contrast font-color
  return (
    <SmallPreviewContainer onClick={handleClick}>
      <Title>
        {deal.name} <StageTag style={{backgroundColor: deal.stage.color, color}}>{deal.stage.name}</StageTag>
      </Title>
      {deal.closingDate && (
        <ExpectedCloseDate>Expected close date: {moment(deal.closingDate).format('ll')}</ExpectedCloseDate>
      )}
    </SmallPreviewContainer>
  );
};

export default DealPreviewContainer;
