import React from 'react';
import styles from './SearchResultCommon.module.scss';

interface Props {}

const InvalidSearchOption: React.FC<Props> = () => (
  <div className={styles.container}>
    <span>
      Please type at least 3 characters...
    </span>
  </div>
);

export default InvalidSearchOption;
