import template from './entity-deals-list.html';
import dealsNetworkService from '../../network-services/deals-network-service';

function EntityDealsListController(
  $scope, $window, MainService, AddEditService,
) {
  Object.assign(this, $scope, {
    MainService,
    AddEditService,
    MODEL: $window.DataModel,
    addDealsToEntity,
    selectedRowCustomerTable,
    deleteDeal,
    removeDealFromEntity,
    saveEntityDeals,
    revertEntityDeals,
    $onInit,
  });

  function $onInit() {
    switch (this.entityType) {
      case 'account': {
        this.dirtyFlagProperty = 'accountDealsModified';
        break;
      }
      case 'contact': {
        this.dirtyFlagProperty = 'contactDealsModified';
        break;
      }
      default: {
        this.dirtyFlagProperty = 'dirtyEditFlag';
      }
    }
    this.properCaseEntityType = this.entityType.charAt(0).toUpperCase() + this.entityType.slice(1);
  }

  function addDealsToEntity() {
    this.MainService.populateEditPopupData('deals');
  }

  function selectedRowCustomerTable(pin, updating, crmtype) {
    this.MainService.selectedRowCustomerTable(pin, updating, crmtype);
  }

  async function deleteDeal(id, event) {
    if (event) {
      event.stopPropagation();
    }

    if (this.MODEL.accountDeals[id]) {
      await swal({
        title: 'Are you sure?',
        text: 'The deal would be deleted!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, remove this deal',
        closeOnConfirm: false,
      });
      // code for deleting deal
      await dealsNetworkService.deleteDeal(id);
    }

    delete this.MODEL.accountDeals[id];
    window.refreshDom({accountDeals: this.MODEL.accountDeals});
  }

  function removeDealFromEntity(id, event) {
    this.AddEditService[`removeDealFrom${this.properCaseEntityType}`](id);

    if (event) {
      event.stopPropagation();
    }
  }

  async function saveEntityDeals() {
    this.MODEL[this.dirtyFlagProperty] = false;
    await this.AddEditService.saveCrmType('deals', this.MODEL.currentCrmObjectId, this.MODEL[`${this.entityType}Deals`]);

    window.refreshDom({[this.dirtyFlagProperty]: this.MODEL[this.dirtyFlagProperty]});
  }

  async function revertEntityDeals() {
    this.MODEL[this.dirtyFlagProperty] = false;

    const obj = {...this.MODEL[`prev${this.properCaseEntityType}Deals`]};

    window.refreshDom({[`${this.entityType}Deals`]: obj});
    window.refreshDom({[this.dirtyFlagProperty]: this.MODEL[this.dirtyFlagProperty]});
  }
}

EntityDealsListController.$inject = [
  '$scope', '$window', 'MainService', 'AddEditService',
];

function entityDealsList() {
  return {
    restrict: 'E',
    scope: {
      entityType: '@',
      restrictAccess: '=',
    },
    bindToController: true,
    controller: 'EntityDealsListController',
    controllerAs: '$ctrl',
    template,
  };
}

export {EntityDealsListController, entityDealsList};
