import React, {memo} from 'react';
import Service from './Service';
import ServiceType from '../../../type/plans/Service';
import {areServicesVisible} from '../util/util';
import CategoryTitle from './CategoryTitle';
import {useTranslation} from 'react-i18next';

interface Props {
  licenses: number,
  liveLicenses: number,
  onSelect?: (serviceId: ServiceType['id'], selected: boolean) => void,
  selectedServiceIds: Set<ServiceType['id']>,
  services: ServiceType[],
}

const ServiceList = memo<Props>(({licenses, liveLicenses, onSelect, selectedServiceIds, services}) => {
  const {t} = useTranslation(['billing']);

  if (!areServicesVisible(services, licenses)) {
    return null;
  }

  return (
    <>
      <CategoryTitle>{t('Professional Services')}</CategoryTitle>
      {services.map(service => (
        <Service
          checked={selectedServiceIds.has(service.id)}
          key={service.id}
          liveLicenses={liveLicenses}
          licenses={licenses}
          service={service}
          onCheck={onSelect}
        />
      ))}
    </>
  );
});

export default ServiceList;
