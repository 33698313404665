import template from './show-tour-overlay.html';

class ShowTourOverlayController {
  constructor($window, $rootScope, $timeout) {
    Object.assign(this, {
      $window,
      $rootScope,
      $timeout,
      MODEL: $window.DataModel,
    });
  }

  startHopTour() {
    this.MODEL.productTour.inProgress = true;
    this.$rootScope.showTourOverlay = true;
    this.$window.refreshDom({showTourPop: false});
    this.MODEL.tourData = {
      id: 'mmc-onboarding',
      bubbleWidth: 250,
      steps: [
        {
          title: 'Your personal dashboard',
          content: 'Here, you can easily view all of your companies, check your upcoming activities, analyze rep performance, and much more!',
          target: '#logoImage',
          placement: 'bottom',
          onShow: () => {
            this.$timeout(() => {
              this.$rootScope.showTourOverlay = false;
            }, 1);
            $('#sticky-nav').css('z-index', '1032');
            this.changeTargetElem('#nav-bar-dash');
          },
          onNext: () => {
            $('#sticky-nav').css('z-index', '1030');
          },
          showPrevButton: false,
          showNextButton: true,
        },
        {
          title: 'Filter by users',
          content: 'Easily filter the information on your dashboard by toggling between team members.',
          target: '.viewByUser',
          placement: 'bottom',
          arrowOffset: 220,
          xOffset: -220,
          onShow: () => {
            this.$timeout(() => {
              this.$rootScope.showTourOverlay = true;
            }, 1);
            $('#sticky-nav').css('z-index', '1030');
            this.changeTargetElem('.viewByUser');
          },
          showPrevButton: true,
          showNextButton: true,
        },
        {
          title: 'Click here to access the team management section',
          content: 'You can send individual or team messages, share customers and pins, and track progress.',
          target: '.dashboardButton',
          placement: 'right',
          onShow: () => {
            this.$timeout(() => {
              this.$rootScope.showTourOverlay = true;
            }, 1);
            $('#sticky-nav').css('z-index', '1030');
            this.changeTargetElem('.dashboardButton');
          },
          showPrevButton: true,
          showNextButton: true,
        },
        {
          title: 'Access & check all of your CRM details',
          content:
                        'The navigation bar allows for seamless movement between CRM objects.',
          target: '#nav-bar-contacts',
          placement: 'bottom',
          onShow: () => {
            this.$timeout(() => {
              this.$rootScope.showTourOverlay = false;
            }, 1);
            $('#sticky-nav').css('z-index', '1032');
            this.changeTargetElem('#nav-bar-contacts');
          },
          onNext: () => {
            $('#sticky-nav').css('z-index', '1030');
          },
          onPrev: () => {
            $('#sticky-nav').css('z-index', '1030');
          },
          showPrevButton: true,
          showNextButton: true,
        },
        {
          title: 'Need to manually input data? No problem!',
          content: 'Individually add your Companies, People, and Deals.',
          target: '#addDealsFromNavBar',
          placement: 'bottom',
          onShow: () => {
            this.$timeout(() => {
              this.$rootScope.showTourOverlay = false;
              $('#sticky-nav').off('click');
              $('#sticky-nav').css('z-index', '1032');
              $('#addCRMBtn').trigger('click');
              this.changeTargetElem('#addDealsFromNavBar');
              $('#sticky-nav').click(() => {
                if (this.MODEL.productTour.inProgress) {
                  return false;
                }
              });
              $('#addCRMDropdown li a').click(() => {
                if (this.MODEL.productTour.inProgress) {
                  return false;
                }
              });
            }, 10);
          },
          arrowOffset: 220,
          xOffset: -220,
          showPrevButton: true,
          showNextButton: true,
        },
        // {
        //     title: "Mass data import in a few easy steps! ",
        //     content:
        //         "Simply import customer spreadsheets or sync your data with our CRM integrations.",
        //     target: "#crm-int",
        //     placement: "bottom",
        //     onShow: () => {
        //         this.$timeout(() => {
        //             $("#sticky-nav").off("click");
        //             $("#sticky-nav").css("z-index", "1032");
        //             $("#addCRMBtn").trigger("click");
        //             this.changeTargetElem("#crm-int");
        //             $("#sticky-nav").click(e => {
        //                 if (this.MODEL.productTour.inProgress) {
        //                     return false;
        //                 }
        //             });
        //             $("#addCRMDropdown li a").click(e => {
        //                 if (this.MODEL.productTour.inProgress) {
        //                     return false;
        //                 }
        //             });
        //         }, 10);
        //     },
        //     showPrevButton: true,
        //     showNextButton: true,
        //     arrowOffset: 220,
        //     xOffset: -220
        // },
        {
          title: 'That’s it for now!',
          content:
                        'Map My Customers has an awesome <a target="_blank" href="https://support.mapmycustomers.com/">help center</a> to help you through anything. If you have any questions, click <a target="_blank" href="https://support.mapmycustomers.com">here</a> to get the answers you need!',
          target: '.navbar',
          placement: 'bottom',
          onShow: () => {
            this.$timeout(() => {
              this.$rootScope.showTourOverlay = true;
              $('#sticky-nav').css('z-index', '1030');
              $('.hopscotch-bubble-arrow-container').css('display', 'none');
              this.changeTargetElem('', true);
              const bubble = $('.hopscotch-bubble.tour-mmc-onboarding');
              const width = bubble.width();
              const height = bubble.height();
              bubble.css({
                position: 'absolute',
                left: `calc(50% - ${Math.floor(width / 2)}px)`,
                top: `calc(50% - ${Math.floor(height / 2)}px)`,
              });
            }, 1);
          },
          showPrevButton: true,
          showNextButton: false,
        },
      ],
    };

    this.MODEL.productTour.numSteps = this.MODEL.tourData.steps.length;

    // Start the tour!
    hopscotch.setRenderer(this.createBubbleHTML);
    hopscotch.startTour(this.MODEL.tourData, 0);
    this.MODEL.productTour.showDemo(true);
  }

  changeTargetElem(selector, hide) {
    $('.target-elem').removeClass('target-elem');
    const step = hopscotch.getCurrStepNum();
    this.MODEL.productTour.remSteps = this.MODEL.productTour.numSteps - step - 1;

    if (!hide) {
      $(selector).addClass('target-elem');
    }
  }

  createBubbleHTML(json) {
    const {step} = json;
    const btn = json.buttons;
    let prevBtn = '';
    let nextBtn = '';
    if (btn.showPrev) {
      prevBtn = '<button class="hopscotch-prev hopscotch--button">PREVIOUS</button>&nbsp;';
    }
    if (btn.showNext) {
      nextBtn = '<button class="hopscotch-next hopscotch--button">NEXT</button>';
    } else {
      nextBtn =
                '<button class="hopscotch-close hopscotch--button" onClick="closeTourFn(true,event)">DONE</button>';
    }
    return `
            <div class="hopscotch-bubble-arrow-container hopscotch-arrow">
                <div class="hopscotch-bubble-arrow-border"></div>
                    <div class="hopscotch-bubble-arrow"></div>
            </div>
            <div class="tour-main-pop" style="width:${step.width}px;">
                <i class="ion-ios-close-empty hopscotch-close" onClick="closeTourFn(true,event)"></i>
                <div class="tour-title">${step.title}</div>
                <div class="tour-content">${step.content}</div>
                <div class="tour-box-footer">
                    <div class="steps-box">
                        <em>
                            <span>${step.num + 1}</span>&nbsp;of&nbsp;
                            <span>${json.tour.numSteps}</span>
                        </em>
                    </div>
                    <div class="tour-box-btns">
                        ${prevBtn}${nextBtn}
                    </div>
                </div>
            </div>
        `;
  }
}

ShowTourOverlayController.$inject = [
  '$window', '$rootScope', '$timeout',
];

const showTourOverlay = {
  bindings: {},
  controller: 'ShowTourOverlayController',
  template,
};

export {ShowTourOverlayController, showTourOverlay};
