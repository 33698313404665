import angular from 'angular';
import ActivityNetworkService from './activity-network-service';
import CrmActivityTypesNetworkService from './crm-activity-types-network-service';
import DashboardNetworkService from './dashboard-network-service';
import DealLossReasonsNetworkService from './deal-loss-reasons-network-service';
import FilterNetworkService from './filter-network-service';
import ImportNetworkService from './import-network-service';
import LocationNetworkService from './location-network-service';
import LoginNetworkService from './login-network-service';
import NylasNetworkService from './nylas-network-service';
import ReportNetworkService from './report-network-service';
import TerrNetworkService from './terr-network-service';

export default angular.module('mapmycustomersApp.networkServices', [])
  .factory('ActivityNetworkService', ActivityNetworkService)
  .factory('CrmActivityTypesNetworkService', CrmActivityTypesNetworkService)
  .factory('DashboardNetworkService', DashboardNetworkService)
  .factory('DealLossReasonsNetworkService', DealLossReasonsNetworkService)
  .factory('FilterNetworkService', FilterNetworkService)
  .factory('ImportNetworkService', ImportNetworkService)
  .factory('LocationNetworkService', LocationNetworkService)
  .factory('LoginNetworkService', LoginNetworkService)
  .factory('NylasNetworkService', NylasNetworkService)
  .factory('ReportNetworkService', ReportNetworkService)
  .factory('TerrNetworkService', TerrNetworkService)
  .name;
