import template from './multiple-rec-same-location.html';

function MultipleRecSameLocationController($scope, $window, MappingService, MainService, $route) {
  let label = 'Preview Company';
  if (['contactsMapPage', 'contactsGroupsContactListPage', 'individualContactsRoutePage'].includes($route.current.id)) {
    label = 'Preview Person';
  }
  if (['dealsGroupsDealListPage'].includes($route.current.id)) {
    label = 'Preview Deal';
  }

  Object.assign(this, $scope, {
    MODEL: $window.DataModel,
    MAN: $window.mmcUtils.MAN,
    $route,
    MappingService,
    MainService,
    closePopOver,
    selectedRowCustomerTable,
    label,
  });

  function closePopOver() {
    this.MappingService.closePopOver();
  }

  function selectedRowCustomerTable(pin, updating, crmtype) {
    if (['accountsMapPage', 'contactsMapPage', 'territoriesMapPage', 'accountsGroupsAccountListPage',
      'contactsGroupsContactListPage', 'dealsGroupsDealListPage', 'individualAccountsRoutePage',
      'individualContactsRoutePage'].includes(this.$route.current.id)) {
      this.MODEL.MappingService.singlePinData = pin;
    } else {
      this.MainService.selectedRowCustomerTable(pin, updating, crmtype);
    }
  }
}

const multipleRecSameLocation = {
  bindings: {
    getDirectionsToAddress: '&',
    callPhoneNumber: '&',
  },
  controller: 'MultipleRecSameLocationController',
  template,
};

MultipleRecSameLocationController.$inject = ['$scope', '$window', 'MappingService', 'MainService', '$route'];

export {MultipleRecSameLocationController, multipleRecSameLocation};
