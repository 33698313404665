export enum Color {
  BLACK = 'black',
  BLUE = 'blue',
  BROWN = 'brown',
  CYAN = 'cyan',
  GOLD = 'gold',
  GREEN = 'green',
  GREY = 'grey',
  LAVENDER = 'lavender',
  MAROON = 'maroon',
  NAVY = 'navy',
  NEON_BLUE = 'neon blue',
  NEON_GREEN = 'neon green',
  NEON_PURPLE = 'neon purple',
  NEON_RED = 'neon red',
  NEON_YELLOW = 'neon yellow',
  OLIVE = 'olive',
  ORANGE = 'orange',
  PEACH = 'peach',
  PINK = 'pink',
  PURPLE = 'purple',
  RED = 'red',
  TEAL = 'teal',
  TURQUOISE = 'turquoise',
  VIOLET = 'violet',
  WHITE = 'white',
  YELLOW = 'yellow',
}

export default interface Colored {
  color: Color | null,
} // eslint-disable-line semi
