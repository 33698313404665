import React, {useCallback} from 'react';
import DialogContainer from 'react-md/lib/Dialogs/DialogContainer';

interface Props {
  cancelButtonLabel?: string,
  confirmButtonLabel?: string,
  id: string,
  focusOnConfirm?: boolean,
  hideOnConfirm?: boolean,
  modal?: boolean,
  onCancel?: () => void,
  onConfirm?: () => void,
  onHide: () => void,
  text?: string,
  title?: string,
  visible?: boolean,
}

const ConfirmationDialog: React.FC<Props> = ({
  id,
  cancelButtonLabel = 'Cancel',
  confirmButtonLabel = 'Confirm',
  focusOnConfirm = true,
  hideOnConfirm = true,
  modal,
  onCancel,
  onConfirm,
  onHide,
  text,
  title = '',
  visible = false,
}) => {
  const handleConfirmClick = useCallback(
    () => {
      hideOnConfirm && onHide();
      onConfirm && onConfirm();
    },
    [hideOnConfirm, onConfirm, onHide],
  );
  const handleCancelClick = useCallback(
    () => {
      onHide();
      if (onCancel && onCancel !== onHide) {
        onCancel();
      }
    },
    [onCancel, onHide],
  );

  return (
    <DialogContainer
      actions={[
        {
          children: cancelButtonLabel,
          className: 'prv-confirmation-dialog__cancel-button',
          onClick: handleCancelClick,
          secondary: true,
        },
        {
          children: confirmButtonLabel,
          className: 'prv-confirmation-dialog__confirm-button',
          onClick: handleConfirmClick,
          primary: true,
        },
      ]}
      dialogClassName="prv-confirmation-dialog"
      initialFocus={focusOnConfirm
        ? '.prv-confirmation-dialog__confirm-button'
        : '.prv-confirmation-dialog__cancel-button'
      }
      id={id}
      modal={modal}
      onHide={onHide}
      renderNode={document.body}
      title={title}
      visible={visible}
    >
      {text && <p>{text}</p>}
    </DialogContainer>
  );
};

export default ConfirmationDialog;
