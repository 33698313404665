import {combineReducers} from '@reduxjs/toolkit';
import avatar from './avatar';
import company, {CompanyActions} from './company';
import people, {PeopleActions} from './people';
import lead, {LeadActions} from './lead';
import integrations, {IntegrationActions} from './integrations';
import notification, {NotificationActions} from './notification';
import modals from './modals';
import routing from './routing-service';
import {GlobalActions} from './globalActions';

const rootReducer = combineReducers({
  [avatar.name]: avatar.reducer,
  [company.name]: company.reducer,
  [integrations.name]: integrations.reducer,
  [lead.name]: lead.reducer,
  [modals.name]: modals.reducer,
  [notification.name]: notification.reducer,
  [people.name]: people.reducer,
  [routing.name]: routing.reducer,
});

export type Actions =
  GlobalActions
  | CompanyActions
  | LeadActions
  | PeopleActions
  | NotificationActions
  | IntegrationActions;

export type ActionsTypes = Actions['type'];

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
