import BaseNetworkService from './base-network-service/base-network-service';
import helperService from '../shared-services/helper-service';
import Organization from '../react/type/Organization';
import Team from '../react/type/Team';
import Setting from '../react/type/Setting';
import User from '../react/type/User';
import ListResponse from '../react/type/service/ListResponse';
import Identified from '../react/type/base/Identified';
import GeoPoint from '../react/type/GeoPoint';

type OrgId = Organization['id'];
type SettingId = Setting['id'];
type TeamId = Team['id'];
type UserId = User['id'];

export interface LocationSummary extends Identified {
  geoPoint: GeoPoint,
  accuracy: string,
  createdAt: string,
  user: Pick<User, 'id' | 'username'>
}

export class TeamNetworkService {
  async getOrg() {
    const endPoint = 'organizations';
    return BaseNetworkService.read(endPoint);
  }

  async getTeams(orgId: OrgId, params?: any): Promise<ListResponse<Team>> {
    const endPoint = `organizations/${orgId}/teams`;
    const response: ListResponse<Team> = await BaseNetworkService.read(endPoint, params);
    response.data = response.data.filter(({viaTeamAccess}) => viaTeamAccess);
    return response;
  }

  async getTeamMembers(orgId: OrgId, teamId: TeamId) {
    const endPoint = `organizations/${orgId}/teams/${teamId}/users`;
    try {
      const response = await BaseNetworkService.read(endPoint);
      response.data = response.data.map((rec: User) => Object.assign(rec, {teamId}));
      return response;
    } catch (e) {
      return {data: []};
    }
  }

  async getSingleTeamMember(orgId: OrgId, userId: UserId) {
    const endPoint = `organizations/${orgId}/users/${userId}`;
    return BaseNetworkService.read(endPoint);
  }

  postTeam(orgId: OrgId, payload: any) {
    const endPoint = `organizations/${orgId}/teams`;
    return helperService.executeWithCaptcha(
      'create',
      (token: string) => BaseNetworkService.create(endPoint, {...payload, token}),
    );
  }

  async putTeam(orgId: OrgId, teamId: TeamId, payload: any) {
    const endPoint = `organizations/${orgId}/teams/${teamId}`;
    return BaseNetworkService.update(endPoint, payload);
  }

  async deleteTeam(orgId: OrgId, teamId: TeamId) {
    const endPoint = `organizations/${orgId}/teams/${teamId}`;
    return BaseNetworkService.delete(endPoint);
  }

  async deleteTeamMembers(orgId: OrgId, teamId: TeamId, userIds: UserId[]) {
    return BaseNetworkService.delete(`organizations/${orgId}/user-teams`, [
      {teamId, userId: userIds},
    ]);
  }

  _getSettingsEndpoint(orgId: OrgId, settingId?: SettingId) {
    let endPoint = `organizations/${orgId}/settings`;
    if (settingId) {
      endPoint += `/${settingId}`;
    }
    return endPoint;
  }

  getSettings(orgId: OrgId) {
    return BaseNetworkService.read(this._getSettingsEndpoint(orgId));
  }

  updateSetting(orgId: OrgId, payload: any) {
    return BaseNetworkService.update(this._getSettingsEndpoint(orgId, payload.id), payload);
  }

  getLocationsSummary(orgId: OrgId, filters: object = {}): Promise<ListResponse<LocationSummary>> {
    const params: any = {};
    if (Object.keys(filters).length) {
      params.$filters = JSON.stringify(filters);
    }
    return BaseNetworkService.read(`organizations/${orgId}/reports/locations/summary`, params);
  }
}

export default new TeamNetworkService();
