import template from './new-member-options-modal.html';

class NewMemberOptionsModalController {
  constructor(TeamService) {
    Object.assign(this, {
      TeamService,
    });
  }

  addNewMember() {
    this.TeamService.addNewMember(this.type);
    this.closeModal();
  }

  closeModal() {
    this.showModal = false;
  }
}

NewMemberOptionsModalController.$inject = ['TeamService'];

const newMemberOptionsModal = {
  bindings: {
    showModal: '=',
  },
  controller: 'NewMemberOptionsModalController',
  template,
};

export {NewMemberOptionsModalController, newMemberOptionsModal};
