import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Button, Tooltip} from 'antd';
import {
  ColDef,
  FilterChangedEvent,
  GridApi,
  RowClickedEvent,
  RowSelectedEvent,
  SortChangedEvent,
} from '@ag-grid-community/core';
import {useTranslation} from 'react-i18next';
import {isWindows} from 'react-device-detect';
import {Grid} from './Grid';
import Pagination from './Pagination';
import MultiSelectBar from './MultiSelectBar';
import ViewByUser from '../ViewByUser/ViewByUser';
import SelectFieldsModal from '../SelectFieldsModal/SelectFieldsModal';
import MainServiceType from '../../type/service/MainService';
import {AnyEntity} from '../../type/AnyEntity';
import IField from '../../type/fieldModel/IField';
import EntityType from '../../type/EntityType';
import defaultNodeIdGetter from '../../util/defaultNodeIdGetter';
import useBoolean from '../../hook/useBoolean';
import buildFilters from '../../util/buildFilters';
import {groupComparator} from '../../type/network/filterComparator';
import FieldFeature from '../../../common/field-model/field/FieldFeature';
import LoadingProgress from '../LoadingProgress/LoadingProgress';
import BlockSpace from '../Layout/BlockSpace';
import theme from '../../style/theme';
import EntityFieldModel from '../../../common/field-model/entity-field-model';

const scrollbarWidth: number = isWindows ? 15 : 10;

const DEFAULT_COL_DEFINITION: ColDef = {
  headerComponentParams: {
    menuIcon: 'fa-bars',
    template: `
      <div class="ag-cell-label-container" role="presentation">
        <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
        <div ref="eLabel" class="ag-header-cell-label" role="presentation">
          <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>
          <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
          <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
          <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
          <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
        </div>
      </div>`,
  },
};

export interface EntityFilter {
  customFields?: object;
  includeCustomFields?: boolean,
  includeGroups?: boolean,
  includeNotes?: boolean,
  viewAs?: number,
  area?: {
    radius: number,
    uom: string,
    point: number[],
    location: string,
  },
  groups?: {[key in groupComparator]: number[]},
}

export interface Sorting {
  fieldName: string,
  ascending: boolean,
}

export interface FilterParam {
  localizationKey: string,
  value: string | number,
  options: {[key: string]: string | number},
}

export interface FilteredField {
  name: string,
  displayName: string,
  filterParam: FilterParam,
  filter: object,
  showNameOnTooltip: boolean,
}

const FilteredFieldsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: white;
  border-bottom: 1px solid #ccc;
  padding: 8px;
`;

const FilteredFieldText = styled.p`
  max-width: 200px;
  overflow: hidden;
`;

const FilteredFieldsList = styled.div`
  display: block;
  justify-content: flex-start;
  & > .filtered-field {
    display: inline-block;
    justify-content: space-between;
    align-items: center;
    background-color: #ddd;
    padding: 4px 8px;
    margin: 4px 10px;
    border-radius: 5px;
    .filtered-field__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > i {
        margin-left: 20px;
        cursor: pointer;
      }    
      & > p {
        margin: 0px;
      }

    }
  }
`;

const Header = styled(BlockSpace)`
  width: 100%;
  height: 50px;
  display: flex;
  padding: 5px;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
`;

const FilterPane = styled.div`
  margin: 10px 10px 10px 0;
  width: 400px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 10px;
  display: flex;
  background-color: #FBFBFC;
`;

const ListViewGridWrapper = styled(Grid)`
  flex-grow: 1;
  font-size: 14px;
  margin-bottom: 1rem;
  .ag-header {
    border: 1px solid #E7EAF3;
    font-weight: 700;
    .ag-header-cell-filtered {
      .ag-header-icon {
        color: ${theme.color.brightBlue};
      }
    }  
  }  
  
  .ag-cell {
    border-bottom: 1px solid #E7EAF3;
    border-top: 1px solid #E7EAF3;
    font-weight: 400;
  }
  
  .ag-body-horizontal-scroll {
    height: 20px;
    min-height: 20px;
    max-height: 20px;
  }
`;

const ListViewGridContainer = styled.div`
  width: 100%;
  height: calc(100% - 10px);
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;
const ICONS = {menu: '<i class="fa fa-filter" />'};

type CompanyPersonOrDealOrLead = EntityType.COMPANY | EntityType.PERSON | EntityType.DEAL | EntityType.LEAD;

interface Props {
  fieldModel: EntityFieldModel,
  entityCount: number | undefined,
  entityList: AnyEntity[] | undefined,
  entityType: CompanyPersonOrDealOrLead,
  onChangePage: (page: number) => void,
  page: number,
  onChangeSorting: (sorting: Sorting) => void,
  onChangeFilters: (filter: EntityFilter) => void,
  multiSelectBar: boolean,
  MainService: MainServiceType,
  isLoading: boolean,
  onReloadData: () => void,
  viewColumns: Set<IField>,
  onUpdateViewColumns: () => void,
  extraFilters?: React.ReactElement,
  filteredFields: FilteredField[],
  pagination?: boolean,
  sorting: Sorting,
  onClearFilter: (filterNames: string[]) => void,
}

const ListViewGrid: React.FC<Props> = ({
  fieldModel,
  entityCount,
  entityList,
  entityType,
  onChangePage,
  page,
  onChangeFilters,
  onChangeSorting,
  multiSelectBar,
  MainService,
  isLoading,
  onReloadData,
  viewColumns,
  onUpdateViewColumns,
  extraFilters,
  filteredFields,
  pagination = true,
  sorting,
  onClearFilter,
}) => {
  const {t} = useTranslation(['grid', 'filter']);
  const [columnDefinitions, setColumnDefinitions] = useState<ColDef[]>([]);
  const [selectFieldsDialogVisible, showSelectFieldsDialog, hideSelectFieldsDialog] = useBoolean(false);
  const [selectedEntities, setSelectedEntities] = useState<Set<AnyEntity>>(new Set());
  const gridApiRef = useRef<GridApi | undefined>();

  useEffect(
    () => {
      setColumnDefinitions(fieldModel.fields
        .filter((field: IField) => viewColumns.has(field))
        .map((field: IField) => ({
          ...field.gridProperties,
          headerClass: field.features.has(FieldFeature.FILTERABLE) ? '' : 'mmc-grid__column--no-hover-color',
        })));
    },
    [fieldModel, viewColumns],
  );

  useEffect(
    () => {
      if (gridApiRef.current) {
        const sortingObject = {colId: sorting.fieldName, sort: sorting.ascending ? 'asc' : 'desc'};
        const sortModel = gridApiRef.current.getSortModel();
        if (sortModel.length) {
          const sortColumn = sortModel[0];
          if (sortColumn.colId !== sortingObject.colId || sortColumn.sort !== sortingObject.sort) {
            gridApiRef.current.setSortModel([sortingObject]);
          }
        }
      }
    },
    [sorting],
  );

  useEffect(
    () => {
      setSelectedEntities(new Set());
    },
    [page, setSelectedEntities],
  );

  const handleRowSelectionChanged = useCallback(
    ({api}: RowSelectedEvent) => setSelectedEntities(new Set(api.getSelectedRows())),
    [selectedEntities, setSelectedEntities],
  );
  const handleChooseRow = useCallback(
    (row: RowClickedEvent) => MainService.selectedRowCustomerTable(row.data, false, entityType),
    [entityType],
  );

  const changeViewBy = useCallback(
    (userId: number | undefined) => {
      if (userId) {
        onChangeFilters({viewAs: userId});
      } else {
        onClearFilter(['viewAs']);
      }
    },
    [onChangePage, onChangeFilters, onClearFilter],
  );

  const handleChangeFilter = useCallback(
    ({api}: FilterChangedEvent) => onChangeFilters(buildFilters(fieldModel, api.getFilterModel())),
    [onChangeFilters, fieldModel],
  );

  const handleChangeSorting = useCallback(
    ({api}: SortChangedEvent) => {
      const sortModel = api.getSortModel();
      if (Array.isArray(sortModel) && sortModel.length) {
        onChangeSorting({fieldName: sortModel[0].colId, ascending: sortModel[0].sort === 'asc'});
      }
    },
    [onChangeSorting],
  );

  useEffect(
    () => {
      if (gridApiRef.current) {
        Object.keys(gridApiRef.current.getFilterModel()).forEach((columnName: string) => {
          if (!filteredFields.find(filteredField => (filteredField.name === columnName))) {
            gridApiRef.current?.destroyFilter(columnName);
          }
        });
      }
    },
    [filteredFields],
  );

  const handleResetFilters = useCallback(
    () => {
      if (gridApiRef.current) {
        gridApiRef.current.setFilterModel(null);
      }
      onClearFilter(filteredFields.map(filteredField => filteredField.name));
    },
    [onClearFilter, filteredFields],
  );

  const onSetGridApi = useCallback(
    ({api}) => {
      gridApiRef.current = api;
    },
    [],
  );


  return (entityList ?
    <Container>
      {extraFilters && (
        <FilterPane>
          {extraFilters}
        </FilterPane>
      )}
      <ListViewGridContainer>
        {filteredFields.length > 0 && (
          <FilteredFieldsContainer>
            <FilteredFieldsList>
              {filteredFields.map((filteredField) => (
                <div key={`filtered-field-${filteredField.name}`} className="filtered-field">
                  <Tooltip
                    title={`${filteredField.showNameOnTooltip ? filteredField.displayName : ''} ${t(filteredField.filterParam.localizationKey, filteredField.filterParam.options)} ${filteredField.filterParam.value}`}
                  >
                    <div className="filtered-field__container">
                      <FilteredFieldText>
                        {filteredField.displayName}
                      </FilteredFieldText>
                      <i className="fa fa-times" onClick={() => onClearFilter([filteredField.name])} />
                    </div>
                  </Tooltip>
                </div>
              ))}
            </FilteredFieldsList>
            <Button onClick={handleResetFilters}>Clear filters</Button>
          </FilteredFieldsContainer>
        )}
        <Header>
          <ViewByUser onChange={changeViewBy} />
          <Button onClick={showSelectFieldsDialog}>{t('SelectFields')}</Button>
        </Header>
        <ListViewGridWrapper
          localeTextFunc={t}
          columnDefs={columnDefinitions}
          deltaColumnMode
          getRowNodeId={defaultNodeIdGetter}
          rowData={entityList}
          isLoading={isLoading}
          rowClass="mmc-collection-grid__row cursor-pointer"
          rowSelection="multiple"
          suppressCellSelection
          suppressRowClickSelection
          suppressHorizontalScroll={false}
          scrollbarWidth={scrollbarWidth}
          floatingFilter={false}
          onRowClicked={handleChooseRow}
          onFilterChanged={handleChangeFilter}
          icons={ICONS}
          onSortChanged={handleChangeSorting}
          onSelectionChanged={handleRowSelectionChanged}
          onGridReady={onSetGridApi}
          suppressMenuHide
          defaultColDef={DEFAULT_COL_DEFINITION}
        />
        {pagination && (
          <Pagination
            total={entityCount!}
            page={page}
            onSetPage={onChangePage}
          />
        )}
        <MultiSelectBar
          entityType={entityType}
          visible={selectedEntities.size > 0 && multiSelectBar}
          selectedEntities={selectedEntities}
          onReloadData={onReloadData}
        />
        <SelectFieldsModal
          columnDefinitions={columnDefinitions}
          fieldModel={fieldModel}
          show={selectFieldsDialogVisible}
          onHide={hideSelectFieldsDialog}
          onSave={onUpdateViewColumns}
          MainService={MainService}
        />
      </ListViewGridContainer>
    </Container>
    : <LoadingProgress />
  );
};

export default ListViewGrid;
