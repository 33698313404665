import invariant from 'invariant';
import moment from 'moment';
import {Range} from '../../../type/plans/Range';
import ServiceType from '../../../type/plans/Service';
import AddonType from '../../../type/plans/Addon';
import Tier from '../../../type/plans/Tier';
import {Interval} from '../../../type/plans/Interval';
import Price from '../../../type/plans/Price';
import Plan from '../../../type/Plan';
import CurrentPlan from '../../../type/plans/CurrentPlan';
import Me from '../../../type/Me';

export const isInRange = ([from, to]: Range, value: number) =>
  value >= from && value <= (to ?? Number.POSITIVE_INFINITY);

export const hasPriceForValue = (haystack: Array<[Range, any]>, value: number): boolean =>
  haystack.some(([range]) => isInRange(range, value));

export const findByValue = <T>(haystack: Array<[Range, T]>, value: number): T|undefined => {
  const price = haystack.find(([range]) => isInRange(range, value));
  return price ? price[1] : undefined;
};

export const areServicesVisible = (services: ServiceType[], licenses: number) =>
  services.some(({intervalPrice}) => hasPriceForValue(intervalPrice, licenses));

export const shouldShowAddon = (
  {intervalPrice}: AddonType,
  licenses: number,
  liveLicenses: number,
  purchased: boolean,
) => {
  const hasOldPrice = hasPriceForValue(intervalPrice, liveLicenses);
  const hasNewPrice = hasPriceForValue(intervalPrice, licenses);
  return hasNewPrice || (purchased && hasOldPrice);
};

export const findTierByIntervalAndLicenses = (tiers: Tier[], interval: Interval, licenses: number) => {
  let tier: Tier | undefined;
  const possibleTiers = tiers.filter(({licenseLimit}) => isInRange(licenseLimit, licenses));
  if (possibleTiers.length === 1) {
    tier = possibleTiers[0];
  } else {
    tier = possibleTiers.find((tier) => tier.interval === interval && !tier.isTrial);
    if (!tier && interval === Interval.MONTHLY) {
      // try to find the best matching annual tier then
      tier = possibleTiers.find((tier) => tier.interval === Interval.ANNUAL && !tier.isTrial);
    }
    if (!tier) {
      // if still not found, then it's probably a trial tier
      tier = possibleTiers.find((tier) => tier.isTrial);
    }
  }
  return tier;
};

export const ensureSameCurrency = (a: Price, b: Price) => {
  if (a.currency.toLowerCase() !== b.currency.toLowerCase()) {
    invariant(false, `Unmatching currencies found: ${JSON.stringify(a)}, ${JSON.stringify(b)}`);
  }
};

const addSubscriptionPrice = (
  price: Price,
  interval: Interval,
  subscriptionPrice: Price,
  subscriptionInterval: Interval,
  prorateRatio: number = 1,
) => {
  const result = {...price};
  ensureSameCurrency(result, subscriptionPrice);
  if (interval === subscriptionInterval) {
    result.value += subscriptionPrice.value * prorateRatio;
  } else if (interval === Interval.ANNUAL && subscriptionInterval === Interval.MONTHLY) {
    result.value += 12 * subscriptionPrice.value * prorateRatio;
  } else if (interval === Interval.MONTHLY && subscriptionInterval === Interval.ANNUAL) {
    result.value += (subscriptionPrice.value / 12) * prorateRatio;
  } else {
    invariant(false, `Invalid intervals: ${interval}, ${subscriptionInterval}`);
  }
  return result;
};

/**
 * calculates prorate ratio which is (number of remaining days in billing cycle) / (billing cycle total days)
 * @param currentPlan
 */
export const getProrateRatioDetails = (currentPlan: CurrentPlan) => {
  if (currentPlan.tier.isTrial) {
    return {remainingDays: 365, daysInCurrentBillingCycle: 365, ratio: 1};
  }
  const nexPaymentMoment = moment(currentPlan.nextPaymentDate).startOf('day');
  const isMonthly = currentPlan.tier.interval === Interval.MONTHLY;
  const daysInCurrentBillingCycle = nexPaymentMoment.diff(
    moment(currentPlan.nextPaymentDate).subtract(1, isMonthly ? 'month' : 'year').startOf('day'),
    'day',
  ); // 365|366 for annual or 28-31 for monthly plans
  const remainingDays = nexPaymentMoment.diff(moment().startOf('day'), 'day');
  return {remainingDays, daysInCurrentBillingCycle, ratio: remainingDays / daysInCurrentBillingCycle};
};

export const getProrateRatio = (currentPlan: CurrentPlan) => {
  const {ratio} = getProrateRatioDetails(currentPlan);
  return ratio;
};

export const calculateSubscriptionPrice = (tier: Tier, selectedAddons: AddonType[], licenses: number) => {
  // price for licenses
  let price = {...tier.price, value: tier.price.value * licenses};

  // price for addons
  selectedAddons.forEach(addon => {
    const addonPrice = findByValue(addon.intervalPrice, licenses);
    if (addonPrice && addonPrice.subscriptionPrice && addonPrice.subscriptionInterval) {
      price = addSubscriptionPrice(price, tier.interval, addonPrice.subscriptionPrice, addonPrice.subscriptionInterval);
    }
  });

  return price;
};

export const calculateProratedPrice = (
  tier: Tier,
  selectedAddons: AddonType[],
  licenses: number,
  currentPlan: CurrentPlan,
  addons: AddonType[],
) => {
  const prorationPrice = {value: 0, currency: tier.price.currency};

  // no proration if the previous plan was trial.
  if (currentPlan.tier.isTrial) {
    return prorationPrice;
  }

  const prorateRatio = getProrateRatio(currentPlan);

  // if tier has changed
  if (tier.id !== currentPlan.tier.id) {
    const currentPriceValue = currentPlan.tier.price.value * currentPlan.licenses;
    ensureSameCurrency(prorationPrice, currentPlan.tier.price);
    prorationPrice.value += currentPriceValue * prorateRatio;
  }

  // if addons have changed
  const selectedAddonsMap = new Map(selectedAddons.map(addon => [addon.id, addon]));
  const currentAddons = addons.filter(({id}) => currentPlan.addonIds.includes(id));
  // compare new addons with a previously selected ones
  currentAddons.forEach(currentAddon => {
    const currentAddonPrice = findByValue(currentAddon.intervalPrice, currentPlan.licenses);
    if (!currentAddonPrice || !currentAddonPrice.subscriptionPrice) {
      return; // skip this addon as it doesn't have the subscription price
    }
    const selectedAddon = selectedAddonsMap.get(currentAddon.id);
    if (!selectedAddon) {
      // not even selected now
      ensureSameCurrency(prorationPrice, currentAddonPrice.subscriptionPrice);
      prorationPrice.value += currentAddonPrice.subscriptionPrice.value * prorateRatio;
    } else {
      // is still selected, check if the price is new
      const selectedAddonPrice = findByValue(selectedAddon.intervalPrice, licenses);
      if (selectedAddonPrice !== currentAddonPrice) {
        ensureSameCurrency(prorationPrice, currentAddonPrice.subscriptionPrice);
        prorationPrice.value += currentAddonPrice.subscriptionPrice.value * prorateRatio;
      }
    }
  });

  return {...prorationPrice, value: -prorationPrice.value};
};

export const calculateDueTodayPrice = (
  tier: Tier,
  addons: AddonType[],
  services: ServiceType[],
  licenses: number,
  currentPlan: CurrentPlan,
  proratedPrice: Price,
) => {
  const prorateRatio = getProrateRatio(currentPlan);

  // price for licenses
  let price = {...tier.price, value: tier.price.value * (licenses - currentPlan.licenses) * prorateRatio};

  // additionally pay for existing licenses if new tier not equal to the old one
  if (tier.id !== currentPlan.tier.id) {
    ensureSameCurrency(price, currentPlan.tier.price);
    price.value += tier.price.value * currentPlan.licenses * prorateRatio;
  }

  services.forEach((service) => {
    const servicePrice = findByValue(service.intervalPrice, licenses);
    if (servicePrice) {
      ensureSameCurrency(price, servicePrice);
      price.value += servicePrice.value;
    }
  });

  const purchasedAddonIds = new Set(currentPlan.addonIds);

  // first, calculate price for newly selected addons
  addons.filter(({id}) => !purchasedAddonIds.has(id)).forEach(addon => {
    const addonPrice = findByValue(addon.intervalPrice, licenses);
    if (addonPrice) {
      if (addonPrice.onboardingPrice) {
        ensureSameCurrency(price, addonPrice.onboardingPrice);
        price.value += addonPrice.onboardingPrice.value;
      }
      if (addonPrice.subscriptionPrice && addonPrice.subscriptionInterval) {
        price = addSubscriptionPrice(
          price,
          tier.interval,
          addonPrice.subscriptionPrice,
          addonPrice.subscriptionInterval,
        );
      }
    }
  });

  // next, calculate price for previosly selected addons if their price has changed
  addons.filter(({id}) => purchasedAddonIds.has(id)).forEach(addon => {
    const oldAddonPrice = findByValue(addon.intervalPrice, currentPlan.licenses);
    const addonPrice = findByValue(addon.intervalPrice, licenses);
    if (oldAddonPrice && addonPrice) {
      // we ignore onboarding price and only charge for the updated subscription price
      if (oldAddonPrice.subscriptionPrice !== addonPrice.subscriptionPrice && addonPrice.subscriptionPrice
        && addonPrice.subscriptionInterval
      ) {
        price = addSubscriptionPrice(
          price,
          tier.interval,
          addonPrice.subscriptionPrice,
          addonPrice.subscriptionInterval,
          prorateRatio,
        );
      }
    }
  });

  if (proratedPrice.value < 0) {
    ensureSameCurrency(price, proratedPrice);
    price.value += proratedPrice.value; // adding since prorationPrice is negative
  }

  return price;
};

export const calculatePrices = (currentPlan: CurrentPlan, tier: Tier, licenses: number, prorateRatio: number) => {
  const isTrial = currentPlan.tier.isTrial;
  const switchingToAnnual = !isTrial && currentPlan.tier.interval === Interval.MONTHLY
    && tier.interval === Interval.ANNUAL;

  // only calculate adjustedPrice when there's a reason to adjust (i.e. new licenses added)
  const adjustedPrice = {...tier.price, value: 0};
  if (switchingToAnnual) {
    adjustedPrice.value = -currentPlan.tier.price.value * currentPlan.licenses * prorateRatio;
  } else if (!isTrial && licenses > currentPlan.licenses && currentPlan.tier.price.value !== tier.price.value) {
    adjustedPrice.value = (tier.price.value - currentPlan.tier.price.value) * currentPlan.licenses * prorateRatio;
  }

  const proratedPrice = {
    ...tier.price,
    value: switchingToAnnual
      ? tier.price.value * licenses
      : tier.price.value * (licenses - currentPlan.licenses) * prorateRatio,
  };
  const dueTodayPrice = {...adjustedPrice, value: adjustedPrice.value + proratedPrice.value};

  return {adjustedPrice, proratedPrice, dueTodayPrice, switchingToAnnual};
};

const mapPlanToTier = (plan: Plan): Tier => ({
  id: plan.id,
  name: plan.name,
  interval: plan.interval === 1 ? Interval.MONTHLY : Interval.ANNUAL,
  price: {currency: 'USD', value: parseFloat(plan.price)},
  isTrial: plan.trialing,
  licenseLimit: plan.metaData?.licenseLimit ?? [0],
});

export const convertToTiers = (plans: Plan[]): Tier[] => plans.filter(({metaData}) => !!metaData).map(mapPlanToTier);

export const buildCurrentPlan = (me: Me): CurrentPlan => {
  const {plan} = me.organization;
  let nextPaymentDate = moment().toDate(); // now, will be used for current paid plans
  if (plan.trialing) {
    nextPaymentDate = moment(me.organization.trialExpiresAt).toDate();
  } else if (plan.nextPaymentDate) {
    nextPaymentDate = moment(plan.nextPaymentDate).toDate();
  }
  return {
    addonIds: [],
    isOldPlan: !(plan.metaData && plan.metaData.licenseLimit),
    licenses: plan.trialing ? 0 : plan.quantity,
    nextPaymentDate,
    tier: mapPlanToTier(plan),
  };
};
