import React from 'react';
import {Col, Row} from 'antd';
import styled from 'styled-components';

const TopLabelCol = styled(Col)`
  margin-bottom: 1.05rem;
`;

const TopLabelRow = styled(Row)`
  font-size: 0.85rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.54);
  line-height: 0.85rem;
  margin-bottom: 0.25rem;
`;

export interface LabeledFieldProps {
  children: React.ReactNode;
  label?: string | null;
  labelPosition?: 'top' | 'side';
  sideLabelSpan?: number;
}

const Labeled: React.FC<LabeledFieldProps> = ({
  children,
  label,
  labelPosition = 'top',
  sideLabelSpan = 6,
}) => {
  if (label === null) {
    return <>children</>;
  }

  if (labelPosition === 'top') {
    return (
      <TopLabelCol>
        <TopLabelRow>{label}</TopLabelRow>
        <Row>{children}</Row>
      </TopLabelCol>
    );
  }

  return (
    <Row>
      <Col span={sideLabelSpan}>{label}</Col>
      <Col span={24 - sideLabelSpan}>{children}</Col>
    </Row>
  );
};

export default Labeled;
