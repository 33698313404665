import React, {ReactNode, useMemo} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {Button, Popconfirm, Space, Table, Tag, Tooltip} from 'antd';
import {ColumnType} from 'antd/es/table';
import Header from '../../components/Header/Header';
import Integration from '../../type/integration/Integration';
import EntityType from '../../type/EntityType';
import entityDisplayName from '../../util/formatter/entityDisplayName';
import IntegrationStatus from '../../type/enum/IntegrationStatus';
import IntegrationService from '../../type/enum/IntegrationService';
import PageContainer from './components/PageContainer';
import {dynamicsSmallLogo, hubspotSmallLogo, salesforceSmallLogo, zohoSmallLogo} from './components/logo';
import {RootState} from '../../../store/rootReducer';
import {getIntegrations, getLoading} from '../../../store/integrations';
import {deleteIntegration, fetchIntegrations, updateIntegrationStatus} from '../../../store/integrations/actions';
import withStore from '../../hoc/withStore';
import useDataLoader from '../../hook/useDataLoader';
import LoadingProgress from '../../components/LoadingProgress/LoadingProgress';
import {SupportedEntityType} from '../../../network-services/custom-fields-network-service';
import useInitializer from '../../hook/useInitializer';
import WizardModal from './components/WizardModal';
import useBoolean from '../../hook/useBoolean';

const StatusText = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const IntegrationName = styled.span<{status: IntegrationStatus}>`
  color: ${props => (props.status === IntegrationStatus.PAUSED ? '#acacac' : 'inherit')};
`;

const entityTypeColor: {[key in EntityType]: string} = {
  [EntityType.ACTIVITY]: '#eb2f96',
  [EntityType.COMPANY]: '#f5222d',
  [EntityType.COMPANY_GROUP]: '#1890ff',
  [EntityType.COMPANY_ROUTE]: '#fa8c16',
  [EntityType.DEAL]: '#722ed1',
  [EntityType.PERSON]: '#52c41a',
  [EntityType.ROUTE]: '#13c2c2',
};

const integrationIcon: {[key in IntegrationService]: ReactNode} = {
  [IntegrationService.DYNAMICS]: <img src={dynamicsSmallLogo} alt="Microsoft Dynamics 365" width={100} />,
  [IntegrationService.HUBSPOT]: <img src={hubspotSmallLogo} alt="Hubspot" width={100} />,
  [IntegrationService.SALESFORCE]: <img src={salesforceSmallLogo} alt="Salesforce" width={100} />,
  [IntegrationService.ZOHO]: <img src={zohoSmallLogo} alt="Zoho" width={100} />,
};

const getColumnConfig = (
  updateStatus: typeof updateIntegrationStatus,
  remove: typeof deleteIntegration,
): ColumnType<Integration>[] => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (name: string, integration: Integration) => (
      <Space size="middle">
        {integrationIcon[integration.type] || undefined}
        <IntegrationName status={IntegrationStatus.ACTIVE}>{name}</IntegrationName>
      </Space>
    ),
  },
  {
    title: 'Object(s)',
    dataIndex: 'syncOptions',
    key: 'syncOptions',
    render: (syncOptions: Integration['syncOptions']) => (
      <>
        {syncOptions && (Object.keys(syncOptions) as SupportedEntityType[])
          .filter(option => syncOptions[option].incoming || syncOptions[option].outgoing)
          .map(entityType => [entityDisplayName(entityType, false, true), entityTypeColor[entityType]])
          .sort((a, b) => (a[0] || '').localeCompare(b[0] || ''))
          .map(([entityName, color]) => <Tag color={color} key={entityName}>{entityName}</Tag>)
        }
      </>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'isLocked',
    key: 'isLocked',
    render: (_, integration: Integration) => {
      const disabledMessage = 'Talk to Sales to purchase an integration. If you have already purchased an Integration the sync will begin in 1 business day. Once the initial sync of your data has started, it can take a full day or more depending on the number of records to sync.';
      const enabledMessage = integration.schedule === null ? 'Initial Sync has been completed.' : `Initial Sync has been completed and your data will sync every ${integration.schedule?.frequency} at ${moment(integration.schedule?.nextRunAt).format('h:mm:ss A')} time.`;
      const hoverMessage = integration.isLocked ? disabledMessage : enabledMessage;

      return (
        <Tooltip
          placement="bottom"
          title={hoverMessage}
        >
          <StatusText>
            {integration.isLocked ? 'Disabled' : 'Enabled'}
          </StatusText>
        </Tooltip>
      );
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, integration: Integration) => (
      <Space size="middle">
        <Tooltip
          placement="left"
          title="Edit integration details"
        >
          <Button
            icon={<i className="fas fa-pen" />}
            onClick={() => { window.location.hash = `#/integrations/edit/${integration.id}`; }}
            type="text"
          />
        </Tooltip>
        <Popconfirm
          placement="left"
          title="Are you sure?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => remove(integration.id)}
        >
          <Tooltip
            placement="left"
            title="Delete this integration"
          >
            <Button icon={<i className="fas fa-trash-alt" />} type="text" />
          </Tooltip>
        </Popconfirm>
      </Space>
    ),
  },
];

interface Props {
  deleteIntegration: typeof deleteIntegration,
  integrations: Integration[],
  fetchIntegrations: typeof fetchIntegrations,
  loading: boolean,
  saving: boolean,
  updateIntegrationStatus: typeof updateIntegrationStatus,
}

const Integrations: React.FC<Props> = ({
  deleteIntegration,
  fetchIntegrations,
  integrations,
  loading,
  updateIntegrationStatus,
}) => {
  const [wizardModalVisible, showWizardModal, hideWizardModal] = useBoolean();

  const {isLoading, loader} = useDataLoader([
    // @ts-ignore
    fetchIntegrations,
  ], true);
  useInitializer(loader);

  const columnConfig = useMemo(
    () => getColumnConfig(updateIntegrationStatus, deleteIntegration),
    [deleteIntegration, updateIntegrationStatus],
  );

  return (
    <PageContainer>
      <Header title="Integrations">
        <Tooltip
          placement="left"
          title={integrations.length > 0 ? 'You can only add one integration' : 'Add integration'}
        >
          <Button
            disabled={integrations.length > 0}
            onClick={showWizardModal}
            type="primary"
          >
            Add Integration
          </Button>
        </Tooltip>
      </Header>
      <p>Set up your integration by following the step-by-step instructions in our <a rel="noopener noreferrer" target="_blank" href="https://support.mapmycustomers.com/hc/en-us/articles/360053375574-HubSpot-Integration-Overview">Knowledge Base</a>.
        Although free for teams fewer than 10, simple integrations will not be activated until confirmed with our team.
        To activate your integration or inquire about more complex syncs, get in touch at <a href="mailto:sales@mapmycustomers.me">sales@mapmycustomers.me</a>
      </p>
      {(isLoading || loading)
        ? <LoadingProgress global={false} />
        : (
          <Table<Integration>
            columns={columnConfig}
            dataSource={integrations}
            pagination={false}
            rowKey="id"
          />
        )
      }
      <WizardModal visible={wizardModalVisible} onHide={hideWizardModal} />
    </PageContainer>
  );
};

const mapStateToProps = (state: RootState) => ({
  integrations: getIntegrations(state),
  loading: getLoading(state),
});

const mapDispatchToProps = {
  deleteIntegration,
  fetchIntegrations,
  updateIntegrationStatus,
};

export default withStore(connect(mapStateToProps, mapDispatchToProps)(Integrations));
