import EntityType from '../react/type/EntityType';
import fileNetworkService from '../network-services/file-network-service';
import {AnyEntityId} from '../react/type/AnyEntity';
import FileType from '../react/type/File';

export const getFileAsUrl = async (file: FileType, entityType: EntityType, entityId: AnyEntityId) => {
  if (file.isPublic && file.publicURI) {
    return file.publicURI;
  }

  const data = await fileNetworkService.getFile(entityType, entityId, file.id);
  return window.URL.createObjectURL(data);
};

export const getFilePreviewAsUrl = async (file: FileType, entityType: EntityType, entityId: AnyEntityId) => {
  if (file.isPublic && file.publicPreviewURI) {
    return file.publicPreviewURI;
  }

  const data = await fileNetworkService.getFilePreview(entityType, entityId, file.id);
  return window.URL.createObjectURL(data);
};

export const downloadFile = async (file: FileType, entityType: EntityType, entityId: AnyEntityId) => {
  if (!file.dataUrl) {
    file.dataUrl = await getFileAsUrl(file, entityType, entityId);
  }
  downloadFileByUrl(file.dataUrl, file.name);
};

export const downloadFileByUrl = (url: string, name?: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.setAttribute('download', name || '');
  const clickEvent = document.createEvent('MouseEvents');
  clickEvent.initEvent('click', false, true);
  a.dispatchEvent(clickEvent);
};
