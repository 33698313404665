import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import AddToRoute from './index';
import Person from '../../type/Person';
import Company from '../../type/Company';
import EntityType from '../../type/EntityType';
import {RootState} from '../../../store/rootReducer';
import {isAddToRouteVisible} from '../../../store/modals/selector';
import modalsSlice from '../../../store/modals';
import withStore from '../../hoc/withStore';

interface Props {
  addToRouteVisible: boolean,
  entities: Array<Company | Person>,
  entityType: EntityType.COMPANY | EntityType.PERSON,
  hideModal: typeof modalsSlice.actions.hideModal,
}

const AngularAddToRoute: React.FC<Props> = ({addToRouteVisible, entities, entityType, hideModal}) => {
  const handleHide = useCallback(() => hideModal('addToRoute'), [hideModal]);
  return (
    addToRouteVisible
      ?
        <AddToRoute
          entities={new Set(entities)}
          entityType={entityType}
          onHide={handleHide}
        />
      : null
  );
};

const mapStateToProps = (state: RootState) => ({
  addToRouteVisible: isAddToRouteVisible(state),
});

const mapDispatchToProps = {
  hideModal: modalsSlice.actions.hideModal,
};

export default withStore(connect(mapStateToProps, mapDispatchToProps)(AngularAddToRoute));
