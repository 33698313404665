import {Dispatch, useCallback} from 'react';
import {EntityFilter, Sorting} from '../components/Grid/ListViewGrid';
import {clearFilter, setFilter, setPage, setSorting} from '../util/reducers/listReducer';

const getListCallbacks = (dispatchParams: Dispatch<any>) => {
  const handleChangeFilter = useCallback(
    (filterUpdate: EntityFilter) => {
      dispatchParams(setFilter(filterUpdate));
    },
    [dispatchParams],
  );

  const handleClearFilter = useCallback(
    (filterNames: string[]) => {
      dispatchParams(clearFilter(filterNames));
    },
    [dispatchParams],
  );

  const handleChangePage = useCallback(
    (page: number) => {
      dispatchParams(setPage(page));
    },
    [dispatchParams],
  );

  const handleChangeSorting = useCallback(
    (sorting: Sorting) => {
      dispatchParams(setSorting(sorting));
    },
    [dispatchParams],
  );
  return {handleChangeFilter, handleClearFilter, handleChangePage, handleChangeSorting};
};

export default getListCallbacks;
