import {call, put, takeEvery} from 'redux-saga/effects';
import {fetchPeopleGroups} from './action';
import people from './index';
import peopleNetworkService from '../../network-services/contacts-network-service';

function* onFetchPeopleGroups() {
  const groups = yield call(
    peopleNetworkService.fetchAllContactGroups.bind(peopleNetworkService),
    undefined,
    1,
    'displayOrder',
    false,
    999999,
  );
  yield put(people.actions.setGroups(groups.data));
}

export default function* peopleSaga() {
  yield takeEvery(fetchPeopleGroups.type, onFetchPeopleGroups);
}
