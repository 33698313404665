import trim from 'lodash/trim';
import get from 'lodash/get';
import helperService from '../../shared-services/helper-service';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';
import template from './edit-entity-form.html';
import GeocodingNetworkService from '../../network-services/geocoding-network-service';
import countryList from '../../common/field-model/country-list.json';
import analyticsService from '../../shared-services/analytics-service';
import getFullName from '../../react/util/formatter/getFullName';

class EditEntityFormController {
  constructor(
    $scope, $window, $location, MainService, CustomerService, $route,
  ) {
    Object.assign(this, {
      MODEL: $window.DataModel,
      MAN: $window.mmcUtils.MAN,
      $window,
      $scope,
      $location,
      localStorage: safeLocalStorage,
      MainService,
      CustomerService,
      $route,
      getEntityDisplayName: MainService.getEntityDisplayName,
    });

    this.$scope.$watch('$ctrl.MODEL.owner', this.updateAccessManagerName.bind(this));
    this.$scope.$watch('$ctrl.MODEL.TeamsService.subTeamUsers', this.updateAccessManagerName.bind(this));
  }

  $onInit() {
    this.entityDetailsKey = `${this.entityType}Details`;
    this.entityTypeSingular = this.getEntityDisplayName(this.entityType);
    this.entityTypeSingularPropercase = this.getEntityDisplayName(this.entityType, false, false);
    this.addressFields = ['address', 'city', 'state', 'country', 'zip'];

    this.addressSectionClass = this.entityType === 'accounts' ? 'col-md-6' : 'col-md-12';

    const extraDetails = {
      color: {options: safeLocalStorage.currentUser.organization.plan.colors},
      name: {required: () => true},
      country: {required: () => !!this.entities.addressEdit},
      website: {link: () => (this.entities.websiteEdit ? `http://${this.entities.websiteEdit}` : '')},
      email: {link: () => (helperService.validateEmail(this.entities.emailEdit) ? `mailto:${this.entities.emailEdit}` : '')},
      phone: {link: () => `tel:${this.entities.phoneEdit || ''}`},
    };
    let fieldModel;
    if (this.entityType === 'accounts') {
      fieldModel = 'accountFieldModel';
    } else if (this.entityType === 'contacts') {
      fieldModel = 'contactFieldModel';
    } if (this.entityType === 'leads') {
      fieldModel = 'leadFieldModel';
    }
    this.generalFields = this.MODEL[fieldModel].editFormFields
      .filter((field) => !field.isAddressField && !field.editFormDetails.isLeadDetails)
      .map((field) => {
        const editFormDetails = {...field.editFormDetails, ...extraDetails[field.name]};
        return editFormDetails;
      });
    this.contactFields = this.MODEL[fieldModel].editFormFields
      .filter((field) => field.isAddressField && field.name !== 'address')
      .map((field) => {
        const editFormDetails = {...field.editFormDetails, ...extraDetails[field.name]};
        return editFormDetails;
      });

    this.leadDetailsFields = this.MODEL[fieldModel].editFormFields
      .filter((field) => field.editFormDetails.isLeadDetails)
      .map((field) => {
        const editFormDetails = {...field.editFormDetails, ...extraDetails[field.name]};
        return editFormDetails;
      });

    if (this.entityType === 'accounts') {
      this.topContactFields = [
        {
          label: 'Phone Number',
          model: 'phone',
          type: 'tel',
          id: 'phone',
          name: 'phone',
          columnWidth: '12',
          link: () => `tel:${this.entities.phoneEdit || ''}`,
          linkId: 'phoneBox',
          linkImg: './images/phoneImage.svg',
        },
        {
          label: 'Email Address',
          model: 'email',
          type: 'email',
          id: 'email',
          name: 'email',
          columnWidth: '12',
          link: () => `mailto:${this.entities.emailEdit || ''}`,
          linkId: 'emailBox',
          linkImg: './images/emailImage.svg',
        },
      ];
    }

    this.updateAccessManagerName();
  }

  $onDestroy() {
    this.MODEL.currentAssociatedAccountId = undefined;
    this.MODEL.prevAssociatedAccountId = undefined;
  }

  updateAccessManagerName() {
    const manager = (
      this.MODEL.TeamsService.subTeamUsers.find(({role}) => role && role.key === 'MANAGER')
            || this.MODEL.owner
    );
    this.accessManagerTitle = manager ? manager.role.name.toLowerCase() : 'team owner';
    this.accessManagerUsername = manager ? manager.username : '';
  }

  determineEditRights() {
    if (this.MODEL.show.loader) {
      return true; // pretend we have edit rights while page is loading to prevent showing unnecessary warnings
    }

    if (this.entities.username === safeLocalStorage.currentUser.username ||
      safeLocalStorage.currentUser.role.key !== 'MEMBER' || get(this.entities, 'accessStatus.update')
    ) {
      return true;
    }

    if (get(this.MODEL.TeamsService, `selectedMemberShared${this.entityType}`)) {
      return get(this.MODEL.TeamsService, `selectedMemberShared${this.entityType}`).includes(this.entities);
    }

    return false;
  }

  setDirtyFlag(field) {
    this.MODEL.dirtyEditFlag = true;
    this.errorMessage = null;

    if (field && this.addressFields.includes(field)) {
      this.MODEL.typeOfLocationUpdatedMostRecently = 'address';
    }

    if (this.entityType === 'contacts' && ['firstName', 'lastName'].includes(field)) {
      this.entities.nameEdit = getFullName(this.entities.firstNameEdit, this.entities.lastNameEdit);
    }

    if (field) {
      this.showUpdateMap = this.addressFields.some(addressField => this.editForm[addressField].$dirty);
    }
  }

  attachAccountToContact() {
    if (!this.determineEditRights()) {
      return;
    }
    this.MODEL.AddEditService.editPopupView = 'account';
    this.MainService.populateEditPopupData('account');
  }

  goToAccount(parentAccount) {
    if (!parentAccount) {
      return;
    }
    this.MODEL.prevAssociatedAccountId = undefined;

    const id = this.MODEL.currentAssociatedAccountId;
    this.$location.path(`/accounts/edit/${id}`);
  }

  clearField(event, page, field, edit) {
    event.stopPropagation();

    if (this.entityType === 'contacts') {
      this.MODEL.currentAssociatedAccountId = undefined;

      if (edit) {
        this.entities.accountEdit = '';
        this.entities.account = null;
        this.MODEL.prevContactAccount = $('#accountEditContact').text();
        $('#accountEditContact').text('');
        this.MODEL.contactAccountModified = true;
        this.$window.refreshDom({contactAccountModified: this.MODEL.contactAccountModified});
      } else {
        $('#accountAddContact').text('');
        $('#linkCustAccAdd').show();
        $('#clearCustAccAdd').hide();
      }
    } else if (this.entityType === 'accounts') {
      this.MODEL.currentAssociatedAccountId = undefined;

      if (edit) {
        this.entities.parentAccountEdit = '';
        this.MODEL.prevParent = $('#parentAccountEditAccount').text();
        $('#parentAccountEditAccount').text('');
        $('#clearAccAccEdit').hide();
        this.MODEL.parentAccountModified = true;
        this.$window.refreshDom({parentAccountModified: this.MODEL.parentAccountModified});
      } else {
        $('#parentAccountAddAccount').text('');
        $('#linkAccAccAdd').show();
        $('#clearAccAccAdd').hide();
      }
    }
  }

  async saveEdits(e, flag) {
    const obj = this.entities;
    const crmPage = `${this.entityType}Page`;

    if (e) {
      e.stopPropagation();
    }

    switch (crmPage) {
      case 'accountsPage':
        if (!flag && this.MODEL.prevAssociatedAccountId !== this.MODEL.currentAssociatedAccountId) {
          swal('Uh-oh', 'Please save parent company first', 'error');
          return;
        }
        if (flag) {
          obj.parentAccount = this.MODEL.currentAssociatedAccountId ? {id: this.MODEL.currentAssociatedAccountId} : null;
        }
        break;
      case 'contactsPage':
        if (!flag && this.MODEL.prevAssociatedAccountId !== this.MODEL.currentAssociatedAccountId) {
          swal('Uh-oh', 'Please save a company first', 'error');
          return;
        }
        if (flag) {
          obj.account = this.MODEL.currentAssociatedAccountId ? {id: this.MODEL.currentAssociatedAccountId} : null;
        }
        break;
      case 'leadsPage':
        if (flag) {
          obj.account = this.MODEL.currentAssociatedAccountId ? {id: this.MODEL.currentAssociatedAccountId} : null;
        }
        break;
    }
    if (crmPage !== 'dealsPage' && trim(obj.addressEdit) && !trim(obj.countryEdit)) {
      swal('Uh-oh', 'Please also specify a country', 'error');
      return;
    }
    const updatedObj = await this.CustomerService.saveEdits(crmPage, obj, flag);
    if (!updatedObj || !updatedObj.id) {
      return;
    }

    let entityChanges;

    switch (crmPage) {
      case 'contactsPage':
        this.MODEL.contactAccountModified = false;
        this.MODEL.prevAssociatedAccountId = this.MODEL.currentAssociatedAccountId;
        entityChanges = this.populateData({entity: updatedObj, populateGroups: false});
        break;
      case 'accountsPage':
        this.MODEL.parentAccountModified = false;
        this.MODEL.prevAssociatedAccountId = this.MODEL.currentAssociatedAccountId;
        entityChanges = this.populateData({entity: updatedObj, populateGroups: false});
        break;
    }

    if (entityChanges) {
      analyticsService.entityUpdated(crmPage, {id: updatedObj.id, ...entityChanges});
    }
    this.$scope.$apply();
  }

  attachParentAccount(id) {
    if (!this.determineEditRights()) {
      return;
    }
    this.MODEL.AddEditService.editPopupView = 'parentAccount';
    this.MainService.populateEditPopupData('parentAccount', id);
  }

  cancelEditParent(e) {
    this.MODEL.parentAccountModified = false;

    $('#parentAccountEditAccount').text(this.MODEL.prevParent);
    this.MODEL.currentAssociatedAccountId = this.MODEL.prevAssociatedAccountId;

    if (e) {
      e.stopPropagation();
    }

    if (!this.MODEL.prevParent) {
      $('#clearAccAccEdit').hide();
    } else {
      $('#clearAccAccEdit').show();
    }
  }

  moveToUserLoc() {
    const latlng = new google.maps.LatLng(this.MAN.userPosition || this.MAN.lastResortPosition);

    this.errorMessage = null;

    this.MODEL.editCrmObjectMarker.setPosition(latlng);
    this.MODEL.map.setCenter(latlng);
    this.MODEL.map.setZoom(5);
    this.addEventListenerToPinDrag();
    this.MODEL.typeOfLocationUpdatedMostRecently = 'pin';
    return this.setAddressFields(latlng);
  }

  addEventListenerToPinDrag() {
    google.maps.event.addListener(this.MODEL.editCrmObjectMarker, 'dragend', (event) => {
      this.MODEL.currentCustomerLat = event.latLng.lat();
      this.MODEL.currentCustomerLng = event.latLng.lng();

      this.MODEL.typeOfLocationUpdatedMostRecently = 'pin';
      this.setAddressFields(event.latLng);
    });
  }

  setAddressFields(latlng) {
    // Do not reverse geocode on every pin drag
    this.entities.countryEdit = '';
    this.entities.cityEdit = '';
    this.entities.stateEdit = '';
    this.entities.addressEdit = '';
    this.entities.zipEdit = '';
    this.entities.currentCustomerLat = latlng.lat();
    this.entities.currentCustomerLng = latlng.lng();

    this.$scope.$evalAsync(() => {
      this.entities = this.entities; // eslint-disable-line no-self-assign
    });

    this.$window.refreshDom({dirtyEditFlag: true});
  }

  revertEntityDetails() {
    analyticsService.canceled(this.$route.current.metadata.title);
    let parentAccount;
    if (this.entityType === 'accounts') {
      parentAccount = this.entities.parentAccount;
      this.entities = {...this.prevEntity};
      this.entities.parentAccount = parentAccount;
    } else {
      this.entities = {...this.prevEntity};
      $('#accountEditContact').text(this.entities.accountEdit);
    }

    // mapped
    if (this.entities.currentCustomerLat && this.entities.currentCustomerLng) {
      const latlng = new google.maps.LatLng({lat: this.entities.currentCustomerLat, lng: this.entities.currentCustomerLng});
      this.MODEL.editCrmObjectMarker.setPosition(latlng);
      this.MODEL.map.setCenter(latlng);
    }

    this.MODEL.typeOfLocationUpdatedMostRecently = undefined;
    this.MODEL.dirtyEditFlag = false;
    this.$window.refreshDom({dirtyEditFlag: this.MODEL.dirtyEditFlag});
  }

  async updateMap() {
    const address = ['addressEdit', 'cityEdit', 'stateEdit', 'zipEdit', 'countryEdit']
      .filter(field => !!this.entities[field])
      .map(field => {
        if (field === 'countryEdit') {
          const row = countryList.find(row => row.value === this.entities[field]);
          return row ? row.label : '';
        }
        return this.entities[field];
      })
      .join(', ');

    try {
      const {geoPoint} = await GeocodingNetworkService.geocodeAddress({address});

      this.addLocationMarker({
        color: this.entities.colorEdit,
        latitude: geoPoint.coordinates[1],
        longitude: geoPoint.coordinates[0],
      });

      this.$scope.$evalAsync(() => {
        this.showUpdateMap = false;
        this.MODEL.map.setZoom(15);
      });
    } catch (error) {
      this.$scope.$evalAsync(() => {
        this.showUpdateMap = false;
        this.errorMessage = `Could not find address. Please update ${this.entityTypeSingular} details.`;
      });
    }
  }
}

EditEntityFormController.$inject = [
  '$scope', '$window', '$location', 'MainService', 'CustomerService', '$route',
];

const editEntityForm = {
  bindings: {
    entities: '=',
    entityType: '@',
    prevEntity: '=',
    populateData: '&',
    prevContact: '&',
    cancelEditContactAccount: '&',
    getDirectionsToAddress: '&',
    addLocationMarker: '&',
  },
  controller: 'EditEntityFormController',
  template,
};

export {EditEntityFormController, editEntityForm};
