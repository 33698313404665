import notification from 'antd/es/notification';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';
import template from './create-company-modal.html';
import randomId from '../../react/util/randomId';

const getNewAppUrl = (path, tail) => `${window.__env.baseNewAppUrl}${path}?mmc_token=${safeLocalStorage.accessToken}&mmc_editpane&${tail}`;

class CreateCompanyModalController {
  static modalShown = false;

  constructor($window, $route, MainService) {
    Object.assign(this, {
      MODEL: $window.DataModel,
      MAN: $window.mmcUtils.MAN,
      MainService,
      $route,
      modalId: randomId(),
    });

    window.addEventListener('message', (e) => {
      if (String(e.data).startsWith('closeCreateCompanyModal')) {
        const messageLength = 'closeCreateCompanyModal'.length;
        let company;
        if (e.data.length > messageLength) {
          company = JSON.parse(e.data.slice(messageLength));
        }
        if (CreateCompanyModalController.modalShown) {
          this.closeModal(company);
        }
      }
    });
  }

  closeModal(newCompany) {
    const modalSelector = `#create-modal-${this.modalId}`;
    if ($(modalSelector).length) {
      CreateCompanyModalController.modalShown = false;

      // refresh map if we're on a map view
      if (this.$route.current.hasMap && !this.findLeadsMode) {
        this.MAN.onMapViewChange(this.MAN.globalMap, true);
      }

      if (newCompany) {
        notification.success({message: 'Company added successfully'});
      }

      if (this.findLeadsMode && newCompany) {
        if (!this.MODEL.accounts.find(account => account.id === newCompany.id)) {
          this.MODEL.accounts.push(newCompany);
        }
        this.MAN.nukeMapContent();
        this.MainService.saveNewFoundCustomer();
      }

      this.MODEL.NavBar.showCreateCompanyModal = false;
      this.MODEL.NavBar.findLeadsMode = false;
      this.MODEL.NavBar.referenceCompany = undefined;
      window.refreshDom({
        'NavBar.showCreateCompanyModal': this.MODEL.NavBar.showCreateCompanyModal,
        'NavBar.findLeadsMode': this.MODEL.NavBar.findLeadsMode,
        'NavBar.referenceCompany': this.MODEL.NavBar.referenceCompany,
      });
      this.toggleOverlay(false);
    }
  }

  toggleOverlay(visible) {
    const initialOverlay = $(`#create-modal-${this.modalId} > .iframe-create-modal-overlay`);
    if (initialOverlay.length) {
      initialOverlay.appendTo('body');
    }
    $(`#create-modal-overlay-${this.modalId}`).toggle(visible);
  }

  $onChanges() {
    setTimeout(() => {
      if (CreateCompanyModalController.modalShown) {
        return;
      }
      CreateCompanyModalController.modalShown = true;

      const modalSelector = `#create-modal-${this.modalId}`;
      $(modalSelector).css({display: 'block'});
      let urlTail = '';
      if (this.referenceCompany) {
        // expected keys are:
        // name, address, city, region, countryCode, postalCode, phone, website, lat, lng
        Object.keys(this.referenceCompany).forEach(fieldName => {
          if (this.referenceCompany[fieldName]) {
            urlTail += `${fieldName}=${encodeURIComponent(this.referenceCompany[fieldName])}&`;
          }
        });
      }
      if (this.findLeadsMode) {
        urlTail += 'mmc_leadgen&';
      }
      document.querySelector(`${modalSelector} > iframe`).src = getNewAppUrl('/web1/company/add', urlTail);
      this.toggleOverlay(true);
    }, 0);
  }
}

CreateCompanyModalController.$inject = ['$window', '$route', 'MainService'];

const createCompanyModal = {
  bindings: {
    className: '@',
    findLeadsMode: '<?',
    referenceCompany: '<?',
  },
  controller: 'CreateCompanyModalController',
  template,
};

export {CreateCompanyModalController, createCompanyModal};
