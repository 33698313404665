import get from 'lodash/get';
import template from './entity-routes.html';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';

class EntityRoutesController {
  constructor($route, RoutingService, BaseController) {
    Object.assign(this, {
      MODEL: window.DataModel,
      $route,
      RoutingService,
      removeFromRoute: RoutingService.removeFromRoute,
      addCrmObject: BaseController.addCrmObject,
    });
  }

  $onInit() {
    this.currentUser = safeLocalStorage.currentUser;
  }

  determineAccessRights(type, entityType) {
    let entity = {};

    if (this.$route.current.id === 'editContactsPage') {
      entity = this.contacts;
      entityType = 'Contacts';
    } else if (this.$route.current.id === 'editAccountsPage') {
      entity = this.accounts;
      entityType = 'Accounts';
    }

    if (entity.username === this.currentUser.username ||
            this.currentUser.role.key !== 'MEMBER'
    ) {
      return true;
    } if (get(entity, `accessStatus.${type}`)) {
      return true;
    } if (get(this.MODEL.TeamsService, `selectedMemberShared${entityType}`)) {
      return get(this.MODEL.TeamsService, `selectedMemberShared${entityType}`).includes(entity);
    }
    return false;
  }

  addToRoute() {
    if (this.$route.current.id === 'editContactsPage') {
      this.RoutingService.addPinToRoute('contact');
    } else if (this.$route.current.id === 'editAccountsPage') {
      this.RoutingService.addPinToRoute('account');
    }
  }
}

EntityRoutesController.$inject = ['$route', 'RoutingService', 'BaseController'];

const entityRoutes = {
  bindings: {
    contacts: '<?',
    accounts: '<?',
    deal: '<?',
    activities: '<?',
  },
  controller: EntityRoutesController,
  template,
};

export {EntityRoutesController, entityRoutes};
