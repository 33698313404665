import get from 'lodash/get';
import safeLocalStorage from './safe-local-storage-service';

class HelperService {
  async executeWithCaptcha(action, callback) {
    return new Promise((resolve, reject) => {
      window.grecaptcha.execute(window.__env.recaptchaSiteKey, {action}).then((token) => {
        Promise.resolve(callback(token)).then(resolve, reject);
      });
    });
  }

  // to validate email id
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  trimLiteral(templateString) {
    return templateString.replace(/\s{2,}/g, ' ');
  }

  showAndLogError(error, message = 'Something went wrong, please try again later') {
    message = this.getErrorMessage(error, message);
    this.logError(error, message);

    if (!window.DataModel.preventSwal) {
      swal('Uh-oh', message, 'error');
    }
  }

  logError(error) {
    const currentUserEmail = get(safeLocalStorage.currentUser, 'username');
    // Allow user to enable error loging by setting window.DataModal.logErrors to true
    if ((window.DataModel && window.DataModel.logErrors) || /mapmycustomers\.me/.test(currentUserEmail)) {
      console.error(error);
    }
  }

  getErrorMessage(error, message = 'Something went wrong, please try again later') {
    if (error) {
      if (Array.isArray(error.validationErrors) && error.validationErrors.length) {
        message = error.validationErrors[0].message;
      } else if (error.message) {
        message = error.message;
      }
    }

    return message;
  }

  debug(message, value) {
    if (window.allowDebug) {
      console.warn(message, value);
    }
  }
}

export default new HelperService();
