const theme = {
  color: {
    primary: '#343b4b',
    secondary: '#3063E4',
    label: '#676767',
    border: '#ddd',
    delete: 'white',
    brightBlue: '#3063E4',
    stateGray: '#3F4659',
    softYellow: '#FFF3BF',
    softRed: '#FF5858',
    lightBlue: '#E5EDFF',
    deepPurple: '#3F4659',
    brightYellow: '#FFDB43',
    green: '#24B275',
    deleteBg: 'rgba(255, 100, 100, 0.8)',
  },
  font: {
    family: "'Roboto', sans-serif",
    base: '12px',
    h1: '20px',
  },
  gutter: '2em',
  screenSizes: {
    wideDesktop: '1530px',
    desktop: '1340px',
    tablet: '920px',
  },
  transition: '300ms ease-in-out',
};

export default theme;
