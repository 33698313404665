import React from 'react';
import {useTranslation} from 'react-i18next';
import {Payment as PaymentType} from '../../../type/plans/Addon';

interface Props {
  payment?: PaymentType,
}

const Payment: React.FC<Props> = ({payment}) => {
  const {t} = useTranslation(['billing']);

  if (!payment) {
    return null;
  }


  return (
    payment.priceDescription
      ? (<div>{t(payment.priceDescription)}</div>)
      : (
        <>
          {payment.onboardingPrice && (<div>{t('price', {price: payment.onboardingPrice})}</div>)}
          {payment.onboardingPrice && payment.subscriptionPrice && (<div><i className="fal fa-plus" /></div>)}
          {payment.subscriptionPrice && (
            <div>
              {t(
                'subscription',
                {price: payment.subscriptionPrice, interval: payment.subscriptionInterval},
              )}
            </div>
          )}
        </>
      )
  );
};

export default Payment;
