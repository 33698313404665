/**
 * @typedef CustomFieldDataType
 */
export default Object.freeze({
  DECIMAL: 'decimal',
  INTEGER: 'integer',
  TEXT: 'text',
  DATE: 'date',
  TIME: 'time',
  BOOLEAN: 'boolean',
  MONETARY: 'monetary',
  SINGLE_OPTION: 'single-option',
  MULTI_OPTION: 'multi-option',
  LARGE_TEXT: 'large-text',
  PHONE: 'phone',
  ADDRESS: 'address',
});
