export enum groupComparator {
  ANY = 'any',
  ALL = 'all',
}

export enum gridFilterComparator {
  EQUALS = 'equals',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'notContains',
  NOT_EQUAL = 'notEqual',
  STARTS_WITH = 'startsWith',
  ENDS_WITH = 'endsWith',
  LESS_THAN = 'lessThan',
  LESS_THAN_OR_EQUAL = 'lessThanOrEqual',
  GREATER_THAN = 'greaterThan',
  GREATER_THAN_OR_EQUAL = 'greaterThanOrEqual',
  IN_RANGE = 'inRange',
}

export enum platformComparator {
  EQUALS = '$eq',
  CONTAINS = '$in',
  NOT_CONTAINS = '$nin',
  NOT_EQUAL = '$ne',
  STARTS_WITH = '$sw',
  ENDS_WITH = '$ew',
  LESS_THAN = '$lt',
  LESS_THAN_OR_EQUAL = '$lte',
  GREATER_THAN = '$gt',
  GREATER_THAN_OR_EQUAL = '$gte',
  ANY = '$any',
  ALL = '$all',
}

type AnyComparator = gridFilterComparator | groupComparator;

const filterComparator: Partial<{[key in AnyComparator]: platformComparator}> = Object.freeze({
  [gridFilterComparator.EQUALS]: platformComparator.EQUALS,
  [gridFilterComparator.CONTAINS]: platformComparator.CONTAINS,
  [gridFilterComparator.NOT_CONTAINS]: platformComparator.NOT_CONTAINS,
  [gridFilterComparator.NOT_EQUAL]: platformComparator.NOT_EQUAL,
  [gridFilterComparator.STARTS_WITH]: platformComparator.STARTS_WITH,
  [gridFilterComparator.ENDS_WITH]: platformComparator.ENDS_WITH,
  [gridFilterComparator.LESS_THAN]: platformComparator.LESS_THAN,
  [gridFilterComparator.LESS_THAN_OR_EQUAL]: platformComparator.LESS_THAN_OR_EQUAL,
  [gridFilterComparator.GREATER_THAN]: platformComparator.GREATER_THAN,
  [gridFilterComparator.GREATER_THAN_OR_EQUAL]: platformComparator.GREATER_THAN_OR_EQUAL,
  [groupComparator.ANY]: platformComparator.ANY,
  [groupComparator.ALL]: platformComparator.ALL,
});

type ReverseMap = {[key in platformComparator]: AnyComparator};

const reversing: ReverseMap = Object.keys(filterComparator)
  .reduce<Partial<ReverseMap>>((result, key) => ({...result, [filterComparator[key as AnyComparator]!]: key}), {}) as ReverseMap;

export const reverseFilterComparator = {...reversing};

export default filterComparator;
