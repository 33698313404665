import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import accountsNetworkService from '../../network-services/accounts-network-service';
import contactsNetworkService from '../../network-services/contacts-network-service';
import dealsNetworkService from '../../network-services/deals-network-service';
import template from './entity-input-with-search.html';

EntityInputWithSearchController.$inject = ['$scope', '$location', 'MainService', 'mmcConst'];

function EntityInputWithSearchController($scope, $location, MainService, mmcConst) {
  Object.assign(this, $scope, {
    $scope,
    $location,
    pluralEntityTypeToSingularEntityType: mmcConst.pluralEntityTypeToSingularEntityType,
    getEntityDisplayName: MainService.getEntityDisplayName,
    $onInit,
    doGetFormattedAddress,
    searchEntity,
    navigateToAssignedEntity,
  });

  function $onInit() {
    this.entityTypeSingular = this.pluralEntityTypeToSingularEntityType[this.entityType];
    this.entityDisplayName = this.getEntityDisplayName(this.entityType, false, false);
    this.navigationImageSrc = `./images/${this.entityTypeSingular}Search.svg`;
  }

  function doGetFormattedAddress(result) {
    this.getFormattedAddressForResult({result});
  }

  async function searchEntity(searchText, type) {
    this.text = searchText;

    const typePropercase = capitalize(type);
    const typeLookup = {
      account: {
        service: accountsNetworkService,
        method: 'fetchAllAccounts',
      },
      contact: {
        service: contactsNetworkService,
        method: 'fetchAllContacts',
      },
      deal: {
        service: dealsNetworkService,
        method: 'fetchAllDeals',
      },
    };

    if (this.text && this.text.length > 0) {
      this.activities[`searching${typePropercase}s`] = true;

      const filter = {name: {$sw: this.text}};

      if (this.activities.account) {
        if (type === 'contact') {
          filter.associatedWithAccountId = this.activities.account.id;
        } else if (type === 'deal') {
          filter.accountId = this.activities.account.id;
        }
      }

      const response = await typeLookup[type].service[typeLookup[type].method](false, filter);
      this.activities[`${type}Options`] = response.data;

      if (!this.activities[`${type}Options`].length) {
        this.$scope.$evalAsync(() => {
          this.activities[`searching${typePropercase}s`] = false;
        });
      } else {
        this.$scope.$evalAsync(() => {
          this.activities[`searching${typePropercase}s`] = false;
          this.activities[`${type}Options`] = this.activities[`${type}Options`];
        });
      }

      return response.data;
    }

    return [];
  }

  function navigateToAssignedEntity() {
    const id = get(this.activities, [this.entityTypeSingular, 'id']);
    if (id) {
      window.DataModel.MappingService.singlePinData = {id, entityType: this.entityType};
      window.refreshDom({'MappingService.singlePinData': window.DataModel.MappingService.singlePinData});
    }
  }
}

function entityInputWithSearch() {
  return {
    restrict: 'E',
    scope: {
      text: '=',
      entityType: '@',
      getFormattedAddressForResult: '&',
      onSelectSearchResult: '&',
      activities: '=',
    },
    bindToController: true,
    controller: 'EntityInputWithSearchController',
    controllerAs: '$ctrl',
    template,
  };
}

export {
  EntityInputWithSearchController,
  entityInputWithSearch,
};
