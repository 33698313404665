import moment, {MomentInput} from 'moment';
import browserLocale from 'browser-locale';

/**
 * @param {Date|Moment|*} date
 * @param {string} format {@link http://momentjs.com/docs/#/displaying/format/}
 * @param {*} invalidDateResult result returned when date is neither a date nor a Moment
 * @returns {string|*} formatted date if Date was given or an invalidDateResult value otherwise
 */
export default <T>(date: MomentInput, format: string = 'L', invalidDateResult: T|string = ''): T|string => {
  const parsedDate = moment(date);
  // TODO: set locale globally when we have a LocaleStore
  return parsedDate.isValid() ? parsedDate.locale(browserLocale()).format(format) : invalidDateResult;
};
