import template from './smart-routing-groups-modal.html';
import mapActions from '../../../store/store-helpers';

function SmartRoutingGroupsModalController($window, RoutingService) {
  function $onInit() {
    this.smartRoutingGroupSelected = this.MODEL.RoutingService.smartRoutingGroupSelected;
  }

  mapActions(this, ['RoutingService']);

  function closeSmartGroupsModal() {
    this.RoutingServiceActions.closeSmartGroupsModal();
  }

  function updateSmartRoutingGroupSelected() {
    this.RoutingServiceActions.updateSmartRoutingGroupSelected(this.smartRoutingGroupSelected);
  }

  function showDepartureAndReturnModal() {
    this.RoutingService.showDepartureAndReturnModal();
  }

  Object.assign(this, {
    $window,
    MODEL: $window.DataModel,
    RoutingService,
    closeSmartGroupsModal,
    updateSmartRoutingGroupSelected,
    showDepartureAndReturnModal,
    $onInit,
  });
}

SmartRoutingGroupsModalController.$inject = ['$window', 'RoutingService'];

function smartRoutingGroupsModal() {
  return {
    restrict: 'E',
    bindToController: {},
    scope: {},
    controller: 'SmartRoutingGroupsModalController',
    controllerAs: '$ctrl',
    template,
  };
}

export {SmartRoutingGroupsModalController, smartRoutingGroupsModal};
