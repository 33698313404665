import get from 'lodash/get';
import mapActions from '../../store/store-helpers';
import {getAvatarColors} from '../../store/avatar/selector';
import template from './avatar.html';
import store from '../../store';
import complementFileUrl from '../../react/util/complementFileUrl';

class AvatarController {
  constructor($window, $timeout) {
    Object.assign(this, {
      MODEL: $window.DataModel,
      $timeout,
    });

    mapActions(this, ['avatar']);
  }

  $onChanges({user}) {
    if (user && user.currentValue) {
      const newUser = user.currentValue;

      if (!getAvatarColors(store.getState())[newUser.id]) {
        this.avatarActions.updateAvatarColorForUserId(newUser.id);
      }

      const {fullName = '', username, profilePhoto} = newUser;

      this.initials = fullName ? fullName.split(/\s/).map(word => word[0].toUpperCase()).slice(0, 2).join('') : username.substring(0, 1).toUpperCase();
      this.color = getAvatarColors(store.getState())[newUser.id];
      this.username = fullName || username;
      this.profilePhotoUrl = complementFileUrl(get(profilePhoto, ['publicURI']));
    } else {
      this.initials = 'NA';
      this.color = 'mmc-blue';
      this.username = 'N/A';
      this.profilePhotoUrl = undefined;
    }

    this.avatarId = `avatar-${Math.floor(Math.random() * 100000)}`;
  }
}

AvatarController.$inject = ['$window', '$timeout'];

const avatar = {
  bindings: {
    user: '<',
    click: '&onClick',
  },
  controller: AvatarController,
  template,
};

export {AvatarController, avatar};
