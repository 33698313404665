import CustomFieldType from '../../react/type/CustomField';
import CustomField from './field/custom-field';
import EntityType from '../../react/type/EntityType';
import Field from './field/field';
import {sortFields} from './utils';
import FieldFeature from './field/FieldFeature';
import FieldCategory from '../../react/type/enum/FieldCategory';
import IField from '../../react/type/fieldModel/IField';

/**
 * Field model class
 */
export default class EntityFieldModel {
  readonly entityType: EntityType | undefined;
  readonly _fields: Field[];
  _customFields: CustomField[];
  readonly _nameToFieldMap: Map<string, Field>;
  readonly _integrationNameToFieldMap: Map<string, Field>;

  constructor(entityType: EntityType | undefined, fields: Field[]) {
    this.entityType = entityType;
    this._fields = fields;
    this._customFields = [];
    this._nameToFieldMap = new Map(fields.map(field => [field.name, field]));
    this._integrationNameToFieldMap = new Map(this._nameToFieldMap);
  }

  get fields(): Field[] {
    return this._fields.concat(this._customFields);
  }

  get filterFields(): Field[] {
    // Result doesn't include custom fields yet
    return this._fields.filter(({filter}) => !!filter);
  }

  get sortedFields() {
    return sortFields(this.fields);
  }

  get categorizedFields(): {[key in FieldCategory]: Field[]} {
    const fields = this.fields;
    return {
      [FieldCategory.GENERAL]: fields.filter(({isAddressField, isCustomField}) => !isAddressField && !isCustomField),
      [FieldCategory.ADDRESS]: fields.filter(({isAddressField}) => isAddressField),
      [FieldCategory.CUSTOM]: fields.filter(({isCustomField}) => isCustomField),
    };
  }

  get categorizedIntegrationFields(): {[key in FieldCategory]: Field[]} {
    return {
      [FieldCategory.GENERAL]: this._fields
        .filter(({isAddressField, isCustomField}) => !isAddressField && !isCustomField)
        .filter(({features}) => !features.has(FieldFeature.NON_INTEGRATION)),
      [FieldCategory.ADDRESS]: this._fields
        .filter(({isAddressField}) => isAddressField)
        .filter(({features}) => !features.has(FieldFeature.NON_INTEGRATION)),
      [FieldCategory.CUSTOM]: this._customFields
        .filter(({isCustomField}) => isCustomField)
        .filter(({features}) => !features.has(FieldFeature.NON_INTEGRATION)),
    };
  }

  getByName(name: string): Field | undefined {
    return this._nameToFieldMap.get(name);
  }

  getByIntegrationName(name: string): Field | undefined {
    return this._integrationNameToFieldMap.get(name);
  }

  /**
   * Updates field model with given custom fields
   * @param {{}} fields custom fields as they're returned by the backend
   */
  setCustomFields(fields: CustomFieldType[]) {
    this._customFields.forEach(/** CustomField */ cf => {
      this._nameToFieldMap.delete(cf.name);
      this._integrationNameToFieldMap.delete(cf.customFieldData.crmPropertyKey);
    });
    this._customFields = fields.map(field => {
      const customField = new CustomField(this.entityType!, field);
      this._nameToFieldMap.set(customField.name, customField);
      this._integrationNameToFieldMap.set(customField.customFieldData.crmPropertyKey, customField);
      return customField;
    });
  }

  get editFormFields() {
    return this.fields.filter(field => field.editFormDetails);
  }

  get hasCustomFields(): boolean {
    return !!this._customFields.length;
  }

  hasCustomFieldsWithValues(entity?: object): boolean {
    return this._customFields.some(field => field.hasValue(entity));
  }

  hasAddress(entity?: object): boolean {
    return this._fields.some((field: Field) => field.features.has(FieldFeature.ADDRESS) && field.hasValue(entity));
  }

  getDefaultFieldsForReport(): IField[] {
    return this._fields.filter(field => field.name === 'name');
  }
}
