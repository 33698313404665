import template from './footer-option-view.html';

class FooterOptionViewController {
  constructor($window, $route, MainService) {
    Object.assign(this, {
      MODEL: $window.DataModel,
      $route,
      MainService,
    });

    this.currentRoute = this.$route.current.id;
  }
}

FooterOptionViewController.$inject = ['$window', '$route', 'MainService'];

const footerOptionView = {
  bindings: {
    deleteCheckedRecords: '&',
    toggleBulkEdit: '&',
  },
  controller: FooterOptionViewController,
  template,
};

export {FooterOptionViewController, footerOptionView};
