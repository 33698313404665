import React, {useCallback} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Activity from '../../../type/Activity';
import Avatar from './Avatar';
import EntityType from '../../../type/EntityType';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
`;

const Title = styled.div`
  font-size: 1.1rem;
  font-weight: 500;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
`;

const AssigneeAvatar = styled(Avatar)`
  width: 32px;
  height: 32px;
  margin-left: 16px;
  & .md-avatar-content {
    font-size: 1em;
  }
`;

interface Props {
  activity: Activity,
  goToEntity: (entityType: EntityType, entityId: number) => void,
}

const RecapActivityCard: React.FC<Props> = ({activity, goToEntity}) => {
  const handleClick = useCallback(() => goToEntity(EntityType.ACTIVITY, activity.id), [activity.id]);
  return (
    <Container onClick={handleClick}>
      <Title>{activity.name}</Title>
      <Footer>
        <span>{moment(activity.startAt).format('lll')}</span>
        {activity.assignee && <AssigneeAvatar user={activity.assignee} tooltipPosition="left" />}
      </Footer>
    </Container>
  );
};

export default RecapActivityCard;
