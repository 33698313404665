import React, {useCallback, useMemo} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import ActivityType from '../../../type/Activity';
import Named from '../../../type/base/Named';
import EntityType from '../../../type/EntityType';
import Avatar from './Avatar';
import theme from '../../../style/theme';

const Container = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 16px 12px 16px;
  margin-bottom: 16px;
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
  background-color: white;
  border-radius: 4px;
  border: 1px solid ${theme.color.border};
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.span`
  font-weight: 600;
  font-size: 1rem;
`;

const ActivityDate = styled.div`
  color: ${props => props.color};
`;

const ActivityEntity = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  font-size: 0.9rem;
  
  & > img {
    margin-right: 8px;
  }
`;

const ActivityTitle = styled(FlexRow)`
  margin-bottom: 4px;
`;

const ActivityTag = styled.div`
  background-color: #aeaeae;
  margin-left: 0.75rem;
  display: inline-block;
  border-radius: 4px;
  text-align: center;
  font-size: 0.7rem;
  font-weight: 600;
  letter-spacing: 0;
  padding: 3px 12px;
  margin-bottom: 0;
  text-transform: uppercase;
  color: white;
`;

const ActivityMarkedTag = styled(ActivityTag)`
  background-color: #3ddcc3;
`;

const AssigneeAvatar = styled(Avatar)`
  margin-left: 8px;
  width: 32px;
  height: 32px;
  & .md-avatar-content {
    font-size: 1em;
  }
`;

const Footer = styled(FlexRow)`
  font-size: 0.9rem;
  margin-top: 16px;
  justify-content: space-between;
`;

interface Props {
  activity: ActivityType,
  goToEntity: (entityType: EntityType, entityId: number) => void,
}

const Activity: React.FC<Props> = ({activity, goToEntity}) => {
  const activityColor = useMemo(
    () => ((activity.completed || moment(activity.startAt).isAfter()) ? '#676767' : '#ff7474'),
    [activity.completed, activity.startAt],
  );
  const handleGoToActivity = useCallback(() => goToEntity(EntityType.ACTIVITY, activity.id), [activity.id]);

  return (
    <Container onClick={handleGoToActivity}>
      <ActivityTitle>
        <Name>{activity.name}</Name>
        <ActivityTag>{activity.crmActivityType.name}</ActivityTag>
        {activity.isPublic && <ActivityMarkedTag>Public</ActivityMarkedTag>}
        {!activity.autoGenerated && (activity.completed
          ? <ActivityMarkedTag>Completed</ActivityMarkedTag>
          : <ActivityTag>Incomplete</ActivityTag>
        )}
        {
          // @ts-ignore
          ['account', 'contact', 'deal'].map((internalEntityType) => (activity[internalEntityType]
              ? (
                <ActivityEntity key={internalEntityType}>
                  <img
                    src={`./images/${internalEntityType}Search.svg`}
                    alt={`${internalEntityType} icon`}
                  />
                  {
                    // @ts-ignore
                    (activity[internalEntityType] as Named).name
                  }
                </ActivityEntity>
              )
              : null
          ))}
      </ActivityTitle>
      {activity.note && (
        <div>
          <FontIcon iconClassName="fa fa-sticky-note" /> {activity.note}
        </div>
      )}
      <Footer>
        <FlexRow>
          {activity.assignee ? `Assigned to ${activity.assignee.username}` : 'Not assigned'}
        </FlexRow>
        <ActivityDate color={activityColor}>
          {moment(activity.startAt).format('LLLL')}
        </ActivityDate>
      </Footer>
    </Container>
  );
};

export default Activity;
