export default function LoginNetworkService(
  $http, $rootScope,
) {
  // main utility functions
  const env = window.__env;

  // service to return
  const service = {};

  // need to move these variables in different file
  const contentType = 'application/x-www-form-urlencoded';

  // get access token using password flow
  service.getAccessToken = (username, password) => {
    const url = `${env.BaseURL}oauth/token`;
    const payload = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&grant_type=password&scope=test&client_id=mmc-web-client-id`;
    return $http({
      method: 'POST',
      timeout: $rootScope.apiTimeout.promise,
      url,
      headers: {
        'Content-Type': contentType,
      },
      data: payload,
    })
      .then(data => data.data);
  };

  return service;
}

LoginNetworkService.$inject = ['$http', '$rootScope'];
