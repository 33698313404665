import {useEffect, useRef} from 'react';

const useInterval = (
  callback: Function,
  active: boolean,
  timeout: number,
  runImmediately: boolean = false
) => {
  const timerId = useRef<number | undefined>();

  useEffect(() => {
    if (active) {
      timerId.current = window.setInterval(callback, timeout);
    }
    if (runImmediately) {
      callback();
    }
    return () => {
      if (timerId.current) {
        window.clearInterval(timerId.current);
        timerId.current = undefined;
      }
    };
  }, [active, callback, timeout, runImmediately]);
};

export default useInterval;
