import get from 'lodash/get';
import helperService from '../shared-services/helper-service';

export default function IntegrationsCtrl(
  $scope, $route, $location, MainService, BaseController,
) {
  // extend BaseController methods onto scope
  Object.assign($scope, BaseController);

  $scope.integrationId = get($route.current, ['params', 'integrationId']);
  if ($scope.integrationId) {
    $scope.integrationId = parseInt($scope.integrationId, 10);
  }

  window.integrationChangeUrl = (url) => $location.url(url);

  // get user data
  MainService.startChainReaction()
    .then(() => {
      window.refreshDom({loader: false}, 'show');
    })
    .catch((error) => {
      helperService.logError(error);
    });
}

IntegrationsCtrl.$inject = [
  '$scope', '$route', '$location', 'MainService', 'BaseController',
];
