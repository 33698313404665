import React, {useCallback} from 'react';
import SmallPreviewContainer from './SmallPreviewContainer';
import Person from '../../../../type/Person';
import EntityType from '../../../../type/EntityType';

interface Props {
  goToEntity: (entityType: EntityType, entityId: number) => void,
  person: Pick<Person, 'id' | 'name'>,
}

const PersonPreviewContainer: React.FC<Props> = ({goToEntity, person}) => {
  const handleClick = useCallback(() => goToEntity(EntityType.PERSON, person.id), [person.id]);
  return (
    <SmallPreviewContainer onClick={handleClick}>
      {person.name}
    </SmallPreviewContainer>
  );
};

export default PersonPreviewContainer;
