import get from 'lodash/get';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import mapActions from '../store/store-helpers';
import accountsNetworkService from '../network-services/accounts-network-service';
import SharedNetworkService from '../network-services/shared-network-service';
import userNetworkService from '../network-services/user-network-service';
import teamNetworkService from '../network-services/team-network-service';
import helperService from '../shared-services/helper-service';
import safeLocalStorage from '../shared-services/safe-local-storage-service';

const getNewAppUrl = (path) => `${window.__env.baseNewAppUrl}${path}?mmc_token=${safeLocalStorage.accessToken}&mmc_editpane`;

export default function DashboardCtrl(
  $scope, $rootScope, $route, MainService, BaseController,
  TeamService, CrmActivitiesService, ActivityService,
  mmcConst, DashboardNetworkService, SettingsService,
  TerrNetworkService, CrmActivityTypesNetworkService,
) {
  // init global utility functions
  const UTILS = window.mmcUtils;
  const MODEL = window.DataModel;

  // set localStorage equal to $scope to get html-binding
  $scope.localStorage = safeLocalStorage;

  // bind data model to scope for access in views
  $scope.data = MODEL;
  $scope.activityTables = [];

  // bind current route id to scope for access in views
  $scope.currentRoute = $route.current.id;

  $scope.newMembers = {
    users: [],
  };

  $scope.activities = {
    showList: true,
    showCal: false,
    activityTypeId: '',
    currentActivityPeriod: 'days',
  };

  $scope.accessRights = mmcConst.accessRights;
  $scope.showAccessRights = false;

  // extend BaseController methods onto scope
  Object.assign($scope, BaseController);

  mapActions($scope, ['modals']);

  $scope.username = safeLocalStorage.currentUser.username;
  $scope.displayName = safeLocalStorage.currentUser.fullName || safeLocalStorage.currentUser.username;

  MainService.startChainReaction();

  if ($scope.currentRoute === 'dashboardPage') {

    $scope.userNetworkService = userNetworkService;
    $scope.teamNetworkService = teamNetworkService;

    window.refreshDom({'show.loader': false});
    return;
  }

  $scope.showViewByUser = UTILS.setViewByUserBasedOnRole(safeLocalStorage);

  const organization = get(safeLocalStorage, 'currentUser.organization');

  if (organization) {
    CrmActivityTypesNetworkService.getTypes(organization.id)
      .then(({data}) => {
        $scope.activityTypes = [{
          id: '',
          name: 'All Types',
        }].concat(data);
      })
      .catch((error) => {
        helperService.showAndLogError(error);
      });
  }

  const initPage = async (userView) => {
    MODEL.show.loader = true;
    $scope.currentUser = safeLocalStorage.currentUser;

    await TeamService.getOrgDetails();
    window.refreshDom({teamStructure: MODEL.TeamsService.teamStructure}, 'TeamsService');

    if ($scope.currentRoute === 'activityPage') {
      await Promise.all([
        $scope.fetchActivities(),
        $scope.fetchPlatformActivities(userView, 100),
      ]);
    }
    window.refreshDom({loader: false}, 'show');
    //
    // const lastWebProductTourSeenAtSetting = SettingsService.getUserSetting('lastWebProductTourSeenAt', null);
    // if (!lastWebProductTourSeenAtSetting || (new Date(lastWebProductTourSeenAtSetting)).getTime() === 0) {
    //   MODEL.showTourPopFn(true);
    // }
  };

  $scope.validEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  $scope.onSelectBulkEmail = async (email) => {
    if ($scope.newMembers.users.length > MODEL.TeamsService.usersLimitLeft) {
      showReachedNumberLicensesModal();
      return;
    }

    if (!$scope.validEmail(email)) {
      $scope.$applyAsync(() => {
        $scope.newMembers.users.pop();
      });
      $('#bulk-member-modal').hide();
      await swal('Invalid Email...', 'Please enter a valid email address.', 'error');
      $('#bulk-member-modal').show();
      $('#bulkNewTeamMemberEmails').val(email);
    }
  };

  $scope.selectedSubTeam = (selectedOpt) => {
    MODEL.TeamsService.createNewSubTeam = selectedOpt === 'Add New Team';
  };

  $scope.fetchAccounts = async user => {
    const filters = {};
    if (user) {
      filters.viewAs = user;
    }
    const data = await accountsNetworkService.fetchAllAccounts(false, filters);
    MODEL.accountsCount = data.total;
  };

  $scope.fetchCrmActivitiesSummary = async function (user) {
    MODEL.currentTime = new Date().getTime();
    const filters = {};
    if (user) {
      filters.viewAs = user;
    }
    let completedDate = moment().toISOString();
    switch ($scope.activities.currentActivityPeriod) {
      case 'days':
        completedDate = moment().subtract(7, 'd').toISOString();
        break;
      case 'weeks':
        completedDate = moment().subtract(4, 'week').toISOString();
        break;
      case 'months':
        completedDate = moment().subtract(6, 'months').toISOString();
        break;
    }

    filters.completedAt = {$gt: completedDate};
    MODEL.crmActivitiesSummary = (await DashboardNetworkService.fetchCrmActivitiesSummary(filters)).data;
    window.refreshDom({crmActivitiesSummary: MODEL.crmActivitiesSummary});
  };

  $scope.changeCrmActivitySummaryView = (user) => {
    $scope.fetchCrmActivitiesSummary(user);
  };

  $scope.fetchCrmActivities = async function (user) {
    MODEL.currentTime = new Date().getTime();
    const filters = {};
    if (user) {
      filters.viewAs = user;
    }
    if ($scope.activities.activityTypeId) {
      filters.crmActivityTypeId = $scope.activities.activityTypeId;
    }
    filters.status = 'upcoming';
    await CrmActivitiesService.fetchCrmActivitiesForDashboard(filters);
  };

  $scope.fetchActivities = async () => {
    await ActivityService.fetchActivities();
    $scope.activityTables = uniq(MODEL.activities.map(({table}) => table))
      .map(value => ({value, label: $scope.getEntityDisplayName(value, true, false)}));
  };

  $scope.fetchPlatformActivities = async (user, limit) => {
    const filters = {};
    if (user) {
      filters.viewAs = user;
    }
    Object.assign(filters, ActivityService.getActivityIdFilter(MODEL.currentActivityView));
    await ActivityService.fetchPlatformActivities(filters, limit);
  };

  $scope.showCrmActivitiesOptions = async function (status) {
    // check exact format of the date-time to be sent
    const monthStart = moment().startOf('month').format('YYYY-MM-DD hh:mm');
    const monthEnd = moment().endOf('month').format('YYYY-MM-DD hh:mm');
    const filters = {
      startAt: {
        $gte: monthStart,
        $lte: monthEnd,
      },
      status,
    };
    if ($scope.activities.activityTypeId) {
      filters.crmActivityTypeId = $scope.activities.activityTypeId;
    }
    await CrmActivitiesService.fetchCrmActivities(filters);
  };

  // open selected option from table
  $scope.openSelectedOption = async (selectedOption, username) => {
    $scope.showAccessRights = true;
    const selectedMember = MODEL.TeamsService.subTeamUsers.find(teamMember => teamMember.username === username);
    MODEL.TeamsService.selectedMember = selectedMember.id;
    MODEL.TeamsService.selectedMemberRoleId = selectedMember.role.id;
    MODEL.TeamsService.selectedMemberName = username;

    if (selectedOption === 'accessRights') {
      MODEL.show.loader = true;
      await TeamService.refreshAccessRightsForSelectedMember();
      TeamService.openSelectedOption(selectedOption, username);
      $scope.showAccessRightsOption('accounts');
      MODEL.show.loader = false;
    } else if (selectedOption === 'sharePinOption') {
      $scope.showShareOption('accounts');
      TeamService.openSelectedOption(selectedOption, username);
    } else if (selectedOption === 'messageOption') {
      $scope.showAccessRights = false;
      swal("You're ahead of the game...", 'This feature is coming soon.', 'success');
    } else {
      TeamService.openSelectedOption(selectedOption, username);
      $scope.showAccessRightsOption('accounts');
    }
  };

  // functions for different screens
  $scope.checkTour = () => {
    const state = hopscotch.getState(); // check for existing tour state
    // check last tour step
    const stepNum = state ? parseInt(state.split(':')[1], 10) : 0;
    hopscotch.startTour($scope.data.tourData, stepNum);
    $scope.data.productTour.showDemo(true);
    $rootScope.showTourOverlay = true;
    $('.hopscotch-bubble').show();
  };

  // close selected option
  $scope.closeSidebar = () => {
    $scope.showAccessRights = false;
  };

  // show employee breadcrumbs
  $scope.showTracks = (username) => {
    const teamUser = $scope.getTeamMembers().find(user => user.username === username);
    if (!teamUser) {
      return;
    }

    $scope.showAccessRights = true; // show sidebar
    const startDate = new Date($('#datetimepicker1').find('input').val());
    const endDate = new Date($('#datetimepicker2').find('input').val());

    MODEL.TeamsService.unselectMember();
    MODEL.TeamsService.selectedMemberName = username;
    TeamService.openSelectedOption('userTracks', username);
    TeamService.showTracks(username, teamUser.id, startDate, endDate);
  };

  // send push to team member
  $scope.sendIndPush = function () {
    TeamService.sendTeamPush(MODEL.TeamsService.selectedMember);
  };

  // toggle shared pins
  $scope.toggleSharedPins = (group) => {
    TeamService.toggleSharedPins(group);
  };

  // toggle shared route
  $scope.toggleSharedRoute = (routeName) => {
    TeamService.toggleSharedRoute(routeName);
  };

  $scope.saveAccessRights = (type, accessRightKey) => {
    TeamService.saveAccessRights(type, accessRightKey);
  };

  // bulk add team members
  $scope.bulkAddMembers = function (isManager) {
    TeamService.bulkAddMembers(isManager);
  };

  // get won stage name
  $scope.getWonName = function () {
    return MODEL.wonName;
  };

  // select user view
  $scope.selectUserView = userView => {
    MODEL.currentLeadsView = userView;
    initPage(userView);
  };

  $scope.deleteOwner = async (username) => {
    try {
      await swal({
        title: 'Delete Owner',
        html: `
<div style="text-align: left; font-size: 16px; line-height: 24px;">
  Before deleting this user, <b>it is recommended to change ownership</b> 
  of their records. To do so:<br/>
  &nbsp; 1) Go to the <a href="${getNewAppUrl('/map')}" target="_blank">Map <i class="fa fa-external-link "></i></a> <br/> 
  &nbsp; 2) Apply the Universal Filter: Owner is any of [user you’d like to delete]<br/> 
  &nbsp; 3) Lasso records<br/> &nbsp; 
  4) Click “Bulk Edit” > Select “Owner” > Select new user from dropdown<br/> 
  <br/>
  <b>If you opt to not change ownership, any records will remain owned by the deleted user and will need to be updated manually or via Import.</b>
</div>`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Delete User',
        cancelButtonText: 'Cancel',
        input: 'checkbox',
        inputValue: 0,
        inputPlaceholder:
          'Check here to confirm you want to delete the user',
        inputValidator: (result) => new Promise((resolve, reject) => {
          if (result) {
            resolve();
          } else {
            reject(new Error('Please confirm deletion'));
          }
        }),
      });
      const {allUsers} = MODEL.TeamsService;

      const user = allUsers.find(u => u.username === username);
      if (!user) {
        helperService.logError(false, 'Something was wrong. User not found');
        return;
      }
      await userNetworkService.deleteUser(safeLocalStorage.currentUser.organization.id, user.id);
      // better to reload page after owner deletion.
      window.location.reload();
    } catch (e) {
      if (['cancel', 'overlay', 'esc'].includes(e)) {
        return;
      }
      helperService.showAndLogError(e, 'Failed to delete user, please try again');
    }
  };

  // change activity options
  $scope.changeCrmActivitiesOptions = async (option, status) => {
    if (option === 'list') {
      $scope.activities.showList = true;
      $scope.activities.showCal = false;
    } else {
      $scope.activities.showList = false;
      $scope.activities.showCal = true;
      MODEL.show.loader = true;
      // TO-DO: inject user based on cached filters
      await $scope.showCrmActivitiesOptions(status);
      window.refreshDom({loader: false}, 'show');
    }
  };

  // get time in 12 hr clock format
  $scope.convertTime = (timeString) => ActivityService.convertTime(timeString);

  // change activty filter
  $scope.changeActivityFilter = async (activityView) => {
    MODEL.show.loader = true;
    const user = MODEL.currentLeadsView;
    MODEL.currentActivityView = activityView;

    const filters = {};
    if (user) {
      filters.viewAs = user;
    }
    Object.assign(filters, ActivityService.getActivityIdFilter(activityView));
    await ActivityService.fetchPlatformActivities(filters, 100);
    window.refreshDom({loader: false}, 'show');
  };

  // open team message swal
  $scope.openTeamMessageSwal = () => {
    swal({
      title: 'Send Team Push',
      text: 'Send a push notification to everyone on your team instantly',
      input: 'text',
      inputPlaceholder: 'Message to send team...',
      showCancelButton: false,
      confirmButtonText: 'Submit',
      showCloseButton: true,
      allowOutsideClick: false,
    })
      .then((teamMessage) => {
        if (teamMessage.length > 0) {
          MODEL.TeamsService.teamMessage = teamMessage;
          TeamService.sendTeamPush();
        } else {
          swal('Oops...', "Message can't be empty", 'error');
        }
      })
      .catch((error) => {
        helperService.logError(error);
      });
  };

  $scope.toggleSharedPins = async (groupId) => {
    MODEL.show.loader = true;
    const sharedGroup = MODEL.sharedGroups.find(sg => sg.group.id === groupId);
    if (sharedGroup) {
      // delete sharedGroup
      await SharedNetworkService.deleteSharedGroup(MODEL.TeamsService.selectedMember, {
        sharedGroups: [{groupId}],
      });
    } else {
      // create sharedGroup
      await SharedNetworkService.postSharedGroup(MODEL.TeamsService.selectedMember, {
        sharedGroups: [{groupId}],
      });
    }
    await $scope.refreshSharedGroups();
    window.refreshDom({loader: false}, 'show');
  };

  $scope.refreshSharedGroups = async () => {
    const groups = await SharedNetworkService.fetchSharedGroups(MODEL.TeamsService.selectedMember);
    MODEL.sharedGroups = groups.data;
  };

  $scope.hasSharedGroup = (groupId) => !!MODEL.sharedGroups.find(sg => sg.group.id === groupId);

  $scope.showShareOption = (option) => {
    if (option !== 'territories' || safeLocalStorage.currentUser?.organization.addOnServices.territories) {
      MODEL.TeamsService.shareOption = option;
      TeamService.highlightShareOption(option);
    } else {
      swal('Oops...', 'Territories are not enabled for your organization. Please reach out to support@mapmycustomers.me for access.', 'error');
    }
  };

  $scope.showAccessRightsOption = (option) => {
    MODEL.TeamsService.accessRightsOption = option;
    TeamService.highlightAccessRightsOption(option);
    window.refreshDom({selectedMemberAccessRights: MODEL.TeamsService.selectedMemberAccessRights}, 'TeamsService');
  };

  const showReachedNumberLicensesModal = async () => {
    const result = await swal({
      title: 'Limit Reached',
      html: `<div>
          You have reached your maximum number of licenses. To add new users, visit the billing page below to either add licenses or delete users.
        </div>`,
      showCancelButton: true,
      confirmButtonText: 'Add More Licenses',
      cancelButtonText: 'Close',
      type: 'error',
    });
    if (result) {
      window.location.href = '#/upgrade';
    }
  };

  // show add member modal
  $scope.showAddMemberModal = async () => {
    if (MODEL.TeamsService.usersLimitLeft > 0) {
      $scope.showNewMemberOptionsModal = true;
      $('#newMemberEmail').val('');
      MODEL.TeamsService.selectedSubTeam = '';
      MODEL.TeamsService.createNewSubTeam = false;
      MODEL.TeamsService.createWithNoTeam = false;
      $('#bulkNewTeamMembers').val('');
      $('#newSubTeamName').val('');
    } else {
      showReachedNumberLicensesModal();
    }
  };

  $scope.showAddTeamModal = async () => {
    let name = await swal({
      title: 'Create Team',
      text: 'Please enter new team name',
      showCancelButton: true,
      confirmButtonText: 'Create Team',
      cancelButtonText: 'Cancel',
      input: 'text',
      inputValue: '',
      inputValidator: (result) => new Promise((resolve, reject) => {
        const name = result.trim();
        if (name.length === 0) {
          reject(new Error('Empty team name'));
        } else if (MODEL.TeamsService.subTeams.find(team => team.name === name)) {
          reject(new Error('The team name should be unique'));
        } else {
          resolve();
        }
      }),
    });
    name = name.trim();
    const newTeam = await teamNetworkService.postTeam(organization.id, {name, domain: organization.domain});
    if (!newTeam.id) {
      helperService.showAndLogError(newTeam, 'Failed to create team');
    } else {
      MODEL.TeamsService.subTeams.push(newTeam);
      MODEL.TeamsService.teamStructure = TeamService.createTeamStructure(MODEL.TeamsService.subTeams, MODEL.TeamsService.subTeamUsers, MODEL.owner);
      window.refreshDom({teamStructure: MODEL.TeamsService.teamStructure}, 'TeamsService');
      swal('Success!', `Team ${name} successfully created`, 'success');
    }
  };

  $scope.isFinishBulkAddingMemberEnabled = () => {
    if (MODEL.TeamsService.createNewSubTeam) {
      return (MODEL.TeamsService.newTeamName || '').trim().length > 0;
    } else if (!MODEL.TeamsService.selectedSubTeam.trim()) {
      return false;
    }
    return true;
  };

  $scope.finishBulkTeamMembers = async function () {
    MODEL.show.loader = true;
    try {
      await TeamService.finishBulkTeamMembers($scope.newMembers.users);
      $scope.newMembers.users = [];
    } catch (e) {
      // is processed in the TeamService.finishBulkTeamMembers
    }
    window.refreshDom({
      'show.loader': false,
      'TeamsService.teamStructure': MODEL.TeamsService.teamStructure,
      'TeamsService.verifiedTeamMembers': MODEL.TeamsService.verifiedTeamMembers,
    });
  };

  $scope.changeSubTeamForTheMember = (member) => {
    TeamService.changeSubTeamForTheMember(member);
  };

  $scope.finishChangeSubTeamForTheMembers = async function () {
    const selectedBenchedMem = $('[name="selectedBenchedMembers"]:checked').map(function () {
      return parseInt(this.value, 10);
    }).get();
    await TeamService.finishChangeSubTeamForTheMembers(selectedBenchedMem);
  };

  $scope.changeMemberType = async function (member, isManager) {
    MODEL.show.loader = true;
    this.modalsActions.hideModal('manageMembersModal');
    await TeamService.changeMemberType(member, isManager);
    window.refreshDom({loader: false}, 'show');
    this.modalsActions.showModal('manageMembersModal');
  };

  $scope.finishManageMembers = () => {
    TeamService.finishManageMembers();
  };

  $scope.changeSubTeamName = (teamId) => {
    MODEL.show.loader = true;
    TeamService.changeSubTeamName(teamId)
      .then(() => {
        window.refreshDom({loader: false}, 'show');
      })
      .catch((error) => {
        helperService.showAndLogError(error);
      });
  };

  $scope.removeTeamMember = async function (member) {
    this.modalsActions.hideModal('manageMembersModal');
    await TeamService.removeTeamMember(member);
    this.modalsActions.showModal('manageMembersModal');
  };


  // toggle shared accounts
  $scope.toggleSharedAccountsAndDeals = (option) => {
    TeamService.toggleSharedAccountsAndDeals(option);
  };

  // create new team from individual account
  $scope.createTeamFromIndividualAccount = function () {
    const teamName = $('#newTeamName').val();
    TeamService.createTeamFromIndividualAccount(teamName);
  };

  // create dashboard page
  $scope.openCreateTeamPage = () => {
    window.location.href = '#/create-team';
  };

  $scope.hasAccessRight = (accessRightKey) => TeamService.getAccessRight(accessRightKey);

  $scope.getActivitiesSummaryIcon = (summary) => mmcConst.activitiesColoredIcons[summary.crmActivityType.name.toLowerCase()] || mmcConst.activitiesColoredIcons.custom;

  $scope.filterBasedOnCrmActivityType = (user) => {
    $scope.fetchCrmActivities(user);
  };

  $scope.toggleComplete = async (activity, user, event) => {
    if (event) {
      event.stopPropagation();
    }
    const response = await CrmActivitiesService.toggleComplete(activity);
    if (response) {
      $scope.fetchCrmActivities(user);
    }
  };

  $scope.closeAddExistingMembersModal = () => {
    $scope.modalsActions.hideModal('addExistingMembersModal');
  };

  $scope.closeManageMembersModal = () => {
    $scope.modalsActions.hideModal('manageMembersModal');
  };

  $scope.getTeamMembers = () => uniqBy(MODEL.TeamsService.subTeamUsers.concat(MODEL.TeamsService.usersWithoutTeam), 'id');

  $scope.sharedOptions = {
    accounts: 'Company groups',
    contacts: 'People groups',
    deals: 'Deal groups',
    accountRoutes: 'Company Routes',
    contactRoutes: 'People Routes',
    territories: 'Territories',
  };

  $scope.accessRightOptions = {
    accounts: 'Companies',
    contacts: 'People',
    deals: 'Deals',
    routes: 'Routes',
    territories: 'Territories',
  };

  initPage();
}

DashboardCtrl.$inject = [
  '$scope', '$rootScope', '$route', 'MainService', 'BaseController',
  'TeamService', 'CrmActivitiesService', 'ActivityService',
  'mmcConst', 'DashboardNetworkService', 'SettingsService',
  'TerrNetworkService', 'CrmActivityTypesNetworkService',
];
