import template from './cadence-modal.html';
import analyticsService from '../../shared-services/analytics-service';

function CadenceModalController($scope, $window, GroupsService) {
  Object.assign(this, $scope, {
    $window,
    GroupsService,
    MODEL: $window.DataModel,
    cancelCadenceEdit,
    closeCadenceModal,
    changeCadence,
    updateCadence,
    group: {...$window.DataModel.GroupsService.currentGroup, cadence: $window.DataModel.GroupsService.currentGroup.cadenceInterval ? true : null},
  });

  function cancelCadenceEdit() {
    analyticsService.canceled('Cadence Edit');
    this.closeCadenceModal();
  }

  function closeCadenceModal() {
    this.MODEL.GroupsService.showCadenceModal = false;
    this.MODEL.GroupsService.currentGroupCadence = '';
  }

  function changeCadence() {
    this.MODEL.GroupsService.currentGroupCadence = this.group.cadence ? this.MODEL.GroupsService.currentGroup.cadenceInterval : '';
  }

  async function updateCadence() {
    const group = this.MODEL.GroupsService.currentGroup;

    group.cadenceInterval = this.MODEL.GroupsService.currentGroupCadence
      ? parseInt(this.MODEL.GroupsService.currentGroupCadence, 10)
      : null;

    this.closeCadenceModal();
    await this.GroupsService.updateGroup(group);
    analyticsService.entityUpdated('Group', {cadenceInterval: group.cadenceInterval});
  }
}

CadenceModalController.$inject = ['$scope', '$window', 'GroupsService'];

function cadenceModal() {
  return {
    restrict: 'E',
    controller: 'CadenceModalController',
    controllerAs: '$ctrl',
    template,
    bindToController: 'true',
  };
}

export {CadenceModalController, cadenceModal};
