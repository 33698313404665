import template from './choose-route-type-modal.html';
import mapActions from '../../../store/store-helpers';

ChooseRouteTypeModalController.$inject = ['$window', 'RoutingService'];

function ChooseRouteTypeModalController($window, RoutingService) {
  Object.assign(this, {
    $window,
    MODEL: $window.DataModel,
    RoutingService,
    closeRouteTypeModal,
    finishSelectRouteType,
    updateInitialRouteType,
    $onInit,
  });

  function $onInit() {
    this.initialRouteType = this.MODEL.RoutingService.initialRouteType;
  }

  mapActions(this, ['RoutingService']);

  function closeRouteTypeModal() {
    this.RoutingServiceActions.closeRouteTypeModal();
  }

  function finishSelectRouteType() {
    this.RoutingService.finishSelectRouteType();
  }

  function updateInitialRouteType() {
    this.RoutingServiceActions.updateInitialRouteType(this.initialRouteType);
  }
}

function chooseRouteTypeModal() {
  return {
    restrict: 'E',
    bindToController: {},
    scope: {},
    controller: 'ChooseRouteTypeModalController',
    controllerAs: '$ctrl',
    template,
  };
}

export {ChooseRouteTypeModalController, chooseRouteTypeModal};
