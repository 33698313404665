import styled, {css} from 'styled-components';

const CardBody = styled.div<{centerAlignContent: boolean}>`
  width: 100%;
  background-color: rgba(40, 40, 40, 0.15);
  display: flex;
  flex: 1;
  ${({centerAlignContent}) => centerAlignContent && css`
    align-items: center;
    justify-items: center;
  `}
`;

export default CardBody;
