const buildFilters = (fieldModel: {[key: string]: any}, filterModel: {[key: string]: any}) =>
  Object.keys(filterModel).reduce(
    (result, fieldName) => {
      const {type, filter, filterTo, dateFrom, dateTo, values} = filterModel[fieldName];
      return fieldModel.getByName(fieldName)!.addToFilter(
        result,
        type,
        filter || dateFrom || values,
        filterTo || dateTo,
      );
    },
    {},
  );
export default buildFilters;
