import mapActions from '../../store/store-helpers';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';
import analyticsService from '../../shared-services/analytics-service';
import template from './crm-activity-type-list.html';

class CrmActivityTypeListController {
  constructor(
    $scope, $window, BaseController,
  ) {
    Object.assign(this, {
      MODEL: $window.DataModel,
      $window,
      localStorage: safeLocalStorage,
      getActivityTypeIcon: BaseController.getActivitiesIcon,
    });

    mapActions(this, ['modals']);
  }

  $onInit() {
    this.isReordering = false;
    this.currentUser = safeLocalStorage.currentUser;
  }

  reorderTypes() {
    this.isReordering = true;
  }

  saveOrder() {
    this.isReordering = false;
    const crmActivityTypes = this.crmActivityTypes.map((activityType, index) => ({...activityType, displayOrder: index + 1}));
    this.saveActivityTypesOrder({crmActivityTypes});
  }

  /**
     * Handles activity type drag end event
     * @param index type that has been moved
     * @param e event
     */
  activityTypeMoved(index, e) {
    if (e) {
      e.stopPropagation();
    }
    this.crmActivityTypes.splice(index, 1);
    analyticsService.clicked(['Activities', 'type', 'reorder'], this.crmActivityTypes);
  }

  handleAddActivityType() {
    this.addActivityType();
  }
}

CrmActivityTypeListController.$inject = [
  '$scope', '$window', 'BaseController',
];

const crmActivityTypeList = {
  bindings: {
    crmActivityTypes: '<',
    addActivityType: '&',
    editActivityType: '&',
    deleteActivityType: '&',
    onActivityTypeClick: '&',
    toggleActivityTypeVisibility: '&',
    saveActivityTypesOrder: '&',
  },
  controller: 'CrmActivityTypeListController',
  template,
};

export {CrmActivityTypeListController, crmActivityTypeList};
