import get from 'lodash/get';
import contactsNetworkService from '../../network-services/contacts-network-service';
import TextSearchNetworkService from '../../network-services/textsearch-network-service';
import mapActions from '../../store/store-helpers';

export default function AllContactsService(MappingService, FetchCRMDataService) {
  // main utility functions
  const MODEL = window.DataModel;

  // service to return
  const service = {};
  mapActions(service, ['modals']);

  // fetch accounts data based on filters and the current caching structure
  service.fetchContacts = async function (map, filters, page, column, ascending, columns, limit) {
    // set default current values if not sent
    page = page || MODEL.cachedState.contacts.page;
    column = column || MODEL.cachedState.contacts.column;
    ascending = ascending || MODEL.cachedState.contacts.ascending;
    MODEL.selectedRecords = {};
    MODEL.selectedEntities = [];
    window.refreshDom({selectedRecords: MODEL.selectedRecords});
    if (map) {
      filters.includeGroups = true;
    }
    const data = await contactsNetworkService.fetchAllContacts(map, filters, page, column, ascending, columns, limit);
    const contactsData = data.data;
    MODEL.contactsCount = data.total;

    if (!data.total && !MODEL.currentLeadsView && !MODEL.FilterService.filtersSelected.length) {
      service.modalsActions.showModal('noContactsModal');
    } else {
      service.modalsActions.resetVisibility('noContactsModal');
    }

    service.parseContacts(contactsData);
    window.refreshDom({contacts: MODEL.contacts});
  };

  service.refreshContactsTotalCount = async () => {
    const response = await contactsNetworkService.getContactsCount();
    window.refreshDom({contactsTotalCount: get(response, 'total', 0)});
  };

  // parse contacts data as per required by the UI
  service.parseContacts = (contactsData) => {
    MODEL.contacts = contactsData.map(record => {
      record.longitude = 0;
      record.latitude = 0;
      record.unmapped = !record.geoPoint || !Array.isArray(record.geoPoint.coordinates);
      if (!record.unmapped) {
        record.longitude = record.geoPoint.coordinates[0];
        record.latitude = record.geoPoint.coordinates[1];
      }
      return record;
    });
  };

  // set the map view
  service.setMapView = async function (nukeMap) {
    const shouldNukeMap = nukeMap !== undefined ? nukeMap : (!MODEL.MappingService.findNewCustomers || (MODEL.MappingService.findNewCustomers && !MODEL.MappingService.showOtherPins));
    MappingService.parseMappingObjects(MODEL.contacts, 'contacts', shouldNukeMap);
  };

  // set the view headers
  service.setViewHeaders = (map) => {
    const title = map ? 'Mapped People' : 'All People';
    const count = MODEL.contactsCount;
    const btn = 'Add Person';
    const showFilter = map;
    const showAdd = false;
    FetchCRMDataService.setCurrentPageVariable(title, count, btn, showFilter, showAdd);
  };

  // ---> search all contacts (customersListArray)
  // this can be called directly from submit on contacts page search bar,
  // or it will execute if there is a q url query param.
  service.searchFromContacts = async function (searchVal) {
    let searchString;
    if (window.getParameterByName('q')) {
      MODEL.searchString = window.getParameterByName('q');
      searchString = MODEL.searchString;
    } else {
      // was used when we had search on map page.
      MODEL.routingSearchString = searchVal;
      searchString = searchVal;
    }
    searchString = searchString.toLowerCase().trim();

    // look for contacts using global search feature
    return (await TextSearchNetworkService.findEntities(searchString, 'contacts')).data;
  };

  // check all in contacts list for delete
  service.checkAllForDelete = function (selectedBoxes) {
    if (MODEL.AllContactsService.allBoxesChecked) {
      // de-select all checkboxes on page
      MODEL.customersListSegment.forEach((item) => {
        selectedBoxes[item.objectId] = false;
      });
      MODEL.AllContactsService.allBoxesChecked = false;
      if (MODEL.AllContactsService.deleteAllCheckboxPages.indexOf(MODEL.currentSkipNumber / 100 + 1) >= 0) {
        const index = MODEL.AllContactsService.deleteAllCheckboxPages.indexOf(MODEL.currentSkipNumber / 100 + 1);
        console.log('index is', index);
        MODEL.AllContactsService.deleteAllCheckboxPages.splice(index, 1);
      }
    } else {
      // select all checkboxes on page
      MODEL.customersListSegment.forEach((item) => {
        selectedBoxes[item.objectId] = true;
      });
      MODEL.AllContactsService.allBoxesChecked = true;
      MODEL.AllContactsService.deleteAllCheckboxPages.push(MODEL.currentSkipNumber / 100 + 1);
    }
  };

  // edit checked customers
  service.editCheckedCustomers = function (selectedBoxes) {
    // grab those contacts checked
    MODEL.AllContactsService.selectedContacts = [];
    MODEL.customersListSegment.forEach((item) => {
      if (selectedBoxes[item.objectId]) {
        MODEL.AllContactsService.selectedContacts.push(item);
      }
    });

    // show selection options modal
    $('#modalbg').show();
    $('#modal').show();
    $('#photoBox').hide();
    $('#queryBox').hide();
    $('#importBox').hide();
    $('#customFieldBox').hide();
    $('#selectColumnsBox').hide();
    $('#modal').removeClass('modal-map').removeClass('modal-import').removeClass('modal-account')
      .removeClass('modal-query');
    $('#lassoSaveBox').show();
  };

  return service;
}

AllContactsService.$inject = ['MappingService', 'FetchCRMDataService'];
