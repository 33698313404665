import avatarFireActions from './avatar/actions';
import modalsFireActions from './modals/actions';
import routingFireActions from './routing-service/actions';

const mapActions = (service: any, moduleArray: ('avatar' | 'modals' | 'RoutingService')[]) => {
  if (moduleArray.includes('avatar')) {
    service.avatarActions = avatarFireActions;
  }
  if (moduleArray.includes('modals')) {
    service.modalsActions = modalsFireActions;
  }
  if (moduleArray.includes('RoutingService')) {
    service.RoutingServiceActions = routingFireActions;
  }
};

export default mapActions;
