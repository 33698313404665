import styled from 'styled-components';

interface Props {
  zebra: boolean | undefined,
}

const GridContainer = styled.div<Props>`
  flex: 1 1 auto; // take all remaining container's space

  ${props => (props.zebra && `
    & > .ag-row-odd:not(.ag-row-selected) {
      background-color: $zebra-background-color;
    }
  `)}
`;

export default GridContainer;
