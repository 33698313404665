import angular from 'angular';
import MappingCtrl from './mapping-controller';
import MappingService from './mapping-service';
import {RecordLimitWarningModalController, recordLimitWarningModal} from './record-limit-warning-modal/record-limit-warning-modal-component';
import {ToolbarController, toolbar} from './toolbar/toolbar-component';
import LeadGenService from './lead-gen-service';

import RoutingModule from './routing/routing-module';
import TerritoriesModule from './territories/territories-module';

export default angular.module('mapmycustomersApp.mapping', [
  RoutingModule, TerritoriesModule,
])
  .controller('MappingCtrl', MappingCtrl)
  .factory('MappingService', MappingService)
  .controller('RecordLimitWarningModalController', RecordLimitWarningModalController)
  .component('recordLimitWarningModal', recordLimitWarningModal)
  .controller('ToolbarController', ToolbarController)
  .component('toolbar', toolbar)
  .service('LeadGenService', LeadGenService)
  .name;
