import TextSearchResult from '../../../../../type/TextSearchResult';
import EntityType from '../../../../../type/EntityType';
import safeLocalStorage from '../../../../../../shared-services/safe-local-storage-service';

const getNewAppUrl = (path: string): string => `${window.__env.baseNewAppUrl}${path}?mmc_token=${safeLocalStorage.accessToken}`;

const getSearchResultUrl = (
  {entity, entityId}: TextSearchResult,
) => {
  let path = '';

  switch (entity) {
    case EntityType.PEOPLE_ROUTE:
      path = `#/contacts/routing/edit/${entityId}`;
      break;
    case EntityType.COMPANY_ROUTE:
      path = `#/accounts/routing/edit/${entityId}`;
      break;
    case EntityType.TERRITORY:
      path = getNewAppUrl(`/map/territories/${entityId}`);
      break;
    case EntityType.ACTIVITY:
      path = `#/activities/edit/${entityId}`;
      break;
    case EntityType.COMPANY_GROUP:
      path = getNewAppUrl(`/map/groups/accounts/${entityId}`);
      break;
    case EntityType.PEOPLE_GROUP:
      path = getNewAppUrl(`/map/groups/contacts/${entityId}`);
      break;
    case EntityType.DEAL_GROUP:
      path = getNewAppUrl(`/map/groups/deals/${entityId}`);
      break;
    default:
      path = `#/${entity}/edit/${entityId}`;
      break;
  }

  return path || '#';
};

export default getSearchResultUrl;
