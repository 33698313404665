import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {Col, Collapse, Row} from 'antd';
import IStep from './IStep';
import ButtonBar from './ButtonBar';
import entityDisplayName from '../../../../util/formatter/entityDisplayName';
import FieldMappingTable from '../FieldMappingTable';
import BlockSpace from '../../../../components/Layout/BlockSpace';
import Integration, {SupportedEntityType} from '../../../../type/integration/Integration';
import {RootState} from '../../../../../store/rootReducer';
import {getIntegrationFields, getLoadingFields} from '../../../../../store/integrations';
import IntegrationField from '../../../../type/integration/IntegrationField';
import fieldModelByEntityType from '../../util/fieldModelByEntityType';
import {initializeMappingStep} from '../../../../../store/integrations/actions';
import useInitializer from '../../../../hook/useInitializer';
import StepDataLoadingProgress from './StepDataLoadingProgress';

interface Props extends IStep {
  initializeMappingStep: typeof initializeMappingStep,
  integrationFields: {[key in SupportedEntityType]: IntegrationField[]},
  loading: boolean,
  serviceName: string,
  syncOptions: Integration['syncOptions'],
}

const Step2: React.FC<Props> = ({
  className,
  initializeMappingStep,
  integrationFields,
  loading,
  syncOptions,
  onNextClick,
  onPreviousClick,
  serviceName,
  style,
}) => {
  useInitializer(initializeMappingStep);

  const syncedEntityTypes = useMemo(
    () => (Object.keys(syncOptions) as SupportedEntityType[])
      .filter(entityType => syncOptions[entityType].incoming || syncOptions[entityType].outgoing)
      .sort(),
    [syncOptions],
  );

  if (loading) {
    return <StepDataLoadingProgress />;
  }

  return (
    <Row className={className} style={style}>
      <Col span={24}>
        <BlockSpace direction="vertical" size="large">
          <Collapse
            accordion
            defaultActiveKey={syncedEntityTypes.length ? syncedEntityTypes[0] : undefined}
          >
            {syncedEntityTypes.map(entityType => (
              <Collapse.Panel header={entityDisplayName(entityType)} key={entityType}>
                <FieldMappingTable
                  dataset={integrationFields[entityType]!}
                  entityType={entityType}
                  fieldModel={fieldModelByEntityType[entityType]!}
                  serviceName={serviceName}
                />
              </Collapse.Panel>
            ))}
          </Collapse>

          <ButtonBar
            nextButtonTitle="Save Changes"
            nextType="primary"
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
          />
        </BlockSpace>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: RootState) => ({
  integrationFields: getIntegrationFields(state),
  loading: getLoadingFields(state),
});

const mapDispatchToProps = {
  initializeMappingStep,
};

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
