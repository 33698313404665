import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../rootReducer';
import {ValueOf} from '../../react/util/ts';
import {Actions} from './action';
import Group from '../../react/type/Group';

export type PeopleState = {
  groups: Group[],
};

const initialState: PeopleState = {
  groups: [],
};

const people = createSlice({
  name: 'people',
  initialState,
  reducers: {
    setGroups: (state, action: PayloadAction<Group[]>) => {
      state.groups = action.payload;
    },
  },
});

const peopleState = (state: RootState) => state.people;

export const getPeopleGroups = createSelector(peopleState, ({groups}) => groups);

export type PeopleActions =
  | ReturnType<ValueOf<typeof people.actions>>
  | Actions;

export default people;
