import React, {useCallback, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Modal} from 'antd';
import EntityType from '../../type/EntityType';
import SelectColor from '../SelectColor/SelectColor';
import addEditNetworkService from '../../../network-services/add-edit-network-service';
import accountsNetworkService from '../../../network-services/accounts-network-service';
import contactsNetworkService from '../../../network-services/contacts-network-service';
import dealsNetworkService from '../../../network-services/deals-network-service';
import {AnyEntity} from '../../type/AnyEntity';
import useSet from '../../hook/useSet';
import Group from '../../type/Group';
import Checkbox from '../../components/Checkbox/Checkbox';
import {RootState} from '../../../store/rootReducer';
import {getCompanyGroups} from '../../../store/company';
import withStore from '../../hoc/withStore';
import {getPeopleGroups} from '../../../store/people';
import {getLeadGroups} from '../../../store/lead';

type CompanyPersonOrDeal = EntityType.COMPANY | EntityType.PERSON | EntityType.DEAL;

const entityUpdaters: {[key in CompanyPersonOrDeal]: any} = {
  [EntityType.COMPANY]: accountsNetworkService.updateAccounts,
  [EntityType.PERSON]: contactsNetworkService.updateContacts,
  [EntityType.DEAL]: dealsNetworkService.updateDeals,
};

const Groups = styled.div`
  height: 150px;
  overflow-y: auto;
`;

interface Props {
  onHide: () => void,
  visible: boolean,
  entityType: CompanyPersonOrDeal,
  entities: Set<AnyEntity>,
  groups: {[key: EntityType]: Group[]},
}

const BulkEntityEditModal: React.FC<Props> = ({onHide, visible, entityType, entities, groups}) => {
  const {t} = useTranslation(['translation', 'entity-common']);
  const [groupIds,, toggleGroupId] = useSet<number>();
  const [color, setColor] = useState<string | undefined>(undefined);
  const groupList: Group[] = useMemo(
    () => groups[entityType],
    [entityType],
  );

  const saveEdits = useCallback(
    () => {
      (async () => {
        const entityArray: AnyEntity[] = Array.from(entities);
        await Promise.all([
          ...Array.from(groupIds, groupId => addEditNetworkService.addToGroup(groupId, entityType, entityArray.map((entity: AnyEntity) => entity.id))),
          ...[color ? entityUpdaters[entityType](entityArray.map((entity: AnyEntity) => ({id: entity.id, color}))) : []],
        ]);
        onHide();
      })();
    },
    [groupIds, color, entityType, entities, onHide],
  );

  return (
    <Modal
      visible={visible}
      onOk={saveEdits}
      okText={t('Save')}
      onCancel={onHide}
      cancelText={t('Cancel')}
    >
      <h4>{t('setGroups')}</h4>
      <Groups>
        {groupList.map((group: Group) => (
          <div key={group.id}>
            <Checkbox
              id={`bulk-edit-group-${group.id}`}
              name={`bulk-edit-group-${group.id}`}
              onChange={(checked) => toggleGroupId(group.id, checked)}
            >
              {group.name}
            </Checkbox>
          </div>
        ))}
      </Groups>
      <h4>{t('setColor')}</h4>
      <SelectColor onChange={setColor} />
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  groups: {
    [EntityType.COMPANY]: getCompanyGroups(state),
    [EntityType.PERSON]: getPeopleGroups(state),
    [EntityType.LEAD]: getLeadGroups(state),
  },
});

const mapDispatchToProps = {
};

export default withStore(connect(mapStateToProps, mapDispatchToProps)(BulkEntityEditModal));
