import {Calendar} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import mapActions from '../store/store-helpers';
import analyticsService from '../shared-services/analytics-service';
import activitiesNetworkService from '../network-services/crm-activities-network-service';

export default class CrmActivitiesService {
  constructor(
    $window, MappingService, FetchCRMDataService,
    CacheService, NylasNetworkService,
  ) {
    Object.assign(this, {
      $window,
      MODEL: $window.DataModel,
      MappingService,
      FetchCRMDataService,
      CacheService,
      NylasNetworkService,
    });
    mapActions(this, ['modals']);
  }

  async setViewBasedOnType(map, filters, page, column, ascending) {
    let response = null;

    if (map) {
      response = await this.fetchCrmActivitiesForMap(filters);
      this.setCrmActivitiesMapView();
    } else {
      response = await this.fetchCrmActivities(filters, page, column, ascending);
      this.setCrmActivitiesListView();

      if (window.isOnPage('calendar')) {
        this.updateCalendarEvents();
      }
    }

    return response;
  }

  async refreshCrmActivitiesTotalCount() {
    this.$window.refreshDom({crmActivitiesTotalCount: await activitiesNetworkService.fetchCrmActivityCount() || 0});
  }

  // fetch activities for list
  async fetchCrmActivities(incomingFilter, page, column, ascending, noLimit = false) {
    page = page || this.MODEL.cachedState.crmActivities.page;
    column = column || this.MODEL.cachedState.crmActivities.column;
    let filters = {...incomingFilter};
    filters.crmObjectType = this.MODEL.cachedState.crmActivities.crmObjectType;
    if (!filters.crmActivityTypeId) {
      if (this.MODEL.cachedState.crmActivities.crmActivityTypeIds.length > 0) {
        filters.crmActivityTypeId = {$in: this.MODEL.cachedState.crmActivities.crmActivityTypeIds};
      } else {
        delete filters.crmActivityTypeId;
      }
    }

    filters = this.getFilterFromStatus(filters.status || this.MODEL.cachedState.crmActivities.status, filters);

    if (this.MODEL.cachedState.crmActivities.user) {
      filters.viewAs = this.MODEL.cachedState.crmActivities.user;
    } else {
      delete filters.viewAs;
    }

    const [data, totalCount] = await Promise.all([
      activitiesNetworkService.fetchAllCrmActivities(false, filters, page, column, ascending, noLimit),
      activitiesNetworkService.fetchCrmActivitiesCount(),
    ]);

    analyticsService.clicked(['Filter'], filters);

    this.MODEL.crmActivitiesCount = data.total;
    this.MODEL.crmActivities = data.data;

    if (!totalCount) {
      this.modalsActions.showModal('noActivitiesModal');
    } else {
      this.modalsActions.resetVisibility('noActivitiesModal');
    }

    return data.data;
  }

  async fetchCrmActivitiesForDashboard(filters, page, column, ascending) {
    filters = this.getFilterFromStatus(filters.status, filters);

    this.MODEL.crmActivitiesCount = 0;
    this.$window.refreshDom({crmActivitiesCount: this.MODEL.crmActivitiesCount});

    let data = await activitiesNetworkService.fetchAllCrmActivities(false, filters, page, column, ascending);
    this.MODEL.crmActivitiesCount = data.total;
    data = data.data;
    this.MODEL.crmActivities = data;
  }

  // setting the activities view
  setCrmActivitiesListView() {
    this.$window.refreshDom({crmActivities: this.MODEL.crmActivities});
    const title = 'Activities';
    const count = this.MODEL.crmActivitiesCount;
    const btn = 'Add Activity';
    const showFilter = true;
    const showAdd = false;
    this.FetchCRMDataService.setCurrentPageVariable(title, count, btn, showFilter, showAdd);
    this.$window.refreshDom({loader: false}, 'show');
  }

  // fetch activities for map
  async fetchCrmActivitiesForMap(filters) {
    const [data, totalCount] = await Promise.all([
      activitiesNetworkService.fetchAllCrmActivities(true, filters),
      activitiesNetworkService.fetchCrmActivitiesCount(),
    ]);
    this.MODEL.crmActivitiesCount = data.total;
    this.MODEL.cachedState.crmActivities.column = 'updatedAt';
    this.MODEL.cachedState.crmActivities.ascending = false;

    if (!totalCount) {
      this.modalsActions.showModal('noActivitiesModal');
    } else {
      this.modalsActions.resetVisibility('noActivitiesModal');
    }
  }

  // set activities map view
  setCrmActivitiesMapView() {
    const title = 'Activity Locations';
    const count = this.MODEL.crmActivitiesCount;
    this.$window.refreshDom({crmActivities: this.MODEL.crmActivities});
    this.MappingService.parseMappingObjects(this.MODEL.crmActivities, 'crmActivities');
    this.FetchCRMDataService.setCurrentPageVariable(title, count, 'Add Activity');
    this.$window.refreshDom({loader: false}, 'show');
  }

  // build status filter
  getFilterFromStatus(status, filters) {
    delete filters.completed;
    delete filters.status;
    delete filters.isPublic;
    switch (status) {
      case 'incomplete':
        filters.completed = false;
        break;
      case 'overdue':
        filters.completed = false;
        filters.startAt = {$lte: moment().toISOString()};
        break;
      case 'completed':
        filters.completed = true;
        break;
      case 'public':
        filters.isPublic = true;
        break;
    }
    return filters;
  }

  // initialize activities calendar
  initFullCalendar() {
    const calendarEl = document.getElementById('fullCalendar');

    if (calendarEl) {
      this.calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
        defaultView: 'dayGridMonth',
        header: {
          left: 'title',
          center: 'dayGridMonth,timeGridWeek,timeGridDay',
          right: 'prev,next today',
        },

        defaultDate: new Date(),
        selectable: true,
        selectHelper: true,
        titleRangeSeparator: ' - ',
        titleFormat: {
          month: 'long', // September 2015
          day: 'numeric', // Tuesday, Sep 8, 2015
        },
        // buttonIcons: false,
        editable: true,
        eventLimit: true, // allow "more" link when too many events

        datesRender: ({view}) => {
          const startDate = view.currentStart.toISOString();
          const endDate = view.currentEnd.toISOString();
          if (this.MODEL.CrmActivitiesService.calendarViewStartDate !== startDate || this.MODEL.CrmActivitiesService.calendarViewEndDate !== endDate) {
            this.MODEL.CrmActivitiesService.calendarViewStartDate = startDate;
            this.MODEL.CrmActivitiesService.calendarViewEndDate = endDate;
            this.changeCalendarView();
          }
        },
      });

      this.calendar.render();
      this.calendar.batchRendering(() => {
        this.addEventsToCalendar();
      });
    }
  }

  updateCalendarEvents() {
    this.calendar.batchRendering(() => {
      this.removeEventsFromCalendar();
      this.addEventsToCalendar();
    });
  }

  async changeCalendarView() {
    this.CacheService.cacheCurrentView(this.MODEL.currentLeadsView, 1);
    const cachedCrm = this.MODEL.cachedState.crmActivities;
    const filters = {};
    if (cachedCrm.user) {
      filters.viewAs = cachedCrm.user;
    }
    filters.startedAt = {
      $gte: this.MODEL.CrmActivitiesService.calendarViewStartDate,
      $lte: this.MODEL.CrmActivitiesService.calendarViewEndDate,
    };
    this.MODEL.show.loader = true;
    await this.fetchCrmActivities(filters, false, false, false, true);
    this.setCrmActivitiesListView();
    this.updateCalendarEvents();
  }

  addEventsToCalendar() {
    // create fill list of activities
    this.calendarRenderEvents = [];

    this.MODEL.crmActivities.forEach((activity) => {
      if (activity.startAt) {
        const startAt = moment(activity.startAt);
        const isFutureActivity = startAt.isAfter();
        let backgroundColor = 'rgba(233, 233, 233, 0.3)';
        let textColor = '#a3a3a3';

        if (!activity.completed) {
          backgroundColor = isFutureActivity ? 'rgba(61, 220, 195, 0.1)' : 'rgba(255, 116, 116, 0.1)';
          textColor = isFutureActivity ? '#3ddcc3' : '#ff7474';
        }

        const url = `#/activities/edit/${activity.id}`;

        const calendarRenderEvent = {
          id: activity.id,
          title: activity.name,
          start: startAt.toISOString(),
          end: startAt.add(activity.duration, 'minutes').toISOString(), // add the duration
          allDay: false,
          url,
          backgroundColor,
          textColor,
        };

        this.calendarRenderEvents.push(calendarRenderEvent);
        this.calendar.addEvent(calendarRenderEvent);
      }
    });

    this.calendar.rerenderEvents();
  }

  removeEventsFromCalendar() {
    if (this.calendarRenderEvents && this.calendarRenderEvents.length) {
      this.calendarRenderEvents.forEach((event) => {
        this.calendar.getEventById(event.id).remove();
      });
    }
  }

  async toggleComplete(activity) {
    this.MODEL.show.loader = true;
    activity.completed = !activity.completed;
    let response = null;

    try {
      response = await activitiesNetworkService.updateCrmActivity(activity);
      analyticsService.entityUpdated('Activity', response);
    } catch (e) {
      const message = Array.isArray(e.validationErrors) && e.validationErrors.length
        ? e.validationErrors[0].message
        : 'Failed to save changes, please try again';
      activity.completed = !activity.completed;
      swal('Uh-Oh!', message, 'error');
    }

    this.$window.refreshDom({loader: false}, 'show');
    return response;
  }

  async processResponse(response) {
    this.MODEL.CrmActivitiesService.nylasInfo = response;
    this.MODEL.CrmActivitiesService.nylasInfo.twoWaySync = this.MODEL.CrmActivitiesService.nylasInfo.twoWaySync ? 1 : 0;
    this.MODEL.CrmActivitiesService.syncedTypes = this.MODEL.CrmActivitiesService.nylasInfo.nylasCrmActivityTypes
      .map(({id}) => id);
    this.$window.refreshDom({'CrmActivitiesService.nylasInfo': this.MODEL.CrmActivitiesService.nylasInfo});
    if (response.calendarId) {
      const calendar = await this.NylasNetworkService.fetchCalendar(response.calendarId);
      this.MODEL.CrmActivitiesService.calendarName = calendar.name;
      this.$window.refreshDom({calendarName: calendar.name}, 'CrmActivitiesService');
    }
  }

  async fetchMyNylas() {
    this.MODEL.CrmActivitiesService.nylasInfo = undefined;
    this.MODEL.CrmActivitiesService.syncedTypes = [];
    this.MODEL.CrmActivitiesService.calendarName = undefined;

    try {
      const response = await this.NylasNetworkService.fetchConnection();
      if (!response.id) {
        return;
      }
      await this.processResponse(response);
    } catch (e) {
      console.error('Failed to fetch Nylas info', e);
    }
  }

  async updateNylasInfo(update) {
    if (!this.MODEL.CrmActivitiesService.nylasInfo) {
      return;
    }

    let payload = {
      id: this.MODEL.CrmActivitiesService.nylasInfo.id,
      token: this.MODEL.CrmActivitiesService.nylasInfo.token,
      twoWaySync: this.MODEL.CrmActivitiesService.nylasInfo.twoWaySync > 0,
      calendarId: this.MODEL.CrmActivitiesService.nylasInfo.calendarId,
      nylasAccountId: this.MODEL.CrmActivitiesService.nylasInfo.nylasAccountId,
      calendarCrmActivityTypeId: this.MODEL.CrmActivitiesService.nylasInfo.calendarCrmActivityType
        ? parseInt(this.MODEL.CrmActivitiesService.nylasInfo.calendarCrmActivityType.id, 10)
        : null,
      nylasCrmActivityTypes: [],
    };
    payload = Object.assign(payload, update);

    try {
      const response = await this.NylasNetworkService.updateConnection(payload);
      await this.processResponse(response);
    } catch (e) {
      swal('Uh-oh', 'Failed to save updates, please try again later', 'error');
      throw e;
    }
  }

  async saveNylasToken(token, nylasAccountId) {
    let attempt = 5;
    while (attempt > 0) {
      try {
        const payload = {
          token,
          twoWaySync: null,
          calendarId: null,
          nylasAccountId,
          calendarCrmActivityTypeId: null,
        };
        // eslint-disable-next-line no-await-in-loop
        await this.NylasNetworkService.createConnection(payload);
        return this.fetchMyNylas();
      } catch (e) {
        attempt--;
      }
    }

    swal('Uh-oh', 'Sorry, we were not able to persist Nylas token. Could you please try again?', 'error');
  }

  async fetchNylasCalendars() {
    try {
      const response = await this.NylasNetworkService.fetchCalendars();
      // eslint-disable-next-line camelcase
      this.$window.refreshDom({calendars: response.filter(({read_only}) => !read_only)}, 'CrmActivitiesService');
    } catch (e) {
      swal('Uh-oh', 'Failed to fetch calendar list, please try again later.', 'error');
      throw e;
    }
  }

  async stopSyncing(nylasId) {
    try {
      await this.NylasNetworkService.deleteConnection(nylasId);
      this.$window.refreshDom({nylasInfo: undefined, syncedTypes: undefined, calendarName: undefined}, 'CrmActivitiesService');
    } catch (e) {
      swal('Uh-oh', 'There were problems revoking syncing, please refresh the page and try again.', 'error');
      throw e;
    }
  }
}

CrmActivitiesService.$inject = [
  '$window', 'MappingService', 'FetchCRMDataService',
  'CacheService', 'NylasNetworkService',
];
