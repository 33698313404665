import notification from 'antd/es/notification';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';
import template from './create-person-modal.html';
import randomId from '../../react/util/randomId';

const getNewAppUrl = (path) => `${window.__env.baseNewAppUrl}${path}?mmc_token=${safeLocalStorage.accessToken}&mmc_editpane`;

class CreatePersonModalController {
  static modalShown = false;

  constructor($window, $route, MainService) {
    Object.assign(this, {
      MODEL: $window.DataModel,
      MAN: $window.mmcUtils.MAN,
      MainService,
      $route,
      modalId: randomId(),
    });

    window.addEventListener('message', (e) => {
      if (String(e.data).startsWith('closeCreatePersonModal')) {
        const messageLength = 'closeCreatePersonModal'.length;
        let person;
        if (e.data.length > messageLength) {
          person = JSON.parse(e.data.slice(messageLength));
        }
        if (CreatePersonModalController.modalShown) {
          this.closeModal(person);
        }
      }
    });
  }

  closeModal(newPerson) {
    const modalSelector = `#create-modal-${this.modalId}`;

    if (newPerson) {
      notification.success({message: 'Person added successfully'});
    }

    if ($(modalSelector).length) {
      CreatePersonModalController.modalShown = false;

      this.MODEL.NavBar.showCreatePersonModal = false;
      window.refreshDom({'NavBar.showCreatePersonModal': this.MODEL.NavBar.showCreatePersonModal});
      this.toggleOverlay(false);

      // refresh map if we're on a map view
      if (this.$route.current.hasMap) {
        this.MAN.onMapViewChange(this.MAN.globalMap, true);
      }
    }
  }

  toggleOverlay(visible) {
    const initialOverlay = $(`#create-modal-${this.modalId} > .iframe-create-modal-overlay`);
    if (initialOverlay.length) {
      initialOverlay.appendTo('body');
    }
    $(`#create-modal-overlay-${this.modalId}`).toggle(visible);
  }

  $onChanges() {
    setTimeout(() => {
      if (CreatePersonModalController.modalShown) {
        return;
      }
      CreatePersonModalController.modalShown = true;

      const modalSelector = `#create-modal-${this.modalId}`;
      $(modalSelector).css({display: 'block'});
      const url = '/web1/people/add/';
      document.querySelector(`${modalSelector} > iframe`).src = getNewAppUrl(url);
      this.toggleOverlay(true);
    }, 0);
  }
}

CreatePersonModalController.$inject = ['$window', '$route', 'MainService'];

const createPersonModal = {
  bindings: {
    className: '@',
  },
  controller: 'CreatePersonModalController',
  template,
};

export {CreatePersonModalController, createPersonModal};
