import template from './territories-map-filter.html';

TerritoriesMapFilterController.$inject = ['$scope', '$window', 'AccountsService', 'TerritoriesService'];

function TerritoriesMapFilterController($scope, $window, AccountsService, TerritoriesService) {
  Object.assign(this, $scope, {
    $window,
    MODEL: $window.DataModel,
    AccountsService,
    TerritoriesService,
    toggleShowingOtherPins,
    toggleShowingTerritories,
  });

  async function toggleShowingOtherPins() {
    this.MODEL.show.loader = true;
    this.MODEL.TerritoriesService.showOtherPins = !this.MODEL.TerritoriesService.showOtherPins;

    const filters = this.buildFilters();

    if (!this.MODEL.TerritoriesService.showOtherPins) {
      filters.territoryIds = this.MODEL.territories
        .filter(({territoryDetail}) => !territoryDetail.hidden)
        .map(({id}) => id);
    }

    await this.AccountsService.fetchAccounts(true, filters);
    this.TerritoriesService.setMapView();
    this.TerritoriesService.setViewHeaders(true);
    this.$window.refreshDom({loader: false}, 'show');
  }

  function toggleShowingTerritories() {
    this.MODEL.TerritoriesService.showTerritories = !this.MODEL.TerritoriesService.showTerritories;
    this.TerritoriesService.updateTerritoriesVisibilityOnMap(this.MODEL.TerritoriesService.showTerritories);
  }
}

function territoriesMapFilter() {
  return {
    restrict: 'E',
    scope: {
      buildFilters: '&',
    },
    bindToController: true,
    controller: 'TerritoriesMapFilterController',
    controllerAs: '$ctrl',
    template,
  };
}

export {TerritoriesMapFilterController, territoriesMapFilter};
