import template from './crm-activity.html';

CrmActivityController.$inject = ['$scope', '$window', 'CrmActivitiesService'];

function CrmActivityController($scope, $window, CrmActivitiesService) {
  Object.assign(this, $scope, {
    $window,
    MODEL: $window.DataModel,
    CrmActivitiesService,
    $onInit,
    goToActivity,
    goToObjectFromActivities,
    getActivitiesColor,
    callToggleComplete,
    updateImgSrc,
  });

  function $onInit() {
    this.dataTag = `activity-${this.crmActivity.name.toLowerCase().replace(/\s/g, '-')}`;
    this.updateImgSrc();
  }

  function goToActivity(activity) {
    if (activity) {
      window.refreshDom({'NavBar.showCreateCompanyModal': false, 'NavBar.showCreateEditActivityModal': true, 'NavBar.showCreateDealModal': false, 'NavBar.showCreatePersonModal': false, 'NavBar.editActivityModalActivityId': activity.id});
    } else {
      window.refreshDom({'NavBar.showCreateCompanyModal': false, 'NavBar.showCreateEditActivityModal': true, 'NavBar.showCreateDealModal': false, 'NavBar.showCreatePersonModal': false, 'NavBar.editActivityModalActivityId': undefined});
    }
  }

  function goToObjectFromActivities(object, type, e) {
    if (e) {
      e.stopPropagation();
    }

    window.DataModel.MappingService.singlePinData = {id: object.id, entityType: type};
    window.refreshDom({'MappingService.singlePinData': window.DataModel.MappingService.singlePinData});
  }

  function getActivitiesColor(activity) {
    if (activity.completed) {
      return {color: '#676767'};
    }
    return {color: moment(activity.startAt).isAfter() ? '#3ddcc3' : '#ff7474'};
  }

  function callToggleComplete($event) {
    if (this.restrictAccess) {
      return;
    }

    const params = {
      activity: this.crmActivity,
      event: $event,
    };

    if (this.source === 'addEdit') {
      this.toggleComplete(Object.assign(params, {entityType: this.entityType}));
    } else if (this.source === 'crmActivitiesList') {
      this.toggleComplete(params);
    } else {
      this.toggleComplete(Object.assign(params, {user: this.MODEL.currentLeadsView}));
    }

    this.updateImgSrc();
  }

  function updateImgSrc() {
    if (this.crmActivity.autoGenerated) {
      this.imgSrc = '';
    } else if (this.crmActivity.completed) {
      this.imgSrc = 'images/registration/success.png';
    } else {
      this.imgSrc = './images/dashboard/check.png';
    }
  }
}

function crmActivity() {
  return {
    restrict: 'E',
    scope: {
      crmActivity: '=',
      toggleComplete: '&',
      getProfileLogo: '&',
      entityType: '=',
      sourceClass: '@',
      source: '@',
      restrictAccess: '=',
    },
    controller: 'CrmActivityController',
    controllerAs: '$ctrl',
    template,
    bindToController: 'true',
  };
}

export {CrmActivityController, crmActivity};
