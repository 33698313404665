import React, {useCallback, useState} from 'react';
import moment, {Moment} from 'moment';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {DatePicker, Dropdown, Menu} from 'antd';
import CardCta from '../DashCard/CardCta';
import DashCard from '../DashCard/DashCard';
import activitiesNetworkService from '../../../../../network-services/crm-activities-network-service';
import useDataLoader from '../../../../hook/useDataLoader';
import useInitializer from '../../../../hook/useInitializer';
import User from '../../../../type/User';
import useBoolean from '../../../../hook/useBoolean';
import SelectUsersDialog from '../../../../components/SelectEntityDialog/SelectUsersDialog';
import userDisplayName from '../../../../util/formatter/userDisplayName';
import defaultNodeIdGetter from '../../../../util/defaultNodeIdGetter';
import ListResponse from '../../../../type/service/ListResponse';
import Activity from '../../../../type/Activity';
import ActivityListItem from '../ActivityListItem';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import LoadingProgress from '../../../../components/LoadingProgress/LoadingProgress';
import MoreButton from '../../../../components/MoreButton/MoreButton';

const ActivityList = styled.div`
  flex: 1;
  background: white;
  padding: 10px;
  overflow: auto;
  height: calc(400px - 72px);
`;

const MenuItem = styled(Menu.Item)`
  padding-left: 36px;
`;

interface Props {
  hasActivities: boolean,
  users: User[],
}

const TodayActivitiesCard: React.FC<Props> = ({hasActivities, users}) => {
  const [showCompleted, setShowCompleted] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<User[]>(users);

  const [date, setDate] = useState<Moment>(moment().startOf('day'));
  const handleChangeDate = useCallback((date) => setDate(date), [setDate]);

  const [usersDialogVisible, showUsersDialog, hideUsersDialog] = useBoolean();

  const getActivities = useCallback(
    () => activitiesNetworkService.fetchAllCrmActivities(
      false,
      {
        startAt: {$gte: moment(date).startOf('day').toISOString(), $lte: moment(date).endOf('day').toISOString()},
        assigneeId: {$in: selectedUsers.map(({id}) => id)},
        completed: showCompleted,
      },
      1,
      'startAt',
      true,
      true,
    ),
    [date, selectedUsers, showCompleted],
  );

  const {isLoading, loader, data} = useDataLoader([getActivities]);
  useInitializer(loader);
  const {t} = useTranslation(['home']);

  const activities = data.length ? (data[0] as ListResponse<Activity>).data : [];
  const hasFilteredActivities = !!activities.length;

  const handleActivityClick = useCallback((activity: Activity) => {
    window.location.href = `#/activities/edit/${activity.id}`;
  }, []);

  return (
    <DashCard
      actions={[
        <DatePicker
          allowClear={false}
          format="ll"
          key="date-picker"
          onChange={handleChangeDate}
          value={date}
        />,
        <Dropdown
          key="menu"
          overlay={
            <Menu>
              <Menu.Item>
                <Checkbox
                  checked={showCompleted}
                  name="home-page-today-activities-card-show-completed"
                  onChange={setShowCompleted}
                >
                  Show completed
                </Checkbox>
              </Menu.Item>
              <Menu.Divider />
              <MenuItem onClick={showUsersDialog}>Select Users...</MenuItem>
            </Menu>
          }
          placement="bottomRight"
        >
          <MoreButton />
        </Dropdown>,
      ]}
      centerAlignContent={isLoading}
      title={t('Today’s Activities')}
      titleIconClassName="far fa-calendar-alt"
    >
      {isLoading && <LoadingProgress global={false} />}
      {!isLoading && !hasFilteredActivities && !hasActivities && (
        <CardCta
          buttonCaption="Add Activity"
          onClick={() => {
            window.refreshDom({'NavBar.showCreateCompanyModal': false, 'NavBar.showCreateEditActivityModal': true, 'NavBar.showCreateDealModal': false, 'NavBar.showCreatePersonModal': false, 'NavBar.editActivityModalActivityId': undefined});
          }}
          text="Add your first activity"
        >
          <a
            href="https://support.mapmycustomers.com/hc/en-us/articles/360050260013-Activities-Overview"
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn more about activities
          </a>
        </CardCta>
      )}
      {!isLoading && !hasFilteredActivities && hasActivities && (
        <CardCta
          buttonCaption="Add Activity"
          onClick={() => {
            window.refreshDom({'NavBar.showCreateCompanyModal': false, 'NavBar.showCreateEditActivityModal': true, 'NavBar.showCreateDealModal': false, 'NavBar.showCreatePersonModal': false, 'NavBar.editActivityModalActivityId': undefined});
          }}
          text="No activities found. Add an activity or change filters."
        >
          <a
            href="https://support.mapmycustomers.com/hc/en-us/articles/360050260013-Activities-Overview"
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn more about activities
          </a>
        </CardCta>
      )}
      {!isLoading && hasFilteredActivities && (
        <ActivityList>
          {activities.map(activity => (
            <ActivityListItem
              activity={activity}
              dateFormat="LT"
              key={activity.id}
              onClick={handleActivityClick}
            />
          ))}
        </ActivityList>
      )}
      <SelectUsersDialog
        entities={users}
        getDisplayName={userDisplayName}
        getKey={defaultNodeIdGetter}
        id="home-page-activities-card-users-dialog"
        onConfirm={setSelectedUsers}
        onHide={hideUsersDialog}
        selectedEntities={selectedUsers}
        title="Select Users"
        visible={usersDialogVisible}
      />
    </DashCard>
  );
};

export default TodayActivitiesCard;
