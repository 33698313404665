import React, {useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {Button, Col, Row} from 'antd';
import PageContainer from './components/PageContainer';
import Header from '../../components/Header/Header';
import LoadingProgress from '../../components/LoadingProgress/LoadingProgress';
import IntegrationService from '../../type/enum/IntegrationService';
import {RootState} from '../../../store/rootReducer';
import {createIntegration, selectService} from '../../../store/integrations/actions';
import {getCurrentIntegration} from '../../../store/integrations';
import Integration from '../../type/integration/Integration';
import withStore from '../../hoc/withStore';
import ServiceButtons from './components/ServiceButtons';

interface Props {
  currentIntegration?: Partial<Integration>,
  createIntegration: typeof createIntegration,
  selectService: typeof selectService,
}

const AddIntegration: React.FC<Props> = ({createIntegration, selectService}) => {
  const params = new Map(new URLSearchParams(window.location.search).entries());

  useEffect(
    () => {
      if (params.size > 0) {
        createIntegration(params);
      }
    },
    [params.size],
  );

  const handleCancelClick = useCallback(() => { window.location.hash = '#/integrations'; }, []);

  const handleChangeService = useCallback(
    (service: IntegrationService | undefined) => {
      if (service) {
        selectService(service);
      }
    },
    [],
  );

  if (params.size > 0) {
    return (
      <PageContainer>
        <LoadingProgress />
      </PageContainer>
    );
  }

  return (
    <PageContainer className="mmc-add-integration">
      <Header title="Add new integration">
        <Button onClick={handleCancelClick}>Cancel</Button>
      </Header>
      <Row>
        <Col xxl={{span: 12, offset: 6}} xl={{span: 18, offset: 3}} lg={{span: 24}}>
          <ServiceButtons onChange={handleChangeService} />
        </Col>
      </Row>
    </PageContainer>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentIntegration: getCurrentIntegration(state),
});

const mapDispatchToProps = {
  createIntegration,
  selectService,
};

export default withStore(connect(mapStateToProps, mapDispatchToProps)(AddIntegration));
