import styled, {css} from 'styled-components';

const PlanName = styled.label<{pointer?: boolean}>`
  font-weight: 700;
  font-size: 16px;
  ${props => props.pointer && css`
    cursor: pointer;
  `}
`;

export default PlanName;
