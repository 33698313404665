import React, {useCallback} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Activity from '../../../type/Activity';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border: 1px solid transparent;
  
  &:nth-child(odd) {
    background-color: #f3fcf9;
  }
  &:hover {
    border: 1px solid #adffe4;
    background-color: #ebfff8;
  }
`;

interface Props {
  activity: Activity,
  dateFormat?: string,
  onClick?: (activity: Activity) => void,
}

const getIcon = (activity: Activity) => {
  if (activity.deal) {
    return <i className="fal fa-funnel-dollar" />;
  }
  if (activity.contact) {
    return <i className="fal fa-users" />;
  }
  return activity.account ? <i className="fal fa-building" /> : null;
};

const ActivityListItem: React.FC<Props> = ({activity, dateFormat = 'lll', onClick}) => {
  const handleClick = useCallback(() => onClick && onClick(activity), [activity, onClick]);
  return (
    <Container onClick={handleClick}>
      <div>{activity.name} {getIcon(activity)}</div>
      <div>{moment(activity.startAt).format(dateFormat)}</div>
    </Container>
  );
};

export default ActivityListItem;
