enum FieldFeature {
  ADDRESS = 'address',
  CUSTOM_FIELD = 'customField',
  SORTABLE = 'sortable',
  FILTERABLE = 'filterable',
  NUMERIC = 'numeric',
  LIST = 'list',
  OBJECT = 'object',
  DATE = 'date',
  TEXT = 'text',
  ALWAYS_VISIBLE = 'alwaysVisible',
  INTEGRATION_ONLY = 'integrationOnly', // a temporary feature
  NON_INTEGRATION = 'nonIntegration',
}

export default FieldFeature;
