import helperService from '../shared-services/helper-service';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import SharedNetworkService from '../network-services/shared-network-service';
import mapActions from '../store/store-helpers';
import MappedCellRenderer, {tooltipValueGetter} from '../common/grid/MappedCellRenderer';

export default function AccountsCtrl(
  $scope, $route, FetchCRMDataService, MainService, BaseController,
  AccountsService, TeamService, CacheService, GroupsService, $timeout,
) {
  // init global utility functions
  const UTILS = window.mmcUtils;
  const MODEL = window.DataModel;

  // extend BaseController methods onto scope
  Object.assign($scope, BaseController);
  mapActions($scope, ['modals']);
  $scope.modalsActions.resetVisibility('noAccountsModal');

  $scope.getFields = () => MODEL.accountFieldModel.fields;

  $scope.LAST_COLUMN = {
    ...$scope.SELECT_COLUMNS_COLUMN_DEF,
    cellRenderer: MappedCellRenderer,
    cellStyle: {padding: '4px 0 0', textAlign: 'center'},
    tooltipValueGetter,
    name: 'mapped',
    displayName: 'Mapped?',
  };

  $scope.MODEL = MODEL;
  $scope.$watchCollection('MODEL.accounts', (rows) => {
    $timeout(() => {
      $scope.setGridRowData(rows);
    });
  });
  $scope.$watchCollection('MODEL.listviewSelectedColumns', (columns) => {
    $timeout(() => {
      $scope.updateColumnDefinitions($scope.getFields(), columns, false, [$scope.LAST_COLUMN]);
    });
  });

  // set localStorage equal to $scope to get html-binding
  $scope.localStorage = safeLocalStorage;

  // bind data model to scope for access in views
  $scope.data = MODEL;

  $scope.currentState = MODEL.cachedState ? MODEL.cachedState.accounts : {};
  $scope.showViewByUser = UTILS.setViewByUserBasedOnRole(safeLocalStorage);

  // bind current route id to scope for access in views
  $scope.currentRoute = $route.current.id;

  UTILS.appInit(safeLocalStorage.accessToken);

  $(document.body).show();
  // get accounts data
  MODEL.accounts = [];

  $scope.setLeadsView = () => {
    $scope.data.currentLeadsView = UTILS.setLeadsView($scope.currentState);
  };

  $scope.toggleBulkEdit = function (show) {
    $scope.showBulkEdit = show;
  };

  $scope.handleRowSelectionChanged = ({api}) => {
    const selectedRows = api.getSelectedNodes();
    MODEL.selectedRecords = selectedRows.reduce((result, {id}) => Object.assign(result, {[id]: true}), {});
    $scope.selectRecord();
  };

  if (!MODEL.TeamsService.verifiedTeamMembers.length) {
    TeamService.getOrgDetails()
      .then(() => {
        $scope.setLeadsView();
      })
      .catch((error) => {
        helperService.showAndLogError(error, "We could not retrieve your organization's details. Please reload the page or retry login.");
      });
  } else {
    $scope.setLeadsView();
  }

  CacheService.checkForFilters();

  (async function () {
    try {
      await MainService.startChainReaction();
      MainService.setCurrentPage();
      const currenciesResponse = await SharedNetworkService.fetchCurrencies();
      MODEL.CustomFields.currencies = currenciesResponse.data;
    } catch (error) {
      helperService.showAndLogError(error, 'We were unable to retrieve your companies. Please try again');
      window.refreshDom({loader: false}, 'show');
    }
  }());

  MODEL.currentSubPage = 'list';
}

AccountsCtrl.$inject = [
  '$scope', '$route', 'FetchCRMDataService', 'MainService', 'BaseController',
  'AccountsService', 'TeamService', 'CacheService', 'GroupsService', '$timeout',
];
