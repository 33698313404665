export default function DefaultDataService() {
  // main utility functions
  const MODEL = window.DataModel;

  // service to return
  const service = {};

  service.getTeam = (name) => ({
    name,
    domain: MODEL.org.domain,
  });

  service.getRole = (makeManager) => {
    const role = {};
    if (makeManager) {
      const manager = MODEL.roles.find(role => role.key === 'MANAGER');
      role.name = manager.name;
      role.id = manager.id;
    } else {
      const member = MODEL.roles.find(role => role.key === 'MEMBER');
      role.name = member.name;
      role.id = member.id;
    }
    return role;
  };

  service.getUser = (username, makeManager, makeOwner = false) => {
    // TODO:- remove this hardcoded part
    const role = {};
    if (makeManager) {
      role.id = MODEL.roles.find(role => role.key === 'MANAGER').id;
    } else if (makeOwner) {
      role.id = MODEL.roles.find(role => role.key === 'OWNER').id;
    } else {
      role.id = MODEL.roles.find(role => role.key === 'MEMBER').id;
    }

    return {
      username,
      role,
      emailVerified: false,
      profilePhoto: null,
    };
  };

  service.getErrorSwal = (data) => swal('Oops', data.validationErrors[0].message, 'error');

  return service;
}

DefaultDataService.$inject = [];
