import React, {useCallback} from 'react';
import styled from 'styled-components';
import {useDropzone} from 'react-dropzone';

const Container = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 150px;
`;

const Icon = styled.i`
  font-size: 5em;
`;

const Underlined = styled.span`
  text-decoration: underline;
`;

const Information = styled.div`
  font-size: 1.4em;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface Props {
  onSelect: (files: File[]) => void
}

const DropZone: React.FC<Props> = ({onSelect}) => {
  const onDrop = useCallback((files: File[]) => {
    onSelect(files);
  }, []);
  const {getRootProps, getInputProps} = useDropzone({onDrop, accept: '.csv'});

  return (
    <Container {...getRootProps()}>
      <div>
        <input {...getInputProps()} />
        <Icon className="fas fa-cloud-upload-alt" />
      </div>
      <Information>
        <div>Drag and drop or <Underlined>choose a file</Underlined></div>
        <div>We support .csv files up to 10MB.</div>
      </Information>
    </Container>
  );
};

export default DropZone;
