import mapActions from '../store/store-helpers';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import teamNetworkService from '../network-services/team-network-service';

export default function ReportsCtrl($scope, MainService, BaseController, ReportsService, CrmActivityTypesNetworkService) {
  // init global utility functions
  const MODEL = window.DataModel;

  // bind data model to scope for access in views
  $scope.data = MODEL;

  // extend BaseController methods onto scope
  Object.assign($scope, BaseController);

  mapActions($scope, ['modals']);

  async function $onInit() {
    $scope.modalsActions.resetVisibility('noReportsModal');
    await MainService.startChainReaction();
    MODEL.ReportsService.selectedFolder = 'all';
    MODEL.ReportsService.selectedReportView = 'all';
    await ReportsService.fetchReports();

    MODEL.ReportsService.allSelected = MODEL.ReportsService.selectedReportIds.length === MODEL.ReportsService.currentReports.length
            && MODEL.ReportsService.currentReports.length;
    const currentUser = safeLocalStorage.currentUser;

    MODEL.crmActivityTypes = (await CrmActivityTypesNetworkService.getTypes(safeLocalStorage.currentUser.organization.id)).data;

    const teams = await teamNetworkService.getTeams(safeLocalStorage.currentUser.organization.id);
    MODEL.TeamsService.subTeams = teams.data;
    const teamUserProm = MODEL.TeamsService.subTeams.map(st => teamNetworkService.getTeamMembers(safeLocalStorage.currentUser.organization.id, st.id));
    const teamUsers = await Promise.all(teamUserProm);
    MODEL.TeamsService.subTeamUsers = teamUsers.reduce((curr, data) => curr.concat(data.data), []);
    MODEL.owner = currentUser.organization.owner;
  }

  $onInit();

  // show create report option
  $scope.showCreateReport = () => {
    if (MODEL.modals.noReportsModal) {
      $scope.modalsActions.hideModal('noReportsModal');
    }
    ReportsService.showCreateReport();
  };
  // save selected columns
  $scope.saveSelectColumns = ReportsService.saveSelectColumns;

  $scope.shouldShowNoData = () => (
    // if no reports
    MODEL.modals.noReportsModal
    // and we're not in the create/edit mode
    && !MODEL.ReportsService.isReportEditingVisible
  );
}

ReportsCtrl.$inject = ['$scope', 'MainService', 'BaseController', 'ReportsService', 'CrmActivityTypesNetworkService'];
