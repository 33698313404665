import React, {useCallback, useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import {Button, Result, Table} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import IStep from './IStep';
import {RootState} from '../../../../../store/rootReducer';
import {getSaving, getCustomFieldIntegrationResponse} from '../../../../../store/integrations';
import {saveIntegration} from '../../../../../store/integrations/actions';
import StepDataLoadingProgress from './StepDataLoadingProgress';
import IntegrationFieldResponse from '../../../../../react/type/integration/IntegrationFieldResponse';

interface Props extends IStep {
  saving: boolean,
  saveIntegration: typeof saveIntegration,
  serviceName: string,
  customFieldResponse: IntegrationFieldResponse[],
}

const Step4: React.FC<Props> = ({
  className,
  saving,
  saveIntegration,
  serviceName,
  customFieldResponse,
  style,
}) => {
  const handleReturnClick = useCallback(() => { window.location.hash = '#/integrations'; }, []);
  const resColumns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'mmcDisplayName',
    },
    {
      title:'Data Type',
      dataIndex: 'mmcDataType',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Info',
      dataIndex: 'errorInfo',
    }
  ];
  
  useEffect(
    () => {
      saveIntegration();
    },
    [saveIntegration],
  );

  const customFields: IntegrationFieldResponse[] = useMemo(
    () => customFieldResponse.map((row) => ({
      ...row,
      errorInfo: row?.errors?.[0]?.message,
      status: row.errored ? "Failed" : "Successfully created"
    })),
    [customFieldResponse],
  );
  
  if (saving) {
    return <StepDataLoadingProgress />;
  } 

  return (<>
    <Result
      className={className}
      extra={[
        <Button
          key="return"
          onClick={handleReturnClick}
          type="primary"
        >
          Return
        </Button>,
      ]}
      status="success"
      style={style}
      title={`Successfully saved ${serviceName} integration!`}
    />
    {customFields.length 
      ? (
        <Table
          columns={resColumns}
          dataSource={customFields}
          pagination={false}
          rowKey="id"
        />  
      )
      : null
    }
    </>
    );
};

const mapStateToProps = (state: RootState) => ({
  saving: getSaving(state),
  customFieldResponse: getCustomFieldIntegrationResponse(state)
});

const mapDispatchToProps = {
  saveIntegration,
};

export default connect(mapStateToProps, mapDispatchToProps)(Step4);
