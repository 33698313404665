import {AxiosResponse} from 'axios';
import baseNetworkService from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import EntityType from '../react/type/EntityType';
import FileType from '../react/type/File';
import ListResponse from '../react/type/service/ListResponse';
import {AnyEntityId} from '../react/type/AnyEntity';

const identity = <T> (x: T): T => x;

class FileNetworkService {
  private getFilesEndpoint(entityType?: EntityType, entityId?: AnyEntityId, id?: FileType['id']): string {
    const org = safeLocalStorage.currentUser!.organization.id;
    let endpoint = `organizations/${org}`;
    if (entityType && entityId) {
      endpoint += `/${entityType}/${entityId}`;
    }
    endpoint += '/files';
    if (id) {
      endpoint += `/${id}`;
    }
    return endpoint;
  }

  private getDownloadUrl(entityType: EntityType, entityId: AnyEntityId, id: FileType['id'], isPreview: boolean): string {
    return this.getFilesEndpoint(entityType, entityId, id) + (isPreview ? '/download-preview' : '/download');
  }

  public getFiles(entityType: EntityType, entityId: AnyEntityId): Promise<ListResponse<FileType>> {
    return baseNetworkService.read(this.getFilesEndpoint(entityType, entityId), {$order: '-createdAt'});
  }

  public createFile(entityType: EntityType | undefined, entityId: AnyEntityId | undefined, file: File, isPublic: boolean = false): Promise<FileType> {
    const formData = new FormData();
    if (isPublic) {
      formData.append('isPublic', true.toString());
    }
    formData.append('file', file);

    return baseNetworkService.create(
      this.getFilesEndpoint(entityType, entityId),
      formData,
      {
        headers: {'Content-Type': undefined}, // it'll be multipart/form-data with boundary
        transformRequest: identity,
      },
    );
  }

  public getFilePreview(entityType: EntityType, entityId: AnyEntityId, id: FileType['id']): Promise<Blob> {
    return baseNetworkService.read(
      this.getDownloadUrl(entityType, entityId, id, true),
      undefined,
      {responseType: 'blob'},
    );
  }

  public getFile(entityType: EntityType, entityId: AnyEntityId, id: FileType['id']): Promise<Blob> {
    return baseNetworkService.read(
      this.getDownloadUrl(entityType, entityId, id, false),
      undefined,
      {responseType: 'blob'},
    );
  }

  public deleteFile(entityType: EntityType, entityId: AnyEntityId, id: FileType['id']): Promise<AxiosResponse> {
    return baseNetworkService.delete(this.getFilesEndpoint(entityType, entityId, id));
  }
}

export default new FileNetworkService();
