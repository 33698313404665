import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import Breadcrumb from './component/Breadcrumb';
import BottomNavigation from './component/BottomNavigation';
import History from './component/History';
import ChooseFile from './component/ChooseFile';
import Options from './component/Options';
import MapColumns from './component/MapColumns';
import EntityType from '../../type/EntityType';
import ImportType from '../../type/ImportType';
import FilePreview from '../../type/FilePreview';
import ImportSettings from '../../type/ImportSettings';
import ImportNetworkServiceType from '../../type/network/ImportNetworkService';
import SettingsServiceType from '../../type/SettingsService';
import ImportServiceType from '../../type/ImportService';
import analyticsService from '../../../shared-services/analytics-service';
import showSupportModal from '../../util/showSupportModal';

export interface Page {
  name: string;
  component: React.FC<any>,
  props?: object
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const StepWrapper = styled.div`
  width: auto;
  margin: 0px 5% 20px;
  height: calc(100% - 160px);
`;

const showError = (errorMessage: string): void => window.swal('Uh-oh', errorMessage, 'error');

interface Props {
  ImportNetworkService: ImportNetworkServiceType,
  SettingsService: SettingsServiceType,
  ImportService: ImportServiceType,
}

const Import: React.FC<Props> = ({ImportNetworkService, SettingsService, ImportService}) => {
  const {t} = useTranslation(['import']);

  const [initialized, setInitialized] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [entityType, setEntityType] = useState(EntityType.COMPANY);
  const [importType, setImportType] = useState(ImportType.NO_UID);
  const [filePreview, setFilePreview] = useState<FilePreview | undefined>(undefined);
  const [entityColumns, setEntityColumns] = useState<any[]>([]);
  const [mappedColumns, setMappedColumns] = useState<object>({});
  const [uid, setUid] = useState<string | undefined>();
  const [uidIsSaved, setUidIsSaved] = useState<boolean>(false);
  const [importStarted, setImportStarted] = useState<boolean>(false);
  const [mapping, setMapping] = useState<object>({});
  const [settings, setSettings] = useState<ImportSettings>({companyUniqueId: undefined, peopleUniqueId: undefined, dealUniqueId: undefined});

  useEffect(
    () => analyticsService.clicked('Set entity type', {entityType}),
    [entityType],
  );

  useEffect(
    () => analyticsService.clicked('Set import type', {importType}),
    [importType],
  );

  useEffect(
    () => {
      const savedSettings: ImportSettings = SettingsService.getOrganizationSetting('import', settings);
      setSettings(savedSettings);
      (async () => {
        await ImportService.populateCustomFields();
        setInitialized(true);
        setEntityColumns(ImportService.populateColumnsForEntity(entityType));
      })();
    },
    [],
  );

  useEffect(
    () => {
      if (filePreview) {
        setMappedColumns(ImportService.findMatches(filePreview.headers, entityColumns));
      }
    },
    [entityColumns, filePreview],
  );

  useEffect(
    () => {
      if (initialized) {
        setEntityColumns(ImportService.populateColumnsForEntity(entityType));
      }
    },
    [entityType, setEntityColumns],
  );

  useEffect(
    () => {
      if (uid && (importType === ImportType.NO_UID)) {
        setImportType(ImportType.UID);
      }
    },
    [uid, importType, setImportType],
  );

  useEffect(
    () => {
      let newUid: string | undefined;
      if (entityType === EntityType.COMPANY) {
        newUid = settings.companyUniqueId;
      } else if (entityType === EntityType.PERSON) {
        newUid = settings.peopleUniqueId;
      } else {
        newUid = settings.dealUniqueId;
      }
      setUidIsSaved(!newUid);
      setUid(newUid);
    },
    [entityType, settings, setUid, setUidIsSaved],
  );

  const handleSetFilePreview = useCallback(
    (filePreview: FilePreview | undefined) => {
      setFilePreview(filePreview);
      if (filePreview) {
        setCurrentPage(2);
      }
    },
    [],
  );

  const handleSetPage = useCallback(
    (page: number): void => {
      if (page === -1) {
        analyticsService.canceled('Import');
        window.history.back();
        return;
      }
      if (page === 4) {
        if (!importStarted) {
          finishImport();
        }
        return;
      }
      if (page === 3) {
        if (importType === ImportType.SUPPORT) {
          analyticsService.clicked('Show support window');
          showSupportModal();
          return;
        }
        if (entityColumns.filter(entityColumn => entityColumn.required).length > filePreview!.headers.length) {
          showError(t('columnCountErrorMessage'));
          return;
        }
      }
      analyticsService.track('Change import page', {page: page + 1});
      setCurrentPage(page);
    },
    [uid, filePreview, importType, entityColumns, mapping, importStarted],
  );

  const finishImport = useCallback(
    (): void => {
      if (importType === ImportType.UID && !uid) {
        showError(t('provideUIDErrorMessage'));
        return;
      }
      const emptyFields = Object.keys(mapping).filter(k => mapping[k] === false);
      if (emptyFields.length > 0) {
        showError(`${emptyFields.map(k => `"${k}"`).join(', ')} column${emptyFields.length > 1 ? 's are' : ' is'} required but not selected`);
        return;
      }

      const emptyFieldWithCondition = ImportService.getEmptyFieldWithCondition(entityColumns, mapping);
      if (emptyFieldWithCondition.length) {
        const requiredFields: string = emptyFieldWithCondition.filter(entityColumn => emptyFieldWithCondition[0].requiredIfEmpty.includes(entityColumn.key)).map(entityColumn => entityColumn.label).join(', ');
        showError(`At least one of these fields must be defined: ${emptyFieldWithCondition[0].label}, ${requiredFields}`);
        return;
      }

      const overfillWithCondition = ImportService.getOverfillWithCondition(entityColumns, mapping);
      if (overfillWithCondition.length) {
        const overfillFields: string = overfillWithCondition.filter(entityColumn => overfillWithCondition[0].invalidIfSet.includes(entityColumn.key)).map(entityColumn => entityColumn.label).join(', ');
        showError(`This field should be empty: ${overfillWithCondition[0].label} when these fields are selected:  ${overfillFields}`);
        return;
      }

      const mappingToSend = {...mapping};
      delete mappingToSend[ImportService.skipKey];
      delete mappingToSend[ImportService.separatorKey];
      setImportStarted(true);
      (async () => {
        await SettingsService.updateOrgSettings('import', {
          companyUniqueId: entityType === EntityType.COMPANY ? uid : settings.companyUniqueId,
          peopleUniqueId: entityType === EntityType.PERSON ? uid : settings.peopleUniqueId,
          dealUniqueId: entityType === EntityType.DEAL ? uid : settings.dealUniqueId,
        });
        await ImportNetworkService.startImport(entityType, filePreview!.file.id, mappingToSend, {
          updateExistingCustomers: importType === ImportType.UID,
          geoManagementState: 'automaticPreserveAddress',
          uniqueColumn: uid,
        });
        analyticsService.completed('Import', {
          entityType,
          fileId: filePreview!.file.id,
          mappingToSend,
        });
        setImportStarted(false);
        window.location.href = '/#/';
      })();
    },
    [uid, importType, entityType, filePreview, mapping, setImportStarted, entityColumns],
  );

  const pages: Page[] = [
    {name: t('importHistory'), component: History, props: {ImportNetworkService}},
    {name: t('chooseFile'), component: ChooseFile, props: {ImportNetworkService, filePreview, handleSetFilePreview}},
    {name: t('importOptions'), component: Options, props: {allowNoUid: !uid, uidIsSaved, entityType, setEntityType, importType, setImportType}},
    {name: t('mapColumns'), component: MapColumns, props: {entityColumns, filePreview, importType, entityType, setUid, uid, setMapping, mappedColumns, ImportService}},
  ];

  const CurrentPageComponent = pages[currentPage].component;
  const CurrentPageProps = pages[currentPage].props || {};

  return (
    <Container>
      <Breadcrumb currentPage={currentPage} pages={pages} />
      <StepWrapper>
        <CurrentPageComponent {...CurrentPageProps} />
      </StepWrapper>
      <BottomNavigation
        fileIsSelected={!!filePreview}
        currentPage={currentPage}
        stepCount={pages.length}
        setPage={handleSetPage}
      />
    </Container>
  );
};

export default Import;
