import helperService from '../shared-services/helper-service';
import dealsNetworkService from '../network-services/deals-network-service';
import analyticsService from '../shared-services/analytics-service';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import DateField from '../common/field-model/field/date-field';
import mapActions from '../store/store-helpers';

const getNewAppUrl = (path) => `${window.__env.baseNewAppUrl}${path}?mmc_token=${safeLocalStorage.accessToken}`;

export default function FunnelCtrl(
  $scope, $timeout, $compile, $route, $filter, MainService, FunnelService,
  BaseController, MappingService, FetchCRMDataService, CacheService, TeamService,
  GroupsService, CustomerService, mmcConst, SettingsService,
) {
  let currencyFormatter;

  if (Intl) {
    currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    });
  } else {
    currencyFormatter = {
      format(value) {
        return value;
      },
    };
  }

  // init global utility functions
  const UTILS = window.mmcUtils;
  const {MAN} = window.mmcUtils;
  const MODEL = window.DataModel;

  // extend BaseController methods onto scope
  Object.assign($scope, BaseController);
  mapActions($scope, ['modals']);
  $scope.modalsActions.resetVisibility('noDealsModal');

  // set localStorage equal to $scope to get html-binding
  $scope.localStorage = safeLocalStorage;

  // bind data model to scope for access in views
  $scope.data = MODEL;
  $scope.funnels = [];
  $scope.stages = [];
  $scope.deal = {};

  $scope.getFields = () => MODEL.dealFieldModel.fields;

  $scope.MODEL = MODEL;
  $scope.$watchCollection('MODEL.deals', (rows) => {
    $timeout(() => {
      $scope.setGridRowData(rows);
    });
  });
  $scope.$watchCollection('MODEL.listviewSelectedColumns', (columns) => {
    $timeout(() => {
      $scope.updateColumnDefinitions($scope.getFields(), columns);
    });
  });

  $scope.currentState = MODEL.cachedState ? MODEL.cachedState.deals : {};

  $scope.showViewByUser = UTILS.setViewByUserBasedOnRole(safeLocalStorage);

  // bind current route id to scope for access in views
  $scope.currentRoute = $route.current.id;
  $scope.dealType = [];

  // extend BaseController methods onto scope
  Object.assign($scope, BaseController);

  $(document.body).show();

  $scope.setLeadsView = () => {
    $scope.data.currentLeadsView = $scope.currentState.user || undefined;
  };

  // get accounts data
  MODEL.show.loader = true;
  $scope.setLeadsView = () => {
    if ($scope.currentState.user) {
      window.refreshDom({currentLeadsView: $scope.currentState.user});
    } else {
      $scope.data.currentLeadsView = undefined;
    }
  };

  if (!MODEL.TeamsService.verifiedTeamMembers.length) {
    TeamService.getOrgDetails()
      .then(() => {
        $scope.setLeadsView();
      })
      .catch((error) => {
        helperService.showAndLogError(error, "We could not retrieve your organization's details. Please reload the page or retry login.");
      });
  } else {
    $scope.setLeadsView();
  }

  CacheService.checkForFilters();
  let filters = $scope.buildFilters();

  $scope.initPage = () => {
    window.location.href = getNewAppUrl('/map');
  };

  $scope.initPage();

  $scope.handleRowSelectionChanged = ({api}) => {
    const selectedRows = api.getSelectedNodes();
    MODEL.selectedRecords = selectedRows.reduce((result, {id}) => Object.assign(result, {[id]: true}), {});
    $scope.selectRecord();
  };

  //
  // --------------------- GENERAL / UTILITY FUNCTIONS  --------------------- //
  //

  // show the leads circle map
  $scope.showLeadsMap = function () {
    FunnelService.showLeadsMap();
  };

  // show leads heat map
  $scope.leadsHeatMap = function () {
    FunnelService.leadsHeatMap();
  };

  // close the form that was tapped
  $scope.closeAccount = function (objectType) {
    $scope.deal = {};
    MainService.closeAccount(objectType);
  };

  // gets the leads for the respective lead stage
  $scope.getLeads = function (stage) {
    MODEL.customersListSegment = [];
    MODEL.show.loader = true;

    FunnelService.getLeads(stage)
      .then(() => {
        FunnelService.showFunnelChart()
          .then(() => {
            const {title} = MODEL.currentPageSubHeader;
            const count = MODEL.allLeads.length;
            const btn = MODEL.currentPageSubHeader.addButtonName;
            const showFilter = MODEL.showFilterBtn;
            const showAdd = false;
            FetchCRMDataService.setCurrentPageVariable(title, count, btn, showFilter, showAdd);
            MODEL.show.loader = false;
          })
          .catch((error) => {
            helperService.showAndLogError(error);
          });

        if ($route.current.id === 'dealsMapPage') {
          FunnelService.showPreservedLeadsView();
        }
      })
      .catch((error) => {
        helperService.showAndLogError(error);
      });
  };

  // edit if lead shows/hides in funnel
  $scope.editLeadCheck = function (stage) {
    analyticsService.entityUpdated('Deal Lead', {check: !stage.hidden});
    FunnelService.editLeadCheck(stage);
  };

  // sorts customers by dropdown arrow
  $scope.sortCustomers = function (type) {
    MappingService.sortCustomers(type);
  };

  // close edit stages view --> and then re-show leads funnel
  $scope.closeEditStages = async () => {
    $('#edit-stages').hide();
    if (MODEL.FunnelService.modalFunnelId === MODEL.FunnelService.currentFunnelId) {
      MODEL.show.loader = true;
      const stages = await dealsNetworkService.fetchStagesForFunnel(MODEL.FunnelService.currentFunnelId);
      $scope.stages = stages.data;

      const options = {
        funnel: MODEL.FunnelService.currentFunnelId,
        dealStage: MODEL.FunnelService.currentStage,
      };

      $scope.filterView(false, options, filters);
      window.refreshDom({loader: false}, 'show');
    }
  };

  $scope.closeAddEditStageModal = () => {
    analyticsService.canceled('Add stage');
    if ($scope.currentModalStage) {
      $(`#stage-color-picker-${$scope.currentModalStage.color}`).removeClass('selected-color');
    }
  };

  // highlight colors
  $scope.selectStageColor = (selectedColor) => {
    safeLocalStorage.currentUser.organization.plan.colors.forEach(color => {
      $(`#stage-color-picker-${color.replace(' ', '')}`).removeClass('selected-color');
    });
    MODEL.FunnelService.stageColor = selectedColor;
    $(`#stage-color-picker-${selectedColor.replace(' ', '')}`).addClass('selected-color');
  };

  // show leads funnel --> close leads map
  $scope.showLeadsFunnel = function () {
    $('#stages').show();
    $('#mapLegend').hide();
    $('#noMappedLeadsAlert').hide();
    $('#leadsFunnelChart').addClass('btn-fill');
    $('#leadsMap').removeClass('btn-fill');
    MODEL.FunnelService.showingLeadsMap = false;
  };

  // leads help button
  $scope.showLeadsHelp = function () {
    $('#modalbg').show();
    $('#modal').show();
    $('#leadsHelpBox').show();
    $('#editStagesBox').hide();
    $('#photoBox').hide();
  };

  // search user's leads funnel
  $scope.searchFromLeads = function () {
    FunnelService.searchFromLeads();
  };

  // edit number of lead stages, and if they show in funnel visual
  $scope.showEditStages = function () {
    $('#leadsFunnel').hide();
    $('#edit-stages').show();
  };

  // sort leads by respective view
  $scope.sortLeadsByView = function (currentLeadsView, dealType) {
    MODEL.FunnelService.currentLeadsTab = '';
    const cachedDealType = MODEL.FunnelService.currentDealTypeId;

    const options = {
      dealType: dealType || cachedDealType,
      dealStage: MODEL.FunnelService.currentLeadsTab,
    };
    // if dealType doesn't exist
    if (!dealType) FunnelService.filterFunnelOptions(currentLeadsView);

    CacheService.cacheCurrentView(currentLeadsView, MODEL.currentPageNum, options);
    if (MODEL.nonEnterprise) {
      MODEL.show.loader = true;
      FunnelService.initFunnelAndMap(MODEL.FunnelService.currentLeadsTab);
      $timeout(() => {
        window.refreshDom({loader: false}, 'show');
      }, 1200);
    }
  };

  // edit name of lead stages
  $scope.editStageName = function (stage) {
    FunnelService.editStageName(stage);
  };

  //
  // --------------------- LEADS MAP LEGEND --------------------- //
  //

  $scope.getLeadsMapSizeIntervalOne = function () {
    return `$0 – ${currencyFormatter.format(MODEL.FunnelService.leadCirclesMax * 0.33)}`;
  };

  $scope.getLeadsMapSizeIntervalTwo = function () {
    return `${currencyFormatter.format(MODEL.FunnelService.leadCirclesMax * 0.33)} – ${currencyFormatter.format(MODEL.FunnelService.leadCirclesMax * 0.66)}`;
  };

  $scope.getLeadsMapSizeIntervalThree = function () {
    return `${currencyFormatter.format(MODEL.FunnelService.leadCirclesMax * 0.66)} – ${currencyFormatter.format(MODEL.FunnelService.leadCirclesMax)}`;
  };

  // show pin map
  $scope.showPinMap = (firstTime = false) => {
    MODEL.FunnelService.mapName = 'pin';
    $('#heatMap').removeClass('highlightedBtn');
    $('#circleMap').removeClass('highlightedBtn');
    $('#pinMap').removeClass('highlightedBtn').addClass('highlightedBtn');
    if (firstTime) {
      FunnelService.initGoogleMap();
    }
    FunnelService.resetDealsMapPage();

    // add nearby circle to the map
    if (MODEL.FilterService.filtersSelected) {
      const radiusFilter = MODEL.FilterService.filtersSelected.find(filter => filter.category === 'radius');
      if (radiusFilter) {
        MappingService.setNearbyCircle();
      }
    }

    const infowindow = new google.maps.InfoWindow();
    const bounds = new google.maps.LatLngBounds();

    MODEL.deals.forEach(deal => {
      let geoPoint = deal.account && deal.account.geoPoint ? deal.account.geoPoint : undefined;
      if (!geoPoint && deal.contact) {
        geoPoint = deal.contact.geoPoint;
      }
      if (!geoPoint) {
        return;
      }

      const markerIcon = SettingsService.getUserSetting('markerIcon', 'pin');
      const markerSize = SettingsService.getUserSetting('markerSize', 'large');
      let key = 'pinLarge'; // default to pin large
      if (markerIcon === 'pin') {
        if (markerSize === 'small') {
          key = 'pinSmall';
        }
      } else if (markerIcon === 'circle') {
        if (markerSize === 'large') {
          key = 'circleLarge';
        } else if (markerSize === 'small') {
          key = 'circleSmall';
        }
      }
      const {width, height, location} = mmcConst[key];
      const markerImageUrl = `${location}_${deal.stage.color.replace(' ', '_')}.png`;

      const latlng = new google.maps.LatLng(geoPoint.coordinates[1], geoPoint.coordinates[0]);

      const markerOptions = {
        icon: {
          url: markerImageUrl,
          scaledSize: new google.maps.Size(width, height),
        },
        map: MODEL.FunnelService.leadsMap,
        position: latlng,
        id: deal.id,
      };
      const marker = new google.maps.Marker(markerOptions);

      MODEL.FunnelService.allPins.push(marker);

      const poppupHTML = FunnelService.getPinPopupHTML(deal);

      bounds.extend(latlng);

      const popup = $(poppupHTML);
      $compile(popup)($scope);

      google.maps.event.addListener(marker, 'click', (function (marker, popup, scope) {
        return function () {
          MODEL.currentCrmObjectId = marker.get('id');
          infowindow.setContent(popup);
          infowindow.open(scope.map, marker);
        };
      }(marker, popup[0], $scope)));
    });

    // only needed at the first time deals map page is shown
    // after that we need to keep the zoom level at whatever is currently set so that user sees the same
    // when he switches view types (pin, circle, heat map)
    if (firstTime && !MAN.hasSavedZoomOrCenter()) {
      FunnelService.setMapBoundsForTheView(bounds);
      // fit map to bounds
      MODEL.FunnelService.leadsMap.fitBounds(bounds);
      if (MODEL.FunnelService.leadsMap.getZoom() >= 9) {
        MODEL.FunnelService.leadsMap.setZoom(7);
      }

      // set in the bounds of the nearby circle
      if (MODEL.FilterService.filtersSelected) {
        const radiusFilter = MODEL.FilterService.filtersSelected.find(filter => filter.category === 'radius');
        if (radiusFilter) {
          MODEL.FunnelService.leadsMap.fitBounds(MODEL.FilterService.nearbyCircle.getBounds());
        }
      }
    } else {
      MAN.goToSavedZoomAndCenter(MODEL.FunnelService.leadsMap);
    }
  };
  window.showPinMap = $scope.showPinMap;

  // show manage stages popup
  $scope.showManageStages = async function () {
    if (safeLocalStorage.currentUser.role.key !== 'OWNER') {
      return;
    }
    analyticsService.clicked(['Deals', 'Funnel settings']);

    MODEL.show.loader = true;
    MODEL.FunnelService.modalFunnelId = MODEL.FunnelService.currentFunnelId;
    const stages = await dealsNetworkService.fetchStagesForFunnel(MODEL.FunnelService.modalFunnelId);
    $scope.modalStages = stages.data;
    MODEL.FunnelService.modalStages = stages.data;
    FunnelService.showManageStages();
    window.refreshDom({loader: false}, 'show');
    $('#edit-stages').show();
  };

  // toggle funnel
  $scope.showFunnelStages = async function (funnelId) {
    $('#edit-stages').hide();
    MODEL.show.loader = true;
    const stages = await dealsNetworkService.fetchStagesForFunnel(funnelId);
    $scope.modalStages = stages.data;
    MODEL.FunnelService.modalStages = stages.data;
    window.refreshDom({loader: false}, 'show');
    $('#edit-stages').show();
  };

  // delete stage
  $scope.deleteStage = (stage) => {
    FunnelService.deleteStage(stage);
  };

  $scope.deleteFunnelOrStage = () => {
    MODEL.show.loader = true;
    return FunnelService.moveFunnelAndStage().finally(() => {
      $('#edit-stages').show();
      MODEL.show.loader = false;
    });
  };

  // add deal funnel
  $scope.addFunnel = () => {
    MODEL.FunnelService.funnelHeader = 'Add Funnel';
    MODEL.FunnelService.addEditFunnelName = '';
    $scope.currentFunnel = '';

    if (Object.keys(MODEL.FunnelService.selectedFunnels).length >= 8) {
      $('#edit-stages').hide();
      return swal('Uh-oh', 'You cannot create more than 8 funnels.', 'error')
        .then(() => {
          $('#edit-stages').show();
        });
    }

    $('#edit-stages').hide();
    $scope.toggleFunnelModal(true);
  };

  // add deal stage
  $scope.addStage = () => {
    analyticsService.clicked(['Deals', 'funnel Settings', 'add stage']);
    MODEL.FunnelService.stageHeader = 'Add Stage';
    MODEL.FunnelService.stageTitle = 'New Name';
    MODEL.FunnelService.addEditStageName = '';
    MODEL.FunnelService.addEditStageProb = 0;
    MODEL.FunnelService.stageColor = '';
    if ($scope.currentModalStage) {
      $(`#stage-color-picker-${$scope.currentModalStage.color}`).removeClass('selected-color');
    }
    $('#edit-stages').hide();
    $('#add-edit-stage-modal').show();
  };

  // edit deal funnel
  $scope.editFunnel = (funnel) => {
    MODEL.FunnelService.funnelHeader = 'Edit Funnel';
    MODEL.FunnelService.addEditFunnelName = funnel.name;
    MODEL.FunnelService.addEditFunnelOwnedByTeamId = funnel.team ? funnel.team.id : null;
    $scope.currentFunnel = funnel;

    $('#edit-stages').hide();
    $scope.toggleFunnelModal(true);
  };

  $scope.toggleFunnelModal = (show) => {
    $scope.showAddEditFunnelModal = show;
  };

  // edit deal funnel
  $scope.editStage = (stage) => {
    analyticsService.initiated('Deal Edit Stage', stage);
    MODEL.FunnelService.stageHeader = 'Edit Stage';
    MODEL.FunnelService.stageTitle = 'Edit Name';
    MODEL.FunnelService.addEditStageName = stage.name;
    MODEL.FunnelService.addEditStageProb = stage.weight * 100;
    MODEL.FunnelService.stageColor = stage.color;
    $(`#stage-color-picker-${stage.color.replace(' ', '')}`).addClass('selected-color');
    $scope.currentModalStage = stage;
    $('#edit-stages').hide();
    $('#add-edit-stage-modal').show();
  };

  $scope.saveStages = async function () {
    $('#add-edit-stage-modal').hide();
    MODEL.show.loader = true;
    // remove white space
    MODEL.FunnelService.addEditStageName = MODEL.FunnelService.addEditStageName.trim();
    try {
      const doesStageAdding = MODEL.FunnelService.stageHeader === 'Add Stage';
      const result = doesStageAdding
        ? await FunnelService.createNewStage($scope.modalStages.length)
        : await FunnelService.updateStage($scope.currentModalStage);
      analyticsService[doesStageAdding ? 'entityAdded' : 'entityUpdated']('Stage', result);
      if (result) {
        await $scope.showFunnelStages(MODEL.FunnelService.modalFunnelId);
        if (MODEL.FunnelService.modalFunnelId === MODEL.FunnelService.currentFunnelId) {
          await $scope.initPage();
        }
      }
    } catch (error) {
      UTILS.showValidationError(error, 'We were unable to save this stage.');
    }

    MODEL.show.loader = false;
    window.refreshDom({selectedFunnels: MODEL.FunnelService.selectedFunnels}, 'FunnelService');
    $('#edit-stages').show();
  };

  // filter Leads By Type
  $scope.filterLeadsByType = (type) => {
    FunnelService.filterLeadsByType(type);
  };

  // init funnel and map
  $scope.initFunnelAndMap = (stage) => {
    const options = {
      funnel: MODEL.FunnelService.currentFunnelId,
      dealStage: stage.id,
    };
    CacheService.cacheCurrentView(MODEL.currentLeadsView, MODEL.currentPageNum, options);
    FunnelService.initFunnelAndMap(stage);
  };

  // set manage View : deal or type
  $scope.setManageView = (view) => {
    $('#stageTabBtn').removeClass('mmc-button--secondary').removeClass('mmc-button--secondary-soft');
    $('#typeTabBtn').removeClass('mmc-button--secondary').removeClass('mmc-button--secondary-soft');

    if (view === 'stage') {
      $('#stageTabView').show();
      $('#typeTabView').hide();
      $('#stageTabBtn').addClass('mmc-button--secondary');
      $('#typeTabBtn').addClass('mmc-button--secondary-soft');
    } else {
      $('#stageTabView').hide();
      $('#typeTabView').show();
      $('#typeTabBtn').addClass('mmc-button--secondary');
      $('#stageTabBtn').addClass('mmc-button--secondary-soft');
    }
  };

  $scope.dealsMapPinClicked = () => {
    const record = {id: MODEL.currentCrmObjectId};
    window.selectedRowCustomerTable(record);
  };

  // initialize delete stage
  $scope.initDeleteStage = (funnelId) => {
    FunnelService.initDeleteStage(funnelId);
  };

  // // NEW FUNCTIONS

  // filter the view by stage
  $scope.filterByStage = async function (mapView, stage) {
    MODEL.show.loader = true;
    const options = {
      funnel: MODEL.FunnelService.currentFunnelId,
      dealStage: stage ? stage.id : null,
    };

    filters = $scope.extendFiltersToIncludeDataForVisibleColumns(filters);
    MODEL.FunnelService.currentStage = options.dealStage;
    CacheService.cacheCurrentView(MODEL.currentLeadsView, MODEL.currentPageNum, options);

    await FunnelService.fetchDeals(mapView, filters, undefined, undefined, undefined, stage ? [stage] : $scope.stages);

    if (mapView) {
      // re-init map
      await FunnelService.setMapView();
    } else {
      // re-init funnel
      FunnelService.showFunnelChart(MODEL.FunnelService.funnelData, options.funnel);
    }
    analyticsService.clicked(['Deals', 'set funnel stage'], {stage});
    if (MODEL.FunnelService.funnelData) {
      $scope.totalDeals = MODEL.FunnelService.funnelData.reduce((count, {totalDeals = 0}) => count + totalDeals, 0);
    } else {
      $scope.totalDeals = MODEL.deals.length;
    }

    $scope.preventPopup = !!(stage);

    FunnelService.setViewHeaders();
  };

  // filter the view by funnel
  $scope.filterByFunnel = async function (mapView) {
    analyticsService.clicked(['Deals', mapView ? 'Map' : 'List', 'Filter by funnel'], {funnelId: MODEL.FunnelService.currentFunnelId});
    MODEL.show.loader = true;
    const options = {
      funnel: MODEL.FunnelService.currentFunnelId,
      dealStage: null,
    };
    MODEL.FunnelService.currentStage = options.dealStage;
    // reset normal cache var
    CacheService.resetCacheVar('deals');
    CacheService.cacheCurrentView(MODEL.currentLeadsView, MODEL.currentPageNum, options);
    $scope.filterView(mapView, options, $scope.buildFilters());
  };

  // refresh funnel
  $scope.refreshFunnels = async function () {
    const funnels = await dealsNetworkService.fetchAllFunnels();
    $scope.funnels = funnels.data;
  };

  // filter the view by user
  $scope.filterByUser = async function (mapView, userId) {
    MODEL.show.loader = true;
    const funnelFilter = {};
    if (userId) {
      funnelFilter.viewAs = userId;
      filters.viewAs = userId;
    } else {
      delete filters.viewAs;
    }

    const funnels = await dealsNetworkService.fetchAllFunnels(funnelFilter);
    if (funnels.data.length) {
      $scope.funnels = funnels.data;
      const funnelId = funnels.data[0].id;
      filters.funnelId = funnelId;
      MODEL.FunnelService.currentFunnelId = funnelId;
      MODEL.FunnelService.currentStage = null;

      const options = {
        funnel: MODEL.FunnelService.currentFunnelId,
        dealStage: MODEL.FunnelService.currentStage,
      };

      // reset normal cache var
      CacheService.resetCacheVar('deals');
      CacheService.cacheCurrentView(MODEL.currentLeadsView, MODEL.currentPageNum, options);
      $scope.filterView(mapView, options, filters);
    } else {
      swal('Uh-oh!', 'There are no funnels for the team that the user belongs to. Please share a funnel with the team', 'error')
        .then(() => window.refreshDom({loader: false}, 'show'));
    }
  };

  $scope.filterView = async function (mapView, options, filters) {
    if (mapView) {
      const stages = await dealsNetworkService.fetchStagesForFunnel(options.funnel);
      $scope.stages = stages.data;
      await FunnelService.fetchDeals(mapView, filters, undefined, undefined, undefined, $scope.stages);
      await FunnelService.setMapView();
      FunnelService.setViewHeaders();
    } else {
      const stages = await dealsNetworkService.fetchStagesForFunnel(options.funnel);
      $scope.stages = stages.data;
      filters = $scope.extendFiltersToIncludeDataForVisibleColumns(filters);
      await FunnelService.setFunnelView(options.funnel);
      await FunnelService.fetchDeals(mapView, filters, undefined, undefined, undefined, $scope.stages);
      FunnelService.setViewHeaders();
    }
  };

  $scope.deleteDealStage = async function (stage) {
    await swal({
      title: 'Are you sure?',
      text: 'This stage will be removed from the funnel!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, remove this deal stage',
      closeOnConfirm: false,
    });
    const result = await dealsNetworkService.deleteDealStage(stage.funnel.id, stage.id);
    if (result.validationErrors) {
      swal('Uh-oh', result.validationErrors[0].message, 'error');
      return;
    }
    analyticsService.entityDeleted('Deal Stage', stage);
    swal('Deleted...', 'The deal stage has been deleted successfully.', 'success');
    await $scope.showFunnelStages(MODEL.FunnelService.modalFunnelId);
    if (MODEL.FunnelService.modalFunnelId === MODEL.FunnelService.currentFunnelId) {
      const stages = await dealsNetworkService.fetchStagesForFunnel(MODEL.FunnelService.currentFunnelId);
      $scope.stages = stages.data;
    }
  };

  $scope.deleteFunnel = async function (funnel) {
    if (funnel && $scope.funnels.length < 2) {
      swal('Uh-oh', 'In order to delete this funnel, please create a new one first', 'error');
      return;
    }

    await swal({
      title: 'Are you sure?',
      text: 'This funnel will be removed from the organization!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, remove this funnel',
      closeOnConfirm: false,
    });
    await dealsNetworkService.deleteFunnel(funnel.id);
    swal('Deleted...', 'The funnel has been deleted successfully.', 'success');
    await $scope.refreshFunnels();
    $scope.$apply();
  };

  // save new customer
  $scope.saveCustomer = function (objectType, obj) {
    obj = obj || {};

    if (MODEL.currentPageEditOrAddSubheader.addButtonName.indexOf('Company') >= 0) {
      objectType = 'accounts';
      obj = $scope.accounts;
    } else if (MODEL.currentPageEditOrAddSubheader.addButtonName.indexOf('Person') >= 0) {
      objectType = 'contacts';
      obj = $scope.contacts;
    } else {
      objectType = 'deals';
      obj = $scope.deal;
    }

    CustomerService.saveCustomer(objectType, obj);
  };

  $scope.toggleBulkEdit = function (show) {
    $scope.showBulkEdit = show;
  };

  /**
     * Returns formatted date string for the tooltip if field is a DateField
     * @param {Field} field
     * @param {*} deal
     * @returns {string}
     */
  $scope.formatDealTooltip = (field, deal) => (field instanceof DateField ? $filter('date')(field.getEntityValue(deal), 'MMM dd, yyyy h:mm a') : '');
}

FunnelCtrl.$inject = [
  '$scope', '$timeout', '$compile', '$route', '$filter', 'MainService', 'FunnelService',
  'BaseController', 'MappingService', 'FetchCRMDataService', 'CacheService', 'TeamService',
  'GroupsService', 'CustomerService', 'mmcConst', 'SettingsService',
];
