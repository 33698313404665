import styled from 'styled-components';

const CategoryTitle = styled.div`
  background: #e1e4e8;
  color: black;
  font-weight: 600;
  padding: 20px;
`;

export default CategoryTitle;
