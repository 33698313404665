import template from './report-folders.html';
import './report-folders.scss';

class ReportFoldersController {
  constructor(ReportsService) {
    Object.assign(this, {
      MODEL: window.DataModel,
      ReportsService,
    });
  }

  updateCurrentReports(folderView, selectedFolder) {
    this.ReportsService.updateCurrentReports(folderView, selectedFolder);
  }
}

ReportFoldersController.$inject = ['ReportsService'];

const reportFolders = {
  bindings: {},
  controller: 'ReportFoldersController',
  template,
};

export {ReportFoldersController, reportFolders};
