import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import {initReactI18next} from 'react-i18next';
import Price from './react/type/plans/Price';

i18n
  // load translation using xhr -> see /public/locales
  .use(Backend)
  .use(LanguageDetector)
  .use(intervalPlural)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: !window.__env.isProduction,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value, format, lng) => {
        if (format?.startsWith('currency')) {
          const precision = format.includes(':') ? parseInt(format.split(':')[1], 10) : 0;
          const formatter = new Intl.NumberFormat(lng, {
            style: 'currency',
            currency: (value as Price).currency,
            minimumFractionDigits: Number.isNaN(precision) ? 0 : precision,
          });

          return formatter.format((value as Price).value);
        }

        return value;
      },
    },

    react: {
      wait: true,
      nsMode: 'fallback',
      useSuspense: false,
    },
  });

export default i18n;
