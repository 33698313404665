import angular from 'angular';
import AddEditCtrl from './add-edit-ctrl';
import AddEditService from './add-edit-service';
import CacheService from './cache-service';
import CustomerService from './customer-service';
import DataService from './data-service';
import DefaultDataService from './default-data-service';
import FetchCRMDataService from './fetch-crm-data-service';
import FilterService from './filter-service';
import LassoService from './lasso-service';
import MainService from './main-service';

export default angular.module('mapmycustomersApp.sharedServices', [])
  .controller('AddEditCtrl', AddEditCtrl)
  .factory('AddEditService', AddEditService)
  .factory('CacheService', CacheService)
  .factory('CustomerService', CustomerService)
  .factory('DataService', DataService)
  .factory('DefaultDataService', DefaultDataService)
  .factory('FetchCRMDataService', FetchCRMDataService)
  .factory('FilterService', FilterService)
  .service('LassoService', LassoService)
  .factory('MainService', MainService)
  .name;
