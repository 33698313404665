import slice from './index';
import store from '../../store/index';

const actions = slice.actions;
type ActionName = keyof typeof actions;

const routingFireActions: typeof actions = (Object.keys(actions) as ActionName[]).reduce(
  (result, action) => ({
    ...result,
    [action]: (...args: any[]) => store.dispatch(actions[action](args)),
  }),
  {},
) as typeof actions;

export default routingFireActions;
