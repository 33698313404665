import styled from 'styled-components';

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 25px -15px 0;
`;

export default CardsContainer;
