import BaseNetworkService from './base-network-service/base-network-service';
import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import helperService from '../shared-services/helper-service';
import Me from '../react/type/Me';
import Organization from '../react/type/Organization';
import User from '../react/type/User';
import Team from '../react/type/Team';
import Role from '../react/type/Role';
import AccessRight from '../react/type/AccessRight';
import ListResponse from '../react/type/service/ListResponse';

type OrgId = Organization['id'];
type RoleId = Role['id'];
type TeamId = Team['id'];
type UserId = User['id'];
type AccessRightId = AccessRight['id'];

export class UserNetworkService {
  async getAllUsers(orgId: OrgId): Promise<ListResponse<User>> {
    const endPoint = `organizations/${orgId}/users?$limit=999999999`;
    return BaseNetworkService.read(endPoint);
  }

  async getAllUsersCount(orgId: OrgId) {
    const endPoint = `organizations/${orgId}/users?$limit=0`;
    return BaseNetworkService.read(endPoint);
  }

  userExists(email: string) {
    return BaseNetworkServiceWithPromises.create('users/exists', {username: email});
  }

  async getUserData(): Promise<Me> {
    const endPoint = 'users/me';
    return BaseNetworkService.read(endPoint);
  }

  async updateMe(payload: Me) {
    const endPoint = 'users/me';
    return BaseNetworkServiceWithPromises.update(endPoint, payload);
  }

  async createUser(orgId: OrgId, payload: any) {
    const endPoint = `organizations/${orgId}/users`;
    return helperService.executeWithCaptcha(
      'create',
      (token: string) => BaseNetworkService.create(endPoint, {...payload, token}),
    );
  }

  async updateUser(orgId: OrgId, userId: UserId, payload: any) {
    const endPoint = `organizations/${orgId}/users/${userId}`;
    return BaseNetworkService.update(endPoint, payload);
  }

  async deleteUser(orgId: OrgId, userId: UserId) {
    const endPoint = `organizations/${orgId}/users/${userId}`;
    return BaseNetworkService.delete(endPoint);
  }

  async createTeamMembers(orgId: OrgId, teamId: TeamId, userIds: UserId[]) {
    return BaseNetworkService.create(`organizations/${orgId}/user-teams`, [
      {teamId, userId: userIds},
    ]);
  }

  async deleteTeamMembers(orgId: OrgId, teamId: TeamId, userIds: UserId[]) {
    return BaseNetworkService.delete(`organizations/${orgId}/user-teams`, [
      {teamId, userId: userIds},
    ]);
  }

  async getUnassignedUsers(orgId: OrgId) {
    const endPoint = `organizations/${orgId}/users?$limit=999999999&$filters={"assignable":true}`;
    return BaseNetworkService.read(endPoint);
  }

  // /// ACCESS RIGHTS CRUD OPERATIONS /////

  getAccessRights(orgId: OrgId, userId: UserId) {
    const endPoint = `organizations/${orgId}/users/${userId}/user-access-rights`;
    return BaseNetworkService.read(endPoint);
  }

  getUserPermissions(roleId: RoleId) {
    const endPoint = `roles/${roleId}/permissions?$limit=${window.DataModel.recordsUpperLimit}`;
    return BaseNetworkService.read(endPoint);
  }

  createAccessRight(orgId: OrgId, userId: UserId, payload: any) {
    const endPoint = `organizations/${orgId}/users/${userId}/user-access-rights`;
    return BaseNetworkService.create(endPoint, payload);
  }

  deleteAcessRight(orgId: OrgId, userId: UserId, accessRightId: AccessRightId) {
    const endPoint = `organizations/${orgId}/users/${userId}/user-access-rights/${accessRightId}`;
    return BaseNetworkService.delete(endPoint);
  }

  requestResetPasswordLink(username: string) {
    return BaseNetworkServiceWithPromises.create('request-password-reset', {username});
  }

  resetPassword(username: string, password: string, code: string) {
    return BaseNetworkServiceWithPromises.create('reset-password', {username, password, code});
  }

  resendTeamMemberInvite(orgId: OrgId, username: string) {
    const endPoint = `organizations/${orgId}/send-accept-invite`;
    return BaseNetworkServiceWithPromises.create(endPoint, {username, sendEmail: true});
  }
}

export default new UserNetworkService();
