import React, {useCallback, useEffect, useReducer, useState} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import ListViewGrid from '../../components/Grid/ListViewGrid';
import accountsNetworkService from '../../../network-services/accounts-network-service';
import accountFieldModel from '../../../common/field-model/account-field-model';
import EntityType from '../../type/EntityType';
import FetchCRMDataServiceType from '../../type/service/FetchCRMDataService';
import MainServiceType from '../../type/service/MainService';
import ListResponse from '../../type/service/ListResponse';
import Company from '../../type/Company';
import useBoolean from '../../hook/useBoolean';
import helperService from '../../../shared-services/helper-service';
import customFieldsNetworkService from '../../../network-services/custom-fields-network-service';
import useInitializer from '../../hook/useInitializer';
import Group from '../../type/Group';
import {setViewColumns} from '../../util/reducers/listReducer';
import persistenceReducer from './reducers/companyList';
import safeLocalStorage from '../../../shared-services/safe-local-storage-service';
import getListCallbacks from '../../hook/getListCallbacks';
import FilterPane from '../../components/FilterPane/FilterPane';
import {RootState} from '../../../store/rootReducer';
import {getCompanyGroups} from '../../../store/company';
import {fetchCompanyGroups} from '../../../store/company/action';
import withStore from '../../hoc/withStore';

export interface Page {
  name: string;
  component: React.FC<any>,
  props?: object
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

interface Props {
  FetchCRMDataService: FetchCRMDataServiceType,
  MainService: MainServiceType,
  groups: Group[],
  fetchCompanyGroups: () => {},
}

const OldCompanyList: React.FC<Props> = ({
  FetchCRMDataService,
  MainService,
  groups,
  fetchCompanyGroups,
}) => {
  const [companyData, setCompanyData] = useState<ListResponse<Company> | undefined>(undefined);
  const [isLoading, setLoading, setNoLoading] = useBoolean();
  const [initialized, setInitialized] = useBoolean();
  const [{filter, viewColumns, page, sorting, filteredFields}, dispatchParams] = useReducer(persistenceReducer, safeLocalStorage.companyListViewState);
  const updateViewColumns = useCallback(
    async () => {
      dispatchParams(setViewColumns(await FetchCRMDataService.initListViewCols()));
    },
    [dispatchParams],
  );

  // TODO delete it after migration subnav from angular to React
  useEffect(
    () => {
      FetchCRMDataService.setCurrentPageVariable('Company List', companyData?.total, 'Add Company', false, false);
    },
    [companyData],
  );

  const initialize = useCallback(
    async () => {
      try {
        fetchCompanyGroups();
        const response = await customFieldsNetworkService.getFields(EntityType.COMPANY);
        accountFieldModel.setCustomFields(response.data);
        await updateViewColumns();
      } catch (e) {
        helperService.showAndLogError(e);
      }
      setInitialized();
    },
    [fetchCompanyGroups],
  );
  useInitializer(initialize);

  const reloadCompanies = useCallback(
    async () => {
      setLoading();
      try {
        setCompanyData(await accountsNetworkService.fetchAllAccounts(false, filter, page, sorting.fieldName, sorting.ascending));
      } catch (e) {
        helperService.showAndLogError(e);
      }
      setNoLoading();
    },
    [setCompanyData, setLoading, setNoLoading, filter, page, sorting],
  );

  useEffect(
    () => { reloadCompanies(); },
    [reloadCompanies, filter, page, sorting],
  );

  const {handleChangeFilter, handleClearFilter, handleChangePage, handleChangeSorting} = getListCallbacks(dispatchParams);

  return (
    <Container>
      {initialized &&
        <ListViewGrid
          fieldModel={accountFieldModel}
          entityCount={companyData?.total}
          entityList={companyData?.data}
          entityType={EntityType.COMPANY}
          // TODO: we have to move all methods and fields from MainService to some redux store
          MainService={MainService}
          onChangePage={handleChangePage}
          page={page}
          onChangeFilters={handleChangeFilter}
          onChangeSorting={handleChangeSorting}
          multiSelectBar
          isLoading={isLoading}
          onReloadData={reloadCompanies}
          viewColumns={viewColumns}
          onUpdateViewColumns={updateViewColumns}
          filteredFields={filteredFields}
          onClearFilter={handleClearFilter}
          sorting={sorting}
          extraFilters={(
            <FilterPane
              groups={groups}
              onFilterChanged={handleChangeFilter}
              filteredFields={filteredFields}
            />
          )}
        />
      }
    </Container>
  );
};


const mapStateToProps = (state: RootState) => ({
  groups: getCompanyGroups(state),
});

const mapDispatchToProps = {
  fetchCompanyGroups,
};

export default withStore(connect(mapStateToProps, mapDispatchToProps)(OldCompanyList));
