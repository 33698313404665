import React from 'react';
import {Provider} from 'react-redux';
import store from '../../store';

const withStore = (WrappedComponent: any) => (props: any) => (
  <React.StrictMode>
    <Provider store={store}>
      <WrappedComponent {...props} />
    </Provider>
  </React.StrictMode>
);

export default withStore;
