import React, {ChangeEvent, useCallback} from 'react';
import {Input} from 'antd';
import {SearchProps} from 'antd/es/input';
import Labeled, {LabeledFieldProps} from '../Labeled';

const {Search} = Input;

interface Props
  extends Omit<SearchProps, 'onChange'>,
    Omit<LabeledFieldProps, 'children'> {
  onChange?: (value: string, event: ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea: React.FC<Props> = ({
  label,
  labelPosition,
  onChange,
  sideLabelSpan,
  ...props
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) =>
      onChange && onChange(event.target.value, event),
    [onChange],
  );

  return (
    <Labeled
      label={label}
      labelPosition={labelPosition}
      sideLabelSpan={sideLabelSpan}
    >
      <Search onChange={handleChange} {...props} />
    </Labeled>
  );
};

export default TextArea;
