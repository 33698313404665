import template from './upgrade.html';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';

class UpgradeController {
  constructor() {
    Object.assign(this, {
      MODEL: window.DataModel,
      currentUser: safeLocalStorage.currentUser,
    });
  }
}

UpgradeController.$inject = [];

const upgrade = {
  bindings: {},
  controller: 'UpgradeController',
  template,
};

export {UpgradeController, upgrade};
