import get from 'lodash/get';
import template from './edit-subheader.html';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';

class EditSubheaderController {
  constructor($window, MainService) {
    Object.assign(this, {
      MODEL: $window.DataModel,
      MainService,
    });
  }

  get owner() {
    const crmObjectUsername = get(this.MODEL.currentCRMObject, 'user.username');

    if (crmObjectUsername === safeLocalStorage.currentUser.username) {
      return safeLocalStorage.currentUser;
    } if (crmObjectUsername === get(this.MODEL.TeamsService, 'teamStructure.teamOwnerName')) {
      return this.MODEL.owner;
    }
    return this.MODEL.TeamsService.subTeamUsers.find(({username}) => username === crmObjectUsername);
  }

  closeEntity(objectType) {
    this.MainService.closeAccount(objectType);
  }

  googleSearch() {
    this.MainService.googleSearch();
  }

  openDealLossReasonsPopup() {
    this.MODEL.AddEditService.showDealLossReasonsPopup = true;
  }

  tryNewRecordView() {
    this.MODEL.recordView = true;
    swal(
      'Coming Soon',
      `This is a preview of the new record view we are working on. 
        We have heard your feedback and are excited to bring this as the default view soon!<br/>
        Please let us know if you have feedback.<br/>
        To return to the edit view, just refresh the page.`,
    );
  }
}

EditSubheaderController.$inject = ['$window', 'MainService'];

const editSubheader = {
  bindings: {
    saveCustomer: '&',
    stages: '<',
    isRegularStage: '&',
    makeDealWon: '&',
    reopenDeal: '&',
    hasAccessRights: '<',
    deal: '<?',
  },
  controller: 'EditSubheaderController',
  template,
};

export {EditSubheaderController, editSubheader};
