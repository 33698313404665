import React, {useMemo} from 'react';
import styled from 'styled-components';
import moment, {unitOfTime} from 'moment';
import Activity from '../../../type/Activity';
import ActivityType from '../../../type/ActivityType';
import RecapDateItems from './RecapDateItems';
import EntityType from '../../../type/EntityType';
import DateRange from '../type/DateRange';

const RecapGraphContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: flex-start;
`;

const RecapItem = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    min-height: 26px;
    text-transform: uppercase;
    font-size: 0.8rem;
    padding-top: 2px;
    display: flex;
    align-items: center;
  }
`;

const RecapItemList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  margin-left: 16px;
`;

type DateActivities = [Date, Activity[]];

const groupByDate = (dateRange: DateRange, activities: Activity[], unit: unitOfTime.DurationConstructor = 'day'): DateActivities[] => {
  const dateToActivityMap = new Map<string, Activity[]>();
  // populate with all dates according to current dateRange and dateUnit
  for (let date = moment(dateRange.start); date.isSameOrBefore(dateRange.end); date.add(1, unit)) {
    dateToActivityMap.set(date.toISOString(), []);
  }
  // add activities into the dateToActivityMap
  activities.forEach((activity) => {
    const date = moment(activity.startAt).startOf(unit).toISOString();
    if (dateToActivityMap.has(date)) {
      // eslint-disable-next-line no-unused-expressions
      dateToActivityMap.get(date)?.push(activity);
    } else {
      dateToActivityMap.set(date, [activity]);
    }
  });
  return Array.from(dateToActivityMap.entries())
    .map(([dateString, activities]) => [moment(dateString).toDate(), activities] as DateActivities)
    .sort(([dateA], [dateB]) => dateA.getTime() - dateB.getTime());
};

interface Props {
  activities: Activity[],
  activityTypes: Set<ActivityType>,
  dateRange: DateRange,
  dateUnit: unitOfTime.DurationConstructor,
  goToEntity: (entityType: EntityType, entityId: number) => void,
}

const RecapGraph: React.FC<Props> = ({activities, activityTypes, dateRange, dateUnit, goToEntity}) => {
  const visibleActivityTypes = useMemo(
    () => {
      if (activities.length) {
        const activityTypeMap = new Map(Array.from(activityTypes).map(activityType => [activityType.id, activityType]));
        return Array.from(new Set(activities
          .map(({crmActivityType}) => crmActivityType.id)
          .filter(id => activityTypeMap.has(id))
          .map(id => activityTypeMap.get(id) as ActivityType), // we're sure it's not undefined
        ))
          .sort((a, b) => a.displayOrder - b.displayOrder);
      }

      return Array.from(activityTypes);
    },
    [activities, activityTypes],
  );

  const activitiesGroupedByDate: DateActivities[] = useMemo(
    () => groupByDate(dateRange, activities, dateUnit),
    [activities, dateRange, dateUnit],
  );

  if (!visibleActivityTypes.length) {
    return null;
  }

  return (
    <RecapGraphContainer>
      <RecapItem>
        {visibleActivityTypes.map(activityType => (
          <div key={activityType.id}>{activityType.name}</div>
        ))}
      </RecapItem>
      <RecapItemList>
        {activitiesGroupedByDate.map(([date, activities]) => (
          <RecapDateItems
            activities={activities}
            activityTypes={visibleActivityTypes}
            date={date}
            goToEntity={goToEntity}
            key={date.toISOString()}
          />
        ))}
      </RecapItemList>
    </RecapGraphContainer>
  );
};

export default RecapGraph;
