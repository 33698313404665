import {useCallback, useState} from 'react';
import useBoolean from './useBoolean';

type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends ((...args: any[]) => Promise<infer V>)
    ? V
    : T;

const useDataLoader = <R extends any>(
  fetchers: Array<() => Promise<any>>,
  loadingByDefault: boolean = false,
) => {
  const [isLoading, startLoading, stopLoading] = useBoolean(loadingByDefault);
  const [data, setData] = useState<ThenArg<typeof fetchers[number]>[]>([]);
  const [error, setError] = useState<Error | undefined>(undefined);
  const loader = useCallback(
    async () => {
      startLoading();
      setError(undefined);
      try {
        const responses = await Promise.all(fetchers.map(f => f())); // call all fetches
        setData(responses);
      } catch (e) {
        setError(e);
        console.error('Uhoh', e); // FIXME: use helperService when possible
      }
      stopLoading();
    },
    fetchers,
  );
  return {
    isLoading,
    error,
    loader,
    data,
  };
};

export default useDataLoader;
