import angular from 'angular';
import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';

export default function ImportNetworkService() {
  const getPreview = (file) => {
    const formData = new FormData();
    formData.append('file', file);

    return BaseNetworkServiceWithPromises.create(
      `organizations/${safeLocalStorage.currentUser.organization.id}/imports/preview`,
      formData,
      {
        headers: {'Content-Type': undefined}, // it'll be multipart/form-data with boundary
        transformRequest: angular.identity,
      },
    );
  };

  /**
   * Starts new import using given data as options
   * @param {"accounts" | "contacts" | "deals"} type import type
   * @param {number} fileId cvs file id
   * @param {Object<string, string>} columnMappings a key to a csv column name map
   * @param {{geoManagementState: string, updateExistingCustomers?: boolean, uniqueColumn?: string}} options
   * @return {Promise<*>} request promise
   */
  const startImport = (type, fileId, columnMappings, options) => {
    const payload = {
      file: {
        id: fileId,
      },
      mapping: {
        type,
        properties: columnMappings,
        options: {
          geoManagementState: options.geoManagementState,
        },
      },
    };

    if (options.updateExistingCustomers && options.uniqueColumn) {
      payload.mapping.properties.importId = options.uniqueColumn;
      payload.mapping.identification = ['importId'];
    }

    return BaseNetworkServiceWithPromises.create(`organizations/${safeLocalStorage.currentUser.organization.id}/imports`, payload);
  };

  /**
   * Fetches information about given import
   * @param {number} importId
   * @returns {Promise<*>}
   */
  const fetchImport = (importId) => BaseNetworkServiceWithPromises.read(`organizations/${safeLocalStorage.currentUser.organization.id}/imports/${importId}`);

  /**
   * @param {object} params
   * @returns {Promise<*>}
   */
  const fetchImportData = (params) => BaseNetworkServiceWithPromises.read(`organizations/${safeLocalStorage.currentUser.organization.id}/import-data`, params);

  /**
   * Fetches recent imports
   * @returns {Promise<*>}
   */
  const fetchImports = (params) => BaseNetworkServiceWithPromises.read(`organizations/${safeLocalStorage.currentUser.organization.id}/imports`, params);

  return {
    getPreview,
    startImport,
    fetchImport,
    fetchImportData,
    fetchImports,
  };
}
