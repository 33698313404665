import React, {useCallback} from 'react';
import styled from 'styled-components';
import SmallPreviewContainer from './SmallPreviewContainer';
import Company from '../../../../type/Company';
import EntityType from '../../../../type/EntityType';

const ParentTag = styled.div`
  font-size: 75%;
  color: #aaa;
`;

export interface RelatedCompany extends Pick<Company, 'id' | 'name'> {
  isParentCompany?: boolean,
}

interface Props {
  company: RelatedCompany,
  goToEntity: (entityType: EntityType, entityId: number) => void,
}

const CompanySmallPreview: React.FC<Props> = ({company, goToEntity}) => {
  const handleClick = useCallback(() => goToEntity(EntityType.COMPANY, company.id), [company.id]);
  return (
    <SmallPreviewContainer onClick={handleClick}>
      {company.name}
      {company.isParentCompany && <ParentTag>Parent Company</ParentTag>}
    </SmallPreviewContainer>
  );
};

export default CompanySmallPreview;
