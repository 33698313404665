import React, {useCallback, useMemo} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {Col, Divider, Row, Select, Table} from 'antd';
import {ColumnType} from 'antd/es/table';
import BlockSpace from '../../../../components/Layout/BlockSpace';
import ObjectSyncSettings from '../ObjectSyncSettings';
import User, {UserRef} from '../../../../type/User';
import ButtonBar from './ButtonBar';
import IStep from './IStep';
import ServiceButtons from '../ServiceButtons';
import Integration, {SupportedEntityType} from '../../../../type/integration/Integration';
import {RootState} from '../../../../../store/rootReducer';
import integrationsSlice, {getCurrentIntegration, getIntegrationUsers} from '../../../../../store/integrations';
import IntegrationUser from '../../../../type/integration/IntegrationUser';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import {ServiceDescription} from '../../../../../store/integrations/supported-services';

class UserIdSelect extends Select<User['id']> {}
const UserSelect = styled(UserIdSelect)`
  width: 100%;
`;

const getUserTableColumnConfig = (
  onChangeMapping: typeof integrationsSlice.actions.changeIntegrationUser,
  onChangeSyncing: typeof integrationsSlice.actions.changeIntegrationUserSync,
  serviceName: string,
  users: UserRef[],
): ColumnType<IntegrationUser>[] => [
  {
    title: `Map ${serviceName} Username`,
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'To...',
    dataIndex: 'userId',
    key: 'userId',
    render: (mmcUserId, integrationUser: IntegrationUserWithSyncUnmappedUsers) => (
      <UserSelect
        dropdownMatchSelectWidth={false}
        showSearch
        onChange={(selectedUserId: User['id'] | 0) => onChangeMapping({integrationUser, mmcUserId: selectedUserId || undefined})}
        filterOption={(input, option) => option?.children.toLowerCase().includes(input.toLowerCase())}
        value={mmcUserId ?? ''}
      >
        <Select.Option key={0} value={0}>None</Select.Option>
        {users.map(user => (
          <Select.Option key={user.id} value={user.id}>{user.username}</Select.Option>
        ))}
      </UserSelect>
    ),
  },
  {
    title: 'Sync Enabled',
    dataIndex: 'syncing',
    key: 'syncing',
    render: (syncing, integrationUser: IntegrationUserWithSyncUnmappedUsers) => (
      <Checkbox
        disabled={!integrationUser.syncUnmappedUsers && !integrationUser.userId}
        checked={syncing}
        onChange={(enabled) => onChangeSyncing({integrationUser, enabled})}
      />
    ),
  },
];

interface IntegrationUserWithSyncUnmappedUsers extends IntegrationUser{
  syncUnmappedUsers: boolean
}

interface Props extends IStep {
  changeSyncOption: typeof integrationsSlice.actions.changeSyncOption,
  changeUserMapping: typeof integrationsSlice.actions.changeIntegrationUser,
  changeUserSyncing: typeof integrationsSlice.actions.changeIntegrationUserSync,
  currentIntegration: Integration,
  integrationUsers: IntegrationUser[],
  mmcUsers: User[],
  syncOptions: Integration['syncOptions'],
  selectedService?: ServiceDescription,
  supportedEntityTypes: SupportedEntityType[],
}

const Step1: React.FC<Props> = ({
  changeSyncOption,
  changeUserMapping,
  changeUserSyncing,
  className,
  currentIntegration,
  integrationUsers,
  mmcUsers,
  syncOptions,
  onNextClick,
  onPreviousClick,
  selectedService,
  style,
  supportedEntityTypes,
}) => {
  const userTableColumnConfig = useMemo(
    () => getUserTableColumnConfig(changeUserMapping, changeUserSyncing, selectedService?.name ?? '', mmcUsers),
    [selectedService, mmcUsers],
  );

  const anySyncEnabled = Object.values(syncOptions).some(({incoming, outgoing}) => incoming || outgoing);

  const handleSyncOptionChange = useCallback((entityType, incoming, outgoing) => changeSyncOption({entityType, incoming, outgoing}), []);

  const integrationUsersWithSyncUnmappedUsers: IntegrationUserWithSyncUnmappedUsers[] = useMemo(
    () => integrationUsers.map(user => ({...user, syncUnmappedUsers: currentIntegration.syncUnmappedUsers})),
    [integrationUsers, currentIntegration],
  );

  return (
    <Row className={className} style={style}>
      <Col span={24}>
        <BlockSpace direction="vertical" size="large">
          <ServiceButtons selectedService={selectedService?.type} />
          {supportedEntityTypes.length > 0 && (
            <Row>
              <Col span={24}>
                <Divider orientation="left">Choose objects to sync from CRM</Divider>
                <BlockSpace direction="vertical" size="large">
                  {supportedEntityTypes.map(entityType => (
                    <ObjectSyncSettings
                      incoming={syncOptions[entityType].incoming}
                      outgoing={syncOptions[entityType].outgoing}
                      entityType={entityType}
                      key={entityType}
                      onChange={handleSyncOptionChange}
                    />
                  ))}
                </BlockSpace>
              </Col>
            </Row>
          )}
          {supportedEntityTypes.length > 0 && (
            <Row>
              <Col span={24}>
                <Divider orientation="left">Select the users(s) you would like to sync</Divider>
                <Table<IntegrationUser>
                  columns={userTableColumnConfig}
                  dataSource={integrationUsersWithSyncUnmappedUsers}
                  pagination={false}
                  rowKey="email"
                />
              </Col>
            </Row>
          )}
          <ButtonBar
            nextDisabled={selectedService === undefined || !anySyncEnabled}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            previousDisabled
          />
        </BlockSpace>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: RootState) => ({
  integrationUsers: getIntegrationUsers(state),
  currentIntegration: getCurrentIntegration(state),
});

const mapDispatchToProps = {
  changeSyncOption: integrationsSlice.actions.changeSyncOption,
  changeUserMapping: integrationsSlice.actions.changeIntegrationUser,
  changeUserSyncing: integrationsSlice.actions.changeIntegrationUserSync,
};

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
