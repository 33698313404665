import React, {useCallback, useMemo} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import SmallPreviewContainer from './SmallPreviewContainer';
import Route from '../../../../type/Route';
import EntityType from '../../../../type/EntityType';

const StopsCount = styled.div`
  font-size: 75%;
  color: #aaa;
`;

interface Props {
  goToEntity: (entityType: EntityType, entityId: number) => void,
  route: Route,
}

const RouteSmallPreview: React.FC<Props> = ({goToEntity, route}) => {
  const {t, ready} = useTranslation(['route']);
  const handleClick = useCallback(() => goToEntity(EntityType.COMPANY_ROUTE, route.id), [route.id]);

  const numberOfStops = useMemo(
    () => (route.routeAccounts?.length || 0) + (route.routeContacts?.length || 0),
    [route],
  );

  if (!ready) {
    return null;
  }

  return (
    <SmallPreviewContainer onClick={handleClick}>
      {route.name}
      <StopsCount>
        {t('RouteStopsWithCount_interval', {postProcess: 'interval', count: numberOfStops})}
      </StopsCount>
    </SmallPreviewContainer>
  );
};

export default RouteSmallPreview;
