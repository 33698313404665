import styled from 'styled-components';
import theme from '../../../../style/theme'

const Greeting = styled.div`
  margin: 0;
  font-weight: normal;
  font-size: 34px;
  color: ${theme.color.label};
`;

export default Greeting;
