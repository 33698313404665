import template from './lead-gen-multi-select.html';
import {entityToRecordNameString} from '../../common/convertEntityToRecordName';

LeadGenMutliSelectController.$inject = ['$scope', 'MainService'];

function LeadGenMutliSelectController($scope, MainService) {
  Object.assign(this, $scope, {$onInit, $onChanges, MainService, handleAddButtonClick, handleCheckboxClick});

  const filterPins = (pins, existingEntitiesNames) => {
    this.filteredPins = pins.filter((pin) => {
      const {lat, lng} = pin.geometry.location;
      return !existingEntitiesNames[entityToRecordNameString(pin.name, lng(), lat())];
    });
  };

  function $onInit() {
    this.entityTypeSingular = this.MainService.getEntityDisplayName(this.entityType, true, false);
    filterPins(this.pins, this.existingEntitiesNames);

    $scope.$watchCollection('$ctrl.pins', newPins => filterPins(newPins, this.existingEntitiesNames));
    $scope.$watchCollection('$ctrl.existingEntitiesNames', newExistingEntitiesNames => {
      filterPins(this.pins, newExistingEntitiesNames);
    });
  }

  function $onChanges() {
    this.entityTypeSingular = this.MainService.getEntityDisplayName(this.entityType, true, false);
  }

  function handleCheckboxClick(pin) {
    this.onToggle({pin});
  }

  function handleAddButtonClick() {
    this.onAddButtonClick();
  }
}

function leadGenMultiSelect() {
  return {
    restrict: 'E',
    scope: {
      entityType: '@',
      existingEntitiesNames: '=',
      pins: '=',
      selectedPins: '=',
      onToggle: '&',
      onAddButtonClick: '&',
    },
    bindToController: true,
    controller: 'LeadGenMutliSelectController',
    controllerAs: '$ctrl',
    template,
  };
}

export {
  LeadGenMutliSelectController,
  leadGenMultiSelect,
};
