import {Constants} from '@ag-grid-community/core';
import EntityFieldModel from './entity-field-model';
import DateField from './field/date-field';
import {createField} from './utils';
import FieldFeature from './field/FieldFeature';
import entityDisplayName from '../../react/util/formatter/entityDisplayName';

const typeFormatter = (entity, reportType) => entityDisplayName(reportType);

const reportFieldModel = new EntityFieldModel(undefined, [
  {
    name: 'name',
    displayName: 'Report Name',
    features: [FieldFeature.SORTABLE, FieldFeature.ALWAYS_VISIBLE],
    customGridProperties: {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      cellClass: ['cursor-pointer'],
    },
  },
  {
    name: 'description',
    displayName: 'Description',
    features: [FieldFeature.SORTABLE],
    customGridProperties: {
      cellClass: ['cursor-pointer'],
    },
  },
  {
    name: 'tableName',
    displayName: 'Folder',
    features: [FieldFeature.SORTABLE],
    valueFormatter: typeFormatter,
  },
  new DateField({name: 'updatedAt', displayName: 'Last Updated', customGridProperties: {sort: Constants.SORT_DESC}}),
  new DateField({name: 'lastGeneratedAt', displayName: 'Last Downloaded'}),
].map(createField));

export default reportFieldModel;
