class AnalyticsService {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  callSegmentMethod = (method, ...parameters) => {
    // TODO: enable again in a week or so
    // if (window.analytics) {
    //   if (window.analytics[method] || typeof window.analytics[method] !== 'function') {
    //     window.analytics[method](...parameters, {traits: window.DataModel.traits});
    //   }
    // }
  }

  page() {
    this.callSegmentMethod('page');
  }

  track(event, properties = {}) {
    this.callSegmentMethod('track', event, {
      ...properties,
      groupId: window.DataModel.traits.orgId,
    });
  }

  initiated(event, properties = {}) {
    this.track(`Initiated: ${event}`, properties);
  }

  feature(featurePath, properties = {}) {
    this.track(`Feature: ${Array.isArray(featurePath) ? featurePath.join(' -> ') : featurePath}`, properties);
  }

  clicked(featurePath, properties = {}) {
    this.track(`Clicked: ${Array.isArray(featurePath) ? featurePath.join(' -> ') : featurePath}`, properties);
  }

  completed(event, properties = {}) {
    this.track(`Completed: ${event}`, properties);
  }

  canceled(event, properties = {}) {
    this.track(`Canceled: ${event}`, properties);
  }

  failed(event, properties = {}) {
    this.track(`Failed: ${event}`, properties);
  }

  entityAdded(entityType, entity) {
    this.track(`Entity added: ${entityType}`, entity);
  }

  entityUpdated(entityType, entity) {
    this.track(`Entity updated: ${entityType}`, entity);
  }

  entityDeleted(entityType, entity) {
    this.track(`Entity deleted: ${entityType}`, entity);
  }
}

export default new AnalyticsService();
