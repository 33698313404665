import React from 'react';
import get from 'lodash/get';
import randomColor from 'randomcolor';
import AvatarComponent from 'react-md/lib/Avatars/Avatar';
import Tooltipped from 'react-md/lib/Tooltips/Tooltipped';
import {Positions} from 'react-md/lib/Tooltips/injectTooltip';
import User from '../../../type/User';
import Identified from '../../../type/base/Identified';
import userDisplayName from '../../../util/formatter/userDisplayName';
import complementFileUrl from '../../../util/complementFileUrl';

const colorMap = new Map<number, string>();
const simpleHash = (n: number) => (n * 0xdeadbeef) % 0xffffff;
const getColor = (user: Identified): string => {
  if (!colorMap.has(user.id)) {
    const color = randomColor({
      luminosity: 'light',
      format: 'rgb',
      seed: simpleHash(user.id),
    });
    colorMap.set(user.id, color);
  }

  return colorMap.get(user.id) as string;
};

interface Props {
  className?: string,
  tooltipPosition?: Positions,
  user: User,
}

const Avatar: React.FC<Props> = ({className, tooltipPosition, user}) => {
  const {fullName, profilePhoto, username} = user;
  const backgroundColor = getColor(user);
  const initials = fullName
    ? fullName.split(/\s/).map(word => word[0].toUpperCase()).slice(0, 2).join('')
    : username.substring(0, 1).toUpperCase();
  const profilePhotoUrl = complementFileUrl(get(profilePhoto, ['publicURI']));

  return (
    <Tooltipped
      label={userDisplayName(user)}
      position={tooltipPosition || 'top'}
      setPosition
    >
      {profilePhotoUrl
        ? <AvatarComponent
          className={className}
          src={profilePhotoUrl}
        />
        : (
          <span key="initials">
            <AvatarComponent className={className} style={{backgroundColor}}>
              {initials}
            </AvatarComponent>
          </span>
        )
      }
    </Tooltipped>
  );
};

export default Avatar;
