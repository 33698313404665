import trim from 'lodash/trim';
import template from './quick-create-account.html';
import countryList from '../../common/field-model/country-list.json';

class QuickCreateAccountController {
  constructor() {
    Object.assign(this, {
      MODEL: window.DataModel,
    });

    this.addressTouched = false;
  }

  $onInit() {
    this.accounts.quickNameAdd = '';
    this.accounts.quickAddressAdd = '';
    this.accounts.quickCityAdd = '';
    this.accounts.quickStateAdd = '';
    this.accounts.quickCountryAdd = '';
    this.countryList = countryList;
  }

  updateAddress() {
    if (!this.addressTouched) {
      this.addressTouched = true;
      this.accounts.quickCountryAdd = 'United States';
    }
  }

  isValidQuickAddDialog() {
    const hasName = !!trim(this.accounts.quickNameAdd);
    const hasAddress = !!trim(this.accounts.quickAddressAdd);
    const hasCountry = !!trim(this.accounts.quickCountryAdd);
    const hasEnoughInformationToGeocode =
            !hasAddress || (hasAddress && hasCountry);

    if (!this.MODEL.quickCreateCustomFields.length) {
      return hasName && hasEnoughInformationToGeocode;
    }

    const customFieldValues = Object.values(
      this.MODEL.quickCreateCustomFieldValues,
    );
    const areAllCustomFieldValuesSet = customFieldValues.every(({value}) => Array.isArray(value) && value.length && value[0] !== undefined);

    return (
      hasName && hasEnoughInformationToGeocode && areAllCustomFieldValuesSet
    );
  }
}

QuickCreateAccountController.$inject = [];

const quickCreateAccount = {
  bindings: {
    accounts: '=',
    cancelQuickCreate: '&',
    saveQuickCreate: '&',
  },
  controller: 'QuickCreateAccountController',
  template,
};

export {QuickCreateAccountController, quickCreateAccount};
