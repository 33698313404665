ActivityService.$inject = ['ActivityNetworkService'];

export default function ActivityService(ActivityNetworkService) {
  // main utility functions
  const MODEL = window.DataModel;

  // service to return
  const service = {};

  service.getActivityIdFilter = (activityView) => {
    const activityIds = MODEL.activities.filter(({table}) => table === activityView).map(({id}) => id);
    if (activityIds.length) {
      return {activityId: {$in: activityIds}};
    }
    return null;
  };

  service.fetchEntityHistory = async (entityType, entityId, limit = 20) => {
    const filters = {};
    Object.assign(filters, ActivityService.getActivityIdFilter(entityType));
    service.fetchPlatformActivities(filters, limit);
  };

  service.fetchPlatformActivities = async (filters, limit = 20) => {
    const params = {
      $order: '-createdAt',
      $filters: JSON.stringify(filters),
      $limit: limit,
    };
    const result = await ActivityNetworkService.fetchPlatformActivities(params);
    MODEL.platformActivities = result.data;
    MODEL.platformActivitiesCount = result.total;
    window.refreshDom({
      platformActivities: MODEL.platformActivities,
      platformActivitiesCount: MODEL.platformActivitiesCount,
    });
  };

  service.fetchActivities = async () => {
    const result = await ActivityNetworkService.fetchActivities();
    MODEL.activities = result.data;
    window.refreshDom({activities: MODEL.activities});
  };

  return service;
}
