import get from 'lodash/get';
import safeLocalStorage from '../shared-services/safe-local-storage-service';

export enum Feature {
  MAP = 'map',
  DISALLOW_COLOR = 'disallowColor'
}

type Features = Partial<Record<Feature, {enabled: boolean}>>;

export const getFeatures = (): Features | undefined => get(safeLocalStorage, 'currentUser.organization.metaData.features', {});

export const hasNewMaps = (): boolean => getFeatures()?.map?.enabled ?? false;
