import {createAction} from '@reduxjs/toolkit';
import {withPayloadType} from '../../react/util/redux';

export const fetchCompanyCount = createAction('company/fetchCount');
export const initCompanyListVew = createAction('company/initListView');
export const fetchCompanies = createAction('company/fetchCompanies', withPayloadType<{skipFirst?: boolean}>());
export const fetchCompanyGroups = createAction('company/fetchCompanyGroups', withPayloadType<{skipFirst?: boolean}>());

export type Actions =
  ReturnType<typeof initCompanyListVew>
  | ReturnType<typeof fetchCompanyCount>
  | ReturnType<typeof fetchCompanies>
  | ReturnType<typeof fetchCompanyGroups>;
