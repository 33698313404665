import React, {useCallback} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {LatLngBounds} from 'leaflet';
import {Map, Marker, Popup, LayersControl} from 'react-leaflet';
import {GoogleLayer} from 'react-leaflet-google-v2';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import DashCard from '../DashCard/DashCard';
import useDataLoader from '../../../../hook/useDataLoader';
import useInitializer from '../../../../hook/useInitializer';
import Centered from '../../../../style/Centered';
import teamNetworkService, {LocationSummary} from '../../../../../network-services/team-network-service';
import safeLocalStorage from '../../../../../shared-services/safe-local-storage-service';
import ListResponse from '../../../../type/service/ListResponse';
import swapGeoPointCoordinates from '../../../../util/geo/swapGeoPointCoordinates';
import googleAuthProps from '../../../../util/geo/googleAuthProps';
import {userIcon} from '../../../../components/map-icons/icons';
import LoadingProgress from '../../../../components/LoadingProgress/LoadingProgress';

const MapContainer = styled.div`
  flex: 1;
  height: calc(400px - 72px);
`;

interface Props {
}

const UserLocationCard: React.FC<Props> = () => {
  const {t} = useTranslation('home');

  const getLocationSummary = useCallback(
    () => teamNetworkService.getLocationsSummary(safeLocalStorage.currentUser!.organization.id),
    [],
  );
  const {isLoading, loader, data} = useDataLoader([getLocationSummary]);
  useInitializer(loader);

  const locations = data.length && data[0].data ? (data[0] as ListResponse<LocationSummary>).data.map(swapGeoPointCoordinates) : [];
  const bounds = new LatLngBounds(locations.map(l => l.geoPoint.coordinates));

  return (
    <DashCard
      centerAlignContent={isLoading}
      title={t('UserLocation_interval', {postProcess: 'interval', count: locations.length})}
      titleIconClassName="far fa-map"
      span={3}
    >
      {isLoading && <LoadingProgress global={false} />}
      {!isLoading && !locations.length && (
        <Centered>No logged locations found</Centered>
      )}
      {!isLoading && locations.length && (
        <MapContainer>
          <Map bounds={bounds} style={{height: '100%'}} attributionControl={false}>
            <LayersControl position="topright">
              <LayersControl.BaseLayer checked name="Map">
                <GoogleLayer maptype="roadmap" {...googleAuthProps} />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Hybrid">
                <GoogleLayer maptype="hybrid" {...googleAuthProps} />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Satellite">
                <GoogleLayer maptype="satellite" {...googleAuthProps} />
              </LayersControl.BaseLayer>
            </LayersControl>
            <MarkerClusterGroup>
              {locations.map(location => (
                <Marker
                  icon={userIcon}
                  key={location.id}
                  position={location.geoPoint.coordinates}
                >
                  <Popup>
                    {location.user.username}<br />
                    {moment(location.createdAt).format('llll')}
                  </Popup>
                </Marker>
              ))}
            </MarkerClusterGroup>
          </Map>
        </MapContainer>
      )}
    </DashCard>
  );
};

export default UserLocationCard;
