import React, {useCallback} from 'react';
import Button from 'react-md/lib/Buttons/Button';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import showSupportModal from '../../../util/showSupportModal';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const TalkToSalesButton = styled(Button)`
  &:hover,
  &:focus {
    color: rgba(0, 0, 0, 0.87);
  }

  &.md-background--secondary {
    &:hover,
    &:focus {
      color: #fff;
    }
  }
`;

interface Props {
  checkoutEnabled: boolean,
  onCheckoutClick?: () => void,
  onlyTalkToSales: boolean,
}

const Buttons: React.FC<Props> = ({checkoutEnabled, onCheckoutClick, onlyTalkToSales}) => {
  const {t} = useTranslation(['billing']);
  return (
    <Container>
      <TalkToSalesButton
        flat={!onlyTalkToSales}
        onClick={showSupportModal}
        raised={onlyTalkToSales}
        secondary={onlyTalkToSales}
      >
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{__html: t('Talk to Sales', {interpolation: {escapeValue: false}})}} />
      </TalkToSalesButton>
      {!onlyTalkToSales && (
        <Button
          disabled={!checkoutEnabled}
          raised
          secondary
          onClick={onCheckoutClick}
        >
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{__html: t('Proceed to Checkout', {interpolation: {escapeValue: false}})}} />
        </Button>
      )}
    </Container>
  );
};

export default Buttons;
