import {call, takeEvery} from 'redux-saga/effects';
import {notification} from 'antd';
import {ArgsProps} from 'antd/es/notification';
import {handleError} from './action';
import helperService from '../../shared-services/helper-service';

type NotificationType = 'success' | 'error' | 'info' | 'warning';
const showNotification = (type: NotificationType, payload: ArgsProps) =>
  notification[type](payload);

function* onError(action: ReturnType<typeof handleError>) {
  const error = action.payload;
  console.error('onError', error);
  const message = helperService.getErrorMessage(error, 'Ooops. Something went wrong.');
  yield call(showNotification, 'error', {message, duration: 0});
}

// eslint-disable-next-line import/prefer-default-export
export default function* errorsSaga() {
  yield takeEvery(handleError.type, onError);
}
