import React from 'react';
import AntDMenu from 'antd/es/menu';
import MenuItemTitle from './MenuItemTitle';
import {Version} from '../../../../common/versions';
import {hasNewMaps} from '../../../../common/features';
import styles from './CommonMenu.module.scss';

interface Props {
  currentMenu?: string,
}

const Menu: React.FC<Props> = ({currentMenu}) => {
  const mapActive = currentMenu === 'map';
  const planActive = currentMenu === 'plan';
  const listActive = currentMenu === 'record';
  const manageActive = currentMenu === 'manage';
  const reportsActive = currentMenu === 'reports';
  const routesActive = currentMenu === 'routes';

  const newMaps = hasNewMaps();

  return (
    <nav className={styles.container}>
      {newMaps ? (
        <AntDMenu
          className={styles.menu}
          mode="horizontal"
          // @ts-ignore
          triggerSubMenuAction="click"
        >
          <AntDMenu.Item className={styles.topItem} key="map">
            <MenuItemTitle icon="fa-map" path="/map">
              Map
            </MenuItemTitle>
          </AntDMenu.Item>
          <AntDMenu.Item className={styles.topItem} key="planner">
            <MenuItemTitle icon="fa-calendar-alt" path="/activity">
              Planner
            </MenuItemTitle>
          </AntDMenu.Item>
          {/* Routes Menu */}
          <AntDMenu.SubMenu
            className={styles.topItem}
            key="routes"
            popupClassName={styles.submenu}
            popupOffset={[0, 10]}
            title={
              <MenuItemTitle active={routesActive} hasSubmenu icon="fa-road">
                Routes
              </MenuItemTitle>
            }
          >
            <AntDMenu.Item key="companyRoutesPlan">
              <MenuItemTitle legacyPath="/accounts/routing/list">
                Company Routes
              </MenuItemTitle>
            </AntDMenu.Item>
            <AntDMenu.Item key="peopleRoutesPlan">
              <MenuItemTitle legacyPath="/contacts/routing/list">
                People Routes
              </MenuItemTitle>
            </AntDMenu.Item>
          </AntDMenu.SubMenu>
          {/* Records Menu */}
          <AntDMenu.SubMenu
            className={styles.topItem}
            popupClassName={styles.submenu}
            popupOffset={[0, 10]}
            key="records"
            title={
              <MenuItemTitle hasSubmenu icon="fa-clipboard-list">
                Records
              </MenuItemTitle>
            }
          >
            <AntDMenu.Item key="companiesListRecords">
              <MenuItemTitle path="/company">
                Companies
              </MenuItemTitle>
            </AntDMenu.Item>
            <AntDMenu.Item key="peopleListRecords">
              <MenuItemTitle path="/people">
                People
              </MenuItemTitle>
            </AntDMenu.Item>
            <AntDMenu.Item key="funnelsDealsRecords">
              <MenuItemTitle path="/deals">
                Deals and Funnels
              </MenuItemTitle>
            </AntDMenu.Item>
          </AntDMenu.SubMenu>
          {/* Reports Menu */}
          <AntDMenu.Item className={styles.topItem} key="reports">
            <MenuItemTitle active={reportsActive} icon="fa-chart-bar" legacyPath="/reports" path="/reports/overview/user">
              Reports
            </MenuItemTitle>
          </AntDMenu.Item>
        </AntDMenu>
      ) : (
        <AntDMenu
          className={styles.menu}
          mode="horizontal"
          // @ts-ignore
          triggerSubMenuAction="click"
        >
          {/* Maps Menu */}
          <AntDMenu.SubMenu
            className={styles.topItem}
            popupClassName={styles.submenu}
            key="map"
            popupOffset={[0, 28]}
            title={
              <MenuItemTitle active={mapActive} hasSubmenu icon="fa-map">
                Map
              </MenuItemTitle>
            }
          >
            <AntDMenu.Item key="companiesMap">
              <MenuItemTitle icon="fa-building" legacyPath="/accounts/map">
                Companies Map
              </MenuItemTitle>
            </AntDMenu.Item>
            <AntDMenu.Item key="peopleMap">
              <MenuItemTitle icon="fa-users" legacyPath="/contacts/map">
                People Map
              </MenuItemTitle>
            </AntDMenu.Item>
            <AntDMenu.Item key="dealsMap">
              <MenuItemTitle icon="fa-handshake" legacyPath="/deals/map">
                Deals Map
              </MenuItemTitle>
            </AntDMenu.Item>
            <AntDMenu.Item key="territoriesMap">
              <MenuItemTitle icon="fa-flag" legacyPath="/accounts/territories/map">
                Territories Map
              </MenuItemTitle>
            </AntDMenu.Item>
          </AntDMenu.SubMenu>
          {/* Plan Menu */}
          <AntDMenu.SubMenu
            className={styles.topItem}
            popupClassName={styles.submenu}
            key="plan"
            popupOffset={[0, 28]}
            title={
              <MenuItemTitle active={planActive} hasSubmenu icon="fa-check-double">
                Plan
              </MenuItemTitle>
            }
          >
            <AntDMenu.Item key="activitiesPlan">
              <MenuItemTitle icon="fa-calendar-alt" path="/activity">
                Activities
              </MenuItemTitle>
            </AntDMenu.Item>
            <AntDMenu.SubMenu
              key="routesPlan"
              title={
                <MenuItemTitle hasSubmenu icon="fa-route">
                  Routes
                </MenuItemTitle>
              }
            >
              <AntDMenu.Item key="companyRoutesPlan">
                <MenuItemTitle legacyPath="/accounts/routing/list">
                  Company Routes
                </MenuItemTitle>
              </AntDMenu.Item>
              <AntDMenu.Item key="peopleRoutesPlan">
                <MenuItemTitle legacyPath="/contacts/routing/list">
                  People Routes
                </MenuItemTitle>
              </AntDMenu.Item>
            </AntDMenu.SubMenu>
            <AntDMenu.SubMenu
              key="groupsPlan"
              title={
                <MenuItemTitle hasSubmenu icon="fa-layer-group">
                  Groups
                </MenuItemTitle>
              }
            >
              <AntDMenu.Item key="companyGroupsPlan">
                <MenuItemTitle legacyPath="/accounts/groups/list">
                  Company Groups
                </MenuItemTitle>
              </AntDMenu.Item>
              <AntDMenu.Item key="peopleGroupsPlan">
                <MenuItemTitle legacyPath="/contacts/groups/list">
                  People Groups
                </MenuItemTitle>
              </AntDMenu.Item>
              <AntDMenu.Item key="dealGroupsPlan">
                <MenuItemTitle legacyPath="/deals/groups/list">
                  Deal Groups
                </MenuItemTitle>
              </AntDMenu.Item>
            </AntDMenu.SubMenu>
          </AntDMenu.SubMenu>

          {/* Records Menu */}
          <AntDMenu.SubMenu
            className={styles.topItem}
            popupClassName={styles.submenu}
            key="records"
            popupOffset={[0, 28]}
            title={
              <MenuItemTitle active={listActive} hasSubmenu icon="fa-clipboard-list">
                Records
              </MenuItemTitle>
            }
          >
            <AntDMenu.Item key="companiesListRecords">
              <MenuItemTitle icon="fa-building" legacyPath="/accounts/list" path="/company">
                Companies List
              </MenuItemTitle>
            </AntDMenu.Item>
            <AntDMenu.Item key="peopleListRecords">
              <MenuItemTitle icon="fa-users" legacyPath="/contacts/list" path="/people">
                People List
              </MenuItemTitle>
            </AntDMenu.Item>
            <AntDMenu.Item key="funnelsDealsRecords">
              <MenuItemTitle
                icon="fa-funnel-dollar"
                legacyPath="/deals/list"
                path="/deals"
                // only available for v2 beta
                pathVersions={[Version.V2_BETA]}
              >
                Funnels/Deals
              </MenuItemTitle>
            </AntDMenu.Item>
          </AntDMenu.SubMenu>

          {/* Manage Menu */}
          <AntDMenu.SubMenu
            className={styles.topItem}
            popupClassName={styles.submenu}
            popupOffset={[0, 28]}
            key="manage"
            title={
              <MenuItemTitle active={manageActive} hasSubmenu icon="fa-briefcase">
                Manage
              </MenuItemTitle>
            }
          >
            <AntDMenu.Item key="importsManage">
              <MenuItemTitle icon="fa-file-import" legacyPath="/import" path="/import">
                Imports
              </MenuItemTitle>
            </AntDMenu.Item>
            <AntDMenu.Item key="manageUsersManage">
              <MenuItemTitle icon="fa-users-cog" legacyPath="/dashboard/teams">
                Manage Users
              </MenuItemTitle>
            </AntDMenu.Item>
          </AntDMenu.SubMenu>

          {/* Reports Menu */}
          <AntDMenu.Item className={styles.topItem} key="reports">
            <MenuItemTitle active={reportsActive} icon="fa-chart-bar" legacyPath="/reports" path="/reports/overview/user">
              Reports
            </MenuItemTitle>
          </AntDMenu.Item>
        </AntDMenu>
      )}
    </nav>
  );
};

export default Menu;
