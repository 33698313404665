import React from 'react';
import cn from 'classnames';

interface Props {
  className?: string,
  icon: string,
  onClick?: React.MouseEventHandler<HTMLElement>
  type?: string,
}

const FontAwesomeIcon: React.FC<Props> = ({className, icon, onClick, type = 'fas'}) => {
  return (
    // eslint-disable-next-line
    <i className={cn(className, type, icon)} onClick={onClick} />
  );
};

export default FontAwesomeIcon;
