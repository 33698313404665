import BaseNetworkService from '../network-services/base-network-service/base-network-service';
import accountsNetworkService from '../network-services/accounts-network-service';
import contactsNetworkService from '../network-services/contacts-network-service';
import dealsNetworkService from '../network-services/deals-network-service';
import analyticsService from '../shared-services/analytics-service';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import mapActions from '../store/store-helpers';

export default function GroupsService(
  FetchCRMDataService, SettingsService, MappingService,
  AllContactsService, AccountsService, FunnelService,
) {
  // main utility functions
  const MODEL = window.DataModel;

  // service to return
  const service = {};
  mapActions(service, ['modals']);

  const typeToGroupKey = {
    contacts: 'selectedContactGroups',
    deals: 'selectedDealGroups',
    accounts: 'selectedAccountGroups',
  };

  // fetch map data for groups page for specific type
  service.fetchGroupsForMap = async function (type) {
    // fetch data from settings
    let data = [];
    const settingsData = await SettingsService.getUserSettings();
    const selectedGroup = typeToGroupKey[type];
    MODEL.GroupsService.selectedGroups = settingsData.data.find(obj => obj.key === selectedGroup).value;
    const filters = {groups: {$any: MODEL.GroupsService.selectedGroups}, includeGroups: true};
    switch (type) {
      case 'accounts':
        if (!MODEL.GroupsService.selectedGroups.length) {
          MODEL.accountsCount = 0;
          AccountsService.setViewHeaders(true);
          return;
        }
        data = await accountsNetworkService.fetchAllAccounts(true, filters);
        MODEL.accountsCount = data.total;
        AccountsService.parseAccounts(data.data);
        MappingService.parseMappingObjects(MODEL.accounts, type);
        break;
      case 'contacts':
        if (!MODEL.GroupsService.selectedGroups.length) {
          MODEL.contactsCount = 0;
          AllContactsService.setViewHeaders(true);
          return;
        }
        data = await contactsNetworkService.fetchAllContacts(true, filters);
        MODEL.contactsCount = data.total;
        AllContactsService.parseContacts(data.data);
        MappingService.parseMappingObjects(MODEL.contacts, type);
        break;
      case 'deals':
        if (!MODEL.GroupsService.selectedGroups.length) {
          MODEL.dealsCount = 0;
          return;
        }

        data = await dealsNetworkService.fetchAllDeals(true, filters);
        FunnelService.parseDeals(data.data);
        MODEL.dealsCount = MODEL.deals.length;
        MappingService.parseMappingObjects(MODEL.deals, type);
        break;
    }
  };

  // fetch list data for groups page for specific type
  service.fetchGroupsForList = async function (type, filters, page, column, ascending) {
    let data = [];

    page = page || MODEL.cachedState[`${type}Groups`].page;
    column = column || MODEL.cachedState[`${type}Groups`].column;
    ascending = ascending || MODEL.cachedState[`${type}Groups`].ascending;

    switch (type) {
      case 'accounts':
        data = await Promise.all([accountsNetworkService.fetchAllAccountGroups(filters, page, column, ascending), SettingsService.getUserSettings()]);
        MODEL.GroupsService.currentPageGroups = data[0].data;
        MODEL.GroupsService.currentPageGroupsCount = data[0].total;
        MODEL.GroupsService.selectedGroups = service.formatSelectedGroupsArray(
          data[1].data.find(obj => obj.key === 'selectedAccountGroups').value,
        );
        break;
      case 'contacts':
        data = await Promise.all([contactsNetworkService.fetchAllContactGroups(filters, page, column, ascending), SettingsService.getUserSettings()]);
        MODEL.GroupsService.currentPageGroups = data[0].data;
        MODEL.GroupsService.currentPageGroupsCount = data[0].total;
        MODEL.GroupsService.selectedGroups = service.formatSelectedGroupsArray(data[1].data.find(obj => obj.key === 'selectedContactGroups').value);
        break;
      case 'deals':
        data = await Promise.all([dealsNetworkService.fetchAllDealGroups(filters, page, column, ascending), SettingsService.getUserSettings()]);
        MODEL.GroupsService.currentPageGroups = data[0].data;
        MODEL.GroupsService.currentPageGroupsCount = data[0].total;
        MODEL.GroupsService.selectedGroups = service.formatSelectedGroupsArray(data[1].data.find(obj => obj.key === 'selectedDealGroups').value);
        break;
    }
    MODEL.GroupsService.currentGroupsPage = type;

    if (!MODEL.GroupsService.currentPageGroupsCount && !MODEL.currentLeadsView && !MODEL.FilterService.filtersSelected.length) {
      service.modalsActions.showModal('noGroupsModal');
    } else {
      service.modalsActions.resetVisibility('noGroupsModal');
    }
  };

  service.fetchTotalGroupsCount = async function () {
    const accountsRequest = accountsNetworkService.fetchAccountGroupsCount();
    const contactsRequest = contactsNetworkService.fetchContactGroupsCount();
    const dealsRequest = dealsNetworkService.fetchDealGroupsCount();

    const [
      accountGroupsResponse, contactGroupsResponse, dealGroupsResponse,
    ] = await Promise.all([
      accountsRequest, contactsRequest, dealsRequest,
    ]);

    const totalGroupsCount = accountGroupsResponse + contactGroupsResponse + dealGroupsResponse;
    window.DataModel.GroupsService.totalGroupsCount = totalGroupsCount;
    return totalGroupsCount;
  };

  service.setViewHeaders = (title, count, showFilter = true) => {
    const btn = 'Add Group';
    const showAdd = true;
    FetchCRMDataService.setCurrentPageVariable(title, count, btn, showFilter, showAdd);
  };

  // toggle group selection
  service.toggleIndividualGroup = function (currentGroupId) {
    const selectedGroupsPayload = [];
    MODEL.GroupsService.selectedGroups.forEach((toggleOn, groupId) => {
      if (toggleOn) selectedGroupsPayload.push(groupId);
    });

    // look at the currently toggled group --> if it has items in it and was just checked on
    // then, show the footer detail bar to let user know the map has updated
    const currentGroupObj = MODEL.GroupsService.currentPageGroups.find(obj => obj.id === currentGroupId);
    if (currentGroupObj.items > 0) MODEL.GroupsService.showFooterDetails = true;

    // send request
    service.updateSelectedGroups(MODEL.GroupsService.currentPageSelectedGroupsEndpoint, selectedGroupsPayload);
  };

  // toggles all groups on the current page on/off
  service.toggleAllGroups = function (toggleOn) {
    // reset selected groups completely
    const selectedGroupsPayload = [];

    // toggle all groups on
    if (toggleOn) {
      MODEL.GroupsService.currentPageGroups.forEach((group) => {
        selectedGroupsPayload.push(group.id);
        MODEL.GroupsService.selectedGroups[group.id] = true;
      });
    } else {
      MODEL.GroupsService.selectedGroups = [];
    }

    // send request
    service.updateSelectedGroups(MODEL.GroupsService.currentPageSelectedGroupsEndpoint, selectedGroupsPayload);
  };

  // sends actual request to update selected groups for the given key
  service.updateSelectedGroups = async function (userSettingsKey, value) {
    const res = await SettingsService.updateUserSettings(userSettingsKey, value);
    analyticsService.clicked(['Groups', 'Update selected groups'], {groups: value});
    return res;
  };

  // formats the selected groups into correct format (e.g. [3: true, 4: false])
  service.formatSelectedGroupsArray = function (groupsSelected) {
    const selectedGroups = [];
    groupsSelected.forEach((groupId) => {
      selectedGroups[groupId] = true;
    });
    return selectedGroups;
  };

  // change cadence value and update to parse
  service.updateGroup = async (group) => {
    const org = safeLocalStorage.currentUser.organization.id;
    const endPoint = `organizations/${org}/${MODEL.GroupsService.currentPageEndpoint}/${group.id}`;
    const payload = group;
    const response = await BaseNetworkService.update(endPoint, payload);
    analyticsService.entityUpdated('Group', payload);
    return response;
  };

  // creates a new group
  service.createGroup = name => {
    const org = safeLocalStorage.currentUser.organization.id;
    const endPoint = `organizations/${org}/${MODEL.GroupsService.currentPageEndpoint}`;
    return BaseNetworkService.create(endPoint, {name, displayOrder: 1});
  };

  // delete group
  service.deleteGroup = function (group) {
    const org = safeLocalStorage.currentUser.organization.id;
    const endPoint = `organizations/${org}/${MODEL.GroupsService.currentPageEndpoint}/${group.id}`;
    return BaseNetworkService.delete(endPoint);
  };

  // fetch individual group
  service.fetchGroup = function (crmEntity, id) {
    const org = safeLocalStorage.currentUser.organization.id;
    const endPoint = `organizations/${org}/${crmEntity}-groups/${id}`;
    return BaseNetworkService.read(endPoint);
  };

  // gets all records for one specific group
  service.getAllRecordsForGroup = async function (group, showCadenceColors, crmActivityId) {
    const org = safeLocalStorage.currentUser.organization.id;
    const filters = {includeGroups: true, groups: {$any: [group.id]}};
    if (showCadenceColors) {
      filters.cadence = true;
    }
    if (crmActivityId) {
      filters.crmActivityTypeId = crmActivityId;
    }
    const endPoint = `organizations/${org}/${MODEL.GroupsService.currentGroupsPage}?$limit=${MODEL.recordsUpperLimit}&$filters=${JSON.stringify(filters)}`;
    const response = await BaseNetworkService.read(endPoint);
    MODEL.GroupsService.pinsInGroup = response.data;
    // populate the map with pins in this group
    if (response.data) {
      switch (MODEL.GroupsService.currentGroupsPage) {
        case 'accounts': {
          const accountsData = MODEL.MappingService.toggleCadence ? service.parseCadenceColors(response.data) : response.data;
          AccountsService.parseAccounts(accountsData);
          MappingService.parseMappingObjects(MODEL.accounts.filter(rec => rec.geoPoint), MODEL.GroupsService.currentGroupsPage);
          break;
        }
        case 'contacts': {
          // parse response to have every pin be red (overdue) or white (not overdue)
          const contactsData = MODEL.MappingService.toggleCadence ? service.parseCadenceColors(response.data) : response.data;
          AllContactsService.parseContacts(contactsData);
          MappingService.parseMappingObjects(MODEL.contacts.filter(rec => rec.geoPoint), MODEL.GroupsService.currentGroupsPage);
          break;
        }
        case 'deals': {
          const dealsData = FunnelService.parseDeals(response.data);
          MappingService.parseMappingObjects(dealsData.filter(rec => rec.geoPoint), MODEL.GroupsService.currentGroupsPage);
          break;
        }
      }
      window.refreshDom({loader: false}, 'show');
    } else {
      swal('Yikes!', 'There are no pins in this group yet.');
    }
  };

  // converts input of records into all red/white/grey pins
  service.parseCadenceColors = function (records) {
    return records.map(record => {
      let color = 'grey';
      if (record.outOfCadence) {
        color = 'red';
      } else if (record.outOfCadence === false) {
        color = 'white';
      }
      return {...record, color};
    });
  };

  // passes back the correct groups endpoints for the given CRM page (accounts/contacts/deals)
  service.determineGroupsEndpointsForPage = function () {
    switch (MODEL.GroupsService.currentGroupsPage) {
      case 'accounts':
        MODEL.GroupsService.currentPageEndpoint = 'account-groups';
        MODEL.GroupsService.currentPageSelectedGroupsEndpoint = 'selectedAccountGroups';
        break;
      case 'contacts':
        MODEL.GroupsService.currentPageEndpoint = 'contact-groups';
        MODEL.GroupsService.currentPageSelectedGroupsEndpoint = 'selectedContactGroups';
        break;
      case 'deals':
        MODEL.GroupsService.currentPageEndpoint = 'deal-groups';
        MODEL.GroupsService.currentPageSelectedGroupsEndpoint = 'selectedDealGroups';
        break;
    }
  };

  return service;
}

GroupsService.$inject = [
  'FetchCRMDataService', 'SettingsService', 'MappingService',
  'AllContactsService', 'AccountsService', 'FunnelService',
];
