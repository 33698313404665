import safeLocalStorage from '../shared-services/safe-local-storage-service';
import helperService from '../shared-services/helper-service';
import userNetworkService from '../network-services/user-network-service';

export default function RegisterService($http, $timeout) {
  // main utility functions
  const MODEL = window.DataModel;
  const env = window.__env;

  // service to return
  const service = {};

  service.redirectToHome = () => {
    // redirect to home page
    MODEL.show.loader = false;
    $timeout(() => {
      window.location.href = '#/';
    }, 200);
  };

  // registers the user
  service.registerUser = function (email, fullName, password, phone, findUs, teamName, teamDomain, numFieldReps, inviteCode, agree) {
    MODEL.show.loader = true;
    const registerData = {};
    registerData.username = email.toLowerCase();
    registerData.fullName = fullName;
    registerData.password = password;
    registerData.phone = phone;
    registerData.numFieldReps = numFieldReps;
    registerData.orgMetaData = {tnc: agree};
    let url = '';
    let userData = '';

    // invitation-code flow
    if (inviteCode) {
      registerData.invitationCode = inviteCode;
      url = `${env.BaseURL}accept-invitation`;
    } else {
      // normal signup flow
      registerData.type = findUs;
      registerData.organizationName = teamName;
      registerData.organizationDomain = teamDomain;
      url = `${env.BaseURL}signup`;
    }

    return helperService.executeWithCaptcha(
      'signup',
      (token) => {
        // type (team or individual)
        const body = {...registerData, token};
        return $http({method: 'POST', url, data: body})
          .then((data) => {
            userData = data.data;
            safeLocalStorage.currentUser = userData;
            const body = {
              username: registerData.username,
              password,
              grant_type: 'password',
              client_id: 'mmc-web-client-id',
              scope: 'test',
            };
            const headers = {
              'Content-Type': 'application/x-www-form-urlencoded',
            };
            const url = `${window.__env.BaseURL}oauth/token`;

            const bodyParams = Object.keys(body).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`).join('&');

            return $http({
              method: 'POST', headers, url, data: bodyParams,
            });
          })
          .then(data => {
            if (data.status !== 200) {
              swal('Oops...', "We weren't able to register you at this time. Please contact support@mapmycustomers.me for help.", 'error');
            } else {
              data = data.data;
              window.accessToken = data.accessToken;
              safeLocalStorage.accessToken = data.accessToken;
            }
            return userNetworkService.getUserData();
          })
          .then(data => {
            safeLocalStorage.currentUser = data;
            service.redirectToHome();
          })
          .catch(errorData => {
            const result = errorData.data;
            if (result.validationErrors && result.validationErrors.length) {
              const error = result.validationErrors[0];
              swal('Oops...', error.message, 'error');
            } else {
              swal('Oops...', "We weren't able to register you at this time. Please contact support@mapmycustomers.me for help.", 'error');
            }
            MODEL.show.loader = false;
          });
      },
    );
  };

  // check if string has an uppercase letter in it
  service.checkIfHasUppercase = pass => pass !== pass.toLowerCase();

  // checking special characters
  function isSpecialChar(char) {
    const code = char.charCodeAt(0);
    return (code > 32 && code < 48) || (code > 57 && code < 65) || (code > 90 && code < 97) || (code > 122 && code < 127);
  }

  // check if string has a special letter in it
  service.checkIfHasSpecialChar = pass => pass.split('').some(isSpecialChar);

  service.evaluatePassword = (password) => {
    const minChars = password && password.length >= 6;
    const strength = window.zxcvbn(password).score;
    return {minChars, isValid: minChars, strength};
  };

  service.doesUserExist = async (email) => {
    try {
      return (await userNetworkService.userExists(email)).userExists;
    } catch (e) {
      return undefined;
    }
  };

  return service;
}

RegisterService.$inject = ['$http', '$timeout'];
