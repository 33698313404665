import React, {useState, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import moment, {Moment} from 'moment';
import business from 'moment-business';
import {Col, Row, Divider, Select, DatePicker} from 'antd';
import IStep from './IStep';
import ButtonBar from './ButtonBar';
import BlockSpace from '../../../../components/Layout/BlockSpace';
import integrationsSlice from '../../../../../store/integrations';
import FrequencyType from '../../../../type/enum/FrequencyType';

const {Option} = Select;

const disabledDate = (current: Moment) => current && (current < moment().endOf('day').add(1, 'd') || moment(current).day() === 0 || moment(current).day() === 6);
interface Props extends IStep {
  changeFrequency: typeof integrationsSlice.actions.changeFrequency,
  changeStartSyncDate: typeof integrationsSlice.actions.changeStartSyncDate,
  serviceName: string,
}

const Step3: React.FC<Props> = ({
  changeFrequency,
  changeStartSyncDate,
  className,
  onNextClick,
  onPreviousClick,
  style,
  serviceName,
}) => {
  const [startSyncDate, setStartSyncDate] = useState<Moment>(business.addWeekDays(moment(), 2));
  const [frequency, setFrequency] = useState<FrequencyType>(FrequencyType.DAILY);

  useEffect(() => {
    changeStartSyncDate({nextRunAt: startSyncDate.toISOString()});
    changeFrequency({frequency});
  }, [changeFrequency, changeStartSyncDate]);

  const handleFrequencyChange = useCallback((frequency) => {
    setFrequency(frequency);
    changeFrequency({frequency});
  }, [setFrequency, changeFrequency]);

  const handleChangeDate = useCallback((nextRunAt) => {
    setStartSyncDate(nextRunAt);
    changeStartSyncDate({nextRunAt: moment(nextRunAt).toISOString()});
  }, [setStartSyncDate, changeStartSyncDate]);

  return (
    <Row className={className} style={style}>
      <Col span={24}>
        <BlockSpace direction="vertical" size="large">
          <Row>
            <Col span={24}>
              <Divider orientation="left">Please select the frequency to sync your records with {serviceName}.</Divider>
              <Select style={{width: '50%'}} defaultValue={FrequencyType.DAILY} placeholder="Select frequency" onChange={handleFrequencyChange}>
                <Option value={FrequencyType.DAILY}>Daily</Option>
                <Option value={FrequencyType.WEEKLY}>Weekly</Option>
                <Option value={FrequencyType.MONTHLY}>Monthly</Option>
              </Select>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Divider orientation="left">Please choose which day you would like you begin your sync.</Divider>
              <DatePicker placeholder="Select your desired sync day" style={{width: '50%'}} disabledDate={disabledDate} allowClear={false} onChange={handleChangeDate} value={startSyncDate} />
            </Col>
          </Row>

          <ButtonBar
            nextButtonTitle="Save Changes"
            nextType="primary"
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
          />
        </BlockSpace>
      </Col>
    </Row>
  );
};

const mapDispatchToProps = {
  changeFrequency: integrationsSlice.actions.changeFrequency,
  changeStartSyncDate: integrationsSlice.actions.changeStartSyncDate,
};

export default connect(null, mapDispatchToProps)(Step3);
