import React, {memo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import Switch from 'react-md/lib/SelectionControls/Switch';
import {findByValue} from '../util/util';
import {PlanCard, PlanDescription, PlanName, PlanPrice, PlanText, PlanWarning} from './PlanCard';
import AddonType from '../../../type/plans/Addon';
import Payment from './Payment';

const AddonSwitch = styled(Switch)`
  margin: 20px 0 20px 8px;
`;

interface Props {
  addon: AddonType,
  checked: boolean,
  liveLicenses: number,
  licenses: number,
  onCheck?: (id: AddonType['id'], checked: boolean) => void,
  wasChecked: boolean,
}

const Addon = memo<Props>(({addon, checked, licenses, liveLicenses, onCheck, wasChecked}) => {
  const oldPrice = findByValue(addon.intervalPrice, liveLicenses);
  const price = findByValue(addon.intervalPrice, licenses);

  const {t} = useTranslation(['billing']);
  const handleChange = useCallback((checked) => onCheck && onCheck(addon.id, checked), [onCheck, addon.id]);

  if (!price && !(wasChecked && oldPrice)) {
    return null;
  }

  // is addon free or not
  const hasPrice = (price?.onboardingPrice && price?.onboardingPrice.value > 0)
    || (price?.subscriptionPrice && price?.subscriptionPrice.value > 0);

  return (
    <PlanCard>
      <AddonSwitch
        aria-label={addon.name}
        checked={checked || !hasPrice}
        disabled={!price || wasChecked || !hasPrice}
        id={`${addon.id}-switch`}
        name={`${addon.id}-${addon.name}`}
        onChange={handleChange}
      />
      <PlanText isAvailable={!!price}>
        <PlanName htmlFor={`${addon.id}-switch`} pointer>{addon.name}</PlanName>
        {addon.description && (<PlanDescription>{addon.description}</PlanDescription>)}
        {price && wasChecked && (<PlanDescription>{t('Contact Sales to unsubscribe')}</PlanDescription>)}
        {!price && (
          <PlanWarning red>{t('This add-on is not available on a new tier')}</PlanWarning>
        )}
      </PlanText>
      <PlanPrice>
        <Payment payment={price} />
        {oldPrice && oldPrice !== price && wasChecked && (
          <div>{t('Prorated')}</div>
        )}
      </PlanPrice>
    </PlanCard>
  );
});

export default Addon;
