import {useMemo} from 'react';
import deepmerge from 'deepmerge';
import sortedUniq from 'lodash/sortedUniq';
import moment from 'moment';
import ListResponse from '../../../../type/service/ListResponse';
import Activity from '../../../../type/Activity';
import {
  ActivityCount,
  DEFAULT_DATE_FORMAT,
  getDateGranularity,
  getDateRangeTooltipFormatter,
  getSortedActivityUsers,
  groupActivitiesByUserIdAndDate,
} from './util';
import dateFormatter from '../../../../util/formatter/date';

const useActivitiesChartConfig = (
  defaultConfig: object,
  data: ListResponse<Activity> | undefined,
  dateRange: string,
  groupByAssignee: boolean = true,
) =>
  useMemo(
    () => {
      const sortedActivities = (data?.data || [])
        .map((activity) => ({...activity, startAt: moment(activity.startAt).startOf(getDateGranularity(dateRange))}))
        .sort((a, b) => a.startAt.valueOf() - b.startAt.valueOf());
      const userToActivityCountMap = groupActivitiesByUserIdAndDate(sortedActivities, groupByAssignee);
      const userList = getSortedActivityUsers(sortedActivities, groupByAssignee);

      return deepmerge.all([
        {},
        defaultConfig,
        {
          tooltip: {
            formatter: getDateRangeTooltipFormatter(dateRange),
          },
          xAxis: {
            categories: sortedUniq(
              sortedActivities.map(({startAt}) => dateFormatter(startAt, DEFAULT_DATE_FORMAT)),
            ),
          },
          series: userList.map((user) => ({
            name: user.name,
            data: userToActivityCountMap[`${user.id}`]
              .map(({date, count}: ActivityCount) => [dateFormatter(date, DEFAULT_DATE_FORMAT), count]),
          })),
        },
      ]);
    },
    // not dependent on dateRange because otherwise chart is redrawn when range is changed
    // but new data is not yet loaded. Can probably be also fixed by changing chart setting isPureConfig to false
    [data],
  );

export default useActivitiesChartConfig;
