import React, {useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {Select, Table} from 'antd';
import {ColumnType} from 'antd/es/table';
import styled from 'styled-components';
import EntityFieldModel from '../../../../common/field-model/entity-field-model';
import Field from '../../../../common/field-model/field/field';
import fieldCategoryDisplayName from '../../../util/formatter/fieldCategoryDisplayName';
import FieldCategory from '../../../type/enum/FieldCategory';
import isDefined from '../../../util/isDefined';
import IntegrationField from '../../../type/integration/IntegrationField';
import Checkbox from '../../../components/Checkbox/Checkbox';
import integrationsSlice from '../../../../store/integrations';
import {SupportedEntityType} from '../../../type/integration/Integration';
import EntityType from '../../../type/EntityType';
import {CreateNewCustomField} from '../../../../store/integrations';

class StringSelect extends Select<string> {}
const FieldSelect = styled(StringSelect)`
  width: 100%;
`;

const getMappingTableColumnConfig = (
  entityType: SupportedEntityType,
  fieldModel: EntityFieldModel,
  fieldCategories: Partial<{[key in FieldCategory]: Field[]}>,
  serviceName: string,
  usedFieldNames: Set<string>,
  onSearch: (value: string) => void,
  onChangeMapping: typeof integrationsSlice.actions.changeIntegrationField,
  onChangeSyncing: typeof integrationsSlice.actions.changeIntegrationFieldSync,
  onChangeGrouping: typeof integrationsSlice.actions.changeIntegrationFieldGroup,
): ColumnType<IntegrationField>[] => {
  const handleFieldChange = (integrationField: IntegrationField, mmcFieldName: string) => {
    onChangeMapping({entityType, integrationField, field: mmcFieldName === CreateNewCustomField? mmcFieldName : fieldModel.getByName(mmcFieldName)});
  };

  const columns = [
    {
      title: `Map ${serviceName} Field`,
      dataIndex: 'crmDisplayName',
      key: 'crmDisplayName',
    },
    {
      title: 'To...',
      dataIndex: 'mmcField',
      key: 'mmcField',
      render: (mappedField: string | null, integrationField: IntegrationField) => {
        return (
          <FieldSelect
            dropdownMatchSelectWidth={false}
            showSearch
            onChange={(fieldName) => handleFieldChange(integrationField, fieldName)}
            onSearch={onSearch}
            filterOption={false}
            value={mappedField || ''}
          >
            <Select.Option key={0} value="">None</Select.Option>

            {(Object.keys(fieldCategories) as FieldCategory[]).map(category => (
              <Select.OptGroup key={category} label={fieldCategoryDisplayName(category)}>
                {fieldCategories[category]!.map(field => (
                  <Select.Option
                    disabled={usedFieldNames.has(field.name)}
                    key={field.name}
                    value={field.name}
                  >
                    {field.displayName}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ))}
            
            <Select.Option key={CreateNewCustomField} value={CreateNewCustomField}>Create new custom field</Select.Option>
          </FieldSelect>
        );
      },
    },
    {
      title: 'Sync Enabled',
      dataIndex: 'syncing',
      key: 'syncing',
      render: (syncing: boolean, integrationField: IntegrationField) => (
        <Checkbox
          checked={syncing}
          onChange={(enabled) => onChangeSyncing({entityType, integrationField, enabled})}
        />
      ),
    },
  ];

  // we don't support grouping for activities yet
  if (entityType !== EntityType.ACTIVITY) {
    columns.push({
      title: 'Grouping',
      dataIndex: 'mmcGroupField',
      key: 'mmcGroupField',
      render: (mmcGroupField: string | null, integrationField: IntegrationField) => (
        <Checkbox
          checked={!!mmcGroupField}
          onChange={(enabled) => onChangeGrouping({entityType, integrationField, enabled})}
        />
      ),
    });
  }
  return columns;
};

interface Props {
  changeFieldGrouping: typeof integrationsSlice.actions.changeIntegrationFieldGroup,
  changeFieldMapping: typeof integrationsSlice.actions.changeIntegrationField,
  changeFieldSyncing: typeof integrationsSlice.actions.changeIntegrationFieldSync,
  dataset: IntegrationField[],
  entityType: SupportedEntityType,
  fieldModel: EntityFieldModel,
  serviceName: string,
}

const FieldMappingTable: React.FC<Props> = ({
  changeFieldGrouping,
  changeFieldMapping,
  changeFieldSyncing,
  dataset,
  entityType,
  fieldModel,
  serviceName,
}) => {
  const [filterString, setFilterString] = useState('');
  const fieldCategories: Partial<{[key in FieldCategory]: Field[]}> = useMemo(
    () => {
      const filter = filterString.toLowerCase().trim();
      return (Object.entries(fieldModel.categorizedIntegrationFields) as [FieldCategory, Field[]][])
        .map(([category, fields]) =>
          [category, fields.filter(({displayName}) => displayName.toLowerCase().includes(filter))])
        .filter(([, fields]) => fields.length)
        .reduce(
          (result, [category, fields]) => Object.assign(result, {[category as FieldCategory]: fields}),
          {},
        );
    },
    [fieldModel, filterString],
  );
  const usedFieldNames = useMemo(
    () => new Set(dataset.map(({mmcField}) => mmcField || undefined).filter(isDefined)),
    [dataset],
  );
  const tableColumnConfig = useMemo(
    () => getMappingTableColumnConfig(
      entityType,
      fieldModel,
      fieldCategories,
      serviceName,
      usedFieldNames,
      setFilterString,
      changeFieldMapping,
      changeFieldSyncing,
      changeFieldGrouping,
    ),
    [entityType, fieldCategories, fieldModel, serviceName, setFilterString, usedFieldNames],
  );

  return (
    <Table<IntegrationField>
      columns={tableColumnConfig}
      dataSource={dataset}
      pagination={false}
      rowKey="id"
    />
  );
};

const mapDispatchToProps = {
  changeFieldGrouping: integrationsSlice.actions.changeIntegrationFieldGroup,
  changeFieldMapping: integrationsSlice.actions.changeIntegrationField,
  changeFieldSyncing: integrationsSlice.actions.changeIntegrationFieldSync,
};

export default connect(null, mapDispatchToProps)(FieldMappingTable);
