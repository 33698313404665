import angular from 'angular';
import ReportsCtrl from './reports-controller';
import ReportsService from './reports-service';
import {ReportsListController, reportsList} from './reports-list/reports-list-component';
import {CreateReportController, createReport} from './create-report/create-report-component';
import {ReportFoldersController, reportFolders} from './report-folders/report-folders-component';
import './reports.scss';

export default angular.module('mapmycustomersApp.reports', [])
  .controller('ReportsCtrl', ReportsCtrl)
  .factory('ReportsService', ReportsService)
  .component('reportsList', reportsList)
  .controller('ReportsListController', ReportsListController)
  .component('createReport', createReport)
  .controller('CreateReportController', CreateReportController)
  .component('reportFolders', reportFolders)
  .controller('ReportFoldersController', ReportFoldersController)
  .name;
