import React, {useState} from 'react';
import Dropdown from 'antd/es/dropdown';
import {Badge} from 'antd';
import {connect} from 'react-redux';
import MenuItemTitle from '../MenuItemTitle';
import Information from './component/Information';
import {RootState} from '../../../../../store/rootReducer';
import {getUnreadTotal} from '../../../../../store/notification';
import withStore from '../../../../hoc/withStore';
import {fetchNotificationsUnreadTotal} from '../../../../../store/notification/actions';
import useInterval from '../../../../hook/useInterval';
import styles from './Notifiation.module.scss';

interface Props {
  fetchNotificationsUnreadTotal: typeof fetchNotificationsUnreadTotal,
  unreadTotal: number,
}

const Notification: React.FC<Props> = ({
  fetchNotificationsUnreadTotal,
  unreadTotal,
}) => {
  const [visible, setVisible] = useState(false);

  useInterval(fetchNotificationsUnreadTotal, true, 60000, true);

  return (
    <Dropdown
      className={styles.container}
      onVisibleChange={setVisible}
      overlay={<Information onHide={() => setVisible(false)} />}
      placement="bottomRight"
      trigger={['click']}
      visible={visible}
    >
      <Badge className={styles.badge} count={unreadTotal} overflowCount={9}>
        <MenuItemTitle icon="fa-bell" iconType="fas" onClick={() => setVisible(true)} />
      </Badge>
    </Dropdown>
  );
};


const mapStateToProps = (state: RootState) => ({
  unreadTotal: getUnreadTotal(state),
});

const mapDispatchToProps = {
  fetchNotificationsUnreadTotal,
};

export default withStore(connect(mapStateToProps, mapDispatchToProps)(Notification));
