import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';

export default function ReportNetworkService() {
  const getReportUrl = (id, generate) => {
    let url = `organizations/${safeLocalStorage.currentUser.organization.id}/reports`;
    if (id) {
      url += `/${id}`;
      if (generate) {
        url += '/generate';
      }
    }

    return url;
  };

  const fetchReports = (params) => BaseNetworkServiceWithPromises.read(getReportUrl(), params);

  const createReport = (name, description, entityType, filters, selectedColumns, customFields) => {
    const payload = {
      name,
      description,
      selectedColumns,
      tableName: entityType,
      isStarred: false,
      selectedFilters: filters,
      customFields,
      link: null,
    };
    // It is important to convert payload to a string here, otherwise Angular will do that
    // for us and it will cut off all keys with $ at the beginning. Which can lead to spoiling
    // selectedFilters field. Our filters use keys with $ (like $any or $in) pretty often.
    return BaseNetworkServiceWithPromises.create(getReportUrl(), JSON.stringify(payload));
  };

  const updateReport = (report) => BaseNetworkServiceWithPromises.update(getReportUrl(report.id), report);

  const deleteReport = (reportId) => BaseNetworkServiceWithPromises.delete(getReportUrl(reportId));

  const downloadReport = (reportId, username) => BaseNetworkServiceWithPromises.create(
    getReportUrl(reportId, true),
    {username},
  );

  return {
    fetchReports,
    createReport,
    updateReport,
    deleteReport,
    downloadReport,
  };
}
