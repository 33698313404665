import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';

class GeocodingNetworkService {
  // convert address to lat/lng
  async geocodeAddress(addressJson) {
    const orgId = safeLocalStorage.currentUser.organization.id;
    const endPoint = `organizations/${orgId}/geocode/address`;

    try {
      const response = await BaseNetworkServiceWithPromises.create(endPoint, addressJson);
      if (response && response.geoPoint) {
        return response;
      }
      return null;
    } catch (e) {
      // log or just silently ignore an error if we don't need to show an error message for geocoding failures
      return null;
    }
  }

  // convert lat/lng to address
  async reverseGeocodeAddress(latitude, longitude) {
    const orgId = safeLocalStorage.currentUser.organization.id;
    const geoPoint = {type: 'Point', coordinates: [longitude, latitude]};
    const endPoint = `organizations/${orgId}/geocode/latlon`;

    try {
      const response = await BaseNetworkServiceWithPromises.create(endPoint, geoPoint);
      if (response && response.address) {
        return response;
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  async getGeocodingLimits() {
    const orgId = safeLocalStorage.currentUser.organization.id;
    const endPoint = `organizations/${orgId}/geocode/limits`;

    try {
      const response = await BaseNetworkServiceWithPromises.read(endPoint);
      return response;
    } catch (e) {
      return null;
    }
  }
}

export default new GeocodingNetworkService();
