import template from './add-group-modal.html';

class AddGroupModalController {
  constructor($window, GroupsService) {
    Object.assign(this, {
      $window,
      MODEL: $window.DataModel,
      UTILS: $window.mmcUtils,
      GroupsService,
    });
  }

  closeAddGroupModal() {
    this.MODEL.GroupsService.showAddGroupModal = false;
    this.MODEL.GroupsService.newGroupName = '';
  }

  async submitGroup(groupName) {
    try {
      const res = await this.GroupsService.createGroup(groupName);

      if (res.validationErrors) {
        return this.UTILS.showValidationError(res);
      }
      // success --> add new group to top of groups list
      this.MODEL.GroupsService.currentPageGroups.unshift(res);
      this.MODEL.GroupsService.currentPageGroupsCount++;
      this.$window.refreshDom({
        'GroupsService.currentPageGroups': this.MODEL.GroupsService.currentPageGroups,
        'GroupsService.showAddGroupModal': false,
        'currentPageSubHeader.numberOfObjects': this.MODEL.GroupsService.currentPageGroupsCount,
      });
    } catch (error) {
      return swal('Uh-Oh', "Something's not right. Please try saving again!", 'error');
    }
  }
}

AddGroupModalController.$inject = ['$window', 'GroupsService'];

const addGroupModal = {
  bindings: {},
  controller: 'AddGroupModalController',
  template,
};

export {AddGroupModalController, addGroupModal};
