import swal from 'sweetalert2';
import template from './files.html';
import './files.scss';

class FilesController {
  constructor($window, FilesService, $scope, MainService) {
    Object.assign(this, {
      $window, FilesService, $scope, MainService,
    });

    /**
     * List of files
     * @type {{}[]}
     */
    this.files = [];
    /**
     * File opened for a larger preview.
     * @type {{}}
     */
    this.file = undefined;
    /**
     * Files which were marked as invalid (exceed size limit, wrong extension, etc.)
     * @type {{}[]}
     */
    this.invalidFiles = [];
  }

  async $onInit() {
    await this.refreshFiles();
  }

  /**
   * Reloads file list
   * @return {Promise<void>}
   */
  async refreshFiles() {
    this.MainService.toggleLoader(true);
    this.files = await this.FilesService.getFiles();
    this.MainService.toggleLoader(false);
  }

  /**
     * Uploads one or more files
     * @param {File[]} files
     * @return {Promise<void>}
     */
  async upload(files) {
    this.MainService.toggleLoader(true);
    await this.FilesService.uploadFiles(files);
    this.MainService.toggleLoader(false);
    this.refreshFiles();
  }

  /**
     * Opens larger preview popup
     * @param {{}} file
     */
  showPopup(file) {
    this.fileView = file;
  }

  /**
     * Hides preview popup and releases resources
     */
  hidePopup() {
    if (this.fileView.dataUrl && !this.fileView.isPublic) {
      this.$window.URL.revokeObjectURL(this.fileView.dataUrl);
    }
    delete this.fileView.dataUrl;
    this.fileView = undefined;
  }

  /**
     * Gets data-url for the preview
     * @param {{}} file
     * @return {Promise<void>}
     */
  async getFilePreview(file) {
    if (!file.previewDataUrl) {
      file.previewDataUrl = await this.FilesService.getFilePreview(
        file,
        this.MainService.getCurrentCrmObjectType(),
        this.MainService.getCurrentCrmObjectId(),
      );
      this.$scope.$digest();
    }
  }

  /**
     * Gets data-url for the full view
     * @param {{}} file
     * @return {Promise<void>}
     */
  async getFile(file) {
    if (!file.dataUrl) {
      file.dataUrl = await this.FilesService.getFile(
        file,
        this.MainService.getCurrentCrmObjectType(),
        this.MainService.getCurrentCrmObjectId(),
      );
      this.$scope.$digest();
    }
  }

  /**
     * Releases preview's file data-url
     * @param {{isPublic: boolean, previewDataUrl: string|null}} file
     */
  revokePreviewUrl(file) {
    if (!file.isPublic && file.previewDataUrl) {
      this.$window.URL.revokeObjectURL(file.previewDataUrl);
    }
  }

  /**
     * Deletes given file
     * @param {{id: number}} file
     * @return {Promise<void>}
     */
  async deleteFile(file) {
    this.MainService.toggleLoader(true);
    try {
      await this.FilesService.deleteFile(file.id);
      const index = this.files.findIndex(({id}) => file.id === id);

      if (index >= 0) {
        this.files.splice(index, 1);
      }

      this.hidePopup();
      swal('Success!', 'This file has been deleted.', 'success');
    } catch (e) {
      console.error(e);
      swal('Uh-Oh', "We couldn't delete this file. Try again.", 'error');
    }

    this.MainService.toggleLoader(false);
    this.$scope.$digest();
  }

  /**
     * Downloads given file
     * @param file
     * @return {boolean}
     */
  downloadFile(file) {
    this.FilesService.downloadFile(
      file,
      this.MainService.getCurrentCrmObjectType(),
      this.MainService.getCurrentCrmObjectId(),
    );
    return false;
  }

  /**
     * Returns file category
     * @param {{contentType: string, name: string}} file
     * @return {"image"|"pdf"|"xls"}
     */
  getFileCategory(file) {
    if (file.contentType.startsWith('image/')) {
      return 'image';
    }
    const name = file.name.toLowerCase();
    if (name.endsWith('.xls') || name.endsWith('.xlsx')) {
      return 'xls';
    }
    if (name.endsWith('.pdf')) {
      return 'pdf';
    }

    return undefined;
  }
}

FilesController.$inject = ['$window', 'FilesService', '$scope', 'MainService'];

const files = {
  bindings: {
    hasUpdateAccess: '<',
    hasDeleteAccess: '<',
  },
  controller: 'FilesController',
  template,
};

export {FilesController, files};
