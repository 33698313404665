import React, {ReactNode} from 'react';
import styled from 'styled-components';
import theme from '../../style/theme';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const Title = styled.div`
  margin: 0;
  font-weight: normal;
  font-size: 34px;
  color: ${theme.color.label};
`;

interface Props {
  children: ReactNode,
  title: string,
}

const Header: React.FC<Props> = ({children, title}) => {
  return (
    <Container>
      <Title>{title}</Title>
      {children && (
        <div>
          {children}
        </div>
      )}
    </Container>
  );
};

export default Header;
