import moment from 'moment';
import get from 'lodash/get';
import template from './routing-table.html';
import mapActions from '../../../store/store-helpers';
import {doesUseMiles} from '../../../common/settings';

class RoutingTableController {
  constructor($window, $route, MainService) {
    Object.assign(this, {
      $window,
      $route,
      MODEL: $window.DataModel,
      MAN: $window.mmcUtils.MAN,
      MainService,
    });

    mapActions(this, ['RoutingService']);
  }

  $onInit() {
    this.selectedBoxes = this.MODEL.selectedBoxes;
    this.useMiles = doesUseMiles();
    this.isCreatingRoute = this.$route.current.id === 'routeCreatePage';
    this.allottedTimeValues = [0, 300, 900, 1800, 2700, 3600];
    this.objectIdToAllottedTime = {};
    this.objectIdToAllottedTimeInitialized = false;
  }

  getArrivalTime(arrivalTime, index) {
    let secondCount = 0;
    for (let i = 0; i < index; i++) {
      const obj = this.MODEL.RoutingService.routeObjects[i];
      const allottedTime = this.objectIdToAllottedTime[obj.id];
      secondCount += allottedTime * 60;
    }
    return moment(arrivalTime).add(secondCount, 'seconds').format('h:mm a');
  }

  isTableAvailable() {
    return (
      (get(this.MODEL, 'RoutingService.currentRoute.routeDetail.allottedTime') !== undefined) ||
      get(this.MODEL, 'RoutingService.currentRoute.stopTime')
    ) && !!get(this.MODEL, 'RoutingService.routeObjects.length');
  }

  initAllottedTimeToObjectIds() {
    this.objectIdToAllottedTimeInitialized = false;
    const stopTime = this.MODEL.RoutingService.currentRoute.stopTime || [];
    const stopTimeMap = new Map(stopTime.map(row => [row.accountId ?? row.contactId, row]));
    if (!Object.keys(this.objectIdToAllottedTime).length) {
      this.objectIdToAllottedTime = (
        (window.isOnPage('accounts')
          ? this.MODEL.RoutingService.currentRoute.routeAccounts
          : this.MODEL.RoutingService.currentRoute.routeContacts) ?? this.MODEL.RoutingService.routeObjects
      )
        .reduce((result, object) => {
          const stopTimeRow = stopTimeMap.get(object.id);
          const allottedTime = stopTimeRow
            ? stopTimeRow.allottedTime
            : this.MODEL.RoutingService.currentRoute.routeDetail.allottedTime;
          result[object.id] = allottedTime / 60;
          return result;
        }, {});
    }
    this.objectIdToAllottedTimeInitialized = true;
  }

  visitPinInRoute(id) {
    this.MODEL.MappingService.singlePinData = {id};
    window.refreshDom({'MappingService.singlePinData': this.MODEL.MappingService.singlePinData});
  }

  getAddress(row) {
    return [row.address, row.region, row.city, row.country].filter(element => !!element).join(', ');
  }

  selectRecord(allRecords = false) {
    this.MainService.selectRecord(allRecords);
  }

  removeStop(stop) {
    this.RoutingServiceActions.updateRouteObjects(this.MODEL.RoutingService.routeObjects.filter(row => row.id !== stop.id), true);
    this.RoutingServiceActions.updateEditFlag({rebuildRoute: true});
  }

  init(objectId) {
    if (!this.objectIdToAllottedTime[objectId]) {
      const stopTime = this.MODEL.RoutingService.currentRoute.stopTime || [];
      const stopTimeMap = new Map(stopTime.map(row => [row.accountId ?? row.contactId, row]));
      const stopTimeRow = stopTimeMap.get(objectId);
      const allottedTime = stopTimeRow
        ? stopTimeRow.allottedTime
        : this.MODEL.RoutingService.currentRoute.routeDetail.allottedTime;
      this.objectIdToAllottedTime[objectId] = allottedTime / 60;
      this.updateAllottedTime({allottedTime: this.objectIdToAllottedTime});
    }
  }
}

RoutingTableController.$inject = ['$window', '$route', 'MainService'];

const routingTable = {
  bindings: {
    updateAllottedTime: '&',
    getDirectionsToAddress: '&',
    routeEditing: '=',
  },
  controller: 'RoutingTableController',
  template,
};

export {RoutingTableController, routingTable};
