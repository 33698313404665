import trim from 'lodash/trim';
import set from 'lodash/set';
import helperService from '../shared-services/helper-service';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import customFieldsNetworkService from '../network-services/custom-fields-network-service';
import dealsNetworkService from '../network-services/deals-network-service';
import mapActions from '../store/store-helpers';

export default function CommonCtrl(
  $scope, $rootScope, $timeout, BaseController, CustomerService,
  AddEditService, SettingsService,
) {
  const MODEL = window.DataModel;
  $scope.show = window.DataModel.show;
  $scope.data = window.DataModel;

  mapActions($scope, ['modals']);

  $scope.quickAddCrmRecord = async () => {
    BaseController.quickAddCrmRecord();
    if (MODEL.editPopup.addButtonName.includes('Deal')) {
      MODEL.show.loader = true;
      $scope.deals.quickFunnelAdd = '';
      $scope.deals.quickStageAdd = '';
      const response = await dealsNetworkService.fetchAllFunnels();
      if (response.data) {
        $scope.funnels = response.data;
      } else {
        swal(
          'Uh-oh',
          'There was a network error. Please try again...',
          'error',
        );
      }
      window.refreshDom({loader: false}, 'show');
    }
  };

  $scope.contacts = {
    quickNameAdd: '',
    quickAddressAdd: '',
    quickCityAdd: '',
    quickStateAdd: '',
    quickCountryAdd: '',
    quickPostalCodeAdd: '',
  };
  $scope.deals = {
    quickNameAdd: '',
    quickAccountNameAdd: '',
    quickStageAdd: '',
  };
  $scope.accounts = {
    quickNameAdd: '',
    quickAddressAdd: '',
    quickCityAdd: '',
    quickStateAdd: '',
    quickCountryAdd: '',
  };
  $scope.funnels = [];
  $scope.stages = [];

  window.$apply = () => {
    if (!$rootScope.$$phase) {
      $rootScope.$apply();
    }
  };

  $scope.refreshDom = (argObj, modKey) => $timeout(() => {
    Object.keys(argObj).forEach(key => {
      if (modKey) {
        MODEL[modKey][key] = argObj[key];
      } else {
        set(MODEL, key, argObj[key]);
      }
    });
  }, 50);
  window.refreshDom = $scope.refreshDom;

  // ----------- Onboarding starts ------------->

  // Show/hide tour start popup
  MODEL.showTourPopFn = show => {
    // set tour flag true in both the cases
    window.refreshDom({showTourPop: show});
    SettingsService.updateUserSettings(
      'lastWebProductTourSeenAt',
      new Date().toISOString(),
    );
  };

  $scope.data.productTour.showDemo = flag => {
    if (flag) {
      $('#sticky-nav').click(() => {
        if ($scope.data.productTour.inProgress) {
          return false;
        }
      });
      $('.search-toolbox').attr('disabled', 'disabled');
      $('.target-elem').click(() => false);
    } else {
      $('#sticky-nav').off('click');
      $('#addCRMDropdown li a').off('click');
      $('.search-toolbox').removeAttr('disabled');
    }
  };

  // Highlight target element
  $scope.changeTargetElem = (selector, hide) => {
    $('.target-elem').removeClass('target-elem');
    const step = hopscotch.getCurrStepNum();
    $scope.data.productTour.remSteps =
            $scope.data.productTour.numSteps - step - 1;
    if (!hide) {
      $(selector).addClass('target-elem');
    }
  };

  $scope.closeTourFn = (close, e) => {
    if (close) {
      if (!$scope.data.resumeTourPop && $scope.data.productTour.remSteps) {
        window.refreshDom({resumeTourPop: true});
        $('.hopscotch-bubble').hide();
        e.stopPropagation();
        return false;
      }
      $scope.changeTargetElem('', true);
      $rootScope.showTourOverlay = false;
      window.refreshDom({resumeTourPop: false});
      $scope.data.productTour.inProgress = false;
      $scope.data.productTour.showDemo(false);
      $('.hopscotch-bubble').remove();
    } else {
      window.refreshDom({resumeTourPop: false});
      $('.hopscotch-bubble').show();
      e.stopPropagation();
    }
  };
  window.closeTourFn = $scope.closeTourFn;

  // -------Onboarding tour ends here --------->

  $scope.hideQuickCreateModal = entityType => {
    const lookup = {
      accounts: 'quickCreateAccount',
      deals: 'quickCreateDeal',
      contacts: 'quickCreateContact',
    };

    $scope.modalsActions.hideModal(lookup[entityType]);
  };

  $scope.clearQuickPopup = () => {
    $scope.accounts.quickNameAdd = '';
    $scope.accounts.quickAddressAdd = '';
    $scope.accounts.quickCityAdd = '';
    $scope.accounts.quickStateAdd = '';
    $scope.accounts.quickCountryAdd = '';
  };

  $scope.cancelQuickCreate = entityType => {
    $scope.clearQuickPopup();
    $scope.hideQuickCreateModal(entityType);
  };

  const prepareCustomFieldValueToPost = fieldId => {
    const field = MODEL.quickCreateCustomFields.find(
      ({id}) => id === fieldId,
    );
    if (!field) {
      return;
    }

    const replaceValue = value => ({...MODEL.quickCreateCustomFieldValues[fieldId], value});

    if (field.dataType === 'single-option') {
      return replaceValue([
        parseInt(MODEL.quickCreateCustomFieldValues[fieldId].value[0], 10),
      ]);
    }
    if (field.dataType === 'multi-option') {
      return replaceValue(
        MODEL.quickCreateCustomFieldValues[fieldId].value[0].map(value => parseInt(value, 10)),
      );
    } if (field.dataType === 'date') {
      return replaceValue([MODEL.quickCreateCustomFieldValues[fieldId].value[0]]);
    } if (field.dataType === 'monetary') {
      return replaceValue([
        {
          currencyId: parseInt(MODEL.quickCreateCustomFieldValues[fieldId].value[0].currencyId, 10),
          value: parseInt(MODEL.quickCreateCustomFieldValues[fieldId].value[0].value, 10),
        },
      ]);
    }

    return MODEL.quickCreateCustomFieldValues[fieldId];
  };

  $scope.saveQuickCreate = async function (entityType) {
    let response;
    if (!$scope.isValidQuickAddDialog(entityType)) {
      return;
    }
    $scope.hideQuickCreateModal(entityType);
    if (entityType === 'contacts') {
      const newContact = {
        user: {id: safeLocalStorage.currentUser.id},
        organization: {id: safeLocalStorage.currentUser.organization.id},
        name: $scope.contacts.quickNameAdd,
        firstName: $scope.contacts.quickFirstNameAdd,
        lastName: $scope.contacts.quickLastNameAdd,
        address: $scope.contacts.quickAddressAdd,
        city: $scope.contacts.quickCityAdd,
        region: $scope.contacts.quickStateAdd,
        country: $scope.contacts.quickCountryAdd,
        postalCode: $scope.contacts.quickPostalCodeAdd,
        table: 'contacts',
        color: 'black',
      };
      if (MODEL.currentAssociatedAccountId) {
        newContact.account = {id: MODEL.currentAssociatedAccountId};
      }

      response = await CustomerService.saveQuickRecord(
        entityType,
        newContact,
      );
    } else if (entityType === 'accounts') {
      const newAccount = {
        name: $scope.accounts.quickNameAdd,
        address: $scope.accounts.quickAddressAdd,
        city: $scope.accounts.quickCityAdd,
        region: $scope.accounts.quickStateAdd,
        country: $scope.accounts.quickCountryAdd,
        user: {id: safeLocalStorage.currentUser.id},
        organization: {id: safeLocalStorage.currentUser.organization.id},
      };

      response = await CustomerService.saveQuickRecord(
        entityType,
        newAccount,
      );
    } else if (entityType === 'deals') {
      const newDeal = {
        username: safeLocalStorage.currentUser.username,
        name: $scope.deals.quickNameAdd,
        account: {id: parseInt(MODEL.currentCrmObjectId, 10)},
        stage: {id: $scope.deals.quickStageAdd},
        funnel: {id: $scope.deals.quickFunnelAdd},
      };
      response = await CustomerService.saveQuickDeal(newDeal);
    } else {
      return;
    }

    if (!response || !response.id) {
      CustomerService.showQuickCreateView();
      return;
    }

    MODEL[entityType].unshift(response);

    try {
      const valuesToSave = Object.keys(
        MODEL.quickCreateCustomFieldValues,
      ).map(fieldId => prepareCustomFieldValueToPost(parseInt(fieldId, 10)));
      await Promise.all(
        valuesToSave.map(value => customFieldsNetworkService.createValue(
          entityType,
          response.id,
          value,
        )),
      );
    } catch (e) {
      swal('Uh-oh', 'Some custom field values were not saved', 'error');
    }

    $scope.associateCrmObject(response.id);
    $scope.clearQuickPopup();
    $scope.modalsActions.hideModal('addEditPopup');
  };

  $scope.changePassword = function (currentPass, newPass, confirmPass) {
    SettingsService.changePassword(currentPass, newPass, confirmPass)
      .then(
        res => console.log(res),
      )
      .catch((error) => {
        helperService.showAndLogError(error);
      });
  };

  $scope.associateCrmObject = crmObjectId => {
    $scope.modalsActions.hideModal('addEditPopup');
    AddEditService.associateCrmObject(
      MODEL.editPopup.editPopupType,
      crmObjectId,
    );
  };

  $scope.isValidQuickAddDialog = contextType => {
    if (!contextType || !$scope[contextType]) {
      return false;
    }
    const hasName = !!trim($scope[contextType].quickNameAdd);
    const hasAddress = !!trim($scope[contextType].quickAddressAdd);
    const hasCountry = !!trim($scope[contextType].quickCountryAdd);
    const hasEnoughInformationToGeocode =
            !hasAddress || (hasAddress && hasCountry);

    if (!MODEL.quickCreateCustomFields.length) {
      return hasName && hasEnoughInformationToGeocode;
    }

    const customFieldValues = Object.values(
      MODEL.quickCreateCustomFieldValues,
    );
    const areAllCustomFieldValuesSet =
            customFieldValues.length ===
            customFieldValues.filter(
              ({value}) => Array.isArray(value) && value.length && value[0] !== undefined,
            ).length;

    return (
      hasName && hasEnoughInformationToGeocode && areAllCustomFieldValuesSet
    );
  };
}

CommonCtrl.$inject = [
  '$scope', '$rootScope', '$timeout', 'BaseController', 'CustomerService',
  'AddEditService', 'SettingsService',
];
