import helperService from '../shared-services/helper-service';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import {getEntityDisplayName} from '../common/field-model/formatter';

export default function ImportCtrl(
  $scope, $location, $timeout, MainService, BaseController, ImportService,
) {
  // init global utility functions
  const MODEL = window.DataModel;

  // set localStorage equal to $scope to get html-binding
  $scope.localStorage = safeLocalStorage;

  // bind data model to scope for  access in views
  $scope.data = MODEL;
  $scope.importStep = 0;
  $scope.activeDetails = 'errors';

  $scope.stepTitles = ['Instructions', 'Choose File', 'Import Options', 'Map Columns'];
  $scope.importFile = undefined;
  $scope.marketingURL = window.__env.marketingURL;
  $(document.body).show();
  window.refreshDom({loader: false}, 'show');

  // extend BaseController methods onto scope
  Object.assign($scope, BaseController);

  // cancel entire import flow
  $scope.cancelImportFrame = function () {
    $scope.importStep = 0;
    $scope.importFile = undefined;
    ImportService.resetImport();
  };

  const initDropZone = (attemptsLeft = 10) => {
    if (!attemptsLeft) {
      return;
    }
    if (!$('#submit-csv').length && attemptsLeft > 0) {
      $timeout(() => initDropZone(attemptsLeft - 1), 250);
      return;
    }
    ImportService.dropzonify('#submit-csv');
  };

  // cancel entire import flow
  $scope.backImportFrame = function () {
    if ($scope.importStep > 0) {
      $scope.importStep--;
      if ($scope.importStep === 1) {
        $timeout(() => initDropZone(), 1);
      }
    }
  };

  // go to next import frame
  $scope.nextImportFrame = function () {
    if ($scope.importStep === 0) {
      $scope.importStep++;
      $timeout(() => initDropZone(), 1);
    } else if ($scope.importStep === 1) {
      if ($scope.importFile) {
        MODEL.ImportService.importDataArray.matchedColumns = [];
        MODEL.ImportService.importDataArray.unmatchedColumns = [];
        $scope.importStep++;
      }
    } else if ($scope.importStep === 2) {
      const {crmView, spreadsheetHeaders, spreadsheetPreview} = MODEL.ImportService;
      ImportService.populateColumnsForEntity(crmView);
      ImportService.populateMatchedAndUnmatchedColumns(spreadsheetHeaders, spreadsheetPreview);
      $scope.importStep++;
    } else if ($scope.importStep === 3) {
      ImportService.completeImport($scope.importFile)
        .then((successful) => {
          if (successful) {
            $location.url('/dashboard');
          }
        })
        .catch((error) => {
          helperService.showAndLogError(error);
        });
    } else {
      $scope.importStep++;
    }
  };

  $scope.isContinueButtonEnabled = () => {
    if ($scope.importStep === 1) {
      return !!$scope.importFile;
    }

    return true;
  };

  $scope.getNumImportPinsLeft = function () {
    return MODEL.ImportService.pinsLeft;
  };

  $scope.getImportTimeRemaining = function () {
    return MODEL.ImportService.timeRemaining;
  };

  $scope.removeAttachedFile = () => {
    $scope.importFile = undefined;
  };

  $scope.refreshDropzone = function (file) {
    // go to the next frame
    $scope.importFile = file;
    $scope.importStep++;
    $('#dropzone-element').html(`
      <i class="ion-upload"></i>
      <br/>
      <strong>Drag and drop a CSV/Excel file here to upload</strong>
      <br/>
      or
      <br/>
      <span>Select a file</span>
    `);
    $scope.$digest(); // apply step increment
  };
  window.refreshDropzone = $scope.refreshDropzone;

  $scope.toggleColumns = ImportService.toggleColumns;

  $scope.createNewCustomField = ImportService.createNewCustomField;

  $scope.cancelCustomField = function (columnIndex, isMatchedColumn) {
    const columnList = MODEL.ImportService.importDataArray[isMatchedColumn ? 'matchedColumns' : 'unmatchedColumns'];
    const column = columnList[columnIndex];
    const skipColumn = MODEL.ImportService.mmcColumns.find(({skip}) => skip);
    column.matchedFieldName = skipColumn.key;
    if (isMatchedColumn) {
      MODEL.ImportService.creatingNewColumnForMatchedColumnIndex = undefined;
    } else {
      MODEL.ImportService.creatingNewColumnForUnmatchedColumnIndex = undefined;
    }
  };

  $scope.showHelpView = function (type) {
    if (type === 'mapped') {
      swal('Mapped Customers', 'Mapped column denotes the customers that have address. These customers will be shown on the Map page.', 'warning');
    } else if (type === 'unmapped') {
      swal('UnMapped Customers', 'UnMapped column denotes the customers that do not have address. These customers will be shown on the People page.', 'warning');
    } else {
      swal('Updated Customers', 'Updated column denotes the customers that are already present in Map My Customers and have been updated with the spreadsheet data.', 'warning');
    }
  };

  /**
   * Called when the user wishes to display the errors and warnings for the user
   * @param {Object} i The import object to show details for
   */
  $scope.showDetails = async (i) => {
    const imp = {
      errors: [],
      warnings: [],
    };

    const response = await ImportService.fetchImportData(i.id);
    response.data.forEach((row) => {
      const {errors, warnings} = row;
      if (errors) {
        if (Array.isArray(errors.validationErrors)) {
          errors.validationErrors.forEach(({message, field, code}) => {
            imp.errors.push({
              row: errors.row, message, field, code,
            });
          });
        } else if (errors.row && errors.message) {
          imp.errors.push({
            row: errors.row, message: errors.message, field: '', code: '',
          });
        }
        if (warnings) {
          imp.warnings.push(warnings);
        }
      }
    });
    window.refreshDom(
      {errorsWarningsImport: imp},
      'ImportService',
    );
  };

  $scope.hideDetails = () => {
    window.refreshDom(
      {errorsWarningsImport: undefined},
      'ImportService',
    );
  };

  $scope.setActiveDetails = state => {
    $scope.activeDetails = state;
  };

  $scope.getSpecialFieldCount = (importRow, fieldName) => {
    const value = importRow[fieldName];
    if (value) {
      if (Array.isArray(value)) {
        return value.length;
      }
      return parseInt(value, 10);
    }
    return 0;
  };

  $scope.getEntityDisplayName = getEntityDisplayName;
}

ImportCtrl.$inject = [
  '$scope', '$location', '$timeout', 'MainService', 'BaseController', 'ImportService',
];
