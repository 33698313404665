import safeLocalStorage from './safe-local-storage-service';

export default function FetchCRMDataService($route, SettingsService) {
  // main utility functions
  const UTILS = window.mmcUtils;
  const MODEL = window.DataModel;

  // service to return
  const service = {};

  /* ------------------- PARSE RAW DATA FROM REDIS INTO MODEL VARS ------------------- */

  // set the current page data var
  service.setCurrentPageVariable = (
    title, numberOfObjects = 0, addButtonName, showFilterButton = true, showAddButton = true,
  ) => {
    // show the current page header variables
    MODEL.currentPageSubHeader.title = title;
    MODEL.currentPageSubHeader.numberOfObjects = numberOfObjects;
    MODEL.currentPageSubHeader.addButtonName = addButtonName;

    // show/hide the add button
    if (showAddButton) {
      $('#addButton').show();
      MODEL.showAddBtn = true;
    } else {
      MODEL.showAddBtn = false;
      $('#addButton').hide();
    }
    // show/add the filter button
    if (showFilterButton) {
      $('#filterButton').show();
      MODEL.showFilterBtn = true;
    } else {
      MODEL.showFilterBtn = false;
      $('#filterButton').hide();
    }
    window.refreshDom({title, numberOfObjects, addButtonName}, 'currentPageSubHeader');
  };

  // scrolls page down slightly
  service.scrollSlowlyIfUnscrolled = function () {
    setTimeout(() => {
      if (!$('.main-panel').scrollTop()) { // if we're at top of page after two seconds
        window.mmcGlobals.slowScroll = setInterval(() => { // set an interval to start scrolling us down slowly
          // note: the != 1 is an attempted hack to fix apple, where it _seems_ that they land at 1px and things don't work.
          if ($('.main-panel').scrollTop() !== MODEL.scrollHeight && $('.main-panel').scrollTop() !== 1) { // if we detect that the user has scrolled
            clearInterval(window.mmcGlobals.slowScroll); // then kill the interval
          } else { // otherwise, continue on scrolling
            MODEL.scrollHeight += 1;
            $('.main-panel').scrollTop(MODEL.scrollHeight);
          }
        }, 50);
      }
    }, 2000);
  };

  // set the subheader map/list view colors
  service.setSubHeaderDomView = () => {
    // if not on the activities page
    if (!window.isOnPage('activities')) {
      // lookup to check which page we are on and highlight map and list view tab
      const lookup = {
        dealsMapPage: true,
        accountsMapPage: true,
        contactsMapPage: true,
        accountsGroupsMapPage: true,
        contactsGroupsMapPage: true,
        dealsGroupsMapPage: true,
        territoriesMapPage: true,
        accountsRoutingMapPage: true,
        contactsRoutingMapPage: true,
      };

      if (lookup[$route.current.id]) {
        $('.subheader-map-view').addClass('currently-active').removeClass('currently-inactive');
        $('.subheader-list-view').addClass('currently-inactive').removeClass('currently-active');
        $('#subheader-map-view-img').attr('src', './images/sub-nav/map-active.svg');
        $('#subheader-list-view-img').attr('src', './images/sub-nav/list-inactive.svg');
      } else {
        $('.subheader-list-view').addClass('currently-active').removeClass('currently-inactive');
        $('.subheader-map-view').addClass('currently-inactive').removeClass('currently-active');
        $('#subheader-map-view-img').attr('src', './images/sub-nav/map-inactive.svg');
        $('#subheader-list-view-img').attr('src', './images/sub-nav/list-active.svg');
      }
    }
  };

  // set the navbar active elements
  service.setHeaderDomView = () => {
    if (window.isOnPage('accounts')) {
      $('#nav-bar-accounts').addClass('navbar-active').removeClass('navbar-in-active');
      $('#nav-bar-contacts').addClass('navbar-in-active').removeClass('navbar-active');
      $('#nav-bar-deals').addClass('navbar-active').removeClass('navbar-active');
      $('#nav-bar-dash').addClass('navbar-in-active').removeClass('navbar-active');
      $('#nav-bar-reports').addClass('navbar-in-active').removeClass('navbar-active');
    } else if (window.isOnPage('contacts')) {
      $('#nav-bar-contacts').addClass('navbar-active').removeClass('navbar-in-active');
      $('#nav-bar-accounts').addClass('navbar-in-active').removeClass('navbar-active');
      $('#nav-bar-deals').addClass('navbar-active').removeClass('navbar-active');
      $('#nav-bar-dash').addClass('navbar-in-active').removeClass('navbar-active');
      $('#nav-bar-reports').addClass('navbar-in-active').removeClass('navbar-active');
    } else if (window.isOnPage('deals')) {
      $('#nav-bar-deals').addClass('navbar-active').removeClass('navbar-in-active');
      $('#nav-bar-contacts').addClass('navbar-in-active').removeClass('navbar-active');
      $('#nav-bar-accounts').addClass('navbar-active').removeClass('navbar-active');
      $('#nav-bar-dash').addClass('navbar-in-active').removeClass('navbar-active');
      $('#nav-bar-reports').addClass('navbar-in-active').removeClass('navbar-active');
    } else if (window.isOnPage('dashboard')) {
      $('#nav-bar-dash').addClass('navbar-active').removeClass('navbar-in-active');
      $('#nav-bar-contacts').addClass('navbar-in-active').removeClass('navbar-active');
      $('#nav-bar-deals').addClass('navbar-active').removeClass('navbar-active');
      $('#nav-bar-accounts').addClass('navbar-in-active').removeClass('navbar-active');
      $('#nav-bar-reports').addClass('navbar-in-active').removeClass('navbar-active');
    } else if (window.isOnPage('reports')) {
      $('#nav-bar-reports').addClass('navbar-active').removeClass('navbar-in-active');
      $('#nav-bar-contacts').addClass('navbar-in-active').removeClass('navbar-active');
      $('#nav-bar-deals').addClass('navbar-active').removeClass('navbar-active');
      $('#nav-bar-accounts').addClass('navbar-in-active').removeClass('navbar-active');
      $('#nav-bar-dash').addClass('navbar-in-active').removeClass('navbar-active');
    }
  };

  // set the subheaders value for the respective page
  service.setSubHeadersForPage = () => {
    const lookup = {
      accountsMapPage: ['Mapped Companies', 0, 'Add Company', true, false],
      accountsPage: ['All Companies', 0, 'Add Company', true, false],
      accountsRoutingMapPage: ['Mapped Companies', 0, 'Add Route', true, true],
      contactsMapPage: ['Mapped People', 0, 'Add Person', true, false],
      dealsMapPage: ['Mapped Deals', 0, 'Add Deal', true, false],
      dealsPage: ['All Deals', 0, 'Add Deal', true, false],
      contactsPage: ['All People', 0, 'Add Person', true, false],
      contactsGroupsMapPage: ['Grouped Pins', 0, 'Add Group', false, true],
      accountsGroupsMapPage: ['Grouped Pins', 0, 'Add Group', false, true],
      dealsGroupsMapPage: ['Grouped Pins', 0, 'Add Group', false, true],
      contactsGroupsListPage: ['Groups', 0, 'Add Group', false, true],
      accountsGroupsListPage: ['Groups', 0, 'Add Group', false, true],
      dealsGroupsListPage: ['Groups', 0, 'Add Group', false, true],
      routingPage: ['Routes', 0, 'Add Route', false, true],
      contactsRoutingMapPage: ['Mapped People', 0, 'Add Route', true, true],
      territoriesPage: ['Territories', 0, 'Add Territory', false, true],
      territoriesMapPage: ['Territory People', 0, 'Add Territory', false, true],
    };

    if ($route.current.id in lookup) {
      service.setCurrentPageVariable(...lookup[$route.current.id]);
    }
  };

  // populate current page groups data structure
  service.populateCurrentGroupsData = (tableName) => {
    if (!tableName) {
      if (window.isOnPage('accounts')) {
        tableName = 'accounts';
      } else if (window.isOnPage('contacts')) {
        tableName = 'contacts';
      } else if (window.isOnPage('deals')) {
        tableName = 'deals';
      }
    }

    if (tableName === 'accounts') {
      MODEL.GroupsService.currentPageGroups = safeLocalStorage.currentUser.groupsAccounts ? safeLocalStorage.currentUser.groupsAccounts : [];
      MODEL.currentPageHiddenGroups = safeLocalStorage.currentUser.groupsAccountsHidden ? safeLocalStorage.currentUser.groupsAccountsHidden : [];
      MODEL.currentPageGroupsColors = safeLocalStorage.currentUser.groupsAccountsColors ? safeLocalStorage.currentUser.groupsAccountsColors : {};
      MODEL.currentGroupsPage = 'Companies';
      MODEL.currentGroupsPageAccessRights = MODEL.accessRightsAccounts;
    } else if (tableName === 'contacts') {
      MODEL.GroupsService.currentPageGroups = safeLocalStorage.currentUser.groupsContacts ? safeLocalStorage.currentUser.groupsContacts : [];
      MODEL.currentPageHiddenGroups = safeLocalStorage.currentUser.groupsContactsHidden ? safeLocalStorage.currentUser.groupsContactsHidden : [];
      MODEL.currentPageGroupsColors = safeLocalStorage.currentUser.groupsContactsColors ? safeLocalStorage.currentUser.groupsContactsColors : {};
      MODEL.currentGroupsPage = 'People';
      MODEL.currentGroupsPageAccessRights = MODEL.accessRightsContacts;
    } else if (tableName === 'deals') {
      MODEL.GroupsService.currentPageGroups = safeLocalStorage.currentUser.groupsDeals ? safeLocalStorage.currentUser.groupsDeals : [];
      MODEL.currentPageHiddenGroups = safeLocalStorage.currentUser.groupsDealsHidden ? safeLocalStorage.currentUser.groupsDealsHidden : [];
      MODEL.currentPageGroupsColors = safeLocalStorage.currentUser.groupsDealsColors ? safeLocalStorage.currentUser.groupsDealsColors : {};
      MODEL.currentGroupsPage = 'Deals';
      MODEL.currentGroupsPageAccessRights = MODEL.accessRightsDeals;
    }

    if (UTILS.isOnPage('contactsGroupsListPage') || UTILS.isOnPage('accountsGroupsListPage') || UTILS.isOnPage('dealsGroupsListPage')) {
      service.setCurrentPageVariable('Groups', MODEL.GroupsService.currentPageGroups.length, 'Add Group', false, true);
    }
  };

  // set map page count for warning modal
  service.setcurrentMapPageCount = () => {
    if ($route.current.id === 'contactsMapPage') {
      MODEL.currentPageRecordCount = MODEL.customersCount;
    } else if ($route.current.id === 'dealsMapPage') {
      MODEL.currentPageRecordCount = MODEL.dealsCount;
    } else if ($route.current.id === 'accountsMapPage') {
      MODEL.currentPageRecordCount = MODEL.accountsCount;
    }
  };

  // init list view cols and selected fields for list view page
  service.initListViewCols = async () => {
    if (['contactsPage', 'accountsPage', 'dealsPage', 'leadsPage'].includes($route.current.id)) {
      let listViewColumns = SettingsService.getUserSetting('listViewColumns');
      if (!listViewColumns) {
        listViewColumns = {contacts: [], accounts: [], deals: [], leads: []};
        await SettingsService.updateUserSettings('listViewColumns', listViewColumns);
      }

      const crmObjectType = $route.current.id.replace('Page', '');
      const fieldModelName = `${crmObjectType.replace(/s$/, '')}FieldModel`;
      MODEL.listviewSelectedColumns = listViewColumns[crmObjectType]
        .map(name => MODEL[fieldModelName].getByName(name))
        .filter(field => !!field); // remove falsy values

      // Add name field if it is present in entity fields model and is not present in list.
      const nameField = MODEL[fieldModelName].getByName('name');
      if (nameField && !MODEL.listviewSelectedColumns.includes(nameField)) {
        MODEL.listviewSelectedColumns.unshift(nameField);
      }
      return MODEL.listviewSelectedColumns;
    }
  };

  return service;
}

FetchCRMDataService.$inject = ['$route', 'SettingsService'];
