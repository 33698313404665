import EntityType from '../../type/EntityType';

const nameByEntityType: {[key in EntityType]: string} = Object.freeze({
  [EntityType.COMPANY]: 'Company',
  [EntityType.PERSON]: 'Person',
  [EntityType.DEAL]: 'Deal',
  [EntityType.ROUTE]: 'People Route',
  [EntityType.ACTIVITY]: 'Activity',
  [EntityType.COMPANY_GROUP]: 'Company Group',
  [EntityType.COMPANY_ROUTE]: 'Companies Route',
});

const pluralNameByEntityType: {[key in EntityType]: string} = Object.freeze({
  [EntityType.COMPANY]: 'Companies',
  [EntityType.PERSON]: 'People',
  [EntityType.DEAL]: 'Deals',
  [EntityType.ROUTE]: 'People Routes',
  [EntityType.ACTIVITY]: 'Activities',
  [EntityType.COMPANY_GROUP]: 'Company Groups',
  [EntityType.COMPANY_ROUTE]: 'Companies Routes',
});

const entityDisplayName = (entityType: EntityType, lowerCase: boolean = false, plural: boolean = true): string => {
  const dictionary = plural ? pluralNameByEntityType : nameByEntityType;
  let displayName = dictionary[entityType] || entityType;

  if (lowerCase) {
    displayName = displayName.toLowerCase();
  }
  return displayName;
};

export default entityDisplayName;
