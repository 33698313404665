import Tier from '../../type/plans/Tier';
import {Interval} from '../../type/plans/Interval';
import Addon from '../../type/plans/Addon';
import Service from '../../type/plans/Service';

export const tiers: Tier[] = [
  {
    id: 7,
    name: 'Trial',
    interval: Interval.MONTHLY,
    isTrial: true,
    licenseLimit: [0],
    price: {
      currency: 'USD',
      value: 0.00,
    },
  },
  {
    id: 13,
    name: 'Small',
    interval: Interval.MONTHLY,
    licenseLimit: [1, 3],
    price: {
      currency: 'USD',
      value: 60.00,
    },
  },
  {
    id: 14,
    name: 'Small',
    interval: Interval.ANNUAL,
    licenseLimit: [1, 3],
    price: {
      currency: 'USD',
      value: 600.00,
    },
  },
  {
    id: 15,
    name: 'Medium',
    interval: Interval.ANNUAL,
    licenseLimit: [4, 30],
    price: {
      currency: 'USD',
      value: 840.00,
    },
  },
  {
    id: 16,
    name: 'Enterprise',
    interval: Interval.ANNUAL,
    licenseLimit: [31],
    price: {
      currency: 'USD',
      value: 1100.00,
    },
  },
];

export const addons: Addon[] = [
  // {
  //   id: 5,
  //   name: 'Full Integration',
  //   description: 'Sync your CRM data with our near-instant API Integrations for Hubspot, Salesforce, Zoho, Dynamics.',
  //   intervalPrice: [
  //     [
  //       [31],
  //       {
  //         onboardingPrice: {
  //           value: 3750.00,
  //           currency: 'USD',
  //         },
  //         subscriptionPrice: {
  //           value: 3750.00,
  //           currency: 'USD',
  //         },
  //         subscriptionInterval: Interval.ANNUAL,
  //       },
  //     ],
  //   ],
  // },
  // {
  //   id: 6,
  //   name: 'Automated Imports',
  //   description: 'Automatic imports and exports, Monday through Friday,  to stay on top of your data without the commitment of API.',
  //   intervalPrice: [
  //     [[4, 10], {subscriptionPrice: {value: 2000.00, currency: 'USD'}, subscriptionInterval: Interval.ANNUAL}],
  //     [[11, 30], {subscriptionPrice: {value: 6000.00, currency: 'USD'}, subscriptionInterval: Interval.ANNUAL}],
  //   ],
  // },
];

export const services: Service[] = [
  // {
  //   id: 8,
  //   name: 'Premium Onboarding',
  //   description: 'Our seasoned onboarding experts will guide you through the entire implementation period for maximum adoption and ROI.',
  //   intervalPrice: [
  //     [[4, 10], {value: 2500.00, currency: 'USD'}],
  //     [[10, 30], {value: 6650.00, currency: 'USD'}],
  //     [[30], {value: 19500.00, currency: 'USD'}],
  //   ],
  // },
  // {
  //   id: 9,
  //   name: 'Team Training',
  //   description: 'We’ll set up 3 remote training sessions for your reps so they’ll feel confident and excited to use Map My Customers.',
  //   intervalPrice: [
  //     [[4, 10], {value: 375.00, currency: 'USD'}],
  //     [[10, 30], {value: 1750.00, currency: 'USD'}],
  //     [[30], {value: 6000.00, currency: 'USD'}],
  //   ],
  // },
  // {
  //   id: 10,
  //   name: 'Manager Training',
  //   description: 'We’ll set up 3 remote training sessions for your reps so they’ll feel confident and excited to use Map My Customers.',
  //   intervalPrice: [
  //     [[4, 10], {value: 500.00, currency: 'USD'}],
  //     [[10, 30], {value: 1750.00, currency: 'USD'}],
  //     [[30], {value: 4500.00, currency: 'USD'}],
  //   ],
  // },
  // {
  //   id: 11,
  //   name: 'On-site Training',
  //   description: 'From big-room training to one-on-one personalized training, our specialists will design a program that meets your needs and conduct it entirely on-site.\n',
  //   intervalPrice: [
  //     [[4, 10], {value: 3000.00, currency: 'USD'}],
  //     [[10, 30], {value: 8400.00, currency: 'USD'}],
  //     [[30], {value: 14400.00, currency: 'USD'}],
  //   ],
  // },
  // {
  //   id: 12,
  //   name: 'Custom Usage Reporting',
  //   description: 'Highly specialized usage reports to highlight individual team members usage patterns. Our CSMs will analyze the reports and offer custom insights.  To start on the first of the coming month for one quarter.',
  //   intervalPrice: [
  //     [[4, 10], {value: 1500.00, currency: 'USD'}],
  //     [[10, 30], {value: 2100.00, currency: 'USD'}],
  //     [[30], {value: 3600.00, currency: 'USD'}],
  //   ],
  // },
  // {
  //   id: 13,
  //   name: 'Import/Export Services',
  //   description: 'Our team will work with yours to create a standard template for each of Accounts, Contacts, and Deals to manually sync with another system on a weekly basis. To start on the first of the coming month for one quarter.',
  //   intervalPrice: [
  //     [[4, 10], {value: 1500.00, currency: 'USD'}],
  //     [[10, 30], {value: 2800.00, currency: 'USD'}],
  //     [[30], {value: 6000.00, currency: 'USD'}],
  //   ],
  // },
];

// export const currentPlan: CurrentPlan = {
//   tier: tiers.find(({id}) => id === 10) as Tier,
//   licenses: 9,
//   addonIds: [6],
//   nextPaymentDate: new Date('2021-03-08'),
// };
