import {call, put, takeEvery} from 'redux-saga/effects';
import {
  fetchNotificationsUnreadTotal,
} from './actions';
import notificationSlice from '../notification';
import notificationNetworkService from '../../network-services/notification-network-service';
import {handleError} from '../errors/action';
import NotificationListResponse from '../../react/type/webNotification/NotificationListResponse';
import WebNotification from '../../react/type/webNotification/webNotification';

export function* onFetchNotificationsUnreadTotal() {
  try {
    const response: NotificationListResponse<WebNotification> = yield call(notificationNetworkService.list.bind(notificationNetworkService));
    yield put(notificationSlice.actions.setNotificationsUnreadTotal(response.unreadTotal));
  } catch (err) {
    yield put(handleError(err));
  }
}

export default function* notificationSaga() {
  yield takeEvery(fetchNotificationsUnreadTotal.type, onFetchNotificationsUnreadTotal);
}
