import {useEffect, useState} from 'react';

const defaultRevokeObjectUrl = URL.revokeObjectURL;

const useObjectUrl = (
  createObjectUrl: () => Promise<string | undefined>,
  revokeObjectUrl: (url: string) => void = defaultRevokeObjectUrl,
) => {
  const [url, setUrl] = useState<string | undefined>(undefined);
  useEffect(
    () => {
      let objectUrl: string | undefined;
      createObjectUrl().then((url) => {
        objectUrl = url;
        setUrl(url);
      });
      return () => {
        if (objectUrl) {
          revokeObjectUrl(objectUrl);
        }
        setUrl(undefined);
      };
    },
    [createObjectUrl, revokeObjectUrl],
  );
  return [url];
};

export default useObjectUrl;
