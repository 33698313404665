import {css} from 'styled-components';
import theme from './theme';

const {screenSizes} = theme;

type ScreenSizeType = typeof screenSizes;
type ScreenSizeKey = keyof ScreenSizeType;
type MediaProps = {[key in ScreenSizeKey]: typeof css};
type QueryFactory = <K extends ScreenSizeKey>(sizeValue: ScreenSizeType[K], sizeName: K) => string;

const mediaQueryFactory = (queryFactory: QueryFactory): MediaProps => (
  (Object.keys(screenSizes) as ScreenSizeKey[]).reduce<MediaProps>(
    (result, label) => Object.assign(
      result,
      {
        [label]: (...args: Parameters<typeof css>) => css`
        @media (${queryFactory(screenSizes[label], label)}) {
          ${css(...args)}
        }
      `,
      },
    ),
    // @ts-ignore
    {},
  )
);

// You can use this like ${maxWidth.desktop`width: 100%`}
export const maxWidth = mediaQueryFactory((value) => `max-width: ${value}`);
export const minWidth = mediaQueryFactory((value) => `min-width: ${value}`);
