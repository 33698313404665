import BaseNetworkService from './base-network-service/base-network-service';
import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import Company from '../react/type/Company';
import Person from '../react/type/Person';
import DataModel from '../common/data-model';

type Params = {
  $order?: string,
  $limit?: number,
  $offset?: number,
  $filters?: string,
  $columns?: string,
}

class ContactsNetworkService {
  fetchAllContacts(
    map: boolean,
    filters: object,
    page: number = 1,
    column: string = 'updatedAt',
    ascending: boolean = false,
    columns?: string,
    limit?: number,
  ) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    let params: Params = {
      $order: ascending ? column : `-${column}`,
    };

    if (map) {
      filters = {...filters, mapped: true};
      params.$limit = limit ?? DataModel.recordsUpperLimit;
    } else {
      const {pageSize} = DataModel;
      if (page) {
        params.$offset = (page - 1) * (limit ?? pageSize);
      }

      params.$limit = limit ?? pageSize;
    }

    if (Object.keys(filters).length) {
      params = Object.assign(params, {$filters: JSON.stringify(filters)});
    }

    if (columns) {
      params.$columns = columns;
    }

    return BaseNetworkService.read(`organizations/${orgId}/contacts`, params);
  }

  async searchByName(
    name: string,
    page: number = 1,
    pageSize: number = DataModel.pageSize,
  ) {
    const orgId: number = safeLocalStorage.currentUser!.organization.id;
    return BaseNetworkService.read(
      `organizations/${orgId}/contacts`,
      {
        $order: 'name',
        $filters: JSON.stringify({
          name: {$in: name},
        }),
        $offset: Math.max((page - 1) * pageSize, 0),
        $limit: Math.max(pageSize, 1),
      },
    );
  }

  // fetches a single contact
  fetchSpecificContact(id: Person['id']) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/contacts/${id}`;
    return BaseNetworkService.read(endPoint);
  }

  // fetches contacts for a specific account
  fetchAccountContacts(id: Company['id']) {
    const orgId: number = safeLocalStorage.currentUser!.organization.id;
    const filters = {
      accountId: id,
    };
    const endPoint: string = `organizations/${orgId}/contacts?$filters=${encodeURIComponent(JSON.stringify(filters))}`;
    return BaseNetworkService.read(endPoint);
  }

  fetchTerritoriesContacts(territoryIds: number[]) {
    const orgId: number = safeLocalStorage.currentUser!.organization.id;
    const endPoint: string = `organizations/${orgId}/contacts`;
    const $filters = {territoryIds};
    return BaseNetworkService.read(endPoint, {$filters});
  }

  // fetches all contact groups
  fetchAllContactGroups(
    filters: object | null = null,
    page: number = 1,
    column: string = 'displayOrder',
    ascending: boolean = false,
    limit: number = 0,
  ) {
    const orgId: number = safeLocalStorage.currentUser!.organization.id;
    const {pageSize} = DataModel;
    const params: Params = {
      $order: ascending ? column : `-${column}`,
      $filters: filters ? JSON.stringify(filters) : undefined,
      $limit: limit === 0 ? pageSize : limit,
      $offset: page ? Math.max(0, page - 1) * pageSize : undefined,
    };

    return BaseNetworkService.read(`organizations/${orgId}/contact-groups`, params);
  }

  async fetchContactGroupsCount() {
    const orgId: number = safeLocalStorage.currentUser!.organization.id;
    const endPoint: string = `organizations/${orgId}/contact-groups?$limit=0`;

    return (await BaseNetworkServiceWithPromises.read(endPoint)).total;
  }

  bulkUpdateContactGroups(groupsData: object[]) {
    const orgId: number = safeLocalStorage.currentUser!.organization.id;
    const endPoint: string = `organizations/${orgId}/contact-groups/bulk`;
    const payload: object = {contactGroups: groupsData.map(data => ({...data, _action_: 'update'}))};
    return BaseNetworkServiceWithPromises.create(endPoint, payload);
  }

  // get contacts count
  async getContactsCount() {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/contacts?$limit=0`;
    return BaseNetworkService.read(endPoint);
  }

  createContact(payload: Person) {
    const orgId: number = safeLocalStorage.currentUser!.organization.id;
    const endPoint: string = `organizations/${orgId}/contacts`;
    return BaseNetworkService.create(endPoint, payload);
  }

  createContacts(contacts: Person[]) {
    const orgId: number = safeLocalStorage.currentUser!.organization.id;
    const endPoint: string = `organizations/${orgId}/contacts/bulk-create`;
    return BaseNetworkServiceWithPromises.create(endPoint, {contactIds: contacts});
  }

  async updateContact(id: Person['id'], payload: Person) {
    const orgId: number = safeLocalStorage.currentUser!.organization.id;
    const endPoint : string = `organizations/${orgId}/contacts/${id}`;
    return BaseNetworkService.update(endPoint, payload);
  }


  updateContacts(contacts: Person[]) {
    const orgId: number = safeLocalStorage.currentUser!.organization.id;
    const endPoint: string = `organizations/${orgId}/contacts/bulk-update`;
    return BaseNetworkServiceWithPromises.update(endPoint, {contactIds: contacts});
  }

  deleteContact(id: Person['id']) {
    const orgId: number = safeLocalStorage.currentUser!.organization.id;
    return BaseNetworkServiceWithPromises.delete(`organizations/${orgId}/contacts/${id}`);
  }

  deleteContacts(ids: Person['id'][]) {
    const orgId: number = safeLocalStorage.currentUser!.organization.id;
    return BaseNetworkServiceWithPromises.delete(
      `organizations/${orgId}/contacts/bulk-delete`,
      {contactIds: ids.map(id => ({id}))},
    );
  }
}

export default new ContactsNetworkService();
