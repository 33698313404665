import React, {useCallback} from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import safeLocalStorage from '../../../../shared-services/safe-local-storage-service';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 15px 10%;
  & > div {
    display: flex;
    & > button {
      margin-right: 15px;
    }
  }
`;

interface Props {
  currentPage: number,
  stepCount: number,
  setPage(step: number): void,
  fileIsSelected: boolean
}

const BottomNavigation: React.FC<Props> = ({currentPage, stepCount, setPage, fileIsSelected}) => {
  const allowNextStep: boolean = safeLocalStorage!.currentUser.role.key !== 'MEMBER';

  const increaseStep = useCallback(
    () => {
      setPage(currentPage + 1);
    },
    [currentPage, setPage],
  );

  const decreaseStep = useCallback(
    () => {
      setPage(currentPage - 1);
    },
    [currentPage, setPage],
  );

  return (
    <Container>
      <div>
        <Button
          type="primary"
          onClick={decreaseStep}
        >
          {currentPage === 0 ? 'Cancel' : 'Back'}
        </Button>
      </div>
      {allowNextStep ?
        <Button
          disabled={(currentPage === 1) && !fileIsSelected}
          type="primary"
          onClick={increaseStep}
        >
          {currentPage < stepCount - 1 ? 'Continue' : 'Finish'}
        </Button> :
        <b>Please talk to a Team Manager to import records.</b>
      }
    </Container>
  );
};

export default BottomNavigation;
