import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import CircularProgress from 'react-md/lib/Progress/CircularProgress';
import {useTranslation} from 'react-i18next';
import styled, {css} from 'styled-components';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import LicenseItem from './components/LicenseItem';
import AddonList from './components/AddonList';
import ServiceList from './components/ServiceList';
import Buttons from './components/Buttons';
import SummaryItem from './components/SummaryItem';
import BillingInformation from './components/BillingInformation';
import {
  changeAnnual,
  changeLicenses,
  hideCheckout,
  initState,
  reducer,
  selectAddon,
  selectService,
  setCurrentPlan,
  setTiers,
  showCheckout,
} from './reducers/billing';
import {buildCurrentPlan, calculatePrices, convertToTiers, getProrateRatio} from './util/util';
import {addons, services} from './config';
import {Interval} from '../../type/plans/Interval';
import CheckoutDialog from './components/CheckoutDialog';
import useDataLoader from '../../hook/useDataLoader';
import upgradeNetworkService from '../../../network-services/upgrade-network-service';
import userNetworkService from '../../../network-services/user-network-service';
import useInitializer from '../../hook/useInitializer';
import CurrentPlan from '../../type/plans/CurrentPlan';
import Plan from '../../type/Plan';
import Me from '../../type/Me';
import safeLocalStorage from '../../../shared-services/safe-local-storage-service';
import {isVersion2} from '../../../common/versions';

const Container = styled.div<{centerContent?: boolean}>`
  width: 80%; 
  margin: 0 auto;
  padding: 20px 0 60px;
  ${props => props.centerContent && css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  `};
`;

const Title = styled.h1`
  color: black;
  display: block;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #e1e4e8;
  margin-bottom: 32px;
`;

const PlanContainer = styled.div`
  background: white;
  border: 1px solid #e1e4e8;
  position: relative;
`;

const ContactLabel = styled.div`
  text-align: right;
  margin-top: 16px;
`;

const TeaserContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  backdrop-filter: saturate(200%) blur(2px);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.1);
`;

// Calling `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
const stripePromise = loadStripe(window.__env.stripeCheckoutKey);

interface Props {
  currentPlan: CurrentPlan,
  isOwner: boolean,
  onCheckout: () => any,
}

const Billing: React.FC<Props> = ({currentPlan, isOwner, onCheckout}) => {
  const {t, ready} = useTranslation(['billing']); // preload translation

  const {isLoading, loader, data} = useDataLoader([upgradeNetworkService.getPlanData], true);
  useInitializer(loader);
  const tiers = useMemo(
    () => (isLoading || !data ? [] : convertToTiers(data[0] as Plan[])),
    [data, isLoading],
  );

  const enterprisePlan = tiers.find(({licenseLimit}) => licenseLimit.length === 1 && licenseLimit[0] > 0);
  const enterpriseLicenseLimit = enterprisePlan ? enterprisePlan.licenseLimit[0] : Number.POSITIVE_INFINITY;
  const [
    {
      checkoutVisible,
      isAnnual,
      licenses,
      selectedAddonIds,
      selectedAddons,
      selectedServiceIds,
      selectedServices,
      selectedTier,
    },
    dispatch,
  ] = useReducer(reducer, {tiers, addons, services, currentPlan}, initState);

  useEffect(() => { dispatch(setTiers(tiers)); }, [tiers]);
  useEffect(() => { dispatch(setCurrentPlan(currentPlan)); }, [currentPlan]);
  const handleAnnualChange = useCallback((isAnnual) => dispatch(changeAnnual(isAnnual)), [dispatch]);
  const handleLicenseChange = useCallback((licenses) => dispatch(changeLicenses(licenses)), [dispatch]);
  const toggleAddon = useCallback((id, selected) => dispatch(selectAddon(id, selected)), [dispatch]);
  const toggleService = useCallback((id, selected) => dispatch(selectService(id, selected)), [dispatch]);
  const handleCheckoutClick = useCallback(() => dispatch(showCheckout()), [dispatch]);
  const handleHideCheckout = useCallback(() => dispatch(hideCheckout()), [dispatch]);

  const changeToAnnual = useCallback(() => handleAnnualChange(true), [handleAnnualChange]);
  const shouldOnlyTalkToSales = enterpriseLicenseLimit > currentPlan.licenses && enterpriseLicenseLimit <= licenses;
  const checkoutEnabled = licenses > currentPlan.licenses
    || (currentPlan.tier.interval === Interval.MONTHLY && selectedTier.interval === Interval.ANNUAL);
  const {dueTodayPrice} = calculatePrices(currentPlan, selectedTier, licenses, getProrateRatio(currentPlan));

  return (
    <Elements stripe={stripePromise}>
      {(ready && !isLoading)
        ? (
          <Container>
            <Title>{t('Manage Your Subscription')}</Title>

            <BillingInformation
              currentPlan={currentPlan}
              onSwitchToYearlyBillingClick={changeToAnnual}
              selectedTier={selectedTier}
            />

            {isOwner && (
              <PlanContainer>
                {currentPlan.isOldPlan && (<TeaserContainer />)}

                <LicenseItem
                  currentPlan={currentPlan}
                  isAnnual={isAnnual}
                  licenses={licenses}
                  onChangeInterval={handleAnnualChange}
                  onChangeLicenses={handleLicenseChange}
                  selectedTier={selectedTier}
                />

                <AddonList
                  addons={addons}
                  currentPlan={currentPlan}
                  licenses={licenses}
                  selectedAddonIds={selectedAddonIds}
                  onSelect={toggleAddon}
                />

                <ServiceList
                  licenses={licenses}
                  liveLicenses={currentPlan.licenses}
                  selectedServiceIds={selectedServiceIds}
                  services={services}
                  onSelect={toggleService}
                />

                <SummaryItem
                  addons={addons}
                  currentPlan={currentPlan}
                  licenses={licenses}
                  selectedAddons={selectedAddons}
                  selectedServices={selectedServices}
                  selectedTier={selectedTier}
                />
              </PlanContainer>
            )}

            {isOwner && !currentPlan.isOldPlan && (
              <Buttons
                checkoutEnabled={checkoutEnabled}
                onlyTalkToSales={shouldOnlyTalkToSales}
                onCheckoutClick={handleCheckoutClick}
              />
            )}

            {licenses >= 4 && !shouldOnlyTalkToSales && (selectedAddons.length > 0 || selectedServices.length > 0) &&
              !currentPlan.isOldPlan && (
              <ContactLabel>
                {t('Someone from the success team will follow up on your purchase within 48 hours')}
              </ContactLabel>
            )}
          </Container>
        )
        : (
          <Container centerContent>
            <CircularProgress id="billing-page-loading" scale={2} />
          </Container>
        )
      }
      <CheckoutDialog
        amount={dueTodayPrice}
        licenses={licenses}
        onCompleteCheckout={onCheckout}
        onHide={handleHideCheckout}
        selectedTier={selectedTier}
        visible={checkoutVisible}
      />
    </Elements>
  );
};

interface WrapperProps {
  currentUser: Me,
}

const getNewAppUrl = (path: string): string => `${window.__env.baseNewAppUrl}${path}?mmc_token=${safeLocalStorage.accessToken}`;

const wrapper: React.FC<WrapperProps> = ({currentUser}) => {
  if (isVersion2()) {
    window.location.href = getNewAppUrl('/billing');
    return null;
  }

  const currentPlan = useMemo(() => buildCurrentPlan(currentUser), []);
  const [plan, setPlan] = useState(currentPlan);

  const handleCompleteCheckout = useCallback(
    async () => {
      const userData = await userNetworkService.getUserData();
      const currentPlan = buildCurrentPlan(userData);
      setPlan(currentPlan);
      // update the free trial flag
      window.DataModel.isFreeTrial = currentPlan.tier.isTrial;
      window.refreshDom({isFreeTrial: window.DataModel.isFreeTrial});

      safeLocalStorage.currentUser = userData;
    },
    [],
  );

  return (
    <Billing
      currentPlan={plan}
      isOwner={currentUser.role.key === 'OWNER'}
      onCheckout={handleCompleteCheckout}
    />
  );
};

export default wrapper;
