import angular from 'angular';
import BaseController from './base-controller';
import CommonCtrl from './common-controller';
import mmcConst from './constants';
import {
  reportTypeDisplay,
  color,
  textSearch,
  numberToLocaleString,
  getCampaignForId,
  getTemplateForId,
  noNullDates,
  underscores,
  regularStage,
  textColor,
  getDate,
  getLeadStatus,
  replaceSpaceWithDash,
  getNameForPin,
  getAddressForPin,
  removeSpaces,
} from './filters';
import {SubheaderController, subheader} from './subheader/subheader-component';
import {CustomFieldsController, customFields} from './custom-fields/custom-fields-component';
import {AvatarController, avatar} from './avatar/avatar-component';
import {EditPopupController, editPopup} from './edit-popup/edit-popup-component';
import {MultiSelectTableController, multiSelectTable} from './multi-select-table/multi-select-table-component';
import {EditSubheaderController, editSubheader} from './edit-subheader/edit-subheader-component';
import {NotesController, notes} from './notes/notes-component';
import {QuickCreateDealController, quickCreateDeal} from './quick-create-deal/quick-create-deal-component';
import {FooterOptionViewController, footerOptionView} from './footer-option-view/footer-option-view-component';
import {QuickCreateContactController, quickCreateContact} from './quick-create-contact/quick-create-contact-component';
import {QuickCreateAccountController, quickCreateAccount} from './quick-create-account/quick-create-account-component';
import {RecordOwnerController, recordOwner} from './record-owner/record-owner-component';
import {ShowTourOverlayController, showTourOverlay} from './show-tour-overlay/show-tour-overlay-component';
import {EntityRoutesController, entityRoutes} from './entity-routes/entity-routes-component';

import DebounceModule from './debounce';
import mod from './infinite-scroll';

export default angular.module('mapmycustomersApp.common', [
  mod, DebounceModule,
])
  .factory('BaseController', BaseController)
  .controller('CommonCtrl', CommonCtrl)
  .constant('mmcConst', mmcConst)
  .filter('reportTypeDisplay', reportTypeDisplay)
  .filter('color', color)
  .filter('textSearch', textSearch)
  .filter('numberToLocaleString', numberToLocaleString)
  .filter('getCampaignForId', getCampaignForId)
  .filter('getTemplateForId', getTemplateForId)
  .filter('noNullDates', noNullDates)
  .filter('underscores', underscores)
  .filter('regularStage', regularStage)
  .filter('textColor', textColor)
  .filter('getDate', getDate)
  .filter('getLeadStatus', getLeadStatus)
  .filter('replaceSpaceWithDash', replaceSpaceWithDash)
  .filter('getNameForPin', getNameForPin)
  .filter('getAddressForPin', getAddressForPin)
  .filter('removeSpaces', removeSpaces)
  .component('subheader', subheader)
  .controller('SubheaderController', SubheaderController)
  .component('customFields', customFields)
  .controller('CustomFieldsController', CustomFieldsController)
  .component('avatar', avatar)
  .controller('AvatarController', AvatarController)
  .component('editPopup', editPopup)
  .controller('EditPopupController', EditPopupController)
  .component('multiSelectTable', multiSelectTable)
  .controller('MultiSelectTableController', MultiSelectTableController)
  .component('editSubheader', editSubheader)
  .controller('EditSubheaderController', EditSubheaderController)
  .component('notes', notes)
  .controller('NotesController', NotesController)
  .component('quickCreateDeal', quickCreateDeal)
  .controller('QuickCreateDealController', QuickCreateDealController)
  .component('footerOptionView', footerOptionView)
  .controller('FooterOptionViewController', FooterOptionViewController)
  .component('quickCreateContact', quickCreateContact)
  .controller('QuickCreateContactController', QuickCreateContactController)
  .component('quickCreateAccount', quickCreateAccount)
  .controller('QuickCreateAccountController', QuickCreateAccountController)
  .component('recordOwner', recordOwner)
  .controller('RecordOwnerController', RecordOwnerController)
  .component('showTourOverlay', showTourOverlay)
  .controller('ShowTourOverlayController', ShowTourOverlayController)
  .component('entityRoutes', entityRoutes)
  .controller('EntityRoutesController', EntityRoutesController)
  .name;
