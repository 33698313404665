import React, {useCallback} from 'react';
import Checkbox from '../../components/Checkbox/Checkbox';

interface Props {
  checked?: boolean,
  displayName: string,
  entity: any,
  id: string,
  onToggle?: (entity: any, checked: boolean) => void,
}

const EntityCheckboxItem: React.FC<Props> = ({checked, displayName, entity, id, onToggle}) => {
  const handleCheckboxChange = useCallback((checked) => onToggle && onToggle(entity, checked), [entity, onToggle]);
  return (
    <div>
      <Checkbox
        checked={checked}
        id={`${id}-checkbox`}
        name={`${id}-checkbox`}
        onChange={handleCheckboxChange}
      >
        {displayName}
      </Checkbox>
    </div>
  );
};

export default EntityCheckboxItem;
