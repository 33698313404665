import React, {memo, useCallback, useState} from 'react';
import styled from 'styled-components';
import Switch from 'react-md/lib/SelectionControls/Switch';
import TextField from 'react-md/lib/TextFields/TextField';
import {useTranslation} from 'react-i18next';
import {PlanCard, PlanDescription, PlanName, PlanPrice, PlanText} from './PlanCard';
import {tiers} from '../config';
import {isInRange} from '../util/util';
import Tier from '../../../type/plans/Tier';
import {Interval} from '../../../type/plans/Interval';
import CurrentPlan from '../../../type/plans/CurrentPlan';

const LicensesTextField = styled(TextField)`
  width: 100px;
`;

const IntervalSwitch = styled(Switch)`
  margin-left: -8px;
`;

const SubscriptionPrice = styled.div`
  margin-top: 8px;
`;

const AddUsersIcon = styled.div`
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
`;

interface Props {
  currentPlan: CurrentPlan,
  isAnnual: boolean,
  licenses: number,
  onChangeInterval?: (isAnnual: boolean) => void,
  onChangeLicenses?: (licenses: number) => void,
  selectedTier: Tier,
}

const LicenseItem = memo<Props>(({
  currentPlan,
  isAnnual,
  licenses,
  onChangeInterval,
  onChangeLicenses,
  selectedTier,
}) => {
  const intervals = tiers
    .filter(tier => isInRange(tier.licenseLimit, licenses) && !tier.isTrial)
    .map(({interval}) => interval);

  const {t} = useTranslation(['billing']);
  const handleIntervalChange = useCallback((checked) => onChangeInterval && onChangeInterval(checked), [onChangeInterval]);

  const [addedLicenses, setAddedLicenses] = useState<number | string>(1);
  const [error, setError] = useState<string | undefined>();
  const handleChangeLicenses = useCallback(
    (value) => {
      const parsedValue = parseInt(`${value}`.trim(), 10);
      if (!Number.isNaN(parsedValue) && parsedValue >= 0) {
        if (onChangeLicenses) {
          onChangeLicenses(currentPlan.licenses + parsedValue);
          setError(undefined);
        }
      } else if (Number.isNaN(parsedValue) && `${value}`.trim() !== '') {
        setError(t('Please enter a number'));
      } else if (parsedValue < 0) {
        setError(t('You can not reduce licenses number'));
      }
      setAddedLicenses(Number.isNaN(parsedValue) ? '' : parsedValue);
    },
    [currentPlan.licenses, setAddedLicenses],
  );

  return (
    <PlanCard>
      <AddUsersIcon>
        <i className="fas fa-users" />
      </AddUsersIcon>
      <PlanText>
        <PlanName>
          Add Licenses
        </PlanName>
        <PlanDescription>
          Licenses can be transferred to other team members once purchased and you can add additional licenses at
          anytime.
          <br />
          <a target="_blank" rel="noopener noreferrer" href="https://www.mapmycustomers.com/pricing">Explore Pricing</a>
        </PlanDescription>
      </PlanText>
      <PlanPrice>
        <LicensesTextField
          aria-label="Number of additional licenses"
          error={!!error}
          errorText={error}
          id="number-of-additional-licenses"
          min={0}
          onChange={handleChangeLicenses}
          pattern="^\d+"
          step={1}
          type="number"
          value={addedLicenses}
        />
        <IntervalSwitch
          checked={isAnnual}
          disabled={intervals.length < 2 || currentPlan.tier.interval === Interval.ANNUAL}
          id="licenses-interval-switch"
          label={t(isAnnual ? 'annual_capitalized' : 'monthly_capitalized')}
          name="licenses-interval-switch"
          onChange={handleIntervalChange}
        />
        <SubscriptionPrice>
          {t('license', {price: selectedTier.price, interval: selectedTier.interval})}
        </SubscriptionPrice>
      </PlanPrice>
    </PlanCard>
  );
});

export default LicenseItem;
