import React from 'react';
import EntityType from '../../type/EntityType';
import FontAwesomeIcon from '../NavigationBar/component/FontAwesomeIcon';
import territoriesIcon from "./territoriesDisabled.svg";

interface Props {
  className?: string,
  entityType: EntityType;
}

const EntityTypeIcon: React.FC<Props> = ({className, entityType}) => {
  let icon: string | undefined;

  switch (entityType) {
    case EntityType.COMPANY:
      icon = 'fa-building';
      break;

    case EntityType.PERSON:
      icon = 'fa-users';
      break;

    case EntityType.DEAL:
      icon = 'fa-handshake';
      break;

    case EntityType.ACTIVITY:
      icon = 'fa-calendar-alt';
      break;

    case EntityType.COMPANY_ROUTE:
    case EntityType.PEOPLE_ROUTE:
      icon = 'fa-route';
      break;

    case EntityType.TERRITORY:
      return <img className={className} src={territoriesIcon} />;

    case EntityType.COMPANY_GROUP:
    case EntityType.PEOPLE_GROUP:
    case EntityType.DEAL_GROUP:
      icon = 'fa-layer-group';
      break;
  }

  return icon ? <FontAwesomeIcon className={className} icon={icon} /> : null;
};

export default EntityTypeIcon;
