import template from './groups-section.html';
import mapActions from '../../../store/store-helpers';

class GroupsSectionController {
  constructor(
    $scope, $window, GroupsSectionService,
  ) {
    Object.assign(this, {
      MODEL: $window.DataModel,
      $scope,
      $window,
      GroupsSectionService,
    });
    mapActions(this, ['modals']);

    this.$scope.$watch('$ctrl.MODEL.GroupsService.currentPageGroups', (groups) => {
      this.availableGroups = groups;
    });
  }

  $onInit() {
    if (this.GroupsSectionService.init(this.entityId === undefined)) {
      this.groups = this.groups || [];
      this.previousGroupsIds = this.groups.map(({id}) => id);
    }
  }

  showManageGroupsDialog() {
    if (this.hasUpdateRights) {
      this.modalsActions.showModal('manageGroupsModal');
    }
  }

  async detachGroup(group) {
    if (!this.hasUpdateRights) {
      return;
    }
    try {
      await swal({
        title: 'Are you sure?',
        text: 'You won\'t be able to undo this action!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, delete now',
      });

      const groupIndex = this.groups.findIndex(({id}) => id === group.id);
      this.updateGroups([...this.groups.slice(0, groupIndex), ...this.groups.slice(groupIndex + 1)]);
    } catch (e) {
      // ajax exceptions handled in updateGroups method, swal's 'cancel' error is ignored here
    }
  }

  async updateGroups(groups) {
    this.MODEL.show.loader = true;
    try {
      this.groups = await this.GroupsSectionService.updateGroups(
        this.entityType,
        this.entityId,
        groups,
        this.previousGroupsIds,
      );
      this.previousGroupsIds = this.groups.map(({id}) => id);
      this.$scope.$apply();
    } catch (e) {
      swal('Uh-Oh!', 'Failed to save groups', 'error');
    }
    this.$window.refreshDom({loader: false}, 'show');
  }
}

GroupsSectionController.$inject = [
  '$scope', '$window', 'GroupsSectionService',
];

const groupsSection = {
  bindings: {
    groups: '<?',
    entityId: '<?',
    hasUpdateRights: '<',
    entityType: '@',
  },
  controller: 'GroupsSectionController',
  template,
};

export {GroupsSectionController, groupsSection};
