import styled from 'styled-components';

const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
`;

export default CardContainer;
