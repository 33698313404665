import {all, call, select, put, takeEvery} from 'redux-saga/effects';
import {fetchLeads, fetchLeadCount, fetchLeadGroups, initLeadListVew} from './action';
import lead, {getLeadsCount, getTotalLeadCount} from './index';
import leadNetworkService from '../../network-services/leads-network-service';
import customFieldsNetworkService from '../../network-services/custom-fields-network-service';
import EntityType from '../../react/type/EntityType';
import ListResponse from '../../react/type/service/ListResponse';
import Lead from '../../react/type/Lead';
import CustomField from '../../react/type/CustomField';

// don't load all leads if there's more records than this limit
// (temporarily set to 0)
export const LEAD_COUNT_THRESHOLD = 0;
const LEAD_LIMIT_PER_REQUEST = 2000;

const callFetchLeads = (page: number, limit: number = LEAD_LIMIT_PER_REQUEST) => call(
  leadNetworkService.fetchAllLeads.bind(leadNetworkService),
  false,
  {
    includeGroups: true,
    includeNotes: true,
    includeCustomFields: true,
  },
  page,
  'updatedAt',
  false,
  limit,
);
//
// function* onInitLeadListView() {
//   yield put(lead.actions.setError(undefined));
//   yield put(lead.actions.setLoading(true));
//
//   const leadCountResponse: ListResponse<Lead> = yield callFetchLeads(1, 1);
//   yield put(lead.actions.setLeadCount(leadCountResponse.total)); // update total lead count
//
//   if (leadCountResponse.total > LEAD_COUNT_THRESHOLD) {
//     // do not continue loading, we'll fall back to the old lead list view
//     yield put(lead.actions.setLoading(false));
//     return;
//   }
//
//   const leadsCount = yield select(getLeadsCount);
//   // leads are already loaded, not reloading
//   if (leadsCount === leadCountResponse.total) {
//     yield put(lead.actions.setLoading(false));
//     return;
//   }
//
//   console.time();
//   const [leadResponse, customFieldsResponse]: [ListResponse<Lead>, ListResponse<CustomField>] = yield all([
//     callFetchLeads(1),
//     call(customFieldsNetworkService.getFields.bind(customFieldsNetworkService), EntityType.LEAD),
//   ]);
//   console.timeEnd();
//
//   yield put(lead.actions.setLeads(leadResponse.data));
//   yield put(lead.actions.updateCustomFields(customFieldsResponse.data));
//   yield put(lead.actions.setLoading(false));
//   // load the rest of pages
//   yield put(fetchLeads({skipFirst: true}));
// }

function* onFetchLeadCount() {
  const leadCount = yield call(leadNetworkService.getLeadsCount);
  yield put(lead.actions.setLeadCount(leadCount));
}
//
// function* onFetchLeads({payload: {skipFirst = false}}: ReturnType<typeof fetchLeads>) {
//   // there is one problem with such loading:
//   // if you've loaded 1..10 leads and one of the leads (say 5th) is deleted and now you load
//   // leads 11..20, you will actually load leads with ids 12..21. So you'll miss one lead.
//   // As an opposite: if somebody added new lead in between, you won't miss it because it'll get a
//   // higher id and will be loaded at the end
//
//   let page = 1;
//   if (!skipFirst) {
//     const response = yield callFetchLeads(1);
//     yield put(lead.actions.setLeadCount(response.total)); // update total lead count
//     yield put(lead.actions.setLeads(response.data));
//   }
//
//   let totalCount = yield select(getTotalLeadCount);
//
//   console.time();
//   while (page * LEAD_LIMIT_PER_REQUEST < totalCount) {
//     const response: ListResponse<Lead> = yield callFetchLeads(++page);
//     if (totalCount !== response.total) {
//       totalCount = response.total;
//       yield put(lead.actions.setLeadCount(response.total));
//     }
//     yield put(lead.actions.appendLeads(response.data));
//   }
//   console.timeEnd();
// }

function* onFetchLeadGroups() {
  const groups = yield call(
    leadNetworkService.fetchAllLeadGroups.bind(leadNetworkService),
    undefined,
    1,
    'displayOrder',
    false,
    999999,
  );
  yield put(lead.actions.setGroups(groups.data));
}

export default function* leadSaga() {
  // yield takeEvery(initLeadListVew.type, onInitLeadListView);
  yield takeEvery(fetchLeadCount.type, onFetchLeadCount);
  // yield takeEvery(fetchLeads.type, onFetchLeads);
  yield takeEvery(fetchLeadGroups.type, onFetchLeadGroups);
}
