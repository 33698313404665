import angular from 'angular';
/* ng-infinite-scroll - v1.0.0 - 2013-02-23 */
const mod = angular.module('infinite-scroll', []);

mod.directive('whenScrollEnds', () => ({
  restrict: 'A',
  link(scope, element, attrs) {
    let visibleHeight = element.height();
    const threshold = 100;

    $(element[0]).bind('scroll', () => {
      visibleHeight = element.height();
      const scrollableHeight = element.prop('scrollHeight');
      const hiddenContentHeight = scrollableHeight - visibleHeight;

      if (hiddenContentHeight - element.scrollTop() <= threshold) {
        // Scroll is almost at the bottom. Loading more rows
        scope.$evalAsync(attrs.whenScrollEnds);
      } else if (element.scrollTop() + element.offsetHeight >= element.scrollHeight) {
        scope.$evalAsync(attrs.whenScrollEnds);
      }
    });
  },
}));

export default mod.name;
