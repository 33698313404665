import template from './add-leads-form.html';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';

class AddLeadsFormController {
  constructor($window) {
    Object.assign(this, {
      MODEL: $window.DataModel,
    });
  }

  $onInit() {
    const extraDetails = {
      color: {options: safeLocalStorage.currentUser.organization.plan.colors},
      country: {required: () => !!this.leads.addressAdd},
      website: {link: () => (this.leads.websiteEdit ? `http://${this.leads.websiteAdd}` : '')},
      email: {link: () => `mailto:${this.leads.emailAdd || ''}`},
      phone: {link: () => `tel:${this.leads.phoneAdd || ''}`},
      address: {
        link: () => '',
        linkImg: './images/curr_location.svg',
        linkId: 'gpsBox',
        fn: 'findMe',
      },
    };

    this.generalFields = this.MODEL.leadFieldModel.editFormFields
      .filter((field) => !field.isAddressField && !field.editFormDetails.isLeadDetails)
      .map((field) => {
        const editFormDetails = {...field.editFormDetails, ...extraDetails[field.name]};
        return editFormDetails;
      });
    this.contactFields = [
      {
        label: 'Phone Number',
        model: 'phone',
        type: 'tel',
        id: 'phone',
        name: 'phone',
        columnWidth: '12',
        link: () => `tel:${this.leads.phoneAdd || ''}`,
        linkId: 'phoneBox',
        linkImg: './images/phoneImage.svg',
      },
      {
        label: 'Email Address',
        model: 'email',
        type: 'email',
        id: 'email',
        name: 'email',
        columnWidth: '12',
        link: () => `mailto:${this.leads.emailAdd || ''}`,
        linkId: 'emailBox',
        linkImg: './images/emailImage.svg',
      },
    ].concat(this.MODEL.leadFieldModel.editFormFields
      .filter((field) => field.isAddressField)
      .map((field) => {
        const editFormDetails = {...field.editFormDetails, ...extraDetails[field.name]};
        return editFormDetails;
      }));

    this.leadDetailsFields = this.MODEL.leadFieldModel.editFormFields
      .filter((field) => field.editFormDetails.isLeadDetails)
      .map((field) => {
        const editFormDetails = {...field.editFormDetails, ...extraDetails[field.name]};
        return editFormDetails;
      });

  }

  fieldFunction(method) {
    if (method) {
      this[method]();
    }
  }

  setDirtyFlag(fieldName) {
    this.MODEL.dirtyEditFlag = true;

    if (fieldName && ['address', 'city', 'state', 'country', 'zip'].includes(fieldName)) {
      this.MODEL.typeOfLocationUpdatedMostRecently = 'address';

      if (fieldName !== 'country' && !this.addressTouched) {
        this.addressTouched = true;
        this.leads.countryAdd = 'United States';
      }
    }
  }
}

AddLeadsFormController.$inject = ['$window'];

const addLeadsForm = {
  bindings: {
    leads: '=',
    findMe: '&',
  },
  controller: AddLeadsFormController,
  template,
};

export {AddLeadsFormController, addLeadsForm};
