import template from './add-edit-funnel-modal.html';
import analyticsService from '../../shared-services/analytics-service';

class AddEditFunnelModalController {
  constructor(
    $window, FunnelService,
  ) {
    Object.assign(this, {
      MODEL: $window.DataModel,
      $window,
      FunnelService,
      UTILS: $window.mmcUtils,
    });
  }

  $onInit() {
    this.originalName = this.MODEL.FunnelService.addEditFunnelName;
  }

  closeTypeModal() {
    this.toggleFunnelModal({show: false});
    $('#edit-stages').show();
  }

  async saveFunnel(funnelName) {
    // clear any white space from funnel input field && trim whitespace from user input
    this.toggleFunnelModal({show: false});
    this.MODEL.show.loader = true;
    this.MODEL.FunnelService.addEditFunnelName = funnelName.trim();

    let result;

    try {
      result = !this.currentFunnel
        ? await this.FunnelService.createNewFunnel(funnelName)
        : await this.FunnelService.updateFunnel(this.currentFunnel);
      analyticsService[this.currentFunnel ? 'entityUpdated' : 'entityAdded']('Funnel', result);

      if (result) {
        await this.refreshFunnels();
      }

      this.MODEL.show.loader = false;
      this.$window.refreshDom({selectedFunnels: this.MODEL.FunnelService.selectedFunnels}, 'FunnelService');
      this.toggleFunnelModal(false);
      $('#edit-stages').show();
    } catch (error) {
      this.UTILS.showValidationError(error, 'We were unable to save funnel.');
      this.MODEL.show.loader = false;
      this.toggleFunnelModal({show: true});
      this.UTILS.showValidationError(error, 'We were unable to save funnel.');
      this.$window.refreshDom({selectedFunnels: this.MODEL.FunnelService.selectedFunnels}, 'FunnelService');
    }
  }
}

AddEditFunnelModalController.$inject = [
  '$window', 'FunnelService',
];

const addEditFunnelModal = {
  bindings: {
    currentFunnel: '=',
    funnels: '=',
    toggleFunnelModal: '&',
    refreshFunnels: '&',
  },
  controller: 'AddEditFunnelModalController',
  template,
};

export {AddEditFunnelModalController, addEditFunnelModal};
