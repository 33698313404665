export default {
  accessRights: Object.freeze({
    accounts: Object.freeze({export: 'accounts:e', edit: 'accounts:u', delete: 'accounts:d'}),
    contacts: Object.freeze({export: 'contacts:e', edit: 'contacts:u', delete: 'contacts:d'}),
    deals: Object.freeze({export: 'deals:e', edit: 'deals:u', delete: 'deals:d'}),
    routes: Object.freeze({export: 'routes:e', edit: 'routes:u', delete: 'routes:d'}),
    territories: Object.freeze({export: 'territories:e', edit: 'territories:u', delete: 'territories:d'}),
  }),
  nameByEntityType: Object.freeze({
    accounts: 'Company',
    contacts: 'Person',
    deals: 'Deal',
    territories: 'Territory',
  }),
  pluralNameByEntityType: Object.freeze({
    accounts: 'companies',
    contacts: 'people',
    deals: 'deals',
    territories: 'territories',
  }),
  // this is an entityType to entityType mapping, no need to update to new nomenclature
  pluralEntityTypeToSingularEntityType: Object.freeze({
    accounts: 'account',
    contacts: 'contact',
    deals: 'deal',
    activities: 'activity',
    territories: 'territory',
  }),
  pinLarge: Object.freeze({
    width: 36,
    height: 46,
    extension: '.png',
    location: './images/pins-large/marker',
    checkinPinLocation: './images/pins-large-checkins/pin_red',
  }),
  pinSmall: Object.freeze({
    width: 18,
    height: 23,
    extension: '.png',
    location: './images/pins-large/marker',
    checkinPinLocation: './images/pins-small-checkins/pin_red',
  }),
  circleLarge: Object.freeze({
    width: 36,
    height: 36,
    extension: '.png',
    location: './images/circles-large/circle',
    checkinPinLocation: './images/circles-large-checkins/circle_red',
  }),
  circleSmall: Object.freeze({
    width: 18,
    height: 18,
    extension: '.png',
    location: './images/circles-large/circle',
    checkinPinLocation: './images/circles-small-checkins/circle_red',
  }),
  dealStageWon: Object.freeze({type: 'won'}),
  dealStageLost: Object.freeze({type: 'lost'}),
  activitiesIcons: Object.freeze({
    visit: './images/crm-activities/visit-activity.svg',
    call: './images/crm-activities/call-activity.svg',
    email: './images/crm-activities/email-activity.svg',
    lunch: './images/crm-activities/lunch-activity.svg',
    meeting: './images/crm-activities/meeting-activity.svg',
    task: './images/crm-activities/task-activity.svg',
    custom: './images/crm-activities/custom-activity.svg',
  }),
  activitiesIconsWhite: Object.freeze({
    visit: './images/crm-activities/visit-activity-white.svg',
    call: './images/crm-activities/call-activity-white.svg',
    email: './images/crm-activities/email-activity-white.svg',
    lunch: './images/crm-activities/lunch-activity-white.svg',
    meeting: './images/crm-activities/meeting-activity-white.svg',
    task: './images/crm-activities/task-activity-white.svg',
    custom: './images/crm-activities/custom-activity-white.svg',
  }),
  activitiesIconsActive: Object.freeze({
    visit: './images/crm-activities/visit-activity-active.svg',
    call: './images/crm-activities/call-activity-active.svg',
    email: './images/crm-activities/email-activity-active.svg',
    lunch: './images/crm-activities/lunch-activity-active.svg',
    meeting: './images/crm-activities/meeting-activity-active.svg',
    task: './images/crm-activities/task-activity-active.svg',
    custom: './images/crm-activities/custom-activity-active.svg',
  }),
  activitiesColoredIcons: Object.freeze({
    visit: './images/dashboard/visit-colored icon.svg',
    call: './images/dashboard/call-colored icon.svg',
    email: './images/dashboard/email-colored icon.svg',
    lunch: './images/dashboard/lunch-colored icon.svg',
    meeting: './images/dashboard/meeting-colored icon.svg',
    task: './images/dashboard/task-colored icon.svg',
    custom: './images/dashboard/custom-colored icon.svg',
  }),
  // all field names must be in a lower case
  standardFieldNames: Object.freeze({
    accounts: [
      'company name',
      'phone number',
      'website',
      'pin color',
      'email address',
      '# employees',
      'street address',
      'parent company',
      'city',
      'state/province',
      'groups',
      'postal code',
      'country',
      'annual revenue',
      'created at',
      'updated at',
    ],
    contacts: [
      'name',
      'street address',
      'email address',
      'company name',
      'city',
      'phone number',
      'pin color',
      'state/province',
      'groups',
      'country',
      'postal code',
    ],
    deals: [
      'deal name',
      'amount',
      'company name',
      'person name',
      'funnel',
      'stage',
      'groups',
      'score',
      'closing date',
    ],
    crmActivities: [
      'from',
      'duration',
      'assign to',
      'company',
      'person',
      'deal',
    ],
  }),
  color: Object.freeze({
    selectedMarker: 'green',
  }),
  EntityType: Object.freeze({
    ACCOUNT: 'account',
    CONTACT: 'contact',
    CRM_ACTIVITY: 'crmActivity',
    DEAL: 'deal',
    LEAD: 'lead',
  }),
};
