import safeLocalStorage from '../../../../shared-services/safe-local-storage-service';
import {listReducer, ListState, ParamsAction} from '../../../util/reducers/listReducer';
import accountFieldModel from '../../../../common/field-model/contact-field-model';

const persistenceReducer = (incomingState: ListState, action: ParamsAction): ListState => {
  const state: ListState = listReducer(incomingState, action, accountFieldModel.fields);
  safeLocalStorage.peopleListViewState = state;
  return state;
};

export default persistenceReducer;
