import 'croppie';
import get from 'lodash/get';
import find from 'lodash/find';
import * as agGrid from '@ag-grid-community/core';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import helperService from '../shared-services/helper-service';
import customFieldsNetworkService from '../network-services/custom-fields-network-service';
import analyticsService from '../shared-services/analytics-service';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import mapActions from '../store/store-helpers';
import columnTypes from './field-model/column-types';
import getFullName from '../react/util/formatter/getFullName';
import complementFileUrl from '../react/util/complementFileUrl';
import {doesUseMiles} from './settings';

export default function BaseController(
  $rootScope, $timeout, $compile, $route, $location, Upload,
  MainService, MappingService, CustomerService, RoutingService, ImportService,
  AllContactsService, FilterService, CacheService, AddEditService,
  AccountsService, TerritoriesService, LassoService, mmcConst, SettingsService,
) {
  // main utility functions
  const UTILS = window.mmcUtils;
  const MODEL = window.DataModel;

  // service to return
  const service = {
    _gridApi: undefined,
    SELECT_COLUMNS_COLUMN_DEF: {
      headerName: '',
      headerTooltip: 'Select Columns',
      lockVisible: true,
      lockPinned: true,
      resizable: false,
      suppressMenu: false,
      suppressMovable: true,
      suppressAutoSize: true,
      suppressSizeToFit: true,
      // pinned: 'right',
      width: 100,
    },
  };

  mapActions(service, ['RoutingService', 'modals']);

  // init parse
  UTILS.appInit(safeLocalStorage.accessToken);

  //
  // ----------------------- SHARED METHODS b/w controllers ----------------------- //
  //

  service.getOrganizationLogo = () => {
    let logoPath = SettingsService.getOrganizationSetting('logo');
    if (logoPath && logoPath.startsWith('/')) {
      logoPath = logoPath.slice(1);
    }
    return logoPath ? MODEL.__env.BaseURL + logoPath : 'images/logo.png';
  };

  service.getProfileLogo = () => {
    const avatarUrl = get(safeLocalStorage, 'currentUser.profilePhoto.publicURI');
    return complementFileUrl(avatarUrl) || 'images/default_profile.png';
  };

  // checking window width
  service.checkWinWidth = () => window.outerWidth;

  // find accounts & contacts this deal is associated with
  service.addAccountsAndContactsForDeals = function (record, scope) {
    // find accounts & contacts this deal is associated with
    const account = find(MODEL.accountsRaw, {objectId: record.accountId});
    const contact = find(MODEL.customersRaw, {objectId: record.contactId});

    // add accounts link
    $('#account').html('');
    if (account) {
      const link = $(`<div class="crmLink" ng-click="goToAccount('${account.objectId}')">${account.name}</div>`);
      $compile(link)(scope);
      $('#account').html(link);
    }

    // add contacts link
    $('#contact').html('');
    if (contact) {
      const link = $(`<div class="crmLink" ng-click="goToContact('${contact.objectId}')">${contact.name}</div>`);
      $compile(link)(scope);
      $('#contact').html(link);
    }
  };

  // refreshes the map
  // (button on the modal after import)
  //  we no longer wait until this point to refresh, and instead do it as soon as possible in background)
  service.refreshMap = function () {
    $('#importBox').hide();
    $('#modalbg').hide();
    $('#modal').hide();
    $('#newCustomers').html('');
  };

  // clears existing notes
  service.clearCurrentNotes = function () {
    MODEL.MappingService.currentCustomersNotes = [];
  };

  // open image in lightbox
  service.openImage = function (image) {
    CustomerService.openImage(image);
  };

  // delete customer
  service.deleteEntity = function (tableName, recordId) {
    MainService.deleteEntity(tableName, recordId);
  };

  service.openConvertLeadModal = (lead) => {
    MODEL.leadToConvert = lead;
    service.modalsActions.showModal('conversionModal');
  };

  service.doesLeadHaveAnythingToConvert = (lead) => lead && (
    (lead.accounts ? lead.accounts.length : 0)
    + (lead.contacts ? lead.contacts.length : 0)
    + (lead.deals ? lead.deals.length : 0)
  ) === 0;

  // delete image selectedor
  service.deleteCurrentPhoto = function () {
    MainService.deleteCurrentPhoto();
  };

  service.addRouteFromListView = (routeType) => {
    MODEL.smartRouting.flow = false;
    MODEL.manualRouting.flow = true;
    $location.url(`${routeType}/routing/map`);
    RoutingService.commonRoutingFlow();
  };

  // add territorries from list view
  service.addTerritoriesFromListView = () => {
    MODEL.TerritoriesService.addTerritoriesFromListView = true;
    window.location.href = '#/accounts/territories/map';
    // $("#newTerr").show();
  };

  // go to find new customers page
  service.goToFindNewCustomers = function () {
    $('#modalbg').hide();
    $('#modal').hide();
    window.location.href = '#/find-new-customers';
  };

  // go to a specific account
  service.goToAccount = function (objectId) {
    window.location = `#/accounts/${objectId}`;
    $route.reload();
  };

  // go to a specific contact
  service.goToContact = function (objectId) {
    window.location = `#/contacts/${objectId}`;
    $route.reload();
  };

  // go to a specific deal
  service.goToDeal = function (objectId) {
    window.location = `#/deals/${objectId}`;
    $route.reload();
  };

  service.goToEntity = (entityType, entityId) => {
    let location;
    if (entityType === 'contact-route') {
      location = `contacts/routing/edit/${entityId}`;
    } else if (entityType === 'account-route') {
      location = `accounts/routing/edit/${entityId}`;
    } else if (entityType === 'territory') {
      location = 'contacts/territories/list';
    } else if (entityType === 'crmActivities') {
      location = `activities/edit/${entityId}`;
    } else if (entityType === 'account-groups') {
      location = `accounts/groups/list/${entityId}`;
    } else if (entityType === 'contact-groups') {
      location = `contacts/groups/list/${entityId}`;
    } else if (entityType === 'deal-groups') {
      location = `deals/groups/list/${entityId}`;
    } else {
      location = `${entityType}/edit/${entityId}`;
    }
    if (location) {
      window.location = `#/${location}`;
      $route.reload();
    }
  };

  service.showCustomFields = function (crmPage) {
    MainService.showCustomFields(crmPage);
  };

  // show additional fields for add pin view
  service.showAdditionalFields = function (type) {
    $(`#additionalFields${type}`).show();
    $(`#additionalFieldsLink${type}`).hide();
  };

  // adds custom field
  service.addCustomFieldIfValidFromDom = function () {
    MainService.addCustomFieldIfValidFromDom();
  };

  // add event/activity to calendar
  service.addToCalendar = function () {
    MainService.addToCalendar();
  };

  // selected a customer from a table (map, contacts, leads pages, etc.)
  service.selectedRowCustomerTable = function (pin, updating, crmtype) {
    MainService.selectedRowCustomerTable(pin, updating, crmtype);
  };

  service.callPhoneNumber = function (number, event) {
    if (event) {
      event.stopPropagation();
    }

    window.open(`tel:${number}`, '_blank');
  };

  service.findEntityByCurrentCrmObjectId = function () {
    let closestPin;
    if (window.isOnPage('accounts')) {
      closestPin = find(MODEL.accounts, {id: parseInt(MODEL.currentCrmObjectId, 10)});
    } else if (window.isOnPage('contacts')) {
      closestPin = find(MODEL.contacts, {id: parseInt(MODEL.currentCrmObjectId, 10)});
    }
    if (!closestPin && MODEL.currentCRMObject && MODEL.currentCRMObject.id === parseInt(MODEL.currentCrmObjectId, 10)) {
      closestPin = MODEL.currentCRMObject;
    }
    return closestPin;
  };

  service.addToRoute = function (event) {
    if (event) {
      event.stopPropagation();
    }
    analyticsService.clicked(['Map', 'add to route']);
    const pin = service.findEntityByCurrentCrmObjectId();
    // put pin to lassoed pins, cause addToRoute modal uses lassoedPins array for it's functional
    LassoService.setLassoedPins([pin]);
    service.modalsActions.showModal('addToRoute');
  };

  service.previewEntity = function (event, entityType, entityId) {
    if (event) {
      event.stopPropagation();
    }
    analyticsService.clicked(['Map', 'preview']);
    MODEL.MappingService.singlePinData = {id: entityId};
    window.refreshDom({'MappingService.singlePinData': MODEL.MappingService.singlePinData});
  };

  // get directions to address
  service.getDirectionsToAddress = function (pin, event) {
    if (event) {
      event.stopPropagation();
    }
    analyticsService.clicked(['Map', 'route']);

    let closestPin = pin;
    if (!closestPin) {
      closestPin = service.findEntityByCurrentCrmObjectId();
    }

    closestPin.lat = get(closestPin, 'geoPoint.coordinates[1]');
    closestPin.lng = get(closestPin, 'geoPoint.coordinates[0]');
    let url;
    const fullAddress = UTILS.buildCompleteAddress(closestPin);
    // mobile browsers
    if (UTILS.isMobile.any()) {
      if (UTILS.isMobile.Windows()) {
        url = `bingmaps:?rtp=pos.${MODEL.currentlat}_${MODEL.currentlng}~pos.${closestPin.lat}_${closestPin.lng}`;
        window.open(url, '_blank');
      } else if (UTILS.isMobile.Android()) {
        if (closestPin.address) {
          url = `geo:0,0?q=${fullAddress}`;
        } else {
          url = `geo:0,0?q=${closestPin.lat},${closestPin.lng}(${fullAddress})`;
        }
        window.open(url, '_blank');
      } else if (UTILS.isMobile.iOS()) {
        if (closestPin.address) {
          url = `https://maps.google.com?saddr=Current+Location&daddr=${fullAddress}`;
        } else {
          url = `https://maps.google.com?saddr=Current+Location&daddr=${closestPin.lat},${closestPin.lng}`;
        }
        window.open(url, '_blank');
      }
    } else if (UTILS.isMobile.Edge() || UTILS.isMobile.Trident()) {
      // desktop browsers

      url = `bingmaps:?rtp=pos.${MODEL.currentlat}_${MODEL.currentlng}~pos.${closestPin.lat}_${closestPin.lng}`;
      window.open(url, '_blank');
    } else {
      if (closestPin.address) {
        url = `https://maps.google.com?saddr=Current+Location&daddr=${fullAddress}`;
      } else {
        url = `https://maps.google.com?saddr=Current+Location&daddr=${closestPin.lat},${closestPin.lng}`;
      }
      window.open(url, '_blank');
    }
  };

  // return number of customers (used in map.html for binding)
  service.numCustomersMap = function () {
    if (UTILS.isOnPage('nearbyPage')) {
      return MODEL.customersNearbyArray.length;
    }
    if (UTILS.isOnPage('contactsPage')) {
      return MODEL.customersRaw.length;
    }
    if (UTILS.isOnPage('accountsPage')) {
      return MODEL.accountsRaw.length;
    }
    if (UTILS.isOnPage('dealsPage') || UTILS.isOnPage('dealsMapPage')) {
      return MODEL.dealsRaw.length;
    }
    if (UTILS.isOnPage('territoriesPage') || UTILS.isOnPage('territoriesMapPage')) {
      let count = 0;
      // loop thru all territory's shown pins count
      Object.values(MODEL.showHidePinTracking.yes).forEach(pinsInTerr => {
        count += pinsInTerr;
      });
      return count;
    }

    return (Object.values(MODEL.showHidePinTracking.yes)[0] || 0);
  };

  // for pagination --> shows customers for respective page
  service.currentCustomerSegment = function (skipNumber) {
    MainService.currentCustomerSegment(skipNumber);
  };

  // return search query
  service.searchQuery = function () {
    MainService.searchQuery();
  };

  service.closeSearch = () => {
    MODEL.routingSearchText = '';
  };

  // search for contacts to add to a route (not currently using elastic search)
  service.searchForRouteEntities = async function (searchText) {
    MODEL.routingSearchText = searchText;
    const type = ['contacts', 'accounts'].find(entityType => $route.current.originalPath.includes(entityType));
    if (!type) {
      return;
    }

    let results = [];
    MODEL.routingSearchOptions = [];
    if (MODEL.routingSearchText && MODEL.routingSearchText.length) {
      if (type === 'contacts') {
        results = await AllContactsService.searchFromContacts(MODEL.routingSearchText);
      } else {
        results = await AccountsService.searchFromAccounts(MODEL.routingSearchText);
      }
    }


    $rootScope.$applyAsync(() => {
      MODEL.routingSearchOptions = results;
    });
    return results;
  };

  // clear filter
  service.clearQuery = function () {
    CustomerService.clearQuery();
  };

  // update import columns when select dropdown changes
  service.updateSelectedColumns = function (selectedField, oldValue) {
    ImportService.updateSelectedColumns(selectedField, oldValue);
  };

  // check if CSV is OK to import
  service.considerImportingCSV = function () {
    ImportService.considerImportingCSV();
  };

  // go to CRM page
  service.visitCrmPage = function () {
    $('#modalbg').hide();
    $('#modal').hide();
    if ($(window).width() < 992) {
      swal('Yikes!', 'You can only integrate with CRMs from a laptop or desktop computer.');
    } else {
      window.location.href = '#/crm-integrations';
    }
  };

  service.goToImportPage = function () {
    $('#modalbg').hide();
    $('#modal').hide();
    if ($(window).width() < 992) {
      swal('Yikes!', 'You can only import from a laptop or desktop computer.');
    } else {
      window.location.href = '#/import';
    }
  };

  // create form for the CRM object to be added
  service.addCrmObject = (objectType) => {
    if ($rootScope.showTourOverlay) {
      return false;
    }

    if (!objectType) {
      if (MODEL.currentPageSubHeader.addButtonName.indexOf('Company') >= 0) {
        objectType = 'accounts';
      } else if (MODEL.currentPageSubHeader.addButtonName.indexOf('Person') >= 0) {
        objectType = 'contacts';
      } else if (MODEL.currentPageSubHeader.addButtonName.indexOf('Deal') >= 0) {
        objectType = 'deals';
      } else if (MODEL.currentPageSubHeader.addButtonName.indexOf('Lead') >= 0) {
        objectType = 'leads';
      } else if (MODEL.currentPageSubHeader.addButtonName.indexOf('Group') >= 0) {
        objectType = 'groups';
      } else if (MODEL.currentPageSubHeader.addButtonName.indexOf('Route') >= 0) {
        objectType = 'routes';
      } else if (MODEL.currentPageSubHeader.addButtonName.indexOf('Territory') >= 0) {
        objectType = 'territories';
      } else if (MODEL.currentPageSubHeader.addButtonName.indexOf('Activity') >= 0) {
        objectType = 'activities';
      }
    }
    analyticsService.initiated(MODEL.currentPageSubHeader.addButtonName);

    // hide select user view option
    $('#subheader').show();
    $('#mapViewByUser').hide();
    $('#dealsMapView').hide();
    $('#dealList').hide();
    $('#teamHeader').hide();
    $('#allroutes').hide();
    $('#routeDetail').hide();
    $('#teamSideDiv').hide();
    $('#dashMiddleDiv').hide();
    $('#activityRightDiv').hide();
    $('#emailBlastImg').hide();
    $('#settingsView').hide();
    $('#upgradeHeader').hide();
    $('#upgradeHeaderBtn').hide();
    $('#upgradeTeam').hide();
    $('#upgradeInd').hide();
    $('[name="groupsAddContact"]:checked').click();
    $('[name="groupsAddAccount"]:checked').click();
    $('[name="groupsAddDeal"]:checked').click();
    MODEL.MappingService.selectedGroups = [];
    MODEL.typeOfLocationUpdatedMostRecently = undefined;

    // reset multiple page popup data
    MappingService.resetMultiplePopupData();

    // reset MODEL vars depending on table data is from
    switch (objectType) {
      case 'accounts': // account creation form
        if (MODEL.modals.noAccountsModal) {
          service.modalsActions.hideModal('noAccountsModal');
        }
        window.refreshDom({'NavBar.showCreateCompanyModal': true, 'NavBar.showCreateEditActivityModal': false, 'NavBar.showCreateDealModal': false, 'NavBar.showCreatePersonModal': false, 'NavBar.editActivityModalActivityId': undefined});
        break;
      case 'contacts': // contacts creation form
        if (MODEL.modals.noContactsModal) {
          service.modalsActions.hideModal('noContactsModal');
        }
        window.refreshDom({'NavBar.showCreateCompanyModal': false, 'NavBar.showCreateEditActivityModal': false, 'NavBar.showCreateDealModal': false, 'NavBar.showCreatePersonModal': true, 'NavBar.editActivityModalActivityId': undefined});
        break;
      case 'deals': // deals creation form
        if (MODEL.modals.noDealsModal) {
          service.modalsActions.hideModal('noDealsModal');
        }
        window.refreshDom({'NavBar.showCreateCompanyModal': false, 'NavBar.showCreateEditActivityModal': false, 'NavBar.showCreateDealModal': true, 'NavBar.showCreatePersonModal': false, 'NavBar.editActivityModalActivityId': undefined});
        break;
      case 'leads':
        $location.url('/leads/add');
        break;
      case 'routes':
        if (MODEL.modals.noRoutesModal) {
          service.modalsActions.hideModal('noRoutesModal');
        }
        if (MODEL.geocodingOrgLimitReached) {
          swal({
            title: 'Your organization has reached its daily geocoding limit',
            text: 'We’re unable to build or edit routes until the starting and ending locations on the map can be geocoded. Please try again once your organization’s daily limit resets. ',
            type: 'warning',
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'OK, got it.',
          });
          break;
        } else if (MODEL.geocodingMmcLimitReached) {
          swal({
            title: 'Building routes temporarily unavailable',
            text: 'We’re experiencing heavy geocoding usage and are unable to build or edit routes. We apologize for this inconvenience - please try again later.',
            type: 'warning',
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'OK, got it.',
          });
          break;
        }
        service.RoutingServiceActions.addRoute();
        MODEL.RoutingService.removeRouteMarkers();
        MODEL.smartRouting.chooseFromMap = '';
        MODEL.manualRouting.startingLocation = '';
        MODEL.manualRouting.endingLocation = '';

        if (UTILS.isOnPage('routingPage', 'editContactsPage', 'dashboardPage')) {
          if ($route.current.originalPath.includes('contacts')) {
            service.addRouteFromListView('contacts');
          } else if ($route.current.originalPath.includes('accounts') || $route.current.originalPath.includes('dashboard')) {
            service.addRouteFromListView('accounts');
          }
        } else {
          MODEL.smartRouting.flow = false;
          MODEL.manualRouting.flow = true;
          RoutingService.commonRoutingFlow();
        }
        break;
      case 'groups':
        if (MODEL.modals.noGroupsModal) {
          service.modalsActions.hideModal('noGroupsModal');
        }
        MODEL.GroupsService.showAddGroupModal = true;
        MODEL.GroupsService.newGroupName = '';
        window.location.href = window.location.href.replace(/\/[^/]*$/, '/list');
        break;
      case 'territories':
        if (MODEL.modals.noTerritoriesModal) {
          service.modalsActions.hideModal('noTerritoriesModal');
        }
        TerritoriesService.showCreateTerritoryPopup();
        break;
      case 'activities':
        if (MODEL.modals.noActivitiesModal) {
          service.modalsActions.hideModal('noActivitiesModal');
        }
        window.refreshDom({'NavBar.showCreateCompanyModal': false, 'NavBar.showCreateEditActivityModal': true, 'NavBar.showCreateDealModal': false, 'NavBar.showCreatePersonModal': false, 'NavBar.editActivityModalActivityId': undefined});
        break;
    }
  };
  window.addCrmObject = service.addCrmObject;

  //
  // ----------------------- Filter Methods ----------------------- //
  //

  // show the filter box in the page
  service.showFilter = () => {
    FilterService.setUpFilter();
    MODEL.show.filters = true;
    $('#subheader').hide();
    MODEL.show.loader = false;

    MappingService.resetMultiplePopupData();
  };

  // set the map tools after ng-include has been loaded
  service.initNearbySlider = () => {
    FilterService.initNearbySlider();
  };

  service.buildFilters = () => {
    const filters = {};
    const crm = service.checkTable() || '';
    const userView = (MODEL.cachedState && MODEL.cachedState[crm]) ? MODEL.cachedState[crm].user : '';

    if (userView === 'unassigned' || userView === null) {
      filters.assigneeId = null;
    } else if (userView) {
      filters.viewAs = userView;
    }

    if ($route.current.id !== 'routingPage') {
      if (MODEL.FilterService && MODEL.FilterService.filtersSelected && MODEL.FilterService.filtersSelected.length) {
        MODEL.FilterService.filterActive = true;
      } else if (MODEL.FilterService) {
        MODEL.FilterService.filterActive = false;
      }

      MODEL.show.loader = true;
      const filtersSelected = MODEL.FilterService ? MODEL.FilterService.filtersSelected : [];

      if (filtersSelected.length) {
        filtersSelected.forEach((curr) => {
          if (curr.category !== 'radius') {
            if (curr.comparator === 'equals') {
              filters[curr.category] = curr.value;
            } else if (curr.comparator === 'exists') {
              filters[curr.category] = {$ne: null};
            } else if (curr.comparator === 'does not exist') {
              filters[curr.category] = null;
            } else {
              filters[curr.category] = {[MODEL.FilterService.parseOpString[curr.comparator]]: curr.value};
            }
          } else {
            const radius = parseInt(curr.radius, 10) * (doesUseMiles() ? 1609.334 : 1000.0);
            filters.area = {
              radius,
              uom: 'meter',
              point: [curr.latlng.lng, curr.latlng.lat],
            };
          }
        });
      }
    }

    if (filters.groups) {
      filters.includeGroups = true;
    }

    return filters;
  };

  // apply filters based on the view
  service.applyAllFilters = async function () {
    MODEL.currentPageNum = 1;
    $('#subheader').show();
    const page = $route.current.id;
    const map = page.includes('Map');
    if (map) {
      service.resetViewportIfRadiusFilterSelected();
    }
    // special case where we don't need to cache filters
    if (page !== 'individualAccountsRoutePage' && page !== 'individualContactsRoutePage') {
      CacheService.setFilterCache(isEmpty(MODEL.FilterService.filtersSelected));
    }
    const filters = service.buildFilters();
    analyticsService.completed('Filter', filters);

    try {
      if (MODEL.FilterService && MODEL.FilterService.filtersSelected && MODEL.FilterService.filtersSelected.length > 0) {
        await service.setViewBasedOnFilters(page, map, filters);
      } else {
        await service.resetFilter();
      }
    } catch (e) {
      helperService.logError(e);
    }

    MODEL.show.filters = false;
    window.refreshDom({loader: false}, 'show');
  };

  /**
   * Resets saved map viewport when radius filter is selected for the first time.
   */
  service.resetViewportIfRadiusFilterSelected = () => {
    const entityType = service.checkTable();
    const hadRadiusFilter = get(MODEL.cachedState, [entityType, 'filters'], []).some(({category}) => category === 'radius');
    const hasRadiusFilter = MODEL.FilterService.filtersSelected.some(({category}) => category === 'radius');
    if (!hadRadiusFilter && hasRadiusFilter) {
      MODEL.savedMapViewport = {}; // to allow zoom map to fit selected radius, see MappingService.parseMappingObjects
    }
  };

  service.resetFilter = (userFilter) => FilterService.resetFilter(userFilter);

  // set the view based on the filters sent
  service.setViewBasedOnFilters = async function (table, map, filters) {
    if (service.doesColumnListContainCustomField()) {
      filters.includeCustomFields = true;
    }

    await MainService.setViewBasedOnFilters(table, map, filters);
  };

  //
  // ----------------------- Edit Popup Methods ----------------------- //
  //

  // add contacts to the account from the add/edit account page
  service.addContactsToAccount = () => {
    MainService.populateEditPopupData('contacts');
  };

  // add deals to the account from the add/edit account page
  service.addDealsToAccount = () => {
    MainService.populateEditPopupData('deals');
  };

  // add deals to the account from the add/edit account page
  service.addDealsToContact = () => {
    MainService.populateEditPopupData('deals');
  };

  // remove the child account from the local data structure
  service.removeDealFromAccountOrContact = (objectId, e) => {
    CustomerService.removeDealFromAccountOrContact(objectId);
    if (e) {
      e.stopPropagation();
    }
  };

  service.getQuickCreateEntityType = () => {
    const buttonNameToEntityType = {
      Company: 'account',
      Person: 'contact',
      Deal: 'deal',
    };

    const currentButton = Object.keys(buttonNameToEntityType)
      .find(buttonName => MODEL.editPopup.addButtonName.includes(buttonName));
    if (!currentButton) {
      return;
    }
    return buttonNameToEntityType[currentButton];
  };

  // quick add crm object from the popup
  service.quickAddCrmRecord = async () => {
    service.modalsActions.hideModal('addEditPopup');

    const entityType = service.getQuickCreateEntityType();
    if (!entityType) {
      return;
    }

    // init custom-fields stuff
    let fields = (await customFieldsNetworkService.getFields(`${entityType}s`)).data;
    fields = fields.filter(({required}) => required); // only keep required CFs
    fields = fields.filter(({dataType}) => dataType !== 'boolean'); // we don't support boolean type now
    MODEL.quickCreateEntityType = entityType;
    MODEL.quickCreateCustomFields = fields;
    MODEL.quickCreateCustomFieldValues = fields
      .reduce((result, {id}) => Object.assign(result, {[id]: {customField: {id}, value: [undefined]}}), {});
    MODEL.quickCreateCustomFieldValidationErrors = {};
    $rootScope.$digest();

    const entityTypePropercase = capitalize(entityType);
    $timeout(() => {
      service.modalsActions.showModal(`quickCreate${entityTypePropercase}`);
    }, 0);

    // init date-pickers after slideIn animation is complete
    let hasDatePickers = fields.some(({dataType}) => dataType === 'date');
    let hasTimePickers = fields.some(({dataType}) => dataType === 'time');
    let attemptsLeft = 20;
    const initializeDateTimePickers = () => {
      if (hasDatePickers) {
        const pickers = $('.quickCreateModal__field-list .datetimepicker');
        if (pickers.length) {
          hasDatePickers = false;
          pickers
            .on('dp.change', _handleDateTimeChange)
            .datetimepicker({inline: true, sideBySide: true, format: 'LL'});
        }
      }

      if (hasTimePickers) {
        const pickers = $('.quickCreateModal__field-list .datetimepicker');
        if (pickers.length) {
          hasTimePickers = false;
          $('.quickCreateModal__field-list .timepicker')
            .on('dp.change', _handleDateTimeChange)
            .datetimepicker({inline: true, sideBySide: true, format: 'LT'});
        }
      }

      if ((hasTimePickers || hasDatePickers) && --attemptsLeft > 0) {
        setTimeout(initializeDateTimePickers, 100);
      }
    };
    if (hasDatePickers || hasTimePickers) {
      setTimeout(initializeDateTimePickers, 100);
    }
  };

  function _handleDateTimeChange(e) {
    const input = e.target.nextElementSibling;
    if (!input) {
      return;
    }
    /**
     * @type {number}
     */
    const fieldId = parseInt($(input).data().fieldId, 10);
    let value = e.date ? e.date.utc().toISOString() : undefined;

    const field = MODEL.quickCreateCustomFields.find(({id}) => id === fieldId);
    if (!field) {
      return;
    }
    if (field.dataType === 'time' && value) {
      value = value.substr(value.indexOf('T') + 1); // only keep the time part for time-type field
    }

    // create or update value
    if (MODEL.quickCreateCustomFieldValues[fieldId]) {
      MODEL.quickCreateCustomFieldValues[fieldId] = {...MODEL.quickCreateCustomFieldValues[fieldId], value: [value]};
    } else {
      MODEL.quickCreateCustomFieldValues[fieldId] = {customField: {id: fieldId}, value: [value]};
    }
    input.dispatchEvent(new Event('change')); // let angular know the input field has updated
    service.onQuickCustomFieldValueChange(fieldId);
    $rootScope.$digest();
  }

  service.onQuickCustomFieldValueChange = (fieldId) => {
    if (MODEL.quickCreateCustomFieldValidationErrors[fieldId]) {
      delete MODEL.quickCreateCustomFieldValidationErrors[fieldId];
      $rootScope.$digest();
    }
  };

  // attach the parent account to the account
  service.attachParentAccount = (id) => {
    MODEL.AddEditService.editPopupView = 'parentAccount';
    MainService.populateEditPopupData('parentAccount', id);
  };

  service.attachAccountToContact = () => {
    MODEL.AddEditService.editPopupView = 'account';
    MainService.populateEditPopupData('account');
  };

  const showAddEditPopup = () => {
    $('body').css('overflow-y', 'hidden');
    service.modalsActions.showModal('addEditPopup');
  };

  service.attachAccountToDeal = () => {
    service.modalsActions.hideModal('quickCreateDeal');
    MODEL.AddEditService.editPopupView = 'account';
    MainService.populateEditPopupData('account');
    showAddEditPopup();
  };

  service.attachContactToDeal = () => {
    MODEL.AddEditService.editPopupView = 'parentAccount';
    MainService.populateEditPopupData('contacts');
    showAddEditPopup();
  };

  // ------------------------ User icon view --------------------- //

  // change profile photo from the sidebar (but take user to account page so we don't repeat js that was previously necessary in the view)
  service.changeProfilePhotoFromAnywhere = function () {
    // hide edit logo divs
    $('#logoPhotoBox').hide();

    // show profile photo divs
    // $("#modalbg").show();
    // $("#modal").show();
    $('#accountPhotoBox').show();

    // clear logo box
    $('#profile-upload').empty();

    window.initCroppieForProfilePhoto();
  };

  // sort according to group header
  service.sortTable = function (tableName, selectedCol, numbers = false, secondTable, thirdTable) {
    MainService.sortTable(tableName, selectedCol, numbers, secondTable, thirdTable);
  };

  service.checkTable = () => {
    const page = $route.current.id;
    let crm = '';
    switch (page) {
      case 'accountsPage':
      case 'accountsMapPage':
      case 'accountsGroupsListPage':
      case 'accountsGroupsMapPage':
        crm = 'accounts';
        break;
      case 'contactsPage':
      case 'contactsMapPage':
      case 'contactsGroupsListPage':
      case 'contactsGroupsMapPage':
        crm = 'contacts';
        break;
      case 'dealsPage':
      case 'dealsMapPage':
      case 'dealsGroupsListPage':
      case 'dealsGroupsMapPage':
        crm = 'deals';
        break;
      case 'routingPage':
      case 'accountsRoutingMapPage':
      case 'contactsRoutingMapPage':
        crm = 'routes';
        break;
      case 'territories':
      case 'territoriesPage':
      case 'territoriesMapPage':
        crm = 'territories';
        break;
      case 'reportsPage':
        crm = 'reports';
        break;
      case 'crmActivitiesPage':
      case 'crmActivitiesCalPage':
        crm = 'crmActivities';
        break;
    }
    return crm;
  };

  // paginate list page results
  service.showList = async function (crm, page, column, sorting) {
    CacheService.cacheCurrentView(MODEL.currentLeadsView, page);
    const cachedCrm = MODEL.cachedState[crm];
    cachedCrm.page = page;
    const filters = service.buildFilters();
    if (sorting) {
      if (cachedCrm.page === page) {
        if (cachedCrm.column === column) {
          cachedCrm.ascending = !cachedCrm.ascending;
        } else {
          cachedCrm.ascending = false;
          cachedCrm.column = column;
        }
      }
    }

    await service.setViewBasedOnFilters(crm, false, filters);
    MODEL[`${crm}Sort`] = undefined;
    window.refreshDom({loader: false}, 'show');
  };

  service.showMapModal = () => {
    if ($route.current.id !== 'editDealsPage') {
      CustomerService.showMapModal();
    } else {
      CustomerService.showDealMapModal();
    }
  };

  service.finishEditLocation = () => {
    CustomerService.finishEditLocation();
  };

  // bulk edit option according to page
  service.saveCheckedDetails = () => {
    const filters = service.buildFilters();
    MainService.saveCheckedRecords(filters);
  };

  // select record
  service.selectRecord = (allRecords = false) => {
    MainService.selectRecord(allRecords);
  };

  // delete  all customers checked off from UI
  service.deleteCheckedRecords = () => {
    if (window.isOnPage('map')) {
      LassoService.saveLassoedPins(true);
    }
    MainService.deleteCheckedRecords(service.applyAllFilters);
  };

  // filter based on view by user
  service.filterByUser = async function (userId, table) {
    analyticsService.initiated('Filter by user', {userId, table});
    const map = table.includes('Map');
    const crm = service.checkTable();
    MODEL[crm] = [];
    if (MODEL.FilterService.filterSlider) {
      MODEL.FilterService.filterSlider.update({from: 0});
    }

    MappingService.resetMultiplePopupData();
    MODEL.currentLeadsView = userId || undefined;
    MODEL.show.loader = true;
    MODEL.cachedState[crm].filters = [];
    MODEL.FilterService.filtersSelected = [];
    CacheService.cacheCurrentView(userId, MODEL.currentPageNum);

    const filters = service.buildFilters();

    if (map) {
      MODEL.cachedState[crm].column = 'updatedAt';
      MODEL.cachedState[crm].ascending = false;
    }

    await service.setViewBasedOnFilters(table, map, filters, MODEL.cachedState[crm]);

    window.refreshDom({loader: false}, 'show');
  };

  // show list view select cols
  service.showSelectFieldsModal = MainService.showSelectFieldsModal;

  // save list view columns
  service.saveSelectedListViewFields = async (fields) => {
    MODEL.show.loader = true;
    try {
      await Promise.all([
        MainService.saveSelectedListViewFields(fields),
        service.setViewBasedOnFilters($route.current.id, false, service.buildFilters()),
      ]);
    } catch (e) {
      helperService.showAndLogError(e);
    }
    window.refreshDom({loader: false}, 'show');
  };

  service.fieldIsVisible = name => MODEL.listviewSelectedColumns.some(field => field.name === name);

  // set dirt flag if add/edit page is changed
  service.setDirtyFlag = (field) => {
    MODEL.dirtyEditFlag = true;
    if (field && ['address', 'city', 'state', 'country', 'zip'].includes(field)) {
      MODEL.typeOfLocationUpdatedMostRecently = 'address';
    }
  };

  service.setContactName = (contacts) => {
    contacts.nameAdd = getFullName(contacts.firstNameAdd, contacts.lastNameAdd);
    service.setDirtyFlag();
  };

  // got to last recent page
  service.goToLastPage = () => {
    window.history.back();
  };

  service.getActivitiesColor = (activity) => {
    if (activity.completed) {
      return '#676767';
    }
    return moment(activity.startAt).isAfter() ? '#3ddcc3' : '#ff7474';
  };

  service.goToActivity = (activity) => {
    if (activity) {
      window.refreshDom({'NavBar.showCreateCompanyModal': false, 'NavBar.showCreateEditActivityModal': true, 'NavBar.showCreateDealModal': false, 'NavBar.showCreatePersonModal': false, 'NavBar.editActivityModalActivityId': activity.id});
    } else {
      window.refreshDom({'NavBar.showCreateCompanyModal': false, 'NavBar.showCreateEditActivityModal': true, 'NavBar.showCreateDealModal': false, 'NavBar.showCreatePersonModal': false, 'NavBar.editActivityModalActivityId': undefined});
    }
  };

  service.getActivitiesIcon = (type, id, crmActivityType) => {
    let iconType;
    if (crmActivityType) {
      iconType = (id === crmActivityType) ? 'activitiesIconsWhite' : 'activitiesIconsActive';
    } else {
      iconType = 'activitiesIconsActive';
    }
    return mmcConst[iconType][type.name.toLowerCase()] || mmcConst[iconType].custom;
  };

  /**
   * Gets the deleted status of an entity that is true if entity has deletedAt field with the date
   * greater than 0 at unixtime.
   *
   * @param {{deletedAt?: string|Date}} [entity]
   * @returns {boolean}
   */
  service.isDeleted = (entity) => {
    if (!entity || !entity.deletedAt) {
      return false;
    }
    const date = new Date(entity.deletedAt);
    return date.getTime() > 0;
  };

  service.goToObjectFromActivities = (object, type, e) => {
    if (e) {
      e.stopPropagation();
    }
    window.location.href = `#/${type}/edit/${object.id}`;
  };

  const MAX_FORMATTED_VALUE_LENGTH = 200; // 200 chars is pretty enough
  /**
   * @param {Object} entity
   * @param {Field} field
   * @returns {string|*}
   */
  service.formatField = (entity, field) => {
    /** @type {string} */
    let formattedValue = field.getEntityFormattedValue(entity);
    if (formattedValue && formattedValue.length > MAX_FORMATTED_VALUE_LENGTH) {
      formattedValue = `${formattedValue.substr(0, MAX_FORMATTED_VALUE_LENGTH - 1)}...`;
    }
    return formattedValue;
  };

  /**
   * Checks if columns selected for list view contains any custom fields.
   * @returns {boolean}
   */
  service.doesColumnListContainCustomField = () => (
    MODEL.listviewSelectedColumns.some(({isCustomField}) => isCustomField)
  );

  /**
   * Checks if columns selected for list view contains a groups field.
   * @returns {boolean}
   */
  service.doesColumnListContainGroupsField = () => (
    MODEL.listviewSelectedColumns.some(({name, isList}) => name === 'groups' && isList)
  );

  /**
   * Extends given filters to include custom fields or groups if such columns are visible
   * @param {{}} originalFilters
   * @returns {{}}
   */
  service.extendFiltersToIncludeDataForVisibleColumns = (originalFilters) => {
    const filters = {...originalFilters};
    if (service.doesColumnListContainCustomField()) {
      filters.includeCustomFields = true;
    }
    if (service.doesColumnListContainGroupsField()) {
      filters.includeGroups = true;
    }
    return filters;
  };

  service.updateColumnDefinitions = (fields, selectedColumns, includeSelectingColumns = true, extraColumns = []) => {
    selectedColumns = selectedColumns || MODEL.listviewSelectedColumns;
    MODEL.columnDefinitions = fields
      .filter(field => selectedColumns === true || selectedColumns.includes(field))
      .map(/** Field */ field => field.gridProperties);

    if (Array.isArray(extraColumns)) {
      MODEL.columnDefinitions = MODEL.columnDefinitions.concat(extraColumns);
    }

    if (includeSelectingColumns) {
      MODEL.columnDefinitions.push(service.SELECT_COLUMNS_COLUMN_DEF);
    }

    if (service._gridApi) {
      service._gridApi.setColumnDefs(MODEL.columnDefinitions);
    }
  };

  service.getGridApi = () => service._gridApi;

  service.initializeGrid = (element, extraOptions) => {
    const gridOptions = {

      columnDefs: MODEL.columnDefinitions,
      deltaColumnMode: true,
      getRowNodeId: service.defaultNodeIdGetter,
      rowData: [],
      rowClass: 'mmc-collection-grid__row',
      rowSelection: 'multiple',
      suppressCellSelection: true,
      suppressRowClickSelection: true,
      suppressHorizontalScroll: false,
      scrollbarWidth: 15,
      floatingFilter: false,
      columnTypes,
      deltaRowDataMode: true,
      onGridReady: service._handleGridReady,
      onGridSizeChanged: service._handleGridSizeChanged,
      rowBuffer: 10,
      onSortChanged: service._handleSortChanged,
      sortingOrder: ['asc', 'desc'],
      ...extraOptions,
    };
    new agGrid.Grid(element, gridOptions, {modules: [ClientSideRowModelModule]}); // eslint-disable-line no-new
    service._gridApi = gridOptions.api;
  };

  service.defaultNodeIdGetter = ({id}) => `${id}`;

  service._handleSortChanged = async ({api}) => {
    const entityType = service.checkTable();
    if (!entityType) {
      return;
    }
    const sortModel = api.getSortModel();
    if (!Array.isArray(sortModel)) {
      return;
    }

    if (sortModel.length) {
      const columnSort = sortModel[0];
      // using colId (which is equal to field name) is not very correct, but is fine because this assumption is
      // correct for sortable columns only
      MODEL.cachedState.accounts.column = columnSort.colId;
      MODEL.cachedState.accounts.ascending = columnSort.sort === 'asc';
    } else {
      MODEL.cachedState.accounts.column = undefined;
    }

    let filters = service.buildFilters();
    filters = service.extendFiltersToIncludeDataForVisibleColumns(filters);
    await service.setViewBasedOnFilters(entityType, false, filters);
    analyticsService.clicked(['List', 'sort'], {entity: entityType, sortModel});
    window.refreshDom({loader: false}, 'show');
  };

  service._handleGridReady = (event) => {
    if (event) {
      service._gridApi = event.api;
      // service._resizeGrid();
    }
  };

  service._handleGridSizeChanged = () => {
    // service._resizeGrid();
  };

  service._resizeGrid = () => {
    if (service._gridApi) {
      service._gridApi.sizeColumnsToFit();
    }
  };

  service.setGridRowData = (rows) => {
    if (service._gridApi) {
      service._gridApi.setRowData(rows);
    }
  };

  service.getEntityDisplayName = MainService.getEntityDisplayName;

  return service;
}

BaseController.$inject = [
  '$rootScope', '$timeout', '$compile', '$route', '$location', 'Upload',
  'MainService', 'MappingService', 'CustomerService', 'RoutingService', 'ImportService',
  'AllContactsService', 'FilterService', 'CacheService', 'AddEditService',
  'AccountsService', 'TerritoriesService', 'LassoService', 'mmcConst', 'SettingsService',
];
