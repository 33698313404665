import React, {useCallback} from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {useTranslation} from 'react-i18next';
import DropZone from '../../../components/Dropzone/DropZone';
import FilePreview from '../../../type/FilePreview';
import File from '../../../type/File';
import ImportNetworkServiceType from '../../../type/network/ImportNetworkService';
import {downloadFileByUrl} from '../../../../shared-services/file-service';
import analyticsService from '../../../../shared-services/analytics-service';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const Information = styled.div`
  width: 100%;
  font-size: 1.3em;
  padding: 5px;
  margin-bottom: 15px;
`;

const DropArea = styled.div`
  background-color: #edf9ff;
  width: 100%;
  padding: 25px;
`;

interface Props {
  ImportNetworkService: ImportNetworkServiceType,
  filePreview: FilePreview,
  handleSetFilePreview: (filePreview: FilePreview | undefined) => void
}

const showError = (errorMessage: string | undefined): void => window.swal('Uh-oh', errorMessage, 'error');

const ChooseFile: React.FC<Props> = ({ImportNetworkService, filePreview, handleSetFilePreview}) => {
  const {t} = useTranslation(['translation', 'import']);
  const handleSelectFile = useCallback(
    (files: File[]) => {
      if (files.length > 0) {
        analyticsService.clicked('Choose a file');
        ImportNetworkService.getPreview(files[0]).then((preview: FilePreview) => {
          if (preview.isErrored) {
            showError(preview.errorMessage);
          } else {
            handleSetFilePreview(preview);
          }
        });
      }
    },
    [],
  );

  const handleDownloadFile = useCallback(
    (dataUrl: string, type: string) => {
      analyticsService.clicked('Download file', {type});
      downloadFileByUrl(dataUrl);
    },
    [],
  );

  const handleChooseAnotherFile = useCallback(
    () => {
      analyticsService.clicked('Choose another file');
      handleSetFilePreview(undefined);
    },
    [handleSetFilePreview],
  );

  return (
    <Container>
      <Information>
        <h2>{t('chooseYourFile')}:</h2>
        <h3>
          Download&nbsp;
          <a onClick={() => handleDownloadFile('https://docs.google.com/spreadsheets/d/e/2PACX-1vSNvO1y6Rc9aXSehO3jNQ21_p8st3bwKGFCDrfTVW7nZv0lo8tc5yaG06y3LPuPzbj4zghSalOMZhuY/pub?output=xlsx', 'instructions')} >instructions</a>
          &nbsp;or&nbsp;
          <a onClick={() => handleDownloadFile('https://docs.google.com/spreadsheets/d/e/2PACX-1vSNvO1y6Rc9aXSehO3jNQ21_p8st3bwKGFCDrfTVW7nZv0lo8tc5yaG06y3LPuPzbj4zghSalOMZhuY/pub?gid=0&single=true&output=csv', 'sample file')} >a sample file.</a>
        </h3>
      </Information>
      <DropArea>
        {filePreview
          ? (
            <div>
              <div><b>{filePreview.file.name}</b></div>
              <Button onClick={handleChooseAnotherFile}>
                {t('chooseAnotherFile')}
              </Button>
            </div>
          )
          : <DropZone onSelect={handleSelectFile} />
        }
      </DropArea>
    </Container>
  );
};

export default ChooseFile;
