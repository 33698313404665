import {createAction} from '@reduxjs/toolkit';
import {withPayloadType} from '../../react/util/redux';

export const fetchLeadCount = createAction('lead/fetchCount');
export const initLeadListVew = createAction('lead/initListView');
export const fetchLeads = createAction('lead/fetchLeads', withPayloadType<{skipFirst?: boolean}>());
export const fetchLeadGroups = createAction('lead/fetchLeadGroups', withPayloadType<{skipFirst?: boolean}>());

export type Actions =
  ReturnType<typeof initLeadListVew>
  | ReturnType<typeof fetchLeadCount>
  | ReturnType<typeof fetchLeads>
  | ReturnType<typeof fetchLeadGroups>;
