import 'croppie';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';
import TextSearchNetworkService from '../../network-services/textsearch-network-service';
import analyticsService from '../../shared-services/analytics-service';
import mapActions from '../../store/store-helpers';
import template from './navbar.html';
import './navbar.scss';

class NavbarController {
  constructor($window, $location, BaseController, $route) {
    Object.assign(this, {
      $window,
      $route,
      MODEL: $window.DataModel,
      localStorage: safeLocalStorage,
      $location,
      addCrmObject: BaseController.addCrmObject,
      getOrganizationLogo: BaseController.getOrganizationLogo,
      getProfileLogo: BaseController.getProfileLogo,
      currentUser: safeLocalStorage.currentUser,
      showBanner: !['checkoutPage', 'upgradePage'].includes($route.current.id),
    });

    mapActions(this, ['modals']);

    this.$window.initCroppieForProfilePhoto = function () {
      if (document.readyState === 'complete') {
        // init croppie for image picker modal
        $window.$uploadCrop = $('#profile-upload').croppie({
          viewport: {
            width: 250,
            height: 250,
            type: 'square',
          },
          boundary: {
            width: 255,
            height: 300,
          },
        });

        $window.$uploadCrop.bind();
      }
    };

    $(document).on('click touchstart', 'body', () => {
      $('.navbar .dropdown-submenu ul.dropdown-menu').css('display', 'none');
      $('.navbar .dropdown.open').removeClass('open');
    });

    $(document).on('mouseenter click touchstart', '.dropdown-menu .dropdown-submenu', function (e) {
      $('.dropdown-submenu ul.dropdown-menu').css({display: 'none'});
      $(this).children('ul').css({display: 'block'});
      e.stopPropagation();
    });

    $(document).on('click touchstart', '.dropdown.main-nav>a', (e) => {
      $('.dropdown-submenu ul.dropdown-menu').css('display', 'none');
      e.stopPropagation();
    });
  }

  $onInit() {
    this.fixed = this.fixed === undefined ? true : !!this.fixed;
  }

  handleAddCrmObject(objectType) {
    analyticsService.initiated(`Quick add ${objectType}`);
    this.addCrmObject(objectType);
  }

  isValidSearchText(searchText) {
    return searchText.trim().length >= 3;
  }

  globalSearchValidator(searchText) {
    return this.isValidSearchText(searchText) || 'Please type at least 3 characters...';
  }

  async globalSearch(searchText = '') {
    if (this.isValidSearchText(searchText)) {
      this.MODEL.searchingGlobal = true;

      try {
        const response = await TextSearchNetworkService.findEntities(searchText);
        this.MODEL.searchOptions = response.data;
      } catch (e) {
        this.MODEL.searchOptions = [];
      }

      this.MODEL.searchingGlobal = false;
      window.refreshDom({searchOptions: this.MODEL.searchOptions});
      return this.MODEL.searchOptions;
    }
    this.MODEL.searchOptions = [];
    return [];
  }

  goToGlobalResult(record) {
    if (record) {
      this.MODEL.searchText = '';
      this.MODEL.globalResult = record;
      const {entity: entityType, entityId} = record;

      if (entityType === 'contact-route') {
        this.$location.url(`contacts/routing/edit/${entityId}`);
      } else if (entityType === 'account-route') {
        this.$location.url(`accounts/routing/edit/${entityId}`);
      } else if (entityType === 'territory') {
        this.$location.url('contacts/territories/list');
      } else if (entityType === 'crmActivities') {
        this.$location.url(`activities/edit/${entityId}`);
      } else if (entityType === 'account-groups') {
        this.$location.url(`accounts/groups/list/${entityId}`);
      } else if (entityType === 'contact-groups') {
        this.$location.url(`contacts/groups/list/${entityId}`);
      } else if (entityType === 'deal-groups') {
        this.$location.url(`deals/groups/list/${entityId}`);
      } else {
        this.$location.url(`${entityType}/edit/${entityId}`);
      }
    } else {
      this.$location.url('/dashboard');
    }
  }

  handleImportClick() {
    this.$location.path('/import');
  }

  openIntegrationsModal() {
    this.MODEL.show.integrationsModal = true;
    this.$window.refreshDom({showModal: true}, 'integrationsModal');
    this.trackEvent('Click on Integrations modal');
  }

  goToPath(pageName) {
    this.$location.path(pageName);
  }

  showSearchBar() {
    this.MODEL.showSearchBar = !this.MODEL.showSearchBar;
  }

  changeProfilePhotoFromAnywhere() {
    console.log('starting navigation so we can add profile photo');

    // hide edit logo divs
    $('#logoPhotoBox').hide();

    // show profile photo divs
    // $("#modalbg").show();
    // $("#modal").show();
    $('#accountPhotoBox').show();

    // clear logo box
    $('#profile-upload').empty();

    this.$window.initCroppieForProfilePhoto();
  }

  openPasswordModal() {
    this.MODEL.password.showModal = true;
    this.$window.refreshDom({showModal: true}, 'password');
    this.trackEvent('Click on Change password');
  }

  trackEvent(event) {
    analyticsService.track(event);
  }

  goToUpgradePage() {
    if (this.currentUser.role.key === 'OWNER') {
      this.$location.url('/upgrade');
      this.showBanner = false;
    }
  }
}

NavbarController.$inject = ['$window', '$location', 'BaseController', '$route'];

const navbar = {
  bindings: {
    fixed: '<?',
  },
  controller: 'NavbarController',
  template,
};

export {NavbarController, navbar};
