import React, {PureComponent} from 'react';
import {ColDef, FilterChangedEvent, GridApi, GridReadyEvent, Module} from '@ag-grid-community/core';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {LicenseManager} from '@ag-grid-enterprise/core';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {AgGridReact, AgGridReactProps} from '@ag-grid-community/react';
import cn from 'classnames';
import GridContainer from './GridContainer';
import NoRowsComponent from './NoRowsComponent';
import columnTypes from '../../../common/field-model/column-types';
import LoadingOverlay from './overlay/LoadingOverlay';

const AllModules: Module[] = [ClientSideRowModelModule, SetFilterModule];
LicenseManager.setLicenseKey('CompanyName=Map My Customers,LicensedApplication=Map My Customers,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-008109,ExpiryDate=13_May_2021_[v2]_MTYyMDg2MDQwMDAwMA==a59a78b047793e840036bdda325e8bc7');

// Framework components for ag-grid
const noRowsComponentFactory = () => (<NoRowsComponent>No Rows Available</NoRowsComponent>);
const loadingComponentFactory = () => <LoadingOverlay />;
const _frameworkComponents = {noRowsComponentFactory, loadingComponentFactory};

export type Props = AgGridReactProps & {
  /**
   * Classname to be applied to the div that wraps the grid
   */
  className?: string,

  isLoading?: boolean,
  /**
   * This will make the grid fill width and respond to page-size changes
   */
  responsiveGrid?: boolean,
  /**
   * Number of rows to have in a buffer
   */
  rowBuffer?: number,
  /**
   * Zebra striping for rows
   */
  zebra?: boolean,
};

interface State {
  frameworkComponents: any,
  columnTypes: {
    [key: string]: ColDef,
  },
}

export class Grid extends PureComponent<Props, State> {
  _gridApi: GridApi | undefined;

  static defaultProps: Props = {
    deltaRowDataMode: true,
    isLoading: false,
    responsiveGrid: true,
    rowBuffer: 10,
    zebra: true,
  };

  constructor(props: Props) {
    super(props);
    this.state = Grid.getDerivedStateFromProps(props);
  }

  static getDerivedStateFromProps(props: Props): State {
    return {
      frameworkComponents: Object.assign({}, _frameworkComponents, props.frameworkComponents),
      columnTypes: Object.assign({}, columnTypes, props.columnTypes),
    };
  }

  componentDidUpdate() {
    const {_gridApi, props: {isLoading, rowData}} = this;
    if (!_gridApi) {
      return;
    }

    if (isLoading || !rowData || (rowData && rowData.length)) {
      _gridApi.hideOverlay();
      isLoading && _gridApi.showLoadingOverlay();
    } else {
      _gridApi.showNoRowsOverlay();
    }
  }

  _handleFilterChanged = (event?: FilterChangedEvent) => {
    const {_gridApi, props: {isLoading, onFilterChanged}} = this;
    if (!_gridApi) {
      return;
    }
    if (_gridApi.getDisplayedRowCount() > 0 || isLoading) {
      _gridApi.hideOverlay();
      isLoading && _gridApi.showLoadingOverlay();
    } else {
      _gridApi.showNoRowsOverlay();
    }
    onFilterChanged && event && onFilterChanged(event);
  };

  _handleGridReady = (event?: GridReadyEvent) => {
    const {props: {onGridReady}} = this;
    if (event) {
      this._gridApi = event.api;
      onGridReady && onGridReady(event);
    }
    this.componentDidUpdate();
  };

  _handleGridSizeChanged = (params: {}) => {
    const {props: {onGridSizeChanged}} = this;
    onGridSizeChanged && onGridSizeChanged(params);
  };

  render() {
    const {
      _handleFilterChanged,
      _handleGridReady,
      _handleGridSizeChanged,
      props: {
        className,
        columnTypes: ignoredTypes, // mentioned to not get into restProps, ignored to not collide with state
        deltaRowDataMode,
        frameworkComponents: ignoredComponents, // mentioned to not get into restProps, ignored to not collide with state
        icons,
        onFilterChanged,
        onGridReady,
        onGridSizeChanged,
        responsiveGrid,
        rowBuffer,
        rowClass,
        rowData,
        zebra,
        ...restProps
      },
      state: {
        columnTypes,
        frameworkComponents,
      },
    } = this;

    return (
      <GridContainer
        className={cn('ag-theme-alpine', className)}
        zebra={zebra}
      >
        <AgGridReact
          columnTypes={columnTypes}
          modules={AllModules}
          deltaRowDataMode={deltaRowDataMode}
          frameworkComponents={frameworkComponents}
          icons={{
            menu: `<i class='${cn('material-icons', 'mmc-ag-grid__header-icon')}'>menu</i>`,
            ...icons,
          }}
          loadingOverlayComponent="loadingComponentFactory"
          noRowsOverlayComponent="noRowsComponentFactory"
          onFilterChanged={_handleFilterChanged}
          onGridReady={_handleGridReady}
          onGridSizeChanged={_handleGridSizeChanged}
          rowBuffer={rowBuffer}
          rowClass={cn('mmc-ag-grid__row', rowClass)}
          rowData={rowData}
          {...restProps}
        />
      </GridContainer>
    );
  }
}
