import teamNetworkService from '../../../network-services/team-network-service';
import userNetworkService from '../../../network-services/user-network-service';
import safeLocalStorage from '../../../shared-services/safe-local-storage-service';
import helperService from '../../../shared-services/helper-service';
import mapActions from '../../../store/store-helpers';
import template from './manage-teams-table.html';
import './manage-teams-table.scss';

const getNewAppUrl = (path) => `${window.__env.baseNewAppUrl}${path}?mmc_token=${safeLocalStorage.accessToken}&mmc_editpane`;

class ManageTeamsTableController {
  constructor($window, $scope, TeamService) {
    Object.assign(this, {
      $window,
      MODEL: $window.DataModel,
      localStorage: safeLocalStorage,
      $scope,
      TeamService,
    });

    mapActions(this, ['modals']);
  }

  $onInit() {
    this.currentUser = safeLocalStorage.currentUser;
  }

  changeSubTeamName(teamId) {
    this.MODEL.show.loader = true;
    return this.TeamService.changeSubTeamName(teamId)
      .then(() => {
        this.$window.refreshDom({loader: false}, 'show');
      })
      .catch(() => {
        swal('Uh-oh', 'Unable to change team name.', 'error');
        this.$window.refreshDom({loader: false}, 'show');
      });
  }

  async addExistingMembers(subTeam) {
    this.MODEL.show.loader = true;
    this.MODEL.TeamsService.selectedSubTeam = subTeam;

    await this.TeamService.showBenchedMembers();

    this.MODEL.show.loader = false;
    this.modalsActions.showModal('addExistingMembersModal');
  }

  async manageMembers(subTeam) {
    this.MODEL.show.loader = true;

    await this.TeamService.manageMembers(subTeam);

    this.$window.refreshDom({loader: false}, 'show');
    this.modalsActions.showModal('manageMembersModal');
  }

  async deleteTeam(subTeam) {
    await swal({
      title: 'Are you sure?',
      text: 'Deleting this team will remove all members from the team and data associated with the team. Are you sure you would like to delete this team?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, delete this team',
      closeOnConfirm: true,
    });

    this.MODEL.show.loader = true;

    const subTeamMem = this.MODEL.TeamsService.subTeamUsers.filter(user => user.teamId === subTeam.teamId);

    await userNetworkService.deleteTeamMembers(
      safeLocalStorage.currentUser.organization.id,
      subTeam.teamId,
      subTeamMem.map(({id}) => id),
    );
    await teamNetworkService.deleteTeam(safeLocalStorage.currentUser.organization.id, subTeam.teamId);
    await this.TeamService.getOrgDetails();

    this.$window.refreshDom({loader: false}, 'show');
  }

  isVerifiedTeamMember(username) {
    return this.MODEL.TeamsService.verifiedTeamMembers.some(user => user.username === username);
  }

  async resendInvite(user) {
    try {
      await this.TeamService.resendTeamMemberInvite(user);
      swal('Success...', 'Invite sent successfully.', 'success');
    } catch (error) {
      swal('Uh-oh', 'Invite was not sent successfully.', 'error');
    }
  }

  removeUserFromList(member, subTeam, memberType) {
    this.$scope.$applyAsync(() => {
      const {teamStructure} = this.MODEL.TeamsService;
      const subTeamIndex = teamStructure.subTeams.findIndex((team) => team.teamId === subTeam.teamId);

      if (subTeamIndex > -1) {
        if (memberType === 'member') {
          teamStructure.subTeams[subTeamIndex].teamMembersNames = teamStructure.subTeams[subTeamIndex].teamMembersNames.filter((teamMembersName) => teamMembersName !== member);
        } else {
          teamStructure.subTeams[subTeamIndex].teamManagersNames = teamStructure.subTeams[subTeamIndex].teamManagersNames.filter((teamManagersName) => teamManagersName !== member);
        }
      }
    });
  }

  async deleteTeamMember(member, subTeam, memberType) {
    try {
      await swal({
        title: 'Remove User From Team',
        text: 'You’re about to remove the member from the team. You can still find that member in the “Users without Team” list after removal.',
        type: 'warning',
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Delete',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
      });

      await this.TeamService.removeMember(member, subTeam.teamId);

      this.removeUserFromList(member, subTeam, memberType);
      await this.TeamService.calculateTeams();
      const {teamStructure} = this.MODEL.TeamsService;
      this.$scope.$applyAsync(() => {
        const noTeamIndex = teamStructure.subTeams.findIndex((team) => !team.teamId);
        if (noTeamIndex > -1) {
          teamStructure.subTeams[noTeamIndex].teamMembersNames.push(member);
        }
      });
      swal('Success!', `Team member ${member} successfully removed`, 'success');
    } catch (e) {
      if (['cancel', 'overlay', 'esc'].includes(e)) {
        return;
      }
      helperService.showAndLogError(e, 'Failed to remove user from the team, please try again');
    }
  }

  async deleteUser(member, subTeam, memberType) {
    try {
      await swal({
        title: 'Delete User',
        html: `
<div style="text-align: left; font-size: 16px; line-height: 24px;">
  Before deleting this user, <b>it is recommended to change ownership</b> 
  of their records. To do so:<br/>
  &nbsp; 1) Go to the <a href="${getNewAppUrl('/map')}" target="_blank">Map <i class="fa fa-external-link "></i></a> <br/> 
  &nbsp; 2) Apply the Universal Filter: Owner is any of [user you’d like to delete]<br/> 
  &nbsp; 3) Lasso records<br/> &nbsp; 
  4) Click “Bulk Edit” > Select “Owner” > Select new user from dropdown<br/> 
  <br/>
  <b>If you opt to not change ownership, any records will remain owned by the deleted user and will need to be updated manually or via Import.</b>
</div>`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Delete User',
        cancelButtonText: 'Cancel',
        input: 'checkbox',
        inputValue: 0,
        inputPlaceholder:
            'Check here to confirm you want to delete the user',
        inputValidator: (result) => new Promise((resolve, reject) => {
          if (result) {
            resolve();
          } else {
            reject(new Error('Please confirm deletion'));
          }
        }),
      });
      const {allUsers} = this.MODEL.TeamsService;

      const user = allUsers.find(u => u.username === member);
      if (!user) {
        helperService.logError(false, 'Something was wrong. User not found');
        return;
      }

      await userNetworkService.deleteUser(safeLocalStorage.currentUser.organization.id, user.id);
      this.removeUserFromList(member, subTeam, memberType);
      await this.TeamService.calculateTeams();

      swal('Success!', `User ${member} successfully deleted`, 'success');
    } catch (e) {
      if (['cancel', 'overlay', 'esc'].includes(e)) {
        return;
      }
      helperService.showAndLogError(e, 'Failed to delete user, please try again');
    }
  }
}

ManageTeamsTableController.$inject = [
  '$window', '$scope', 'TeamService',
];

const manageTeamsTable = {
  bindings: {
    openSelectedOption: '&',
    showTracks: '&',
  },
  controller: 'ManageTeamsTableController',
  template,
};

export {ManageTeamsTableController, manageTeamsTable};
