import angular from 'angular';
import {react2angular} from 'react2angular';
import CompanyRecordView from './scene/CompanyRecordView/CompanyRecordView';
import Home from './scene/Home/Home';
import Import from './scene/Import/Import';
import Billing from './scene/Billing/Billing';
import CompanyList from './scene/CompanyList/OldCompanyList';
import PeopleList from './scene/PeopleList/PeopleList';
import Integrations from './scene/Integrations/Integrations';
import AddIntegration from './scene/Integrations/AddIntegration';
import EditIntegration from './scene/Integrations/EditIntegration';
import AngularAddToRoute from './components/AddToRouteModal/AngularAddToRoute';
import NavBar from './components/NavigationBar/index';
import LeadList from './scene/LeadList/LeadList';

export default angular.module('mapmycustomersApp.react-components', [])
  .component('billing', react2angular(Billing, ['currentUser'], []))
  .component('homePage', react2angular(
    Home,
    [
      'user',
    ],
    [
      'CrmActivityTypesNetworkService',
    ],
  ))
  .component('companyRecordView', react2angular(
    CompanyRecordView,
    [
      'activities',
      'activityTypes',
      'childCompanies',
      'company',
      'fieldModel',
      'files',
      'getDirectionsToAddress',
      'goToEntity',
      'relatedDeals',
      'relatedPeople',
      'relatedRoutes',
    ],
    [
      'AddEditService',
    ],
  ))
  .component('import', react2angular(
    Import,
    [],
    [
      'ImportService',
      'ImportNetworkService',
      'SettingsService',
    ]))
  .component('companyList', react2angular(
    CompanyList,
    [],
    [
      'FetchCRMDataService',
      'MainService',
    ]))
  .component('leadList', react2angular(
    LeadList,
    [],
    [
      'FetchCRMDataService',
      'MainService',
    ]))
  .component('peopleList', react2angular(
    PeopleList,
    [],
    [
      'FetchCRMDataService',
      'MainService',
    ]))
  .component('integrations', react2angular(
    Integrations,
    [],
    [],
  ))
  .component('addIntegration', react2angular(
    AddIntegration,
    [],
    [],
  ))
  .component('editIntegration', react2angular(
    EditIntegration,
    [
      'integrationId',
    ],
    [],
  ))
  .component('addToRoute', react2angular(
    AngularAddToRoute,
    [
      'entities',
      'entityType',
    ],
    [],
  ))
  .component('reactNav', react2angular(
    NavBar,
    [
    ],
    ['$route', 'SettingsService'],
  ))
  .name;
