import React, {useCallback} from 'react';
import styled from 'styled-components';
import {Button, Space} from 'antd';
import IntegrationService from '../../../type/enum/IntegrationService';

const Container = styled(Button)`
  &.ant-btn {
    padding: 1rem;
    height: 200px;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
`;

const Logo = styled.img`
  width: 100%;
`;

interface Props {
  enabled: boolean,
  name: string,
  logo: string,
  type: IntegrationService,
  onSelect?: (service: IntegrationService | undefined) => void,
  selectedService?: IntegrationService,
}

const ServiceButton: React.FC<Props> = ({enabled, name, logo, type, onSelect, selectedService}) => {
  const handleClick = useCallback(
    () => onSelect && onSelect(selectedService === type ? undefined : type),
    [onSelect, selectedService, type],
  );
  return (
    <Container
      disabled={!enabled}
      onClick={handleClick}
      type={selectedService === type ? 'primary' : 'default'}
    >
      <Space direction="vertical">
        <Logo src={logo} alt={name} />
        {name}
        {!enabled && (
          <span>Coming soon!</span>
        )}
      </Space>
    </Container>
  );
};

export default ServiceButton;
