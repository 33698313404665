import analyticsService from '../shared-services/analytics-service';
import helperService from '../shared-services/helper-service';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import mapActions from '../store/store-helpers';

export default function CrmActivitiesCtrl(
  $scope, $location, $route, MainService, BaseController,
  TeamService, CrmActivityTypesNetworkService, CrmActivitiesService, CacheService,
) {
  // init global utility functions
  const MODEL = window.DataModel;

  // extend BaseController methods onto scope
  Object.assign($scope, BaseController);
  mapActions($scope, ['modals']);
  $scope.modalsActions.resetVisibility('noActivitiesModal');

  // set localStorage equal to $scope to get html-binding
  $scope.localStorage = safeLocalStorage;
  $scope.crmActivity = {
    types: [],
  };

  // bind data model to scope for access in views
  $scope.data = MODEL;

  $scope.currentState = MODEL.cachedState.crmActivities;
  MODEL.currentCRMObject = {};
  MODEL.currentCRMObjectType = '';

  $(document.body).show();

  $scope.setLeadsView = () => {
    $scope.data.currentLeadsView = $scope.currentState.user ? $scope.currentState.user : undefined;
    $scope.viewFilterOptions = [
      {
        id: 'unassigned',
        username: 'Unassigned',
      },
    ].concat(MODEL.TeamsService.verifiedTeamMembers);
  };

  $scope.filters = {};
  if ($scope.currentState.user) {
    $scope.filters.viewAs = $scope.currentState.user;
  }

  if (!Array.isArray(MODEL.TeamsService.verifiedTeamMembers) || !MODEL.TeamsService.verifiedTeamMembers.length) {
    TeamService.getOrgDetails()
      .then(() => {
        $scope.setLeadsView();
      })
      .catch((error) => {
        helperService.showAndLogError(error, "We could not retrieve your organization's details. Please reload the page or retry login.");
      });
  } else {
    $scope.setLeadsView();
  }

  $scope.getActivityTypes = async (orgId) => {
    const types = await CrmActivityTypesNetworkService.getTypes(orgId, {$order: 'displayOrder'});
    $scope.$evalAsync(() => {
      $scope.crmActivity.types = types.data;
    });
    return types;
  };

  $scope.page = $scope.currentState.page;
  $scope.column = $scope.currentState.column;
  $scope.ascending = $scope.currentState.ascending;

  const orgId = safeLocalStorage.currentUser.organization.id;

  const currentRoute = $route.current.id;
  MainService.setCurrentPage();

  if (!MODEL.crmActivityTypes) {
    CrmActivityTypesNetworkService.getTypes(safeLocalStorage.currentUser.organization.id)
      .then((response) => {
        MODEL.crmActivityTypes = response.data;
      });
  }

  MODEL.crmActivities = [];

  // list page
  if (currentRoute === 'crmActivitiesPage') {
    $scope.getActivityTypes(orgId)
      .then(() => {
        MODEL.show.loader = true;
        const {filters, page, column, ascending} = $scope.getCurrentViewState();
        CrmActivitiesService.setViewBasedOnType(false, filters, page, column, ascending);
      })
      .catch((error) => {
        helperService.showAndLogError(error);
      });
    // calendar page
  } else if (currentRoute === 'crmActivitiesCalPage') {
    $scope.getActivityTypes(orgId)
      .then(() => {
        MODEL.CrmActivitiesService.calendarViewStartDate = '';
        MODEL.CrmActivitiesService.calendarViewEndDate = '';
        CrmActivitiesService.initFullCalendar();
        MODEL.currentSubPage = 'calendar';
      })
      .catch((error) => {
        helperService.showAndLogError(error);
      });
  } else if (currentRoute === 'crmActivitiesCalSettingsPage') { // calendar settings page
    MODEL.show.loader = true;
    const promises = [$scope.getActivityTypes(orgId)];
    const urlParams = new URLSearchParams(window.location.hash);

    if (urlParams.has('access_token')) {
      promises.push(CrmActivitiesService.saveNylasToken(urlParams.get('access_token'), urlParams.get('account_id')));
    }

    Promise.all(promises)
      .finally(() => {
        if (urlParams.has('access_token')) {
          // Delete all that nylas info from the url
          $location.url('activities/calendar-settings');
        } else {
          window.refreshDom({loader: false}, 'show');
        }
      })
      .catch((error) => {
        helperService.showAndLogError(error);
      });
  }

  $scope.filterCrmActivitiesByCrmActivityType = async function (map, crmActivityTypeId) {
    CacheService.cacheCurrentView(MODEL.currentLeadsView, $scope.page, {crmActivityTypeId});
    MODEL.CrmActivitiesService.selectedTypes = MODEL.cachedState.crmActivities.crmActivityTypeIds;
    const {filters, page, column, ascending} = $scope.getCurrentViewState();
    MODEL.show.loader = true;
    await CrmActivitiesService.setViewBasedOnType(map, filters, page, column, ascending);
    MODEL.show.loader = false;
  };

  $scope.addActivityType = () => {
    MODEL.CrmActivitiesService.showAddCrmActivityTypeModal = true;
    MODEL.CrmActivitiesService.activityTypeName = '';
  };

  $scope.closeActivityTypeModal = () => {
    MODEL.CrmActivitiesService.showAddCrmActivityTypeModal = false;
    MODEL.CrmActivitiesService.currentActivityType = null;
  };

  $scope.submitActivityType = async (name) => {
    if (!name) {
      swal('Error...', 'The activity name cannot be empty', 'error');
      return;
    }

    MODEL.CrmActivitiesService.showAddCrmActivityTypeModal = false;

    let response = null;
    if (MODEL.CrmActivitiesService.currentActivityType) {
      MODEL.CrmActivitiesService.currentActivityType.name = MODEL.CrmActivitiesService.activityTypeName;
      response = await CrmActivityTypesNetworkService.updateCrmActivityType(MODEL.CrmActivitiesService.currentActivityType);
      analyticsService.entityUpdated('Activity Type', response);
    } else {
      // new display order is max of current display order + 1
      const displayOrder = 1 + $scope.crmActivity.types
        .reduce((maxDisplayOrder, {displayOrder}) => (displayOrder > maxDisplayOrder ? displayOrder : maxDisplayOrder), 0);
      response = await CrmActivityTypesNetworkService.createCrmActivityType({name, displayOrder, active: true});
      analyticsService.entityAdded('Activity Type', response);
    }

    if (response.validationErrors) {
      swal('Uh-oh', response.validationErrors[0].message, 'error');
      MODEL.CrmActivitiesService.showAddCrmActivityTypeModal = true;
      return;
    }

    if (MODEL.CrmActivitiesService.currentActivityType) {
      swal('Success...', 'The activity type has been updated successfully.', 'success');
    } else {
      swal('Success...', 'The activity type has been created successfully.', 'success');
    }
    MODEL.CrmActivitiesService.activityTypeName = '';
    MODEL.CrmActivitiesService.currentActivityType = null;
    $scope.getActivityTypes(orgId);
  };

  $scope.getCurrentViewState = () => {
    const {page, column, ascending} = $scope.currentState;
    return {filters: BaseController.buildFilters(), page, column, ascending};
  };

  $scope.toggleComplete = async (activity, event) => {
    if (event) {
      event.stopPropagation();
    }
    const response = await CrmActivitiesService.toggleComplete(activity);
    const {filters, page, column, ascending} = $scope.getCurrentViewState();
    if (response) {
      CrmActivitiesService.setViewBasedOnType(false, filters, page, column, ascending);
    }
  };

  $scope.editActivity = (activityType) => {
    MODEL.CrmActivitiesService.showAddCrmActivityTypeModal = true;
    MODEL.CrmActivitiesService.activityTypeName = activityType.name;
    MODEL.CrmActivitiesService.currentActivityType = activityType;
  };

  $scope.toggleActivityTypeVisibility = async (activityType) => {
    try {
      MODEL.show.loader = true;
      const payload = {...activityType, active: !activityType.active};
      const updatedActivityType = await CrmActivityTypesNetworkService.updateCrmActivityType(payload);
      const index = $scope.crmActivity.types.indexOf(activityType);
      if (index >= 0) {
        $scope.crmActivity.types[index] = updatedActivityType;
      }
      if (!updatedActivityType.active && MODEL.CrmActivitiesService.selectedTypes.includes(activityType.id)) {
        // toggle activity type filter if it became inactive
        await $scope.filterCrmActivitiesByCrmActivityType(false, activityType.id);
      } else {
        // otherwise just reload the activity list
        const {filters, page, column, ascending} = $scope.getCurrentViewState();
        await CrmActivitiesService.setViewBasedOnType(false, filters, page, column, ascending);
      }
      window.refreshDom({loader: false}, 'show');
    } catch (e) {
      window.refreshDom({loader: false}, 'show');
      swal('Oops...', 'Something went wrong, please try again later.', 'error');
    }
  };

  $scope.deleteActivityType = async (activityType) => {
    try {
      await swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this activity type!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete now',
      });
      MODEL.show.loader = true;
      await CrmActivityTypesNetworkService.deleteCrmActivityType(activityType.id);
      await $scope.getActivityTypes(orgId);
      if (MODEL.CrmActivitiesService.selectedTypes.includes(activityType.id)) {
        // toggle activity type if it was selected
        await $scope.filterCrmActivitiesByCrmActivityType(false, activityType.id);
      }
      window.refreshDom({loader: false}, 'show');
      swal('Deleted', 'Activity type has been deleted.', 'success');
    } catch (e) {
      if (e === 'cancel') {
        return;
      }
      window.refreshDom({loader: false}, 'show');
      return swal('Oops...', 'There was a problem deleting this activity type.', 'error');
    }
  };

  $scope.persistActivityTypesOrder = async (crmActivityTypes) => {
    // map for a previous' crm activity types display orders to compare to new ones
    const idToDisplayOrderMap = $scope.crmActivity.types.reduce(
      (result, activityType) => Object.assign(result, {[activityType.id]: activityType.displayOrder}),
      {},
    );
    // only update those activity types whose displayOrder has been changed
    const activityTypesToUpdate = crmActivityTypes.filter(({id, displayOrder}) => idToDisplayOrderMap[id] !== displayOrder);
    if (!activityTypesToUpdate.length) {
      return;
    }

    try {
      MODEL.show.loader = true;
      await Promise.all(activityTypesToUpdate.map(activityType => CrmActivityTypesNetworkService.updateCrmActivityType(activityType)));
      await $scope.getActivityTypes(orgId);
      window.refreshDom({loader: false}, 'show');
    } catch (e) {
      window.refreshDom({loader: false}, 'show');
      swal('Oops...', 'There was a problem saving activity types order, please try again.', 'error');
      $scope.getActivityTypes(orgId);
    }
  };

  $scope.openCalendarSettings = () => {
    $location.url('activities/calendar-settings');
  };

  $scope.closeCalendarSettings = () => {
    window.history.back();
  };
}

CrmActivitiesCtrl.$inject = [
  '$scope', '$location', '$route', 'MainService', 'BaseController',
  'TeamService', 'CrmActivityTypesNetworkService', 'CrmActivitiesService', 'CacheService',
];
