import React, {useCallback, useEffect, useMemo, useState} from 'react';
import moment, {unitOfTime} from 'moment';
import styled from 'styled-components';
import Checkbox from 'react-md/lib/SelectionControls/Checkbox';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import CircularProgress from 'react-md/lib/Progress/CircularProgress';
import Activity from '../../../type/Activity';
import ActivityType from '../../../type/ActivityType';
import activitiesNetworkService, {FetchFilters} from '../../../../network-services/crm-activities-network-service';
import Company from '../../../type/Company';
import RecapGraph from './RecapGraph';
import EntityType from '../../../type/EntityType';
import DateRange from '../type/DateRange';
import theme from '../../../style/theme';

const RecapContainer = styled.section`
  margin-bottom: 16px;
  padding: 16px;
  background-color: white;
  border: 1px solid ${theme.color.border};
  border-radius: 16px;
`;

const RecapHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const RecapTypes = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const NoActivitiesMessage = styled.div`
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  font-weight: 500;
  color: #333;
`;

const createThisDateRange = (unit: unitOfTime.DurationConstructor): DateRange => ({
  start: moment().startOf(unit).toDate(),
  end: moment().endOf(unit).toDate(),
});

const createLastDateRange = (unit: unitOfTime.DurationConstructor): DateRange => ({
  start: moment().startOf(unit).subtract(1, unit).toDate(),
  end: moment().startOf(unit).toDate(),
});

const recapRangeLabels = [
  'Last 2 weeks',
  'Last 14 days',
  'This Month',
  'Last Month',
  'This Quarter',
  'Last Quarter',
  'This Year',
  'Last Year',
];

const recapRangeUnitOfTime = new Map<string, unitOfTime.DurationConstructor>([
  ['Last 2 weeks', 'day'],
  ['Last 14 days', 'day'],
  ['This Month', 'day'],
  ['Last Month', 'day'],
  ['This Quarter', 'week'],
  ['Last Quarter', 'week'],
  ['This Year', 'month'],
  ['Last Year', 'month'],
]);

const recapRangeValues = new Map<string, DateRange>([
  ['Last 2 weeks', ({
    start: moment().endOf('week').subtract(2, 'weeks').toDate(),
    end: moment().endOf('week').toDate(),
  })],
  ['Last 14 days', ({
    start: moment().endOf('day').subtract(14, 'days').toDate(),
    end: moment().endOf('day').toDate(),
  })],
  ['This Month', createThisDateRange('month')],
  ['Last Month', createLastDateRange('month')],
  ['This Quarter', createThisDateRange('quarter')],
  ['Last Quarter', createLastDateRange('quarter')],
  ['This Year', createThisDateRange('year')],
  ['Last Year', createLastDateRange('year')],
]);

interface Props {
  activityTypes?: ActivityType[],
  company: Company,
  goToEntity: (entityType: EntityType, entityId: number) => void,
}

const Recap: React.FC<Props> = ({activityTypes, company, goToEntity}) => {
  const {id} = company;
  // @ts-ignore
  const [recapRangeLabel, setRecapRangeLabel] = useState<string>('Last 2 weeks');
  const sortedActivityTypes: ActivityType[] = (activityTypes ?? [])
    .sort((a, b) => a.displayOrder - b.displayOrder);
  const defaultActivityTypes = useMemo(
    () => (activityTypes ?? [])
      .filter(({active, isDefault}) => active && isDefault)
      .sort((a, b) => a.displayOrder - b.displayOrder),
    [activityTypes],
  );

  const [selectedTypes, setSelectedTypes] = useState<Set<ActivityType>>(new Set(defaultActivityTypes || sortedActivityTypes));
  const handleTypeSelectionChange = useCallback(
    (activityType, selected) => {
      const newSelectedTypes = new Set(selectedTypes);
      if (selected) {
        newSelectedTypes.add(activityType);
      } else {
        newSelectedTypes.delete(activityType);
      }
      setSelectedTypes(newSelectedTypes);
    },
    [selectedTypes, setSelectedTypes],
  );

  const [isLoading, setLoading] = useState(false);
  const [activities, setActivities] = useState<Activity[]>([]);
  useEffect(
    () => {
      const recapRange: DateRange | undefined = recapRangeValues.get(recapRangeLabel);
      if (!recapRange) {
        return;
      }
      const selectedActivityTypeIds = Array.from(selectedTypes).map(({id}) => id);
      const filters: FetchFilters = {
        accountId: company.id,
        crmActivityTypeId: {$in: selectedActivityTypeIds},
        startAt: {$gte: recapRange.start.toISOString(), $lt: recapRange.end.toISOString()},
      };
      setLoading(true);
      activitiesNetworkService.fetchAllCrmActivities(
        false,
        filters,
        1,
        'startAt',
        true,
        true,
      )
        .then(({data}) => {
          setLoading(false);
          setActivities(data);
        })
        .catch(() => setLoading(false));
    },
    [company, recapRangeLabel, selectedTypes],
  );

  return (
    <RecapContainer>
      <RecapHeader>
        <div>
          <SelectField
            id={`recap-range-${id}`}
            label="Recap"
            menuItems={recapRangeLabels}
            simplifiedMenu={false}
            onChange={(value) => setRecapRangeLabel(value as string)}
            style={{width: '200px'}}
            value={recapRangeLabel}
          />
        </div>
        {sortedActivityTypes.length > 0 && (
          <RecapTypes>
            {sortedActivityTypes.map(activityType => (
              <Checkbox
                checked={selectedTypes.has(activityType)}
                id={`recap-type-${id}-${activityType.name}`}
                key={activityType.id}
                label={activityType.name}
                name="recap-type"
                onChange={(selected) => handleTypeSelectionChange(activityType, selected)}
              />
            ))}
          </RecapTypes>
        )}
      </RecapHeader>
      {(selectedTypes.size && !isLoading)
        ? (
          <RecapGraph
            activities={activities}
            activityTypes={selectedTypes}
            goToEntity={goToEntity}
            dateRange={recapRangeValues.get(recapRangeLabel)!}
            dateUnit={recapRangeUnitOfTime.get(recapRangeLabel)!}
          />
        )
        : (
          <NoActivitiesMessage>
            {isLoading
              ? <CircularProgress id={`loading-activities-recap-${company.id}`} />
              : 'No activities'
            }
          </NoActivitiesMessage>
        )
      }
    </RecapContainer>
  );
};

export default Recap;
