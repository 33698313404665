import max from 'lodash/max';
import sortBy from 'lodash/sortBy';
import accountsNetworkService from '../network-services/accounts-network-service';
import contactsNetworkService from '../network-services/contacts-network-service';
import customFieldsNetworkService from '../network-services/custom-fields-network-service';
import dealsNetworkService from '../network-services/deals-network-service';

export default function ImportService(MainService, ImportNetworkService) {
  // main utility functions
  const UTILS = window.mmcUtils;
  const MODEL = window.DataModel;

  // service to return
  const service = {};

  // other common vars
  MODEL.MappingService.importTimeInterval = UTILS.env === 'production' ? () => 640 : () => Math.random() * 640 * UTILS.getRandomInRange(0.75, 1.25);

  service.separatorKey = '__separator__';
  service.skipKey = '__skip';

  service.populateColumnsForEntity = entityType => {
    if (!['accounts', 'contacts', 'deals'].includes(entityType)) {
      return;
    }

    // copy default columns
    /** @var {{key: string, label: string, required?: boolean, skip?: boolean, addNew?: boolean, separator?: boolean}[]} */
    let defaultColumns = MODEL.ImportService.defaultKnownColumns[entityType].map(column => ({...column}));
    const primaryKeys = ['parentAccount', 'contact'];

    // add custom field columns
    const customColumns = MODEL.ImportService.customFields[entityType].map(({displayName, required}) => ({
      key: displayName,
      label: displayName,
      required,
    }));

    // and some special columns
    const specialColumns = [
      {
        key: 'id',
        label: 'MMC ID',
      },
    ];
    const primaryColumns = [
      {
        key: service.skipKey,
        label: 'SKIP',
        skip: true,
      },
      ...defaultColumns.filter(({key}) => primaryKeys.includes(key)),
    ];
    defaultColumns = defaultColumns.filter(({key}) => !primaryKeys.includes(key));

    // create separator column
    const maxColumnNameLength = max([].concat(
      defaultColumns.map(({label}) => label.length),
      customColumns.map(({label}) => label.length),
      specialColumns.map(({label}) => label.length),
    ));
    // I had to add "disabled: true" because disable-with-attr directive doesn't work well with a condition
    // like (column.disabled || column.separated). This directive is used in the import-step-5.html view.
    const separatorItem = {
      key: service.separatorKey, label: '\u2500'.repeat(maxColumnNameLength), separator: true, disabled: true,
    };

    MODEL.ImportService.availableColumns = [].concat(
      primaryColumns,
      [separatorItem],
      sortBy(defaultColumns, 'label'),
      [separatorItem],
      sortBy(customColumns, 'label'),
      customColumns.length > 0 ? [separatorItem] : [],
      specialColumns,
    )
    // add an asterisk for the required fields
      .map(column => ({...column, label: `${column.label}${column.required ? ' (*)' : ''}`}));

    MODEL.ImportService.availableColumnsMap = MODEL.ImportService.availableColumns.reduce(
      (result, column) => Object.assign(result, {[column.key]: column}),
      {},
    );
    return MODEL.ImportService.availableColumns;
  };

  /**
   * Prepares column name for matching. Only keeps alphanumeric chars and space, converting string to a lower case.
   * @param {string} columnName column name to prepare
   * @returns {string} prepared column name
   */
  const prepareColumnNameForMatching = columnName => columnName.replace(/[^a-z0-9 ]/gi, '').toLowerCase();

  /**
   * Detects if internal and external columns are matching.
   *
   * Columns are matching if 6 first letters of an external column are present in the internal column's name.
   * Also handles a special case for the "postal code" internal column which also matches external columns
   * "zip", and "postco".
   *
   * @param {string} externalColumnName external column name in lower case with no special chars except space
   * @param {string} internalColumnName internal column name in lower case with no special chars except space
   * @return {boolean} <tt>true</tt> if given external column matches given internal column, <tt>false</tt> otherwise
   *
   * @see {prepareColumnNameForMatching}
   */
  const arePreparedColumnsMatching = (externalColumnName, internalColumnName) => externalColumnName.split(' ')
    .filter(word => (
      // only keep words longer than 3 letters to avoid matching by "in", "the", "a", etc.
      word.length > 3
      // also keep word when it's "zip"
      || word === 'zip'
      // also keep word when one of internalColumnName's parts has the same length as word
      // (to match by "SKU" and similar abbreviations)
      || internalColumnName.split(' ').some(w => w.length === word.length)
    ))
    .some(word => {
      const first6Letters = word.slice(0, 6);
      if (internalColumnName.includes(first6Letters)) {
        return true;
      }
      // special case for the "postal code" internal column name
      return internalColumnName === 'postal code' && (word.includes('zip') || word.includes('postco'));
    });

  /**
   * Matches given external column names to internal ones.
   *
   * @param {string[]} externalColumnNames
   * @param {{key: string, label: string}[]} internalColumns
   * @returns {Object<string, string>} found matches where key is external column name and the value is the
   * corresponding internal column name
   */
  service.findMatches = (externalColumnNames, internalColumns) => {
    const preparedExternalColumnNames = externalColumnNames.map(prepareColumnNameForMatching);

    return internalColumns.reduce(
      (result, {key, label}) => {
        const preparedInternalColumnName = prepareColumnNameForMatching(label);
        const matchingExternalColumnIndex = key === 'id' ? -1 : preparedExternalColumnNames.findIndex(externalColumnName => arePreparedColumnsMatching(externalColumnName, preparedInternalColumnName));

        return matchingExternalColumnIndex >= 0
          ? Object.assign(result, {[externalColumnNames[matchingExternalColumnIndex]]: key})
          : result;
      },
      {},
    );
  };

  /**
   * Returns first non-empty preview for the given header index
   *
   * @param {number} headerIndex
   * @param {string[][]} previews
   * @returns {string|undefined} found preview value or <tt>undefined</tt> if nothing was found
   */
  const getColumnPreview = (headerIndex, previews) => {
    const row = previews.find(row => !!row[headerIndex]);
    return row ? row[headerIndex] : undefined;
  };

  /**
   * Checks if the given column is a regular one. Regular = not separator, neither "SKIP" nor "Add New Column".
   *
   * @param {{skip?: boolean, separator?: boolean, addNew?: boolean}} column to verify
   * @returns {boolean} <tt>true</tt> if given column is a regular one and <tt>false</tt> otherwise
   */
  const regularColumnFilter = ({skip, separator, addNew}) => !skip && !separator && !addNew;

  /**
   * Processes preview endpoint's response updating importDataArray.matchedColumns,
   * importDataArray.unmatchedColumns fields in MODEL.ImportService.
   * Also disables columns in MODEL.ImportService.availableColumns which matched.
   *
   * @param {string[]} headers found headers
   * @param {string[][]} preview found previews
   */
  service.populateMatchedAndUnmatchedColumns = (headers, preview = []) => {
    const matchedColumns = [];
    const unmatchedColumns = [];

    // A list of regular columns
    const columnsToMatchTo = MODEL.ImportService.availableColumns.filter(regularColumnFilter);
    const headerToColumnKey = service.findMatches(headers, columnsToMatchTo);

    const skipColumn = MODEL.ImportService.availableColumns.find(({skip}) => skip);

    headers.forEach((header, index) => {
      const matchedKey = headerToColumnKey[header];
      if (matchedKey) {
        matchedColumns.push({
          columnName: header,
          preview: getColumnPreview(index, preview),
          matchedKey,
          index: matchedColumns.length,
        });
      } else {
        unmatchedColumns.push({
          columnName: header,
          preview: getColumnPreview(index, preview),
          matchedKey: skipColumn.key,
          index: unmatchedColumns.length,
        });
      }
    });

    // mark matched columns disabled so that there're not be available for selection the UI
    Object.values(headerToColumnKey).forEach(key => {
      MODEL.ImportService.availableColumnsMap[key].disabled = true;
    });

    MODEL.ImportService.importDataArray.matchedColumns = matchedColumns;
    MODEL.ImportService.importDataArray.unmatchedColumns = unmatchedColumns;
  };

  service.dropzonify = function (selector) {
    $(selector).dropzone({
      clickable: true,
      maxFiles: 1,
      maxFilesize: 10, // MB
      acceptedFiles: '.csv, .xls, .xlsx',
      // done() is never called that's why file is not actually uploaded to that dummy url below
      accept: async (selectedFile) => {
        selectedFile.previewElement.remove();

        MODEL.show.loader = true;
        try {
          const {file, headers, preview} = await ImportNetworkService.getPreview(selectedFile);
          MODEL.ImportService.spreadsheetHeaders = headers;
          MODEL.ImportService.spreadsheetPreview = preview;
          window.refreshDropzone(file);
        } catch (e) {
          console.error('Failed to upload', e);
          swal('Uh-oh', "Something went wrong. Please check file's format and try again.", 'error');
        }

        window.refreshDom({loader: false}, 'show');
      },
      autoProcessQueue: false,
      url: 'http://localhost:1339/dummy', // dummy url needed for dropzone to init, we don't upload there anyway
    });
  };

  const isValidFieldName = fieldName => {
    const cleanedFieldName = (fieldName || '').replace(/[^a-z0-9]/gi, '').trim();

    if (!cleanedFieldName.length) {
      return 'Field name is blank, please try again.';
    }
    if (MODEL.ImportService.availableColumns.some(({key}) => key.replace(/[^a-z0-9]/gi, '').toLowerCase() === cleanedFieldName.toLowerCase())) {
      return 'Field with this name already exists';
    }

    return true;
  };

  // create new custom field
  service.createNewCustomField = (columnIndex, isMatchingColumn) => {
    const name = MODEL.ImportService[isMatchingColumn ? 'newColumnNameForMatchedColumn' : 'newColumnNameForUnmatchedColumn'];
    const validationResult = isValidFieldName(name);

    if (validationResult !== true) {
      swal('Invalid Field', validationResult, 'error');
    }

    // TODO: complete once it's clear what to do with the custom fields
    //
    // MODEL.show.loader = true;
    // var customField = window.sanitizeNewField(newFieldName);
    // window.addCustomFieldIfValidFromDom(customField, MODEL.ImportService.crmView)
    //     .then(function() {
    //     window.refreshDom({ loader: false }, "show");
    //         console.log("success adding new field!", customField);
    //     });
    //
    // MODEL.ImportService.availableColumns.splice(MODEL.ImportService.availableColumns.length - 1, 0, {"key" : customField, "disabled" : true});
    //
    // MODEL.ImportService.importDataArray.matchedColumns.forEach(function(matchedColumn){
    //     if (matchedColumn.matchedFieldName === "Add New Custom Field") {
    //         matchedColumn.matchedFieldName = customField;
    //         $("#selectOptions_matched_" + index).show();
    //         $("#customFieldDiv_matched_" + index).hide();
    //     }
    // });
    //
    // MODEL.ImportService.importDataArray.unmatchedColumns.forEach(function(matchedColumn){
    //     if (matchedColumn.matchedFieldName === "Add New Custom Field") {
    //         matchedColumn.matchedFieldName = customField;
    //         $("#selectOptions_unmatched_" + index).show();
    //         $("#customFieldDiv_unmatched_" + index).hide();
    //     }
    // });
    //
    //
    // service.toggleColumns(columnIndex, isMatchingColumn);
  };

  // choose columns
  service.toggleColumns = function (columnIndex, isMatchedColumn) {
    // Update "disabled" key for all availableColumns. This is a pretty crappy code, I know
    const matchedColumnKeys = [].concat.apply([], [
      MODEL.ImportService.importDataArray.matchedColumns.map(({matchedKey}) => matchedKey),
      MODEL.ImportService.importDataArray.unmatchedColumns.map(({matchedKey}) => matchedKey),
    ]);
    MODEL.ImportService.availableColumns.filter(regularColumnFilter).forEach(column => {
      column.disabled = matchedColumnKeys.includes(column.key);
    });

    const columnList = MODEL.ImportService.importDataArray[isMatchedColumn ? 'matchedColumns' : 'unmatchedColumns'];
    const selectedColumn = MODEL.ImportService.availableColumnsMap[columnList[columnIndex].matchedKey];
    if (selectedColumn.addNew) {
      if (isMatchedColumn) {
        MODEL.ImportService.creatingNewColumnForMatchedColumnIndex = columnIndex;
      } else {
        MODEL.ImportService.creatingNewColumnForUnmatchedColumnIndex = columnIndex;
      }

      // look if there was another column in the "Add new" state and cancel creating a field for it
      const columnBeingEditedToo = columnList.find(({matchedKey}, index) => matchedKey === selectedColumn.key && index !== columnIndex);
      if (columnBeingEditedToo) {
        columnBeingEditedToo.matchedKey = MODEL.ImportService.availableColumns.find(({skip}) => skip).key;
      }
    }
  };

  // restart import -> re-initiallise all import dependent variables
  service.resetImport = function () {
    // refresh all import service variable
    MODEL.ImportService.cleanUpAdressInfo = false;
    MODEL.ImportService.geoManagementState = 'automaticPreserveAddress';
    MODEL.ImportService.updateExistingCustomers = 0;
    MODEL.ImportService.uniqueColumn = undefined;

    service.populateEntityCount();
    service.populateCustomFields();
  };

  // import process finished
  service.finishImport = function () {
    MODEL.ImportService.importProcessRunning = false;
    MODEL.ImportService.pinsLeft = '0 / 0';
    MODEL.ImportService.timeRemaining = '00:00';
    MainService.toggleImportProgressBar();
    MODEL.ImportService.importProcessRunning = false;
    MainService.startChainReaction();
    service.resetImport();
    $('#startImportButton').show();
  };

  // import process started
  service.startImport = function (result) {
    const {contactsSynced} = result;
    const {timeRemaining} = result;
    MODEL.ImportService.pinsLeft = `${contactsSynced} / ${MODEL.ImportService.spreadsheetData.length - 1}`;
    const percentCompleted = (contactsSynced / (MODEL.ImportService.spreadsheetData.length - 1)) * 100;
    MODEL.ImportService.timeRemaining = `(${timeRemaining} left)`;
    $('#progress-bar-import').css('width', `${parseInt(percentCompleted, 0)}%`);
    $('#import-status').html(`${MODEL.ImportService.pinsLeft}<span>${MODEL.ImportService.timeRemaining}</span>`);
    MODEL.ImportService.importProcessRunning = true;
    $('#startImportButton').hide();
  };

  service.showImportConfirmation = () => importConfirmation();

  let importConfirmation = () => swal({
    title: 'Confirm',
    text: `Are you sure you want to upload ${MODEL.ImportService.spreadsheetName}? You will not be able to cancel the import process once it has been started.`,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#F55252',
    confirmButtonText: '<i class="icon ion-android-upload"></i>   Upload',
    cancelButtonText: '<i class="icon ion-android-cancel"></i>   Cancel',
    buttonsStyling: true,
  });

  /**
   * Fetches and stores accounts, contacts, and deals count.
   * @returns {Promise<void>}
   */
  service.populateEntityCount = async () => {
    const [accountCount, contactCount, dealCount] = await Promise.all([
      accountsNetworkService.getAccountsCount(),
      contactsNetworkService.getContactsCount(),
      dealsNetworkService.getDealsCount(),
    ]);

    window.refreshDom(
      {accountCount, contactCount: contactCount.total, dealCount},
      'ImportService',
    );
  };

  /**
   * Fetches and stores custom fields for accounts, contacts, and deals.
   * @returns {Promise<void>}
   */
  service.populateCustomFields = async () => {
    const [accountCustomFields, contactCustomFields, dealCustomFields] = await Promise.all(
      ['accounts', 'contacts', 'deals'].map(entityType => customFieldsNetworkService.getFields(entityType)),
    );

    MODEL.ImportService.customFields = {
      accounts: accountCustomFields.data,
      contacts: contactCustomFields.data,
      deals: dealCustomFields.data,
    };

    window.refreshDom(
      {
        customFields: {
          accounts: accountCustomFields.data,
          contacts: contactCustomFields.data,
          deals: dealCustomFields.data,
        },
      },
      'ImportService',
    );
  };

  /**
   * Iterates over availableColumns and checks that every required column is selected. Also checks requiresIfSet
   * flag of a column.
   * @returns {string | true} an error message or  <tt>true</tt> if validation is successful
   */
  const validateFieldMapping = () => {
    const mappedColumnKeys = new Set([].concat(
      MODEL.ImportService.importDataArray.matchedColumns.filter(regularColumnFilter).map(({matchedKey}) => matchedKey),
      MODEL.ImportService.importDataArray.unmatchedColumns.filter(regularColumnFilter).map(({matchedKey}) => matchedKey),
    ));

    const validationError = MODEL.ImportService.availableColumns
      .map(({
        key, label, required, requiresIfSet,
      }) => {
        if (required && !mappedColumnKeys.has(key)) {
          return `"${label}" column is required but not selected`;
        }
        if (Array.isArray(requiresIfSet) && mappedColumnKeys.has(key)) {
          const notSetKeys = requiresIfSet.filter(k => !mappedColumnKeys.has(k));
          if (notSetKeys.length) {
            const correspondingLabes = notSetKeys.map(key => MODEL.ImportService.availableColumnsMap[key].label);
            return `"${label}" requires these column(s) to be also selected: ${correspondingLabes.join(', ')}`;
          }
        }
        return true;
      })
      .find(error => error !== true);

    return validationError || true;
  };

  const validateAndShowErrorMessage = () => {
    const validationResult = validateFieldMapping();
    if (validationResult !== true) {
      swal('Uh-oh', validationResult, 'error');
      return false;
    }

    if (MODEL.ImportService.updateExistingCustomers && !MODEL.ImportService.uniqueColumn) {
      swal('Uh-oh', 'Please select a unique identifier.', 'error');
      return false;
    }

    return true;
  };

  service.completeImport = async (file) => {
    if (!validateAndShowErrorMessage()) {
      return false;
    }

    // column key to csv column name map
    const mappings = {

      ...MODEL.ImportService.importDataArray.matchedColumns
        .filter(({matchedKey}) => !MODEL.ImportService.availableColumnsMap[matchedKey].skip)
        .reduce((result, {columnName, matchedKey}) => Object.assign(result, {[matchedKey]: columnName}), {}),
      ...MODEL.ImportService.importDataArray.unmatchedColumns
        .filter(({matchedKey}) => !MODEL.ImportService.availableColumnsMap[matchedKey].skip)
        .reduce((result, {columnName, matchedKey}) => Object.assign(result, {[matchedKey]: columnName}), {}),
    };

    MODEL.show.loader = true;
    const response = await ImportNetworkService.startImport(
      MODEL.ImportService.crmView,
      file.id,
      mappings,
      {
        updateExistingCustomers: MODEL.ImportService.updateExistingCustomers,
        uniqueColumn: MODEL.ImportService.uniqueColumn,
        geoManagementState: MODEL.ImportService.geoManagementState,
      },
    );
    MODEL.ImportService.runningImportId = response.id;
    window.refreshDom({loader: false}, 'show');

    if (response.status === 'queued' || response.status === 'running') {
      window.refreshDom(
        {importProcessRunning: true},
        'ImportService',
      );
    }

    MainService.updateImportProgress();

    return true;
  };

  service.fetchRecentImports = async () => {
    MODEL.ImportService.importHistory = [];
    const recentImports = await ImportNetworkService.fetchImports({$order: '-updatedAt', $limit: 20});
    if (recentImports.data && !recentImports.data.length) {
      return;
    }
    window.refreshDom(
      {importHistory: recentImports.data},
      'ImportService',
    );
  };

  service.fetchImportData = async (importId) => ImportNetworkService.fetchImportData({$filters: {importId}});

  service.getEmptyFieldWithCondition = (entityColumns, mapping) => entityColumns.filter(entityColumn => {
    if (mapping[entityColumn.key]) {
      return false;
    }
    let response = false;
    if (entityColumn.requiredIfEmpty) {
      if (!entityColumn.invalidIfSet || !entityColumn.invalidIfSet.some(requiredField => mapping[requiredField])) {
        response = entityColumn.requiredIfEmpty.some(requiredField => !mapping[requiredField]);
      }
    }
    return response;
  });

  service.getOverfillWithCondition = (entityColumns, mapping) => entityColumns.filter(entityColumn => {
    if (!mapping[entityColumn.key]) {
      return false;
    }
    let response = false;
    if (entityColumn.invalidIfSet) {
      response = entityColumn.invalidIfSet.some(requiredField => mapping[requiredField]);
    }
    return response;
  });

  return service;
}

ImportService.$inject = ['MainService', 'ImportNetworkService'];
