import {useEffect} from 'react';

const useInitializer = (initializer: () => any) => useEffect(
  () => {
    initializer();
  },
  [initializer],
);

export default useInitializer;
