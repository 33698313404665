import cloneDeep from 'lodash/cloneDeep';
import accountsNetworkService from '../network-services/accounts-network-service';
import contactsNetworkService from '../network-services/contacts-network-service';
import customFieldsNetworkService from '../network-services/custom-fields-network-service';
import activitiesNetworkService from '../network-services/crm-activities-network-service';
import dealsNetworkService from '../network-services/deals-network-service';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import helperService from '../shared-services/helper-service';
import mapActions from '../store/store-helpers';

export default function ReportsService(ReportNetworkService, ActivityNetworkService) {
  const MODEL = window.DataModel;
  const service = {};

  mapActions(service, ['modals']);

  // show create report
  service.showCreateReport = async () => {
    MODEL.ReportsService.isReportEditingVisible = true;

    MODEL.ReportsService.createReportView = 'accounts';
    service.resetFilterVar();
    $('#accountsRadio').prop('checked', true);
    MODEL.ReportsService.currentSelectedReportId = '';
    MODEL.ReportsService.selectedColumns = [];
    MODEL.ReportsService.reportName = '';
    MODEL.ReportsService.reportDescription = '';
    await service.initAvailableColumns();
  };

  service.resetFilterVar = () => {
    MODEL.FilterService.currentCategorySelected = '';
    MODEL.FilterService.currentComparatorSelected = '';
    MODEL.FilterService.selectedIndividualGroupFilter = '';
    MODEL.FilterService.currentCategoryModel = {};
    MODEL.FilterService.currentSelectedGroups = [];
    MODEL.FilterService.currentComparatorModel = {};
    MODEL.FilterService.filtersSelected = [];
    MODEL.FilterService.groupsFilterModel = {};
    MODEL.FilterService.filterNearbyLatLng = {lat: '', lng: ''};
    MODEL.FilterService.filterNearbyLocation = '';
    MODEL.FilterService.filterMaxMiles = 0;
    MODEL.FilterService.filterCurrentMiles = 0;
    MODEL.FilterService.groupsFilterOptions = 'pins in any';
    MODEL.currentPageNum = 1;
    MODEL.FilterService.filterCurrentMiles = 0;
    if (MODEL.FilterService.filterSlider) {
      MODEL.FilterService.filterSlider.update({from: 0});
    }
  };
  // show all reports
  service.showAllReports = async () => {
    MODEL.ReportsService.isReportEditingVisible = false;
    $('#accountsRadio').prop('checked', true);
    MODEL.ReportsService.currentSelectedReportId = '';
    MODEL.ReportsService.selectedColumns = [];
    await service.initAvailableColumns();
    MODEL.ReportsService.createOrEditReport = 'Create Report';
  };

  // download all Reports
  service.fetchReports = async () => {
    const params = {
      $limit: 1000,
      $order: '-updatedAt',
    };
    try {
      const response = await ReportNetworkService.fetchReports(params);
      MODEL.ReportsService.allReports = response.data;
      MODEL.ReportsService.allReportsCount = response.total;

      if (!response.total && !MODEL.currentLeadsView && !MODEL.FilterService.filtersSelected.length) {
        service.modalsActions.showModal('noReportsModal');
      } else {
        service.modalsActions.resetVisibility('noReportsModal');
      }

      MODEL.ReportsService.reportIdToReportMap = MODEL.ReportsService.allReports.reduce(
        (result, report) => Object.assign(result, {[report.id]: report}),
        {},
      );
      service.updateCurrentReports(MODEL.ReportsService.selectedReportView, MODEL.ReportsService.selectedFolder);
    } catch (e) {
      console.error('failed to load reports', e);
    }
  };

  // highlight selected folder and selected reportView
  service.highlightSelectedReportView = (folderView, selectedFolder) => {
    // highlight selected folder
    $('#allFolder').removeClass('highlightedFolder');
    $('#accountsFolder').removeClass('highlightedFolder');
    $('#dealsFolder').removeClass('highlightedFolder');
    $('#contactsFolder').removeClass('highlightedFolder');
    $('#checkinsFolder').removeClass('highlightedFolder');
    $('#remindersFolder').removeClass('highlightedFolder');
    $('#notesFolder').removeClass('highlightedFolder');
    $('#activitiesFolder').removeClass('highlightedFolder');
    $('#crmActivitiesFolder').removeClass('highlightedFolder');
    $('#membersFolder').removeClass('highlightedFolder');
    $('#trashFolder').removeClass('highlightedFolder');

    $(`#${selectedFolder}Folder`).addClass('highlightedFolder');

    // highlight selected report
    $('#allReportView').removeClass('highlightedHeaderBtn');
    $('#favoritesReportView').removeClass('highlightedHeaderBtn');
    $('#downloadedReportView').removeClass('highlightedHeaderBtn');

    $(`#${folderView}ReportView`).addClass('highlightedHeaderBtn');
  };

  // update reports table acc to selected option : all Fav Downloaded and acc to folder option
  service.updateCurrentReports = (folderView, selectedFolder) => {
    MODEL.ReportsService.selectedReportsLength = 0;
    service.highlightSelectedReportView(folderView, selectedFolder);

    MODEL.ReportsService.selectedReportView = folderView;

    MODEL.ReportsService.currentReports = MODEL.ReportsService.allReports;
    if (folderView === 'favorites') {
      MODEL.ReportsService.currentReports = MODEL.ReportsService.currentReports
        .filter(({isStarred}) => isStarred);
    } else if (folderView === 'downloaded') {
      MODEL.ReportsService.currentReports = MODEL.ReportsService.currentReports
        .filter(({lastGeneratedAt}) => !!lastGeneratedAt);
    }

    MODEL.ReportsService.selectedFolder = selectedFolder;
    if (selectedFolder !== 'all') {
      MODEL.ReportsService.currentReports = MODEL.ReportsService.currentReports
        .filter(({tableName}) => tableName === selectedFolder);
    }

    window.refreshDom({currentReports: MODEL.ReportsService.currentReports}, 'ReportsService');
  };

  // click star
  service.clickStar = async (report) => {
    const response = await ReportNetworkService.updateReport({...report, isStarred: !report.isStarred});
    report.isStarred = response.isStarred;
    service.updateCurrentReports(MODEL.ReportsService.selectedReportView, MODEL.ReportsService.selectedFolder);
  };

  // change reports View
  service.changeCreateReportView = async (selectedView) => {
    MODEL.ReportsService.createReportView = selectedView;

    // reset available columns, selected columns, and data rows
    MODEL.ReportsService.selectedColumns = [];
    MODEL.ReportsService.reportsData = [];
    MODEL.ReportsService.filters = {};
    await service.initAvailableColumns();
  };

  // select columns
  service.selectColumns = () => {
    service.modalsActions.showModal('selectFieldsModal');
  };

  const reportTypeToFieldModel = Object.freeze({
    accounts: MODEL.accountFieldModel,
    contacts: MODEL.contactFieldModel,
    deals: MODEL.dealFieldModel,
    crmActivities: MODEL.crmActivityFieldModel,
  });

  // init available columns
  service.initAvailableColumns = async () => {
    MODEL.ReportsService.currentFieldModel = reportTypeToFieldModel[MODEL.ReportsService.createReportView];

    if (!['accounts', 'contacts', 'deals', 'crmActivities'].includes(MODEL.ReportsService.createReportView)) {
      return; // no custom fields for other views
    }

    const customFields = (await customFieldsNetworkService.getFields(MODEL.ReportsService.createReportView)).data;
    MODEL.ReportsService.currentFieldModel.setCustomFields(customFields);
    MODEL.ReportsService.selectedColumns = MODEL.ReportsService.currentFieldModel.getDefaultFieldsForReport();
    service.fetchPreview();
  };

  // save select columns
  service.saveSelectColumns = (fields) => {
    MODEL.ReportsService.selectedColumns = fields;
    service.fetchPreview();
  };

  // save new report
  service.saveReport = async () => {
    const reportName = MODEL.ReportsService.reportName.trim();
    const reportDescription = MODEL.ReportsService.reportDescription.trim();

    if (!reportName.length) {
      return swal('Uh-Oh', "Title can't be empty. Please enter a title for report.", 'error');
    }

    // if selected columns is empty throw error swal
    if (!MODEL.ReportsService.selectedColumns.length) {
      return swal('Uh-Oh', "You haven't selected any fields. Please select fields.", 'error');
    }

    const customFieldIds = MODEL.ReportsService.selectedColumns
      .filter(({isCustomField}) => isCustomField)
      .map(({customFieldData: {id}}) => id);
    const columnNames = MODEL.ReportsService.selectedColumns
      .filter(({isCustomField}) => !isCustomField)
      .map(({name}) => name);

    try {
      if (!MODEL.ReportsService.currentSelectedReportId) {
        if (MODEL.ReportsService.allReports.some(({name}) => name === reportName)) {
          return swal('Uh-Oh', 'Report name already exists. Please enter a different report name.', 'error');
        }

        MODEL.show.loader = true;
        await ReportNetworkService.createReport(
          reportName,
          reportDescription,
          MODEL.ReportsService.createReportView,
          service.getReportFilters(),
          columnNames,
          customFieldIds,
        );
      } else {

        const report = MODEL.ReportsService.allReports.find(({id}) => id === MODEL.ReportsService.currentSelectedReportId);
        if (!report) {
          return Promise.reject(new Error(`Unexpected error, report id=${MODEL.ReportsService.currentSelectedReportId} not found`));
        }

        MODEL.show.loader = true;
        const updatedReport = {
          ...report,
          name: reportName,
          description: reportDescription,
          tableName: MODEL.ReportsService.createReportView,
          selectedFilters: service.getReportFilters(),
          selectedColumns: columnNames,
          customFields: customFieldIds,
        };

        await ReportNetworkService.updateReport(updatedReport);
      }

      if (MODEL.ReportsService.selectedFolder !== MODEL.ReportsService.createReportView) {
        MODEL.ReportsService.selectedReportView = 'all';
      }
      MODEL.ReportsService.selectedFolder = 'all';
      window.refreshDom(
        {
          selectedReportView: MODEL.ReportsService.selectedReportView,
          selectedFolder: MODEL.ReportsService.selectedFolder,
          currentSelectedReportId: undefined,
          isReportEditingVisible: false,
        },
        'ReportsService',
      );
      await service.fetchReports();
      await service.showAllReports();
    } catch (e) {
      window.refreshDom({'show.loader': false});
      helperService.showAndLogError(e, 'Failed to create report');
      return Promise.reject(e);
    }

    window.refreshDom({loader: false}, 'show');
    return Promise.resolve();
  };

  // open selected report
  service.openSelectedReport = async (report) => {
    MODEL.ReportsService.isReportEditingVisible = true;
    MODEL.ReportsService.createOrEditReport = 'Edit Report';
    $(`#${report.tableName}Radio`).prop('checked', true);

    MODEL.ReportsService.currentSelectedReportId = report.id;
    // set variables
    MODEL.ReportsService.currentReport = report;
    MODEL.ReportsService.reportName = report.name;
    MODEL.ReportsService.reportDescription = report.description;
    MODEL.ReportsService.createReportView = report.tableName;
    MODEL.ReportsService.filters = report.selectedFilters;
    $(`#${report.tableName}Radio`).prop('checked', true);
    await service.initAvailableColumns();
    MODEL.ReportsService.selectedColumns = report.selectedColumns
      .map(columnName => MODEL.ReportsService.currentFieldModel.getByName(columnName))
      .concat(report.customFields.map(id => MODEL.ReportsService.currentFieldModel.getByName(`cf_${id}`)))
      .filter(field => !!field);
    window.refreshDom({selectedColumns: MODEL.ReportsService.selectedColumns}, 'ReportsService');
    await service.fetchPreview();
  };

  service.downloadReport = report => {
    // silently skip re-generating report if it is already being generated
    if (report.status && ['queued', 'running'].includes(report.status)) {
      return Promise.resolve();
    }

    return ReportNetworkService.downloadReport(report.id, safeLocalStorage.currentUser.username);
  };

  service.sendToTrash = report => ReportNetworkService.deleteReport(report.id);

  service.clearSelectedReportIds = () => {
    MODEL.ReportsService.selectedReportIds = [];
  };

  // delete all selected
  service.deleteAllSelected = reportIdList => Promise.all(
    reportIdList.map(id => service.sendToTrash(MODEL.ReportsService.reportIdToReportMap[id])),
  );

  service.downloadAllSelected = reportIdList => Promise.all(
    reportIdList.map(id => service.downloadReport(MODEL.ReportsService.reportIdToReportMap[id])),
  );

  const reportEntityTypeToFetchMethodMap = {
    accounts: filters => accountsNetworkService.fetchAllAccounts(false, filters),
    contacts: filters => contactsNetworkService.fetchAllContacts(false, filters),
    deals: filters => dealsNetworkService.fetchAllDeals(false, filters),
    activities: filters => ActivityNetworkService.fetchPlatformActivities({
      $filters: JSON.stringify(filters),
      $limit: 100,
      $order: '-createdAt',
    }),
    crmActivities: filters => activitiesNetworkService.fetchAllCrmActivities(false, filters),
  };

  service.getReportFilters = () => {
    const filters = cloneDeep(MODEL.ReportsService.filters || {});
    if (MODEL.ReportsService.selectedColumns.some(({name}) => name === 'groups')) {
      filters.includeGroups = true;
    }
    if (MODEL.ReportsService.selectedColumns.some(({name}) => name === 'notes')) {
      filters.includeNotes = true;
    }
    if (MODEL.ReportsService.selectedColumns.some(({isCustomField}) => isCustomField)) {
      filters.includeCustomFields = true;
    }

    if (filters.status) {
      if (filters.status === 'overdue') {
        filters.startAt = {$lt: moment().toISOString()};
      } else if (filters.status === 'upcoming') {
        filters.startAt = {$gt: moment().toISOString()};
      } else if (filters.status === 'completed') {
        filters.completed = true;
      }
      delete filters.status;
    }

    return filters;
  };

  service.fetchPreview = async (newFilters) => {
    if (newFilters) {
      MODEL.ReportsService.filters = newFilters;
    }

    const fetchPreviewData = reportEntityTypeToFetchMethodMap[MODEL.ReportsService.createReportView];
    if (!fetchPreviewData) {
      return;
    }

    const filters = service.getReportFilters();
    try {
      const previewData = await fetchPreviewData(filters);
      const reportsData = previewData.data;
      window.refreshDom({reportsDataCount: previewData.total, reportsData}, 'ReportsService');
    } catch (e) {
      swal('Uh-oh', 'There was a problem loading preview. Please try again later.', 'error');
      throw e;
    }
  };

  return service;
}

ReportsService.$inject = ['ReportNetworkService', 'ActivityNetworkService'];
