export interface HistoryState {
  errorsPage: number;
  warningsPage: number;
  selectedImportId: number | undefined,
}

export const initialHistoryState: HistoryState = {
  errorsPage: 1,
  warningsPage: 1,
  selectedImportId: undefined,
};

type HistoryAction =
  {type: 'setErrorsPage', page: number}
  | {type: 'setWarningsPage', page: number}
  | {type: 'setSelectedImportId', importId: number | undefined};

export const historyReducer = (state: HistoryState, action: HistoryAction): HistoryState => {
  switch (action.type) {
    case 'setErrorsPage': {
      return {
        ...state,
        errorsPage: action.page,
      };
    }
    case 'setWarningsPage': {
      return {
        ...state,
        warningsPage: action.page,
      };
    }
    case 'setSelectedImportId': {
      return {
        ...state,
        errorsPage: 1,
        warningsPage: 1,
        selectedImportId: action.importId,
      };
    }
  }
  return state;
};

export const setErrorsPage = (page: number): HistoryAction => ({type: 'setErrorsPage', page});
export const setWarningsPage = (page: number): HistoryAction => ({type: 'setWarningsPage', page});
export const setSelectedImportId = (importId: number | undefined): HistoryAction => ({type: 'setSelectedImportId', importId});
