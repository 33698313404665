import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import mapActions from '../../../store/store-helpers';
import analyticsService from '../../../shared-services/analytics-service';
import safeLocalStorage from '../../../shared-services/safe-local-storage-service';
import template from './calendar-settings-form.html';
import './calendar-settings-form.scss';

class CalendarSettingsFormController {
  constructor($window, $location, CrmActivitiesService, NylasNetworkService) {
    Object.assign(this, {
      $window,
      $location,
      localStorage: safeLocalStorage,
      CrmActivitiesService,
      NylasNetworkService,
      MODEL: $window.DataModel,
    });

    mapActions(this, ['modals']);
  }

  async $onInit() {
    await this.CrmActivitiesService.fetchMyNylas();
    this.updateCanSave();
  }

  doGetActivityTypeIcon(activityType) {
    this.getActivityTypeIcon({activityType});
  }

  async stopSyncing() {
    if (!this.MODEL.CrmActivitiesService.nylasInfo || !this.MODEL.CrmActivitiesService.nylasInfo.id) {
      return;
    }
    this.MODEL.show.loader = true;
    try {
      await this.CrmActivitiesService.stopSyncing(this.MODEL.CrmActivitiesService.nylasInfo.id);
      analyticsService.canceled('Calendar syncing');
    } catch (e) {
      console.error(e);
    }
    this.updateCanSave();
    this.$window.refreshDom({loader: false}, 'show');
  }

  connectToNylas() {
    // Open Nylas to authenticate and get a token
    this.$window.location = this.NylasNetworkService.buildAuthenticationUrl(
      this.MODEL.__env.nylasAppId,
      `${this.$window.__env.baseAppUrl}#/activities/calendar-settings`,
    );
  }

  async startSyncing() {
    if (!this.MODEL.CrmActivitiesService.nylasInfo) {
      this.connectToNylas();
      return;
    } else if (!this.MODEL.CrmActivitiesService.nylasInfo.calendarId) {
      // Otherwise if we have token already, just let user to pick up a calendar
      await this.pickUpCalendar();
    }
    analyticsService.initiated('Calendar syncing');
    this.updateCanSave();
  }

  async pickUpCalendar() {
    this.MODEL.show.loader = true;
    try {
      await this.CrmActivitiesService.fetchNylasCalendars();
      analyticsService.clicked(['Calendar settings', 'Sync another calendar']);
      this.$window.refreshDom({showSelectCalendarPopup: true}, 'CrmActivitiesService');
    } catch (e) {
      console.error(e);
      await swal('Uh-oh', 'Your Nylas token has expired, please authenticate', 'warning');
      this.connectToNylas();
    }
    this.$window.refreshDom({loader: false}, 'show');
  }

  async handleSaveClick() {
    if (!this.MODEL.CrmActivitiesService.nylasInfo) {
      return;
    }

    const oldTypeIds = this.MODEL.CrmActivitiesService.nylasInfo.nylasCrmActivityTypes.map(({id}) => id);
    const newTypeIds = this.MODEL.CrmActivitiesService.syncedTypes;

    const nylasCrmActivityTypes = [];
    difference(oldTypeIds, newTypeIds).forEach(typeId => nylasCrmActivityTypes.push({
      crmActivityTypeId: typeId,
      _action_: 'delete',
    }));
    difference(newTypeIds, oldTypeIds).forEach(typeId => nylasCrmActivityTypes.push({
      crmActivityTypeId: typeId,
      _action_: 'create',
    }));
    try {
      await this.CrmActivitiesService.updateNylasInfo({nylasCrmActivityTypes});
      analyticsService.entityUpdated('Nylas Crm Activity Types', {nylasCrmActivityTypes});
      return swal('Syncing Preferences', 'Your calendar syncing preferences have been successfully updated.', 'success');
    } catch (e) {
      console.error(e);
      return swal('Uh-Oh', 'We could not save your syncing preferences. Please try again.', 'error');
    }
  }

  updateCanSave() {
    this.canSave = !!this.MODEL.CrmActivitiesService.calendarName && !isEmpty(this.MODEL.CrmActivitiesService.syncedTypes);

    if (this.MODEL.CrmActivitiesService.nylasInfo && this.MODEL.CrmActivitiesService.nylasInfo.twoWaySync === 1 &&
            !this.MODEL.CrmActivitiesService.nylasInfo.calendarCrmActivityType) {
      this.canSave = false;
    }
  }

  closeCalendarSelectionPopup() {
    this.MODEL.CrmActivitiesService.showSelectCalendarPopup = false;
  }

  async selectCalendar(calendar) {
    try {
      this.MODEL.show.loader = true;
      await this.CrmActivitiesService.updateNylasInfo({calendarId: calendar.id, nylasAccountId: calendar.account_id});
      this.closeCalendarSelectionPopup();
    } catch (e) {
      console.error(e);
    }
    this.$window.refreshDom({loader: false}, 'show');
  }

  handleCancelClick() {
    this.onClose();
    analyticsService.canceled('Calendar settings');
  }
}

CalendarSettingsFormController.$inject = [
  '$window', '$location', 'CrmActivitiesService', 'NylasNetworkService',
];

const calendarSettingsForm = {
  bindings: {
    crmActivityTypes: '<',
    getActivityTypeIcon: '&',
    onClose: '&',
  },
  controller: 'CalendarSettingsFormController',
  template,
};

export {CalendarSettingsFormController, calendarSettingsForm};
