const isMapped = ({geoPoint}) => geoPoint && geoPoint.coordinates;

class MappedCellRenderer {
  init(agParams) {
    this.eGui = document.createElement('div');
    this.refresh(agParams);
  }

  refresh(agParams) {
    this.agParams = agParams;
    this.eGui.innerHTML = this.renderUi();
    return true;
  }

  renderUi() {
    return isMapped(this.agParams.data) ? '<span class="far fa-check"/>' : '';
  }

  getGui() {
    return this.eGui;
  }
}

export const tooltipValueGetter = ({data}) => (isMapped(data) ? 'Mapped' : 'Not mapped');

export default MappedCellRenderer;
