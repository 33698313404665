import React, {useCallback} from 'react';
import SmallPreviewContainer from './SmallPreviewContainer';
import Group from '../../../../type/Group';
import EntityType from '../../../../type/EntityType';

interface Props {
  goToEntity: (entityType: EntityType, entityId: number) => void,
  group: Pick<Group, 'id' | 'name'>,
}

const GroupPreviewContainer: React.FC<Props> = ({goToEntity, group}) => {
  const handleClick = useCallback(() => goToEntity(EntityType.COMPANY_GROUP, group.id), [group.id]);
  return (
    <SmallPreviewContainer onClick={handleClick}>
      {group.name}
    </SmallPreviewContainer>
  );
};

export default GroupPreviewContainer;
