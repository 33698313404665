import React, {useMemo, useRef} from 'react';
import randomColor from 'randomcolor';
import styled from 'styled-components';
import AccessibleFakeButton from 'react-md/lib/Helpers/AccessibleFakeButton';
import Layover from 'react-md/lib/Helpers/Layover';
import Portal from 'react-md/lib/Helpers/Portal';
import Activity from '../../../type/Activity';
import ActivityType from '../../../type/ActivityType';
import useBoolean from '../../../hook/useBoolean';
import RecapActivityCard from './RecapActivityCard';
import EntityType from '../../../type/EntityType';

const ActivitiesList = styled.div`
  background: white;
  border: 1px solid #eee;
  z-index: 17;
  max-height: 200px;
  overflow: auto;
`;

const ActivitiesContainer = styled.div<{backgroundColor: string}>`
  min-height: 16px;
  width: 16px;
  cursor: pointer;
  position: relative;
  background-color: ${props => props.backgroundColor};
`;

// simple and stupid hash to distribute ids a little bit better to get more distributed colors
const simpleHash = (n: number) => (n * 0xdeadbeef) % 0xffffff;
const colorMap = new Map<number, string>();
const getColor = (activityType: ActivityType): string => {
  if (!colorMap.has(activityType.id)) {
    const color = randomColor({
      luminosity: 'light',
      format: 'rgb',
      seed: simpleHash(activityType.id),
    });
    colorMap.set(activityType.id, color);
  }

  return colorMap.get(activityType.id) as string;
};

const anchor = {
  x: Layover.HorizontalAnchors.LEFT,
  y: Layover.VerticalAnchors.BOTTOM,
};

interface Props {
  activities: Activity[],
  activityType: ActivityType,
  goToEntity: (entityType: EntityType, entityId: number) => void,
}

const RecapActivityBox: React.FC<Props> = ({activities, activityType, goToEntity}) => {
  const id = useRef(`${activityType.id}-${Math.floor(Math.random() * 100000)}`);
  const [listVisible, showList, hideList] = useBoolean();
  const backgroundColor = useMemo(() => getColor(activityType), [activityType]);

  return (
    <Layover
      id={`${id.current}-layover`}
      onClick={showList}
      toggle={(
        <ActivitiesContainer backgroundColor={backgroundColor} role="button" />
      )}
      onClose={hideList}
      visible={listVisible}
      anchor={anchor}
    >
      <ActivitiesList className="md-paper md-paper--1" onMouseMove={(e) => e.stopPropagation()}>
        <Portal visible={listVisible}>
          <AccessibleFakeButton
            id={`${id.current}-overlay`}
            className="md-overlay"
            onClick={hideList}
          />
        </Portal>
        {activities.map(activity => (
          <RecapActivityCard key={activity.id} activity={activity} goToEntity={goToEntity} />
        ))}
      </ActivitiesList>
    </Layover>
  );
};

export default RecapActivityBox;
