import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';

class TextSearchNetworkService {
  async findEntities(searchText, entityType) {
    const orgId = safeLocalStorage.currentUser.organization.id;
    const params = {$filters: {$q: searchText}, $limit: 50};
    if (entityType) {
      params.$filters.entityType = [entityType];
    }
    return BaseNetworkServiceWithPromises.read(`organizations/${orgId}/full-search`, params);
  }
}

export default new TextSearchNetworkService();
