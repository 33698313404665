import get from 'lodash/get';
import template from './search-bar.html';
import analyticsService from '../../shared-services/analytics-service';

class SearchBarController {
  constructor($window, $timeout) {
    Object.assign(this, {
      $window,
      $timeout,
      MODEL: $window.DataModel,
    });

    this.searchOptionsLength = 0;
  }

  $onInit() {
    this.searchLength = parseInt(this.searchLength, 10) || 1;
  }

  $onChanges(changesObj) {
    const {searchModel} = changesObj;

    if (searchModel) {
      this.searchText = get(searchModel, 'currentValue');
    }
  }

  onSearch() {
    const validationResult = this.validateSearchString(this.searchText);
    analyticsService.initiated('Global search', {text: this.searchText});
    if (validationResult !== true) {
      this.performSearch({searchText: undefined});
      return;
    }

    this.searching = true;
    this.performSearch({searchText: this.searchText})
      .then((searchResults) => {
        this.searching = false;
        this.searchOptionsLength = searchResults.length;
      })
      .catch((error) => {
        this.searching = false;
        this.searchOptionsLength = 0;
        console.error(error);
      });
  }

  validateSearchString(searchText = '') {
    if (this.searchStringValidator) {
      this.validationResult = this.searchStringValidator({searchText});
    } else {
      this.validationResult = searchText.trim().length >= this.searchLength ||
                `Please type at least ${this.searchLength} characters`;
    }
    return this.validationResult;
  }

  onSearchInputFocus() {
    this.searchFocused = true;
  }

  blurFocus() {
    this.$timeout(() => {
      this.searchFocused = false;
      this.MODEL.searchOptions = [];
    }, 200);
  }
}

SearchBarController.$inject = ['$window', '$timeout'];

const searchBar = {
  bindings: {
    className: '@?',
    navigationIconClassName: '@?',
    navigationImageSrc: '@?',
    onNavigationClick: '&?',
    performSearch: '&',
    searchStringValidator: '&?',
    searchModel: '<?',
    searchLength: '=?',
  },
  controller: 'SearchBarController',
  template,
};

export {SearchBarController, searchBar};

