import styled, {css} from 'styled-components';
import PlanName from './PlanName';
import PlanDescription from './PlanDescription';

const PlanText = styled.div<{isAvailable?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding: 20px;
  ${({isAvailable = true}) => !isAvailable && css`
    & > ${PlanName}, & > ${PlanDescription} {
        text-decoration: line-through;
    }
  `};
`;

export default PlanText;
