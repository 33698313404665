import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Table, Select} from 'antd';
import {useTranslation} from 'react-i18next';
import {ColumnsType} from 'antd/lib/table';
import styled from 'styled-components';
import MmcField from '../../../type/MmcField';
import ImportType from '../../../type/ImportType';
import FilePreview from '../../../type/FilePreview';
import ImportServiceType from '../../../type/ImportService';
import EntityType from '../../../type/EntityType';
import analyticsService from '../../../../shared-services/analytics-service';
import BlockSpace from '../../../components/Layout/BlockSpace';

const {Option} = Select;

const Container = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const Title = styled.div`
  font-size: 1.3em;
  font-weight: 700;
`;

const UIDSelect = styled(Select)`
  min-width: 250px;
`;

const FieldSelect = styled(Select)`
  width: 100%;
`;

interface Props {
  entityColumns: any[],
  filePreview: FilePreview,
  importType: ImportType,
  entityType: EntityType,
  setUid: (uid: string) => void,
  uid: string,
  setMapping: (mapping: object) => void,
  mappedColumns: object,
  ImportService: ImportServiceType,
}

interface ImportField {
  index: number,
  fileField: string,
  mmcField?: string,
  preview: string
}

const MapColumns: React.FC<Props> = ({
  entityColumns,
  filePreview,
  importType,
  setUid,
  uid,
  setMapping,
  mappedColumns,
  ImportService,
}) => {
  const {t} = useTranslation(['import']);
  const [importFields, setImportFields] = useState<ImportField[]>([]);
  const [mmcFields, setMmcFields] = useState<MmcField[]>([]);

  useEffect(() => {
    setImportFields(filePreview.headers.map((header: string, index: number) => ({
      index,
      fileField: header,
      mmcField: mappedColumns[header] || ImportService.skipKey,
      preview: filePreview.preview.length > 0 ? filePreview.preview[0][index] : '',
    })));
  }, [filePreview, mappedColumns]);

  useEffect(() => {
    setMmcFields(entityColumns.map(({key, label, required, separator}, i) => ({
      label,
      value: separator ? i : key,
      required,
      disabled: key !== ImportService.skipKey && (separator || importFields.some(importField => importField.mmcField === key)),
    })));
  }, [importFields]);

  useEffect(() => {
    const mapping: object = {};
    mmcFields.forEach(mmcField => {
      if (mmcField.required) {
        mapping[mmcField.value] = false;
      }
    });
    importFields.forEach(row => {
      if (row.mmcField) {
        mapping[row.mmcField] = row.fileField;
      }
    });
    setMapping(mapping);
  }, [importFields, mmcFields]);

  const handleSetUid = useCallback(
    (uid) => {
      analyticsService.clicked('Set UID', {uid});
      setUid(uid as string);
    },
    [setUid, uid],
  );

  const setMmcField = (index: number, mmcField: string) => {
    const tempImportFields = [...importFields];
    analyticsService.clicked('Set mapping field', {
      fileField: tempImportFields[index].fileField,
      mmcField,
    });
    tempImportFields[index] = {...tempImportFields[index], mmcField};
    setImportFields(tempImportFields);
  };

  const mapColumns: ColumnsType<any> = useMemo(
    () => [
      {
        title: t('Field NO.'),
        dataIndex: 'index',
        render: (value) => value + 1,
      },
      {
        title: t('Column From Csv'),
        dataIndex: 'fileField',
      },
      {
        title: t('Map My Customers Field'),
        render: (_: string, {mmcField, index}: ImportField) => (
          <FieldSelect value={mmcField} onChange={value => setMmcField(index, value as string)}>
            {mmcFields.map(({value, label, disabled}) => (
              <Option disabled={disabled} key={value} value={value}>{label}</Option>
            ))}
          </FieldSelect>
        ),
      },
      {
        title: t('Preview'),
        dataIndex: 'preview',
      },
    ],
    [setMmcField],
  );

  return (
    <Container>
      <BlockSpace direction="vertical" size="large">
        {importType === ImportType.UID &&
          <div>
            <Title>{t('assignSelectUID')}</Title>
            <div>{t('assignSelectUIDDescription')}</div>
            <UIDSelect defaultValue={uid} onChange={handleSetUid}>
              {filePreview.headers.map((header: string) => (
                <Option key={header} value={header}>{header}</Option>
              ))}
            </UIDSelect>
          </div>
        }
        <div>
          <Title>{t('mapColumns')}</Title>
          {Object.keys(mappedColumns).length > 0 &&
            <div>
              {t('goodNews')}
            </div>
          }
          <Table
            columns={mapColumns}
            dataSource={importFields}
            pagination={false}
            rowKey="index"
          />
        </div>
      </BlockSpace>
    </Container>
  );
};

export default MapColumns;
