import React, {useCallback} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import Button from 'react-md/lib/Buttons/Button';
import Tab from 'react-md/lib/Tabs/Tab';
import Tabs from 'react-md/lib/Tabs/Tabs';
import TabsContainer from 'react-md/lib/Tabs/TabsContainer';
import Company from '../../../type/Company';
import ActivityType from '../../../type/Activity';
import ActivityTypeType from '../../../type/ActivityType';
import EntityType from '../../../type/EntityType';
import Recap from './Recap';
import NotesTab from './tabs/NotesTab';
import ActivitiesTab from './tabs/ActivitiesTab';
import accountNetworkService from '../../../../network-services/accounts-network-service';
import helperService from '../../../../shared-services/helper-service';
import useBoolean from '../../../hook/useBoolean';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import Centered from '../../../style/Centered';
import safeLocalStorage from '../../../../shared-services/safe-local-storage-service';
import {isVersion2} from '../../../../common/versions';

const CompanyInfo = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

const CompanyTitle = styled.div`
  font-size: 1.75rem;
`;

const AdditionalCompanyInfo = styled.section`
  display: flex;
  font-size: 1rem;
  margin-top: 8px;
  & > *:not(:last-child) {
    margin-right: 16px;
  }
`;

const CompanyActions = styled.div`
`;

const BottomActionsContainer = styled(Centered)`
  margin-top: 32px;
`;

const PaddedTabsContainer = styled(TabsContainer)`
  & > .md-tabs-content {
    margin-top: 16px;
  }
`;

const formatLink = (url: string): string => (/^https?:\/\//.test(url) ? url : `//${url}`);

const getDomainName = (url: string): string | undefined => {
  const m = url.match(/^(https?:\/\/)?([^/]+)/);
  return m ? m[2] : undefined;
};

interface Props {
  activities: ActivityType[],
  activityTypes?: ActivityTypeType[],
  company: Company,
  getDirectionsToAddress: (company: Company) => void,
  goToEntity: (entityType: EntityType, entityId: number) => void,
  fetchCrmActivities: (entityType: string, entityId: number, type: 'upcoming' | 'overdue' | 'completed') => void,
}

const CompanyMainDetails: React.FC<Props> = ({
  activities,
  activityTypes,
  company,
  getDirectionsToAddress,
  goToEntity,
  fetchCrmActivities,
}) => {
  const {t} = useTranslation(['entity-common']);
  const handleRouteClick = useCallback(() => getDirectionsToAddress(company), [company, getDirectionsToAddress]);
  const [isDeleteConfirmationVisible, showDeleteConfirmation, hideDeleteConfirmation] = useBoolean();
  const handleDeleteCompany = useCallback(
    async () => {
      try {
        await accountNetworkService.deleteAccount(company.id);
        window.location.href = isVersion2()
          ? `${window.__env.baseNewAppUrl}/company?mmc_token=${safeLocalStorage.accessToken}`
          : '#/accounts/list';
      } catch (e) {
        helperService.showAndLogError(e, 'Failed to delete account, please try again later.');
      }
    },
    [],
  );

  return (
    <>
      <CompanyInfo>
        <CompanyTitle>
          <div>{company.name}</div>
          <AdditionalCompanyInfo>
            {company.website && (
              <div>
                <FontIcon iconClassName="fa fa-globe" />
                <a className="md-icon-text" href={formatLink(company.website ?? '')} target="_blank" rel="noopener noreferrer">
                  {getDomainName(company.website)}
                </a>
              </div>
            )}
          </AdditionalCompanyInfo>
        </CompanyTitle>
        <CompanyActions>
          {(company.address || company.geoPoint) && (
            <Button flat iconClassName="fa fa-location-arrow" onClick={handleRouteClick}>Route</Button>
          )}
          {company.phone && (
            <Button flat iconClassName="fa fa-phone" href={`tel:${company.phone}`}>Call</Button>
          )}
          {company.email && (
            <Button flat iconClassName="fa fa-envelope" href={`mailto:${company.email}`}>Email</Button>
          )}
        </CompanyActions>
      </CompanyInfo>
      <Recap
        activityTypes={activityTypes}
        company={company}
        goToEntity={goToEntity}
      />
      <PaddedTabsContainer colored>
        <Tabs tabId="company-tabs" centered>
          <Tab label="Notes">
            <NotesTab company={company} />
          </Tab>
          <Tab label="Activities">
            <ActivitiesTab
              activities={activities}
              company={company}
              fetchCrmActivities={fetchCrmActivities}
              goToEntity={goToEntity}
            />
          </Tab>
        </Tabs>
      </PaddedTabsContainer>
      <BottomActionsContainer>
        <Button
          flat
          iconClassName="fas fa-trash"
          onClick={showDeleteConfirmation}
        >
          Delete Company
        </Button>
      </BottomActionsContainer>
      <ConfirmationDialog
        cancelButtonLabel={t('CancelDelete')}
        confirmButtonLabel={t('DoDelete')}
        focusOnConfirm={false}
        id="company-delete-confirmation-dialog"
        onConfirm={handleDeleteCompany}
        onCancel={hideDeleteConfirmation}
        onHide={hideDeleteConfirmation}
        title={t('DeleteCompanyConfirmation', {name: company.name})}
        text={t('DeleteCompanyConfirmationText', {name: company.name})}
        visible={isDeleteConfirmationVisible}
      />
    </>
  );
};

export default CompanyMainDetails;
