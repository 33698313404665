import _get from 'lodash/get';
import analyticsService from '../shared-services/analytics-service';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import helperService from '../shared-services/helper-service';
import accountsNetworkService from '../network-services/accounts-network-service';
import contactsNetworkService from '../network-services/contacts-network-service';
import GeocodingNetworkService from '../network-services/geocoding-network-service';
import {doesUseMiles} from '../common/settings';

export default class LeadGenService {
  constructor(
    $window, $route, MappingService, SettingsService,
  ) {
    Object.assign(this, {
      $window,
      MODEL: $window.DataModel,
      MAN: $window.mmcUtils.MAN,
      $route,
      localStorage: safeLocalStorage,
      MappingService,
      SettingsService,
    });
  }

  showSavedSearch(savedSearch) {
    // if unchecked, check it
    const index = this.MODEL.MappingService.currentSearchTerms.indexOf(savedSearch);

    if (index < 0) {
      // add this term to map
      this.getSearchTerms(savedSearch);
    } else {
      // remove this term from map
      this.removeSearchTerm(savedSearch);
    }
  }

  // saveNewSearches () {
  //     // selected groups
  //     let selectedValues = $("[name=\"currentSearchTerms\"]:checked").map(function () {
  //         return this.value;
  //     }).get();

  //     this.updateSavedSearches(selectedValues);
  // }

  // updateSavedSearches (selectedValues) {
  //     // if lead gen searches array is undefined
  //     if (!safeLocalStorage.currentUser.leadGenSearches) {
  //         safeLocalStorage.currentUser.leadGenSearches = [];
  //     }

  //     selectedValues.forEach(selectedValue => {
  //         if (safeLocalStorage.currentUser.leadGenSearches.indexOf(selectedValue) < 0) {
  //             safeLocalStorage.currentUser.leadGenSearches.push(selectedValue);
  //         }
  //     });

  //     return this.SettingsService.updateUserSettings("leadGenSearches", safeLocalStorage.currentUser.leadGenSearches)
  //         .then(() => {
  //             console.log("successfully updated");
  //         })
  //         .catch(() => {
  //             return swal("Uh-Oh", "Something's not right. Please try saving again.", "error");
  //         });
  // }

  // // delete saved Search
  // deleteSavedSearch (deletedTerm) {
  //     // remove from current search terms
  //     const index = safeLocalStorage.currentUser.leadGenSearches.indexOf(deletedTerm);
  //     safeLocalStorage.currentUser.leadGenSearches.splice(index, 1);

  //     return this.SettingsService.updateUserSettings("leadGenSearches", safeLocalStorage.currentUser.leadGenSearches)
  //         .then(() => {
  //             console.log("successfully updated");
  //         })
  //         .catch(() => {
  //             return swal("Uh-Oh", "Something's not right. Please try saving again.", "error");
  //         });
  // }

  // new find new customers page
  getSearchTerms(savedSearch, searchTerms) {
    let newTerm;

    if (savedSearch) {
      newTerm = savedSearch;
    } else {
      newTerm = searchTerms.trim().toLowerCase();
    }

    // add to recent search array
    if (!safeLocalStorage.leadGenSearches) {
      safeLocalStorage.leadGenSearches = [];
    }

    if (safeLocalStorage.leadGenSearches.indexOf(newTerm) < 0 && newTerm) {
      safeLocalStorage.leadGenSearches.push(newTerm);

      if (safeLocalStorage.leadGenSearches.length > 5) {
        safeLocalStorage.leadGenSearches.shift();
      }
    }

    const colorArray = ['blue', 'maroon', 'orange', 'pink', 'yellow', 'red', 'purple', 'black', 'grey'];

    if (this.MODEL.MappingService.currentSearchTerms.indexOf(newTerm) < 0 && newTerm) {
      this.MODEL.MappingService.currentSearchTerms.push(newTerm);

      // assign color to search term
      let color = colorArray.filter(color => !this.MODEL.MappingService.currentSearchTermColor.includes(color));
      if (color.length > 0) {
        color = color[0];
      }
      this.MODEL.MappingService.currentSearchTermColor.push(color);
      this.MODEL.MappingService.searchTermColors[newTerm] = color;

      // search new term and show results on map
      return this.MappingService.localCustomerSearch(newTerm);
    }

    return Promise.resolve();
  }

  removeSearchTerm(deletedTerm) {
    // remove from current search terms
    let index = this.MODEL.MappingService.currentSearchTerms.indexOf(deletedTerm);
    this.MODEL.MappingService.currentSearchTerms.splice(index, 1);

    // remove assigned color
    const searchTermColor = this.MODEL.MappingService.searchTermColors[deletedTerm];
    index = this.MODEL.MappingService.currentSearchTermColor.indexOf(searchTermColor);
    this.MODEL.MappingService.currentSearchTermColor.splice(index, 1);

    // remove assigned term-color (key-value) pair from object
    delete this.MODEL.MappingService.searchTermColors[deletedTerm];

    // delete pins from Map
    this.MODEL.localCustomersFound = this.MODEL.localCustomersFound.filter((obj) => obj.searchTerm !== deletedTerm);

    // add all pins again
    // search for all terms again if any of the term is removed
    this.MappingService.setMapOnAll(null);
    this.MappingService.setSearchPinLocation();

    return this.MappingService.searchAllCurrentTerms();
  }

  removeRecentSearchTerm(deletedTerm) {
    const index = safeLocalStorage.leadGenSearches.indexOf(deletedTerm);
    safeLocalStorage.leadGenSearches.splice(index, 1);
  }

  changeSearchRadius() {
    const radius = parseFloat($('#leadGenRadius').val().trim());

    if (radius > 0 && radius <= 25) {
      this.MODEL.MappingService.findCustomersSearchRadius = radius;
      // set radius takes input in meters
      // to convert meters into miles + small error rate
      this.MODEL.findCustomersCircle.setRadius(radius * (doesUseMiles() ? 1609.0 : 1000.0));
      // remove all previous data since location has changed
      this.MODEL.MappingService.globalCustomersFound = [];
      this.MODEL.localCustomersFound = [];
      // delete previous pins searched for
      this.MAN.nukeMapContent();
      this.MappingService.setMapOnAll(null);
      this.MappingService.setSearchPinLocation(false, true);
      // show other pins if toggle is on
      if (this.MODEL.MappingService.showOtherPins) {
        this.MappingService.showOtherPins();
      }
      this.MappingService.searchAllCurrentTerms();
    } else {
      return swal('Oops...', `Please enter a search radius from 0 to 25${doesUseMiles() ? ' miles' : 'km'}`)
        .then(() => {
          $('#leadGenRadius').val(this.MODEL.MappingService.findCustomersSearchRadius);
        });
    }
  }

  // change current search location
  async changeSearchLocation(address) {
    // remove all previous data since location has changed
    this.MODEL.MappingService.globalCustomersFound = [];
    this.MODEL.localCustomersFound = [];

    this.MODEL.show.loader = true;
    const coords = await GeocodingNetworkService.geocodeAddress({address});

    if (coords) {
      swal({
        type: 'success',
        title: 'Got it!',
        text: `Your location is: ${address}`,
      });

      this.MODEL.MappingService.currentSearchLocation = {
        lat: coords.geoPoint.coordinates[1],
        lng: coords.geoPoint.coordinates[0],
      };
      this.MODEL.MappingService.currentSearchAddress = address;

      // delete previous pins searched for
      this.MAN.nukeMapContent();

      // show other pins if toggle is on
      if (this.MODEL.MappingService.showOtherPins) {
        this.MappingService.showOtherPins();
      }

      this.MappingService.setMapOnAll(null);
      this.MappingService.setSearchPinLocation(false, true);

      if (this.MODEL.MappingService.currentSearchTerms.length > 0) {
        this.MODEL.MappingService.currentSearchTerms.forEach((searchTerm) => {
          this.MappingService.localCustomerSearch(searchTerm, true);
        });
      }

      const infowindow = new google.maps.InfoWindow();
      google.maps.event.addListener(this.MODEL.MappingService.searchStartingLocationPin, 'click', () => {
        infowindow.setContent('<b>Searching From Here</b>');
        infowindow.open(this.MODEL.map, this.MODEL.MappingService.searchStartingLocationPin);
      });

      this.MAN.setCenter(this.MODEL.MappingService.currentSearchLocation);
      this.MAN.setZoom(10);
      this.$window.refreshDom({loader: false}, 'show');
    } else {
      this.$window.refreshDom({loader: false}, 'show');
      swal('error', "We weren't able to understand that location. Maybe try again? It can be an address with city, state, and postal code.");
    }
  }

  // add selected lead gen records
  selectLeadGenRecords() {
    this.MODEL.selectedLeadGenRecords = [];
    const tableRecords = this.MODEL.localCustomersFound;

    const selectedValues = $('[name="leadGenMultiSelectInput"]:checked').map(function () {
      return this.value;
    }).get();

    tableRecords.forEach(record => {
      if (selectedValues.indexOf(record.id) >= 0) {
        this.MODEL.selectedLeadGenRecords.push(record);
      }
    });
  }

  async saveLeadGenMultipleRecords() {
    if (!this.MODEL.selectedLeadGenRecords.length) {
      return;
    }

    this.MODEL.show.loader = true;

    const newEntities = await Promise.all(this.MODEL.selectedLeadGenRecords.map(entity =>
      this.MappingService.getPlaceDetails(entity.place_id).then(details => this.buildObject.bind(this)({...entity, ...details})),
    ));
    if (this.$route.current.id.includes('account')) {
      try {
        const response = await accountsNetworkService.createAccounts(newEntities, true);
        if (Array.isArray(response)) {
          this.MODEL.accounts = this.MODEL.accounts.concat(response);
          swal('Success!', 'The companies were saved successfully!', 'success');
          analyticsService.completed('Lead Gen bulk companies create', response);
        }
      } catch (e) {
        helperService.showAndLogError(e, 'Failed to create records, please try again later');
      }
    } else {
      try {
        const response = await contactsNetworkService.createContacts(newEntities);

        if (Array.isArray(response)) {
          this.MODEL.contacts = this.MODEL.contacts.concat(response);
          swal('Success!', 'The people were saved successfully!', 'success');
          analyticsService.completed('Lead Gen bulk people create', response);
        }
      } catch (e) {
        helperService.showAndLogError(e, 'Failed to create records, please try again later');
      }
    }

    this.MAN.nukeMapContent();
    this.MappingService.saveNewFoundCustomer(true);
    this.MODEL.selectedLeadGenRecords = [];
    this.$window.refreshDom({selectedLeadGenRecords: this.MODEL.selectedLeadGenRecords});
    this.$window.refreshDom({loader: false}, 'show');
  }

  // build object to save to the server
  async buildObject(record) {
    const obj = {
      name: record.name,
      color: 'black',
      user: {id: safeLocalStorage.currentUser.id},
    };

    Object.assign(obj, await this.MappingService.getAddressFromEntity(record));

    const lat = _get(record, 'geometry.location.lat');
    const lng = _get(record, 'geometry.location.lng');
    if ((typeof lat === 'function') && (typeof lng === 'function')) {
      obj.geoManagementState = 'manual';
      obj.geoPoint = {
        type: 'Point',
        coordinates: [lng(), lat()],
      };
    }
    return obj;
  }
}

LeadGenService.$inject = [
  '$window', '$route', 'MappingService', 'SettingsService',
];
