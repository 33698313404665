import DataModel from './common/data-model';

export default function envConfig(win: Window) {
  try {
    win.DataModel = DataModel;
    win.DataModel.__env = {...win.__env};

    if (!win.DataModel.__env.BaseURL.endsWith('/')) {
      win.DataModel.__env.BaseURL += '/';
    }
  } catch (e) {
    // DataModel might be not defined at this point, ignore that
  }
}
