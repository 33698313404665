import BaseNetworkService from './base-network-service/base-network-service';
import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import Company from '../react/type/Company';
import Person from '../react/type/Person';
import Deal from '../react/type/Deal';
import Funnel from '../react/type/Funnel';
import Stage from '../react/type/Stage';
import ListResponse from '../react/type/service/ListResponse';
import DataModel from '../common/data-model';

type Params = Partial<{
  $order: string,
  $limit: number,
  $offset: number,
  $filters: string,
}>

export type StageSummary = {
  stage: Stage,
  totalDeals: number,
  totalValue: number,
}

class DealsNetworkService {
  fetchAllDeals(map: boolean, filters: object, page: number = 1, column: string = 'updatedAt', ascending: boolean = false) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    let endPoint = `organizations/${orgId}/deals`;
    const params: Params = {
      $order: ascending ? column : `-${column}`,
    };

    if (map) {
      filters = {...filters, mapped: true};
      params.$limit = DataModel.recordsUpperLimit;
    } else {
      if (page) {
        params.$offset = (page - 1) * DataModel.pageSize;
      }
      params.$limit = DataModel.pageSize;
    }
    if (Object.keys(filters).length) {
      endPoint += `?$filters=${encodeURIComponent(JSON.stringify(filters))}`;
    }
    return BaseNetworkService.read(endPoint, params);
  }

  // fetch all the deals for an account
  async fetchAccountDeals(id: Company['id']) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const filters = {
      accountId: id,
    };
    const endPoint = `organizations/${orgId}/deals?$filters=${encodeURIComponent(JSON.stringify(filters))}`;
    return BaseNetworkService.read(endPoint);
  }

  // fetch all the deals for a contact
  async fetchContactDeals(id: Person['id']) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const filters = {
      contactId: id,
    };
    const endPoint = `organizations/${orgId}/deals?$filters=${encodeURIComponent(JSON.stringify(filters))}`;
    return BaseNetworkService.read(endPoint);
  }

  // get deals count
  async getDealsCount(): Promise<number> {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/deals?$limit=0`;
    return (await BaseNetworkService.read(endPoint)).total;
  }

  async deleteDeal(id: Deal['id']) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/deals/${id}`;
    return BaseNetworkService.delete(endPoint);
  }

  // fetches all deal groups
  fetchAllDealGroups(
    filters: {} | null = null,
    page: number = 1,
    column: string = 'displayOrder',
    ascending: boolean = false,
    limit: number = 0,
  ) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const {pageSize} = window.DataModel;
    const params: Params = {
      $order: ascending ? column : `-${column}`,
      $filters: filters ? JSON.stringify(filters) : undefined,
      $limit: limit === 0 ? pageSize : limit,
      $offset: page ? Math.max(0, page - 1) * pageSize : undefined,
    };

    return BaseNetworkService.read(`organizations/${orgId}/deal-groups`, params);
  }

  async fetchDealGroupsCount() {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/deal-groups?$limit=0`;
    return (await BaseNetworkServiceWithPromises.read(endPoint)).total;
  }

  /**
     * Bulk update deal groups. Will only update fields that are given in the groupsData array. Originally
     * created to bulk update group's displayOrder field.
     *
     * @param {{id: number}[]} groupsData groups data to update, must include group id and any other fields that
     * need to be updated
     * @returns {Promise<*>}
     */
  bulkUpdateDealGroups(groupsData: Array<any>) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/deal-groups/bulk`;
    const payload = {dealGroups: groupsData.map(data => ({...data, _action_: 'update'}))};
    return BaseNetworkServiceWithPromises.create(endPoint, payload);
  }

  // fetch all funnel data for the user
  async fetchAllFunnels(filters?: object) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    let endPoint = `organizations/${orgId}/funnels`;

    if (filters && Object.keys(filters).length) {
      endPoint += `?$filters=${encodeURIComponent(JSON.stringify(filters))}`;
    }

    return BaseNetworkService.read(endPoint);
  }

  // fetch all the stages for the funnel
  async fetchStagesForFunnel(funnelId: Funnel['id']): Promise<ListResponse<StageSummary>> {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/funnels/${funnelId}/stages?$order=displayOrder`;
    return BaseNetworkService.read(endPoint);
  }

  // get data to build the funnel on the UI
  fetchFunnelSummary(funnelId: Funnel['id'], filters: object = {}) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const params: Params = {};
    if (Object.keys(filters).length) {
      params.$filters = JSON.stringify(filters);
    }
    return BaseNetworkService.read(`organizations/${orgId}/reports/funnels/${funnelId}/summary`, params);
  }

  // create a new funnel
  async createFunnel(payload: any) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/funnels`;
    return BaseNetworkService.create(endPoint, payload);
  }

  // update an existing funnel
  async updateFunnel(funnelId: Funnel['id'], payload: any) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/funnels/${funnelId}`;
    return BaseNetworkService.update(endPoint, payload);
  }

  // create a new deal stage
  async createDealStage(funnelId: Funnel['id'], payload: any) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/funnels/${funnelId}/stages`;
    return BaseNetworkService.create(endPoint, payload);
  }

  // update a new deal stage
  updateDealStage(funnelId: Funnel['id'], stageId: Stage['id'], payload: any) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/funnels/${funnelId}/stages/${stageId}`;
    return BaseNetworkService.update(endPoint, payload);
  }

  // delete a deal stage
  async deleteDealStage(funnelId: Funnel['id'], stageId: Stage['id']) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/funnels/${funnelId}/stages/${stageId}`;
    return BaseNetworkService.delete(endPoint);
  }

  // delete a funnel
  async deleteFunnel(funnelId: Funnel['id']) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/funnels/${funnelId}`;
    return BaseNetworkService.delete(endPoint);
  }

  // create a new deal
  async createDeal(payload: any) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/deals`;
    return BaseNetworkService.create(endPoint, payload);
  }

  // update an exisiting deal
  async updateDeal(id: Deal['id'], payload: any) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/deals/${id}`;
    return BaseNetworkService.update(endPoint, payload);
  }

  /**
     * Bulk update deals
     * @param {{id: number, color: string}[]} deals deals to update
     * @returns {*}
     */
  updateDeals(deals: Array<Pick<Deal, 'id' | 'color'>>) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/deals/bulk-update`;
    return BaseNetworkServiceWithPromises.update(endPoint, {dealIds: deals});
  }

  /**
     * Bulk-deletes given deals by their ids.
     * @param {number[]} ids
     * @returns {*}
     */
  deleteDeals(ids: Array<Deal['id']>) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    return BaseNetworkServiceWithPromises.delete(
      `organizations/${orgId}/deals/bulk-delete`,
      {dealIds: ids.map(id => ({id}))},
    );
  }
}

export default new DealsNetworkService();
