import template from './add-new-member-modal.html';
import mapActions from '../../store/store-helpers';

class AddNewMemberModalController {
  constructor($window, TeamService) {
    Object.assign(this, {
      $window,
      MODEL: $window.DataModel,
      TeamService,
    });

    mapActions(this, ['modals']);
  }

  closeModal() {
    this.modalsActions.hideModal('addNewMemberModal');
  }

  async finishAddingNewTeamMember() {
    this.MODEL.show.loader = true;
    await this.TeamService.finishAddingNewTeamMember(this.memberType === 'teamManager', this.memberType === 'teamOwner');

    this.MODEL.show.loader = false;
    this.$window.refreshDom({teamStructure: this.MODEL.TeamsService.teamStructure}, 'TeamsService');
  }

  selectedSubTeam(selectedOpt) {
    this.MODEL.TeamsService.createNewSubTeam = selectedOpt === 'Add New Team';
    this.MODEL.TeamsService.createWithNoTeam = !this.MODEL.TeamsService.createNewSubTeam &&
      !this.MODEL.TeamsService.subTeams.find(team => team.name === this.MODEL.TeamsService.selectedSubTeam);
  }
}

AddNewMemberModalController.$inject = ['$window', 'TeamService'];

const addNewMemberModal = {
  bindings: {},
  controller: 'AddNewMemberModalController',
  template,
};

export {AddNewMemberModalController, addNewMemberModal};
