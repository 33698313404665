import safeLocalStorage from '../../shared-services/safe-local-storage-service';
import analyticsService from '../../shared-services/analytics-service';

import template from './toolbar.html';
import './toolbar.scss';

class ToolbarController {
  constructor($window, $route, MappingService, FetchCRMDataService) {
    Object.assign(this, {
      $window,
      MODEL: $window.DataModel,
      MappingService,
      FetchCRMDataService,
      currentRoute: $route.current.id,
    });

    this.currentUser = safeLocalStorage.currentUser;
    this.trafficEnabled = this.currentUser.showTraffic;
    if (this.trafficEnabled) {
      this.MappingService.showTraffic();
    }
  }

  applyHeatMap() {
    analyticsService.clicked(['Map', 'Heat map']);
    this.MappingService.applyHeatMap();
    this.resetToolbarOptions({selectedOption: 'heatmap'});
  }

  lassoPins(type) {
    analyticsService.clicked(['Map', 'Lasso pins']);
    this.MODEL.LassoService.lassoApplied = true;
    this.MappingService.lassoPins(type);

    if (type === 'map') {
      this.resetToolbarOptions({selectedOption: 'lasso'});
    }
  }

  printMap() {
    analyticsService.clicked(['Map', 'print map']);
    this.MappingService.printMap();
  }

  showTraffic(layer) {
    analyticsService.clicked(['Map', 'show traffic']);
    this.trafficEnabled = !this.trafficEnabled;
    this.MappingService.showTraffic(layer);
  }
}

ToolbarController.$inject = ['$window', '$route', 'MappingService', 'FetchCRMDataService'];

const toolbar = {
  bindings: {
    resetToolbarOptions: '&',
  },
  controller: 'ToolbarController',
  template,
};

export {ToolbarController, toolbar};
