import indexOf from 'lodash/indexOf';
import find from 'lodash/find';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import forIn from 'lodash/forIn';
import difference from 'lodash/difference';
import analyticsService from './analytics-service';
import helperService from './helper-service';
import safeLocalStorage from './safe-local-storage-service';
import BaseNetworkService from '../network-services/base-network-service/base-network-service';
import accountsNetworkService from '../network-services/accounts-network-service';
import contactsNetworkService from '../network-services/contacts-network-service';
import dealsNetworkService from '../network-services/deals-network-service';
import GeocodingNetworkService from '../network-services/geocoding-network-service';
import mapActions from '../store/store-helpers';
import {getEntityDisplayName} from '../common/field-model/formatter';
import addEditNetworkService from '../network-services/add-edit-network-service';
import EntityType from '../react/type/EntityType';
import {isVersion2, isVersion2Beta} from '../common/versions';

export default function MainService(
  $window, $location, $route, $timeout, DataService, MappingService,
  RoutingService, FunnelService, AllContactsService, LassoService, TerritoriesService,
  AddEditService, TerrNetworkService, SettingsService,
  AccountsService, ImportNetworkService, ReportsService,
  CrmActivitiesService, GroupsService,
) {
  // main utility functions
  const UTILS = window.mmcUtils;
  const {MAN} = window.mmcUtils;
  const MODEL = window.DataModel;
  const CAL = window.calendarUtils;

  // bind angular vars to global vars
  MAN.$route = $route;
  UTILS.$route = $route;
  let sortedABC = false;

  // service to return
  const service = {};

  mapActions(service, ['RoutingService', 'modals']);

  //
  // ------------------------ MAIN ROUTINE START ------------------------ //
  //

  // sets everything into motion to create all data structures
  service.startChainReaction = async function () {
    helperService.debug('begin startChainReaction');
    // prevents login flash
    $(document.body).show();

    MODEL.haveMap = $route.current.hasMap === undefined ? true : $route.current.hasMap;
    DataService.initCustomerSuccessTools();

    helperService.debug('userPosition', MAN.userPosition);
    if (!MAN.userPosition) {
      await service.getLocation();
    }

    const mapSettings = {
      gestureHandling: 'cooperative',
    };

    UTILS.firstLoad = false;

    if (MODEL.haveMap) {
      // init map (done here bc views needed to be loaded first)
      if (!MODEL.map) MODEL.map = MAN.getMap(mapSettings);

      MAN.nukeMapContent();

      // all of this nonsense is to handle the craziness of the map loading on pages where we have
      // a map div, but not map visible, as the first page load.
      // these flags are because otherwise it will save.
      MAN.saveMapPositionChangesFlag = false;
      MAN.recycleMap();
      MAN.saveMapPositionChangesFlag = false;
      $('#map').show();
      MAN.saveMapPositionChangesFlag = false;
      MAN.triggerResize();
      MAN.goToSavedZoomAndCenter();

      // GUI prep/setup & create pin objects after initi'g local user data
      MappingService.createCustomMarkerPrototype();

      helperService.debug('Completed Map Setup');
    } else {
      $('#map').hide(); // necessary for settings page... otherwise, starting app on settings page causes gray map elsewhere in app.
    }

    service.setCurrentPage();
    MODEL.show.loader = false;
    helperService.debug('Completed startChainReaction');
    return Promise.resolve();
  };


  /**
   * @param {string} entityType entity type
   * @param {boolean} [plural] convert to singular or plural
   * @param {boolean} [lowercase] convert to be all lowercase or capitalized, lowercase by default
   * @returns {string}
   */
  service.getEntityDisplayName = getEntityDisplayName;

  service.handleGetCurrentPositionError = (resolve) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        window.showPosition(position);
        resolve();
      },
      (error) => {
        helperService.debug('error', error);
        window.showError(error);
        resolve();
      },
      {
        maximumAge: Infinity,
        timeout: 1000,
        enableHighAccuracy: false,
      },
    );
  };

  // get current location and query all teams data
  service.getLocation = () => {
    // get location
    helperService.debug('navigator', navigator.geolocation);
    if (navigator.geolocation && UTILS.env === 'production') {
      return new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(
        (position) => {
          window.showPosition(position);
          resolve();
        },
        (error) => {
          // Error code 3 is a timeout from navigator.geolocation
          if (error.code === 3) {
            service.handleGetCurrentPositionError(resolve, reject);
            return;
          }
          helperService.debug('error', error);
          window.showError(error);
          resolve();
        },
        {
          timeout: 5000,
          enableHighAccuracy: true,
          maximumAge: 300000, // Infinity
        },
      ));
    } if (UTILS.env === 'production') {
      const error = 'Geolocation is not supported by this browser.';
      console.error(error);
      return Promise.resolve();
    } if (navigator.geolocation) {
      return new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(
        (position) => {
          window.showPosition(position);
          resolve();
        },
        (error) => {
          // Error code 3 is a timeout from navigator.geolocation
          if (error.code === 3) {
            service.handleGetCurrentPositionError(resolve, reject);
            return;
          }

          window.showError(error);
          resolve();
        },
        {
          timeout: 5000,
          enableHighAccuracy: false,
          maximumAge: 300000, // Infinity
        },
      ));
    }
    // do nothing, navigator doesn't work unless we're on HTTPS, which is only production.
    // or, Let's fake it, and pass in Cary, NC.
    console.warn("No location, but not in production, so I'll fake it.");
    const fakeNavigator = () => {
      window.showPosition({
        coords: {
          // somewhere random in middle america.
          latitude: UTILS.getRandomInRange(34, 42),
          longitude: UTILS.getRandomInRange(-108, -91),
        },
      });
    };
    setTimeout(fakeNavigator, 1500);
    return Promise.resolve();
  };

  //
  // ------------------------ UTILITY CALLS  ------------------------ //
  // -------- (shared amongst several controllers/services) --------- //
  //

  // wrapper for controller's services to call through to data-services
  service.createCustomMarker = function (latlng, map, pinAttributes) {
    return new MappingService.CustomMarker(
      latlng,
      map,
      pinAttributes,
    );
  };

  // logout the user completely, clear cache, and wipe local storage
  window.logout = function logout() {
    safeLocalStorage.clear();
    window.location.href = '#/login';
  };

  // onboarding modal
  service.beginOnboardImport = function () {
    $('#modalbg').show();
    $('#modal').show();
    $('#uploadStyleBox').show();
    $('#modal').removeClass('modal-map').removeClass('modal-account').addClass('modal-import');
  };
  window.beginOnboardImport = service.beginOnboardImport;

  // stats bar counter
  service.setHiddenHover = function () {
    let titleString = '';
    if (MODEL.searchString) {
      titleString += `search results: ${MODEL.searchResults.length || 0}`; // return MODEL.searchResults ? MODEL.searchResults.length : 0;
    } else {
      Object.keys(MODEL.showHidePinTracking.yes).forEach(key => {
        titleString += `${key}: ${MODEL.showHidePinTracking.yes[key]}\n    -----    \n`;
      });
      Object.keys(MODEL.showHidePinTracking.no).forEach(key => {
        titleString += `${key}: ${MODEL.showHidePinTracking.no[key]}\n`;
      });
    }
    $('#shownHiddenDisplayCounter').attr('title', titleString);
  };
  window.setHiddenHover = service.setHiddenHover;

  // calculate pagination blocks needed
  service.getPagination = function () {
    let paginationLength; let
      numberOfResults;

    if (UTILS.isOnPage('dashPage')) {
      paginationLength = MODEL.activityPageArray.length / 100;
      numberOfResults = 100;
    } else if (UTILS.isOnPage('accountsPage')) {
      paginationLength = MODEL.accounts.length / 100;
      numberOfResults = 100;
    } else if (MODEL.haveMap && UTILS.isNotOnPage('contactsPage')) {
      paginationLength = MODEL.contacts.length / 5;
      numberOfResults = 5;
    } else {
      paginationLength = MODEL.contacts.length / 100;
      numberOfResults = 100;
    }
    MODEL.paginationBlocks = [];
    for (let i = 0; i < paginationLength; i++) {
      MODEL.paginationBlocks.push(i * numberOfResults);
    }

    return numberOfResults;
  };
  window.getPagination = service.getPagination;

  // return params from params on URL
  service.getParameterByName = function (name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`, 'i');
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };
  window.getParameterByName = service.getParameterByName;

  // return search query
  service.searchQuery = function () {
    // search is being done
    if (window.location.href.indexOf('?q=') >= 0) {
      // grab customers to start
      if (MODEL.customersDone) {
        MODEL.searchString = service.getParameterByName('q');

        if (MODEL.searchString !== '') {
          MappingService.searchMap();
        }

        return MODEL.searchString;
      }
    }
    return '';
  };

  // set the navbar active elements
  service.setCurrentPage = () => {
    MODEL.currentPath = $location.path();

    ['accounts', 'contacts', 'deals', 'dashboard', 'reports', 'activities', 'settings'].forEach((page) => {
      if (window.isOnPage(page)) {
        MODEL.currentPage = page;
      }
    });

    // subpage (for subheader)
    ['list', 'map', 'calendar'].forEach((subPage) => {
      if (window.isOnPage(subPage)) {
        MODEL.currentSubPage = subPage;
      }
    });
  };

  // for tracking if pins are shown or hidden (due to filters)
  service.trackShowOrHide = function (shown, reason, count = 1) {
    const yesOrNo = shown ? 'yes' : 'no';
    const alreadySet = MODEL.showHidePinTracking[yesOrNo][reason] !== undefined;
    MODEL.showHidePinTracking[yesOrNo][reason] = alreadySet
      ? MODEL.showHidePinTracking[yesOrNo][reason] + count
      : count;
  };
  window.trackShowOrHide = service.trackShowOrHide;

  // pagination for various pages
  service.currentCustomerSegment = function (skipNumber) {
    MODEL.currentSkipNumber = skipNumber;

    if (UTILS.isOnPage('dealsPage') || UTILS.isOnPage('dealsMapPage')) {
      MODEL.numberOfResults = 50;
      MODEL.customersListSegment = MODEL.allLeads.slice(skipNumber, skipNumber + MODEL.numberOfResults);
    } else if (MODEL.haveMap && UTILS.isNotOnPage('contactsPage')) {
      MODEL.numberOfResults = 5;
      MODEL.customersListSegment = MODEL.searchString && MODEL.contacts
        ? MODEL.contacts.slice(skipNumber, skipNumber + MODEL.numberOfResults)
        : [];
    } else {
      // if on contacts page
      if (UTILS.isOnPage('contactsPage')) {
        // manipulation for the select all checkbox on the contacts page
        // if page number present in the array -> check it
        if (MODEL.AllContactsService.deleteAllCheckboxPages.indexOf((skipNumber / 100) + 1) >= 0) {
          MODEL.AllContactsService.deleteAllCheckbox = true;
          MODEL.AllContactsService.allBoxesChecked = true;
        } else { // not present -> uncheck it
          MODEL.AllContactsService.deleteAllCheckbox = false;
          MODEL.AllContactsService.allBoxesChecked = false;
        }
      }

      MODEL.numberOfResults = 100;
    }

    $('#startingPinNumber').text(skipNumber);
    $('#endingPinNumber').text(skipNumber + MODEL.numberOfResults);
  };
  window.currentCustomerSegment = service.currentCustomerSegment;

  // DOM manipulation stuff
  service.viewManipulationOnLoad = function () {
    CAL.showHideCalendarButtons(); // must hide import customers box on page load
    service.setMapTools(); // map tools DOM config
    service.toggleImportProgressBar(); // import progress bar config

    if (MODEL.preImportCSVRefresh) {
      $('#importCustomers').hide();
    }

    /* ------------------ Mapping Pages ------------------ */

    // groups page -- hide features based on what page we're on
    if ($route.current.id === 'groupsPage') {
      $('#groups').show();
    } else {
      $('#groups').hide();
    }

    // nearby page
    if ($route.current.id === 'nearbyPage') {
      $('#nearby').show();
    } else {
      $('#nearby').hide();
    }

    // territories page
    if ($route.current.id === 'territoriesPage' || $route.current.id === 'territoriesMapPage') {
      $('#territories').show();
    } else {
      $('#territories').hide();
    }

    // routes page
    if ($route.current.id === 'routingPage') {
      $('#listRoutes').show();
    } else {
      $('#listRoutes').hide();
    }

    if (UTILS.isOnPage('onboardingPage')) {
      $('#map').hide();
    }

    // for drag-and-drop re-ordering route directions/groups tables
    if ($route.current.id.includes('groupsList')) {
      const fixHelperModified = function (e, tr) {
        const $originals = tr.children();
        const $helper = tr.clone();
        $helper.children().each(function (index) {
          $(this).width($originals.eq(index).width());
        });
        return $helper;
      };

      let startIndex;

      // Make desired tables sortable
      $('#groups-table tbody').sortable({
        helper: fixHelperModified,
        start(event, ui) {
          startIndex = ui.item.index();
        },
        stop(event, ui) {
          service.reorderTable('groups', startIndex, ui.item.index());
        },
      }).disableSelection();
    }

    /* ------------------ Accounts/Contacts/Deals Pages ------------------ */

    // viewing ind. contact --> just show edit customer form
    if ($route.current.id === 'contactsPage' && $route.current.params.contactId) {
      $('#customersTable').hide();
      $('#editPinForm').show();
    } else if ($route.current.id === 'accountsPage' && $route.current.params.accountId) {
      $('#accountsTable').hide();
      $('#editPinForm').show();
    } else if ($route.current.id === 'dealsPage' && $route.current.params.dealId) {
      $('#dealsTable').hide();
      $('#editPinForm').show();
    }
  };

  service.setMapTools = function () {
    // show/hide green dot for filters
    if (MODEL.filteredGroups.length > 0 || MODEL.filterString === 'pinsThatMatch') {
      // outside menu
      $('#toolsMenuGreenDot').show();

      // inside menu
      switch (MODEL.filterString) {
        case 'pinsInAny':
          $('.greenDotSpacing').hide();
          $('#greenDotAny').show();
          break;
        case 'pinsInAll':
          $('.greenDotSpacing').hide();
          $('#greenDotAll').show();
          break;
        case 'pinsThatMatch':
          $('.greenDotSpacing').hide();
          $('#greenDotMatching').show();
          break;
        default:
          $('.greenDotSpacing').hide();
          break;
      }
    } else {
      $('#toolsMenuGreenDot').hide();
    }

    // maps page
    if ($route.current.id === 'mapPage') {
      // which tools menu items to show
      $('#circlesListItem').hide();
      $('#heatMapListItem').show();
      $('#lassoListItem').show();
      $('#lassoRouteListItem').hide();
      $('#filtersListItem').show();
      $('#printingListItem').show();
      $('#trafficListItem').show();
      $('#searchListItem').show();
      $('#hideShowRoutesListItem').hide();
    }

    // groups page -- hide features based on what page we're on
    if ($route.current.id === 'groupsPage') {
      // which tools menu items to show
      $('#circlesListItem').hide();
      $('#heatMapListItem').show();
      $('#lassoListItem').show();
      $('#lassoRouteListItem').hide();
      $('#filtersListItem').show();
      $('#printingListItem').show();
      $('#trafficListItem').show();
      $('#searchListItem').show();
      $('#hideShowRoutesListItem').hide();
    }
    // nearby page
    if ($route.current.id === 'nearbyPage') {
      // which tools menu items to show
      $('#circlesListItem').hide();
      $('#heatMapListItem').show();
      $('#lassoListItem').show();
      $('#lassoRouteListItem').hide();
      $('#filtersListItem').show();
      $('#printingListItem').show();
      $('#trafficListItem').show();
      $('#searchListItem').show();
      $('#hideShowRoutesListItem').hide();
    }

    // territories page
    if ($route.current.id === 'territoriesPage' || $route.current.id === 'territoriesMapPage') {
      // which tools menu items to show
      $('#circlesListItem').hide();
      $('#heatMapListItem').hide();
      $('#lassoListItem').hide();
      $('#lassoRouteListItem').hide();
      $('#filtersListItem').hide();
      $('#printingListItem').show();
      $('#trafficListItem').hide();
      $('#searchListItem').show();
      $('#hideShowRoutesListItem').hide();
    }

    // routes page
    if ($route.current.id === 'routingPage') {
      // which tools menu items to show
      $('#circlesListItem').hide();
      $('#heatMapListItem').hide();
      $('#lassoListItem').hide();
      $('#lassoRouteListItem').hide();
      $('#filtersListItem').show();
      $('#printingListItem').show();
      $('#trafficListItem').show();
      $('#searchListItem').hide();
      $('#hideShowRoutesListItem').show();
    }
  };

  // re-order routes or groups
  service.reorderTable = function (table, startIndex, endIndex) {
    // remove element from array, then add it back at new index
    const element = safeLocalStorage.currentUser.groups[startIndex];
    safeLocalStorage.currentUser.groups.splice(startIndex, 1);
    safeLocalStorage.currentUser.groups.splice(endIndex, 0, element);
    throw new Error('Implement me!');
  };

  // delete image selected
  service.deleteCurrentPhoto = function () {
    MODEL.show.loader = true;

    // delete from database
    throw new Error('Implement me!');
  };

  // selected a customer (from map)
  service.selectedCustomer = function (pin) {
    service.selectedRowCustomerTable(pin);
  };

  // selected a customer from a table (map, contacts, leads pages, etc.)
  service.selectedRowCustomerTable = function (record, updating, crmtype) {
    $('#edit-subheader2').show();
    MODEL.currentCrmObjectId = record.id;
    MODEL.currentCrmObjectType = crmtype || service.getCurrentCrmObjectType();
    const {currentCrmObjectId: id, currentCrmObjectType: type} = MODEL;
    window.location.href = `#/${type}/edit/${id}`;
  };
  window.selectedRowCustomerTable = service.selectedRowCustomerTable;

  // sorts notes passed in
  service.sortNotes = notes => notes.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  // closes a customer (set to previous zoom and previous coordinates)
  service.closeAccount = () => {
    // reset state vars for selected pin
    MODEL.newCustomerLatitude = 0;
    MODEL.newCustomerLongitude = 0;
    const tempSelectedPinId = MODEL.selectedPinId;
    MODEL.selectedPinId = '';
    const tempSelectedPinObject = MODEL.selectedPinObject;
    MODEL.selectedPinObject = {};
    MODEL.rating.current = 0;
    MODEL.MappingService.currentCustomersNotes = [];
    MODEL.currentCrmObjectChildAccountsData = [];
    MODEL.currentCrmObjectContactsData = [];
    MODEL.GroupsService.selectedGroups = [];
    MODEL.currentCrmObjectDealsData = [];
    MODEL.currentAssociatedAccountId = undefined;
    MODEL.currentAssociatedContactId = '';
    MODEL.fromEditPage = true;
    MODEL.MappingService.selectedGroups = [];
    const completeClosing = () => {
      service.postClosingAccount(tempSelectedPinId, tempSelectedPinObject);
      AddEditService.initializeData();
      MODEL.dirtyEditFlag = false;
      MODEL.childAccountsModified = false;
      MODEL.accountContactsModified = false;
      MODEL.accountDealsModified = false;
      analyticsService.canceled('Edit entity');
    };

    if (MODEL.dirtyEditFlag || MODEL.childAccountsModified || MODEL.accountContactsModified ||
      MODEL.accountDealsModified || MODEL.contactDealsModified
    ) {
      swal({
        title: 'Uh-Oh...',
        text: 'You have unsaved changes, are you sure you want to leave this page?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Close it!',
      }).then((result) => {
        if (result) {
          completeClosing();
        }
      }).catch(swal.noop);
    } else {
      completeClosing();
    }
  };

  service.initFNC = () => {
    // for find new customers page
    if (MODEL.MappingService.findNewCustomers) {
      $('#map').show();
      $('#subheader').hide();
      $('#findNewCustomers').show();
      $('#mapViewByUser').hide();
      $('#leadGenMultipleSelectButton').show();
    }
  };

  // after closing account
  service.postClosingAccount = () => {
    service.RoutingServiceActions.updateFromEditForm(false);
    // is on a different controller
    if (window.isOnPage('edit') || window.isOnPage('add')) {
      $timeout(() => {
        $('#edit-subheader2').hide();
        $window.history.back();
      });
    } else {
      // hide proper DOM elements
      $('#addPinForm').hide();
      $('#addAccountsForm').hide();
      $('#addDealsForm').hide();
      $('#searchResults').hide();
      $('#localCustomerSearch').hide();
      $('#importCustomers').hide();
      $('#pinDroppedEditing').hide();
      $('#edit-subheader').hide();
      $('#subheader').show();
      $('.teamHeader').show();
      $('#edit-subheader').hide();
      $('#map-toolbar').show();
      $('#mapViewByUser').show();
      $('#teamHeader').show();
      $('#allterr').show();

      // show correct page based on route
      if ($route.current.id === 'accountsMapPage') {
        $('#map').show();
        service.initFNC();
      } else if ($route.current.id === 'contactsMapPage') {
        $('#map').show();
        service.initFNC();
      } else if ($route.current.id === 'contactsGroupsMapPage') {
        $('#map').show();
        service.initFNC();
      } else if ($route.current.id === 'dealsMapPage') {
        $('#dealsMapView').show();
        service.initFNC();
      } else if ($route.current.id === 'mapPage') {
        $('#addPinForm').hide();
      } else if (['accountsRoutingMapPage', 'contactsRoutingMapPage'].includes($route.current.id)) {
        $('.teamHeader').hide();
        service.initFNC();
      }

      // came from local search --> don't close local search page yet
      if (MODEL.cameFromLocalSearch) {
        $('#introPage').hide();
        $('#localCustomerSearch').show();
        MODEL.cameFromLocalSearch = false;
      }
    }
  };

  // close account after save from add page
  service.closeAccountAfterSave = (tableName, isGeocoded) => {
    // is in unique url
    if (window.isOnPage('add')) {
      $('#edit-subheader2').hide();

      if (isGeocoded) {
        window.location.href = `#/${tableName}/map`;
      } else {
        let redirectUrl = `#/${tableName}/list`;
        if (isVersion2() && tableName === 'accounts') {
          redirectUrl = `${window.__env.baseNewAppUrl}/company?mmc_token=${safeLocalStorage.accessToken}`;
        }
        if (isVersion2() && tableName === 'contacts') {
          redirectUrl = `${window.__env.baseNewAppUrl}/people?mmc_token=${safeLocalStorage.accessToken}`;
        }
        if (isVersion2Beta() && tableName === 'deals') {
          redirectUrl = `${window.__env.baseNewAppUrl}/deals?mmc_token=${safeLocalStorage.accessToken}`;
        }
        window.location.href = redirectUrl;
      }
    } else {
      service.closeAccount();
    }
  };

  service.closeAddAccount = () => {
    // reset state vars for selected pin
    MODEL.newCustomerLatitude = 0;
    MODEL.newCustomerLongitude = 0;
    MODEL.selectedPinId = '';
    MODEL.selectedPinObject = {};
    MODEL.rating.current = 0;
    MODEL.MappingService.currentCustomersNotes = [];
    MODEL.currentCrmObjectChildAccountsData = [];
    MODEL.currentCrmObjectContactsData = [];
    MODEL.GroupsService.selectedGroups = [];
    MODEL.currentCrmObjectDealsData = [];
    MODEL.currentAssociatedAccountId = undefined;
    MODEL.currentAssociatedContactId = '';

    // hide proper DOM elements
    $('#editAccountForm').hide();
    $('#editContactForm').hide();
    $('#editPinForm').hide();
    $('#editDealForm').hide();
    $('#addPinForm').hide();
    $('#addAccountsForm').hide();
    $('#addDealsForm').hide();
    $('#searchResults').hide();
    $('#localCustomerSearch').hide();
    $('#importCustomers').hide();
    $('#pinDroppedEditing').hide();
    $('#subheader').show();
    $('.teamHeader').show();
    $('#edit-subheader').hide();
    $('#map-toolbar').show();
    $('#mapViewByUser').show();
    $('#teamHeader').show();
    $('#add-terr-subheader').hide();
    $('#allterr').show();

    // show correct page based on route
    if ($route.current.id.indexOf('GroupsListPage') >= 0) {
      if (MODEL.GroupsService.pinsInGroupBeingShow) {
        MODEL.GroupsService.showGroupsDetailList = true;
        $('#map').show();
      } else {
        $('#allgroups').show();
      }
    } else if ($route.current.id === 'accountsMapPage') {
      $('#map').show();

      // for find new customers page
      if (MODEL.MappingService.findNewCustomers) {
        $('#subheader').hide();
        $('#findNewCustomers').show();
        $('#mapViewByUser').hide();
      }
    } else if ($route.current.id === 'contactsMapPage') {
      $('#map').show();
    } else if ($route.current.id === 'contactsGroupsMapPage') {
      $('#map').show();
    } else if ($route.current.id === 'dealsMapPage') {
      $('#dealsMapView').show();
    } else if ($route.current.id === 'dealsPage') {
      $('#dealList').show();
    } else if ($route.current.id === 'mapPage') {
      $('#addPinForm').hide();
    } else if ($route.current.id === 'dashPage') {
      $('#dashBoxes').show();
    } else if ($route.current.id === 'dashboardPage') {
      $('#teamSideDiv').show();
      $('#dashMiddleDiv').show();
      $('#activityRightDiv').show();
    } else if ($route.current.id === 'contactsGroupsListPage' || $route.current.id === 'accountsGroupsListPage' ||
      $route.current.id === 'dealsGroupsListPage'
    ) {
      MODEL.GroupsService.showGroupsDetailList = true;

      if (MODEL.MappingService.toggleCadence) {
        MODEL.GroupsService.showCadenceLegend = true;
        MODEL.GroupsService.showActivityTypeFilter = true;
      }
    } else if ($route.current.id === 'territoriesPage' || $route.current.id === 'territoriesMapPage') {
      $('#territories').show();
      $('.teamHeader').hide();
    } else if ($route.current.id === 'accountsPage') {
      $('#accountsListView').show();
      // $location.path("/accounts", false);
    } else if ($route.current.id === 'contactsPage') {
      $('#customersTable').show();
      // $location.path("/contacts", false);
    } else if ($route.current.id === 'routingPage') {
      $('#allroutes').show();


      MODEL.startingLocationPin.setVisible(true);
    } else if (['accountsRoutingMapPage', 'contactsRoutingMapPage'].includes($route.current.id)) {
      $('.teamHeader').hide();
    } else if ($route.current.id === 'reportsPage') {
      $('#allReportDetails').show();
      $('#map').hide();
    } else if ($route.current.id === 'settingsPage') {
      $('#settingsView').show();
    } else if ($route.current.id === 'upgradePage') {
      $('#upgradeHeader').show();
      $('#upgradeTeam').show();
      $('#upgradeInd').show();
      $('#map').hide();
      $('#upgradeHeaderBtn').show();
    }

    // came from local search --> don't close local search page yet
    if (MODEL.cameFromLocalSearch) {
      $('#introPage').hide();
      $('#localCustomerSearch').show();
      MODEL.cameFromLocalSearch = false;
    }

    // scroll back to existing point
    // if(MODEL.lastScrollPoint) {
    //   $(".main-panel").animate({
    //     scrollTop: MODEL.lastScrollPoint
    //   }, 0);
    // }
  };

  // refresh map
  service.refreshOverlay = function () {
    MAN.nukeMapContent();
  };
  window.refreshOverlay = service.refreshOverlay;

  // cleans up address for geocoding
  service.generateGeocodableAddress = function (...addressPieces) {
    return addressPieces.filter(piece => typeof piece === 'string' && piece.trim() !== '').join(', ');
  };

  // update pin data (pin was edited)
  service.updatePinData = function (newPin, entityId, noRefresh, updateTypeString) {
    if ($route.current.id === 'editContactsPage') {
      // replace pin in main data structure
      const index = MODEL.contacts.findIndex(({id}) => id === entityId);
      if (index >= 0) {
        MODEL.contacts.splice(index, 1, newPin);
      }
      window.currentCustomerSegment(MODEL.currentSkipNumber || 0);
    }

    if ($route.current.id === 'editAccountsPage') {
      // replace pin in main data structure
      let index = indexOf(MODEL.accountsListArray, find(MODEL.accountsListArray, {id: entityId}));
      MODEL.accountsListArray.splice(index, 1, newPin);

      index = indexOf(MODEL.accountsRaw, find(MODEL.accountsRaw, {id: entityId}));
      MODEL.accountsRaw.splice(index, 1, newPin);

      // window.refreshPagination();
    }

    // leads page --> refresh leads data
    if ($route.current.id === 'editDealsPage') {
      const leadsIndex = indexOf(MODEL.dealsRaw, find(MODEL.dealsRaw, {id: entityId}));
      MODEL.dealsRaw.splice(leadsIndex, 1, newPin);
      FunnelService.initFunnelAndMap(MODEL.FunnelService.currentLeadsTab);
    }

    // update list of updates (dash page only)
    if ($route.current.id === 'dashPage') {
      const index = indexOf(MODEL.activityPageArray, find(MODEL.activityPageArray, {customerId: entityId}));
      const currentPinObj = MODEL.activityPageArray[index];
      currentPinObj.address = newPin.address;
      currentPinObj.city = newPin.city;
      currentPinObj.company = newPin.company;
      currentPinObj.name = newPin.name;
      currentPinObj.notes = updateTypeString;
      currentPinObj.state = newPin.state;
      currentPinObj.time = Math.round((new Date()).getTime() / 1000);
      currentPinObj.type = 'pinEdit';
      MODEL.activityPageArray.splice(index, 1, currentPinObj);
    }

    // get new pagination
    service.getPagination();

    // added location for first time to pin on contacts or leads page
    if (newPin.geoPoint && ($route.current.id === 'contactsPage' || $route.current.id === 'dealsPage' || $route.current.id === 'dealsMapPage')) {
      if (!noRefresh) {
        service.selectedRowCustomerTable(newPin, true);
      }
    } else if (MODEL.customersOverlay) { // remove and add pin back to map
      MODEL.customersOverlay.forEach((pin) => {
        const posOfMarker = pin.args.marker_id.indexOf(entityId);
        if (posOfMarker >= 0) {
          // refresh for contact/leads page
          if ($route.current.id === 'contactsPage' || $route.current.id === 'dealsPage' || $route.current.id === 'dealsMapPage' || $route.current.id === 'dashPage') {
            service.selectedRowCustomerTable(newPin, true);
          } else { // main mapping page
            if (UTILS.isOnPage('contactsMapPage')) {
              service.addMarkerForContacts(newPin, posOfMarker, pin);
            }

            if (UTILS.isOnPage('accountsMapPage')) {
              service.addMarkerForAccounts(newPin, posOfMarker, pin);
            }
          }
        }
      });
    }
  };

  // remove and add back marker for contacts pin
  service.addMarkerForContacts = (newPin, posOfMarker, pin) => {
    pin.args.name[posOfMarker] = newPin.name;
    pin.args.phone[posOfMarker] = newPin.phone;
    pin.args.email[posOfMarker] = newPin.email;
    pin.args.address = newPin.address;
    pin.args.color = newPin.color;
    pin.args.city = newPin.city;
    pin.args.state = newPin.region;
    pin.args.zip = newPin.postalCode;
    pin.args.country = newPin.country;
    pin.args.group = newPin.groups;
    pin.args.dirty = true;
    pin.args.createdAt = newPin.createdAt;
    pin.args.updatedAt = newPin.updatedAt;

    // export groups
    let groupsExport = '';
    if (newPin.groups) {
      for (let n = 0; n < newPin.groups.length; n++) {
        // last group --> don't add semicolon
        if (n === (newPin.groups.length - 1)) {
          groupsExport += newPin.groups[n];
        } else {
          groupsExport += `${newPin.groups[n]};`;
        }
      }
    }

    pin.args.groupsExport = groupsExport;

    // pass in color to set the actual image for the pin on the overlay
    pin.args.color = newPin.color;
    MODEL.previousPin = pin.div;
    MODEL.MappingService.previousPinColor = UTILS.getPinImageForColor(newPin.color);

    // if location changed --> update location on map automatically by removing/adding marker to cluster object
    if (newPin.latitude && newPin.longitude) {
      // update location for pin object that is in the customersOverlay
      pin.args.latitude = newPin.latitude;
      pin.args.longitude = newPin.longitude;
      pin.geoPoint = newPin.geoPoint;
      const latlng = new google.maps.LatLng(newPin.latitude, newPin.longitude);
      const newMarker = new MappingService.CustomMarker(
        latlng,
        MODEL.map,
        pin.args,
      );

      // remove pin then add new one to map to update location
      MODEL.markerCluster.removeMarker(pin);
      MODEL.markerCluster.addMarker(newMarker);
    } else {
      pin.unmapped = true;
    }
  };

  // remove and add back marker for accounts pin
  service.addMarkerForAccounts = (newPin, posOfMarker, pin) => {
    // new pin properties (pin is passed by reference --> so can change its properties here)
    pin.args.name[posOfMarker] = newPin.name;
    pin.args.phone[posOfMarker] = newPin.phone;
    pin.args.email[posOfMarker] = newPin.email;
    pin.args.address = newPin.address;
    pin.args.color = newPin.color;
    pin.args.city = newPin.city;
    pin.args.state = newPin.state;
    pin.args.zip = newPin.zip;
    pin.args.country = newPin.country;
    pin.args.group = newPin.groups;
    pin.args.dirty = true;
    pin.args.createdAt = newPin.createdAt;
    pin.args.updatedAt = newPin.updatedAt;
    pin.args.numEmployees = newPin.numEmployees;
    pin.args.website = newPin.website;
    pin.args.parentAccountId = newPin.parentAccountId;
    pin.args.annualRevenue = newPin.annualRevenue;
    pin.args.customFields = newPin.customFields;

    // export groups
    let groupsExport = '';
    if (newPin.groups) {
      for (let n = 0; n < newPin.groups.length; n++) {
        // last group --> don't add semicolon
        if (n === (newPin.groups.length - 1)) {
          groupsExport += newPin.groups[n];
        } else {
          groupsExport += `${newPin.groups[n]};`;
        }
      }
    }

    pin.args.groupsExport = groupsExport;

    // pass in color to set the actual image for the pin on the overlay
    pin.args.color = newPin.color;
    MODEL.previousPin = pin.div;
    MODEL.MappingService.previousPinColor = UTILS.getPinImageForColor(newPin.color);

    // if location changed --> update location on map automatically by removing/adding marker to cluster object
    if (newPin.latitude && newPin.longitude) {
      // update location for pin object that is in the customersOverlay
      pin.args.latitude = newPin.latitude;
      pin.args.longitude = newPin.longitude;
      pin.geoPoint = newPin.geoPoint;
      const latlng = new google.maps.LatLng(newPin.latitude, newPin.longitude);
      const newMarker = new MappingService.CustomMarker(
        latlng,
        MODEL.map,
        pin.args,
      );

      // remove pin then add new one to map to update location
      MODEL.markerCluster.removeMarker(pin);
      MODEL.markerCluster.addMarker(newMarker);
    } else {
      pin.unmapped = true;
    }
  };

  // update custom field data in the local data structure
  service.updateCustomFieldsData = function (newCustomFieldData) {
    if (newCustomFieldData.tableName === 'accounts') {
      const index = indexOf(MODEL.accounts, find(MODEL.accounts, {objectId: newCustomFieldData.tableObjectId}));
      MODEL.accounts[index].customFields = newCustomFieldData;
      MODEL.accountsListSegment[index].customFields = newCustomFieldData;
    } else if (newCustomFieldData.tableName === 'contacts') {
      const index = indexOf(MODEL.contacts, find(MODEL.contacts, {id: newCustomFieldData.tableObjectId}));
      MODEL.contacts[index].customFields = newCustomFieldData;
    } else if (newCustomFieldData.tableName === 'deals') {
      const index = indexOf(MODEL.deals, find(MODEL.accounts, {objectId: newCustomFieldData.tableObjectId}));
      MODEL.deals[index].customFields = newCustomFieldData;
      MODEL.dealsListSegment[index].customFields = newCustomFieldData;
    }
  };

  // upload notes to parse
  service.uploadNotes = function (...notes) {
    service.updateCurrentCrmObjectInfo();
    MODEL.MappingService.currentCustomersNotes = MODEL.MappingService.currentCustomersNotes || [];

    notes.forEach(toBeUploadedNote => {
      $('.noteButton').hide();

      BaseNetworkService.create(`${MODEL.currentCrmObjectType}/${MODEL.currentCrmObjectId}/notes`, toBeUploadedNote)
        .then(uploadedNote => {
          if (!MODEL.MappingService.importingRightNow) {
            // now use real data for the tempNote we created
            const tempNote = MODEL.MappingService.currentCustomersNotes
              .find(note => note.createdAt.getTime() === toBeUploadedNote.createdAt.getTime());

            if (tempNote) {
              tempNote.id = uploadedNote.id;
              tempNote.user = uploadedNote.user;
              tempNote.createdAt = uploadedNote.createdAt;
              tempNote.uploadingStatus = undefined;
              window.refreshDom({currentCustomersNotes: MODEL.MappingService.currentCustomersNotes}, 'MappingService');
            }
            $('.noteButton').show();
          }

          // add the new note to the data structure
          const index = MODEL.notesData.findIndex(({crmObjectId}) => crmObjectId === toBeUploadedNote.customerId);
          if (index >= 0) {
            const crmObjectNote = {
              id: uploadedNote.id,
              note: toBeUploadedNote.note,
              user: {username: safeLocalStorage.currentUser.username},
              createdAt: toBeUploadedNote.createdAt,
              updatedAt: uploadedNote.createdAt,
            };
            MODEL.notesData[index].crmObjectNotes.unshift(crmObjectNote);
          } else {
            MODEL.notesData.unshift({
              crmObjectId: toBeUploadedNote.customerId,
              crmObjectNotes: [{
                id: uploadedNote.id,
                note: toBeUploadedNote.note,
                user: {username: safeLocalStorage.currentUser.username},
                createdAt: toBeUploadedNote.createdAt,
                updatedAt: uploadedNote.createdAt,
              }],
            });
          }
        })
        .catch(() => {
          // update the temp note to notify customer it is unsaved. We don't want to delete it, that could piss someone off if it was long.
          const noteIndex = MODEL.MappingService.currentCustomersNotes
            .findIndex(({createdAt}) => createdAt.getTime() === toBeUploadedNote.createdAt.getTime());
          if (noteIndex >= 0) {
            MODEL.MappingService.currentCustomersNotes[noteIndex] = {

              ...MODEL.MappingService.currentCustomersNotes[noteIndex],
              uploadingStatus: 'ERROR. WAS NOT SAVED. PLEASE TRY AGAIN.',
            };
          }
          $('.noteButton').show();
          swal("That's embarrassing...", 'We had some trouble saving your note. Please try again.', 'error');
        });
    });
  };

  // add note to customer
  service.addNote = (deferSave, optionalNoteText, optionalStorageArray) => {
    service.updateCurrentCrmObjectInfo();

    // deferSave argument:
    // if the customer doesn't exist, we need to hold off on uploading these notes until
    // we've made the customer.
    const noteInputFieldId = `note${deferSave ? `_new_crmobject_${MODEL.currentCrmObjectType}` : ''}`;

    MODEL.MappingService.currentCustomersNotes = MODEL.MappingService.currentCustomersNotes || []; // this one is bound with gui
    if (deferSave) {
      // this one is very similar, but has the 'true' objects to be uploaded, which lack the loading text.
      window.mmcGlobals.deferredNotes = window.mmcGlobals.deferredNotes || [];
    }

    // if it's a deferSave, they don't exist yet, so we can't add the note since we don't have a customerId.
    const noteToSave = {
      note: optionalNoteText || document.getElementById(noteInputFieldId).value,
      createdAt: new Date(),
    };

    // add note to interface--slightly different text body that indicates it is saving.
    const tempNote = UTILS.jsonClone(noteToSave);
    if (!deferSave) {
      tempNote.uploadingStatus = '(saving, please wait...)';
    }
    tempNote.user = {username: safeLocalStorage.currentUser.username};
    // to allow editing, we need id present.
    tempNote.id = `fakeObjectId_${window.mmcGlobals.deferredNotes
      ? window.mmcGlobals.deferredNotes.length
      : MODEL.MappingService.currentCustomersNotes.length}`;
    MODEL.MappingService.currentCustomersNotes.unshift(tempNote);

    if (!deferSave) {
      service.uploadNotes(noteToSave);
    } else {
      (optionalStorageArray || window.mmcGlobals.deferredNotes).push(noteToSave);
    }

    $(`#${noteInputFieldId}`).val('');
  };

  // uploads deferred notes
  service.uploadDeferredNotes = (customerId, optionalStorageArray) => {
    if (!window.mmcGlobals.deferredNotes && !optionalStorageArray) return;

    // else...
    const deferredNotes = optionalStorageArray || window.mmcGlobals.deferredNotes;
    deferredNotes.forEach(note => {
      note.customerId = customerId;
    });
    service.uploadNotes(...deferredNotes);
    delete window.mmcGlobals.deferredNotes;
  };

  // edit note (and refresh page)
  // this could be rewritten to use $scope.$digest,
  // which I hadn't known about when I wrote this
  service.editNote = function (id) {
    service.updateCurrentCrmObjectInfo();
    const noteToEdit = find(MODEL.MappingService.currentCustomersNotes, {id});

    if (noteToEdit.user.username !== safeLocalStorage.currentUser.username && !MODEL.teamOwner) {
      swal("We're sorry", 'Only the team member who wrote this note can edit it.', 'error');
      return;
    }

    // only allow user to edit if they own the note
    const isEditingDeferred = (window.mmcGlobals.deferredNotes && window.mmcGlobals.deferredNotes.length);
    const noteDivId = `note_${isEditingDeferred ? 'new_' : ''}${id}`;
    const editNoteButtonDivId = `editNoteButton_${isEditingDeferred ? 'new_' : ''}${id}`;

    if (!MODEL.isEditingNote) {
      // STEP 1 -- replace div with text box
      const $input = $('<input />', {id: noteDivId, class: 'form-control'})
        .val(noteToEdit.note)
        .submit(() => window.mmcGlobals.editNote(id));
      $(`#${noteDivId}`).replaceWith($input);

      // change edit button to save button
      $('.noteButton').hide(); // if you allow multiple concurrent edits, the current 'isEditingNote' mechanism breaks down.
      $(`#${editNoteButtonDivId}`).html("<i class='icon ion-checkmark'></i> Save").show();

      // broken mechanism -- won't handle editing multiple notes, or deleting note being edited. needs to be an array we add to. Temp fix: hide all edit buttons while editing anything.
      MODEL.isEditingNote = true;
    } else {
      // STEP 2
      // replace back with solid div and save to db

      // dis-allow editing until upload completes.
      $('.noteButton').hide();

      const newText = $(`#${noteDivId}`).val();
      const $div = $('<div>', {
        id: noteDivId,
        class: 'a',
        onclick: `window.mmcGlobals.editNote('${id}')`,
      })
        .text(newText);

      // if deferred, we don't save the update to DB, just to local temp object AND gui matching object.
      // first gui matching with fake objectId and createdAt and so on:
      if (isEditingDeferred) {
        // then proper stored updates.
        const deferredNote = find(window.mmcGlobals.deferredNotes, {createdAt: noteToEdit.createdAt});
        deferredNote.note = newText;

        $(`#${editNoteButtonDivId}`).html("<i class='icon ion-edit'></i> Edit").show();
        $('.noteButton').show();
        MODEL.isEditingNote = false;
        $(`#${noteDivId}`).replaceWith($div);
      } else {
        // already exists, so we can just save directly to db
        BaseNetworkService.update(`${MODEL.currentCrmObjectType}/${MODEL.currentCrmObjectId}/notes/${id}`, {
          id,
          note: newText,
        })
          .then(data => {
            // re-allow editing
            $('.noteButton').show();

            // update the local data structure
            for (let i = 0; i < MODEL.notesData.length; i++) {
              const formattedNotes = MODEL.notesData[i];
              if (MODEL.currentCrmObjectId === formattedNotes.crmObjectId) {
                const crmObjectNote = remove(formattedNotes.crmObjectNotes, {id});
                if (crmObjectNote !== undefined) {
                  crmObjectNote[0].note = newText;
                  crmObjectNote[0].updatedAt = data.updatedAt;
                  formattedNotes.crmObjectNotes.unshift(crmObjectNote[0]);
                  MODEL.notesData[i] = formattedNotes;
                }
              }
            }

            // send to updates table
            $(`#${noteDivId}`).replaceWith($div);
            noteToEdit.note = newText;

            // change edit button to save button
            $(`#${editNoteButtonDivId}`).html("<i class='icon ion-edit'></i> Edit");
            MODEL.isEditingNote = false;
          })
          .catch(() => {
            swal('Uh-Oh', 'We had trouble saving that note. Try again.', 'error');
            console.error('Error adding notes for customer');
            $(`#${editNoteButtonDivId}`).show();
          });
      }
    }
  };
  window.mmcGlobals.editNote = service.editNote;

  // delete note (and refresh page)
  service.deleteNote = (id) => {
    // remote note from interface
    const noteIndex = MODEL.MappingService.currentCustomersNotes.findIndex(note => note.id === id);
    if (noteIndex < 0) {
      return;
    }

    const noteToRemove = MODEL.MappingService.currentCustomersNotes[noteIndex];
    if (noteToRemove.user.username !== safeLocalStorage.currentUser.username && !MODEL.teamOwner) {
      swal("We're sorry", 'Only the team member who wrote this note can remove it.', 'error');
      return;
    }

    MODEL.MappingService.currentCustomersNotes.splice(noteIndex, 1);

    service.updateCurrentCrmObjectInfo();
    BaseNetworkService.delete(`${MODEL.currentCrmObjectType}/${MODEL.currentCrmObjectId}/notes/${id}`)
      .then(() => {
        // remove notes from the local data structure
        for (let i = 0; i < MODEL.notesData.length; i++) {
          const formattedNotes = MODEL.notesData[i];
          if (MODEL.currentCrmObjectId === formattedNotes.crmObjectId) {
            remove(formattedNotes.crmObjectNotes, {id});
          }
        }
      })
      .catch(() => {
        console.error('Error deleting notes');
      });
  };

  // google search for this customer
  service.googleSearch = () => {
    const name = MODEL.currentCRMObject.name || '';
    const city = MODEL.currentCRMObject.city || '';
    const state = MODEL.currentCRMObject.region || '';
    const address = MODEL.currentCRMObject.address || '';
    const zip = MODEL.currentCRMObject.postalCode || '';
    const url = `https://www.google.com/search?q=${name} ${address} ${city}, ${state} ${zip}`;
    window.open(url, '_blank');
  };

  // delete customer
  service.deleteEntity = (entityType, id) => {
    const entityTypeName = service.getEntityDisplayName(entityType);

    const entityId = id || $route.current.params.recordId;

    // user must 'own' this pin
    if ((MODEL.currentUsername === safeLocalStorage.currentUser.username) || service.canDelete(entityType)) {
      // show delete box page
      swal({
        title: 'Are you sure?',
        text: `You won't be able to recover this ${entityTypeName}!`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, delete now',
      })
        .then(() => {
          if (entityType === 'contacts') {
            return contactsNetworkService.deleteContact(entityId);
          } if (entityType === 'deals') {
            return dealsNetworkService.deleteDeal(entityId);
          } if (entityType === 'accounts') {
            return accountsNetworkService.deleteAccount(entityId);
          } if (entityType === 'territories') {
            return TerrNetworkService.deleteTerritory(entityId)
              .then(() => {
                // TODO: delete from MODEL.territories
              });
          }
          return Promise.reject(new Error(`Unknown entity type: ${entityType}`));
        })
        .then(() => {
          swal('Deleted', `This ${entityTypeName} has been deleted.`, 'success');
          analyticsService.entityDeleted(entityTypeName, entityId);
          service.closeAccount();
        })
        .catch((res) => {
          if (res !== 'cancel') {
            swal('Uh-Oh', `We couldn't delete this ${entityTypeName}. Try again.`, 'error');
          }
        });
    } else {
      swal('Yikes!', `Your team owner must give you permission to remove this ${entityTypeName}.`, 'error');
    }
  };

  service.canDelete = (tableName) => {
    if (tableName === 'accounts') {
      return MODEL.accessRightsAccounts.canDelete;
    } if (tableName === 'contacts') {
      return MODEL.accessRightsContacts.canDelete;
    }
    return MODEL.accessRightsDeals.canDelete;
  };

  // remove an entire collection of pins from the map instantly
  service.instantlyRemovePinsFromMap = (pinCollection) => {
    // remove this cadre of pins from the map
    const deletedPins = [];
    MODEL.customersOverlay.forEach((marker) => {
      // see if this marker is in deleted list
      const customer = find(pinCollection, {objectId: marker.args.marker_id[0]});
      if (customer) {
        deletedPins.push(marker);
        marker.setMap(null);
        marker = null;
      }
    });

    // finishes removing them
    if (MODEL.markerCluster !== '') {
      MODEL.markerCluster.removeMarkers(deletedPins);
    }

    // remove pins from local data structures
    pinCollection.forEach((pin) => {
      // contacts array
      let index = indexOf(MODEL.contacts, find(MODEL.contacts, {id: pin.objectId}));
      MODEL.contacts.splice(index, 1);

      // customersExport array
      index = indexOf(MODEL.customersExport, find(MODEL.customersExport, {id: pin.objectId}));
      MODEL.customersExport.splice(index, 1);

      // customersList copy
      index = indexOf(MODEL.customersListCopy, find(MODEL.customersListCopy, {id: pin.objectId}));
      MODEL.customersListCopy.splice(index, 1);
    });
  };
  window.instantlyRemovePinsFromMap = service.instantlyRemovePinsFromMap;

  // hides/shows an entire collection of pins from the map instantly
  service.instantlyRemovePinsFromMap = (pinCollectionIds, show) => {
    const pins = [];
    MODEL.customersOverlay.forEach((marker) => {
      // see if this marker is in pinCollection list
      if (pinCollectionIds.indexOf(marker.args.marker_id[0]) >= 0) {
        pins.push(marker);
        marker.setMap(show ? MODEL.map : null);
      }
    });

    // update numCustomers label
    if (show) {
      MODEL.showHidePinTracking.yes[Object.keys(MODEL.showHidePinTracking.yes)] += pins.length;
    } else {
      MODEL.showHidePinTracking.yes[Object.keys(MODEL.showHidePinTracking.yes)] -= pins.length;
    }
    let pinsShown = MODEL.customersOverlay.reduce((acc, marker) => {
      if (marker.args.marker_id && marker.args.marker_id.length) {
        return acc + marker.args.marker_id.length;
      }
      return acc + 0;
    }, 0);
    if (!show) {
      pinsShown -= pins.length;
    }
    window.refreshDom({numberOfObjects: pinsShown}, 'currentPageSubHeader');
  };
  window.instantlyTogglePinsFromMap = service.instantlyRemovePinsFromMap;

  // toggle groups
  service.toggleGroup = (groupName, toggleAll) => {
    let toggleAllCount = 0;

    if (toggleAll) {
      // increase count of groups gone thru
      toggleAllCount += 1;
      // gone thru all groups --> now refresh map
      if (toggleAllCount >= safeLocalStorage.currentUser.groups.length) {
        toggleAllCount = 0;
        MODEL.show.loader = false;
      }
    }
  };

  // add event to calendar
  service.addToCalendar = () => {
    // is authorized
    if (!CAL.authorized) {
      CAL.gapiAuthorizeWithCallback(service.addToCalendar);
    } else {
      MODEL.currentCrmObjectId = document.getElementById('crmObjectId').innerHTML;
      const currentPin = find(MODEL.contacts, {id: MODEL.currentCrmObjectId});
      const type = document.getElementById('reminderType').value;
      const remindAt = $('#datetimepicker').data('DateTimePicker').date();
      const addToCalendarButton = document.getElementById('addToCalendarButton');

      // create calendar name
      let calName = '';
      calName += `${type}: `;
      if (currentPin.name) {
        calName += `${currentPin.name} `;
      }
      if (currentPin.company) {
        calName += currentPin.company;
      }

      // create cal address
      let calAddress = '';
      if (currentPin.address) {
        calAddress += `${currentPin.address}, `;
      }
      if (currentPin.city) {
        calAddress += `${currentPin.city} `;
      }
      if (currentPin.state) {
        calAddress += `${currentPin.state} `;
      }
      if (currentPin.zip) {
        calAddress += `${currentPin.zip} `;
      }
      if (currentPin.country) {
        calAddress += currentPin.country;
      }

      // save meeting to google calendar
      const event = {
        summary: calName,
        location: calAddress,
        start: {
          dateTime: remindAt.toISOString(),
        },
        end: {
          dateTime: remindAt.add(1, 'hour').toISOString(),
        },
        reminders: {
          useDefault: false,
          overrides: [
            {method: 'email', minutes: 24 * 60},
            {method: 'popup', minutes: 30},
          ],
        },
      };

      const request = gapi.client.calendar.events.insert({
        calendarId: 'primary',
        resource: event,
      });

      request.execute(() => {});

      // change text on button to success
      addToCalendarButton.innerHTML = "<i class='ion-checkmark'></i> Added";
    }
  };

  // add custom field
  service.addCustomFieldIfValidFromDom = (newFieldName, crmPage) => {
    MODEL.MappingService.addingFieldFromDom = true;
    const validity = MappingService.validField(newFieldName);
    newFieldName = MappingService.sanitizeNewField(newFieldName);

    if (!validity[0]) {
      swal('Invalid Field', validity[1], validity[2])
        .then(() => {
          // show the custom field swal again
          service.showCustomFields(crmPage);
        })
        .catch(() => {});
    } else {
      MappingService.addCustomField(newFieldName, crmPage);
    }
  };

  // populate the dom data for custom fields
  service.populateCustomFieldsDomData = (crmObjectCustomFields, record, tableName) => {
    if (record) {
      $('#customFieldObjectId').val(record.id);
      // populate custom fields
      forIn(crmObjectCustomFields, (value, key) => {
        const argName = `custom${value}`;
        if (record[argName]) {
          $(`#${key}${tableName}`).val(record[argName]);
        } else {
          $(`#${key}${tableName}`).val('');
        }
      });
    }
  };
  window.populateCustomFieldsDomData = service.populateCustomFieldsDomData;

  // check which page the user is in
  service.isOnPage = pageName => $location.path().includes(pageName);
  window.isOnPage = service.isOnPage;

  //
  // ------------------------ LOCATION CALLS ------------------------ //
  //

  // errors grabbing location
  service.showError = (error) => {
    let message;
    let showMessage = true;

    switch (error.code) {
      case error.PERMISSION_DENIED:
        message = 'User denied the request for Geolocation.';
        showMessage = false;
        break;
      case error.POSITION_UNAVAILABLE:
        message = 'Location information is unavailable.';
        break;
      case error.TIMEOUT:
        message = 'The request to get user location timed out.';
        showMessage = false;
        break;
      default:
        message = 'An unknown error occurred.';
        break;
    }

    if (showMessage) {
      helperService.showAndLogError(error, message);
    } else {
      helperService.logError(error, message);
    }
    window.refreshDom({loader: false}, 'show');
    window.showPosition({coords: {latitude: MAN.lastResortPosition.lat, longitude: MAN.lastResortPosition.lng}});
  };
  window.showError = service.showError;

  // get user's position
  service.showPosition = (position) => {
    const {latitude, longitude} = position.coords;
    MODEL.startingLocationPinLat = latitude;
    MODEL.currentlat = latitude;
    MODEL.startingLocationPinLng = longitude;
    MODEL.currentlng = longitude;

    if (!MAN.userPosition) {
      MAN.userPosition = {lat: latitude, lng: longitude};
      MAN.savedOptions.center = MAN.userPosition;
    }

    MappingService.setPinLocation();
    MappingService.setUserMarker();
  };
  window.showPosition = service.showPosition;

  // populates the add new customer form with your current location
  service.findMe = async function (crmObject) {
    const latlng = MAN.userPosition;

    if (!latlng) {
      MAN.promptForPosition(service.findMe);
    } else {
      const response = await GeocodingNetworkService.reverseGeocodeAddress(latlng.lat, latlng.lng);

      if (response.address) {
        crmObject.addressAdd = response.address.address;
        crmObject.cityAdd = response.address.city;
        crmObject.stateAdd = response.address.region;
        crmObject.zipAdd = response.address.postalCode;
        crmObject.countryAdd = response.address.countryCode;
      } else {
        swal('Sorry about that...', 'Something seems to have gone wrong. Please try again.', 'error');
      }
    }

    return crmObject;
  };

  // import progress bar underneath profile picture
  service.toggleImportProgressBar = () => {
    // show a global progress bar if the import is running
    $('#import-progress-modal').toggle(MODEL.ImportService.importProcessRunning);
    // highlights the correct tab in sidebar to be .active
    service.highlightSideBarTab();
  };
  window.toggleImportProgressBar = service.toggleImportProgressBar;

  // highlights the correct tab in sidebar to be .active
  service.highlightSideBarTab = function () {
    // remove all currently active tabs
    $('#mappingTab').removeClass('active');
    $('#dashTab').removeClass('active');
    $('#crmTab').removeClass('active');
    $('#teamsTab').removeClass('active');
    $('#accountTab').removeClass('active');

    // highlight correct tab based on current page (setInterval lets JS catch up)
    setInterval(
      () => {
        if (MODEL.mappingPages.indexOf($route.current.id) >= 0) {
          $('#mappingTab').addClass('active');
        } else if (MODEL.crmPages.indexOf($route.current.id) >= 0) {
          $('#crmTab').addClass('active');
        } else if (MODEL.teamsPages.indexOf($route.current.id) >= 0) {
          $('#teamsTab').addClass('active');
        } else if (MODEL.accountPages.indexOf($route.current.id) >= 0) {
          $('#accountTab').addClass('active');
        } else if ($route.current.id === 'dashPage') {
          $('#dashTab').addClass('active');
        }
      },
      0,
    );
  };

  // map mmc columns
  service.getMMCColumns = () => (MODEL.ImportService.defaultKnownColumns[MODEL.ImportService.crmView] || [])
    .map(column => ({key: column, disabled: false}));

  // populates columns on import/integrations pages
  service.populateMMCColumns = () => {
    // populate the mmc columns array
    MODEL.ImportService.mmcColumns = service.getMMCColumns();

    const crmObjectType = MODEL.ImportService.crmView;
    const customFieldsNames = Object.keys(safeLocalStorage.currentUser[`${crmObjectType}CustomFields`] || {});
    customFieldsNames.forEach((column) => {
      MODEL.ImportService.mmcColumns.push({key: column, disabled: false});
    });

    const mmcColumnNames = MODEL.ImportService.mmcColumns.map(({key}) => key);

    // Add SKIP field
    MODEL.ImportService.mmcColumns.unshift({key: 'SKIP', skip: true});
    // Add an "Add new field" field
    MODEL.ImportService.mmcColumns.push({key: 'Add New Custom Field', addNew: true});

    MODEL.ImportService.mmcColumns.forEach(c => console.log('after creation', c));

    return mmcColumnNames;
  };

  // hide common dom elements for adding crm objects
  service.hideDomElementsForAddingCRMObjects = () => {
    $('#modalbg').hide();
    $('#modal').hide();
    $('#contactsContainer').hide();
    MODEL.MappingService.showLeadGenMultipleSelect = false;
    $('#importCustomers').hide();
    $('#localCustomerSearch').hide();
    $('#customerBox').hide();
    $('#customFieldBox').hide();
    $('#editGroupBox').hide();
    $('#editTerritoryBox').hide();
    $('#setColorBox').hide();
    $('#setGroupColorBox').hide();
    $('#defineZipsBox').hide();
    $('#lassoSaveBox').hide();
    $('#editRouteBox').hide();
    $('#selectColumnsBox').hide();
    $('#introPage').hide();
    $('#editPinForm').hide();
    $('#customerBox').hide();
    $('#introPage').hide();
    $('#searchResults').hide();
    $('#territories').hide();
    $('#importCustomers').hide();
    $('#groups').hide();
    MODEL.GroupsService.showGroupsDetailList = false;
    $('#nearby').hide();
    $('#activity').hide();
    $('#accountsTable').hide();
    $('#customersTable').hide();
    $('#dealsTable').hide();
    $('#routeDetailsForm').hide();
    $('#listRoutes').hide();
    $('#uploadDataBox').hide();
    $('#uploadStyleBox').hide();
    $('#uploadImportBox').hide();
    $('#additionalFields').hide();
    $('#additionalFieldsLink').show();
    $('#map').hide();
    $('#subheader').hide();
    $('#edit-subheader').show();
    $('#map-toolbar').hide();
    $('#accountsListView').hide();
  };

  // set edit or add page
  service.setEditOrAddPageSubHeaderVariable = (title, addButtonName, type) => {
    $('#edit-subheader').show();
    MODEL.currentPageEditOrAddSubheader.title = title;
    MODEL.currentPageEditOrAddSubheader.addButtonName = addButtonName;
    MODEL.currentPageEditOrAddSubheader.type = type;
  };
  window.setEditOrAddPageSubHeaderVariable = service.setEditOrAddPageSubHeaderVariable;

  // populate the edit popup data structure
  service.populateEditPopupData = async (editPopupType) => {
    MODEL.editPopup.editPopupType = editPopupType;
    MODEL.searchTextEditPopup = '';
    window.refreshDom({searchTextEditPopup: ''});
    MODEL.show.loader = true;

    let filters = {};
    const id = parseInt(MODEL.currentCrmObjectId, 10);

    if (editPopupType === 'childAccounts' || editPopupType === 'parentAccount' || editPopupType === 'account') {
      // non enterprise
      if (!MODEL.nonEnterprise) {
        MODEL.popupDataEnterprise = MODEL.defaultPopupData.accounts;
        MODEL.searchTextEditPopup = '';
        window.refreshDom({popupDataEnterprise: MODEL.defaultPopupData.accounts});
        window.refreshDom({searchTextEditPopup: ''});
      }

      MODEL.editPopup.tableRowData = [];
      MODEL.editPopup.tableHeaderData = ['NAME', 'PHONE', 'EMAIL', 'ADDRESS'];
      MODEL.editPopup.tableRowName = ['name', 'phone', 'email', 'address'];

      if (editPopupType === 'account') {
        MODEL.editPopup.addButtonName = 'Add New Company';
        MODEL.editPopup.accountType = undefined;
      } else if (editPopupType === 'parentAccount') {
        filters = {notDescendantOf: id};
        MODEL.editPopup.addButtonName = 'Add New Company';
        MODEL.editPopup.accountType = 'parent';
      } else if (editPopupType === 'childAccounts') {
        filters = {notAncestorOf: id};
        MODEL.editPopup.addButtonName = 'Add New Company';
        MODEL.editPopup.accountType = 'child';
      }

      MODEL.editPopup.filters = filters;
      const response = await accountsNetworkService.fetchAllAccounts(false, filters, 1, 'name', true);
      MODEL.accounts = response.data;

      // do not include account itself into list of available parent accounts
      if (MODEL.AddEditService.addEditView === 'accounts' && MODEL.currentCRMObject) {
        MODEL.accounts = MODEL.accounts.filter(({id}) => id !== MODEL.currentCRMObject.id);
      }

      const tableRowData = MODEL.accounts.map((account) => ({
        row0: account.id,
        row1: account.name,
        row2: account.email,
        row3: account.phone,
        row4: account.address,
        fullAddress: [account.address, account.city, account.region].filter(x => !!x).join(', \n'),
      }));
      MODEL.editPopup.initialData = MODEL.accounts;
      window.refreshDom({tableRowData}, 'editPopup');
    } else if (editPopupType === 'contacts') {
      if ($route.current.id === 'editDealsPage' || $route.current.id === 'addDealsPage') {
        if (MODEL.currentAssociatedAccountId) {
          filters.associatedWithAccountId = MODEL.currentAssociatedAccountId;
        }
      } else {
        filters.excludeAssociatedContacts = true;
      }

      const response = await contactsNetworkService.fetchAllContacts(false, filters, 1, 'name', true);
      MODEL.contacts = response.data;
      const tableRowData = MODEL.contacts.map((contact) => ({
        row0: contact.id,
        row1: contact.name,
        row2: contact.email,
        row3: contact.phone,
        row4: contact.address,
        fullAddress: [contact.address, contact.city, contact.region].filter(x => !!x).join(', \n'),
      }));
      window.refreshDom({tableRowData}, 'editPopup');
      MODEL.editPopup.initialData = MODEL.contacts;
      MODEL.editPopup.addButtonName = 'Add New Person';
      MODEL.editPopup.filters = filters;
    } else if (editPopupType === 'deals') {
      MODEL.editPopup.addButtonName = 'Add New Deal';

      if ($route.current.id === 'editContactsPage') {
        // if any account has been associated
        if (MODEL.currentAssociatedAccountId) {
          filters = {accountId: MODEL.currentAssociatedAccountId};
          // no account associated -> don't make any server request
        } else {
          window.refreshDom({loader: false}, 'show');
          MODEL.editPopup.tableRowData = [];
          window.refreshDom({tableRowData: []}, 'editPopup');
          return;
        }
      }

      MODEL.editPopup.filters = filters;
      const response = await dealsNetworkService.fetchAllDeals(false, filters, 1, 'name', true);
      MODEL.deals = response.data;
      const tableRowData = MODEL.deals.map((deal) => ({
        row0: deal.id,
        row1: deal.name,
        row2: deal.email,
        row3: deal.phone,
        row4: deal.address,
        fullAddress: [deal.address, deal.city, deal.region].filter(x => !!x).join(', \n'),
      }));
      MODEL.editPopup.initialData = MODEL.deals;
      window.refreshDom({tableRowData}, 'editPopup');
      service.modalsActions.showModal('addEditPopup');
    }

    window.refreshDom({loader: false}, 'show');
    MODEL.editPopup.tableRowData = MODEL.editPopup.tableRowData.slice(0, 10);
  };

  // add the accounts/contacts/deal for the account
  service.addDealsForContact = (record) => {
    MODEL.currentCrmObjectDealsData = MODEL.dealsRaw
      .filter(({contactId}) => contactId === record.id)
      .map(({
        id, name, amount, closingDate, stage,
      }) => ({
        id, name, amount, closingDate, stage,
      }));
  };

  // show product Updates
  service.viewProductUpdates = () => {

  };

  // color selected col
  service.highlightSelectedTableHeader = (colNames, selectedCol) => {
    colNames.forEach(colName => {
      if (colName !== '$$hashKey') {
        $(`#${colName}Hdr`).removeClass('highlightedTableHdr');
        $('#closingDateHdr').removeClass('highlightedTableHdr');
      }
    });

    $(`#${selectedCol}Hdr`).addClass('highlightedTableHdr');
  };

  // sort table
  service.sortTable = function (tableName, selectedCol, numbers = false, secondTable, thirdTable) {
    if (thirdTable) {
      if (numbers) {
        if (sortedABC === true) {
          MODEL[tableName][secondTable][thirdTable] = sortBy(MODEL[tableName][secondTable][thirdTable], [function (o) {
            return o[selectedCol];
          }]);
          sortedABC = false;
        } else if (sortedABC === false) {
          MODEL[tableName][secondTable][thirdTable] = sortBy(MODEL[tableName][secondTable][thirdTable], [function (o) {
            return o[selectedCol];
          }]).reverse();
          sortedABC = true;
        } else {
          MODEL[tableName][secondTable][thirdTable] = sortBy(MODEL[tableName][secondTable][thirdTable], [function (o) {
            return o[selectedCol];
          }]).reverse();
          sortedABC = true;
        }
      } else if (sortedABC === true) {
        MODEL[tableName][secondTable][thirdTable].sort((a, b) => b[selectedCol].toLowerCase().localeCompare(a[selectedCol].toLowerCase()));
        sortedABC = false;
      } else if (sortedABC === false) {
        MODEL[tableName][secondTable][thirdTable].sort((a, b) => a[selectedCol].toLowerCase().localeCompare(b[selectedCol].toLowerCase()));
        sortedABC = true;
      } else {
        MODEL[tableName][secondTable][thirdTable].sort((a, b) => a[selectedCol].toLowerCase().localeCompare(b[selectedCol].toLowerCase()));
        sortedABC = true;
      }

      if (MODEL[tableName][secondTable][thirdTable].length) {
        service.highlightSelectedTableHeader(Object.keys(MODEL[tableName][secondTable][thirdTable][0]), selectedCol);
      }
    } else if (secondTable) {
      if (numbers) {
        if (sortedABC === true) {
          MODEL[tableName][secondTable] = sortBy(MODEL[tableName][secondTable], [function (o) {
            return o[selectedCol];
          }]);
          sortedABC = false;
        } else if (sortedABC === false) {
          MODEL[tableName][secondTable] = sortBy(MODEL[tableName][secondTable], [function (o) {
            return o[selectedCol];
          }]).reverse();
          sortedABC = true;
        } else {
          MODEL[tableName][secondTable] = sortBy(MODEL[tableName][secondTable], [function (o) {
            return o[selectedCol];
          }]).reverse();
          sortedABC = true;
        }
      } else if (sortedABC === true) {
        MODEL[tableName][secondTable].sort((a, b) => b[selectedCol].toLowerCase().localeCompare(a[selectedCol].toLowerCase()));
        sortedABC = false;
      } else if (sortedABC === false) {
        MODEL[tableName][secondTable].sort((a, b) => a[selectedCol].toLowerCase().localeCompare(b[selectedCol].toLowerCase()));
        sortedABC = true;
      } else {
        MODEL[tableName][secondTable].sort((a, b) => a[selectedCol].toLowerCase().localeCompare(b[selectedCol].toLowerCase()));
        sortedABC = true;
      }

      if (MODEL[tableName][secondTable].length) {
        MODEL[tableName][secondTable][0].selectedColumn = selectedCol;
      }
    } else if (numbers) {
      if (sortedABC === true) {
        MODEL[tableName] = sortBy(MODEL[tableName], [function (o) {
          return o[selectedCol];
        }]);
        sortedABC = false;
      } else if (sortedABC === false) {
        MODEL[tableName] = sortBy(MODEL[tableName], [function (o) {
          return o[selectedCol];
        }]).reverse();
        sortedABC = true;
      } else {
        MODEL[tableName] = sortBy(MODEL[tableName], [function (o) {
          return o[selectedCol];
        }]).reverse();
        sortedABC = true;
      }
      if (MODEL[tableName].length) {
        service.highlightSelectedTableHeader(Object.keys(MODEL[tableName][0]), selectedCol);
      }
    } else {
      if (selectedCol in MODEL.listViewColumnToColumnData) {
        selectedCol = MODEL.listViewColumnToColumnData[selectedCol];
      }
      MODEL[tableName].sort((a, b) => {
        const valueA = get(a, selectedCol, '').toLowerCase();
        const valueB = get(b, selectedCol, '').toLowerCase();
        return (sortedABC ? 1 : -1) * valueB.localeCompare(valueA);
      });
      sortedABC = !sortedABC;
      MODEL[`${tableName}Sort`] = {};
      MODEL[`${tableName}Sort`][selectedCol] = sortedABC ? 'desc' : 'asc';
      MODEL.cachedState[tableName].column = undefined;

      if (MODEL[tableName].length) {
        service.highlightSelectedTableHeader(Object.keys(MODEL[tableName][0]), selectedCol);
      }
    }
  };

  // highlight colors
  service.highlightColorModals = (selectedColor) => {
    safeLocalStorage.currentUser.organization.plan.colors.forEach(color => {
      $(`#color-picker-${color.replace(' ', '')}`).removeClass('selected-color');
    });

    MODEL.GroupsService.newGroupColor = selectedColor;

    $(`#color-picker-${selectedColor.replace(' ', '')}`).addClass('selected-color');
  };

  // select record
  service.selectRecord = (allRecords) => {
    MODEL.GroupsService.showFooterDetails = true;
    let tableRecords = [];
    // init all arrays
    if ($route.current.id === 'contactsPage') {
      tableRecords = MODEL.contacts;
    } else if ($route.current.id === 'accountsPage') {
      tableRecords = MODEL.accounts;
    } else if ($route.current.id === 'dealsPage') {
      tableRecords = MODEL.deals;
    } else if (['routeCreatePage', 'individualAccountsRoutePage', 'individualContactsRoutePage'].includes($route.current.id)) {
      tableRecords = MODEL.RoutingService.routeObjects;
    }

    if (allRecords && !MODEL.allRecordsSelected) {
      MODEL.selectedRecords = {};
    } else if (allRecords) {
      MODEL.selectedRecords = tableRecords.reduce((selectedObj, record) => {
        selectedObj[record.id] = true;
        return selectedObj;
      }, {});
    }

    MODEL.selectedEntities = tableRecords.filter(record => !!MODEL.selectedRecords[record.id]);
    MODEL.allRecordsSelected = MODEL.selectedEntities.length === tableRecords.length;
    window.refreshDom({selectedEntities: MODEL.selectedEntities, allRecordsSelected: MODEL.allRecordsSelected});
  };

  /**
   * @param {{id: number, groups: {id: number}[]}} entities
   * @param {number[]} newGroupIds
   * @returns {{create: Object<number, number[]>, remove: Object<number, number[]>}} two maps of groupIds
   * to entityId list to create and to remove
   */
  const getGroupsToUpdate = (entities, newGroupIds) => entities
    // group by entity
    .map(entity => {
      const currentGroupIds = (entity.groups || []).map(({id}) => id);
      return {
        entityId: entity.id,
        create: difference(newGroupIds, currentGroupIds),
        remove: difference(currentGroupIds, newGroupIds),
      };
    })
    // re-group by groupId
    .reduce(
      (result, {entityId, create, remove}) => {
        create.forEach(groupId => {
          if (!result.create[groupId]) {
            result.create[groupId] = [];
          }
          result.create[groupId].push(entityId);
        });
        remove.forEach(groupId => {
          if (!result.remove[groupId]) {
            result.remove[groupId] = [];
          }
          result.remove[groupId].push(entityId);
        });
        return result;
      },
      {create: {}, remove: {}},
    );

  service.applyBulkEntityChanges = async (entityType, updateEntities, isList, filters) => {
    const selectedEntities = isList ? MODEL.selectedEntities : LassoService.saveLassoedPins();

    const selectedGroups = $('[name="bulkGroupsPin"]:checked').map(function () {
      return parseInt(this.value, 10);
    }).get();
    const selectedColor = MODEL.GroupsService.newGroupColor;

    if (!selectedEntities.length) {
      swal('Try Again!', `Select the ${service.getEntityDisplayName(entityType)} on the far left you'd like to edit.`, 'error');
      if (!isList) {
        LassoService.cancelLassoCompletely();
      }
      return;
    }

    const groupsToUpdate = getGroupsToUpdate(selectedEntities, selectedGroups);
    MODEL.show.loader = true;
    const promises = [];
    promises.push(Promise.all(Object.keys(groupsToUpdate.create).map(groupId => addEditNetworkService.addToGroup(
      parseInt(groupId, 10),
      entityType,
      groupsToUpdate.create[groupId],
    ))));
    promises.push(
      (selectedColor && updateEntities)
        ? updateEntities(selectedEntities
          .filter(({color}) => color !== selectedColor)
          .map(entity => ({id: entity.id, color: selectedColor})))
        : Promise.resolve(),
    );

    try {
      await Promise.all(promises);
      analyticsService.completed(`Bulk edit ${service.getEntityDisplayName(entityType, false)}`, updateEntities);

      if (!isList) {
        LassoService.cancelLassoCompletely();
      }
      MODEL.GroupsService.newGroupColor = false;
      swal('Success!', `Info for selected ${service.getEntityDisplayName(entityType, true)} have been saved.`, 'success');
      // reset view after bulk update
      MODEL.selectedRecords = {};
      MODEL.selectedEntities = [];
      await service.setViewBasedOnFilters(entityType, !isList, filters);
      window.refreshDom({loader: false}, 'show');
    } catch (e) {
      console.error(e);
      if (!isList) {
        $('#lassoSaveBox').hide();
        LassoService.cancelLassoCompletely();
      }
      swal('Yikes!', `There was an issue editing your ${service.getEntityDisplayName(entityType, true)}. Please try again.`, 'error');
      window.refreshDom({loader: false}, 'show');
    }
  };

  // set view based on the filters
  service.setViewBasedOnFilters = async (table, map, filters) => {
    const groupsTypeMap = {
      accountsGroupsListPage: {
        fetchGroupsArg: 'accounts',
        viewHeader: 'Company Groups',
      },
      contactsGroupsListPage: {
        fetchGroupsArg: 'contacts',
        viewHeader: 'People Groups',
      },
      dealsGroupsListPage: {
        fetchGroupsArg: 'deals',
        viewHeader: 'Deal Groups',
      },
    };

    if (['accounts', 'accountsPage', 'accountsMapPage', 'territoriesMapPage', 'accountsRoutingMapPage'].includes(table)) {
      await AccountsService.fetchAccounts(map, filters);

      if (map) {
        if (table === 'territoriesMapPage') {
          const {territories, territoryCount} = await TerritoriesService.fetchTerritories(filters);
          MODEL.territories = territories;
          MODEL.territoryCount = territoryCount;
          TerritoriesService.setMapView();
        } else {
          AccountsService.setMapView();
        }
      }

      if (table === 'accountsRoutingMapPage') {
        RoutingService.setViewHeaders(map);
      } else if (table === 'territoriesMapPage') {
        TerritoriesService.setViewHeaders(map);
      } else {
        AccountsService.setViewHeaders(map);
      }
    } else if (['contacts', 'contactsMapPage', 'contactsRoutingMapPage', 'contactsPage'].includes(table)) {
      await AllContactsService.fetchContacts(map, filters);

      if (map) {
        AllContactsService.setMapView();
      }

      if (table === 'contactsRoutingMapPage') {
        RoutingService.setViewHeaders(map);
      } else {
        AllContactsService.setViewHeaders(map);
      }
    } else if (table === 'individualContactsRoutePage') {
      await AllContactsService.fetchContacts(true, filters);
      AllContactsService.setMapView(true);
    } else if (table === 'individualAccountsRoutePage') {
      await AccountsService.fetchAccounts(map, filters);
      AccountsService.setMapView(true);
    } else if (table === 'routingPage' || table === 'routes') {
      await RoutingService.fetchRoutes(filters);

      RoutingService.setViewHeaders();
    } else if (['deals', 'dealsPage', 'dealsMapPage'].includes(table)) {
      await FunnelService.fetchDeals(map, filters);

      if (map) {
        await FunnelService.setMapView();
      } else {
        await FunnelService.setFunnelView(MODEL.FunnelService.currentFunnelId);
      }

      FunnelService.setViewHeaders();
    } else if (table === 'crmActivities' || table === 'crmActivitiesPage' || table === 'crmActivitiesCalPage') {
      await CrmActivitiesService.setViewBasedOnType(map, filters);
    } else if (table === 'reports' || table === 'reportsPage') {
      await ReportsService.fetchPreview(filters);
    } else if (['accountsGroups', 'contactsGroups', 'dealsGroups'].includes(table)) {
      await GroupsService.fetchGroupsForList(groupsTypeMap[$route.current.id].fetchGroupsArg, filters);
    } else if (table === 'territories' || table === 'territoriesPage') {
      const {territories, territoryCount} = await TerritoriesService.fetchTerritories(filters);
      MODEL.territories = territories;
      MODEL.territoryCount = territoryCount;
      TerritoriesService.setViewHeaders(false);
    }
  };

  // save checked records
  // save all checked customers
  service.saveCheckedRecords = (filters) => {
    if (MODEL.map) {
      MappingService.cacheMapState();
    }

    if (['accountsMapPage', 'accountsPage', 'accountsGroupsMapPage'].includes($route.current.id)) {
      return service.applyBulkEntityChanges(
        EntityType.COMPANY,
        accountsNetworkService.updateAccounts,
        $route.current.id === 'accountsPage', filters,
      );
    } if (['contactsMapPage', 'contactsPage', 'contactsGroupsMapPage'].includes($route.current.id)) {
      return service.applyBulkEntityChanges(
        EntityType.PERSON,
        contactsNetworkService.updateContacts,
        $route.current.id === 'contactsPage',
        filters,
      );
    } if (['dealsMapPage', 'dealsPage', 'dealsGroupsMapPage'].includes($route.current.id)) {
      return service.applyBulkEntityChanges(
        EntityType.DEAL,
        undefined,
        $route.current.id === 'dealsPage',
        filters,
      );
    }
    return Promise.resolve();
  };

  // delete all checked customers
  service.deleteCheckedRecords = (fetchEntities) => {
    let table = '';
    let deleteEntities;
    const map = window.isOnPage('map');

    if (['contactsPage', 'contactsMapPage', 'contactsGroupsMapPage'].includes($route.current.id)) {
      table = 'contacts';
      deleteEntities = contactsNetworkService.deleteContacts;
    } else if (['accountsPage', 'accountsMapPage', 'accountsGroupsMapPage'].includes($route.current.id)) {
      table = 'accounts';
      deleteEntities = accountsNetworkService.deleteAccounts;
    } else if (['dealsPage', 'dealsMapPage', 'dealsGroupsMapPage'].includes($route.current.id)) {
      table = 'deals';
      deleteEntities = dealsNetworkService.deleteDeals;
    }

    const entityTypeNames = service.getEntityDisplayName(table, true);

    // delete all checked
    if (MODEL.selectedEntities.length > 0) {
      const warningText = `You won't be able to recover these ${entityTypeNames}!`;

      swal({
        title: 'Are you sure?',
        text: warningText,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, delete now',
        showloaderOnConfirm: true,
      })
        .then(() => deleteEntities(MODEL.selectedEntities.map(({id}) => id)))
        .then(() => fetchEntities())
        .then(() => {
          swal('Success!', `These ${entityTypeNames} have been removed.`, 'success');
          if (map) {
            LassoService.clearLasso();
          }
          MODEL.selectedEntities = [];
          MODEL.selectedRecords = {};
        })
        .catch((err) => {
          if (err === 'cancel') {
            return;
          }
          helperService.showAndLogError(err, `Sorry, we couldn't delete those ${entityTypeNames}. Please try again.`);
        });
    } else {
      swal('Try Again!', `Select the ${entityTypeNames} on the far left you'd like to edit.`, 'error');
    }
  };

  // show list view select cols
  service.showSelectFieldsModal = () => {
    service.modalsActions.showModal('selectFieldsModal');
  };

  // save List View Selected Cols
  service.saveSelectedListViewFields = async (fields) => {
    const crmObjectType = $route.current.id.replace('Page', '');
    MODEL.listviewSelectedColumns = fields;

    let listViewColumns = SettingsService.getUserSetting('listViewColumns');
    if (!listViewColumns) {
      listViewColumns = {contacts: [], accounts: [], deals: []};
    }
    listViewColumns[crmObjectType] = MODEL.listviewSelectedColumns.map(({name}) => name);

    await SettingsService.updateUserSettings('listViewColumns', listViewColumns);
  };

  // save new found customer wrapper
  service.saveNewFoundCustomer = () => {
    MappingService.saveNewFoundCustomer();
  };

  /**
        * Returns current crm object type or <tt>undefined</tt> if no crm object is selected
        * @return {string|undefined}
        */
  service.getCurrentCrmObjectType = () => {
    const result = ['contacts', 'accounts', 'deals', 'activities', 'leads'].find(service.isOnPage);
    return result === 'activities' ? 'crmActivities' : result;
  };

  /**
        * Returns current crm object id or <tt>undefined</tt> if no crm object is selected
        * @return {string|undefined}
        */
  service.getCurrentCrmObjectId = () => ($route.current.params ? $route.current.params.recordId : undefined);

  /**
        * Show of hide loader overlay
        * @param {boolean} show
        */
  service.toggleLoader = (show) => {
    window.refreshDom({loader: show}, 'show');
  };

  /**
        * Updates model with a current CRM object type and Id
        */
  service.updateCurrentCrmObjectInfo = () => {
    MODEL.currentCrmObjectId = $route.current.params.recordId;
    MODEL.currentCrmObjectType = service.getCurrentCrmObjectType();
  };

  //
  // ------------------ MODALS / SWALS ------------------ //
  //

  service.updateImportProgress = async () => {
    if (!MODEL.ImportService.runningImportId) {
      return;
    }
    const importInfo = await ImportNetworkService.fetchImport(MODEL.ImportService.runningImportId);
    if (importInfo.status === 'completed' || importInfo.status === 'errored') {
      window.refreshDom(
        {importProcessRunning: false, runningImportId: undefined},
        'ImportService',
      );
      // TODO: show either "success" or "fail" popup
    }

    const {totalRows, progress, startTime, createdCount, updatedCount, errors, warnings} = importInfo.metadata;
    const processedRowCount = createdCount
      + updatedCount
      + (Array.isArray(errors) ? errors.length : parseInt(errors, 10))
      + (Array.isArray(warnings) ? warnings.length : parseInt(warnings, 10));

    if (importInfo.status === 'queued') {
      $('.mmc-import-progress-modal__title').text('Import is queued');
      $('.mmc-import-progress-modal__records-processed').text('0');
      $('.mmc-import-progress-modal__estimated-time').text('∞');
    } else if (importInfo.status === 'running') {
      $('.mmc-import-progress-modal__title').text('Import is running...');
      if (progress > 0) {
        $('.mmc-import-progress-modal__records-processed').text(`${processedRowCount}/${totalRows}`);

        // get the estimated number of time left basing on a progress and elapsed time
        const secondsElapsed = moment().diff(startTime, 'seconds');
        const secondsLeft = Math.floor(((100.0 / progress) - 1) * secondsElapsed);
        $('.mmc-import-progress-modal__estimated-time')
          .text(`${moment.duration(secondsLeft, 'seconds').humanize()} left`);
      }
    }

    $('.mmc-import-progress-modal__progress-bar').css({width: `${progress}%`});

    setTimeout(service.updateImportProgress, 5000); // poll data again in 5 sec since import not completed yet
  };

  return service;
}

MainService.$inject = [
  '$window', '$location', '$route', '$timeout', 'DataService', 'MappingService',
  'RoutingService', 'FunnelService', 'AllContactsService', 'LassoService', 'TerritoriesService',
  'AddEditService', 'TerrNetworkService', 'SettingsService',
  'AccountsService', 'ImportNetworkService', 'ReportsService',
  'CrmActivitiesService', 'GroupsService',
];
