import customFieldsNetworkService from '../network-services/custom-fields-network-service';

CustomFieldsService.$inject = ['$route', 'MainService'];

export default function CustomFieldsService($route, MainService) {
// when creating a new account/contact/deal we can't save values coz the entity id is not known
// so we save them here and can push the values later
  let fieldValuesForNewEntity = {};
  let onCreatePage = false;

  /**
     * Reset the values to save
     * @param {boolean} isOnCreatePage true if CF is being initialized from the map page
     * @return {boolean} true if initialization was successful
     */
  const init = (isOnCreatePage = false) => {
    fieldValuesForNewEntity = {};
    onCreatePage = isOnCreatePage;

    // List of pages where the CF are allowed to be initialized
    const allowedPages = ['editAccountsPage', 'addAccountsPage', 'editContactsPage', 'addContactsPage',
      'editDealsPage', 'addDealsPage', 'editActivityPage', 'addActivityPage'];

    return isOnCreatePage || allowedPages.includes($route.current.id);
  };

  /**
     * Check if we're on the entity creation page
     * @returns {boolean} <tt>true</tt> if user is currently adding new entity, <tt>false</tt> otherwise
     */
  const isOnCreatePage = () => onCreatePage ||
        ['addAccountsPage', 'addDealsPage', 'addContactsPage', 'addActivityPage'].includes($route.current.id);

  /**
     * Create all saved values, should be called when the account/contact/deal is being created
     * @param {string} entityType type of an entity used to assign the values to
     * @param {number} entityId id of an entity used to assign the values to
     * @returns {Promise<void[]>}
     */
  const actuallyCreateValues = (entityType, entityId) => Promise.all(Object.keys(fieldValuesForNewEntity).map(id => customFieldsNetworkService.createValue(
    entityType,
    entityId,
    {customField: {id}, value: fieldValuesForNewEntity[id].value},
  )));

  /**
     * Returns a list of custom fields for active CRM object
     * @return {Promise<{id: number, displayName: string, dataType: string}[]>}
     */
  const getFields = async () => {
    const {data} = await customFieldsNetworkService.getFields(MainService.getCurrentCrmObjectType());
    return data.map(({createdAt, updatedAt, ...otherProps}) => ({
      ...otherProps,
      createdAt: new Date(createdAt),
      updatedAt: new Date(updatedAt),
    }));
  };

  /**
     * Returns a map from custom field id to its value.
     * @return {Promise<Object<number, {id: number, customField: {id: number}, value: Array}>>}
     */

  const getValues = async () => {
    if (isOnCreatePage()) {
      return fieldValuesForNewEntity;
    }

    const {data} = await customFieldsNetworkService.getValues(
      MainService.getCurrentCrmObjectType(),
      MainService.getCurrentCrmObjectId(),
    );

    return data.reduce((result, value) => Object.assign(result, {[value.customField.id]: value}), {});
  };

  /**
     * Creates new custom field value
     * @param {number} id
     * @param {Array} value
     * @return {Promise<void>}
     */

  const createValue = (id, value) => {
    if (isOnCreatePage()) {
      fieldValuesForNewEntity[id] = {value};
      return Promise.resolve();
    }

    return customFieldsNetworkService.createValue(
      MainService.getCurrentCrmObjectType(),
      MainService.getCurrentCrmObjectId(),
      {customField: {id}, value},
    );
  };

  /**
     * Updates custom field value
     * @param {number} id
     * @param {{id: number}} customField
     * @param {Array} value
     * @return {Promise<void>}
     */

  const updateValue = ({id, customField, value}) => {
    if (isOnCreatePage()) {
      fieldValuesForNewEntity[id] = {value};
      return Promise.resolve();
    }

    return customFieldsNetworkService.updateValue(
      MainService.getCurrentCrmObjectType(),
      MainService.getCurrentCrmObjectId(),
      {id, customField, value},
    );
  };

  return {
    init,
    isOnCreatePage,
    actuallyCreateValues,
    getFields,
    getValues,
    createValue,
    updateValue,
  };
}
