import styled, {css} from 'styled-components';
import React from 'react';

interface Props {summary?: boolean, total?: boolean, padded?: boolean}
const PlanCardComponent = ({summary, total, padded, ...props}: Props & React.HTMLAttributes<HTMLDivElement>) => (
  <div {...props} />
);
const PlanCard = styled(PlanCardComponent)<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  font-weight: 400;

  &:not(:last-child) {
    border-bottom: 1px solid #e1e4e8;
  }
  ${props => props.summary && css`
    background-color: #e1e4e8;
    &:not(:last-child) {
      border-bottom: 1px solid white;
    }
  `}
  ${props => props.total && css`
    font-weight: 800;
  `}
  ${props => (props.padded) && css`
    padding-left: 70px;
  `}
`;

export default PlanCard;
