import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';

export default function DashboardNetworkService() {
  // service to return
  const service = {};

  service.fetchFunnels = function (filters) {
    const endPoint = `organizations/${safeLocalStorage.currentUser.organization.id}/funnels`;
    filters = {...filters};
    let params = {$limit: 100};

    if (Object.keys(filters).length) {
      params = Object.assign(params, {$filters: JSON.stringify(filters)});
    }
    return BaseNetworkServiceWithPromises.read(endPoint, params);
  };

  service.fetchAccountCountData = (filters) => {
    const endPoint = `organizations/${safeLocalStorage.currentUser.organization.id}/reports/accounts/created-over-time`;
    filters = {...filters};
    let params = {$limit: 100};

    if (Object.keys(filters).length) {
      params = Object.assign(params, {$filters: JSON.stringify(filters)});
    }
    return BaseNetworkServiceWithPromises.read(endPoint, params);
  };

  service.fetchCrmActivityCountChart = (filters, type) => {
    const endPoint = `organizations/${safeLocalStorage.currentUser.organization.id}/reports/crm-activities-${type}/created-over-time`;
    filters = {...filters};
    let params = {$limit: 100};

    if (Object.keys(filters).length) {
      params = Object.assign(params, {$filters: JSON.stringify(filters)});
    }
    return BaseNetworkServiceWithPromises.read(endPoint, params);
  };


  service.fetchDealsRevenueData = (filters) => {
    const endPoint = `organizations/${safeLocalStorage.currentUser.organization.id}/reports/deals-revenue/created-over-time`;
    const params = {$limit: 100};
    if (filters) {
      params.$filters = JSON.stringify(filters);
    }
    return BaseNetworkServiceWithPromises.read(endPoint, params);
  };

  service.fetchDealLossReasonsData = (funnelId, filters) => {
    const endPoint = `organizations/${safeLocalStorage.currentUser.organization.id}/reports/funnels/${funnelId}/deal-loss-reasons/summary`;
    const params = {$limit: 5, $order: '-totalDeals'};
    if (filters) {
      params.$filters = JSON.stringify(filters);
    }
    return BaseNetworkServiceWithPromises.read(endPoint, params);
  };

  service.fetchDealsStatusChangesData = (filters) => {
    const endPoint = `organizations/${safeLocalStorage.currentUser.organization.id}/reports/deal-status-changes/created-over-time`;
    const params = {$limit: 100};
    if (filters) {
      params.$filters = JSON.stringify(filters);
    }
    return BaseNetworkServiceWithPromises.read(endPoint, params);
  };

  service.fetchDealCountChart = (filters) => {
    const endPoint = `organizations/${safeLocalStorage.currentUser.organization.id}/reports/deals/created-over-time`;
    filters = {...filters};
    let params = {$limit: 100};

    if (Object.keys(filters).length) {
      params = Object.assign(params, {$filters: JSON.stringify(filters)});
    }
    return BaseNetworkServiceWithPromises.read(endPoint, params);
  };

  service.fetchContactCountData = (filters) => {
    const endPoint = `organizations/${safeLocalStorage.currentUser.organization.id}/reports/contacts/created-over-time`;
    filters = {...filters};
    let params = {$limit: 100};

    if (Object.keys(filters).length) {
      params = Object.assign(params, {$filters: JSON.stringify(filters)});
    }
    return BaseNetworkServiceWithPromises.read(endPoint, params);
  };

  service.fetchCrmActivitiesSummary = (filters) => {
    const org = safeLocalStorage.currentUser.organization.id;
    const url = `organizations/${org}/reports/crm-activities/summary`;
    filters = {...filters};
    let params = {$limit: 100};

    if (Object.keys(filters).length) {
      params = Object.assign(params, {$filters: JSON.stringify(filters)});
    }
    return BaseNetworkServiceWithPromises.read(url, params);
  };

  return service;
}
