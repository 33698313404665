import BaseNetworkService from './base-network-service/base-network-service';
import safeLocalStorage from '../shared-services/safe-local-storage-service';

class SharedNetworkService {

  get orgId() {
    return safeLocalStorage.currentUser.organization.id;
  }

  getRoles() {
    return BaseNetworkService.read('roles?$order=priority');
  }

  // get notes data for given entityType and entityId
  fetchNotes(entityType, entityId) {
    return BaseNetworkService.read(`${entityType}/${entityId}/notes?$order=-updatedAt`);
  }

  // get currencies
  fetchCurrencies() {
    return BaseNetworkService.read('currencies?$limit=500');
  }

  // get shared groups
  fetchSharedGroups(userId) {
    return BaseNetworkService.read(`organizations/${this.orgId}/users/${userId}/shared-groups`, {$limit: -1});
  }

  // post shared groups
  postSharedGroup(userId, params) {
    return BaseNetworkService.create(`organizations/${this.orgId}/users/${userId}/shared-groups/bulk-create`, params);
  }

  // delete shared groups
  deleteSharedGroup(userId, params) {
    return BaseNetworkService.delete(`organizations/${this.orgId}/users/${userId}/shared-groups/bulk-delete`, params);
  }

  fetchCrmActivitiesForEntity(entityType, entityId, type) {
    let params = {};
    switch (entityType) {
      case 'accounts':
        params.accountId = entityId;
        break;
      case 'contacts':
        params.contactId = entityId;
        break;
      case 'deals':
        params.dealId = entityId;
        break;
    }
    switch (type) {
      case 'upcoming':
        params.startAt = {$gt: moment().toISOString()};
        break;
      case 'overdue':
        params.startAt = {$lt: moment().toISOString()};
        break;
      case 'completed':
        params.completed = true;
        break;
    }
    params = {$filters: JSON.stringify(params), $order: '-startAt'};
    return BaseNetworkService.read(`organizations/${this.orgId}/crm-activities`, params);
  }
}

export default new SharedNetworkService();
