import angular from 'angular';
import AccountCtrl from './account-controller';
import SettingsService from './settings/settings-service';
import {CustomFieldsSettingsController, customFieldsSettings} from './settings/custom-fields-settings/custom-fields-settings-component';

export default angular.module('mapmycustomersApp.account', [])
  .controller('AccountCtrl', AccountCtrl)
  .factory('SettingsService', SettingsService)
  .controller('CustomFieldsSettingsController', CustomFieldsSettingsController)
  .component('customFieldsSettings', customFieldsSettings)
  .name;
