import React from 'react';
import {Button, Row} from 'antd';
import {ButtonType} from 'antd/es/button';
import IStep from './IStep';

interface Props extends IStep {
  nextButtonTitle?: string,
  nextDisabled?: boolean,
  nextType?: ButtonType,
  previousButtonTitle?: string,
  previousDisabled?: boolean,
}

const ButtonBar: React.FC<Props> = ({
  nextButtonTitle = 'Continue',
  nextDisabled,
  nextType,
  onNextClick,
  onPreviousClick,
  previousButtonTitle = 'Back',
  previousDisabled,
}) => (
  <Row justify="space-between">
    <Button
      disabled={previousDisabled}
      onClick={onPreviousClick}
    >
      {previousButtonTitle}
    </Button>
    <Button
      disabled={nextDisabled}
      onClick={onNextClick}
      type={nextType}
    >
      {nextButtonTitle}
    </Button>
  </Row>
);

export default ButtonBar;
