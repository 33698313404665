import mapActions from '../../store/store-helpers';
import helperService from '../../shared-services/helper-service';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';

export default function RoutingCtrl(
  $scope, $route, BaseController, RoutingService, MainService,
  TeamService,
) {
  // init global variables
  const MODEL = window.DataModel;
  const UTILS = window.mmcUtils;
  $scope.data = MODEL;

  // extend BaseController methods onto scope
  Object.assign($scope, BaseController);

  mapActions($scope, ['RoutingService', 'modals']);
  $scope.modalsActions.resetVisibility('noRoutesModal');

  $scope.currentState = MODEL.cachedState ? MODEL.cachedState.routes : {};

  $scope.initialRouteContacts = {};

  const filters = {};
  if ($scope.currentState.user) {
    filters.viewAs = $scope.currentState.user;
  }

  $scope.showViewByUser = UTILS.setViewByUserBasedOnRole(safeLocalStorage);

  // bind current route id to scope for access in views
  $scope.currentRoute = $route.current.id;

  // init parse
  UTILS.appInit(safeLocalStorage.accessToken);

  $(document.body).show();
  MODEL.currentSubPage = 'list';
  // get accounts data
  MODEL.show.loader = true;
  MODEL.MappingService.pinData = [];

  $scope.setLeadsView = () => {
    $scope.data.currentLeadsView = UTILS.setLeadsView($scope.currentState);
  };

  if (!MODEL.TeamsService.verifiedTeamMembers.length) {
    TeamService.getOrgDetails()
      .then(() => {
        $scope.setLeadsView();
      })
      .catch((error) => {
        helperService.showAndLogError(error, "We could not retrieve your organization's details. Please reload the page or retry login.");
      });
  } else {
    $scope.setLeadsView();
  }

  // get user data
  MainService.startChainReaction();
  $scope.showViewByUser = UTILS.setViewByUserBasedOnRole(safeLocalStorage);

  $(document.body).show();
  MODEL.show.loader = true;
  $scope.RoutingServiceActions.initRoutingController();
  $scope.startingAddress = MODEL.RoutingService.startingAddress;
  $scope.routeTypeSelected = MODEL.RoutingService.routeTypeSelected;
  $scope.endingAddress = MODEL.RoutingService.endingAddress;

  (async function () {
    const filters = $scope.currentState.user ? {viewAs: $scope.currentState.user} : {};
    await RoutingService.fetchRoutes(filters);
    await RoutingService.updateGeocodingLimits();
    RoutingService.setViewHeaders();
    window.refreshDom({loader: false}, 'show');
  }());

  // route options (in-order or optimized)
  $scope.showRouteOptions = function (id) {
    $scope.RoutingServiceActions.updateFromEditForm(false);
    if (window.isOnPage('accounts')) {
      window.location.href = `/#/accounts/routing/edit/${id}`;
    } else {
      window.location.href = `/#/contacts/routing/edit/${id}`;
    }
  };

  $scope.getCurrentLocation = async (locationType) => {
    await RoutingService.getCurrentLocation(locationType);

    if (locationType === 'startingLocation') {
      $scope.startingAddress = MODEL.RoutingService.startingAddress;
    } else if (locationType === 'endingLocation') {
      $scope.endingAddress = MODEL.RoutingService.endingAddress;
    }
  };

  // show edit route modal
  $scope.editRoute = function (route) {
    MODEL.show.loader = true;
    route = route || MODEL.RoutingService.currentRoute;

    RoutingService.enableDragDrop(true, $scope.updateUi);
    $('#saveRouteButton').show();
    $('#cancelRouteButton').show();

    // show lasso route tool
    $('#lassoRouteListItem').show();
    $scope.RoutingServiceActions.editRoute(route);
    MODEL.MappingService.creatingOrEditingRoute = true;
    // MODEL.MappingService.newRoute = MODEL.RoutingService.currentRouteViewing.points;
    MODEL.MappingService.creatingOrEditingRoute = true;
    // reset all the creating/editing variables
    MODEL.creatingRoute = true;

    const pinsNotInRoute = MODEL.contacts.reduce((vals, c) => {
      if (MODEL.RoutingService.currentRouteObjects.indexOf(c.id) === -1) {
        vals.push(c.id);
      }
      return vals;
    }, []);
    window.instantlyTogglePinsFromMap(pinsNotInRoute, true);
    $scope.RoutingServiceActions.updateOriginalRoute(route);
    MODEL.show.loader = false;
  };

  // submit route
  $scope.submitRoute = function () {
    $scope.showTeamHeader = true;
    RoutingService.submitRoute();
  };

  // show edit route name modal
  $scope.editRouteName = function (route) {
    $('#editRouteNameBox').show();
    $('#routeEditName').val(route.name);
    $scope.RoutingServiceActions.editRouteName(route);
  };

  // delete route
  $scope.deleteRoute = function (route) {
    RoutingService.deleteRoute(route);
  };

  // cancel current route being made
  $scope.cancelRoute = function () {
    $scope.showTeamHeader = false;
    RoutingService.cancelRoute();
  };

  $scope.updateUi = () => {
    $scope.startingAddress = MODEL.RoutingService.startingAddress;
    $scope.routeTypeSelected = MODEL.RoutingService.routeTypeSelected;
    $scope.endingAddress = MODEL.RoutingService.endingAddress;
  };

  $scope.detailsChanged = async (type) => {
    $scope.updateUi();
    $scope.RoutingServiceActions.updateEditFlag({
      details: true,
      rebuildRoute: true,
    });
    // rebuild route if order changed
    if (type === 'routeType') {
      await $scope.rebuildRoute();
    }
  };

  $scope.updateAddressDetails = () => {
    $scope.RoutingServiceActions.updateAddressDetails({
      endingAddress: $scope.endingAddress,
      startingAddress: $scope.startingAddress,
      routeTypeSelected: $scope.routeTypeSelected,
    });
    $scope.detailsChanged();
  };

}

RoutingCtrl.$inject = [
  '$scope', '$route', 'BaseController', 'RoutingService', 'MainService',
  'TeamService',
];
