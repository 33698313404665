import template from './integrations-modal.html';
import showSupportModal from '../../react/util/showSupportModal';

class IntegrationsModalController {
  constructor($window, SettingsService) {
    Object.assign(this, {
      $window,
      SettingsService,
      MODEL: $window.DataModel,
    });
  }

  closeIntegrationsModal() {
    this.MODEL.show.integrationsModal = false;
  }

  askAboutIntegrations() {
    this.closeIntegrationsModal();
    showSupportModal();
  }

  goToImport() {
    window.location.href = '/#/import';
    this.closeIntegrationsModal();
  }

}

IntegrationsModalController.$inject = ['$window', 'SettingsService'];

const integrationsModal = {
  bindings: {},
  controller: 'IntegrationsModalController',
  template,
};

export {IntegrationsModalController, integrationsModal};
