import {useMemo} from 'react';

// might be used to add a default option in select fields prior to other values, e.g.
// values={useDefaultOption(users, 'All Users')}
const useDefaultOption = <T>(
  list: T[],
  value: any,
  key: any = '',
  keyFieldName: string = 'value',
  valueFieldName: string = 'label',
) => useMemo(
    () => [{[keyFieldName]: key, [valueFieldName]: value}, ...list] as T[],
    [list, key, value, keyFieldName, valueFieldName],
  );

export default useDefaultOption;
