import template from './select-fields-modal.html';
import mapActions from '../../store/store-helpers';
import {sortFields} from '../../common/field-model/utils';

/**
 * @property {EntityFieldModel} fieldModel current field model
 * @property {Set<Field>} requiredFields a set of fields that are required
 * @property {Field[]} _sortedAvailableFields all field model's fields sorted for convenience
 * @property {Field[]} selectedFields fields that were selected when this modal was opened
 * @property {Field[]} visibleFields fields that are visible on the screen (after applying filter)
 * @property {{"General Details": Field[], "Address Details": Field[], "Custom Fields": Field[]}} fieldCategory field categories
 * @property {Set<Field>} selected list of fields that are currently selected
 * @property {boolean} isSelectAllCheckboxChecked
 * @property {function({fields: !Field[]})} onChange
 */
class SelectFieldsModalController {
  constructor($timeout, $window) {
    Object.assign(this, {
      $timeout,
      $window,
      MODEL: $window.DataModel,
    });

    mapActions(this, ['modals']);
  }

  $onInit() {
    if (this.requiredFields) {
      // required fields can be either an array of field names or a string with comma-separated field names
      const requiredFieldNames = Array.isArray(this.requiredFields) ? this.requiredFields : this.requiredFields.split(',');
      this.requiredFields = new Set(requiredFieldNames.map(name => this.fieldModel.getByName(name)).filter(field => !!field));
    } else {
      this.requiredFields = new Set();
    }
    this._sortedAvailableFields = sortFields(this.fieldModel.fields.slice());
    this.visibleFields = this._filter();
    this.fieldCategory = this.constructor._categorizeFields(this.visibleFields);
    this.selected = new Set(this.selectedFields);
    this._ensureRequiredFieldsSelected();
    this._refreshToggleAllCheckbox();
  }

  /**
     * Filters available fields by displayName
     * @param {string} [searchText]
     * @returns {Field[]}
     * @private
     */
  _filter(searchText = '') {
    searchText = searchText.trim().toLowerCase();
    return searchText.length
      ? this._sortedAvailableFields.filter(({displayName}) => displayName.toLowerCase().includes(searchText))
      : this._sortedAvailableFields;
  }

  /**
     * Prepares fields for view splitting them into categories.
     * @param {Field[]} fields
     * @returns {{"General Details": Field[], "Address Details": Field[], "Custom Fields": Field[]}}
     * @private
     */
  static _categorizeFields(fields) {
    return {
      'General Details': fields.filter(({isAddressField, isCustomField}) => !isAddressField && !isCustomField),
      'Address Details': fields.filter(({isAddressField}) => isAddressField),
      'Custom Fields': fields.filter(({isCustomField}) => isCustomField),
    };
  }

  /**
     * Updates ToggleAll checkbox: set to "checked" if all visibleFields are present in selected set. Uncheck otherwise.
     * @private
     */
  _refreshToggleAllCheckbox() {
    this.isSelectAllCheckboxChecked = this.visibleFields.every(field => this.selected.has(field));
  }

  /**
     * Ensures that required fields are present in selected set
     * @private
     */
  _ensureRequiredFieldsSelected() {
    this.requiredFields.forEach(field => {
      if (!this.selected.has(field)) {
        this.selected.add(field);
      }
    });
  }

  /**
     * Converts selected set into array and sorts it so that fields are present in exactly same order as in the UI.
     * @returns {Field[]}
     * @private
     */
  _convertSelectedFieldsToArrayAndSort() {
    const fieldNameToIndexMap = this._sortedAvailableFields.reduce((result, {name}, index) => Object.assign(result, {[name]: index}), {});
    return Array.from(this.selected).sort((a, b) => fieldNameToIndexMap[a.name] - fieldNameToIndexMap[b.name]);
  }

  closeModal() {
    this.modalsActions.hideModal('selectFieldsModal');
  }

  /**
     * Add or remove all visible fields from the selected list. Only works for currently visible fields!
     * I.e. if you had 10 fields and then entered some search text so that you only see 3 fields, only those 3 fields
     * are gonna be toggled.
     */
  toggleAllAvailableFields() {
    if (this.visibleFields.every(field => this.selected.has(field))) {
      let deletedAnything = false;
      this.visibleFields.forEach(field => {
        if (!this.requiredFields.has(field)) {
          this.selected.delete(field);
          deletedAnything = true;
        }
      });
      this.isSelectAllCheckboxChecked = !deletedAnything; // keep checkbox checked if nothing has been deleted
    } else {
      this.visibleFields.forEach(field => this.selected.add(field));
      this.isSelectAllCheckboxChecked = true;
    }
  }

  /**
     * Field checkbox handler, updates selected set to reflect selected fields
     * @param {Field} field
     */
  fieldCheckboxClicked(field) {
    if (this.selected.has(field)) {
      this.selected.delete(field);
    } else {
      this.selected.add(field);
    }
    this._refreshToggleAllCheckbox();
  }

  saveSelectedFields() {
    this.onChange({fields: this._convertSelectedFieldsToArrayAndSort()});
    this.closeModal();
  }

  /**
     * Performs fields filtering by field's displayName
     * @param {string} searchText
     * @returns {Promise<Field[]>}
     */
  async onSearch(searchText) {
    this.visibleFields = this._filter(searchText);
    this.fieldCategory = this.constructor._categorizeFields(this.visibleFields);
    this._refreshToggleAllCheckbox();
    return this.visibleFields;
  }
}

SelectFieldsModalController.$inject = ['$timeout', '$window'];

const selectFieldsModal = {
  bindings: {
    fieldModel: '<',
    onChange: '&',
    requiredFields: '<',
    selectedFields: '<',
  },
  controller: 'SelectFieldsModalController',
  template,
};

export {SelectFieldsModalController, selectFieldsModal};
