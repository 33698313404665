import notification from 'antd/es/notification';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';
import template from './create-edit-activity-modal.html';
import randomId from '../../react/util/randomId';

const getNewAppUrl = (path) => `${window.__env.baseNewAppUrl}${path}?mmc_token=${safeLocalStorage.accessToken}&mmc_editpane`;

class CreateEditActivityModalController {
  static modalShown = false;

  constructor($window, $route, MainService) {
    Object.assign(this, {
      MODEL: $window.DataModel,
      MAN: $window.mmcUtils.MAN,
      MainService,
      $route,
      modalId: randomId(),
    });

    window.addEventListener('message', (e) => {
      if (String(e.data).startsWith('closeCreateEditActivityModal')) {
        const messageLength = 'closeCreateEditActivityModal'.length;
        let activity;
        if (e.data.length > messageLength) {
          activity = JSON.parse(e.data.slice(messageLength));
        }
        if (CreateEditActivityModalController.modalShown) {
          this.closeModal(activity);
        }
      }
    });
  }

  closeModal(newActivity) {
    const modalSelector = `#create-modal-${this.modalId}`;

    if (newActivity) {
      notification.success({message: 'Activity added successfully'});
    }

    if ($(modalSelector).length) {
      CreateEditActivityModalController.modalShown = false;

      this.MODEL.NavBar.showCreateEditActivityModal = false;
      window.refreshDom({'NavBar.showCreateEditActivityModal': this.MODEL.NavBar.showCreateEditActivityModal});
      this.toggleOverlay(false);

      // refresh map if we're on a map view
      if (this.$route.current.hasMap) {
        this.MAN.onMapViewChange(this.MAN.globalMap, true);
      }
    }
  }

  toggleOverlay(visible) {
    const initialOverlay = $(`#create-modal-${this.modalId} > .iframe-create-modal-overlay`);
    if (initialOverlay.length) {
      initialOverlay.appendTo('body');
    }
    $(`#create-modal-overlay-${this.modalId}`).toggle(visible);
  }

  $onChanges() {
    setTimeout(() => {
      if (CreateEditActivityModalController.modalShown) {
        return;
      }
      CreateEditActivityModalController.modalShown = true;

      const modalSelector = `#create-modal-${this.modalId}`;
      $(modalSelector).css({display: 'block'});

      let url = '/web1/activities/add/';
      if (this.activityId) {
        url = `/web1/activities/edit/${this.activityId}`;
      }
      document.querySelector(`${modalSelector} > iframe`).src = getNewAppUrl(url);
      this.toggleOverlay(true);
    }, 0);
  }
}

CreateEditActivityModalController.$inject = ['$window', '$route', 'MainService'];

const createEditActivityModal = {
  bindings: {
    className: '@',
    activityId: '<?',
  },
  controller: 'CreateEditActivityModalController',
  template,
};

export {CreateEditActivityModalController, createEditActivityModal};
