import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import Button from 'react-md/lib/Buttons/Button';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import ActivityType from '../../../../type/Activity';
import Activity from '../Activity';
import Centered from '../../../../style/Centered';
import Company from '../../../../type/Company';
import EntityType from '../../../../type/EntityType';

const ActivitiesContainer = styled.div`
  min-height: 150px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ActivityStatusSelectField = styled(SelectField)`
  max-width: 190px;
`;

const ACTIVITY_STATUSES = ['upcoming', 'overdue', 'completed'];

interface Props {
  activities: ActivityType[] | undefined,
  company: Company,
  fetchCrmActivities: (entityType: string, entityId: number, type: 'upcoming' | 'overdue' | 'completed') => void,
  goToEntity: (entityType: EntityType, entityId: number) => void,
}

const ActivitiesTab: React.FC<Props> = ({activities, company, fetchCrmActivities, goToEntity}) => {
  const {t} = useTranslation(['record-view']);
  const [activityStatus, setActivityStatus] = useState<'upcoming' | 'overdue' | 'completed'>('upcoming');
  const handleChangeActivityStatus = useCallback(
    (value) => {
      setActivityStatus(value);
      fetchCrmActivities(EntityType.COMPANY, company.id, value);
    },
    [fetchCrmActivities],
  );
  useEffect(
    () => {
      fetchCrmActivities(EntityType.COMPANY, company.id, activityStatus);
    },
    [company.id],
  );

  const handleAddActivity = useCallback(() => {
    window.refreshDom({'NavBar.showCreateCompanyModal': false, 'NavBar.showCreateEditActivityModal': true, 'NavBar.showCreateDealModal': false, 'NavBar.showCreatePersonModal': false, 'NavBar.editActivityModalActivityId': undefined});
  }, []);

  return (
    <>
      <ActivitiesContainer>
        <ActivityStatusSelectField
          id={`activity-type-${company.id}`}
          label={t('ActivityStatus')}
          menuItems={ACTIVITY_STATUSES}
          onChange={handleChangeActivityStatus}
          simplifiedMenu
          value={activityStatus}
        />
        {activities && activities.length
          ? activities?.map((activity: ActivityType) => (
            <Activity key={activity.id} activity={activity} goToEntity={goToEntity} />
          ))
          : <Centered>{t(`NoActivities-${activityStatus}`)}</Centered>
        }
        <Centered>
          <Button
            primary
            raised
            onClick={handleAddActivity}
          >
            Add Activity
          </Button>
        </Centered>
      </ActivitiesContainer>
    </>
  );
};

export default ActivitiesTab;
