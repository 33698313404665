import get from 'lodash/get';
import {EntityFilter, FilteredField, FilterParam, Sorting} from '../../components/Grid/ListViewGrid';
import IField from '../../type/fieldModel/IField';
import {
  gridFilterComparator,
  platformComparator,
  reverseFilterComparator,
} from '../../type/network/filterComparator';

export interface ListState {
  page: number;
  filter: EntityFilter,
  sorting: Sorting,
  viewColumns: Set<IField>,
  filteredFields: FilteredField[],
}

export const initialListState: ListState = {
  page: 1,
  filter: {},
  sorting: {fieldName: 'updatedAt', ascending: false},
  viewColumns: new Set(),
  filteredFields: [],
};

export type ParamsAction =
  {type: 'setFilter', filter: EntityFilter}
  | {type: 'clearFilter', filterNames: string[]}
  | {type: 'setPage', page: number}
  | {type: 'setViewColumns', viewColumns: IField[]}
  | {type: 'setSorting', sorting: Sorting};

const filterToParam = (filterRecord: object): FilterParam => {

  if (filterRecord[platformComparator.GREATER_THAN_OR_EQUAL] && filterRecord[platformComparator.LESS_THAN_OR_EQUAL]) {
    return {
      localizationKey: 'valueBetween',
      options: {
        from: filterRecord[platformComparator.GREATER_THAN_OR_EQUAL],
        to: filterRecord[platformComparator.LESS_THAN_OR_EQUAL],
      },
      value: '',
      filter: filterRecord,
    };
  }
  const comparatorKey: string = Object.keys(reverseFilterComparator).find((key: string) => !!filterRecord[key]) || '';
  return {
    localizationKey: comparatorKey ? reverseFilterComparator[comparatorKey] : gridFilterComparator.EQUALS,
    options: {},
    value: comparatorKey ? filterRecord[comparatorKey] : filterRecord,
    filter: filterRecord,
  };
};

const getFieldFilterName = (field: IField) => field.filter?.name || field.name;

const getFilteredFieldsByFilter = (filter: EntityFilter, modelFields: IField[]): FilteredField[] => {
  const allFilters: {[key: string]: any} = {...filter, ...(filter?.customFields ?? {})};
  const fields: FilteredField[] = modelFields
    .filter((field: IField) => !!allFilters[getFieldFilterName(field)])
    .map((field: IField) => {
      const name: string = getFieldFilterName(field);
      let displayName: string = field.displayName;
      let filter: object = allFilters[name];
      let showNameOnTooltip: boolean = true;
      const prepareFilterToFilteredFieldShowing = get(field, 'gridProperties.filterParams.prepareFilterToFilteredFieldShowing', false);
      if (prepareFilterToFilteredFieldShowing) {
        filter = prepareFilterToFilteredFieldShowing(filter);
      }
      let filterParam;
      if (name === 'groups') {
        showNameOnTooltip = false;
        filterParam = {
          localizationKey: filter[platformComparator.ANY] ? 'recordsInAnyGroups' : 'recordsInAllGroups',
          options: {},
          value: '',
        };
      } else {
        filterParam = filterToParam(filter);
        if (Array.isArray(filterParam.value)) {
          if (field.filter?.mapIdToFieldValue) {
            filterParam.value = field.filter.mapIdToFieldValue(filterParam.value);
          }
          if (field.isOptionField) {
            filterParam.value = field.mapCustomFieldIdToValue(filterParam.value);
          }
        }
      }

      return {
        name,
        displayName,
        filterParam,
        filter: allFilters[getFieldFilterName(field)],
        showNameOnTooltip,
      };
    });
  if (filter.area) {
    fields.push({
      name: 'area',
      displayName: 'Radius',
      filterParam: {localizationKey: gridFilterComparator.LESS_THAN_OR_EQUAL, options: {}, value: filter.area.radius},
      filter: filter.area,
      showNameOnTooltip: true,
    });
  }
  return fields;
};

export const listReducer = (state: ListState, action: ParamsAction, fields: IField[]): ListState => {
  switch (action.type) {
    case 'setFilter': {
      const filter: EntityFilter = {...state.filter, ...action.filter};
      return {
        ...state,
        filter,
        filteredFields: getFilteredFieldsByFilter(filter, fields),
        page: 1,
      };
    }
    case 'clearFilter': {
      const filter: EntityFilter = {...state.filter};
      action.filterNames.forEach((name) => {
        delete filter[name];
        if (filter.customFields) {
          delete filter.customFields[name];
        }
      });
      return {
        ...state,
        filter,
        filteredFields: getFilteredFieldsByFilter(filter, fields),
        page: 1,
      };
    }
    case 'setViewColumns': {
      const {viewColumns} = action;
      const {filter} = state;
      const stateToUpdate: ListState = {...state, viewColumns: new Set(viewColumns)};
      const filterToUpdate: EntityFilter = {
        includeCustomFields: viewColumns.some(({isCustomField}) => isCustomField),
        includeGroups: viewColumns.some(({name, isList}) => name === 'groups' && isList),
        includeNotes: viewColumns.some(({name, isList}) => name === 'notes' && isList),
      };
      if (
        filterToUpdate.includeCustomFields != filter.includeCustomFields
        || filterToUpdate.includeGroups != filter.includeGroups
        || filterToUpdate.includeNotes != filter.includeNotes
      ) {
        stateToUpdate.filter = filterToUpdate;
      }
      return stateToUpdate;
    }
    case 'setPage':
      return {...state, page: action.page};
    case 'setSorting':
      return {...state, sorting: action.sorting};
  }
  return state;
};

export const setFilter = (filter: EntityFilter): ParamsAction => ({type: 'setFilter', filter});
export const clearFilter = (filterNames: string[]): ParamsAction => ({type: 'clearFilter', filterNames});
export const setPage = (page: number): ParamsAction => ({type: 'setPage', page});
export const setSorting = (sorting: Sorting): ParamsAction => ({type: 'setSorting', sorting});
export const setViewColumns = (viewColumns: IField[]): ParamsAction => ({type: 'setViewColumns', viewColumns});
