import {all, fork} from 'redux-saga/effects';
import companySaga from './company/saga';
import leadSaga from './lead/saga';
import peopleSaga from './people/saga';
import errorsSaga from './errors/saga';
import integrationsSaga from './integrations/saga';
import notificationSaga from './notification/saga';
import globalSagas from './globalSagas';

function* rootSaga() {
  yield all([
    fork(companySaga),
    fork(errorsSaga),
    fork(globalSagas),
    fork(integrationsSaga),
    fork(leadSaga),
    fork(notificationSaga),
    fork(peopleSaga),
  ]);
}

export default rootSaga;
