import angular from 'angular';
import {NavbarController, navbar} from './navbar/navbar-component';
import {FindNewCustomersController, findNewCustomers} from './find-new-customers/find-new-customers-component';
import {AddEditFunnelModalController, addEditFunnelModal} from './add-edit-funnel-modal/add-edit-funnel-modal-component';
import {BulkEditController, bulkEdit} from './bulk-edit/bulk-edit-directive';
import {EditEntityFormController, editEntityForm} from './edit-entity-form/edit-entity-form-component';
import GroupsSectionService from './edit-entity-form/groups/groups-section-service';
import {GroupsSectionController, groupsSection} from './edit-entity-form/groups/groups-section-component';
import {ManageGroupsModalController, manageGroupsModal} from './edit-entity-form/manage-groups-modal/manage-groups-modal-component';
import {TeamShareGroupsController, teamShareGroups} from './team-share-groups/team-share-groups.directive';
import {SearchBarController, searchBar} from './search-bar/search-bar-component';
import {FiltersEditorController, filtersEditor} from './filters-editor/filters-editor-directive';
import {MultipleRecSameLocationController, multipleRecSameLocation} from './multiple-rec-same-location/multiple-rec-same-location-component';
import {LeadGenMutliSelectController, leadGenMultiSelect} from './lead-gen-multi-select/lead-gen-multi-select-directive';
import {EntityInputWithSearchController, entityInputWithSearch} from './entity-input-with-search/entity-input-with-search-directive';
import {TerritoriesMapFilterController, territoriesMapFilter} from './territories-map-filter/territories-map-filter-directive';
import {ActivityTypeFilterModalController, activityTypeFilterModal} from './activity-type-filter-modal/activity-type-filter-modal-directive';
import {CadenceModalController, cadenceModal} from './cadence-modal/cadence-modal-directive';
import {EntityDealsListController, entityDealsList} from './entity-deals-list/entity-deals-list-directive';
import {SelectFieldsModalController, selectFieldsModal} from './select-fields-modal/select-fields-modal-component';
import {CrmActivityTypeListController, crmActivityTypeList} from './crm-activity-type-list/crm-activity-type-list-component';
import {PreviewPaneController, previewPane} from './preview-pane/preview-pane-component';
import {CreateCompanyModalController, createCompanyModal} from './create-company-modal/create-company-modal-component';
import {CreateDealModalController, createDealModal} from './create-deal-modal/create-deal-modal-component';
import {CreateEditActivityModalController, createEditActivityModal} from './create-edit-activity-modal/create-edit-activity-modal-component';
import {CreatePersonModalController, createPersonModal} from './create-person-modal/create-person-modal-component';
import radioOption from './radio-option/radio-option-component';

export default angular.module('mapmycustomersApp.components', [])
  .controller('FindNewCustomersController', FindNewCustomersController)
  .component('findNewCustomers', findNewCustomers)
  .controller('BulkEditController', BulkEditController)
  .directive('bulkEdit', bulkEdit)
  .controller('LeadGenMutliSelectController', LeadGenMutliSelectController)
  .directive('leadGenMultiSelect', leadGenMultiSelect)
  .controller('EntityInputWithSearchController', EntityInputWithSearchController)
  .directive('entityInputWithSearch', entityInputWithSearch)
  .controller('AddEditFunnelModalController', AddEditFunnelModalController)
  .component('addEditFunnelModal', addEditFunnelModal)
  .controller('NavbarController', NavbarController)
  .component('navbar', navbar)
  .controller('EditEntityFormController', EditEntityFormController)
  .component('editEntityForm', editEntityForm)
  .factory('GroupsSectionService', GroupsSectionService)
  .controller('GroupsSectionController', GroupsSectionController)
  .component('groupsSection', groupsSection)
  .controller('ManageGroupsModalController', ManageGroupsModalController)
  .component('manageGroupsModal', manageGroupsModal)
  .controller('TeamShareGroupsController', TeamShareGroupsController)
  .directive('teamShareGroups', teamShareGroups)
  .controller('SearchBarController', SearchBarController)
  .component('searchBar', searchBar)
  .controller('FiltersEditorController', FiltersEditorController)
  .directive('filtersEditor', filtersEditor)
  .controller('MultipleRecSameLocationController', MultipleRecSameLocationController)
  .component('multipleRecSameLocation', multipleRecSameLocation)
  .controller('TerritoriesMapFilterController', TerritoriesMapFilterController)
  .directive('territoriesMapFilter', territoriesMapFilter)
  .controller('ActivityTypeFilterModalController', ActivityTypeFilterModalController)
  .directive('activityTypeFilterModal', activityTypeFilterModal)
  .controller('CadenceModalController', CadenceModalController)
  .directive('cadenceModal', cadenceModal)
  .controller('EntityDealsListController', EntityDealsListController)
  .directive('entityDealsList', entityDealsList)
  .controller('SelectFieldsModalController', SelectFieldsModalController)
  .component('selectFieldsModal', selectFieldsModal)
  .controller('CrmActivityTypeListController', CrmActivityTypeListController)
  .component('crmActivityTypeList', crmActivityTypeList)
  .controller('PreviewPaneController', PreviewPaneController)
  .component('previewPane', previewPane)
  .controller('CreateCompanyModalController', CreateCompanyModalController)
  .component('createCompanyModal', createCompanyModal)
  .controller('CreateDealModalController', CreateDealModalController)
  .component('createDealModal', createDealModal)
  .controller('CreateEditActivityModalController', CreateEditActivityModalController)
  .component('createEditActivityModal', createEditActivityModal)
  .controller('CreatePersonModalController', CreatePersonModalController)
  .component('createPersonModal', createPersonModal)
  .component('radioOption', radioOption)
  .name;
