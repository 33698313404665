import {createSlice} from '@reduxjs/toolkit';
import get from 'lodash/get';
import analyticsService from '../../shared-services/analytics-service';

export interface RoutingState {
  addRouteFromListView: string,
  creatingRoute: boolean,
  usersRoutes: Array<any>,
  routesForCustomer: Array<any>,
  currentRouteResponse: string,
  currentRouteCoord: Array<any>,
  currentRouteArrivals: Array<any>,
  currentRouteObjects: Array<any>,
  manualLocation: string,
  manualWelcomeModal: boolean,
  routeEditName: string,
  usedRoutePins: Array<any>,
  directionsBeingShown: boolean,
  totalRouteDistance: number | undefined,
  totalRouteDuration: number | undefined,
  isEditingRoute: boolean,
  optimizeRoute: boolean,
  smartRouting: boolean,
  smartRoutingDepartureTime: number,
  smartRoutingReturnTime: number,
  smartRoutingGroupSelected: string,
  smartRoutingCadence: number,
  smartRoutingStopInterval: number,
  smartRoutingBreakTime: number,
  timePerRouteStop: number,
  currentRouteViewing: Object,
  optimize: boolean,
  routeTypeSelected: string,
  currentRouteId: string,
  currentRoute: any,
  originalRoute: string,
  currentRouteName: string,
  newRouteName: string,
  routeName: string,
  showRoutesPins: boolean,
  startingLocation: string,
  endingLocation: string,
  endingLocationLatLng: string,
  startingLocationCoords: Array<any>,
  endingLocationCoords: Array<any>,
  routeObjects: Array<any>,
  endLocationPresent: boolean,
  fromChooseRoutes: boolean,
  fromEditData: boolean,
  fromEditForm: boolean,
  endingLocationFromEdit: boolean,
  selectedUserRoutes: Array<any>,
  editFlag: {
    details: boolean,
    contacts: boolean,
    rebuildRoute: boolean,
    accounts: boolean,
  },
  routeNameModal: boolean,
  manualQuestionsModal: boolean,
  startingAddress: string,
  endingAddress: string,
  initialRouteType: string,
  routeOp: string,
  smartLocationModal?: boolean,
  routeTypeModal?: boolean,
  smartWelcomeModal?: boolean,
  cadenceModal?: boolean,
  smartGroupsModal?: boolean,
  smartAreaModal?: boolean,
  smartVisitingModal?: boolean,
  currentObjects?: Array<any>
  allGroups?: any,

  [key: string]: RoutingState[keyof RoutingState];
}

const initialState = Object.freeze({
  addRouteFromListView: '',
  creatingRoute: false,
  usersRoutes: [],
  routesForCustomer: [],
  currentRouteResponse: '',
  currentRouteCoord: [],
  currentRouteArrivals: [],
  currentRouteObjects: [],
  manualLocation: '',
  manualWelcomeModal: false,
  routeEditName: '',
  usedRoutePins: [],
  directionsBeingShown: false,
  totalRouteDistance: undefined,
  totalRouteDuration: undefined,
  isEditingRoute: false,
  optimizeRoute: true,
  smartRouting: false,
  smartRoutingDepartureTime: 0,
  smartRoutingReturnTime: 0,
  smartRoutingGroupSelected: '',
  smartRoutingCadence: 0,
  smartRoutingStopInterval: 0,
  smartRoutingBreakTime: 0,
  timePerRouteStop: 0,
  currentRouteViewing: {},
  optimize: true,
  routeTypeSelected: '',
  currentRouteId: '',
  currentRoute: '',
  originalRoute: '',
  currentRouteName: '',
  newRouteName: '',
  routeName: '',
  showRoutesPins: true,
  startingLocation: '',
  endingLocation: '',
  endingLocationLatLng: '',
  startingLocationCoords: [],
  endingLocationCoords: [],
  routeObjects: [],
  endLocationPresent: false,
  fromChooseRoutes: false,
  fromEditData: false,
  fromEditForm: false,
  endingLocationFromEdit: false,
  selectedUserRoutes: [],
  editFlag: {
    details: false,
    contacts: false,
    rebuildRoute: false,
    accounts: false,
  },
  routeNameModal: false,
  manualQuestionsModal: false,
  startingAddress: '',
  endingAddress: '',
  initialRouteType: '',
  routeOp: '',
}) as RoutingState;

const slice = createSlice({
  name: 'routingService',
  initialState,
  reducers: {
    updateSmartRoutingGroupSelected(state: RoutingState, {payload: [smartRoutingGroupSelected]}) {
      state.smartRoutingGroupSelected = smartRoutingGroupSelected;
    },

    updateShowRoutesPins(state: RoutingState, {payload: [showRoutesPins]}) {
      state.showRoutesPins = showRoutesPins;
    },

    initRouting(state: RoutingState) {
      state.routeObjects = [];
      state.currentRouteObjects = [];
      state.startingLocation = '';
      state.endingLocation = '';
      state.startDate = '';
      state.startTime = '';
    },

    updateIsEditingRoute(state: RoutingState, {payload: [isEditingRoute]}) {
      state.isEditingRoute = isEditingRoute;
    },

    updateFromEditForm(state: RoutingState, {payload: [fromEditForm]}) {
      state.fromEditForm = fromEditForm;
    },

    updateCurrentRoute(state: RoutingState, {payload: [currentRoute]}) {
      state.currentRoute = currentRoute;
      state.timePerRouteStop = get(currentRoute, 'routeDetail.allottedTime') || state.timePerRouteStop || 0;
    },

    updateCurrentRouteObjects(state: RoutingState, {payload: [currentRouteObjects]}) {
      state.currentRouteObjects = currentRouteObjects;
    },

    updateRouteObjects(state: RoutingState, {payload: [routeObjects, forceUpdate]}) {
      if (!window.DataModel.routeObjectsSaved || forceUpdate) {
        window.DataModel.routeObjectsSaved = routeObjects;
      }
      state.routeObjects = routeObjects;
    },

    updateAddressDetails(state: RoutingState, {payload: [{startingAddress, endingAddress, routeTypeSelected}]}) {
      state.startingAddress = startingAddress;
      state.endingAddress = endingAddress;
      state.routeTypeSelected = routeTypeSelected;
    },

    updateStartingAddress(state: RoutingState, {payload: [startingAddress]}) {
      state.startingAddress = startingAddress;
    },

    updateFromEditData(state: RoutingState, {payload: [fromEditData]}) {
      state.fromEditData = fromEditData;
    },

    updateEditFlag(state: RoutingState, {payload: [details]}) {
      Object.assign(state.editFlag, details);
    },

    closeManualWelcomeModal(state: RoutingState) {
      state.manualWelcomeModal = false;
    },

    openManualWelcomeModal(state: RoutingState) {
      state.manualWelcomeModal = true;
    },

    selectOptimize(state: RoutingState, {payload: [optimize]}) {
      state.optimize = optimize;
    },

    closeSmartLocationPopup(state: RoutingState) {
      state.smartLocationModal = false;
    },

    openSmartLocationPopup(state: RoutingState) {
      state.smartLocationModal = true;
    },

    closeRouteTypeModal(state: RoutingState) {
      state.routeTypeModal = false;
    },

    closeRouteNameModal(state: RoutingState) {
      state.routeNameModal = false;
    },

    openRouteNameModal(state: RoutingState) {
      state.routeNameModal = true;
    },

    closeManualQuestionsModal(state: RoutingState) {
      state.manualQuestionsModal = false;
    },

    openManualQuestionsModal(state: RoutingState) {
      state.manualQuestionsModal = true;
    },

    closeSmartWelcomeModal(state: RoutingState) {
      state.smartWelcomeModal = false;
    },

    openSmartWelcomeModal(state: RoutingState) {
      state.smartWelcomeModal = true;
    },

    closeCadenceModal(state: RoutingState) {
      state.cadenceModal = false;
    },

    closeSmartGroupsModal(state: RoutingState) {
      state.smartGroupsModal = false;
    },

    openSmartGroupsModal(state: RoutingState) {
      state.smartGroupsModal = true;
    },

    closeSmartAreaModal(state: RoutingState) {
      state.smartAreaModal = false;
    },

    closeSmartVisitingModal(state: RoutingState) {
      state.smartVisitingModal = false;
    },

    openSmartVisitingModal(state: RoutingState) {
      state.smartVisitingModal = true;
    },

    editRoute(state: RoutingState, {payload: [route]}) {
      state.currentRouteId = route.id;
      // state.currentRouteObjects = (route.routeAccounts || route.routeContacts).map(({id}: {id: number}) => id);
      state.currentRoute = route;
      state.isEditingRoute = true;
    },

    updateOriginalRoute(state: RoutingState, {payload: [route]}) {
      state.originalRoute = route;
    },

    updateCreatingRoute(state: RoutingState, {payload: [creatingRoute]}) {
      state.creatingRoute = creatingRoute;
    },

    updateCurrentRouteId(state: RoutingState, {payload: [routeId]}) {
      state.currentRoute.id = routeId;
    },

    resetObjects(state: RoutingState) {
      state.currentObjects = [];
      state.routeObjects = [];
    },

    addRoute(state: RoutingState) {
      state.smartRoutingGroupSelected = '';
      state.startingAddress = '';
      state.endingAddress = '';
      state.currentRouteObjects = [];
      state.fromEditData = false;
      state.currentRouteViewing = [];
      state.smartRouting = false;
      state.initialRouteType = '';
      state.smartRoutingCadence = 0;
      state.smartRoutingStopInterval = 0;
      state.smartRoutingBreakTime = 0;
      state.smartRoutingDepartureTime = 0;
      state.smartRoutingReturnTime = 0;
      state.timePerRouteStop = 0;
      state.routeOp = '';
      state.routeName = '';
      state.currentRoute = '';
    },

    updateSmartRouting(state: RoutingState, {payload: [smartRoutingCadence, smartRoutingStopInterval, smartRoutingBreakTime]}) {
      state.smartRoutingCadence = smartRoutingCadence;
      state.smartRoutingStopInterval = smartRoutingStopInterval;
      state.smartRoutingBreakTime = smartRoutingBreakTime;
    },

    updateRouteName(state: RoutingState, {payload: [routeName]}) {
      state.routeName = routeName;
    },

    addObjectToCurrentRouteObjects(state: RoutingState, {payload: [objectId]}) {
      if (!(state.currentRouteObjects.includes(objectId))) {
        state.currentRouteObjects.push(objectId);
      }
    },

    addObjectToRouteObjects(state: RoutingState, {payload: [object]}) {
      if (state.routeObjects.filter(obj => obj.id === object.id).length === 0) {
        state.routeObjects.push(object);
      }
    },

    removeObjectFromCurrentRouteObjects(state: RoutingState, {payload: [index]}) {
      state.currentRouteObjects.splice(index, 1);
    },

    removeObjectFromRouteObjects(state: RoutingState, {payload: [index]}) {
      state.routeObjects.splice(index, 1);
    },

    addObjectToCurrentRouteObjectsAtIndex(state: RoutingState, {payload: [{index, object}]}) {
      const duplicateObjects = state.currentRouteObjects.filter((obj) => {
        if (typeof obj === 'object' && typeof object === 'object') {
          return obj.id === object.id;
        }
        return obj === object;
      });

      if (duplicateObjects.length === 0) {
        state.currentRouteObjects.splice(index, 0, object);
      }
    },

    addObjectToRouteObjectsAtIndex(state: RoutingState, {payload: [{index, object}]}) {
      if (state.routeObjects.filter(obj => obj.id === object.id).length === 0) {
        state.routeObjects.splice(index, 0, object);
      }
    },

    initRoutingController(state: RoutingState) {
      state.isEditingRoute = false;
      state.startingLocation = '';
      state.endingLocation = '';
      state.startDate = '';
      state.startTime = '';
    },

    editRouteName(state: RoutingState, {payload: [route]}) {
      state.currentRoute = route;
      state.currentRouteId = route.id;
      state.currentRouteName = route.name;
    },

    updateDirectionsBeingShown(state: RoutingState, {payload: [directionsBeingShown]}) {
      state.directionsBeingShown = directionsBeingShown;
    },

    updateFromChooseRoutes(state: RoutingState, {payload: [fromChooseRoutes]}) {
      state.fromChooseRoutes = fromChooseRoutes;
    },

    initSmartRouting(state: RoutingState) {
      state.timePerRouteStop = state.smartRoutingStopInterval;
      state.currentRouteArrivals = [];
    },

    updateTimePerRouteStop(state: RoutingState, {payload: [timePerRouteStop]}) {
      state.timePerRouteStop = timePerRouteStop;
    },

    addToCurrentRouteArrivals(state: RoutingState, {payload: [arrival]}) {
      state.currentRouteArrivals.push(arrival);
    },

    updateTotalRouteDistance(state: RoutingState, {payload: [totalRouteDistance]}) {
      state.totalRouteDistance = totalRouteDistance;
    },

    updateTotalRouteDuration(state: RoutingState, {payload: [totalRouteDuration]}) {
      state.totalRouteDuration = totalRouteDuration;
    },

    updateEndObject(state: RoutingState, {payload: [endObject]}) {
      const UTILS = window.mmcUtils as any;
      state.endingAddress = endObject.address || '';
      state.endingLocation = (endObject && endObject.geoPoint) ? UTILS.convertGeoPointToString(endObject.geoPoint) : '';
      state.endingLocationCoords = (endObject && endObject.geoPoint) ? [endObject.geoPoint.coordinates[1], endObject.geoPoint.coordinates[0]] : [];
    },

    endSmartRouting(state: RoutingState) {
      state.smartRouting = false;
    },

    closeRoute(state: RoutingState) {
      state.currentRouteObjects = [];
      state.fromEditData = false;
      state.endingAddress = '';

      // empty the previous routes data when clicked on the close button
      state.currentRouteViewing = [];
      state.smartRouting = false;
    },

    addRouteCoordToCurrentRouteCoord(state: RoutingState, {payload: [routeCoord]}) {
      state.currentRouteCoord.push(routeCoord);
    },

    updateCurrentRouteViewing(state: RoutingState, {payload: [routeViewing]}) {
      state.currentRouteViewing = routeViewing;
    },

    updateStartingLocationDetails(state: RoutingState, {payload: [startingLocation, startingLocationCoords]}) {
      state.startingLocation = startingLocation;
      state.startingLocationCoords = startingLocationCoords;
    },

    updateStartingLocationCoords(state: RoutingState, {payload: [startingLocationCoords]}) {
      state.startingLocationCoords = startingLocationCoords;
    },

    updateStartingLocation(state: RoutingState, {payload: [startingLocation]}) {
      state.startingLocation = startingLocation;
    },

    updateStartTime(state: RoutingState, {payload: [startTime]}) {
      state.startTime = startTime;
    },

    updateStartDate(state: RoutingState, {payload: [startDate]}) {
      state.startDate = startDate;
    },

    updateEndingLocationDetails(state: RoutingState, {payload: [endingLocation, endingLocationCoords]}) {
      state.endingLocation = endingLocation;
      state.endingLocationCoords = endingLocationCoords;
    },

    updateEndingLocationLatLng(state: RoutingState, {payload: [endingLocationLatLng, endingLocationCoords]}) {
      state.endingLocationLatLng = endingLocationLatLng;
      state.endingLocationCoords = endingLocationCoords;
    },

    updateEndingLocation(state: RoutingState, {payload: [endingLocation]}) {
      state.endingLocation = endingLocation;
    },

    updateRouteTypeSelected(state: RoutingState, {payload: [routeTypeSelected]}) {
      state.routeTypeSelected = routeTypeSelected;
    },

    updateCurrentRouteName(state: RoutingState, {payload: [routeName]}) {
      state.currentRoute.name = routeName;
    },

    editStartLocation(state: RoutingState, {payload: [startingLocation, fromEditData]}) {
      state.startingLocation = startingLocation;
      state.fromEditData = fromEditData;
    },

    chooseEndingLocation(state: RoutingState) {
      state.endingLocationFromEdit = true;
    },

    chooseRouteStyle(state: RoutingState, {payload: [optimize]}) {
      state.optimize = optimize;
      state.fromEditData = true;
    },

    showCadenceModal(state: RoutingState) {
      analyticsService.initiated('Cadence edit');
      state.smartWelcomeModal = false;
      state.cadenceModal = true;
    },

    showAreaModal(state: RoutingState) {
      state.cadenceModal = false;
      state.smartAreaModal = true;
    },

    updateAllGroups(state: RoutingState, {payload: [allGroups]}) {
      state.allGroups = allGroups;
    },

    buildSmartRoute(state: RoutingState) {
      state.smartRoutingDepartureTime *= 60;
      state.smartRoutingBreakTime *= 60;
      state.smartRoutingReturnTime *= 60;
      state.routeTypeSelected = 'optimized';

      // set map & reset any previous directions on map & reset values
      state.totalRouteDuration = 0;
      state.totalRouteDistance = 0;
      state.currentRouteResponse = '';

      state.smartRouting = true;
      state.optimize = true;
    },

    updateSmartRoutingTimes(state: RoutingState, {payload: [smartRoutingDepartureTime, smartRoutingReturnTime]}) {
      state.smartRoutingDepartureTime = smartRoutingDepartureTime;
      state.smartRoutingReturnTime = smartRoutingReturnTime;
    },

    removeEndingLocationFromEdit(state: RoutingState) {
      state.fromEditData = true;
      state.endingLocationFromEdit = false;
    },

    updateEndLocationPresent(state: RoutingState, {payload: [endLocationPresent]}) {
      state.endLocationPresent = endLocationPresent;
    },

    updateRoutesForCustomer(state: RoutingState, {payload: [routes]}) {
      state.routesForCustomer = routes;
    },

    updateUsersRoutes(state: RoutingState, {payload: [routes]}) {
      state.usersRoutes = routes;
    },

    cancelSmartRouting(state: RoutingState) {
      state.smartRouting = false;
    },

    updateInitialRouteType(state: RoutingState, {payload: [routeType]}) {
      state.initialRouteType = routeType;
    },

    updateRouteEditName(state: RoutingState, {payload: [routeEditName]}) {
      state.routeEditName = routeEditName;
    },

    updateRouteOp(state: RoutingState, {payload: [routeOp]}) {
      state.routeOp = routeOp;
    },
  },
});

export default slice;
