export const valueGetterUsingFunction = (fn) => (params) => fn(params.data);

export const valueGetterUsingPath = (path) => (Array.isArray(path) ? path.join('.') : path);

export const valueFormatterUsingFunction = (fn) => (params) => fn(params.data, params.value);

export const defaultTooltipValueGetter = (fieldName) => (params) => {
  if (params.valueFormatted !== undefined) {
    return params.valueFormatted;
  }
  if (params.value !== undefined) {
    return params.value;
  }
  return params.data[fieldName];
};
