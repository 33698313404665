import template from './manage-groups-modal.html';
import mapActions from '../../../store/store-helpers';
import accountsNetworkService from '../../../network-services/accounts-network-service';
import contactsNetworkService from '../../../network-services/contacts-network-service';
import dealsNetworkService from '../../../network-services/deals-network-service';

const fetchGroupsMethodByEntityType = Object.freeze({
  accounts: accountsNetworkService.fetchAllAccountGroups,
  contacts: contactsNetworkService.fetchAllContactGroups,
  deals: dealsNetworkService.fetchAllDealGroups,
});

class ManageGroupsModalController {
  constructor($scope, $timeout) {
    Object.assign(this, {
      $scope,
      $timeout,
    });
    mapActions(this, ['modals']);
  }

  $onInit() {
    this.selectedGroups = this.groups.reduce((result, {id}) => Object.assign(result, {[id]: true}), {});
    this.displayedGroups = this.availableGroups;
  }

  closeModal() {
    this.modalsActions.hideModal('manageGroupsModal');
  }

  saveSelectedGroups() {
    const idToGroupMap = new Map(this.availableGroups.map(group => [group.id, group]));
    const newGroups = Object.keys(this.selectedGroups)
      .filter(id => this.selectedGroups[id])
      .map(id => idToGroupMap.get(parseInt(id, 10)));
    this.onSaveGroups({groups: newGroups});
    this.$timeout(() => {
      this.closeModal();
    });
  }

  async groupSearch(searchText = '') {
    if (searchText && searchText.length > 2) {
      const response = await fetchGroupsMethodByEntityType[this.entityType]({name: {$in: searchText}});
      this.searchOptions = response.data;
      this.$scope.$applyAsync(() => {
        this.displayedGroups = this.searchOptions;
      });
    } else {
      this.searchOptions = [];
      this.$scope.$applyAsync(() => {
        this.displayedGroups = this.availableGroups;
      });
    }

    return this.searchOptions;
  }
}

ManageGroupsModalController.$inject = ['$scope', '$timeout'];

const manageGroupsModal = {
  bindings: {
    availableGroups: '<',
    entityType: '<',
    groups: '<',
    onSaveGroups: '&',
  },
  controller: 'ManageGroupsModalController',
  template,
};

export {ManageGroupsModalController, manageGroupsModal};
