import angular from 'angular';

export default angular.module('debounce', [])
  .service('debounce', ['$timeout', function ($timeout) {
    return function (func, wait, immediate, invokeApply) {
      let timeout; let context; let
        result;
      function debounce(...args) {
        /* jshint validthis:true */
        context = this;
        const later = function () {
          timeout = null;
          if (!immediate) {
            result = func.apply(context, args);
          }
        };
        const callNow = immediate && !timeout;
        if (timeout) {
          $timeout.cancel(timeout);
        }
        timeout = $timeout(later, wait, invokeApply);
        if (callNow) {
          result = func.apply(context, args);
        }
        return result;
      }
      debounce.cancel = function () {
        $timeout.cancel(timeout);
        timeout = null;
      };
      return debounce;
    };
  }])
  .directive('debounce', ['debounce', '$parse', function (debounce, $parse) {
    return {
      require: 'ngModel',
      priority: 999,
      link($scope, $element, $attrs, ngModelController) {
        const debounceDuration = $parse($attrs.debounce)($scope);
        const immediate = !!$parse($attrs.immediate)($scope);
        let debouncedValue; let
          pass;
        const prevRender = ngModelController.$render.bind(ngModelController);
        const commitSoon = debounce((viewValue) => {
          pass = true;
          ngModelController.$$lastCommittedViewValue = debouncedValue;
          ngModelController.$setViewValue(viewValue);
          pass = false;
        }, parseInt(debounceDuration, 10), immediate);
        ngModelController.$render = function () {
          prevRender();
          commitSoon.cancel();
          // we must be first parser for this to work properly,
          // so we have priority 999 so that we unshift into parsers last
          debouncedValue = this.$viewValue;
        };
        ngModelController.$parsers.unshift((value) => {
          if (pass) {
            debouncedValue = value;
            return value;
          }

          commitSoon(ngModelController.$viewValue);
          return debouncedValue;
        });
      },
    };
  }])
  .name;
