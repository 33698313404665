import React from 'react';
import {Button, Row, Col} from 'antd';
import styles from './Information.module.scss';
import safeLocalStorage from '../../../../../../shared-services/safe-local-storage-service';

const getNewAppUrl = (path: string): string => `${window.__env.baseNewAppUrl}${path}?mmc_token=${safeLocalStorage.accessToken}`;

interface Props {
  onHide: () => void
}

const Notifications: React.FC<Props> = ({onHide}) => {

  return (
    <div className={styles.container}>
      <p className={styles.title}>Notifications</p>
      <p className={styles.description}>We are unable to display notifications on this page.
        To review your notifications, click the button below:
      </p>
      <Row justify="end" gutter={7}>
        <Col>
          <Button
            className={styles.cancelButton}
            onClick={onHide}
          >
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            className={styles.reviewNotificationsButton}
            href={getNewAppUrl('/notifications')}
            target="_blank"
            type="primary"
          >
            Review Notifications
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Notifications;
