import BaseNetworkService from './base-network-service/base-network-service';
import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import Organization from '../react/type/Organization';
import ActivityType from '../react/type/ActivityType';
import ListResponse from '../react/type/service/ListResponse';

type OrgId = Organization['id'];
type ActivityTypeId = ActivityType['id'];

export default function CrmActivityTypesNetworkService() {
  return {
    getTypes(orgId: OrgId, params?: object): Promise<ListResponse<ActivityType>> {
      return BaseNetworkService.read(`organizations/${orgId}/crm-activity-types`, params);
    },
    createCrmActivityType(payload: object) {
      const orgId = safeLocalStorage.currentUser!.organization.id;
      return BaseNetworkService.create(`organizations/${orgId}/crm-activity-types`, payload);
    },
    updateCrmActivityType(payload: any) {
      const orgId = safeLocalStorage.currentUser!.organization.id;
      return BaseNetworkService.update(`organizations/${orgId}/crm-activity-types/${payload.id}`, payload);
    },
    deleteCrmActivityType(activityTypeId: ActivityTypeId) {
      const orgId = safeLocalStorage.currentUser!.organization.id;
      return BaseNetworkServiceWithPromises.delete(`organizations/${orgId}/crm-activity-types/${activityTypeId}`);
    },
  };
}
