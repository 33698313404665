import swapLatLng from './swapLatLng';
import GeoPoint from '../../type/GeoPoint';

const swapGeoPointCoordinates = <T extends {geoPoint?: GeoPoint | null}>(item: T) => (item.geoPoint
  ? {
    ...item,
    geoPoint: {...item.geoPoint, coordinates: swapLatLng(item.geoPoint.coordinates)},
  }
  : item
);

export default swapGeoPointCoordinates;
