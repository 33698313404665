import angular from 'angular';
import {AddLeadsFormController, addLeadsForm} from './add-leads-form/add-leads-form-component';
import {ConversionModalController, conversionModal} from './conversion-modal/conversion-modal-component';

export default angular.module('mapmycustomersApp.leads', [])
  .component('addLeadsForm', addLeadsForm)
  .controller('AddLeadsFormController', AddLeadsFormController)
  .component('conversionModal', conversionModal)
  .controller('ConversionModalController', ConversionModalController)
  .name;
