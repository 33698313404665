import RoutingService from '../store/routing-service/selector';
import Modals from '../store/modals/selector';
import TeamsServiceModel from './data-model/teams-service-model';
import ShowAttributes from './data-model/show-attributes';
import accountFieldModel from './field-model/account-field-model';
import leadFieldModel from './field-model/lead-field-model';
import personFieldModel from './field-model/contact-field-model';
import dealFieldModel from './field-model/deal-field-model';
import crmActivityFieldModel from './field-model/crm-activity-field-model';
import reportFieldModel from './field-model/report-field-model';
import {sortFields} from './field-model/utils';

/**
 * @param {EntityFieldModel} fieldModel
 * @returns {{show: string, type: string, value: string}[]}
 */
const getFilterFields = fieldModel => sortFields(fieldModel.filterFields).map(field => ({
  type: field.filter.type,
  value: field.filter.name || field.name,
  show: field.filter.displayName || field.displayName,
  getValues: field.filter.getValues,
  nameField: field.filter.nameField !== undefined ? field.filter.nameField : 'name',
}));

export default {
  env: {},

  // login screen
  loginErrorMessage: undefined,
  preventSwal: false,

  accountFieldModel,
  leadFieldModel,
  contactFieldModel: personFieldModel,
  dealFieldModel,
  crmActivityFieldModel,
  reportFieldModel,

  // contacts data structures
  contacts: [],
  customersRaw: [],
  customersExport: [],
  customersExportFilteredByGroup: [],
  customersListArray: [],
  customersListArrayBeforeSort: [],
  customersNearbyArray: [],
  customersListCopy: [],
  customersMissedImporting: [],
  customersCount: 0,
  tempFilteredCount: 0,
  timeCheck: 0,
  fromEditPage: false,
  showAddBtn: true,
  showFilterBtn: true,
  dirtyEditFlag: false,
  typeOfLocationUpdatedMostRecently: undefined,
  org: null,
  ownerId: null,
  roles: [],
  // start chain reaction fetch pages
  accountsPages: ['accountsMapPage', 'accountsPage', 'accountsRoutingMapPage'],
  contactsPages: ['contactsMapPage', 'contactsPage', 'contactsRoutingMapPage'],
  dealsPages: ['dealsMapPage', 'dealsPage'],
  dealStageColors: ['firstStage',
    'secondStage',
    'thirdStage',
    'fourthStage',
    'fifthStage',
    'sixthStage',
    'seventhStage',
    'eighthStage'],
  dealColorMapping: {
    firstStage: '#ff987c',
    secondStage: '#a46bd7',
    thirdStage: '#ff96b8',
    fourthStage: '#ff6767',
    fifthStage: '#3fc8b8',
    sixthStage: '#8fdeff',
    seventhStage: '#fffb9f',
    eighthStage: '#cfde68',
  },
  // cached state
  cachedState: {
    accounts: {
      user: '', page: 1, filters: [], column: 'updatedAt', ascending: false,
    },
    deals: {
      user: '', page: 1, filters: [], column: 'updatedAt', ascending: false,
    },
    contacts: {
      user: '', page: 1, filters: [], column: 'updatedAt', ascending: false,
    },
    accountsGroups: {
      user: '', page: 1, filters: [], column: 'updatedAt', ascending: false,
    },
    contactsGroups: {
      user: '', page: 1, filters: [], column: 'updatedAt', ascending: false,
    },
    dealsGroups: {
      user: '', page: 1, filters: [], column: 'updatedAt', ascending: false,
    },
    crmActivities: {
      user: '', page: 1, filters: [], column: 'startAt', ascending: false, crmActivityTypeIds: [], crmObjectType: 'all', status: 'incomplete',
    },
    reports: {
      user: '', page: 1, filters: [], column: 'updatedAt', ascending: false,
    },
    routes: {
      user: '', page: 1, filters: [], column: 'updatedAt', ascending: false,
    },
    territories: {
      user: '', page: 1, filters: [], column: 'updatedAt', ascending: true,
    },
  },

  currentCrmType: 'all',

  recordView: false,

  // map state cache variable
  currentMapState: {},
  // to not show stateless images while loading data
  initDataModel: false,

  // accounts data structures
  accountsRaw: [],
  accounts: [],
  accountsListSegment: [],
  accountsListArray: [],
  accountsCount: 0,

  // deals data structures
  dealsRaw: [],
  deals: [],
  dealsListSegment: [],
  dealsCount: 0,

  // notes data structures
  notesRaw: [],
  notesData: [],

  nonEnterprise: true,
  recordsUpperLimit: 10000,
  listPageRecordCount: 100,
  popupDataEnterprise: [],
  defaultPopupData: {},
  activitiesCount: 0,

  // modal variables
  selectedRecords: {},
  selectedEntities: [],
  selectedLeadGenRecords: [],

  // for showing loader
  show: new ShowAttributes(),

  // list view edit columns for list view pages
  listViewExcludedData: ['closingDate', 'accountName', 'createdAt', 'updatedAt', 'annual_revenue', 'num_employees',
    'numEmployees', 'remindAt', 'remindType', 'annualRevenue', '#employees', 'distance', 'type', 'region',
    'address', 'city', 'username', 'postalCode', 'color', 'country', 'crmActivityTypeId', 'viewAs'],
  listViewExcludedMapping: {
    closingDate: 'closing date',
    accountName: 'company name',
    createdAt: 'created at',
    updatedAt: 'updated at',
    annual_revenue: 'annual revenue',
    num_employees: 'num of employees',
    numEmployees: 'num of employees',
    remindAt: 'remind at',
    remindType: 'remind type',
    annualRevenue: 'annual revenue',
    '#employees': 'num of employees',
    type: 'Funnel',
    region: 'State/Province',
    address: 'Address',
    city: 'City',
    username: 'Username',
    postalCode: 'Postal Code',
    color: 'Color',
    country: 'Country',
    crmActivityTypeId: 'Activity type',
    viewAs: 'User',
  },
  listViewExcludeSorting: {
    accounts: ['username'],
    contacts: ['company', 'username'],
    deals: ['accountName', 'type', 'username', 'stage'],
  },
  listViewColumnToColumnData: {
    type: 'funnel.name',
    username: 'user.username',
    accountName: 'account.name',
    stage: 'stage.name',
  },
  listViewDateFields: ['closingDate', 'remindAt', 'time', 'Start At', 'Created At', 'Updated At',
    'Completed At', 'Remind At', 'Closing Date', 'Triggered At'],
  listViewNumFields: ['score', 'closingDate', 'amount'],
  defaultListViewAvailableCols: Object.freeze({
    accounts: ['numEmployees', 'color', 'city', 'region', 'country', 'postalCode', 'email', 'website', 'phone', 'username'],
    contacts: ['address', 'city', 'region', 'country', 'postalCode', 'color', 'username'],
    deals: ['amount', 'stage', 'accountName', 'type', 'closingDate', 'username', 'score'],
  }),
  listviewAvailableCols: {accounts: [], contacts: [], deals: []},
  listviewSelectedColumns: [],
  lvscBeforeSave: [],
  lvacBeforeSave: [],

  // page route ids
  mappingPages: ['mapPage', 'groupsPage', 'nearbyPage', 'routingPage', 'territoriesMapPage', 'dealsMapPage'],
  crmPages: ['contactsPage', 'accountsPage', 'dealsPage'],
  crmMapPages: ['contactsMapPage', 'accountsMapPage', 'dealsMapPage', 'routingMapPage'],
  teamsPages: ['teamsPage', 'myTeamPage', 'teamPage'],
  accountPages: ['myAccount', 'upgradePage', 'settingPages'],
  crmPagesToTableName: {accountsPage: 'accounts', contactsPage: 'contacts', dealsPage: 'deals'},

  // other common variables
  map: '',
  currentPageSubHeader: {
    title: 'All Companies', numberOfObjects: '0', addButtonName: 'Add', showFilterButton: true, showAddButton: true,
  },
  currentPageEditOrAddSubheader: {title: 'Add Person', addButtonName: 'Save Person', type: 'save'},
  info_window: '',
  markerWidth: 0,
  markerHeight: 0,
  skip: 0,
  othersPins: 0,
  customersDone: false,
  error: '',
  startingLocationPinLat: 0,
  startingLocationPinLng: 0,
  newCustomerLatitude: 0,
  newCustomerLongitude: 0,
  currentCustomerLat: 0,
  currentCustomerLng: 0,
  currentlat: 0,
  currentlng: 0,
  startingLocationPin: '',
  trialOver: false,
  paid: false,
  accountBlocked: false,
  team: '',
  teamOwner: false,
  teamDomain: '',
  fullHost: '',
  hostWhiteList: ['www', 'mapmycustomers', 'localhost', '159', 'beta', 'staging', '10', 'mmc-prod'],
  sharedGroupsAccounts: [],
  sharedGroupsContacts: [],
  sharedGroupsDeals: [],
  sharedGroups: [],
  sharedRoutes: [],
  manager: false,
  teamOwnerUsername: '',
  accessRightsContacts: {
    canEdit: true, canDelete: true, canExport: true, canEditGroups: true,
  },
  accessRightsAccounts: {
    canEdit: true, canDelete: true, canExport: true, canEditGroups: true,
  },
  accessRightsDeals: {
    canEdit: true, canDelete: true, canExport: true, canEditGroups: true,
  },
  accessRightsRoutes: {canEdit: true, canDelete: true, canExport: true},
  accessRightsTerritories: {canEdit: true, canDelete: true, canExport: true},
  teamMembers: [],
  teamObjectId: '',
  sendTeamNotifications: true,
  cameFromLocalSearch: false,
  localCustomersFound: [],
  currentLocalMarkers: [],
  currentTime: Date.now() / 1000,
  selectedPageGroups: {},
  territories: [],
  selectedTerrs: [],
  territoryCount: 0,
  numTerrs: 0,
  bounds: '',
  leadName: '',
  pitchedName: '',
  qualifiedName: '',
  lostName: '',
  wonName: '',
  stage6: '',
  stage7: '',
  stage8: '',
  groupsWithoutLeadStages: [],
  newMarker: '',
  customersOverlay: [],
  mcOptions: {maxZoom: 12, minimumClusterSize: 10, imagePath: 'images/m'},
  markerCluster: '',
  searchString: '',
  currentSearchString: '',
  showHidePinTracking: {
    yes: {},
    no: {},
  },
  currentLeadsView: undefined,
  currentActivityStatus: 'incomplete',
  emailSent: [],
  numberOfResults: 0,
  sorted: false,
  stars: [],
  activityPageArray: [],
  paginationBlocks: [],
  currentSkipNumber: 0,
  searchResults: [],
  currentPage: '',
  currentPath: '',
  currentSubPage: '',
  filteredGroups: [],
  filterString: '',
  currentlyActiveFilter: '',
  singleGroupPageFilter: '',
  pinsAlreadyInRoutes: [],
  circle: '',
  findCustomersCircle: '',
  allLeadsCopy: [],
  allLeads: [],
  allLeadsExport: [],
  allLeadStages: [],
  allLeadStagesInOrder: [],
  teamMemberMarkers: [],
  overlapCount: 0,
  updates: [],
  refreshing: false,
  scrollHeight: 0,
  refreshTerritories: false,
  poly: '',
  preImportCSVRefresh: false,
  matchFileImportFields: '',
  distanceUnit: 'miles',
  groupShownStatusSet: {},
  lastPinLat: 0,
  lastPinLng: 0,
  selectedPinId: '',
  selectedPinObject: {},
  rating: {current: 0, max: 5},
  currentPhoto: {},
  lastScrollPoint: 0,
  isEditingNote: false,
  currentCrmObjectId: '',
  currentCrmObjectType: '',
  currentCRMObject: {},
  currentCustomFieldObjectId: '',
  previousZoom: 4,
  users: '',
  previousCenter: '',
  availableImportFields: [],
  searchRadius: 150,
  searchText: '',
  searchGroupText: '',
  searchFocused: false,
  searchGroupFocussed: true,
  routingSearchText: '',
  searchTextEditPopup: '',
  allNotesForCustomersFromNotesTable: [],
  productTour: {},
  calendar_auth_object: [],
  password: {showModal: false},
  editPopup: {
    tableHeaderData: [], tableRowData: [], addButtonName: '', tableRowName: [], initialData: [],
  },
  currentCrmObjectChildAccountsData: [],
  currentCrmObjectContactsData: [],
  currentCrmObjectDealsData: [],
  currentAssociatedAccountId: undefined,
  currentAssociatedContactId: '',
  prevAssociatedAccountId: undefined,
  prevAssociatedContactId: '',

  currentPageHiddenGroups: [],
  currentPageGroupsColors: [],
  searchOptions: [],
  routingSearchOptions: [],
  searchCounter: 0,
  showSearchBar: false,

  activities: [],
  platformActivities: [],
  platformActivitiesCount: 0,

  // pagination
  currentPageNum: 1,
  currentPageNumEnt: 1,
  pageSize: 100,
  totalCount: '',
  hideMessage: false,
  currentPageRecordCount: 0,

  editCrmObjectMap: [],
  editCrmObjectMarker: '',
  currentCustomerGeoCodingData: {
    address: '', city: '', state: '', zip: '', country: '',
  },

  quickCreateCustomFields: [],
  quickCreateCustomFieldValues: {},
  quickCreateCustomFieldValidationErrors: {},
  quickCreateEntityType: undefined,

  colorsToHex: {
    black: '#464646',
    green: '#32CD32',
    blue: '#4A90E2',
    grey: '#ACACAC',
    orange: '#FF6F1B',
    pink: '#FF6F9C',
    purple: '#B549A2',
    red: '#FF5D6C',
    teal: '#008080',
    yellow: '#FFA700',
    'neon red': '#FF0000',
    'neon yellow': '#F3F315',
    'neon green': '#AEFF14',
    'neon blue': '#4D4DFF',
    'neon purple': '#EA6FFF',
    lavender: '#E6E6FF',
    violet: '#951CE4',
    turquoise: '#40E0D0',
    peach: '#FFDAB9',
    navy: '#000080',
    olive: '#556B2F',
    cyan: '#00FFFF',
    gold: '#DAA520',
    maroon: '#AA133F',
    brown: '#CB0303',
    white: '#ffffff',
  },

  AddEditService: {
    editPopupView: '',
    showAddAccountPop: false,
    addEditView: '',
    showDealLossReasonsPopup: false,
    dealLossReasonShowMenu: false,
    dealLossReasonNewReason: false,
    dealLossReasonNewReasonName: '',
  },
  AllContactsService: {
    contactKeys: ['objectId',
      'username',
      'name',
      'address',
      'city',
      'state',
      'zip',
      'country',
      'phone',
      'email',
      'color',
      'groups',
      'groupsArray',
      'groupsExport',
      'dealAmount',
      'leadStatus',
      'leadScore',
      'notes',
      'latitude',
      'longitude',
      'searchText',
      'lastVisit',
      'numVisits',
      'remindAt',
      'remindType',
      'updatedAt',
      'createdAt',
      'custom0',
      'custom1',
      'custom2',
      'custom3',
      'custom4',
      'custom5',
      'custom6',
      'custom7',
      'custom8',
      'custom9',
      'custom10',
      'custom11',
      'custom12',
      'custom13',
      'custom14',
      'custom15',
      'custom16',
      'custom17',
      'custom18',
      'custom19',
      'custom20',
      'custom21',
      'custom22',
      'custom23',
      'custom24',
      'custom25',
      'custom26',
      'custom27',
      'custom28',
      'custom29',
      'outlookid',
      'outlookFolderName',
      'stage',
      'type',
    ],
    allBoxesChecked: false,
    selectedContacts: [],
    deleteAllCheckbox: '',
    deleteAllCheckboxPages: [],
  },

  BulkLeadsService: {
    sampleLeadsJSON: [],

  },

  CustomRequestService: {
    // if the team owner username is present in this array -> user tracks will not be shown
    removeUserTrackUsername: ['dauret@wesco.com'],
  },
  CustomFields: {
    textField: '',
    textPlaceHolder: '',
    CFTypeMappings: {
      text: 'Text',
      'large-text': 'Large Text',
      decimal: 'Numerical',
      monetary: 'Monetary',
      'multi-option': 'Multiple Option',
      'single-option': 'Single Option',
      phone: 'Phone',
      date: 'Date',
      time: 'Time',
      address: 'Address',
    },
    currencies: [],
    placeholderFields: ['text', 'large-text'],
    options: [],
    multiOptionFields: ['single-option', 'multi-option'],
    required: false,
  },

  FunnelService: {
    modalStages: [],
    dealsWon: 0,
    dealsLost: 0,
    totalDealAmount: 0,
    adjustedDealAmount: 0,
    totalDealCount: 0,
    currentLeadsTab: '',
    showingLeadsMap: false,
    showingPinMap: false,
    totalDeals: 0,
    leadsMap: '',
    heatmap: '',
    pinMap: '',
    funnelChart: '',
    allPins: [],
    allCircles: [],
    leadCirclesMin: 0,
    leadCirclesMax: 0,
    mapName: '',
    dealType: [],
    showingStageTab: true,
    stageStatus: {
      leadName: false,
      pitchedName: false,
      qualifiedName: false,
      lostName: false,
      wonName: false,
      stage6: false,
      stage7: false,
      stage8: false,
    },
    deletedStages: [],
    selectedFunnels: {},
    funnelObjectIds: [],
    dealColors: ['#ff987c', '#a46bd7', '#ff96b8', '#ff6767', '#3fc8b8', '#8fdeff', '#fffb9f', '#cfde68'],
    funnelHeader: 'Add Funnel',
    addEditFunnelName: '',
    addEditFunnelOwnedByTeamId: '',
    currFunnelId: '',
    stageHeader: 'Add Stage',
    stageTitle: 'New Name',
    addEditStageName: '',
    addEditStageProb: 0,
    oldStageName: '',
    funnelArray: [],
    delStageFunnel: '',
    delStageFunnelName: '',
    delStageFunnelId: '',
    delFunnelId: '',
    delFunnelName: '',
    delStageTypeCount: 0,
    delFunnelView: {},
    addEditFunnelId: '',
  },

  FilterService: {
    // types are for the comparator model
    categoryModel: {
      accounts: getFilterFields(accountFieldModel),
      contacts: getFilterFields(personFieldModel),
      deals: getFilterFields(dealFieldModel),
      notes: {
        'Note Owner': 'type1',
        'Created At': 'type3',
        Note: 'type1',
        Time: 'type3',
      },
      crmActivities: getFilterFields(crmActivityFieldModel),
    },
    parseOpString: {
      'greater than': '$gt',
      'less than': '$lt',
      contains: '$in',
      'does not equal': '$ne',
      'starts with': '$sw',
      'pins in any': '$any',
      'pins in all': '$all',
      'is after': '$gt',
      'is before': '$lt',
      in: '$in',
      'not in': '$nin',
    },
    nearbyCircle: '',
    currentCategorySelected: {},
    currentComparatorSelected: '',
    selectedIndividualGroupFilter: '',
    currentPageCount: 0,
    currentCategoryModel: {},
    currentSelectedGroups: [],
    currentComparatorModel: [],
    comparatorModel: {
      type1: ['contains', 'equals', 'does not equal', 'exists', 'does not exist', 'starts with'],
      type2: ['greater than', 'less than', 'equals'],
      type3: ['is after', 'is before'],
      type4: ['exists', 'does not exist'],
      type_multiselect: ['in', 'not in'],
      type_singleselect: ['equals', 'does not equal'],
    },
    filtersSelected: [],
    appliedFilters: {},
    groupsFilterModel: [],
    filterNearbyLatLng: {lat: '', lng: ''},
    filterNearbyLocation: '',
    filterMaxMiles: 0,
    filterCurrentMiles: 0,
    filterActive: false,
    parseFilterQuery: {},
    filterSlider: '',
    groupsFilterOptions: 'pins in any',
    selectedFilterTable: '',
  },

  GroupsService: {
    currentGroupsPage: '',
    currentGroup: null,
    showSetColorModal: false,
    showEditGroupModal: false,
    showAddGroupModal: false,
    showCadenceModal: false,
    showDeleteGroupModal: false,
    showCadenceLegend: false,
    showActivityTypeFilter: false,
    newGroupColor: '',
    newGroupName: '',
    currentGroupCadence: '',
    currentPageGroups: [],
    currentPageGroupsCount: 0,
    totalGroupsCount: 0,
    pinsInGroup: [],
    selectedGroups: [],
    toggleAllGroups: false,
    showFooterDetails: false,
    currentPageEndpoint: '',
    currentPageSelectedGroupsEndpoint: '',
  },

  IntegrationsService: {
    highlightIntegHeader: [],
    integrationsDictionary: {
      Salesforce: {
        infoArray: ['The Salesforce sync will run once every 24 hours. Contact us for real-time syncing.',
          'You will be able to select custom objects and associated fields from Salesforce and map them to any fields in Map My Customers.',
          '5,000 contacts will be synced per day. For a higher limit, please contact sales@mapmycustomers.me.',
        ],
        parseTable: 'salesforce_auth_data',
        integrationTable: [],
        integrationFields: [],
        alreadyConnected: false,
        integrationProcessCompleted: false,
        objectId: '',
        specialOptions: 'If you want to map to groups in MMC, use the lead stages field from Salesforce.',
      },
      Quickbooks: {
        infoArray: ['The Quickbooks sync will run once every 24 hours. Contact us for real-time syncing.',
          'New pins will be imported into Map My Customers based on Billing Address info and Contacts.',
          'Data sent from QuickBooks will include: free form phone number, display name, company name, billing address, billing city, billing subdivision code, billing postal code, and alternate phone number.',
          '5,000 contacts will be synced per day. For a higher limit, please contact sales@mapmycustomers.me.',
        ],
        parseTable: 'quickbooks_auth_data',
        integrationTable: [],
        integrationFields: [],
        alreadyConnected: false,
        objectId: '',
      },
      Zoho: {
        infoArray: ['The Zoho sync will run once every 24 hours. Contact us for real-time syncing.',
          'The integration will sync your leads from the Zoho leads table (including address info, contact info, etc.) into your MMC account.',
          'The integration works as a two-way sync (pulls leads from Zoho into MMC and new leads from MMC into Zoho).',
          'Currently we only support syncing of the leads table from Zoho.',
          '5,000 contacts will be synced per day. For a higher limit, please contact sales@mapmycustomers.me.',
        ],
        parseTable: 'zoho_auth_data',
        integrationTable: [],
        integrationFields: [],
        alreadyConnected: false,
        objectId: '',
        timezone: '',
      },
      Hubspot: {
        infoArray: ['The Hubspot sync will run once every 24 hours. Contact us for real-time syncing.',
          'The integration will sync your contacts from Hubspot (including address info, contact info, etc.) into your MMC account.',
          'The integration works as a two-way sync (pull contacts from Hubspot into MMC and new contacts from MMC into Hubspot).',
          'Currently we only support syncing of the contacts table from Hubspot.',
          '5,000 contacts will be synced per day. For a higher limit, please contact sales@mapmycustomers.me.',
        ],
        parseTable: 'hubspot_auth_data',
        integrationTable: [],
        integrationFields: [],
        alreadyConnected: false,
        objectId: '',
      },
      Infusionsoft: {
        infoArray: ['The Infusionsoft sync will run once every 24 hours. Contact us for real-time syncing.',
          'The integration will sync your contacts from Infusionsoft (including address info, contact info, etc.) into your MMC account.',
          'The integration works as a two-way sync (pulls contacts from Infusionsoft into MMC and new contacts from MMC into Infusionsoft).',
          'Currently we only support syncing of the contacts table from Infusionsoft.',
          '5,000 contacts will be synced per day. For a higher limit, please contact sales@mapmycustomers.me.',
        ],
        parseTable: 'infusionsoft_auth_data',
        integrationTable: [],
        integrationFields: [],
        alreadyConnected: false,
        objectId: '',
      },
      Dynamics: {
        infoArray: ['The Dynamics sync will run once every 24 hours. Contact us for real-time syncing.',
          'The integration will sync your contacts from Dynamics (including address info, contact info, etc.) into your MMC account.',
          'The integration works as a two-way sync (pulls contacts from Dynamics into MMC and new contacts from MMC into Dynamics).',
          '5,000 contacts will be synced per day. For a higher limit, please contact sales@mapmycustomers.me.',
        ],
        parseTable: 'dynamics_auth_data',
        integrationTable: [],
        integrationFields: [],
        alreadyConnected: false,
        integrationProcessCompleted: false,
        objectId: '',
      },

    },

    selectedPlatformName: '',
    selectedSyncType: 'one_way',
    selectedTable: '',
    integrationAuthCheckComplete: false,

  },

  ImportService: {
    currentImportStep: 0,

    importDataArray: {
      matchedColumns: [],
      unmatchedColumns: [],
    },

    availableColumns: [],
    availableColumnsMap: {},

    updateExistingCustomers: 0,
    geoManagementState: 'automaticPreserveAddress',

    spreadsheetName: '',
    spreadsheetHeaders: [],
    spreadsheetPreview: [],

    pinsLeft: '0 / 0',
    timeRemaining: '00:00',
    importProcessRunning: false,
    runningImportId: undefined,
    importHistory: [],
    errorsWarningsImport: undefined,

    crmView: 'accounts',
    uniqueColumn: undefined,

    accountCount: undefined,
    contactCount: undefined,
    dealCount: undefined,
    recordCount: undefined,

    creatingNewColumnForMatchedColumnIndex: undefined,
    creatingNewColumnForUnmatchedColumnIndex: undefined,
    newColumnNameForMatchedColumn: '',
    newColumnNameForUnmatchedColumn: '',

    defaultKnownColumns: {
      accounts: [
        {key: 'name', label: 'Company Name', required: true},
        {key: 'phone', label: 'Phone'},
        {key: 'email', label: 'Email'},
        {key: 'color', label: 'Color'},
        {key: 'address', label: 'Street Address'},
        {key: 'city', label: 'City'},
        {key: 'region', label: 'State/Province'},
        {key: 'postalCode', label: 'Postal Code'},
        {key: 'country', label: 'Country', required: true},
        {key: 'groups', label: 'Groups'},
        {key: 'notes', label: 'Notes'},
        {key: 'website', label: 'Website'},
        {key: 'numEmployees', label: '#Employees'},
        {key: 'parentAccount', label: 'Parent Company Unique ID'},
        {key: 'annualRevenue', label: 'Annual Revenue'},
        {key: 'latitude', label: 'Latitude', requiresIfSet: ['longitude']},
        {key: 'longitude', label: 'Longitude', requiresIfSet: ['latitude']},
      ],
      contacts: [
        {key: 'firstName', label: 'First Name', requiredIfEmpty: ['lastName'], invalidIfSet: ['name']},
        {key: 'lastName', label: 'Last Name', requiredIfEmpty: ['firstName'], invalidIfSet: ['name']},
        {key: 'name', label: 'Full Name', requiredIfEmpty: ['firstName', 'lastName'], invalidIfSet: ['firstName', 'lastName']},
        {key: 'address', label: 'Street Address'},
        {key: 'region', label: 'State/Province'},
        {key: 'postalCode', label: 'Postal Code'},
        {key: 'country', label: 'Country', required: true},
        {key: 'phone', label: 'Phone'},
        {key: 'email', label: 'Email'},
        {key: 'color', label: 'Color'},
        {key: 'city', label: 'City'},
        {key: 'groups', label: 'Groups'},
        {key: 'notes', label: 'Notes'},
        {key: 'parentAccount', label: 'Related Company Unique ID'},
        {key: 'latitude', label: 'Latitude', requiresIfSet: ['longitude']},
        {key: 'longitude', label: 'Longitude', requiresIfSet: ['latitude']},
      ],
      deals: [
        {key: 'name', label: 'Deal Name', required: true},
        {key: 'funnel', label: 'Funnel', required: true},
        {key: 'score', label: 'Deal Score'},
        {key: 'stage', label: 'Deal Stage', required: true},
        {key: 'amount', label: 'Deal Amount'},
        {key: 'closingDate', label: 'Closing Date'},
        {key: 'groups', label: 'Groups'},
        {key: 'notes', label: 'Notes'},
        {key: 'parentAccount', label: 'Related Company Unique ID', required: true},
        {key: 'contact', label: 'Related Person Unique ID'},
        {key: 'dealLossReason', label: 'Deal Loss Reason', required: true},
        {key: 'dealLossComment', label: 'Deal Loss Comment', required: true},
      ],
    },
  },

  LassoService: {
    showingObjectIds: [],
    selectedValues: '',
    selectedColor: '',
    lassoPolygonContainer: '',
    lassoApplied: false,
  },
  smartRouting: {},
  manualRouting: {},

  // vars for mapping service
  MappingService: {
    newRoute: [],
    editTerr: false,
    pinDir: '',
    pinDirCheckIn: '',
    findNewCustomers: false,
    previousPin: '',
    previousPinColor: '',
    popovers: [],
    clickingPin: false,
    closePopOverClicked: false,
    creatingOrEditingRoute: false,
    currentCustomerObjects: [],
    currentPinPhotos: [],
    currentColor: 'black',
    currentGroup: [],
    searchStartingLocationPin: [],
    currentSearchStartingPin: [],
    savedSearches: [],
    recentSearches: [],
    currentSearchAddress: '',
    currentCompanyName: '',
    currentStreet: '',
    currentState: '',
    currentCity: '',
    currentZip: '',
    currentUsername: '',
    newGroupName: '',
    currentCustomersNotes: [],
    recordNames: {},
    globalCustomersFound: [],
    groupCustomers: [],
    showMapTools: true,
    toggleLassoRouteOn: true,
    addingFieldFromDom: true,
    toggleCadence: false,
    overdue: false,
    currentPopOverData: '',
    pinData: [],
    lassoedPins: [],
    findCustomersSearchRadius: 10,
    multiSelectPage: 'contacts',
    leadGenPages: ['accountsMapPage', 'accountsGroupsMapPage', 'accountsRoutingMapPage'],
    showOtherPins: false,
    defaultFields: [
      'Person Name',
      'Company Name',
      'Street Address',
      'City',
      'State/Province',
      'Postal Code',
      'Country',
      'Phone',
      'Email',
      'Notes',
      'Color',
      'Groups',
      'Lead Status',
      'Deal Amount',
      'Number of Visits',
      'Lead Score',
      'Last Visit',
    ],
    currentColumn: 0,
    currentPinSearchText: '',
    importingRightNow: false,
    importingFromOnboard: false,
    selectedImportFields: [],
    importHeaders: [],
    availableImportFields: [],
    importSampleData: {},
    unprocessedCSV: {},
    currentGroupSelected: '',
    lassoOptions: {},
    lassoLatLng: [],
    allowImportCustomersAsContacts: true,
    useGeocodedAddresses: true,
    updateExistingCustomerInfo: false,
    autoGroupOption: '',
    addedCount: 0,
    cancelledImport: false,
    parsedCSV: '',
    importTimeInterval: 640,
    customersAdded: 0,
    addedToContacts: 0,
    duplicates: 0,
    tempNotesImportStorage: {},
    importedPinUniqueIdentifiers: [],
    numRows: 0,
    importedCSV: [],
    csvData: '',
    beenWarned: false,
    heatMapEnabled: false,
    circlesEnabled: false,
    heatmap: '',
    groupsSortedABC: false,
    currentGroupColor: '',
    leadsSortedAsc: false,
    leadsSortedDateAsc: false,
    leadScoreSortedAsc: false,
    distanceSortToggle: false,
    allCircles: [],
    oldestVisit: 0,
    oldestVisitInDays: 365, // skipping leap year for now
    currentSearchTerms: [],
    currentSearchTermColor: [],
    searchTermColors: {},
    colorMapping: {
      blue: '#4a90e2',
      yellow: '#ffa700',
      grey: '#acacac',
      maroon: '#aa133f',
      green: '#32cd32',
      orange: '#ff6f1b',
      pink: '#ff6f9c',
      red: '#ff0000',
      purple: '#b549a2',
      black: '#464646',
    },
    showLeadGenMultipleSelect: false,
    leadGenMultiRecordsSaved: false,
    showTraffic: false,
    onChangeMapView: false,
    radiusFilterMultiplier: 1.2,
    meterToMile: 1609.34,
    previousCenter: false,
    previousRadius: 0,
    entitiesAreCovered: false,
    coveredMapRadiusFilter: false,
  },

  CrmActivitiesService: {
    calendarViewStartDate: '',
    calendarViewEndDate: '',
    showAddCrmActivityTypeModal: false,

    showSelectCalendarPopup: false,
    nylasInfo: undefined,
    calendarName: undefined,
    syncedTypes: [],
    calendars: [],
    selectedTypes: [],
  },

  // vars for routing service
  RoutingService: new RoutingService(),

  ReportsService: {
    currentFieldModel: undefined,
    isReportEditingVisible: false,
    allReports: [],
    allReportsCount: 0,
    currentReports: [],
    selectedFolder: 'all',
    selectedReportView: 'all',
    createReportView: '',
    filters: {},
    selectedColumns: [],
    currentSelectedReportId: '',
    selectedReportIds: [],
    reportsData: [],
    reportsDataCount: 0,
    createOrEditReport: 'Create Report',
    reportName: '',
    reportDescription: '',
  },

  SettingsService: {
    customersLookupObject: {},
    exportNoteAttachmentCount: {
      attach: 0, not: 0, nope: {}, yup: {},
    },
    markerIcon: 'pin',
    markerSize: 'small',
    scrollEnabled: true,
    sendTeamNotifications: null,
    logo: null,
    sendReportCard: null,
    sendOverdueActivityEmail: null,
    automaticActivities: null,
    activityAutomationSchedule: null,
    activityAutomationRule: null,
    activityAutomationType: null,
    showLogoPhotoModal: false,
    autoVisits: null,
    totalRecordCount: 0,
    totalGroupsCount: 0,
    totalTerritoryCount: 0,
  },

  TeamsService: new TeamsServiceModel(),

  TerritoriesService: {
    addEditPopupMode: undefined, // undefined, "add", "edit"
    territoryName: undefined,
    showEditColorPopup: false,
    territoryColor: undefined,
    territoryOpacity: undefined,
    opacitySlider: undefined,
    mapUpdateMessageBarVisible: false,
    originalVisibility: undefined,
    poly: undefined,
    currentTerrId: undefined,
    showOtherPins: true,
    showTerritories: true,
    createTerritoryOption: undefined,
    showEditZipsPopup: false,
    territoryZips: '',

    terrbounds: '',
    clearedTerr: false,
    terrListener: '',
    addedNewTerr: false,
    currentTerritoryIndex: 0,
    clickingPin: false,
    populateDeleteTotal: 0,
    populateDeleteCount: 0,
    deleteSkip: 0,
    populatePinsDeletion: [],
    currentTerritory: '',
    editedTerrByDragging: false,
    editedTerritoryMarkers: [],
    currentTerrColor: '',
    currentTerrOpacity: 0.3,
    territoryMarkers: [],
    polyOrigPath: '',
    isEditingTerr: false,
    newTerrName: '',
    addTerritoriesFromListView: false,
    currentTerritoryName: '',
    editBoundsOption: false,
    editTerrIndex: false,
    visibleTerr: 0,
    selectedUserTerritories: [],
  },
  modals: new Modals(),
  statusEnum: [
    {id: 'overdue', name: 'overdue'},
    {id: 'upcoming', name: 'upcoming'},
    {id: 'completed', name: 'completed'},
  ],
  isFreeTrial: false,
  trialDaysRemaining: 0,
  trialExpired: false,
  leadToConvert: false,

  NavBar: {
    entityId: undefined,
    entityType: undefined,
    showCreateCompanyModal: false,
    showCreateDealModal: false,
    showCreateEditActivityModal: false,
    showCreatePersonModal: false,
    editActivityModalActivityId: undefined,
  },
  traits: {},
  routeObjectsSaved: undefined,
  geocodingOrgLimit: 0,
  geocodingOrgLimitReached: false,
  geocodingMmcLimitReached: false,
};
