import mapActions from '../../store/store-helpers';
import analyticsService from '../../shared-services/analytics-service';
import helperService from '../../shared-services/helper-service';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';

export default function TerritoriesCtrl(
  $scope, BaseController, TeamService, MainService, TerritoriesService,
) {
  // init global utility functions
  const UTILS = window.mmcUtils;
  const MODEL = window.DataModel;

  // extend BaseController methods onto scope
  Object.assign($scope, BaseController);
  mapActions($scope, ['modals']);
  $scope.modalsActions.resetVisibility('noTerritoriesModal');

  $scope.localStorage = safeLocalStorage;
  $scope.data = MODEL;
  $scope.currentState = MODEL.cachedState.territories;

  $scope.showViewByUser = UTILS.setViewByUserBasedOnRole(safeLocalStorage);

  const initPage = async () => {
    try {
      await MainService.startChainReaction();
      MODEL.currentSubPage = 'list';
      $('#map').hide();
      window.refreshDom({loader: true}, 'show');

      await (MODEL.TeamsService.verifiedTeamMembers.length ? Promise.resolve() : TeamService.getOrgDetails())
        .then(() => {
          $scope.setLeadsView();
        });

      await $scope.fetchTerritories();
    } catch (error) {
      window.refreshDom({loader: true}, 'show');
      helperService.showAndLogError(error);
    }
  };

  $scope.fetchTerritories = async () => {
    window.refreshDom({loader: true}, 'show');

    const filters = {};
    if ($scope.currentState.user) {
      filters.viewAs = $scope.currentState.user;
    }

    const {territories, territoryCount} = await TerritoriesService.fetchTerritories(
      filters,
      $scope.currentState.page,
      MODEL.pageSize,
      $scope.currentState.column,
      $scope.currentState.ascending,
    );

    await window.refreshDom({territories, territoryCount});
    TerritoriesService.setViewHeaders(false);

    window.refreshDom({loader: false}, 'show');
  };

  $scope.setLeadsView = () => {
    $scope.data.currentLeadsView = $scope.currentState.user ? $scope.currentState.user : undefined;
  };

  $scope.areAllTerritoriesVisible = () => MODEL.territories.length ===
        MODEL.territories.reduce((count, territory) => count + (territory.territoryDetail.hidden ? 0 : 1), 0);

  $scope.toggleAllTerritories = async () => {
    const allVisible = $scope.areAllTerritoriesVisible();
    if (allVisible) {
      // hide all
      await Promise.all(MODEL.territories.map(({id}) => TerritoriesService.setTerritoryVisibility(id, true)));
    } else {
      // show all that are hidden
      await Promise.all(MODEL.territories
        .filter(({territoryDetail: {hidden}}) => hidden)
        .map(({id}) => TerritoriesService.setTerritoryVisibility(id, false)));
    }
    TerritoriesService.setMapUpdatedMessageBarVisibility(true);
  };

  $scope.toggleTerritoryVisibility = async (territoryId) => {
    const territory = MODEL.territories.find(({id}) => id === territoryId);
    if (!territory) {
      return;
    }

    territory.territoryDetail.hidden = !territory.territoryDetail.hidden;
    await TerritoriesService.setTerritoryVisibility(territoryId, territory.territoryDetail.hidden);
    TerritoriesService.setMapUpdatedMessageBarVisibility(true);
  };

  $scope.editTerritoryName = (territoryId, territoryName) => {
    TerritoriesService.showEditTerritoryNamePopup(territoryId, territoryName);
  };

  $scope.saveTerritory = async () => {
    const
      id = MODEL.TerritoriesService.currentTerrId;
    const name = MODEL.TerritoriesService.territoryName.trim();
    MODEL.TerritoriesService.territoryName = name;

    if (!TerritoriesService.validateTerritoryName(name)) {
      return;
    }

    if (id) {
      await TerritoriesService.saveTerritoryName(id, name);
      $scope.closeAddEditTerritory();
      $scope.fetchTerritories();
      return;
    }

    $scope.closeAddEditTerritory();
    TerritoriesService.createTerritoryBounds();
    analyticsService.clicked(['Territory Creation', 'Drop pins and define'], {name});
  };

  $scope.saveTerritoryColor = async () => {
    const {currentTerrId, territoryColor, territoryOpacity} = MODEL.TerritoriesService;
    await TerritoriesService.saveTerritoryColor(currentTerrId, territoryColor, territoryOpacity);
    $scope.closeEditTerritoryColor();
  };

  $scope.closeAddEditTerritory = () => {
    window.refreshDom({addEditPopupMode: undefined}, 'TerritoriesService');
  };

  $scope.closeEditTerritoryColor = () => {
    window.refreshDom(
      {
        showEditColorPopup: false, currentTerrId: undefined, territoryColor: undefined, territoryOpacity: undefined,
      },
      'TerritoriesService',
    );
  };

  $scope.deleteTerritory = async (territory) => {
    const deleted = await TerritoriesService.deleteTerritory(territory.id);
    if (deleted) {
      $scope.fetchTerritories();
    }
  };

  $scope.editTerritoryColor = (territoryId, color, opacity) => {
    TerritoriesService.showEditTerritoryColorPopup(territoryId, color, opacity);
  };

  $scope.setTerritoryColor = (color) => {
    TerritoriesService.setTerritoryColor(color);
  };

  $scope.editTerritoryBounds = (territoryId) => {
    const territory = MODEL.territories.find(({id}) => id === territoryId);
    if (!territory) {
      return;
    }

    if (territory.postalCodes && territory.postalCodes.length) {
      TerritoriesService.showZipsPopup(
        territoryId,
        territory.postalCodes.map(({postalCode}) => postalCode).join(' '),
      );
    } else {
      TerritoriesService.editTerritoryBounds(territoryId);
    }
  };

  $scope.hideMapUpdatedMessageBar = () => {
    TerritoriesService.setMapUpdatedMessageBarVisibility(false);
  };

  $scope.clearTerritoryMarkers = () => {
    TerritoriesService.clearTerritory();
  };

  $scope.cancelTerritoryBounds = () => {
    TerritoriesService.cancelTerritory();
  };

  $scope.saveTerritoryBounds = async () => {
    if (MODEL.TerritoriesService.currentTerrId) {
      await TerritoriesService.updateTerritoryBounds(MODEL.TerritoriesService.currentTerrId);
    } else {
      await TerritoriesService.createTerritory();
    }
    $scope.fetchTerritories();
  };

  $scope.territoryNameInputChanged = () => {
    MODEL.TerritoriesService.territoryName =
            MODEL.TerritoriesService.territoryName.replace(/^[^\w\s-–]/gi, '');
  };

  $scope.closeEditZipsPopup = () => {
    window.refreshDom(
      {showEditZipsPopup: false, currentTerrId: undefined, territoryZips: ''},
      'TerritoriesService',
    );
  };

  $scope.saveTerritoryZips = async () => {
    const
      id = MODEL.TerritoriesService.currentTerrId;
    const zips = MODEL.TerritoriesService.territoryZips.trim();
    MODEL.TerritoriesService.territoryZips = zips;

    if (!TerritoriesService.validateTerritoryZips(zips)) {
      return;
    }

    if (id) {
      await TerritoriesService.updateTerritoryZips(id, zips);
      $scope.closeEditZipsPopup();
    } else {
      const name = MODEL.TerritoriesService.territoryName;
      await TerritoriesService.createTerritoryWithZips(name, zips);
      $scope.closeEditZipsPopup();
      $scope.fetchTerritories();
    }
  };

  initPage();
}

TerritoriesCtrl.$inject = [
  '$scope', 'BaseController', 'TeamService', 'MainService', 'TerritoriesService',
];
