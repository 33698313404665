import template from './radio-option.html';

const radioOption = {
  bindings: {
    description: '@?',
    model: '=',
    name: '<',
    required: '<?',
    text: '@',
    value: '<',
  },
  controller: angular.noop,
  controllerAs: '$ctrl',
  template,
};

export default radioOption;
