import {all, call, select, put, takeEvery} from 'redux-saga/effects';
import {fetchCompanies, fetchCompanyCount, fetchCompanyGroups, initCompanyListVew} from './action';
import company, {getCompaniesCount, getTotalCompanyCount} from './index';
import companyNetworkService from '../../network-services/accounts-network-service';
import customFieldsNetworkService from '../../network-services/custom-fields-network-service';
import EntityType from '../../react/type/EntityType';
import ListResponse from '../../react/type/service/ListResponse';
import Company from '../../react/type/Company';
import CustomField from '../../react/type/CustomField';

// don't load all companies if there's more records than this limit
// (temporarily set to 0)
export const COMPANY_COUNT_THRESHOLD = 0;
const COMPANY_LIMIT_PER_REQUEST = 2000;

const callFetchCompanies = (page: number, limit: number = COMPANY_LIMIT_PER_REQUEST) => call(
  companyNetworkService.fetchAllAccounts.bind(companyNetworkService),
  false,
  {
    includeGroups: true,
    includeNotes: true,
    includeCustomFields: true,
  },
  page,
  'updatedAt',
  false,
  limit,
);

function* onInitCompanyListView() {
  yield put(company.actions.setError(undefined));
  yield put(company.actions.setLoading(true));

  const companyCountResponse: ListResponse<Company> = yield callFetchCompanies(1, 1);
  yield put(company.actions.setCompanyCount(companyCountResponse.total)); // update total company count

  if (companyCountResponse.total > COMPANY_COUNT_THRESHOLD) {
    // do not continue loading, we'll fall back to the old company list view
    yield put(company.actions.setLoading(false));
    return;
  }

  const companiesCount = yield select(getCompaniesCount);
  // companies are already loaded, not reloading
  if (companiesCount === companyCountResponse.total) {
    yield put(company.actions.setLoading(false));
    return;
  }

  console.time();
  const [companyResponse, customFieldsResponse]: [ListResponse<Company>, ListResponse<CustomField>] = yield all([
    callFetchCompanies(1),
    call(customFieldsNetworkService.getFields.bind(customFieldsNetworkService), EntityType.COMPANY),
  ]);
  console.timeEnd();

  yield put(company.actions.setCompanies(companyResponse.data));
  yield put(company.actions.updateCustomFields(customFieldsResponse.data));
  yield put(company.actions.setLoading(false));
  // load the rest of pages
  yield put(fetchCompanies({skipFirst: true}));
}

function* onFetchCompanyCount() {
  const companyCount = yield call(companyNetworkService.getAccountsCount);
  yield put(company.actions.setCompanyCount(companyCount));
}

function* onFetchCompanies({payload: {skipFirst = false}}: ReturnType<typeof fetchCompanies>) {
  // there is one problem with such loading:
  // if you've loaded 1..10 companies and one of the companies (say 5th) is deleted and now you load
  // companies 11..20, you will actually load companies with ids 12..21. So you'll miss one company.
  // As an opposite: if somebody added new company in between, you won't miss it because it'll get a
  // higher id and will be loaded at the end

  let page = 1;
  if (!skipFirst) {
    const response = yield callFetchCompanies(1);
    yield put(company.actions.setCompanyCount(response.total)); // update total company count
    yield put(company.actions.setCompanies(response.data));
  }

  let totalCount = yield select(getTotalCompanyCount);

  console.time();
  while (page * COMPANY_LIMIT_PER_REQUEST < totalCount) {
    const response: ListResponse<Company> = yield callFetchCompanies(++page);
    if (totalCount !== response.total) {
      totalCount = response.total;
      yield put(company.actions.setCompanyCount(response.total));
    }
    yield put(company.actions.appendCompanies(response.data));
  }
  console.timeEnd();
}

function* onFetchCompanyGroups() {
  const groups = yield call(
    companyNetworkService.fetchAllAccountGroups.bind(companyNetworkService),
    undefined,
    1,
    'displayOrder',
    false,
    999999,
  );
  yield put(company.actions.setGroups(groups.data));
}

export default function* companySaga() {
  yield takeEvery(initCompanyListVew.type, onInitCompanyListView);
  yield takeEvery(fetchCompanyCount.type, onFetchCompanyCount);
  yield takeEvery(fetchCompanies.type, onFetchCompanies);
  yield takeEvery(fetchCompanyGroups.type, onFetchCompanyGroups);
}
