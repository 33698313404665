import React, {useCallback} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import NoteType from '../../../type/Note';
import theme from '../../../style/theme';
import LinkButton from '../../../components/LinkButton/LinkButton';

const NoteActions = styled.div`
  visibility: hidden;
  width: 0;
  display: flex;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: width 0.25s ease-in-out;
`;

const Container = styled.div`
  display: flex;
  background-color: ${theme.color.deleteBg};
  margin: 0 auto;
  border-radius: 4px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  &:hover > ${NoteActions} {
    width: 40px;
    visibility: visible;
  }
  max-width: calc(100% - 16px);
`;

const DeleteButton = styled(LinkButton)`
  color: ${theme.color.delete};
  height: 100%;
  width: 100%;
  border-radius: 0 4px 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover, &:focus, &:visited {
    color: ${theme.color.delete};
  }
`;

const NoteContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: white;
  border: 1px solid ${theme.color.border};
  border-radius: 4px;
`;

const NoteText = styled.div`
  margin-bottom: 8px;
`;

const NoteMetadata = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NoteUser = styled.div`
  margin-bottom: 8px;
`;

interface Props {
  note: NoteType,
  onDelete?: (note: NoteType) => void,
}

const Note: React.FC<Props> = ({note, onDelete}) => {
  const handleDeleteClick = useCallback(() => onDelete && onDelete(note), [note, onDelete]);
  return (
    <Container>
      <NoteContainer>
        <NoteText>{note.note}</NoteText>
        <NoteMetadata>
          <NoteUser>
            by {note.user.username}
          </NoteUser>
          <div>
            {moment(note.createdAt).format('LLLL')}
          </div>
        </NoteMetadata>
      </NoteContainer>
      <NoteActions>
        <DeleteButton onClick={handleDeleteClick}>
          <FontIcon inherit iconClassName="fas fa-trash" />
        </DeleteButton>
      </NoteActions>
    </Container>
  );
};

export default Note;
