import moment, {Moment, MomentInput, unitOfTime} from 'moment';
import sortedUniqBy from 'lodash/sortedUniqBy';
import {TooltipFormatterContextObject} from 'highcharts';
import Activity from '../../../../type/Activity';
import User from '../../../../type/User';
import userDisplayName from '../../../../util/formatter/userDisplayName';

export type ActivityCount = {date: Moment, count: number};

export const groupActivitiesByUserIdAndDate = (activities: Activity[], groupByAssignee: boolean) => {
  const groupedActivities = activities.reduce<{ [key: string]: ActivityCount[] }>(
    (result, activity) => {
      const userId = (groupByAssignee ? activity.assignee?.id : activity.user.id) ?? 0;
      const activityDate = moment(activity.startAt);
      const activityCounts: ActivityCount[] = result[userId] ?? [];
      const entry = activityCounts.find(({date}) => date.valueOf() === activityDate.valueOf());
      if (entry) {
        entry.count++;
      } else {
        activityCounts.push({date: activityDate, count: 1});
      }
      return Object.assign(result, {[userId]: activityCounts});
    },
    {},
  );

  // convert counts from [1, 2, 1] to [1, 3, 4], i.e. every next count is count itself + a sum of previous counts
  // Object.keys(groupedActivities).forEach((userId: string) => {
  //   let numberOfActivities = 0;
  //   // eslint-disable-next-line no-return-assign
  //   groupedActivities[userId] = groupedActivities[userId].map(activity => ({
  //     ...activity,
  //     count: numberOfActivities += activity.count,
  //   }));
  // });

  return groupedActivities;
};

export const getSortedActivityUsers = (
  activities: Activity[],
  groupByAssignee: boolean,
  noAssigneeLabel: string = 'No Assignee',
) => sortedUniqBy(
  activities
    .map(({assignee, user}: Activity) => (groupByAssignee ? assignee : user))
    .map((user: Pick<User, 'id' | 'username' | 'fullName'> | undefined) => (user
      ? {id: user.id, name: userDisplayName(user)}
      : {id: 0, name: noAssigneeLabel}
    ))
    .sort((a, b) => {
      // guarantee that No Assignee is at the top
      if (a.name === noAssigneeLabel || b.name === noAssigneeLabel) {
        return a.id - b.id;
      }
      return (a.name || '').localeCompare(b.name);
    }),
  'id',
);

export const DEFAULT_DATE_FORMAT = 'll';

export type SimpleRangeType = {
  label: string,
  getStartDate: () => Date | undefined,
  dateGranularity: unitOfTime.StartOf | undefined,
  dateFormatter: (date: MomentInput) => string,
};

const minusDays = (days: number) => () => moment().subtract(days, 'd').startOf('day').toDate();

const dayGranularityDateFormatter = (date: MomentInput) => moment(date).format(DEFAULT_DATE_FORMAT);
const weekGranularityDateFormatter = (date: MomentInput) =>
  `${dayGranularityDateFormatter(moment(date).startOf('week'))} - ${dayGranularityDateFormatter(moment(date).endOf('week'))}`;
const monthGranularityDateFormatter = (date: MomentInput) =>
  `${dayGranularityDateFormatter(moment(date).startOf('month'))} - ${dayGranularityDateFormatter(moment(date).endOf('month'))}`;

const SIMPLE_DATE_RANGE: SimpleRangeType[] = [
  {label: 'Last 7 Days', getStartDate: minusDays(7), dateGranularity: 'day', dateFormatter: dayGranularityDateFormatter},
  {label: 'Last 30 Days', getStartDate: minusDays(30), dateGranularity: 'week', dateFormatter: weekGranularityDateFormatter},
  {label: 'Last 90 Days', getStartDate: minusDays(90), dateGranularity: 'month', dateFormatter: monthGranularityDateFormatter},
];

export const SIMPLE_DATE_RANGE_LABELS = SIMPLE_DATE_RANGE.map(({label}) => ({value: label, label}));
export const getStartDate = (rangeLabel: string) =>
  SIMPLE_DATE_RANGE.find(({label}) => label === rangeLabel)?.getStartDate();
export const getDateGranularity = (rangeLabel: string) =>
  SIMPLE_DATE_RANGE.find(({label}) => label === rangeLabel)?.dateGranularity;

type TooltipFormatter = (this: TooltipFormatterContextObject) => string;
const tooltipFormatter = (dateFormatter: (date: MomentInput) => string): TooltipFormatter => function () {
  return `${dateFormatter(moment(this.key, DEFAULT_DATE_FORMAT))}<br/><small>${this.y}</small>`;
};

export const getDateRangeTooltipFormatter = (rangeLabel: string) => {
  const dateFormatter = SIMPLE_DATE_RANGE.find(({label}) => label === rangeLabel)?.dateFormatter;
  return dateFormatter ? tooltipFormatter(dateFormatter) : undefined;
};
