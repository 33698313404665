import FieldFeature from './field/FieldFeature';
import {gridFilterComparator} from '../../react/type/network/filterComparator';

export const defaultFilterParams: {[key: string]: boolean} = {
  suppressAndOrCondition: true,
  applyButton: true,
  closeOnApply: true,
};

const genericFieldTextFilterOptions: gridFilterComparator[] = [
  gridFilterComparator.CONTAINS,
  gridFilterComparator.EQUALS,
  gridFilterComparator.NOT_EQUAL,
  gridFilterComparator.STARTS_WITH,
  gridFilterComparator.ENDS_WITH,
];

export const customFieldTextFilterOptions: gridFilterComparator[] = [
  gridFilterComparator.CONTAINS,
  // Platform doesn't support this comparation TART-1747
  // gridFilterComparator.NOT_CONTAINS,
  gridFilterComparator.EQUALS,
  // Platform doesn't support this comparation TART-1747
  // gridFilterComparator.NOT_EQUAL,
];

export default Object.freeze({
  [FieldFeature.ALWAYS_VISIBLE]: {
    suppressMovable: true,
    lockPosition: true,
    lockVisible: true,
    lockPinned: true,
  },
  [FieldFeature.FILTERABLE]: {},
  [FieldFeature.SORTABLE]: {
    sortable: true,
  },
  [FieldFeature.LIST]: {
    autoHeight: true,
    filter: 'agSetColumnFilter',
  },
  [FieldFeature.DATE]: {
    filter: 'agDateColumnFilter',
    filterParams: defaultFilterParams,
  },
  [FieldFeature.NUMERIC]: {
    filter: 'agNumberColumnFilter',
    filterParams: defaultFilterParams,
  },
  [FieldFeature.TEXT]: {
    filter: 'agTextColumnFilter',
    filterParams: {
      ...defaultFilterParams,
      filterOptions: genericFieldTextFilterOptions,
    },
  },
  [FieldFeature.OBJECT]: {},
  [FieldFeature.CUSTOM_FIELD]: {},
  [FieldFeature.ADDRESS]: {
    filter: 'agTextColumnFilter',
    filterParams: defaultFilterParams,
  },
  [FieldFeature.NON_INTEGRATION]: {},
});
