import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import Button from 'react-md/lib/Buttons/Button';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import CircularProgress from 'react-md/lib/Progress/CircularProgress';
import TextField from 'react-md/lib/TextFields/TextField';
import NoteType from '../../../../type/Note';
import Centered from '../../../../style/Centered';
import helperService from '../../../../../shared-services/helper-service';
import safeLocalStorage from '../../../../../shared-services/safe-local-storage-service';
import notesNetworkService from '../../../../../network-services/notes-network-service';
import Note from '../Note';
import EntityType from '../../../../type/EntityType';
import Company from '../../../../type/Company';
import useDataLoader from '../../../../hook/useDataLoader';
import useInitializer from '../../../../hook/useInitializer';
import ListResponse from '../../../../type/service/ListResponse';
import useBoolean from '../../../../hook/useBoolean';
import ConfirmationDialog from '../../../../components/ConfirmationDialog/ConfirmationDialog';

const NewNoteContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: calc(100% - 16px);
  margin: 0 auto 1rem;
`;

interface Props {
  company: Company,
}

const NotesTab: React.FC<Props> = ({company}) => {
  const {t} = useTranslation(['entity-common']);
  const fetchNotes = useCallback(() => notesNetworkService.fetchNotes(EntityType.COMPANY, company!.id), [company]);
  const {isLoading, loader, data} = useDataLoader([fetchNotes]);
  useInitializer(loader);
  const notes = data && data.length ? (data[0] as ListResponse<NoteType>).data : [];

  const [newNoteText, setNewNoteText] = useState<string>('');
  const [isCreating, startCreating, stopCreating] = useBoolean(false);

  const handleAddNewNote = useCallback(
    async () => {
      try {
        startCreating();
        await notesNetworkService.createNote(
          EntityType.COMPANY,
          company.id,
          {
            note: newNoteText,
            createdAt: new Date().toISOString(),
            user: {id: safeLocalStorage.currentUser!.id, username: safeLocalStorage.currentUser!.username},
          },
        );
        setNewNoteText('');
        await loader();
      } catch (e) {
        helperService.showAndLogError(e, 'Failed to save note, please try again later');
      }
      stopCreating();
    },
    [loader, newNoteText, setNewNoteText],
  );
  const handleNoteKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Enter') {
        event.stopPropagation();
        handleAddNewNote();
      }
    },
    [handleAddNewNote],
  );

  const [noteToDelete, setNoteToDelete] = useState<NoteType | undefined>(undefined);
  const handleDeleteNoteRequest = useCallback((note) => setNoteToDelete(note), [setNoteToDelete]);
  const handleDeleteNote = useCallback(
    async () => {
      try {
        await notesNetworkService.deleteNote(EntityType.COMPANY, company.id, noteToDelete!.id);
        setNoteToDelete(undefined);
        await loader();
      } catch (e) {
        helperService.showAndLogError(e, 'Failed to delete a note, please try again later.');
        setNoteToDelete(undefined);
      }
    },
    [loader, noteToDelete, setNoteToDelete],
  );

  return (
    <>
      <NewNoteContainer>
        <TextField
          id="new-note-text-field"
          disabled={isCreating || isLoading}
          fullWidth
          label="New Note"
          leftIcon={<FontIcon iconClassName="fas fa-pen" />}
          onChange={setNewNoteText}
          onKeyDown={handleNoteKeyDown}
          placeholder="Type new note text here..."
          value={newNoteText}
        />
        {newNoteText.length > 0 && (
          <Button
            disabled={isCreating || isLoading}
            flat
            onClick={handleAddNewNote}
          >
            Save
          </Button>
        )}
      </NewNoteContainer>
      {(isLoading || isCreating) && <CircularProgress id="notes-loader" centered />}
      {notes.length > 0 && (
        notes?.map((note: NoteType) => (
          <Note
            key={note.id}
            note={note}
            onDelete={handleDeleteNoteRequest}
          />
        ))
      )}
      {!notes.length && !(isLoading || isCreating) && (<Centered>No notes</Centered>)}
      <ConfirmationDialog
        cancelButtonLabel={t('CancelDelete')}
        confirmButtonLabel={t('DoDelete')}
        focusOnConfirm={false}
        id="note-delete-confirmation-dialog"
        onConfirm={handleDeleteNote}
        onHide={() => setNoteToDelete(undefined)}
        title={t('DeleteNoteConfirmation', {note: noteToDelete?.note})}
        text={t('DeleteNoteConfirmationText', {note: noteToDelete?.note})}
        visible={!!noteToDelete}
      />
    </>
  );
};

export default NotesTab;
