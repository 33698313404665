import cloneDeep from 'lodash/cloneDeep';
import routingFireActions from '../routing-service/actions';
import store from '../../store';
import {RootState} from '../rootReducer';

export const getRoutingServiceState = (state: RootState) => state.routingService;

export default class RoutingService {
  get addRouteFromListView() { return getRoutingServiceState(store.getState()).addRouteFromListView; }

  get creatingRoute() { return getRoutingServiceState(store.getState()).creatingRoute; }

  get usersRoutes() { return getRoutingServiceState(store.getState()).usersRoutes; }

  get routesForCustomer() { return getRoutingServiceState(store.getState()).routesForCustomer; }

  get currentRouteResponse() { return getRoutingServiceState(store.getState()).currentRouteResponse; }

  get startTime() { return getRoutingServiceState(store.getState()).startTime; }

  get startDate() { return getRoutingServiceState(store.getState()).startDate; }

  get currentRouteCoord() { return getRoutingServiceState(store.getState()).currentRouteCoord; }

  get currentRouteArrivals() { return getRoutingServiceState(store.getState()).currentRouteArrivals; }

  get currentRouteObjects() { return getRoutingServiceState(store.getState()).currentRouteObjects; }

  get manualLocation() { return getRoutingServiceState(store.getState()).manualLocation; }

  get manualWelcomeModal() { return getRoutingServiceState(store.getState()).manualWelcomeModal; }

  get routeEditName() { return getRoutingServiceState(store.getState()).routeEditName; }

  get usedRoutePins() { return getRoutingServiceState(store.getState()).usedRoutePins; }

  get directionsBeingShown() { return getRoutingServiceState(store.getState()).directionsBeingShown; }

  get totalRouteDistance() { return getRoutingServiceState(store.getState()).totalRouteDistance; }

  get totalRouteDuration() { return getRoutingServiceState(store.getState()).totalRouteDuration; }

  get isEditingRoute() { return getRoutingServiceState(store.getState()).isEditingRoute; }

  get optimizeRoute() { return getRoutingServiceState(store.getState()).optimizeRoute; }

  get smartRouting() { return getRoutingServiceState(store.getState()).smartRouting; }

  get smartRoutingDepartureTime() { return getRoutingServiceState(store.getState()).smartRoutingDepartureTime; }

  get smartRoutingReturnTime() { return getRoutingServiceState(store.getState()).smartRoutingReturnTime; }

  get smartRoutingGroupSelected() { return getRoutingServiceState(store.getState()).smartRoutingGroupSelected; }

  get smartRoutingCadence() { return getRoutingServiceState(store.getState()).smartRoutingCadence; }

  get smartRoutingStopInterval() { return getRoutingServiceState(store.getState()).smartRoutingStopInterval; }

  get smartRoutingBreakTime() { return getRoutingServiceState(store.getState()).smartRoutingBreakTime; }

  get timePerRouteStop() { return getRoutingServiceState(store.getState()).timePerRouteStop; }

  get currentRouteViewing() { return getRoutingServiceState(store.getState()).currentRouteViewing; }

  get optimize() { return getRoutingServiceState(store.getState()).optimize; }

  get routeTypeSelected() { return getRoutingServiceState(store.getState()).routeTypeSelected; }

  get currentRouteId() { return getRoutingServiceState(store.getState()).currentRouteId; }

  get currentRoute() { return getRoutingServiceState(store.getState()).currentRoute; }

  get currentRouteName() { return getRoutingServiceState(store.getState()).currentRouteName; }

  get newRouteName() { return getRoutingServiceState(store.getState()).newRouteName; }

  get routeName() { return getRoutingServiceState(store.getState()).routeName; }

  get showRoutesPins() { return getRoutingServiceState(store.getState()).showRoutesPins; }

  get startingLocation() { return getRoutingServiceState(store.getState()).startingLocation; }

  get endingLocation() { return getRoutingServiceState(store.getState()).endingLocation; }

  get endingLocationLatLng() { return getRoutingServiceState(store.getState()).endingLocationLatLng; }

  get startingLocationCoords() { return getRoutingServiceState(store.getState()).startingLocationCoords; }

  get endingLocationCoords() { return getRoutingServiceState(store.getState()).endingLocationCoords; }

  get routeObjects() { return getRoutingServiceState(store.getState()).routeObjects; }

  get endLocationPresent() { return getRoutingServiceState(store.getState()).endLocationPresent; }

  get fromChooseRoutes() { return getRoutingServiceState(store.getState()).fromChooseRoutes; }

  get fromEditData() { return getRoutingServiceState(store.getState()).fromEditData; }

  get fromEditForm() { return getRoutingServiceState(store.getState()).fromEditForm; }

  get endingLocationFromEdit() { return getRoutingServiceState(store.getState()).endingLocationFromEdit; }

  get selectedUserRoutes() { return getRoutingServiceState(store.getState()).selectedUserRoutes; }

  get editFlag() { return getRoutingServiceState(store.getState()).editFlag; }

  get routeNameModal() { return getRoutingServiceState(store.getState()).routeNameModal; }

  get manualQuestionsModal() { return getRoutingServiceState(store.getState()).manualQuestionsModal; }

  get originalRoute() { return getRoutingServiceState(store.getState()).originalRoute; }

  get startingAddress() { return getRoutingServiceState(store.getState()).startingAddress; }

  get endingAddress() { return getRoutingServiceState(store.getState()).endingAddress; }

  get initialRouteType() { return getRoutingServiceState(store.getState()).initialRouteType; }

  get routeOp() { return getRoutingServiceState(store.getState()).routeOp; }

  set timePerRouteStop(newValue) {
    routingFireActions.updateTimePerRouteStop(newValue);
  }

  set startingAddress(newValue) {
    routingFireActions.updateStartingAddress(newValue);
  }

  // fields below this line are not-serializable objects (some google maps objects) which
  // can not be put in the store, thus we keep them here
  // it's not a good solution, but eventually we'll store them in another place

  private _encodedPolyline: any = undefined;
  get encodedPolyline(): any {
    return this._encodedPolyline;
  }
  set encodedPolyline(value: any) {
    this._encodedPolyline = cloneDeep(value);
  }

  private _routeMarkers: any[] = [];
  get routeMarkers(): any[] {
    return this._routeMarkers;
  }
  removeRouteMarkers() {
    this._routeMarkers = [];
  }
  addRouteMarkerToRouteMarkers(marker: any) {
    this._routeMarkers.push(cloneDeep(marker));
  }

  private _routesNewMarker: any[] = [];
  get routesNewMarker(): any[] {
    return this._routesNewMarker;
  }
  resetRoutesNewMarker() {
    this._routesNewMarker = [];
  }
  addMarkerToRoutesNewMarker(marker: any) {
    this._routesNewMarker.push(cloneDeep(marker));
  }
}
