// this file is for global sagas, which affect the app as a whole
import {all, takeLeading} from 'redux-saga/effects';
import {initApp} from './globalActions';
import safeLocalStorage from '../shared-services/safe-local-storage-service';

function* onInitApp() {
  if (safeLocalStorage.currentUser) {
    // only run these actions when we're authorized
    yield all([
    ]);
  }
}

export default function* companySaga() {
  yield takeLeading(initApp.type, onInitApp);
}
