import find from 'lodash/find';
import helperService from '../../shared-services/helper-service';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';
import mapActions from '../../store/store-helpers';
import MappedCellRenderer, {tooltipValueGetter} from '../../common/grid/MappedCellRenderer';

export default function AllContactsCtrl(
  $scope, $controller, $compile, $route, $routeParams, MainService,
  AllContactsService, BaseController, CustomerService, FetchCRMDataService, TeamService,
  GroupsService, $timeout,
) {
  // init global utility functions
  const UTILS = window.mmcUtils;
  const MODEL = window.DataModel;

  mapActions($scope, ['modals']);
  $scope.modalsActions.resetVisibility('noAccountsModal');

  // set localStorage equal to $scope to get html-binding
  $scope.localStorage = safeLocalStorage;
  $scope.showViewByUser = UTILS.setViewByUserBasedOnRole(safeLocalStorage);
  // bind data model to scope for access in views
  $scope.data = MODEL;
  $scope.currentState = MODEL.cachedState ? MODEL.cachedState.contacts : {};
  // bind current route id to scope for access in views
  $scope.currentRoute = $route.current.id;
  MODEL.currentSubPage = 'list';
  // outerWidth
  $scope.outerWidth = window.outerWidth;
  // default vals for ng-model vars
  MODEL.selectedRecords = {};
  $scope.selectedBoxes = MODEL.selectedRecords;
  MODEL.selectedEntities = [];
  MODEL.allRecordsSelected = false;
  MODEL.paginationBlocks = [];

  // extend BaseController methods onto scope
  Object.assign($scope, BaseController);
  Object.assign(this, $controller('CommonCtrl', {$scope}));

  $scope.getFields = () => MODEL.contactFieldModel.fields;

  $scope.LAST_COLUMN = {
    ...$scope.SELECT_COLUMNS_COLUMN_DEF,
    cellRenderer: MappedCellRenderer,
    cellStyle: {padding: '4px 0 0', textAlign: 'center'},
    tooltipValueGetter,
    name: 'mapped',
    displayName: 'Mapped?',
  };

  $scope.MODEL = MODEL;
  $scope.$watchCollection('MODEL.contacts', (rows) => {
    $timeout(() => {
      $scope.setGridRowData(rows);
    });
  });
  $scope.$watchCollection('MODEL.listviewSelectedColumns', (columns) => {
    $timeout(() => {
      $scope.updateColumnDefinitions($scope.getFields(), columns, false, [$scope.LAST_COLUMN]);
    });
  });

  $(document.body).show();
  MODEL.contacts = [];
  // get contacts data
  MODEL.show.loader = true;

  $scope.setLeadsView = () => {
    $scope.data.currentLeadsView = UTILS.setLeadsView($scope.currentState);
  };

  if (!MODEL.TeamsService.verifiedTeamMembers.length) {
    TeamService.getOrgDetails()
      .then(() => {
        $scope.setLeadsView();
      })
      .catch((error) => {
        helperService.showAndLogError(error, "We could not retrieve your organization's details. Please reload the page or retry login.");
      });
  } else {
    $scope.setLeadsView();
  }

  MainService.setCurrentPage();
  window.refreshDom({loader: false}, 'show');

  $scope.handleRowSelectionChanged = ({api}) => {
    const selectedRows = api.getSelectedNodes();
    MODEL.selectedRecords = selectedRows.reduce((result, {id}) => Object.assign(result, {[id]: true}), {});
    $scope.selectRecord();
  };

  // -------Onboarding tour starts here --------->

  // function for different screens
  $scope.nextScreen = (count) => {
    switch (count) {
      case -1:
        $scope.closeTourFn(true);
        window.location.href = '/#/contacts/map';
        break;
      case 1:
        $scope.closeTourFn(true);
        window.location.href = '/#/contacts/crm-activities/map';
        break;
    }
  };
  // -------Onboarding tour ends here --------->

  //
  // ------------------- GENERAL / UTILITY Methods ------------------- //
  //

  // shows the customer table when visiting contacts page
  $scope.showCustomerTable = function () {
    $('#customersTable').show();
    $('#editPinForm').hide();
  };

  // apply selected sort item
  $scope.sortCustomersListArray = function (selectedSortItem) {
    AllContactsService.sortCustomersListArray(selectedSortItem);
  };

  // check all in contacts list for delete
  $scope.checkAllForDelete = function () {
    AllContactsService.checkAllForDelete($scope.selectedBoxes);
  };

  $scope.toggleBulkEdit = function (show) {
    $scope.showBulkEdit = show;
  };

  // changed comparator on filter modal
  $scope.changedComparator = function () {
    CustomerService.changedComparator($scope.queryRow1Compare, $scope.queryRow2Compare, $scope.queryRow3Compare, $scope.queryRow4Compare);
  };

  // which fields to show after choosing comparator
  $scope.showCompareSelect = function (row) {
    CustomerService.showCompareSelect(row, $scope.queryRow1Option, $scope.queryRow2Option, $scope.queryRow3Option, $scope.queryRow4Option, $scope.queryRow1Compare, $scope.queryRow2Compare, $scope.queryRow3Compare, $scope.queryRow4Compare);

    // reset dropdown comparators (must be done in controller bc of $scope vars)
    if (row === 'row1' && ($scope.queryRow1Compare === 'containsString' || $scope.queryRow1Compare === 'doesNotContainString' || $scope.queryRow1Compare === 'contains' || $scope.queryRow1Compare === 'equals' || $scope.queryRow1Compare === 'doesNotEqual')) {
      $scope.queryRow1Compare = '';
    }

    if (row === 'row2' && ($scope.queryRow2Compare === 'containsString' || $scope.queryRow2Compare === 'doesNotContainString' || $scope.queryRow2Compare === 'contains' || $scope.queryRow2Compare === 'equals' || $scope.queryRow2Compare === 'doesNotEqual')) {
      $scope.queryRow2Compare = '';
    }

    if (row === 'row3' && ($scope.queryRow3Compare === 'containsString' || $scope.queryRow3Compare === 'doesNotContainString' || $scope.queryRow3Compare === 'contains' || $scope.queryRow3Compare === 'equals' || $scope.queryRow3Compare === 'doesNotEqual')) {
      $scope.queryRow3Compare = '';
    }

    if (row === 'row4' && ($scope.queryRow4Compare === 'containsString' || $scope.queryRow4Compare === 'doesNotContainString' || $scope.queryRow4Compare === 'contains' || $scope.ueryRow4Compare === 'equals' || $scope.queryRow4Compare === 'doesNotEqual')) {
      $scope.queryRow4Compare = '';
    }
  };

  // applies a new filter being run
  $scope.applyNewQuery = function () {
    CustomerService.applyNewQuery($scope.queryRow1Option, $scope.queryRow2Option, $scope.queryRow3Option, $scope.queryRow4Option, $scope.queryRow1Compare, $scope.queryRow2Compare, $scope.queryRow3Compare, $scope.queryRow4Compare);
  };

  // find accounts & deals this contact is associated with
  $scope.addAccountsAndDealsForContact = function (record) {
    const account = find(MODEL.accountsRaw, {id: record.accountId});
    const deals = MODEL.dealsRaw.filter(deal => deal.contactId === record.id);

    // link for account
    $('#account').html('');
    if (account) {
      const link = $(`<div class="crmLink" ng-click="goToAccount('${account.objectId}')">${account.name}</div>`);
      $compile(link)($scope);
      $('#account').html(link);
    }

    $('#dealsTableModule').empty();
    deals.forEach(deal => {
      const row = $(`
    <tr ng-click="goToDeal('${deal.objectId}')">
        <td>${deal.name}</td>
        <td>${deal.stage}</td>
        <td>${deal.amount}</td>
        <td>${deal.score}</td>
        <td>${deal.closingDate}</td>
    </tr>`);
      $compile(row)($scope);
      $('#dealsTableModule').append(row);
    });
  };
  window.addAccountsAndDealsForContact = $scope.addAccountsAndDealsForContact;
}

AllContactsCtrl.$inject = [
  '$scope', '$controller', '$compile', '$route', '$routeParams', 'MainService',
  'AllContactsService', 'BaseController', 'CustomerService', 'FetchCRMDataService', 'TeamService',
  'GroupsService', '$timeout',
];
