import React from 'react';
import styled from 'styled-components';
import DataTable from 'react-md/lib/DataTables/DataTable';
import TableBody from 'react-md/lib/DataTables/TableBody';
import TableColumn from 'react-md/lib/DataTables/TableColumn';
import TableRow from 'react-md/lib/DataTables/TableRow';
import MiniMap from './MiniMap';
import Company from '../../../type/Company';
import Location from '../../../type/Location';
import FieldFeature from '../../../../common/field-model/field/FieldFeature';
import Field from '../../../../common/field-model/field/field';
import EntityFieldModel from '../../../../common/field-model/entity-field-model';
import DetailField from './DetailField';

const Container = styled.section`
  height: 100%;
  overflow: auto;
  padding: 16px;
  font-size: 0.9rem;
`;

const Row = styled(TableRow)`
  border-bottom: none !important;
  & .md-table-column {
    padding: 0 !important;
  }
  &.md-table-row--hover {
    background: initial;
  }
`;

const SectionTitle = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:first-of-type) {
    border-top: 1px solid #dedede;
    margin-top: 16px;
    padding-top: 16px;
  }
`;

const isDefined = (value: any): boolean => !!value;
const formatAddress = (entity: Location) => (
  [
    entity.address,
    entity.city,
    [entity.region, entity.postalCode].filter(isDefined).join(' '),
    entity.country,
  ]
    .filter(isDefined)
    .join(', ')
);

interface Props {
  company?: Company,
  fieldModel: EntityFieldModel,
}

const isSidebarRegularField = ({name, features}: Field) => !features.has(FieldFeature.ADDRESS) && !features.has(FieldFeature.CUSTOM_FIELD)
  && !features.has(FieldFeature.LIST) && !features.has(FieldFeature.DATE)
  && !['name', 'parentAccount', 'website'].includes(name);

const CompanyDetails: React.FC<Props> = ({company, fieldModel}) => {
  if (!company) {
    return null;
  }

  return (
    <Container>
      <SectionTitle>
        <div className="md-title" style={{marginBottom: '0'}}>Company Details</div>
      </SectionTitle>
      <DataTable plain>
        <TableBody>
          {fieldModel.sortedFields
            .filter(isSidebarRegularField)
            .filter((field: Field) => field.hasValue(company))
            .map((field: Field) => (
              <Row key={field.name}>
                <TableColumn>{field.displayName}</TableColumn>
                <TableColumn>
                  <DetailField
                    company={company}
                    field={field}
                  />
                </TableColumn>
              </Row>
            ))}
        </TableBody>
      </DataTable>
      {fieldModel.hasCustomFieldsWithValues(company) && (
        <>
          <SectionTitle>
            <div className="md-title" style={{marginBottom: '0'}}>Custom Fields</div>
          </SectionTitle>
          <DataTable plain>
            <TableBody>
              {fieldModel.fields
                .filter((field: Field) => field.features.has(FieldFeature.CUSTOM_FIELD) && field.hasValue(company))
                .map((field: Field) => (
                  <Row key={field.name}>
                    <TableColumn>{field.displayName}</TableColumn>
                    <TableColumn>{field.getEntityFormattedValue(company)}</TableColumn>
                  </Row>
                ))
              }
            </TableBody>
          </DataTable>
        </>
      )}
      {fieldModel.hasAddress(company) && (
        <>
          <SectionTitle>
            <div className="md-title" style={{marginBottom: '0'}}>Address</div>
          </SectionTitle>
          <MiniMap
            entity={company}
            label={formatAddress(company)}
          />
          <DataTable plain>
            <TableBody>
              {fieldModel.sortedFields
                .filter((field: Field) => field.features.has(FieldFeature.ADDRESS) && field.hasValue(company))
                .map((field: Field) => (
                  <Row key={field.name}>
                    <TableColumn>{field.displayName}</TableColumn>
                    <TableColumn>{field.getEntityFormattedValue(company)}</TableColumn>
                  </Row>
                ))
              }
            </TableBody>
          </DataTable>
        </>
      )}
    </Container>
  );
};

export default CompanyDetails;
