import template from './notes.html';
import './notes.scss';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';

class NotesController {
  constructor($window, MainService) {
    Object.assign(this, {
      MODEL: $window.DataModel,
      localStorage: safeLocalStorage,
      MainService,
    });
  }

  addNote(deferSave, optionalNoteText, optionalStorageArray) {
    this.MainService.addNote(deferSave, optionalNoteText, optionalStorageArray);
  }

  deleteNote(noteId) {
    this.MainService.deleteNote(noteId);
  }

  editNote(noteId) {
    this.MainService.editNote(noteId);
  }
}

NotesController.$inject = ['$window', 'MainService'];

const notes = {
  bindings: {
    hasUpdateAccess: '<',
    hasDeleteAccess: '<',
  },
  controller: 'NotesController',
  template,
};

export {NotesController, notes};
