import React from 'react';
import cn from 'classnames';
import AntDAvatar, {AvatarProps} from 'antd/es/avatar';
import {UserRef} from '../../type/User';
import styles from './Avatar.module.scss';
import complementFileUrl from '../../util/complementFileUrl';
import Tooltip from "antd/es/tooltip";
import userDisplayName from '../../util/formatter/userDisplayName';

const DEFAULT_AVATAR_COLORS = [
  'red',
  'pink',
  'purple',
  'deep-purple',
  'indigo',
  'blue',
  'light-blue',
  'cyan',
  'teal',
  'green',
  'light-green',
  'lime',
  'yellow',
  'amber',
  'orange',
  'deep-orange',
  'brown',
  'grey',
  'blue-grey',
];

const userIdToColorIndex = new Map<number, number>();

interface Props extends Omit<AvatarProps, 'className'> {
  className?: string;
  randomColor?: boolean;
  tooltip?: boolean,
  user: UserRef;
}

const Avatar: React.FC<Props> = ({className, randomColor, tooltip, user, ...avatarProps}) => {
  const publicProfilePhoto = complementFileUrl(user.profilePhoto?.publicURI);

  const nameInitials = user.fullName
    ? user.fullName
      .split(/\s/)
      .map((word) => word[0].toUpperCase())
      .slice(0, 2)
      .join('')
    : user.username.substring(0, 1).toUpperCase();

  let colorClassName;
  if (!publicProfilePhoto) {
    if (randomColor) {
      if (!userIdToColorIndex.has(user.id)) {
        userIdToColorIndex.set(
          user.id,
          Math.round(Math.random() * (DEFAULT_AVATAR_COLORS.length - 1)),
        );
      }
      colorClassName = styles[DEFAULT_AVATAR_COLORS[userIdToColorIndex.get(user.id)!]];
    } else {
      colorClassName = styles.grey;
    }
  }

  return (
    <Tooltip title={tooltip ? userDisplayName(user) : undefined}>
      <AntDAvatar className={cn(styles.container, colorClassName, className)} src={publicProfilePhoto} {...avatarProps}>
        {publicProfilePhoto ? ' ' : nameInitials}
      </AntDAvatar>
    </Tooltip>
  );
};

export default Avatar;
