import React, {useMemo} from 'react';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import styled from 'styled-components';
import Activity from '../../../type/Activity';
import ActivityType from '../../../type/ActivityType';
import RecapActivityBox from './RecapActivityBox';
import EntityType from '../../../type/EntityType';

const ActivitiesBox = styled.div`
  min-height: 26px;
  border-bottom: 1px solid #eee;
  padding: 2px 0 4px 4px;
`;

const DateContainer = styled.div`
  min-height: 40px;
  margin-top: 4px;
  padding-left: 4px;
`;

const DateBox = styled.div`
  text-transform: uppercase;
  font-size: 0.75rem;
  transform: rotate(-75deg) translate(-16px,-7px);
  white-space: nowrap;
`;

const RecapContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  activities: Activity[],
  activityTypes: ActivityType[],
  date: Date,
  goToEntity: (entityType: EntityType, entityId: number) => void,
}

const RecapDateItems: React.FC<Props> = ({activities, activityTypes, date, goToEntity}) => {
  const activityByTypeId: {[key: number]: Activity[]} = useMemo(
    () => groupBy(activities, (activity) => activity.crmActivityType.id),
    [activities, activityTypes],
  );
  return (
    <RecapContainer>
      {activityTypes.map(activityType => (
        <ActivitiesBox key={activityType.id}>
          {activityByTypeId[activityType.id]
            ? (
              <RecapActivityBox
                activities={activityByTypeId[activityType.id]}
                activityType={activityType}
                goToEntity={goToEntity}
              />
            )
            : ''
          }
        </ActivitiesBox>
      ))}
      <DateContainer>
        <DateBox>{moment(date).format('MMM D')}</DateBox>
      </DateContainer>
    </RecapContainer>
  );
};

export default RecapDateItems;
