import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import Company from '../../../type/Company';
import Deal from '../../../type/Deal';
import Person from '../../../type/Person';
import Route from '../../../type/Route';
import File from '../../../type/File';
import FilePreview from './FilePreview';
import PersonSmallPreview from './small-preview/PersonSmallPreview';
import CompanySmallPreview, {RelatedCompany} from './small-preview/CompanySmallPreview';
import DealSmallPreview from './small-preview/DealSmallPreview';
import GroupSmallPreview from './small-preview/GroupSmallPreview';
import RouteSmallPreview from './small-preview/RouteSmallPreview';
import EntityType from '../../../type/EntityType';
import {maxWidth} from '../../../style/media';
import useSet from '../../../hook/useSet';
import ExpandableSection from './ExpandableSection';

const Container = styled.section`
  height: 100%;
  overflow: auto;
  padding: 16px;
  ${maxWidth.wideDesktop`
    padding: 0 16px 16px;
  `};
`;

const FilePreviews = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  overflow-scrolling: touch;
  padding: 0 5px 5px 0; // otherwise box-shadow is not visible
  max-width: 100%;
`;

interface Props {
  childCompanies: Company[],
  company: Company,
  deals: Deal[],
  files: File[],
  goToEntity: (entityType: EntityType, entityId: number) => void,
  people: Person[],
  routes: Route[],
}

enum Panels {
  PEOPLE,
  DEALS,
  COMPANY,
  GROUPS,
  ROUTES,
  FILES,
}

const CompanyConnections: React.FC<Props> = ({
  childCompanies,
  company,
  deals,
  files,
  goToEntity,
  people,
  routes,
}) => {
  const {t} = useTranslation(['translation']);

  const relatedCompanies: RelatedCompany[] = useMemo(
    () => [
      ...(company.parentAccount ? [{...company.parentAccount, isParentCompany: true}] : []),
      ...childCompanies,
    ],
    [childCompanies, company.parentAccount],
  );

  const [hiddenPanels,, togglePanel] = useSet<Panels>();

  return (
    <Container>
      <ExpandableSection
        hasChildren={people.length > 0}
        onTogglePanel={togglePanel}
        panel={Panels.PEOPLE}
        title={t('PeopleWithCount_interval', {postProcess: 'interval', count: people.length})}
        visible={!hiddenPanels.has(Panels.PEOPLE)}
      >
        {people.map((person) => (
          <PersonSmallPreview key={person.id} person={person} goToEntity={goToEntity} />
        ))}
      </ExpandableSection>

      <ExpandableSection
        hasChildren={deals.length > 0}
        onTogglePanel={togglePanel}
        panel={Panels.DEALS}
        title={t('DealWithCount_interval', {postProcess: 'interval', count: deals.length})}
        visible={!hiddenPanels.has(Panels.DEALS)}
      >
        {deals.map((deal) => (
          <DealSmallPreview key={deal.id} deal={deal} goToEntity={goToEntity} />
        ))}
      </ExpandableSection>

      <ExpandableSection
        hasChildren={relatedCompanies.length > 0}
        onTogglePanel={togglePanel}
        panel={Panels.COMPANY}
        title={t('RelatedCompanyWithCount_interval', {postProcess: 'interval', count: relatedCompanies.length})}
        visible={!hiddenPanels.has(Panels.COMPANY)}
      >
        {relatedCompanies.map((company) => (
          <CompanySmallPreview key={company.id} company={company} goToEntity={goToEntity} />
        ))}
      </ExpandableSection>

      <ExpandableSection
        hasChildren={!!company.groups?.length}
        onTogglePanel={togglePanel}
        panel={Panels.GROUPS}
        title={t('GroupWithCount_interval', {postProcess: 'interval', count: company.groups?.length})}
        visible={!hiddenPanels.has(Panels.GROUPS)}
      >
        {company.groups?.map(group => (
          <GroupSmallPreview key={group.id} goToEntity={goToEntity} group={group} />
        ))}
      </ExpandableSection>

      <ExpandableSection
        hasChildren={routes.length > 0}
        onTogglePanel={togglePanel}
        panel={Panels.ROUTES}
        title={t('RouteWithCount_interval', {postProcess: 'interval', count: routes.length})}
        visible={!hiddenPanels.has(Panels.ROUTES)}
      >
        {routes.map(route => (
          <RouteSmallPreview key={route.id} route={route} goToEntity={goToEntity} />
        ))}
      </ExpandableSection>

      <ExpandableSection
        hasChildren={files.length > 0}
        onTogglePanel={togglePanel}
        panel={Panels.FILES}
        title={t('FileWithCount_interval', {postProcess: 'interval', count: files.length})}
        visible={!hiddenPanels.has(Panels.FILES)}
      >
        {files.length > 0 && (
          <FilePreviews>
            {files.map(file => (
              <FilePreview
                key={file.id}
                entityType={EntityType.COMPANY}
                entityId={company.id}
                file={file}
              />
            ))}
          </FilePreviews>
        )}
      </ExpandableSection>
    </Container>
  );
};

export default CompanyConnections;
