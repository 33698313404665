import invariant from 'invariant';
import Tier from '../../../type/plans/Tier';
import Addon from '../../../type/plans/Addon';
import Service from '../../../type/plans/Service';
import {findTierByIntervalAndLicenses} from '../util/util';
import {Interval} from '../../../type/plans/Interval';
import toggleItemInSet from '../../../util/toggleItemInSet';
import CurrentPlan from '../../../type/plans/CurrentPlan';

export interface BillingState {
  addons: Addon[],
  checkoutVisible: boolean,
  isAnnual: boolean,
  licenses: number,
  selectedTier: Tier,
  selectedAddonIds: Set<Addon['id']>,
  selectedAddons: Addon[],
  selectedServiceIds: Set<Service['id']>,
  selectedServices: Service[],
  services: Service[],
  tiers: Tier[],
}

type BillingAction =
  {type: 'changeAnnual', isAnnual: boolean}
  | {type: 'changeLicenses', licenses: number}
  | {type: 'selectAddon', id: number, selected: boolean}
  | {type: 'selectService', id: number, selected: boolean}
  | {type: 'toggleCheckout', visibility: boolean}
  | {type: 'setTiers', tiers: Tier[]}
  | {type: 'setCurrentPlan', currentPlan: CurrentPlan};

const findTier = (tiers: Tier[], isAnnual: boolean, licenses: number): Tier => {
  const tier = findTierByIntervalAndLicenses(tiers, isAnnual ? Interval.ANNUAL : Interval.MONTHLY, licenses);
  invariant(
    tier !== undefined,
    `${isAnnual ? 'Annual' : 'Monthly'} tier not found for ${licenses} licenses: ${JSON.stringify(tiers)}`,
  );
  return tier;
};

export const reducer = (state: BillingState, action: BillingAction): BillingState => {
  switch (action.type) {
    case 'setTiers':
      return {
        ...state,
        tiers: action.tiers,
        selectedTier: action.tiers.length ? findTier(action.tiers, state.isAnnual, state.licenses) : state.selectedTier,
      };
    case 'changeAnnual':
      return {
        ...state,
        isAnnual: action.isAnnual,
        selectedTier: findTier(state.tiers, action.isAnnual, state.licenses),
      };
    case 'changeLicenses': {
      const selectedTier = findTier(state.tiers, state.isAnnual, action.licenses);
      return {...state, isAnnual: selectedTier.interval === Interval.ANNUAL, licenses: action.licenses, selectedTier};
    }
    case 'selectAddon': {
      const selectedAddonIds = toggleItemInSet(state.selectedAddonIds, action.id, action.selected);
      return {
        ...state,
        selectedAddonIds,
        selectedAddons: state.selectedAddonIds !== selectedAddonIds // only update when set was changed
          ? state.addons.filter(({id}) => selectedAddonIds.has(id))
          : state.selectedAddons,
      };
    }
    case 'selectService': {
      const selectedServiceIds = toggleItemInSet(state.selectedServiceIds, action.id, action.selected);
      return {
        ...state,
        selectedServiceIds,
        selectedServices: state.selectedServiceIds !== selectedServiceIds // only update when set was changed
          ? state.services.filter(({id}) => selectedServiceIds.has(id))
          : state.selectedServices,
      };
    }
    case 'toggleCheckout':
      return {...state, checkoutVisible: action.visibility};
    case 'setCurrentPlan':
      return {
        ...state,
        isAnnual: action.currentPlan.tier.interval === Interval.ANNUAL,
        licenses: action.currentPlan.licenses + 1, // 1 license is pre-selected
        selectedAddonIds: new Set(action.currentPlan.addonIds),
        selectedAddons: state.addons.filter(({id}) => action.currentPlan.addonIds.includes(id)),
      };
  }
  return state;
};

type InitArguments = {tiers: Tier[], addons: Addon[], services: Service[], currentPlan: CurrentPlan};
export const initState = ({tiers, addons, services, currentPlan}: InitArguments): BillingState => ({
  addons,
  checkoutVisible: false,
  isAnnual: currentPlan.tier.interval === Interval.ANNUAL,
  licenses: currentPlan.licenses + 1, // 1 license is pre-selected
  selectedTier: currentPlan.tier,
  selectedAddonIds: new Set(currentPlan.addonIds),
  selectedAddons: addons.filter(({id}) => currentPlan.addonIds.includes(id)),
  selectedServiceIds: new Set(),
  selectedServices: [],
  services,
  tiers,
});

export const setTiers = (tiers: Tier[]): BillingAction => ({type: 'setTiers', tiers});
export const setCurrentPlan = (currentPlan: CurrentPlan): BillingAction => ({type: 'setCurrentPlan', currentPlan});
export const changeAnnual = (isAnnual: boolean): BillingAction => ({type: 'changeAnnual', isAnnual});
export const changeLicenses = (licenses: number): BillingAction => ({type: 'changeLicenses', licenses});
export const selectAddon = (id: number, selected: boolean): BillingAction => ({type: 'selectAddon', id, selected});
export const selectService = (id: number, selected: boolean): BillingAction => ({type: 'selectService', id, selected});
export const showCheckout = (): BillingAction => ({type: 'toggleCheckout', visibility: true});
export const hideCheckout = (): BillingAction => ({type: 'toggleCheckout', visibility: false});
