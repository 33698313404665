import angular from 'angular';
import DashboardCtrl from './dashboard-controller';
import TeamService from './teams/teams-service';
import ActivityService from './activity-service';
import {ActivityCardController, activityCard} from './activity-card/activity-card-component';
import {NewMemberOptionsModalController, newMemberOptionsModal} from './new-member-options-modal/new-member-options-modal-component';
import {AddNewMemberModalController, addNewMemberModal} from './add-new-member-modal/add-new-member-modal-component';
import {ManageTeamsTableController, manageTeamsTable} from './teams/manage-teams-table/manage-teams-table-component';
import {ViewTeamsTableController, viewTeamsTable} from './teams/view-teams-table/view-teams-table-component';

export default angular.module('mapmycustomersApp.dashboard', [])
  .factory('TeamService', TeamService)
  .controller('DashboardCtrl', DashboardCtrl)
  .factory('ActivityService', ActivityService)
  .controller('ActivityCardController', ActivityCardController)
  .component('activityCard', activityCard)
  .controller('NewMemberOptionsModalController', NewMemberOptionsModalController)
  .component('newMemberOptionsModal', newMemberOptionsModal)
  .controller('AddNewMemberModalController', AddNewMemberModalController)
  .component('addNewMemberModal', addNewMemberModal)
  .controller('ManageTeamsTableController', ManageTeamsTableController)
  .component('manageTeamsTable', manageTeamsTable)
  .controller('ViewTeamsTableController', ViewTeamsTableController)
  .component('viewTeamsTable', viewTeamsTable)
  .name;
