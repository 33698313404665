import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';

export default function TerrNetworkService() {
  const _getTerritoryUrl = (id) => {
    let url = `organizations/${safeLocalStorage.currentUser.organization.id}/territories`;
    if (id) {
      url += `/${id}`;
    }
    return url;
  };

  const fetchTerritories = (params = {}) => BaseNetworkServiceWithPromises.read(_getTerritoryUrl(), params);

  const deleteTerritory = (id) => BaseNetworkServiceWithPromises.delete(_getTerritoryUrl(id));

  const updateTerritory = (territory) => BaseNetworkServiceWithPromises.update(_getTerritoryUrl(territory.id), territory);

  const createTerritory = (territory) => BaseNetworkServiceWithPromises.create(_getTerritoryUrl(), territory);

  return {
    fetchTerritories,
    createTerritory,
    updateTerritory,
    deleteTerritory,
  };
}
