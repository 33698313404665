import find from 'lodash/find';
import SharedNetworkService from '../network-services/shared-network-service';
import addEditNetworkService from '../network-services/add-edit-network-service';

export default function AddEditService($route) {
  // main utility functions
  const MODEL = window.DataModel;

  // service to return
  const service = {};

  service.deletePinFromContact = (/* id */) => {
    throw new Error('Implement me!');
  };

  // query parse to get record for selected recordId
  service.getRecordfromRecordId = (/* table, recordId */) => {
    throw new Error('Implement me!');
  };

  // query parse to get record for selected recordId
  // remove this - after new endpoints are everywhere
  service.getCustomFieldsfromRecordId = (/* recordId */) => {
    throw new Error('Implement me!');
  };

  service.saveCrmType = async function (table, parentId, obj, deleteAll) {
    let key = '';
    switch (table) {
      case 'accounts':
        key = 'parentAccount';
        break;
      case 'deals':
        // adding from accounts page
        if ($route.current.id === 'editAccountsPage') {
          key = 'account';
        } else {
          // adding from contacts page
          key = 'contact';
        }
        break;
      case 'contacts':
        key = 'account';
        break;
    }

    await Promise.all(Object.keys(obj).map(async id => {
      const elem = {...obj[id]};
      const deleted = deleteAll ? true : elem.deleted;
      const {alreadySaved} = elem;
      delete elem.deleted;
      delete elem.alreadySaved;
      if (alreadySaved) {
        if (deleted) {
          // code for dissociating
          elem[key] = null;
          await addEditNetworkService.updateAssociatedObjects(table, id, elem);
          // TODO - show errors for the response
        }
      } else if (!deleted) {
        // code for associating
        elem[key] = {
          id: parseInt(parentId, 10),
        };
        await addEditNetworkService.updateAssociatedObjects(table, id, elem);
        // TODO - show errors for the response
      }
    }));
  };

  service.loadDefaultData = async function (crmObjectType) {
    if (crmObjectType) {
      const response = await addEditNetworkService.getGroups(crmObjectType);
      MODEL.GroupsService.currentPageGroups = response.data;
      MODEL.GroupsService.currentPageGroupsCount = response.total;
      window.refreshDom(
        {
          currentPageGroups: MODEL.GroupsService.currentPageGroups,
          currentPageGroupsCount: MODEL.GroupsService.currentPageGroupsCount,
        },
        'GroupsService',
      );
    }
  };

  service.initializeData = () => {
    MODEL.currentCrmObjectId = '';
    MODEL.currentCrmObjectDealsData = [];
    MODEL.MappingService.currentCustomersNotes = [];
    MODEL.currentCrmObjectContactsData = [];
    MODEL.currentCrmObjectChildAccountsData = [];
    MODEL.GroupsService.selectedGroups = [];
    window.mmcGlobals.deferredNotes = [];
    MODEL.currentAssociatedContactId = '';
    MODEL.currentAssociatedAccountId = undefined;
    $('#parentAccountAddAccount').text('');
    $('#accountAddContact').text('');
    $('#accountEditContact').text('');
    $('#accountNameAddDeal').text('');
    $('#accountNameEditDeal').text('');
    $('#parentAccountEditAccount').text('');
    $('#contactNameAddDeal').text('');
    $('#contactNameEditDeal').text('');
  };

  service.fetchNotes = async (entityType, entityId) => {
    const response = await SharedNetworkService.fetchNotes(entityType, entityId);
    MODEL.MappingService.currentCustomersNotes = response.data.map(note => ({...note, createdAt: new Date(note.createdAt)}));
    window.refreshDom({currentCustomersNotes: MODEL.MappingService.currentCustomersNotes}, 'MappingService');
  };

  service.fetchCrmActivities = async (entityType, entityId, type) => {
    const response = await SharedNetworkService.fetchCrmActivitiesForEntity(entityType, parseInt(entityId, 10), type);
    MODEL.MappingService.currentCustomerActivites = response.data;
    window.refreshDom({currentCustomerActivites: MODEL.MappingService.currentCustomerActivites}, 'MappingService');
  };

  // associate the crm object with the respective objectType
  service.associateCrmObject = (objectType, id) => {
    const route = $route.current.id;
    if (objectType === 'parentAccount' || objectType === 'account') {
      const account = find(MODEL.accounts, {id});
      switch (route) {
        case 'addContactsPage':
          // Add Customers link account
          $('#linkCustAccAdd').hide();
          $('#clearCustAccAdd').show();
          break;

        case 'editContactsPage':
          // Edit Customers link account
          $('#clearCustAccEdit').show();
          break;

        case 'addAccountsPage':
          // Add Accounts link account
          $('#linkAccAccAdd').hide();
          $('#clearAccAccAdd').show();
          break;

        case 'editAccountsPage':
          // Edit Accounts link account
          $('#clearAccAccEdit').show();
          break;

        case 'addDealsPage':
          // Add Deals link account
          $('#linkDealAccAdd').hide();
          $('#clearDealAccAdd').show();
          MODEL.dirtyEditFlag = true;
          break;

        case 'editDealsPage':
          // Edit Deals link account
          $('#linkDealAccEdit').hide();
          $('#clearDealAccEdit').show();
          MODEL.dirtyEditFlag = true;
      }

      if ($route.current.id === 'editAccountsPage') {
        MODEL.parentAccountModified = true;
        MODEL.childAccounts = {};
      } else if ($route.current.id === 'editContactsPage') {
        MODEL.contactAccountModified = true;
        MODEL.contactDeals = {};
      } else if ($route.current.id === 'editDealsPage') {
        MODEL.dirtyEditFlag = true;
        window.refreshDom({dirtyEditFlag: MODEL.dirtyEditFlag});
      }

      $('#parentAccountAddAccount').text(account.name);
      $('#accountAddContact').text(account.name);
      $('#accountEditContact').text(account.name);
      $('#accountNameAddDeal').text(account.name);
      $('#accountNameEditDeal').text(account.name);
      $('#parentAccountEditAccount').text(account.name);

      MODEL.currentAssociatedAccountId = id;
      MODEL.parentAccountModified = true;
      MODEL.childAccounts = {};
      window.refreshDom({currentCrmObjectChildAccountsData: MODEL.currentCrmObjectChildAccountsData});
      window.refreshDom({currentAssociatedAccountId: MODEL.currentAssociatedAccountId});
    } else if (objectType === 'childAccounts') {
      const elem = find(MODEL.accounts, {id});
      if (MODEL.childAccounts[elem.id]) {
        elem.deleted = false;
      }
      MODEL.childAccountsModified = true;
      MODEL.childAccounts[elem.id] = elem;
      window.refreshDom({childAccounts: MODEL.childAccounts});
      window.refreshDom({childAccountsModified: MODEL.childAccountsModified});
    } else if (objectType === 'contacts') {
      if (route === 'editDealsPage' || route === 'addDealsPage') {
        const elem = find(MODEL.contacts, {id});
        MODEL.currentAssociatedContactId = id;
        MODEL.dirtyEditFlag = true;
        $('#contactNameEditDeal').text(elem.name);
        $('#contactNameAddDeal').text(elem.name);
        $('#linkDealCustEdit').hide();
        $('#linkDealCustAdd').hide();
        $('#clearDealCustEdit').show();
        $('#clearDealCustAdd').show();
      } else {
        const elem = find(MODEL.contacts, {id});
        if (MODEL.accountContacts[elem.id]) {
          elem.deleted = false;
        }
        MODEL.accountContactsModified = true;
        MODEL.accountContacts[elem.id] = elem;
        window.refreshDom({accountContacts: MODEL.accountContacts});
        window.refreshDom({accountContactsModified: MODEL.accountContactsModified});
      }
    } else if (objectType === 'deals') {
      const elem = find(MODEL.deals, {id});
      if ($route.current.id === 'editContactsPage') {
        if (MODEL.contactDeals[elem.id]) {
          elem.deleted = false;
        }
        MODEL.contactDealsModified = true;
        MODEL.contactDeals[elem.id] = elem;
        window.refreshDom({contactDeals: MODEL.contactDeals});
        window.refreshDom({contactDealsModified: MODEL.contactDealsModified});
      } else if ($route.current.id === 'editAccountsPage') {
        if (MODEL.accountDeals[elem.id]) {
          elem.deleted = false;
        }
        MODEL.accountDealsModified = true;
        MODEL.accountDeals[elem.id] = elem;
        window.refreshDom({accountDeals: MODEL.accountDeals});
        window.refreshDom({accountDealsModified: MODEL.accountDealsModified});
      }
    }
  };

  // remove a child account from the local data structure
  service.removeChildAccount = (id) => {
    MODEL.childAccountsModified = true;
    MODEL.childAccounts[id].deleted = true;
    window.refreshDom({childAccounts: MODEL.childAccounts});
    window.refreshDom({childAccountsModified: MODEL.childAccountsModified});
  };

  // remove a contact from Account
  service.removeContactFromAccount = (id) => {
    MODEL.accountContactsModified = true;
    MODEL.accountContacts[id].deleted = true;
    window.refreshDom({accountContacts: MODEL.accountContacts});
    window.refreshDom({accountContactsModified: MODEL.accountContactsModified});
  };

  // remove a deal from Account
  service.removeDealFromAccount = (id) => {
    MODEL.accountDealsModified = true;
    MODEL.accountDeals[id].deleted = true;
    window.refreshDom({accountDeals: MODEL.accountDeals});
    window.refreshDom({accountDealsModified: MODEL.accountDealsModified});
  };

  // remove a deal from the local data structure
  service.removeDealFromContact = (id) => {
    MODEL.contactDealsModified = true;
    MODEL.contactDeals[id].deleted = true;
    window.refreshDom({contactDeals: MODEL.contactDeals});
    window.refreshDom({contactDealsModified: MODEL.contactDealsModified});
  };

  return service;
}

AddEditService.$inject = ['$route'];
