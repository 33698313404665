import isString from 'lodash/isString';
import find from 'lodash/find';

function reportTypeDisplay() {
  return function (reportType) {
    if (reportType === 'crmActivities') {
      return 'Activities';
    }
    if (reportType === 'activities') {
      return 'History';
    }

    return reportType;
  };
}

function color() {
  return function (input) {
    const currentTime = Math.round((new Date()).getTime() / 1000);
    if (input < currentTime) {
      return 'red; color:white; ';
    }
  };
}

function textSearch() {
  return function (pins, searchTerm, field) {
    searchTerm = searchTerm.toLowerCase().trim();
    return Array.isArray(pins)
      ? pins.filter(pin => (field ? pin[field] : pin).toLowerCase().includes(searchTerm))
      : [];
  };
}

function numberToLocaleString() {
  return function (input) {
    return Number(input).toLocaleString();
  };
}

function getCampaignForId() {
  return function (input, campaigns) {
    const campaignObj = find(campaigns, {objectId: input});
    if (campaignObj) {
      return campaignObj.name;
    }

    return '';
  };
}

function getTemplateForId() {
  return function (input, templates) {
    const templateObj = find(templates, {objectId: input});
    if (templateObj) {
      return templateObj.subject;
    }

    return '';
  };
}

noNullDates.$inject = ['$filter'];

function noNullDates($filter) {
  return function (text) {
    if (!text || text === 'null') {
      return '';
    }

    return $filter('date')(text, 'MM-dd-yyyy h:mm a');
  };
}

function underscores() {
  return function (text) {
    return String(text).replace(/ /mg, '_');
  };
}

regularStage.$inject = ['mmcConst'];

function regularStage(mmcConst) {
  return (stages) => (Array.isArray(stages)
    ? stages.filter(({type}) => type !== mmcConst.dealStageWon.type && type !== mmcConst.dealStageLost.type)
    : stages);
}


function textColor() {
  return function (input) {
    const currentTime = Math.round((new Date()).getTime() / 1000);
    if (input < currentTime) {
      return 'color:white; ';
    }

    return 'color:#8D8D8D; ';
  };
}

function getDate() {
  return function (input) {
    let date = new Date(input * 1000);
    date = date.toString();
    const dateSplit = date.split(' ');
    if (dateSplit[3]) {
      const lastVisit = `${dateSplit[0]}, ${dateSplit[1]} ${dateSplit[2]} ${dateSplit[3]}`;
      return lastVisit;
    }
  };
}

function getLeadStatus() {
  return function (input, leadStages) {
    const leadType = input.stage;

    // return correct lead button
    if (leadType === leadStages[0]) {
      $(`#${input.objectId}`).addClass('leadStageBtn').css('background-color', '#ff987c');
    } else if (leadType === leadStages[1]) {
      $(`#${input.objectId}`).addClass('leadStageBtn').css('background-color', '#ff96b8');
    } else if (leadType === leadStages[2]) {
      $(`#${input.objectId}`).addClass('leadStageBtn').css('background-color', '#a46bd7');
    } else if (leadType === leadStages[3]) {
      $(`#${input.objectId}`).addClass('leadStageBtn').css('background-color', '#ff6767');
    } else if (leadType === leadStages[4]) {
      $(`#${input.objectId}`).addClass('leadStageBtn').css('background-color', '#3fc8b8');
    } else if (leadType === leadStages[5]) {
      $(`#${input.objectId}`).addClass('leadStageBtn').css('background-color', '#8fdeff');
    } else if (leadType === leadStages[6]) {
      $(`#${input.objectId}`).addClass('leadStageBtn').css('background-color', '#fffb9f');
    } else if (leadType === leadStages[7]) {
      $(`#${input.objectId}`).addClass('leadStageBtn').css('background-color', '#cfde68');
    }


    return leadType;
  };
}

function replaceSpaceWithDash() {
  return function (string) {
    if (!isString(string)) {
      return string;
    }
    return string.replace(/ /g, '-');
  };
}

function getNameForPin() {
  return function (input, customers, pinLabelShowCompany) {
    let routePin;

    customers.forEach((pin) => {
      if (pin.objectId === input) {
        routePin = pin;
      }
    });

    if (routePin) {
      // show company on route
      if (pinLabelShowCompany) {
        if (routePin.company) {
          return routePin.company;
        }

        return routePin.name;
      }

      // show name on route
      if (routePin.name) {
        return routePin.name;
      }

      return routePin.company;
    }

    return '';
  };
}

function getAddressForPin() {
  return function (input, customers) {
    let routePin;

    customers.forEach((pin) => {
      if (pin.objectId === input) {
        routePin = pin;
      }
    });

    if (routePin) {
      if (routePin.zip) {
        return `${routePin.address}, ${routePin.city}, ${routePin.state} ${routePin.zip}`;
      }

      return `${routePin.address}, ${routePin.city}, ${routePin.state}`;
    }

    return '';
  };
}

function removeSpaces() {
  return function (text) {
    return String(text).replace(/ /g, '');
  };
}

export {
  reportTypeDisplay,
  color,
  textSearch,
  numberToLocaleString,
  getCampaignForId,
  getTemplateForId,
  noNullDates,
  underscores,
  regularStage,
  textColor,
  getDate,
  getLeadStatus,
  replaceSpaceWithDash,
  getNameForPin,
  getAddressForPin,
  removeSpaces,
};
