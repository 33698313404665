import template from './create-report.html';
import './create-report.scss';

class CreateReportController {
  constructor($scope, $timeout, ReportsService, BaseController) {
    Object.assign(this, {
      MODEL: window.DataModel,
      $scope,
      $timeout,
      ReportsService,
      showAllReports: ReportsService.showAllReports,
      saveReport: ReportsService.saveReport,
      selectColumns: ReportsService.selectColumns,
      showFilter: BaseController.showFilter,
      formatField: BaseController.formatField,
      setGridRowData: BaseController.setGridRowData,
      updateColumnDefinitions: BaseController.updateColumnDefinitions,
      initializeGrid: BaseController.initializeGrid,
    });

    this.createReportView = 'accounts';
    if (!this.MODEL.ReportsService.currentFieldModel) {
      this.MODEL.ReportsService.currentFieldModel = this.MODEL.accountFieldModel;
    }
    this.reportTypes = [
      {
        display: 'companies',
        name: 'accounts',
        fieldModel: this.MODEL.accountFieldModel,
      },
      {
        display: 'people',
        name: 'contacts',
        fieldModel: this.MODEL.contactFieldModel,
      },
      {
        display: 'deals',
        name: 'deals',
        fieldModel: this.MODEL.dealFieldModel,
      },
      {
        display: 'activities',
        name: 'crmActivities',
        fieldModel: this.MODEL.crmActivityFieldModel,
      },
    ];

    this.initializeGrid(document.querySelector('#data-grid'));
    this.subscribeToTableModelChanges();
  }

  subscribeToTableModelChanges() {
    this.$scope.$watchCollection('$ctrl.MODEL.ReportsService.reportsData', (rows) => {
      this.$timeout(() => {
        this.setGridRowData(rows);
      });
    });
    this.$scope.$watchCollection('$ctrl.MODEL.ReportsService.selectedColumns', (columns) => {
      this.$timeout(() => {
        this.updateColumnDefinitions(this.MODEL.ReportsService.currentFieldModel.fields, columns);
      });
    });
  }

  async changeCreateReportView() {
    await this.ReportsService.changeCreateReportView(this.createReportView);
  }

  async downloadReport(report) {
    if (report.status && ['queued', 'running'].includes(report.status)) {
      swal('Generating', "This report is being generated already. Please wait, it'll be delivered to your email.", 'success');
      return;
    }

    this.MODEL.show.loader = true;

    try {
      await this.ReportsService.downloadReport(report);
      swal('Generated', 'This report will be sent to your email shortly.', 'success');
    } catch (e) {
      let errMsg = 'Failed to generate this report. Please try again later.';
      if (e && e.validationErrors.length) {
        errMsg = e.validationErrors.reduce((msg, err) => `${msg}${err.message} `, '');
      }
      swal('Uh-oh', errMsg, 'error');
    }

    window.refreshDom({loader: false}, 'show');
    await this.ReportsService.fetchReports();
  }
}

CreateReportController.$inject = ['$scope', '$timeout', 'ReportsService', 'BaseController'];

const createReport = {
  bindings: {},
  controller: 'CreateReportController',
  template,
};

export {CreateReportController, createReport};
