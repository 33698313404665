import React, {useCallback, useEffect, useReducer, useState} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import FetchCRMDataServiceType from '../../type/service/FetchCRMDataService';
import MainServiceType from '../../type/service/MainService';
import ListResponse from '../../type/service/ListResponse';
import Person from '../../type/Person';
import useBoolean from '../../hook/useBoolean';
import persistenceReducer from './reducers/peopleList';
import {
  setViewColumns,
} from '../../util/reducers/listReducer';
import safeLocalStorage from '../../../shared-services/safe-local-storage-service';
import Group from '../../type/Group';
import customFieldsNetworkService from '../../../network-services/custom-fields-network-service';
import EntityType from '../../type/EntityType';
import contactsNetworkService from '../../../network-services/contacts-network-service';
import contactFieldModel from '../../../common/field-model/contact-field-model';
import helperService from '../../../shared-services/helper-service';
import useInitializer from '../../hook/useInitializer';
import ListViewGrid from '../../components/Grid/ListViewGrid';
import getListCallbacks from '../../hook/getListCallbacks';
import FilterPane from '../../components/FilterPane/FilterPane';
import {RootState} from '../../../store/rootReducer';
import {fetchPeopleGroups} from '../../../store/people/action';
import withStore from '../../hoc/withStore';
import {getPeopleGroups} from '../../../store/people';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

interface Props {
  FetchCRMDataService: FetchCRMDataServiceType,
  MainService: MainServiceType,
  groups: Group[],
  fetchPeopleGroups: () => {},
}

const PeopleList: React.FC<Props> = ({
  FetchCRMDataService,
  MainService,
  groups,
  fetchPeopleGroups,
}) => {
  const [peopleData, setPeopleData] = useState<ListResponse<Person> | undefined>(undefined);
  const [isLoading, setLoading, setNoLoading] = useBoolean();
  const [initialized, setInitialized] = useBoolean();
  const [{filter, viewColumns, page, sorting, filteredFields}, dispatchParams] = useReducer(persistenceReducer, safeLocalStorage.peopleListViewState);

  const updateViewColumns = useCallback(
    async () => {
      dispatchParams(setViewColumns(await FetchCRMDataService.initListViewCols()));
    },
    [dispatchParams],
  );

  // TODO delete it after migration subnav from angular to React
  useEffect(
    () => {
      FetchCRMDataService.setCurrentPageVariable('People List', peopleData?.total, 'Add Person', false, false);
    },
    [peopleData],
  );

  const initialize = useCallback(
    async () => {
      try {
        fetchPeopleGroups();
        const response = await customFieldsNetworkService.getFields(EntityType.PERSON);
        contactFieldModel.setCustomFields(response.data);
        await updateViewColumns();
      } catch (e) {
        helperService.showAndLogError(e);
      }
      setInitialized();
    },
    [fetchPeopleGroups, setInitialized],
  );
  useInitializer(initialize);

  const reloadPeople = useCallback(
    async () => {
      setLoading();
      try {
        setPeopleData(await contactsNetworkService.fetchAllContacts(false, filter, page, sorting.fieldName, sorting.ascending));
      } catch (e) {
        helperService.showAndLogError(e);
      }
      setNoLoading();
    },
    [setPeopleData, setLoading, setNoLoading, filter, page, sorting],
  );

  useEffect(
    () => { reloadPeople(); },
    [reloadPeople, filter, page, sorting],
  );

  const {handleChangeFilter, handleClearFilter, handleChangePage, handleChangeSorting} = getListCallbacks(dispatchParams);

  return (
    <Container>
      {initialized &&
      <ListViewGrid
        fieldModel={contactFieldModel}
        entityCount={peopleData?.total}
        entityList={peopleData?.data}
        entityType={EntityType.PERSON}
        MainService={MainService}
        onChangePage={handleChangePage}
        page={page}
        onChangeFilters={handleChangeFilter}
        onChangeSorting={handleChangeSorting}
        multiSelectBar
        isLoading={isLoading}
        onReloadData={reloadPeople}
        viewColumns={viewColumns}
        onUpdateViewColumns={updateViewColumns}
        filteredFields={filteredFields}
        onClearFilter={handleClearFilter}
        sorting={sorting}
        extraFilters={(
          <FilterPane
            groups={groups}
            onFilterChanged={handleChangeFilter}
            filteredFields={filteredFields}
          />
        )}
      />
      }
    </Container>
  );
};


const mapStateToProps = (state: RootState) => ({
  groups: getPeopleGroups(state),
});

const mapDispatchToProps = {
  fetchPeopleGroups,
};

export default withStore(connect(mapStateToProps, mapDispatchToProps)(PeopleList));
