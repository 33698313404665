import safeLocalStorage from '../../../shared-services/safe-local-storage-service';
import Setting from '../../type/Setting';

export enum MarkerSize {
  LARGE = 'large',
  SMALL = 'small',
}

export enum MarkerIcon {
  PIN = 'pin',
  CIRCLE = 'circle',
}

class UserSetting {
  static getSettingByName(name: string): Setting | undefined {
    return safeLocalStorage.currentUser!.settings.find(({key}) => key === name);
  }

  static get markerSize(): MarkerSize {
    const setting = UserSetting.getSettingByName('markerSize');
    return setting ? setting.value as MarkerSize : MarkerSize.LARGE;
  }

  static get markerIcon(): MarkerIcon {
    const setting = UserSetting.getSettingByName('markerIcon');
    return setting ? setting.value as MarkerIcon : MarkerIcon.PIN;
  }
}

export default UserSetting;
