import helperService from '../../shared-services/helper-service';
import template from './change-password.html';

class ChangePasswordController {
  constructor($window, SettingsService) {
    Object.assign(this, {
      $window,
      SettingsService,
      MODEL: $window.DataModel,
    });

    this.password = {
      current: '',
      new: '',
      confirm: '',
      showModal: false,
    };
  }

  closePasswordModal() {
    this.password.current = '';
    this.password.new = '';
    this.password.confirm = '';
    this.password.showModal = false;
    this.MODEL.password.showModal = false;
  }

  changePassword(currentPass, newPass, confirmPass) {
    this.SettingsService.changePassword(currentPass, newPass, confirmPass)
      .then(res => console.log(res))
      .catch((error) => {
        helperService.showAndLogError(error);
      });
  }
}

ChangePasswordController.$inject = ['$window', 'SettingsService'];

const changePassword = {
  bindings: {},
  controller: 'ChangePasswordController',
  template,
};

export {ChangePasswordController, changePassword};
