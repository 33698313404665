import React, {useCallback} from 'react';
import {Radio as AntdRadio} from 'antd';
import {RadioGroupProps} from 'antd/es/radio';
import {RadioChangeEvent} from 'antd/es/radio/interface';

interface Props extends Omit<RadioGroupProps, 'onChange'> {
  onChange: (value: any, e: RadioChangeEvent) => void;
}

const RadioGroup: React.FC<Props> = ({
  onChange,
  ...props
}) => {
  const handleChange = useCallback(
    (e) => onChange(e.target.value, e),
    [onChange],
  );

  return (
    <AntdRadio.Group
      {...props}
      onChange={handleChange}
    />
  );
};

export default RadioGroup;
