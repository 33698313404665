import angular from 'angular';
import {RoutingTableController, routingTable} from './routing-table/routing-table-component';
import RoutingService from './routing-service';
import RoutingCtrl from './routing-controller';
import {ChooseRouteTypeModalController, chooseRouteTypeModal} from './choose-route-type-modal/choose-route-type-modal-directive';
import {EditRouteNameController, editRouteName} from './edit-route-name/edit-route-name-directive';
import {ManualRouteQuestionsModalController, manualRouteQuestionsModal} from './manual-route-questions-modal/manual-route-questions-modal-component';
import {RouteNameModalController, routeNameModal} from './route-name-modal/route-name-modal-component';
import {RoutingCadenceModalController, routingCadenceModal} from './routing-cadence-modal/routing-cadence-modal-directive';
import {SmartRouteLocationModalController, smartRouteLocationModal} from './smart-route-location-modal/smart-route-location-modal-directive';
import {SmartRoutingGroupsModalController, smartRoutingGroupsModal} from './smart-routing-groups-modal/smart-routing-groups-modal-directive';
import {SmartRoutingVisitingModalController, smartRoutingVisitingModal} from './smart-routing-visiting-modal/smart-routing-visiting-modal-directive';
import {
  ManualRouteWelcomeModalController, manualRouteWelcomeModal,
} from './manual-route-welcome-modal/manual-route-welcome-modal-component';
import './routing.scss';

export default angular.module('mapmycustomersApp.routing', [])
  .component('routingTable', routingTable)
  .controller('RoutingTableController', RoutingTableController)
  .factory('RoutingService', RoutingService)
  .controller('RoutingCtrl', RoutingCtrl)
  .controller('ChooseRouteTypeModalController', ChooseRouteTypeModalController)
  .controller('EditRouteNameController', EditRouteNameController)
  .directive('chooseRouteTypeModal', chooseRouteTypeModal)
  .directive('editRouteName', editRouteName)
  .controller('ManualRouteQuestionsModalController', ManualRouteQuestionsModalController)
  .component('manualRouteQuestionsModal', manualRouteQuestionsModal)
  .controller('RouteNameModalController', RouteNameModalController)
  .component('routeNameModal', routeNameModal)
  .controller('RoutingCadenceModalController', RoutingCadenceModalController)
  .directive('routingCadenceModal', routingCadenceModal)
  .controller('SmartRouteLocationModalController', SmartRouteLocationModalController)
  .directive('smartRouteLocationModal', smartRouteLocationModal)
  .controller('SmartRoutingGroupsModalController', SmartRoutingGroupsModalController)
  .directive('smartRoutingGroupsModal', smartRoutingGroupsModal)
  .controller('SmartRoutingVisitingModalController', SmartRoutingVisitingModalController)
  .directive('smartRoutingVisitingModal', smartRoutingVisitingModal)
  .controller('ManualRouteWelcomeModalController', ManualRouteWelcomeModalController)
  .component('manualRouteWelcomeModal', manualRouteWelcomeModal)
  .name;
