import template from './import-progress-modal.html';

class ImportProgressModalController {
  constructor($window) {
    Object.assign(this, {
      $window,
      MODEL: $window.DataModel,
    });
  }
}

ImportProgressModalController.$inject = [
  '$window',
];

const importProgressModal = {
  bindings: {},
  controller: 'ImportProgressModalController',
  controllerAs: '$ctrl',
  template,
};

export {ImportProgressModalController, importProgressModal};
