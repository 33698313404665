import template from './route-name-modal.html';
import mapActions from '../../../store/store-helpers';

class RouteNameModalController {
  constructor($window, RoutingService) {
    Object.assign(this, {
      $window,
      RoutingService,
      MODEL: $window.DataModel,
    });

    mapActions(this, ['RoutingService']);
  }

  $onInit() {
    this.routeNameModel = this.MODEL.RoutingService.routeName;
    document.getElementById('route-name-input').focus();
  }

  closeRouteNameModal() {
    this.RoutingServiceActions.closeRouteNameModal();
  }

  routingAddName() {
    this.RoutingService.routingAddName();
  }

  changeRouteName() {
    this.RoutingServiceActions.updateRouteName(this.routeNameModel);
  }
}

RouteNameModalController.$inject = ['$window', 'RoutingService'];

const routeNameModal = {
  restrict: 'E',
  bindings: {},
  controller: 'RouteNameModalController',
  template,
};

export {RouteNameModalController, routeNameModal};
