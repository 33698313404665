import React, {useCallback, useMemo} from 'react';
import styled from 'styled-components';
import DialogContainer from 'react-md/lib/Dialogs/DialogContainer';
import CircularProgress from 'react-md/lib/Progress/CircularProgress';
import File from '../../../type/File';
import useObjectUrl from '../../../hook/useObjectUrl';
import useBoolean from '../../../hook/useBoolean';
import {downloadFile, getFileAsUrl, getFilePreviewAsUrl} from '../../../../shared-services/file-service';
import {AnyEntityId} from '../../../type/AnyEntity';
import EntityType from '../../../type/EntityType';

const FilePreviewContainer = styled.div`
  height: 158px;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  align-items: center;

  &:not(:last-child) {
      margin-right: 10px;
  }
`;

const FileThumbnail = `
  vertical-align: baseline;
  max-height: 158px;
  
  &:hover {
      box-shadow: 2px 2px 2px #bbb;
  }
`;

const FileThumbnailNoPreview = styled.div`
  ${FileThumbnail}
  text-overflow: ellipsis;
  max-width: 100px;
  overflow-wrap: break-word;
  overflow: hidden;
  background: #fafafa;
  padding: 10px;
  white-space: nowrap;
`;

const ImageThumbnail = styled.img`
  ${FileThumbnail}
`;

const FakeImageThumbnail = styled.img`
  width: 100%;
  display: block;
`;

const PreviewDialog = styled(DialogContainer)`
  & > .md-dialog {
    width: inherit;
    max-width: 80vw;
    
    top: calc(50% + 30px);
    max-height: calc(100% - 90px);
    transform: translate3d(-50%, -50%, 0);
  }
`;

const DialogNoPreviewContainer = styled.div`
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: center;
  min-width: 100px;
  min-height: 100px;
`;

const DialogFakePreviewImage = styled.img`
  max-width: 100%;
  max-height: 300px;
`;

const DialogPreviewImage = styled.img`
  max-width: 80vw;
`;

interface Props {
  file: File,
  entityType: EntityType,
  entityId: AnyEntityId,
}

const FilePreview: React.FC<Props> = ({entityId, entityType, file}) => {
  const fileCategory = useMemo(
    () => {
      if (file.contentType.startsWith('image/')) {
        return 'image';
      }
      const name = file.name.toLowerCase();
      if (name.endsWith('.xls') || name.endsWith('.xlsx')) {
        return 'xls';
      }
      if (name.endsWith('.pdf')) {
        return 'pdf';
      }

      return undefined;
    },
    [file],
  );

  const [previewDataUrl] = useObjectUrl(useCallback(
    async () => (fileCategory !== 'image' ? undefined : getFilePreviewAsUrl(file, entityType, entityId)),
    [file, fileCategory],
  ));

  const [isPreviewVisible, showPreview, hidePreview] = useBoolean();
  const [dataUrl] = useObjectUrl(useCallback(
    async () => (!isPreviewVisible ? undefined : getFileAsUrl(file, entityType, entityId)),
    [file, isPreviewVisible],
  ));
  const handleDownload = useCallback(
    () => dataUrl && downloadFile({...file, dataUrl}, entityType, entityId),
    [dataUrl, file],
  );

  if (!fileCategory) {
    return null;
  }

  return (
    <>
      <FilePreviewContainer
        onClick={showPreview}
      >
        {fileCategory === 'pdf' && (
          <FileThumbnailNoPreview>
            <FakeImageThumbnail src="images/file-preview/pdf-icon.svg" alt={`File preview for ${file.name}`} />
            {file.name}
          </FileThumbnailNoPreview>
        )}
        {fileCategory === 'xls' && (
          <FileThumbnailNoPreview>
            <FakeImageThumbnail src="images/file-preview/xls-icon.svg" alt={`File preview for ${file.name}`} />
            {file.name}
          </FileThumbnailNoPreview>
        )}
        {fileCategory === 'image' && (
          previewDataUrl
            ? <ImageThumbnail src={previewDataUrl} alt={`File preview for ${file.name}`} />
            : <FileThumbnailNoPreview><CircularProgress id={`Loading-file-preview-${file.id}`} /></FileThumbnailNoPreview>
        )}
      </FilePreviewContainer>
      <PreviewDialog
        actions={[
          {
            children: 'Download',
            onClick: handleDownload,
            disabled: !dataUrl && fileCategory === 'image',
            secondary: true,
          },
          {
            children: 'Close',
            className: 'mmc-file-preview-dialog__close-button',
            onClick: hidePreview,
            primary: true,
          },
        ]}
        initialFocus=".mmc-file-preview-dialog__close-button"
        id={`file-preview-dialog-${file.id}`}
        modal
        onHide={hidePreview}
        portal
        renderNode={document.body}
        title={file.name}
        visible={isPreviewVisible}
      >
        {fileCategory === 'pdf' && (
          <DialogNoPreviewContainer>
            <DialogFakePreviewImage src="images/file-preview/pdf-icon.svg" alt="pdf" />
          </DialogNoPreviewContainer>
        )}
        {fileCategory === 'xls' && (
          <DialogNoPreviewContainer>
            <DialogFakePreviewImage src="images/file-preview/xls-icon.svg" alt="xls" />
          </DialogNoPreviewContainer>
        )}
        {fileCategory === 'image' && (
          dataUrl
            ? <DialogPreviewImage src={dataUrl} alt="File preview" />
            : <CircularProgress id={`Large-file-preview-${file.id}`} />
        )}
      </PreviewDialog>
    </>
  );
};

export default FilePreview;
