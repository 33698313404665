import React from 'react';
import styles from './SearchResultCommon.module.scss';

interface Props {}

const LoadingOption: React.FC<Props> = () => (
  <div className={styles.container}>
    <span>
      Loading...
    </span>
  </div>
);

export default LoadingOption;
