import get from 'lodash/get';
import safeLocalStorage from '../shared-services/safe-local-storage-service';

// Version is a string!
export enum Version {
  V1 = '1',
  V2 = '2',
  V2_BETA = '2 (beta)'
}

// assuming we're all on v2 already, so we can use it as a default one even when webVersion is not
// explicitly set for user.
const DEFAULT_VERSION = Version.V2;

export const getVersion = (): Version => get(safeLocalStorage, 'currentUser.organization.metaData.webVersion', DEFAULT_VERSION);

export const isVersion1 = (): boolean => getVersion() === Version.V1;

// Use isVersion2 when you don't care if it's a v2 or v2beta, and use isVersion2Beta when you do need
// to distinguish 2nd versions.
export const isVersion2 = (): boolean => [Version.V2, Version.V2_BETA].includes(getVersion());
export const isVersion2Beta = (): boolean => getVersion() === Version.V2_BETA;
