import helperService from '../shared-services/helper-service';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import {isVersion2} from '../common/versions';

export default function LoginService($http, $location) {
  // main utility functions
  const MODEL = window.DataModel;

  // service to return
  const service = {};

  service.integrationsURL = window.__env.integrationsURL;
  service.baseAppUrl = window.__env.baseAppUrl;

  // sends link to user's team login URL
  service.sendCompanyURL = function () {
    // modal to ask user for their email so we can send them link to login page
    const enterEmailModel = () => swal({
      title: 'Enter your email',
      text: "Please enter your email address and we'll redirect you to your team's unique login URL.",
      input: 'text',
      showCancelButton: false,
      confirmButtonText: 'Submit',
      showCloseButton: true,
      allowOutsideClick: false,
    });

    // submitted email --> grab team link and email to user
    enterEmailModel()
      .then((emailAddress) => {
        // find domain for email address
        $http({
          method: 'POST', url: `${service.integrationsURL}update-trials/find-team-member-domain.php`, headers: {'Content-Type': undefined}, data: {email: emailAddress, code: '0mQs8T5wG00b39wt5em0nXx7nZTf8bH5'},
        })
          .then((data) => {
            MODEL.show.loader = false;
            if (data) {
              const teamDomain = data;
              console.error('enteremailmodel');
              window.location.href = `https://${teamDomain}.mapmycustomers.com/app/#/login`;
            } else {
              swal("We're sorry", 'This email address is not associated with a team, try logging in as an individual', 'error');
            }
          })
          .catch(() => {
            MODEL.show.loader = false;
          });
      })
      .catch((error) => {
        helperService.logError(error);
      });
  };

  // change the user's password
  service.changePassword = function (objectId, newPass) {
    $http({
      method: 'POST', url: `${service.integrationsURL}update-trials/update-password.php`, headers: {'Content-Type': undefined}, data: {objectId, password: newPass, code: '0mQs8T5wG00b39wt5em0nXx7nZTf8bH5'},
    })
      .then(() => {
        MODEL.show.loader = false;

        swal({
          title: 'Congrats!',
          text: 'Your password has been reset. Please login at mapmycustomers.com.',
          type: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Login Now',
          closeOnConfirm: false,
        })
          .then(() => {
            window.location.href = '/login';
          })
          .catch(() => {});
      })
      .catch(() => {
        MODEL.show.loader = false;
        swal('Uh-Oh', 'This reset link is no longer valid.', 'error');
      });
  };

  // determines correct place to send user
  service.determineRedirectURI = function (redirect = 'dashboard') {
    if (isVersion2()) {
      window.location.href = `${window.__env.baseNewAppUrl}/home?mmc_token=${safeLocalStorage.accessToken}`;
      return;
    }

    MODEL.fullHost = $location.host().split('.');
    // is on localhost or staging --> let thru to normal login
    const url = !service.isLocalhostOrStaging(MODEL.fullHost[0]) ? service.baseAppUrl : '';
    window.location.href = `${url}#/${redirect}`;
  };

  // logs the user in
  service.logUserIn = function (username, password, redirect) {
    service.determineRedirectURI(redirect);
  };

  service.isLocalhostOrStaging = (subdomain) => {
    const lookup = {
      localhost: true,
      159: true,
      beta: true,
      staging: true,
      10: true,
      'mapmycustomers-dot-mmc-prod': true,
    };
    return !!lookup[subdomain];
  };

  return service;
}

LoginService.$inject = ['$http', '$location'];
