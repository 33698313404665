import template from './find-new-customers.html';
import analyticsService from '../../shared-services/analytics-service';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';
import {doesUseMiles} from '../../common/settings';

class FindNewCustomersController {
  constructor($scope, $window, $timeout, MappingService, LeadGenService) {
    Object.assign(this, {
      $scope,
      MODEL: $window.DataModel,
      MAN: $window.mmcUtils.MAN,
      localStorage: safeLocalStorage,
      $timeout,
      MappingService,
      LeadGenService,
    });
  }

  $onInit() {
    this.useMiles = doesUseMiles();
    this.$timeout(() => {
      this.currentSearchAddress = this.MODEL.MappingService.currentSearchAddress;
    }, 400);

    this.$scope.$watch('$ctrl.MODEL.MappingService.currentSearchAddress', (newValue) => {
      this.currentSearchAddress = newValue;
    }, true);

    this.currentUser = safeLocalStorage.currentUser;
    this.showSearchBlock = false;
    this.searchRadius = 20;
    this.prevScore = this.searchRadius;

    if (this.MODEL.MappingService.showOtherPins) {
      this.MappingService.showOtherPins();
    }

    /*
          Define the adapter so that it's reusable
        */
    $.fn.select2.amd.define('select2/SaveSearchAdapter', [
      'select2/utils',
      'select2/dropdown',
      'select2/dropdown/attachBody',
    ], (Utils, Dropdown, AttachBody) => {
      function SaveSearch() { }
      SaveSearch.prototype.render = function (decorated) {
        const $rendered = decorated.call(this);
        const $SaveSearch = $(
          '<button id="showSavedSearchDialog" class="btn btn-link" type="button" onclick="window.showSaveOptionDialog()"> Save Search</button>',
        );
        const $btnContainer = $('<div id="saveSearchBtn" style="padding-top:3px;">').append($SaveSearch);

        // select
        $SaveSearch.on('click', () => {
          console.log('select all clicked!!!');
        });
        $rendered.find('.select2-dropdown').prepend($btnContainer);

        return $rendered;
      };

      return Utils.Decorate(
        Utils.Decorate(
          Dropdown,
          AttachBody,
        ),
        SaveSearch,
      );
    });
  }

  $onDestroy() {
    this.showFindNewCustomers({showLoader: false, hideLeadGen: true});
  }

  removeSearchTerm(searchTerm) {
    this.LeadGenService.removeSearchTerm(searchTerm);
  }

  // find new customers page
  getSearchTerms(tag) {
    if (tag) {
      analyticsService.clicked(['Lead Gen', 'Search', 'Recent Search'], {text: tag});
    } else {
      analyticsService.clicked(['Lead Gen', 'Search', 'Input Text'], {text: this.searchTerms});
    }
    this.LeadGenService.getSearchTerms(tag, this.searchTerms);
    this.searchTerms = '';
  }

  changeSearchRadius() {
    analyticsService.clicked(['Lead Gen', 'Change Search Radius'], {value: this.searchRadius});
    return this.LeadGenService.changeSearchRadius();
  }

  checkSearchRadius() {
    if (this.searchRadius > 25 || this.searchRadius <= 0) {
      this.searchRadius = this.prevScore;
    } else {
      this.prevScore = this.searchRadius;
    }
  }

  // change current location
  changeSearchLocation() {
    analyticsService.clicked(['Lead Gen', 'Change Location'], {text: this.currentSearchAddress});
    this.LeadGenService.changeSearchLocation(this.currentSearchAddress);
  }

  // get saved searches and add it to map
  showSavedSearch(searchTerm) {
    this.LeadGenService.showSavedSearch(searchTerm);
  }

  // delete saved search
  deleteSavedSearch(searchTerm) {
    this.LeadGenService.deleteSavedSearch(searchTerm);
  }

  showOtherPins() {
    analyticsService.clicked(['Lead Gen', 'Show other pins'], {show: this.MODEL.MappingService.showOtherPins});
    if (this.MODEL.MappingService.showOtherPins) {
      this.MappingService.showOtherPins();
    } else {
      this.MAN.nukeMapContent();
      this.MappingService.setSearchPinLocation();
      this.MappingService.searchAllCurrentTerms();
    }
  }

  showSaveOptionDialog() {
    $('#lead-gen-modal').show();
  }

  removeRecentSearchTerm(deletedTerm) {
    analyticsService.clicked(['Lead Gen', 'Search', 'Remove recent search'], {text: deletedTerm});
    this.LeadGenService.removeRecentSearchTerm(deletedTerm);
  }

  getIconStyle(tag) {
    return {color: `${this.MODEL.MappingService.colorMapping[(this.MODEL.MappingService.searchTermColors[tag])]}`};
  }

  getTagStyle(tag) {
    return {
      border: `solid 0.5px ${this.MODEL.MappingService.colorMapping[(this.MODEL.MappingService.searchTermColors[tag])]}`,
      color: `${this.MODEL.MappingService.colorMapping[(this.MODEL.MappingService.searchTermColors[tag])]}`,
    };
  }

  onSearchTermDivMouseEnter() {
    this.showSearchBlock = true;
  }

  onSearchTermDivMouseLeave() {
    this.showSearchBlock = false;
  }

  closeFindNewCustomers() {
    analyticsService.canceled('Lead Gen');
    this.MODEL.MappingService.findNewCustomers = false;
  }
}

const findNewCustomers = {
  bindings: {
    showFindNewCustomers: '&',
  },
  controller: 'FindNewCustomersController',
  template,
};

FindNewCustomersController.$inject = [
  '$scope', '$window', '$timeout', 'MappingService', 'LeadGenService',
];

export {FindNewCustomersController, findNewCustomers};
