export const colorsToHex = {
  black: '#464646',
  green: '#32cc32',
  blue: '#4A90E2',
  grey: '#ACACAC',
  orange: '#FF6F1B',
  pink: '#FF6F9C',
  purple: '#B549A2',
  red: '#FF5D6C',
  teal: '#008080',
  yellow: '#FFA700',
  'neon red': '#FF0000',
  'neon yellow': '#F3F315',
  'neon green': '#AEFF14',
  'neon blue': '#4D4DFF',
  'neon purple': '#EA6FFF',
  lavender: '#E6E6FF',
  violet: '#951CE4',
  turquoise: '#40E0D0',
  peach: '#FFDAB9',
  navy: '#000080',
  olive: '#556B2F',
  cyan: '#00FFFF',
  gold: '#DAA520',
  maroon: '#AA133F',
  brown: '#CB0303',
  white: '#ffffff',
};

const colorNameToHex = (color: keyof typeof colorsToHex): string => colorsToHex[color] || color;

export default colorNameToHex;
