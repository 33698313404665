import React, {ChangeEvent, ReactNode, useCallback} from 'react';
import {Col, Input, Row} from 'antd';
import {InputProps} from 'antd/lib/input/Input';
import Labeled, {LabeledFieldProps} from '../Labeled';
import ErrorRow from '../ErrorRow';

interface Props
  extends Omit<InputProps, 'onChange'>,
    Omit<LabeledFieldProps, 'children'> {
  error?: ReactNode;
  onChange?: (value: string, event: ChangeEvent<HTMLInputElement>) => void;
}

const TextField: React.FC<Props> = ({
  error,
  label,
  labelPosition,
  onChange,
  sideLabelSpan,
  ...props
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      onChange && onChange(event.target.value, event),
    [onChange],
  );

  return (
    <Labeled
      label={label}
      labelPosition={labelPosition}
      sideLabelSpan={sideLabelSpan}
    >
      <Col span={24}>
        <Row>
          <Input onChange={handleChange} {...props} />
        </Row>
        {error && <ErrorRow>{error}</ErrorRow>}
      </Col>
    </Labeled>
  );
};

export default TextField;
