import styled from 'styled-components';

const PlanPrice = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 200px;
  padding: 20px;
  align-items: flex-start;
`;

export default PlanPrice;
