import React, {useMemo} from 'react';
import AntDLayout from 'antd/es/layout';
import OrganizationLogo from './component/OrganizationLogo';
import Menu from './component/Menu';
import GlobalSearch from './component/GlobalSearch';
import RightMenu from './component/RightMenu';
import UserMenu from './component/UserMenu';
import SearchButton from './component/SearchButton';
import useBoolean from '../../hook/useBoolean';
import withStore from '../../hoc/withStore';
import complementFileUrl from '../../util/complementFileUrl';
import styles from './NavigationBar.module.scss';
import {hasNewMaps} from '../../../common/features';

interface Props {
  currentMenu?: string,
  importAllowed?: string,
  organizationLogoUrl?: string;
}

export const NavigationBar: React.FC<Props> = ({
  currentMenu,
                                                 importAllowed,
  organizationLogoUrl,
}) => {
  const logoUrl = useMemo(() => complementFileUrl(organizationLogoUrl) ?? 'images/logo.svg', [organizationLogoUrl]);
  const [isSearchVisible, , , toggleSearch] = useBoolean(false);

  return (
    <AntDLayout.Header className={styles.container}>
      <section className={styles.leftSection}>
        <OrganizationLogo logoUrl={logoUrl} />
        <Menu currentMenu={currentMenu} />
      </section>
      <section className={styles.rightSection}>
        <div className={styles.search}>
          <GlobalSearch />
        </div>
        <div className={styles.secondSearch}>{isSearchVisible ? <GlobalSearch /> : null}</div>
        <div className={styles.searchButton}>
          <SearchButton onClick={toggleSearch} />
        </div>
        <RightMenu importAllowed={importAllowed}/>
        <UserMenu />
      </section>
    </AntDLayout.Header>
  );
};

interface WrapperProps {
  $route: any,
  SettingsService: any,
}

const NavbarWrapper: React.FC<WrapperProps> = ({$route, SettingsService}) => {
  let logoPath = SettingsService.getOrganizationSetting('logo');
  let importAllowed = SettingsService.getOrganizationSetting('importAllowed');
  if (logoPath && logoPath.startsWith('/')) {
    logoPath = logoPath.slice(1);
  }

  const newMaps = hasNewMaps();

  return (
    <NavigationBar
      currentMenu={newMaps ? $route.current.newRootMenu : $route.current.rootMenu}
      importAllowed={importAllowed}
      organizationLogoUrl={logoPath}
    />
  );
};

export default withStore(NavbarWrapper);
