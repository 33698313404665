import angular from 'angular';
import AccountsCtrl from './accounts-controller';
import AccountsService from './accounts-service';
import {AddAccountsFormController, addAccountsForm} from './add-accounts-form/add-accounts-form-component';

export default angular.module('mapmycustomersApp.accounts', [])
  .controller('AccountsCtrl', AccountsCtrl)
  .service('AccountsService', AccountsService)
  .controller('AddAccountsFormController', AddAccountsFormController)
  .component('addAccountsForm', addAccountsForm)
  .name;
