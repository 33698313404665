import React, {useCallback} from 'react';
import Avatar from '../../../Avatar';
import {UserRef} from '../../../../type/User';
import styles from './UserProfileInfo.module.scss';
import analyticsService from "../../../../../shared-services/analytics-service";

interface Props {
  user: UserRef;
}

const UserProfileInfo: React.FC<Props> = ({user}) => {
  const handlePhotoClick = useCallback(() => {
    console.log('starting navigation so we can add profile photo');
    analyticsService.clicked(['Navbar', 'Profile Picture']);
    // hide edit logo divs
    $('#logoPhotoBox').hide();
    // show profile photo divs
    $('#accountPhotoBox').show();
    // clear logo box
    $('#profile-upload').empty();

    // @ts-ignore
    window.initCroppieForProfilePhoto();
  }, []);


  return (
    <div className={styles.userProfile}>
      <Avatar user={user} />
      <div className={styles.userProfileOverlay} onClick={handlePhotoClick}>
        Edit
      </div>

      <section className={styles.userDetails}>
        <div>{user.fullName}</div>
        <div>{user.username}</div>
      </section>
    </div>
  );
};

export default UserProfileInfo;
