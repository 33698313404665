import safeLocalStorage from '../../shared-services/safe-local-storage-service';
import analyticsService from '../../shared-services/analytics-service';
import mapActions from '../../store/store-helpers';
import template from './subheader.html';
import {isVersion2, isVersion2Beta} from '../versions';

class SubheaderController {
  constructor(
    $window, $route, $location, $timeout, FilterService, MappingService, BaseController, GroupsService,
    TerritoriesService,
  ) {
    Object.assign(this, {
      $window,
      $route,
      $location,
      $timeout,
      FilterService,
      MappingService,
      GroupsService,
      TerritoriesService,
      MODEL: $window.DataModel,
      addCrmObject: BaseController.addCrmObject,
    });

    mapActions(this, ['modals']);

    this.currentRoute = this.$route.current.id;
  }

  async $onInit() {
    this.currentUser = safeLocalStorage.currentUser;
  }

  showMapView() {
    if (['contactsGroupsMapPage', 'contactsGroupsListPage'].includes(this.currentRoute)) {
      this.$location.path('/contacts/groups/map');
    } else if (['dealsMapPage', 'dealsPage'].includes(this.currentRoute)) {
      this.$location.path('/deals/map');
    } else if (['territoriesPage', 'territoriesMapPage'].includes(this.currentRoute)) {
      this.$location.path('/accounts/territories/map');
    } else if (['accountsPage', 'accountsMapPage'].includes(this.currentRoute)) {
      this.$location.path('/accounts/map');
    } else if (this.currentRoute === 'routingPage') {
      if (window.isOnPage('accounts')) {
        this.$location.path('/accounts/routing/map');
      } else {
        this.$location.path('/contacts/routing/map');
      }
    } else if (['accountsRoutingMapPage'].includes(this.currentRoute)) {
      this.$location.path('/accounts/routing/map');
    } else if (['contactsRoutingMapPage'].includes(this.currentRoute)) {
      this.$location.path('/contacts/routing/map');
    } else if (['accountsGroupsListPage', 'accountsGroupsMapPage'].includes(this.currentRoute)) {
      this.$location.path('/accounts/groups/map');
    } else if (['contactsPage', 'contactsMapPage'].includes(this.currentRoute)) {
      this.$location.path('/contacts/map');
    } else if (['dealsGroupsListPage', 'dealsGroupsMapPage'].includes(this.currentRoute)) {
      this.$location.path('/deals/groups/map');
    } else if (['crmActivitiesMapPage', 'crmActivitiesPage', 'crmActivitiesCalPage'].includes(this.currentRoute)) {
      this.$location.path('/activities/map');
    }
  }

  showCalendarView() {
    if (this.currentRoute === 'crmActivitiesMapPage' || this.currentRoute === 'crmActivitiesPage') {
      this.$location.path('/activities/calendar');
    }
  }

  showListView() {
    if (['contactsGroupsMapPage', 'contactsGroupsListPage'].includes(this.currentRoute)) {
      this.$location.path('/contacts/groups/list');
    } else if (['dealsMapPage', 'dealsPage'].includes(this.currentRoute)) {
      if (isVersion2Beta()) {
        window.location.href = `${window.__env.baseNewAppUrl}/deals?mmc_token=${safeLocalStorage.accessToken}`;
      } else {
        this.$location.path('/deals/list');
      }
    } else if (['territoriesPage', 'territoriesMapPage'].includes(this.currentRoute)) {
      this.$location.path('/accounts/territories/list');
    } else if (['accountsPage', 'accountsMapPage'].includes(this.currentRoute)) {
      if (isVersion2()) {
        window.location.href = `${window.__env.baseNewAppUrl}/company?mmc_token=${safeLocalStorage.accessToken}`;
      } else {
        this.$location.path('/accounts/list');
      }
    } else if (['routingPage'].includes(this.currentRoute)) {
      if (window.isOnPage('accounts')) {
        this.$location.path('/accounts/routing/list');
      } else {
        this.$location.path('/contacts/routing/list');
      }
    } else if (['accountsRoutingMapPage'].includes(this.currentRoute)) {
      this.$location.path('/accounts/routing/list');
    } else if (['contactsRoutingMapPage'].includes(this.currentRoute)) {
      this.$location.path('/contacts/routing/list');
    } else if (['accountsGroupsListPage', 'accountsGroupsMapPage'].includes(this.currentRoute)) {
      this.$location.path('/accounts/groups/list');
    } else if (['contactsPage', 'contactsMapPage'].includes(this.currentRoute)) {
      if (isVersion2()) {
        window.location.href = `${window.__env.baseNewAppUrl}/people?mmc_token=${safeLocalStorage.accessToken}`;
      } else {
        this.$location.path('/contacts/list');
      }
    } else if (['dealsGroupsListPage', 'dealsGroupsMapPage'].includes(this.currentRoute)) {
      this.$location.path('/deals/groups/list');
    } else if (['crmActivitiesMapPage', 'crmActivitiesPage', 'crmActivitiesCalPage'].includes(this.currentRoute)) {
      this.$location.path('/activities/list');
    }
  }

  showFilter() {
    analyticsService.initiated('Filter');
    this.MODEL.show.loader = true;
    this.MODEL.show.filters = true;
    $('#subheader').hide();

    this.$timeout(() => {
      this.FilterService.setUpFilter();
      this.$window.refreshDom({loader: false}, 'show');
      this.MappingService.resetMultiplePopupData();
    });
  }

  resetFilter() {
    this.FilterService.resetFilter();
  }

  handleAddCrmObject() {
    this.addCrmObject();
  }
}

SubheaderController.$inject = [
  '$window', '$route', '$location', '$timeout', 'FilterService', 'MappingService', 'BaseController',
  'GroupsService', 'TerritoriesService',
];

const subheader = {
  bindings: {
    showFindNewCustomers: '&',
  },
  controller: 'SubheaderController',
  template,
};

export {SubheaderController, subheader};
