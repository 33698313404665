import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import {Tooltip} from 'antd';
import safeLocalStorage from '../../../shared-services/safe-local-storage-service';
import colorNameToHex, {colorsToHex} from '../../util/formatter/colorNameToHex';

interface ColorProps {
  color: keyof typeof colorsToHex,
  selected: boolean,
}
const ColorButton = styled.div<ColorProps>`
  width: 32px;
  height: 32px;
  margin: 3px;
  padding: 8px;
  display: inline-block;
  cursor: pointer;
  background-color: ${props => colorNameToHex(props.color)};
  border: 2px solid ${props => (props.selected ? '#000' : props.color)};
`;

const Container = styled.div`
  padding: 20px;
`;

interface Props {
  onChange: (color: string) => void,
}
const SelectColor: React.FC<Props> = ({onChange}) => {
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const handleSelect = useCallback(
    (color: string) => {
      setSelected(color);
      onChange(color);
    },
    [setSelected],
  );

  const colors = get(safeLocalStorage, 'currentUser.organization.plan.colors', []);

  return (
    <Container>
      {colors.map((color: string, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <Tooltip title={color} key={index}>
          <ColorButton
            key={`select-color-${color}`}
            color={color as keyof typeof colorsToHex}
            onClick={() => handleSelect(color)}
            selected={color === selected}
          >
            &nbsp;
          </ColorButton>
        </Tooltip>
      ))}
    </Container>
  );
};

export default SelectColor;
