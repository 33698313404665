import {dynamicsLogo, hubspotLogo, salesforceLogo, zohoLogo} from '../../react/scene/Integrations/components/logo';
import IntegrationService from '../../react/type/enum/IntegrationService';
import EntityType from '../../react/type/EntityType';
import {SupportedEntityType} from '../../react/type/integration/Integration';

export type ServiceDescription = {
  name: string,
  logo: string,
  type: IntegrationService,
  enabled: boolean,
  supportedEntityTypes: SupportedEntityType[],
  url?: string,
};

const SUPPORTED_SERVICES: ServiceDescription[] = [
  {
    name: 'Hubspot',
    logo: hubspotLogo,
    type: IntegrationService.HUBSPOT,
    enabled: true,
    supportedEntityTypes: [EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL, EntityType.ACTIVITY],
    url: __HUBSPOT_AUTH_URL__,
  },
  {
    name: 'Dynamics',
    logo: dynamicsLogo,
    type: IntegrationService.DYNAMICS,
    enabled: false,
    supportedEntityTypes: [],
  },
  {
    name: 'Salesforce',
    logo: salesforceLogo,
    type: IntegrationService.SALESFORCE,
    enabled: false,
    supportedEntityTypes: [],
  },
  {
    name: 'Zoho',
    logo: zohoLogo,
    type: IntegrationService.ZOHO,
    enabled: false,
    supportedEntityTypes: [],
  },
];

export default SUPPORTED_SERVICES;
