import React, {SyntheticEvent, useCallback, useState} from 'react';
import styles from './OrganizationLogo.module.scss';
import safeLocalStorage from '../../../../shared-services/safe-local-storage-service';
import analyticsService from '../../../../shared-services/analytics-service';
import {isVersion2} from '../../../../common/versions';

const getNewAppUrl = (path: string): string =>
  `${window.__env.baseNewAppUrl}${path}?mmc_token=${safeLocalStorage.accessToken}`;

interface Props {
  logoUrl?: string;
}

const OrganizationLogo: React.FC<Props> = ({logoUrl}) => {
  // This code is needed to guarantee reserving horizontal space for the logo image,
  // but also reducing this space if image turned to be smaller.
  // Without this code menu will be "jumping". With this code it can jump too, but
  // within a smaller distances :)
  // Also using CSS transitions to reduce jumping effect.
  const [width, setWidth] = useState<number>(styles.maxWidth); // default logo image width

  const handleOnLoad = useCallback(
    (e: SyntheticEvent<HTMLImageElement>) => {
      setWidth(Math.min(e.currentTarget.naturalWidth, styles.maxWidth));
    },
    [setWidth],
  );

  const handleClick = () => analyticsService.clicked('Logo Click');

  return (
    <a className={styles.container} href={isVersion2() ? getNewAppUrl('/home') : '#/dashboard'} onClick={handleClick}>
      <img
        alt="Logo"
        className={styles.logo}
        onLoad={handleOnLoad}
        src={logoUrl}
        style={{width}}
      />
    </a>
  );
};

export default OrganizationLogo;
