import React, {useCallback, useState} from 'react';
import moment from 'moment';
import ReactHighcharts from 'react-highcharts';
import {TooltipFormatterContextObject} from 'highcharts';
import {Dropdown, Menu} from 'antd';
import CardCta from '../DashCard/CardCta';
import DashCard from '../DashCard/DashCard';
import activitiesNetworkService from '../../../../../network-services/crm-activities-network-service';
import useDataLoader from '../../../../hook/useDataLoader';
import useInitializer from '../../../../hook/useInitializer';
import {getStartDate, SIMPLE_DATE_RANGE_LABELS,} from './util';
import useActivitiesChartConfig from './useActivitiesChartConfig';
import User from '../../../../type/User';
import useBoolean from '../../../../hook/useBoolean';
import SelectUsersDialog from '../../../../components/SelectEntityDialog/SelectUsersDialog';
import userDisplayName from '../../../../util/formatter/userDisplayName';
import defaultNodeIdGetter from '../../../../util/defaultNodeIdGetter';
import ActivityType from '../../../../type/ActivityType';
import SelectActivityTypesDialog from '../../../../components/SelectEntityDialog/SelectActivityTypesDialog';
import defaultNodeNameGetter from '../../../../util/defaultNodeNameGetter';
import ListResponse from '../../../../type/service/ListResponse';
import Activity from '../../../../type/Activity';
import MoreButton from '../../../../components/MoreButton/MoreButton';
import SelectField from '../../../../components/SelectField/SelectField';
import LoadingProgress from '../../../../components/LoadingProgress/LoadingProgress';

const chartStyles = {
  maxWidth: '100%',
  width: '100%',
  backgroundColor: 'white',
};

const DEFAULT_CHART_CONFIG = Object.freeze({
  chart: {
    type: 'line',
  },
  colors: ['#ff9b00', '#ff5858', '#3064e4', '#37cdcd', '#24b375', '#575bff'],
  title: null,
  legend: {
    enabled: true,
  },
  xAxis: {
    visible: true,
    type: 'category',
  },
  yAxis: {
    title: null,
    labels: {
      enabled: true,
    },
    allowDecimals: false,
    endOnTick: true,
    tickAmount: 5,
    maxPadding: 0.05,
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    useHtml: true,
    headerFormat: '<small>{point.key}</small><br/>',
    formatter(this: TooltipFormatterContextObject): string {
      return `${this.key}<br/><small>${this.y}</small>`;
    },
  },
});

interface Props {
  activityTypes: ActivityType[],
  hasActivities: boolean,
  users: User[],
}

const ActivitiesCard: React.FC<Props> = ({activityTypes, hasActivities, users}) => {
  const [selectedUsers, setSelectedUsers] = useState<User[]>(users);
  const [selectedActivityTypes, setActivityTypes] = useState<ActivityType[]>(activityTypes);

  const [dateRange, setDateRange] = useState(SIMPLE_DATE_RANGE_LABELS.length ? SIMPLE_DATE_RANGE_LABELS[0].value : '');
  const handleChangeDateRange = useCallback((value) => setDateRange(`${value}`), [setDateRange]);

  const [usersDialogVisible, showUsersDialog, hideUsersDialog] = useBoolean();
  const [activityTypesDialogVisible, showActivityTypesDialog, hideActivityTypesDialog] = useBoolean();

  const getActivities = useCallback(
    () => activitiesNetworkService.fetchAllCrmActivities(
      false,
      {
        startAt: {$gte: getStartDate(dateRange)?.toISOString(), $lte: moment().endOf('day').toISOString()},
        assigneeId: {$in: selectedUsers.map(({id}) => id)},
        crmActivityTypeId: {$in: selectedActivityTypes.map(({id}) => id)},
      },
      1,
      'startAt',
      true,
      true,
    ),
    [dateRange, selectedActivityTypes, selectedUsers],
  );
  const {isLoading, loader, data} = useDataLoader([getActivities]);
  useInitializer(loader);

  const hasFilteredActivities = data.length ? (data[0] as ListResponse<Activity>).data.length > 0 : false;
  const chartConfig = useActivitiesChartConfig(DEFAULT_CHART_CONFIG, data.length ? data[0] : undefined, dateRange);

  return (
    <DashCard
      actions={[
        <SelectField<string>
          key="date-range"
          label="Date Range"
          onChange={handleChangeDateRange}
          options={SIMPLE_DATE_RANGE_LABELS}
          size="small"
          value={dateRange}
        />,
        <Dropdown
          key="menu"
          overlay={
            <Menu>
              <Menu.Item onClick={showUsersDialog}>Select Users...</Menu.Item>
              <Menu.Item onClick={showActivityTypesDialog}>Select Activity Types...</Menu.Item>
            </Menu>
          }
          placement="bottomRight"
        >
          <MoreButton />
        </Dropdown>,
      ]}
      centerAlignContent={isLoading}
      title="Activities By Rep"
      titleIconClassName="far fa-chart-line"
    >
      {isLoading && <LoadingProgress global={false} />}
      {!isLoading && !hasFilteredActivities && !hasActivities && (
        <CardCta
          buttonCaption="Add Activity"
          onClick={() => {
            window.refreshDom({'NavBar.showCreateCompanyModal': false, 'NavBar.showCreateEditActivityModal': true, 'NavBar.showCreateDealModal': false, 'NavBar.showCreatePersonModal': false, 'NavBar.editActivityModalActivityId': undefined});
          }}
          text="Add your first activity"
        >
          <a
            href="https://support.mapmycustomers.com/hc/en-us/articles/360050260013-Activities-Overview"
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn more about activities
          </a>
        </CardCta>
      )}
      {!isLoading && !hasFilteredActivities && hasActivities && (
        <CardCta
          buttonCaption="Add Activity"
          onClick={() => {
            window.refreshDom({'NavBar.showCreateCompanyModal': false, 'NavBar.showCreateEditActivityModal': true, 'NavBar.showCreateDealModal': false, 'NavBar.showCreatePersonModal': false, 'NavBar.editActivityModalActivityId': undefined});
          }}
          text="No activities found. Add an activity or change filters."
        >
          <a
            href="https://support.mapmycustomers.com/hc/en-us/articles/360050260013-Activities-Overview"
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn more about activities
          </a>
        </CardCta>
      )}
      {!isLoading && hasFilteredActivities && (
        <ReactHighcharts
          config={chartConfig}
          // @ts-ignore domProps actually exists, but is missed in @types/react-highcharts
          domProps={{style: chartStyles}}
          isPureConfig
        />
      )}
      <SelectUsersDialog
        entities={users}
        getDisplayName={userDisplayName}
        getKey={defaultNodeIdGetter}
        id="home-page-activities-card-users-dialog"
        onConfirm={setSelectedUsers}
        onHide={hideUsersDialog}
        selectedEntities={selectedUsers}
        title="Select Users"
        visible={usersDialogVisible}
      />
      <SelectActivityTypesDialog
        entities={activityTypes}
        getDisplayName={defaultNodeNameGetter}
        getKey={defaultNodeIdGetter}
        id="home-page-activities-card-activity-types-dialog"
        onConfirm={setActivityTypes}
        onHide={hideActivityTypesDialog}
        selectedEntities={selectedActivityTypes}
        title="Select Activity Types"
        visible={activityTypesDialogVisible}
      />
    </DashCard>
  );
};

export default ActivitiesCard;
