import React from 'react';
import HeaderContainer from './HeaderContainer';
import Me from '../../../../type/Me';
import Greeting from './Greeting';
import userDisplayName from '../../../../util/formatter/userDisplayName';

interface Props {
  user: Me,
}

const HomeHeader: React.FC<Props> = ({user}) => {
  return (
    <HeaderContainer>
      <Greeting>
        Hi, {userDisplayName(user)}
      </Greeting>
    </HeaderContainer>
  );
};

export default HomeHeader;
