import get from 'lodash/get';
import BaseNetworkServiceWithPromises from './base-network-service-with-promises';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';

import envConfig from '../../env';

envConfig(window);

/**
 * Legacy NetworkService class which does swallow error messages.
 * If you don't need that (and you don't, especially for new code), use BaseNetworkServiceWithPromises
 *
 * @deprecated
 */
class BaseNetworkService {
  constructor() {
    this.MODEL = window.DataModel;
    this.integrationsURL = window.__env.integrationsURL;
  }

  async create(endPoint, payload, options = {}) {
    return BaseNetworkServiceWithPromises.create(endPoint, payload, options).catch(response => response);
  }

  async update(endPoint, payload) {
    return BaseNetworkServiceWithPromises.update(endPoint, payload).catch(response => response);
  }

  async read(endPoint, params, options = {}) {
    return BaseNetworkServiceWithPromises.read(endPoint, params, options).catch(response => response);
  }

  async delete(endPoint, payload) {
    return BaseNetworkServiceWithPromises.delete(endPoint, payload).catch(response => response);
  }

  getLimit(options) {
    return 'limit' in options ? options.limit : this.MODEL.recordsUpperLimit;
  }

  getSkip(options) {
    return 'skip' in options ? options.skip : 0;
  }

  getCount(options) {
    return 'count' in options ? options.count : 0;
  }

  getQuery(options) {
    return 'whereQuery' in options ? options.whereQuery : this.defaultQueryObject();
  }

  /**
     * returns query object for user
     * @return {object} parse query
     */
  defaultQueryObject() {
    const currentUser = safeLocalStorage.currentUser;
    // you are team owner
    let whereQuery;
    if (this.MODEL.teamOwnerUsername === get(currentUser, 'username')) {
      whereQuery = {
        username: {$in: this.MODEL.TeamsService.verifiedTeamMembers},
        deleted: {$ne: true},
      };
    } else {
      // not team owner

      const allTeam = this.MODEL.teamMembers;
      whereQuery = {
        username: {$in: allTeam},
        deleted: {$ne: true},
      };
    }
    return whereQuery;
  }
}

export default new BaseNetworkService();
