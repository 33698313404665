import React from 'react';
import LoadingProgress from '../../LoadingProgress/LoadingProgress';

export interface Props {}

const LoadingOverlay: React.FC<Props> = () => (
  <LoadingProgress global={false} />
);

export default LoadingOverlay;
