import template from './conversion-modal.html';
import './conversion-modal.scss';
import dealNetworkService from '../../network-services/deals-network-service';
import leadNetworkService from '../../network-services/leads-network-service';

class ConversionModalController {
  constructor($window, BaseController) {
    Object.assign(this, {
      $window,
      MODEL: $window.DataModel,
      BaseController,
    });
    this.entity = {
      company: {
        do: false,
        name: '',
      },
      person: {
        do: false,
        firstName: '',
        lastName: '',
      },
      deal: {
        do: false,
        name: '',
        funnel: false,
        stage: false,
      },
    };
    this.funnels = [];
    this.stages = [];
    dealNetworkService.fetchAllFunnels()
      .then(({data}) => {
        this.funnels = data;
      });
  }

  async $onInit() {
    this.multipleSelected = {};
    this.entity.company.name = this.MODEL.leadToConvert.companyEdit;
    this.entity.person.firstName = this.MODEL.leadToConvert.firstNameEdit;
    this.entity.person.lastName = this.MODEL.leadToConvert.lastNameEdit;
  }

  async selectFunnel() {
    this.entity.deal.stage = false;
    const response = await dealNetworkService.fetchStagesForFunnel(this.entity.deal.funnel.id);
    this.stages = response.data;
  }

  showError(message) {
    swal('Uh-oh', message, 'error');
  }

  canConvert() {
    const {company, person, deal} = this.entity;
    return company.do || person.do || deal.do;
  }

  async convertLead() {
    const payload = {};
    const {company, person, deal} = this.entity;
    if (company.do) {
      if (company.name.trim().length === 0) {
        this.showError('Please enter a company name.');
        return;
      }
      payload.account = {
        name: company.name,
      };
    }
    if (person.do) {
      if (person.firstName.trim().length === 0 && person.lastName.trim().length === 0) {
        this.showError('Please enter a person first or last name.');
        return;
      }
      payload.contact = {
        firstName: person.firstName,
        lastName: person.lastName,
      };
    }
    if (deal.do) {
      if (deal.name.trim().length === 0) {
        this.showError('Please enter a deal name.');
        return;
      }
      if (!deal.funnel) {
        this.showError('Please enter a deal funnel.');
        return;
      }
      if (!deal.stage) {
        this.showError('Please enter a deal stage.');
        return;
      }
      payload.deal = {
        name: deal.name,
        funnel: {id: deal.funnel.id},
        stage: {id: deal.stage.id},
      };
    }
    if (Object.keys(payload).length === 0) {
      this.showError('Please select some entity type.');
      return;
    }
    payload.id = this.MODEL.leadToConvert.id;

    await leadNetworkService.convertLead(payload.id, payload);
    this.closeConversionModal();
  }

  closeConversionModal() {
    this.BaseController.modalsActions.hideModal('conversionModal');
  }

}

ConversionModalController.$inject = ['$window', 'BaseController'];

const conversionModal = {
  bindings: {
    associateCrmObject: '&',
    quickAddCrmRecord: '&',
  },
  controller: 'ConversionModalController',
  template,
};

export {ConversionModalController, conversionModal};
