import helperService from './helper-service';
import safeLocalStorage from './safe-local-storage-service';
import userNetworkService from '../network-services/user-network-service';
import {doesUseMiles} from '../common/settings';

export default function DataService(
  $http, MappingService, FetchCRMDataService,
) {
  // main utility functions
  const {MAN} = window.mmcUtils;
  const MODEL = window.DataModel;

  // service to return
  const service = {};

  service.integrationsURL = window.__env.integrationsURL;
  service.baseAppUrl = window.__env.baseAppUrl;

  //
  // ---------------------- MAIN DATA CALLS (Top Level) ------------------------ //
  // ------------- Smaller services (other data) in scripts/data-services/ ------------- //
  //

  service.fetchData = () => userNetworkService.getUserData()
    .then((data) => {
      safeLocalStorage.currentUser = data;
      FetchCRMDataService.fetchPageData();
    });

  // inits profitwell and intercom for customer success
  service.initCustomerSuccessTools = () => {
    // intercom --> user chat
    window.intercomSettings = {
      app_id: window.__env.intercomAppId,
      name: safeLocalStorage.currentUser.fullName, // Full name
      email: safeLocalStorage.currentUser.username, // Email address
      phone: safeLocalStorage.currentUser.phone ? safeLocalStorage.currentUser.phone : '', // Phone
      company: MODEL.team ? {id: MODEL.teamObjectId, name: MODEL.team, size: MODEL.teamMembers.length} : '', // Company
      created_at: safeLocalStorage.currentUser.createdAt, // createdAt
      paid: safeLocalStorage.currentUser.paid, // paid
      user_type: safeLocalStorage.currentUser.userType, // userType (teamOwner or teamMember)
    };
    /* eslint-disable */
    (function () {
      const w = window; const ic = w.Intercom; if (typeof ic === 'function') {
        ic('reattach_activator'); ic('update', window.intercomSettings);
      } else {
        const d = document; var i = function () {
          i.c(arguments);
        }; i.q = []; i.c = function (args) {
          i.q.push(args);
        }; w.Intercom = i; function l() {
          const s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = `https://widget.intercom.io/widget/${window.__env.intercomAppId}`; const x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x);
        } if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    }());
    /* eslint-enable */
  };

  $('#teamNotificationDiv').show();

  // see if a pin is within set radius of you
  service.pinIsNearby = function (pin, customerData) {
    if (!MAN.userPosition) {
      MAN.promptForPosition(service.parseCustomers.bind(null, customerData));
      // this is a guess as to which function to call, but you should probably add this
      // code in yourself before you use this function.
      return false;
    }

    // else...
    if (MODEL.circle) {
      MODEL.circle.setMap(null);
      MODEL.circle = null;
    }

    const userLL = new google.maps.LatLng(MAN.userPosition);
    MODEL.bounds.extend({
      lat: MODEL.startingLocationPin.position.lat(),
      lng: MODEL.startingLocationPin.position.lng(),
    });

    MODEL.circle = new google.maps.Circle({
      strokeColor: 'rgb(0,180,255)',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: 'rgb(0,180,255)',
      fillOpacity: 0.25,
      map: MODEL.map,
      center: userLL,
      radius: MODEL.searchRadius * (doesUseMiles() ? 1609.344 : 1000.0),
    });

    MODEL.circle.bindTo('center', MODEL.startingLocationPin, 'position');
    // check if pin inside circle
    const pinLatLng = new google.maps.LatLng(pin.latitude, pin.longitude);
    if (google.maps.geometry.spherical.computeDistanceBetween(pinLatLng, MODEL.circle.getCenter()) <= MODEL.circle.getRadius()) {
      // note, if you get this wrong, no error is thrown for some reason, but it halts code.
      const distance = google.maps.geometry.spherical.computeDistanceBetween(userLL, pinLatLng);
      pin.distanceFromUser = distance / (doesUseMiles() ? 1609.344 : 1000);
      pin.distanceFromUser = parseFloat(pin.distanceFromUser).toFixed(0);
      pin.distanceFromUser = Number(pin.distanceFromUser);
      return true;
    }

    return false;
  };

  // redirect team to their specific domain if they have one
  service.redirectToSubdomainIfOnTeam = function () {
    // is an individual and should not be on a subdomain --> redirect to www
    if (!MODEL.teamDomain && MODEL.hostWhiteList.indexOf(MODEL.fullHost[0]) < 0) {
      window.location.href = `${service.integrationsURL}#/`;
    } else if (MODEL.teamDomain && (MODEL.fullHost[0] !== MODEL.teamDomain) && (MODEL.hostWhiteList.indexOf(MODEL.fullHost[0]) < 0)) {
      // if on a team, must be their correct subdomain --> else, redirect to their subdomain
      window.location.href = `https://${MODEL.teamDomain}.mapmycustomers.com/app/#/`;
    }
  };

  //
  // ---------------------- CALENDAR DATA CALLS ------------------------ //
  //

  // get the new access token from the server
  service.getCalendarAccessTokenFromServer = function () {
    return new Promise(((resolve, reject) => {
      $http({
        method: 'POST',
        url: `${service.integrationsURL}calendar/gapi-calendar-get-access-token.php`,
        headers: {'Content-Type': undefined},
        data: {
          type: 'get_token',
          username: safeLocalStorage.currentUser.username,
        },
      })
        .then((response) => {
          const currentTime = Math.round((new Date()).getTime() / 1000);
          MODEL.calendar_auth_object.access_token = response.access_token_data.access_token;
          MODEL.calendar_auth_object.acess_token_fetch_time = currentTime;
          resolve(response.access_token_data.access_token);
        })
        .catch((error) => {
          helperService.showAndLogError(error);
          reject(error);
        });
    }));
  };

  // swal for the authorization label
  const authorizationLoadingSwal = () => swal({
    title: 'Checking Authorization Status',
    text: 'Please complete the authorization process to continue...',
    html: "<div class='spinner'><div class='double-bounce1'></div><div class='double-bounce2'></div></div>",
    showCancelButton: true,
    showConfirmButton: false,
    allowOutsideClick: false,
    customClass: 'animated fadeInUpBig',
    animation: false,
  });

  // check if authorized with the calendar
  window.checkAuthorizationComplete = function () {
    return new Promise(((resolve, reject) => {
      // check authorization status nevery 2s
      const callInterval = setInterval(() => {
        throw new Error('Implement me!');
      }, 2000);

      authorizationLoadingSwal()
        .then(() => {})
        .catch((error) => {
          // cancel authorization
          if (error === 'cancel') {
            clearInterval(callInterval);
          }

          reject(error);
        });
      swal.enableButtons();
    }));
  };

  // checks if the calendar token is valid or not
  service.calendarTokenValid = function () {
    const currentTime = Math.round((new Date()).getTime() / 1000);
    // check if it has been more than an hour since the last token refresh
    // access token for calendar expires every one hour hence new token is required from the server every hour
    if (currentTime > MODEL.calendar_auth_object.acess_token_fetch_time + 3600) {
      return false;
    }
    return true;
  };

  // create an array from objects to use in the views
  service.processCustomFields = () => {
    // build custom fields array for the contacts view
    safeLocalStorage.currentUser.contactsCustomFieldsSortedArray = [];
    Object.keys(safeLocalStorage.currentUser.contactsCustomFields).forEach(fieldName => {
      safeLocalStorage.currentUser.contactsCustomFieldsSortedArray.push([fieldName, safeLocalStorage.currentUser.contactsCustomFields[fieldName]]);
    });
    safeLocalStorage.currentUser.contactsCustomFieldsSortedArray.sort((a, b) => a[1] - b[1]);

    // build custom fields array for the accounts view
    safeLocalStorage.currentUser.accountsCustomFieldsSortedArray = [];
    Object.keys(safeLocalStorage.currentUser.accountsCustomFields).forEach(fieldName => {
      safeLocalStorage.currentUser.accountsCustomFieldsSortedArray.push([fieldName, safeLocalStorage.currentUser.accountsCustomFields[fieldName]]);
    });
    safeLocalStorage.currentUser.accountsCustomFieldsSortedArray.sort((a, b) => a[1] - b[1]);

    // build custom fields array for the deals view
    safeLocalStorage.currentUser.dealsCustomFieldsSortedArray = [];
    Object.keys(safeLocalStorage.currentUser.dealsCustomFields).forEach(fieldName => {
      safeLocalStorage.currentUser.dealsCustomFieldsSortedArray.push([fieldName, safeLocalStorage.currentUser.dealsCustomFields[fieldName]]);
    });
    safeLocalStorage.currentUser.dealsCustomFieldsSortedArray.sort((a, b) => a[1] - b[1]);
  };

  return service;
}

DataService.$inject = [
  '$http', 'MappingService', 'FetchCRMDataService',
];
