import Field from './field';
import {dateParser} from '../parser';
import {dateFormatter} from '../formatter';
import FieldFeature from './FieldFeature';

export default class DateField extends Field {
  /**
     * @param {{name: string, displayName: string, customGridProperties?: object, features?: FieldFeature[], valueFormatter?: (entity: any, value: any) => string}} data
     */
  constructor(data) {
    super({
      features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.DATE, FieldFeature.NON_INTEGRATION],
      valueGetter: dateParser(),
      valueFormatter: dateFormatter,
      filter: {type: 'type3'},
      ...data,
    });
  }
}
