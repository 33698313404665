import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';

export default function NylasNetworkService($http, $rootScope) {
  const MODEL = window.DataModel;
  const NYLAS_API_BASE = 'https://api.nylas.com/';

  const nylasGet = async (endPoint, params = {}) => {
    try {
      const response = await $http({
        method: 'GET',
        timeout: $rootScope.apiTimeout.promise,
        url: `${NYLAS_API_BASE}${endPoint}`,
        params,
        headers: {
          Authorization: `${MODEL.CrmActivitiesService.nylasInfo.token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('read failed', error);
      throw error;
    }
  };

  const buildAuthenticationUrl = (appId, redirectUri) => {
    const queryString = $.param({
      client_id: appId,
      response_type: 'token',
      scopes: 'calendar',
      redirect_uri: redirectUri,
    });
    return `${NYLAS_API_BASE}oauth/authorize?${queryString}`;
  };

  const fetchCalendar = (id) => nylasGet(`calendars/${id}`);
  const fetchCalendars = () => nylasGet('calendars');

  const _getBackendEndpoint = (id) => {
    let endpoint = 'nylas';
    if (id) {
      endpoint += `/${id}`;
    }
    return endpoint;
  };

  const fetchConnection = (id = 'me') => BaseNetworkServiceWithPromises.read(_getBackendEndpoint(id));
  const createConnection = payload => BaseNetworkServiceWithPromises.create(_getBackendEndpoint(), payload);
  const updateConnection = payload => BaseNetworkServiceWithPromises.update(_getBackendEndpoint(payload.id), payload);
  const deleteConnection = nylasId => BaseNetworkServiceWithPromises.delete(_getBackendEndpoint(nylasId));

  return {
    fetchConnection,
    createConnection,
    updateConnection,
    deleteConnection,
    buildAuthenticationUrl,
    fetchCalendar,
    fetchCalendars,
  };
}

NylasNetworkService.$inject = ['$http', '$rootScope'];
