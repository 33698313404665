import React from 'react';
import {Map, Marker, Popup} from 'react-leaflet';
import {GoogleLayer} from 'react-leaflet-google-v2';
import 'react-leaflet-markercluster/dist/styles.min.css';
import styled from 'styled-components';
import swapLatLng from '../../../util/geo/swapLatLng';
import googleAuthProps from '../../../util/geo/googleAuthProps';
import {getMarkerIcon} from '../../../components/map-icons/icons';
import {AnyEntity} from '../../../type/AnyEntity';

const MapContainer = styled.section`
  width: 100%;
  height: 300px;
`;

interface Props {
  entity: AnyEntity,
  label?: string,
}

const MiniMap: React.FC<Props> = ({entity, label}) => {
  if (!entity.geoPoint) {
    return null;
  }

  const position = swapLatLng(entity.geoPoint.coordinates);

  return (
    <MapContainer>
      <Map center={position} zoom={13} style={{height: '100%'}} attributionControl={false}>
        <GoogleLayer maptype="roadmap" {...googleAuthProps} />
        {label && (
          <Marker
            icon={getMarkerIcon(entity)}
            position={position}
            draggable={false}
          >
            <Popup>{label}</Popup>
          </Marker>
        )}
      </Map>
    </MapContainer>
  );
};

export default MiniMap;
