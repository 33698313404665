import safeLocalStorage from '../../../../shared-services/safe-local-storage-service';
import {listReducer, ListState, ParamsAction} from '../../../util/reducers/listReducer';
import leadFieldModel from '../../../../common/field-model/lead-field-model';

const persistenceReducer = (incomingState: ListState, action: ParamsAction): ListState => {
  console.warn(incomingState);
  const state: ListState = listReducer(incomingState, action, leadFieldModel.fields);
  safeLocalStorage.leadListViewState = state;
  return state;
};

export default persistenceReducer;
