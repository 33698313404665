import axios from 'axios';
import get from 'lodash/get';
import envConfig from '../../env';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';

envConfig(window);

class BaseNetworkServiceWithPromises {
  constructor() {
    this.headers = {
      'Content-Type': 'application/json',
      'x-mmc-client': 'WEB',
    };

    axios.interceptors.response.use((response) => response, (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        // only re-login if it was a 401 from our backend
        if (error.response.config.url.includes(window.__env.BaseURL)) {
          this.expiredTokenLogout();
        }
        originalRequest._retry = true;
        return Promise.reject(error);
      } if ((error.response.status === 209 || error.response.status === 403) && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          const currentUser = safeLocalStorage.currentUser;

          if (!currentUser || (!currentUser.id || !currentUser.username)) {
            this.expiredTokenLogout();
            return Promise.reject(error);
          }
        } catch (err) {
          console.error(err);
          return Promise.reject(error);
        }
      }
      return Promise.reject(get(error, 'response.data') || error);
    });
  }

  expiredTokenLogout() {
    safeLocalStorage.clear();
    window.DataModel.loginErrorMessage = 'Your session has expired. Please login again to continue - your security is our top concern.';
    window.location.href = '#/login';
  }

  /**
     * Merges objects recursively.
     * Compare mergeObjects and Object.assign:
     *   > Object.assign({x: 1, y: {z: 4, p: 2}}, {y: {p: 1111, q: 999}})
     *   > { x: 1, y: { p: 1111, q: 999 } }    // lost y.z
     *   > mergeObjects({x: 1, y: {z: 4, p: 2}}, {y: {p: 1111, q: 999}})
     *   > { x: 1, y: { z: 4, p: 1111, q: 999 } }
     */
  mergeObjects(...objs) {
    return objs.reduce((result, obj) => {
      Object.keys(obj).forEach(key => {
        if (!(key in result) || obj[key] === null) {
          result[key] = obj[key];
        } else if (typeof obj[key] === 'object') {
          this.mergeObjects(result[key], obj[key]);
        } else {
          result[key] = obj[key];
        }
      });
      return result;
    });
  }

  async create(endPoint, payload, options = {}) {
    try {
      const response = await axios(this.mergeObjects(
        {
          method: 'POST',
          url: `${window.__env.BaseURL}${endPoint}`,
          headers: {
            ...this.headers,
            Authorization: `Bearer ${safeLocalStorage.accessToken}`,
          },
          data: payload,
        },
        options,
      ));
      return response.data;
    } catch (error) {
      console.error('create failed', error);
      throw error;
    }
  }

  async update(endPoint, payload) {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${window.__env.BaseURL}${endPoint}`,
        headers: {
          ...this.headers,
          Authorization: `Bearer ${safeLocalStorage.accessToken}`,
        },
        data: payload,
      });
      return response.data;
    } catch (error) {
      console.error('update failed', error);
      throw error;
    }
  }

  async read(endPoint, params, options = {}) {
    try {
      const response = await axios(this.mergeObjects(
        {
          method: 'GET',
          url: `${window.__env.BaseURL}${endPoint}`,
          params,
          headers: {
            ...this.headers,
            Authorization: `Bearer ${safeLocalStorage.accessToken}`,
            'x-compression': true,
          },
        },
        options,
      ));
      return response.data;
    } catch (error) {
      console.error('read failed', error);
      throw error;
    }
  }

  async delete(endPoint, payload) {
    try {
      return await axios({
        method: 'DELETE',
        url: `${window.__env.BaseURL}${endPoint}`,
        headers: {
          ...this.headers,
          Authorization: `Bearer ${safeLocalStorage.accessToken}`,
        },
        data: payload,
      });
    } catch (error) {
      console.error('delete failed', error);
      throw error;
    }
  }
}

export default new BaseNetworkServiceWithPromises();
