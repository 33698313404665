import styled from 'styled-components';

const SmallPreviewContainer = styled.div`
  font-size: 1.2rem;
  padding: 16px;
  margin: 8px 0;
  border-radius: 4px;
  font-weight: 400;
  border: 1px solid #eee;
  cursor: pointer;
  background-color: white;
  &:hover {
    background-color: #eee;
  }
`;

export default SmallPreviewContainer;
