import addPinTemplate from '../views/shared-views/add-pin.html';

import editCustomersTemplate from '../views/shared-views/edit-customers.html';

import addAccountsTemplate from '../views/shared-views/add-accounts.html';
import editAccountsTemplate from '../views/shared-views/edit-accounts.html';

import addLeadsTemplate from '../views/shared-views/add-leads.html';

import mapTemplate from './mapping/map.html';

import addDealsTemplate from '../views/shared-views/add-deals.html';
import editDealsTemplate from '../views/shared-views/edit-deals.html';
import dealsTemplate from '../views/deals/deals.html';
import dealsMapTemplate from '../views/deals/dealsMap.html';

import groupsMapTemplate from '../views/groups/groupsMap.html';
import groupsTemplate from '../views/groups/groups.html';
import groupsListTemplate from '../views/groups/groupsList.html';

import addEditCrmActivityTemplate from './crm-activities/add-edit-crm-activity.html';
import crmActivitiesTemplate from './crm-activities/crm-activities.html';
import crmActivitiesCalTemplate from './crm-activities/crm-activities-cal.html';
import crmActivitiesCalSettingsTemplate from '../views/crm-activities/crm-activities-cal-settings.html';

import territoriesTemplate from '../views/territories/territories.html';
import territoriesMapTemplate from '../views/territories/territoriesMap.html';

import importNewTemplate from '../views/import-new.html';
import integrationsTemplate from '../views/integrations.html';
import addIntegrationTemplate from '../views/add-integration.html';
import editIntegrationTemplate from '../views/edit-integration.html';

import loginTemplate from '../views/login.html';
import forgotPasswordTemplate from '../views/forgot-password.html';
import resetPasswordTemplate from '../views/reset-password.html';
import registerTemplate from '../views/register.html';

import accountsTemplate from '../views/accounts.html';
import contactsTemplate from '../views/contacts.html';
import leadsTemplate from '../views/leads.html';
import editLeadsTemplate from '../views/shared-views/edit-leads.html';
import reportsTemplate from './reports/reports.html';

import settingsTemplate from '../views/settings/settings.html';
import emailUnsubscribeTemplate from '../views/email-unsubscribe.html';
import teamsTemplate from './dashboard/teams/create-new-team.html';
import onboardingTemplate from '../views/onboarding.html';

import dashboardTemplate from './dashboard/dashboard.html';
import dashboardTeamsTemplate from './dashboard/teams/teams.html';

import routingTemplate from '../views/routing/routing.html';
import routingMapTemplate from './mapping/routing/routingMap.html';

import fourOhFourtemplate from '../views/404.html';

AppConfig.$inject = [
  '$routeProvider', '$httpProvider', '$locationProvider', '$compileProvider', 'mmcConst',
];

export default function AppConfig(
  $routeProvider, $httpProvider, $locationProvider, $compileProvider, {EntityType},
) {
  $httpProvider.interceptors.push('httpInterceptor');
  // remove debugging info (ng-scope and ng-isolate-scope)
  if (!/localhost/.test(window.__env.baseAppUrl)) {
    $compileProvider.debugInfoEnabled(false);
  }

  // We use AngularJS dependency injection to fetch the route provider.
  // The route provider is used to setup our app"s routes.

  // The config below simply says when you visit "/" it"ll render
  // the views/main.html template controlled by the MainCtrl controller.

  // The otherwise method specifies what the app should do if it doesn"t recognise
  // the route entered by a user. In this case, redirect to home.
  $routeProvider
    .when('/', {
      redirectTo: '/dashboard',
    })
    .when('/accounts/', {
      redirectTo: '/accounts/map',
      entityType: EntityType.ACCOUNT,
    })
    .when('/accounts/map', {
      template: mapTemplate,
      id: 'accountsMapPage',
      controller: 'MappingCtrl',
      metadata: {
        title: 'Companies Map',
        description: 'Visualize your companies all in one place.',
        keywords: 'map, add, customers, import, leads',
      },
      entityType: EntityType.ACCOUNT,
      hasMap: true,
      rootMenu: 'map',
    })

    .when('/contacts/', {
      redirectTo: '/contacts/map',
      entityType: EntityType.CONTACT,
    })

    .when('/contacts/add', {
      template: addPinTemplate,
      id: 'addContactsPage',
      controller: 'AddEditCtrl',
      entityType: EntityType.CONTACT,
      metadata: {
        title: 'Add Person',
        description: 'Add pins or people, find new leads, import.',
        keywords: 'map, add, customers, import, leads',
      },
      hasMap: true,
      rootMenu: 'record',
    })

    .when('/activities/add', {
      template: addEditCrmActivityTemplate,
      id: 'addActivityPage',
      controller: 'AddEditCtrl',
      entityType: EntityType.CRM_ACTIVITY,
      metadata: {
        title: 'Add Activity',
        description: 'Add or edit companies, people, deals',
        keywords: 'map, add, customers, import, leads',
      },
      hasMap: true,
      rootMenu: 'plan',
    })

    .when('/activities/edit/:recordId', {
      template: addEditCrmActivityTemplate,
      id: 'editActivityPage',
      controller: 'AddEditCtrl',
      entityType: EntityType.CRM_ACTIVITY,
      metadata: {
        title: 'Edit Activity',
        description: 'Add pins or people, find new leads, import.',
        keywords: 'map, add, customers, import, leads',
      },
      hasMap: true,
      rootMenu: 'plan',
    })

    .when('/contacts/edit/:recordId', {
      template: editCustomersTemplate,
      id: 'editContactsPage',
      controller: 'AddEditCtrl',
      entityType: EntityType.CONTACT,
      metadata: {
        title: 'Edit Person',
        description: 'Add pins or people, find new leads, import.',
        keywords: 'map, add, customers, import, leads',
      },
      hasMap: true,
      rootMenu: 'record',
    })

    .when('/deals/add', {
      template: addDealsTemplate,
      id: 'addDealsPage',
      controller: 'AddEditCtrl',
      entityType: EntityType.DEAL,
      metadata: {
        title: 'Add Deal',
        description: 'Add pins or people, find new leads, import.',
        keywords: 'map, add, customers, import, leads',
      },
      hasMap: true,
      rootMenu: 'record',
    })

    .when('/deals/edit/:recordId', {
      template: editDealsTemplate,
      id: 'editDealsPage',
      controller: 'AddEditCtrl',
      entityType: EntityType.DEAL,
      metadata: {
        title: 'Edit Deal',
        description: 'Add pins or people, find new leads, import.',
        keywords: 'map, add, customers, import, leads',
      },
      hasMap: true,
      rootMenu: 'record',
    })

    .when('/accounts/add', {
      template: addAccountsTemplate,
      id: 'addAccountsPage',
      controller: 'AddEditCtrl',
      entityType: EntityType.ACCOUNT,
      metadata: {
        title: 'Add Company',
        description: 'Add pins or companies, find new leads, import.',
        keywords: 'map, add, customers, import, leads',
      },
      hasMap: true,
      rootMenu: 'record',
    })
    .when('/leads/add', {
      template: addLeadsTemplate,
      id: 'addLeadsPage',
      controller: 'AddEditCtrl',
      entityType: EntityType.LEAD,
      metadata: {
        title: 'Add Lead',
        description: 'Add pins or companies, find new leads, import.',
        keywords: 'map, add, customers, import, leads',
      },
      hasMap: true,
      rootMenu: 'record',
    })

    .when('/accounts/edit/:recordId', {
      template: editAccountsTemplate,
      id: 'editAccountsPage',
      controller: 'AddEditCtrl',
      entityType: EntityType.ACCOUNT,
      metadata: {
        title: 'Edit Company',
        description: 'Add pins or people, find new leads, import.',
        keywords: 'map, add, customers, import, leads',
      },
      hasMap: true,
    })

    .when('/leads/edit/:recordId', {
      template: editLeadsTemplate,
      id: 'editLeadsPage',
      controller: 'AddEditCtrl',
      entityType: EntityType.LEAD,
      metadata: {
        title: 'Edit Lead',
        description: 'Add pins or people, find new leads, import.',
        keywords: 'map, add, customers, import, leads',
      },
      hasMap: true,
    })

    .when('/contacts/map', {
      template: mapTemplate,
      id: 'contactsMapPage',
      controller: 'MappingCtrl',
      entityType: EntityType.CONTACT,
      metadata: {
        title: 'People Map',
        description: 'Visualize your people all in one place.',
        keywords: 'map, add, customers, import, leads',
      },
      hasMap: true,
      rootMenu: 'map',
    })
    .when('/accounts/list', {
      template: accountsTemplate,
      id: 'accountsPage',
      controller: 'AccountsCtrl',
      entityType: EntityType.ACCOUNT,
      metadata: {
        title: 'Companies List',
        description: 'View all your companies, sort, and filter them.',
        keywords: 'companies, sorting, filter',
      },
      hasMap: false,
      rootMenu: 'record',
    })
    .when('/leads/list', {
      template: leadsTemplate,
      id: 'leadsPage',
      controller: 'AccountsCtrl',
      entityType: EntityType.LEAD,
      metadata: {
        title: 'Leads List',
        description: 'View all your leads, sort, and filter them.',
        keywords: 'leads, sorting, filter',
      },
      hasMap: false,
      rootMenu: 'record',
    })
    .when('/contacts/list', {
      template: contactsTemplate,
      id: 'contactsPage',
      controller: 'AllContactsCtrl',
      entityType: EntityType.CONTACT,
      metadata: {
        title: 'People List',
        description: 'Visualize your people all in one place.',
        keywords: 'people, sorting, filter',
      },
      hasMap: true,
      rootMenu: 'record',
    })
    .when('/deals/list', {
      template: dealsTemplate,
      id: 'dealsPage',
      controller: 'FunnelCtrl',
      entityType: EntityType.DEAL,
      metadata: {
        title: 'Deals List',
        description: 'Visualize your deals all in one funnel.',
        keywords: 'deals, opportunities, sales, numbers, organize',
      },
      hasMap: true,
      rootMenu: 'record',
    })
    .when('/deals/map', {
      template: dealsMapTemplate,
      id: 'dealsMapPage',
      controller: 'FunnelCtrl',
      entityType: EntityType.DEAL,
      metadata: {
        title: 'Deals Map',
        description: 'Visualize your deals by stage and size.',
        keywords: 'deals, opportunities, sales, numbers, organize',
      },
      hasMap: true,
      rootMenu: 'map',
    })
    .when('/accounts/groups/map', {
      template: groupsMapTemplate,
      id: 'accountsGroupsMapPage',
      controller: 'MappingCtrl',
      entityType: EntityType.ACCOUNT,
      metadata: {
        title: 'Company Groups Map',
        description: 'Group together common companies for easy filtering.',
        keywords: 'map, add, customers, import, leads',
      },
      hasMap: true,
      rootMenu: 'plan',
    })
    .when('/deals/groups/map', {
      template: groupsMapTemplate,
      id: 'dealsGroupsMapPage',
      controller: 'MappingCtrl',
      entityType: EntityType.DEAL,
      metadata: {
        title: 'Deals Groups Map',
        description: 'Group together common deals for easy filtering.',
        keywords: 'map, add, customers, import, leads',
      },
      hasMap: true,
      rootMenu: 'plan',
    })
    .when('/contacts/groups/map', {
      template: groupsMapTemplate,
      id: 'contactsGroupsMapPage',
      controller: 'MappingCtrl',
      entityType: EntityType.CONTACT,
      metadata: {
        title: 'People Groups Map',
        description: 'Group together common people for easy filtering.',
        keywords: 'map, add, customers, import, leads',
      },
      hasMap: true,
      rootMenu: 'plan',
    })
    .when('/accounts/groups/list', {
      template: groupsTemplate,
      id: 'accountsGroupsListPage',
      controller: 'GroupsCtrl',
      entityType: EntityType.ACCOUNT,
      metadata: {
        title: 'Companies Group List',
        description: 'Organize your pins into groups.',
        keywords: 'groups, pins, map',
      },
      hasMap: false,
      rootMenu: 'plan',
    })
    .when('/deals/groups/list', {
      template: groupsTemplate,
      id: 'dealsGroupsListPage',
      controller: 'GroupsCtrl',
      entityType: EntityType.DEAL,
      metadata: {
        title: 'Deals Group List',
        description: 'Organize your pins into groups.',
        keywords: 'groups, pins, map',
      },
      hasMap: false,
      rootMenu: 'plan',
    })
    .when('/contacts/groups/list', {
      template: groupsTemplate,
      id: 'contactsGroupsListPage',
      controller: 'GroupsCtrl',
      entityType: EntityType.CONTACT,
      metadata: {
        title: 'Contacts Group List',
        description: 'Organize your pins into groups.',
        keywords: 'groups, pins, map',
      },
      hasMap: false,
      rootMenu: 'plan',
    })
    .when('/accounts/groups/list/:id', {
      template: groupsListTemplate,
      id: 'accountsGroupsAccountListPage',
      controller: 'MappingCtrl',
      entityType: EntityType.ACCOUNT,
      metadata: {
        title: 'Companies Group List',
        description: 'Organize your pins into groups.',
        keywords: 'groups, pins, map',
      },
      hasMap: true,
      rootMenu: 'plan',
    })
    .when('/deals/groups/list/:id', {
      template: groupsListTemplate,
      id: 'dealsGroupsDealListPage',
      controller: 'MappingCtrl',
      entityType: EntityType.DEAL,
      metadata: {
        title: 'Deals Groups List',
        description: 'Organize your pins into groups.',
        keywords: 'groups, pins, map',
      },
      hasMap: true,
      rootMenu: 'plan',
    })
    .when('/contacts/groups/list/:id', {
      template: groupsListTemplate,
      id: 'contactsGroupsContactListPage',
      controller: 'MappingCtrl',
      entityType: EntityType.CONTACT,
      metadata: {
        title: 'People Groups List',
        description: 'Organize your pins into groups.',
        keywords: 'groups, pins, map',
      },
      hasMap: true,
      rootMenu: 'plan',
    })
    .when('/activities/list', {
      template: crmActivitiesTemplate,
      id: 'crmActivitiesPage',
      controller: 'CrmActivitiesCtrl',
      entityType: EntityType.CRM_ACTIVITY,
      metadata: {
        title: 'Activities',
        description: '',
        keywords: 'activities, call, visit',
      },
      hasMap: true,
      rootMenu: 'plan',
    })
    .when('/activities/calendar', {
      template: crmActivitiesCalTemplate,
      id: 'crmActivitiesCalPage',
      controller: 'CrmActivitiesCtrl',
      entityType: EntityType.CRM_ACTIVITY,
      metadata: {
        title: 'Activities',
        description: '',
        keywords: 'activities, call, visit',
      },
      hasMap: true,
      rootMenu: 'plan',
    })
    .when('/activities/calendar-settings', {
      template: crmActivitiesCalSettingsTemplate,
      id: 'crmActivitiesCalSettingsPage',
      controller: 'CrmActivitiesCtrl',
      entityType: EntityType.CRM_ACTIVITY,
      metadata: {
        title: 'Activities',
        description: '',
        keywords: 'activities, call, visit',
      },
      hasMap: true,
      rootMenu: 'plan',
    })
    .when('/accounts/territories/list', {
      template: territoriesTemplate,
      id: 'territoriesPage',
      controller: 'TerritoriesCtrl',
      metadata: {
        title: 'Territories List',
        description: 'All your territories for your customers in one neat list.',
        keywords: 'lasso, territory, map',
      },
      hasMap: true,
      rootMenu: 'map',
    })
    .when('/accounts/territories/map', {
      template: territoriesMapTemplate,
      id: 'territoriesMapPage',
      controller: 'MappingCtrl',
      metadata: {
        title: 'Territories Map',
        description: 'Visualize all your various territories for your customers.',
        keywords: 'lasso, territory, map',
      },
      hasMap: true,
      rootMenu: 'map',
    })
    .when('/find-new-customers', {
      template: "<find-new-customers id='findNewCustomers' show-find-new-customers='showFindNewCustomers(showloader, hideLeadGen)' />",
      id: 'findNewCustomers',
      controller: 'MappingCtrl',
      metadata: {
        title: 'Find New Customers',
        description: 'Find new customers to add to your map.',
        keywords: 'new, customers, map, radius',
      },
      hasMap: true,
      rootMenu: 'map',
    })
    .when('/import', {
      template: importNewTemplate,
      id: 'importNewPage',
      controller: 'ImportCtrl',
      metadata: {
        title: 'Import',
        description: 'Import new customers, people, or deals from or csv here.',
        keywords: 'import, contacts, pins, leads, customers',
      },
      hasMap: false,
      rootMenu: 'manage',
    })
    .when('/integrations', {
      template: integrationsTemplate,
      id: 'integrationsPage',
      controller: 'IntegrationsCtrl',
      metadata: {
        title: 'CRM Integrations',
        description: 'Import new companies, people, or deals directly from your CRM here.',
        keywords: 'crms, integrations, third, party, salesforce, hubspot, quickbooks, infusionsoft',
      },
      hasMap: false,
      rootMenu: 'manage',
    })
    .when('/integrations/add', {
      template: addIntegrationTemplate,
      id: 'addIntegrationPage',
      controller: 'IntegrationsCtrl',
      metadata: {
        title: 'CRM Integrations',
        description: 'Import new companies, people, or deals directly from your CRM here.',
        keywords: 'crms, integrations, third, party, salesforce, hubspot, quickbooks, infusionsoft',
      },
      hasMap: false,
    })
    .when('/integrations/edit/:integrationId', {
      template: editIntegrationTemplate,
      id: 'editIntegrationPage',
      controller: 'IntegrationsCtrl',
      metadata: {
        title: 'CRM Integrations',
        description: 'Import new companies, people, or deals directly from your CRM here.',
        keywords: 'crms, integrations, third, party, salesforce, hubspot, quickbooks, infusionsoft',
      },
      hasMap: false,
    })
    .when('/login', {
      template: loginTemplate,
      id: 'loginPage',
      controller: 'LoginCtrl',
      metadata: {
        title: 'Login',
        description: 'Login to map my customers.',
        keywords: 'login, map, customers',
      },
      hasMap: false,
    })
    .when('/forgot-password', {
      template: forgotPasswordTemplate,
      id: 'forgotPassword',
      controller: 'LoginCtrl',
      metadata: {
        title: 'Forgot Password',
        description: 'Forgot password to map my customers.',
        keywords: 'forgot, password, map, customers',
      },
      hasMap: false,
    })
    .when('/reset-password', {
      template: resetPasswordTemplate,
      id: 'resetPassword',
      controller: 'LoginCtrl',
      metadata: {
        title: 'Reset Password',
        description: 'Reset password to map my customers.',
        keywords: 'reset, password, map, customers',
      },
      hasMap: false,
    })
    .when('/register', {
      template: registerTemplate,
      id: 'registerPage',
      controller: 'RegisterCtrl',
      metadata: {
        title: 'Register',
        description: 'Register for map my customers.',
        keywords: 'register, map, customers',
      },
      hasMap: false,
    })
    .when('/contacts/edit/:contactId', {
      template: contactsTemplate,
      id: 'contactsEditPage',
      controller: 'AllContactsCtrl',
      entityType: EntityType.CONTACT,
      metadata: {
        title: 'Edit Person',
        description: 'View all your people, sort, and filter them.',
        keywords: 'people, sorting, filter',
      },
      hasMap: true,
      rootMenu: 'record',
    })
    .when('/reports', {
      template: reportsTemplate,
      id: 'reportsPage',
      controller: 'ReportsCtrl',
      metadata: {
        title: 'Reports',
        description: 'Build, save, and download custom reports.',
        keywords: 'reports, details, filter',
      },
      hasMap: false,
      rootMenu: 'reports',
    })
    .when('/settings', {
      template: settingsTemplate,
      id: 'settingsPage', // also the settings page.
      controller: 'AccountCtrl',
      metadata: {
        title: 'Settings',
        description: 'view information about your account and upgrading.',
        keywords: 'info, account, upgrade, blog',
      },
      hasMap: false,
      rootMenu: 'manage',
    })
    .when('/upgrade', {
      template: '<upgrade></upgrade>',
      id: 'upgradePage',
      controller: 'AccountCtrl',
      metadata: {
        title: 'Upgrade',
        description: 'View pricing information about upgrading your account.',
        keywords: 'pricing, upgrade, store',
      },
      hasMap: false,
    })
    .when('/checkout', {
      template: '<checkout-page></checkout-page>',
      id: 'checkoutPage',
      metadata: {
        title: 'Checkout',
        description: 'Submit payment information and upgrade your account.',
        keywords: 'checkout, upgrade, store',
      },
      hasMap: false,
    })
    .when('/email-unsubscribe', {
      template: emailUnsubscribeTemplate,
      id: 'emailUnsubscribePage',
      controller: 'MappingCtrl',
      metadata: {
        title: 'Unsubscribe From Emails',
        description: '',
        keywords: 'email, unsubscribe',
      },
      hasMap: false,
    })
    .when('/create-team', {
      template: teamsTemplate,
      id: 'createTeamPage',
      controller: 'DashboardCtrl',
      metadata: {
        title: 'Create A Team',
        description: 'Start a map my customers team to easily share and give access to your pins.',
        keywords: 'team, share, rights, track, locations, activity',
      },
      hasMap: false,
      rootMenu: 'manage',
    })
    .when('/onboarding', {
      template: onboardingTemplate,
      id: 'onboardingPage',
      controller: 'MappingCtrl',
      metadata: {
        title: 'Onboarding',
        description: 'Get started using Map My Customers.',
        keywords: 'onboarding, tutorial, how, to',
      },
      hasMap: true,
    })
    .when('/dashboard', {
      template: dashboardTemplate,
      id: 'dashboardPage',
      controller: 'DashboardCtrl',
      metadata: {
        title: 'Dashboard',
        description: 'View your operation from a high-level: notes, charts, activities and more.',
        keywords: 'map, dash, notes, activities',
      },
      hasMap: false,
    })
    .when('/dashboard/teams', {
      template: dashboardTeamsTemplate,
      id: 'teamPage',
      controller: 'DashboardCtrl',
      metadata: {
        title: 'Teams',
        description: 'Start a map my customers team to easily share and give access to your pins.',
        keywords: 'team, share, rights, track, locations, activity',
      },
      hasMap: true,
      rootMenu: 'manage',
    })
    .when('/contacts/routing/list', {
      template: routingTemplate,
      id: 'routingPage',
      controller: 'RoutingCtrl',
      metadata: {
        title: 'Routing',
        description: 'Find the shortest distance route between pins.',
        keywords: 'routing, pins, shortest, distance',
      },
      hasMap: true,
      rootMenu: 'plan',
      newRootMenu: 'routes',
    })
    .when('/accounts/routing/list', {
      template: routingTemplate,
      id: 'routingPage',
      controller: 'RoutingCtrl',
      metadata: {
        title: 'Routing',
        description: 'Find the shortest distance route between pins.',
        keywords: 'routing, pins, shortest, distance',
      },
      hasMap: true,
      rootMenu: 'plan',
      newRootMenu: 'routes',
    })
    .when('/contacts/routing/edit/:routeId', {
      template: routingTemplate,
      id: 'individualContactsRoutePage',
      controller: 'MappingCtrl',
      metadata: {
        title: 'Routing',
        description: 'Find the shortest distance route between pins.',
        keywords: 'routing, pins, shortest, distance',
      },
      hasMap: true,
      rootMenu: 'plan',
      newRootMenu: 'routes',
    })
    .when('/accounts/routing/edit/:routeId', {
      template: routingTemplate,
      id: 'individualAccountsRoutePage',
      controller: 'MappingCtrl',
      metadata: {
        title: 'Routing',
        description: 'Find the shortest distance route between pins.',
        keywords: 'routing, pins, shortest, distance',
      },
      hasMap: true,
      rootMenu: 'plan',
      newRootMenu: 'routes',
    })
    .when('/contacts/routing/create', {
      template: routingTemplate,
      id: 'routeCreatePage',
      controller: 'MappingCtrl',
      metadata: {
        title: 'Routing',
        description: 'Find the shortest distance route between pins.',
        keywords: 'routing, pins, shortest, distance',
      },
      hasMap: true,
      rootMenu: 'plan',
      newRootMenu: 'routes',
    })
    .when('/accounts/routing/create', {
      template: routingTemplate,
      id: 'routeCreatePage',
      controller: 'MappingCtrl',
      metadata: {
        title: 'Routing',
        description: 'Find the shortest distance route between pins.',
        keywords: 'routing, pins, shortest, distance',
      },
      hasMap: true,
      rootMenu: 'plan',
      newRootMenu: 'routes',
    })
    .when('/contacts/routing/map', {
      template: routingMapTemplate,
      id: 'contactsRoutingMapPage',
      controller: 'MappingCtrl',
      metadata: {
        title: 'Routing Map',
        description: 'Find the shortest distance route between pins.',
        keywords: 'routing, pins, shortest, distance',
      },
      hasMap: true,
      rootMenu: 'plan',
      newRootMenu: 'routes',
    })
    .when('/accounts/routing/map', {
      template: routingMapTemplate,
      id: 'accountsRoutingMapPage',
      controller: 'MappingCtrl',
      metadata: {
        title: 'Routing Map',
        description: 'Find the shortest distance route between pins.',
        keywords: 'routing, pins, shortest, distance',
      },
      hasMap: true,
      rootMenu: 'plan',
      newRootMenu: 'routes',
    })
    // doesn't recognize the route (do nothing)
    .otherwise({
      template: fourOhFourtemplate,
      id: '404',
      controller: '',
      metadata: {
        title: 'Page Not Found',
        description: 'Uh-Oh, we could not find this page!',
        keywords: 'page, not, found, missing, 404',
      },
      hasMap: false,
    });

  $locationProvider.hashPrefix('');
  // use the HTML5 History API
  // $locationProvider.html5Mode(true).hashPrefix("!");
}
