import analyticsService from '../shared-services/analytics-service';
import mapActions from '../store/store-helpers';
import helperService from '../shared-services/helper-service';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import EntityType from '../react/type/EntityType';

export default function GroupsCtrl(
  $scope, $route, MainService, BaseController, GroupsService,
) {
  // init global utility functions
  const MODEL = window.DataModel;

  // extend BaseController methods onto scope
  Object.assign($scope, BaseController);
  mapActions($scope, ['modals']);
  $scope.modalsActions.resetVisibility('noGroupsModal');

  // bind data model to scope for access in views
  $scope.data = MODEL;

  $scope.group = {
    cadence: false,
  };

  // bind current route id to scope for access in views
  $scope.currentRoute = $route.current.id;

  // set localStorage equal to $scope to get html-binding
  $scope.localStorage = safeLocalStorage;

  MainService.startChainReaction();

  // initializes the page with a list of groups or map of groups
  $scope.initPage = async function () {
    const entityTypeMap = {
      accountsGroupsListPage: {
        entityType: EntityType.COMPANY,
        viewHeader: 'Company Groups',
        listPage: 'accountsGroups',
      },
      contactsGroupsListPage: {
        entityType: EntityType.PERSON,
        viewHeader: 'People Groups',
        listPage: 'contactsGroups',
      },
      dealsGroupsListPage: {
        entityType: EntityType.DEAL,
        viewHeader: 'Deal Groups',
        listPage: 'dealsGroups',
      },
    };

    $scope.listPage = entityTypeMap[$route.current.id].listPage;
    $scope.entityType = entityTypeMap[$route.current.id].entityType;

    await GroupsService.fetchGroupsForList(entityTypeMap[$route.current.id].entityType);
    GroupsService.setViewHeaders(
      entityTypeMap[$route.current.id].viewHeader,
      MODEL.GroupsService.currentPageGroupsCount,
      false,
    );

    // get endpoints for the given page
    GroupsService.determineGroupsEndpointsForPage();
    window.refreshDom({loader: false}, 'show');
  };

  $scope.initPage();

  // toggle on/off groups (to be shown/hidden on map)
  $scope.toggleIndividualGroup = (groupId) => {
    GroupsService.toggleIndividualGroup(groupId);
  };

  // toggle all Groups
  $scope.toggleAllGroups = () => {
    GroupsService.toggleAllGroups(MODEL.GroupsService.toggleAllGroups);
  };

  // updates group object for any property changed
  $scope.updateGroup = async (group) => {
    group.cadenceInterval = group.cadenceInterval ? parseInt(group.cadenceInterval, 10) : null;
    await GroupsService.updateGroup(group);
  };

  // update the cadence interval of the group
  $scope.updateCadence = async () => {
    const group = MODEL.GroupsService.currentGroup;
    group.cadenceInterval = MODEL.GroupsService.currentGroupCadence
      ? parseInt(MODEL.GroupsService.currentGroupCadence, 10)
      : null;
    $scope.closeCadenceModal();
    await GroupsService.updateGroup(group);
  };

  // shows set group color modal
  $scope.showSetGroupColorModal = function (group) {
    MODEL.GroupsService.showSetColorModal = true;
    MODEL.GroupsService.currentGroup = group;
    $scope.populateColorPicker();
  };

  $scope.showCadenceModal = (group) => {
    if (!$scope.canEditGroup(group)) {
      return;
    }

    MODEL.GroupsService.showCadenceModal = true;
    MODEL.GroupsService.currentGroup = group;
    MODEL.GroupsService.currentGroupCadence = group.cadenceInterval;
    $scope.group.cadence = !!group.cadenceInterval;
  };

  $scope.changeCadence = () => {
    MODEL.GroupsService.currentGroupCadence = $scope.group.cadence
      ? MODEL.GroupsService.currentGroup.cadenceInterval
      : '';
  };

  // closes the set group color modal
  $scope.closeSetGroupColorModal = function () {
    $(`#color-picker-${MODEL.GroupsService.newGroupColorCanonical}`).removeClass('selected-color');
    MODEL.GroupsService.showSetColorModal = false;
    MODEL.GroupsService.currentGroup = null;
  };

  // sets the colors of the little squares in the group color modal
  $scope.populateColorPicker = () => {
    safeLocalStorage.currentUser.organization.plan.colors.forEach((color) => {
      const canonicalColor = color.replace(/ /g, '');
      if (MODEL.colorsToHex[color]) {
        $(`#color-picker-${canonicalColor}`).css('background-color', MODEL.colorsToHex[color]);
      }
    });

    $('.color-pickers').removeClass('selected-color');
    $(`#color-picker-${MODEL.GroupsService.currentGroup.color || 'no-color'}`).addClass('selected-color');
  };

  // user selected a new group color --> highlight the choice
  $scope.selectedNewGroupColor = (color) => {
    // remove highlight from old selected color
    $('.color-pickers').removeClass('selected-color');

    // set highlight on newly selected color (canonical color is required as "neon-red", "neon-blue", etc. must have dashed removed for the hex dict)
    MODEL.GroupsService.newGroupColor = color;
    MODEL.GroupsService.newGroupColorCanonical = color != null ? color.replace(/ /g, '') : 'no-color';
    $(`#color-picker-${MODEL.GroupsService.newGroupColorCanonical}`).addClass('selected-color');
    MODEL.TerritoriesService.currentTerrColor = MODEL.GroupsService.newGroupColor;

    // change css for preview circle on terrs page
    $('#previewCircle').css({
      'background-color': MODEL.TerritoriesService.currentTerrColor,
      opacity: MODEL.TerritoriesService.currentTerrOpacity,
    });
  };

  // saves the newly chosen group color
  $scope.saveEditedGroupColor = function () {
    MODEL.GroupsService.currentGroup.color = MODEL.GroupsService.newGroupColor;
    GroupsService.updateGroup(MODEL.GroupsService.currentGroup);
    MODEL.GroupsService.showSetColorModal = false;
  };

  // edit a group name modal
  $scope.showEditGroupModal = function (group) {
    MODEL.GroupsService.showEditGroupModal = true;
    MODEL.GroupsService.currentGroup = group;
    MODEL.GroupsService.currentGroup.oldGrpName = group.name;
  };

  // closes the edit group modal
  $scope.closeEditGroupModal = function () {
    MODEL.GroupsService.showEditGroupModal = false;
    MODEL.GroupsService.currentGroup.name = MODEL.GroupsService.currentGroup.oldGrpName;
    MODEL.GroupsService.currentGroup = null;
  };

  // save the new group name
  $scope.saveNewGroupName = async function (groupName) {
    MODEL.GroupsService.currentGroup.name = groupName;
    const response = await GroupsService.updateGroup(MODEL.GroupsService.currentGroup);
    if (response.code === 400) {
      helperService.showAndLogError(response);
    } else {
      window.refreshDom({'GroupsService.showEditGroupModal': false});
    }
  };

  // deletes a group
  $scope.showDeleteGroupModal = function (group) {
    MODEL.GroupsService.showDeleteGroupModal = true;
    MODEL.GroupsService.currentGroup = group;
  };

  // closes the delete group
  $scope.closeDeleteGroupModal = function () {
    MODEL.GroupsService.showDeleteGroupModal = false;
    MODEL.GroupsService.currentGroup = null;
  };

  $scope.closeCadenceModal = () => {
    MODEL.GroupsService.showCadenceModal = false;
    MODEL.GroupsService.currentGroupCadence = '';
  };

  // actually deleted the group from the db
  $scope.actuallyDeleteGroup = async () => {
    await GroupsService.deleteGroup(MODEL.GroupsService.currentGroup);
    analyticsService.entityDeleted('Group', MODEL.GroupsService.currentGroup);
    // remove group from groups table
    MODEL.GroupsService.currentPageGroups = MODEL.GroupsService.currentPageGroups.filter(({id}) => id !== MODEL.GroupsService.currentGroup.id);
    MODEL.GroupsService.currentPageGroupsCount--;
    window.refreshDom({
      'GroupsService.currentPageGroups': MODEL.GroupsService.currentPageGroups,
      'GroupsService.showDeleteGroupModal': false,
      'currentPageSubHeader.numberOfObjects': MODEL.GroupsService.currentPageGroupsCount,
    });
  };

  // show just the pins from the group on the map
  $scope.showGroupRecords = function (group) {
    MODEL.show.loader = true;
    MODEL.GroupsService.currentGroup = group;
    window.location.href = window.location.href.replace(/\/[^/]*$/, `/list/${group.id}`);
  };

  // hides the footer detail bar
  $scope.hideFooterDetailBar = function () {
    MODEL.GroupsService.showFooterDetails = false;
  };

  // Can edit if you're a group owner or a team manager/owner
  $scope.canEditGroup = (group) => group.user.id === safeLocalStorage.currentUser.id || safeLocalStorage.currentUser.role.key !== 'MEMBER';

  // sort groups
  $scope.sortGroups = function () {
    const order = MODEL.MappingService.groupsSortedABC === 'desc' ? 1 : -1;
    MODEL.MappingService.groupsSortedABC = order === 1 ? 'asc' : 'desc';

    $scope.showList($scope.listPage, MODEL.currentPageNum, 'name', true);

    // highlight header
    MainService.highlightSelectedTableHeader([], 'name');
  };

  MODEL.currentSubPage = $route.current.id.includes('Map') ? 'map' : 'list';
}

GroupsCtrl.$inject = [
  '$scope', '$route', 'MainService', 'BaseController', 'GroupsService',
];
