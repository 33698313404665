import {SupportedEntityType} from '../../../type/integration/Integration';
import EntityFieldModel from '../../../../common/field-model/entity-field-model';
import EntityType from '../../../type/EntityType';
import companyFieldModel from '../../../../common/field-model/account-field-model';
import personFieldModel from '../../../../common/field-model/contact-field-model';
import dealFieldModel from '../../../../common/field-model/deal-field-model';
import crmActivityFieldModel from '../../../../common/field-model/crm-activity-field-model';

const fieldModelByEntityType: {[key in SupportedEntityType]: EntityFieldModel} = {
  [EntityType.COMPANY]: companyFieldModel,
  [EntityType.PERSON]: personFieldModel,
  [EntityType.DEAL]: dealFieldModel,
  [EntityType.ACTIVITY]: crmActivityFieldModel,
};

export default fieldModelByEntityType;
