import React, {useCallback, useMemo} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {Button, Popconfirm, Space, Tooltip} from 'antd';
import {useTranslation} from 'react-i18next';
import BulkEditModal from '../BulkEditModal/BulkEditModal';
import entityDisplayName from '../../util/formatter/entityDisplayName';
import EntityType from '../../type/EntityType';
import {AnyEntity} from '../../type/AnyEntity';
import useBoolean from '../../hook/useBoolean';
import contactsNetworkService from '../../../network-services/contacts-network-service';
import accountsNetworkService from '../../../network-services/accounts-network-service';
import dealsNetworkService from '../../../network-services/deals-network-service';
import AddToRoute from '../AddToRouteModal';
import Person from '../../type/Person';
import Company from '../../type/Company';
import modalsSlice from '../../../store/modals';
import {isAddToRouteVisible} from '../../../store/modals/selector';
import {RootState} from '../../../store/rootReducer';
import withStore from '../../hoc/withStore';

const MultiSelectBarContainer = styled.div`
  position: fixed;
  padding: 10px 10%;
  left: 0px;
  bottom: 0px;
  width: 100%;
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2)
`;

interface Props {
  addToRouteVisible: boolean,
  entityType: CompanyPersonOrDealOrLead,
  hideModal: typeof modalsSlice.actions.hideModal,
  onReloadData: () => void,
  selectedEntities: Set<AnyEntity>,
  showModal: typeof modalsSlice.actions.showModal,
  visible: boolean,
}

type CompanyPersonOrDealOrLead = EntityType.COMPANY | EntityType.PERSON | EntityType.DEAL;

const deleteEntityMethod: {[key in CompanyPersonOrDealOrLead]: any} = Object.freeze({
  [EntityType.COMPANY]: accountsNetworkService.deleteAccounts,
  [EntityType.PERSON]: contactsNetworkService.deleteContacts,
  [EntityType.DEAL]: dealsNetworkService.deleteDeals,
});

const MultiSelectBar: React.FC<Props> = ({
  addToRouteVisible,
  entityType,
  hideModal,
  onReloadData,
  selectedEntities,
  showModal,
  visible,
}) => {
  const {t} = useTranslation(['translation', 'grid', 'add-to-route']);

  const [bulkEditVisible, showBulkEdit, hideBulkEdit] = useBoolean();
  const entityName: string = useMemo(
    () => entityDisplayName(entityType, true, selectedEntities.size > 1),
    [entityType, selectedEntities],
  );
  const handleDelete = useCallback(
    async () => {
      await deleteEntityMethod[entityType](Array.from(selectedEntities, ({id}: AnyEntity) => id));
      window.swal('Success!', t('DeleteEntityTextComplete', {count: selectedEntities.size, entityName}), 'success');
      onReloadData();
    },
    [entityType, selectedEntities, entityName, onReloadData],
  );

  const handleAddToRouteClick = useCallback(() => showModal('addToRoute'), [showModal]);
  const handleHideAddToRoute = useCallback(() => hideModal('addToRoute'), [hideModal]);

  return (
    <>
      {visible ?
        <MultiSelectBarContainer>
          <div>
            {t('EntitySelected', {count: selectedEntities.size, entityName})}
          </div>
          <Space>
            {(entityType === EntityType.PERSON || entityType === EntityType.COMPANY) && (
              <Tooltip
                placement="top"
                title={selectedEntities.size > 69 ? t('You can only add 69 stops to a route') : ''}
              >
                <Button
                  disabled={selectedEntities.size > 69}
                  type="primary"
                  onClick={handleAddToRouteClick}
                >
                  {t('AddToRoute')}
                </Button>
              </Tooltip>
            )}
            <Button type="primary" onClick={showBulkEdit}>{t('BulkEdit')}</Button>
            <Popconfirm
              placement="topLeft"
              title={t('DeleteEntityText', {count: selectedEntities.size, entityName})}
              onConfirm={handleDelete}
              okText={t('Delete')}
              cancelText={t('Cancel')}
            >
              <Button icon={<i className="far fa-trash" />}>{t('DeleteEntity', {entityName})}</Button>
            </Popconfirm>
          </Space>
        </MultiSelectBarContainer>
        : <div />
      }
      <BulkEditModal
        entities={selectedEntities}
        entityType={entityType}
        onHide={hideBulkEdit}
        visible={bulkEditVisible}
      />
      {addToRouteVisible && (entityType === EntityType.COMPANY || entityType === EntityType.PERSON) && (
        <AddToRoute
          entities={selectedEntities as Set<Company | Person>}
          entityType={entityType}
          onHide={handleHideAddToRoute}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  addToRouteVisible: isAddToRouteVisible(state),
});

const mapDispatchToProps = {
  showModal: modalsSlice.actions.showModal,
  hideModal: modalsSlice.actions.hideModal,
};

export default withStore(connect(mapStateToProps, mapDispatchToProps)(MultiSelectBar));
