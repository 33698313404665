import React from 'react';
import capitalize from 'lodash/capitalize';
import styled from 'styled-components';
import Field from '../../../../common/field-model/field/field';
import Company from '../../../type/Company';

const Color = styled.div<{backgroundColor: string}>`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${props => props.backgroundColor};
`;

const ColorDetail = styled.div`
  display: flex;
  align-items: center;
  
  & > ${Color} {
    margin-right: 8px;
  }
`;

interface Props {
  company: Company,
  field: Field,
}

const DetailField: React.FC<Props> = ({company, field}) => {
  const fieldValue = field.getEntityValue(company);
  if (field.name === 'color') {
    return (
      <ColorDetail>
        <Color backgroundColor={fieldValue} />
        {capitalize(field.getEntityFormattedValue(company))}
      </ColorDetail>
    );
  } else if (field.name === 'email') {
    return <a href={`mailto:${fieldValue}`}>{field.getEntityFormattedValue(company)}</a>;
  } else if (field.name === 'phone') {
    return <a href={`tel:${fieldValue}`}>{field.getEntityFormattedValue(company)}</a>;
  }

  return <span>{field.getEntityFormattedValue(company)}</span>;
};

export default DetailField;
