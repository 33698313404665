import React, {memo} from 'react';
import styled, {css} from 'styled-components';
import Button, {ButtonProps} from 'react-md/lib/Buttons/Button';
import {InjectedTooltipProps} from 'react-md/lib/Tooltips/injectTooltip';
import theme from '../../style/theme';

interface StyledProps {
  withMargin?: boolean
}
const Component = ({withMargin, ...props}: StyledProps & ButtonProps) => (
  <Button {...props} />
);

const StyledComponent = styled(Component)<StyledProps>`
  width: 20px;
  height: 20px;
  padding: 0;
  ${props => props.withMargin && css`margin-left: calc(${theme.gutter} / 4);`};
`;

interface Props extends InjectedTooltipProps, StyledProps {
}

const TooltipIcon = memo<Props>(({withMargin = true, ...props}) => (
  <StyledComponent
    icon
    iconClassName="fas fa-question-circle"
    inkDisabled
    withMargin={withMargin}
    {...props}
  />
));

export default TooltipIcon;
