import cloneDeep from 'lodash/cloneDeep';
import template from './custom-fields.html';
import './custom-fields.scss';

class CustomFieldsController {
  constructor($window, $scope, $timeout, CustomFieldsService) {
    Object.assign(this, {
      $window,
      $scope,
      $timeout,
      CustomFieldsService,
      MODEL: $window.DataModel,
    });

    this.fields = [];
    this.values = {};
    this.validationErrors = {};
  }

  async $onInit(force = false) {
    if (this.CustomFieldsService.init(force)) {
      await this.refreshFields();

      this.$scope.$applyAsync(() => {
        this.$timeout(() => {
          $('.mmc-custom-fields__field .datetimepicker').datetimepicker({
            allowInputToggle: true,
            format: 'MM-DD-YYYY',
          }).on('dp.change', this._handleDateTimeChange.bind(this));

          $('.mmc-custom-fields__field .timepicker')
            .on('dp.change', this._handleDateTimeChange.bind(this))
            .datetimepicker({format: 'LT'});

          this.refreshValues();
        });
      });
    }
  }

  async persistChange(fieldId) {
    const field = this.fields.find(({id}) => id === fieldId);

    if (!field) {
      throw new Error(`Internal error, unknown fieldId ${fieldId}`);
    }

    const value = this.getFormattedValues(field);
    if (value === undefined) {
      if (field.required) {
        throw new Error('Required field has no value');
      }
      return; // nothing to save here
    }

    try {
      this.validateValues(field, value);
      const result = value.id
        ? await this.CustomFieldsService.updateValue(value)
        : await this.CustomFieldsService.createValue(fieldId, Object.values(value.value));

      delete this.validationErrors[fieldId];
      return result;
    } catch (e) {
      let message = 'Something went wrong, please try again later';

      if (e && Array.isArray(e.validationErrors) && e.validationErrors.length) {
        message = e.validationErrors[0].message;
      }

      this.validationErrors[fieldId] = message;

      throw e;
    }
  }

  getFormattedValues({id, dataType}) {
    const replaceValue = value => ({...this.values[id], value});

    switch (dataType) {
      case 'single-option': {
        const value = this.values[id].value[0];
        return replaceValue(value == null ? [] : [parseInt(value, 10)]);
      }
      case 'multi-option':
        return replaceValue(this.values[id].value.map(value => parseInt(value, 10)));
      case 'date':
        return replaceValue([this.values[id].value[0]]);
      case 'monetary': {
        const currencyId = parseInt(this.values[id].value[0].currencyId, 10);
        const value = parseInt(this.values[id].value[0].value, 10);
        return replaceValue(Number.isFinite(currencyId) || Number.isFinite(value) ? [{currencyId, value}] : []);
      }
    }

    return this.values[id];
  }

  validateValues({dataType}, fieldValue) {
    if (dataType === 'monetary') {
      if (!fieldValue.value.length) {
        return;
      }
      const {currencyId, value} = fieldValue.value[0];
      if (!Number.isFinite(currencyId) && Number.isFinite(value)) {
        throw {validationErrors: [{message: 'Currency is missing'}]}; // eslint-disable-line no-throw-literal
      }
    }
  }

  async saveValues() {
    if (this.customFieldsForm.$invalid) {
      return;
    }

    try {
      await Promise.all(
        this.fields
          .filter((field) => {
            if (field.dataType === 'monetary') {
              const formFieldCurrency = this.customFieldsForm[`custom-field-${field.id}-currency`] || this.customFieldsForm[`custom-field-${field.id}-currency`];
              const formFieldAmount = this.customFieldsForm[`custom-field-${field.id}`] || this.customFieldsForm[`custom-field-${field.id}`];

              if ((formFieldCurrency && formFieldCurrency.$dirty) || (formFieldAmount && formFieldAmount.$dirty)) {
                return true;
              }
            } else if (this.customFieldsForm[`custom-field-${field.id}`]) {
              return this.customFieldsForm[`custom-field-${field.id}`].$dirty;
            }

            return false;
          })
          .map(field => field.id)
          .map(this.persistChange.bind(this)),
      );

      this.refreshValues();
    } catch (e) {
      swal('Uh-oh', 'There was a problem saving Custom Fields. Please fix errors and try again.', 'error');
      this.$scope.$digest(); // let this.validationErrors to appear on screen
      throw e;
    }
  }

  async refreshFields() {
    this.fields = await this.CustomFieldsService.getFields();
  }

  async refreshValues() {
    // clone is needed to remove connection between this.values and CustomFieldsService.fieldValuesForNewEntity
    this.values = cloneDeep(await this.CustomFieldsService.getValues());

    this.validationErrors = {};
    this.customFieldsForm.$setPristine();

    // find all date/time-type fields and update their datepickers
    const dateFieldsIds = this.fields
      .filter(({dataType}) => dataType === 'date' || dataType === 'time')
      .map(({id}) => id);

    Object.values(this.values)
      .filter(({customField}) => dateFieldsIds.includes(customField.id))
      .forEach(({customField: {id}, value}) => {
        const element = $(`#custom-field-${id}`).parent();
        let dateValue = value[0];

        if (dateValue) {
          dateValue = dateValue.includes('T')
            ? moment(dateValue)
            : moment(`${moment(new Date()).format('YYYY-MM-DD')}T${dateValue}`);
        }

        element.off('dp.change'); // disable event listeners to avoid triggering them
        element.data('DateTimePicker').date(dateValue); // set value
        element.on('dp.change', this._handleDateTimeChange.bind(this)); // re-enable listeners
      });

    this.$scope.$digest();
  }

  isOnCreatePage() {
    return this.CustomFieldsService.isOnCreatePage();
  }

  onValueChange(fieldId) {
    if (this.validationErrors[fieldId]) {
      delete this.validationErrors[fieldId];
    }

    // we only instant-save when are on the create entity (account/contact/deal) page
    if (!this.isOnCreatePage()) {
      return;
    }

    this.persistChange(fieldId);
  }

  _handleDateTimeChange(e) {
    const input = e.target.nextElementSibling;

    if (!input) {
      return;
    }

    const fieldId = parseInt($(input).data().fieldId, 10);
    let value = e.date ? moment(e.date.utc()).toISOString() : undefined;

    const field = this.fields.find(({id}) => id === fieldId);

    if (!field) {
      return;
    }

    if (field.dataType === 'time' && value) {
      value = value.substr(value.indexOf('T') + 1); // only keep the time part for time-type field
    }

    // create or update value
    if (this.values[fieldId]) {
      this.values[fieldId] = {...this.values[fieldId], value: [value]};
    } else {
      this.values[fieldId] = {customField: {id: fieldId}, value: [value]};
    }
    // angular doesn't see these changes sometimes, we're helping it by marking the field and
    // the whole form as "modified"
    this.customFieldsForm[`custom-field-${fieldId}`].$dirty = true;
    this.customFieldsForm.$setDirty();

    // let angular know the input field has updated
    input.value = value;
    input.dispatchEvent(new Event('change'));

    this.onValueChange(fieldId);
    this.$scope.$digest();
  }
}

CustomFieldsController.$inject = ['$window', '$scope', '$timeout', 'CustomFieldsService'];

const customFields = {
  bindings: {
    showViewByUser: '<',
    hasAccessRights: '<',
  },
  controller: 'CustomFieldsController',
  template,
};

export {CustomFieldsController, customFields};
