import angular from 'angular';
import CrmActivitiesService from './crm-activities-service';
import CrmActivitiesCtrl from './crm-activities-controller';
import {CalendarSettingsFormController, calendarSettingsForm} from './calendar-settings/calendar-settings-form/calendar-settings-form-component';
import {CrmActivityController, crmActivity} from './crm-activity/crm-activity-directive';
import {CrmActivitiesHeaderController, crmActivitiesHeader} from './crm-activities-header/crm-activities-header-component';
import './crm-activities.scss';

export default angular.module('mapmycustomersApp.crmActivities', [])
  .service('CrmActivitiesService', CrmActivitiesService)
  .controller('CrmActivitiesCtrl', CrmActivitiesCtrl)
  .component('calendarSettingsForm', calendarSettingsForm)
  .controller('CalendarSettingsFormController', CalendarSettingsFormController)
  .component('crmActivitiesHeader', crmActivitiesHeader)
  .controller('CrmActivitiesHeaderController', CrmActivitiesHeaderController)
  .controller('CrmActivityController', CrmActivityController)
  .directive('crmActivity', crmActivity)
  .name;
