import template from './smart-route-location-modal.html';
import mapActions from '../../../store/store-helpers';

function SmartRouteLocationModalController($window, RoutingService) {
  function $onInit() {
    this.startingAddress = this.MODEL.RoutingService.startingAddress;
    this.endingAddress = this.MODEL.RoutingService.endingAddress;
  }

  mapActions(this, ['RoutingService']);

  function closeSmartLocationPopup() {
    this.RoutingServiceActions.closeSmartLocationPopup();
  }

  function updateStartingAddress() {
    this.RoutingServiceActions.updateStartingAddress(this.startingAddress);
  }

  function updateEndingAddress() {
    this.RoutingServiceActions.updateEndingAddress(this.endingAddress);
  }

  function finishSmartRouteCreation() {
    this.RoutingService.finishSmartRouteCreation();
  }

  async function getCurrentLocation(locationType) {
    await this.RoutingService.getCurrentLocation(locationType);

    if (locationType === 'startingLocation') {
      this.startingAddress = this.MODEL.RoutingService.startingAddress;
    } else if (locationType === 'endingLocation') {
      this.endingAddress = this.MODEL.RoutingService.endingAddress;
    }
  }

  Object.assign(this, {
    $window,
    MODEL: $window.DataModel,
    RoutingService,
    closeSmartLocationPopup,
    updateStartingAddress,
    updateEndingAddress,
    finishSmartRouteCreation,
    getCurrentLocation,
    $onInit,
  });
}

SmartRouteLocationModalController.$inject = ['$window', 'RoutingService'];


function smartRouteLocationModal() {
  return {
    restrict: 'E',
    bindToController: {},
    scope: {},
    controller: 'SmartRouteLocationModalController',
    controllerAs: '$ctrl',
    template,
  };
}

export {SmartRouteLocationModalController, smartRouteLocationModal};
