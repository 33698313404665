import moment from 'moment';
import EntityFieldModel from './entity-field-model';
import EntityType from '../../react/type/EntityType';
import DateField from './field/date-field';
import {createField} from './utils';
import Activity from '../../react/type/Activity';
import FieldFeature from './field/FieldFeature';
import wholeTeamGetter from '../wholeTeamGetter';

const crmActivityFieldModel = new EntityFieldModel(EntityType.ACTIVITY, [
  {
    name: 'account',
    displayName: 'Company',
    value: ['account', 'name'],
    features: [FieldFeature.TEXT, FieldFeature.FILTERABLE],
  },
  {
    name: 'contact',
    displayName: 'Person',
    value: ['contact', 'name'],
    features: [FieldFeature.TEXT, FieldFeature.FILTERABLE],
  },
  {
    name: 'deal',
    displayName: 'Deal',
    value: ['deal', 'name'],
    features: [FieldFeature.TEXT, FieldFeature.FILTERABLE],
  },
  {
    name: 'activityType',
    displayName: 'Activity Type',
    value: ['crmActivityType', 'name'],
    filter: {type: 'type_singleselect', name: 'crmActivityTypeId', getValues: () => window.DataModel.crmActivityTypes},
    features: [FieldFeature.TEXT, FieldFeature.FILTERABLE],
  },
  {
    name: 'user',
    displayName: 'Owner',
    value: ['user', 'username'],
    filter: {type: 'type_multiselect', name: 'userId', nameField: 'username', getValues: wholeTeamGetter},
    features: [FieldFeature.TEXT, FieldFeature.FILTERABLE],
  },
  {
    name: 'name',
    displayName: 'Name',
    filter: {type: 'type1'},
    features: [FieldFeature.TEXT, FieldFeature.FILTERABLE, FieldFeature.SORTABLE],
  },
  new DateField({name: 'createdAt', displayName: 'Created At'}),
  new DateField({name: 'updatedAt', displayName: 'Modified At'}),
  new DateField({
    name: 'startAt',
    displayName: 'Start At',
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.DATE],
  }),
  new DateField({
    name: 'completedAt',
    displayName: 'Completed At',
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.DATE],
  }),
  {
    name: 'status',
    displayName: 'Status',
    valueGetter: (crmActivity: Activity) => {
      if (crmActivity.completed) {
        return 'completed';
      }
      const startAt = moment(crmActivity.startAt);
      if (!startAt.isValid()) {
        return '';
      }
      return moment().isAfter(crmActivity.startAt) ? 'overdue' : 'upcoming';
    },
    filter: {
      type: 'type_singleselect',
      getValues: () => window.DataModel.statusEnum,
    },
    features: [FieldFeature.TEXT, FieldFeature.NON_INTEGRATION],
  },
  {
    name: 'note',
    displayName: 'Note',
    features: [FieldFeature.TEXT, FieldFeature.FILTERABLE, FieldFeature.SORTABLE],
  },
  {
    name: 'duration',
    displayName: 'Duration',
    features: [FieldFeature.NUMERIC, FieldFeature.FILTERABLE, FieldFeature.SORTABLE],
  },
  {
    name: 'assignee',
    displayName: 'Assignee',
    value: ['assignee', 'username'],
    sortable: false,
    features: [FieldFeature.TEXT, FieldFeature.FILTERABLE],
  },
].map(createField));

export default crmActivityFieldModel;
