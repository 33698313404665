import get from 'lodash/get';
import invert from 'lodash/invert';
import mapActions from '../store/store-helpers';
import analyticsService from '../shared-services/analytics-service';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import customFieldsNetworkService from '../network-services/custom-fields-network-service';
import userNetworkService from '../network-services/user-network-service';
import complementFileUrl from '../react/util/complementFileUrl';

export default function AccountCtrl(
  $scope, $location, BaseController, MainService, MappingService,
  SettingsService, TeamService, CrmActivityTypesNetworkService, mmcConst,
) {
  // init global utility functions
  const UTILS = window.mmcUtils;
  const MODEL = window.DataModel;

  // set localStorage equal to $scope to get html-binding
  $scope.localStorage = safeLocalStorage;

  $scope.activeCf = 'accounts';
  $scope.visibleCustomFields = [];
  $scope.upgrade = {
    agreeToTermsAndConditions: false,
  };

  // bind data model to scope for access in views
  $scope.data = MODEL;

  // extend BaseController methods onto scope
  Object.assign($scope, BaseController);

  mapActions($scope, ['modals']);

  const setProfileData = ({
    fullName, phone, username, profilePhoto,
  }) => {
    const avatarUrl = get(profilePhoto, ['publicURI']);
    window.refreshDom({
      profileFullName: fullName,
      profilePhone: phone,
      profileEmail: username,
      profilePhoto: complementFileUrl(avatarUrl),
    }, 'SettingsService');
  };

  const showValidationError = (e, message = 'Something went wrong, please try again later') => {
    if (e && Array.isArray(e.validationErrors) && e.validationErrors.length) {
      message = e.validationErrors[0].message;
    }
    swal('Uh-oh', message, 'error');
  };

  // init controller
  $scope.initPage = async function () {
    MODEL.selectedRecords = [];
    $scope.currentUser = safeLocalStorage.currentUser;

    await MainService.startChainReaction();

    if (UTILS.isOnPage('upgradePage')) {
      // TODO: get plan data when backend is ready to return it
      window.refreshDom({loader: false}, 'show');
      return;
    }

    // get org details
    MODEL.show.loader = true;
    await TeamService.getOrgDetails();

    const settingsProperties = [
      'markerIcon',
      'markerSize',
      'sendReportCard',
      'sendOverdueActivityEmail',
      'activityAutomationSchedule',
      'activityAutomationRule',
      'activityAutomationType',
      'automaticActivities',
      'autoVisits',
    ];
    const orgSettingNames = [
      'sendTeamNotifications',
      'checkIn',
    ];

    // define variables for user + org settings
    setProfileData(safeLocalStorage.currentUser);

    orgSettingNames.forEach(name => {
      MODEL.SettingsService[name] = SettingsService.getOrganizationSetting(name);
    });
    settingsProperties.forEach(name => {
      MODEL.SettingsService[name] = name === 'autoVisits' && MODEL.SettingsService.checkIn ? false : SettingsService.getUserSetting(name);
    });

    // get check in types
    const response = await CrmActivityTypesNetworkService.getTypes(safeLocalStorage.currentUser.organization.id);
    MODEL.crmActivityTypes = response.data;

    await Promise.all([
      SettingsService.getUserRecordTotal(),
      SettingsService.getUserGroupsTotal(),
      SettingsService.refreshTerritoryCount(),
      SettingsService.getCustomFields($scope.activeCf),
    ]);
    MODEL.show.loader = false;
    return window.refreshDom({loader: false}, 'show');
  };

  $scope.initPage();

  $scope.paymentStatus = $location.search().paymentStatus;

  if ($scope.paymentStatus) {
    window.refreshDom({loader: false}, 'show');
    if ($scope.paymentStatus === 'failed') {
      swal('Oh No!', "We couldn't complete that transaction. Don't worry, your card has not been charged – try again and contact support(at)mapmycustomers.com if problems persist.", 'error');
    } else if ($scope.paymentStatus === 'success') {
      userNetworkService.getUserData()
        .then((data) => {
          swal('Congrats!', 'Your payment was processed succesfully', 'success');
          safeLocalStorage.currentUser = data;
        })
        .catch(() => {
          swal('Uh-oh', 'We were unable to update your account info. Please reload the page to reflect new plan.', 'error');
        });
    }
  }

  //
  // ------------------ SETTINGS Methods ------------------ //
  //

  // set markers as (pins/circles)
  $scope.updateMarkerIconSettings = function () {
    SettingsService.updateUserSettings('markerIcon', MODEL.SettingsService.markerIcon);
  };

  // set marker size (small/large)
  $scope.updateMarkerSizeSettings = function () {
    SettingsService.updateUserSettings('markerSize', MODEL.SettingsService.markerSize);
  };

  // shows the modal to change logo in navbar
  $scope.showLogoPhotoModal = () => {
    analyticsService.initiated('Change logo');
    MODEL.SettingsService.showLogoPhotoModal = true;
  };

  // new account photo
  $scope.submitPhoto = function (photoType) {
    SettingsService.submitPhoto(photoType);
  };

  // closes change logo modal
  $scope.closeLogoPhotoModal = () => {
    MODEL.SettingsService.showLogoPhotoModal = false;
  };

  // activity automation schedule
  $scope.updateActivityAutomationSchedule = function () {
    SettingsService.updateUserSettings('activityAutomationSchedule', MODEL.SettingsService.activityAutomationSchedule);
  };

  // activity automation rule
  $scope.updateActivityAutomationRule = function () {
    SettingsService.updateUserSettings('activityAutomationRule', MODEL.SettingsService.activityAutomationRule);
  };

  // activity automation type
  $scope.updateActivityAutomationType = function () {
    SettingsService.updateUserSettings('activityAutomationType', MODEL.SettingsService.activityAutomationType);
  };

  // enable automatic activities
  $scope.enableAutomaticActivities = function () {
    SettingsService.updateUserSettings('automaticActivities', MODEL.SettingsService.automaticActivities);
  };

  $scope.updateCheckIn = function () {
    SettingsService.updateOrgSettings('checkIn', MODEL.SettingsService.checkIn);

    if (MODEL.SettingsService.checkIn) {
      MODEL.SettingsService.autoVisits = false;
      $scope.updateAutoVisits();
    }
  };

  // enable automatic visits
  $scope.updateAutoVisits = function () {
    SettingsService.updateUserSettings('autoVisits', MODEL.SettingsService.autoVisits);
  };

  $scope.updateUserSetting = function (settingName) {
    SettingsService.updateUserSettings(settingName, MODEL.SettingsService[settingName]);
  };

  $scope.checkForSetting = function (settingName) {
    return SettingsService.checkForSetting(settingName);
  };

  // send receipt to user
  $scope.sendPaymentReceipt = function () {
    SettingsService.sendPaymentReceipt();
  };

  $scope.changeUserName = function () {
    SettingsService.changeUserName();
  };

  // show explanation (or) additional input field for "other" cancelation reason
  $scope.showOtherText = function () {
    // show other reason input field
    if ($scope.deletionOption === 'other') {
      $('#otherText').show();
    } else {
      $('#otherText').hide();

      // show explanation of why they should not still delete account
      let text;

      switch ($scope.deletionOption) {
        case 'tooExpensive':
          text = "The majority of our users are able to expense the platform to their company for business purposes. We're also able to give group discounts to non-profits and larger teams.";
          swal('Did You Know?', text);
          break;
        case 'switchedJob':
          text = "If you're moving to another sales position, we can transfer your data to a new email address or clean your account and import your new customers for free.";
          swal('Did You Know?', text);
          break;
        case 'inHouse':
          text = 'Our solution is cheaper and faster than solutions built in-house, offers more features, and can be used on any mobile device, tablet or computer. We also offer free training sessions for individuals and teams of any size.';
          swal('Did You Know?', text);
          break;
        case 'notUsing':
          text = "Our platform can be used on iOS, Android, Tablet, and Web. We also offer full CRM capabilities such as auto activities, visits, notes, charts, and reports. If you're unsure of how to properly use certain features, we offer free training sessions.";
          swal('Did You Know?', text);
          break;
        case 'neededCRMIntegration':
          text = "We offer integrations for popular CRMs: Salesforce, Quickbooks, Outlook, Infusionsoft, Microsoft Dyanmics, and Zoho. We're always adding new integrations, and are able to work with you on building the next one.";
          swal('Did You Know?', text);
          break;
      }
    }
  };

  $scope.cancelAccountPopup = () => swal('Cancel Account', 'Please call support at: +1 (919).694.6839 to cancel your account today', 'warning');

  // choose your team name
  $scope.chooseTeamName = function () {
    SettingsService.chooseTeamName($scope.teamName);
  };

  // enable show company on route
  $scope.showCompanyOnRoute = function () {
    SettingsService.showCompanyOnRoute();
  };

  // adds custom field
  $scope.addCustomFieldIfValidFromDom = function () {
    MODEL.MappingService.addingFieldFromDom = true;
    let newFieldName = document.getElementById('newFieldName').value;
    const validity = MappingService.validField(newFieldName);
    newFieldName = MappingService.sanitizeNewField(newFieldName);

    if (!validity[0]) {
      swal('Invalid Field', validity[1], validity[2]);
    } else {
      MappingService.addCustomField(newFieldName);
    }
  };

  // edit custom field chosen
  $scope.editCustomField = function (columnNumber) {
    // show edit name boxes
    $('#customFieldBox').hide();
    $('#renameCustomFieldBox').show();
    MODEL.MappingService.currentColumn = columnNumber;
    const invertedObject = invert(safeLocalStorage.currentUser.customFieldsToColumns);
    document.getElementById('newFieldNameEdit').value = invertedObject[columnNumber];
  };

  // save new custom field name
  $scope.saveNewCustomFieldName = function () {
    $('#renameCustomFieldBox').hide();
    $('#customFieldBox').show();
    MappingService.saveNewCustomFieldName();
  };

  // change custom field options
  $scope.changeCustomFieldsOptions = async (crmObjectType) => {
    $scope.activeCf = crmObjectType;
    $scope.visibleCustomFields = [];
    MODEL.show.loader = true;
    await SettingsService.getCustomFields(crmObjectType);
    window.refreshDom({loader: false}, 'show');
  };

  $scope.closeAddCFModal = () => {
    $scope.modalsActions.hideModal('addCfModal');
  };

  $scope.openCFType = (type) => {
    $scope.customFieldType = type;
    MODEL.CustomFields.textField = '';
    MODEL.CustomFields.textPlaceHolder = '';
    MODEL.CustomFields.required = false;
    MODEL.CustomFields.options = [];
    $scope.modalsActions.hideModal('addCfModal');
    $('#addCFType').show();
  };

  $scope.closeCFType = () => {
    $('#addCFType').hide();
    $scope.modalsActions.showModal('addCfModal');
  };

  const needOptions = () => MODEL.CustomFields.multiOptionFields.includes($scope.customFieldType);
  const hasOptions = () => Array.isArray(MODEL.CustomFields.options) && MODEL.CustomFields.options.length;
  const isStandardFieldName = (name) => mmcConst.standardFieldNames[$scope.activeCf].includes((name || '').trim().toLowerCase());

  $scope.isValidCustomFieldForm = () => $scope.addCustomFieldForm && $scope.addCustomFieldForm.$valid
    && ((needOptions() && hasOptions()) || !needOptions())
    && !isStandardFieldName(MODEL.CustomFields.textField);

  $scope.saveCFType = async (type) => {
    if (!$scope.isValidCustomFieldForm()) {
      return;
    }

    MODEL.show.loader = true;
    let options = [];
    if (MODEL.CustomFields.multiOptionFields.includes(type)) {
      options = MODEL.CustomFields.options.map((opt, index) => ({
        displayName: opt,
        value: index + 1,
      }));
    }
    const payload = {
      displayName: MODEL.CustomFields.textField,
      dataType: type,
      crmPropertyKey: MODEL.CustomFields.textField.toLowerCase().replace(/ /g, '_'),
      displayOrder: MODEL.orgCustomFields.length + 1,
      required: MODEL.CustomFields.required,
      options,
      readonly: false,
      defaultValue: [],
      placeholder: MODEL.CustomFields.textPlaceHolder,
    };
    try {
      await customFieldsNetworkService.create($scope.activeCf, payload);
      await $scope.changeCustomFieldsOptions($scope.activeCf);
      analyticsService.entityAdded(`Custom field: ${$scope.activeCf}`, payload);
      $scope.modalsActions.hideModal('addCfModal');
      $('#addCFType').hide();
    } catch (e) {
      showValidationError(e);
    }
    window.refreshDom({loader: false}, 'show');
  };

  $scope.addCFOptions = () => {
    if (!MODEL.CustomFields.options.includes(MODEL.CustomFields.textPlaceHolder)) {
      MODEL.CustomFields.options.push(MODEL.CustomFields.textPlaceHolder);
      MODEL.CustomFields.textPlaceHolder = '';
    }
  };

  $scope.removeCfOptions = (ind) => {
    if (MODEL.CustomFields.options.includes(ind)) {
      const index = MODEL.CustomFields.options.indexOf(ind);
      MODEL.CustomFields.options.splice(index, 1);
    }
  };

  $scope.revertProfileEdits = () => {
    setProfileData(safeLocalStorage.currentUser);
    MODEL.dirtyEditFlag = false;
  };

  $scope.saveProfileEdits = async () => {
    const fullName = MODEL.SettingsService.profileFullName.trim();
    const phone = MODEL.SettingsService.profilePhone.trim();
    const username = MODEL.SettingsService.profileEmail.trim().toLowerCase();

    if (!fullName) {
      swal('Uh-oh', "Name can't be empty", 'error');
      return;
    }

    // validate username if it was changed
    if (safeLocalStorage.currentUser.username !== username) {
      try {
        const {userExists} = await userNetworkService.userExists(username);
        if (userExists) {
          swal('Uh-oh', 'Sorry, user with this email already exists.', 'error');
          return;
        }
      } catch (e) {
        swal('Uh-oh', 'Unexpected error, please try again later.', 'error');
        return;
      }
    }

    const payload = {
      id: safeLocalStorage.currentUser.id,
      fullName,
      phone,
      username,
    };
    const {profilePhoto} = safeLocalStorage.currentUser;
    payload.profilePhoto = profilePhoto && profilePhoto.id ? profilePhoto : null;

    MODEL.show.loader = true;

    try {
      const response = await userNetworkService.updateMe(payload);

      if (response && response.id) {
        analyticsService.entityUpdated('Profile', payload);
        // backend only returns photo id in this response. we're restoring the full object
        if (response.profilePhoto && profilePhoto && response.profilePhoto === profilePhoto.id) {
          response.profilePhoto = profilePhoto;
        }

        safeLocalStorage.currentUser = {...safeLocalStorage.currentUser, ...response};
        setProfileData(response);
        window.refreshDom({dirtyEditFlag: false});
      } else {
        let message = 'Failed to save profile information, please try again later.';

        if (response && response.validationErrors && response.validationErrors.length) {
          message = response.validationErrors[0].message;
        }

        swal('Uh-oh', message, 'error');
      }
    } catch (error) {
      swal('Uh-oh', error.message, 'error');
    }

    window.refreshDom({loader: false}, 'show');
  };

  $scope.goToUserManagement = () => {
    $location.path('/dashboard/teams');
  };

  $scope.setAddCustomFieldForm = (form) => {
    $scope.addCustomFieldForm = form;
  };
}

AccountCtrl.$inject = [
  '$scope', '$location', 'BaseController', 'MainService', 'MappingService',
  'SettingsService', 'TeamService', 'CrmActivityTypesNetworkService', 'mmcConst',
];
