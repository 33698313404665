import React, {useMemo} from 'react';
import TextSearchResult from '../../../../../type/TextSearchResult';
import isSearchTextValid from './isSearchTextValid';
import InvalidSearchOption from '../components/InvalidSearchOption';
import NoResultsOption from '../components/NoResultsOption';
import SearchResultOption from '../components/SearchResultOption';
import LoadingOption from '../components/LoadingOption';

const useAutocompleteOptions = (
  loading: boolean,
  searchResults: TextSearchResult[],
  searchText: string,
) =>
  useMemo(() => {
    if (!searchText) {
      return [];
    }

    if (!isSearchTextValid(searchText)) {
      return [{value: 'invalidSearch', label: <InvalidSearchOption />}];
    }

    if (loading) {
      return [{value: 'loading', label: <LoadingOption />}];
    }

    if (!searchResults.length) {
      return [{value: 'noResults', label: <NoResultsOption />}];
    }

    return searchResults.map((result) => ({
      value: `${result.name}_${result.eId}_${result.entityId}`,
      label: <SearchResultOption result={result} />,
    }));
  }, [loading, searchResults, searchText]);

export default useAutocompleteOptions;
