import React, {useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';
import {Modal} from 'antd';
import {maxWidth} from '../../style/media';
import EntityCheckboxItem from './EntityCheckboxItem';
import useMutableSet from '../../hook/useMutableSet';
import Checkbox from '../../components/Checkbox/Checkbox';
import Search from '../../components/Input/Search';

const EntityList = styled.div`
  max-height: 33vh;
  flex-grow: 1;
  overflow: auto;
  margin-top: 10px;
  ${maxWidth.tablet`
    max-height: unset; /* let it take full height on mobiles */
  `};
`;

const NoEntitiesMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3em;
  font-weight: 600;
`;

const SearchInput = styled(Search)`
  margin-top: 10px;
`;

const defaultFilter = function defaultFilter<T>(entities: T[], searchQuery: string, getDisplayName: (e: T) => string): T[] {
  const lowerCaseQuery = searchQuery.trim().toLowerCase();
  return lowerCaseQuery
    ? entities.filter((entity) => getDisplayName(entity)?.toLowerCase().includes(lowerCaseQuery))
    : entities;
};

const defaultAllSelectCaption = (allSelected: boolean, isFiltering: boolean) =>
  (allSelected ? 'Deselect All' : 'Select All') + (isFiltering ? ' filtered items' : '');

export interface SelectEntityDialogProps<T = unknown> {
  cancelButtonLabel?: string,
  className?: string,
  confirmButtonLabel?: string,
  entities: T[],
  filter?: null | ((entities: T[], queryString: string) => T[]),
  focusOnConfirm?: boolean,
  getDisplayName: (entity: T) => string,
  getKey: (entity: T) => string,
  id: string,
  modal?: boolean,
  onCancel?: () => void,
  onConfirm?: (selectedEntities: T[]) => void,
  onHide: () => void,
  selectedEntities?: T[],
  title: string,
  visible: boolean,
}

const selectEntityDialogFactory = <T extends any>(
  noEntitiesMessage: string,
  getSelectAllMessage: (allSelected: boolean, isFiltering: boolean) => string = defaultAllSelectCaption,
): React.FC<SelectEntityDialogProps<T>> =>
    ({
      cancelButtonLabel = 'Cancel',
      className,
      confirmButtonLabel = 'Done',
      entities,
      filter,
      focusOnConfirm = true,
      getDisplayName,
      getKey,
      id,
      modal = false,
      onCancel,
      onConfirm,
      onHide,
      selectedEntities = [],
      title,
      visible,
    }) => {
      // filtering-related stuff
      const [searchQuery, setSearchQuery] = useState<string>('');
      const handleChangeSearchQuery = useCallback((value) => setSearchQuery(value), [setSearchQuery]);
      const filteredEntities = useMemo(
        () => {
          if (filter === null) {
            return entities;
          }
          return filter ? filter(entities, searchQuery) : defaultFilter(entities, searchQuery, getDisplayName);
        },
        [entities, filter, searchQuery],
      );

      // checking-related stuff
      const [checkedEntities, updateCheckedEntities, handleToggleEntity] = useMutableSet<T>(() => new Set(selectedEntities));
      const handleSelectAllCheckboxChange = useCallback(
        (checked) => updateCheckedEntities(set => {
          filteredEntities.forEach(entity => {
            if (checked) {
              set.add(entity);
            } else {
              set.delete(entity);
            }
          });
          return true;
        }),
        [filteredEntities, updateCheckedEntities],
      );

      const allSelected = filteredEntities.every(entity => checkedEntities.has(entity));
      const isFiltering = filteredEntities.length < entities.length;
      const selectAllLabel = getSelectAllMessage(allSelected, isFiltering);

      const handleConfirmClick = useCallback(
        () => {
          onHide();
          onConfirm && onConfirm(Array.from(checkedEntities));
        },
        [onConfirm, onHide],
      );

      return (
        <Modal
          title={title}
          visible={visible}
          onOk={handleConfirmClick}
          okText={confirmButtonLabel}
          onCancel={onCancel || onHide}
          cancelText={cancelButtonLabel}
          autoFocusButton={focusOnConfirm ? 'ok' : 'cancel'}
        >
          <Checkbox
            checked={allSelected}
            id={`${id}-check-all-checkbox`}
            indeterminate={allSelected ? false : filteredEntities.some(entity => checkedEntities.has(entity))}
            name={`${id}-check-all-checkbox`}
            onChange={handleSelectAllCheckboxChange}
          >
            {selectAllLabel}
          </Checkbox>
          {filter !== null && (
            <SearchInput
              value={searchQuery}
              placeholder="Search"
              onChange={handleChangeSearchQuery}
            />
          )}
          <EntityList>
            {filteredEntities.map(entity => (
              <EntityCheckboxItem
                displayName={getDisplayName(entity)}
                entity={entity}
                id={`${id}-entity-item-${getKey(entity)}`}
                key={getKey(entity)}
                checked={checkedEntities.has(entity)}
                onToggle={handleToggleEntity}
              />
            ))}
            {!filteredEntities.length && (
              <NoEntitiesMessage>{noEntitiesMessage}</NoEntitiesMessage>
            )}
          </EntityList>
        </Modal>
      );
    };

export default selectEntityDialogFactory;
