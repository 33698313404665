export default class ShowAttributes {
  constructor() {
    this._loader = true;
    this._filters = false;
    this.loadingMessage = false;
    this.integrationsModal = false;
  }

  get loader() {
    return this._loader;
  }

  set loader(value) {
    // Uncomment next line to see call stack in console when something is changing loader state
    // console.error("setLoader, value=", value);
    this._loader = value;
    if (value) {
      const loader = document.getElementById('loadingGif');
      if (document.getElementsByClassName('main-panel--no-subnav').length) {
        loader.classList.add('loading__no-subnav');
      } else {
        loader.classList.remove('loading__no-subnav');
      }
    }
  }

  get filters() {
    return this._filters;
  }

  set filters(value) {
    this._filters = value;
  }
}
