import {useMemo} from 'react';
import deepmerge from 'deepmerge';
import {StageSummary} from '../../../../../network-services/deals-network-service';

const useStageSummariesChartConfig = (
  defaultConfig: object,
  data: StageSummary[],
) =>
  useMemo(
    () => deepmerge.all([
      {},
      defaultConfig,
      {
        series: [{
          data: data.map((stageSummary: StageSummary) => ({
            name: stageSummary.stage.name,
            color: stageSummary.stage.color,
            y: stageSummary.totalValue,
          })),
        }],
      },
    ]),
    [data],
  );

export default useStageSummariesChartConfig;
