enum EntityType {
  COMPANY = 'accounts',
  PERSON = 'contacts',
  DEAL = 'deals',
  ROUTE = 'routes',
  ACTIVITY = 'crmActivities',
  COMPANY_GROUP = 'account-groups',
  COMPANY_ROUTE = 'account-route',
  PEOPLE_GROUP = 'contact-groups',
  PEOPLE_ROUTE = 'contact-route',
  DEAL_GROUP = 'deal-groups',
  TERRITORY = 'territories',
  LEAD = 'leads',
}

export default EntityType;
