import Me from '../react/type/Me';
import {ListState, initialListState} from '../react/util/reducers/listReducer';

class LocalStorage implements Storage {
  protected storage: Map<string, any>
  private _syncWithLocalStorage: boolean;

  constructor(syncWithLocalStorage: boolean) {
    this.storage = new Map<string, any>();
    this._syncWithLocalStorage = syncWithLocalStorage;
  }

  set syncWithLocalStorage(value: boolean) {
    this._syncWithLocalStorage = value;
  }

  get length() {
    return this.storage.size;
  }

  key(index: number): string|null {
    return index < this.length ? Array.from(this.storage.keys())[index] : null;
  }

  setItem(key: string, value: any) {
    this.storage.set(key, value);
    if (this._syncWithLocalStorage) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  getItem(key: string) {
    if (this.storage.has(key)) {
      return this.storage.get(key);
    }

    let externalValue = localStorage.getItem(key);
    if (externalValue != null) {
      try {
        externalValue = JSON.parse(externalValue);
      } catch (e) {
        return null; // can't parse
      }
      this.storage.set(key, externalValue);
      return externalValue;
    }

    return null;
  }

  removeItem(key: string) {
    this.storage.delete(key);
    if (this._syncWithLocalStorage) {
      localStorage.removeItem(key);
    }
  }

  clear() {
    this.storage.clear();
    if (this._syncWithLocalStorage) {
      localStorage.clear();
    }
  }
}

export class MmcLocalStorage extends LocalStorage {
  get accessToken(): string | null {
    return this.getItem('accessToken');
  }

  set accessToken(accessToken: string | null) {
    if (accessToken) {
      this.setItem('accessToken', accessToken);
    } else {
      this.removeItem('accessToken');
    }
  }

  get currentUser(): Me | null {
    return this.getItem('currentUser');
  }

  set currentUser(me: Me | null) {
    if (me) {
      this.setItem('currentUser', me);
    } else {
      this.removeItem('currentUser');
    }
  }

  get leadGenSearches(): Array<string> | null {
    return this.getItem('leadGenSearches');
  }

  set leadGenSearches(searches: Array<string> | null) {
    if (searches) {
      this.setItem('leadGenSearches', searches);
    } else {
      this.removeItem('leadGenSearches');
    }
  }

  get companyListViewState(): ListState {
    return this.getItem('companyListViewState') || initialListState;
  }

  set companyListViewState(companyListState: ListState) {
    this.setItem('companyListViewState', companyListState);
  }

  get leadListViewState(): ListState {
    return this.getItem('leadListViewState') || initialListState;
  }

  set leadListViewState(leadListState: ListState) {
    this.setItem('leadListViewState', leadListState);
  }

  get peopleListViewState(): ListState {
    return this.getItem('peopleListViewState') || initialListState;
  }

  set peopleListViewState(peopleListState: ListState) {
    this.setItem('peopleListViewState', peopleListState);
  }

  get browserAlertLastShown(): number {
    return this.getItem('browserAlertLastShown') || 0;
  }

  set browserAlertLastShown(currentTimestamp: number | null) {
    this.setItem('browserAlertLastShown', currentTimestamp);
  }
}

const defaultInstance = new MmcLocalStorage(true);
export default defaultInstance;
