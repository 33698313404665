import uniq from 'lodash/uniq';
import BaseNetworkService from './base-network-service/base-network-service';
import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import EntityType from '../react/type/EntityType';
import Route from '../react/type/Route';
import Group from '../react/type/Group';
import DataModel from '../common/data-model';
import Company from '../react/type/Company';
import Person from '../react/type/Person';
import RouteDetail from '../react/type/RouteDetail';
import GeoPoint from '../react/type/GeoPoint';

type Params = {
  $order?: string,
  $limit?: number,
  $offset?: number,
  $filters?: string,
}

type RoutableEntity = Company | Person;

class RoutingNetworkService {

  get orgId(): number {
    return safeLocalStorage.currentUser!.organization.id;
  }

  getBaseRoutingEndPoint(type?: EntityType): string {
    return type === EntityType.COMPANY || window.isOnPage('accounts') ?
      `organizations/${this.orgId}/account-routes` : `organizations/${this.orgId}/routes`;
  }

  getBaseRouteBulkEndPoint(routeId: Route['id']) {
    return window.isOnPage('accounts')
      ? `organizations/${this.orgId}/routes/${routeId}/accounts/bulk`
      : `organizations/${this.orgId}/routes/${routeId}/contacts/bulk`;
  }

  getBaseRoutePreviewEndPoint() {
    return window.isOnPage('accounts') ?
      `organizations/${this.orgId}/routes/accounts/preview` :
      `organizations/${this.orgId}/routes/preview`;
  }

  async getRoutes(
    filters: object,
    page: number = 1,
    column: string = 'updatedAt',
    ascending: boolean = false,
  ) {
    const endPoint = this.getBaseRoutingEndPoint();
    let params: Params = {
      $order: ascending ? column : `-${column}`,
      $limit: DataModel!.pageSize,
    };
    if (page) {
      params.$offset = (page - 1) * DataModel!.pageSize;
    }
    if (Object.keys(filters).length) {
      params = Object.assign(params, {$filters: JSON.stringify(filters)});
    }
    return BaseNetworkService.read(endPoint, params);
  }

  // get account routes from the server
  async getAccountRoutes(
    filters: object = {},
    page: number = 1,
    column: string = 'updatedAt',
    ascending: boolean = false,
  ) {
    const endPoint = this.getBaseRoutingEndPoint(EntityType.COMPANY);
    let params: Params = {
      $order: ascending ? column : `-${column}`,
      $limit: DataModel!.pageSize,
    };
    if (page) {
      params.$offset = (page - 1) * DataModel!.pageSize;
    }
    if (Object.keys(filters).length) {
      params = Object.assign(params, {$filters: JSON.stringify(filters)});
    }
    return BaseNetworkService.read(endPoint, params);
  }

  // get contact routes from the server
  async getContactRoutes(
    filters: object = {},
    page: number = 1,
    column: string = 'updatedAt',
    ascending: boolean = false,
    limit: number | false = false,
  ) {
    const endPoint = this.getBaseRoutingEndPoint(EntityType.PERSON);
    let params: Params = {
      $order: ascending ? column : `-${column}`,
      $limit: limit === false ? undefined : (limit ?? DataModel.pageSize),
    };
    if (page) {
      params.$offset = (page - 1) * DataModel!.pageSize;
    }
    if (Object.keys(filters).length) {
      params = Object.assign(params, {$filters: JSON.stringify(filters)});
    }
    return BaseNetworkService.read(endPoint, params);
  }

  async getRouteDetails(id: Route['id']): Promise<Route> {
    // adding hardcoded filters for now since the detail endpoint is not returning contacts.
    // Will remove once the changes are done on the server side
    let endPoint = this.getBaseRoutingEndPoint();
    if (window.isOnPage('accounts')) {
      endPoint = `${endPoint}/${id}?$filters={"includeAccounts":true}`;
    } else {
      endPoint = `${endPoint}/${id}?$filters={"includeContacts":true}`;
    }

    return BaseNetworkService.read(endPoint);
  }

  async createRoute(payload: {name: Route['name'], routeDetail: Pick<RouteDetail, 'type' | 'allottedTime' | 'startAddress' | 'endAddress' | 'startGeoPoint' | 'endGeoPoint' | 'startAt'>}) {
    const endPoint = this.getBaseRoutingEndPoint();
    return BaseNetworkService.create(endPoint, payload);
  }

  async updateRoute(routeId: Route['id'], payload: Route) {
    const endPoint = `${this.getBaseRoutingEndPoint()}/${routeId}`;
    return BaseNetworkService.update(endPoint, payload);
  }

  async updateRouteObjects(routeId: Route['id'], payload: object) {
    const endPoint = this.getBaseRouteBulkEndPoint(routeId);
    return BaseNetworkService.create(endPoint, payload);
  }

  async buildRoute(params: {[key: string]: any}) {
    const endPoint = this.getBaseRoutePreviewEndPoint();

    if (window.isOnPage('accounts')) {
      params.routeAccounts = params.routeObjects;
    } else {
      params.routeContacts = params.routeObjects;
    }

    return BaseNetworkService.create(endPoint, params);
  }

  // tries to build a route using the existing stops from the route identified by routeId
  // plus new stops provided in newEntityIds
  async tryRoute(newEntityIds: Array<RoutableEntity['id']>, routeId: Route['id']) {
    const route = await this.getRouteDetails(routeId);
    if (!route.id) {
      throw route; // getRouteDetails uses BaseNetworkService, thus errors are returned as a regular response
    }

    const endPoint = this.getBaseRoutePreviewEndPoint();

    const payload: {
      routeType: RouteDetail['type'],
      startGeoPoint: GeoPoint,
      endGeoPoint: GeoPoint,
      routeAccounts?: Array<Company['id']>,
      routeContacts?: Array<Person['id']>,
    } = {
      routeType: route.routeDetail.type,
      startGeoPoint: route.routeDetail.startGeoPoint,
      endGeoPoint: route.routeDetail.endGeoPoint,
    };
    if (window.isOnPage('accounts')) {
      payload.routeAccounts = uniq([...(route.routeAccounts ?? []).map(({id}) => id), ...newEntityIds]);
    } else {
      payload.routeContacts = uniq([...(route.routeContacts ?? []).map(({id}) => id), ...newEntityIds]);
    }

    return BaseNetworkServiceWithPromises.create(endPoint, payload);
  }

  async deleteRoute(id: Route['id']) {
    const endPoint = `${this.getBaseRoutingEndPoint()}/${id}`;
    return BaseNetworkService.delete(endPoint);
  }

  async getContactGroups() {
    const endPoint = `organizations/${this.orgId}/contact-groups`;
    return BaseNetworkService.read(endPoint);
  }

  async contactGroupDetails(id: Group['id']) {
    const endPoint = `organizations/${this.orgId}/contacts`;
    const filters = {
      groups: {
        $any: [id],
      },
    };
    const params: Params = {$filters: JSON.stringify(filters)};
    return BaseNetworkService.read(endPoint, params);
  }
}

export default new RoutingNetworkService();
