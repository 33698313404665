import React, {useCallback, useState} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {LatLngBounds} from 'leaflet';
import {LayersControl, Map, Marker, Popup} from 'react-leaflet';
import {GoogleLayer} from 'react-leaflet-google-v2';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import DashCard from '../DashCard/DashCard';
import useDataLoader from '../../../../hook/useDataLoader';
import useInitializer from '../../../../hook/useInitializer';
import accountsNetworkService from '../../../../../network-services/accounts-network-service';
import ListResponse from '../../../../type/service/ListResponse';
import swapGeoPointCoordinates from '../../../../util/geo/swapGeoPointCoordinates';
import googleAuthProps from '../../../../util/geo/googleAuthProps';
import User from '../../../../type/User';
import useDefaultOption from '../../../../hook/useDefaultOption';
import useSelectFieldOptions from '../../../../hook/useSelectFieldOptions';
import userSelectOptionMapper from '../../../../util/mappers/userSelectOptionMapper';
import Company from '../../../../type/Company';
import isMapped from '../../../../util/geo/isMapped';
import CardCta from '../DashCard/CardCta';
import {getMarkerIcon} from '../../../../components/map-icons/icons';
import LoadingProgress from '../../../../components/LoadingProgress/LoadingProgress';
import sameLabelValueMapper from '../../../../util/mappers/sameLabelValueMapper';
import SelectField from '../../../../components/SelectField/SelectField';

const ALL_USERS_KEY = -Math.random();
const limitOptions = [5, 10, 25, 50, 100].map(sameLabelValueMapper);

const MapContainer = styled.div`
  flex: 1;
  height: calc(400px - 72px);
`;

class UserIdSelect extends SelectField<User['id']> {}
const UserSelectField = styled(UserIdSelect)`
  width: 200px;
`;

interface Props {
  hasCompanies: boolean,
  users: User[],
}

const RecentCompaniesCard: React.FC<Props> = ({hasCompanies, users}) => {
  const {t} = useTranslation('home');
  const usersOptions = useDefaultOption(useSelectFieldOptions(users, userSelectOptionMapper), 'All Users', ALL_USERS_KEY);

  const [limit, setLimit] = useState(10);
  const handleChangeLimit = useCallback((limit) => setLimit(parseInt(limit, 10)), [setLimit]);

  const [selectedUserId, setSelectedUserId] = useState<User['id'] | undefined>(undefined);
  const handleSelectUser = useCallback((userId) => setSelectedUserId(userId === ALL_USERS_KEY ? undefined : userId), [setSelectedUserId]);

  const getRecentCompanies = useCallback(
    () => accountsNetworkService.fetchAllAccounts(
      true,
      {viewAs: selectedUserId},
      1,
      'updatedAt',
      false,
      limit,
    ),
    [limit, selectedUserId],
  );
  const {isLoading, loader, data} = useDataLoader([getRecentCompanies]);
  useInitializer(loader);

  const companies = data.length
    ? (data[0] as ListResponse<Company>).data.filter(isMapped).map(swapGeoPointCoordinates)
    : [];
  const bounds = new LatLngBounds(companies.map(c => c.geoPoint.coordinates));

  return (
    <DashCard
      actions={[
        <SelectField<number>
          key={1}
          label={t('Limit')}
          onChange={handleChangeLimit}
          options={limitOptions}
          size="small"
          value={limit}
        />,
        <UserSelectField
          key={2}
          label={t('View By User')}
          onChange={handleSelectUser}
          options={usersOptions}
          size="small"
          value={selectedUserId ?? ALL_USERS_KEY}
        />,
      ]}
      centerAlignContent={isLoading}
      title={t('Recently Added Companies')}
      titleIconClassName="far fa-building"
    >
      {isLoading && <LoadingProgress global={false} />}
      {!isLoading && !companies.length && !hasCompanies && (
        <CardCta
          buttonCaption="Add Company"
          onClick={() => {
            window.refreshDom({'NavBar.showCreateCompanyModal': true, 'NavBar.showCreateEditActivityModal': false, 'NavBar.showCreateDealModal': false, 'NavBar.showCreatePersonModal': false, 'NavBar.editActivityModalActivityId': undefined});
          }}
          text="Add your first company"
        >
          <a
            href="https://support.mapmycustomers.com/hc/en-us/articles/360050258093-Companies-Overview"
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn more about companies
          </a>
        </CardCta>
      )}
      {!isLoading && !companies.length && hasCompanies && (
        <CardCta
          buttonCaption="Add Company"
          onClick={() => {
            window.refreshDom({'NavBar.showCreateCompanyModal': true, 'NavBar.showCreateEditActivityModal': false, 'NavBar.showCreateDealModal': false, 'NavBar.showCreatePersonModal': false, 'NavBar.editActivityModalActivityId': undefined});
          }}
          text="No recent companies found. Add a company or change filters."
        >
          <a
            href="https://support.mapmycustomers.com/hc/en-us/articles/360050258093-Companies-Overview"
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn more about companies
          </a>
        </CardCta>
      )}
      {!isLoading && companies.length > 0 && (
        <MapContainer>
          <Map bounds={bounds} style={{height: '100%'}} attributionControl={false}>
            <LayersControl position="topright">
              <LayersControl.BaseLayer checked name="Map">
                <GoogleLayer maptype="roadmap" {...googleAuthProps} />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Hybrid">
                <GoogleLayer maptype="hybrid" {...googleAuthProps} />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Satellite">
                <GoogleLayer maptype="satellite" {...googleAuthProps} />
              </LayersControl.BaseLayer>
            </LayersControl>
            <MarkerClusterGroup>
              {companies.map(company => (
                <Marker
                  icon={getMarkerIcon(company)}
                  key={company.id}
                  position={company.geoPoint.coordinates}
                >
                  <Popup>
                    <a href={`#/accounts/edit/${company.id}`}>{company.name}</a><br />
                    {moment(company.createdAt).format('ll')}<br />
                    by {company.user.username}
                  </Popup>
                </Marker>
              ))}
            </MarkerClusterGroup>
          </Map>
        </MapContainer>
      )}
    </DashCard>
  );
};

export default RecentCompaniesCard;
