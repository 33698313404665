import {createAction} from '@reduxjs/toolkit';
import {withPayloadType} from '../../react/util/redux';

export const fetchNotificationsUnreadTotal = createAction('notifications/fetchNotificationsUnreadTotal');

export const setNotificationsUnreadTotal = createAction('integrations/setNotificationsUnreadTotal', withPayloadType<number>());

export type Actions =
  | ReturnType<typeof fetchNotificationsUnreadTotal>
  | ReturnType<typeof setNotificationsUnreadTotal>
