import FieldCategory from '../../type/enum/FieldCategory';

const fieldCategoryToDisplayName: {[key in FieldCategory]: string} = {
  [FieldCategory.GENERAL]: 'General Details',
  [FieldCategory.ADDRESS]: 'Address Details',
  [FieldCategory.CUSTOM]: 'Custom Fields',
};

const fieldCategoryDisplayName = (fieldCategory: FieldCategory): string => fieldCategoryToDisplayName[fieldCategory];

export default fieldCategoryDisplayName;
