import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import CustomField from '../react/type/CustomField';
import EntityType from '../react/type/EntityType';
import Company from '../react/type/Company';
import Person from '../react/type/Person';
import Deal from '../react/type/Deal';
import Activity from '../react/type/Activity';
import ListResponse from '../react/type/service/ListResponse';

type CustomFieldId = CustomField['id'];
export type SupportedEntityType = EntityType.COMPANY | EntityType.PERSON | EntityType.DEAL | EntityType.ACTIVITY | EntityType.LEAD;
export type SupportedEntities = Company | Person | Deal | Activity;

const entityTypeToEndpoint: {[key in SupportedEntityType]: string} = {
  [EntityType.COMPANY]: 'account-custom-fields',
  [EntityType.PERSON]: 'contact-custom-fields',
  [EntityType.DEAL]: 'deal-custom-fields',
  [EntityType.LEAD]: 'lead-custom-fields',
  [EntityType.ACTIVITY]: 'crm-activity-custom-fields',
};

class CustomFieldsNetworkService {
  // Custom Fields methods

  _getCustomFieldsEndpoint(entityType: SupportedEntityType, id?: CustomFieldId) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    let endpoint = `organizations/${orgId}/${entityTypeToEndpoint[entityType]}`;

    if (id) {
      endpoint += `/${id}`;
    }

    return endpoint;
  }

  getFields(entityType: SupportedEntityType): Promise<ListResponse<CustomField>> {
    return BaseNetworkServiceWithPromises.read(
      this._getCustomFieldsEndpoint(entityType),
      {$limit: 99999, $order: 'displayOrder'},
    );
  }

  create(entityType: SupportedEntityType, field: any) {
    return BaseNetworkServiceWithPromises.create(this._getCustomFieldsEndpoint(entityType), field);
  }

  update(entityType: SupportedEntityType, field: any) {
    return BaseNetworkServiceWithPromises.update(this._getCustomFieldsEndpoint(entityType, field.id), field);
  }

  remove(entityType: SupportedEntityType, id: CustomFieldId) {
    return BaseNetworkServiceWithPromises.delete(this._getCustomFieldsEndpoint(entityType, id));
  }

  // Custom Field Values methods

  _getCustomFieldValuesEndpoint(entityType: SupportedEntityType, entityId: SupportedEntities['id'], id?: CustomFieldId) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const path = entityType === EntityType.ACTIVITY ? 'crm-activities' : entityType;
    let endpoint = `organizations/${orgId}/${path}/${entityId}/custom-field-values`;

    if (id) {
      endpoint += `/${id}`;
    }

    return endpoint;
  }

  getValues(entityType: SupportedEntityType, entityId: SupportedEntities['id']) {
    return BaseNetworkServiceWithPromises.read(this._getCustomFieldValuesEndpoint(entityType, entityId), {$limit: 50});
  }

  createValue(entityType: SupportedEntityType, entityId: SupportedEntities['id'], value: any) {
    return BaseNetworkServiceWithPromises.create(this._getCustomFieldValuesEndpoint(entityType, entityId), value);
  }

  updateValue(entityType: SupportedEntityType, entityId: SupportedEntities['id'], value: any) {
    return BaseNetworkServiceWithPromises.update(this._getCustomFieldValuesEndpoint(entityType, entityId, value.id), value);
  }
}

export default new CustomFieldsNetworkService();
