import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import {Table, Button, Modal, Tabs} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import styled from 'styled-components';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import ImportResponse, {
  ImportData,
  ImportDataResponse,
  ImportRow,
} from '../../../type/network/ImportResponse';
import ImportNetworkServiceType from '../../../type/network/ImportNetworkService';
import entityDisplayName from '../../../util/formatter/entityDisplayName';
import Pagination from '../../../components/Grid/Pagination';
import {
  historyReducer,
  initialHistoryState,
  setErrorsPage,
  setSelectedImportId,
  setWarningsPage,
} from '../reducers/history';
import analyticsService from '../../../../shared-services/analytics-service';

const {TabPane} = Tabs;

const Container = styled.div`
  justify-content: center;
  padding: 23px;
  height: 100%;
  display: flex;
  flex-direction: column;
  & .ant-table-wrapper{
    height: 100%;
    overflow-y: auto;
  }
`;

const DetailsModal = styled(Modal)`
  height: 80vh;
  & > .ant-modal-content {
    height: 100%;
    & > .ant-modal-body {
      height: 100%;
      & > .ant-tabs {
        height: calc(100% - 60px);
        & > .ant-tabs-content-holder {
          overflow-y: auto;
          height: calc(100% - 48px);
        }
      }
    }
  }
`;

enum ImportDataStatus {
  WARNING = 'warning',
  ERRORED = 'errored'
}

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

interface Props {
  ImportNetworkService: ImportNetworkServiceType
}

const History: React.FC<Props> = ({ImportNetworkService}) => {
  const {t} = useTranslation(['import']);
  const [rows, setRows] = useState<ImportRow[]>([]);
  const [errorRows, setErrorRows] = useState<ImportDataResponse | undefined>();
  const [warningRows, setWarningRows] = useState<ImportDataResponse>();
  const [tabIndex, setTabIndex] = useState<string>('1');
  const [{errorsPage, warningsPage, selectedImportId}, dispatch] = useReducer(historyReducer, initialHistoryState);

  useEffect(
    () => {
      analyticsService.clicked('Change import details tab', {tab: tabIndex === '1' ? 'Errors' : 'Warnings'});
    },
    [tabIndex],
  );

  useEffect(() => {
    ImportNetworkService.fetchImports({$order: '-updatedAt'})
      .then((response: ImportResponse) => {
        setRows(response.data.map((row: ImportRow) => {
          const {mapping, metadata} = row;
          const {errors, warnings} = metadata;
          const errorsCount: number = Array.isArray(errors) ? errors.length : parseInt(errors || 0, 10);
          const warningsCount: number = Array.isArray(warnings) ? warnings.length : parseInt(warnings || 0, 10);
          return {
            ...row,
            type: entityDisplayName(mapping.type),
            date: moment(row.createdAt).format('LLL'),
            createdCount: metadata.createdCount,
            updatedCount: metadata.updatedCount,
            totalRows: metadata.totalRows,
            warningsCount,
            errorsCount,
          };
        }));
      });
  }, []);

  useEffect(
    () => {
      if (selectedImportId) {
        (async () => {
          const response = await ImportNetworkService.fetchImportData({
            $filters: {status: ImportDataStatus.ERRORED, importId: selectedImportId},
            $limit: 100,
            $offset: (errorsPage - 1) * 100,
          });
          setErrorRows({
            ...response,
            data: response.data.map(({errors, rowNum}: ImportData, index: number) => ({
              rowNum,
              field: errors.userMessage?.field || '',
              message: errors.userMessage ? errors.userMessage.message : errors.message,
              code: errors.userMessage?.code || '',
              index,
            })),
          });
        })();
      }
    },
    [selectedImportId, errorsPage, setErrorRows],
  );

  useEffect(
    () => {
      if (selectedImportId) {
        (async () => {
          const response = await ImportNetworkService.fetchImportData({
            $filters: {status: ImportDataStatus.WARNING, importId: selectedImportId},
            $limit: 100,
            $offset: (warningsPage - 1) * 100,
          });
          setWarningRows({
            ...response,
            data: response.data.map(({warnings}: ImportData, index: number) => ({
              message: warnings,
              index,
            })),
          });
        })();
      }
    },
    [selectedImportId, warningsPage, setWarningRows],
  );

  const handleSelectImport = useCallback(
    async (importId: number | undefined) => {
      analyticsService.clicked('View import details', {importId});
      dispatch(setSelectedImportId(importId));
    },
    [dispatch],
  );

  const onSetErrorsPage = useCallback(
    async (page: number) => {
      dispatch(setErrorsPage(page));
    },
    [dispatch],
  );

  const onSetWarningsPage = useCallback(
    async (page: number) => {
      dispatch(setWarningsPage(page));
    },
    [dispatch],
  );

  const historyColumns: ColumnsType<any> = useMemo(
    () => [
      {
        title: t('Type'),
        dataIndex: 'type',
      },
      {
        title: t('Date'),
        dataIndex: 'date',
      },
      {
        title: t('Status'),
        dataIndex: 'status',
      },
      {
        title: t('entitiesCreated'),
        dataIndex: 'createdCount',
      },
      {
        title: t('entitiesUpdated'),
        dataIndex: 'updatedCount',
      },
      {
        title: t('totalRows'),
        dataIndex: 'totalRows',
      },
      {
        title: t('Warnings'),
        dataIndex: 'warningsCount',
      },
      {
        title: t('Errors'),
        dataIndex: 'errorsCount',
      },
      {
        dataIndex: 'id',
        render: (_: string, {id, warningsCount, errorsCount}: {[key: string]: any}) => {
          if ((errorsCount + warningsCount) > 0) {
            return (
              <Button type="link" onClick={() => handleSelectImport(id)}>
                {t('viewDetails')}
              </Button>
            );
          }
          return <React.Fragment />;
        },
      },
    ],
    [handleSelectImport, t],
  );

  const errorsColumns: ColumnsType<any> = [
    {
      title: t('Row'),
      dataIndex: 'rowNum',
    },
    {
      title: t('Field'),
      dataIndex: 'field',
    },
    {
      title: t('Message'),
      dataIndex: 'message',
    },
    {
      title: t('Code'),
      dataIndex: 'code',
    },
  ];

  const warningsColumns: ColumnsType<any> = [
    {
      title: t('Message'),
      dataIndex: 'message',
    },
  ];

  return (
    <Container>
      <div>
        {t('historyDescription')}
      </div>
      <Table
        columns={historyColumns}
        dataSource={rows}
        pagination={false}
        rowKey="id"
      />
      <DetailsModal
        visible={selectedImportId !== undefined}
        footer={null}
        onCancel={() => handleSelectImport(undefined)}
        width="80vw"
      >
        {selectedImportId !== undefined && errorRows !== undefined && warningRows !== undefined && (
          <Tabs defaultActiveKey="1" onChange={setTabIndex}>
            <TabPane tab={t('Errors')} key="1">
              <Table
                columns={errorsColumns}
                dataSource={errorRows.data}
                pagination={false}
                rowKey="index"
              />
            </TabPane>
            <TabPane tab={t('Warnings')} key="2">
              <Table
                columns={warningsColumns}
                dataSource={warningRows.data}
                pagination={false}
                rowKey="index"
              />
            </TabPane>
          </Tabs>
        )}
        {tabIndex === '1' ?
          <PaginationContainer>
            <Pagination total={errorRows?.total} page={errorsPage} onSetPage={onSetErrorsPage} />
          </PaginationContainer> :
          <PaginationContainer>
            <Pagination total={warningRows?.total} page={warningsPage} setPage={onSetWarningsPage} />
          </PaginationContainer>
        }
      </DetailsModal>
    </Container>
  );
};

export default History;


