import {createSlice} from '@reduxjs/toolkit';
import User from '../../../react/type/User';

const DEFAULT_AVATAR_COLORS = [
  'red',
  'pink',
  'purple',
  'deep-purple',
  'indigo',
  'blue',
  'light-blue',
  'cyan',
  'teal',
  'green',
  'light-green',
  'lime',
  'yellow',
  'amber',
  'orange',
  'deep-orange',
  'brown',
  'grey',
  'blue-grey',
];

export interface AvatarState {
  avatarColors: {[key: number]: typeof DEFAULT_AVATAR_COLORS[number]},
}

const slice = createSlice({
  name: 'avatar',
  initialState: {
    avatarColors: {},
  } as AvatarState,
  reducers: {
    updateAvatarColorForUserId(state: AvatarState, {payload: userId}: {payload: User['id']}) {
      state.avatarColors[userId] = DEFAULT_AVATAR_COLORS[(Math.round(
        Math.random() * (DEFAULT_AVATAR_COLORS.length - 1)))];
    },
  },
});

export default slice;
