import React, {ReactText, useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Select, Typography, Space} from 'antd';
import {useTranslation} from 'react-i18next';
import useDefaultOption from '../../hook/useDefaultOption';
import useSelectFieldOptions from '../../hook/useSelectFieldOptions';
import userSelectOptionMapper from '../../util/mappers/userSelectOptionMapper';
import userNetworkService from '../../../network-services/user-network-service';
import safeLocalStorage from '../../../shared-services/safe-local-storage-service';
import User from '../../type/User';
import {SelectFieldOption} from '../../type/react-md/SelectFieldOption';

const {Option} = Select;

const ALL_USERS_KEY = `all_users-${Math.random()}`;

const ViewBySelect = styled(Select)`
  width: 200px;
`;

interface Props {
  onChange: (userId: number | undefined) => void,
}

const ViewByUser: React.FC<Props> = ({onChange}) => {
  const {t} = useTranslation();
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<User['id'] | undefined>(undefined);

  useEffect(
    () => {
      (async () => setAllUsers((await userNetworkService.getAllUsers(safeLocalStorage.currentUser!.organization.id)).data))();
    },
    [],
  );

  const handleSelectUser = useCallback((userId: ReactText) => {
    const value: number | undefined = userId === ALL_USERS_KEY ? undefined : Number(userId);
    setSelectedUserId(value);
    onChange(value);
  }, [setSelectedUserId]);

  const usersOptions: SelectFieldOption[] = useDefaultOption(useSelectFieldOptions(allUsers, userSelectOptionMapper), 'All Users', ALL_USERS_KEY);

  return (
    <Space>
      <Typography.Text>{t('ViewByUser')}</Typography.Text>
      <ViewBySelect defaultValue={selectedUserId ?? ALL_USERS_KEY} onChange={value => handleSelectUser(value as ReactText)}>
        {usersOptions.map(userOption => (
          <Option key={userOption.value} value={userOption.value}>{userOption.label}</Option>
        ))}
      </ViewBySelect>
    </Space>
  );
};

export default ViewByUser;
