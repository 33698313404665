import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import IntegrationService from '../react/type/enum/IntegrationService';
import ListResponse from '../react/type/service/ListResponse';
import Integration, {SupportedEntityType} from '../react/type/integration/Integration';
import IntegrationUser from '../react/type/integration/IntegrationUser';
import IntegrationField from '../react/type/integration/IntegrationField';

class IntegrationNetworkService {
  _getEndpoint(id?: Integration['id'], extra?: string) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    let endpoint = `organizations/${orgId}/integrations`;

    if (id) {
      endpoint += `/${id}`;
    }

    if (extra) {
      endpoint += `/${extra}`;
    }

    return endpoint;
  }

  list(): Promise<ListResponse<Integration>> {
    return BaseNetworkServiceWithPromises.read(this._getEndpoint());
  }

  fetch(id: Integration['id']): Promise<Integration> {
    return BaseNetworkServiceWithPromises.read(this._getEndpoint(id));
  }

  async delete(id: Integration['id']): Promise<void> {
    await BaseNetworkServiceWithPromises.delete(this._getEndpoint(id));
  }

  fetchUsers(id: Integration['id']): Promise<ListResponse<IntegrationUser>> {
    return BaseNetworkServiceWithPromises.read(
      this._getEndpoint(id, 'users'),
      {
        $order: 'firstName,lastName',
        $limit: -1,
      },
    );
  }

  fetchFields(id: Integration['id'], entityType: SupportedEntityType): Promise<ListResponse<IntegrationField>> {
    return BaseNetworkServiceWithPromises.read(
      this._getEndpoint(id, 'fields'),
      {$filters: {entityType}, $order: 'crmDisplayName', $limit: -1},
    );
  }

  createHubspotIntegration(code: string): Promise<Integration> {
    return BaseNetworkServiceWithPromises.create(this._getEndpoint(), {type: IntegrationService.HUBSPOT, code});
  }

  update(id: Integration['id'], payload: Pick<Integration, 'id' | 'isLocked' | 'syncOptions'>): Promise<Integration> {
    return BaseNetworkServiceWithPromises.update(this._getEndpoint(id), payload);
  }

  updateUsers(id: Integration['id'], payload: Pick<IntegrationUser, 'id' | 'syncing' | 'userId'>[]): Promise<ListResponse<IntegrationUser>> {
    return BaseNetworkServiceWithPromises.update(this._getEndpoint(id, 'users/bulk-update'), payload);
  }

  updateFields(
    id: Integration['id'],
    entityType: SupportedEntityType,
    payload: Pick<IntegrationField, 'id' | 'mmcField' | 'mmcGroupField' | 'syncing' | 'customField'>[],
  ): Promise<ListResponse<IntegrationField>> {
    return BaseNetworkServiceWithPromises.update(this._getEndpoint(id, `fields/bulk-update?entityType=${entityType}`), payload);
  }
}

export default new IntegrationNetworkService();
