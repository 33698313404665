import React, {useCallback} from 'react';
import TextSearchResult from '../../../../../type/TextSearchResult';
import getSearchResultUrl from '../util/getSearchResultUrl';
import EntityTypeIcon from '../../../../EntityTypeIcon';
import EntityType from '../../../../../type/EntityType';
import commonStyles from './SearchResultCommon.module.scss';
import styles from './SearchResultOption.module.scss';
import analyticsService from '../../../../../../shared-services/analytics-service';
import Avatar from '../../../../Avatar';
import User from '../../../../../type/User';
import Route from '../../../../../type/Route';
import Button from 'antd/es/button';

interface Props {
  result: TextSearchResult;
}

const SearchResultOption: React.FC<Props> = ({result}) => {
  const handleClick = useCallback(() => {
    analyticsService.clicked(['Global search', 'click']);
    if ([EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL].includes(result.entity as EntityType)) {
      window.refreshDom({entityType: result.entity, entityId: result.entityId}, 'NavBar');
    } else if ((result.entity as EntityType) === EntityType.ACTIVITY) {
      window.refreshDom({
        showCreateCompanyModal: false,
        showCreateEditActivityModal: true,
        showCreateDealModal: false,
        showCreatePersonModal: false,
        editActivityModalActivityId: result.entityId
      }, 'NavBar');
    } else {
      window.location.href = getSearchResultUrl(result);
    }
  }, []);

  const isGroup = [
    EntityType.COMPANY_GROUP,
    EntityType.PEOPLE_GROUP,
    EntityType.DEAL_GROUP,
  ].includes(result.entity as EntityType);

  const isTerritory = [EntityType.TERRITORY].includes(result.entity as EntityType);

  const isRoute = [EntityType.COMPANY_ROUTE, EntityType.PEOPLE_ROUTE].includes(
    result.entity as EntityType
  );

  let additionalInfo = result.metaFields;
  if (isRoute) {
    additionalInfo = `${(result.metaData! as Route).items} Stops`;
  }

  const avatar =
    isGroup || isRoute || isTerritory ? (
      <Avatar className={styles.avatar} randomColor tooltip user={result.metaData!.user as User} />
    ) : null;

  return (
    <Button
      className={commonStyles.container}
      onClick={handleClick}
      type="link"
    >
      <div className={styles.content}>
        <div className={commonStyles.container}>
          <span className={styles.title}>
            <EntityTypeIcon className={styles.icon} entityType={result.entity as EntityType} />
            {result.name}
          </span>
          {additionalInfo && <span className={styles.metaFields}>{additionalInfo}</span>}
        </div>
        <div className={styles.avatarContainer}>{avatar}</div>
      </div>
    </Button>
  );
};

export default SearchResultOption;
