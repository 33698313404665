import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../rootReducer';
import {ValueOf} from '../../react/util/ts';
import {Actions} from './actions';
import Integration, {SupportedEntityType} from '../../react/type/integration/Integration';
import IntegrationUser from '../../react/type/integration/IntegrationUser';
import User from '../../react/type/User';
import IntegrationField from '../../react/type/integration/IntegrationField';
import EntityType from '../../react/type/EntityType';
import Field from '../../common/field-model/field/field';
import IntegrationFieldResponse from '../../react/type/integration/IntegrationFieldResponse';

export type IntegrationFieldsMapping = {[key in SupportedEntityType]: IntegrationField[]};

export const EmptyIntegrationFieldsMappingState: IntegrationFieldsMapping = Object.freeze({
  [EntityType.COMPANY]: [],
  [EntityType.PERSON]: [],
  [EntityType.DEAL]: [],
  [EntityType.ACTIVITY]: [],
});


export const CreateNewCustomField = "__mmc_create_new_field";

export type IntegrationsState = {
  currentIntegration?: Integration,
  integrationUsers: IntegrationUser[],
  integrationFields: IntegrationFieldsMapping,
  loading: boolean;
  loadingFields: boolean;
  saving: boolean;
  integrations: Integration[],
  customFieldIntegrationResponse?: IntegrationFieldResponse[], 
  error: Error | undefined;
};

const initialState: IntegrationsState = {
  currentIntegration: undefined,
  integrationUsers: [],
  integrationFields: {...EmptyIntegrationFieldsMappingState},
  loading: false,
  loadingFields: false,
  saving: false,
  integrations: [],
  error: undefined,
  customFieldIntegrationResponse: [],
};

const integrations = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    setIntegrations: (state, action: PayloadAction<Integration[]>) => {
      state.integrations = action.payload;
    },
    setCurrentIntegration: (state, action: PayloadAction<Integration>) => {
      state.currentIntegration = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
    setLoadingFields: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loadingFields: action.payload,
    }),
    setSaving: (state, action: PayloadAction<boolean>) => ({
        ...state,
        saving: action.payload,
    }),
    setCustomFieldIntegrationResponse: (state, action: PayloadAction<IntegrationFieldResponse[]>) => ({
        ...state,
        customFieldIntegrationResponse: action.payload,
    }),
    changeFrequency: (state, {payload: {frequency}}: PayloadAction<{frequency: string}>) => {
      state.currentIntegration!.schedule = {frequency};
    },
    changeStartSyncDate: (state, {payload: {nextRunAt}}: PayloadAction<{nextRunAt: string}>) => {
      state.currentIntegration!.schedule = {nextRunAt};
    },
    changeSyncOption: (state, {payload: {entityType, incoming, outgoing}}: PayloadAction<{entityType: SupportedEntityType, incoming: boolean, outgoing: boolean}>) => {
      state.currentIntegration!.syncOptions[entityType] = {incoming, outgoing};
    },
    setIntegrationUsers: (state, action: PayloadAction<IntegrationUser[]>) => {
      state.integrationUsers = action.payload;
    },
    setIntegrationFields: (state, action: PayloadAction<IntegrationFieldsMapping>) => {
      state.integrationFields = action.payload;
    },
    changeIntegrationUser: (state, {payload: {integrationUser, mmcUserId}}: PayloadAction<{integrationUser: IntegrationUser, mmcUserId: User['id'] | undefined}>) => {
      const index = state.integrationUsers.findIndex(({id}) => id === integrationUser.id);
      if (index < 0) {
        return;
      }
      state.integrationUsers[index] = {
        ...state.integrationUsers[index],
        userId: mmcUserId ?? null,
        syncing: !!mmcUserId,
      };
    },
    changeIntegrationUserSync: (state, {payload: {integrationUser, enabled}}: PayloadAction<{integrationUser: IntegrationUser, enabled: boolean}>) => {
      const index = state.integrationUsers.findIndex(({id}) => id === integrationUser.id);
      if (index < 0) {
        return;
      }
      state.integrationUsers[index] = {...state.integrationUsers[index], syncing: enabled};
    },
    changeIntegrationField: (
      state,
      {payload: {entityType, integrationField, field}}: PayloadAction<{entityType: SupportedEntityType, integrationField: IntegrationField, field: Field | undefined}>,
    ) => {
      const index = state.integrationFields[entityType].findIndex(({id}) => id === integrationField.id);
      if (index < 0) {
        return;
      }
      state.integrationFields[entityType][index] = {
        ...state.integrationFields[entityType][index],
        mmcField: field === CreateNewCustomField ? field : field?.name ?? null,
        customField: field === CreateNewCustomField,
        syncing: !!field,
      };
    },
    changeIntegrationFieldSync: (
      state,
      {payload: {entityType, integrationField, enabled}}: PayloadAction<{entityType: SupportedEntityType, integrationField: IntegrationField, enabled: boolean}>
    ) => {
      const index = state.integrationFields[entityType].findIndex(({id}) => id === integrationField.id);
      if (index < 0) {
        return;
      }
      state.integrationFields[entityType][index] = {...state.integrationFields[entityType][index], syncing: enabled};
    },
    changeIntegrationFieldGroup: (
      state,
      {payload: {entityType, integrationField, enabled}}: PayloadAction<{entityType: SupportedEntityType, integrationField: IntegrationField, enabled: boolean}>
    ) => {
      const index = state.integrationFields[entityType].findIndex(({id}) => id === integrationField.id);
      if (index < 0) {
        return;
      }
      state.integrationFields[entityType][index] = {...state.integrationFields[entityType][index], mmcGroupField: enabled || null};
    },
    toggleIntegrationStatus: (state, {payload: integrationId}: PayloadAction<Integration['id']>) => {
      const index = state.integrations.findIndex(({id}) => id === integrationId);
      if (index < 0) {
        return;
      }
      state.integrations[index] = {...state.integrations[index], isLocked: !state.integrations[index].isLocked};
    },
  },
});

const integrationsState = (state: RootState) => state.integrations;
export const getError = createSelector(integrationsState, ({error}) => error);
export const getLoading = createSelector(integrationsState, ({loading}) => loading);
export const getLoadingFields = createSelector(integrationsState, ({loadingFields}) => loadingFields);
export const getSaving = createSelector(integrationsState, ({saving}) => saving);
export const getCurrentIntegration = createSelector(integrationsState, ({currentIntegration}) => currentIntegration);
export const getIntegrations = createSelector(integrationsState, ({integrations}) => integrations);
export const getIntegrationUsers = createSelector(integrationsState, ({integrationUsers}) => integrationUsers);
export const getIntegrationFields = createSelector(integrationsState, ({integrationFields}) => integrationFields);
export const getCustomFieldIntegrationResponse = createSelector(integrationsState, ({customFieldIntegrationResponse}) => customFieldIntegrationResponse);

export type IntegrationActions = ReturnType<ValueOf<typeof integrations.actions>> | Actions;
export default integrations;
