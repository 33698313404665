import React, {useCallback} from 'react';
import styled from 'styled-components';
import {Radio} from 'antd';
import {useTranslation} from 'react-i18next';
import EntityType from '../../../type/EntityType';
import ImportType from '../../../type/ImportType';
import entityDisplayName from '../../../util/formatter/entityDisplayName';
import RadioGroup from '../../../components/RadioGroup/RadioGroup';
import BlockSpace from '../../../components/Layout/BlockSpace';

const SelectTitle = styled.div`
  font-size: 1.2em;
  font-weight: 700;
`;

const BlockRadio = styled(Radio)`
  &.ant-radio-wrapper {
    display: flex;
    align-items: center;
  }
`;

const ImportTypeRadio = styled(Radio)`
  &.ant-radio-wrapper {
    display: flex;
    align-items: baseline;
  }
`;

const ImportTypeRadioGroup = styled(RadioGroup)`
  &.ant-radio-group {
    display: flex;
    flex-direction: column;
  }
`;

interface Props {
  entityType: EntityType,
  setEntityType: (entityType: EntityType) => void,
  importType: ImportType,
  setImportType: (importType: ImportType) => void,
  allowNoUid: boolean,
  uidIsSaved: boolean,
}

const Options: React.FC<Props> = ({entityType, setEntityType, importType, setImportType, allowNoUid, uidIsSaved}) => {
  const {t} = useTranslation(['import']);
  const handleSetEntityType = useCallback((value: string) => setEntityType(value as EntityType), []);
  const handleSetImportType = useCallback((value: string) => setImportType(value as ImportType), []);
  const displayName: string = entityDisplayName(entityType, true);

  return (
    <div>
      <BlockSpace direction="vertical" size="large">
        <div>
          <h5>{t('imImporting')}</h5>
          <RadioGroup
            onChange={handleSetEntityType}
            value={entityType}
          >
            <BlockRadio value={EntityType.COMPANY}>{entityDisplayName(EntityType.COMPANY)}</BlockRadio>
            <BlockRadio value={EntityType.PERSON}>{entityDisplayName(EntityType.PERSON)}</BlockRadio>
            <BlockRadio value={EntityType.DEAL}>{entityDisplayName(EntityType.DEAL)}</BlockRadio>
          </RadioGroup>
        </div>
        <div>
          <h5>{t('iWantTo')}</h5>
          <ImportTypeRadioGroup
            onChange={handleSetImportType}
            value={importType}
          >
            {allowNoUid &&
              <ImportTypeRadio value={ImportType.NO_UID}>
                <div>
                  <SelectTitle>{t('noUidTitle', {displayName})}</SelectTitle>
                  <p>{t('noUidDescription', {displayName})}</p>
                </div>
              </ImportTypeRadio>
            }
            <ImportTypeRadio value={ImportType.UID}>
              <div>
                <SelectTitle>
                  {t(uidIsSaved ? 'uidTitle' : 'uidTitleWithUpdate', {displayName})}
                </SelectTitle>
                <p>{t('uidDescription')}</p>
              </div>
            </ImportTypeRadio>
            <ImportTypeRadio value={ImportType.SUPPORT}>
              <div>
                <SelectTitle>{t('supportTitle')}</SelectTitle>
                <p>{t('supportDescription')}</p>
              </div>
            </ImportTypeRadio>
          </ImportTypeRadioGroup>
        </div>
      </BlockSpace>
    </div>
  );
};

export default Options;
