import template from './activity-type-filter-modal.html';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';

ActivityTypeFilterModalController.$inject = [
  '$scope', '$window', 'GroupsService', 'mmcConst', 'CrmActivityTypesNetworkService',
];

function ActivityTypeFilterModalController(
  $scope, $window, GroupsService, mmcConst, CrmActivityTypesNetworkService,
) {
  Object.assign(this, $scope, {
    localStorage: safeLocalStorage,
    GroupsService,
    mmcConst,
    CrmActivityTypesNetworkService,
    MODEL: $window.DataModel,
    group: {...$window.DataModel.GroupsService.currentGroup, cadence: $window.DataModel.GroupsService.currentGroup.cadenceInterval ? true : null},
    filterByActivityType,
    getActivitiesIcon,
    $onInit,
  });

  async function $onInit() {
    if (!this.crmActivityTypes) {
      const response = await this.CrmActivityTypesNetworkService.getTypes(safeLocalStorage.currentUser.organization.id);

      this.crmActivityTypes = response.data;
    }
    this.filterByActivityType(this.crmActivityTypes[0].id);
    this.group.activityTypeId = this.crmActivityTypes[0].id;
    this.username = safeLocalStorage.currentUser.username;
  }


  function filterByActivityType(activityId) {
    this.MODEL.show.loader = true;
    this.GroupsService.getAllRecordsForGroup(this.MODEL.GroupsService.currentGroup, this.MODEL.GroupsService.showCadenceLegend, activityId);
  }

  function getActivitiesIcon(type) {
    if (type) {
      let svgPath = mmcConst.activitiesIcons[type.name.toLowerCase()] || mmcConst.activitiesIcons.custom;

      if (type.id === this.group.activityTypeId) {
        svgPath = svgPath.replace(/(.+)\.svg/, '$1-active.svg');
      }

      return svgPath;
    }
  }
}

function activityTypeFilterModal() {
  return {
    restrict: 'E',
    scope: {
      crmActivityTypes: '=',
    },
    controller: 'ActivityTypeFilterModalController',
    controllerAs: '$ctrl',
    template,
    bindToController: 'true',
  };
}

export {ActivityTypeFilterModalController, activityTypeFilterModal};
