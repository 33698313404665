import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import Centered from '../../../../style/Centered';
import DashCard from '../DashCard/DashCard';
import showSupportModal from '../../../../util/showSupportModal';
import LinkButton from '../../../../components/LinkButton/LinkButton';

const Content = styled(Centered)`
  padding: 50px;
  flex-direction: column;
  background-color: #dcfbd0;
`;

const Text = styled.p`
  font-size: 1.3rem;
  text-align: center;
  line-height: 2rem;
`;

interface Props {}

const MoreToComeCard: React.FC<Props> = () => {
  const {t} = useTranslation(['home']);
  return (
    <DashCard title={t('We’d Love to Hear from You')} titleIcon="🎉" centerAlignContent>
      <Content>
        <Text>
          We are always trying to make Map My Customers better for you.
          We would love to hear your <LinkButton onClick={showSupportModal}>feedback and suggestions</LinkButton> about
          the new home page!
        </Text>
      </Content>
    </DashCard>
  );
};

export default MoreToComeCard;
