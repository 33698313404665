import BaseNetworkService from './base-network-service/base-network-service';
import safeLocalStorage from '../shared-services/safe-local-storage-service';

export default function LocationNetworkService() {
  // service to return
  const service = {};

  // get location
  service.getLocation = (filters, options) => {
    const params = {
      $filters: JSON.stringify(filters),
      $limit: BaseNetworkService.getLimit(options),
      $offset: BaseNetworkService.getSkip(options),
      $count: BaseNetworkService.getCount(options),
    };
    if (options.order) {
      params.$order = options.order;
    }

    return BaseNetworkService.read(`organizations/${safeLocalStorage.currentUser.organization.id}/locations`, params);
  };

  return service;
}
