import template from './manual-route-welcome-modal.html';
import './manual-route-welcome-modal.scss';
import mapActions from '../../../store/store-helpers';

class ManualRouteWelcomeModalController {
  constructor() {
    mapActions(this, ['RoutingService']);
  }

  closeManualWelcomeModal() {
    this.RoutingServiceActions.closeManualWelcomeModal();
  }
}

ManualRouteWelcomeModalController.$inject = [];

const manualRouteWelcomeModal = {
  bindings: {},
  controller: 'ManualRouteWelcomeModalController',
  template,
};

export {ManualRouteWelcomeModalController, manualRouteWelcomeModal};
