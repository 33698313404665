import template from './edit-route-name.html';
import mapActions from '../../../store/store-helpers';

EditRouteNameController.$inject = ['$window', 'RoutingService'];

function EditRouteNameController($window, RoutingService) {
  Object.assign(this, {
    $window,
    MODEL: $window.DataModel,
    RoutingService,
    closeEditRouteName,
    saveNewRouteName,
    updateRouteEditName,
    $onInit,
  });

  function $onInit() {
    this.routeEditName = this.MODEL.RoutingService.routeEditName;
  }

  mapActions(this, ['RoutingService']);

  function closeEditRouteName() {
    $('#editRouteNameBox').hide();
  }

  function saveNewRouteName() {
    this.RoutingService.saveNewRouteName();
    this.closeEditRouteName();
  }

  function updateRouteEditName() {
    this.RoutingServiceActions.updateRouteEditName(this.routeEditName);
  }
}

function editRouteName() {
  return {
    restrict: 'E',
    bindToController: {},
    scope: {},
    controller: 'EditRouteNameController',
    controllerAs: '$ctrl',
    template,
  };
}

export {EditRouteNameController, editRouteName};
