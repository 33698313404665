class StageCellRenderer {
  init(agParams) {
    this.eGui = document.createElement('button');
    this.eGui.type = 'button';
    this.eGui.className = 'dealStageBtn';
    this.refresh(agParams);
  }

  refresh(agParams) {
    this.agParams = agParams;
    const name = this.agParams.valueFormatted;
    this.eGui.innerText = name && name.length > 15 ? `${name.substr(0, 15)}...` : name;
    this.eGui.style.backgroundColor = window.DataModel.colorsToHex[agParams.data.stage.color];
    return true;
  }

  getGui() {
    return this.eGui;
  }
}

export default StageCellRenderer;
