import cloneDeep from 'lodash/cloneDeep';
import mapActions from '../../../store/store-helpers';
import customFieldsNetworkService from '../../../network-services/custom-fields-network-service';
import safeLocalStorage from '../../../shared-services/safe-local-storage-service';
import analyticsService from '../../../shared-services/analytics-service';

import template from './custom-fields-settings.html';
import './custom-fields-settings.scss';

class CustomFieldsSettingsController {
  constructor(BaseController, mmcConst, SettingsService) {
    Object.assign(this, {
      MODEL: window.DataModel,
      UTILS: window.mmcUtils,
      getEntityDisplayName: BaseController.getEntityDisplayName,
      mmcConst,
      SettingsService,
      visibleCustomFields: [],
    });

    mapActions(this, ['modals']);
  }

  $onInit() {
    this.currentUser = safeLocalStorage.currentUser;
  }

  addNewCustomField() {
    this.modalsActions.showModal('addCfModal');
  }

  async customFieldMoved($index, e) {
    if (e) {
      e.stopPropagation();
    }

    this.MODEL.orgCustomFields.splice($index, 1);
    const fieldsToUpdate = this.MODEL.orgCustomFields
    // update displayOrder for items with new position
      .map((customField, index) => (customField.displayOrder !== index + 1) && {...customField, displayOrder: index + 1})
    // filter out false values to only keep updated custom fields
      .filter(customField => !!customField);

    if (!fieldsToUpdate.length) {
      return;
    }

    try {
      await Promise.all(fieldsToUpdate.map(field => customFieldsNetworkService.update(this.activeType, field)));
    } catch (e) {
      this.UTILS.showValidationError(e);
    }

    this.changeCustomFieldsOptions(this.activeType);
  }

  async changeCustomFieldsOptions(crmObjectType) {
    this.activeType = crmObjectType;
    this.visibleCustomFields = [];
    this.MODEL.show.loader = true;
    await this.SettingsService.getCustomFields(crmObjectType);
    window.refreshDom({loader: false}, 'show');
  }

  toggleCustomField(customFieldId) {
    if (this.visibleCustomFields.includes(customFieldId)) {
      this.visibleCustomFields = this.visibleCustomFields.filter(id => id !== customFieldId);
    } else {
      this.visibleCustomFields.push(customFieldId);
    }
  }

  onCustomFieldChange(cf) {
    cf.isChanged = true;
  }

  async deleteCustomField(customField) {
    try {
      await swal({
        title: 'Are you sure?',
        text: `Deleting this custom field will remove it from all ${this.getEntityDisplayName(this.activeType)}`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, delete this custom field',
      });

      await customFieldsNetworkService.remove(this.activeType, customField.id);
      swal('Deleted...', 'This custom field has been deleted successfully.', 'success');
      this.changeCustomFieldsOptions(this.activeType);
    } catch (e) {
      if (e === 'cancel') {
        return;
      }

      this.UTILS.showValidationError(e);
    }
  }

  customFieldTransform(displayName) {
    return ({displayName});
  }

  revertCustomFieldChanges(cf) {
    const originalCustomField = this.MODEL.orgCustomFieldsOriginal.find(({id}) => id === cf.id);
    const customFieldIndex = this.MODEL.orgCustomFields.findIndex(({id}) => id === cf.id);
    if (!originalCustomField || customFieldIndex < 0) {
      return;
    }

    this.MODEL.orgCustomFields[customFieldIndex] = cloneDeep(originalCustomField);
  }

  async saveCustomFieldChanges(cf) {
    if (this.isStandardFieldName(cf.displayName)) {
      return swal('Uh-oh', 'You can not use standard field names, please pick something else', 'error');
    }

    // new option(s) has been added, need to assign it a value
    if (cf.options.some(({isTag}) => isTag)) {
      const originalCustomField = this.MODEL.orgCustomFieldsOriginal.find(({id}) => id === cf.id);
      let nextValue = originalCustomField.options.reduce((res, {value}) => (res > value ? res : value), 0) + 1;

      cf.options = cf.options.map(option => {
        if (!option.isTag) {
          return option;
        }

        return {value: nextValue++, displayName: option.displayName};
      });
    }

    cf.defaultValue = [];
    this.MODEL.show.loader = true;

    try {
      const response = await customFieldsNetworkService.update(this.activeType, cf);
      analyticsService.entityUpdated('Custom field', {type: this.activeType, ...cf});
      const originalCustomFieldIndex = this.MODEL.orgCustomFieldsOriginal.findIndex(({id}) => id === cf.id);
      const customFieldIndex = this.MODEL.orgCustomFields.findIndex(({id}) => id === cf.id);

      if (originalCustomFieldIndex < 0 || customFieldIndex < 0) {
        cf.isChanged = false;
        return;
      }

      this.MODEL.orgCustomFieldsOriginal[originalCustomFieldIndex] = response;
      this.MODEL.orgCustomFields[customFieldIndex] = cloneDeep(response);
    } catch (e) {
      this.UTILS.showValidationError(e);
    }

    window.refreshDom({loader: false}, 'show');
  }

  isStandardFieldName(name) {
    return this.mmcConst.standardFieldNames[this.activeType].includes((name || '').trim().toLowerCase());
  }
}

CustomFieldsSettingsController.$inject = ['BaseController', 'mmcConst', 'SettingsService'];

const customFieldsSettings = {
  bindings: {
    activeType: '=',
  },
  controller: 'CustomFieldsSettingsController',
  template,
};

export {CustomFieldsSettingsController, customFieldsSettings};
