import template from './add-accounts-form.html';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';

class AddAccountsFormController {
  constructor($window, MainService) {
    Object.assign(this, {
      MODEL: $window.DataModel,
      MainService,
    });
  }

  $onInit() {
    const extraDetails = {
      color: {options: safeLocalStorage.currentUser.organization.plan.colors},
      name: {required: () => true},
      country: {required: () => !!this.accounts.addressAdd},
      website: {link: () => (this.accounts.websiteEdit ? `http://${this.accounts.websiteAdd}` : '')},
      email: {link: () => `mailto:${this.accounts.emailAdd || ''}`},
      phone: {link: () => `tel:${this.accounts.phoneAdd || ''}`},
      address: {
        link: () => '',
        linkImg: './images/curr_location.svg',
        linkId: 'gpsBox',
        fn: 'findMe',
      },
    };

    this.generalFields = this.MODEL.accountFieldModel.editFormFields
      .filter((field) => !field.isAddressField)
      .map((field) => {
        const editFormDetails = {...field.editFormDetails, ...extraDetails[field.name]};
        return editFormDetails;
      });
    this.contactFields = [
      {
        label: 'Phone Number',
        model: 'phone',
        type: 'tel',
        id: 'phone',
        name: 'phone',
        columnWidth: '12',
        link: () => `tel:${this.accounts.phoneAdd || ''}`,
        linkId: 'phoneBox',
        linkImg: './images/phoneImage.svg',
      },
      {
        label: 'Email Address',
        model: 'email',
        type: 'email',
        id: 'email',
        name: 'email',
        columnWidth: '12',
        link: () => `mailto:${this.accounts.emailAdd || ''}`,
        linkId: 'emailBox',
        linkImg: './images/emailImage.svg',
      },
    ].concat(this.MODEL.accountFieldModel.editFormFields
      .filter((field) => field.isAddressField)
      .map((field) => {
        const editFormDetails = {...field.editFormDetails, ...extraDetails[field.name]};
        return editFormDetails;
      }));
  }

  fieldFunction(method) {
    if (method) {
      this[method]();
    }
  }

  setDirtyFlag(fieldName) {
    this.MODEL.dirtyEditFlag = true;

    if (fieldName && ['address', 'city', 'state', 'country', 'zip'].includes(fieldName)) {
      this.MODEL.typeOfLocationUpdatedMostRecently = 'address';

      if (fieldName !== 'country' && !this.addressTouched) {
        this.addressTouched = true;
        this.accounts.countryAdd = 'United States';
      }
    }
  }


  attachParentAccount = async function () {
    this.MODEL.AddEditService.editPopupView = 'parentAccount';
    this.MainService.populateEditPopupData('parentAccount');
  };


  clearParentAccount(event) {
    event.stopPropagation();
    this.MODEL.currentAssociatedAccountId = '';
    $('#parentAccountAddAccount').text('');
    $('#linkAccAccAdd').show();
    $('#clearAccAccAdd').hide();
  }
}

AddAccountsFormController.$inject = ['$window', 'MainService'];

const addAccountsForm = {
  bindings: {
    accounts: '=',
    findMe: '&',
  },
  controller: AddAccountsFormController,
  template,
};

export {AddAccountsFormController, addAccountsForm};
