import isNil from 'lodash/isNil';
import invariant from 'invariant';
import Field from './field';
import {parseDate, parseTime} from '../parser';
import {booleanFormatter, dateFormatter, timeFormatter} from '../formatter';
import CustomFieldDataType from './CustomFieldDataType';
import FieldFeature from './FieldFeature';
import filterComparator, {
  gridFilterComparator, platformComparator,
} from '../../../react/type/network/filterComparator';
import {defaultFilterParams, customFieldTextFilterOptions} from '../column-types';

import EntityType from '../../../react/type/EntityType';

const entityTypeToLookupPropertyName = {
  [EntityType.COMPANY]: 'accountCustomFieldId',
  [EntityType.PERSON]: 'contactCustomFieldId',
  [EntityType.DEAL]: 'dealCustomFieldId',
  [EntityType.ACTIVITY]: 'crmActivityCustomFieldId',
};

const monetaryAmountGetter = (fn) => (params) => fn(params.data).value;

export const currencyFormatter = (entity, {currencyId, value}) => {
  const currency = window.DataModel.CustomFields.currencies.find(({id}) => id === currencyId);
  if (!currency) {
    return undefined;
  }
  if (!Number.isFinite(value)) {
    return currency.code;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency.code,
    minimumFractionDigits: 0,
  });

  return formatter.format(value);
};

export default class CustomField extends Field {

  optionName2value = {};
  optionValue2name = {};

  /**
     * Creates new Custom Field object
     * @param {EntityType} entityType entity type
     * @param {{}} customFieldData custom field data as it is returned by the backend
     * @param {{}} additionalProperties any additional field properties, e.g. to override default ones if necessary
     */
  constructor(entityType, customFieldData, additionalProperties = {}) {
    let valueFormatter;
    let valueGetter;
    const features = [FieldFeature.CUSTOM_FIELD];
    switch (customFieldData.dataType) {
      case CustomFieldDataType.DATE:
        features.push(FieldFeature.DATE);
        valueGetter = parseDate;
        valueFormatter = dateFormatter;
        break;
      case CustomFieldDataType.TIME:
        features.push(FieldFeature.DATE); // really?
        valueFormatter = timeFormatter;
        break;
      case CustomFieldDataType.MONETARY:
        features.push(FieldFeature.OBJECT);
        features.push(FieldFeature.FILTERABLE);
        features.push(FieldFeature.NUMERIC);
        valueFormatter = currencyFormatter;
        break;
      case CustomFieldDataType.BOOLEAN:
        valueFormatter = booleanFormatter;
        break;
      case CustomFieldDataType.INTEGER:
        features.push(FieldFeature.FILTERABLE);
        features.push(FieldFeature.NUMERIC);
        break;
      case CustomFieldDataType.DECIMAL:
        features.push(FieldFeature.FILTERABLE);
        features.push(FieldFeature.NUMERIC);
        break;
      case CustomFieldDataType.SINGLE_OPTION:
        features.push(FieldFeature.FILTERABLE);
        features.push(FieldFeature.LIST);
        break;
      case CustomFieldDataType.MULTI_OPTION:
        features.push(FieldFeature.FILTERABLE);
        features.push(FieldFeature.LIST);
        break;
      case CustomFieldDataType.ADDRESS:
      case CustomFieldDataType.LARGE_TEXT:
      case CustomFieldDataType.PHONE:
      case CustomFieldDataType.TEXT:
        features.push(FieldFeature.FILTERABLE);
        features.push(FieldFeature.TEXT);
        break;
    }

    super({
      name: `${customFieldData.id}`,
      displayName: customFieldData.displayName,
      valueFormatter,
      valueGetter,
      features,
      ...additionalProperties,
    });

    this._customFieldData = customFieldData;
    this._lookupPropertyName = entityTypeToLookupPropertyName[entityType];
    this._optionMap = new Map((customFieldData.options || [])
      .map(({value, displayName}) => [value, displayName]));

    this._enhanceGridProperties();
  }

  _enhanceGridProperties() {
    if (this._customFieldData.dataType === CustomFieldDataType.MONETARY) {
      this.gridProperties.cellClass = 'md-text-right';
      if (this.features.has(FieldFeature.FILTERABLE)) {
        this.gridProperties.filter = 'agNumberColumnFilter';
        this.gridProperties.filterValueGetter = monetaryAmountGetter(this.getEntityValue.bind(this));
      }
    }
    if ([CustomFieldDataType.SINGLE_OPTION, CustomFieldDataType.MULTI_OPTION].includes(this._customFieldData.dataType)) {
      this.gridProperties = {
        ...this.gridProperties,
        filterValueGetter: (ds) => this.mapCustomFieldIdToValue(this.getEntityValue.bind(this)(ds.data)),
        filterParams: {
          ...defaultFilterParams,
          values: (params) => {
            params.success(this._customFieldData.options.map(option => option.displayName));
          },
        },
      };
      const name2value = {};
      const value2name = {};
      this._customFieldData.options.forEach(option => {
        name2value[option.displayName] = option.value;
        value2name[option.value] = option.displayName;
      });
      this.optionName2value = name2value;
      this.optionValue2name = value2name;
    }
    if (this.features.has(FieldFeature.TEXT)) {
      this.gridProperties.filterParams = {
        ...defaultFilterParams,
        ...(this.gridProperties.filterParams || {}),
        filterOptions: customFieldTextFilterOptions,
        prepareFilterToFilteredFieldShowing: this.prepareFilterToFilteredFieldShowing,
      };
    }

  }

  /**
   * @param {array} values
   * @returns {array}
   */
  mapCustomFieldValueToId(values) {
    return values.map(value => this.optionName2value[value]);
  }

  /**
   * @param {array} values
   * @returns {array}
   */
  mapCustomFieldIdToValue(values) {
    return values.map(value => this.optionValue2name[value]);
  }

  /**
   * This is a tricky mapping for custom text fields, because platform has such API.
   * You can check same mapping in addToFilter function
   * @param {string} filter
   * @returns {string}
   */
  prepareFilterToFilteredFieldShowing(filter) {
    const resultFilter = {...filter};
    const comparator2comparator = {
      [platformComparator.EQUALS]: platformComparator.CONTAINS,
      [platformComparator.NOT_EQUAL]: platformComparator.NOT_CONTAINS,
      [platformComparator.CONTAINS]: platformComparator.EQUALS,
      [platformComparator.NOT_CONTAINS]: platformComparator.NOT_EQUAL,
    };
    const comparator = Object.keys(comparator2comparator).find(comparator => !!resultFilter[comparator]);
    if (comparator) {
      resultFilter[comparator2comparator[comparator]] = resultFilter[comparator];
      delete resultFilter[comparator];

    }
    return resultFilter;
  }


  /**
   * @returns {boolean}
   */
  get isSingleOptionField() {
    return this._customFieldData.dataType === 'single-option';
  }

  /**
   * @returns {boolean}
   */
  get isMultiOptionField() {
    return this._customFieldData.dataType === 'multi-option';
  }

  /**
   * @returns {boolean}
   */
  get isOptionField() {
    return this.isSingleOptionField || this.isMultiOptionField;
  }

  /**
   * @returns {*}
   */
  get customFieldData() {
    return this._customFieldData;
  }

  /**
   * Returns column's value from the given entity object
   * @param {Object | undefined} entity
   * @returns {*}
   */
  getEntityValue(entity) {
    const customFieldValue = ((entity && entity.customFields) || [])
      .find(fieldValue => fieldValue[this._lookupPropertyName] === this._customFieldData.id);
    // could use this._customFieldData.defaultValue if there's no customFieldValue,
    // but our UI doesn't allow setting default values now, thus we can't use this field
    let value = customFieldValue ? customFieldValue.value : undefined;

    if (this.isOptionField) {
      return value || [];
    }

    value = Array.isArray(value) && value.length ? value[0] : undefined;
    if (isNil(value)) {
      return value;
    }

    switch (this._customFieldData.dataType) {
      case 'date': return parseDate(value);
      case 'time': return parseTime(value);
      case 'decimal': return parseFloat(value);
      case 'integer': return parseInt(value, 10);
      case 'monetary': return {currencyId: parseInt(value.currencyId, 10), value: parseInt(value.value, 10)};
    }

    return value;
  }

  /**
   * Returns formatted value from the given entity object and value.
   * @param {Object} entity
   * @param {*} value
   * @returns {string}
   */
  _formatValue(entity, value) {
    if (isNil(value)) {
      return '';
    }
    if (this.isOptionField && this._optionMap) {
      const formattedValue = value.map((optionId) => this._optionMap.get(optionId));
      return this.isSingleOptionField ? formattedValue[0] : formattedValue;
    }
    return this._valueFormatter ? this._valueFormatter(entity, value) : value;
  }

  set customGridProperties(customGridProperties) {
    this.gridProperties = super._buildGridProperties(customGridProperties);
    this._enhanceGridProperties();
  }

  addToFilter(filter, comparator, value, valueTo) {
    let filterValue;
    if (this.features.has(FieldFeature.LIST)) {
      if (value.length) {
        filterValue = {
          [filterComparator[gridFilterComparator.CONTAINS]]: this.mapCustomFieldValueToId(value),
        };
      }
    } else if (comparator === gridFilterComparator.IN_RANGE) {
      filterValue = {
        [filterComparator[gridFilterComparator.GREATER_THAN_OR_EQUAL]]: value,
        [filterComparator[gridFilterComparator.LESS_THAN_OR_EQUAL]]: valueTo,
      };
    } else if (comparator === gridFilterComparator.CONTAINS) {
      filterValue = {
        [filterComparator[gridFilterComparator.EQUALS]]: value,
      };
    } else if (comparator === gridFilterComparator.NOT_CONTAINS) {
      filterValue = {
        [filterComparator[gridFilterComparator.NOT_EQUAL]]: value,
      };
    } else if (comparator === gridFilterComparator.EQUALS) {
      filterValue = {
        [filterComparator[gridFilterComparator.CONTAINS]]: [value],
      };
    } else if (comparator === gridFilterComparator.NOT_EQUAL) {
      filterValue = {
        [filterComparator[gridFilterComparator.NOT_CONTAINS]]: [value],
      };
    } else {
      invariant(!!filterComparator[comparator], 'Invalid comparator');
      filterValue = {[filterComparator[comparator]]: value};
    }
    if (filterValue) {
      filterValue.dataType = this.customFieldData.dataType;
      return {...filter, customFields: {...filter.customFields, [this.name]: filterValue}};
    }
    return filter;
  }
}
