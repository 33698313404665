// this is a place for gross repeat methods.
// At least we can define them once, if nothing else.
// if something needs to be put on the window, anywhere in the code, this is a better place to put it.

function addCustomersFromAnywhere() {
  console.log('starting navigation so we can add contacts');
  let timer;
  if (window.location.href.indexOf('/contacts') >= 0) {
    timer = 0;
  } else {
    window.DataModel.show.loader = true;
    window.location.href = '#/contacts';
    timer = 100;
  }
  const theInt = setInterval(
    () => {
      if (!window.DataModel.show.loader) {
        console.log('navigation complete? starting import');
        clearInterval(theInt);

        $('#modalbg').show();
        $('#modal').show();
        $('#customerBox').show();
        $('#queryBox').hide();
        $('#lassoSaveBox').hide();
        $('#photoBox').hide();
        $('#customFieldBox').hide();
        $('#territoryBox').hide();

        $('#modal')
          .removeClass('modal-import')
          .removeClass('modal-query')
          .addClass('modal-map');
      }
    }, timer,
  );
}

// this method could use some updates to be more like addCustomersFromAnywhere to be truly robust
function beginOnboardImportFromAnywhere() {
  console.log('starting navigation so we can do onboard import');
  window.location.href = '#/';
  let loop = 0;
  // window.DataModel.show.loader = true;
  const theInt = setInterval(
    () => {
      if (!window.DataModel.show.loader && window.beginOnboardImport) {
        console.log('LOOP', loop);
        console.log('navigation complete? starting import', window.beginOnboardImport);
        clearInterval(theInt);
        window.beginOnboardImport();
      } else {
        loop++;
      }
    }, 100,
  );
}

export {addCustomersFromAnywhere, beginOnboardImportFromAnywhere};
