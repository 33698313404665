import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  Actions,
} from './actions';
import {RootState} from "../rootReducer";

export interface NotificationState {
  unreadTotal: number;
}

const initialState: NotificationState = {
  unreadTotal: 0,
};

const notification = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotificationsUnreadTotal: (state, action: PayloadAction<number>) => {
      state.unreadTotal = action.payload;
    },
  },
});

const notificationState = (state: RootState) => state.notification;

export const getUnreadTotal = createSelector(notificationState, ({unreadTotal}) => unreadTotal);

export type NotificationActions = Actions;
export default notification;
