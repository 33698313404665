import template from './manual-route-questions-modal.html';
import mapActions from '../../../store/store-helpers';

class ManualRouteQuestionsModalController {
  constructor($window, RoutingService, $timeout, $applyAsync) {
    Object.assign(this, {
      $window,
      MODEL: $window.DataModel,
      RoutingService,
      $timeout,
      $applyAsync,
    });

    mapActions(this, ['RoutingService']);
  }

  $onInit() {
    this.routeOp = this.MODEL.RoutingService.routeOp;
    this.timePerRouteStop = this.MODEL.RoutingService.timePerRouteStop;
    this.startAt = this.RoutingService.getStartAt().toISOString();
    this.$timeout(() => {
      const defaultDate = moment().add(5, 'minutes');

      $('#startTimeContainer').datetimepicker({
        format: 'LT',
      });
      const startAtElement = $('#startAt').parent();
      if (startAtElement.data('DateTimePicker')) {
        startAtElement.data('DateTimePicker').date(defaultDate);
        this.RoutingServiceActions.updateStartTime(defaultDate.toISOString());
      }
      startAtElement.on('dp.change', this.updateStartTime.bind(this));

      $('#startDateContainer').datetimepicker({
        format: 'MMM DD, YYYY',
        minDate: moment().startOf('day'),
      });

      const startDateElement = $('#startDate').parent();
      if (startDateElement.data('DateTimePicker')) {
        startDateElement.data('DateTimePicker').date(defaultDate);
        this.RoutingServiceActions.updateStartDate(defaultDate.toISOString());
      }
      startDateElement.on('dp.change', this.updateStartDate.bind(this));
    });
  }

  updateStartTime(e) {
    if (!e.date) {
      return;
    }

    this.RoutingServiceActions.updateStartTime(e.date.toISOString());
    if (this.RoutingService.getStartAt().isBefore(new Date())) {
      const startTimeElement = $('#startAt').parent();
      if (startTimeElement.data('DateTimePicker')) {
        startTimeElement.data('DateTimePicker').date(moment().add(5, 'minutes'));
        this.RoutingServiceActions.updateStartTime(moment().add(5, 'minutes').toISOString());
      }
    }
  }

  updateStartDate(e) {
    if (!e.date) {
      return;
    }

    this.RoutingServiceActions.updateStartDate(e.date.toISOString());
    if (this.RoutingService.getStartAt().isBefore(new Date())) {
      const startTimeElement = $('#startAt').parent();
      if (startTimeElement.data('DateTimePicker')) {
        startTimeElement.data('DateTimePicker').date(moment().add(5, 'minutes'));
        this.RoutingServiceActions.updateStartTime(moment().add(5, 'minutes').toISOString());
      }
    }
  }

  closeManualQuestionsModal() {
    this.RoutingServiceActions.closeManualQuestionsModal();
  }

  getCurrentLocation(locationType) {
    this.RoutingService.getCurrentLocation(locationType);
  }

  updateRouteOp() {
    this.RoutingServiceActions.updateRouteOp(this.routeOp);
  }

  updateTimePerRouteStop() {
    this.RoutingServiceActions.updateTimePerRouteStop(parseInt(this.timePerRouteStop, 10) * 60);
  }
}

ManualRouteQuestionsModalController.$inject = ['$window', 'RoutingService', '$timeout'];

const manualRouteQuestionsModal = {
  restrict: 'E',
  bindings: {
    submitRoute: '&',
  },
  controller: 'ManualRouteQuestionsModalController',
  template,
};

export {ManualRouteQuestionsModalController, manualRouteQuestionsModal};
