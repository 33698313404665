export default class TeamsServiceModel {
  constructor() {
    this.subTeams = [];
    this.subTeamUsers = [];
    this.toggledSharedGroups = false;
    this.toggledSharedRoutes = false;
    this.allTeamMembers = [];
    this.verifiedTeamMembers = [];
    this.teamStructure = {teamOwnerName: '', teamOwnerNames: [], subTeams: []};
    this.employeesVerified = [];
    this.employees = [];
    this.teamId = '';
    this.ownedGroups = [];
    this.ownedAccounts = [];
    this.teamOwnerName = ''; // @deprecated, use teamStructure.teamOwnerName instead
    this.selectedMemberSharedGroups = [];
    this.employeesObjects = [];
    this.customers = [];
    this.updates = [];
    this.markers = [];
    this.poly = '';
    this.bounds = '';
    this.teamMessage = '';
    this.selectedMember = '';
    this.selectedMemberName = '';
    this.selectedMemberRoleId = '';
    this.selectedMemberSharedRoutes = [];
    this.selectedMemberSharedTerritories = [];
    this.selectedMemberSharedAccounts = [];
    this.selectedMemberEmail = '';
    this.selectedMemberAccessRights = [];
    this.selectedMemberPermissions = [];
    this.currentSharedGroups = [];
    this.currentSharedRoutes = [];
    this.map = '';
    this.shareOption = 'accounts';
    this.accessRightsOption = 'accounts';
    this.teamMemberEmailsArray = [];
    this.selectedSubTeam = '';
    this.optionsSubTeamData = '';
    this.createNewSubTeam = false;
    this.createWithNoTeam = false;
    this.teamMembersEmailsArray = [];
    this.changedSubTeamsMembers = [];
    this.selectedSubTeamMemberTypes = [];
    this.teamMap = [];
    this.selectedOption = undefined;
    this.usersWithoutTeam = [];
    this.allUsers = [];
    this.usersLimitLeft = 0;
    this.quantity = 0;
  }

  unselectMember() {
    this.selectedMember = '';
    this.selectedMemberName = '';
    this.selectedMemberRoleId = '';
    this.selectedMemberSharedRoutes = [];
    this.selectedMemberSharedTerritories = [];
    this.selectedMemberSharedAccounts = [];
    this.selectedMemberEmail = '';
    this.selectedMemberAccessRights = [];
    this.selectedMemberPermissions = [];
  }
}
