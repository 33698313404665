import get from 'lodash/get';
import safeLocalStorage from '../shared-services/safe-local-storage-service';

export enum DistanceUnit {
  KM = 'km',
  MILES = 'mi',
}

const DEFAULT_DISTANCE_UNIT = DistanceUnit.MILES;

export const getDistanceUnit = (): DistanceUnit => {
  const settings: any = get(safeLocalStorage, 'currentUser.organization.settings', []);
  return settings.find(({key}: {key: string}) => key === 'distanceUnit')?.value ?? DEFAULT_DISTANCE_UNIT;
};


export const doesUseMiles = (): boolean => getDistanceUnit() === DistanceUnit.MILES;
