import React, {useCallback, useMemo} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import activitiesNetworkService from '../../../network-services/crm-activities-network-service';
import dealNetworkService from '../../../network-services/deals-network-service';
import userNetworkService from '../../../network-services/user-network-service';
import CrmActivityTypesNetworkService from '../../../network-services/crm-activity-types-network-service';
import useDataLoader from '../../hook/useDataLoader';
import useInitializer from '../../hook/useInitializer';
import HomeHeader from './component/HomeHeader/HomeHeader';
import CardsContainer from './component/Cards/CardsContainer';
import ActivitiesCard from './component/Cards/ActivitiesCard';
import ActivityTypesCard from './component/Cards/ActivityTypesCard';
import ListResponse from '../../type/service/ListResponse';
import Me from '../../type/Me';
import User from '../../type/User';
import ActivityType from '../../type/ActivityType';
import TodayActivitiesCard from './component/Cards/TodayActivitiesCard';
import Funnel from '../../type/Funnel';
import StagesCard from './component/Cards/StagesCard';
import UserLocationCard from './component/Cards/UserLocationCard';
import RecentCompaniesCard from './component/Cards/RecentCompaniesCard';
import accountsNetworkService from '../../../network-services/accounts-network-service';
import MoreToComeCard from './component/Cards/MoreToComeCard';
import LoadingProgress from '../../components/LoadingProgress/LoadingProgress';

const HomeContainer = styled.div`
  width: 100%;
  padding: 40px;
  background-color: #F2F4F7;
`;

interface Props {
  CrmActivityTypesNetworkService: ReturnType<typeof CrmActivityTypesNetworkService>,
  user: Me,
}

const Home: React.FC<Props> = ({
  CrmActivityTypesNetworkService: activityTypesNetworkService,
  user,
}) => {
  const {ready} = useTranslation(['home']); // preload translation

  const orgId = user.organization.id;
  const getAllUsers = useCallback(() => userNetworkService.getAllUsers(orgId), [userNetworkService, orgId]);
  const getActivityTypes = useCallback(() => activityTypesNetworkService.getTypes(orgId), [activityTypesNetworkService, orgId]);
  const getAnyActivities = useCallback(
    () => activitiesNetworkService.fetchAllCrmActivities(false, {})
      .then((response) => !!response.data.length),
    [],
  );
  const {isLoading, error, loader, data} = useDataLoader([
    getAllUsers,
    getActivityTypes,
    getAnyActivities,
    dealNetworkService.fetchAllFunnels,
    dealNetworkService.getDealsCount,
    accountsNetworkService.getAccountsCount,
  ]);
  useInitializer(loader);

  const allUsers = useMemo(() => (!data.length ? [] : (data[0] as ListResponse<User>).data), [data]);
  const activityTypes = useMemo(() => (!data.length ? [] : (data[1] as ListResponse<ActivityType>).data), [data]);
  const hasAnyActivities = !data.length ? false : !!data[2];
  const funnels = useMemo(() => (!data.length ? [] : (data[3] as ListResponse<Funnel>).data), [data]);
  const hasAnyDeals = !data.length ? false : data[4] > 0;
  const hasAnyCompanies = !data.length ? false : data[5] > 0;

  if (isLoading || !ready) {
    return (
      <LoadingProgress />
    );
  }

  if (error || !data.length) {
    // TODO: log an error
    return (
      <HomeContainer>
        <p>Unexpected error happened, please try reloading the page. Error details:</p>
        <p>
          {error ? error.message : 'Unknown error'}
        </p>
      </HomeContainer>
    );
  }

  return (
    <HomeContainer className="mmc-scroll-pane mmc-scroll-pane--no-subnav">
      <HomeHeader user={user} />
      <CardsContainer>
        <TodayActivitiesCard
          hasActivities={hasAnyActivities}
          users={allUsers}
        />

        <RecentCompaniesCard
          hasCompanies={hasAnyCompanies}
          users={allUsers}
        />

        <MoreToComeCard />

        <StagesCard
          funnels={funnels}
          hasDeals={hasAnyDeals}
          users={allUsers}
        />

        <ActivitiesCard
          activityTypes={activityTypes}
          hasActivities={hasAnyActivities}
          users={allUsers}
        />

        <ActivityTypesCard
          activityTypes={activityTypes}
          hasActivities={hasAnyActivities}
          users={allUsers}
        />

        <UserLocationCard />

      </CardsContainer>
    </HomeContainer>
  );
};

export default Home;
