import difference from 'lodash/difference';
import addEditNetworkService from '../../../network-services/add-edit-network-service';

export default function GroupsSectionService($route) {
  // when creating a new account/contact/deal we can't save groups coz the entity id is not known
  // so we save them here and can attach group later
  let attachGroupsRequestParams;
  let onCreatePage = false;

  /**
   * Reset the values to save
   * @param {boolean} isOnCreatePage true if groups is being initialized from the add-entity page
   * @return {boolean} true if initialization was successful
   */
  const init = (isOnCreatePage = false) => {
    attachGroupsRequestParams = undefined;
    onCreatePage = isOnCreatePage;

    // List of pages where the groups are allowed to be initialized
    const allowedPages = ['editAccountsPage', 'addAccountsPage', 'editContactsPage', 'addContactsPage',
      'editDealsPage', 'addDealsPage'];

    return isOnCreatePage || allowedPages.includes($route.current.id);
  };

  /**
     * Check if we're on the entity creation page
     * @returns {boolean} <tt>true</tt> if user is currently adding new entity, <tt>false</tt> otherwise
     */
  const isOnCreatePage = () => onCreatePage ||
    ['addAccountsPage', 'addDealsPage', 'addContactsPage'].includes($route.current.id);

  const processGroupUpdates = async (entityType, entityId, groups, previousGroupsIds = []) => {
    const currentGroupsIds = groups.map(({id}) => id);
    const addGroups = difference(currentGroupsIds, previousGroupsIds);
    const removeGroups = difference(previousGroupsIds, currentGroupsIds);
    await Promise.all([
      addGroups.length
        ? addEditNetworkService.addGroups(entityType, entityId, addGroups)
        : Promise.resolve(),
      removeGroups.length
        ? addEditNetworkService.removeGroups(entityType, entityId, removeGroups)
        : Promise.resolve(),
    ]);
    return groups;
  };

  /**
   * Attach all selected groups, should be called when the account/contact/deal is being created
   * @returns {Promise<{}[]>}
   */
  const attachGroups = (entityId) => (attachGroupsRequestParams
    ? processGroupUpdates(
      attachGroupsRequestParams.entityType,
      entityId,
      attachGroupsRequestParams.groups,
      attachGroupsRequestParams.previousGroupsIds,
    )
    : Promise.resolve([])
  );

  const updateGroups = async (entityType, entityId, groups, previousGroupsIds = []) => {
    if (isOnCreatePage()) {
      attachGroupsRequestParams = {entityType, groups, previousGroupsIds};
      return groups;
    }

    return processGroupUpdates(entityType, entityId, groups, previousGroupsIds);
  };

  return {
    init,
    isOnCreatePage,
    attachGroups,
    updateGroups,
  };
}

GroupsSectionService.$inject = ['$route'];
