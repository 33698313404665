import mapActions from '../../../store/store-helpers';
import template from './view-teams-table.html';
import safeLocalStorage from '../../../shared-services/safe-local-storage-service';

class ViewTeamsTableController {
  constructor() {
    Object.assign(this, {
      MODEL: window.DataModel,
    });

    mapActions(this, ['modals']);
  }

  $onInit() {
    this.currentUser = safeLocalStorage.currentUser;
  }

  isMe(username) {
    return this.currentUser.username === username;
  }

  isVerifiedTeamMember(username) {
    return this.MODEL.TeamsService.verifiedTeamMembers.some(user => user.username === username);
  }
}

ViewTeamsTableController.$inject = [];

const viewTeamsTable = {
  bindings: {
    subTeam: '<',
    openSelectedOption: '&',
    showTracks: '&',
  },
  controller: 'ViewTeamsTableController',
  controllerAs: '$ctrl',
  template,
};

export {ViewTeamsTableController, viewTeamsTable};
