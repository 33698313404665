import accountsNetworkService from '../../network-services/accounts-network-service';
import contactsNetworkService from '../../network-services/contacts-network-service';
import dealsNetworkService from '../../network-services/deals-network-service';
import template from './edit-popup.html';
import './edit-popup.scss';

class EditPopupController {
  constructor($window) {
    Object.assign(this, {
      $window,
      MODEL: $window.DataModel,
    });
  }

  async $onInit() {
    this.multipleSelected = {};
  }

  async searchTextForEditPopup() {
    const {editPopupType} = this.MODEL.editPopup;

    switch (editPopupType) {
      case 'childAccounts':
      case 'parentAccount':
      case 'account':
        return this.searchTextForAccountPopup();
      case 'contacts':
        return this.searchTextForContactPopup();
      case 'deals':
        return this.searchTextForDealPopup();
    }
  }

  async searchTextForAccountPopup() {
    const searchText = this.MODEL.searchTextEditPopup.trim();
    let filters;

    if (searchText.length < 3) {
      filters = {

        ...this.MODEL.editPopup.filters,
      };
    } else {
      filters = {
        name: {$in: searchText},
        ...this.MODEL.editPopup.filters,
      };
    }

    this.MODEL.show.loader = true;

    const {data} = await accountsNetworkService.fetchAllAccounts(
      false,
      filters,
      0,
      'name',
      true,
    );
    this.MODEL.accounts = data;
    // do not include account itself into list of available parent accounts
    if (this.MODEL.AddEditService.addEditView === 'accounts' && this.MODEL.currentCRMObject) {
      this.MODEL.accounts = this.MODEL.accounts.filter(({id}) => id !== this.MODEL.currentCRMObject.id);
    }

    const tableRowData = this._convertToTableRowData(this.MODEL.accounts);
    this.$window.refreshDom({tableRowData}, 'editPopup');
    this.$window.refreshDom({loader: false}, 'show');
  }

  async searchTextForContactPopup() {
    const searchText = this.MODEL.searchTextEditPopup.trim();

    if (searchText.length < 3) {
      const tableRowData = this._convertToTableRowData(searchText.length ? this.MODEL.contacts : this.MODEL.editPopup.initialData);
      this.$window.refreshDom({tableRowData}, 'editPopup');

      return;
    }

    this.MODEL.show.loader = true;
    const filters = {
      name: {$in: searchText},
      ...this.MODEL.editPopup.filters,
    };
    const {data} = await contactsNetworkService.fetchAllContacts(
      false,
      filters,
      1,
      'name',
      true,
    );
    this.MODEL.contacts = data;
    const tableRowData = this._convertToTableRowData(data);
    this.$window.refreshDom({tableRowData}, 'editPopup');
    this.$window.refreshDom({loader: false}, 'show');
  }

  async searchTextForDealPopup() {
    const searchText = this.MODEL.searchTextEditPopup.trim();

    if (searchText.length < 3) {
      const tableRowData = this._convertToTableRowData(searchText.length ? this.MODEL.deals : this.MODEL.editPopup.initialData);
      this.$window.refreshDom({tableRowData}, 'editPopup');

      return;
    }

    this.MODEL.show.loader = true;
    const filters = {
      name: {$in: searchText},
      ...this.MODEL.editPopup.filters,
    };
    const {data} = await dealsNetworkService.fetchAllDeals(
      false,
      filters,
      1,
      'name',
      true,
    );
    this.MODEL.deals = data;
    const tableRowData = this._convertToTableRowData(data);
    this.$window.refreshDom({tableRowData}, 'editPopup');
    this.$window.refreshDom({loader: false}, 'show');
  }

  _convertToTableRowData(entities) {
    return entities.map(({
      id, name, email, phone, address,
    }) => ({
      row0: id,
      row1: name,
      row2: email,
      row3: phone,
      row4: address,
    }));
  }

  hideQuickCreateButton() {
    const currentPage = this.MODEL.currentPageEditOrAddSubheader.title;
    const buttonName = this.MODEL.editPopup.addButtonName;
    let hideBtnCond = (currentPage === 'Add Company')
            || (currentPage === 'Add Person')
            || (currentPage === 'Edit Person');
    hideBtnCond = hideBtnCond && buttonName === 'Add New Deal';

    return !hideBtnCond;
  }

  associateMultipleCrmObjects() {
    Object.keys(this.multipleSelected)
      .filter(id => this.multipleSelected[id])
      .forEach(id => {
        this.associateCrmObject({crmObjectId: parseInt(id, 10)});
      });
  }

  noObjectsSelected() {
    return !Object.keys(this.multipleSelected).some(id => this.multipleSelected[id]);
  }

  handleQuickAddCrmRecord() {
    this.quickAddCrmRecord();
  }
}

EditPopupController.$inject = ['$window'];

const editPopup = {
  bindings: {
    associateCrmObject: '&',
    quickAddCrmRecord: '&',
  },
  controller: 'EditPopupController',
  template,
};

export {EditPopupController, editPopup};
