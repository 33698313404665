import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import Organization from '../react/type/Organization';
import safeLocalStorage from '../shared-services/safe-local-storage-service';

type OrgId = Organization['id'];

export class UserRecentNetworkService {

  get orgId(): number {
    return safeLocalStorage.currentUser!.organization.id;
  }

  addUserRecent(payload: { entity: { id: string, type: string } }) {
    const endPoint = `organizations/${this.orgId}/user-activities`;
    return BaseNetworkServiceWithPromises.create(endPoint, payload);
  }
}

export default new UserRecentNetworkService();
