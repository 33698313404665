import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import {ColDef} from '@ag-grid-community/core';
import invariant from 'invariant';
import FieldFeature from './FieldFeature';
import {defaultTooltipValueGetter, valueFormatterUsingFunction, valueGetterUsingFunction} from './grid-util';
import IField, {FieldFilter} from '../../../react/type/fieldModel/IField';
import filterComparator, {gridFilterComparator} from '../../../react/type/network/filterComparator';

export type FieldValueGetter = string | string[] | ((entity: any, field: IField) => any);
export type FieldValueFormatter = (entity: any, value: any) => string;

export type FieldProperties = {
  name: string,
  displayName?: string,
  displayOrder?: number,
  features?: FieldFeature[],
  valueGetter?: FieldValueGetter,
  valueFormatter?: FieldValueFormatter,
  customGridProperties?: ColDef,

  // should be deprecated
  value?: string,
  editFormDetails?: object,
  filter?: FieldFilter,
}

export interface IFieldConstructor {
  new (properties: FieldProperties): IField,
}

export default class Field implements IField {
  readonly name: string;
  readonly displayName: string;
  readonly displayOrder: number | undefined;
  readonly features: Set<FieldFeature>;

  _customGridProperties?: ColDef;
  gridProperties: ColDef;

  protected readonly _valueGetter: undefined | FieldValueGetter;
  protected readonly _valueFormatter: undefined | FieldValueFormatter;

  readonly value: string | undefined;
  readonly editFormDetails: object | undefined;
  readonly filter?: FieldFilter;

  constructor({
    name,
    displayName,
    displayOrder,
    value,
    valueGetter,
    valueFormatter,
    editFormDetails,
    features,
    filter,
    customGridProperties,
  }: FieldProperties) {
    this.name = name;
    this.displayName = displayName || name;
    this.displayOrder = displayOrder;
    this.value = value;
    this._valueGetter = valueGetter;
    this._valueFormatter = valueFormatter;
    this.editFormDetails = editFormDetails;
    this.filter = filter;
    this.features = new Set(features || []);
    this._customGridProperties = customGridProperties;
    this.gridProperties = this._buildGridProperties(customGridProperties);
  }

  /**
   * Build grid column properties that can be used in the AgGrid to define a column.
   * Properties are created according to FieldProperties and can be extended
   * by the given customGridProperties. customGridProperties takes precedence.
   *
   * @param {{}} customGridProperties
   * @return {{}}
   */
  _buildGridProperties(customGridProperties?: ColDef) {
    const gridProperties: ColDef = {
      colId: this.name,
      type: Array.from(this.features), // some column properties are set according to types, see Grid.tsx
      suppressMenu: false,
      unSortIcon: false,
      suppressSizeToFit: false,
      resizable: true,
      suppressAutoSize: false,
      editable: false,
      headerName: this.displayName,
      headerTooltip: this.displayName,

      // avoid moving columns for now
      suppressMovable: true,
      lockPosition: true,
      lockVisible: true,
      lockPinned: true,
      // end: moving columns
    };

    if (this.features.has(FieldFeature.NUMERIC)) {
      gridProperties.cellClass = 'fc-rtl';
    }

    gridProperties.valueGetter = valueGetterUsingFunction(this.getEntityValue.bind(this));
    gridProperties.tooltipValueGetter = defaultTooltipValueGetter(this.name);
    gridProperties.valueFormatter = valueFormatterUsingFunction(this._formatValue.bind(this));

    if (this.features.has(FieldFeature.FILTERABLE)) {
      gridProperties.menuTabs = ['filterMenuTab'];
    } else {
      gridProperties.filter = false;
    }

    return Object.assign(gridProperties, customGridProperties);
  }

  get customGridProperties(): ColDef | undefined {
    return this._customGridProperties;
  }

  set customGridProperties(customGridProperties: ColDef | undefined) {
    this.gridProperties = this._buildGridProperties(customGridProperties);
  }

  /**
   * @returns {boolean}
   */
  get isAddressField() {
    return this.features.has(FieldFeature.ADDRESS);
  }

  /**
   * @returns {boolean}
   */
  get isCustomField() {
    return this.features.has(FieldFeature.CUSTOM_FIELD);
  }

  /**
   * @returns {function(*): string}
   */
  get valueFormatter() {
    return this._valueFormatter;
  }

  /**
     * @returns {boolean}
     */
  get sortable() {
    return this.features.has(FieldFeature.SORTABLE);
  }

  /**
     * @returns {boolean}
     */
  get isNumeric() {
    return this.features.has(FieldFeature.NUMERIC);
  }

  /**
     * @returns {boolean}
     */
  get isList() {
    return this.features.has(FieldFeature.LIST);
  }

  /**
   * Returns column's value from the given entity object
   * @param {Object} entity
   * @returns {*}
   */
  getEntityValue(entity?: object): any {
    if (!entity) {
      return undefined;
    }
    if (isFunction(this._valueGetter)) {
      return this._valueGetter(entity, this);
    }
    return get(entity, this._valueGetter || this.value || this.name);
  }

  /**
   * Checks if the value is not "empty". Which means undefined, null, empty string or empty array.
   * @param entity
   */
  hasValue(entity?: object): boolean {
    const value = this.getEntityValue(entity);
    return !(isNil(value) || (Array.isArray(value) && !value.length)
      || (typeof value === 'string' && value.trim() === ''));
  }

  /**
   * Returns column's formatted value from the given entity object
   * @param {Object} entity
   * @returns {*}
   */
  getEntityFormattedValue(entity?: object): string {
    if (!entity) {
      return '';
    }
    const value = this.getEntityValue(entity);
    return this._formatValue(entity, value);
  }

  /**
   * Returns formatted value from the given entity object and value.
   * @param {Object} entity
   * @param {*} value
   * @returns {string}
   */
  _formatValue(entity: object, value: any) {
    return this._valueFormatter ? this._valueFormatter(entity, value) : value;
  }

  addToFilter(filter: object, comparator: gridFilterComparator, value: any, valueTo: any) {
    let filterValue: object | string = {};
    if (this.features.has(FieldFeature.LIST)) {
      filterValue = {
        [filterComparator[gridFilterComparator.CONTAINS]]: this.filter?.mapFieldValueToId
          ? this.filter.mapFieldValueToId(value)
          : value,
      };
    } else if (comparator === gridFilterComparator.IN_RANGE) {
      filterValue = {
        [filterComparator[gridFilterComparator.GREATER_THAN_OR_EQUAL]]: value,
        [filterComparator[gridFilterComparator.LESS_THAN_OR_EQUAL]]: valueTo,
      };
    } else if (comparator === gridFilterComparator.EQUALS) {
      filterValue = value;
    } else {
      invariant(!!filterComparator[comparator], 'Invalid comparator');
      filterValue = {[filterComparator[comparator]]: value};
    }
    return {...filter, [this.filter?.name || this.name]: filterValue};
  }
}
