import React from 'react';
import styled from 'styled-components';
import {Page} from '../Import';

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 122px;
  padding: 23px;
`;

const getStepColor = (passed: boolean, active: boolean): string => {
  if (passed) {
    return '#56c7b4';
  }
  if (active) {
    return '#000000';
  }
  return '#aaaaaa';
};

const PageItem = styled.div<{active: boolean, passed: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px 3%;
  color: ${props => getStepColor(props.passed, props.active)};
`;

const StepNumberWrapper = styled.div<{passed: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border: 1px solid;
  border-radius: 21px;
  font-weight: bold;
  background-color: ${props => (props.passed ? '#56c7b4' : 'inherit')};
  margin-bottom: 10px;
  & > i {
    color: #ffffff;
  }
`;

interface Props {
  currentPage: number,
  pages: Page[],
}

const Breadcrumb: React.FC<Props> = ({currentPage, pages}) => (
  <Container>
    {pages.map(({name}, i: number) => {
      const passed: boolean = currentPage > i;
      const active: boolean = currentPage === i;
      return (
        <PageItem key={i} active={active} passed={passed}>
          <StepNumberWrapper passed={passed} className="number">
            {passed ? <i className="fa fa-check" /> : i + 1}
          </StepNumberWrapper>
          <div>{name}</div>
        </PageItem>
      );
    })}
  </Container>
);

export default Breadcrumb;
