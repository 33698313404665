import find from 'lodash/find';
import remove from 'lodash/remove';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import mapActions from '../../store/store-helpers';
import analyticsService from '../../shared-services/analytics-service';
import helperService from '../../shared-services/helper-service';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';
import accountsNetworkService from '../../network-services/accounts-network-service';
import contactsNetworkService from '../../network-services/contacts-network-service';
import GeocodingNetworkService from '../../network-services/geocoding-network-service';
import routingNetworkService from '../../network-services/routing-network-service';
import EntityType from '../../react/type/EntityType';
import {getUserLocationAddress} from '../../shared-services/geo-service';
import {doesUseMiles} from '../../common/settings';

const meterToMiles = 1609.34;

export default function RoutingService(
  $rootScope, $route, LassoService, FetchCRMDataService, MappingService, $location,
) {
  // main utility functions
  const UTILS = window.mmcUtils;
  const {MAN} = window.mmcUtils;
  const MODEL = window.DataModel;

  // service to return
  const service = {};

  mapActions(service, ['RoutingService', 'modals']);

  //
  // ------------------ MANUAL ROUTING Modals ------------------ //
  //

  // choose the route style from optimize or in-order
  const chooseRouteStyle = () => swal({
    title: 'Select Route Type',
    text: 'Optimize your route for shortest time and distance or visit pins in the order you made your route.',
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#9ed444',
    confirmButtonText: '<i class="icon ion-ios-stopwatch"></i>    Optimize Entire Route',
    cancelButtonText: '<i class="icon ion-android-share-alt"></i>   Visit Route In Order',
    // confirmButtonClass: 'btn btn-primary',
    // cancelButtonClass: 'btn btn-primary',
    buttonsStyling: true,
  });

  // interval for stop at each location
  const routeTimePerStop = () => swal({
    title: 'How long at each stop?',
    showCloseButton: true,
    showCancelButton: false,
    allowOutsideClick: false,
    customClass: 'animated fadeInUpBig',
    animation: false,
    confirmButtonText: 'Next',
    html: `
        <p style="font-size:18px;">Choose the amount of time you will spend at each stop.</p>
        <div class="form-group" style="margin:15px 25px; margin-top:0px;">
        <select class="form-control" id="routeTime" ng-model="data.RoutingService.timePerRouteStop">
            <option value="" selected="">Do Not Set</option>
            <option value="300">5 Minutes</option>
            <option value="900">15 Minutes</option>
            <option value="1800">30 Minutes</option>
            <option value="2700">45 Minutes</option>
            <option value="3600">60 Minutes</option>
        </select>
        </div>
`,
    preConfirm() {
      return new Promise(((resolve) => {
        service.RoutingServiceActions.updateTimePerRouteStop(document.getElementById('routeTime').value);
        resolve();
      }));
    },
  });

  // choose starting location for the route
  const chooseStartingLocation = () => swal({
    title: 'Select Starting Location',
    text: 'Would you like to begin this route from your current location or a different location?',
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#9ed444',
    confirmButtonText: '<i class="icon ion-navigate"></i> Current Location',
    cancelButtonText: '<i class="icon ion-map"></i> Choose Starting Location',
    // confirmButtonClass: 'btn btn-primary',
    // cancelButtonClass: 'btn btn-primary',
    buttonsStyling: true,
  });

  // interval for stop at each location
  const chooseEndingLocation = () => swal({
    title: 'Select Ending Location',
    type: 'warning',
    showCloseButton: false,
    showCancelButton: false,
    allowOutsideClick: true,
    showConfirmButton: false,
    customClass: 'animated fadeInUpBig',
    animation: false,
    html: `
        <p>Choose where you would like to end your route...</p>
        <button type="button" role="button" tabindex="0" class="CurrentLocation customSwalBtn" style="background-color: rgb(48, 133, 214);"><i class="icon ion-navigate"></i> Current Location </button>
        <button type="button" role="button" tabindex="0" class="EndingLocation customSwalBtn" style="background-color: rgb(158, 212, 68);"><i class="icon ion-map"></i> Choose Ending Location  </button>
        <button type="button" role="button" tabindex="0" class="OptimizerDecide customSwalBtn" style="background-color: rgb(0, 197, 239);"><i class="icon ion-iphone"></i> Let Optimizer Decide </button>
`,
  });

  // enter the custom starting location for the route
  const customStartingLocation = () => swal({
    title: 'Select Starting Location',
    text: 'Select the location you\'d like to begin this route from below.',
    input: 'text',
    showCancelButton: false,
    confirmButtonText: 'Next',
    showCloseButton: true,
    allowOutsideClick: false,
  });

  //
  // ------------------ GENERAL Routing Methods ------------------ //
  //

  // grab current user's routes
  service.fetchRoutes = async function (filters, page, column, ascending) {
    // set default current values if not sent
    page = page || MODEL.cachedState.routes.page;
    column = column || MODEL.cachedState.routes.column;
    ascending = ascending || MODEL.cachedState.routes.ascending;
    const data = await routingNetworkService.getRoutes(filters, page, column, ascending);
    const routesData = data.data;
    MODEL.routesCount = data.total;

    if (!data.total && !MODEL.currentLeadsView && !MODEL.FilterService.filtersSelected.length) {
      service.modalsActions.showModal('noRoutesModal');
    } else {
      service.modalsActions.resetVisibility('noRoutesModal');
    }

    MODEL.routes = routesData;
    window.refreshDom({routes: MODEL.routes});
    return data;
  };

  service.updateGeocodingLimits = async function () {
    const limits = await GeocodingNetworkService.getGeocodingLimits();
    MODEL.geocodingOrgLimit = limits.organization.limit;
    MODEL.geocodingOrgLimitReached = limits.organization.limit <= limits.organization.used;
    MODEL.geocodingMmcLimitReached = limits.MMC.limit <= limits.MMC.used;
  };

  // get all routes for this contact
  service.getRoutesForCustomer = async (contactId, useReturnValueNotGlobal = false) => {
    if (!contactId) {
      return [];
    }

    const routes = (await routingNetworkService.getRoutes({includeContacts: true})).data;
    const result = routes.filter(route => route.routeContacts.some(({id}) => id === contactId));

    if (useReturnValueNotGlobal) {
      return result;
    }
    service.RoutingServiceActions.updateRoutesForCustomer(result);
    service.RoutingServiceActions.updateUsersRoutes(routes);

    window.refreshDom({});
  };

  // get all routes for this account
  service.getRoutesForAccount = async (accountId, useReturnValueNotGlobal = false) => {
    if (!accountId) {
      return [];
    }

    const routes = (await routingNetworkService.getRoutes({includeAccounts: true})).data;
    const result = routes.filter(route => route.routeAccounts.some(({id}) => id === accountId));

    if (useReturnValueNotGlobal) {
      return result;
    }
    service.RoutingServiceActions.updateRoutesForCustomer(result);
    service.RoutingServiceActions.updateUsersRoutes(routes);

    window.refreshDom({});
  };

  service.setIndividualHeaders = function () {
    $('#allroutes').hide();
    $('#subheader').hide();
    $('#routeDetail').show();
    $('#map').show();

    MODEL.show.loader = true;
    service.RoutingServiceActions.updateFromEditData(false);
    MappingService.createCustomMarkerPrototype();
  };

  // set the view headers
  service.setViewHeaders = (map) => {
    const viewObject = window.isOnPage('accounts') ? {
      subTitle: 'Companies',
      count: MODEL.accountsCount,
    } : {
      subTitle: 'People',
      count: MODEL.contactsCount,
    };
    const title = map ? `Mapped ${viewObject.subTitle}` : 'Routes';
    const count = map ? viewObject.count : MODEL.routesCount;
    const btn = 'Add Route';
    const showFilter = !!map; // map can be undefined, we need to provide boolean
    const showAdd = true;
    FetchCRMDataService.setCurrentPageVariable(title, count, btn, showFilter, showAdd);
  };

  // route details
  service.showRouteDetails = async function (startingLocation, endingLocation) {
    console.error('!!! showRouteDetails', MODEL.RoutingService.routeObjects);
    if (!startingLocation && !endingLocation && !MAN.userPosition) {
      MAN.promptForPosition(() => {
        service.showRouteDetails(startingLocation, endingLocation);
      });
    } else if (MODEL.RoutingService.routeObjects.length > 0) {
      // filter all data that has geoPoint
      // case for when we smart route through groups
      // it might have contacts with no geopoint
      service.RoutingServiceActions.updateRouteObjects(
        MODEL.RoutingService.routeObjects.filter(c => !!c.geoPoint),
      );

      if (MODEL.RoutingService.encodedPolyline) {
        MODEL.RoutingService.encodedPolyline.setMap(null);
      }

      // remove previous route markers
      MODEL.RoutingService.routeMarkers.forEach((marker) => {
        marker.setMap(null);
        marker = null;
      });
      MODEL.RoutingService.removeRouteMarkers();

      // remove previous markers before creating new one's on the map
      MODEL.RoutingService.routesNewMarker.forEach((marker) => {
        marker.setMap(null);
        marker = null;
      });

      // hide route style options modal
      service.RoutingServiceActions.updateDirectionsBeingShown(true);

      await service.executeRoute(startingLocation, endingLocation);

      service.RoutingServiceActions.updateFromChooseRoutes(false);
    } else {
      swal('Uh-Oh', 'No pins in this route.', 'error');
      window.location.href = window.isOnPage('accounts') ? '/#/accounts/routing/list' : '/#/contacts/routing/list';
    }
  };

  // execute the route from above
  service.executeRoute = async function (startingLocation, endingLocation) {
    let date = service.getStartAt();
    if (moment().isAfter(date)) {
      date = moment().add(5, 'minutes');
      swal({
        title: 'Start Date Updated',
        text: 'This date was updated to the current day because routes can’t be in the past.',
        type: 'success',
      });
      const startTimeElement = $('#start-time').parent();
      if (startTimeElement.data('DateTimePicker')) {
        service.RoutingServiceActions.updateStartTime(date.toISOString());
        startTimeElement.data('DateTimePicker').date(date);
      }
      const startDateElement = $('#start-date').parent();
      if (startDateElement.data('DateTimePicker')) {
        service.RoutingServiceActions.updateStartDate(date.toISOString());
        startDateElement.data('DateTimePicker').date(date);
      }
    }
    const params = {
      routeObjects: (MODEL.routeObjectsSaved ?? MODEL.RoutingService.routeObjects).map(ro => ro.id),
      routeType: MODEL.RoutingService.routeTypeSelected,
      startGeoPoint: UTILS.convertStringToGeoPoint(startingLocation),
      startAt: date.toISOString(),
    };

    if (endingLocation) {
      params.endGeoPoint = UTILS.convertStringToGeoPoint(endingLocation);
    }

    if (MODEL.RoutingService.smartRouting) {
      params.smartRouting = true;
    }

    const response = await routingNetworkService.buildRoute(params);
    let routeObjects;
    if (window.isOnPage('accounts')) {
      routeObjects = get(response, 'accounts');
    } else {
      routeObjects = get(response, 'contacts');
    }
    if (routeObjects) {
      service.RoutingServiceActions.updateRouteObjects(routeObjects, true);
    }

    if (response.polyline) {
      MODEL.show.loader = false;
      service.RoutingServiceActions.updateRouteObjects(
        window.isOnPage('accounts') ? response.accounts : response.contacts,
      );
      service.RoutingServiceActions.updateCurrentRouteObjects(
        MODEL.RoutingService.routeObjects.map(ro => ro.id),
      );

      if (MODEL.RoutingService.smartRouting) {
        service.executeSmartRoute();
      } else {
        service.executeManualRoute(response, startingLocation, endingLocation);
      }

      MODEL.show.loader = false;
    } else {
      helperService.showAndLogError(response, 'Unexpected error loading this route. Please try again later.');
      if (window.isOnPage('accounts')) {
        window.location.href = '/#/accounts/routing/list';
      } else {
        window.location.href = '/#/contacts/routing/list';
      }
    }
  };

  service.executeSmartRoute = function () {
    MODEL.smartRouting.flow = false;
    service.RoutingServiceActions.initSmartRouting();
    // get distance & time of route
    let lastArrival = MODEL.RoutingService.smartRoutingDepartureTime;
    let count = 0;
    let breakTimeAdded = false;
    let d = new Date();
    let totalRouteDuration = 0;
    let totalRouteDistance = 0;
    const usualBreakTime = 3600;

    d.setHours(0, 0, 0, 0);
    d /= 1000;

    for (let i = 0; i < MODEL.RoutingService.routeObjects.length; i++) {
      const routeObject = MODEL.RoutingService.routeObjects[i];

      if (MODEL.RoutingService.timePerRouteStop > 0 && count > 0) {
        lastArrival = lastArrival + parseFloat(routeObject.travelTime) + parseFloat(MODEL.RoutingService.timePerRouteStop);
      } else {
        lastArrival += parseFloat(routeObject.travelTime);
      }

      if (lastArrival < MODEL.RoutingService.smartRoutingReturnTime) {
        if (lastArrival > MODEL.RoutingService.smartRoutingBreakTime && !breakTimeAdded) {
          lastArrival += usualBreakTime;
          totalRouteDuration = totalRouteDuration + usualBreakTime + routeObject.travelTime;
          totalRouteDistance += routeObject.travelDistance;

          service.RoutingServiceActions.addToCurrentRouteArrivals(lastArrival + d);
          breakTimeAdded = true;
        } else {
          totalRouteDuration += routeObject.travelTime;
          totalRouteDistance += routeObject.travelDistance;
          service.RoutingServiceActions.addToCurrentRouteArrivals(lastArrival + d);
        }

        service.RoutingServiceActions.updateTotalRouteDistance(
          doesUseMiles()
            ? `${(totalRouteDistance / meterToMiles).toFixed(2)} miles`
            : `${(totalRouteDistance / 1000).toFixed(2)}km`,
        );
      } else {
        break;
      }
      count += 1;
    }

    if (MODEL.RoutingService.routeObjects.length > MODEL.RoutingService.currentRouteArrivals.length) {
      service.RoutingServiceActions.updateRouteObjects(
        MODEL.RoutingService.routeObjects.splice(0, MODEL.RoutingService.currentRouteArrivals.length),
      );
      service.RoutingServiceActions.updateCurrentRouteObjects(
        MODEL.RoutingService.currentRouteObjects.splice(0, MODEL.RoutingService.currentRouteArrivals.length),
      );
      const endObject = MODEL.RoutingService.routeObjects[MODEL.RoutingService.routeObjects.length - 1];

      if (endObject) {
        service.RoutingServiceActions.updateEndObject(endObject);
      }
    } else if (!MODEL.RoutingService.endingLocation) {
      const endObject = MODEL.RoutingService.routeObjects[MODEL.RoutingService.routeObjects.length - 1];

      if (endObject) {
        service.RoutingServiceActions.updateEndObject(endObject);
      }
    }

    if (MODEL.RoutingService.routeObjects.length > 0) {
      const startingCoords = origin.split(',');
      const destCoords = MODEL.RoutingService.endingLocation.split(',');

      // add in time per stop to total duration
      if (MODEL.RoutingService.timePerRouteStop > 0) {
        totalRouteDuration += MODEL.RoutingService.routeObjects.length * MODEL.RoutingService.timePerRouteStop;
        service.RoutingServiceActions.updateTotalRouteDuration(
          `${(totalRouteDuration / 60 / 60).toFixed(2)} hours`,
        );
      }

      const points = [];
      let i;
      let
        j;
      for (i = 0; i < MODEL.RoutingService.currentRouteArrivals.length; i++) {
        const {steps} = MODEL.RoutingService.routeObjects[i];
        for (j = 0; j < steps.length; j++) {
          if (i === 0) {
            points.push(steps[j].start_location);
          }
          points.push(steps[j].end_location);
        }
      }

      // put polyline on map
      MODEL.RoutingService.encodedPolyline = new google.maps.Polyline({
        strokeColor: '#3C5AD2',
        strokeOpacity: 0.7,
        strokeWeight: 6,
        path: points,
        clickable: false,
      });
      MAN.routePolyline = MODEL.RoutingService.encodedPolyline;

      // apply line & bounds to map
      MODEL.RoutingService.encodedPolyline.setMap(MODEL.map);
      MAN.allMapContent.push(MAN.routePolyline);
      MAN.addedContentMessage('added executeRoute polyline');

      const bounds = new google.maps.LatLngBounds();

      const startIcon = {
        url: './images/starting_flag.png', // url
        scaledSize: new google.maps.Size(25, 25), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(0, 10), // anchor
      };

      // add starting location marker
      const startinglatlng = new google.maps.LatLng(startingCoords[0], startingCoords[1]);
      const startingMarker = new google.maps.Marker({
        position: startinglatlng,
        icon: startIcon,
        optimized: false,
        zIndex: 1000,
        opacity: 1,
      });

      MAN.addedContentMessage('added starting location marker for route');
      MODEL.RoutingService.addRouteMarkerToRouteMarkers(startingMarker);
      MAN.allMapContent.push(startingMarker);
      startingMarker.setMap(MODEL.map);

      // create numbered markers to drop on map
      for (let i = 0; i < MODEL.RoutingService.currentRouteArrivals.length - 1; i++) {
        const coords = MODEL.RoutingService.routeObjects[i].geoPoint;
        const latlng = new google.maps.LatLng(coords[1], coords[0]);
        const icon = `http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${i + 1}|00FF00|000000`;
        const marker = new google.maps.Marker({
          position: latlng,
          icon,
          zIndex: 120,
        });
        MAN.allMapContent.push(marker);

        // extend the bounds to include each marker's position
        bounds.extend(marker.position);
        MODEL.RoutingService.addRouteMarkerToRouteMarkers(marker);
      }

      const destIcon = {
        url: './images/finish_flag.png', // url
        scaledSize: new google.maps.Size(30, 30), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(0, 20), // anchor
      };

      // add destination marker
      if (Math.abs(startingCoords[0] - destCoords[0]) + Math.abs(startingCoords[1] - destCoords[1]) > 0.003) {
        const destlatlng = new google.maps.LatLng(destCoords[0], destCoords[1]);
        const destMarker = new google.maps.Marker({
          position: destlatlng,
          icon: destIcon,
          optimized: false,
          zIndex: 1000,
          opacity: 1,
        });

        MODEL.RoutingService.addRouteMarkerToRouteMarkers(destMarker);
        destMarker.setMap(MODEL.map);
        MAN.allMapContent.push(destMarker);
        MAN.addedContentMessage('added destination marker for route');
        bounds.extend(destMarker.position);
      }

      // now fit the map to the newly inclusive bounds
      MAN.fitBounds(bounds);
      service.hidePinsNotInRoute();
    } else {
      $rootScope.$evalAsync(() => {
        MODEL.show.loader = false;
      });

      service.RoutingServiceActions.endSmartRouting();
      swal('Oops...', 'We couldn\'t build a route to meet your criteria. Try again with new preferences.', 'error');
      service.closeRoute();
    }
  };

  service.executeManualRoute = function (response, startingLocation, endingLocation) {
    const path = google.maps.geometry.encoding.decodePath(response.polyline);
    // put polyline on map
    MODEL.RoutingService.encodedPolyline = new google.maps.Polyline({
      strokeColor: '#3C5AD2',
      strokeOpacity: 0.7,
      strokeWeight: 6,
      path,
      clickable: false,
    });
    MAN.routePolyline = MODEL.RoutingService.encodedPolyline;

    // apply line & bounds to map
    MODEL.RoutingService.encodedPolyline.setMap(MODEL.map);
    MAN.allMapContent.push(MAN.routePolyline);
    MAN.addedContentMessage('added executeRoute polyline');

    const startIcon = {
      url: './images/starting_flag.png', // url
      scaledSize: new google.maps.Size(25, 25), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(0, 10), // anchor
    };

    const startingCoords = startingLocation.split(',');
    const destCoords = endingLocation.split(',');

    // add starting location marker

    const startinglatlng = new google.maps.LatLng(startingCoords[0], startingCoords[1]);
    const startingMarker = new google.maps.Marker({
      position: startinglatlng,
      icon: startIcon,
      optimized: false,
      zIndex: 1000,
      opacity: 1,
    });

    MODEL.RoutingService.addRouteMarkerToRouteMarkers(startingMarker);
    startingMarker.setMap(MODEL.map);
    MAN.allMapContent.push(startingMarker);
    MAN.addedContentMessage('added starting location marker for route');

    const destIcon = {
      url: './images/finish_flag.png', // url
      scaledSize: new google.maps.Size(30, 30), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(0, 20), // anchor
    };

    // add destination marker
    if (Math.abs(startingCoords[0] - destCoords[0]) + Math.abs(startingCoords[1] - destCoords[1]) > 0.003) {
      const destlatlng = new google.maps.LatLng(destCoords[0], destCoords[1]);
      const destMarker = new google.maps.Marker({
        position: destlatlng,
        icon: destIcon,
        optimized: false,
        zIndex: 1000,
        opacity: 1,
      });
      MODEL.RoutingService.addRouteMarkerToRouteMarkers(destMarker);
      destMarker.setMap(MODEL.map);
      MAN.allMapContent.push(destMarker);
      MAN.addedContentMessage('added destination marker for route');
    }


    const bounds = new google.maps.LatLngBounds();
    const polyPoints = MODEL.RoutingService.encodedPolyline.getPath().getArray();

    for (let n = 0; n < polyPoints.length; n++) {
      bounds.extend(polyPoints[n]);
    }

    let {totalDuration} = response;

    if (MODEL.RoutingService.timePerRouteStop > 0) {
      totalDuration += MODEL.RoutingService.routeObjects.length * MODEL.RoutingService.timePerRouteStop;
    }

    service.RoutingServiceActions.updateTotalRouteDistance(
      doesUseMiles()
        ? `${(response.totalDistance / meterToMiles).toFixed(2)} miles`
        : `${(response.totalDistance / 1000).toFixed(2)}km`,
    );
    service.RoutingServiceActions.updateTotalRouteDuration(`${(totalDuration / 60 / 60).toFixed(2)} hours`);
    MAN.fitBounds(bounds);
    MODEL.show.loader = false;
  };

  // saves new route name
  service.saveNewRouteName = async function () {
    // $("#saveRouteName").text("Saving...");
    const input = MODEL.RoutingService.routeEditName;
    let newRouteName = input.replace(/[^\w\s-–]/gi, '');
    newRouteName = newRouteName.trim();

    if (newRouteName.length > 0) {
      const {...payload} = MODEL.RoutingService.currentRoute;
      payload.name = newRouteName;
      payload.id = parseInt(payload.id, 10);
      await routingNetworkService.updateRoute(payload.id, payload);
      analyticsService.entityUpdated('Route', {id: payload.id, name: payload.name});

      const state = MODEL.cachedState.routes;
      const filters = state.user ? {viewAs: state.user} : {};

      await service.fetchRoutes(filters);
      service.setViewHeaders();
    } else {
      swal('Oops...', 'Route name can\'t be empty', 'error');
    }
  };

  // add a pin to route
  service.addPinToRoute = async (type) => {
    const routeName = document.getElementById('routes').value;
    const route = find(MODEL.RoutingService.usersRoutes, {name: routeName});

    if (!route) {
      return;
    }

    let routeObjects = route.routeContacts;
    let payload;

    if (type === 'account') {
      routeObjects = route.routeAccounts;
      payload = {
        routeAccountGroups: [{
          accountId: MODEL.currentCRMObject.id,
          _action_: 'create',
          allottedTime: route.routeDetail.allottedTime,
        }],
      };
    } else {
      payload = {
        routeContactGroups: [{
          contactId: MODEL.currentCRMObject.id,
          _action_: 'create',
          allottedTime: route.routeDetail.allottedTime,
        }],
      };
    }

    // not pinned
    if (!MODEL.currentCRMObject.geoPoint) {
      return swal(
        'Uh-Oh',
        'Sorry this customer is not mapped. You\'ll need to add an address before including it in a route.',
        'error',
      );
    }

    if (routeObjects.some(({id}) => id === MODEL.currentCRMObject.id)) {
      return swal('Oh No!', 'This pin is already in this route.', 'error');
    }

    // route too long
    if (routeObjects.length > 69) {
      return swal('Too many pins!', 'You can only add 69 pins to a route.', 'error');
    }

    await routingNetworkService.updateRouteObjects(route.id, payload);

    if (type === 'account') {
      await service.getRoutesForAccount(MODEL.currentCRMObject.id);
    } else {
      await service.getRoutesForCustomer(MODEL.currentCRMObject.id);
    }
  };

  service.addPinsToRoute = (routeId, contacts) => {
    const route = MODEL.routes.find(({id}) => id === routeId);
    if (!route) {
      return;
    }

    const contactsWithCoordinates = contacts.filter(({geoPoint}) => !!geoPoint);
    if (contactsWithCoordinates.length !== contacts.length) {
      swal('Uh-Oh', 'Some customers are not mapped and were not included it in a route.', 'warning');
    }

    // route too long
    if (route.routeContacts.length + contactsWithCoordinates.length > 69) {
      swal('Too many pins!', 'You can only add 69 pins to a route.', 'error');
      return Promise.reject();
    }

    const payload = {
      routeContactGroups: contactsWithCoordinates.map(({id}) => ({contactId: id, _action_: 'create', allottedTime: route.routeDetail.allottedTime})),
    };
    return routingNetworkService.updateRouteObjects(routeId, payload);
  };

  /**
   * Remove contact from the route
   * @param {{id: number, routeContacts: {id: number}[]}} route route to modify
   * @param {number|string} entityId entity to remove from given route
   * @param {string} entityType entity type
   */
  service.removeFromRoute = async (route, entityId, entityType = 'contacts') => {
    entityId = parseInt(entityId, 10);
    const routeKey = `route${capitalize(entityType)}`;
    // not an array or contact is not a part of this route
    if (!route || !route.id || !Array.isArray(route[routeKey]) || !route[routeKey].some(({id}) => id === entityId)) {
      return;
    }

    const singularEntityType = entityType.substring(0, entityType.length - 1);
    const payload = {
      [`route${capitalize(singularEntityType)}Groups`]: [{
        [`${singularEntityType}Id`]: entityId,
        _action_: 'delete',
      }],
    };
    await routingNetworkService.updateRouteObjects(route.id, payload);
    await (entityType === 'accounts' ? service.getRoutesForAccount : service.getRoutesForCustomer)(entityId);
  };

  service.removeMultipleFromRoute = async (route, entityIds, type = 'contact') => {
    const typeProperCase = type === 'contact' ? 'Contact' : 'Account';

    const payload = {
      [`route${typeProperCase}Groups`]: entityIds.map((entityId) => ({[`${type}Id`]: entityId, _action_: 'delete'})),
    };

    return routingNetworkService.updateRouteObjects(route.id, payload);
  };

  // selected one of the route type
  service.finishSelectRouteType = () => {
    const type = MODEL.RoutingService.initialRouteType;

    // checked Smart Routing
    if (type === 'smartRouting') {
      MODEL.smartRouting.flow = true;
      MODEL.manualRouting.flow = false;
    } else {
      // checked Manual Routing

      MODEL.smartRouting.flow = false;
      MODEL.manualRouting.flow = true;
    }
    service.commonRoutingFlow();
  };

  // save new route (before building)
  service.saveRoute = function () {
    // get coords from lasso tool
    const lassoCoords = MODEL.MappingService.lassoLatLng;

    // add polygon (auto-filled) to map
    const lassoPolygon = new google.maps.Polygon({
      paths: lassoCoords,
      strokeColor: '#3063E4',
      strokeOpacity: 1.0,
      strokeWeight: 3,
      fillColor: '#3063E4',
      fillOpacity: 0.35,
      clickable: false,
    });

    // create list of pins that are showing on map (bc customersListsArray has all pins)
    const showingObjectIds = [];
    const objects = window.isOnPage('accounts') ? MODEL.accounts : MODEL.contacts;
    //  add to route if pin is inside the current polygon
    for (let i = 0; i < objects.length; i++) {
      const currentPin = objects[i];

      showingObjectIds.push(currentPin.id);

      if (currentPin.latitude) {
        const currentPinLatLng = new google.maps.LatLng(currentPin.latitude, currentPin.longitude);
        const isWithinPolygon = google.maps.geometry.poly.containsLocation(currentPinLatLng, lassoPolygon);

        // pin is inside current territory polygon && is showing on the map, add to new route
        if (isWithinPolygon && showingObjectIds.indexOf(currentPin.id) > -1) {
          // if pins already in routes are hidden
          if (!MODEL.RoutingService.showRoutesPins) {
            // add only the ones that are shown on the map
            if (MODEL.pinsAlreadyInRoutes.indexOf(currentPin.id) === -1) {
              service.RoutingServiceActions.addObjectToCurrentRouteObjects(currentPin.id);
              service.RoutingServiceActions.addObjectToRouteObjects(currentPin);
            }
            // if pins already in routes are shown
          } else {
            service.RoutingServiceActions.addObjectToCurrentRouteObjects(currentPin.id);
            service.RoutingServiceActions.addObjectToRouteObjects(currentPin);
          }
        }
      }
    }

    MODEL.MappingService.toggleLassoRouteOn = true;

    // too many pins in lasso
    if (MODEL.RoutingService.currentRouteObjects.length > 69) {
      swal('Too many pins!', 'You can only add 69 pins to a route.', 'error')
        .then(() => {
          // remove lasso and add back -> assuming that only in lasso there will be more than 69 pins
          LassoService.clearLasso();
          LassoService.startLasso();
          service.RoutingServiceActions.updateCurrentRouteObjects([]);
          service.RoutingServiceActions.updateRouteObjects([]);
        })
        .catch(() => {
        });
    } else {
      service.RoutingServiceActions.updateCreatingRoute(true);
      MODEL.MappingService.creatingOrEditingRoute = false;
      MODEL.MappingService.toggleLassoRouteOn = true;

      if (MODEL.smartRouting.flow) {
        $rootScope.$evalAsync(() => {
          service.RoutingServiceActions.openSmartVisitingModal(true);
        });
      } else {
        console.log('!!! before creation completion', MODEL.RoutingService.routeObjects.length);
        service.finishRouteCreation();
      }
    }

    // clear lasso and remove any lat/lng
    MODEL.MappingService.lassoLatLng = [];
    LassoService.clearLasso();
  };

  // delete a route
  service.deleteRoute = function (route) {
    // show delete box page
    swal({
      title: 'Are you sure?',
      text: 'You won\'t be able to recover this route!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, delete now',
      closeOnConfirm: false,
    })
      .then(async () => {
        MODEL.show.loader = true;
        await routingNetworkService.deleteRoute(route.id);
        analyticsService.entityDeleted('Route', route);
        const state = MODEL.cachedState.routes;
        const filters = state.user ? {viewAs: state.user} : {};
        await service.fetchRoutes(filters);
        service.setViewHeaders();
        window.refreshDom({loader: false}, 'show');
      })
      .catch((error) => {
        helperService.logError(error);
      });

  };

  // close route
  service.closeRoute = function () {
    // $("#routeDetailsForm").hide();
    // $("#editRouteForm").hide();
    // $("#addRouteForm").hide();
    // $("#listRoutes").show();
    $('#allroutes').show();
    $('#subheader').show();
    $('#routeDetail').hide();
    service.RoutingServiceActions.updateIsEditingRoute(false);
    MODEL.MappingService.creatingOrEditingRoute = false;

    service.RoutingServiceActions.updateDirectionsBeingShown(false);
    if (MODEL.RoutingService.encodedPolyline) {
      MODEL.RoutingService.encodedPolyline.setMap(null);
    }

    // remove previous route markers
    MODEL.RoutingService.routeMarkers.forEach((marker) => {
      marker.setMap(null);
      marker = null;
    });
    MODEL.RoutingService.removeRouteMarkers();

    MODEL.RoutingService.routesNewMarker.forEach((marker) => {
      marker.setMap(null);
      marker = null;
    });

    service.RoutingServiceActions.closeRoute();

    if ($route.current.id === 'routeCreatePage') window.location.href = '/#/contacts/routing/list';
  };

  // cancels the route currently being made
  service.cancelRoute = function () {
    // hide buttons to save/cancel route
    $('#saveRouteButton').hide();
    $('#cancelRouteButton').hide();
    $('#lassoRouteListItem').hide();
    $('#subheader').show();

    if (MODEL.RoutingService.isEditingRoute) {
      MODEL.MappingService.newRoute.forEach((coord) => {
        service.RoutingServiceActions.addRouteCoordToCurrentRouteCoord({
          userCoord: coord.split(','),
        });
      });
    }
    if (!MODEL.RoutingService.isEditingRoute) {
      service.RoutingServiceActions.updateCurrentRouteObjects([]);
    }
    // reset all the creating/editing variables
    MODEL.MappingService.creatingOrEditingRoute = false;
    service.RoutingServiceActions.updateIsEditingRoute(false);
    MODEL.creatingRoute = false;

    const tappedPins = [];
    MODEL.customersListArray.forEach((object) => {
      if (MODEL.RoutingService.currentRouteObjects.indexOf(object.id) > -1) {
        tappedPins.push(object);
      }
    });
    service.unhighlightMapPins(MODEL.RoutingService.currentRouteObjects, tappedPins);
    service.RoutingServiceActions.updateCurrentRouteViewing([]);
    service.RoutingServiceActions.updateCurrentRoute(MODEL.RoutingService.originalRoute);
    LassoService.cancelLassoCompletely();
  };

  // return objectIds of pins in routes
  service.populatePinsAlreadyInRoutes = async function () {
    MODEL.pinsAlreadyInRoutes = [];
    const filters = {inRoute: true};
    let response = null;
    if (window.isOnPage('accounts')) {
      response = await accountsNetworkService.fetchAllAccounts(true, filters);
    } else {
      response = await contactsNetworkService.fetchAllContacts(true, filters);
    }
    MODEL.pinsAlreadyInRoutes = response.data.map(object => object.id);
    window.instantlyTogglePinsFromMap(MODEL.pinsAlreadyInRoutes, MODEL.RoutingService.showRoutesPins);
  };

  // re arrange the current route object to match the order sent by the google server
  service.reArrangeCurrentRouteObjects = function (waypointOrder) {
    const tempRouteContacts = [];
    waypointOrder.forEach(index => {
      tempRouteContacts.push(MODEL.RoutingService.routeObjects[index]);
    });
    if (waypointOrder.length !== MODEL.RoutingService.routeObjects.length) {
      // add the last contact
      tempRouteContacts.push(MODEL.RoutingService.routeObjects[MODEL.RoutingService.routeObjects.length - 1]);
    }
    service.RoutingServiceActions.updateRouteObjects(tempRouteContacts);
    service.RoutingServiceActions.updateCurrentRouteObjects(
      MODEL.RoutingService.routeObjects.map(contact => contact.id),
    );
  };

  // hide pins from map not in current selected route
  service.hidePinsNotInRoute = function () {
    const routesMarker = [];
    MODEL.customersOverlay.forEach((marker) => {
      // this check is since there can be pins which have multiple customers but only one of them have been added to the route. Show those pins as well
      if (marker.args.marker_id.length > 1) {
        let pinPresent = false;
        // see if this marker is in deleted list
        marker.args.marker_id.forEach((id) => {
          if (MODEL.RoutingService.currentRouteObjects.indexOf(id) > -1) {
            pinPresent = true;
          }
        });
        if (pinPresent) {
          routesMarker.push(marker);
        }
      } else if (MODEL.RoutingService.currentRouteObjects.indexOf(marker.args.marker_id[0]) > -1) {
        routesMarker.push(marker);
      }

      marker.setMap(null);
      marker = null;
    });

    // finishes removing them
    if (MODEL.markerCluster !== '') {
      MODEL.markerCluster.removeMarkers(MODEL.customersOverlay);
    }


    // remove previous markers before creating new one's on the map
    MODEL.RoutingService.routesNewMarker.forEach((marker) => {
      marker.setMap(null);
      marker = null;
    });

    MODEL.RoutingService.resetRoutesNewMarker();
    routesMarker.forEach((marker) => {
      const newMarker = new window.CustomMarker(marker.latlng, MODEL.map, marker.args);
      MODEL.RoutingService.addMarkerToRoutesNewMarker(newMarker);
    });

    if (MODEL.markerCluster.addMarkers) {
      MODEL.markerCluster.addMarkers(MODEL.RoutingService.routesNewMarker);
    }
  };

  // creates a new route
  service.submitRoute = async () => {
    let routeType = 'optimized';
    // manual route
    if (!MODEL.smartRouting.flow) {
      routeType = MODEL.RoutingService.routeOp === 'optimized' ? 'optimized' : 'ordered';
      const {startingLocation} = MODEL.manualRouting;
      const endingLocation = MODEL.manualRouting.endingLocation || null;

      service.RoutingServiceActions.updateAddressDetails({
        startingAddress: startingLocation,
        endingAddress: endingLocation || '',
        routeTypeSelected: routeType,
      });

      service.RoutingServiceActions.closeManualQuestionsModal();

      const coords = await GeocodingNetworkService.geocodeAddress({address: startingLocation});
      if (coords) {
        service.RoutingServiceActions.updateStartingLocationDetails(
          UTILS.convertGeoPointToString(coords.geoPoint),
          [coords.geoPoint.coordinates[0], coords.geoPoint.coordinates[1]],
        );
      }

      if (endingLocation) {
        const coords = await GeocodingNetworkService.geocodeAddress({address: endingLocation});
        if (coords) {
          MODEL.smartRouting.endingLocation = UTILS.convertGeoPointToString(coords.geoPoint);
          service.RoutingServiceActions.updateEndingLocationDetails(
            UTILS.convertGeoPointToString(coords.geoPoint),
            [coords.geoPoint.coordinates[0], coords.geoPoint.coordinates[1]],
          );
        }
      } else {
        MODEL.smartRouting.endingLocation = '';
        service.RoutingServiceActions.updateEndingLocationDetails('', []);
      }
    }

    service.RoutingServiceActions.updateRouteTypeSelected(routeType);
    service.RoutingServiceActions.updateCreatingRoute(true);

    if (MODEL.RoutingService.currentRoute) {
      service.RoutingServiceActions.updateCurrentRouteName(MODEL.RoutingService.routeName);
    } else {
      service.RoutingServiceActions.updateCurrentRoute({
        name: MODEL.RoutingService.routeName,
        routeDetail: {
          allottedTime: MODEL.RoutingService.timePerRouteStop,
        },
      });
    }

    service.RoutingServiceActions.updateOriginalRoute(MODEL.RoutingService.currentRoute);

    $rootScope.$evalAsync(() => {
      MODEL.MappingService.newRoute = [];
      MODEL.MappingService.currentRouteObjects = [];
      MODEL.MappingService.creatingOrEditingRoute = true;
    });
    $('#subheader').hide();
    service.RoutingServiceActions.openManualWelcomeModal();
  };

  // set the map pins to their respective color property
  // had to be kept here to avoid the issue of circular dependency since Mapping Service cannot be called from Routing Service
  // this essentially being a mapping utility feature
  // a wrapper has been made in the mapping servic to maintain the flow of thin
  service.unhighlightMapPins = function (pinIds) {
    if (!pinIds) {
      pinIds = [];
    }

    MODEL.customersOverlay.forEach((pin) => {
      const pinFound = pinIds.includes(pin.args.marker_id);
      if ((pin.modified || pinFound) && pin.div) {
        const backgroundImage = UTILS.getPinImageForColor(pin.args.color);
        if (pin.div.style) {
          pin.div.style.backgroundImage = backgroundImage;
        } else {
          pin.div.style = {backgroundImage};
        }
      }
    });
  };

  service.editStartLocation = function () {
    chooseStartingLocation()
      .then(() => {
        service.RoutingServiceActions.editStartLocation('', true);
        service.showRouteDetails(MODEL.RoutingService.startingLocation, MODEL.RoutingService.endingLocation);
      }, (dismiss) => {
        if (dismiss === 'cancel') {
          customStartingLocation()
            .then((location) => {
              window.mmcUtils.geoCodeAddress(location)
                .then((geoCodedArray) => {
                  service.RoutingServiceActions.editStartLocation(
                    `${geoCodedArray.lat},${geoCodedArray.lng}`,
                    true,
                  );
                  service.showRouteDetails(MODEL.RoutingService.startingLocation, MODEL.RoutingService.endingLocation);
                })
                .catch((error) => {
                  helperService.showAndLogError(error);
                });
            })
            .catch((error) => {
              helperService.showAndLogError(error);
            });
        }
      })
      .catch((error) => {
        helperService.showAndLogError(error);
      });
  };


  service.editEndLocation = function () {
    service.RoutingServiceActions.chooseEndingLocation();
    chooseEndingLocation();
  };

  service.editRouteType = function () {
    chooseRouteStyle()
      .then(() => {
        service.RoutingServiceActions.chooseRouteStyle(true);
        service.showRouteDetails(MODEL.RoutingService.startingLocation, MODEL.RoutingService.endingLocation);
      }, (dismiss) => {
        if (dismiss === 'cancel') {
          service.RoutingServiceActions.chooseRouteStyle(false);
          service.showRouteDetails(MODEL.RoutingService.startingLocation, MODEL.RoutingService.endingLocation);
        }
      })
      .catch((error) => {
        helperService.showAndLogError(error);
      });
  };


  // geocode starting location based on condition
  service.geoCodeStartingLocation = function (startinglocation) {
    return new Promise((resolve, reject) => {
      if (startinglocation) {
        window.mmcUtils.geoCodeAddress(startinglocation)
          .then((geocodedArray) => {
            const geoCodedStartingLocation = `${geocodedArray.lat},${geocodedArray.lng}`;
            resolve(geoCodedStartingLocation);
          })
          .catch((error) => {
            helperService.showAndLogError(error);
            reject(error);
          });
      } else {
        const geoCodedStartingLocation = `${parseFloat(MAN.userPosition.lat).toFixed(5)},${parseFloat(MAN.userPosition.lng).toFixed(
          5)}`;
        resolve(geoCodedStartingLocation);
      }
    });
  };

  //
  // ------------------ SMART & MANUAL ROUTING Methods ------------------ //
  //

  service.showCadenceModal = () => {
    service.RoutingServiceActions.showCadenceModal();
  };

  service.showAreaModal = () => {
    $rootScope.$evalAsync(() => {
      service.RoutingServiceActions.showAreaModal();
    });
  };

  service.showSmartRouteGroupModal = async function () {
    const response = await routingNetworkService.getContactGroups();
    service.RoutingServiceActions.updateAllGroups(response.data);
    $rootScope.$evalAsync(() => {
      service.RoutingServiceActions.closeSmartAreaModal();
      const groupingChoice = MODEL.smartRouting.chooseFromMap;

      if (groupingChoice === 'choseGroup') {
        service.RoutingServiceActions.openSmartGroupsModal();
      } else {
        MODEL.MappingService.creatingOrEditingRoute = true;
        service.submitRoute();
      }
    });
  };

  service.showDepartureAndReturnModal = async function () {
    const selectedGroup = MODEL.RoutingService.smartRoutingGroupSelected;
    const response = await routingNetworkService.contactGroupDetails(selectedGroup);

    if (response.total > 23) {
      swal('Maximum 23 pins allowed', 'This group has more than 23 pins. Please select another group.', 'error');
      return;
    }

    service.RoutingServiceActions.updateRouteObjects(response.data);
    service.RoutingServiceActions.updateCurrentRouteObjects(response.data.map(o => o.id));

    $rootScope.$evalAsync(() => {
      service.RoutingServiceActions.closeSmartGroupsModal();
      service.RoutingServiceActions.openSmartVisitingModal();
    });
  };

  service.showSmartRoutingLocationModal = () => {
    $rootScope.$evalAsync(() => {
      service.RoutingServiceActions.closeSmartVisitingModal();
      service.RoutingServiceActions.openSmartLocationPopup();
    });
  };

  // area modal flow begins here
  service.areaModalFlow = function () {
    areaModal()
      .then(() => {
        swal('You\'re ahead of the game...', 'This feature is coming soon.', 'success')
          .then(service.areaModalFlow)
          .catch(() => {
          });
      }, (dismiss) => {
        // select smart routing from current groups
        if (dismiss === 'cancel') {
          selectGroupModal()
            .then(() => {
              service.departureAndReturnModalFlow();
            })
            .catch((error) => {
              helperService.logError(error);
            });
        }
      })
      .catch(() => {
      });
  };

  // smart routing --> departure/return time
  service.departureAndReturnModalFlow = function () {
    departureAndReturnTimeModal()
      .then(() => {
        chooseStartingLocation()
          .then(() => {
            service.buildSmartRoute(true, window.location);
          }, (dismiss) => {
            if (dismiss === 'cancel') {
              customStartingLocation()
                .then((location) => {
                  service.buildSmartRoute(false, location);
                })
                .catch((error) => {
                  helperService.showAndLogError(error);
                });
            }
          })
          .catch((error) => {
            helperService.showAndLogError(error);
          });
      })
      .catch((error) => {
        helperService.showAndLogError(error);
      });
  };

  // manual routing flow begins here
  service.commonRoutingFlow = () => {
    $rootScope.$evalAsync(() => {
      service.RoutingServiceActions.closeRouteTypeModal();
    });

    const type = MODEL.RoutingService.initialRouteType;

    if (type !== 'smartRouting') {
      $rootScope.$evalAsync(() => {
        service.RoutingServiceActions.openRouteNameModal();
      });
    } else {
      $rootScope.$evalAsync(() => {
        service.RoutingServiceActions.openSmartWelcomeModal();
      });
    }
  };

  service.openSmartNameModal = () => {
    $rootScope.$evalAsync(() => {
      service.RoutingServiceActions.closeSmartWelcomeModal();
      service.RoutingServiceActions.openRouteNameModal();
    });
  };

  // add the route name for manual routing
  service.routingAddName = async function () {
    $rootScope.$evalAsync(() => {
      service.RoutingServiceActions.closeRouteNameModal();
    });

    const {routeName} = MODEL.RoutingService;

    if (routeName.length > 0) {
      const filters = {name: routeName};
      // check if name already exists
      const response = await service.fetchRoutes(filters);
      if (response.data && response.data.length) {
        return swal('Oops...', 'Route with same name already exists.', 'error');
      }
      // save the name of the route
      const type = MODEL.RoutingService.initialRouteType;

      // checked Smart Routing
      if (type === 'smartRouting') {
        service.showCadenceModal();
      } else {
        // checked Manual Routing
        service.routeStyleChoose();
      }
    } else {
      swal('Oops...', 'Please add a name to your route.', 'error');
    }
  };

  // route choose flow
  service.routeStyleChoose = function () {
    if (MODEL.smartRouting.flow) {
      service.RoutingServiceActions.openSmartVisitingModal();
    } else {
      service.RoutingServiceActions.openManualQuestionsModal();
    }
  };

  // route creation completed
  service.finishRouteCreation = () => {
    if (window.isOnPage('accounts')) {
      $location.path('/accounts/routing/create');
    } else {
      $location.path('/contacts/routing/create');
    }
  };

  // set the current location
  service.getCurrentLocation = async (locationType) => {
    try {
      const location = await getUserLocationAddress();
      if (MODEL.smartRouting.flow) {
        service.RoutingServiceActions.updateStartingAddress(location);
        window.refreshDom({});
      } else if (locationType === 'startingLocation') {
        MODEL.manualRouting.startingLocation = location;
        window.refreshDom({});
      } else if (locationType === 'endingLocation') {
        MODEL.manualRouting.endingLocation = location;
        window.refreshDom({});
      }
      $(`#${locationType}EditInput`).val(location);

      if (UTILS.isOnPage('routingPage')) {
        service.rebuildRoute();
      }
    } catch (error) {
      swal('Sorry about that...', 'Something seems to have gone wrong. Please try again.', 'error');
    }
  };

  // rebuild route -> location edited
  service.rebuildRoute = async function () {

    let startingLoc;
    let endingLoc;
    let endProm;
    if (!MODEL.RoutingService.startingAddress) {
      swal('Oops...', 'Start location is mandatory', 'error');
      return;
    }

    MODEL.show.loader = true;

    if (!MODEL.RoutingService.endingAddress) {
      service.RoutingServiceActions.updateEndingLocation('');
      endingLoc = '';
    }

    if (MODEL.RoutingService.routeTypeSelected === 'optimized') {
      service.RoutingServiceActions.chooseRouteStyle(true);
    } else {
      service.RoutingServiceActions.chooseRouteStyle(false);
    }

    const startProm = GeocodingNetworkService.geocodeAddress({address: MODEL.RoutingService.startingAddress});

    if (MODEL.RoutingService.endingAddress) {
      endProm = GeocodingNetworkService.geocodeAddress({address: MODEL.RoutingService.endingAddress});
    }

    const locations = await Promise.all([startProm, endProm]);

    if (locations[0]) {
      startingLoc = UTILS.convertGeoPointToString(locations[0].geoPoint);
      service.RoutingServiceActions.updateStartingLocationCoords(
        [locations[0].geoPoint.coordinates[0], locations[0].geoPoint.coordinates[1]],
      );
    }

    if (locations[1]) {
      endingLoc = UTILS.convertGeoPointToString(locations[1].geoPoint);
      service.RoutingServiceActions.updateEndingLocationLatLng(
        endingLoc,
        [locations[1].geoPoint.coordinates[0], locations[1].geoPoint.coordinates[1]],
      );
    }

    if (endingLoc) {
      service.RoutingServiceActions.updateEndingLocation(endingLoc);
    }

    await service.showRouteDetails(startingLoc, endingLoc);
    service.hidePinsNotInRoute();

    service.RoutingServiceActions.updateEditFlag({rebuildRoute: false});
  };

  // timer and location chooser function
  service.routeTimerChoose = function () {
    routeTimePerStop()
      .then(() => {
        chooseStartingLocation()
          .then(() => {
            service.RoutingServiceActions.updateStartingLocation('');
            chooseEndingLocation();
          }, (dismiss) => {
            if (dismiss === 'cancel') {
              customStartingLocation()
                .then((location) => {
                  service.RoutingServiceActions.updateStartingLocation(location);
                  chooseEndingLocation();
                })
                .catch((error) => {
                  helperService.showAndLogError(error);
                });
            }
          })
          .catch((error) => {
            helperService.showAndLogError(error);
          });
      })
      .catch((error) => {
        helperService.showAndLogError(error);
      });
  };

  // enter the custom ending location for the route
  const customEndingLocation = () => swal({
    title: 'Select Ending Location',
    text: 'Select the location you\'d like to end this route from below.',
    input: 'text',
    showCancelButton: false,
    confirmButtonText: 'Next',
    showCloseButton: true,
    allowOutsideClick: false,
  });

  // ending location swal
  service.endingLocationChoose = function () {
    customEndingLocation()
      .then((location) => {
        service.RoutingServiceActions.updateEndingLocation(location);
        window.mmcUtils.geoCodeAddress(location)
          .then((geoCodedArray) => {
            service.RoutingServiceActions.updateEndingLocationLatLng(
              `${geoCodedArray.lat},${geoCodedArray.lng}`,
              [geoCodedArray.lng, geoCodedArray.lat],
            );
            service.showRouteDetails(MODEL.RoutingService.startingLocation, MODEL.RoutingService.endingLocation);
          })
          .catch((error) => {
            helperService.showAndLogError(error);
          });
      })
      .catch((error) => {
        helperService.showAndLogError(error);
      });
  };

  service.finishSmartRouteCreation = () => {
    $rootScope.$evalAsync(() => {
      service.RoutingServiceActions.closeSmartLocationPopup();
    });
    const location = MODEL.RoutingService.startingAddress;
    service.buildSmartRoute(!location, location);
  };

  // build smart route
  service.buildSmartRoute = async function (useCurrentLocation, location) {
    service.RoutingServiceActions.buildSmartRoute();

    const cadence = parseInt(MODEL.RoutingService.smartRoutingCadence.split(' ')[0], 10);

    let pinsInThisGroup = [];
    const objects = window.isOnPage('accounts') ? MODEL.accounts : MODEL.contacts;
    pinsInThisGroup = objects.filter(object => MODEL.RoutingService.currentRouteObjects.includes(object.id));

    if (pinsInThisGroup.length > 0) {
      let pinsAfterCadenceFilter = pinsInThisGroup.filter((pin) => {
        if (pin.activity && pin.activity.length) {
          const lastVisit = moment(pin.activity[0].createdAt);
          const given = moment(lastVisit, 'YYYY-MM-DD');
          const current = moment().startOf('day');
          // Difference in number of weeks
          const differenceInWeeks = moment.duration(current.diff(given)).asWeeks();
          return differenceInWeeks > cadence;
        }
        return true;
      });

      if (pinsAfterCadenceFilter.length > 23) {
        pinsAfterCadenceFilter = pinsAfterCadenceFilter.slice(0, 23);
      }

      if (pinsAfterCadenceFilter.length > 0) {
        // calculate waypoints (each point but last --> last point is destination)
        const waypts = [];

        // build waypoints
        for (let i = 0; i < pinsAfterCadenceFilter.length; i++) {
          if (i !== pinsAfterCadenceFilter.length - 1) {
            waypts.push(UTILS.convertGeoPointToString(pinsAfterCadenceFilter[i].geoPoint));
          }
        }

        service.RoutingServiceActions.updateCurrentRouteObjects(
          pinsAfterCadenceFilter.map(pin => pin.id),
        );


        // determine origin for route
        let origin;
        let
          startingLocation;

        // current location
        if (useCurrentLocation) {
          console.warn('USING MAN.userPosition AS ORIGIN', MAN.userPosition, new Error().stack);
          origin = MAN.userPosition; // MODEL.currentlat + "," + MODEL.currentlng;
          startingLocation = `${origin.lat},${origin.lng}`;

          service.RoutingServiceActions.updateStartingLocation(startingLocation);
          // choose starting location
        } else {
          startingLocation = encodeURI(location);
          const coords = await GeocodingNetworkService.geocodeAddress({address: location});
          if (coords) {
            service.RoutingServiceActions.updateStartingLocationDetails(
              UTILS.convertGeoPointToString(coords.geoPoint),
              [coords.geoPoint.coordinates[0], coords.geoPoint.coordinates[1]],
            );
          }
        }

        if (MODEL.RoutingService.endingAddress) {
          const loc = MODEL.RoutingService.endingAddress;
          const coords = await GeocodingNetworkService.geocodeAddress({address: loc});

          if (coords) {
            service.RoutingServiceActions.updateEndingLocationDetails(
              MODEL.smartRouting.endingLocation = UTILS.convertGeoPointToString(coords.geoPoint),
              [coords.geoPoint.coordinates[0], coords.geoPoint.coordinates[1]],
            );
          }
        }

        service.RoutingServiceActions.updateRouteTypeSelected('optimized');

        // hide route style options modal
        $('#modalbg').hide();
        $('#modal').hide();
        $('#routeStyleBox').hide();
        const {routeName} = MODEL.RoutingService;
        // show directions
        $('#listRoutes').hide();
        $('#editPinForm').hide();
        $('#routeDetailsForm').show();
        MODEL.show.loader = true;
        $('#routeTitle').text(routeName);

        service.RoutingServiceActions.updateDirectionsBeingShown(true);
        service.RoutingServiceActions.updateCreatingRoute(true);

        if (MODEL.RoutingService.currentRoute) {
          service.RoutingServiceActions.updateCurrentRouteName(routeName);
        } else {
          service.RoutingServiceActions.updateCurrentRoute({
            name: routeName,
            routeDetail: {
              allottedTime: MODEL.RoutingService.timePerRouteStop,
            },
          });
        }

        service.RoutingServiceActions.updateOriginalRoute(MODEL.RoutingService.currentRoute);

        window.location.href = window.isOnPage('accounts') ? '/#/accounts/routing/create' : '/#/contacts/routing/create';
      } else {
        service.closeRoute();
        swal(
          'Nice work...',
          'You\'ve already checked in with all the customers given your selected time range.',
          'error',
        );
      }
      // no pins exist in this group
    } else {
      service.closeRoute();
      swal('Yikes!', 'This group has no pins. Edit the group to add pins.', 'error');
    }
  };


  // add or remove pin from the existing route
  service.togglePinFromRoute = async (entityId) => {
    const entityType = ['contacts', 'accounts'].find(type => $route.current.originalPath.includes(type));
    if (!entityType) {
      return;
    }

    const pin = entityType === 'contacts'
      ? await contactsNetworkService.fetchSpecificContact(entityId)
      : await accountsNetworkService.fetchAccount(entityId);
    if (pin.geoPoint) {
      pin.longitude = pin.geoPoint.coordinates[0];
      pin.latitude = pin.geoPoint.coordinates[1];
    }

    service.RoutingServiceActions.updateEditFlag({
      accounts: entityType === EntityType.COMPANY,
      contacts: entityType === EntityType.PERSON,
      rebuildRoute: true,
    });
    const index = MODEL.RoutingService.currentRouteObjects.indexOf(pin.id);
    const coord = `${pin.latitude},${pin.longitude}`;

    const marker = MODEL.customersOverlay.find(({args}) => args.marker_id[0] === pin.id);
    if (index >= 0) {
      service.RoutingServiceActions.removeObjectFromCurrentRouteObjects(index);
      service.RoutingServiceActions.removeObjectFromRouteObjects(index);
      MODEL.MappingService.newRoute = remove(MODEL.MappingService.newRoute, (routeCoord) => coord !== routeCoord);
      // need to check marker's div too because marker might be a part of a cluster instead of being standalone pin
      if (marker && marker.div) {
        marker.div.style.backgroundImage = marker.args.previousPinColor;
      }
    } else {
      service.RoutingServiceActions.addObjectToCurrentRouteObjects(pin.id);
      service.RoutingServiceActions.addObjectToRouteObjects(pin);
      MODEL.MappingService.newRoute.push(coord);
      if (marker && marker.div) {
        marker.args.previousPinColor = marker.div.style.backgroundImage;
        marker.div.style.backgroundImage = `url('${MODEL.MappingService.pinDir}_home.png')`;
      }
    }
    MODEL.routeObjectsSaved = [...MODEL.RoutingService.routeObjects];
    window.refreshDom({}); // force angular to refresh
  };

  service.getStartAt = () => {
    const startDate = moment(MODEL.RoutingService.startDate);
    const startTime = moment(MODEL.RoutingService.startTime);
    const date = moment();
    date.set('year', startDate.get('year'));
    date.set('month', startDate.get('month'));
    date.set('date', startDate.get('date'));
    date.set('hour', startTime.get('hour'));
    date.set('minute', startTime.get('minute'));

    return date;
  };

  service.enableDragDrop = (enable = false, refreshUi = undefined) => {
    const fixHelperModified = function (e, tr) {
      const $originals = tr.children();
      const $helper = tr.clone();
      $helper.children().each(function (index) {
        $(this).width($originals.eq(index).width());
      });
      return $helper;
    };

    let startIndex;
    if (enable) {
      $('#routing-table tbody').sortable({
        helper: fixHelperModified,
        start(event, ui) {
          startIndex = ui.item.index();
        },
        stop(event, ui) {
          $('#routing-table tbody').sortable({disabled: true});
          setTimeout(() => {
            service.reorderTable(startIndex, ui.item.index());
            $('#routing-table tbody').sortable({disabled: false});
          }, 1000);
          $('#routing-table tbody tr').each((index, tr) => {
            tr.children[1].innerHTML = `${index + 1})`;
          });
          if (refreshUi) {
            refreshUi();
          }
        },
        disabled: false,
      }).disableSelection();
    } else if (MODEL.RoutingService.isEditingRoute) {
      try {
        $('#routing-table tbody').sortable('disable');
      } catch (e) {
        console.error('Error', e);
      }
    }
  };

  service.reorderTable = function (startIndex, endIndex) {
    const element = {...MODEL.routeObjectsSaved[startIndex]};

    MODEL.routeObjectsSaved = [
      ...MODEL.routeObjectsSaved.slice(0, startIndex),
      ...MODEL.routeObjectsSaved.slice(startIndex + 1),
    ];
    MODEL.routeObjectsSaved = [
      ...MODEL.routeObjectsSaved.slice(0, endIndex),
      element,
      ...MODEL.routeObjectsSaved.slice(endIndex),
    ];
    service.RoutingServiceActions.updateEditFlag({
      rebuildRoute: true,
      details: true,
    });

    service.RoutingServiceActions.updateRouteTypeSelected('ordered');
    if (MODEL.onUpdateRouteTypeSelected) {
      MODEL.onUpdateRouteTypeSelected();
    }
    if (window.isOnPage('accounts')) {
      service.RoutingServiceActions.updateEditFlag({accounts: true});
    } else {
      service.RoutingServiceActions.updateEditFlag({contacts: true});
    }
  };


  //
  // ------------------ SMART ROUTING Modals ------------------ //
  //

  const generateGroupsDropdown = function generateGroupsDropdown(groups) {
    const dropdown = document.createElement('select');
    $(dropdown).attr('id', 'groupsSelector');
    $(dropdown).attr('class', 'form-control');
    $(dropdown).attr('style', 'width: 100%; margin-right: 0px; margin-top:30px');

    groups.forEach((group) => {
      dropdown.append($(`<option value="${group}">${group}</option>`)[0]);
    });
    return dropdown;
  };

  let selectGroupModal = () => swal({
    title: 'Please select a group...',
    showCloseButton: true,
    showCancelButton: true,
    customClass: 'animated fadeInUpBig',
    animation: false,
    confirmButtonText: 'Next',
    onOpen() {
      $('#groupsDropdown').prepend(generateGroupsDropdown(safeLocalStorage.currentUser.groups));
    },
    html: '<div class="form-group" id="groupsDropdown" style="margin:15px 25px; margin-top:0px;"> </div>',
    preConfirm() {
      return new Promise(((resolve) => {
        service.RoutingServiceActions.updateSmartRoutingGroupSelected(
          document.getElementById('groupsSelector').value,
        );
        resolve();
      }));
    },
  });

  // choose starting location for the route
  let areaModal = () => swal({
    title: 'Select the area',
    text: 'Click and drag to draw a shape around the area you\'d like to include in your smart route',
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#9ed444',
    confirmButtonText: '<i class="icon ion-navigate"></i> Select From Map',
    cancelButtonText: '<i class="icon ion-map"></i> Choose From Existing Group',
    // confirmButtonClass: 'btn btn-primary',
    // cancelButtonClass: 'btn btn-primary',
    buttonsStyling: true,
  });

  let departureAndReturnTimeModal = () => swal({
    title: 'Departure and Return Time',
    showCloseButton: true,
    showCancelButton: true,
    customClass: 'animated fadeInUpBig',
    animation: false,
    confirmButtonText: 'Next',
    html: `
        <p style="font-size:18px;">What time do you leave to visit customers?</p>
        <div class="form-group" style="margin:15px 25px; margin-top:0px;">
        <select class="form-control" id="departureTime">
            <option value="300" >5:00 AM</option>
            <option value="330" >5:30 AM</option>
            <option value="360" >6:00 AM</option>
            <option value="390" >6:30 AM</option>
            <option value="420" >7:00 AM</option>
            <option value="450" >7:30 AM</option>
            <option value="480" >8:00 AM</option>
            <option value="510" >8:30 AM</option>
            <option value="540" >9:00 AM</option>
            <option value="570" >9:30 AM</option>
            <option value="600" >10:00 AM</option>
            <option value="630" >10:30 AM</option>
            <option value="660" >11:00 AM</option>
            <option value="690" >11:30 AM</option>
            <option value="720" >12:00 PM</option>
            <option value="750" >12:30 PM</option>
            <option value="780" >1:00 PM</option>
            <option value="810" >1:30 PM</option>
            <option value="840" >2:00 PM</option>
            <option value="870" >2:30 PM</option>
            <option value="900" >3:00 PM</option>
            <option value="930" >3:30 PM</option>
            <option value="960" >4:00 PM</option>
            <option value="990" >4:30 PM</option>
            <option value="1020" >5:00 PM</option>
        </select>
        </div>
        <p style="font-size:18px;">What is the time that you return after visiting customers?</p>
        <div class="form-group" style="margin:15px 25px; margin-top:0px;">
        <select class="form-control" id="returnTime">
            <option value="720" >12:00 PM</option>
            <option value="750" >12:30 PM</option>
            <option value="780" >1:00 PM</option>
            <option value="810" >1:30 PM</option>
            <option value="840" >2:00 PM</option>
            <option value="870" >2:30 PM</option>
            <option value="900" >3:00 PM</option>
            <option value="930" >3:30 PM</option>
            <option value="960" >4:00 PM</option>
            <option value="990" >4:30 PM</option>
            <option value="1020" >5:00 PM</option>
            <option value="1050" >5:30 PM</option>
            <option value="1080" >6:00 PM</option>
            <option value="1110" >6:30 PM</option>
            <option value="1140" >7:00 PM</option>
            <option value="1170" >7:30 PM</option>
            <option value="1200" >8:00 PM</option>
            <option value="1230" >8:30 PM</option>
            <option value="1260" >9:00 PM</option>
            <option value="1290" >9:30 PM</option>
            <option value="1320" >10:00 PM</option>
            <option value="1350" >10:30 PM</option>
            <option value="1380" >11:00 PM</option>
            <option value="1410" >11:30 PM</option>
        </select>
        </div>
`,
    preConfirm() {
      return new Promise(((resolve) => {
        service.RoutingServiceActions.updateSmartRoutingTimes(
          document.getElementById('departureTime').value,
          document.getElementById('returnTime').value,
        );
        // handle case where departure time is entered wrongly by the user
        if (parseInt(
          MODEL.RoutingService.smartRoutingDepartureTime,
          10,
        ) > parseInt(MODEL.RoutingService.smartRoutingReturnTime, 10)) {
          swal('Oops...', 'The departure time should be earlier than the return time.', 'error')
            .then(() => {
              service.departureAndReturnModalFlow();
            })
            .catch(() => {
            });
        } else {
          resolve();
        }
      }));
    },
  });

  //
  // ------------------ ENDING LOCATION CALLBACK FUNCTIONS ------------------ //
  //


  // current location
  $(document).on('click', '.CurrentLocation', () => {
    if (MODEL.RoutingService.endingLocationFromEdit) {
      service.RoutingServiceActions.removeEndingLocationFromEdit();
    }

    service.RoutingServiceActions.endLocationPresent(true);
    service.RoutingServiceActions.updateEndingLocation('');
    swal.clickConfirm();
    service.showRouteDetails(MODEL.RoutingService.startingLocation, MODEL.RoutingService.endingLocation);
  });

  // custom ending location
  $(document).on('click', '.EndingLocation', () => {
    if (MODEL.RoutingService.endingLocationFromEdit) {
      service.RoutingServiceActions.removeEndingLocationFromEdit();
    }

    service.RoutingServiceActions.endLocationPresent(true);
    service.endingLocationChoose();
  });

  // let optimizer decide
  $(document).on('click', '.OptimizerDecide', () => {
    if (MODEL.RoutingService.endingLocationFromEdit) {
      service.RoutingServiceActions.removeEndingLocationFromEdit();
    }

    service.RoutingServiceActions.endLocationPresent(false);
    service.RoutingServiceActions.updateEndingLocation('');
    swal.clickConfirm();
    service.showRouteDetails(MODEL.RoutingService.startingLocation, MODEL.RoutingService.endingLocation);
  });

  return service;
}

RoutingService.$inject = [
  '$rootScope', '$route', 'LassoService', 'FetchCRMDataService', 'MappingService', '$location',
];
