import template from './record-owner.html';
import helperService from '../../shared-services/helper-service';
import accountsNetworkService from '../../network-services/accounts-network-service';
import contactsNetworkService from '../../network-services/contacts-network-service';
import dealsNetworkService from '../../network-services/deals-network-service';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';
import './record-owner.scss';

class RecordOwnerController {
  constructor($window, $route) {
    Object.assign(this, {
      $window,
      $route,
      localStorage: safeLocalStorage,
      MODEL: $window.DataModel,
    });

    this.methodMap = {
      account: accountsNetworkService.updateAccount,
      contact: contactsNetworkService.updateContact,
      deal: dealsNetworkService.updateDeal,
    };
  }

  $onInit() {
    // Hidden until backend supports transferring ownership
    // this.canEdit = this.owner.id === this.record.user.id || [ "Team Owner", "Team Manager" ].includes(get(safeLocalStorage.currentUser, "role.name"));
    this.canEdit = false;
  }

  transferOwnership(user) {
    swal({
      title: 'Confirm',
      text: `Are you sure you want transfer to ${user.fullName || user.username}?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#F55252',
      confirmButtonText: 'Transfer Ownership',
      cancelButtonText: 'Cancel',
      buttonsStyling: true,
    })
      .then(() => {
        this.record.user = {
          id: user.id,
          username: user.username,
        };

        this.methodMap[this.$route.current.entityType](this.record.id, this.record)
          .then(() => { /* waiting for backend to support transferring ownership */ })
          .catch(() => { /* waiting for backend to support transferring ownership */ });
      })
      .catch((error) => {
        helperService.logError(error);
      });
  }
}

RecordOwnerController.$inject = [
  '$window', '$route',
];

const recordOwner = {
  bindings: {
    owner: '<',
    record: '<',
  },
  controller: 'RecordOwnerController',
  template,
};

export {RecordOwnerController, recordOwner};
