import React from 'react';
import styles from './SearchResultCommon.module.scss';

interface Props {}

const NoResultsOption: React.FC<Props> = () => (
  <div className={styles.container}>
    <span>
      No results - Please try a different search.
    </span>
  </div>
);

export default NoResultsOption;
