import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface VisibilityState {
  prevent: boolean,
  visible: boolean
}

export interface ModalState {
  addNewMemberModal: boolean,
  addEditPopup: boolean,
  addToRoute: boolean,
  recordLimitWarningModal: boolean,
  quickCreateDeal: boolean,
  quickCreateContact: boolean,
  quickCreateAccount: boolean,
  addExistingMembersModal: boolean,
  manageMembersModal: boolean,
  selectFieldsModal: boolean,
  upgradeModal: boolean,
  talkToSales: boolean,
  featuresModal: boolean,
  addCfModal: boolean,
  manageGroupsModal: boolean,
  conversionModal: boolean,
  noAccountsModal: VisibilityState,
  noContactsModal: VisibilityState,
  noDealsModal: VisibilityState,
  noRoutesModal: VisibilityState,
  noGroupsModal: VisibilityState,
  noTerritoriesModal: VisibilityState,
  noReportsModal: VisibilityState,
  noActivitiesModal: VisibilityState,
}

type PickWithType<Base, Condition> = Pick<Base, {
  [Key in keyof Base]: Condition extends Extract<Base[Key], Condition> ? Key : never;
}[keyof Base]>;

export type ModalName = keyof ModalState;
export type NoDataModalName = keyof PickWithType<ModalState, VisibilityState>;

const noDataModalNames: string[] = ['noAccountsModal', 'noContactsModal', 'noDealsModal', 'noRoutesModal', 'noGroupsModal',
  'noTerritoriesModal', 'noReportsModal', 'noActivitiesModal'];
const isNoDataModalName = (modalName: ModalName): modalName is NoDataModalName => noDataModalNames.includes(modalName);

const initialState = Object.freeze({
  addNewMemberModal: false,
  addEditPopup: false,
  addToRoute: false,
  recordLimitWarningModal: false,
  quickCreateDeal: false,
  quickCreateContact: false,
  quickCreateAccount: false,
  addExistingMembersModal: false,
  manageMembersModal: false,
  selectFieldsModal: false,
  upgradeModal: false,
  talkToSales: false,
  featuresModal: false,
  addCfModal: false,
  manageGroupsModal: false,
  conversionModal: false,
  noAccountsModal: {prevent: false, visible: false},
  noContactsModal: {prevent: false, visible: false},
  noDealsModal: {prevent: false, visible: false},
  noRoutesModal: {prevent: false, visible: false},
  noGroupsModal: {prevent: false, visible: false},
  noTerritoriesModal: {prevent: false, visible: false},
  noReportsModal: {prevent: false, visible: false},
  noActivitiesModal: {prevent: false, visible: false},
}) as ModalState;

const slice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    resetVisibility(state: ModalState, {payload: modalName}: PayloadAction<ModalName>) {
      if (isNoDataModalName(modalName)) {
        state[modalName] = {...state[modalName], visible: false};
      } else {
        state[modalName] = false;
      }
    },
    hideModal(state: ModalState, {payload: modalName}: PayloadAction<ModalName>) {
      if (isNoDataModalName(modalName)) {
        state[modalName] = {prevent: true, visible: false};
      } else {
        state[modalName] = false;
      }
      document.body.style.overflowY = 'auto'; // should not be here
    },
    showModal(state: ModalState, {payload: modalName}: PayloadAction<ModalName>) {
      if (isNoDataModalName(modalName)) {
        state[modalName] = {...state[modalName], visible: true};
      } else {
        state[modalName] = true;
      }
    },
  },
});

export default slice;
