import React, {useCallback} from 'react';
import {Modal, Button} from 'antd';
import styled from 'styled-components';

const WizardInformation = styled.p`
  text-align: justify;
`;

interface Props {
  visible: boolean,
  onHide: () => void
}

const WizardModal: React.FC<Props> = ({visible, onHide}) => {
  const handleOkClick = useCallback(() => { window.location.hash = '#/integrations/add'; }, []);

  const handleSalesClick = useCallback(() => {
    onHide();
    window.open('https://share.hsforms.com/1sYuCWmlBT-yx3x4Cwf3flw3is1h');
  }, []);

  return (
    <>
      <Modal
        visible={visible}
        title="Data Integration Services"
        onOk={handleOkClick}
        onCancel={onHide}
        footer={[
          <Button onClick={handleSalesClick}>
            Talk to Sales
          </Button>,
          <Button key="submit" type="primary" onClick={handleOkClick}>
            Got it!
          </Button>,
          ]}
      >
        <WizardInformation>
          This Wizard will walk you through the integration process. If you have paid for an integration with the sales team,{' '}
          once completed, the integration will begin it’s initial sync in 1 business day. If you haven’t purchased an integration{' '}
          you may preview the process and then talk to sales to get it started!
        </WizardInformation>
      </Modal>
    </>
  );
};

export default WizardModal;
