import swal from 'sweetalert2';
import trim from 'lodash/trim';
import dealsNetworkService from '../../network-services/deals-network-service';
import template from './quick-create-deal.html';
import './quick-create-deal.scss';

class QuickCreateDealController {
  constructor($window) {
    Object.assign(this, {
      $window,
      MODEL: $window.DataModel,
    });

    this.stages = [];
  }

  async funnelChanged(funnelId) {
    this.MODEL.show.loader = true;
    const response = await dealsNetworkService.fetchStagesForFunnel(funnelId);

    if (response.data) {
      this.stages = response.data;
    } else {
      swal(
        'Uh-oh',
        'There was a network error. Please try again...',
        'error',
      );
    }
    this.$window.refreshDom({loader: false}, 'show');
  }

  isValidQuickAddDialog() {
    const hasName = !!trim(this.deals.quickNameAdd);
    const hasAddress = !!trim(this.deals.quickAddressAdd);
    const hasCountry = !!trim(this.deals.quickCountryAdd);
    const hasEnoughInformationToGeocode =
            !hasAddress || (hasAddress && hasCountry);

    if (!this.MODEL.quickCreateCustomFields.length) {
      return hasName && hasEnoughInformationToGeocode;
    }

    const customFieldValues = Object.values(
      this.MODEL.quickCreateCustomFieldValues,
    );
    const areAllCustomFieldValuesSet =
            customFieldValues.length ===
            customFieldValues.filter(
              ({value}) => Array.isArray(value) && value.length && value[0] !== undefined,
            ).length;

    return (
      hasName && hasEnoughInformationToGeocode && areAllCustomFieldValuesSet
    );
  }
}

QuickCreateDealController.$inject = ['$window'];

const quickCreateDeal = {
  bindings: {
    deals: '=',
    funnels: '<',
    cancelQuickCreate: '&',
    saveQuickCreate: '&',
  },
  controller: 'QuickCreateDealController',
  template,
};

export {QuickCreateDealController, quickCreateDeal};
