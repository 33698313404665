import fileNetworkService from '../network-services/file-network-service';
import {downloadFile, getFileAsUrl, getFilePreviewAsUrl} from '../shared-services/file-service';

FilesService.$inject = ['MainService'];

export default function FilesService(MainService) {
  /**
   * Returns a list of files for active CRM object
   * @return {Promise<{id: number}[]>}
   */
  const getFiles = async () => {
    const response = await fileNetworkService.getFiles(
      MainService.getCurrentCrmObjectType(),
      MainService.getCurrentCrmObjectId(),
    );
    return response.data;
  };

  /**
   * Uploads all given files attaching them to the active CRM object
   * @param {File[]} files
   * @return {Promise<{}[]>}
   */
  const uploadFiles = (files) => {
    if (!Array.isArray(files)) {
      return Promise.reject();
    }

    const
      entityType = MainService.getCurrentCrmObjectType();
    const entityId = MainService.getCurrentCrmObjectId();

    return Promise.all(files.map(file => fileNetworkService.createFile(entityType, entityId, file)));
  };

  /**
   * Deletes given file
   * @param {number} id
   * @return {Promise<void>}
   */
  const deleteFile = id => fileNetworkService.deleteFile(
    MainService.getCurrentCrmObjectType(),
    MainService.getCurrentCrmObjectId(),
    id,
  );

  return {
    getFiles,
    uploadFiles,
    getFilePreview: getFilePreviewAsUrl,
    getFile: getFileAsUrl,
    deleteFile,
    downloadFile,
  };
}
