import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import ReactHighcharts from 'react-highcharts';
import {useTranslation} from 'react-i18next';
import {TooltipFormatterContextObject} from 'highcharts';
import numeral from 'numeral';
import CardCta from '../DashCard/CardCta';
import DashCard from '../DashCard/DashCard';
import useDataLoader from '../../../../hook/useDataLoader';
import useInitializer from '../../../../hook/useInitializer';
import User from '../../../../type/User';
import ListResponse from '../../../../type/service/ListResponse';
import dealNetworkService, {StageSummary} from '../../../../../network-services/deals-network-service';
import Funnel from '../../../../type/Funnel';
import userSelectOptionMapper from '../../../../util/mappers/userSelectOptionMapper';
import useDefaultOption from '../../../../hook/useDefaultOption';
import useSelectFieldOptions from '../../../../hook/useSelectFieldOptions';
import defaultSelectOptionMapper from '../../../../util/mappers/defaultSelectOptionMapper';
import useStageSummariesChartConfig from './useStageSummariesChartConfig';
import SelectField from '../../../../components/SelectField/SelectField';
import LoadingProgress from '../../../../components/LoadingProgress/LoadingProgress';

const ALL_USERS_KEY = -Math.random();

class UserIdSelect extends SelectField<User['id']> {}
const UserSelectField = styled(UserIdSelect)`
  width: 200px;
`;

const chartStyles = {
  maxWidth: '100%',
  width: '100%',
  backgroundColor: 'white',
};

const DEFAULT_CHART_CONFIG = Object.freeze({
  chart: {
    type: 'pie',
  },
  title: null,
  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
      },
    },
  },
  legend: {
    enabled: true,
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    useHtml: true,
    headerFormat: '<small>{point.key}</small><br/>',
    formatter(this: TooltipFormatterContextObject): string {
      return `${this.key}<br/><small>${numeral(this.y).format('0,0')}</small>`;
    },
  },
});

interface Props {
  funnels: Funnel[],
  hasDeals: boolean,
  users: User[],
}

const StagesCard: React.FC<Props> = ({funnels, hasDeals, users}) => {
  const {t} = useTranslation('home');

  const funnelsOptions = useSelectFieldOptions(funnels, defaultSelectOptionMapper);
  const usersOptions = useDefaultOption(useSelectFieldOptions(users, userSelectOptionMapper), 'All Users', ALL_USERS_KEY);

  const [selectedFunnel, setSelectedFunnel] = useState<Funnel | undefined>(funnels.length ? funnels[0] : undefined);
  const handleSelectFunnel = useCallback(
    (funnelId) => setSelectedFunnel(funnels.find(({id}) => id === funnelId)),
    [funnels, setSelectedFunnel],
  );

  const [selectedUserId, setSelectedUserId] = useState<User['id'] | undefined>(undefined);
  const handleSelectUser = useCallback((userId) => setSelectedUserId(userId === ALL_USERS_KEY ? undefined : userId), [setSelectedUserId]);

  const getStagesSummary = useCallback(
    () => (selectedFunnel
      ? dealNetworkService.fetchFunnelSummary(selectedFunnel.id, {viewAs: selectedUserId})
      : Promise.reject()
    ),
    [selectedFunnel, selectedUserId],
  );
  const {isLoading, loader, data} = useDataLoader([getStagesSummary]);
  useInitializer(loader);

  const hasAnyData = funnels.length > 0 && hasDeals;
  const stageSummaries = data.length > 0
    ? (data[0] as ListResponse<StageSummary>).data.filter(({totalValue}) => totalValue > 0)
    : [];
  const hasFilteredStages = stageSummaries.length > 0;
  const chartConfig = useStageSummariesChartConfig(DEFAULT_CHART_CONFIG, stageSummaries);

  return (
    <DashCard
      actions={[
        <SelectField<Funnel['id']>
          key={1}
          label={t('Funnel')}
          onChange={handleSelectFunnel}
          options={funnelsOptions}
          size="small"
          value={selectedFunnel?.id}
        />,
        <UserSelectField
          key={2}
          label={t('View By User')}
          options={usersOptions}
          onChange={handleSelectUser}
          size="small"
          value={selectedUserId ?? ALL_USERS_KEY}
        />,
      ]}
      centerAlignContent={isLoading}
      title="Deals"
      titleIconClassName="far fa-funnel-dollar"
    >
      {isLoading && <LoadingProgress global={false} />}
      {!isLoading && !hasFilteredStages && !hasAnyData && (
        <CardCta
          buttonCaption="Add Deal"
          onClick={() => {
            window.refreshDom({'NavBar.showCreateCompanyModal': false, 'NavBar.showCreateEditActivityModal': false, 'NavBar.showCreateDealModal': true, 'NavBar.showCreatePersonModal': false, 'NavBar.editActivityModalActivityId': undefined});
          }}
          text="Add your first deal"
        >
          <a
            href="https://support.mapmycustomers.com/hc/en-us/articles/360049487294-Deals-Overview"
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn more about deals
          </a>
        </CardCta>
      )}
      {!isLoading && !hasFilteredStages && hasAnyData && (
        <CardCta
          buttonCaption="Add Deal"
          onClick={() => {
            window.refreshDom({'NavBar.showCreateCompanyModal': false, 'NavBar.showCreateEditActivityModal': false, 'NavBar.showCreateDealModal': true, 'NavBar.showCreatePersonModal': false, 'NavBar.editActivityModalActivityId': undefined});
          }}
          text="No deals found. Add a deal or change filters."
        >
          <a
            href="https://support.mapmycustomers.com/hc/en-us/articles/360049487294-Deals-Overview"
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn more about deals
          </a>
        </CardCta>
      )}
      {!isLoading && hasFilteredStages && (
        <ReactHighcharts
          config={chartConfig}
          // @ts-ignore domProps actually exists, but is missed in @types/react-highcharts
          domProps={{style: chartStyles}}
          isPureConfig
        />
      )}
    </DashCard>
  );
};

export default StagesCard;
