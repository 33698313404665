import template from './bulk-edit.html';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';

BulkEditController.$inject = ['$scope', '$window', '$route', 'MainService'];

function BulkEditController($scope, $window, $route, MainService) {
  Object.assign(this, $scope, {
    MODEL: $window.DataModel,
    MAN: $window.mmcUtils.MAN,
    $route,
    localStorage: safeLocalStorage,
    MainService,
    highlightColorModals,
    onSave,
    $onInit,
  });

  function $onInit() {
    this.currentRoute = $route.current.id;
  }

  function highlightColorModals(selectedColor) {
    this.MainService.highlightColorModals(selectedColor);
  }

  function onSave() {
    this.saveCheckedDetails();
    this.toggleBulkEdit({show: false});
  }
}

function bulkEdit() {
  return {
    restrict: 'E',
    scope: {
      saveCheckedDetails: '&',
      toggleBulkEdit: '&',
    },
    bindToController: true,
    controller: 'BulkEditController',
    controllerAs: '$ctrl',
    template,
  };
}

export {BulkEditController, bulkEdit};
