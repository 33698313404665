import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import uniqBy from 'lodash/uniqBy';
import {
  createIntegration,
  deleteIntegration,
  fetchIntegration,
  fetchIntegrationFields,
  fetchIntegrations,
  fetchIntegrationUsers,
  initializeEditPage, initializeMappingStep,
  saveIntegration,
  selectService, updateIntegrationStatus,
} from './actions';
import {handleError} from '../errors/action';
import integrationsSlice, {
  EmptyIntegrationFieldsMappingState,
  getCurrentIntegration,
  getIntegrationFields, getIntegrations,
  getIntegrationUsers,
  IntegrationFieldsMapping,
} from './index';
import SUPPORTED_SERVICES from './supported-services';
import integrationNetworkService from '../../network-services/integration-network-service';
import IntegrationService from '../../react/type/enum/IntegrationService';
import ListResponse from '../../react/type/service/ListResponse';
import Integration, {SupportedEntityType} from '../../react/type/integration/Integration';
import IntegrationUser from '../../react/type/integration/IntegrationUser';
import IntegrationField from '../../react/type/integration/IntegrationField';
import IntegrationFieldResponse from '../../react/type/integration/IntegrationFieldResponse';
import customFieldsNetworkService from '../../network-services/custom-fields-network-service';
import EntityType from '../../react/type/EntityType';
import fieldModelByEntityType from '../../react/scene/Integrations/util/fieldModelByEntityType';
import CustomField from '../../common/field-model/field/custom-field';
import CustomFieldType from '../../react/type/CustomField';
import {CreateNewCustomField} from '../../store/integrations';

const redirectTo = (url: string) => {
  window.location.href = url;
};

const softRedirect = (url: string) => {
  window.location.hash = url;
};

const pushState = (url: string) => {
  window.history.pushState({}, document.title, url);
};

const replaceState = (url: string) => {
  window.history.replaceState({}, document.title, url);
};

const processMmcFieldName = (integrationField: IntegrationField): IntegrationField => {
  if (!integrationField.mmcField) {
    return integrationField;
  }
  const fieldModel = fieldModelByEntityType[integrationField.entityType];
  const field = fieldModel.getByIntegrationName(integrationField.mmcField);
  if (field) {
    return {...integrationField, mmcField: field.name};
  }
  console.error('Unknown field', integrationField);
  return integrationField;
};

const convertMmcFieldToSave = (entityType: SupportedEntityType, mmcField: string | null): string | null => {
  if (!mmcField) {
    return mmcField;
  }
  const fieldModel = fieldModelByEntityType[entityType];
  const field = fieldModel.getByName(mmcField);
  if (field) {
    return field.isCustomField ? (field as CustomField).customFieldData.crmPropertyKey : field.name;
  }
  console.error('Unknown field', entityType, mmcField);
  return mmcField;
};

function* onSelectService(
  action: ReturnType<typeof selectService>,
) {
  try {
    const url = SUPPORTED_SERVICES.find(service => service.type === action.payload)?.url;
    if (url) {
      yield call(redirectTo, url);
    }
  } catch (err) {
    yield put(handleError(err));
  }
}

function* onCreateIntegration(
  action: ReturnType<typeof createIntegration>,
) {
  try {
    const serviceName = action.payload.get('serviceName');
    if (serviceName === IntegrationService.HUBSPOT) {
      const code = action.payload.get('code');
      if (!code) {
        throw new Error('Invalid hubspot authorization, please try again');
      }
      const response = yield call(integrationNetworkService.createHubspotIntegration.bind(integrationNetworkService), code);
      yield call(redirectTo, `${window.location.protocol}//${window.location.host}/#/integrations/edit/${response.id}`);
    } else {
      throw new Error('This service is not supported yet');
    }
  } catch (err) {
    yield put(handleError(err));
    setTimeout(() => redirectTo(`${window.location.protocol}//${window.location.host}/#/integrations`), 1000);
  }
}

function* onFetchIntegrations() {
  try {
    yield put(integrationsSlice.actions.setLoading(true));
    const response: ListResponse<Integration> = yield call(integrationNetworkService.list.bind(integrationNetworkService));
    yield put(integrationsSlice.actions.setIntegrations(response.data));
    yield put(integrationsSlice.actions.setLoading(false));
  } catch (err) {
    yield put(integrationsSlice.actions.setLoading(false));
    yield put(handleError(err));
  }
}

function* onInitializeEditPage({payload: integrationId}: ReturnType<typeof initializeEditPage>) {
  try {
    yield put(integrationsSlice.actions.setLoading(true));

    const [
      integration,
      integrationUsersResponse,
      companyCustomFieldsResponse,
      peopleCustomFieldsResponse,
      dealCustomFieldsResponse,
      activityCustomFieldsResponse,
    ]: [
      Integration,
      ListResponse<IntegrationUser>,
      ListResponse<CustomFieldType>,
      ListResponse<CustomFieldType>,
      ListResponse<CustomFieldType>,
      ListResponse<CustomFieldType>,
    ] = yield all([
      call(integrationNetworkService.fetch.bind(integrationNetworkService), integrationId),
      call(integrationNetworkService.fetchUsers.bind(integrationNetworkService), integrationId),
      call(customFieldsNetworkService.getFields.bind(customFieldsNetworkService), EntityType.COMPANY),
      call(customFieldsNetworkService.getFields.bind(customFieldsNetworkService), EntityType.PERSON),
      call(customFieldsNetworkService.getFields.bind(customFieldsNetworkService), EntityType.DEAL),
      call(customFieldsNetworkService.getFields.bind(customFieldsNetworkService), EntityType.ACTIVITY),
    ]);
    yield put(integrationsSlice.actions.setCurrentIntegration(integration));
    yield put(integrationsSlice.actions.setIntegrationUsers(integrationUsersResponse.data));

    yield call(
      fieldModelByEntityType[EntityType.COMPANY].setCustomFields.bind(fieldModelByEntityType[EntityType.COMPANY]),
      companyCustomFieldsResponse.data,
    );
    yield call(
      fieldModelByEntityType[EntityType.PERSON].setCustomFields.bind(fieldModelByEntityType[EntityType.PERSON]),
      peopleCustomFieldsResponse.data,
    );
    yield call(
      fieldModelByEntityType[EntityType.DEAL].setCustomFields.bind(fieldModelByEntityType[EntityType.DEAL]),
      dealCustomFieldsResponse.data,
    );
    yield call(
      fieldModelByEntityType[EntityType.ACTIVITY].setCustomFields.bind(fieldModelByEntityType[EntityType.ACTIVITY]),
      activityCustomFieldsResponse.data,
    );

    yield put(integrationsSlice.actions.setIntegrationFields({...EmptyIntegrationFieldsMappingState}));

    yield put(integrationsSlice.actions.setLoading(false));

  } catch (err) {
    yield put(integrationsSlice.actions.setLoading(false));
    yield put(handleError(err));
  }
}

function* onInitializeMappingStep() {
  try {
    yield put(integrationsSlice.actions.setLoadingFields(true));

    const {id: integrationId, syncOptions}: Integration = yield select(getCurrentIntegration);
    const syncedEntityTypes = (Object.keys(syncOptions) as SupportedEntityType[])
      .filter(entityType => syncOptions[entityType].incoming || syncOptions[entityType].outgoing);

    const responses: ListResponse<IntegrationField>[] = yield all(syncedEntityTypes.map(entityType => call(
      integrationNetworkService.fetchFields.bind(integrationNetworkService),
      integrationId,
      entityType,
    )));

    const currentIntegrationFields: IntegrationFieldsMapping = yield select(getIntegrationFields);
    yield put(integrationsSlice.actions.setIntegrationFields({
      ...EmptyIntegrationFieldsMappingState,
      ...syncedEntityTypes.reduce<Partial<IntegrationFieldsMapping>>(
        (result, entityType, i) => ({
          ...result,
          [entityType]: uniqBy([
            ...currentIntegrationFields[entityType], // current mapping overrides loaded
            ...responses[i].data.map(processMmcFieldName),
          ], 'id'),
        }),
        {},
      ),
    }));

    yield put(integrationsSlice.actions.setLoadingFields(false));

  } catch (err) {
    yield put(integrationsSlice.actions.setLoadingFields(false));
    yield put(handleError(err));
  }
}

function* onFetchIntegration(action: ReturnType<typeof fetchIntegration>) {
  try {
    const response: Integration = yield call(integrationNetworkService.fetch.bind(integrationNetworkService), action.payload);
    yield put(integrationsSlice.actions.setCurrentIntegration(response));
  } catch (err) {
    yield put(handleError(err));
  }
}

function* onFetchIntegrationUsers(action: ReturnType<typeof fetchIntegrationUsers>) {
  try {
    const response: ListResponse<IntegrationUser> = yield call(integrationNetworkService.fetchUsers.bind(integrationNetworkService), action.payload);
    yield put(integrationsSlice.actions.setIntegrationUsers(response.data));
  } catch (err) {
    yield put(handleError(err));
  }
}

function* onFetchIntegrationFields(action: ReturnType<typeof fetchIntegrationFields>) {
  try {
    const responses: ListResponse<IntegrationField>[] = yield all(action.payload.entityTypes.map(entityType => call(
      integrationNetworkService.fetchFields.bind(integrationNetworkService),
      action.payload.integrationId,
      entityType,
    )));
    yield put(integrationsSlice.actions.setIntegrationFields({
      ...EmptyIntegrationFieldsMappingState,
      ...action.payload.entityTypes.reduce<Partial<IntegrationFieldsMapping>>(
        (result, entityType, i) => ({
          ...result,
          [entityType]: responses[i].data.map(processMmcFieldName),
        }),
        {},
      ),
    }));
  } catch (err) {
    yield put(handleError(err));
  }
}

function* onDeleteIntegration(
  action: ReturnType<typeof deleteIntegration>,
) {
  try {
    yield call(integrationNetworkService.delete.bind(integrationNetworkService), action.payload);
    yield put(fetchIntegrations());
  } catch (err) {
    yield put(handleError(err));
  }
}

function* onSaveIntegration() {
  try {
    const integration: Integration | undefined = yield select(getCurrentIntegration);
    if (!integration || !integration.id) {
      return;
    }

    yield put(integrationsSlice.actions.setSaving(true));

    const payload = {id: integration.id, syncOptions: integration.syncOptions, isLocked: integration.isLocked};
    yield call(integrationNetworkService.update.bind(integrationNetworkService), integration.id, payload);

    const integrationUsers: IntegrationUser[] = yield select(getIntegrationUsers);
    yield call(
      integrationNetworkService.updateUsers.bind(integrationNetworkService),
      integration.id,
      integrationUsers.map(({id, syncing, userId}) => ({id, syncing, userId})),
    );

    const integrationFields: IntegrationFieldsMapping = yield select(getIntegrationFields);

    const integrationFieldsResponses = yield all((Object.keys(integrationFields) as SupportedEntityType[])
      .filter(entityType => integration.syncOptions[entityType].incoming || integration.syncOptions[entityType].outgoing)
      .map(entityType => call(
        integrationNetworkService.updateFields.bind(integrationNetworkService),
        integration.id,
        entityType,
        integrationFields[entityType].map(({id, mmcField, mmcGroupField, syncing, customField}) => ({
          id,
          mmcField: mmcField === CreateNewCustomField ? null : convertMmcFieldToSave(entityType, mmcField),
          mmcGroupField,
          syncing,
          customField: !!customField
        })),  
      )),
    );
    yield put(integrationsSlice.actions.setSaving(false));

    const customFieldRes = integrationFieldsResponses.reduce(
      (result: IntegrationFieldResponse[], response: IntegrationFieldResponse[]) => 
        result.concat(response.filter((mmcFieldItem: IntegrationFieldResponse) => mmcFieldItem.customField )
      ),
      [],
    );
    yield put(integrationsSlice.actions.setCustomFieldIntegrationResponse(customFieldRes));

  } catch (err) {
    yield put(integrationsSlice.actions.setSaving(false));
    yield put(handleError(err));
  }
}

function* onUpdateIntegrationStatus(action: ReturnType<typeof updateIntegrationStatus>) {
  try {
    const integrations: Integration[] = yield select(getIntegrations);
    const index = integrations.findIndex(({id}) => id === action.payload.integrationId);
    if (index < 0) {
      return;
    }
    const integration = integrations[index];

    yield put(integrationsSlice.actions.setSaving(true));

    const payload = {id: integration.id, syncOptions: integration.syncOptions, isLocked: action.payload.isLocked};
    const response: Integration = yield call(
      integrationNetworkService.update.bind(integrationNetworkService),
      integration.id,
      payload,
    );

    yield put(integrationsSlice.actions.setSaving(false));

    const updatedIntegrations = [...integrations.slice(0, index), response, ...integrations.slice(index + 1)];
    yield put(integrationsSlice.actions.setIntegrations(updatedIntegrations));

  } catch (err) {
    yield put(integrationsSlice.actions.setSaving(false));
    yield put(handleError(err));
  }
}

export default function* integrationsSaga() {
  yield takeLatest(selectService.type, onSelectService);
  yield takeLatest(createIntegration.type, onCreateIntegration);
  yield takeLatest(fetchIntegrations.type, onFetchIntegrations);
  yield takeLatest(initializeEditPage.type, onInitializeEditPage);
  yield takeLatest(initializeMappingStep.type, onInitializeMappingStep);
  yield takeLatest(fetchIntegration.type, onFetchIntegration);
  yield takeLatest(fetchIntegrationUsers.type, onFetchIntegrationUsers);
  yield takeLatest(fetchIntegrationFields.type, onFetchIntegrationFields);
  yield takeLatest(deleteIntegration.type, onDeleteIntegration);
  yield takeLatest(saveIntegration.type, onSaveIntegration);
  yield takeLatest(updateIntegrationStatus.type, onUpdateIntegrationStatus);
}
