import {configureStore} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import {enableMapSet} from 'immer';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import {initApp} from './globalActions';

enableMapSet();
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  devTools: true,
  reducer: rootReducer,
  // @ts-ignore
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);
store.dispatch(initApp());

console.log('I am store', store);

// // we need to refresh angular app
// const updateAngular = debounce(
//   () => {
//     console.error('updating angular');
//     if (window.$apply) {
//       window.$apply();
//     }
//   },
//   100, // not more than once in 100ms
//   {maxWait: 500}, // but guaranteed to updated at least once in 500ms
// );
// store.subscribe(() => {
//   updateAngular();
// });

export const initializer = () => {
  console.log('I am called after app startup');
};

export default store;
