import React, {memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Addon from './Addon';
import AddonType from '../../../type/plans/Addon';
import CategoryTitle from './CategoryTitle';
import {shouldShowAddon} from '../util/util';
import CurrentPlan from '../../../type/plans/CurrentPlan';

interface Props {
  addons: AddonType[],
  currentPlan: CurrentPlan,
  licenses: number,
  onSelect?: (addonId: AddonType['id'], selected: boolean) => void,
  selectedAddonIds: Set<AddonType['id']>,
}

const AddonList = memo<Props>(({addons, currentPlan, licenses, onSelect, selectedAddonIds}) => {
  const {t} = useTranslation(['billing']);
  const purchasedAddons = useMemo(() => new Set(currentPlan.addonIds), [currentPlan]);
  const hasVisibleAddons = useMemo(
    () => addons.some(addon => shouldShowAddon(addon, licenses, currentPlan.licenses, purchasedAddons.has(addon.id))),
    [addons, currentPlan.licenses, licenses, purchasedAddons],
  );

  if (!hasVisibleAddons) {
    return null;
  }

  return (
    <>
      <CategoryTitle>{t('Add-on Subscriptions')}</CategoryTitle>
      {addons.map(addon => (
        <Addon
          addon={addon}
          checked={selectedAddonIds.has(addon.id)}
          key={addon.id}
          liveLicenses={currentPlan.licenses}
          licenses={licenses}
          onCheck={onSelect}
          wasChecked={currentPlan.addonIds.includes(addon.id)}
        />
      ))}
    </>
  );
});

export default AddonList;
