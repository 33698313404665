import angular from 'angular';
import each from 'lodash/each';
import mapActions from '../store/store-helpers';

//
// ------------------- Custom Directives ------------------- //
//

const addDirectives = (angularApp) => {
  angularApp
    .directive('integer', () => ({
      require: 'ngModel',
      link(scope, ele, attr, ctrl) {
        ctrl.$parsers.unshift((viewValue) => parseInt(viewValue, 10));
      },
    }))
    .directive('ngEnter', () => function (scope, element, attrs) {
      element.bind('keydown keypress', (event) => {
        if (event.which === 13) {
          scope.$apply(() => {
            scope.$eval(attrs.ngEnter);
          });
          event.stopPropagation();
          event.preventDefault();
        }
      });
    })
    .directive('disableWithAttr', ['$parse', function ($parse) {
      const disableOptions = function (scope, attr, element, data, fnDisableIfTrue) {
        // refresh disabled  options in the select element.
        const options = element.find('option');
        each(options, (value, index) => {
          const elem = angular.element(value);
          if (elem.val()) {
            const locals = {};
            locals[attr] = data[index];
            elem.attr('disabled', fnDisableIfTrue(scope, locals));
          }
        });
      };
      return {
        priority: 0,
        require: 'ngModel',
        link(scope, iElement, iAttrs) {
          // parse the expression and build array of disabled options
          const expElements = iAttrs.disableWithAttr.match(/^\s*(.+)\s+for\s+(.+)\s+in\s+(.+)?\s*/);
          const attrToWatch = expElements[3];
          const fnDisableIfTrue = $parse(expElements[1]);

          // Register Watch
          scope.$watch(attrToWatch, (newVal) => {
            if (newVal) {
              disableOptions(scope, expElements[2], iElement, newVal, fnDisableIfTrue);
            }
          }, true);

          // Register Watch
          scope.$watch(iAttrs.ngModel, (newVal) => {
            const disOptions = $parse(attrToWatch)(scope);
            if (newVal) {
              disableOptions(scope, expElements[2], iElement, disOptions, fnDisableIfTrue);
            }
          });
        },
      };
    }])
    .directive('starRating', () => ({
      restrict: 'A',
      template: '<ul class="rating">' +
        '<li ng-repeat="star in stars" ng-class="star" ng-click="toggle($index)">' +
        '<i class="fa fa-star" aria-hidden="true"></i>' +
        '</li>' +
        '</ul>',
      scope: {
        ratingValue: '=',
        max: '=',
        onRatingSelected: '&',
      },
      link(scope) {
        const updateStars = function () {
          scope.stars = [];
          for (let i = 0; i < scope.max; i++) {
            scope.stars.push({
              filled: i < scope.ratingValue,
            });
          }
        };

        scope.toggle = function (index) {
          scope.ratingValue = index + 1;
          scope.onRatingSelected({
            rating: index + 1,
          });
        };

        scope.$watch('ratingValue', () => {
          updateStars();
        });
      },
    }))
    .directive('windowSize', ['$window', function ($window) {
      return function (scope) {
        const w = angular.element($window);
        scope.getWindowDimensions = function () {
          return {
            h: w.height(),
            w: w.width(),
          };
        };
        scope.$watch(scope.getWindowDimensions, (newValue) => {
          scope.windowHeight = newValue.h;
          scope.windowWidth = newValue.w;
          scope.style = function () {
            return {
              height: `${newValue.h - 100}px`,
              width: `${newValue.w - 100}px`,
            };
          };
        }, true);

        w.bind('resize', () => {
          scope.$apply();
        });
      };
    }])
    .directive('dynamicName', () => ({
      restrict: 'A',
      priority: -1,
      require: ['ngModel'],
      link(scope, element, attr, ngModel) {
        ngModel[0].$name = attr.name;
      },
    }))
    .directive('ngOnload', ['$parse', function ($parse) {
      return {
        restrict: 'A',
        link(scope, elem, attrs) {
          const fn = $parse(attrs.ngOnload);
          elem.on('load', (event) => {
            scope.$apply(() => {
              fn(scope, {$event: event});
            });
          });
        },
      };
    }])
    // show/hide modal popups
    .directive('showPopupOverlay', () => ({
      restrict: 'C',
      link(scope, elem, attrib) {
        mapActions(scope, ['modals']);
        elem.on('click', () => {
          if (!attrib.disabled) {
            if (!attrib.close) {
              $('body').css('overflow-y', 'hidden');
              console.log('show add edit popup', scope);
              scope.modalsActions.showModal('addEditPopup');
            } else {
              $('body').css('overflow-y', 'auto');
              scope.modalsActions.hideModal('addEditPopup');
              console.log('hide add edit popup');
            }
          }
        });
      },
    }))
    // show/hide passwords
    .directive('showPass', () => ({
      restrict: 'A',
      link(scope, elem, attrib) {
        function revealPass(id) {
          $(`#${id}`).attr('type', 'text');
        }
        function concealPass(id) {
          $(`#${id}`).attr('type', 'password');
        }
        elem.on('click', () => {
          const id = attrib.showPass;
          const {toggle} = attrib;
          $(`#${toggle}`).css('display', 'inline-block');
          $(`#${attrib.id}`).css('display', 'none');
          if (attrib.show === 'true') {
            revealPass(id);
          } else {
            concealPass(id);
          }
        });
      },
    }))
    /**
     * Directives to show a no-data stubs.
     */
    .directive('noDataBase', () => ({
      restrict: 'E',
      scope: {
        popupId: '<',
        buttonTitle: '=',
        className: '=',
        description: '=',
        imageUrl: '=',
        click: '&onClick',
        title: '=',
      },
      link(scope) {
        mapActions(scope, ['modals']);
      },
      controller: ['$scope', function NoDataBaseController($scope) {
        $scope.closeSelf = function () {
          $scope.modalsActions.hideModal($scope.popupId);
        };
      }],
      templateUrl: 'views/no-data/no-data-base.html',
    }))
    .directive('noData', () => ({
      restrict: 'E',
      scope: {
        click: '&onClick',
        type: '&',
      },
      templateUrl: (elem, attr) => `views/no-data/no-data-${attr.type}.html`,
    }))
    .directive('noDataGroup', () => ({
      restrict: 'E',
      scope: {
        click: '&onClick',
      },
      template: () => {
        const type = ['accounts', 'contacts', 'deals'].find(window.isOnPage);
        return type ? `<no-data type="${type}-groups" on-click="click()"/>` : '';
      },
    }))

    .directive('mmcTooltip', [function () {
      return {
        restrict: 'A',
        scope: {
          title: '=tooltipTitle',
          placement: '=tooltipPlacement',
        },
        link: function link(scope, element) {
          element.tooltip({
            title: scope.title,
            placement: scope.placement,
          });

          scope.$watch('placement', (placement) => {
            element.tooltip({placement});
          });

          scope.$watch('title', (title) => {
            element.tooltip({title});
          });

          element.on('$destroy', () => {
            try {
              element.tooltip('dispose');
            } catch (e) {
              // ignore error, it can happen with some versions of bootstrap
              // https://github.com/sorich87/bootstrap-tour/issues/354
            }
          });
        },
      };
    }])
    .directive('autosize', () => ({
      restrict: 'A',
      require: 'ngModel',
      scope: {
      },
      link(scope, element, attrs, model) {
        const adjust = (element) => {
          if (element) {
            element.style.overflow = 'hidden';
            element.style.height = 'auto'; // needed to shrink height if there's short content
            element.style.height = `${element.scrollHeight}px`;
          }
        };

        $(element).on('input', () => {
          adjust(element[0]);
        });

        scope.$watch(() => model.$modelValue, () => adjust(element[0]));

        adjust(element[0]);
      },
    }))
    .directive('crmLink', () => ({
      restrict: 'E',
      scope: {
        link: '=',
      },
      template: '<div class="form-group" ng-if="link">' +
        '<label>CRM link</label>' +
        '<div><a target="_blank" ng-href="{{link}}">{{link}}</a></div>' +
        '</div>',
    }));
};

export default addDirectives;
