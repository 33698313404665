import React, {useCallback} from 'react';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Avatar from '../../../Avatar';
import UserProfileInfo from './UserProfileInfo';
import safeLocalStorage from '../../../../../shared-services/safe-local-storage-service';
import analyticsService from '../../../../../shared-services/analytics-service';
import styles from './UserMenu.module.scss';
import {getVersion, isVersion2, Version} from '../../../../../common/versions';

const getNewAppUrl = (path: string): string => `${window.__env.baseNewAppUrl}${path}?mmc_token=${safeLocalStorage.accessToken}`;
const getNewAppLogoutUrl = (webVersion?: Version) => (webVersion !== Version.V1 ? `${window.__env.baseNewAppUrl}?mmc_logout` : 'https://mapmycustomers.com');

interface Props {
}

const UserMenu: React.FC<Props> = () => {
  const webVersion = getVersion();
  const trackAnalytic = (event: string) => analyticsService.clicked(['Navbar', event]);

  // Logout wrapped in useCallback to avoid click event getting into its arguments
  const handleLogoutClick = useCallback(() => {
    // get webVersion before clearing safeLocalStorage
    safeLocalStorage.clear();
    trackAnalytic('Logout');
    window.location.href = getNewAppLogoutUrl(webVersion);
  }, [webVersion]);


  const handleChangePassword = useCallback(() => {
    window.DataModel.password.showModal = true;
    window.refreshDom({showModal: true}, 'password');
    trackAnalytic('Change Password');
  }, []);

  const handleSubscriptionClick = useCallback(
    () => {
      trackAnalytic('Manage Your Subscription');
      if (isVersion2()) {
        window.location.href = getNewAppUrl('/billing');
      } else {
        window.location.hash = 'upgrade';
      }
    }, [webVersion],
  );

  const handleSupportClick = () => trackAnalytic('Support');

  const me = safeLocalStorage.currentUser!;

  const userMenuOptions = (
    <Menu className={styles.userMenuItem} onSelect={alert}>
      <Menu.Item className={styles.userProfileInfo} key="userProfileInfo">
        <UserProfileInfo user={me} />
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="changePassword">
        {/* eslint-disable-next-line */}
        <div onClick={handleChangePassword}>Change Password</div>
      </Menu.Item>
      <Menu.Item key="manageSubscription" onClick={handleSubscriptionClick}>
        Manage Your Subscription
      </Menu.Item>
      <Menu.Item key="support">
        <a
          href="https://support.mapmycustomers.com/hc/en-us"
          onClick={handleSupportClick}
          target="_blank"
          rel="noopener noreferrer"
        >
          Support
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        {/* eslint-disable-next-line */}
        <div className={styles.logout} onClick={handleLogoutClick}>
          Logout
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={userMenuOptions} placement="bottomRight" trigger={['click']}>
      <div className={styles.container}>
        <Avatar size={36} user={me} />
      </div>
    </Dropdown>
  );
};

export default UserMenu;
