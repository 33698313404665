import {Dispatch, SetStateAction, useCallback, useState} from 'react';

type SetMutator<T> = (set: Set<T>) => boolean;
type MutableSetHookResult<T> = [
  Set<T>,
  (mutator: SetMutator<T>) => void,
  (item: T, included?: boolean) => void,
  Dispatch<SetStateAction<Set<T>>>,
]
/**
 * This is a hook used for having a mutable set. Set is stored as a ref (using useRef) to prevent
 * recreating the set (as if it was stored in useState). However, changing any ref doesn't trigger
 * rendering. That's why we have to use a state via useState which is changed every time the set is
 * mutated.
 *
 * @param setCreator a method used to create an initial set
 */
const useSet = <T>(setCreator?: () => Set<T>): MutableSetHookResult<T> => {
  const [set, updateSet] = useState<Set<T>>(setCreator ? setCreator() : new Set<T>());
  const mutateSet = useCallback(
    (mutator: SetMutator<T>) => updateSet(set => {
      const newSet = new Set(set);
      return mutator(newSet) ? newSet : set;
    }),
    [updateSet],
  );
  const toggleItem = useCallback(
    (item: T, included?: boolean) => mutateSet((set: Set<T>) => {
      if (included === undefined) {
        included = !set.has(item);
      }
      const setChanged = included ? !set.has(item) : set.has(item);
      if (setChanged && included) {
        set.add(item);
      } else if (setChanged) {
        set.delete(item);
      }
      return setChanged;
    }),
    [mutateSet],
  );
  return [set, mutateSet, toggleItem, updateSet];
};

export default useSet;
