import template from './routing-cadence-modal.html';
import mapActions from '../../../store/store-helpers';

RoutingCadenceModalController.$inject = ['$window'];

function RoutingCadenceModalController($window) {
  Object.assign(this, {
    $window,
    MODEL: $window.DataModel,
    closeCadenceModal,
    updateSmartRoutingCadence,
    updateSmartRoutingStopInterval,
    updateSmartRoutingBreakTime,
    $onInit,
  });

  function $onInit() {
    this.smartRoutingCadence = this.MODEL.RoutingService.smartRoutingCadence;
    this.smartRoutingStopInterval = this.MODEL.RoutingService.smartRoutingStopInterval;
    this.smartRoutingBreakTime = this.MODEL.RoutingService.smartRoutingBreakTime;
  }

  mapActions(this, ['RoutingService']);

  function closeCadenceModal() {
    this.RoutingServiceActions.closeCadenceModal();
  }

  function updateSmartRoutingCadence() {
    this.RoutingServiceActions.updateSmartRoutingCadence(this.smartRoutingCadence);
  }

  function updateSmartRoutingStopInterval() {
    this.RoutingServiceActions.updateSmartRoutingStopInterval(this.smartRoutingStopInterval);
  }

  function updateSmartRoutingBreakTime() {
    this.RoutingServiceActions.updateSmartRoutingBreakTime(this.smartRoutingBreakTime);
  }
}

function routingCadenceModal() {
  return {
    restrict: 'E',
    bindToController: {},
    scope: {},
    controller: 'RoutingCadenceModalController',
    controllerAs: '$ctrl',
    template,
  };
}

export {RoutingCadenceModalController, routingCadenceModal};
