import React, {useCallback} from 'react';
import cn from 'classnames';
import Collapse from 'react-md/lib/Helpers/Collapse';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import styled from 'styled-components';
import {maxWidth} from '../../../style/media';

const SectionTitle = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:not(:first-of-type) {
    border-top: 1px solid #dedede;
    margin-top: 16px;
    padding-top: 16px;
  }
  ${maxWidth.wideDesktop`
    &:first-of-type {
      border-top: 1px solid #dedede;
      padding-top: 16px;
    }
  `};
`;

const SectionName = styled.div`
  flex-grow: 1;
  margin-bottom: 0;
`;

interface Props {
  children: React.ReactNode,
  hasChildren: boolean,
  onTogglePanel?: (panel: number) => void,
  panel: number,
  title: string,
  visible: boolean,
}

const ExpandableSection: React.FC<Props> = ({children, hasChildren, onTogglePanel, panel, title, visible}) => {
  const handleTogglePanel = useCallback(() => onTogglePanel && onTogglePanel(panel), [onTogglePanel, panel]);
  return (
    <>
      <SectionTitle onClick={handleTogglePanel}>
        <FontIcon className={cn('md-collapser', {'md-collapser--flipped': visible})} inherit>
          {hasChildren ? 'expand_more' : ' '}
        </FontIcon>
        <SectionName className="md-title">
          {title}
        </SectionName>
      </SectionTitle>
      <Collapse collapsed={!visible}>
        <>
          {children}
        </>
      </Collapse>
    </>
  );
};

export default ExpandableSection;
