import React, {useMemo} from 'react';
import styled from 'styled-components';
import {Button, Pagination as AntdPagination} from 'antd';
import {useTranslation} from 'react-i18next';
import theme from '../../style/theme';

const PageButton = styled(Button)`
  padding: 4px 12px;
  min-width: inherit;
  margin: 0px 5px;
`;


interface PageBoxProps {
  text: string,
  onClick?: () => void,
  disabled?: boolean,
}

const PageBox: React.FC<PageBoxProps> = ({text, onClick = () => {}, disabled = false}) => (
  <PageButton disabled={disabled} onClick={onClick}>{text}</PageButton>
);

const Container = styled.div`
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  .ant-pagination-item {
    border-radius: 4px;
  }
  .ant-pagination-item-active {
    background-color: ${theme.color.brightBlue};
    a {
      color: #fff;
    }
  }
`;

interface Props {
  total: number,
  page: number,
  pageSize?: number,
  onSetPage: (page: number) => void,
}

const Pagination: React.FC<Props> = ({page, total, onSetPage, pageSize = 100}) => {
  const {t} = useTranslation(['grid']);
  const pageCount = useMemo(
    () => Math.trunc(total / pageSize) + 1,
    [total, pageSize],
  );

  return (
    <Container>
      <PageBox text={t('First')} disabled={page === 1} onClick={() => onSetPage(1)} />
      <AntdPagination total={total} pageSize={pageSize} showSizeChanger={false} current={page} onChange={onSetPage} />
      <PageBox text={t('Last')} disabled={page >= pageCount} onClick={() => onSetPage(pageCount)} />
    </Container>
  );
};

export default Pagination;
