import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../rootReducer';
import {ValueOf} from '../../react/util/ts';
import {Actions} from './action';
import EntityFieldModel from '../../common/field-model/entity-field-model';
import leadFieldModel from '../../common/field-model/lead-field-model';
import CustomField from '../../react/type/CustomField';
import Group from '../../react/type/Group';
import Lead from '../../react/type/Lead';

export type LeadState = {
  leads: Lead[],
  leadIndexById: Map<Lead['id'], number>,
  error: Error | undefined,
  fieldModel: EntityFieldModel,
  loading: boolean,
  totalLeadCount: number,
  groups: Group[],
};

const initialState: LeadState = {
  leads: [],
  leadIndexById: new Map<Lead['id'], number>(),
  fieldModel: leadFieldModel,
  error: undefined,
  loading: false,
  totalLeadCount: 0,
  groups: [],
};

const lead = createSlice({
  name: 'lead',
  initialState,
  reducers: {
    setLeads: (state, action: PayloadAction<Lead[]>) => {
      state.leads = action.payload;
      state.leadIndexById = new Map(action.payload.map(({id}, index) => [id, index]));
    },
    appendLeads: (state, action: PayloadAction<Lead[]>) => {
      const startIndex = state.leads.length;
      const leadIndexById = new Map([
        ...Array.from(state.leadIndexById),
        ...action.payload.map(({id}, index) => [id, index + startIndex] as const),
      ]);
      const leads = state.leads.concat(action.payload);
      return {...state, leadIndexById, leads};
    },
    setError: (state, action: PayloadAction<Error | undefined>) => {
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLeadCount: (state, action: PayloadAction<number>) => {
      state.totalLeadCount = action.payload;
    },
    updateCustomFields: (state, action: PayloadAction<CustomField[]>) => {
      const fieldModelClone = Object.assign(Object.create(Object.getPrototypeOf(state.fieldModel)), state.fieldModel);
      fieldModelClone.setCustomFields(action.payload);
      state.fieldModel = fieldModelClone;
    },
    setGroups: (state, action: PayloadAction<Group[]>) => {
      state.groups = action.payload;
    },
  },
});

const leadState = (state: RootState) => state.lead;
export const getLeads = createSelector(leadState, ({leads}) => leads);
export const getLeadsCount = createSelector(leadState, ({leads}) => leads.length);
export const hasLeads = createSelector(leadState, ({leads}) => leads.length > 0);
export const getError = createSelector(leadState, ({error}) => error);
export const getFieldModel = createSelector(leadState, ({fieldModel}) => fieldModel);
export const getLoading = createSelector(leadState, ({loading}) => loading);
export const getTotalLeadCount = createSelector(leadState, ({totalLeadCount}) => totalLeadCount);
export const getLeadGroups = createSelector(leadState, ({groups}) => groups);

export type LeadActions =
  | ReturnType<ValueOf<typeof lead.actions>>
  | Actions;

export default lead;
