import template from './smart-routing-visiting-modal.html';
import mapActions from '../../../store/store-helpers';

function SmartRoutingVisitingModalController($window, RoutingService) {
  function $onInit() {
    this.smartRoutingDepartureTime = this.RoutingService.smartRoutingDepartureTime;
    this.smartRoutingReturnTime = this.RoutingService.smartRoutingReturnTime;
  }

  mapActions(this, ['RoutingService']);

  function closeSmartVisitingModal() {
    this.RoutingServiceActions.closeSmartVisitingModal();
  }

  function updateSmartRoutingDepartureTime() {
    this.RoutingServiceActions.updateSmartRoutingDepartureTime(this.smartRoutingDepartureTime);
  }

  function updateSmartRoutingReturnTime() {
    this.RoutingServiceActions.updStesmartRoutingReturnTime(this.smartRoutingReturnTime);
  }

  function showSmartRoutingLocationModal() {
    this.RoutingService.showSmartRoutingLocationModal();
  }

  Object.assign(this, {
    $window,
    MODEL: $window.DataModel,
    RoutingService,
    closeSmartVisitingModal,
    updateSmartRoutingDepartureTime,
    updateSmartRoutingReturnTime,
    showSmartRoutingLocationModal,
    $onInit,
  });
}

SmartRoutingVisitingModalController.$inject = ['$window', 'RoutingService'];

function smartRoutingVisitingModal() {
  return {
    restrict: 'E',
    bindToController: {},
    scope: {},
    controller: 'SmartRoutingVisitingModalController',
    controllerAs: '$ctrl',
    template,
  };
}

export {SmartRoutingVisitingModalController, smartRoutingVisitingModal};
