import angular from 'angular';
import GroupsCtrl from './groups-controller';
import GroupsService from './groups-service';
import {AddGroupModalController, addGroupModal} from './add-group-modal/add-group-modal-component';

export default angular.module('mapmycustomersApp.groups', [])
  .controller('GroupsCtrl', GroupsCtrl)
  .factory('GroupsService', GroupsService)
  .component('addGroupModal', addGroupModal)
  .controller('AddGroupModalController', AddGroupModalController)
  .name;
