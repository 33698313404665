import isEmpty from 'lodash/isEmpty';

export default function FilterNetworkService() {
  // main utility functions
  const MODEL = window.DataModel;

  // service to return
  const service = {};

  // parse filter query
  service.getParseFilterQuery = () => {
    const whereQuery = {};

    const customFilters = [];
    MODEL.FilterService.filtersSelected.forEach(filter => {
      if (filter.category !== 'radius' && filter.category !== 'groups') {
        customFilters.push(filter);
      }
    });

    customFilters.forEach(filter => {
      // equals
      if (filter.comparator === 'equals') {
        whereQuery[filter.category] = filter.value;
        // exist and does not exist
      } else if (filter.comparator === 'exists' || filter.comparator === 'does not exist') {
        const filterField = MODEL.FilterService.parseOpString[filter.comparator];

        // check key exists or not
        if (isEmpty(whereQuery[filter.category])) {
          whereQuery[filter.category] = {};
        }

        if (filter.comparator === 'exists') {
          whereQuery[filter.category][filterField] = true;
        } else {
          whereQuery[filter.category][filterField] = false;
        }
      } else if (filter.comparator === 'is before') {
        // other than createdAt, every time field is integer
        if (filter.category !== 'createdAt') {
          const filterField = MODEL.FilterService.parseOpString[filter.comparator];
          const filterDatePickerData = Date.parse(filter.value);
          const filterDate = parseFloat(filterDatePickerData.valueOf() / 1000);

          // check key exists or not
          if (isEmpty(whereQuery[filter.category])) {
            whereQuery[filter.category] = {};
          }

          // check relational query exists or not
          if (isEmpty(whereQuery[filter.category][filterField])) {
            whereQuery[filter.category][filterField] = filterDate;
          }
        } else {
          //  convert it into iso format date string
          const filterDate = new Date(filter.value).toISOString();
          whereQuery[filter.category] = {$lte: {__type: 'Date', iso: filterDate}};
        }
      } else if (filter.comparator === 'is after') {
        // other than createdAt, every time field is integer
        if (filter.category !== 'createdAt') {
          const filterField = MODEL.FilterService.parseOpString[filter.comparator];
          const filterDatePickerData = Date.parse(filter.value);
          const filterDate = parseFloat(filterDatePickerData.valueOf() / 1000);

          // check key exists or not
          if (isEmpty(whereQuery[filter.category])) {
            whereQuery[filter.category] = {};
          }

          // check relational query exists or not
          if (isEmpty(whereQuery[filter.category][filterField])) {
            whereQuery[filter.category][filterField] = filterDate;
          }
        } else {
          //  convert it into iso format date string
          const filterDate = new Date(filter.value).toISOString();
          whereQuery[filter.category] = {$gte: {__type: 'Date', iso: filterDate}};
        }
      } else {
        const filterField = MODEL.FilterService.parseOpString[filter.comparator];

        // currently case sensitive
        if (filter.comparator === 'starts with') {
          filter.value = `^${filter.value}`;
        }

        // check key exists or not
        if (isEmpty(whereQuery[filter.category])) {
          whereQuery[filter.category] = {};
        }

        // check relational query exists or not
        if (isEmpty(whereQuery[filter.category][filterField])) {
          whereQuery[filter.category][filterField] = filter.value;
        }
      }
    });

    // groups filter
    if (MODEL.FilterService.currentSelectedGroups.length) {
      whereQuery.groups = {$in: MODEL.FilterService.currentSelectedGroups};
    }

    // radius filter
    if (MODEL.FilterService.filterCurrentMiles && MODEL.FilterService.filterNearbyLatLng) {
      whereQuery.geoPoint = {
        $nearSphere: {
          __type: 'GeoPoint',
          latitude: MODEL.FilterService.filterNearbyLatLng.lat,
          longitude: MODEL.FilterService.filterNearbyLatLng.lng,
        },
        $maxDistanceInMiles: MODEL.FilterService.filterCurrentMiles,
      };
    }

    return whereQuery;
  };

  return service;
}

FilterNetworkService.$inject = [];
