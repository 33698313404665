import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import mapActions from '../../store/store-helpers';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';
import SharedNetworkService from '../../network-services/shared-network-service';
import teamNetworkService from '../../network-services/team-network-service';
import userNetworkService from '../../network-services/user-network-service';
import helperService from '../../shared-services/helper-service';
import integrationNetworkService from '../../network-services/integration-network-service';

export default function TeamService(
  $rootScope, $route, MainService,
  LocationNetworkService, DefaultDataService,
) {
  // main utility functions
  const {MAN} = window.mmcUtils;
  const MODEL = window.DataModel;

  // service to return
  const service = {};
  mapActions(service, ['modals']);

  service.integrationsURL = window.__env.integrationsURL;
  service.baseAppUrl = window.__env.baseAppUrl;

  service.getOrgDetails = async function () {
    const response = await Promise.all([userNetworkService.getUserData(), SharedNetworkService.getRoles()]);
    safeLocalStorage.currentUser = response[0];
    MODEL.org = response[0].organization;
    MODEL.ownerId = MODEL.org.owner.id;
    MODEL.roles = response[1].data;
    const teams = await teamNetworkService.getTeams(MODEL.org.id);

    MODEL.TeamsService.subTeams = teams.data;
    await this.calculateTeams();
    MODEL.TeamsService.allUsers = (await userNetworkService.getAllUsers(MODEL.org.id)).data.filter(user => user.viaTeamAccess);
    this.calculateUsersWithoutTeam();
    MODEL.TeamsService.teamStructure = service.createTeamStructure(
      MODEL.TeamsService.subTeams,
      MODEL.TeamsService.subTeamUsers,
      MODEL.owner,
    );

    window.refreshDom({verifiedTeamMembers: MODEL.TeamsService.verifiedTeamMembers}, 'TeamsService');
    if ($route.current.hasMap && $route.current.id !== 'dashboardPage') {
      service.getLocation();
    }
  };

  service.calculateTeams = async () => {
    const teamUserProm = MODEL.TeamsService.subTeams.map((st) => teamNetworkService.getTeamMembers(MODEL.org.id, st.id));
    const teamUsers = await Promise.all(teamUserProm);
    MODEL.TeamsService.allUsers = (await userNetworkService.getAllUsers(MODEL.org.id)).data.filter(user => user.viaTeamAccess);
    MODEL.TeamsService.subTeamUsers = teamUsers.reduce((curr, data) => curr.concat(data.data), []);
    MODEL.owner = await teamNetworkService.getSingleTeamMember(MODEL.org.id, MODEL.ownerId);
    MODEL.TeamsService.verifiedTeamMembers = service.getVerifiedTeamMembers(MODEL.TeamsService.subTeamUsers);
    MODEL.TeamsService.quantity = get(MODEL.org, 'plan.quantity', 0);
    MODEL.TeamsService.usersLimitLeft = MODEL.TeamsService.quantity === 0
      ? Number.POSITIVE_INFINITY
      : MODEL.TeamsService.quantity - MODEL.org.teamSize;
  };

  service.calculateUsersWithoutTeam = () => {
    const usersWithTeam = new Set([MODEL.ownerId, ...MODEL.TeamsService.subTeamUsers.map(({id}) => id)]);
    MODEL.TeamsService.usersWithoutTeam = MODEL.TeamsService.allUsers.filter(({id}) => !usersWithTeam.has(id));
  };

  service.getVerifiedTeamMembers = (teamUsers) => {
    service.calculateUsersWithoutTeam();
    const teamMembers = teamUsers.concat(MODEL.TeamsService.usersWithoutTeam)
      .map(({id, username}) => ({id, username}))
      .sort((a, b) => a.username.localeCompare(b.username));

    teamMembers.unshift({username: 'All Users'});

    return uniqBy(teamMembers, 'id');
  };

  service.createTeamStructure = (subTeams, teamMembers, owner) => {
    const usersWithoutTeam = MODEL.TeamsService.usersWithoutTeam;
    let teams = subTeams.map(st => ({
      teamId: st.id,
      name: st.name,
    }));

    const teamOwnerNames = new Set([owner.username]);

    teams = teams.map(team => {
      team.teamManagersNames = [];
      team.teamMembersNames = [];
      teamMembers.forEach(mem => {
        if (mem.teamId === team.teamId && mem.role.key === 'MANAGER') {
          team.teamManagersNames.push(mem.username);
        } else if (mem.teamId === team.teamId && (mem.role.key === 'MEMBER' || mem.role.key === 'READ_ONLY')) {
          team.teamMembersNames.push(mem.username);
        } else if (mem.teamId === team.teamId && mem.role.key === 'OWNER') {
          teamOwnerNames.add(mem.username);
        }
      });

      return team;
    });
    const noOwnerUsersWithoutTeam = [];
    usersWithoutTeam.forEach(user => {
      if (user.role.key === 'OWNER') {
        teamOwnerNames.add(user.username);
      } else {
        noOwnerUsersWithoutTeam.push(user);
      }
    });

    return {
      teamOwnerName: owner.username,
      teamOwnerNames: Array.from(teamOwnerNames),
      subTeams: [
        ...(noOwnerUsersWithoutTeam.length
          ? [{
            name: 'Users without a team',
            subheader: 'To share records and assign permissions, add these users to a team.',
            teamManagersNames: [],
            teamMembersNames: noOwnerUsersWithoutTeam.map(u => u.username),
          }]
          : []
        ),
        ...teams,
      ],
    };
  };

  // open side bar for selected option
  service.openSelectedOption = (selectedOption) => {
    window.refreshDom({selectedOption}, 'TeamsService');
  };

  // create new team from individual account
  service.createTeamFromIndividualAccount = teamName => service.chooseTeamName(teamName);

  // choose name for your team
  service.chooseTeamName = async (teamName) => {
    // team name too short or too long
    if (!teamName || teamName.length < 3 || teamName.length > 25) {
      swal('Oops...', 'Enter a team name between 3 and 25 characters.', 'error');
      return;
    }

    MODEL.show.loader = true;
    // check if team name exists already
    const orgId = safeLocalStorage.currentUser.organization.id;
    const teams = await teamNetworkService.getTeams(orgId, {$filters: {name: teamName}});
    // team already exists
    if (teams.data.length > 0) {
      window.refreshDom({loader: false}, 'show');
      return swal('Oops...', 'This team name already exists. Please enter a different name.', 'error');
    }

    // add team to teams table
    let domainName = teamName.replace(/[^a-z0-9-]+/gi, '-');
    domainName = domainName.toLowerCase();

    const payload = {
      owner: safeLocalStorage.currentUser.username,
      name: teamName,
      domain: domainName,
    };

    try {
      const team = await teamNetworkService.postTeam(orgId, payload);
      if (!team.id) {
        throw new Error('Failed to create team');
      }
      window.location.href = '#/dashboard/teams';
    } catch (e) {
      console.error('error', e);
      window.refreshDom({loader: false}, 'show');
    }
  };

  // determine if team name already exists
  service.doesTeamNameExistAlready = function (teamName) {
    const whereQuery = {name: teamName};
    return teamNetworkService.getTeam(whereQuery, {})
      .then(data => {
        // team already exists
        if (data.results.length > 0) return true;

        // team does not exist yet
        return false;
      })
      .catch(() => {
        // TODO:- log to sentry
      });
  };

  // determine if team subdomain already exists
  service.doesDomainNameExistAlready = function (teamDomain) {
    const whereQuery = {domain: teamDomain};
    return teamNetworkService.getTeam(whereQuery, {})
      .then(data => {
        // team already exists
        if (data.results.length > 0) return true;

        // team does not exist yet
        return false;
      })
      .catch(() => {
        // TODO:- log to sentry
      });
  };

  // get current location and query all teams data
  service.getLocation = function () {
    // get location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(MainService.showPosition, MainService.showError, {
        timeout: 1000,
      });
    } else {
      return swal('Oops...', 'Please turn on location sharing for your web browser.');
    }
  };

  service.initTeamLocations = async (userView) => {
    const filters = {};
    if (userView) {
      filters.viewAs = userView;
    }
    const locations = (await teamNetworkService.getLocationsSummary(safeLocalStorage.currentUser.organization.id, filters)).data;
    service.clearTeamMemberMarkers();
    const map = MAN.getMap();
    locations.forEach(location => {
      if (!location.geoPoint) {
        return;
      }

      const
        [longitude, latitude] = location.geoPoint.coordinates;
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(latitude, longitude),
        map,
        title: location.user.username,
      });

      marker.addListener('click', () => {
        const infowindow = new google.maps.InfoWindow({
          content: `${location.user.username}<br/>${moment(location.createdAt).format('lll')}`,
        });
        infowindow.open(map, marker);
        if (MODEL.info_window) {
          MODEL.info_window.close();
        }
        MODEL.info_window = infowindow;
      });

      MODEL.teamMemberMarkers.push(marker);
      MAN.allMapContent.push(marker);
    });
  };

  // delete entire team
  service.deleteTeam = function () {
    // delete from teams table
    return teamNetworkService.deleteTeam(MODEL.TeamsService.teamId)
      .then(() => teamNetworkService.deleteTeamMembers(
        MODEL.org.id,
        MODEL.TeamsService.teamId,
        MODEL.TeamsService.allTeamMembers.map(({id}) => id),
      ))
      .then(() => {
        $('#modalbg').hide();
        $('#modal').hide();
        $('#deleteTeamBox').hide();
        setTimeout(() => {
          window.location.href = '#/';
        }, 200);
      })
      .catch(() => {
        // TODO:- log to sentry
        swal("We're sorry", 'There was a problem deleting this team. Please try again.', 'error');
      });
  };

  // show team map
  service.showTeamMap = (userView) => {
    const map = MAN.getMap();
    google.maps.event.addListenerOnce(map, 'idle', () => {
      google.maps.event.trigger(map, 'resize');
    });
    return service.initTeamLocations(userView);
  };

  // show team map
  service.showIndividualMap = function () {
    // init map
    const latlng = new google.maps.LatLng(33.397, -79);
    const mapOptions = {
      zoom: 4,
      center: latlng,
      disableDefaultUI: false,
    };
    // map
    MODEL.TeamsService.map = new google.maps.Map(document.getElementById('map'), mapOptions);

    // get current user location
    service.getLocation();
  };

  // show user's tracks
  service.showTracks = function (username, userId, startDate, endDate) {
    MAN.nukeMapContent();
    // show loading spinner
    MODEL.show.loader = true;

    // clear pins and line
    if (MODEL.TeamsService.markers.length > 0) {
      service.clearMarkers();
    }
    if (MODEL.teamMemberMarkers.length > 0) {
      service.clearTeamMemberMarkers();
    }
    if (MODEL.TeamsService.poly !== '') {
      MODEL.TeamsService.poly.setMap(null);
    }

    // new polyline
    MODEL.TeamsService.poly = new google.maps.Polyline({
      strokeColor: '#3063E4',
      strokeOpacity: 1.0,
      strokeWeight: 3,
    });
    MODEL.TeamsService.poly.setMap(MODEL.map);
    MAN.allMapContent.push(MODEL.TeamsService.poly);
    MODEL.TeamsService.bounds = new google.maps.LatLngBounds();

    // grab locations for this user
    const
      filters = {
        userId,
        createdAt: {
          $gte: startDate,
          $lte: endDate,
        },
      };
    const options = {
      limit: 100,
      order: '-createdAt',
    };

    return LocationNetworkService.getLocation(filters, options)
      .then(({data}) => {
        service.addLocationsToUserTracks(data, username, startDate, endDate);
      })
      .catch((error) => {
        MODEL.show.loader = false;
        console.error(error);
      });
  };

  service.addLocationsToUserTracks = (data, username, startDate, endDate) => {
    const locations = service.cleanLocationsData(data);

    locations.forEach(({geoPoint, createdAt}) => {
      const [longitude, latitude] = geoPoint.coordinates;
      service.addLatLng(new google.maps.LatLng(latitude, longitude), createdAt);
    });

    MODEL.show.loader = false;
    $rootScope.$apply();

    if (!MODEL.TeamsService.markers.length) {
      const duration = moment(endDate).diff(moment(startDate), 'days') || 1;
      return swal(
        'No activity logged', `No location logged in past ${duration} day${duration > 1 ? 's' : ''} for ${username}`,
        'warning',
      );
    }

    // now fit the map to the newly inclusive bounds
    MODEL.map.fitBounds(MODEL.TeamsService.bounds);
  };

  // Adds a new point to the Polyline.
  service.addLatLng = function (latLng, time) {
    const path = MODEL.TeamsService.poly.getPath();

    // Because path is an MVCArray, we can simply append a new coordinate
    // and it will automatically appear.
    path.push(latLng);

    // Add a new marker at the new plotted point on the polyline.
    const marker = new google.maps.Marker({
      position: latLng,
      title: `#${path.getLength()}`,
      map: MODEL.map,
    });

    // extend the bounds to include each marker's position
    MODEL.TeamsService.bounds.extend(marker.position);
    MODEL.map.fitBounds(MODEL.TeamsService.bounds);

    // show time on pop up
    google.maps.event.addListener(marker, 'click', function () {
      const infoWindow = new google.maps.InfoWindow();
      infoWindow.setContent(moment(time, 'YYYYMMDDTHHmmssZ').fromNow());
      infoWindow.open(MODEL.map, this);
      if (MODEL.info_window) {
        MODEL.info_window.close();
      }
      MODEL.info_window = infoWindow;
    });

    MODEL.TeamsService.markers.push(marker);
    MAN.allMapContent.push(marker);
  };

  // clear map markers
  service.clearMarkers = function () {
    for (let i = 0; i < MODEL.TeamsService.markers.length; i++) {
      MODEL.TeamsService.markers[i].setMap(null);
    }
    MODEL.TeamsService.markers = [];
  };

  // clear team member markers
  service.clearTeamMemberMarkers = function () {
    MODEL.teamMemberMarkers.forEach(marker => marker.setMap(null));
    MODEL.teamMemberMarkers = [];
  };

  // send push message to entire team
  service.sendTeamPush = function (id) {
    MODEL.show.loader = true;

    // check if sending to individual or team
    let individualId; let
      message;
    if (id) {
      individualId = id;
      message = $('#individualMessage').val();
    } else {
      individualId = '';
      message = MODEL.TeamsService.teamMessage;
    }

    // message must be long enough
    if (message.length > 3) {
      return teamNetworkService.sendTeamPush(MODEL.TeamsService.teamId, individualId, message)
        .then(() => {
          MODEL.show.loader = false;
          return swal('Message sent!', message, 'success');
        })
        .catch(() => {
          MODEL.show.loader = false;
          return swal('Uh-Oh!', 'There was a problem sending this message. Try again.', 'error');
        });
    }

    MODEL.show.loader = false;
    return swal('Message too short...', 'Type a longer message and try again.', 'error');
  };


  // bulk add team members
  service.bulkAddMembers = function (isManager = false) {
    return swal({
      title: 'Add Team Members',
      text: 'Enter email addresses below separated by commas. Each team member will receive an invite to join your team.',
      input: 'textarea',
      inputPlaceholder: 'Emails (e.g. email1, email2, email3)...',
      showCancelButton: false,
      confirmButtonText: 'Submit',
      showCloseButton: true,
      allowOutsideClick: false,
    })
      .then(emails => {
        // already paid for team --> must pay again before adding team members
        if (!emails.length) {
          return swal('Oops...', 'Add at least one email address before hitting submit.', 'warning');
        }
        // did not pay yet (on trial) --> can add all emails into team
        return service.addEmailListToTeam(MODEL.teamMemberEmailsArray, isManager);
      });
  };

  // add everyone in email list to team
  service.addEmailListToTeam = function (emailsArray) {
    $('#bulk-member-modal').hide();
    let subTeam = MODEL.TeamsService.selectedSubTeam;
    // remove invalid emails
    emailsArray = emailsArray.filter(email => helperService.validateEmail(email));

    // create new subTeam
    if (MODEL.TeamsService.createNewSubTeam) {
      subTeam = document.getElementById('newSubTeamName').value;
      service.createNewSubTeam(subTeam);
    }

    if (subTeam.length === 0) {
      return swal('Oops...', 'Please select a team for the user.', 'error')
        .then(() => {
          $('#bulk-member-modal').show();
        });
    }

    MODEL.show.loader = true;
    let userAddedCount = 0;
    emailsArray.forEach(email => {
      // get email entered
      email = email.toLowerCase();
      email = email.trim();
      // length of email address is ok and has an @
      if (email.length > 7 && email.indexOf('@') > -1) {
        // check if user already added to table for this team
        MODEL.show.loader = true;
        const whereQuery = {username: email, team: MODEL.team};
        return teamNetworkService.getTeamMembers(whereQuery)
          .then(data => {
            // user not on team yet --> safe to add
            if (!data.results.length && email !== safeLocalStorage.currentUser.username) {
              const postData = {
                username: email,
                team: MODEL.team,
                verified: false,
                sharedGroups: [],
                sharedRoutes: [],
                manager: false,
                accessRightsAccounts: {
                  canEdit: false, canDelete: false, canExport: false, canEditGroups: false,
                },
                accessRightsContacts: {
                  canEdit: false, canDelete: false, canExport: false, canEditGroups: false,
                },
                accessRightsDeals: {
                  canEdit: false, canDelete: false, canExport: false, canEditGroups: false,
                },
                accessRightsRoutes: {
                  canEdit: false, canDelete: false, canExport: false, canEditGroups: false,
                },
                subTeam,
              };

              postData.manager = false;

              // add user to new team members table
              return teamNetworkService.postTeamMember(postData)
                .then(() => {
                  // check if done adding users to team
                  userAddedCount++;
                  service.doneBulkAddingMembers(userAddedCount, emailsArray);

                  // send user added message && grab new objectid of user
                  const whereQuery = {username: email};
                  return teamNetworkService.getTeamMembers(whereQuery)
                    .then((data) => {
                      // send email
                      const verifyLink = `${service.baseAppUrl}#/verify?id=${data.results[0].objectId}`;
                      const subject = `You've been invited to join a Map My Customers team, ${MODEL.team}`;
                      const body = `Hello, <br /><br />You've been invited to join a Map My Customers team, ${MODEL.team}. <br /><br /><strong>Getting Started</strong><br />1. Visit the following link to sign up via the website (with this email address) and click 'Individual': ${service.baseAppUrl}#/register. <br />2. Next, click on this verification link after your account has been created: ${verifyLink}`;

                      return teamNetworkService.sendGenericEmail(email, subject, body);
                    });
                });
            }
            // check if done adding users to team
            userAddedCount++;
            service.doneBulkAddingMembers(userAddedCount, emailsArray);
          });
      }
      // check if done adding users to team
      userAddedCount++;
      service.doneBulkAddingMembers(userAddedCount, emailsArray);
    });
  };

  // done adding team members in bulk
  service.doneBulkAddingMembers = function (userAddedCount, emailsArray) {
    if (userAddedCount === emailsArray.length) {
      MODEL.show.loader = false;
      swal('Invitations sent', 'These team members have been notified via email to join your team.', 'success');
      MODEL.TeamsService.teamMembers = []; // refresh members
      MainService.grabMembers();
    }
  };

  // need to be removed at last -> deprecated
  // change selected member
  service.selectMember = function () {
    // show loading spinner
    MODEL.show.loader = true;

    // get user's shared groups
    const whereQuery = {
      objectId: MODEL.TeamsService.selectedMember,
    };
    return teamNetworkService.getTeamMembers(whereQuery, {})
      .then(data => {
        data = data.results;
        // remove member from table and close modal
        MODEL.TeamsService.selectedMemberSharedGroups = data.sharedGroups;
        MODEL.TeamsService.selectedMemberSharedRoutes = data.sharedRoutes;
        MODEL.TeamsService.selectedMemberSharedAccounts = data.sharedAccounts;
        MODEL.TeamsService.selectedMemberSharedDeals = data.sharedDeals;
        MODEL.TeamsService.selectedMemberEmail = data.username;
        MODEL.TeamsService.selectedMemberAccessRightsContacts = service.getAccessRightsForCrmObject(data.accessRightsContacts);
        MODEL.TeamsService.selectedMemberAccessRightsAccounts = service.getAccessRightsForCrmObject(data.accessRightsAccounts);
        MODEL.TeamsService.selectedMemberAccessRightsDeals = service.getAccessRightsForCrmObject(data.accessRightsDeals);
        MODEL.TeamsService.selectedMemberAccessRightsRoutes = service.getAccessRightsForCrmObject(data.accessRightsRoutes);
        MODEL.show.loader = false;
        // set sharedGroup values to default
        MODEL.TeamsService.toggledSharedGroups = false;
        MODEL.TeamsService.currentSharedGroups = [];
      })
      .catch(() => {
        MODEL.show.loader = false;
        return swal('Oh No!', 'There was a problem sharing this group. Please try again.', 'error');
      });
  };

  service.refreshAccessRightsForSelectedMember = async () => {
    const accessRights = userNetworkService.getAccessRights(MODEL.org.id, MODEL.TeamsService.selectedMember);
    const permissions = userNetworkService.getUserPermissions(MODEL.TeamsService.selectedMemberRoleId);
    const promises = await Promise.all([accessRights, permissions]);
    MODEL.TeamsService.selectedMemberAccessRights = promises[0].data;
    MODEL.TeamsService.selectedMemberPermissions = promises[1].data;
  };

  // save selected member's access rights
  service.saveAccessRights = async function (type, accessRightKey) {
    const accessRight = MODEL.TeamsService.selectedMemberAccessRights.find(accessRight => accessRight.permission.name === accessRightKey);

    MODEL.show.loader = true;
    const userId = MODEL.TeamsService.selectedMember;

    // has access right ->remove it
    if (accessRight) {
      await userNetworkService.deleteAcessRight(MODEL.org.id, userId, accessRight.id);
      MODEL.TeamsService.selectedMemberAccessRights = MODEL.TeamsService.selectedMemberAccessRights.filter(accessRight => accessRight.permission.name !== accessRightKey);
    } else {
      // does not have access right ->add it

      const permission = MODEL.TeamsService.selectedMemberPermissions.find(permission => permission.name === accessRightKey);

      const payload = {permission, type};
      const accessRight = await userNetworkService.createAccessRight(MODEL.org.id, userId, payload);
      accessRight.permission = permission;
      MODEL.TeamsService.selectedMemberAccessRights.push(accessRight);
    }
    window.refreshDom({loader: false}, 'show');
  };

  // toggle shared group
  service.toggleSharedPins = function (group) {
    MODEL.TeamsService.toggledSharedGroups = true;

    // update shared groups
    const position = $.inArray(group, MODEL.TeamsService.selectedMemberSharedGroups);
    if (position >= 0) {
      MODEL.TeamsService.selectedMemberSharedGroups.splice(position, 1);
      // check for multiple toggle of same
      const pos = $.inArray(group, MODEL.TeamsService.currentSharedGroups);
      if (pos >= 0) {
        MODEL.TeamsService.currentSharedGroups.splice(pos, 1);
      }
    } else {
      MODEL.TeamsService.currentSharedGroups.push(group);
      MODEL.TeamsService.selectedMemberSharedGroups.push(group);
    }
    // update shared pin
    return service.updateSharedGroup();
  };

  service.toggleSharedAccountsAndDeals = (option) => {
    let selectedValues;

    if (option === 'account') {
      selectedValues = $('[name="sharedAccounts"]:checked').map(function () {
        return this.value;
      }).get();
    }

    if (option === 'deal') {
      selectedValues = $('[name="sharedAccounts"]:checked').map(function () {
        return this.value;
      }).get();
    }

    MODEL.TeamsService.teamMembers.forEach((member, index, arr) => {
      if (member.objectId === MODEL.TeamsService.selectedMember) {
        if (option === 'account') {
          arr[index].sharedAccounts = selectedValues;
          MODEL.TeamsService.selectedMemberSharedAccounts = selectedValues;
        }

        if (option === 'deal') {
          arr[index].sharedDeals = selectedValues;
          MODEL.TeamsService.selectedMemberSharedDeals = selectedValues;
        }
      }
    });

    service.updateSharedAccount(option);
  };


  // update groups that are shared for team member
  service.updateSharedAccount = function (option) {
    // update db
    if (option === 'account') {
      const putData = {sharedAccounts: MODEL.TeamsService.selectedMemberSharedAccounts};
      return teamNetworkService.putTeamMember(MODEL.TeamsService.selectedMember, putData)
        .then(() => { })
        .catch((error) => {
          helperService.showAndLogError(error);
        });
    }

    if (option === 'deal') {
      const putData = {sharedDeals: MODEL.TeamsService.selectedMemberSharedDeals};
      return teamNetworkService.putTeamMember(MODEL.TeamsService.selectedMember, putData)
        .then(() => { })
        .catch((error) => {
          helperService.showAndLogError(error);
        });
    }
  };

  // update groups that are shared for team member
  service.updateSharedGroup = function () {
    // update db
    const putData = {sharedGroups: MODEL.TeamsService.selectedMemberSharedGroups};
    return teamNetworkService.updateTeamMember(MODEL.TeamsService.selectedMember, putData)
      .then(() => {
        // send email notifying team member of new shared group / groups
        if (MODEL.sendTeamNotifications) {
          // At least one groups should be shared sending a notification
          if (MODEL.TeamsService.currentSharedGroups.length > 0) {
            // create the message for the email
            let groups = '';
            for (let i = 0; i < MODEL.TeamsService.currentSharedGroups.length; i++) {
              if (i < MODEL.TeamsService.currentSharedGroups.length - 1) {
                groups = `${groups}"${MODEL.TeamsService.currentSharedGroups[i]}", `;
              } else {
                groups = `${groups}"${MODEL.TeamsService.currentSharedGroups[i]}"`;
              }
            }
            // set the shared groups variable back to default
            MODEL.TeamsService.currentSharedGroups = [];
            MODEL.TeamsService.toggledSharedGroups = false;

            const subject = 'Someone on your Map My Customers team has shared the group of pins with you';
            const body = `Someone on your Map My Customers team has shared the group of pins ${groups} with you. These pins will now show up on your Map My Customers account.`;
            return teamNetworkService.sendGenericEmail(MODEL.TeamsService.selectedMemberEmail, subject, body);
          }
        }
      })
      .catch(() => { });
  };

  // toggle shared group
  service.toggleSharedRoute = function (route) {
    MODEL.TeamsService.toggledSharedRoutes = true;

    // update shared groups
    const position = $.inArray(route, MODEL.TeamsService.selectedMemberSharedRoutes);
    if (position >= 0) {
      MODEL.TeamsService.selectedMemberSharedRoutes.splice(position, 1);
      // check for multiple toggle of same
      const pos = $.inArray(route, MODEL.TeamsService.currentSharedRoutes);
      if (pos >= 0) {
        MODEL.TeamsService.currentSharedRoutes.splice(pos, 1);
      }
    } else {
      MODEL.TeamsService.currentSharedRoutes.push(route);
      MODEL.TeamsService.selectedMemberSharedRoutes.push(route);
    }

    // update shared routes
    return service.updateSharedRoute();
  };

  service.updateSharedRoute = function () {
    // update db
    const putData = {sharedRoutes: MODEL.TeamsService.selectedMemberSharedRoutes};

    return teamNetworkService.updateTeamMember(MODEL.TeamsService.selectedMember, putData)
      .then(() => {
        // send email notifying team member of new shared group
        if (MODEL.sendTeamNotifications) {
          if (MODEL.TeamsService.currentSharedRoutes.length > 0) {
            // create the message for the email
            let routes = '';
            for (let i = 0; i < MODEL.TeamsService.currentSharedRoutes.length; i++) {
              if (i < MODEL.TeamsService.currentSharedRoutes.length - 1) {
                routes = `${routes}"${MODEL.TeamsService.currentSharedRoutes[i]}", `;
              } else {
                routes = `${routes}"${MODEL.TeamsService.currentSharedRoutes[i]}"`;
              }
            }
            // set the shared groups variable back to default
            MODEL.TeamsService.currentSharedRoutes = [];
            MODEL.TeamsService.toggledSharedRoute = false;
            const subject = 'Someone on your Map My Customers team has shared route with you';
            const body = `Someone on your Map My Customers team has shared the routes ${routes} with you. These routes will now show up on your Map My Customers account.`;
            return teamNetworkService.sendGenericEmail(MODEL.TeamsService.selectedMemberEmail, subject, body);
          }
        }
      })
      .catch(() => { });
  };

  // remove team member
  service.removeMember = function (member, teamId) {
    const user = MODEL.TeamsService.subTeamUsers.find(mem => (mem.username === member && mem.teamId === teamId));
    // delete selected member from team members table
    teamNetworkService.deleteTeamMembers(MODEL.org.id, teamId, [user.id])
      .catch((error) => {
        swal('Uh-Oh', "We weren't able to delete this team member. Please try again.", 'error');
        throw error;
      });
  };

  /**
     * clean the locations data to include the last log location at the same position
     * @param {{createdAt: string, geoPoint: {coordinates: number[]}}[]} locations
     * @return {{createdAt: string, geoPoint: {coordinates: number[]}}[]}
     */
  service.cleanLocationsData = (locations) => locations.filter((location, index, locations) => {
    if (index === locations.length - 1) {
      return true; // always take the most recent location as is
    }
    const [longitude, latitude] = location.geoPoint.coordinates;
    const [nextLongitude, nextLatitude] = locations[index + 1].geoPoint.coordinates;
    return latitude !== nextLatitude || longitude !== nextLongitude; // add location if it's not like the newer one
  });

  service.highlightShareOption = (option) => {
    $('#accountsShareOpt').removeClass('inactiveBtn').removeClass('activeBtn').addClass('inactiveBtn');
    $('#contactsShareOpt').removeClass('inactiveBtn').removeClass('activeBtn').addClass('inactiveBtn');
    $('#dealsShareOpt').removeClass('inactiveBtn').removeClass('activeBtn').addClass('inactiveBtn');
    $('#accountRoutesShareOpt').removeClass('inactiveBtn').removeClass('activeBtn').addClass('inactiveBtn');
    $('#contactRoutesShareOpt').removeClass('inactiveBtn').removeClass('activeBtn').addClass('inactiveBtn');
    $('#territoriesShareOpt').removeClass('inactiveBtn').removeClass('activeBtn').addClass('inactiveBtn');

    $(`#${option}ShareOpt`).removeClass('inactiveBtn').addClass('activeBtn');
  };

  service.highlightAccessRightsOption = (option) => {
    $('#accountsAccessRightsOpt').removeClass('inactiveBtn').removeClass('activeBtn').addClass('inactiveBtn');
    $('#contactsAccessRightsOpt').removeClass('inactiveBtn').removeClass('activeBtn').addClass('inactiveBtn');
    $('#dealsAccessRightsOpt').removeClass('inactiveBtn').removeClass('activeBtn').addClass('inactiveBtn');
    $('#routesAccessRightsOpt').removeClass('inactiveBtn').removeClass('activeBtn').addClass('inactiveBtn');
    $('#accountRoutesAccessRightsOpt').removeClass('inactiveBtn').removeClass('activeBtn').addClass('inactiveBtn');
    $('#contactRoutesAccessRightsOpt').removeClass('inactiveBtn').removeClass('activeBtn').addClass('inactiveBtn');
    $('#territoriesAccessRightsOpt').removeClass('inactiveBtn').removeClass('activeBtn').addClass('inactiveBtn');

    $(`#${option}AccessRightsOpt`).removeClass('inactiveBtn').addClass('activeBtn');
  };

  service.addNewMember = (type) => {
    MODEL.TeamsService.addMemberDialogType = type;
    integrationNetworkService.list().then(response => {
      window.refreshDom({'TeamsService.addMemberDialogHasIntegration': response.data.length > 0});
    });
    // add individual members
    if (type === 'individual') {
      service.modalsActions.showModal('addNewMemberModal');
    } else if (type === 'bulk') {
      // add bulk members
      $('#bulk-member-modal').show();
    }
  };

  service.resendTeamMemberInvite = (user) => userNetworkService.resendTeamMemberInvite(MODEL.org.id, user);

  // add a new team member
  service.finishAddingNewTeamMember = async function (makeManager, makeOwner = false) {
    // get email entered
    let email = document.getElementById('newMemberEmail').value;
    let subTeam = MODEL.TeamsService.selectedSubTeam;
    if (MODEL.TeamsService.createNewSubTeam) {
      subTeam = document.getElementById('newSubTeamName').value.trim();
      if (subTeam.length === 0) {
        return swal('Oops...', 'Please add a team name.', 'error').then(() => {
          service.modalsActions.showModal('addNewMemberModal');
        });
      }
    }

    const orgId = safeLocalStorage.currentUser.organization.id;

    email = email.toLowerCase().trim();

    service.modalsActions.hideModal('addNewMemberModal');

    // length of email address if ok and has an @
    if (!helperService.validateEmail(email)) {
      return swal('Oops...', 'Please enter a valid email address.', 'error')
        .then(() => {
          service.modalsActions.showModal('addNewMemberModal');
        });
    }

    if (subTeam.length === 0 && !makeOwner) {
      return swal('Oops...', 'Please select a team for the user.', 'error')
        .then(() => {
          service.modalsActions.showModal('addNewMemberModal');
        });
    }
    let teamId = null;
    const allUsers = await userNetworkService.getAllUsers(orgId);
    let user = allUsers.data.find(user => user.username === email);
    if (user) {
      const assignableUsers = await userNetworkService.getUnassignedUsers(orgId);
      if (!assignableUsers.data.some(({username}) => username === email)) {
        return swal('Ooops...', 'Username already exists.', 'error')
          .then(() => {
            service.modalsActions.showModal('addNewMemberModal');
          });
      }
    } else {
      const payload = DefaultDataService.getUser(email, makeManager, makeOwner);
      user = await userNetworkService.createUser(orgId, payload);
      if (user.code) {
        return DefaultDataService.getErrorSwal(user)
          .then(() => {
            service.modalsActions.showModal('addNewMemberModal');
          });
      }
    }

    if (MODEL.TeamsService.createNewSubTeam) {
      // create team
      const postData = DefaultDataService.getTeam(subTeam);
      const newTeam = await teamNetworkService.postTeam(orgId, postData);
      if (newTeam.code) {
        return DefaultDataService.getErrorSwal(newTeam)
          .then(() => {
            service.modalsActions.showModal('addNewMemberModal');
          });
      }
      MODEL.TeamsService.subTeams.push(newTeam);
      teamId = newTeam.id;
    } else {
      const team = MODEL.TeamsService.subTeams.find(team => team.name === MODEL.TeamsService.selectedSubTeam);
      if (team) {
        teamId = team.id;
      }
    }
    if (teamId) {
      user.teamId = teamId;
      await userNetworkService.createTeamMembers(orgId, teamId, [user.id]);
      MODEL.TeamsService.subTeamUsers.push(user);
    }
    await service.calculateTeams();

    user.role = DefaultDataService.getRole(makeManager);
    MODEL.TeamsService.teamStructure = service.createTeamStructure(MODEL.TeamsService.subTeams, MODEL.TeamsService.subTeamUsers, MODEL.owner);
    MODEL.TeamsService.verifiedTeamMembers = service.getVerifiedTeamMembers(MODEL.TeamsService.subTeamUsers);
  };

  service.createNewSubTeam = (subTeam) => {
    const subTeams = [];
    MODEL.TeamsService.teamStructure.subTeams.forEach((subTeamName) => {
      subTeams.push(subTeamName.name);
    });
    // add the new team name
    subTeams.push(subTeam);

    const putData = {subTeams};
    return teamNetworkService.putTeam(MODEL.TeamsService.teamId, putData);
  };

  // complete bulk team members addition to the table
  service.finishBulkTeamMembers = async function (members) {
    members = [...new Set(members)];
    $('#bulk-member-modal').hide();
    members = members.filter(email => helperService.validateEmail(email));

    // add as team member if email repeats in member and manager array

    const allUsers = await userNetworkService.getAllUsers(safeLocalStorage.currentUser.organization.id);
    const teamUsernames = members;
    const usernames = allUsers.data.map(user => user.username);

    // intersection of two array
    const intersectionArr = teamUsernames.filter(value => usernames.indexOf(value) !== -1);
    if (intersectionArr.length > 0) {
      swal('Ooops...', `Usernames already exist: ${intersectionArr.join(', ')}`, 'error')
        .then(() => {
          service.addNewMember(MODEL.TeamsService.addMemberDialogType);
        });
      return Promise.reject();
    }

    // already paid for team --> must pay again before adding team members
    if (members.length === 0) {
      swal('Oops...', 'Add at least one valid email address before hitting submit.', 'warning')
        .then(() => {
          service.addNewMember(MODEL.TeamsService.addMemberDialogType);
        });
      return Promise.reject();
    }

    let teamId = null;
    // did not pay yet (on trial) --> can add all emails into team
    if (MODEL.TeamsService.createNewSubTeam) {
      const newTeamName = (MODEL.TeamsService.newTeamName || '').trim();
      if (!newTeamName.length) {
        swal('Oops...', 'Please add a team name.', 'error')
          .then(() => {
            service.addNewMember(MODEL.TeamsService.addMemberDialogType);
          });
        return Promise.reject();
      }
      // create team member
      const postData = DefaultDataService.getTeam(newTeamName);
      const newTeam = await teamNetworkService.postTeam(MODEL.org.id, postData);
      if (newTeam.code) {
        DefaultDataService.getErrorSwal(newTeam)
          .then(() => {
            service.addNewMember(MODEL.TeamsService.addMemberDialogType);
          });
        return Promise.reject();
      }
      MODEL.TeamsService.subTeams.push(newTeam);
      teamId = newTeam.id;
    } else {
      if (!MODEL.TeamsService.selectedSubTeam.trim()) {
        swal('Oops...', 'Team name cannot be empty', 'error')
          .then(() => {
            service.addNewMember(MODEL.TeamsService.addMemberDialogType);
          });
        return Promise.reject();
      }
      const team = MODEL.TeamsService.subTeams.find(team => team.name === MODEL.TeamsService.selectedSubTeam);
      if (team) {
        teamId = team.id;
      }
    }

    const withEmail = (email, p) => p.then(result => ({result, email}), result => ({result, email}));
    const membersProm = members.map(email => {
      const payload = DefaultDataService.getUser(email, false);
      return withEmail(email, userNetworkService.createUser(MODEL.org.id, payload));
    });

    const response = await Promise.all(membersProm);
    const failedUsers = response.filter(({result}) => !result.id).map(({email}) => email);
    if (failedUsers.length < response.length) {
      if (teamId) {
        const userIds = [];
        response.filter(({result}) => !!result.id).forEach(({result}) => {
          userIds.push(result.id);
          result.role = DefaultDataService.getRole();
          result.teamId = teamId;
          MODEL.TeamsService.subTeamUsers.push(result);
        });
        await userNetworkService.createTeamMembers(MODEL.org.id, teamId, userIds);
      }
      MODEL.TeamsService.teamStructure = service.createTeamStructure(MODEL.TeamsService.subTeams, MODEL.TeamsService.subTeamUsers, MODEL.owner);
      MODEL.TeamsService.verifiedTeamMembers = service.getVerifiedTeamMembers(MODEL.TeamsService.subTeamUsers);
      await service.calculateTeams();
      let message = 'Team members added successfully.';
      if (failedUsers.length > 0) {
        message += ` Except these, which were ignored due to errors: ${failedUsers.join(', ')}`;
      }
      return swal('success', message, 'success');
    }
    return swal('Uh-oh', 'Failed to add these users, please try again later', 'error');
  };

  service.showBenchedMembers = async function () {
    const response = await userNetworkService.getUnassignedUsers(MODEL.org.id);
    // filter out manager if exists already in subTeam
    MODEL.TeamsService.benchedMembers = response.data.filter(user => !MODEL.TeamsService.selectedSubTeam.teamManagersNames.includes(user.username));
    window.refreshDom({benchedMembers: MODEL.TeamsService.benchedMembers}, 'TeamsService');
  };

  // change the subteam for the member
  service.changeSubTeamForTheMember = (member) => {
    const selectedMemberObjectId = MODEL.TeamsService.teamMembers
      .find(teamMember => teamMember.username === member).id;

    // remove if the data is already present
    const changedSubTeam = find(MODEL.TeamsService.changedSubTeamsMembers, {id: selectedMemberObjectId});
    const index = MODEL.TeamsService.changedSubTeamsMembers.indexOf(changedSubTeam);
    if (index >= 0) {
      MODEL.TeamsService.changedSubTeamsMembers.splice(index, 1);
    }

    if (MODEL.TeamsService.selectedSubTeam.teamManagersNames.indexOf(member) >= 0
      || MODEL.TeamsService.selectedSubTeam.teamMembersNames.indexOf(member) >= 0
    ) {
      if (MODEL.TeamsService.selectedSubTeam.teamManagersNames.indexOf(member) >= 0) {
        // remove member
        const index = MODEL.TeamsService.selectedSubTeam.teamManagersNames.indexOf(member);
        MODEL.TeamsService.selectedSubTeam.teamManagersNames.splice(index, 1);
      }

      if (MODEL.TeamsService.selectedSubTeam.teamMembersNames.indexOf(member) >= 0) {
        // remove member
        const index = MODEL.TeamsService.selectedSubTeam.teamMembersNames.indexOf(member);
        MODEL.TeamsService.selectedSubTeam.teamMembersNames.splice(index, 1);
      }

      MODEL.TeamsService.changedSubTeamsMembers.push({id: selectedMemberObjectId, subTeam: ''});
    } else {
      MODEL.TeamsService.selectedSubTeam.teamMembersNames.push(member);
      MODEL.TeamsService.changedSubTeamsMembers.push({
        id: selectedMemberObjectId,
        subTeam: MODEL.TeamsService.selectedSubTeam.name,
      });
    }
  };

  // finish the sub teams changes to the parse
  service.finishChangeSubTeamForTheMembers = async function (selectedBenchedMem) {
    this.modalsActions.hideModal('addExistingMembersModal');
    // create user team
    await userNetworkService.createTeamMembers(MODEL.org.id, MODEL.TeamsService.selectedSubTeam.teamId, selectedBenchedMem);
    const newTeamUsers = MODEL.TeamsService.benchedMembers.reduce((curr, user) => {
      if (selectedBenchedMem.includes(user.id)) {
        user.teamId = MODEL.TeamsService.selectedSubTeam.teamId;
        curr.push(user);
      }
      return curr;
    }, []);
    MODEL.TeamsService.subTeamUsers = MODEL.TeamsService.subTeamUsers.concat(newTeamUsers);

    service.calculateUsersWithoutTeam();
    MODEL.TeamsService.teamStructure = service.createTeamStructure(MODEL.TeamsService.subTeams, MODEL.TeamsService.subTeamUsers, MODEL.owner);
    MODEL.TeamsService.verifiedTeamMembers = service.getVerifiedTeamMembers(MODEL.TeamsService.subTeamUsers);
    window.refreshDom({teamStructure: MODEL.TeamsService.teamStructure}, 'TeamsService');
    window.refreshDom({verifiedTeamMembers: MODEL.TeamsService.verifiedTeamMembers}, 'TeamsService');
  };

  // change the member type for the member
  service.changeMemberType = async function (username, isManager) {
    let user = MODEL.TeamsService.subTeamUsers.filter(user => user.username === username);
    if (user.length > 1 && !isManager) {
      return swal('Uh-Oh', 'You cannot make an existing manager of another team a team member of an additional team.', 'error');
    }

    user = user[0];
    if (isManager) {
      const manager = MODEL.roles.find(role => role.key === 'MANAGER');
      user.role = {
        id: manager.id,
        name: 'Team Manager',
      };
    } else {
      const member = MODEL.roles.find(role => role.key === 'MEMBER');
      user.role = {
        id: member.id,
        name: 'Team Member',
      };
    }
    await userNetworkService.updateUser(MODEL.org.id, user.id, user);
    MODEL.TeamsService.selectedSubTeamMemberType = MODEL.TeamsService.selectedSubTeamMemberTypes.map(mem => {
      if (mem.name === username) {
        mem.isManager = isManager;
      }
      return mem;
    });

    MODEL.TeamsService.subTeamUsers = MODEL.TeamsService.subTeamUsers.map(rec => {
      if (rec.username === user.username) {
        rec.role = user.role;
      }
      return rec;
    });
    MODEL.TeamsService.teamStructure = service.createTeamStructure(MODEL.TeamsService.subTeams, MODEL.TeamsService.subTeamUsers, MODEL.owner);
    MODEL.TeamsService.selectedSubTeam = MODEL.TeamsService.teamStructure.subTeams.find(subTeam => MODEL.TeamsService.selectedSubTeam.teamId === subTeam.teamId);
  };

  // finish manage members
  service.finishManageMembers = () => {
    this.modalsActions.hideModal('manageMembersModal');

    MODEL.TeamsService.selectedSubTeam.teamManagersNames = [];
    MODEL.TeamsService.selectedSubTeam.teamMembersNames = [];
  };

  service.manageMembers = async function (subTeam) {
    await service.getOrgDetails();
    subTeam = MODEL.TeamsService.teamStructure.subTeams.find(st => st.teamId === subTeam.teamId);
    MODEL.TeamsService.selectedSubTeamMemberTypes = [];
    MODEL.TeamsService.selectedSubTeam = subTeam;
    subTeam.teamManagersNames.forEach((teamManagersName) => {
      MODEL.TeamsService.selectedSubTeamMemberTypes.push({name: teamManagersName, isManager: true});
    });
    subTeam.teamMembersNames.forEach((teamMembersName) => {
      MODEL.TeamsService.selectedSubTeamMemberTypes.push({name: teamMembersName, isManager: false});
    });
    window.refreshDom({selectedSubTeamMemberTypes: MODEL.TeamsService.selectedSubTeamMemberTypes}, 'TeamsService');
  };

  service.removeTeamMember = async function (member) {
    await swal({
      title: 'Are you sure?',
      text: 'The team member will be removed from the team!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, remove this member',
      closeOnConfirm: false,
    });
    const user = MODEL.TeamsService.subTeamUsers.find(mem => (mem.username === member.name && mem.teamId === MODEL.TeamsService.selectedSubTeam.teamId));
    await userNetworkService.deleteTeamMembers(MODEL.org.id, user.teamId, [user.id]);
    MODEL.TeamsService.subTeamUsers = MODEL.TeamsService.subTeamUsers.filter(mem => !(mem.username === member.name && mem.teamId === MODEL.TeamsService.selectedSubTeam.teamId));
    MODEL.TeamsService.teamStructure = service.createTeamStructure(MODEL.TeamsService.subTeams, MODEL.TeamsService.subTeamUsers, MODEL.owner);
    const selectedSubTeam = MODEL.TeamsService.teamStructure.subTeams.find(team => team.teamId === user.teamId);
    service.manageMembers(selectedSubTeam);
  };

  // change sub team name
  service.changeSubTeamName = async function (teamId) {
    const newSubTeamName = document.getElementById(`subTeam${teamId}`).value.trim();

    const payload = {
      id: teamId,
      name: newSubTeamName,
    };

    const updateTeam = await teamNetworkService.putTeam(MODEL.org.id, teamId, payload);
    if (updateTeam.code) {
      return DefaultDataService.getErrorSwal(updateTeam);
    }
    const teams = await teamNetworkService.getTeams(MODEL.org.id);
    MODEL.TeamsService.subTeams = teams.data;
    MODEL.TeamsService.teamStructure = service.createTeamStructure(MODEL.TeamsService.subTeams, MODEL.TeamsService.subTeamUsers, MODEL.owner);
    return swal('Success', 'Team name changed successfully', 'success');
  };

  service.getAccessRightsForCrmObject = () => {

  };

  service.setTeamLogo = () => {
    // set logo (if one is uploaded)
    if (safeLocalStorage.currentUser.logo && safeLocalStorage.currentUser.logo !== '') {
      $('#logoImage').attr('src', `//mapmycustomers.com/app/${safeLocalStorage.currentUser.logo}`);
      $('#settingsLogoImage').attr('src', `//mapmycustomers.com/app/${safeLocalStorage.currentUser.logo}`);
      $('.custom-logo-image-mobile').attr('src', `mapmycustomers.com/app/${safeLocalStorage.currentUser.logo}`);
    } else {
      $('#logoImage').attr('src', 'images/logo.png');
      $('#settingsLogoImage').attr('src', 'images/logo.png');
      $('.custom-logo-image-mobile').attr('src', 'images/logo.png');
    }

    // set profile photo
    if (safeLocalStorage.currentUser.profilePhoto && safeLocalStorage.currentUser.profilePhoto !== '//mapmycustomers.com/app/images/default_profile.png') {
      $('#profilePhoto').attr('src', `//mapmycustomers.com/app/${safeLocalStorage.currentUser.profilePhoto}`);
      $('#profilePhoto2').attr('src', `//mapmycustomers.com/app/${safeLocalStorage.currentUser.profilePhoto}`);
    } else {
      $('#profilePhoto').attr('src', '//mapmycustomers.com/app/images/default_profile.png');
      $('#profilePhoto2').attr('src', '//mapmycustomers.com/app/images/default_profile.png');
    }
  };

  service.getAccessRight = (accessRightKey) => MODEL.TeamsService.selectedMemberAccessRights
    .find(accessRight => accessRight.permission.name === accessRightKey);

  return service;
}

TeamService.$inject = [
  '$rootScope', '$route', 'MainService',
  'LocationNetworkService', 'DefaultDataService',
];
