import React, {useCallback, useEffect, useState} from 'react';
import AutoComplete from 'antd/es/auto-complete';
import Input from 'antd/es/input';
import {doGlobalSearch} from '../../store/actions';
import TextSearchResult from '../../../../type/TextSearchResult';
import isSearchTextValid from './util/isSearchTextValid';
import useAutocompleteOptions from './util/useAutocompleteOptions';
import TextSearchNetworkService from '../../../../../network-services/textsearch-network-service';
import styles from './GlobalSearch.module.scss';
import FontAwesomeIcon from '../FontAwesomeIcon';
import analyticsService from '../../../../../shared-services/analytics-service';

const isValidSearchText = (searchText: string) => searchText.trim().length >= 3;

interface Props {
  fetchSearchResults: typeof doGlobalSearch.request;
  loading: boolean;
  searchResults: TextSearchResult[];
}

const GlobalSearch: React.FC<Props> = ({fetchSearchResults, loading, searchResults}) => {
  const [searchText, setSearchText] = useState<string>('');
  const handleSearch = useCallback((value: string) => setSearchText(value), [setSearchText]);

  useEffect(() => {
    if (isSearchTextValid(searchText)) {
      fetchSearchResults(searchText);
    }
  }, [fetchSearchResults, searchText]);

  const options = useAutocompleteOptions(loading, searchResults, searchText);
  const handleSearchClick = () => analyticsService.initiated('Global search');

  return (
    <AutoComplete
      className={styles.container}
      dropdownClassName={styles.dropdown}
      dropdownMatchSelectWidth={false}
      onSearch={handleSearch}
      options={options}
      value={searchText}
    >
      <Input
        prefix={<FontAwesomeIcon className={styles.searchIcon} type="far" icon="fa-search" />}
        size="large"
        placeholder="Search..."
        onClick={handleSearchClick}
      />
    </AutoComplete>
  );
};

const GlobalSearchWrapper = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState([]);
  const fetchSearchResults = useCallback(async (searchText: string) => {
    if (isValidSearchText(searchText)) {
      setLoading(true);

      try {
        const response = await TextSearchNetworkService.findEntities(searchText);
        setSearchResults(response.data);
      } catch (e) {
        setSearchResults([]);
      }

      setLoading(false);
    } else {
      setSearchResults([]);
    }
  }, [setLoading, setSearchResults]);

  return (
    <GlobalSearch
      fetchSearchResults={fetchSearchResults}
      loading={loading}
      searchResults={searchResults}
    />
  );
};

export default GlobalSearchWrapper;
