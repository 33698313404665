import {useMemo} from 'react';
import deepmerge from 'deepmerge';
import ListResponse from '../../../../type/service/ListResponse';
import Activity from '../../../../type/Activity';
import ActivityType from '../../../../type/ActivityType';

const useActivityTypesChartConfig = (
  defaultConfig: object,
  data: ListResponse<Activity> | undefined,
  activityTypes: ActivityType[],
) =>
  useMemo(
    () => {
      const activityCountByActivityTypeName = (data?.data || []).reduce<{[key: string]: number}>(
        (result, activity) => Object.assign(result, {[activity.crmActivityType.name]: (result[activity.crmActivityType.name] || 0) + 1}),
        {},
      );
      const filteredActivityTypeNames = Object.keys(activityCountByActivityTypeName);

      return deepmerge.all([
        {},
        defaultConfig,
        {
          xAxis: {
            categories: activityTypes.map(({name}) => name)
              .filter(name => filteredActivityTypeNames.includes(name)),
          },
          series: [{
            name: 'Activity Types',
            colorByPoint: true,
            data: Object.entries(activityCountByActivityTypeName),
          }],
        },
      ]);
    },
    [data],
  );

export default useActivityTypesChartConfig;
