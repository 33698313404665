import styled from 'styled-components';
import {maxWidth} from '../../../style/media';

const BaseCard = styled.div<{span?: number}>`
  max-width: calc(100% / 3 * ${({span = 1}) => span} - 30px - 1px); // -1px to avoid rounding issues
  flex: 0 0 calc(100% / 3 * ${({span = 1}) => span} - 30px - 1px); // -1px to avoid rounding issues
  margin: 0 15px 30px;
  height: 400px;
  box-shadow: 0 2px 10px #ccc;
  
  ${maxWidth.desktop`
    max-width: calc(${({span = 1}) => span === 1 ? '50%' : '100%'} - 30px);
    flex-basis: calc(${({span = 1}) => span === 1 ? '50%' : '100%'} - 30px);
  `};
  ${maxWidth.tablet`
    max-width: calc(100% - 30px);
    flex-basis: calc(100% - 30px);
    height: 400px;
  `};
`;

export default BaseCard;
