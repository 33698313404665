import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {Button, Col, Row, Steps} from 'antd';
import PageContainer from './components/PageContainer';
import Header from '../../components/Header/Header';
import useDataLoader from '../../hook/useDataLoader';
import useInitializer from '../../hook/useInitializer';
import LoadingProgress from '../../components/LoadingProgress/LoadingProgress';
import Step1 from './components/steps/Step1';
import Step2 from './components/steps/Step2';
import Step3 from './components/steps/Step3';
import Step4 from './components/steps/Step4';
import {RootState} from '../../../store/rootReducer';
import {initializeEditPage} from '../../../store/integrations/actions';
import {getCurrentIntegration, getLoading} from '../../../store/integrations';
import Integration from '../../type/integration/Integration';
import withStore from '../../hoc/withStore';
import {Step, steps} from './components/steps';
import SUPPORTED_SERVICES from '../../../store/integrations/supported-services';
import safeLocalStorage from '../../../shared-services/safe-local-storage-service';
import userNetworkService from '../../../network-services/user-network-service';
import ListResponse from '../../type/service/ListResponse';
import User from '../../type/User';

const EditSteps = styled(Steps)`
  margin-bottom: 2rem;
`;

const StepList = styled.div`
  margin-bottom: 2rem;
`;

interface Props {
  currentIntegration?: Integration,
  initializeEditPage: typeof initializeEditPage,
  integrationId: Integration['id'],
  loading: boolean,
}

const EditIntegration: React.FC<Props> = ({
  currentIntegration,
  initializeEditPage,
  integrationId,
  loading,
}) => {
  const handleCancelClick = useCallback(() => { window.location.hash = '#/integrations'; }, []);
  const serviceDescription = useMemo(
    () => SUPPORTED_SERVICES.find(({type}) => type === currentIntegration?.type),
    [currentIntegration?.type],
  );

  const [currentStep, setStep] = useState(Step.CHOOSE);
  const handleNextStep = useCallback(() => setStep((step: number) => (step < Step.FINISH ? step + 1 : step)), [setStep]);
  const handlePreviousStep = useCallback(
    () => setStep((step: number) => (step > Step.CHOOSE ? step - 1 : step)),
    [setStep],
  );

  const initializePage = useCallback(() => initializeEditPage(integrationId), [integrationId]);
  const orgId = safeLocalStorage.currentUser!.organization.id;
  const fetchMmcUsers = useCallback(() => userNetworkService.getAllUsers(orgId), [userNetworkService, orgId]);

  const {isLoading, loader, data} = useDataLoader([
    fetchMmcUsers,
    // @ts-ignore
    initializePage,
  ]);
  useInitializer(loader);

  const mmcUsers = useMemo(() => (!data.length ? [] : (data[0] as ListResponse<User>).data), [data]);

  useEffect(
    () => {
      document.querySelector('.mmc-scroll-pane')?.scrollTo({top: 0, behavior: 'smooth'});
    },
    [currentStep],
  );

  if (loading || isLoading || !currentIntegration || !serviceDescription) {
    return (
      <PageContainer>
        <LoadingProgress />
      </PageContainer>
    );
  }

  return (
    <PageContainer className="mmc-add-integration">
      <Header title={`Edit ${serviceDescription.name} integration`}>
        {currentStep !== Step.FINISH && <Button onClick={handleCancelClick}>Cancel</Button>}
      </Header>
      <Row>
        <Col xxl={{span: 12, offset: 6}} xl={{span: 18, offset: 3}} lg={{span: 24}}>
          <EditSteps current={currentStep}>
            {steps.map((title, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Steps.Step key={index} title={title} />
            ))}
          </EditSteps>
          <StepList>
            {currentStep === Step.CHOOSE && (
              <Step1
                key={Step.CHOOSE}
                mmcUsers={mmcUsers}
                syncOptions={currentIntegration.syncOptions}
                onNextClick={handleNextStep}
                onPreviousClick={handlePreviousStep}
                selectedService={serviceDescription}
                supportedEntityTypes={serviceDescription.supportedEntityTypes}
              />
            )}
            {currentStep === Step.MAP && (
              <Step2
                key={Step.MAP}
                syncOptions={currentIntegration.syncOptions}
                onNextClick={handleNextStep}
                onPreviousClick={handlePreviousStep}
                serviceName={serviceDescription.name ?? ''}
              />
            )}
            {currentStep === Step.FREQUENCY && (
              <Step3
                key={Step.FREQUENCY}
                onNextClick={handleNextStep}
                onPreviousClick={handlePreviousStep}
                serviceName={serviceDescription.name ?? ''}
              />
            )}
            {currentStep === Step.FINISH && (
              <Step4
                serviceName={serviceDescription.name ?? ''}
              />
            )}
          </StepList>
        </Col>
      </Row>
    </PageContainer>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentIntegration: getCurrentIntegration(state),
  loading: getLoading(state),
});

const mapDispatchToProps = {
  initializeEditPage,
};

export default withStore(connect(mapStateToProps, mapDispatchToProps)(EditIntegration));
