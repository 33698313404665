import React from 'react';
import {Col, Row} from 'antd';
import styled from 'styled-components';
import SUPPORTED_SERVICES from '../../../../store/integrations/supported-services';
import ServiceButton from './ServiceButton';
import IntegrationService from '../../../type/enum/IntegrationService';

const ServiceOption = styled(Col)`
  &:not(:last-child) {
    margin-right: 1rem;
  }
`;

interface Props {
  onChange?: (service: IntegrationService | undefined) => void,
  selectedService?: IntegrationService,
}

const ServiceButtons: React.FC<Props> = ({onChange, selectedService}) => {
  return (
    <Row justify="start" align="middle">
      {SUPPORTED_SERVICES.map(props => (
        <ServiceOption key={props.type}>
          <ServiceButton
            {...props}
            onSelect={onChange}
            selectedService={selectedService}
          />
        </ServiceOption>
      ))}
    </Row>
  );
};

export default ServiceButtons;
