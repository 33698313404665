const toggleItemInSet = <T>(set: Set<T>, item: T, selected: boolean): Set<T> => {
  if (selected ? set.has(item) : !set.has(item)) {
    return set; // no changes needed
  }
  const result = new Set(set);
  if (selected) {
    result.add(item);
  } else {
    result.delete(item);
  }
  return result;
};

export default toggleItemInSet;
