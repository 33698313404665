import React, {ReactNode} from 'react';
import styled from 'styled-components';
import theme from '../../../../style/theme';

const TitleContainer = styled.div`
  padding: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${theme.font.h1};
  font-weight: normal;
  
  & > i {
      margin-right: 8px;
      font-weight: normal;
  }
`;

export interface CardTitleProps {
  title: string,
  titleIcon?: ReactNode,
  titleIconClassName?: string,
}

const CardTitle: React.FC<CardTitleProps> = ({title, titleIcon, titleIconClassName}) => {
  return (
    <TitleContainer>
      {titleIcon}
      {titleIconClassName && <i className={titleIconClassName} />}
      {title}
    </TitleContainer>
  );
};

export default CardTitle;
