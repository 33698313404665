import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {Modal} from 'antd';
import {ColDef} from '@ag-grid-community/core';
import {useTranslation} from 'react-i18next';
import MainServiceType from '../../type/service/MainService';
import IField from '../../type/fieldModel/IField';
import FieldFeature from '../../../common/field-model/field/FieldFeature';
import EntityFieldModel from '../../../common/field-model/entity-field-model';
import Search from '../../components/Input/Search';
import Checkbox from '../../components/Checkbox/Checkbox';

const SelectFieldsDialog = styled.div`
  padding: 30px;
`;

const FieldList = styled.div`
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`;


const FieldGroupTitle = styled.div`
  margin: 12px 0 4px;
  font-weight: 700;
`;

const FieldCheckbox = styled(Checkbox)`
  display: flex;
  & label {
    white-space: nowrap;
  }
`;


interface Props {
  onHide: () => void,
  onSave: () => void,
  show: boolean,
  fieldModel: EntityFieldModel,
  columnDefinitions: ColDef[],
  MainService: MainServiceType,
}

interface FieldsStructure {
  name: string,
  fields: IField[]
}

const SelectFieldsModal: React.FC<Props> = ({onHide, onSave, show, fieldModel, columnDefinitions, MainService}) => {
  const {t} = useTranslation();

  const [searchText, setSearchText] = useState<string>('');
  const [selectedFields, setSelectedFields] = useState<string[]>([]);

  const fieldsStructure: FieldsStructure[] = useMemo(
    () => {
      let fields: IField[] = [...fieldModel.fields];
      const _searchText: string = searchText.trim().toLowerCase();
      if (_searchText !== '') {
        fields = fields.filter(({displayName}) => displayName.toLowerCase().includes(_searchText));
      }
      return [
        {name: t('GeneralDetails'), fields: fields.filter(({isAddressField, isCustomField}) => !isAddressField && !isCustomField)},
        {name: t('AddressDetails'), fields: fields.filter(({isAddressField}) => isAddressField)},
        {name: t('CustomFields'), fields: fields.filter(({isCustomField}) => isCustomField)},
      ];
    },
    [fieldModel, searchText],
  );

  useEffect(
    () => setSelectedFields(columnDefinitions.map((cd: ColDef) => cd.colId || '')),
    [columnDefinitions, setSelectedFields],
  );

  const toggleField = useCallback(
    (field: string, addField: boolean) => {
      setSelectedFields(addField ? [...selectedFields, field] : selectedFields.filter(f => f !== field));
    },
    [setSelectedFields, selectedFields],
  );

  const saveEdits = useCallback(
    () => {
      (async () => {
        await MainService.saveSelectedListViewFields(fieldModel.fields.filter(({name}: IField) => selectedFields.includes(name)));
        onSave();
        onHide();
      })();
    },
    [fieldModel, selectedFields, onHide],
  );

  const allSelected = useMemo(
    () => selectedFields.length === fieldModel.fields.length,
    [selectedFields, fieldModel],
  );

  const noSelected = useMemo(
    () => selectedFields.length === 0,
    [selectedFields],
  );

  const selectAllLabel = useMemo(
    () => `${(allSelected ? t('DeselectAll') : t('SelectAll'))} ${(searchText.length ? t('Fields') : '')}`,
    [allSelected, searchText],
  );

  const handleSelectAllCheckboxChange = useCallback(
    () => {
      let fields: IField[] = [...fieldModel.fields];
      if (allSelected) {
        fields = fields.filter((field: IField) => field.features.has(FieldFeature.ALWAYS_VISIBLE));
      }
      setSelectedFields(fields.map((field: IField) => field.name));
    },
    [allSelected, setSelectedFields, fieldModel],
  );

  return (
    <Modal
      visible={show}
      onOk={saveEdits}
      okText={t('Apply')}
      onCancel={onHide}
      cancelText={t('Cancel')}
    >
      <SelectFieldsDialog>
        <Checkbox
          indeterminate={!noSelected && !allSelected}
          checked={allSelected}
          onChange={handleSelectAllCheckboxChange}
        >
          {selectAllLabel}
        </Checkbox>
        <Search
          value={searchText}
          placeholder={t('Search')}
          onChange={setSearchText}
        />
        <FieldList>
          {fieldsStructure.map(({name, fields}: FieldsStructure) => (
            <React.Fragment key={name}>
              <FieldGroupTitle>{name}</FieldGroupTitle>
              {fields.map((field: IField, i: number) => (
                <div key={`select-field-${i}`}>
                  <FieldCheckbox
                    disabled={field.features.has(FieldFeature.ALWAYS_VISIBLE)}
                    checked={selectedFields.includes(field.name)}
                    onChange={checked => toggleField(field.name, checked)}
                  >
                    {field.displayName}
                  </FieldCheckbox>
                </div>
              ))}
            </React.Fragment>
          ))}
        </FieldList>
      </SelectFieldsDialog>
    </Modal>
  );
};

export default SelectFieldsModal;
