import html2canvas from 'html2canvas';
import MarkerClusterer from '@google/markerclusterer';
import union from 'lodash/union';
import get from 'lodash/get';
import find from 'lodash/find';
import isNumber from 'lodash/isNumber';
import mapActions from '../store/store-helpers';
import GeocodingNetworkService from '../network-services/geocoding-network-service';
import userNetworkService from '../network-services/user-network-service';
import analyticsService from '../shared-services/analytics-service';
import helperService from '../shared-services/helper-service';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import convertEntitiesToRecordName, {entityToRecordNameString} from '../common/convertEntityToRecordName';
import EntityType from '../react/type/EntityType';
import {doesUseMiles} from '../common/settings';

export default function MappingService(
  $compile, $route, LassoService, FunnelService, mmcConst,
  SettingsService,
) {
  // main utility functions
  const UTILS = window.mmcUtils;
  const {MAN} = window.mmcUtils;
  const MODEL = window.DataModel;

  // service to return
  const service = {};

  mapActions(service, ['RoutingService', 'modals']);

  //
  // ---------------------- MAPPING / LOCATION Methods ---------------------- //
  //

  // function expression to create a new pin on the map
  service.createCustomMarkerPrototype = function () {
    if (!google) {
      return;
    }
    service.CustomMarker = function (latlng, map, args) {
      this.latlng = latlng;
      this.args = args;
    };
    window.CustomMarker = service.CustomMarker;

    service.CustomMarker.prototype = new google.maps.OverlayView();

    service.CustomMarker.prototype.draw = service.customMarkerDraw;

    service.CustomMarker.prototype.remove = function () {
      if (this.div) {
        if ('parentNode' in this.div) {
          this.div.parentNode.removeChild(this.div);
          this.div = null;
        }
      }
    };

    service.CustomMarker.prototype.getPosition = function () {
      return this.latlng;
    };
  };

  service.customMarkerDraw = function () {
    const self = this;
    let {div} = this;

    if (!div) {
      div = document.createElement('div');
      this.div = div;
      div.className = 'marker';
      div.style.position = 'absolute';
      div.style.cursor = 'pointer';

      // marker icon is pin
      const markerIcon = SettingsService.getUserSetting('markerIcon', 'pin');
      const markerSize = SettingsService.getUserSetting('markerSize', 'large');
      let key = 'pinLarge'; // default to pin large

      if (markerIcon === 'pin') {
        if (markerSize === 'small') {
          key = 'pinSmall';
        }
      } else if (markerIcon === 'circle') {
        if (markerSize === 'large') {
          key = 'circleLarge';
        } else if (markerSize === 'small') {
          key = 'circleSmall';
        }
      }

      const {
        width, height, location, checkinPinLocation, extension,
      } = mmcConst[key];
      div.style.width = `${width}px`;
      div.style.height = `${height}px`;
      MODEL.MappingService.markerWidth = width;
      MODEL.MappingService.markerHeight = height;
      MODEL.MappingService.pinDir = location;
      const pinDirCheckIn = checkinPinLocation;

      div.style.backgroundImage = service.getBackgroundImage(self, pinDirCheckIn, extension);

      // user is editing a route
      let backgroundIsSet = false;
      if (['individualAccountsRoutePage', 'individualContactsRoutePage', 'accountsRoutingMapPage', 'contactsRoutingMapPage', 'routeCreatePage'].includes($route.current.id)) {
        backgroundIsSet = service.setBackgroundImageRouting(self, div);
      }
      if (self.args.marker_id.length > 1 && !backgroundIsSet) {
        div.className += ' marker--container';
        let classModifier = '';
        let text = `${self.args.marker_id.length}`;

        if (self.args.marker_id.length >= 10) {
          classModifier = self.args.marker_id.length >= 100 ? ' marker--pin-count--LARGE' : ' marker--pin-count--MEDIUM';
        }

        if (key === 'pinSmall') {
          classModifier += ' marker--pin-count--PIN-SMALL';

          if (self.args.marker_id.length >= 100) {
            text = '&#43;';
          }
        }

        div.innerHTML = helperService.trimLiteral(`
                    <span class="marker--pin-count${classModifier}">
                        ${text}
                    </span>
                `);
      }

      // add number inside pin id multiple contacts exist at 1 address
      if (typeof (self.args.marker_id) !== 'undefined') {
        div.dataset.marker_id = self.args.marker_id;

        if (!Array.isArray(self.args.marker_id)) {
          self.args.marker_id = [self.args.marker_id];
        }

        // create div to append
        if (self.args.marker_id.length > 1) {
          const insideNumberDiv = document.createElement('div');
          let numDisplay;

          if (safeLocalStorage.currentUser.currentMarkerIcon === 'pin') {
            if (safeLocalStorage.currentUser.currentMarkerSize === 'large') {
              insideNumberDiv.className = 'insideNumberPinLarge';
              numDisplay = self.args.marker_id.length;
              insideNumberDiv.appendChild(document.createTextNode(numDisplay));
            } else if (safeLocalStorage.currentUser.currentMarkerSize === 'small') {
              insideNumberDiv.className = 'insideNumberPinSmall';
              insideNumberDiv.appendChild(document.createTextNode('*'));
            }
          } else if (safeLocalStorage.currentUser.currentMarkerIcon === 'circle') {
            if (safeLocalStorage.currentUser.currentMarkerSize === 'large') {
              insideNumberDiv.className = 'insideNumberCircleLarge';
              numDisplay = self.args.marker_id.length;
              if (numDisplay > 99) {
                insideNumberDiv.appendChild(document.createTextNode('*'));
              } else {
                insideNumberDiv.appendChild(document.createTextNode(numDisplay));
              }
            } else if (safeLocalStorage.currentUser.currentMarkerSize === 'small') {
              insideNumberDiv.className = 'insideNumberCircleSmall';
              insideNumberDiv.appendChild(document.createTextNode('*'));
            }
          }

          div.appendChild(insideNumberDiv);
        }
      }

      // clicked on customer
      if (!($route.current.id === 'contactsPage' || $route.current.id === 'dashPage' || $route.current.id === 'teamsPage')) {
        google.maps.event.addDomListener(div, 'click', (event) => {
          service.pinClickHandler(event, self);
        });
      }

      const panes = this.getPanes();
      panes.overlayImage.appendChild(div);
    } // `if !div` block ends

    const point = this.getProjection().fromLatLngToDivPixel(this.latlng);

    // offset by height of custom pin image
    if (point) {
      div.style.left = `${(point.x - MODEL.MappingService.markerWidth / 2) + 1.5}px`;
      div.style.top = `${point.y - MODEL.MappingService.markerHeight + 8}px`;
    }
  };

  const isOnRoutesPage = () => UTILS.isOnPage('routingPage', 'individualAccountsRoutePage', 'individualContactsRoutePage', 'routeCreatePage', 'accountsRoutingMapPage', 'contactsRoutingMapPage');

  // const getNewAppUrl = (path) => `${window.__env.baseNewAppUrl}${path}?mmc_token=${safeLocalStorage.accessToken}&mmc_editpane`;

  // logic for when user clicks a pin
  service.pinClickHandler = function (event, self) {
    // console.log('pin clicked', self.args, $route.current.id);

    if (window.isOnPage('edit') && $route.current.id !== 'individualAccountsRoutePage' && $route.current.id !== 'individualContactsRoutePage') {
      return;
    }

    const isOnPageWithPreview = ['accountsMapPage', 'contactsMapPage', 'territoriesMapPage', 'accountsGroupsAccountListPage',
      'contactsGroupsContactListPage', 'dealsGroupsDealListPage', 'individualAccountsRoutePage',
      'individualContactsRoutePage'].includes($route.current.id);

    analyticsService.clicked(['Map', 'Pin click']);
    // remove previous popovers
    MODEL.MappingService.popovers.forEach((popover) => {
      popover.remove();
    });

    MODEL.MappingService.pinData = [];
    MODEL.MappingService.popovers = [];
    window.refreshDom({'MappingService.pinData': MODEL.MappingService.pinData});

    // revert color on last pin clicked
    if (MODEL.MappingService.previousPin && !(isOnRoutesPage() && MODEL.MappingService.creatingOrEditingRoute)) {
      MODEL.MappingService.previousPin.style.backgroundImage = MODEL.MappingService.previousPinColor;
    }

    MODEL.MappingService.previousPinColor = self.div.style.backgroundImage;

    // only highlight pin if not clicking close button on popOver
    if (MODEL.MappingService.closePopOverClicked) {
      MODEL.MappingService.closePopOverClicked = false;
      return;
    } if (!isOnRoutesPage()) {
      self.div.style.backgroundImage = `url('${MODEL.MappingService.pinDir}_home.png')`;
    }

    MODEL.MappingService.previousPin = self.div;

    // user is currently clicking a pin
    MODEL.MappingService.clickingPin = true;

    // add popover box to div (if close button not clicked)
    if (!MODEL.MappingService.closePopOverClicked) {
      let pinData = {};

      // address info
      let addressToShow = '';
      addressToShow = UTILS.buildCompleteAddress(self.args);

      if (!addressToShow) {
        const coord = {};
        coord.lat = self.args.latitude;
        coord.lng = self.args.longitude;
        addressToShow = `${coord.lat.toFixed(2)}, ${coord.lng.toFixed(2)}`;
      }

      // create array of objects for selected pin data
      if ($.isArray(self.args.name)) {
        pinData = self.args.name.map((pinName, key) => ({
          name: pinName,
          id: self.args.marker_id[key],
          phone: self.args.phone[key],
          address: self.args.addressPool[key],
        }));
      } else {
        pinData = [{
          name: self.args.name,
          id: self.args.marker_id[0],
          phone: self.args.phone,
          address: addressToShow,
          account: self.args.account,
        }];
      }

      const MaxCharLimit = 50;

      if ((addressToShow && addressToShow.length > MaxCharLimit) && (!$.isArray(addressToShow))) {
        addressToShow = `${addressToShow.substring(0, MaxCharLimit)}...`;
      }

      const extra = '';
      let top = '-150px';
      let left = '-175px';

      let infoString = '';
      let routeString = '';
      let previewString = '';

      if (isOnRoutesPage() && MODEL.MappingService.creatingOrEditingRoute) {
        const index = MODEL.RoutingService.currentRouteObjects.indexOf(pinData[0].id);
        if (index < 0) {
          routeString = `
                        <button ng-click="handlePinClicksForRouting(${pinData[0].id})" class="mmc-button mmc-button--primary col-md-12" style="bottom:10px;">
                            <i class="fa fa-car" aria-hidden="true"></i>
                            Add to Route
                        </button>`;
        } else {
          routeString = `
                        <button ng-click="handlePinClicksForRouting(${pinData[0].id})" class="mmc-button mmc-button--primary-soft col-md-12" style="bottom:10px;">
                            <i class="fa fa-minus-circle" aria-hidden="true"></i>
                            Remove from Route
                        </button>`;
        }
      } else if (pinData.length === 1 && isOnPageWithPreview) {
        let label = 'Preview Company';
        if (MODEL.MappingService.singlePinEntityType === EntityType.PERSON) {
          label = 'Preview Person';
        }
        if (MODEL.MappingService.singlePinEntityType === EntityType.DEAL) {
          label = 'Preview Deal';
        }
        previewString = `<button 
             ng-click="previewEntity($event, MODEL.MappingService.singlePinEntityType, ${pinData[0].id})" 
             class="btn btn-primary btn-fill popUpButton"
             style="width:100%!important;height:32px;line-height: 2px"
           >
            ${label}
          </button>`;
      }

      if (pinData.length === 1) {
        let phoneString = '';
        if (pinData[0].phone && !isOnRoutesPage() && !isOnPageWithPreview) {
          phoneString = `
                        <a ng-click="callPhoneNumber('${pinData[0].phone}', $event)">
                            <button class="mmc-button mmc-button--primary-soft  popup-call-button">
                                <i class="fa fa-phone" aria-hidden="true"></i>
                                Call
                            </button>
                        </a>`;
        }
        const pinSize = safeLocalStorage.currentUser.currentMarkerSize;
        if (pinSize === 'small') {
          left = '-130px';
        }

        if (self.args.crmObjectType === 'accounts') {
          top = '-115px';
        }

        const contactName = pinData[0].name.length > 25 ? `${pinData[0].name.substring(0, 24)}...` : pinData[0].name;
        let accountName;
        if (pinData[0].account) {
          accountName = pinData[0].account.name.length > 25
            ? `${pinData[0].account.name.substring(0, 24)}...`
            : pinData[0].account.name;
        }

        const title = `<div class="popoverBox__title">${accountName || contactName}</div>`;
        const subtitle = accountName
          ? `<div class="popoverBox__title popoverBox__title--subtitle">${contactName}</div>`
          : '';

        infoString += `<div class="popover-content">
                        <div style="overflow:hidden;">
                            ${title}
                            ${subtitle}
                            ${extra}
                            <p>
                                <span class='icon ion-location'></span>
                                ${addressToShow}
                            </p>
                        </div>
                        <div style="padding-top:16px; padding-bottom:4px;text-align:right;">
                          ${phoneString}
                          ${routeString}
                          ${previewString}
                        </div>
                    </div>`;

        // create popover
        const popoverString = `<div id="popupTop" class="popover top in popoverBox"
                    style="display:block;z-index:1050;top:${top};left:${left}">
                    <i class="ion-ios-close-empty popupClose" ng-click="closePopOver()"></i>
                    <div class="arrow" style="z-index:1051;"></div>
                        ${infoString}
                    </div>`;
        const popover = $(popoverString);

        MODEL.MappingService.popovers.push(popover);
        $compile(popover)($scope);
        $(self.div).append(popover);
        service.ensurePopoverFitsIntoMapView(popover[0]);

        // hack to get popover to display above the marker clusters (set z-index on container view containing markers)
        self.div.parentNode.style.zIndex = 110;
        MODEL.MappingService.currentPopOverData = self;
      } else {
        MODEL.MappingService.pinData = pinData;
        MODEL.MappingService.currentPopOverData = self;
        window.refreshDom({'MappingService.pinData': MODEL.MappingService.pinData});
      }
    }

    // mark the current pin clicked
    if (self.args.marker_id.length === 1) {
      MODEL.currentCrmObjectId = self.args.marker_id[0];
    }
  };

  service.ensurePopoverFitsIntoMapView = (popover) => {
    const popoverRect = popover.getBoundingClientRect();
    const mapRect = $('#map')[0].getBoundingClientRect();

    let offsetX = 0;
    if (mapRect.left > popoverRect.left) {
      offsetX = popoverRect.left - mapRect.left;
    } else if (mapRect.right < popoverRect.right) {
      offsetX = popoverRect.right - mapRect.right;
    }
    let offsetY = 0;
    if (mapRect.top > popoverRect.top) {
      offsetY = popoverRect.top - mapRect.top;
    }

    if (offsetX || offsetY) {
      MAN.offsetMap(offsetX, offsetY);
    }
  };

  // handle pin clicks for routing page
  service.handlePinClicksForRouting = (contactId, multiplePin = false) => {
    const marker = MODEL.MappingService.currentPopOverData;
    if (!multiplePin) {
      service.closePopOver();
    }

    // editing existing route
    if (MODEL.RoutingService.isEditingRoute) {
      const editingAccountRoutes = window.isOnPage('accounts');
      service.RoutingServiceActions.updateEditFlag({
        accounts: editingAccountRoutes,
        contacts: !editingAccountRoutes,
        rebuildRoute: true,
      });
    }

    // pin already in route --> remove it
    if (MODEL.RoutingService.currentRouteObjects.includes(contactId)) {
      service.RoutingServiceActions.updateCurrentRouteObjects(
        MODEL.RoutingService.currentRouteObjects.filter(id => contactId !== id),
      );
      service.RoutingServiceActions.updateRouteObjects(
        MODEL.RoutingService.routeObjects.filter(({id}) => contactId !== id),
        true,
      );

      // unselect marker if it was a single contact marker or if none of marker's
      // contacts are added to the route
      if (!multiplePin || !MODEL.RoutingService.currentRouteObjects.some(id => marker.args.marker_id.includes(id))) {
        marker.div.style.backgroundImage = marker.args.previousPinColor;
      }
      return;
    }

    if (MODEL.RoutingService.currentRouteObjects.length > 69) {
      swal('Too many pins...', 'You may only add up to 69 pins to a route.', 'error');
      return;
    }

    // select marker if it was a single contact marker or if none of marker's contacts
    // have been added to the route so far
    if (!multiplePin || !MODEL.RoutingService.currentRouteObjects.some(id => marker.args.marker_id.includes(id))) {
      marker.args.previousPinColor = marker.div.style.backgroundImage;
      marker.div.style.backgroundImage = `url('${MODEL.MappingService.pinDir}_home.png')`;
    }


    service.RoutingServiceActions.addObjectToCurrentRouteObjects(contactId);
    const index = marker.args.marker_id.indexOf(contactId);
    service.RoutingServiceActions.addObjectToRouteObjects({
      id: contactId,
      geoPoint: {
        type: 'Point',
        coordinates: [marker.args.longitude, marker.args.latitude],
      },
      latitude: marker.args.latitude,
      longitude: marker.args.longitude,
      address: Array.isArray(marker.args.address) ? marker.args.address[index] : (marker.args.address || null),
      name: marker.args.name[index],
    });
    MODEL.routeObjectsSaved = [...MODEL.RoutingService.routeObjects];
  };

  // closes the pop-up when pin is clicked
  service.closePopOver = function (multiplePin = false) {
    MODEL.currentCrmObjectId = '';
    MODEL.MappingService.closePopOverClicked = !multiplePin;
    MODEL.MappingService.popovers.forEach((popover) => {
      popover.remove();
    });
    MODEL.MappingService.popovers = [];

    // remove hack to get popover to show above marker clusters -> magic number
    if (MODEL.MappingService.previousPin) {
      MODEL.MappingService.previousPin.parentNode.style.zIndex = 103;
    }

    service.resetMultiplePopupData();
  };
  window.closeMapPopOver = service.closePopOver;

  service.resetMultiplePopupData = () => {
    MODEL.MappingService.pinData = [];
    window.refreshDom({'MappingService.pinData': MODEL.MappingService.pinData});
  };

  //
  // ---------------------- LEAD GEN Methods ---------------------- //
  //

  // set search pin location
  service.setSearchPinLocation = async function (hardRefresh, useCurrentLocation) {
    if (!useCurrentLocation) {
      MODEL.MappingService.currentSearchLocation = MAN.userPosition || MAN.lastResortPosition;
    }

    const {address} = await GeocodingNetworkService.reverseGeocodeAddress(
      MODEL.MappingService.currentSearchLocation.lat,
      MODEL.MappingService.currentSearchLocation.lng,
    );
    MODEL.MappingService.currentSearchAddress = service.addressObjToString(address);

    // marker already on the map --> remove it
    if (MAN.searchStartingLocationPin) {
      MAN.searchStartingLocationPin.setMap(null);
    }
    const latlng = new google.maps.LatLng(MODEL.MappingService.currentSearchLocation);

    // get correct map (normal map page, or mini team map)
    const {map} = MODEL;

    // search pin location in find new customers page
    MODEL.MappingService.searchStartingLocationPin = new google.maps.Marker({
      position: latlng,
      title: 'Searching From Here',
      draggable: true,
      map,
    });
    MAN.searchStartingLocationPin = MODEL.MappingService.searchStartingLocationPin;
    MAN.allMapContent.push(MODEL.MappingService.searchStartingLocationPin);
    MAN.addedContentMessage('added starting location pin "for nearby customers"');

    if (hardRefresh) {
      // on click function for search pin
      const infowindow = new google.maps.InfoWindow();
      google.maps.event.addListener(MODEL.MappingService.searchStartingLocationPin, 'click', () => {
        infowindow.setContent('<b>Searching From Here</b>');
        infowindow.open(map, MODEL.MappingService.searchStartingLocationPin);
      });
    }

    // event listener : if user drags pin in find new customers page
    service.addEventListenerToSearchPin();

    // circle to show search radius
    if (!MODEL.findCustomersCircle) {
      MODEL.findCustomersCircle = new google.maps.Circle({
        strokeColor: '#3063E4',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: 'rgb(0,180,255)',
        fillOpacity: 0.25,
        map: MAN.getMap(),
        center: latlng,
        radius: MODEL.MappingService.findCustomersSearchRadius * 1000 * 1.609,
      });
    } else {
      MODEL.findCustomersCircle.setRadius(MODEL.MappingService.findCustomersSearchRadius * 1000 * 1.609);
    }
    MODEL.findCustomersCircle.bindTo('center', MODEL.MappingService.searchStartingLocationPin, 'position');

    // not using MAN.setCenter because it doesn't change center on frequent calls
    MAN.getMap().setCenter(MODEL.MappingService.currentSearchLocation);
    MAN.setZoom(10);
  };

  // create the draggable current location pin
  service.setPinLocation = function () {
    if (UTILS.isOnPage('nearbyPage') && !MAN.userPosition) {
      MAN.promptForPosition(service.setPinLocation);
      return;
    }

    // marker already on the map --> remove it
    if (MAN.startingLocationPin) {
      MAN.startingLocationPin.setMap(null);
    }

    // add listener to update nearby page when starting pin is dropped
    if (UTILS.isOnPage('nearbyPage')) {
      service.addEventListenerToStartingPin(MODEL.startingLocationPin, 'nearby');
      MAN.setZoom(9);
    } else if (UTILS.isOnPage('findNewCustomers') || MODEL.MappingService.findNewCustomers) {
      // add search radius circle if on findCustomers page
      service.addEventListenerToSearchPin();
      // circle to show search radius
      service.setSearchPinLocation(true);
    } else {
      // index page --> drag and drop pin to add customer
      service.addEventListenerToStartingPin(MODEL.startingLocationPin, 'index');
    }
  };

  service.addEventListenerToSearchPin = function () {
    google.maps.event.addListener(MODEL.MappingService.searchStartingLocationPin, 'dragend', (a) => {
      MODEL.MappingService.currentSearchLocation.lat = a.latLng.lat();
      MODEL.MappingService.currentSearchLocation.lng = a.latLng.lng();

      MAN.nukeMapContent();
      MODEL.localCustomersFound = [];
      MODEL.MappingService.globalCustomersFound = [];

      if (MODEL.MappingService.showOtherPins) {
        service.showOtherPins();
      }
      service.setSearchPinLocation();
      service.searchAllCurrentTerms();

      $('#pinDropped').show();
    });
  };

  // add drag end listener to track lat/lng & update nearby/find customers page when starting pin is dropped
  service.addEventListenerToStartingPin = function (startingLocationPin, page) {
    if (page === 'nearby') {
      google.maps.event.addListener(startingLocationPin, 'dragend', (a) => {
        MAN.userPosition.lat = a.latLng.lat();
        MAN.userPosition.lng = a.latLng.lng();
        MODEL.show.loader = true;

        MAN.nukeMapContent();
        service.setPinLocation();
        window.fetchData(true);
        $('#pinDropped').show();
      });
    }

    if (page === 'findNewCustomers') {
      google.maps.event.addListener(startingLocationPin, 'dragend', (a) => {
        MODEL.MappingService.currentSearchLocation.lat = a.latLng.lat();
        MODEL.MappingService.currentSearchLocation.lng = a.latLng.lng();

        MAN.nukeMapContent();
        service.setSearchPinLocation();
        $('#pinDropped').show();
        return service.localCustomerSearch();
      });
    }
  };

  // find local customers from a Google "places" search
  service.localCustomerSearch = function (singleSearchTerm, refresh = false) {
    // set customers names if not set
    const records = $route.current.id.includes('account') ? MODEL.accounts : MODEL.contacts;

    MODEL.MappingService.recordNames = {
      ...MODEL.MappingService.recordNames,
      ...convertEntitiesToRecordName(records),
    };

    // reset local customers array
    if (!MODEL.MappingService.currentSearchLocation) {
      if (!MAN.userPosition) {
        MAN.promptForPosition(service.localCustomerSearch);
      } else {
        MODEL.MappingService.currentSearchLocation = MAN.userPosition;
      }
    } else {
      // types: https://developers.google.com/places/supported_types
      // let searchTerm = document.getElementById("localSearchBar").value.trim();
      const currentLocation = MODEL.MappingService.currentSearchLocation;

      // delete previous pins searched for
      if (!MODEL.MappingService.currentSearchTerms.length || refresh) {
        service.setMapOnAll(null);
        service.setSearchPinLocation(false, true);
      }

      const infowindow = new google.maps.InfoWindow();
      google.maps.event.addListener(MODEL.MappingService.searchStartingLocationPin, 'click', () => {
        infowindow.setContent('<b>Searching From Here</b>');
        infowindow.open(MAN.getMap(), MODEL.MappingService.searchStartingLocationPin);
      });

      const markerinfowindow = new google.maps.InfoWindow();

      const withNearbySearchResults = (results, status) => {
        const currentlocalCustomersFoundLength = MODEL.localCustomersFound.length;

        if (results == null) {
          MODEL.show.loader = false;
        } else {
          // inject search term in the array
          results.forEach(customerFound => {
            customerFound.searchTerm = singleSearchTerm;
          });
          MODEL.localCustomersFound = union(MODEL.localCustomersFound, results);
          MODEL.MappingService.globalCustomersFound = union(MODEL.MappingService.globalCustomersFound, results);
          $('#leadGenMultipleSelectButton').show();
        }

        if (status === google.maps.places.PlacesServiceStatus.OK) {
          results.forEach((customerFound, customerFoundIndex) => {
            const {lat, lng} = customerFound.geometry.location;
            const alreadyAdded = MODEL.MappingService.recordNames[entityToRecordNameString(customerFound.name, lng(), lat())];

            if (!alreadyAdded) {
              service.createMarker(customerFound, markerinfowindow, customerFoundIndex + currentlocalCustomersFoundLength, service.getMarkerColor(customerFound));
            }
          });

          MAN.setCenter(currentLocation);
          MAN.setZoom(10);
          $('#pinDropped').hide();
        }

        MODEL.show.loader = false;
        window.refreshDom({loader: false}, 'show');
        return Promise.resolve();
      };

      const searchTermData = MODEL.MappingService.globalCustomersFound.filter((obj) => obj.searchTerm === singleSearchTerm);

      if (searchTermData.length > 0) {
        return withNearbySearchResults(searchTermData, google.maps.places.PlacesServiceStatus.OK);
      }
      MODEL.show.loader = true;
      const placesService = new google.maps.places.PlacesService(MAN.getMap());
      window.mmcUtils.tk('vU2x2VcPfu');
      return placesService.nearbySearch({
        location: currentLocation,
        radius: MODEL.MappingService.findCustomersSearchRadius * 1000 * 1.609,
        keyword: singleSearchTerm,
      }, withNearbySearchResults);
    }
  };

  // save new pin from find-new customers page
  service.saveNewFoundCustomer = function () {
    const currentLocation = MODEL.MappingService.currentSearchLocation;

    // delete previous pins searched for
    service.setMapOnAll(null);
    service.setSearchPinLocation(true, true);

    const markerinfowindow = new google.maps.InfoWindow();

    MODEL.MappingService.recordNames = convertEntitiesToRecordName(($route.current.id.includes('account') ? MODEL.accounts : MODEL.contacts));

    MODEL.localCustomersFound.forEach((customerFound, customerFoundIndex) => {
      // check to add pins which aren't already in user's customer list
      const [longitude, latitude] = [customerFound.geometry.location.lng(), customerFound.geometry.location.lat()];
      const alreadyAdded = MODEL.MappingService.recordNames[entityToRecordNameString(customerFound.name, longitude, latitude)];
      if (!alreadyAdded) {
        service.createMarker(customerFound, markerinfowindow, customerFoundIndex, service.getMarkerColor(customerFound));
      }
    });

    MAN.setCenter(currentLocation);
    MAN.setZoom(10);
    $('#pinDropped').hide();

    if (MODEL.MappingService.showOtherPins) {
      service.showOtherPins();
    }
    MODEL.leadGenMultiRecordsSaved = true;
    MODEL.MappingService.globalCustomersFound = union(MODEL.MappingService.globalCustomersFound, MODEL.localCustomersFound);
  };

  service.getMarkerColor = (record) => {
    let markerColor = MODEL.MappingService.searchTermColors[record.searchTerm];
    if (MODEL.selectedLeadGenRecords.includes(record)) {
      markerColor = mmcConst.color.selectedMarker;
    }
    return markerColor;
  };

  service.getMarkerIconUrl = (color) => {
    const type = $route.current.id.includes('account') ? 'account' : 'contact';
    return `./images/lead-gen-pins/${color}-${type}.svg`;
  };

  // creates a Google Maps marker object
  service.createMarker = function (pin, infowindow, customerIndex, color) {
    const markerOptions = {
      icon: {
        url: service.getMarkerIconUrl(color),
      },
      map: MAN.getMap(),
      position: pin.geometry.location,
    };
    const marker = new google.maps.Marker(markerOptions);

    MAN.allMapContent.push(marker);
    MODEL.currentLocalMarkers.push(marker);
    pin.marker = marker;

    const popup = $(`
          <div class="popupTop leadGenPopup">
            <p>${pin.name}</p>
            <button class="btn infoWindowPopUp" ng-click="addLocalCustomer(${customerIndex})">+ Add</button>
          </div>
        `);
    $compile(popup)($scope);
    google.maps.event.addListener(marker, 'click', () => {
      if (MODEL.MappingService.showLeadGenMultipleSelect) {
        service.togglePin(pin);
      } else {
        infowindow.setContent(popup[0]);
        infowindow.open($scope.map, marker);
      }
    });
  };

  service.togglePin = (pin) => {
    const shouldSelect = !MODEL.selectedLeadGenRecords.includes(pin);
    if (shouldSelect) {
      MODEL.selectedLeadGenRecords.push(pin);
    } else {
      MODEL.selectedLeadGenRecords = MODEL.selectedLeadGenRecords.filter(record => record !== pin);
    }

    pin.marker.setIcon({url: service.getMarkerIconUrl(service.getMarkerColor(pin))});
    window.refreshDom({selectedLeadGenRecords: MODEL.selectedLeadGenRecords});
  };

  // clear map markers
  service.setMapOnAll = function (map) {
    for (let i = 0; i < MODEL.currentLocalMarkers.length; i++) {
      MODEL.currentLocalMarkers[i].setMap(map);
    }
  };

  //
  // ------------------ GENERAL / UTILITY Methods ------------------ //
  // ------------ (shared across different mapping pages) ------------- //
  //

  // lasso pins
  service.lassoPins = function (type) {

    if (MODEL.MappingService.heatMapEnabled) service.disableHeatMap();

    // lasso init
    LassoService.setupLasso();

    // map, groups, other pages other than routing
    if (type === 'map') {
      $('#lassoSaveButton').show();
      $('#lassoAddToRouteButton').show();
      $('#lassoDeleteButton').show();
      $('#cancelLassoTool').show();
      // pop up
      const title = 'Lasso Tool';
      let message = "Draw a shape around pins on the map. Then, click 'edit' in the top left.";
      if (['individualAccountsRoutePage', 'individualContactsRoutePage', 'accountsRoutingMapPage', 'contactsRoutingMapPage', 'routeCreatePage'].includes($route.current.id)) {
        message = "Draw a shape around pins on the map. Then, click 'next' in the top right.";
      }
      swal(title, message)
        .then(() => {
          // start the lasso process
          LassoService.startLasso();
        })
        .catch(() => {});

      // routing page
    } else if (type === 'route') {
      // turn lasso on
      if (MODEL.MappingService.toggleLassoRouteOn) {
        $('#cancelLassoTool').show();

        // start the lasso process
        LassoService.startLasso();

        // pop up
        const title = 'Routing Lasso Tool';
        const message = "Draw a shape around pins on the map you'd like to route to.";
        swal(title, message);

        MODEL.MappingService.toggleLassoRouteOn = false;

        // turn lasso off
      } else {
        $('#cancelLassoTool').hide();

        LassoService.clearLasso();
        MODEL.MappingService.toggleLassoRouteOn = true;
        swal('Lasso Turned Off', 'To use the lasso tool again just click on the lasso button.');
      }
    }

    // hide the tools menu so mobile users can use this tool
    $('#toolsMenu').slideUp(190);
    $('#toolsButtonLink').attr('aria-expanded', 'false');
    MODEL.MappingService.showMapTools = true;
  };

  // adds a marker to lasso points
  service.addMarkerToLassoPoints = function (event) {
    const path = MODEL.poly.getPath();
    path.push(event.latLng);

    // Add a new marker at the new plotted point on the polyline.
    const marker = new google.maps.Marker({
      position: event.latLng,
      title: `#${path.getLength()}`,
      map: MAN.getMap(),
    });
    MAN.allMapContent.push(marker);
    MAN.addedContentMessage('added marker point to polyline');
    MODEL.MappingService.lassoMarkers.push(marker);
  };

  // apply heat map
  service.applyHeatMap = function () {
    // enable heat map
    if (!MODEL.MappingService.heatMapEnabled) {
      return service.enableHeatMap();
    }
    // enable regular map
    service.disableHeatMap();
  };


  service.enableHeatMap = function () {

    const currentRouteId = get($route.current, 'id');
    MODEL.MappingService.heatMapEnabled = true;

    // change icon to pins (so you can switch back to regular map)
    $('#heatMapIcon').removeClass('ion-flame').addClass('ion-location');
    MAN.nukeMapContent();
    // hide all pins
    MODEL.customersOverlay.forEach((pin) => {
      pin.setMap(null);
    });

    if (MODEL.markerCluster !== '') {
      MODEL.markerCluster.removeMarkers(MODEL.customersOverlay);
    }

    // get heatmap points
    const allCustomerCoords = [];
    const lookup = {
      accountsMapPage: MODEL.accounts,
      accountsGroupsMapPage: MODEL.accounts,
      accountsRoutingMapPage: MODEL.accounts,
      contactsMapPage: MODEL.contacts,
      contactsGroupsMapPage: MODEL.contacts,
      contactsRoutingMapPage: MODEL.contacts,
      routingGroupsMapPage: MODEL.contacts,
    };
    const records = lookup[currentRouteId];
    records.forEach(item => {
      if (item.geoPoint) {
        const [longitude, latitude] = item.geoPoint.coordinates;
        allCustomerCoords.push(new google.maps.LatLng(latitude, longitude));
      } else if (isNumber(item.latitude) && isNumber(item.longitude)) {
        allCustomerCoords.push(new google.maps.LatLng(item.latitude, item.longitude));
      }
    });

    // apply new heatmap
    if (MODEL.MappingService.heatmap) {
      MODEL.MappingService.heatmap.setMap(null);
    }

    MODEL.MappingService.heatmap = new google.maps.visualization.HeatmapLayer({
      data: allCustomerCoords,
      map: MAN.getMap(),
      radius: 20,
    });

    MAN.allMapContent.push(MODEL.MappingService.heatmap);
    MAN.addedContentMessage('added heatmap');

    return !records.length;
  };

  // disable heat map
  // return true if length of customers overlay is 0
  service.disableHeatMap = function (showPins = true) {
    MODEL.MappingService.heatMapEnabled = false;

    // change icon to flame (so you can switch back to heat map)
    $('#heatMapIcon').removeClass('ion-location').addClass('ion-flame');

    // remove heat map
    if (MODEL.MappingService.heatmap) {
      MODEL.MappingService.heatmap.setMap(null);
    }

    if (showPins) {
      // show all pins again
      MODEL.customersOverlay.forEach((pin) => {
        pin.setMap(MAN.getMap());
        MAN.allMapContent.push(pin);
      });
      MAN.addedContentMessage('added', MODEL.customersOverlay.length, 'pins to customersOverlay');
      MODEL.markerCluster.addMarkers(MODEL.customersOverlay);

      return !MODEL.customersOverlay.length;
    }

    return false;
  };

  // turns any string into a  "DOM id attribute friendly" string
  service.sanitizeNewField = (newFieldName = '') => newFieldName.trim().replace(/\s/gi, '-').replace(/[^a-z0-9]/gi, '');

  // prints screen of the map
  service.printMap = function () {
    // browser must be safari or chrome
    if (navigator.userAgent.indexOf('MSIE') >= 0 || !!document.documentMode) {
      swal('Browser not supported', 'Please use Chrome or Safari web browsers to download your map.', 'error');
    } else {
      // get transform value
      const transform = $('.gm-style>div:first>div:first>div:last>div').css('transform');
      const comp = transform.split(','); // split up the transform matrix
      const mapleft = parseFloat(comp[4]); // get left value
      const maptop = parseFloat(comp[5]); // get top value
      $('.gm-style>div:first>div:first>div:last>div').css({ // get the map container. not sure if stable
        transform: 'none',
        left: mapleft,
        top: maptop,
      });
      html2canvas($('#map'),
        {
          useCORS: true,
          onrendered(canvas) {
            const dataUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
            const a = document.createElement('a');
            a.href = dataUrl;
            a.download = 'mapmycustomers-map.png';
            const click = new MouseEvent('click', {
              view: window,
              bubbles: true,
              cancelable: true,
            });
            a.dispatchEvent(click);

            $('.gm-style>div:first>div:first>div:last>div').css({
              left: 0,
              top: 0,
              transform,
            });
          },
        });
    }
  };

  // enable traffic layer on map
  service.showTraffic = function () {
    const showTraffic = !safeLocalStorage.currentUser.showTraffic;
    safeLocalStorage.currentUser.showTraffic = showTraffic;
    service.enableTraffic(showTraffic);

    // traffic shows at zoom level 5 and above
    if (MAN.getMap().getZoom() < 5) {
      MAN.getMap().setZoom(5);
    }
  };

  // add/remove traffic layer from map
  service.enableTraffic = function (enable) {
    MAN.trafficLayer = MAN.trafficLayer || new google.maps.TrafficLayer();
    MAN.trafficLayer.setMap(enable ? MAN.getMap() : null);
  };

  // unsubscribe from all email communications
  service.unsubscribeFromAllEmails = function () {
    // store setting
    const putData = {turnOffEmails: true};
    return userNetworkService.updateUserData(putData)
      .then(() => swal('Success', 'All automated emails have been turned off. To turn on again, please contact support@mapmycustomers.me.', 'success'));
  };

  // get customers for groups map page which are checked in list page
  service.getCustomersForGroupsMapPage = (customers) => {
    const groupCustomers = [];

    customers.forEach(customer => {
      if (customer.groups
        && customer.groups[0] !== undefined
        && MODEL.currentPageHiddenGroups !== undefined
        && customer.groups !== 'null'
        && MODEL.currentPageHiddenGroups !== 'null'
      ) {
        const groups = customer.groups.filter(group => MODEL.GroupsService.currentPageGroups.find(lsGroup => group === lsGroup && group !== 'null' && lsGroup !== 'null'));
        const diffGroups = groups
          .filter(group => !MODEL.currentPageHiddenGroups.find(lsGroup => group === lsGroup && group !== 'null' && lsGroup !== 'null'));

        if (diffGroups.length > 0) {
          groupCustomers.push(customer);
        }
      }
    });

    return groupCustomers;
  };

  service.setUserMarker = () => {
    if (!MAN.getMap()) {
      return;
    }

    if (MODEL.localCurrentMarker) {
      MODEL.localCurrentMarker.setMap(null);
      MAN.allMapContent = MAN.allMapContent.filter(item => item !== MODEL.localCurrentMarker);
    }

    MODEL.localCurrentMarker = new google.maps.Marker({
      position: MAN.userPosition || MAN.lastResortPosition,
      map: MAN.getMap(),
    });
    MAN.allMapContent.push(MODEL.localCurrentMarker);
    MAN.addedContentMessage('added user marker');
  };

  // search all current terms
  service.searchAllCurrentTerms = () => {
    const tagPromises = MODEL.MappingService.currentSearchTerms.map(tag => service.localCustomerSearch(tag));

    return Promise.all(tagPromises);
  };

  service.cacheMapState = (record) => {
    MODEL.currentMapState.routeId = $route.current.id;
    MODEL.currentMapState.zoom = MAN.getMap().getZoom();
    MODEL.currentMapState.center = MAN.getMap().getCenter();
    MODEL.currentMapState.markerId = record && record.id ? record.id : null;
  };

  // add nearby circle if radius filter is applied
  service.setNearbyCircle = () => {
    service.resetNearbyMap();
    if (MODEL.FilterService.filterCurrentMiles) {
      const map = $route.current.id !== 'dealsMapPage' ? MAN.getMap() : MODEL.FunnelService.leadsMap;
      const latlng = new google.maps.LatLng(MODEL.MappingService.localCurrentLatLng.lat, MODEL.MappingService.localCurrentLatLng.lng);
      MODEL.FilterService.nearbyCircle = new google.maps.Circle({
        strokeColor: '#3063E4',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: 'rgb(0,180,255)',
        fillOpacity: 0.25,
        map,
        center: latlng,
        editable: false,
        clickable: false,
        draggable: false,
        radius: MODEL.FilterService.filterCurrentMiles * (doesUseMiles() ? 1609.334 : 1000.0) + 7000,
      });

      MODEL.FilterService.nearbyCircle.bindTo('center', MODEL.localCurrentMarker, 'position');
    }
  };

  // reset nearby circle on map pages
  service.resetNearbyMap = () => {
    if (!MODEL.FilterService.nearbyCircle) return;

    MODEL.FilterService.nearbyCircle.setMap(null);
  };

  service.showOtherPins = () => {
    if (window.isOnPage('accounts')) {
      service.parseMappingObjects(MODEL.accounts, 'accounts', false);
    } else if (window.isOnPage('contacts')) {
      service.parseMappingObjects(MODEL.contacts, 'contacts', false);
    }
  };

  service.getBackgroundImage = (self, pinDirCheckIn, extension) => {
    // for check-ins page, everything is green (checked-in)
    if ($route.current.id === 'checkinsPage' || $route.current.id === 'checkinsMapPage') {
      return UTILS.getCheckInPinImageForVisitDate(self.args.lastVisit, MODEL.MappingService.oldestVisitInDays, pinDirCheckIn);
    }

    // for reminders page, everything is either red (overdue reminder) or black (not overdue)
    if ($route.current.id === 'remindersPage' || $route.current.id === 'remindersMapPage' || $route.current.id === 'remindersCalPage') {
      return moment(self.args.remindAt).isBefore()
        ? `url('${MODEL.MappingService.pinDir}_red${extension}')`
        : `url('${MODEL.MappingService.pinDir}${extension}')`;
    }

    return UTILS.getPinImageForColor(self.args.color);
  };

  service.setBackgroundImageRouting = (self, div) => {
    let response = false;
    const marker = $(div);
    const markerIds = new Set(self.args.marker_id);
    const index = MODEL.RoutingService.routeObjects.findIndex((row) => markerIds.has(row.id));

    if (index !== -1) {
      marker.css('background-image', 'none');
      marker.addClass('hexagon-pin');
      let color = '#000000';
      if (Object.keys(MODEL.colorsToHex).includes(self.args.color)) {
        color = MODEL.colorsToHex[self.args.color];
      }
      marker.html(`
       <svg width="47" height="36" viewBox="0 0 47 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M29.5089 2C31.2622 2 32.8968 2.9151 33.8049 4.41419L40.2665 15.0349C41.2445 16.6416 41.2445 18.6534 40.2665 20.2601L33.8049 30.878C32.8968 32.3799 31.2622 33.295 29.5089 33.295H17.431C15.7405 33.295 14.1059 32.3799 13.1977 30.878L6.73337 20.2601C5.75554 18.6534 5.75554 16.6416 6.73337 15.0349L13.1977 4.41419C14.1059 2.9151 15.7405 2 17.431 2H29.5089Z" fill="${color}"/>
          <path d="M5.87914 20.78L5.87922 20.7801L12.342 31.3954C12.3423 31.3959 12.3426 31.3964 12.3429 31.3968C13.4299 33.1935 15.3864 34.295 17.431 34.295H29.5089C31.6093 34.295 33.5692 33.1995 34.6598 31.3968C34.6601 31.3964 34.6604 31.3959 34.6607 31.3954L41.1207 20.78C42.2931 18.854 42.2931 16.441 41.1207 14.515L34.6602 3.89606C34.6601 3.89584 34.66 3.89563 34.6599 3.89541C33.5692 2.09555 31.6094 1 29.5089 1H17.431C15.3865 1 13.4301 2.10139 12.343 3.89507C12.3428 3.8954 12.3426 3.89573 12.3424 3.89606L5.87916 14.515L5.87914 14.515C4.70695 16.441 4.70695 18.854 5.87914 20.78Z" stroke="white" stroke-width="2"/>
        </g>
      </svg>
      <span>${index + 1}</span>
    `);
      response = true;
    }
    self.args.previousPinColor = div.style.backgroundImage;
    return response;
  };

  //
  // ---------------------- Map Markers Functions ---------------------- //
  //

  // parse objects to be shown in the map
  service.parseMappingObjects = (objectData, tableName, refresh = true) => {
    if (refresh) {
      MAN.nukeMapContent();
    }

    MODEL.MappingService.localCurrentLatLng = MAN.userPosition || MAN.lastResortPosition;

    if (UTILS.isOnPage('individualAccountsRoutePage') || UTILS.isOnPage('individualContactsRoutePage')) {
      objectData = service.filterBasedOnCurrentRoute(objectData);
      // apply line & bounds to map
      if (MODEL.RoutingService.encodedPolyline) {
        MODEL.RoutingService.encodedPolyline.setMap(MAN.getMap());
        MAN.allMapContent.push(MAN.routePolyline);
      }
    }

    MODEL.bounds = new google.maps.LatLngBounds();
    MODEL.customersOverlay = [];
    // message the data (check if one pin location has multiple contacts)
    const interpolatedCustomers = service.interpolateData(objectData);
    // set map to current location if user has no pins
    if (!interpolatedCustomers.length && !MAN.hasSavedZoomOrCenter()) {
      // if zeo pins are found, map it to user location
      window.refreshDom({numberOfObjects: 0}, 'currentPageSubHeader');
      const latitude = MODEL.MappingService.localCurrentLatLng.lat;
      const longitude = MODEL.MappingService.localCurrentLatLng.lng;
      const latlng = new google.maps.LatLng(latitude, longitude);
      MODEL.bounds.extend(latlng);
      MAN.getMap().fitBounds(MODEL.bounds);
      MAN.getMap().setZoom(7);
      return;
    }

    // -----> loop thru all interpolated customers
    interpolatedCustomers.forEach((interpolatedCustomer) => {
      service.createCustomerMarkers(interpolatedCustomer, tableName);
    });

    // for each interpolatedCustomers
    const shouldNukeMap = !MODEL.MappingService.findNewCustomers || (MODEL.MappingService.findNewCustomers && !MODEL.MappingService.showOtherPins);
    if (shouldNukeMap && !MAN.hasSavedZoomOrCenter()) {
      service.setMapBounds(MODEL.bounds);
    }

    service.setUserMarker();
    MODEL.markerCluster = new MarkerClusterer(MAN.getMap(), MODEL.customersOverlay, MODEL.mcOptions);
    MAN.markerCluster = MODEL.markerCluster;

    if (UTILS.isOnPage('territoriesMapPage')) {
      if (objectData.length) {
        objectData.forEach(territory => {
          if (!territory.territoryDetail.hidden && territory.polygon && territory.polygonLabel) {
            territory.polygon.setMap(MAN.getMap());
            MAN.allMapContent.push(territory.polygon);
            MAN.addedContentMessage('added territory polygon');
            territory.polygonLabel.setMap(MAN.getMap());
            MAN.allMapContent.push(territory.polygonLabel);
            MAN.addedContentMessage('and its label');
          }
        });
      }
    }

    // set in the bounds of the nearby circle
    if (MODEL.FilterService.filtersSelected && !MAN.hasSavedZoomOrCenter()) {
      const radiusFilter = MODEL.FilterService.filtersSelected.find(filter => filter.category === 'radius');
      if (radiusFilter) {
        MAN.getMap().fitBounds(MODEL.FilterService.nearbyCircle.getBounds());
      }
    }

    if (MAN.hasSavedZoomOrCenter()) {
      MAN.goToSavedZoomAndCenter();
    }
  };

  service.createCustomerMarkers = function (interpolatedData, tableName) {
    const crmObject = service.processInterpolatedObjects(tableName, interpolatedData);
    // customer overlay pin
    const latlng = new google.maps.LatLng(crmObject.latitude, crmObject.longitude);

    if (crmObject.username !== safeLocalStorage.currentUser.username) {
      MODEL.othersPins += 1;
    }

    const markerArgs = service.getMapMarkersArgs(tableName, crmObject, latlng);
    MODEL.newMarker = new service.CustomMarker(
      latlng,
      MAN.getMap(),
      markerArgs,
    );

    if (UTILS.isOnPage('accountsRoutingMapPage', 'contactsRoutingMapPage') && MODEL.RoutingService.isEditingRoute) {
      crmObject.id.forEach((id) => {
        if (MODEL.RoutingService.currentRouteObjects.indexOf(id) >= 0) {
          MODEL.bounds.extend(latlng);
        }
      });
    } else {
      MODEL.bounds.extend(latlng);
    }

    MODEL.customersOverlay.push(MODEL.newMarker);
    MAN.allMapContent.push(MODEL.newMarker);
  };

  // interpolate data to combine multiple contacts into one pin
  service.interpolateData = function (customers) {
    const newCustomersArray = [];
    const tempObj = {};

    for (let i = 0; i < customers.length; i++) {
      let multiplePin = false;
      const lat = service.getCustomerLatOrLong(customers[i], 'latitude');
      const lng = service.getCustomerLatOrLong(customers[i], 'longitude');

      if (newCustomersArray.length) {
        if (tempObj[lat] && tempObj[lat][lng]) {
          newCustomersArray[tempObj[lat][lng].index].crmObject.push(customers[i]);
          multiplePin = true;
        }
      }

      if (!multiplePin) {
        tempObj[lat] = tempObj[lat] || {};
        tempObj[lat][lng] = {index: newCustomersArray.length};
        newCustomersArray.push({crmObject: [customers[i]], lat, lng});
      }
    }

    return newCustomersArray;
  };

  service.getCustomerLatOrLong = function (customer, meridian) {
    if (customer[meridian]) {
      return customer[meridian];
    } if (customer.contact && customer.contact.geoPoint) {
      return meridian === 'longitude' ? customer.contact.geoPoint.coordinates[0] : customer.contact.geoPoint.coordinates[1];
    } if (customer.account && customer.account.geoPoint) {
      return meridian === 'longitude' ? customer.account.geoPoint.coordinates[0] : customer.account.geoPoint.coordinates[1];
    } if (customer.geoPoint) {
      return meridian === 'longitude' ? customer.geoPoint.coordinates[0] : customer.geoPoint.coordinates[1];
    }
    return null;
  };

  // procees interpolated objects for adding to the map
  service.processInterpolatedObjects = (tableName, interpolatedData) => {
    let crmObject;

    for (let i = 0; i < interpolatedData.crmObject.length; i++) {
      const data = interpolatedData.crmObject[i];

      if (i === 0) {
        crmObject = service.buildCommonFields(data);
        switch (tableName) {
          case 'accounts':
            crmObject.numEmployees = [data.numEmployees];
            break;
          case 'contacts':
            crmObject.email = [data.email];
            crmObject.account = [data.account];
            break;
        }
      } else {
        crmObject.id.push(data.id);
        crmObject.name.push(data.name);
        crmObject.addressPool.push(UTILS.buildCompleteAddress(data));
        crmObject.phone.push(data.phone);
        crmObject.groups.push(data.groups);
        crmObject.color.push(data.color);
        switch (tableName) {
          case 'accounts':
            crmObject.numEmployees.push(data.numEmployees);
            break;
          case 'contacts':
            crmObject.email.push(data.email);
            crmObject.account.push(data.account);
            break;
        }
      }
    }
    crmObject.groups = crmObject.groups
      .filter(group => !!group)
      .reduce((result, group) => result.concat(group), []); // flatten array
    crmObject.color = crmObject.color.filter(color => !!color);

    ['latitude', 'longitude'].forEach((meridian) => {
      if (!crmObject[meridian]) {
        crmObject[meridian] = meridian === 'longitude' ? interpolatedData.lng : interpolatedData.lat;
      }
    });
    return crmObject;
  };

  service.buildCommonFields = (data) => ({
    id: [data.id],
    name: [data.name],
    addressPool: [UTILS.buildCompleteAddress(data)],
    address: data.address,
    city: data.city,
    region: data.region,
    postalCode: data.postalCode,
    country: data.country,
    phone: [data.phone],
    latitude: data.latitude,
    longitude: data.longitude,
    groups: [data.groups],
    color: [data.color],
  });

  // get the data to be set for pin on the map
  service.getMapMarkersArgs = (tableName, crmObject) => {
    const markerArgs = service.buildCommonMarkerArgs(crmObject);

    if (tableName === 'accounts') {
      markerArgs.numEmployees = crmObject.numEmployees;
    } else if (tableName === 'contacts') {
      markerArgs.email = crmObject.email;
      markerArgs.account = crmObject.account;
    }

    return markerArgs;
  };

  service.buildCommonMarkerArgs = (crmObject) => ({
    marker_id: crmObject.id,
    latitude: crmObject.latitude,
    longitude: crmObject.longitude,
    name: crmObject.name,
    city: crmObject.city,
    region: crmObject.region,
    address: crmObject.address,
    addressPool: crmObject.addressPool,
    postalCode: crmObject.postalCode,
    country: crmObject.country,
    phone: crmObject.phone,
    color: service.getPinColor(crmObject),
  });

  const _getColorFromTheMostRecentGroup = groups => {
    const filterGroupIds = MODEL.FilterService.currentSelectedGroups || [];

    // only keep groups which have color and which are within the filter if filter is set
    const groupsWithColorsWithinFilter = groups
      .filter(({color}) => !!color)
      .filter(({id}) => !filterGroupIds.length || filterGroupIds.includes(id));

    if (!groupsWithColorsWithinFilter.length) {
      return;
    }

    const mostRecentGroupWithColor = groupsWithColorsWithinFilter
      .map(group => ({...group, updatedAt: moment(group.updatedAt)}))
      .reduce((result, group) => (group.updatedAt.isAfter(result.updatedAt) ? group : result));

    return mostRecentGroupWithColor.color;
  };

  // process color required to be set for the pin
  service.getPinColor = (crmObject) => {
    let color;

    if (window.isOnPage('groups/list') && MODEL.GroupsService.currentGroup) {
      color = MODEL.GroupsService.currentGroup.color;
    }

    if (!color && crmObject.groups.length) {
      color = _getColorFromTheMostRecentGroup(crmObject.groups);
    }

    if (crmObject.color && MODEL.MappingService.toggleCadence) {
      color = crmObject.color[0];
    }

    if (!color) {
      const colors = (Array.isArray(crmObject.color) ? crmObject.color : [crmObject.color]).filter(color => !!color);
      if (colors.length) {
        color = colors[0];
      }
    }

    if (!color) {
      // This is some legacy logic inside this IF which I kept for better backwards compatibility

      // prioritise group color over local pin color
      if (MODEL.singleGroupPageFilter && MODEL.currentPageGroupsColors[MODEL.singleGroupPageFilter]) {
        color = MODEL.currentPageGroupsColors[MODEL.singleGroupPageFilter];
      } else {
        let keepGoing = true;
        crmObject.groups.forEach((group) => {
          if (keepGoing && MODEL.currentPageGroupsColors[group]) {
            // color is set for a required group or shown group --> more important
            if (window.groupsOrObjectIdPassFilter(group)[0]) {
              color = MODEL.currentPageGroupsColors[group];
              keepGoing = false;
            } else {
              color = MODEL.currentPageGroupsColors[group];
            }
          }
        });
      }
    }

    return color || 'black';
  };

  service.setMapBounds = (bounds) => {
    if ('routeId' in MODEL.currentMapState) {
      // if back to the same page -> set map zoom and bounds -> destroy cache
      if (MODEL.currentMapState.routeId === $route.current.id) {
        MAN.getMap().setCenter(MODEL.currentMapState.center);
        MAN.getMap().setZoom(MODEL.currentMapState.zoom);
        MODEL.currentMapState = {};
      } else if (!UTILS.isOnPage('territoriesMapPage')) {
        MAN.getMap().fitBounds(bounds);
      }
    } else if (!UTILS.isOnPage('territoriesMapPage')) {
      // fit map to bounds
      if (MAN.getMap()) {
        MAN.getMap().fitBounds(bounds);
      }
    }
  };

  // show records limit warning modal
  service.showRecordLimitWarningModal = (recordCount = 0) => {
    if (recordCount < MODEL.recordsUpperLimit || MODEL.hideMessage || MODEL.RoutingService.creatingRoute) {
      return;
    }

    MODEL.recordCount = recordCount;
    service.modalsActions.showModal('recordLimitWarningModal');
  };

  // filter mapping data structure based on the current route
  service.filterBasedOnCurrentRoute = (objectData) => {
    MODEL.RoutingService.routeObjects.forEach(routeObject => {
      const contact = find(objectData, {id: routeObject.id});
      if (!contact) {
        objectData.push(routeObject);
      }
    });
    return objectData;
  };

  let getDetailsService;
  /**
     * Gets additional details for given place.
     * @param {string} placeId place id
     * @param {string[]|undefined} fields fields to request, requests all fields if not specified
     * @returns {Promise<PlaceResult>}
     */
  service.getPlaceDetails = (placeId, fields) => {
    const request = {placeId};
    if (fields) {
      request.fields = fields;
    }

    if (!getDetailsService) {
      try {
        // only create one service
        getDetailsService = new google.maps.places.PlacesService(MAN.getMap());
      } catch (e) {
        console.error('Failed to create PlacesService: ', e);
        return Promise.reject();
      }
    }
    return new Promise((resolve, reject) => {
      try {
        getDetailsService.getDetails(request, (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(place);
          } else {
            console.error('Failed to get details for placeId', placeId);
            reject();
          }
        });
      } catch (e) {
        console.error('Failed to execute getDetails request for placeId', placeId, e);
        reject();
      }
    });
  };

  service.getAddressFromEntity = async (entity) => {
    let country;
    let region;
    let city;
    let address;
    let postalCode;
    try {
      ({
        country, region, city, address, postalCode,
      } = await service.parsePlaceAddress(entity));
    } catch (e) {
      // No action, we proceed with no address details
    }
    return {
      country,
      region,
      city,
      address,
      postalCode,
      website: entity.website,
      phone: entity.international_phone_number,
    };
  };

  /**
     * Parses PlaceResult's address into a plane object with address details.
     * @param {{address_components: {long_name: string, short_name: string, types: string[]}[]}} place
     * @return {{country: string|undefined, region: string|undefined, city: string|undefined, address: string|undefined, postalCode: string|undefined}}
     * @see https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
     */
  service.parsePlaceAddress = async (place) => {
    if (!place.address_components) {
      // failover should never normally happen, but we'd like to have it in case when there's no address_components
      // so that we're still able to provide some address information to the user
      return service.failoverParseAddressDetails(place);
    }

    const addressDetails = place.address_components ?? [];

    let item;

    // use locality or sub-locality for city
    let city;
    item = addressDetails.find((item) => item.types.includes('locality'));
    if (item) {
      city = item.long_name;
    } else {
      item = addressDetails.find((item) => item.types.includes('sublocality'));
      city = item ? item.long_name : '';
    }

    item = addressDetails.find((item) => item.types.includes('administrative_area_level_1'));
    const region = item ? item.long_name : '';

    item = addressDetails.find((item) => item.types.includes('postal_code'));
    const postalCode = item ? item.long_name : '';

    item = addressDetails.find((item) => item.types.includes('country'));
    const countryCode = item ? item.short_name : '';

    // Getting street address field is tough. Because:
    // 1. street number and address fields order may differ. It is "SN, ADDR" in US, but "ADDR, SN" in Russia
    // 2. there's no ready-to-use field, besides placeDetails might be a street address without building number
    // There's two ways to take street address. One is more reliable: extract it from adr_address field
    // which has the following format: `<span class="street_address">SN+ADDR</span>, <span class="locality">CITY</span>, ...`
    // We try to extract value of the span with "street_address" class name.
    // The other, fallback way is to take formatted_address field. However, it includes all other
    // details too: city, state, etc. To remove these details from there, we collect all non street
    // number and non address fields values and then replace these values in formatted_address by an
    // empty string. Resulting value is [hopefully] a street address.
    let address = '';
    try {
      let tag = (place.adr_address || '')
        .split(/<\/\w+>(,\s*)?/) // split by closing tag
        .find((s) => s.includes('class="street-address"')); // find tag with street-address class name
      tag = tag ? tag.split(/<\w+[^>]*>/) : ''; // remove opening tag or use empty string as a result
      address = tag ? tag[1] : '';
    } catch (e) {
      // nevermind
    }
    if (!address) {
      const nonStreetAddressValues = addressDetails
        .filter((item) => !item.types.includes('route') && !item.types.includes('street_number'))
        .flatMap((item) => [item.long_name, item.short_name]);
      address = nonStreetAddressValues
        .reduce(
          (result, name) => result.replace(new RegExp(`(,\\s*)?${name}`, 'g'), ''),
          place.formatted_address || '',
        )
        .replace(/(^,+)|(,+$)/g, ''); // remove any leading/trailing commas
    }

    return {city, region, address, postalCode, countryCode, country: countryCode};
  };

  /**
     * Failover parsing for address details. Used when place has no address_components (e.g. when we failed to get
     * details for place via getPlaceDetails method). This function is needed to get at least some address details
     * to auto-populate form for user.
     *
     * @param {PlaceResult} place
     * @return {Promise<>{country: string|undefined, region: string|undefined, city: string|undefined, address: string|undefined, postalCode: string|undefined}>}
     * @see #getPlaceDetails
     * @see #parsePlaceAddress
     */
  service.failoverParseAddressDetails = async (place) => {
    const result = {};

    let commaPosition = place.vicinity.lastIndexOf(',');
    if (commaPosition >= 0) {
      result.address = place.vicinity.substring(0, commaPosition).trim();
      result.city = place.vicinity.substring(commaPosition + 1).trim();
    }

    if (place.plus_code && place.plus_code.compound_code) {
      commaPosition = place.plus_code.compound_code.lastIndexOf(',');
      if (commaPosition >= 0) {
        result.country = place.plus_code.compound_code.substring(commaPosition + 1).trim();
      }
    }

    if (get(place, 'geometry.location.lat') && get(place, 'geometry.location.lng')) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      const response = await GeocodingNetworkService.reverseGeocodeAddress(lat, lng);
      result.postalCode = get(response, 'address.postalCode');
      result.region = get(response, 'address.region');
    }

    return result;
  };

  service.addressObjToString = (addressObj) => {
    let addressString = ['address', 'city', 'country', 'postalCode', 'region'].reduce((str, segment) => {
      if (addressObj[segment]) {
        str += `${addressObj[segment]}, `;
      }

      return str;
    }, '');

    addressString = addressString.substring(0, addressString.length - 2);

    return addressString;
  };

  return service;
}

MappingService.$inject = [
  '$compile', '$route', 'LassoService', 'FunnelService', 'mmcConst',
  'SettingsService',
];
