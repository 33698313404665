import angular, {IAngularEvent} from 'angular';
import 'regenerator-runtime/runtime';
import 'bootstrap';
import moment from 'moment';
import Leaflet from 'leaflet';
import get from 'lodash/get';
import ngSanitize from 'angular-sanitize';
import ngRoute from 'angular-route';
import ngFileUpload from 'ng-file-upload';
import uiBootstrap from 'angular-ui-bootstrap';
import 'angular-drag-and-drop-lists/angular-drag-and-drop-lists';
import uiSelect from 'ui-select';
import 'ngstorage';
import 'angular-paging';
import swal from 'sweetalert2';
import EntityType from './react/type/EntityType';

import './module';
import '../styles/all.scss';
import i18n from './i18n';
import {Env} from './config';
import envConfig from './env';
import {addCustomersFromAnywhere, beginOnboardImportFromAnywhere} from './common/misc-globals';
import safeLocalStorage from './shared-services/safe-local-storage-service';
import analyticsService from './shared-services/analytics-service';
import userNetworkService from './network-services/user-network-service';

import appConfig from './app.config';

import {ChangePasswordController, changePassword} from './common/change-password/change-password-component';
import {IntegrationsModalController, integrationsModal} from './common/integrations-modal/integrations-modal-component';
import {ImportProgressModalController, importProgressModal} from './common/import-progress-modal/import-progress-modal-component';
import {AccountPhotoBoxController, accountPhotoBox} from './common/account-photo-box/account-photo-box-component';

// MODULES
import AccountModule from './account/account-module';
import AccountsModule from './accounts/accounts-module';
import LeadsModule from './leads/leads-module';
import CommonModule from './common/common-module';
import ComponentsModule from './components/components-module';
import ReactComponentsModule from './react/react-components-module';
import ContactsModule from './contacts/contacts-module';
import CrmActivitiesModule from './crm-activities/crm-activities-module';
import CustomFieldsModule from './custom-fields/custom-fields-module';
import DashboardModule from './dashboard/dashboard-module';
import DealsModule from './deals/deals-module';
import FilesModule from './files/files-module';
import GroupsModule from './groups/groups-module';
import ImportModule from './import/import-module';
import IntegrationsModule from './integrations/integrations-module';
import LoginModule from './login/login-module';
import MappingModule from './mapping/mapping-module';
import NetworkServicesModule from './network-services/network-services-module';
import RegisterModule from './register/register-module';
import ReportsModule from './reports/reports-module';
import SharedServicesModule from './shared-services/shared-service-module';
import UpgradeModule from './account/upgrade/upgrade-module';
import addDirectives from './common/directives';

Leaflet.Icon.Default.imagePath = '';
// @ts-ignore
delete Leaflet.Icon.Default.prototype._getIconUrl;
Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: 'images/leaflet/node_modules/leaflet/dist/images/marker-icon-2x.png',
  iconUrl: 'images/leaflet/node_modules/leaflet/dist/images/marker-icon.png',
  shadowUrl: 'images/leaflet/node_modules/leaflet/dist/images/marker-shadow.png',
});

declare global {
  const __BASE_MMC_API_URL__: string;
  const __MARKETING_URL__: string;
  const __INTEGRATIONS_URL__: string;
  const __BASE_APP_URL__: string;
  const __BASE_NEW_APP_URL__: string;
  const __STRIPE_CHECKOUT_KEY__: string;
  const __INTERCOM_APP_ID__: string;
  const __NYLAS_APP_ID__: string;
  const __NYLAS_APP_SECRET__: string;
  const __RECAPTCHA_SITE_KEY__: string;
  const __APP_VERSION__: string;
  const __PRODUCTION__: boolean;
  const __GOOGLE_CLIENT__: string;
  const __GOOGLE_KEY__: string;
  const __HUBSPOT_AUTH_URL__: string;
  const __ZENDESK_LOAD_CODE__: string;

  interface Window {
    DataModel: { [key: string]: any },
    isOnPage: (pageName: string) => boolean,
    refreshDom: (argObj: { [key: string]: any }, modKey?: string) => void,
    __env: Env,
    Intercom?: Intercom_.IntercomStatic,
    addCustomersFromAnywhere: Function,
    beginOnboardImportFromAnywhere: Function,
    mmcGlobals: object,
    swal: typeof swal,
    accessToken: string | undefined,
    $: typeof jQuery,
    zE: {
      activate: () => void
    },
    $apply: Function,
    addCrmObject: (entityType?: EntityType) => void,

    heap: any,
    dataLayer: Array<any>,
    mmcUtils: any,
    integrationChangeUrl?: (url: string) => void,
  }
}

window.addCustomersFromAnywhere = addCustomersFromAnywhere;
window.beginOnboardImportFromAnywhere = beginOnboardImportFromAnywhere;
window.mmcGlobals = {};
window.swal = swal;

(function () {
  // Credits for chrome browser checks go to this answer: https://stackoverflow.com/a/13348618/5346779
  // @ts-ignore
  const isChromium = window.chrome;
  const nav = window.navigator;
  const vendorName = nav.vendor;
  // @ts-ignore
  const isOpera = typeof window.opr !== 'undefined';
  const isIEOrEdge = nav.userAgent.indexOf('Edg') > -1;
  const isChromeOS = nav.userAgent.match('CriOS');

  const isChrome = isChromeOS
    || (isChromium !== null && typeof isChromium !== 'undefined' && vendorName === 'Google Inc.' && !isOpera && !isIEOrEdge);

  if (!isChrome) {
    const browserAlertLastShown = safeLocalStorage.browserAlertLastShown;
    if (!browserAlertLastShown || (browserAlertLastShown && moment().subtract(7, 'days').isAfter(browserAlertLastShown))) {
      safeLocalStorage.browserAlertLastShown = moment().valueOf();
      swal('Unsupported Browser', 'We recommend using the latest version of Chrome for the best experience.');
    }
  }
}());

envConfig(window);

i18n.changeLanguage('en');

const runApplication = () => {
  /**
   * Main module of the application.
   */
  angular.module('mapmycustomersApp', [
    'dndLists', ngRoute, uiBootstrap, 'ngStorage', ngFileUpload, 'bw.paging', 'debounce',
    'infinite-scroll', uiSelect, ngSanitize, SharedServicesModule, NetworkServicesModule,
    ComponentsModule, ReactComponentsModule, CommonModule, UpgradeModule, DashboardModule, GroupsModule, ReportsModule,
    ContactsModule, DealsModule, CrmActivitiesModule, AccountModule, AccountsModule, LeadsModule,
    FilesModule, ImportModule, IntegrationsModule, LoginModule, MappingModule,
    RegisterModule, CustomFieldsModule,
  ]).config(appConfig);

  // Event handler to update metadata on index template page
  angular.module('mapmycustomersApp').run(
    ['$rootScope', '$location', '$route', '$q', '$window', 'FilterService', 'MainService', function ($rootScope: any, $location: any, $route: any, $q: any, $window: any, FilterService: any, MainService: any) {
      $rootScope.apiTimeout = null;
      window.accessToken = '';

      const relogin = (message = 'Your session has expired. Please login again to continue - your security is our top concern.') => {
        safeLocalStorage.clear();
        window.DataModel.loginErrorMessage = message;
        window.location.href = '#/login';
      };

      // add reload option to path (used for partial loads (:id) in routes)
      const original = $location.path;
      $location.path = (path: string, reload: boolean) => {
        if (reload === false) {
          const lastRoute = $route.current;
          const un = $rootScope.$on('$locationChangeSuccess', () => {
            $route.current = lastRoute;
            un();
          });
        }
        return original.apply($location, [path]);
      };

      const crmObjectTypes = ['contacts', 'accounts', 'deals'];
      // simple and stupid getCurrentCrmObjectType implementation. Better use map a of id to crm type, but it works good
      const getCurrentCrmObjectType = (id: string) =>
        (id ? crmObjectTypes.find(type => id.toLowerCase().includes(type)) : undefined);

      $rootScope.$on('$routeChangeStart', (event: IAngularEvent, next: any, current: any) => {
        const nonProtectedRoutes = [
          'loginPage', 'registerPage', 'forgotPassword', 'resetPassword', 'verifyPage',
          'emailUnsubscribePage', 'createTeamPage', '404',
        ];

        const currentUser = safeLocalStorage.currentUser;
        if (!currentUser || (!currentUser.id || !currentUser.username)) {
          if (!nonProtectedRoutes.includes(next.$$route.id)) {
            return relogin();
          }
        }

        const MODEL = window.DataModel;

        window.refreshDom({'GroupsService.showActivityTypeFilter': false});
        window.refreshDom({'MappingService.toggleCadence': false});

        MODEL.recordView = false;

        MODEL.isFreeTrial = !!get(currentUser, 'organization.plan.trialing');
        if (MODEL.isFreeTrial) {
          MODEL.trialDaysRemaining = moment(currentUser!.organization.trialExpiresAt).diff(new Date(), 'days');
          MODEL.trialExpired = MODEL.trialDaysRemaining < 0;
        }

        if (MODEL.trialExpired) {
          if (next.$$route.id !== 'upgrade' && next.$$route.id !== 'teamPage') {
            window.location.href = '#/upgrade';
          }
        }

        $window.DataModel.show.filters = false;

        const currentCrmObject = getCurrentCrmObjectType(current && current.$$route && current.$$route.id);
        const nextCrmObject = getCurrentCrmObjectType(next && next.$$route && next.$$route.id);
        // reset filter when crm object type has changed
        if (currentCrmObject && currentCrmObject !== nextCrmObject) {
          FilterService.resetFilterModel();
        }
        if ($rootScope.apiTimeout) {
          $rootScope.apiTimeout.resolve();
          $rootScope.apiTimeout = null;
        }
        $('#resumeTourPop').hide();
        $('#tourOverlay').hide();
        $rootScope.showTourOverlay = false;
        $('.hopscotch-bubble').hide();

        if (current && current.id === 'accountsMapPage') {
          if (MODEL && MODEL.MappingService && MODEL.MappingService.findNewCustomers) {
            MODEL.MappingService.findNewCustomers = false;
            $('#mapViewByUser').show();
            $('#subheader').show();
            $('#leadGenMultipleSelectButton').hide();
            MODEL.MappingService.showLeadGenMultipleSelect = false;
          }
        }
      });

      $rootScope.$on('$routeChangeSuccess', () => {
        $rootScope.apiTimeout = $q.defer();
        $('#resumeTourPop').show();

        // page title
        if ($route.current.metadata !== undefined) {
          document.title = $route.current.metadata.title;

          // page meta description tag
          const metaDescrip = document.querySelector('meta[name=description]');
          metaDescrip!.setAttribute('content', $route.current.metadata.description);

          // page keywords tag
          const metaKeywords = document.querySelector('meta[name=keywords]');
          metaKeywords!.setAttribute('content', $route.current.metadata.keywords);

          // set active state for sidebar
          $('.nav li').removeClass('active');
          $(`#${$route.current.id}`).addClass('active');
        }

        analyticsService.page();
        MainService.updateImportProgress();
      });
    }])
    // ------------------*** HTTP Interceptor **-----------------
    .factory('httpInterceptor', ['$q', function ($q: any) {
      const relogin = (message = 'Your session has expired. Please login again to continue - your security is our top concern.') => {
        safeLocalStorage.clear();
        window.DataModel.preventSwal = true;
        window.DataModel.loginErrorMessage = message;
        window.location.href = '#/login';
      };

      const currentUser = safeLocalStorage.currentUser;

      return {
        request(config: any) {
          //      written for future use
          //     if(config.data && Object.keys(config.data).length) {
          //      let body = config.data;
          //      let bodyParams = Object.keys(body).map((key) => {
          //     return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);
          //   }).join('&');
          //   config.data = bodyParams;
          //   config["Content-Type"] = "application/x-www-form-urlencoded";
          // }
          return config || $q.when(config);
        },
        requestError(rejection: any) {
          return $q.reject(rejection);
        },
        response(response: any) {
          return response || $q.when(response);
        },
        responseError(rejection: any) {
          // only check 401 and other errors if it was our backend
          if (get(rejection, ['config', 'url'], '').includes(window.__env.BaseURL)) {
            if (rejection.status === 401) {
              relogin();
            } else if (rejection.status === 209 || rejection.status === 403) {
              if (!currentUser || (!currentUser.id || !currentUser.username)) {
                relogin();
              }
            }
          }

          return $q.reject(rejection);
        },
      };
    }])
    .component('changePassword', changePassword)
    .controller('ChangePasswordController', ChangePasswordController)
    .component('integrationsModal', integrationsModal)
    .controller('IntegrationsModalController', IntegrationsModalController)
    .controller('ImportProgressModalController', ImportProgressModalController)
    .component('importProgressModal', importProgressModal)
    .controller('AccountPhotoBoxController', AccountPhotoBoxController)
    .component('accountPhotoBox', accountPhotoBox);
  // end component registration

  addDirectives(angular.module('mapmycustomersApp'));

  angular.element(() => {
    angular.bootstrap(document, ['mapmycustomersApp'], {strictDi: true});
  });
};

// check auth token presence in the url
const urlParams = new URLSearchParams(window.location.search);
// if token is present and doesn't match the one in the local storage,...
if (urlParams.has('token') && urlParams.get('token') !== safeLocalStorage.accessToken) {
  // ... put it into the safe local storage
  safeLocalStorage.accessToken = urlParams.get('token');
  // remove it from the url
  window.history.replaceState({}, document.title, window.location.origin + window.location.pathname + window.location.hash);
  // load user data
  userNetworkService.getUserData()
    .then((userData) => {
      if (userData.emailVerified) {
        safeLocalStorage.currentUser = userData;
      }
    })
    .finally(() => {
      // and finally start the app
      runApplication();
    });
} else if (urlParams.has('logout')) {
  safeLocalStorage.clear();
  if (window.__env.isProduction) {
    window.location.href = 'https://mapmycustomers.com';
  } else {
    window.location.href = '/#/login';
  }
} else {
  runApplication();
}
