import React, {memo, useCallback} from 'react';
import Checkbox from 'react-md/lib/SelectionControls/Checkbox';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {findByValue} from '../util/util';
import {PlanCard, PlanDescription, PlanName, PlanPrice, PlanText} from './PlanCard';
import ServiceType from '../../../type/plans/Service';

const ServiceCheckbox = styled(Checkbox)`
  margin: 20px 8px 20px 25px;
`;

interface Props {
  checked: boolean,
  liveLicenses: number,
  licenses: number,
  onCheck?: (id: ServiceType['id'], checked: boolean) => void,
  service: ServiceType,
}

const Service = memo<Props>(({checked, licenses, onCheck, service}) => {
  const price = findByValue(service.intervalPrice, licenses);

  const {t} = useTranslation(['billing']);
  const handleChange = useCallback((checked) => onCheck && onCheck(service.id, checked), [onCheck, service.id]);

  if (!price) {
    return null;
  }

  return (
    <PlanCard>
      <ServiceCheckbox
        aria-label={service.name}
        checked={checked}
        disabled={!price}
        id={`${service.id}-checkbox`}
        name={`${service.id}-${service.name}`}
        onChange={handleChange}
      />
      <PlanText isAvailable={!!price}>
        <PlanName htmlFor={`${service.id}-checkbox`} pointer>{service.name}</PlanName>
        {service.description && (<PlanDescription>{service.description}</PlanDescription>)}
      </PlanText>
      <PlanPrice>
        <div>{t('price', {price})}</div>
      </PlanPrice>
    </PlanCard>
  );
});

export default Service;
