import styled from 'styled-components';

const CardActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
  margin-bottom: -0.2rem;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

export default CardActions;
