import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import countryList from '../common/field-model/country-list.json';
import EntityType from '../react/type/EntityType';
import Company from '../react/type/Company';
import Person from '../react/type/Person';
import Deal from '../react/type/Deal';
import Group from '../react/type/Group';

interface CountryRecord {
  label: string,
  value: string,
}
const countryMap: {[key: string]: string} = {};
const reverseCountryMap: {[key: string]: string} = {};

countryList.forEach((item: CountryRecord) => {
  countryMap[item.value] = item.label;
  reverseCountryMap[item.label] = item.value;
});

type CompanyPersonOrDeal = EntityType.COMPANY | EntityType.PERSON | EntityType.DEAL;

const entityTypeToGroupsParamName: {[key in CompanyPersonOrDeal]: string} = Object.freeze({
  [EntityType.COMPANY]: 'accountGroups',
  [EntityType.PERSON]: 'contactGroups',
  [EntityType.DEAL]: 'dealGroups',
  [EntityType.LEAD]: 'leadGroups',
});

const entityTypeToIdParamName: {[key in CompanyPersonOrDeal]: string} = Object.freeze({
  [EntityType.COMPANY]: 'accountId',
  [EntityType.PERSON]: 'contactId',
  [EntityType.DEAL]: 'dealId',
  [EntityType.LEAD]: 'leadId',
});

const entityTypeToUrlPath: {[key in CompanyPersonOrDeal]: string} = Object.freeze({
  [EntityType.COMPANY]: 'account-groups',
  [EntityType.PERSON]: 'contact-groups',
  [EntityType.DEAL]: 'deal-groups',
  [EntityType.LEAD]: 'lead-groups',
});

export class AddEditNetworkService {

  async getRecord(table: string, id: number, filters: {[key: string]: any} = {}) {
    const org = safeLocalStorage.currentUser!.organization.id;
    let endPoint = `organizations/${org}/${table}/${id}`;

    filters.includeAccessStatus = true;

    if (Object.keys(filters).length) {
      endPoint += `?$filters=${encodeURIComponent(JSON.stringify(filters))}`;
    }
    return BaseNetworkServiceWithPromises.read(endPoint)
      .then(record => {
        if (record.countryCode) {
          record.country = record.countryCode;
        } else if (record.country && !countryMap[record.country] && reverseCountryMap[record.country]) {
          record.country = reverseCountryMap[record.country];
        }
        return record;
      });
  }

  async getGroups(entityType: CompanyPersonOrDeal) {
    const org = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${org}/${entityTypeToUrlPath[entityType]}`;
    const {recordsUpperLimit} = window.DataModel;
    return BaseNetworkServiceWithPromises.read(endPoint, {$limit: recordsUpperLimit});
  }

  async addGroups(entityType: CompanyPersonOrDeal, entityId: number, groupIds: Group['id'][]) {
    const org = safeLocalStorage.currentUser!.organization.id;
    return Promise.all(groupIds.map(groupId => {
      const endPoint = `organizations/${org}/${entityTypeToUrlPath[entityType]}/${groupId}/${entityType}/bulk-create`;
      return BaseNetworkServiceWithPromises.create(
        endPoint,
        {[`${entityTypeToGroupsParamName[entityType]}`]: [{[`${entityTypeToIdParamName[entityType]}`]: entityId}]},
      );
    }));
  }

  async removeGroups(entityType: CompanyPersonOrDeal, entityId: number, groupIds: Group['id'][]) {
    const org = safeLocalStorage.currentUser!.organization.id;
    return Promise.all(groupIds.map(groupId => {
      const endPoint = `organizations/${org}/${entityTypeToUrlPath[entityType]}/${groupId}/${entityType}/bulk-delete`;
      return BaseNetworkServiceWithPromises.delete(
        endPoint,
        {[`${entityTypeToGroupsParamName[entityType]}`]: [{[`${entityTypeToIdParamName[entityType]}`]: entityId}]},
      );
    }));
  }

  async addToGroup(groupId: Group['id'], entityType: CompanyPersonOrDeal, entityIds: (Company['id'] | Person['id'] | Deal['id'])[]) {
    if (!entityIds.length) {
      return Promise.resolve();
    }
    const org = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${org}/${entityTypeToUrlPath[entityType]}/${groupId}/${entityType}/bulk-create`;
    return BaseNetworkServiceWithPromises.create(
      endPoint,
      {[`${entityTypeToGroupsParamName[entityType]}`]: entityIds.map(entityId => ({[`${entityTypeToIdParamName[entityType]}`]: entityId}))},
    );
  }

  async removeFromGroup(groupId: Group['id'], entityType: CompanyPersonOrDeal, entityIds: (Company['id'] | Person['id'] | Deal['id'])[]) {
    if (!entityIds.length) {
      return Promise.resolve();
    }
    const org = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${org}/${entityTypeToUrlPath[entityType]}/${groupId}/${entityType}/bulk-delete`;
    return BaseNetworkServiceWithPromises.delete(
      endPoint,
      {[`${entityTypeToGroupsParamName[entityType]}`]: entityIds.map(entityId => ({[`${entityTypeToIdParamName[entityType]}`]: entityId}))},
    );
  }

  async updateAssociatedObjects(table: CompanyPersonOrDeal, id: Company['id'] | Person['id'] | Deal['id'], payload: {[key: string]: any}) {
    const org = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${org}/${table}/${id}`;
    return BaseNetworkServiceWithPromises.update(endPoint, payload);
  }
}

export default new AddEditNetworkService();
