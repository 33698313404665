import BaseNetworkService from './base-network-service/base-network-service';
import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import Lead from '../react/type/Lead';
import ListResponse from '../react/type/service/ListResponse';
import DataModel from '../common/data-model';

type Params = {
  $order?: string,
  $limit?: number,
  $offset?: number,
  $filters?: string,
  $columns?: string,
}

type LeadId = Lead['id'];

class LeadsNetworkService {
  fetchAllLeads(
    map: boolean,
    filters: object,
    page: number = 1,
    column: string = 'updatedAt',
    ascending: boolean = false,
    limit?: number,
    columns?: string,
  ): Promise<ListResponse<Lead>> {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    let params: Params = {
      $order: ascending ? column : `-${column}`,
    };

    if (map) {
      filters = {...filters, mapped: true};
      params.$limit = limit ?? DataModel.recordsUpperLimit;
    } else {
      if (page) {
        params.$offset = (page - 1) * (limit ?? DataModel.pageSize);
      }

      params.$limit = limit ?? DataModel.pageSize;
    }

    if (Object.keys(filters).length) {
      params = Object.assign(params, {$filters: JSON.stringify(filters)});
    }
    if (columns) {
      params.$columns = columns;
    }
    return BaseNetworkService.read(`organizations/${orgId}/leads`, params);
  }

  createLead(payload: any) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/leads`;
    return BaseNetworkService.create(endPoint, payload);
  }

  /**
     * Bulk-create leads
     * @param {{name: string, address: string, city: string, color: string}[]} leads leads to create
     * @returns {Promise}
     */
  createLeads(leads: Partial<Lead>[]) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/leads/bulk-create`;
    return BaseNetworkServiceWithPromises.create(endPoint, {leadIds: leads});
  }

  updateLead(id: LeadId, payload: Partial<Lead>) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/leads/${id}`;
    return BaseNetworkService.update(endPoint, payload);
  }

  fetchLead(id: LeadId) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    return BaseNetworkService.read(`organizations/${orgId}/leads/${id}`);
  }

  /**
   * Bulk update leads
   * @param {{id: number, color: string}[]} leads leads to update
   * @returns {*}
   */
  updateLeads(leads: Array<Pick<Lead, 'id' | 'color'>>) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/leads/bulk-update`;
    return BaseNetworkServiceWithPromises.update(endPoint, {leadIds: leads});
  }

  deleteLead(id: LeadId) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    return BaseNetworkServiceWithPromises.delete(`organizations/${orgId}/leads/${id}`);
  }

  /**
     * Bulk-deletes given leads by their ids.
     * @param {number[]} ids
     * @returns {*}
     */
  deleteLeads(ids: LeadId[]) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    return BaseNetworkServiceWithPromises.delete(
      `organizations/${orgId}/leads/bulk-delete`,
      {leadIds: ids.map(id => ({id}))},
    );
  }

  async fetchChildLeads(id: LeadId) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const filters = {
      parentLeadId: id,
    };
    const endPoint = `organizations/${orgId}/leads?$filters=${encodeURIComponent(JSON.stringify(filters))}`;
    return BaseNetworkService.read(endPoint);
  }

  // fetches all lead groups
  fetchAllLeadGroups(
    filters?: object,
    page: number = 1,
    column: string = 'displayOrder',
    ascending: boolean = false,
    limit: number = 0,
  ) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const {pageSize} = window.DataModel;
    const params = {
      $order: ascending ? column : `-${column}`,
      $filters: filters ? JSON.stringify(filters) : undefined,
      $limit: limit === 0 ? pageSize : limit,
      $offset: page ? Math.max(0, page - 1) * pageSize : undefined,
    };

    return BaseNetworkService.read(`organizations/${orgId}/lead-groups`, params);
  }

  async fetchLeadGroupsCount() {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/lead-groups?$limit=0`;

    return (await BaseNetworkServiceWithPromises.read(endPoint)).total;
  }

  /**
     * Bulk update lead groups. Will only update fields that are given in the groupsData array. Originally
     * created to bulk update group's displayOrder field.
     *
     * @param {{id: number}[]} groupsData groups data to update, must include group id and any other fields that
     * need to be updated
     * @returns {Promise<*>}
     */
  bulkUpdateLeadGroups(groupsData: Array<any>) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    const endPoint = `organizations/${orgId}/lead-groups/bulk`;
    const payload = {leadGroups: groupsData.map(data => ({...data, _action_: 'update'}))};
    return BaseNetworkServiceWithPromises.create(endPoint, payload);
  }

  // get leads count
  async getLeadsCount(): Promise<number> {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    return (await BaseNetworkService.read(`organizations/${orgId}/leads`, {$limit: 0})).total;
  }


  async convertLead(leadId: Lead['id'], payload: any): Promise<void> {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    return BaseNetworkService.update(`organizations/${orgId}/leads/${leadId}/convert`, payload);
  }
}

export default new LeadsNetworkService();
