import find from 'lodash/find';
import get from 'lodash/get';
import helperService from './helper-service';
import safeLocalStorage from './safe-local-storage-service';
import addEditNetworkService from '../network-services/add-edit-network-service';
import {doesUseMiles} from '../common/settings';

export default function FilterService(
  $route, FetchCRMDataService, MappingService, ReportsService,
  FunnelService, CacheService, AccountsService,
  AllContactsService, TerritoriesService, RoutingService, CrmActivitiesService,
) {
  const {MAN} = window.mmcUtils;
  const MODEL = window.DataModel;

  // service to return
  const service = {};

  // set up the filter to show on the page
  service.setUpFilter = () => {
    const accountLookup = {
      accountsMapPage: true,
      accountsPage: true,
      accountsGroupsListPage: true,
      accountsGroupsMapPage: true,
      accountsRoutingMapPage: true,
      individualAccountsRoutePage: true,
    };
    const contactLookup = {
      contactsMapPage: true,
      contactsPage: true,
      contactsGroupsMapPage: true,
      contactsGroupsListPage: true,
      contactsRoutingMapPage: true,
      individualContactsRoutePage: true,
    };
    const dealLookup = {
      dealsPage: true,
      dealsMapPage: true,
      dealsGroupsListPage: true,
      dealsGroupsMapPage: true,
    };

    $('#filterGroupsOption').hide();
    $('#filterNearbyLocation').show();

    if (accountLookup[$route.current.id]) {
      // selected table
      MODEL.FilterService.selectedFilterTable = 'accounts';
      MODEL.FilterService.groupsFilterModel = [];

      // set groups model
      addEditNetworkService.getGroups('accounts')
        .then(({data}) => {
          window.refreshDom({groupsFilterModel: data}, 'FilterService');
          $('#filterGroupsOption').toggle(!!data.length);
        })
        .catch((error) => {
          helperService.showAndLogError(error);
        });

      // set category model
      // TODO  - implement custom fields filter for all crm objects
      MODEL.FilterService.currentCategoryModel = MODEL.FilterService.categoryModel.accounts;
    } else if (contactLookup[$route.current.id]) {
      // selected table
      MODEL.FilterService.selectedFilterTable = 'contacts';
      MODEL.FilterService.groupsFilterModel = [];

      addEditNetworkService.getGroups('contacts')
        .then(({data}) => {
          window.refreshDom({groupsFilterModel: data}, 'FilterService');
          $('#filterGroupsOption').toggle(!!data.length);
        })
        .catch((error) => {
          helperService.showAndLogError(error);
        });

      MODEL.FilterService.currentCategoryModel = MODEL.FilterService.categoryModel.contacts;
    } else if ($route.current.id === 'reportsPage') {
      service.setUpFilterForReports();
    } else if (dealLookup[$route.current.id]) {
      // selected table
      MODEL.FilterService.selectedFilterTable = 'deals';
      // set groups model
      MODEL.FilterService.groupsFilterModel = [];
      addEditNetworkService.getGroups('deals')
        .then(({data}) => {
          window.refreshDom({groupsFilterModel: data}, 'FilterService');
          $('#filterGroupsOption').toggle(!!data.length);
        })
        .catch((error) => {
          helperService.showAndLogError(error);
        });
      MODEL.FilterService.currentCategoryModel = MODEL.FilterService.categoryModel.deals;

      if ($route.current.id === 'dealsPage') {
        $('#filterNearbyLocation').hide();
      }

      MODEL.FilterService.currentPageCount = 10;
    } else if ($route.current.id === 'territoriesMapPage') {
      // selected table
      MODEL.FilterService.selectedFilterTable = 'contacts';
      MODEL.FilterService.groupsFilterModel = [];

      addEditNetworkService.getGroups('contacts')
        .then(({data}) => {
          window.refreshDom({groupsFilterModel: data}, 'FilterService');
          $('#filterGroupsOption').toggle(!!data.length);
        })
        .catch((error) => {
          helperService.showAndLogError(error);
        });

      MODEL.FilterService.currentCategoryModel = MODEL.FilterService.categoryModel.contacts;
    } else if (['crmActivitiesPage', 'crmActivitiesCalPage'].includes($route.current.id)) {
      $('#filterNearbyLocation').hide();
      MODEL.FilterService.currentCategoryModel = MODEL.FilterService.categoryModel.crmActivities;
    }

    if ($('#filterNearbyLocation').is(':visible')) {
      service.setUpNearbyFiltering();
    }
  };

  service.setUpNearbyFiltering = () => {
    // set the latlng for the location
    MODEL.FilterService.filterNearbyLatLng = MAN.userPosition || MAN.lastResortPosition;

    // set the location in words
    if (!MODEL.FilterService.filterNearbyLocation && MODEL.FilterService.filterNearbyLatLng) {
      MODEL.show.loader = true;
      let latitude;
      let longitude;

      if (MODEL.FilterService.filterNearbyLatLng.coords) {
        latitude = MODEL.FilterService.filterNearbyLatLng.coords.latitude;
        longitude = MODEL.FilterService.filterNearbyLatLng.coords.longitude;
      } else {
        latitude = MODEL.FilterService.filterNearbyLatLng.lat;
        longitude = MODEL.FilterService.filterNearbyLatLng.lng;
      }

      MODEL.MappingService.localCurrentLatLng = {lat: latitude, lng: longitude};

      window.mmcUtils.reverseGeoCode(new google.maps.LatLng(latitude, longitude))
        .then((geocodedArray) => {
          const location = `${geocodedArray.address} ${geocodedArray.city} ${geocodedArray.state}`;
          MODEL.show.loader = false;
          window.refreshDom({filterNearbyLocation: location}, 'FilterService');
        })
        .catch((error) => {
          MODEL.show.loader = false;
          helperService.showAndLogError(error);
        });
    }
  };

  service.setUpFilterForReports = async () => {
    // selected table
    MODEL.FilterService.selectedFilterTable = MODEL.ReportsService.createReportView;
    MODEL.FilterService.currentSelectedGroups = [];

    // set category model
    MODEL.FilterService.currentCategoryModel = MODEL.FilterService.categoryModel[MODEL.ReportsService.createReportView];

    const lookup = {
      accounts: true,
      contacts: true,
      deals: true,
    };

    if (lookup[MODEL.ReportsService.createReportView]) {
      $('#filterGroupsOption').hide();

      if (MODEL.ReportsService.createReportView === 'accounts') {
        addEditNetworkService.getGroups('accounts')
          .then(({data}) => {
            window.refreshDom({groupsFilterModel: data}, 'FilterService');
            $('#filterGroupsOption').toggle(!!data.length);
          })
          .catch((error) => {
            helperService.showAndLogError(error);
          });
      }

      if (MODEL.ReportsService.createReportView === 'contacts') {
        addEditNetworkService.getGroups('contacts')
          .then(({data}) => {
            window.refreshDom({groupsFilterModel: data}, 'FilterService');
            $('#filterGroupsOption').toggle(!!data.length);
          })
          .catch((error) => {
            helperService.showAndLogError(error);
          });
      }

      if (MODEL.ReportsService.createReportView === 'deals') {
        addEditNetworkService.getGroups('deals')
          .then(({data}) => {
            window.refreshDom({groupsFilterModel: data}, 'FilterService');
            $('#filterGroupsOption').toggle(!!data.length);
          })
          .catch((error) => {
            helperService.showAndLogError(error);
          });
      }
    } else {
      MODEL.FilterService.groupsFilterModel = [];
      setTimeout(() => $('#filterGroupsOption').hide(), 0);
    }

    if (['accounts', 'contacts'].includes(MODEL.ReportsService.createReportView)) {
      setTimeout(() => $('#filterNearbyLocation').show(), 0);
      service.setUpNearbyFiltering();
    } else {
      setTimeout(() => $('#filterNearbyLocation').hide(), 0);
    }
  };

  service.checkFilterPage = () => {
    const page = $route.current.id;
    let pageFilter = '';
    switch (page) {
      case 'accountsPage':
      case 'accountsMapPage':
        pageFilter = 'accounts';
        break;
      case 'contactsPage':
      case 'contactsMapPage':
        pageFilter = 'contacts';
        break;
      case 'dealsPage':
      case 'dealsMapPage':
        pageFilter = 'deals';
        break;
      case 'reportsPage':
        pageFilter = 'reports';
        break;
    }
    return pageFilter;
  };

  // processing for adding the filter elements to the view
  service.addFilterSearchElement = () => {
    let category;
    const {FilterService} = MODEL;
    const {currentCategorySelected, currentSelectedItem} = FilterService;

    try {
      category = currentCategorySelected.value;
    } catch (error) {
      category = '';
    }

    const comparator = FilterService.currentComparatorSelected;
    let value = FilterService.currentTextSelected;
    let textView;
    if (MODEL.FilterService.currentCategorySelected.type === 'type3') {
      value = $('#filterDatePicker').data('DateTimePicker').date().toISOString();
    }
    if (currentCategorySelected.type === 'type_multiselect') {
      value = FilterService.currentSelectedIds;
      textView = currentCategorySelected.getValues()
        .filter(row => value.includes(row.id))
        .map(row => (currentCategorySelected.nameField ? row[currentCategorySelected.nameField] : row))
        .join(',');
    }
    if (currentCategorySelected.type === 'type_singleselect') {
      value = currentCategorySelected.nameField ?
        currentSelectedItem.id :
        currentSelectedItem;
      textView = currentCategorySelected.nameField ?
        currentSelectedItem[currentCategorySelected.nameField] :
        currentSelectedItem;
    }

    if (category !== '' && comparator !== '') {
      const newFilter = {category, comparator, value};
      if (textView) {
        newFilter.textView = textView;
      }
      FilterService.filtersSelected.push(newFilter);
      FilterService.currentCategorySelected = '';
      FilterService.currentComparatorSelected = '';
      FilterService.currentTextSelected = '';
      FilterService.currentSelectedIds = [];
      FilterService.currentSelectedItem = '';
    } else {
      swal('Uh-oh!', 'One of the input fields is missing.', 'error');
    }
  };

  // process and remove the filter elements
  service.removeFilterElements = (index) => {
    const filterElement = MODEL.FilterService.filtersSelected[index];
    if (filterElement.category === 'groups') {
      // reset selected groups
      MODEL.FilterService.currentSelectedGroups = [];
    } else if (filterElement.category === 'radius') {
      MODEL.FilterService.filterCurrentMiles = 0;
      MODEL.FilterService.filterSlider.update({from: 0});
      MappingService.resetNearbyMap();
    }
    MODEL.FilterService.filtersSelected.splice(index, 1);
  };

  // toggle groups selection for the filters
  service.toggleGroupsFilter = (group) => {
    // selected group name has already been added
    if (MODEL.FilterService.currentSelectedGroups.indexOf(group) >= 0) {
      MODEL.FilterService.currentSelectedGroups.splice(MODEL.FilterService.currentSelectedGroups.indexOf(group), 1);
      const groupsFilter = find(MODEL.FilterService.filtersSelected, {category: 'groups'});
      if (groupsFilter) {
        groupsFilter.value = MODEL.FilterService.currentSelectedGroups;
        if (MODEL.FilterService.currentSelectedGroups.length === 0) {
          MODEL.FilterService.filtersSelected.splice(MODEL.FilterService.filtersSelected.indexOf(groupsFilter), 1);
        }
      }
    } else {
      MODEL.FilterService.currentSelectedGroups.push(group);
      const groupsFilter = find(MODEL.FilterService.filtersSelected, {category: 'groups'});
      if (groupsFilter) {
        groupsFilter.value = MODEL.FilterService.currentSelectedGroups;
      } else {
        MODEL.FilterService.filtersSelected.push({category: 'groups', comparator: MODEL.FilterService.groupsFilterOptions, value: MODEL.FilterService.currentSelectedGroups});
      }
    }
  };

  service.toggleFilterOptions = () => {
    const groupsFilter = find(MODEL.FilterService.filtersSelected, {category: 'groups'});
    if (groupsFilter) {
      groupsFilter.comparator = MODEL.FilterService.groupsFilterOptions;
    }
  };

  // set the appropriate comparator for the selected category
  service.toggleFilterCategory = () => {
    const type = get(MODEL.FilterService.currentCategorySelected, 'type', 'type1');
    MODEL.FilterService.currentComparatorModel = MODEL.FilterService.comparatorModel[type];
  };

  // process custom filters on the mpa
  service.processCustomFilter = (crmData, crmObjectCustomFields) => {
    // get custom field keys
    const customFieldKeys = Object.keys(crmObjectCustomFields);
    const customFilters = [];
    MODEL.FilterService.filtersSelected.forEach(filter => {
      if (filter.category !== 'radius' && filter.category !== 'groups') {
        customFilters.push(filter);
      }
    });

    if (customFilters.length) {
      customFilters.forEach((filter) => {
        if (filter.comparator === 'equals') {
          crmData = crmData.filter((obj) => {
            const rowOption = filter.category;
            if (customFieldKeys.indexOf(rowOption) > -1) {
              const customField = `custom${crmObjectCustomFields[rowOption]}`;
              if (obj.customFields[customField]) {
                return obj.customFields[customField].toLowerCase() === filter.value.toLowerCase();
              }
              return false;
            }
            if (obj[rowOption]) {
              return obj[rowOption].toLowerCase() === filter.value.toLowerCase();
            }
            return false;
          });
        } else if (filter.comparator === 'does not equal') {
          crmData = crmData.filter((obj) => {
            const rowOption = filter.category;
            if (customFieldKeys.indexOf(rowOption) > -1) {
              const customField = `custom${crmObjectCustomFields[rowOption]}`;
              if (obj.customFields[customField]) {
                return obj.customFields[customField].toLowerCase() !== filter.value.toLowerCase();
              }
              return true;
            }
            if (obj[rowOption]) {
              return obj[rowOption].toLowerCase() !== filter.value.toLowerCase();
            }
            return true;
          });
        } else if (filter.comparator === 'contains') {
          crmData = crmData.filter((obj) => {
            const rowOption = filter.category;
            const searchWord = filter.value;
            if (customFieldKeys.indexOf(rowOption) > -1) {
              const customField = `custom${crmObjectCustomFields[rowOption]}`;
              if (obj.customFields[customField]) {
                return obj.customFields[customField].toLowerCase().indexOf(searchWord.toLowerCase()) > -1;
              }
              return false;
            }
            if (obj[rowOption]) {
              return obj[rowOption].toLowerCase().indexOf(searchWord.toLowerCase()) > -1;
            }
            return false;
          });
        } else if (filter.comparator === 'exists') {
          crmData = crmData.filter((obj) => {
            const rowOption = filter.category;
            if (customFieldKeys.indexOf(rowOption) > -1) {
              const customField = `custom${crmObjectCustomFields[rowOption]}`;
              return !!obj.customFields[customField];
            }
            return !!obj[rowOption];
          });
        } else if (filter.comparator === 'does not exist') {
          crmData = crmData.filter((obj) => {
            const rowOption = filter.category;
            if (customFieldKeys.indexOf(rowOption) > -1) {
              const customField = `custom${crmObjectCustomFields[rowOption]}`;
              return !obj.customFields[customField];
            }

            return !obj[rowOption];
          });
        } else if (filter.comparator === 'starts with') {
          crmData = crmData.filter((obj) => {
            const rowOption = filter.category;
            if (customFieldKeys.indexOf(rowOption) > -1) {
              const customField = `custom${safeLocalStorage.currentUser.customFieldsToColumns[rowOption]}`;
              return obj.customFields[customField].startsWith(filter.value);
            }

            return obj[rowOption].startsWith(filter.value);
          });
        } else if (filter.comparator === 'greater than') {
          crmData = crmData.filter((obj) => {
            const rowOption = filter.category;
            return obj[rowOption] > parseFloat(filter.value);
          });
        } else if (filter.comparator === 'less than') {
          crmData = crmData.filter((obj) => {
            const rowOption = filter.category;
            return obj[rowOption] < parseFloat(filter.value);
          });
        } else if (filter.comparator === 'is after') {
          crmData = crmData.filter((obj) => {
            const rowOption = filter.category;
            let rowValue = obj[rowOption];

            if (rowOption === 'createdAt') {
              rowValue = Date.parse(obj[rowOption]) / 1000;
            }

            const filterDatePickerData = Date.parse(filter.value);
            const filterDate = parseInt(filterDatePickerData.valueOf() / 1000, 0);
            return rowValue > parseFloat(filterDate);
          });
        } else if (filter.comparator === 'is before') {
          crmData = crmData.filter((obj) => {
            const rowOption = filter.category;
            let rowValue = obj[rowOption];

            if (rowOption === 'createdAt') {
              rowValue = Date.parse(obj[rowOption]) / 1000;
            }

            const filterDatePickerData = Date.parse(filter.value);
            const filterDate = parseInt(filterDatePickerData.valueOf() / 1000, 0);
            return rowValue < parseFloat(filterDate);
          });
        }
      });
    }

    // return the filtered data for further processing
    return crmData;
  };
  window.processCustomFilter = service.processCustomFilter;

  //  change user view
  service.changeUserView = (crmData, userView) => {
    // for contacts and accounts list page
    if (userView) {
      return crmData.filter(record => record.username === userView);
    }
    return crmData;
  };

  // process groups filter on the map
  service.processGroupsFilter = (crmData) => {
    if (MODEL.FilterService.currentSelectedGroups.length > 0) {
      // filter for pins in any
      if (MODEL.FilterService.groupsFilterOptions === 'pins in any') {
        crmData = crmData.filter(obj => obj.groups && obj.groups.some(({id}) => MODEL.FilterService.currentSelectedGroups.includes(id)));
      } else { // filter for pins in all
        crmData = crmData.filter(obj => obj.groups &&
                            obj.groups.filter(({id}) => MODEL.FilterService.currentSelectedGroups.includes(id)).length ===
                            MODEL.FilterService.currentSelectedGroups.length);
      }
    }

    return crmData;
  };
  window.processGroupsFilter = service.processGroupsFilter;

  // process nearby filter on the map
  service.processRadiusFilter = (crmData) => {
    MODEL.FilterService.filterCurrentMiles = service.getRadiusFromSelectedFilters();
    if (MODEL.FilterService.filterCurrentMiles > 0) {
      MODEL.FilterService.filterSlider.update({from: MODEL.FilterService.filterCurrentMiles});
      crmData = crmData.filter((obj) => {
        // check if pin matches the radius filter
        const pinLatLng = new google.maps.LatLng(obj.latitude, obj.longitude);
        const currentFilterLatLng = new google.maps.LatLng(MODEL.FilterService.filterNearbyLatLng.lat, MODEL.FilterService.filterNearbyLatLng.lng);
        return google.maps.geometry.spherical.computeDistanceBetween(pinLatLng, currentFilterLatLng) <= MODEL.FilterService.filterCurrentMiles * (doesUseMiles() ? 1609.344 : 1000.0);
      });
      // set nearby circle
      MappingService.setNearbyCircle();
    }
    return crmData;
  };
  window.processRadiusFilter = service.processRadiusFilter;

  service.getRadiusFromSelectedFilters = () => {
    const radiusFilter = MODEL.FilterService.filtersSelected.find(filter => filter.category === 'radius');
    if (radiusFilter) return parseInt(radiusFilter.value.replace(' miles', '').replace('km', ''), 0);

    return 0;
  };

  // init the slider
  service.initNearbySlider = () => {
    $('#filter-slider').ionRangeSlider({
      grid: true,
      min: 0,
      max: 1000,
      from: 0,
      hide_min_max: true,
      postfix: doesUseMiles() ? ' mi' : 'km',
      onChange(data) {
        MODEL.FilterService.filterCurrentMiles = data.from;
        if (MODEL.FilterService.filterCurrentMiles === 0) {
          const radiusFilter = find(MODEL.FilterService.filtersSelected, {category: 'radius'});
          if (radiusFilter) {
            MODEL.FilterService.filtersSelected.splice(MODEL.FilterService.filtersSelected.indexOf(radiusFilter), 1);
          }
        } else {
          const radiusFilter = find(MODEL.FilterService.filtersSelected, {category: 'radius'});
          if (radiusFilter) {
            radiusFilter.value = `${MODEL.FilterService.filterCurrentMiles}${doesUseMiles() ? ' miles' : 'km'}`;
            radiusFilter.radius = MODEL.FilterService.filterCurrentMiles;
          } else {
            const latlng = MODEL.FilterService.filterNearbyLatLng;
            const radius = MODEL.FilterService.filterCurrentMiles;
            MODEL.FilterService.filtersSelected.push({
              category: 'radius', comparator: 'equals', value: `${radius}${doesUseMiles() ? ' miles' : 'km'}`, radius, latlng,
            });
          }
        }
        MappingService.setNearbyCircle();
        window.refreshDom({'FilterService.filtersSelected': MODEL.FilterService.filtersSelected});
      },
    });

    MODEL.FilterService.filterSlider = $('#filter-slider').data('ionRangeSlider');
  };
  window.initNearbySlider = service.initNearbySlider;

  // enter the custom starting location for the route
  const chooseNearbyFilterLocation = () => swal({
    title: 'Select New Location',
    text: 'Change your location for the nearby filter.',
    input: 'text',
    showCancelButton: false,
    confirmButtonText: 'Set Location',
    showCloseButton: true,
    allowOutsideClick: false,
  });

  // change neary filter location
  service.changeFilterNearby = () => {
    chooseNearbyFilterLocation()
      .then((location) => {
        window.mmcUtils.geoCodeAddress(location)
          .then((geoCodedArray) => {
            if (geoCodedArray.status === 'OK') {
              MODEL.FilterService.filterNearbyLocation = location;
              MODEL.FilterService.filterNearbyLatLng.lat = geoCodedArray.lat;
              MODEL.FilterService.filterNearbyLatLng.lng = geoCodedArray.lng;
              window.refreshDom({filterNearbyLocation: location}, 'FilterService');
              // using a local variable for lat/lng
              // in future  we might change location based on something else as well
              // so using another variabel and not using the filter variable
              MODEL.MappingService.localCurrentLatLng = MODEL.FilterService.filterNearbyLatLng;
              MappingService.setUserMarker();
              MappingService.setNearbyCircle();
              swal('Location Changed!', 'Your location has been changed successfully!', 'success');
            } else {
              swal('Location Invalid!', 'Please enter valid location.', 'error');
            }
          })
          .catch((error) => {
            helperService.showAndLogError(error);
          });
      })
      .catch((error) => {
        helperService.showAndLogError(error);
      });
  };

  service.resetFilterModel = function () {
    CacheService.setFilterCache(true);
    // reset filter raidus circle
    MappingService.resetNearbyMap();
    MODEL.FilterService.currentCategorySelected = '';
    MODEL.FilterService.currentComparatorSelected = '';
    MODEL.FilterService.selectedIndividualGroupFilter = '';
    MODEL.FilterService.currentCategoryModel = {};
    MODEL.FilterService.currentSelectedGroups = [];
    MODEL.FilterService.currentComparatorModel = {};
    MODEL.FilterService.filtersSelected = [];
    MODEL.FilterService.groupsFilterModel = {};
    MODEL.FilterService.filterNearbyLatLng = {lat: '', lng: ''};
    MODEL.FilterService.filterNearbyLocation = '';
    MODEL.FilterService.filterMaxMiles = 0;
    MODEL.FilterService.filterCurrentMiles = 0;
    MODEL.FilterService.groupsFilterOptions = 'pins in any';
    MODEL.currentPageNum = 1;
    MODEL.FilterService.filterActive = false;
    MODEL.FilterService.filterCurrentMiles = 0;
    if (MODEL.FilterService.filterSlider) {
      MODEL.FilterService.filterSlider.update({from: 0});
    }
    MODEL.FilterService.parseFilterQuery = {};
  };

  // reset the entire filter models
  service.resetFilter = async function () {
    service.resetFilterModel();

    const page = $route.current.id;
    const map = page.includes('Map');

    if (['accountsMapPage', 'accountsRoutingMapPage', 'accountsPage'].includes(page)) {
      const userFilter = MODEL.cachedState.accounts.user ? {viewsAs: MODEL.cachedState.accounts.user} : {};
      MODEL.cachedState.accounts.column = 'updatedAt';
      MODEL.cachedState.accounts.ascending = false;
      await AccountsService.fetchAccounts(map, userFilter);

      if (map) AccountsService.setMapView();
      if (page === 'accountsRoutingMapPage') {
        RoutingService.setViewHeaders(map);
      } else {
        AccountsService.setViewHeaders(map);
      }
    } else if (['contactsMapPage', 'contactsRoutingMapPage', 'contactsPage', 'territoriesMapPage'].includes(page)) {
      const userFilter = MODEL.cachedState.contacts.user ? {viewsAs: MODEL.cachedState.contacts.user} : {};
      MODEL.cachedState.contacts.column = 'updatedAt';
      MODEL.cachedState.contacts.ascending = false;
      await AllContactsService.fetchContacts(map, userFilter);

      if (map) {
        if (page === 'territoriesMapPage') {
          TerritoriesService.setMapView();
        } else {
          AllContactsService.setMapView();
        }
      }
      if (page === 'contactsRoutingMapPage') {
        RoutingService.setViewHeaders(map);
      } else if (page === 'territoriesMapPage') {
        TerritoriesService.setViewHeaders(map);
      } else {
        AllContactsService.setViewHeaders(map);
      }
    } else if (page === 'individualContactsRoutePage') {
      await AllContactsService.fetchContacts(true, {});
      AllContactsService.setMapView();
    } else if ($route.current.id === 'contactsGroupsMapPage') {
      FetchCRMDataService.processDataForMappingPages('contacts');
    } else if ($route.current.id === 'reportsPage') {
      await ReportsService.fetchPreview({});
    } else if (['dealsPage', 'dealsMapPage'].includes($route.current.id)) {
      const userFilter = MODEL.cachedState.deals.user ? {viewsAs: MODEL.cachedState.deals.user} : {};
      await FunnelService.fetchDeals(map, userFilter);

      if (map) {
        await FunnelService.setMapView();
      } else {
        await FunnelService.setFunnelView(MODEL.FunnelService.currentFunnelId);
      }
      FunnelService.setViewHeaders();
    } else if ($route.current.id === 'dealsGroupsMapPage') {
      MAN.nukeMapContent();
      const groupCustomers = MappingService.getCustomersForGroupsMapPage(MODEL.dealsRaw);

      const filteredContacts = FetchCRMDataService.filterObjectsThroughLatLng(groupCustomers);
      FetchCRMDataService.setCurrentPageVariable('Groups', filteredContacts.length, 'Add Group');
      FetchCRMDataService.parseMappingObjects(filteredContacts, 'contacts');
    } else if (['crmActivitiesPage', 'crmActivitiesCalPage'].includes($route.current.id)) {
      await CrmActivitiesService.setViewBasedOnType(map, {});
    }
    service.setUpFilter();
  };

  // set maximum limit
  service.setMaximumLimit = () => {
    MODEL.FilterService.filterSlider.update({from: 1000});
    MODEL.FilterService.filterCurrentMiles = 1000;
    // set filter to maximum value
    const radiusFilter = find(MODEL.FilterService.filtersSelected, {category: 'radius'});
    if (radiusFilter) {
      radiusFilter.value = `${MODEL.FilterService.filterCurrentMiles}${doesUseMiles() ? ' miles' : 'km'}`;
    } else {
      MODEL.FilterService.filtersSelected.push({category: 'radius', comparator: 'equals', value: `${MODEL.FilterService.filterCurrentMiles}${doesUseMiles() ? ' miles' : 'km'}`});
    }
    MappingService.setNearbyCircle();
    window.refreshDom({'FilterService.filtersSelected': MODEL.FilterService.filtersSelected});
  };

  return service;
}

FilterService.$inject = [
  '$route', 'FetchCRMDataService', 'MappingService', 'ReportsService',
  'FunnelService', 'CacheService', 'AccountsService',
  'AllContactsService', 'TerritoriesService', 'RoutingService', 'CrmActivitiesService',
];
