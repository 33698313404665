import Field from './field/field';

export const createField = (data) => {
  if (data instanceof Field) {
    return data;
  }

  return new Field(data);
};

/**
 * Returns a number that represents field order according to its type. Each type has its own order number.
 * @param {Field} field
 * @returns {number}
 */
export const getDefaultFieldTypeOrder = (field) => {
  if (field.isCustomField) {
    return 2;
  }
  if (field.isAddressField) {
    return 1;
  }
  return 0;
};

/**
 * Sorts given list of fields in-place.
 * Name field is always the first. Address Detail fields are put at the end of list.
 * @param {Field[]} fields
 * @returns {Field[]}
 */
export const sortFields = (fields) => fields.sort((a, b) => {
  // name field is always the first
  if (a.name === 'name') {
    return b.name === 'name' ? 0 : -1;
  } if (b.name === 'name') {
    return 1;
  }

  const aTypeOrder = getDefaultFieldTypeOrder(a);
  const bTypeOrder = getDefaultFieldTypeOrder(b);
  if (aTypeOrder !== bTypeOrder) {
    return aTypeOrder - bTypeOrder;
  }

  if (a.displayOrder !== undefined && b.displayOrder !== undefined) {
    return a.displayOrder - b.displayOrder;
  }

  // if fields are of one type sort them by displayName
  return a.displayName.localeCompare(b.displayName);
});
