import moment from 'moment';
import get from 'lodash/get';

export const parseDate = (date) => {
  const value = moment(date);
  // utc() is needed because we need to display dates as-is despite current user's TZ
  return value.isValid() ? value.utc().toDate() : undefined;
};

export const parseTime = (time) => {
  const value = moment(`${moment(new Date()).format('YYYY-MM-DD')}T${time}`);
  return value.isValid() ? value.utc().toDate() : undefined;
};

export const valueParser = (parser) => (entity, field) => parser(entity, field);
export const dateParser = (path) => valueParser((entity, field) => parseDate(get(entity, path || field.name)));
