import {createAction} from '@reduxjs/toolkit';
import {withPayloadType} from '../../react/util/redux';
import IntegrationService from '../../react/type/enum/IntegrationService';
import Integration, {SupportedEntityType} from '../../react/type/integration/Integration';

export const selectService = createAction('integrations/selectService', withPayloadType<IntegrationService>());
export const createIntegration = createAction('integrations/create', withPayloadType<Map<string, string>>());
export const fetchIntegrations = createAction('integrations/fetchList');
export const initializeEditPage = createAction('integrations/initEditPage', withPayloadType<Integration['id']>());
export const initializeMappingStep = createAction('integrations/initMappingStep');
export const fetchIntegration = createAction('integrations/fetch', withPayloadType<Integration['id']>());
export const fetchIntegrationUsers = createAction('integrations/fetchUsers', withPayloadType<Integration['id']>());
export const fetchIntegrationFields = createAction(
  'integrations/fetchFields',
  withPayloadType<{integrationId: Integration['id'], entityTypes: SupportedEntityType[]}>(),
);
export const deleteIntegration = createAction('integrations/delete', withPayloadType<Integration['id']>());
export const saveIntegration = createAction('integrations/save');
export const updateIntegrationStatus = createAction(
  'integrations/updateIntegrationStatus',
  withPayloadType<{integrationId: Integration['id'], isLocked: boolean}>(),
);

export type Actions =
  | ReturnType<typeof selectService>
  | ReturnType<typeof createIntegration>
  | ReturnType<typeof fetchIntegrations>
  | ReturnType<typeof initializeEditPage>
  | ReturnType<typeof initializeMappingStep>
  | ReturnType<typeof fetchIntegration>
  | ReturnType<typeof fetchIntegrationUsers>
  | ReturnType<typeof fetchIntegrationFields>
  | ReturnType<typeof deleteIntegration>
  | ReturnType<typeof saveIntegration>
  | ReturnType<typeof updateIntegrationStatus>;
