import React, {useCallback, useEffect, useReducer, useState} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import ListResponse from '../../type/service/ListResponse';
import Lead from '../../type/Lead';
import persistenceReducer from './reducers/leadList';
import safeLocalStorage from '../../../shared-services/safe-local-storage-service';
import leadNetworkService from '../../../network-services/leads-network-service';
import helperService from '../../../shared-services/helper-service';
import useBoolean from '../../hook/useBoolean';
import customFieldsNetworkService from '../../../network-services/custom-fields-network-service';
import EntityType from '../../type/EntityType';
import useInitializer from '../../hook/useInitializer';
import {fetchLeadGroups} from '../../../store/lead/action';
import {RootState} from '../../../store/rootReducer';
import withStore from '../../hoc/withStore';
import leadFieldModel from '../../../common/field-model/lead-field-model';
import {setViewColumns} from '../../util/reducers/listReducer';
import FetchCRMDataServiceType from '../../type/service/FetchCRMDataService';
import MainServiceType from '../../type/service/MainService';
import ListViewGrid from '../../components/Grid/ListViewGrid';
import FilterPane from '../../components/FilterPane/FilterPane';
import getListCallbacks from '../../hook/getListCallbacks';
import {getLeadGroups} from '../../../store/lead';
import Group from '../../type/Group';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

interface Props {
  FetchCRMDataService: FetchCRMDataServiceType,
  MainService: MainServiceType,
  groups: Group[],
  fetchLeadGroups: () => {},
}

const LeadList: React.FC<Props> = ({
  FetchCRMDataService,
  MainService,
  groups,
  fetchLeadGroups,
}) => {
  const [leadData, setLeadData] = useState<ListResponse<Lead> | undefined>(undefined);
  const [isLoading, setLoading, setNoLoading] = useBoolean();
  const [initialized, setInitialized] = useBoolean();
  const [{filter, viewColumns, page, sorting, filteredFields}, dispatchParams] = useReducer(persistenceReducer, safeLocalStorage.leadListViewState);
  const {handleChangeFilter, handleClearFilter, handleChangePage, handleChangeSorting} = getListCallbacks(dispatchParams);

  useEffect(
    () => {
      FetchCRMDataService.setCurrentPageVariable('Lead List', leadData?.total, 'Add Lead', false, false);
    },
    [leadData],
  );
  const updateViewColumns = useCallback(
    async () => {
      dispatchParams(setViewColumns(await FetchCRMDataService.initListViewCols()));
    },
    [dispatchParams],
  );

  const initialize = useCallback(
    async () => {
      try {
        fetchLeadGroups();
        const response = await customFieldsNetworkService.getFields(EntityType.LEAD);
        leadFieldModel.setCustomFields(response.data);
        await updateViewColumns();
      } catch (e) {
        helperService.showAndLogError(e);
      }
      setInitialized();
    },
    [fetchLeadGroups, updateViewColumns],
  );
  useInitializer(initialize);


  const reloadLeads = useCallback(
    async () => {
      setLoading();
      try {
        setLeadData(await leadNetworkService.fetchAllLeads(false, filter, page, sorting.fieldName, sorting.ascending));
      } catch (e) {
        helperService.showAndLogError(e);
      }
      setNoLoading();
    },
    [setLeadData, setLoading, setNoLoading, filter, page, sorting],
  );

  useEffect(
    () => { reloadLeads(); },
    [reloadLeads, filter, page, sorting],
  );

  return (
    <Container>
      {initialized &&
        <ListViewGrid
          fieldModel={leadFieldModel}
          entityCount={leadData?.total}
          entityList={leadData?.data}
          entityType={EntityType.LEAD}
          MainService={MainService}
          onChangePage={handleChangePage}
          page={page}
          onChangeFilters={handleChangeFilter}
          onChangeSorting={handleChangeSorting}
          multiSelectBar
          isLoading={isLoading}
          onReloadData={reloadLeads}
          viewColumns={viewColumns}
          onUpdateViewColumns={updateViewColumns}
          filteredFields={filteredFields}
          onClearFilter={handleClearFilter}
          sorting={sorting}
          extraFilters={(
            <FilterPane
              groups={groups}
              onFilterChanged={handleChangeFilter}
              filteredFields={filteredFields}
            />
          )}
        />
      }
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  groups: getLeadGroups(state),
});

const mapDispatchToProps = {
  fetchLeadGroups,
};

export default withStore(connect(mapStateToProps, mapDispatchToProps)(LeadList));
