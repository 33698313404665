import angular from 'angular';
import template from './filters-editor.html';
import analyticsService from '../../shared-services/analytics-service';

FiltersEditorController.$inject = [
  '$scope', '$window', '$timeout', 'FilterService', 'BaseController',
];

function FiltersEditorController(
  $scope, $window, $timeout, FilterService, BaseController,
) {
  Object.assign(this, $scope, {
    $scope,
    MODEL: $window.DataModel,
    $timeout,
    FilterService,
    applyAllFilters: BaseController.applyAllFilters,
    initNearbySlider,
    resetFilter,
    getGroupNameById,
    removeFilterElements,
    toggleFilterCategory,
    addFilterSearchElement,
    toggleFilterOptions,
    blurFocus,
    toggleGroupsFilter,
    setMaximumLimit,
    onGroupsFilterScroll,
    showHideGroupChevrons,
    closeFiltersEditor,
    changeFilterNearby,
    $onInit,
  });

  function $onInit() {
    this.initNearbySlider();

    document.querySelector('#groupsFilterList').addEventListener('scroll', (event) => {
      this.onGroupsFilterScroll(event);
    });
  }

  this.$scope.$watch('$ctrl.MODEL.FilterService.groupsFilterModel', (newVal) => {
    if (newVal) {
      this.$timeout(() => {
        this.showHideGroupChevrons();
      }, 0);
    }
  });

  function initNearbySlider() {
    this.FilterService.initNearbySlider();
  }

  function resetFilter(userFilter) {
    this.FilterService.resetFilter(userFilter);
  }

  function getGroupNameById(groupId) {
    const group = this.MODEL.FilterService.groupsFilterModel.find(({id}) => id === groupId);
    return group ? group.name : null;
  }

  function removeFilterElements(index) {
    this.FilterService.removeFilterElements(index);
  }

  function toggleFilterCategory() {
    this.FilterService.toggleFilterCategory();
  }

  function addFilterSearchElement() {
    this.FilterService.addFilterSearchElement();
  }

  function toggleFilterOptions() {
    this.FilterService.toggleFilterOptions();
  }

  function blurFocus() {
    this.$timeout(() => {
      this.MODEL.searchFocused = false;
      this.MODEL.searchOptions = [];
    }, 200);
  }

  function toggleGroupsFilter(index) {
    this.FilterService.toggleGroupsFilter(index);
  }

  function setMaximumLimit() {
    this.FilterService.setMaximumLimit();
  }

  function onGroupsFilterScroll(event) {
    const $elem = angular.element(event.target);
    const newScrollLeft = $elem.scrollLeft();
    const width = $elem.width();
    const {scrollWidth} = $elem.get(0);
    const offset = 0;

    this.$timeout(() => {
      if (scrollWidth - newScrollLeft - width === offset) {
        this.showRightChevron = false;
      } else {
        this.showRightChevron = true;
      }

      if (newScrollLeft === 0) {
        this.showLeftChevron = false;
      } else {
        this.showLeftChevron = true;
      }
    }, 0);
  }

  function showHideGroupChevrons() {
    const $elem = angular.element(document.querySelector('#groupsFilterList'));
    const width = $elem.width();
    const {scrollWidth} = $elem.get(0);

    this.showLeftChevron = false;
    // box is not scrollable
    if (width === scrollWidth) {
      this.showRightChevron = false;
    } else {
      this.showRightChevron = true;
    }
  }

  function closeFiltersEditor() {
    analyticsService.canceled('Filter');
    this.MODEL.show.filters = false;
    $('#subheader').show();
  }

  function changeFilterNearby() {
    this.FilterService.changeFilterNearby();
  }

  $('#filterDatePicker').datetimepicker({allowInputToggle: true});
}

function filtersEditor() {
  return {
    restrict: 'E',
    scope: {
      currentState: '=',
    },
    bindToController: true,
    controller: 'FiltersEditorController',
    controllerAs: '$ctrl',
    template,
  };
}

export {FiltersEditorController, filtersEditor};
