import get from 'lodash/get';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';
import template from './activity-card.html';
import './activity-card.scss';

class ActivityCardController {
  constructor() {
    Object.assign(this, {
      localStorage: safeLocalStorage,
    });

    this.actionVerbMap = {
      create: 'created',
      update: 'updated',
      delete: 'deleted',
      call: 'called',
      email: 'emailed',
      startSync: 'started',
      endSync: 'ended',
    };

    this.objectMap = {
      contacts: 'person',
      contactNotes: 'person note',
      accounts: 'company',
      accountNotes: 'company note',
      deals: 'deal',
      funnels: 'funnel',
      dealNotes: 'deal note',
      territories: 'territory',
      routes: 'route',
      contactGroups: 'person group',
      accountGroups: 'company group',
      dealGroups: 'deal group',
      integrations: 'integration',
      imports: 'import',
      crmActivities: 'activity',
    };
  }

  $onInit() {
    this.actionVerb = this.actionVerbMap[this.update.activity.action];
    this.object = `${this.update.activity.action === 'create' ? 'new ' : ''}${this.objectMap[this.update.activity.table]}`;
    this.username = get(this.update, 'user.username') || get(safeLocalStorage, 'currentUser.username');

    if (['integrations', 'imports'].includes(this.update.activity.table)) {
      this.article = '';
    } else if (this.update.activity.action !== 'create' && ['account ', 'activity', 'accountGroups'].includes(this.update.activity.table)) {
      this.article = ' an';
    } else {
      this.article = ' a';
    }
  }
}

ActivityCardController.$inject = [];

const activityCard = {
  bindings: {
    update: '<',
  },
  controller: 'ActivityCardController',
  template,
};

export {ActivityCardController, activityCard};
