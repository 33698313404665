import isNil from 'lodash/isNil';
import trim from 'lodash/trim';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';
import isEqual from 'lodash/isEqual';
import helperService from './helper-service';
import dealsNetworkService from '../network-services/deals-network-service';
import contactsNetworkService from '../network-services/contacts-network-service';
import activitiesNetworkService from '../network-services/crm-activities-network-service';
import accountsNetworkService from '../network-services/accounts-network-service';
import SharedNetworkService from '../network-services/shared-network-service';
import countryList from '../common/field-model/country-list.json';
import analyticsService from './analytics-service';
import safeLocalStorage from './safe-local-storage-service';
import addEditNetworkService from '../network-services/add-edit-network-service';

const difference = (object, base) => {
  if (!object || !base) {
    if (object) {
      return object;
    }
    if (base) {
      return base;
    }
    return {};
  }
  const changes = (object, base) => transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
    }
  });
  return changes(object, base);
};

export const normalizeAddEditObject = (object) => {
  const normalized = {};
  Object.keys(object)
    .filter(k => !['createdAtEdit', 'updatedAtEdit'].includes(k))
    .forEach((k) => {
      normalized[k.replace(/(Add|Edit)/, '')] = object[k];
    });
  return normalized;
};

export default function AddEditCtrl(
  $scope, $location, $route, $timeout, BaseController,
  DataService, AddEditService, MainService, FetchCRMDataService, MappingService, RoutingService,
  CrmActivityTypesNetworkService, ActivityNetworkService,
  CustomerService, FunnelService,
  mmcConst, DealLossReasonsNetworkService, CrmActivitiesService,
  TeamService, CustomFieldsService, FilesService,
) {
  // init global utility functions
  const {MAN} = window.mmcUtils;
  const MODEL = window.DataModel;
  const UTILS = window.mmcUtils;

  // set localStorage equal to $scope to get html-binding
  $scope.localStorage = safeLocalStorage;
  $scope.contacts = {
    quickNameAdd: '',
    quickAddressAdd: '',
    quickCityAdd: '',
    quickStateAdd: '',
    quickCountryAdd: '',
    quickPostalCodeAdd: '',
  };
  $scope.deal = {
    quickNameAdd: '',
    quickAccountNameAdd: '',
    quickStageAdd: '',
  };
  $scope.accounts = {
    quickNameAdd: '',
    quickAddressAdd: '',
    quickCityAdd: '',
    quickStateAdd: '',
    quickCountryAdd: '',
  };
  $scope.leads = {
    quickNameAdd: '',
    quickAddressAdd: '',
    quickCityAdd: '',
    quickStateAdd: '',
    quickCountryAdd: '',
  };

  $scope.initializeAddActivityPage = () => {
    $scope.activities = {
      name: ($scope.activities && $scope.activities.name) || '',
      crmActivityType: ($scope.activities && $scope.activities.crmActivityType) || undefined,
      date: moment().format('MMM DD, YYYY'),
      time: moment().format('hh:mm a'),
      startAt: moment().toISOString(),
      duration: 30,
      accountText: '',
      contactText: '',
      dealText: '',
      accountSearchFocused: false,
      contactSearchFocused: false,
      dealSearchFocused: false,
      accountOptions: [],
      contactOptions: [],
      dealOptions: [],
      completed: false,
      isPublic: false,
      showDel: false,
      showCreateAnotherAct: ($scope.activities && $scope.activities.showCreateAnotherAct) || false,
      createNextActivity: ($scope.activities && $scope.activities.createNextActivity) || null,
      headerName: 'Add Activity',
      searchingAccounts: false,
      searchingContacts: false,
      searchingDeals: false,
      nameChanged: false,
      assigneeId: safeLocalStorage.currentUser.id,
    };
  };

  $scope.initializeAddActivityPage();

  $scope.availableUsers = [];

  $scope.dirtyActivityFlag = false;

  $scope.prevAccount = {};
  $scope.prevLead = {};
  $scope.prevContact = {};

  MODEL.prevChildAccounts = {};
  MODEL.prevAccountContacts = {};
  MODEL.prevAccountDeals = {};

  MODEL.childAccounts = {};
  MODEL.accountContacts = {};
  MODEL.accountDeals = {};

  MODEL.prevContactDeals = {};
  MODEL.contactDeals = {};
  MODEL.prevContactAccount = '';

  MODEL.currentCustomerLat = 0;
  MODEL.currentCustomerLng = 0;

  $scope.activeFormAc = 'upcoming';

  MODEL.show.loader = true;

  // extend BaseController methods onto scope
  Object.assign($scope, BaseController);
  $scope.showViewByUser = UTILS.setViewByUserBasedOnRole(safeLocalStorage);

  // bind data model to scope for access in views
  $scope.data = MODEL;

  // prevents login flash
  $(document.body).show();
  // MODEL.show.loader = true;
  MainService.setCurrentPage();

  $scope.updateAvailableUsers = () => {
    if (safeLocalStorage.currentUser.role.key === 'OWNER') {
      $scope.availableUsers = [MODEL.owner].concat(MODEL.TeamsService.verifiedTeamMembers.filter(user => user.id));
    } else {
      const me = MODEL.TeamsService.subTeamUsers.find(user => user.username === safeLocalStorage.currentUser.username);
      $scope.availableUsers = me
        ? MODEL.TeamsService.subTeamUsers.filter(user => me.teamId === user.teamId)
        : [];
    }
  };

  if (!Array.isArray(MODEL.TeamsService.verifiedTeamMembers) || !MODEL.TeamsService.verifiedTeamMembers.length) {
    TeamService.getOrgDetails()
      .then(() => {
        $scope.updateAvailableUsers();
      })
      .catch((error) => {
        helperService.showAndLogError(error, "We could not retrieve your organization's details. Please reload the page or retry login.");
      });
  } else {
    $scope.updateAvailableUsers();
  }

  $('#datetimepicker1').on('dp.change', (e) => {
    $scope.$evalAsync(() => {
      $scope.activities.date = e.date.format('MMM DD, YYYY');
    });
  });

  $('#datetimepicker2').on('dp.change', (e) => {
    $scope.$evalAsync(() => {
      $scope.activities.time = e.date.format('hh:mm a');
    });
  });
  // bind current route id to scope for access in views
  $scope.currentRoute = $route.current.id;

  CrmActivityTypesNetworkService.getTypes(safeLocalStorage.currentUser.organization.id)
    .then(({data}) => {
      MODEL.crmActivityTypes = data;
      if (!$scope.activities.crmActivityType) {
        $scope.activities.crmActivityType = data[0];
        $scope.activities.name = data[0].name;
      }
    })
    .catch((error) => {
      helperService.showAndLogError(error);
    });

  const _clone = (obj) => Object.keys(obj).reduce(
    (result, key) => Object.assign(result, {
      [key]: typeof obj[key] === 'object' && obj[key] !== null
        ? _clone(obj[key])
        : obj[key],
    }),
    {},
  );

  const manager = (
    MODEL.TeamsService.subTeamUsers.find(({role}) => role && role.key === 'MANAGER')
        || MODEL.owner
  );
  $scope.accessManagerTitle = manager ? manager.role.name.toLowerCase() : 'team owner';
  $scope.accessManagerUsername = manager ? manager.username : '';

  // For clearing fields selected from modal popup eg. Parent account
  $scope.clearField = (event, page, field, edit) => {
    if (!$scope.determineAccessRights('update')) {
      return;
    }

    event.stopPropagation();
    if (page === 'contacts') {
      MODEL.currentAssociatedAccountId = undefined;
      if (edit) {
        $scope.contacts.accountEdit = '';
        $scope.contacts.account = null;
        MODEL.prevContactAccount = $('#accountEditContact').text();
        $('#accountEditContact').text('');
        MODEL.contactAccountModified = true;
        window.refreshDom({contactAccountModified: MODEL.contactAccountModified});
      } else {
        $('#accountAddContact').text('');
        $('#linkCustAccAdd').show();
        $('#clearCustAccAdd').hide();
      }
    } else if (page === 'accounts') {
      MODEL.currentAssociatedAccountId = undefined;
      if (edit) {
        $scope.accounts.parentAccountEdit = '';
        MODEL.prevParent = $('#parentAccountEditAccount').text();
        $('#parentAccountEditAccount').text('');
        $('#clearAccAccEdit').hide();
        MODEL.parentAccountModified = true;
        window.refreshDom({parentAccountModified: MODEL.parentAccountModified});
      } else {
        $('#parentAccountAddAccount').text('');
        $('#linkAccAccAdd').show();
        $('#clearAccAccAdd').hide();
      }
    } else if (page === 'deals') {
      if (edit) {
        switch (field) {
          case 'accounts':
            $scope.clearAccountOnDealsPage();
            $scope.clearContactOnDealsPage();
            break;
          case 'contacts':
            $scope.clearContactOnDealsPage();
            break;
        }
      } else {
        switch (field) {
          case 'accounts':
            MODEL.currentAssociatedAccountId = undefined;
            $('#accountNameAddDeal').text('');
            $('#linkDealAccAdd').show();
            $('#clearDealAccAdd').hide();
            break;
          case 'contacts':
            MODEL.currentAssociatedContactId = '';
            $('#contactNameAddDeal').text('');
            $('#linkDealCustAdd').show();
            $('#clearDealCustAdd').hide();
            break;
        }
      }
    }
  };

  // clear account field
  $scope.clearAccountOnDealsPage = () => {
    MODEL.currentAssociatedAccountId = undefined;
    $scope.deal.accountNameEdit = '';
    $('#accountNameEditDeal').text('');
    $('#linkDealAccEdit').show();
    $('#clearDealAccEdit').hide();
    MODEL.dirtyEditFlag = true;
    window.refreshDom({dirtyEditFlag: MODEL.dirtyEditFlag});
  };

  // clear contact field
  $scope.clearContactOnDealsPage = () => {
    MODEL.currentAssociatedContactId = '';
    $scope.deal.contactNameEdit = '';
    $('#contactNameEditDeal').text('');
    $('#linkDealCustEdit').show();
    $('#clearDealCustEdit').hide();
    MODEL.dirtyEditFlag = true;
    window.refreshDom({dirtyEditFlag: MODEL.dirtyEditFlag});
  };

  // Remove uploaded picture from contact
  $scope.removePic = (id, index) => swal({
    title: 'Are you sure?',
    text: "You can't recover this image once deleted",
    type: 'warning',
    showCancelButton: true,
  })
    .then(
      result => {
        if (result) {
          MODEL.show.loader = true;
          return AddEditService.deletePinFromContact(id);
        }
        return false;
      },
      () => false,
    )
    .then((data) => {
      if (data && data.status) {
        if (data.status === 200) {
          MODEL.MappingService.currentPinPhotos.splice(index, 1);
          window.refreshDom({currentPinPhotos: MODEL.MappingService.currentPinPhotos}, 'MappingService');
          window.refreshDom({loader: false}, 'show');
          swal('Hooray!', 'Selected image has been deleted', 'success');
          return;
        }

        window.refreshDom({loader: false}, 'show');
        swal('Sorry', 'Could not delete the selected image', 'error');
      }
    });

  // populate common field
  $scope.populateCommonFields = (record) => {
    // current groups
    if (record.groups) {
      MODEL.MappingService.currentGroup = record.groups;
    }

    // make sure groups are in array form
    if (!$.isArray(MODEL.MappingService.currentGroup)) {
      MODEL.MappingService.currentGroup = [MODEL.MappingService.currentGroup];
    }

    MODEL.GroupsService.selectedGroups = [];
    MODEL.MappingService.currentGroup.forEach((group) => {
      MODEL.GroupsService.selectedGroups[group] = true;
    });
  };

  $scope.entityListToMap = (entities, additionalProps = {}) => entities.reduce(
    (result, entity) => Object.assign(result, {[entity.id]: {...entity, ...additionalProps}}),
    {},
  );

  // add child accounts to the acount from the add/edit account page
  $scope.addChildAccounts = () => {
    MainService.populateEditPopupData('childAccounts');
  };

  // add contacts to the account from the add/edit account page
  $scope.addContactsToAccount = () => {
    MainService.populateEditPopupData('contacts');
  };

  // add deals to the account from the add/edit account page
  $scope.addDealsToAccount = () => {
    MainService.populateEditPopupData('deals');
  };

  // add deals to the account from the add/edit contact page
  $scope.addDealsToContact = () => {
    MainService.populateEditPopupData('deals');
  };

  $scope.populateContactData = (contact, populateAll = true) => {
    const entityChanges = normalizeAddEditObject(difference($scope.contacts, $scope.prevContact));
    MODEL.typeOfLocationUpdatedMostRecently = undefined;
    $scope.contacts.id = contact.id;
    $scope.contacts.importId = contact.importId;
    $scope.contacts.createdAtEdit = new Date(contact.createdAt);
    $scope.contacts.updatedAtEdit = new Date(contact.updatedAt);

    const latLng = contact.geoPoint ? contact.geoPoint.coordinates : '';
    // mapped
    if (latLng) {
      $scope.contacts.currentCustomerLng = latLng[0];
      $scope.contacts.currentCustomerLat = latLng[1];
      $scope.addLocationMarker(contact.color, latLng[1], latLng[0], contact.id);
    } else {
      // not mapped
      $scope.addLocationMarker(contact.color, null, null, contact.id);
    }
    let firstName = contact.firstName;
    let lastName = contact.lastName;
    if (!firstName && !lastName) {
      const spaceIndex = contact.name.indexOf(' ');
      firstName = spaceIndex === -1 ? contact.name : contact.name.slice(0, spaceIndex);
      lastName = spaceIndex === -1 ? '' : contact.name.slice(spaceIndex + 1);
    }

    $scope.contacts.nameEdit = contact.name;
    $scope.contacts.firstNameEdit = firstName;
    $scope.contacts.lastNameEdit = lastName;
    $scope.contacts.emailEdit = contact.email;
    $scope.contacts.phoneEdit = contact.phone;
    $scope.contacts.colorEdit = contact.color || 'black';
    $scope.contacts.cityEdit = contact.city;
    $scope.contacts.stateEdit = contact.region;
    $scope.contacts.addressEdit = contact.address;
    $scope.contacts.zipEdit = contact.postalCode;
    $scope.contacts.countryEdit = contact.country;
    if (contact.account) {
      $scope.contacts.accountEdit = contact.account.name;
      $scope.contacts.account = contact.account;
      MODEL.prevContactAccount = contact.account.name;
      MODEL.currentAssociatedAccountId = contact.account.id;
    } else {
      $scope.contacts.accountEdit = '';
      $scope.contacts.account = null;
      MODEL.currentAssociatedAccountId = undefined;
    }
    MODEL.prevAssociatedAccountId = MODEL.currentAssociatedAccountId;

    if (MODEL.currentAssociatedAccountId) {
      $('#clearCustAccEdit').show();
    }

    $scope.contacts.username = contact.user.username;
    $scope.contacts.crmLink = contact.crmLink;

    // not populating some fields when contact isn't expected to have them, e.g. after PUT
    if (populateAll) {
      $scope.contacts.groups = contact.groups;
      $scope.contacts.accessStatus = contact.accessStatus;
    }

    MODEL.currentCustomerGeoCodingData.address = contact.address;
    MODEL.currentCustomerGeoCodingData.city = contact.city;
    MODEL.currentCustomerGeoCodingData.state = contact.state;
    MODEL.currentCustomerGeoCodingData.zip = contact.zip;
    MODEL.currentCustomerGeoCodingData.country = contact.country;

    $scope.prevContact = {...$scope.contacts};
    return entityChanges;
  };

  // populate contact
  $scope.populateContact = async function (contact) {
    if (!MODEL.map) {
      MODEL.map = MAN.getMap();
    }
    $scope.populateContactData(contact);

    // find accounts & deals this account is associated with
    const deals = await dealsNetworkService.fetchContactDeals(contact.id);
    const dealsMap = $scope.entityListToMap(deals.data, {alreadySaved: true});
    MODEL.contactDeals = dealsMap;
    MODEL.prevContactDeals = {...dealsMap};
    window.refreshDom({contactDeals: MODEL.contactDeals, currentCrmObjectDealsData: MODEL.currentCrmObjectDealsData});

    // TODO - Implement routes for forms
    // get routes for this customer
    if (contact.geoPoint) {
      await RoutingService.getRoutesForCustomer(contact.id);
    }

    // set address icon link
    $('#gpsBox').toggle(!!contact.address);

    // TODO: get emails sent to customer
    // MappingService.getEmailsForCustomer(contact.id);
  };

  $scope.populateAccountData = (record, populateAll = true) => {
    const entityChanges = normalizeAddEditObject(difference($scope.accounts, $scope.prevAccount));

    MODEL.typeOfLocationUpdatedMostRecently = undefined;
    $scope.company = record;
    $scope.accounts.id = record.id;
    $scope.accounts.importId = record.importId;
    $scope.accounts.createdAtEdit = new Date(record.createdAt);
    $scope.accounts.updatedAtEdit = new Date(record.updatedAt);
    const latLng = record.geoPoint ? record.geoPoint.coordinates : undefined;
    if (latLng) {
      $scope.accounts.currentCustomerLng = latLng[0];
      $scope.accounts.currentCustomerLat = latLng[1];
      $scope.addLocationMarker(record.color, latLng[1], latLng[0], record.id);
    } else {
      $scope.addLocationMarker(record.color, null, null, record.id);
    }

    $scope.accounts.allColors = safeLocalStorage.currentUser.organization.plan.colors;
    $scope.accounts.nameEdit = record.name;
    $scope.accounts.phoneEdit = record.phone;
    $scope.accounts.cityEdit = record.city;
    $scope.accounts.stateEdit = record.region;
    $scope.accounts.addressEdit = record.address;
    $scope.accounts.zipEdit = record.postalCode;
    $scope.accounts.countryEdit = record.country;
    $scope.accounts.numEmployeesEdit = record.numEmployees;
    $scope.accounts.websiteEdit = record.website && record.website.replace(/^https?:\/\//, '');
    $scope.accounts.annualRevenueEdit = record.annualRevenue > 0 ? parseFloat(record.annualRevenue) : '';
    $scope.accounts.parentAccountEdit = '';
    $scope.accounts.emailEdit = record.email;
    $scope.accounts.colorEdit = record.color || 'black';
    $scope.accounts.username = record.user.username;
    $scope.accounts.crmLink = record.crmLink;

    // set the parent account id -> if present
    if (record.parentAccount) {
      $('#clearAccAccEdit').show();
      $scope.accounts.parentAccountEdit = record.parentAccount.name;
      MODEL.currentAssociatedAccountId = record.parentAccount.id;
      MODEL.prevAssociatedAccountId = record.parentAccount.id;
      MODEL.prevParent = record.parentAccount.name;
    }

    // not populating some fields when account isn't expected to have them, e.g. after PUT
    if (populateAll) {
      $scope.accounts.groups = record.groups;
      $scope.accounts.accessStatus = record.accessStatus;
    }

    MainService.setEditOrAddPageSubHeaderVariable('Edit Company', 'Save Edits', 'edit');
    MainService.populateCustomFieldsDomData(safeLocalStorage.currentUser.accountsCustomFields, record.customFields, 'accounts');
    $scope.prevAccount = _clone($scope.accounts);

    MODEL.currentCustomerGeoCodingData.address = record.address;
    MODEL.currentCustomerGeoCodingData.city = record.city;
    MODEL.currentCustomerGeoCodingData.state = record.region;
    MODEL.currentCustomerGeoCodingData.zip = record.postalCode;
    MODEL.currentCustomerGeoCodingData.country = record.country;

    return entityChanges;
  };

  $scope.populateLeadData = (record, populateAll = true) => {
    const entityChanges = normalizeAddEditObject(difference($scope.leads, $scope.prevLead));

    MODEL.typeOfLocationUpdatedMostRecently = undefined;
    $scope.lead = record;
    $scope.leads.id = record.id;
    $scope.leads.importId = record.importId;
    $scope.leads.createdAtEdit = new Date(record.createdAt);
    $scope.leads.updatedAtEdit = new Date(record.updatedAt);
    const latLng = record.geoPoint ? record.geoPoint.coordinates : undefined;
    if (latLng) {
      $scope.leads.currentCustomerLng = latLng[0];
      $scope.leads.currentCustomerLat = latLng[1];
      $scope.addLocationMarker(record.color, latLng[1], latLng[0], record.id);
    } else {
      $scope.addLocationMarker(record.color, null, null, record.id);
    }

    $scope.leads.allColors = safeLocalStorage.currentUser.organization.plan.colors;
    $scope.leads.companyEdit = record.company;
    $scope.leads.firstNameEdit = record.firstName;
    $scope.leads.lastNameEdit = record.lastName;
    $scope.leads.phoneEdit = record.phone;
    $scope.leads.cityEdit = record.city;
    $scope.leads.stateEdit = record.region;
    $scope.leads.addressEdit = record.address;
    $scope.leads.zipEdit = record.postalCode;
    $scope.leads.countryEdit = record.country;
    $scope.leads.numEmployeesEdit = record.numEmployees;
    $scope.leads.websiteEdit = record.website && record.website.replace(/^https?:\/\//, '');
    $scope.leads.annualRevenueEdit = record.annualRevenue > 0 ? parseFloat(record.annualRevenue) : '';
    $scope.leads.parentAccountEdit = '';
    $scope.leads.emailEdit = record.email;
    $scope.leads.accounts = record.accounts;
    $scope.leads.contacts = record.contacts;
    $scope.leads.leadStatusEdit = record.leadStatus;
    $scope.leads.leadSourceEdit = record.leadSource;
    $scope.leads.amountEdit = record.amount;
    $scope.leads.industryEdit = record.industry;
    $scope.leads.colorEdit = record.color || 'black';
    $scope.leads.username = record.user.username;
    // not populating some fields when account isn't expected to have them, e.g. after PUT
    if (populateAll) {
      $scope.leads.groups = record.groups;
      $scope.leads.accessStatus = record.accessStatus;
    }

    MainService.setEditOrAddPageSubHeaderVariable('Edit Lead', 'Save Edits', 'edit');

    MainService.populateCustomFieldsDomData(undefined, record.customFields, 'leads');
    $scope.prevLead = _clone($scope.leads);

    MODEL.currentCustomerGeoCodingData.address = record.address;
    MODEL.currentCustomerGeoCodingData.city = record.city;
    MODEL.currentCustomerGeoCodingData.state = record.region;
    MODEL.currentCustomerGeoCodingData.zip = record.postalCode;
    MODEL.currentCustomerGeoCodingData.country = record.country;

    return entityChanges;
  };

  // populate account
  $scope.populateAccount = async (record) => {
    if (!MODEL.map) {
      MODEL.map = MAN.getMap();
    }

    $scope.populateAccountData(record);

    const [childAccountsResponse, contactsResponse, dealsResponse] = await Promise.all([
      accountsNetworkService.fetchChildAccounts(record.id),
      contactsNetworkService.fetchAccountContacts(record.id),
      dealsNetworkService.fetchAccountDeals(record.id),
    ]);

    if (record.geoPoint) {
      await RoutingService.getRoutesForAccount(record.id);
    }

    const childAccountsMap = $scope.entityListToMap(childAccountsResponse.data, {alreadySaved: true});
    MODEL.childAccounts = childAccountsMap;
    MODEL.prevChildAccounts = _clone(childAccountsMap);

    const contactsMap = $scope.entityListToMap(contactsResponse.data, {alreadySaved: true});
    MODEL.accountContacts = contactsMap;
    MODEL.prevAccountContacts = _clone(contactsMap);

    const dealsMap = $scope.entityListToMap(dealsResponse.data, {alreadySaved: true});
    MODEL.accountDeals = dealsMap;
    MODEL.prevAccountDeals = _clone(dealsMap);

    window.refreshDom({
      childAccounts: MODEL.childAccounts,
      accountContacts: MODEL.accountContacts,
      accountDeals: MODEL.accountDeals,
    });
  };

  // populate account
  $scope.populateLead = async (record) => {
    if (!MODEL.map) {
      MODEL.map = MAN.getMap();
    }

    $scope.populateLeadData(record);

    // const [childAccountsResponse, contactsResponse, dealsResponse] = await Promise.all([
    //   accountsNetworkService.fetchChildAccounts(record.id),
    //   contactsNetworkService.fetchAccountContacts(record.id),
    //   dealsNetworkService.fetchAccountDeals(record.id),
    // ]);
    //
    // if (record.geoPoint) {
    //   await RoutingService.getRoutesForAccount(record.id);
    // }
    //
    // const childAccountsMap = $scope.entityListToMap(childAccountsResponse.data, {alreadySaved: true});
    // MODEL.childAccounts = childAccountsMap;
    // MODEL.prevChildAccounts = _clone(childAccountsMap);
    //
    // const contactsMap = $scope.entityListToMap(contactsResponse.data, {alreadySaved: true});
    // MODEL.accountContacts = contactsMap;
    // MODEL.prevAccountContacts = _clone(contactsMap);
    //
    // const dealsMap = $scope.entityListToMap(dealsResponse.data, {alreadySaved: true});
    // MODEL.accountDeals = dealsMap;
    // MODEL.prevAccountDeals = _clone(dealsMap);
    //
    // window.refreshDom({
    //   childAccounts: MODEL.childAccounts,
    //   accountContacts: MODEL.accountContacts,
    //   accountDeals: MODEL.accountDeals,
    // });
  };

  const populateDealData = (record, populateAll = true) => {

    const entityChanges = normalizeAddEditObject(difference($scope.deal, $scope.prevDeal));
    $scope.deal.id = record.id;
    $scope.deal.importId = record.importId;
    $scope.deal.createdAtEdit = new Date(record.createdAt);
    $scope.deal.updatedAtEdit = new Date(record.updatedAt);
    $scope.deal.nameEdit = record.name;
    $scope.deal.funnelEdit = record.funnel.id;
    $scope.deal.stage = record.stage;
    $scope.deal.amountEdit = record.amount;
    $scope.deal.scoreEdit = record.score;
    $scope.deal.dealLossReason = record.dealLossReason;
    $scope.deal.dealLossComment = record.dealLossComment;
    $scope.deal.username = record.user.username;
    $scope.deal.editClosingDate = record.closingDate ? moment(record.closingDate).utc(false).format('MM-DD-YYYY') : '';
    $scope.deal.accountNameEdit = '';
    $scope.deal.contactNameEdit = '';
    $scope.deal.addressEdit = '';
    $scope.deal.cityEdit = '';
    $scope.deal.stateEdit = '';
    $scope.deal.zipEdit = '';
    $scope.deal.countryEdit = '';
    $scope.deal.crmLink = record.crmLink;

    if (record.account) {
      $('#linkDealAccEdit').hide();
      $('#clearDealAccEdit').show();
      $scope.deal.accountNameEdit = record.account.name;
      $scope.deal.addressEdit = record.account.address;
      $scope.deal.cityEdit = record.account.city;
      $scope.deal.stateEdit = record.account.region;
      $scope.deal.zipEdit = record.account.postalCode;
      $scope.deal.countryEdit = record.account.country;

      MODEL.currentAssociatedAccountId = record.account.id;
      MODEL.prevAssociatedAccountId = MODEL.currentAssociatedAccountId;
      const {geoPoint} = record.account;
      if (geoPoint) {
        $scope.addLocationMarker(record.stage.color, geoPoint.coordinates[1], geoPoint.coordinates[0], record.id);
      } else {
        $scope.addLocationMarker(record.stage.color, null, null, record.id);
      }
    }

    if (record.contact) {
      $('#linkDealCustEdit').hide();
      $('#clearDealCustEdit').show();
      $scope.deal.contactNameEdit = record.contact.name;
      MODEL.currentAssociatedContactId = record.contact.id;
      MODEL.prevAssociatedContactId = MODEL.currentAssociatedContactId;
    }

    // not populating some fields when deal isn't expected to have then, e.g. after PUT
    if (populateAll) {
      $scope.deal.groups = record.groups;
      $scope.deal.accessStatus = record.accessStatus;
    }

    $scope.prevDeal = {...$scope.deal};
    window.refreshDom({deal: $scope.deal});
    return entityChanges;
  };

  // populate deals
  $scope.populateDeal = (record) => {
    populateDealData(record);
    MainService.setEditOrAddPageSubHeaderVariable('Edit Deal', 'Save Edits', 'edit');
    window.refreshDom({loader: false}, 'show');

    $('#editDealClosingDatePicker').on('dp.change', (e) => {
      MODEL.dirtyEditFlag = true;
      window.refreshDom({dirtyEditFlag: MODEL.dirtyEditFlag});
      $scope.deal.editClosingDate = e.date.format('MM-DD-YYYY');
    });
  };

  const updateWonLostStageIds = () => {
    const
      wonStage = $scope.stages.find(({type}) => type === mmcConst.dealStageWon.type);
    const lostStage = $scope.stages.find(({type}) => type === mmcConst.dealStageLost.type);
    $scope.wonStageId = wonStage ? wonStage.id : undefined;
    $scope.lostStageId = lostStage ? lostStage.id : undefined;
  };

  // init form fields
  $scope.initFormFields = async (entityType, record) => {
    MODEL.currentCRMObject = record;
    MODEL.currentCRMObjectId = record.id;
    MODEL.currentCRMObjectType = entityType;
    MODEL.typeOfLocationUpdatedMostRecently = undefined;

    if (entityType === 'contacts') {
      const notesPromise = AddEditService.fetchNotes(entityType, record.id);
      AddEditService.fetchCrmActivities(entityType, record.id, $scope.activeFormAc);
      const promises = await Promise.all([notesPromise]);
      $scope.populateCommonFields(record);
      await $scope.populateContact(record, promises[0]);
    } else if (entityType === 'accounts') {
      AddEditService.fetchNotes(entityType, record.id);
      AddEditService.fetchCrmActivities(entityType, record.id, $scope.activeFormAc);
      $scope.populateCommonFields(record);
      await $scope.populateAccount(record);
    } else if (entityType === 'leads') {
      AddEditService.fetchNotes(entityType, record.id);
      AddEditService.fetchCrmActivities(entityType, record.id, $scope.activeFormAc);
      $scope.populateCommonFields(record);
      await $scope.populateLead(record);
    } else if (entityType === 'deals') {
      const funnelPromise = dealsNetworkService.fetchAllFunnels();
      const stagePromise = dealsNetworkService.fetchStagesForFunnel(record.funnel.id);
      const lossReasonsPromise = DealLossReasonsNetworkService.fetchAll();
      const promises = await Promise.all([funnelPromise, stagePromise, lossReasonsPromise]);
      $scope.funnels = promises[0].data;
      $scope.stages = promises[1].data;
      $scope.lossReasons = promises[2].data;
      updateWonLostStageIds();
      AddEditService.fetchNotes(entityType, record.id);
      AddEditService.fetchCrmActivities(entityType, record.id, $scope.activeFormAc);
      $scope.populateCommonFields(record);
      await $scope.populateDeal(record);
    }
  };

  // populate edit page according to records
  $scope.populateEditPage = async (table) => {
    MODEL.currentCrmObjectDealsData.length = [];
    MODEL.currentCrmObjectChildAccountsData = [];
    MODEL.currentCrmObjectContactsData = [];
    let crmObjectType = '';

    // populate default contacts and deals
    const {recordId} = $route.current.params;
    MODEL.currentCrmObjectId = recordId;
    // populate records array
    MODEL.show.loader = true;
    if ($route.current.id === 'editContactsPage') {
      crmObjectType = 'contacts';
    } else if ($route.current.id === 'editAccountsPage') {
      crmObjectType = 'accounts';
    } else if ($route.current.id === 'editDealsPage') {
      crmObjectType = 'deals';
    } else if ($route.current.id === 'editLeadsPage') {
      crmObjectType = 'leads';
    }
    AddEditService.loadDefaultData(crmObjectType);
    const filters = {
      includeGroups: true,
      includeCustomFields: true,
      includeNotes: true,
      includeRoutes: true,
    };
    const recordProm = addEditNetworkService.getRecord(table, recordId, filters);
    const groupsProm = addEditNetworkService.getGroups(crmObjectType);
    const customFields = CustomFieldsService.getFields();
    const filesPromise = await FilesService.getFiles();
    const response = await Promise.all([recordProm, groupsProm, customFields, filesPromise]);
    MODEL.GroupsService.currentPageGroups = response[1].data;
    MODEL.GroupsService.currentPageGroupsCount = response[1].total;
    MODEL.accountFieldModel.setCustomFields(response[2]);
    MODEL.files = response[3];
    $scope.initFormFields(table, response[0]);
  };

  // init add or edit page
  $scope.initializeAddEditPage = async function () {
    MODEL.dirtyEditFlag = false;
    AddEditService.initializeData();
    const currencies = await SharedNetworkService.fetchCurrencies();
    MODEL.CustomFields.currencies = currencies.data;

    if ($route.current.id === 'editContactsPage') {
      // to get groups for current edit page
      FetchCRMDataService.populateCurrentGroupsData();
      MainService.setEditOrAddPageSubHeaderVariable('Edit Person', 'Save Edits', 'edit');
      MODEL.AddEditService.addEditView = 'contacts';
    } else if ($route.current.id === 'editDealsPage') {
      // to get groups for current edit page
      FetchCRMDataService.populateCurrentGroupsData();
      MainService.setEditOrAddPageSubHeaderVariable('Edit Deal', 'Save Edits', 'edit');
      MODEL.AddEditService.addEditView = 'deals';
    } else if ($route.current.id === 'editAccountsPage') {
      // to get groups for current edit page
      FetchCRMDataService.populateCurrentGroupsData();
      MainService.setEditOrAddPageSubHeaderVariable('Edit Company', 'Save Edits', 'edit');
      MODEL.AddEditService.addEditView = 'accounts';
    } else if ($route.current.id === 'editLeadsPage') {
      // to get groups for current edit page
      FetchCRMDataService.populateCurrentGroupsData();
      MainService.setEditOrAddPageSubHeaderVariable('Edit Lead', 'Save Edits', 'edit');
      MODEL.AddEditService.addEditView = 'leads';
    } else if ($route.current.id === 'addContactsPage') {
      // populate default contacts and deals
      AddEditService.loadDefaultData('contacts');
      window.refreshDom({loader: false}, 'show');
      MODEL.AddEditService.addEditView = 'contacts';
      MainService.setEditOrAddPageSubHeaderVariable('Add Person', 'Save Person', 'save');
    } else if ($route.current.id === 'addDealsPage') {
      AddEditService.loadDefaultData('deals');
      const promises = await Promise.all([
        dealsNetworkService.fetchAllFunnels(),
        DealLossReasonsNetworkService.fetchAll(),
      ]);
      $scope.funnels = promises[0].data;
      $scope.lossReasons = promises[1].data;
      window.refreshDom({loader: false}, 'show');
      MODEL.AddEditService.addEditView = 'deals';
      MainService.setEditOrAddPageSubHeaderVariable('Add Deal', 'Save Deal', 'save');
      $('#closingDateAddAccount').on('dp.change', (e) => {
        $scope.deal.closingDateAdd = e.date.format('MM-DD-YYYY');
      });
    } else if ($route.current.id === 'addAccountsPage') {
      AddEditService.loadDefaultData('accounts');
      MODEL.AddEditService.addEditView = 'accounts';
      window.refreshDom({loader: false}, 'show');
      MainService.setEditOrAddPageSubHeaderVariable('Add Company', 'Save Company', 'save');
    } else if ($route.current.id === 'addLeadsPage') {
      AddEditService.loadDefaultData('leads');
      MODEL.AddEditService.addEditView = 'leads';
      window.refreshDom({loader: false}, 'show');
      MainService.setEditOrAddPageSubHeaderVariable('Add Lead', 'Save Lead', 'save');
    } else if ($route.current.id === 'addActivityPage') {
      $scope.activities.showDel = false;
      $scope.activities.showCreateAnotherAct = true;
      $scope.activities.headerName = 'Add Activity';
      MODEL.AddEditService.addEditView = 'activities';
      $scope.populateCrmObjectForm();
      window.refreshDom({loader: false}, 'show');
      MainService.setEditOrAddPageSubHeaderVariable('Add Acitivty', 'Save Activity', 'save');
    } else if ($route.current.id === 'editActivityPage') {
      $scope.activities.showDel = true;
      $scope.activities.showCreateAnotherAct = false;
      $scope.activities.headerName = 'Edit Activity';
      MODEL.AddEditService.addEditView = 'activities';
      MainService.setEditOrAddPageSubHeaderVariable('Edit Activity', 'Save Activity', 'save');
      await $scope.populateActivities();
      window.refreshDom({loader: false}, 'show');
    }

    analyticsService.initiated($route.current.metadata.title);

    if ($route.current.id === 'editContactsPage'
        || $route.current.id === 'editDealsPage'
        || $route.current.id === 'editLeadsPage'
        || $route.current.id === 'editAccountsPage'
    ) {
      await $scope.populateEditPage(MODEL.AddEditService.addEditView);
      $scope.$evalAsync(() => {
        MODEL.show.loader = false;
      });
    }
  };

  $scope.populateCrmObjectForm = () => {
    const {currentPage, currentCRMObject, currentCRMObjectType} = MODEL;

    switch (currentCRMObjectType || currentPage) {
      case 'accounts':
        $scope.activities.account = currentCRMObject;
        $scope.activities.accountText = currentCRMObject.name;
        break;
      case 'contacts':
        $scope.activities.account = currentCRMObject.account || null;
        $scope.activities.accountText = currentCRMObject.account ? currentCRMObject.account.name : null;
        $scope.activities.contact = currentCRMObject;
        $scope.activities.contactText = currentCRMObject.name;
        break;
      case 'deals':
        $scope.activities.account = currentCRMObject.account || null;
        $scope.activities.accountText = currentCRMObject.account ? currentCRMObject.account.name : null;
        $scope.activities.contact = currentCRMObject.contact || null;
        $scope.activities.contactText = currentCRMObject.contact ? currentCRMObject.contact.name : null;
        $scope.activities.deal = currentCRMObject;
        $scope.activities.dealText = currentCRMObject.name;
        break;
    }
  };

  $scope.populateActivities = async () => {
    let record = MODEL.CrmActivitiesService.activity;
    if (!MODEL.CrmActivitiesService.activity) {
      const url = window.location.href;
      const id = url.substring(url.lastIndexOf('/') + 1);
      record = await activitiesNetworkService.fetchCrmActivity(id);
    }

    $scope.activities.name = record.name;
    $scope.activities.duration = record.duration;
    $scope.activities.completed = record.completed;
    $scope.activities.isPublic = record.isPublic;
    $scope.activities.account = record.account;
    $scope.activities.contact = record.contact;
    $scope.activities.deal = record.deal;
    $scope.activities.accountText = record.account && record.account.name ? record.account.name : '';
    $scope.activities.contactText = record.contact && record.contact.name ? record.contact.name : '';
    $scope.activities.dealText = record.deal && record.deal.name ? record.deal.name : '';
    $scope.activities.note = record.note;
    $scope.activities.assigneeId = record.assignee && record.assignee.id ? record.assignee.id : null;
    $scope.activities.crmActivityType = record.crmActivityType;
    const startAt = moment(record.startAt);
    $scope.activities.date = startAt.isValid() ? startAt.format('MMM DD, YYYY') : '';
    $scope.activities.time = startAt.isValid() ? startAt.format('hh:mm a') : '';
    $scope.activities.lastUpdatedBy = record.lastUpdatedBy;
    $scope.activities.updatedAt = record.updatedAt;
    $scope.activities.autoGenerated = record.autoGenerated;
    $scope.activities.crmLink = record.crmLink;
  };

  // set current location on map fo edit pages
  $scope.getCurretLocForEdit = () => {
    const currentLoc = MAN.userPosition || MAN.lastResortPosition;
    const latlng = {lat: currentLoc.lat, lng: currentLoc.lng};
    window.mmcUtils.reverseGeoCode(latlng)
      .then((geocodedArray) => {
        $('#editMapInput').val(`${geocodedArray.address} ${geocodedArray.city} ${geocodedArray.country} ${geocodedArray.zip}`);
        MODEL.editCrmObjectMarker.setMap(null);

        let color = 'black';
        if (MODEL.currentCRMObject.color) {
          color = MODEL.currentCRMObject.color;
        }
        const colorURL = `./images/pins-large/marker_${color || 'black'}.png`;

        const markerOptions = {
          icon: {
            url: colorURL,
          },
          map: MODEL.editCrmObjectMap,
          position: latlng,
          draggable: true,
        };
        MODEL.editCrmObjectMarker = new google.maps.Marker(markerOptions);
        MODEL.editCrmObjectMarker.setMap(MODEL.editCrmObjectMap);
      })
      .catch((error) => {
        helperService.showAndLogError(error);
      });
  };

  $scope.fetchAllData = () => new Promise((resolve, reject) => {
    if (!MODEL.nonEnterprise) {
      resolve();
      return;
    }
    const dataPromises = [];
    dataPromises.push(accountsNetworkService.fetchAccountsData(MODEL.accountsRaw.length === 0 || !MODEL.nonEnterprise));
    dataPromises.push(contactsNetworkService.fetchContactsData(MODEL.customersRaw.length === 0 || !MODEL.nonEnterprise));
    dataPromises.push(dealsNetworkService.getDealsForUser('', {
      limit: MODEL.recordsUpperLimit,
      count: 1,
    }));

    Promise.all(dataPromises)
      .then((tableData) => {
        const pagePromises = [];
        pagePromises.push(FetchCRMDataService.fetchDealsData(tableData[2].response, MODEL.dealsRaw.length === 0 || !MODEL.nonEnterprise));
        pagePromises.push(FetchCRMDataService.fetchCustomerData(tableData[1].response, MODEL.customersRaw.length === 0 || !MODEL.nonEnterprise));
        pagePromises.push(FetchCRMDataService.fetchAccountsData(tableData[0].response, MODEL.accountsRaw.length === 0 || !MODEL.nonEnterprise));

        Promise.all(pagePromises)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            helperService.showAndLogError(error);
            reject(error);
          });
      })
      .catch((error) => {
        helperService.showAndLogError(error);
        reject(error);
      });
  });

  $scope.initializeAddEditPage();

  $scope.addLocationMarker = (color, latitude, longitude) => {
    if (!MODEL.map) {
      MODEL.map = MAN.getMap();
    }
    if (!MODEL.map || !google) {
      return;
    }

    MODEL.customersOverlay.forEach((pin) => {
      pin.setMap(null);
    });
    MAN.allMapContent.forEach(pin => {
      pin.setMap(null);
    });
    MAN.allMapContent = [];
    if (MODEL.markerCluster !== '') {
      MODEL.markerCluster.removeMarkers(MODEL.customersOverlay);
    }
    MODEL.customersOverlay = [];

    // show banner alert if pin is not mapped
    if (isNil(latitude)) {
      $('#customerLat').text('customer_lat');
      $('#customerLng').text('customer_lng');
      const markerOptions = {
        map: MODEL.map,
        position: new google.maps.LatLng(MAN.userPosition || MAN.lastResortPosition),
        draggable: true,
      };
      MODEL.editCrmObjectMarker = new google.maps.Marker(markerOptions);
    } else {
      const pinLatlng = new google.maps.LatLng(latitude, longitude);
      // sometimes color is initialized as ? undefined:undefined ?
      const pinColor = color && color[0] !== '?' ? color : 'black';
      const colorURL = `./images/pins-large/marker_${pinColor.replace(' ', '_')}.png`;
      const markerOptions = {
        icon: {
          url: colorURL,
        },
        map: MODEL.map,
        position: pinLatlng,
        draggable: true,
      };
      MODEL.editCrmObjectMarker = new google.maps.Marker(markerOptions);
    }

    MODEL.editCrmObjectMarker.setMap(MODEL.map);
    MODEL.customersOverlay.push(MODEL.editCrmObjectMarker);
    MAN.allMapContent.push(MODEL.editCrmObjectMarker);

    const bounds = new google.maps.LatLngBounds();
    bounds.extend(MODEL.editCrmObjectMarker.getPosition());
    MODEL.map.fitBounds(bounds);
    MODEL.map.setZoom(isNil(latitude) ? 3 : 12);
    MODEL.map.setCenter(MODEL.editCrmObjectMarker.getPosition());
    MAN.saveCenter(MODEL.map);
    $('#map').show();
    MAN.recycleMap();

    $scope.addEventListenerToPinDrag();
  };

  $scope.removeChildAccount = (id, e) => {
    AddEditService.removeChildAccount(id);
    if (e) {
      e.stopPropagation();
    }
  };

  // remove the contact from Account
  $scope.removeContactFromAccount = (id, e) => {
    AddEditService.removeContactFromAccount(id);
    if (e) {
      e.stopPropagation();
    }
  };

  $scope.saveNewParentAccount = async function (crmPage, e) {
    const parentId = MODEL.currentCrmObjectId;
    const obj = MODEL.childAccounts;
    $scope.saveEdits(crmPage, e);
    await AddEditService.saveCrmType('accounts', parentId, obj, true);
    MODEL.childAccounts = {};
    window.refreshDom({childAccounts: {}});
  };

  // save customer edits
  $scope.saveEdits = async function (crmPage, e, flag) {
    let obj = {};
    if (e) {
      e.stopPropagation();
    }
    switch (crmPage) {
      case 'accountsPage':
        if (!flag && MODEL.prevAssociatedAccountId !== MODEL.currentAssociatedAccountId) {
          swal('Uh-oh', 'Please save parent company first', 'error');
          return;
        }
        if (flag) {
          MODEL.parentAccountModified = false;
          obj = $scope.prevAccount;
          obj.parentAccount = MODEL.currentAssociatedAccountId ? {id: MODEL.currentAssociatedAccountId} : null;
          MODEL.prevAssociatedAccountId = MODEL.currentAssociatedAccountId;
        } else {
          obj = $scope.accounts;
        }
        break;
      case 'contactsPage':
        if (!flag && MODEL.prevAssociatedAccountId !== MODEL.currentAssociatedAccountId) {
          swal('Uh-oh', 'Please save a company first', 'error');
          return;
        }
        if (flag) {
          MODEL.contactAccountModified = false;
          obj = $scope.prevContact;
          obj.account = MODEL.currentAssociatedAccountId ? {id: MODEL.currentAssociatedAccountId} : null;
          MODEL.prevAssociatedAccountId = MODEL.currentAssociatedAccountId;
        } else {
          obj = $scope.contacts;
        }
        break;
      case 'dealsPage':
        obj = $scope.deal;
    }
    if (crmPage !== 'dealsPage' && trim(obj.addressEdit) && !trim(obj.countryEdit)) {
      swal('Uh-oh', 'Please also specify a country', 'error');
      return;
    }
    const updatedObj = await CustomerService.saveEdits(crmPage, obj, flag);
    if (!updatedObj || !updatedObj.id) {
      return;
    }

    switch (crmPage) {
      case 'contactsPage':
        $scope.populateContactData(updatedObj, false);
        break;
      case 'dealsPage':
        populateDealData(updatedObj, false);
        break;
      case 'accountsPage':
        $scope.populateAccountData(updatedObj, false);
        break;
    }

    $scope.$apply();
  };

  $scope.deleteDeal = async function (id, e) {
    if (e) {
      e.stopPropagation();
    }

    if (MODEL.accountDeals[id]) {
      await swal({
        title: 'Are you sure?',
        text: 'The deal would be deleted!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, remove this deal',
        closeOnConfirm: false,
      });
      // code for deleting deal
      await dealsNetworkService.deleteDeal(id);
    }
    delete MODEL.accountDeals[id];
    window.refreshDom({accountDeals: MODEL.accountDeals});
  };

  $scope.setAddressFields = (latlng) => {
    // Do not reverse geocode on every pin drag
    if (window.isOnPage('account')) {
      $scope.accounts.countryEdit = '';
      $scope.accounts.cityEdit = '';
      $scope.accounts.stateEdit = '';
      $scope.accounts.addressEdit = '';
      $scope.accounts.zipEdit = '';
      $scope.accounts.currentCustomerLat = latlng.lat();
      $scope.accounts.currentCustomerLng = latlng.lng();
    }

    if (window.isOnPage('contacts')) {
      $scope.contacts.countryEdit = '';
      $scope.contacts.cityEdit = '';
      $scope.contacts.stateEdit = '';
      $scope.contacts.addressEdit = '';
      $scope.contacts.zipEdit = '';
      $scope.contacts.currentCustomerLat = latlng.lat();
      $scope.contacts.currentCustomerLng = latlng.lng();
    }

    window.refreshDom({dirtyEditFlag: true});
  };

  // add the event listener to pin drag
  $scope.addEventListenerToPinDrag = () => {
    google.maps.event.addListener(MODEL.editCrmObjectMarker, 'dragend', (event) => {
      MODEL.currentCustomerLat = event.latLng.lat();
      MODEL.currentCustomerLng = event.latLng.lng();

      MODEL.typeOfLocationUpdatedMostRecently = 'pin';
      $scope.setAddressFields(event.latLng);
    });
  };

  // function to save all the child accounts
  $scope.saveChildAccounts = async function () {
    await AddEditService.saveCrmType('accounts', MODEL.currentCrmObjectId, MODEL.childAccounts);
    window.refreshDom({childAccountsModified: false});
  };

  $scope.revertChildAccounts = () => {
    window.refreshDom({childAccounts: _clone(MODEL.prevChildAccounts)});
    window.refreshDom({childAccountsModified: false});
  };

  // function to save all the child accounts
  $scope.saveAccountContacts = async function () {
    MODEL.accountContactsModified = false;
    await AddEditService.saveCrmType('contacts', MODEL.currentCrmObjectId, MODEL.accountContacts);
    window.refreshDom({accountContactsModified: MODEL.accountContactsModified});
  };

  $scope.revertAccountContacts = () => {
    window.refreshDom({accountContacts: _clone(MODEL.prevAccountContacts)});
    window.refreshDom({accountContactsModified: false});
  };

  // function to save all the deals
  $scope.saveAccountDeals = async function () {
    MODEL.accountDealsModified = false;
    await AddEditService.saveCrmType('deals', MODEL.currentCrmObjectId, MODEL.accountDeals);
    window.refreshDom({accountDealsModified: MODEL.accountDealsModified});
  };

  $scope.revertAccountDeals = () => {
    window.refreshDom({accountDeals: _clone(MODEL.prevAccountDeals)});
    window.refreshDom({accountDealsModified: false});
  };

  // save new customer
  $scope.saveCustomer = function (objectType) {
    let obj = {};

    if (MODEL.currentPageEditOrAddSubheader.addButtonName.indexOf('Company') >= 0) {
      objectType = 'accounts';
      obj = $scope.accounts;
    } else if (MODEL.currentPageEditOrAddSubheader.addButtonName.indexOf('Person') >= 0) {
      objectType = 'contacts';
      obj = $scope.contacts;
    } else if (MODEL.currentPageEditOrAddSubheader.addButtonName.indexOf('Lead') >= 0) {
      objectType = 'leads';
      obj = $scope.leads;
    } else {
      objectType = 'deals';
      obj = $scope.deal;
    }

    if (objectType !== 'deals' && trim(obj.addressAdd) && !trim(obj.countryAdd)) {
      swal('Uh-oh', 'Please also specify a country', 'error');
      return;
    }

    CustomerService.saveCustomer(objectType, obj);
  };

  $scope.goToAccount = (parentAccount) => {
    if (!parentAccount) {
      return;
    }
    MODEL.prevAssociatedAccountId = undefined;

    const id = MODEL.currentAssociatedAccountId;
    window.location.href = `/#/accounts/edit/${id}`;
  };

  $scope.goToContact = () => {
    if (!MODEL.currentAssociatedContactId || !$scope.determineAccessRights('update', 'contacts')) {
      return;
    }

    const id = MODEL.currentAssociatedContactId;
    $location.path(`/contacts/edit/${id}`);
  };

  // re populate the stages as well
  $scope.changeFunnel = async function (funnelId) {
    MODEL.show.loader = true;
    const stages = await dealsNetworkService.fetchStagesForFunnel(funnelId);
    $scope.stages = stages.data;
    updateWonLostStageIds();
    window.refreshDom({loader: false}, 'show');
  };

  // revert changes made to deals
  $scope.revertDealDetails = () => {
    $('#accountNameEditDeal').text($scope.prevDeal.accountNameEdit);
    $('#contactNameEditDeal').text($scope.prevDeal.contactNameEdit);
    if (!$scope.prevDeal.contactNameEdit) {
      $scope.clearContactOnDealsPage();
    } else {
      $('#linkDealCustEdit').hide();
      $('#clearDealCustEdit').show();
    }
    MODEL.currentAssociatedAccountId = MODEL.prevAssociatedAccountId;
    MODEL.currentAssociatedContactId = MODEL.prevAssociatedContactId;
    $scope.deal = _clone($scope.prevDeal);
    MODEL.dirtyEditFlag = false;
    window.refreshDom({dirtyEditFlag: MODEL.dirtyEditFlag});
  };

  $scope.removeContactDeal = (id, e) => {
    AddEditService.removeContactDeal(id);
    if (e) {
      e.stopPropagation();
    }
  };

  $scope.revertContactDeals = () => {
    MODEL.contactDealsModified = false;
    const obj = {...MODEL.prevContactDeals};
    window.refreshDom({contactDeals: obj});
    window.refreshDom({contactDealsModified: MODEL.contactDealsModified});
  };

  // function to save all the deals
  $scope.saveContactDeals = async function () {
    MODEL.contactDealsModified = false;
    await AddEditService.saveCrmType('deals', MODEL.currentCrmObjectId, MODEL.contactDeals);
    window.refreshDom({contactDealsModified: MODEL.contactDealsModified});
  };

  $scope.removeDealFromContact = (id, e) => {
    AddEditService.removeDealFromContact(id);
    if (e) {
      e.stopPropagation();
    }
  };

  // close the form that was tapped
  $scope.closeAccount = function (objectType) {
    MainService.closeAccount(objectType);
  };

  // populates the add new customer form with your current location
  $scope.findMe = async function () {
    MODEL.show.loader = true;
    let crmObject = $scope.accounts;

    if (window.isOnPage('contacts')) {
      crmObject = $scope.contacts;
    }

    const currentAddress = await MainService.findMe(crmObject);
    $scope.$applyAsync(() => {
      MODEL.show.loader = false;

      Object.assign($scope.accounts, currentAddress);
    });
  };

  $scope.isRegularStage = (stage) => (!stage) || (stage && !['lost', 'won'].includes(stage.type));

  $scope.isWonStage = (stage) => stage && stage.type === 'won';

  $scope.openDealLossReasonsPopup = () => {
    MODEL.AddEditService.showDealLossReasonsPopup = true;
  };

  $scope.closeDealLossReasonsPopup = () => {
    MODEL.AddEditService.showDealLossReasonsPopup = false;
  };

  $scope.changeLossReason = (reason) => {
    MODEL.AddEditService.dealLossReasonShowMenu = false;

    if (reason === 'addnew') {
      $scope.deal.dealLossReason = undefined;
      MODEL.AddEditService.dealLossReasonNewReason = true;
      MODEL.AddEditService.dealLossReasonNewReasonName = '';
      $timeout(() => {
        $('.mmc-deal-loss-reasons-popup__reason-name-field').focus();
      });
    } else {
      $scope.deal.dealLossReason = reason;
    }
  };

  $scope.cancelNewLossReason = () => {
    MODEL.AddEditService.dealLossReasonNewReason = false;
  };

  $scope.saveNewLossReason = async () => {
    const name = MODEL.AddEditService.dealLossReasonNewReasonName.trim();
    MODEL.show.loader = true;

    try {
      const newReason = await DealLossReasonsNetworkService.create(name);
      $scope.lossReasons.push(newReason);
      $scope.deal.dealLossReason = newReason; // this one must go after push into lossReasons
      window.refreshDom({dealLossReasonNewReason: false}, 'AddEditService');
    } catch (e) {
      UTILS.showValidationError(e, 'Something went wrong, please try again');
    }

    window.refreshDom({loader: false}, 'show');
  };

  $scope.saveDealLoss = async () => {
    if (!$scope.deal.dealLossReason) {
      swal('Uh-oh', 'Please specify loss reason', 'error');
      return;
    }

    $scope.deal.stage = {id: $scope.lostStageId};
    $scope.closeDealLossReasonsPopup();
    MODEL.dirtyEditFlag = true;
    $scope.saveEdits('dealsPage');
  };

  $scope.makeDealWon = async () => {
    $scope.deal.stage = {id: $scope.wonStageId};
    $scope.deal.dealLossReason = undefined;
    $scope.deal.dealLossComment = undefined;
    MODEL.dirtyEditFlag = true;
    await $scope.saveEdits('dealsPage');
  };

  $scope.doReopenDeal = () => {
    const stage = $scope.stages
      .filter(({type}) => !['lost', 'won'].includes(type))
      .reduce((result, item) => (item.displayOrder > result.displayOrder ? item : result));
    $scope.deal.stage = {id: stage.id};
    $scope.deal.dealLossReason = undefined;
    $scope.deal.dealLossComment = undefined;
    $scope.closeDealLossReasonsPopup();
    MODEL.dirtyEditFlag = true;
    $scope.saveEdits('dealsPage');
  };

  $scope.reopenDeal = () => {
    if ($scope.deal.stage.type === 'won') {
      $scope.doReopenDeal();
      return;
    }

    $scope.openDealLossReasonsPopup();
  };

  $scope.toggleLossReasonMenu = () => {
    MODEL.AddEditService.dealLossReasonShowMenu = !MODEL.AddEditService.dealLossReasonShowMenu;
  };

  $scope.removeLossReason = (reason, event) => {
    if (event) {
      event.stopPropagation();
    }
    swal({
      title: 'Are you sure?',
      text: "You won't be able to recover this loss reason!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, delete now',
    })
      .then(() => {
        MODEL.show.loader = true;
        return DealLossReasonsNetworkService.remove(reason.id);
      })
      .then(() => {
        $scope.lossReasons = $scope.lossReasons.filter(({id}) => id !== reason.id);
        if ($scope.deal.dealLossReason && $scope.deal.dealLossReason.id === reason.id) {
          $scope.deal.dealLossReason = undefined;
        }
      })
      .catch((e) => {
        if (e === 'cancel') {
          return;
        }
        let message = 'Failed to remove, please try again later!';
        if (e && e.validationErrors && e.validationErrors.length) {
          message = e.validationErrors[0].message;
        }
        swal('Uh-oh', message, 'error');
      })
      .then(() => {
        window.refreshDom({loader: false}, 'show');
      })
      .catch((error) => {
        helperService.logError(error);
      });
  };

  $scope.changeActivityType = (type) => {
    $scope.dirtyActivityFlag = true;
    $scope.activities.crmActivityType = type;
    if (!$scope.activities.nameChanged && $route.current.id === 'addActivityPage') {
      $scope.activities.name = type.name;
    }
  };

  $scope.nameChanged = () => {
    $scope.dirtyActivityFlag = true;
    $scope.activities.nameChanged = true;
  };

  $scope.toggleDateTimePicker = () => {
    $('#activityDatetimepicker').data('DateTimePicker').toggle();
  };

  $scope.addObjectToActivity = (object, type) => {
    switch (type) {
      case 'accounts':
        $scope.activities.account = object;
        $scope.activities.accountText = object.name;
        $scope.activities.contact = null;
        $scope.activities.contactText = '';
        $scope.activities.deal = null;
        $scope.activities.dealText = '';
        $scope.activities.accountSearchFocused = false;
        $scope.activities.accountOptions = [];
        break;
      case 'contacts':
        $scope.activities.contact = object;
        $scope.activities.contactText = object.name;
        $scope.activities.deal = null;
        $scope.activities.dealText = '';
        if (object.account) {
          $scope.activities.account = object.account;
          $scope.activities.accountText = object.account.name;
        }
        $scope.activities.contactSearchFocused = false;
        $scope.activities.contactOptions = [];
        break;
      case 'deals':
        $scope.activities.deal = object;
        $scope.activities.dealText = object.name;
        $scope.activities.account = object.account;
        $scope.activities.accountText = object.account.name;
        if (object.contact) {
          $scope.activities.contact = object.contact;
          $scope.activities.contactText = object.contact.name;
        }
        $scope.activities.dealSearchFocused = false;
        $scope.activities.dealOptions = [];
        break;
    }
    $scope.dirtyActivityFlag = true;
  };

  $scope.saveActivity = async () => {
    MODEL.show.loader = true;
    const obj = {};
    if ($route.current.id === 'editActivityPage') {
      const url = window.location.href;
      obj.id = Number(url.substring(url.lastIndexOf('/') + 1));
    }

    obj.name = $scope.activities.name;
    obj.duration = $scope.activities.duration;
    obj.completed = $scope.activities.completed;
    obj.isPublic = $scope.activities.isPublic;
    obj.account = ($scope.activities.accountText && $scope.activities.account) || null;
    obj.contact = ($scope.activities.contactText && $scope.activities.contact) || null;
    obj.deal = ($scope.activities.dealText && $scope.activities.deal) || null;
    obj.note = $scope.activities.note || null;
    obj.assigneeId = $scope.activities.assigneeId || safeLocalStorage.currentUser.id;
    obj.crmActivityType = $scope.activities.crmActivityType;
    const startAt = moment(`${$scope.activities.date} ${$scope.activities.time}`);
    obj.startAt = startAt.isValid() ? startAt.toISOString() : null;
    obj.autoGenerated = $scope.activities.autoGenerated || null;

    try {
      if (obj.id) {
        await activitiesNetworkService.updateCrmActivity(obj);
        analyticsService.entityUpdated('Activity', obj);
      } else {
        const response = await activitiesNetworkService.createCrmActivity(obj);
        analyticsService.entityAdded('Activity', obj);
        // save custom fields
        try {
          await CustomFieldsService.actuallyCreateValues('crmActivities', response.id);
        } catch (cfResponse) {
          helperService.showAndLogError(cfResponse, 'Failed to save custom fields');
        }
      }

      window.refreshDom({loader: false}, 'show');
    } catch (e) {
      window.refreshDom({loader: false}, 'show');
      if (get(e, 'validationErrors[0].code') === 'v0311') {
        swal('Uh-Oh!', 'Please attach a company, person, or deal before saving this activity.', 'error');
      } else {
        helperService.showAndLogError(e, 'Failed to save changes, please try again');
      }

      return;
    }

    if (!obj.id) {
      swal('Success', 'Your activity was created successfully', 'success');
      if (!$scope.activities.createNextActivity) {
        setTimeout(() => {
          window.history.back();
        }, 1000);
      } else {
        $scope.initializeAddActivityPage();
      }
    } else {
      $scope.dirtyActivityFlag = false;
      window.history.back();
    }
  };

  $scope.closeActivity = () => {
    if ($scope.dirtyActivityFlag) {
      swal({
        title: 'Uh-Oh...',
        text: 'You have unsaved changes, are you sure you want to leave this page?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Close it!',
      }).then((result) => {
        if (result) {
          window.history.back();
        }
      }).catch(swal.noop);
    } else {
      window.history.back();
    }
  };

  $scope.getFormattedAddress = ({address = '', city = '', region = ''}) => {
    const addressParts = [address, city, region].filter(part => !!part);
    if (region && addressParts.length > 1) {
      addressParts[addressParts.length - 2] += ',';
    }
    return addressParts.join(' ');
  };

  $scope.getFormattedContacts = ({phone, email}) => [phone, email].filter(v => !!v).join(', ');

  $scope.changeActivitiesOptions = (type, entityType) => {
    if ($scope.activeFormAc !== type) {
      AddEditService.fetchCrmActivities(entityType, MODEL.currentCrmObjectId, type);
    }
    $scope.activeFormAc = type;
  };

  $scope.toggleComplete = async (activity, entityType, event) => {
    if (event) {
      event.stopPropagation();
    }
    const response = await CrmActivitiesService.toggleComplete(activity);
    if (response) {
      AddEditService.fetchCrmActivities(entityType, MODEL.currentCrmObjectId, $scope.activeFormAc);
    }
  };

  $scope.deleteActivity = async () => {
    const id = UTILS.getIdFromCurrentUrl();
    await swal({
      title: 'Are you sure?',
      text: 'Deleting this activity will remove it from all objects',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes, delete this activity',
      closeOnConfirm: true,
    });
    await activitiesNetworkService.deleteCrmActivity(id);
    analyticsService.entityDeleted('Activity', id);
    window.history.back();
  };

  $scope.hideSearchModal = () => {
    $scope.activities.accountOptions = [];
    $scope.activities.contactOptions = [];
    $scope.activities.dealOptions = [];
  };

  $scope.toggleCompleteActivityOnForm = (complete) => {
    $scope.activities.completed = complete;
    $scope.dirtyActivityFlag = true;
  };

  $scope.toggleVisibility = (isPublic) => {
    $scope.activities.isPublic = isPublic;
    $scope.dirtyActivityFlag = true;
  };

  $scope.addAddressToContact = async () => {
    MODEL.show.loader = true;
    const record = await addEditNetworkService.getRecord('accounts', MODEL.currentAssociatedAccountId, {});
    window.refreshDom({loader: false}, 'show');
    if (record.code === 404) {
      swal('Uh-oh...', 'You do not have the access to the company. Please contact your organization owner to access the company and inherit address from the company.', 'warning');
    } else if ($route.current.id === 'editContactsPage') {
      $scope.contacts = {
        ...$scope.contacts,
        addressEdit: record.address,
        cityEdit: record.city,
        stateEdit: record.region,
        countryEdit: record.country,
        zipEdit: record.postalCode,
      };
      MODEL.dirtyEditFlag = true;
    } else if ($route.current.id === 'addContactsPage') {
      $scope.contacts = {
        ...$scope.contacts,
        addressAdd: record.address,
        cityAdd: record.city,
        stateAdd: record.region,
        countryAdd: record.country,
        zipAdd: record.postalCode,
      };
      MODEL.dirtyEditFlag = true;
    }
  };

  $scope.performAllowedAction = (methodName, accessRight = 'update') => {
    if ($scope.determineAccessRights(accessRight)) {
      $scope[methodName]();
    }
  };

  $scope.determineAccessRights = (type, entityType) => {
    let entity = {};

    if ($route.current.id === 'editContactsPage') {
      entity = $scope.contacts;
      entityType = 'Contacts';
    } else if ($route.current.id === 'editAccountsPage') {
      entity = $scope.accounts;
      entityType = 'Accounts';
    } else if ($route.current.id === 'editDealsPage') {
      entity = $scope.deal;
      entityType = 'Deals';
    } else if ($route.current.id === 'editActivityPage') {
      entity = $scope.activities;
      entityType = 'Activities';
    }

    if (entity.username === safeLocalStorage.currentUser.username ||
            safeLocalStorage.currentUser.role.key !== 'MEMBER'
    ) {
      return true;
    } if (get(entity, `accessStatus.${type}`)) {
      return true;
    } if (get(MODEL.TeamsService, `selectedMemberShared${entityType}`)) {
      return get(MODEL.TeamsService, `selectedMemberShared${entityType}`).includes(entity);
    }
    return false;
  };

  $scope.countryList = countryList;

}

AddEditCtrl.$inject = [
  '$scope', '$location', '$route', '$timeout', 'BaseController',
  'DataService', 'AddEditService', 'MainService', 'FetchCRMDataService', 'MappingService', 'RoutingService',
  'CrmActivityTypesNetworkService', 'ActivityNetworkService',
  'CustomerService', 'FunnelService',
  'mmcConst', 'DealLossReasonsNetworkService', 'CrmActivitiesService',
  'TeamService', 'CustomFieldsService', 'FilesService',
];
