import dynamicsLogo from '../../../../../images/integrations/microsoft-dynamics-365-logo.png';
import hubspotLogo from '../../../../../images/integrations/hubspotlogo-web-color.svg';
import salesforceLogo from '../../../../../images/integrations/logo-salesforce.svg';
import zohoLogo from '../../../../../images/integrations/zoho-logo-512px.png';

// We don't have small logos yet, so we're using a full-sized logos
export const dynamicsSmallLogo = dynamicsLogo;
export const hubspotSmallLogo = hubspotLogo;
export const salesforceSmallLogo = salesforceLogo;
export const zohoSmallLogo = zohoLogo;

export {
  dynamicsLogo,
  hubspotLogo,
  salesforceLogo,
  zohoLogo,
};
