import throttle from 'lodash/throttle';
import accountsNetworkService from '../../network-services/accounts-network-service';
import contactsNetworkService from '../../network-services/contacts-network-service';
import dealsNetworkService from '../../network-services/deals-network-service';
import routingNetworkService from '../../network-services/routing-network-service';
import SharedNetworkService from '../../network-services/shared-network-service';
import helperService from '../../shared-services/helper-service';
import template from './team-share-groups.html';

function TeamShareGroupsController(
  $scope, $window, TeamService, TerrNetworkService,
) {
  Object.assign(this, $scope, {
    $scope,
    $window,
    MODEL: $window.DataModel,
    TeamService,
    SharedNetworkService,
    accountsNetworkService,
    contactsNetworkService,
    dealsNetworkService,
    routingNetworkService,
    TerrNetworkService,
    toggleSelectAll,
    hasSharedGroup,
    toggleSharing,
    refreshSharedGroups,
    entitySearch,
    loadMoreGroups: throttle(loadMoreGroups, 500, {leading: true}),
    $onInit,
    initialize,
    fetchGroups,
  });

  this.$scope.$watch('$ctrl.userId', () => {
    this.refreshSharedGroups();
  });

  this.$scope.$watch('$ctrl.entityType', () => {
    this.initialize();
  });

  function $onInit() {
    this.sharedGroupIds = new Set();
    this.searchText = '';
    this.allGroupsAreChecked = false;
    this.displayedGroups = [];
  }

  function hasSharedGroup({id}) {
    return this.sharedGroupIds.has(id);
  }

  function toggleSelectAll() {
    if (!this.displayedGroups.length) {
      return;
    }

    if (this.allGroupsAreChecked) {
      this.allGroupsAreChecked = false;
      const groupsToDelete = [];
      this.displayedGroups.forEach(group => {
        if (this.sharedGroupIds.has(group.id)) {
          this.sharedGroupIds.delete(group.id);
          groupsToDelete.push({groupId: group.id});
        }
      });
      this.SharedNetworkService.deleteSharedGroup(this.userId, {sharedGroups: groupsToDelete});
    } else {
      this.allGroupsAreChecked = true;
      const groupsToAdd = [];
      this.displayedGroups.forEach(group => {
        this.sharedGroupIds.add(group.id);
        groupsToAdd.push({groupId: group.id});
      });
      this.SharedNetworkService.postSharedGroup(this.userId, {sharedGroups: groupsToAdd});
    }
  }

  const updatedAllGroupsCheckedStatus = () => {
    this.allGroupsAreChecked = this.displayedGroups.length
      && this.displayedGroups.every(group => this.sharedGroupIds.has(group.id));
  };

  function toggleSharing(group) {
    if (this.hasSharedGroup(group)) {
      this.sharedGroupIds.delete(group.id);
      this.allGroupsAreChecked = false;
      this.SharedNetworkService.deleteSharedGroup(this.userId, {sharedGroups: [{groupId: group.id}]})
        .catch((error) => {
          this.sharedGroupIds.add(group.id);
          helperService.logError(error);
        });
    } else {
      this.sharedGroupIds.add(group.id);
      updatedAllGroupsCheckedStatus();
      this.SharedNetworkService.postSharedGroup(this.userId, {sharedGroups: [{groupId: group.id}]})
        .catch((error) => {
          this.allGroupsAreChecked = false;
          this.sharedGroupIds.delete(group.id);
          helperService.logError(error);
        });
    }
  }

  async function refreshSharedGroups() {
    this.MODEL.show.loader = true;
    const groupAssociations = (await this.SharedNetworkService.fetchSharedGroups(this.userId)).data;
    this.sharedGroupIds = new Set(groupAssociations.map(({group}) => group.id));
    updatedAllGroupsCheckedStatus();
    window.refreshDom({loader: false}, 'show');
  }

  async function entitySearch(searchText) {
    this.searchText = searchText;
    if (searchText && searchText.length > 0) {
      this.page = 1;
      this.filter = {name: {$in: searchText}};
      const response = await this.fetchGroups(this.filter, this.page);
      this.totalGroups = response.total;
      this.$scope.$applyAsync(() => {
        this.displayedGroups = response.data;
      });
      return response.data;
    }

    this.$scope.$applyAsync(() => {
      this.displayedGroups = this.unfilteredGroups;
    });
    return [];
  }

  async function loadMoreGroups() {
    if (this.displayedGroups.length < this.totalGroups) {
      const response = await this.fetchGroups(this.filter, ++this.page);
      this.$scope.$applyAsync(() => {
        this.displayedGroups = this.displayedGroups.concat(response.data);
      });
    }
  }

  async function initialize() {
    this.MODEL.show.loader = true;

    this.searchText = '';
    this.filter = {};
    this.page = 1;
    this.groupsInfoImgSrc = 'images/dashboard/groupsAreLoading.svg';

    const response = await this.fetchGroups(this.filter, this.page);
    this.unfilteredGroups = response.data;
    this.totalGroups = response.total;
    this.displayedGroups = this.unfilteredGroups;
    if (this.entityType === 'territories') {
      this.groupsInfoImgSrc = 'images/dashboard/noterrs.svg';
    } else if (this.entityType === 'accountRoutes' || this.entityType === 'contactRoutes') {
      this.groupsInfoImgSrc = 'images/dashboard/noroutes.svg';
    } else {
      this.groupsInfoImgSrc = `images/dashboard/no${this.entityType}.svg`;
    }
    updatedAllGroupsCheckedStatus();
    window.refreshDom({loader: false}, 'show');
  }

  async function fetchGroups(filters = {}, page = 1) {
    try {
      switch (this.entityType) {
        case 'accounts':
          return accountsNetworkService.fetchAllAccountGroups(filters, page, 'name', true, -1);
        case 'contacts':
          return contactsNetworkService.fetchAllContactGroups(filters, page, 'name', true, -1);
        case 'deals':
          return dealsNetworkService.fetchAllDealGroups(filters, page, 'name', true, -1);
        case 'accountRoutes':
          return routingNetworkService.getAccountRoutes(filters, page, 'name', true);
        case 'contactRoutes':
          return routingNetworkService.getContactRoutes(filters, page, 'name', true);
        case 'territories': return TerrNetworkService.fetchTerritories({
          $filters: filters,
          $offset: Math.max(0, page - 1) * this.MODEL.pageSize,
          $limit: this.MODEL.pageSize,
          $order: 'name',
        });
      }
    } catch (e) {
      helperService.showAndLogError(e);
    }
  }
}

TeamShareGroupsController.$inject = [
  '$scope', '$window', 'TeamService', 'TerrNetworkService',
];

function teamShareGroups() {
  return {
    restrict: 'E',
    scope: {
      userId: '<',
      entityType: '<',
    },
    bindToController: true,
    controller: 'TeamShareGroupsController',
    controllerAs: '$ctrl',
    template,
  };
}

export {TeamShareGroupsController, teamShareGroups};
