import capitalize from 'lodash/capitalize';
import isNil from 'lodash/isNil';
import forIn from 'lodash/forIn';
import find from 'lodash/find';
import isNumber from 'lodash/isNumber';
import helperService from './helper-service';
import safeLocalStorage from './safe-local-storage-service';
import mapActions from '../store/store-helpers';
import BaseNetworkService from '../network-services/base-network-service/base-network-service';
import BaseNetworkServiceWithPromises from '../network-services/base-network-service/base-network-service-with-promises';
import accountsNetworkService from '../network-services/accounts-network-service';
import leadsNetworkService from '../network-services/leads-network-service';
import contactsNetworkService from '../network-services/contacts-network-service';
import dealsNetworkService from '../network-services/deals-network-service';
import analyticsService from './analytics-service';
import {normalizeAddEditObject} from './add-edit-ctrl';

export default function CustomerService(
  $route, MainService, MappingService, Upload, mmcConst,
  CustomFieldsService, GroupsSectionService,
) {
  // main utility functions
  const {MAN} = window.mmcUtils;
  const MODEL = window.DataModel;

  // service to return
  const service = {};

  mapActions(service, ['modals']);

  service.integrationsURL = window.__env.integrationsURL;

  //
  // ------------------------ EDIT CRM OBJECTS ------------------------ //
  //

  // save crm objects edits
  service.saveEdits = (crmPage, obj, flag) => {
    if (!flag) {
      // set dirty edit flag false
      MODEL.dirtyEditFlag = false;
    }

    if (!crmPage) {
      if (MODEL.currentPageEditOrAddSubheader.title.indexOf('Company') >= 0) {
        crmPage = 'accountsPage';
      } else if (MODEL.currentPageEditOrAddSubheader.title.indexOf('Person') >= 0) {
        crmPage = 'contactsPage';
      } else if (MODEL.currentPageEditOrAddSubheader.title.indexOf('Lead') >= 0) {
        crmPage = 'leadsPage';
      } else {
        crmPage = 'dealsPage';
      }
    }

    // reset MODEL vars depending on table data is from
    switch (crmPage) {
      case 'accountsPage': // accounts edits function
        return service.saveAccountsEdits(obj);
      case 'contactsPage': // contacts edits function
        return service.saveContactsEdits(obj);
      case 'dealsPage': // deals edits function
        return service.saveDealsEdits(obj);
      case 'leadsPage': // deals edits function
        return service.saveLeadsEdits(obj);
      default:
        return Promise.reject(new Error(`Unsupported entity type: ${crmPage}`));
    }
  };

  // save edits mades on accounts page
  service.saveAccountsEdits = async function (obj) {
    MODEL.currentCrmObjectId = parseInt($route.current.params.recordId, 10);

    // ------- build new pin object to save ------- //
    const account = {};
    account.name = obj.nameEdit;
    account.id = MODEL.currentCrmObjectId;
    account.phone = obj.phoneEdit;
    account.city = obj.cityEdit;
    account.region = obj.stateEdit;
    account.address = obj.addressEdit;
    account.postalCode = obj.zipEdit;
    account.country = obj.countryEdit;
    account.numEmployees = parseInt(obj.numEmployeesEdit, 10);
    account.website = obj.websiteEdit;
    account.email = obj.emailEdit;
    account.color = obj.colorEdit || 'black';
    account.annualRevenue = parseFloat(obj.annualRevenueEdit);

    account.parentAccount = MODEL.currentAssociatedAccountId ? {id: MODEL.currentAssociatedAccountId} : null;
    account.geoManagementState = 'manual';

    if (!isNil(obj.currentCustomerLat) && !isNil(obj.currentCustomerLng)) {
      account.geoPoint = {
        type: 'Point',
        coordinates: [obj.currentCustomerLng, obj.currentCustomerLat],
      };
    }

    // if address contains coordinates, save them as-is in a manual mode
    // otherwise, take a look at MODEL.typeOfLocationUpdatedMostRecently
    const latLonCoordinatesRegex = /^\s*(?<lat>[-+]?\d+(?:\.\d*)?)\s*,\s*(?<long>[-+]?\d+(?:\.\d*)?)$/;
    const latLonMatch = (account.address || '').match(latLonCoordinatesRegex);
    if (latLonMatch !== null && latLonMatch.groups) {
      account.geoManagementState = 'manual';
      account.city = null;
      account.region = null;
      account.country = null;
      account.postalCode = null;
      account.geoPoint = {
        type: 'Point',
        coordinates: [parseFloat(latLonMatch.groups.long), parseFloat(latLonMatch.groups.lat)],
      };
    } else if (MODEL.typeOfLocationUpdatedMostRecently === 'address') {
      account.geoManagementState = 'automaticPreserveAddress';
      account.geoPoint = null;
    } else if (MODEL.typeOfLocationUpdatedMostRecently === 'pin') {
      account.geoManagementState = 'automatic';
      account.address = null;
      account.city = null;
      account.region = null;
      account.country = null;
      account.postalCode = null;
    }

    const response = await accountsNetworkService.updateAccount(account.id, account);
    if (!response.id) {
      const message = Array.isArray(response.validationErrors) && response.validationErrors.length
        ? response.validationErrors[0].message
        : 'Failed to save changes, please try again';
      swal('Uh-Oh!', message, 'error');
    }
    MODEL.typeOfLocationUpdatedMostRecently = undefined;

    return response;
  };

  service.saveLeadsEdits = async function (obj) {
    MODEL.currentCrmObjectId = parseInt($route.current.params.recordId, 10);
    // ------- build new pin object to save ------- //
    const lead = {};
    lead.company = obj.companyEdit;
    lead.firstName = obj.firstNameEdit;
    lead.lastName = obj.lastNameEdit;
    lead.id = MODEL.currentCrmObjectId;
    lead.phone = obj.phoneEdit;
    lead.city = obj.cityEdit;
    lead.region = obj.stateEdit;
    lead.address = obj.addressEdit;
    lead.postalCode = obj.zipEdit;
    lead.country = obj.countryEdit;
    lead.numEmployees = parseInt(obj.numEmployeesEdit, 10);
    lead.website = obj.websiteEdit;
    lead.email = obj.emailEdit;
    lead.leadSource = obj.leadSourceEdit;
    lead.amount = obj.amountEdit;
    lead.industry = obj.industryEdit;
    lead.color = obj.colorEdit || 'black';
    lead.annualRevenue = parseFloat(obj.annualRevenueEdit);
    lead.user = {
      id: safeLocalStorage.currentUser.id,
    };
    lead.geoManagementState = 'manual';

    if (!isNil(obj.currentCustomerLat) && !isNil(obj.currentCustomerLng)) {
      lead.geoPoint = {
        type: 'Point',
        coordinates: [obj.currentCustomerLng, obj.currentCustomerLat],
      };
    }

    if (MODEL.typeOfLocationUpdatedMostRecently === 'address') {
      lead.geoManagementState = 'automaticPreserveAddress';
      lead.geoPoint = null;
    } else if (MODEL.typeOfLocationUpdatedMostRecently === 'pin') {
      lead.geoManagementState = 'automatic';
      lead.address = null;
      lead.city = null;
      lead.region = null;
      lead.country = null;
      lead.postalCode = null;
    }
    const response = await leadsNetworkService.updateLead(lead.id, lead);
    if (!response.id) {
      const message = Array.isArray(response.validationErrors) && response.validationErrors.length
        ? response.validationErrors[0].message
        : 'Failed to save changes, please try again';
      swal('Uh-Oh!', message, 'error');
    }
    MODEL.typeOfLocationUpdatedMostRecently = undefined;

    return response;
  };

  // save edits made on the contacts page
  service.saveContactsEdits = async (obj) => {
    let account = null;
    if (MODEL.currentAssociatedAccountId) {
      account = {id: MODEL.currentAssociatedAccountId};
    }

    MODEL.currentCrmObjectId = parseInt($route.current.params.recordId, 10);

    // ------- build new pin object to save ------- //
    const contact = {};
    contact.id = MODEL.currentCrmObjectId;
    contact.name = obj.nameEdit;
    contact.firstName = obj.firstNameEdit;
    contact.lastName = obj.lastNameEdit;
    contact.phone = obj.phoneEdit;
    contact.city = obj.cityEdit;
    contact.region = obj.stateEdit;
    contact.address = obj.addressEdit;
    contact.postalCode = obj.zipEdit;
    contact.country = obj.countryEdit;
    contact.color = obj.colorEdit || 'black';
    contact.email = obj.emailEdit;
    contact.account = account;
    contact.geoManagementState = 'manual';

    if (!isNil(obj.currentCustomerLat) && !isNil(obj.currentCustomerLng)) {
      contact.geoPoint = {
        type: 'Point',
        coordinates: [obj.currentCustomerLng, obj.currentCustomerLat],
      };
    }

    if (MODEL.typeOfLocationUpdatedMostRecently === 'address') {
      contact.geoManagementState = 'automaticPreserveAddress';
      contact.geoPoint = null;
    } else if (MODEL.typeOfLocationUpdatedMostRecently === 'pin') {
      contact.geoManagementState = 'automatic';
      contact.address = null;
      contact.city = null;
      contact.region = null;
      contact.country = null;
      contact.postalCode = null;
    }


    const response = await contactsNetworkService.updateContact(contact.id, contact);
    if (!response.id) {
      const message = Array.isArray(response.validationErrors) && response.validationErrors.length
        ? response.validationErrors[0].message
        : 'Failed to save changes, please try again';
      swal('Uh-Oh!', message, 'error');
    }
    MODEL.typeOfLocationUpdatedMostRecently = undefined;

    return response;
  };

  // save edits made on the deals page
  service.saveDealsEdits = async (obj) => {
    const name = obj.nameEdit;
    const stageId = obj.stage.id;
    const score = obj.scoreEdit ? parseInt(obj.scoreEdit, 10) : 0;
    const amount = obj.amountEdit ? parseFloat(obj.amountEdit) : 0;
    const funnelId = obj.funnelEdit;
    MODEL.currentCrmObjectId = parseInt($route.current.params.recordId, 10);

    if (!funnelId) {
      return swal('Uh-Oh!', 'Please add a funnel to the deal.', 'error');
    } if (!stageId) {
      return swal('Uh-Oh!', 'Please add a stage to the deal.', 'error');
    }
    // ------- build new pin object to save ------- //
    const deal = {
      id: MODEL.currentCrmObjectId,
      name,
      stage: {id: stageId},
      score,
      amount,
      funnel: {id: funnelId},
      account: MODEL.currentAssociatedAccountId ? {id: MODEL.currentAssociatedAccountId} : null,
      contact: MODEL.currentAssociatedContactId ? {id: MODEL.currentAssociatedContactId} : null,
      dealLossReason: obj.dealLossReason ? {id: obj.dealLossReason.id} : null,
      dealLossComment: obj.dealLossComment || null,
    };

    // if closing date present
    const closingDateData = obj.editClosingDate;
    if (closingDateData) {
      deal.closingDate = moment(closingDateData).format('YYYY-MM-DD');
    }
    const response = await dealsNetworkService.updateDeal(deal.id, deal);
    if (!response.id) {
      const message = Array.isArray(response.validationErrors) && response.validationErrors.length
        ? response.validationErrors[0].message
        : 'Failed to save changes, please try again';
      swal('Uh-Oh!', message, 'error');
    }
    MODEL.typeOfLocationUpdatedMostRecently = undefined;

    return response;
  };

  // TODO make changes to this when implementing geocoding
  // check geocoding required for the pin to be saved
  service.processGeoCoding = (currentPinObj, newPin, entityType, crmObjectCustomFields) => {
    // see if lat/lng is in custom fields (used for importing coordinate data)
    let isUsingCustomLatLngFields;
    let latFieldNumber;
    let lngFieldNumber;
    let isUsingCoords = false;

    forIn(crmObjectCustomFields, (value, key) => {
      // has latitude as custom field, and it has a number as a value
      if (key.toLowerCase() === 'latitude' && !Number.isNaN(parseFloat(newPin.customFields[value]))) {
        isUsingCoords = true;
        isUsingCustomLatLngFields = true;
        latFieldNumber = value;
      }

      if (key.toLowerCase() === 'longitude' && !Number.isNaN(parseFloat(newPin.customFields[value]))) {
        isUsingCoords = true;
        isUsingCustomLatLngFields = true;
        lngFieldNumber = value;
      }
    });

    let addressCoordsLat;
    let addressCoordsLng;

    if (newPin.address && newPin.address.indexOf(',') > 0) {
      const splitAddress = newPin.address.split(',');
      if (!Number.isNaN(splitAddress[0]) && !Number.isNaN(splitAddress[1])) {
        isUsingCoords = true;
        addressCoordsLat = splitAddress[0];
        addressCoordsLng = splitAddress[1];
      }
    }

    // grab lat/lon of address
    const geocodableAddress = MainService.generateGeocodableAddress(newPin.address, newPin.city, newPin.region, newPin.postalCode, newPin.country);
    const geocoder = new google.maps.Geocoder();
    if (!geocoder) {
      return;
    }

    geocoder.geocode({address: geocodableAddress}, (results, status) => {
      // user has ability to edit pins
      if ((MODEL.currentUsername !== safeLocalStorage.currentUser.username) && !service.canEdit(entityType)) {
        swal("We're sorry", 'Your team owner needs to give you permission to edit this record.', 'error');
        return;
      }

      const updatesToPin = {};

      // geocoded OK and isn't using coords
      if (status === google.maps.GeocoderStatus.OK && !isUsingCoords && !MODEL.newCustomerLatitude) {
        const
          latitude = parseFloat(results[0].geometry.location.lat());
        const longitude = parseFloat(results[0].geometry.location.lng());
        const geoPoint = {type: 'Point', coordinates: [longitude, latitude]};

        // store lat/lng/zoom so can refresh to current spot
        MODEL.lastPinLat = latitude;
        MODEL.lastPinLng = longitude;
        MODEL.previousZoom = MODEL.map.getZoom();

        // finish off updating pin data
        updatesToPin.geoPoint = geoPoint;
        updatesToPin.dirty = true;
      } else { // could not geocode --> use old lat/lng data
        // lat/long set by marker
        if (MODEL.newCustomerLatitude !== 0) {
          const latitude = MODEL.newCustomerLatitude;
          const longitude = MODEL.newCustomerLongitude;
          const geoPoint = {type: 'Point', coordinates: [longitude, latitude]};

          // store lat/lng so can refresh to current spot
          MODEL.lastPinLat = MODEL.newCustomerLatitude;
          MODEL.lastPinLng = MODEL.newCustomerLongitude;
          MODEL.newCustomerLatitude = 0;
          MODEL.newCustomerLongitude = 0;

          // set address info to blank if not enough of it exists
          if (!newPin.city && !newPin.address && !newPin.region) {
            updatesToPin.city = '';
            updatesToPin.region = '';
            updatesToPin.address = `${latitude}, ${longitude}`;
          }
          updatesToPin.geoPoint = geoPoint;
        } else if (isUsingCustomLatLngFields || isUsingCoords) { // location set with lat/lng custom fields
          let latitude;
          let longitude;

          // coord data in custom fields
          if (isUsingCustomLatLngFields) {
            latitude = parseFloat(newPin.customFields[latFieldNumber]);
            longitude = parseFloat(newPin.customFields[lngFieldNumber]);
          } else if (isUsingCoords) { // coord data in the address field
            latitude = parseFloat(addressCoordsLat);
            longitude = parseFloat(addressCoordsLng);
          }

          const geoPoint = {type: 'GeoPoint', coordinates: [longitude, latitude]};

          // store lat/lng so can refresh to current spot
          MODEL.lastPinLat = latitude;
          MODEL.lastPinLng = longitude;
          MODEL.newCustomerLatitude = 0;
          MODEL.newCustomerLongitude = 0;

          // set address info to blank if not enough of it exists
          if (!newPin.city || !newPin.address || !newPin.region) {
            updatesToPin.city = '';
            updatesToPin.region = '';
            updatesToPin.address = `${latitude}, ${longitude}`;
          }
          updatesToPin.geoPoint = geoPoint;
        } else if (MODEL.currentCustomerLat !== 0 && MODEL.currentCustomerLat !== 'customer_lat' && MODEL.currentCustomerLat !== '') {
          // marker not set
          const latitude = parseFloat(MODEL.currentCustomerLat);
          const longitude = parseFloat(MODEL.currentCustomerLng);
          const geoPoint = {type: 'GeoPoint', coordinates: [longitude, latitude]};

          // store lat/lng so can refresh to current spot
          MODEL.lastPinLat = latitude;
          MODEL.lastPinLng = longitude;

          updatesToPin.geoPoint = geoPoint;
        } else { // just a contact, no location
          updatesToPin.unmapped = true;
        }

        // store zoom so can refresh to current spot
        MODEL.previousZoom = MODEL.map.getZoom();

        // finish pin data
        updatesToPin.dirty = true;
      }

      newPin = {...newPin, ...updatesToPin};
      service.persistEdits(currentPinObj, entityType, newPin);
    });
  };

  //
  // ------------------------ SAVE CRM OBJECTS ------------------------ //
  //

  // add new account
  service.saveAccount = async function (obj) {
    const account = {};
    account.name = obj.nameAdd || null;
    account.numEmployees = obj.numEmployeesAdd ? parseInt(obj.numEmployeesAdd, 10) : null;
    account.website = obj.websiteAdd || null;
    account.annualRevenue = obj.revenueAdd ? parseFloat(obj.revenueAdd) : null;
    account.phone = obj.phoneAdd || null;
    account.email = obj.emailAdd || null;
    account.city = obj.cityAdd || null;
    account.region = obj.stateAdd || null;
    account.postalCode = obj.zipAdd || null;
    account.country = obj.countryAdd || null;
    account.address = obj.addressAdd || null;
    account.color = obj.colorAdd || 'black';
    account.geoPoint = null;

    account.parentAccount = MODEL.currentAssociatedAccountId ? {id: MODEL.currentAssociatedAccountId} : null;

    // required information missing (skip if newCustomerLatitude is set)
    if (!account.name) {
      swal('Uh-Oh!', 'Each company needs a name', 'error');
      return;
    }

    account.geoManagementState = 'automaticPreserveAddress';
    if (!isNil(obj.currentCustomerLat) && !isNil(obj.currentCustomerLng)) {
      account.geoPoint = {
        type: 'Point',
        coordinates: [obj.currentCustomerLng, obj.currentCustomerLat],
      };
      account.geoManagementState = 'manual';
    }


    if (MODEL.typeOfLocationUpdatedMostRecently === 'address') {
      account.geoPoint = null;
    } else if (MODEL.typeOfLocationUpdatedMostRecently === 'pin') {
      account.address = null;
      account.city = null;
      account.region = null;
      account.country = null;
      account.postalCode = null;
    }

    MODEL.show.loader = true;

    const response = await accountsNetworkService.createAccount(account);
    if (!response.id) {
      const message = Array.isArray(response.validationErrors) && response.validationErrors.length
        ? response.validationErrors[0].message
        : 'Failed to create account, please try again';
      swal('Uh-Oh!', message, 'error');
      window.refreshDom({loader: false}, 'show');
      return;
    }

    MODEL.typeOfLocationUpdatedMostRecently = undefined;

    // save groups
    try {
      await GroupsSectionService.attachGroups(response.id);
    } catch (e) {
      swal('Uh-Oh!', 'Failed to save groups', 'error');
    }

    // save custom fields
    try {
      await CustomFieldsService.actuallyCreateValues('accounts', response.id);
    } catch (cfResponse) {
      const message = Array.isArray(cfResponse.validationErrors) && cfResponse.validationErrors.length
        ? cfResponse.validationErrors[0].message
        : 'Failed to save custom fields';
      swal('Uh-Oh!', message, 'error');
    }

    if (MODEL.MappingService.findNewCustomers) {
      MODEL.accounts.push(response);
      MAN.nukeMapContent();
      MainService.saveNewFoundCustomer();
      MainService.closeAccount();
    } else {
      $('#map').show();
      window.location.href = `#/accounts/edit/${response.id}`;
    }

    window.refreshDom({loader: false}, 'show');

    // reset marker vars
    MODEL.newCustomerLatitude = 0;
    MODEL.newCustomerLongitude = 0;
  };

  service.saveLead = async function (obj) {
    const lead = {};
    lead.company = obj.companyAdd || null;
    lead.firstName = obj.firstNameAdd || null;
    lead.lastName = obj.lastNameAdd || null;
    lead.numEmployees = obj.numEmployeesAdd ? parseInt(obj.numEmployeesAdd, 10) : null;
    lead.website = obj.websiteAdd || null;
    lead.annualRevenue = obj.annualRevenueAdd ? parseFloat(obj.annualRevenueAdd) : null;
    lead.phone = obj.phoneAdd || null;
    lead.email = obj.emailAdd || null;
    lead.city = obj.cityAdd || null;
    lead.region = obj.stateAdd || null;
    lead.postalCode = obj.zipAdd || null;
    lead.country = obj.countryAdd || null;
    lead.address = obj.addressAdd || null;
    lead.color = obj.colorAdd || 'black';
    lead.geoPoint = null;

    lead.user = {id: safeLocalStorage.currentUser.id};
    // lead.parentAccount = MODEL.currentAssociatedAccountId ? {id: MODEL.currentAssociatedAccountId} : null;

    lead.geoManagementState = 'automaticPreserveAddress';
    if (!isNil(obj.currentCustomerLat) && !isNil(obj.currentCustomerLng)) {
      lead.geoPoint = {
        type: 'Point',
        coordinates: [obj.currentCustomerLng, obj.currentCustomerLat],
      };
      lead.geoManagementState = 'manual';
    }


    if (MODEL.typeOfLocationUpdatedMostRecently === 'address') {
      lead.geoPoint = null;
    } else if (MODEL.typeOfLocationUpdatedMostRecently === 'pin') {
      lead.address = null;
      lead.city = null;
      lead.region = null;
      lead.country = null;
      lead.postalCode = null;
    }

    MODEL.show.loader = true;

    const response = await leadsNetworkService.createLead(lead);
    if (!response.id) {
      const message = Array.isArray(response.validationErrors) && response.validationErrors.length
        ? response.validationErrors[0].message
        : 'Failed to create lead, please try again';
      swal('Uh-Oh!', message, 'error');
      window.refreshDom({loader: false}, 'show');
      return;
    }

    MODEL.typeOfLocationUpdatedMostRecently = undefined;

    // save groups
    try {
      await GroupsSectionService.attachGroups(response.id);
    } catch (e) {
      swal('Uh-Oh!', 'Failed to save groups', 'error');
    }

    // save custom fields
    try {
      await CustomFieldsService.actuallyCreateValues('accounts', response.id);
    } catch (cfResponse) {
      const message = Array.isArray(cfResponse.validationErrors) && cfResponse.validationErrors.length
        ? cfResponse.validationErrors[0].message
        : 'Failed to save custom fields';
      swal('Uh-Oh!', message, 'error');
    }

    // if (MODEL.MappingService.findNewCustomers) {
    //   MODEL.accounts.push(response);
    //   MAN.nukeMapContent();
    //   MainService.saveNewFoundCustomer();
    //   MainService.closeLeads();
    // } else {
    $('#map').show();
    window.location.href = `#/leads/edit/${response.id}`;
    // }
    window.refreshDom({loader: false}, 'show');

    // reset marker vars
    MODEL.newCustomerLatitude = 0;
    MODEL.newCustomerLongitude = 0;
  };

  // add new contact
  service.saveContact = async function (obj) {
    const contact = {};
    contact.name = obj.nameAdd || null;
    contact.firstName = obj.firstNameAdd || null;
    contact.lastName = obj.lastNameAdd || null;
    contact.phone = obj.phoneAdd || null;
    contact.email = obj.emailAdd || null;
    contact.city = obj.cityAdd || null;
    contact.region = obj.stateAdd || null;
    contact.postalCode = obj.zipAdd || null;
    contact.country = obj.countryAdd || null;
    contact.address = obj.addressAdd || null;
    contact.color = obj.colorAdd || 'black';

    let account = null;
    if (MODEL.currentAssociatedAccountId) {
      account = {id: MODEL.currentAssociatedAccountId};
    }

    contact.account = account;

    // required information missing (skip if newCustomerLatitude is set)
    if (contact.name === '') {
      swal('Uh-Oh!', 'Each person needs a name', 'error');
      return;
    }

    contact.geoManagementState = 'automaticPreserveAddress';
    if (!isNil(obj.currentCustomerLat) && !isNil(obj.currentCustomerLng)) {
      contact.geoPoint = {
        type: 'Point',
        coordinates: [obj.currentCustomerLng, obj.currentCustomerLat],
      };
    }

    if (MODEL.typeOfLocationUpdatedMostRecently === 'address') {
      contact.geoPoint = null;
    } else if (MODEL.typeOfLocationUpdatedMostRecently === 'pin') {
      contact.geoManagementState = 'automatic';
      contact.address = null;
      contact.city = null;
      contact.region = null;
      contact.country = null;
      contact.postalCode = null;
    }

    MODEL.show.loader = true;

    const response = await contactsNetworkService.createContact(contact);
    if (!response.id) {
      const message = Array.isArray(response.validationErrors) && response.validationErrors.length
        ? response.validationErrors[0].message
        : 'Failed to create contact, please try again';
      swal('Uh-Oh!', message, 'error');
      window.refreshDom({loader: false}, 'show');
      return;
    }
    MODEL.typeOfLocationUpdatedMostRecently = undefined;

    // save groups
    try {
      await GroupsSectionService.attachGroups(response.id);
    } catch (e) {
      swal('Uh-Oh!', 'Failed to save groups', 'error');
    }

    // save custom fields
    try {
      await CustomFieldsService.actuallyCreateValues('contacts', response.id);
    } catch (cfResponse) {
      const message = Array.isArray(cfResponse.validationErrors) && cfResponse.validationErrors.length
        ? cfResponse.validationErrors[0].message
        : 'Failed to save custom fields';
      swal('Uh-Oh!', message, 'error');
    }

    if (MODEL.MappingService.findNewCustomers) {
      MODEL.contacts.push(response);
      MAN.nukeMapContent();
      MainService.saveNewFoundCustomer();
      MainService.closeAccount();
    } else {
      $('#map').show();
      window.location.href = `#/contacts/edit/${response.id}`;
    }
    // reset marker vars
    MODEL.newCustomerLatitude = 0;
    MODEL.newCustomerLongitude = 0;

    window.refreshDom({loader: false}, 'show');
  };

  // add new deal
  service.saveDeal = async function (obj) {
    // else... find values and set object properties
    const name = obj.nameAdd || null;
    const amount = obj.amountAdd || null;
    const stage = obj.stage ? {id: obj.stage.id} : null;
    const funnel = obj.funnelAdd ? {id: obj.funnelAdd} : null;
    const score = obj.scoreAdd || null;

    // required information missing (skip if newCustomerLatitude is set)
    if (!name) {
      return swal('Uh-Oh!', 'Each deal needs a name', 'error');
    }
    // TODO - Implement if the user has records left to create
    // else if(safeLocalStorage.currentUser.pins - MODEL.customers.length <= 0) {
    //   return swal("Out of pins!", "You have no pins left. Upgrade to add more customers.", "error");
    // }
    if (!funnel) {
      return swal('Uh-Oh!', 'Please add a funnel to the deal.', 'error');
    }
    if (!stage) {
      return swal('Uh-Oh!', 'Please add a stage to the deal.', 'error');
    }

    MODEL.show.loader = true;
    const deal = {
      name,
      amount,
      stage,
      funnel,
      score,
      user: {id: safeLocalStorage.currentUser.id},
      dealLossReason: obj.dealLossReason ? {id: obj.dealLossReason.id} : null,
      dealLossComment: obj.dealLossComment || null,
    };

    if (obj.closingDateAdd) {
      deal.closingDate = moment(obj.closingDateAdd).format('YYYY-MM-DD');
    }

    if (MODEL.currentAssociatedAccountId) {
      deal.account = {id: MODEL.currentAssociatedAccountId};
    }
    if (MODEL.currentAssociatedContactId) {
      deal.contact = {id: MODEL.currentAssociatedContactId};
    }
    // reset marker vars
    MODEL.newCustomerLatitude = 0;
    MODEL.newCustomerLongitude = 0;
    const response = await dealsNetworkService.createDeal(deal);
    if (!response.id) {
      const message = Array.isArray(response.validationErrors) && response.validationErrors.length
        ? response.validationErrors[0].message
        : 'Failed to create deal, please try again';
      swal('Uh-Oh!', message, 'error');
      window.refreshDom({loader: false}, 'show');
      return Promise.reject();
    }
    MODEL.typeOfLocationUpdatedMostRecently = undefined;

    // save groups
    try {
      await GroupsSectionService.attachGroups(response.id);
    } catch (e) {
      swal('Uh-Oh!', 'Failed to save groups', 'error');
    }

    // save custom fields
    try {
      await CustomFieldsService.actuallyCreateValues('deals', response.id);
    } catch (cfResponse) {
      const message = Array.isArray(cfResponse.validationErrors) && cfResponse.validationErrors.length
        ? cfResponse.validationErrors[0].message
        : 'Failed to save custom fields';
      swal('Uh-Oh!', message, 'error');
    }

    $('#map').show();
    window.location.href = `#/deals/edit/${response.id}`;
    return Promise.resolve();
  };

  // add new crm object
  service.saveCustomer = function (objectType, obj) {
    let entityType = '';
    if (!objectType) {
      if (MODEL.currentPageEditOrAddSubheader.addButtonName.indexOf('Company') >= 0) {
        objectType = 'accounts';
      } else if (MODEL.currentPageEditOrAddSubheader.addButtonName.indexOf('Person') >= 0) {
        objectType = 'contacts';
      } else if (MODEL.currentPageEditOrAddSubheader.addButtonName.indexOf('Lead') >= 0) {
        objectType = 'leads';
      } else {
        objectType = 'deals';
      }
    }

    // set dirty flag to false
    MODEL.dirtyEditFlag = false;

    // // show user view
    // $("#mapViewByUser").show();

    // reset MODEL vars depending on table data is from
    switch (objectType) {
      case 'accounts':
        entityType = 'Company';
        service.saveAccount(obj);
        break;
      case 'leads':
        entityType = 'Lead';
        service.saveLead(obj);
        break;
      case 'contacts': // contacts save function
        entityType = 'Person';
        service.saveContact(obj);
        break;
      case 'deals': // deals save function
        entityType = 'Deal';
        service.saveDeal(obj);
        break;
    }
    analyticsService.entityAdded(entityType, normalizeAddEditObject(obj));

    // Just in case they didn't hit submit, let's check the notes field:
    if ($('#note_for_new_customer').val() || MODEL.isEditingNote) {
      swal('Did you forget to submit?', "You have unsubmitted text in the notes field. Either 'submit' the note or clear the box.", 'error');
    }
  };


  // process geocoding for saving to parse
  service.procesGeoCodingForSave = (address, isUsingCoords, addressCoordsLat, addressCoordsLng) => new Promise(((resolve) => {
    const geocoder = new google.maps.Geocoder();
    let latitude; let
      longitude;

    if (geocoder) {
      geocoder.geocode({address}, (results, status) => {
        let geoPoint;

        // lat/long found from address && not using coordinate in the address field
        if (status === google.maps.GeocoderStatus.OK && !isUsingCoords && !MODEL.newCustomerLatitude) {
          const tempLat = parseFloat(results[0].geometry.location.lat());
          const tempLng = parseFloat(results[0].geometry.location.lng());
          latitude = parseFloat(tempLat.toFixed(5));
          longitude = parseFloat(tempLng.toFixed(5));
          geoPoint = {__type: 'GeoPoint', latitude, longitude};

          // store lat/lng/zoom so can refresh to current spot
          MODEL.lastPinLat = latitude;
          MODEL.lastPinLng = longitude;
          MODEL.previousZoom = 8;
        } else if (MODEL.newCustomerLatitude !== 0) {
          // lat/long set by marker

          latitude = parseFloat(MODEL.newCustomerLatitude.toFixed(5));
          longitude = parseFloat(MODEL.newCustomerLongitude.toFixed(5));
          geoPoint = {__type: 'GeoPoint', latitude: MODEL.newCustomerLatitude, longitude: MODEL.newCustomerLongitude};

          // set address info to blank if not enough of it exists
          if (!address) {
            address = `${latitude}, ${longitude}`;
          }

          // store lat/lng/zoom so can refresh to current spot
          MODEL.lastPinLat = MODEL.newCustomerLatitude;
          MODEL.lastPinLng = MODEL.newCustomerLongitude;
          MODEL.previousZoom = 8;
        } else if (isUsingCoords) {
          // lat/long set by coordinate in the address field

          const customLat = parseFloat(addressCoordsLat);
          const customLng = parseFloat(addressCoordsLng);
          latitude = parseFloat(customLat.toFixed(5));
          longitude = parseFloat(customLng.toFixed(5));
          geoPoint = {__type: 'GeoPoint', latitude, longitude};

          // store lat/lng so can refresh to current spot
          MODEL.lastPinLat = latitude;
          MODEL.lastPinLng = longitude;
        }

        const result = {
          status: 'OK', latitude, longitude, geoPoint,
        };
        resolve(result);
      });
    }
  }));


  //
  // ------------------------ HELPER FUNCTIONS ------------------------ //
  //

  // check if the user has the permission
  service.canEdit = (tableName) => {
    if (tableName === 'accounts') {
      return MODEL.accessRightsAccounts.canEdit;
    }
    if (tableName === 'contacts') {
      return MODEL.accessRightsContacts.canEdit;
    }

    return MODEL.accessRightsDeals.canEdit;
  };


  // opens image when clicked
  service.openImage = function (image) {
    MODEL.currentPhoto = image;

    $('#modalbg').show();
    $('#modal').show();
    $('#queryBox').hide();
    $('#modal').removeClass('modal-map').removeClass('modal-import').removeClass('modal-query');
    $('#photoBox').show();

    // mobile device --> scroll to top
    if ($(window).width() < 992) {
      $('.main-panel').animate({
        scrollTop: 0,
      }, 400);
    }

    // image source
    let imagePath = '';
    if (image.pdfName) {
      imagePath = image.pdfName;
    } else {
      imagePath = image.fileName;
    }

    // set preview image
    const previewImage = service.integrationsURL + image.fileName;
    $('#photoBoxImage').attr('src', previewImage);

    // image/PDF download link
    const source = service.integrationsURL + imagePath;
    $('#photoBoxDownloadLink').attr('href', source);
    $('#photoBoxDownloadLink').attr('download', imagePath);
  };

  // show edit location map modal
  service.showMapModal = () => {
    $('#accounts-edit-map-modal').show();
    $('#contacts-edit-map-modal').show();

    const {
      address, city, state, country, zip,
    } = MODEL.currentCustomerGeoCodingData;
    $('#editMapInput').val(`${address} ${city} ${state} ${country} ${zip}`);
    if (!MODEL.currentCustomerLat || !MODEL.currentCustomerLng) {
      MODEL.currentCustomerLat = MODEL.currentCRMObject.latitude;
      MODEL.currentCustomerLng = MODEL.currentCRMObject.longitude;
    }

    if (!MODEL.currentCustomerLat || !MODEL.currentCustomerLat) {
      $('#accounts-edit-map-modal').hide();
      $('#contacts-edit-map-modal').hide();
      swal('Uh-oh', 'This record is not mapped to any location.', 'warning');
      return;
    }
    // init map
    const latlng = MAN.userPosition || MAN.lastResortPosition;
    const mapOptions = {
      zoom: 4,
      center: latlng,
      scrollwheel: false,
    };

    // map
    MODEL.editCrmObjectMap = new google.maps.Map(document.getElementById('editCrmObjectMap'), mapOptions);
    window.mmcUtils.tk('E5E955XguG');
    google.maps.event.addListenerOnce(MODEL.editCrmObjectMap, 'idle', () => {
      google.maps.event.trigger(MODEL.editCrmObjectMap, 'resize');
    });

    const pinLatlng = new google.maps.LatLng(MODEL.currentCustomerLat, MODEL.currentCustomerLng);
    let color = 'black';
    if (MODEL.currentCRMObject.color) {
      color = MODEL.currentCRMObject.color;
    }

    const colorURL = `./images/pins-large/marker_${color}.png`;
    // console.log("color url ", colorURL);
    const markerOptions = {
      icon: {
        url: colorURL,
      },
      map: MODEL.editCrmObjectMap,
      position: pinLatlng,
      draggable: true,
    };
    MODEL.editCrmObjectMarker = new google.maps.Marker(markerOptions);
    MODEL.editCrmObjectMarker.setMap(MODEL.editCrmObjectMap);

    if (pinLatlng) {
      MODEL.editCrmObjectMap.setCenter(pinLatlng);
    } else {
      MODEL.editCrmObjectMap.setCenter(MAN.lastResortPosition);
      // ^ if user and/or team members are very close to customer,
      // we might end up too close.
    }

    service.addEventListenerToPinDrag();
  };

  // show edit location map modal
  service.showDealMapModal = () => {
    $('#deals-edit-map-modal').show();
    // init map
    const latlng = MAN.userPosition || MAN.lastResortPosition;
    const mapOptions = {
      zoom: 4,
      center: latlng,
      disableDefaultUI: false,
      scrollwheel: false,
    };

    const account = find(MODEL.accountsRaw, {objectId: MODEL.currentCRMObject.accountId});
    let latitude; let
      longitude;
    if (account) {
      latitude = account.latitude;
      longitude = account.longitude;
    }
    // if account is unmapped or doesn't exist
    if (!latitude || !longitude) {
      $('#deals-edit-map-modal').hide();
      swal('Uh-oh', 'This deal is not mapped to any location.', 'warning');
      return;
    }

    const userLatlng = MAN.userPosition || MAN.lastResortPosition;
    const pinLatlng = new google.maps.LatLng(latitude, longitude);

    // map
    MODEL.editCrmObjectMap = new google.maps.Map(document.getElementById('editCrmObjectMap'), mapOptions);
    window.mmcUtils.tk('E5E955XguG');
    google.maps.event.addListenerOnce(MODEL.editCrmObjectMap, 'idle', () => {
      google.maps.event.trigger(MODEL.editCrmObjectMap, 'resize');
    });

    const markerOptions = {
      map: MODEL.editCrmObjectMap,
      position: userLatlng,
      draggable: false,
    };
    MODEL.editCrmObjectMarker = new google.maps.Marker(markerOptions);
    MODEL.editCrmObjectMarker.setMap(MODEL.editCrmObjectMap);

    if (pinLatlng) {
      MODEL.editCrmObjectMap.setCenter(pinLatlng);
      MODEL.editCrmObjectMap.setZoom(5);
    } else {
      MODEL.editCrmObjectMap.setCenter(userLatlng);
      MODEL.editCrmObjectMap.setZoom(5);
      // ^ if user and/or team members are very close to customer,
      // we might end up too close.
    }
  };

  // add the event listener to pin drag
  service.addEventListenerToPinDrag = () => {
    google.maps.event.addListener(MODEL.editCrmObjectMarker, 'dragend', (a) => {
      MODEL.currentCustomerLat = a.latLng.lat();
      MODEL.currentCustomerLng = a.latLng.lng();

      const latlng = {lat: parseFloat(a.latLng.lat()), lng: parseFloat(a.latLng.lng())};
      window.mmcUtils.reverseGeoCode(latlng)
        .then((geocodedArray) => {
          MODEL.currentCustomerGeoCodingData = geocodedArray;
          $('#editMapInput').val(`${geocodedArray.address} ${geocodedArray.city} ${geocodedArray.state} ${geocodedArray.country} ${geocodedArray.zip}`);
        })
        .catch((error) => {
          helperService.logError(error);
        });
    });
  };

  // edit location done -> close the modal
  service.finishEditLocation = () => {
    $('#accounts-edit-map-modal').hide();
    $('#contacts-edit-map-modal').hide();

    let crmPage = '';

    if (MODEL.currentPageEditOrAddSubheader.title.indexOf('Company') >= 0) {
      crmPage = 'Account';
    } else if (MODEL.currentPageEditOrAddSubheader.title.indexOf('Person') >= 0) {
      crmPage = 'Contact';
    }

    $(`#cityEdit${crmPage}`).val(MODEL.currentCustomerGeoCodingData.city);
    $(`#stateEdit${crmPage}`).val(MODEL.currentCustomerGeoCodingData.state);
    $(`#addressEdit${crmPage}`).val(MODEL.currentCustomerGeoCodingData.address);
    $(`#zipEdit${crmPage}`).val(MODEL.currentCustomerGeoCodingData.zip);
    $(`#countryEdit${crmPage}`).val(MODEL.currentCustomerGeoCodingData.country);

    const currentLoc = MAN.userPosition || MAN.lastResortPosition;
    MODEL.currentCRMObject.city = MODEL.currentCustomerGeoCodingData.city;
    MODEL.currentCRMObject.state = MODEL.currentCustomerGeoCodingData.state;
    MODEL.currentCRMObject.address = MODEL.currentCustomerGeoCodingData.address;
    MODEL.currentCRMObject.zip = MODEL.currentCustomerGeoCodingData.zip;
    MODEL.currentCRMObject.country = MODEL.currentCustomerGeoCodingData.country;
    MODEL.currentCRMObject.latitude = currentLoc.lat;
    MODEL.currentCustomerLat = currentLoc.lat;
    MODEL.currentCustomerLng = currentLoc.lng;
    MODEL.currentCRMObject.longitude = currentLoc.lng;
  };

  service.showQuickCreateView = () => {
    if (MODEL.editPopup.addButtonName.includes('Company')) {
      service.modalsActions.showModal('quickCreateAccount');
    } else if (MODEL.editPopup.addButtonName.includes('Person')) {
      service.modalsActions.showModal('quickCreateContact');
    } else if (MODEL.editPopup.addButtonName.includes('Deal')) {
      service.modalsActions.showModal('quickCreateDeal');
    }
  };

  // check if the object exists in the database
  service.checkCRMObjectNotExists = (entityType, query) => {
    const endPoint = `organizations/${safeLocalStorage.currentUser.organization.id}/${entityType}`;
    const entityName = MainService.getEntityDisplayName(entityType);
    return BaseNetworkService.read(endPoint, {$filters: query, $limit: 1})
      .catch(() => {
        MODEL.show.loader = false;
        swal(
          'Oops...',
          `There was a problem adding this ${entityName}. Make sure to remove any special characters from all fields.`,
          'error',
        );
        return Promise.reject();
      })
      .then(({data}) => {
        MODEL.show.loader = false;
        if (data.length) {
          swal(`${capitalize(entityName)} exists`, `Such ${entityName} already exists. Using that one.`, 'info');
          return Promise.reject(data[0]);
        }
        return Promise.resolve();
      });
  };

  // save quick contact or account
  service.saveQuickRecord = async (entityType, newEntity) => {
    const {
      name, address, city, region,
    } = newEntity;

    if (!name || !name.trim()) {
      swal('Uh-oh!', `Each ${MainService.getEntityDisplayName(entityType)} needs a name`, 'error')
        .then(() => {
          service.showQuickCreateView();
        });
      return Promise.reject();
    }

    MODEL.show.loader = true;
    try {
      await service.checkCRMObjectNotExists(entityType, {name, deleted: {$ne: true}});
    } catch (entity) {
      if (entity) {
        service.attachEntityToCurrentCrmObject({...entity, objectState: 'new'}, entityType);
      }
      window.refreshDom({loader: false}, 'show');
      return entity;
    }

    // get geo encodings for address
    const coordPair = service.isCoordinatePair(address);
    const geocodeAddress = `${address}, ${city}, ${region}`;
    const result = await service.procesGeoCodingForSave(
      geocodeAddress,
      coordPair.isUsingCoords,
      coordPair.addressCoordsLat,
      coordPair.addressCoordsLng,
    );
    newEntity = service.addGeoDataIfAny(newEntity, coordPair, result);

    return service.saveQuickEntity(entityType, newEntity)
      .then(entity => {
        service.addEntityToModel(entity, entityType);
        MODEL.popupDataEnterprise.unshift(entity);
        service.attachEntityToCurrentCrmObject({...entity, objectState: 'new'}, entityType);
        return entity;
      });
  };

  // add record to current crm object
  service.attachEntityToCurrentCrmObject = (entity, entityType) => {
    if (entityType === 'contacts') {
      MODEL.currentCrmObjectContactsData.push(entity);
      $('#contactNameAddDeal').text(entity.name);
      $('#contactNameEditDeal').text(entity.name);
      MODEL.currentAssociatedContactId = entity.id;
    } else if (entityType === 'accounts') {
      if (MODEL.editPopup.editPopupType === 'childAccounts') {
        MODEL.currentCrmObjectChildAccountsData.push(entity);
      } else {
        const {name} = entity;
        $('#parentAccountAddAccount').text(name);
        $('#accountAddContact').text(name);
        $('#accountEditContact').text(name);
        $('#accountNameAddDeal').text(name);
        $('#accountNameEditDeal').text(name);
        $('#parentAccountEditAccount').text(name);
        MODEL.currentAssociatedAccountId = entity.id;
      }
    } else if (entityType === 'deals') {
      MODEL.currentCrmObjectDealsData.push(entity);
    }
  };

  // add new record to repsective CRM table at the beginning
  service.addEntityToModel = (entity, entityType) => {
    if (entityType === 'contacts') {
      MODEL.customersRaw.unshift(entity);
    } else if (entityType === 'accounts') {
      MODEL.accountsRaw.unshift(entity);
    } else if (entityType === 'deals') {
      MODEL.dealsRaw.unshift(entity);
    }
  };

  // get new customer data format ( for accounts and contacts)
  // as we are saving same fields in both cases for quick create
  service.addGeoDataIfAny = (newEntity, coordPair, result) => {
    if ((result.status === 'OK' || MODEL.newCustomerLatitude !== 0 || coordPair.isUsingCoords) && (isNumber(result.latitude))) {
      const {latitude, longitude} = result;
      newEntity = {
        ...newEntity,
        geoPoint: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
      };
    }
    // reset marker vars
    MODEL.newCustomerLatitude = 0;
    MODEL.newCustomerLongitude = 0;

    return newEntity;
  };

  // see if user is adding pin via coordinate pair in the address field
  service.isCoordinatePair = (streetAddress) => {
    let isUsingCoords = false;
    let addressCoordsLat = 0;
    let addressCoordsLng = 0;
    if (streetAddress.indexOf(',') > 0) {
      const splitAddress = streetAddress.split(',');
      if (isNumber(splitAddress[0]) && isNumber(splitAddress[1])) {
        isUsingCoords = true;
        addressCoordsLat = splitAddress[0];
        addressCoordsLng = splitAddress[1];
      }
    }

    return {
      isUsingCoords,
      addressCoordsLat,
      addressCoordsLng,
    };
  };

  // save records
  service.saveQuickEntity = async (entityType, payload) => {
    const endPoint = `organizations/${safeLocalStorage.currentUser.organization.id}/${entityType}`;
    const entityTypeName = MainService.getEntityDisplayName(entityType);

    try {
      const response = await BaseNetworkServiceWithPromises.create(endPoint, payload);
      window.refreshDom({loader: false}, 'show');
      swal('Hooray!', `Your ${entityTypeName} has been added`, 'success');
      return response;
    } catch (e) {
      // Handle any error that occurred in any of the previous promises in the chain.
      window.refreshDom({loader: false}, 'show');
      swal('Oops...', `There was a problem adding this ${entityTypeName}. Make sure to remove any special characters from all fields.`, 'error');
      throw e;
    }
  };

  // save deal from quick create popup using mandatory fields
  service.saveQuickDeal = (newDeal) => {
    const {name, stage, funnel} = newDeal;
    // throw error swal
    if (!name) {
      return swal('Uh-oh!', 'Each deal needs a name', 'error')
        .then(() => {
          service.showQuickCreateView();
        });
    }
    if (!funnel || !funnel.id) {
      return swal('Uh-oh', 'Each deal needs a funnel', 'error')
        .then(() => {
          service.showQuickCreateView();
        });
    }
    if (!stage || !stage.id) {
      return swal('Uh-oh!', 'Each deal needs a stage', 'error')
        .then(() => {
          service.showQuickCreateView();
        });
    }

    MODEL.show.loader = true;
    const whereQuery = {
      username: safeLocalStorage.currentUser.username,
      name,
      deleted: {$ne: true},
    };
    return service.checkCRMObjectNotExists('deals', whereQuery)
      .then(() => service.saveQuickEntity('deals', newDeal)
        .then(record => {
          service.addEntityToModel(record, 'deals');
          MODEL.popupDataEnterprise.unshift(record);
          service.attachEntityToCurrentCrmObject({...record, objectState: 'new'}, 'deals');
          return record;
        }));
  };

  return service;
}

CustomerService.$inject = [
  '$route', 'MainService', 'MappingService', 'Upload', 'mmcConst',
  'CustomFieldsService', 'GroupsSectionService',
];
