import React, {useCallback, useEffect, useState} from 'react';
import {notification, Modal, Select, Button} from 'antd';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import EntityType from '../../type/EntityType';
import Route from '../../type/Route';
import routingNetworkService from '../../../network-services/routing-network-service';
import helperService from '../../../shared-services/helper-service';
import Person from '../../type/Person';
import Company from '../../type/Company';
import useBoolean from '../../hook/useBoolean';
import CreateRouteModal from './CreateRouteModal';
import SelectField from '../SelectField/SelectField';

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

class RouteIdSelect extends SelectField<Route['id']> {}
const RouteSelect = styled(RouteIdSelect)`
  width: 100%;
`;

const fetchRoutesMethod: {[key in EntityType.COMPANY | EntityType.PERSON]: any} = Object.freeze({
  [EntityType.COMPANY]: routingNetworkService.getAccountRoutes.bind(routingNetworkService),
  [EntityType.PERSON]: routingNetworkService.getContactRoutes.bind(routingNetworkService),
});
const updateRouteListFieldName: {[key in EntityType.COMPANY | EntityType.PERSON]: any} = Object.freeze({
  [EntityType.COMPANY]: 'routeAccountGroups',
  [EntityType.PERSON]: 'routeContactGroups',
});
const updateRouteItemFieldName: {[key in EntityType.COMPANY | EntityType.PERSON]: any} = Object.freeze({
  [EntityType.COMPANY]: 'accountId',
  [EntityType.PERSON]: 'contactId',
});

interface Props {
  entities: Set<Company | Person>,
  entityType: EntityType.COMPANY | EntityType.PERSON,
  onHide: () => void,
}

const AddToRoute: React.FC<Props> = ({entities, entityType, onHide}) => {
  const {t} = useTranslation('add-to-route');

  const [routes, setRoutes] = useState<Route[]>([]);
  const [routeId, setRouteId] = useState<Route['id'] | undefined>(undefined);
  const [saving, setSaving] = useState<boolean>(false);
  const [createModalVisible, showCreateModal, hideCreateModal] = useBoolean();

  const handleCreateRoute = useCallback(
    (route: Route) => {
      setRoutes(routes => [route, ...routes]);
      setRouteId(route.id);
      hideCreateModal();
    },
    [hideCreateModal, setRoutes],
  );

  useEffect(
    () => {
      (async () => {
        setRoutes((await fetchRoutesMethod[entityType]()).data);
      })();
    },
    [setRoutes, entityType],
  );

  // if this dialog is somehow opened with more than 69 entities, show error message and close
  useEffect(
    () => {
      if (entities.size > 69) {
        notification.error({message: t('You can only add 69 stops to a route')});
        onHide();
      }
    },
    [entities],
  );

  const handleAddToRoute = useCallback(
    async () => {
      if (!routeId) {
        return;
      }
      setSaving(true);
      const entityIds = Array.from(entities).map(({id}) => id);
      try {
        await routingNetworkService.tryRoute(entityIds, routeId);
      } catch (e) {
        helperService.showAndLogError(e, t('Failed to build the route'));
        setSaving(false);
        return;
      }

      const route = routes.find(({id}) => routeId === id);

      const updateResponse = await routingNetworkService.updateRouteObjects(routeId, {
        [updateRouteListFieldName[entityType]]: entityIds.map(entityId => ({
          [updateRouteItemFieldName[entityType]]: entityId,
          _action_: 'create',
          allottedTime: route!.routeDetail.allottedTime
        })),
      });
      setSaving(false);
      if (updateResponse.message) {
        helperService.showAndLogError(updateResponse, t('Failed to add to route'));
        return;
      }
      notification.info({message: t('Route is updated successfully')});
      onHide();
    },
    [entities, entityType, onHide, routeId],
  );

  const selectedRoute = routes.find(({id}) => id === routeId);
  const isTooManyStops = selectedRoute ? (selectedRoute.items + entities.size) > 69 : false;

  return (
    <Modal
      onCancel={onHide}
      footer={(
        <Footer>
          <div>
            <Button
              onClick={showCreateModal}
              type="ghost"
            >
              {t('Create New Route')}
            </Button>
          </div>
          <div>
            <Button onClick={onHide}>
              {t('Cancel')}
            </Button>
            <Button
              disabled={!routeId || saving || isTooManyStops}
              loading={saving}
              onClick={handleAddToRoute}
              type="primary"
            >
              {t('Add')}
            </Button>
          </div>
        </Footer>
      )}
      visible
    >
      <div>
        <h4>{t('Add to Route')}</h4>
        <RouteSelect
          dropdownMatchSelectWidth={false}
          error={isTooManyStops ? t('You can only add 69 stops to a route') : undefined}
          filterOption={(input, option) => option?.children.toLowerCase().includes(input.toLowerCase())}
          onChange={setRouteId}
          showSearch
          value={routeId}
        >
          {routes.map(route => (
            <Select.Option key={route.id} value={route.id}>{route.name}</Select.Option>
          ))}
        </RouteSelect>
      </div>
      {createModalVisible && (
        <CreateRouteModal
          onCreate={handleCreateRoute}
          onHide={hideCreateModal}
          routes={routes}
        />
      )}
    </Modal>
  );
};

export default AddToRoute;
