import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import BaseNetworkService from '../../network-services/base-network-service/base-network-service';
import customFieldsNetworkService from '../../network-services/custom-fields-network-service';
import fileNetworkService from '../../network-services/file-network-service';
import teamNetworkService from '../../network-services/team-network-service';
import userNetworkService from '../../network-services/user-network-service';
import analyticsService from '../../shared-services/analytics-service';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';
import complementFileUrl from '../../react/util/complementFileUrl';

SettingsService.$inject = [
  'Upload', 'TerrNetworkService',
];

export default function SettingsService(
  Upload, TerrNetworkService,
) {
  // main utility functions
  const MODEL = window.DataModel;

  // service to return
  const service = {};
  service.integrationsURL = window.__env.integrationsURL;

  // Change password
  service.changePassword = async function (currentPass, newPass, confirmPass) {
    // must have text in all 3 text fields --> else, the view will send message to user
    if (currentPass && newPass && confirmPass) {
      // check if passwords match
      if (newPass !== confirmPass) {
        return swal('Uh-Oh', "Your passwords don't match... maybe a typo? Please try again.", 'error');
      }

      // everything checks out --> now update the password!
      try {
        const endPoint = 'users/me/password';
        const payload = {};
        payload.password = newPass;
        payload.currentPassword = currentPass;
        const response = await BaseNetworkService.update(endPoint, payload);

        // analyze response
        if (response.validationErrors) {
          switch (response.validationErrors[0].code) {
            case 'v0082':
              return swal('Yikes!', 'Try a stronger password... make sure to include at least 8 characters, an uppercase character and a special character.', 'error');
            case 'v0158':
              return swal('Uh-Oh', 'Your current password does not look correct. Maybe a typo?', 'error');
          }
        } else {
          $('#password-modal').hide();
          return swal('Congrats!', 'Your new password is ready to go.', 'success');
        }
      } catch (error) {
        return swal('Uh-Oh', "Something's not right. Please try saving again.", 'error');
      }
    }
  };

  // check if string has an uppercase letter in it
  service.checkIfHasUppercase = function (pass) {
    let hasUppercase = false;
    pass.forEach((letter) => {
      if (letter === letter.toLowerCase()) {
        // The character is lowercase
      } else {
        // The character is uppercase
        hasUppercase = true;
      }
    });

    return hasUppercase;
  };

  service.checkForSetting = (settingName) => {
    const settingIndex = safeLocalStorage.currentUser.settings.findIndex(({key}) => key === settingName);
    return settingIndex > 0;
  };

  // gets the user settings for current user
  service.getUserSettings = () => {
    const org = safeLocalStorage.currentUser.organization.id;
    const userId = safeLocalStorage.currentUser.id;
    const endPoint = `organizations/${org}/users/${userId}/settings/`;

    // send request
    return BaseNetworkService.read(endPoint);
  };

  /**
   * Returns value for given settingName. Returns defaultValue if setting is not found.
   * @param {string} settingName
   * @param {*} [defaultValue]
   * @returns {*}
   */
  service.getUserSetting = (settingName, defaultValue) => {
    if (!Array.isArray(safeLocalStorage.currentUser.settings)) {
      return defaultValue;
    }

    const setting = safeLocalStorage.currentUser.settings.find(({key}) => key === settingName);
    return setting ? setting.value : defaultValue;
  };

  /**
     * Returns value for given organization settingName. Returns defaultValue if setting is not found.
     * @param {string} settingName
     * @param {*} [defaultValue]
     * @returns {*}
     */
  service.getOrganizationSetting = (settingName, defaultValue) => {
    if (!Array.isArray(get(safeLocalStorage.currentUser, ['organization', 'settings']))) {
      return defaultValue;
    }

    const setting = safeLocalStorage.currentUser.organization.settings.find(({key}) => key === settingName);
    return setting ? setting.value : defaultValue;
  };

  // updates the user settings passed in
  service.updateUserSettings = async (settingName, value) => {
    const settingIndex = safeLocalStorage.currentUser.settings.findIndex(({key}) => key === settingName);
    if (settingIndex < 0) {
      return Promise.reject(new Error(`Invalid setting name: ${settingName}`));
    }
    const settingId = safeLocalStorage.currentUser.settings[settingIndex].id;

    const
      orgId = safeLocalStorage.currentUser.organization.id;
    const userId = safeLocalStorage.currentUser.id;
    const endPoint = `organizations/${orgId}/users/${userId}/settings/${settingId}`;

    const result = await BaseNetworkService.update(endPoint, {id: settingId, value});
    if (result && Array.isArray(result.validationErrors) && result.validationErrors.length) {
      swal('Uh-oh', result.validationErrors[0].message, 'error');
    } else {
      analyticsService.entityUpdated('Profile setting', {id: settingId, value});
    }
    safeLocalStorage.currentUser.settings.splice(settingIndex, 1, result);
    return result;
  };

  // updates the org settings passed in
  service.updateOrgSettings = async (key, value) => {
    const orgId = safeLocalStorage.currentUser.organization.id;
    const setting = safeLocalStorage.currentUser.organization.settings.find(setting => setting.key === key);
    if (!setting) {
      return Promise.reject();
    }

    return teamNetworkService.updateSetting(orgId, {id: setting.id, key, value});
  };

  // updates the user
  service.updateUser = async (key, value) => {
    // build endpoint
    const orgId = safeLocalStorage.currentUser.organization.id;
    const userId = safeLocalStorage.currentUser.id;
    const endPoint = `organizations/${orgId}/users/${userId}`;

    // build payload
    const payload = {};
    payload.id = userId;
    payload[key] = value;

    // send request
    const response = await BaseNetworkService.update(endPoint, payload);
    return response;
  };

  // gets the total record count for user
  service.getUserRecordTotal = async () => {
    // build endpoint
    const org = safeLocalStorage.currentUser.organization.id;
    const accountsEndPoint = `organizations/${org}/accounts?$limit=0`;
    const contactsEndPoint = `organizations/${org}/contacts?$limit=0`;
    const dealsEndPoint = `organizations/${org}/deals?$limit=0`;

    // send requests
    const data = await Promise.all([BaseNetworkService.read(accountsEndPoint), BaseNetworkService.read(contactsEndPoint), BaseNetworkService.read(dealsEndPoint)]);
    MODEL.SettingsService.totalRecordCount = data[0].total + data[1].total + data[2].total;
  };

  // gets the total groups count for user
  service.getUserGroupsTotal = async () => {
    const org = safeLocalStorage.currentUser.organization.id;
    const data = await Promise.all(['account', 'contact', 'deal'].map(entityType => BaseNetworkService.read(`organizations/${org}/${entityType}-groups`, {$limit: 0})));
    MODEL.SettingsService.totalGroupsCount = data[0].total + data[1].total + data[2].total;
  };

  service.refreshTerritoryCount = async () => {
    try {
      const response = await TerrNetworkService.fetchTerritories({$limit: 0});
      MODEL.SettingsService.totalTerritoryCount = response.total;
    } catch (e) {
      console.error('Failed to fetch territory count', e);
      MODEL.SettingsService.totalTerritoryCount = -1;
    }
  };

  // get custom fields data
  service.getCustomFields = async (crmObjectType) => {
    const data = await customFieldsNetworkService.getFields(crmObjectType);
    MODEL.orgCustomFields = data.data;
    MODEL.orgCustomFieldsOriginal = cloneDeep(data.data);
  };

  service.updateOrganizationLogo = async (fileDataUrl) => {
    MODEL.show.loader = true;
    const blob = await (await fetch(fileDataUrl)).blob();
    try {
      const file = (await fileNetworkService.createFile(null, null, blob, true));
      await service.updateOrgSettings('logo', file.publicURI);
      const logo = safeLocalStorage.currentUser.organization.settings.find(({key}) => key === 'logo');
      if (logo) {
        logo.value = file.publicURI;
      }
      window.refreshDom({loader: false}, 'show');
      window.refreshDom({showLogoPhotoModal: false}, 'SettingsService');
      swal('Success!', 'Successfully updated', 'success');
    } catch (error) {
      window.refreshDom({loader: false}, 'show');
      swal('Uh-oh', error.message, 'error');
    }
  };

  service.updateProfilePhoto = async (fileDataUrl) => {
    MODEL.show.loader = true;
    const blob = await (await fetch(fileDataUrl)).blob();

    try {
      const file = (await fileNetworkService.createFile(null, null, blob, true));

      const {
        id, username, fullName, phone,
      } = safeLocalStorage.currentUser;
      await userNetworkService.updateMe({
        id, username, fullName, phone, profilePhoto: file,
      });
      safeLocalStorage.currentUser.profilePhoto = file;
      window.refreshDom({profilePhoto: complementFileUrl(file.publicURI)}, 'SettingsService');
      window.refreshDom({loader: false}, 'show');
      swal('Success!', 'Successfully updated', 'success');
    } catch (error) {
      window.refreshDom({loader: false}, 'show');
      swal('Uh-oh', error.message, 'error');
    }
  };

  // new account photo
  service.submitPhoto = async function (photoType) {
    // must have a team to white label or be uploading a profile photo
    if (safeLocalStorage.currentUser.organization.plan.type === 'team' || photoType === 'profile-photo') {
      // cropped image result
      const selectedfile = await $uploadCrop.croppie('result', {
        type: 'canvas',
        size: 'viewport',
      });

      // file must exist
      if (!selectedfile) return;

      if (photoType === 'logo') {
        await service.updateOrganizationLogo(selectedfile);
      } else if (photoType === 'profile-photo') {
        await service.updateProfilePhoto(selectedfile);
        $('#accountPhotoBox').hide();
      }
      analyticsService.completed('photoType');
    } else {
      return swal('Want to add your logo?', 'Upgrade to a team plan to enable white-labeling.');
    }
  };

  /* ============ PARSE METHODS ============== */
  // update user's full name
  service.changeUserName = function () {
    throw new Error('Implement me!');
  };

  // choose your team name
  service.chooseTeamName = function (teamName) {
    MODEL.show.loader = true;

    // team name too short or too long
    if (teamName.length < 3 || teamName.length > 25) {
      MODEL.show.loader = false;
      swal('Oops...', 'Your team name needs to be between 3 and 25 characters.', 'error');
    } else {
      // entered name is valid

      throw new Error('Implement me!');
    }
  };

  // enable show company on route
  service.showCompanyOnRoute = function () {
    if (safeLocalStorage.currentUser.pinLabelShowCompany) {
      if (safeLocalStorage.currentUser.pinLabelShowCompany === false) {
        safeLocalStorage.currentUser.pinLabelShowCompany = true;
        $('#showCompanyOnRoute').addClass('btn-fill');
        $('#showCompanyOnRoute').text('Show Company');
      } else {
        safeLocalStorage.currentUser.pinLabelShowCompany = false;
        $('#showCompanyOnRoute').removeClass('btn-fill');
        $('#showCompanyOnRoute').text('Show Name');
      }
    } else {
      safeLocalStorage.currentUser.pinLabelShowCompany = true;
      $('#showCompanyOnRoute').addClass('btn-fill');
      $('#showCompanyOnRoute').text('Show Company');
    }

    throw new Error('Implement me!');
  };

  // sets loading gif immediately, and then wipes away when resolved.
  service.loadifyResolve = function loadifyResolve(realResolve) {
    MODEL.show.loader = true;
    return function (data) {
      MODEL.show.loader = false;
      realResolve(data);
    };
  };

  // add notes to customers
  service.enrichCustomersWithFormattedNotes = function () {
    if (!MODEL.SettingsService.customersLookupObject) {
      console.error("can't run me without first generating a customersLookupObject.");
      swal('Uh-Oh', "Something's wrong on our side. Please contact our support team at support@mapmycustomers.me.", 'error');
    }

    // // else...
    // return new Promise((resolve) => {

    //     resolve = service.loadifyResolve(resolve);
    //     // get all notes for username
    //     // go through all customers
    //     // match up notes and customers by customerId
    //     // map them into formatted separator
    //     // resolve

    //     // IN THE FUTURE:
    //     // It'd be a lot better if notes had their team leader's username on them,
    //     // so we could get them all in one call...
    //     // and then, further, it'd be ideal if we actually just handled notes this way
    //     // on initial load instead of doing a lookup for every customer we click on and
    //     // making the user wait and using the MODEL.currentCustomersNotes pattern.

    //     const teamUsers = UTILS.uniqueArray(MODEL.teamMembers.concat([safeLocalStorage.currentUser.username]));
    //     throw new Error("Implement me!");
    // });
  };

  return service;
}
