import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Modal, notification, Select, TimePicker} from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import Route from '../../type/Route';
import TextField from '../Input/TextField';
import Labeled from '../Labeled';
import {getUserLocationAddress} from '../../../shared-services/geo-service';
import helperService from '../../../shared-services/helper-service';
import routingNetworkService from '../../../network-services/routing-network-service';
import geocodingNetworkService from '../../../network-services/geocoding-network-service';

type VisitType = 'optimized' | 'ordered';
class VisitTypeSelect extends Select<VisitType> {}
const VisitOrderSelect = styled(VisitTypeSelect)`
  width: 100%;
`;

class NumberSelect extends Select<number> {}
const AllottedTimeSelect = styled(NumberSelect)`
  width: 100%;
`;

const StartTime = styled(TimePicker)`
  width: 100%;
`;

interface Props {
  onCreate: (route: Route) => void,
  onHide: () => void,
  routes: Route[],
}

const CreateRouteModal: React.FC<Props> = ({routes, onCreate, onHide}) => {
  const {t} = useTranslation('add-to-route');
  const [name, setName] = useState('');
  const [visitOrder, setVisitOrder] = useState<VisitType>('optimized');
  const [allottedTime, setAllottedTime] = useState<number>(0);
  const [startAddress, setStartAddress] = useState('');
  const [endAddress, setEndAddress] = useState('');
  const [startAt, setStartAt] = useState(moment());

  const handleFindMeStartingLocation = useCallback(
    async () => {
      try {
        setStartAddress(await getUserLocationAddress());
      } catch (e) {
        helperService.showAndLogError(e);
      }
    },
    [setStartAddress],
  );

  const handleFindMeEndingLocation = useCallback(
    async () => {
      try {
        setEndAddress(await getUserLocationAddress());
      } catch (e) {
        helperService.showAndLogError(e);
      }
    },
    [setEndAddress],
  );

  const q = name.trim();
  const isDuplicateName = routes.some(({name}) => name === q);
  const isValidRoute = !isDuplicateName && visitOrder && allottedTime !== undefined;

  const [creating, setCreating] = useState(false);
  const handleCreateClick = useCallback(
    async () => {
      setCreating(true);
      try {
        const startCoords = await geocodingNetworkService.geocodeAddress({address: startAddress});
        if (!startCoords) {
          notification.error({message: t('Failed to find starting location')});
          setCreating(false);
          return;
        }
        const endCoords = endAddress ? await geocodingNetworkService.geocodeAddress({address: endAddress}) : undefined;
        if (endAddress && !endCoords) {
          notification.error({message: t('Failed to find ending location')});
          setCreating(false);
          return;
        }

        const route = await routingNetworkService.createRoute({
          name,
          routeDetail: {
            allottedTime,
            startAddress,
            startAt: startAt.toDate().toISOString(),
            endAddress,
            startGeoPoint: startCoords.geoPoint,
            endGeoPoint: endCoords?.geoPoint ?? null,
            type: visitOrder,
          },
        });
        setCreating(false);

        if (!route.id) {
          helperService.showAndLogError(route);
          return;
        }

        onCreate(route);
      } catch (e) {
        setCreating(false);
      }
    },
    [allottedTime, endAddress, name, setCreating, startAddress, startAt, visitOrder],
  );

  return (
    <Modal
      okButtonProps={{disabled: !isValidRoute || creating, loading: creating}}
      onCancel={onHide}
      onOk={handleCreateClick}
      title={t('Create Route')}
      visible
    >
      <TextField
        error={isDuplicateName ? t('Route with such name already exists') : undefined}
        label={t('Route Name')}
        onChange={setName}
        required
        value={name}
      />
      <Labeled label={t('Visit order')}>
        <VisitOrderSelect dropdownMatchSelectWidth={false} onChange={setVisitOrder} value={visitOrder}>
          <Select.Option value="optimized">{t('Optimize Route')}</Select.Option>
          <Select.Option value="ordered">{t('Visit Route in order')}</Select.Option>
        </VisitOrderSelect>
      </Labeled>
      <Labeled label={t('Time allotted for each stop')}>
        <AllottedTimeSelect dropdownMatchSelectWidth={false} onChange={setAllottedTime} value={allottedTime}>
          <Select.Option value={0}>{t('Do Not Set')}</Select.Option>
          <Select.Option value={300}>{t('5 Minutes')}</Select.Option>
          <Select.Option value={900}>{t('15 Minutes')}</Select.Option>
          <Select.Option value={1800}>{t('30 Minutes')}</Select.Option>
          <Select.Option value={2700}>{t('45 Minutes')}</Select.Option>
          <Select.Option value={3600}>{t('60 Minutes')}</Select.Option>
        </AllottedTimeSelect>
      </Labeled>
      <Labeled label="Start Time">
        <StartTime
          allowClear={false}
          format="hh:mm a"
          onChange={(dateTime) => setStartAt(dateTime ?? moment())}
          use12Hours
          value={startAt}
        />
      </Labeled>
      <TextField
        label={t('Starting Location')}
        onChange={setStartAddress}
        required
        suffix={(
          <Button
            icon={<i className="fas fa-location" />}
            onClick={handleFindMeStartingLocation}
            shape="circle"
            type="text"
          />
        )}
        value={startAddress}
      />
      <TextField
        label={t('Ending Location')}
        onChange={setEndAddress}
        suffix={(
          <Button
            icon={<i className="fas fa-location" />}
            onClick={handleFindMeEndingLocation}
            shape="circle"
            type="text"
          />
        )}
        value={endAddress}
      />
    </Modal>
  );
};

export default CreateRouteModal;
