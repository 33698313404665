import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../rootReducer';
import {ValueOf} from '../../react/util/ts';
import {Actions} from './action';
import Company from '../../react/type/Company';
import EntityFieldModel from '../../common/field-model/entity-field-model';
import companyFieldModel from '../../common/field-model/account-field-model';
import CustomField from '../../react/type/CustomField';
import Group from '../../react/type/Group';

export type CompanyState = {
  companies: Company[],
  companyIndexById: Map<Company['id'], number>,
  error: Error | undefined,
  fieldModel: EntityFieldModel,
  loading: boolean,
  totalCompanyCount: number,
  groups: Group[],
};

const initialState: CompanyState = {
  companies: [],
  companyIndexById: new Map<Company['id'], number>(),
  fieldModel: companyFieldModel,
  error: undefined,
  loading: false,
  totalCompanyCount: 0,
  groups: [],
};

const company = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanies: (state, action: PayloadAction<Company[]>) => {
      state.companies = action.payload;
      state.companyIndexById = new Map(action.payload.map(({id}, index) => [id, index]));
    },
    appendCompanies: (state, action: PayloadAction<Company[]>) => {
      const startIndex = state.companies.length;
      const companyIndexById = new Map([
        ...Array.from(state.companyIndexById),
        ...action.payload.map(({id}, index) => [id, index + startIndex] as const),
      ]);
      const companies = state.companies.concat(action.payload);
      return {...state, companyIndexById, companies};
    },
    setError: (state, action: PayloadAction<Error | undefined>) => {
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCompanyCount: (state, action: PayloadAction<number>) => {
      state.totalCompanyCount = action.payload;
    },
    updateCustomFields: (state, action: PayloadAction<CustomField[]>) => {
      const fieldModelClone = Object.assign(Object.create(Object.getPrototypeOf(state.fieldModel)), state.fieldModel);
      fieldModelClone.setCustomFields(action.payload);
      state.fieldModel = fieldModelClone;
    },
    setGroups: (state, action: PayloadAction<Group[]>) => {
      state.groups = action.payload;
    },
  },
});

const companyState = (state: RootState) => state.company;
export const getCompanies = createSelector(companyState, ({companies}) => companies);
export const getCompaniesCount = createSelector(companyState, ({companies}) => companies.length);
export const hasCompanies = createSelector(companyState, ({companies}) => companies.length > 0);
export const getError = createSelector(companyState, ({error}) => error);
export const getFieldModel = createSelector(companyState, ({fieldModel}) => fieldModel);
export const getLoading = createSelector(companyState, ({loading}) => loading);
export const getTotalCompanyCount = createSelector(companyState, ({totalCompanyCount}) => totalCompanyCount);
export const getCompanyGroups = createSelector(companyState, ({groups}) => groups);

export type CompanyActions =
  | ReturnType<ValueOf<typeof company.actions>>
  | Actions;

export default company;
