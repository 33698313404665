import template from './account-photo-box.html';

class AccountPhotoBoxController {
  constructor($window, $timeout, SettingsService) {
    Object.assign(this, {
      $window,
      $timeout,
      SettingsService,
      MODEL: $window.DataModel,
    });

    this.$window.initCroppieForProfilePhoto = function () {
      if (document.readyState === 'complete') {
        // init croppie for image picker modal
        $window.$uploadCrop = $('#profile-upload').croppie({
          viewport: {
            width: 250,
            height: 250,
            type: 'square',
          },
          boundary: {
            width: 255,
            height: 300,
          },
        });

        $window.$uploadCrop.bind();
      }
    };
  }

  submitPhoto(photoType) {
    this.SettingsService.submitPhoto(photoType);
  }

  closePhotoBox() {
    $('#accountPhotoBox').hide();
  }

  readFile() {
    if (this.file) {
      const reader = new this.$window.FileReader();

      reader.onload = (e) => {
        $uploadCrop.croppie('bind', {
          url: e.target.result,
        });

        this.$timeout(() => {
          this.showSaveAccountPhoto = true;
        }, 1);
      };

      reader.readAsDataURL(this.file);
    }
  }
}

AccountPhotoBoxController.$inject = ['$window', '$timeout', 'SettingsService'];

const accountPhotoBox = {
  bindings: {},
  controller: 'AccountPhotoBoxController',
  template,
};

export {AccountPhotoBoxController, accountPhotoBox};
