import EntityFieldModel from './entity-field-model';
import {currencyFormatter, dateFormatterNoUtc, listFormatter} from './formatter';
import DateField from './field/date-field';
import StageCellRenderer from '../grid/StageCellRenderer';
import {createField} from './utils';
import EntityType from '../../react/type/EntityType';
import FieldFeature from './field/FieldFeature';

const dealFieldModel = new EntityFieldModel(EntityType.DEAL, [
  {
    name: 'name',
    displayName: 'Name',
    filter: {type: 'type1'},
    customGridProperties: {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      cellClass: ['cursor-pointer'],
    },
    features: [FieldFeature.ALWAYS_VISIBLE, FieldFeature.FILTERABLE, FieldFeature.SORTABLE, FieldFeature.TEXT],
  },
  {
    name: 'score',
    displayName: 'Score',
    filter: {type: 'type2'},
    features: [FieldFeature.NUMERIC, FieldFeature.FILTERABLE, FieldFeature.SORTABLE],
  },
  {
    name: 'amount',
    displayName: 'Amount',
    valueFormatter: currencyFormatter,
    filter: {type: 'type2'},
    features: [FieldFeature.NUMERIC, FieldFeature.FILTERABLE, FieldFeature.SORTABLE],
  },
  new DateField({
    name: 'closingDate',
    displayName: 'Closing Date',
    features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE, FieldFeature.DATE],
    valueFormatter: dateFormatterNoUtc,
  }),
  {
    name: 'groups',
    displayName: 'Groups',
    valueFormatter: listFormatter('name'),
    features: [FieldFeature.LIST, FieldFeature.NON_INTEGRATION],
  },
  {
    name: 'user',
    displayName: 'Owner',
    value: ['user', 'username'],
    filter: {type: 'type_singleselect', name: 'viewAs', nameField: 'fullName', getValues: () => window.DataModel.TeamsService.subTeamUsers},
    features: [FieldFeature.FILTERABLE, FieldFeature.TEXT],
  },
  {
    name: 'notes',
    displayName: 'Notes',
    valueFormatter: listFormatter('note'),
    features: [FieldFeature.LIST, FieldFeature.NON_INTEGRATION, FieldFeature.SORTABLE],
  },
  {
    name: 'importId',
    displayName: 'Import Id',
    features: [FieldFeature.TEXT, FieldFeature.NON_INTEGRATION, FieldFeature.SORTABLE],
  },
  {
    name: 'account',
    displayName: 'Company',
    value: ['account', 'name'],
    features: [FieldFeature.TEXT],
  },
  {
    name: 'funnel',
    displayName: 'Funnel',
    value: ['funnel', 'name'],
    features: [FieldFeature.TEXT],
  },
  new DateField({name: 'createdAt', displayName: 'Created At'}),
  new DateField({name: 'updatedAt', displayName: 'Modified At', customGridProperties: {sort: 'desc'}}),
  {
    name: 'stage',
    displayName: 'Stage',
    value: ['stage', 'name'],
    customGridProperties: {
      cellRenderer: StageCellRenderer,
      cellStyle: {padding: '4px 0 0', textAlign: 'center'},
    },
    features: [FieldFeature.TEXT, FieldFeature.FILTERABLE],
  },
].map(createField));

export default dealFieldModel;
