import MarkerClusterer from '@google/markerclusterer';
import 'js-map-label/src/maplabel';
import difference from 'lodash/difference';
import mapActions from '../../store/store-helpers';
import analyticsService from '../../shared-services/analytics-service';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';
import {isManager, isOwner} from '../../react/type/Me';

export default function TerritoriesService(
  TerrNetworkService, FetchCRMDataService, $timeout, MappingService,
) {
  const {MAN} = window.mmcUtils;
  const MODEL = window.DataModel;

  // service to return
  const service = {};
  mapActions(service, ['modals']);

  service.addTerritoryGeometry = (territory) => {
    if (territory.territoryDetail.points && territory.territoryDetail.points.type === 'Polygon' &&
            territory.territoryDetail.points.coordinates.length && territory.territoryDetail.points.coordinates[0].length) {
      // we only support Polygons with a single path
      const points = territory.territoryDetail.points.coordinates[0].map(([lng, lat]) => ({lat, lng}));

      // remove last point if it is the same as the first
      const pointsCount = points.length;
      if (points[0].lat === points[pointsCount - 1].lat && points[0].lng === points[pointsCount - 1].lng) {
        points.splice(pointsCount - 1, 1);
      }

      const polygon = new google.maps.Polygon({
        paths: points,
        strokeColor: MODEL.colorsToHex[territory.color],
        strokeOpacity: 1.0,
        strokeWeight: 3,
        fillColor: MODEL.colorsToHex[territory.color],
        fillOpacity: territory.territoryDetail.opacity,
        clickable: false,
      });

      const polygonLabel = new MapLabel({
        text: territory.name,
        fontSize: 18,
        fontColor: 'black',
        align: 'center',
      });

      const bounds = new google.maps.LatLngBounds();
      points.forEach(point => {
        bounds.extend(new google.maps.LatLng(point));
      });
      polygonLabel.set('position', bounds.getCenter());

      territory = {...territory, polygon, polygonLabel};
    }
    return territory;
  };

  service.fetchTerritories = async (filters = {}, page = 1, pageSize = 100, column, ascending) => {
    if (filters.includeGroups) {
      delete filters.includeGroups;
    }

    const params = {
      $offset: Math.max(0, page - 1) * pageSize,
      $limit: pageSize,
      $filters: filters,
    };

    if (column) {
      params.$order = `${ascending ? '' : '-'}${column}`;
    }

    const response = await TerrNetworkService.fetchTerritories(params);

    if (!response.total && !MODEL.currentLeadsView && !MODEL.FilterService.filtersSelected.length) {
      service.modalsActions.showModal('noTerritoriesModal');
    } else {
      service.modalsActions.resetVisibility('noTerritoriesModal');
    }

    return {
      territories: response.data.map(service.addTerritoryGeometry),
      territoryCount: response.total,
    };
  };

  service.fetchTerritoryCounts = async (page = 1, pageSize = 100, column, ascending, filters) => {
    const params = {
      $offset: Math.max(0, page - 1) * pageSize,
      $limit: pageSize,
      filters,
    };

    if (column) {
      params.$order = `${ascending ? '' : '-'}${column}`;
    }

    return TerrNetworkService.fetchTerritoryCounts(params);
  };

  service.setMapView = () => {
    MAN.nukeMapContent();
    $timeout(() => {
      MAN.triggerResize();
    });
    MODEL.bounds = new google.maps.LatLngBounds();

    // all accounts which share the same coordinates are grouped together
    // then such groups are combined into one contact
    // then we add marker for every resulting contact
    MODEL.customersOverlay = MappingService
      .interpolateData(MODEL.accounts)
      .map(groupedContact => MappingService.processInterpolatedObjects('accounts', groupedContact))
      .map((crmObject) => {
        // customer overlay pin
        const latLng = new google.maps.LatLng(crmObject.latitude, crmObject.longitude);
        const markerArgs = MappingService.getMapMarkersArgs('accounts', crmObject, latLng);
        const marker = new MappingService.CustomMarker(latLng, MODEL.map, markerArgs);
        MODEL.bounds.extend(latLng);
        return marker;
      });

    MODEL.MappingService.localCurrentLatLng = MAN.userPosition || MAN.lastResortPosition;
    MappingService.setUserMarker();
    MODEL.markerCluster = new MarkerClusterer(MODEL.map, MODEL.customersOverlay, MODEL.mcOptions);
    MAN.markerCluster = MODEL.markerCluster;

    const show = MODEL.TerritoriesService.showTerritories || MODEL.currentSubPage === 'list';
    MODEL.territories.forEach(territory => {
      if (!territory.territoryDetail.hidden && territory.polygon && territory.polygonLabel) {
        territory.polygon.setMap(show ? MODEL.map : null);
        MAN.allMapContent.push(territory.polygon);
        MAN.addedContentMessage('added territory polygon');
        territory.polygonLabel.setMap(show ? MODEL.map : null);
        MAN.allMapContent.push(territory.polygonLabel);
        MAN.addedContentMessage('and its label');
        territory.polygon.getPath().forEach(point => {
          MODEL.bounds.extend(point);
        });
      }
    });

    if (MAN.hasSavedZoomOrCenter()) {
      MAN.goToSavedZoomAndCenter();
    } else {
      MAN.fitBounds(MODEL.bounds);
    }
  };

  // set the view headers
  service.setViewHeaders = (map) => {
    const title = map ? 'Mapped Territories' : 'All Territories';
    const count = MODEL.territoryCount;
    const btn = 'Add Territory';
    const showFilter = map;
    const me = safeLocalStorage.currentUser;
    const showAdd = isOwner(me) || isManager(me);
    FetchCRMDataService.setCurrentPageVariable(title, count, btn, showFilter, showAdd);
  };

  service.showCreateTerritoryPopup = () => {
    MODEL.TerritoriesService.addEditPopupMode = 'add';
    MODEL.TerritoriesService.territoryName = '';
    MODEL.TerritoriesService.createTerritoryOption = undefined;
    MODEL.TerritoriesService.currentTerrId = undefined;
    $timeout(() => {
      $('.mmc-add-edit-territory-popup__name-input').focus();
    });
  };

  service.showEditTerritoryNamePopup = (territoryId, territoryName) => {
    MODEL.TerritoriesService.addEditPopupMode = 'edit';
    MODEL.TerritoriesService.territoryName = territoryName;
    MODEL.TerritoriesService.createTerritoryOption = undefined;
    MODEL.TerritoriesService.currentTerrId = territoryId;
    $timeout(() => {
      $('.mmc-add-edit-territory-popup__name-input').focus();
    });
  };

  service.updateTerritoryColorPreview = () => {
    $timeout(() => {
      $('.mmc-edit-territory-color-popup__preview').css({
        'background-color': MODEL.colorsToHex[MODEL.TerritoriesService.territoryColor],
        opacity: MODEL.TerritoriesService.territoryOpacity,
      });
    });
  };

  service.initOpacitySlider = (opacity) => {
    $timeout(() => {
      MODEL.TerritoriesService.opacitySlider = $('#territoryOpacitySlider')
        .ionRangeSlider({
          grid: false,
          min: 0,
          max: 1,
          from: opacity,
          hide_min_max: true,
          hide_from_to: true,
          step: 0.1,
          onChange(data) {
            MODEL.TerritoriesService.territoryOpacity = data.from;
            service.updateTerritoryColorPreview();
          },
        })
        .data('ionRangeSlider');
    });
  };

  service.showEditTerritoryColorPopup = (territoryId, color, opacity) => {
    MODEL.TerritoriesService.showEditColorPopup = true;
    MODEL.TerritoriesService.currentTerrId = territoryId;
    MODEL.TerritoriesService.territoryColor = color;
    MODEL.TerritoriesService.territoryOpacity = opacity;
    service.updateTerritoryColorPreview();
    service.initOpacitySlider(opacity);
  };

  service.setTerritoryColor = color => {
    MODEL.TerritoriesService.territoryColor = color;
    service.updateTerritoryColorPreview();
  };

  service.saveTerritoryName = async (territoryId, name) => {
    const territoryIndex = MODEL.territories.findIndex(({id}) => id === territoryId);
    if (territoryIndex < 0) {
      return Promise.reject();
    }

    const {...territory} = MODEL.territories[territoryIndex];
    const payload = {...territory, name, postalCodes: []};
    MODEL.territories[territoryIndex] = service.addTerritoryGeometry(
      await TerrNetworkService.updateTerritory(payload),
    );
    window.refreshDom({territories: MODEL.territories});
  };

  service.saveTerritoryColor = async (territoryId, color, opacity) => {
    const territoryIndex = MODEL.territories.findIndex(({id}) => id === territoryId);
    if (territoryIndex < 0) {
      return Promise.reject();
    }

    const {...territory} = MODEL.territories[territoryIndex];
    const payload = {
      ...territory, color, territoryDetail: {...territory.territoryDetail, opacity}, postalCodes: [],
    };
    MODEL.territories[territoryIndex] = service.addTerritoryGeometry(
      await TerrNetworkService.updateTerritory(payload),
    );
    window.refreshDom({territories: MODEL.territories});
  };

  service.setTerritoryVisibility = async (territoryId, hidden) => {
    const territoryIndex = MODEL.territories.findIndex(({id}) => id === territoryId);
    if (territoryIndex < 0) {
      return Promise.reject();
    }

    const {...territory} = MODEL.territories[territoryIndex];
    const payload = {...territory, territoryDetail: {...territory.territoryDetail, hidden}, postalCodes: []};
    MODEL.territories[territoryIndex] = service.addTerritoryGeometry(
      await TerrNetworkService.updateTerritory(payload),
    );
    window.refreshDom({territories: MODEL.territories});
  };

  service.setMapUpdatedMessageBarVisibility = (visible) => {
    window.refreshDom({mapUpdateMessageBarVisible: visible}, 'TerritoriesService');
  };

  service.createTerritory = async () => {
    let coordinates = [];
    if (MODEL.TerritoriesService.poly) {
      coordinates = MODEL.TerritoriesService.poly.getPath().getArray().map(({lng, lat}) => [lng(), lat()]);
    }

    if (coordinates.length >= 3) {
      coordinates.push(coordinates[0]); // close the polygon
      const payload = {
        name: MODEL.TerritoriesService.territoryName,
        user: {
          id: safeLocalStorage.currentUser.id,
        },
        color: 'blue', // default color
        territoryDetail: {
          opacity: 0.75, // default opacity
          points: {
            type: 'Polygon',
            coordinates: [coordinates],
          },
        },
        postalCodes: [],
      };
      await TerrNetworkService.createTerritory(payload);
      analyticsService.entityAdded('Territory', payload);
      service.cancelTerritory();
      swal('Success!', 'The territory has been saved.', 'success');
    } else {
      swal('Uh-Oh!', 'Please add at least 3 points.', 'error');
      return Promise.reject();
    }
  };

  service.updateTerritoryBounds = async (territoryId) => {
    const territoryIndex = MODEL.territories.findIndex(({id}) => id === territoryId);
    if (territoryIndex < 0) {
      return Promise.reject();
    }

    let coordinates = [];
    if (MODEL.TerritoriesService.poly) {
      coordinates = MODEL.TerritoriesService.poly.getPath().getArray().map(({lng, lat}) => [lng(), lat()]);
    }

    if (coordinates.length >= 3) {
      coordinates.push(coordinates[0]); // close the polygon
      const {...territory} = MODEL.territories[territoryIndex];
      const payload = {
        ...territory,
        territoryDetail: {
          ...territory.territoryDetail,
          points: {
            type: 'Polygon',
            coordinates: [coordinates],
          },
        },
      };
      MODEL.territories[territoryIndex] = service.addTerritoryGeometry(
        await TerrNetworkService.updateTerritory(payload),
      );
      window.refreshDom({territories: MODEL.territories});
      service.cancelTerritory();
      swal('Success!', 'The territory has been saved.', 'success');
    } else {
      swal('Uh-Oh!', 'Please add at least 3 points.', 'error');
      return Promise.reject();
    }
  };

  service.deleteTerritory = (territoryId) => swal({
    title: 'Are you sure?',
    text: 'You will not be able to recover this territory!',
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#DD6B55',
    confirmButtonText: 'Yes, delete now',
    closeOnConfirm: false,
  })
    .then(async () => {
      await TerrNetworkService.deleteTerritory(territoryId);
      const territoryIndex = MODEL.territories.findIndex(({id}) => id === territoryId);
      if (territoryIndex >= 0) {
        MODEL.territories.splice(territoryIndex, 1);
      }
      return true;
    })
    .catch((res) => {
      if (res !== 'cancel') {
        swal('Uh-Oh', "We couldn't delete this territory. Try again.", 'error');
      }
      return false;
    });

  service.validateTerritoryName = (name) => {
    if (!name.length) {
      swal('Uh-Oh!', 'Please add a name – only use letters, numbers, underscores, spaces, hyphens, and dashes.', 'error');
      return false;
    }

    return true;
  };

  service.validateTerritoryZips = (zips) => {
    if (!zips.length) {
      swal('Uh-Oh!', 'Please add some postal codes.', 'error');
      return false;
    }

    return true;
  };

  service.createTerritoryBounds = () => {
    $('#subheader').hide();
    $('#add-terr-subheader').show();
    $('#map').addClass('mmc-territories__top-offset');
    if (MODEL.currentSubPage === 'list') {
      $('#allterr').hide();
      $('#map').show();
      service.setMapView();
    }
    $('.mmc-edit-bounds-subheader__clear-button').show();

    MODEL.TerritoriesService.clearedTerr = true;
    MODEL.TerritoriesService.poly = new google.maps.Polyline({
      strokeColor: '#3063E4',
      strokeOpacity: 1.0,
      strokeWeight: 3,
    });
    MODEL.TerritoriesService.poly.setMap(MODEL.map);
    MAN.allMapContent.push(MODEL.TerritoriesService.poly);
    MAN.addedContentMessage('polyline added');

    // Add a listener for the click event
    MODEL.TerritoriesService.terrListener = google.maps.event.addListener(MODEL.map, 'click', service.addMarkerToTerritory);
  };

  service.editTerritoryBounds = (territoryId) => {
    $('#subheader').hide();
    $('#add-terr-subheader').show();
    if (MODEL.currentSubPage === 'list') {
      $('#allterr').hide();
      $('#map').show();
      service.setMapView();
    }
    MODEL.TerritoriesService.currentTerrId = territoryId;
    MODEL.TerritoriesService.isEditingTerr = true;
    service.editTerritoryPath(territoryId);
  };

  service.cancelTerritory = function () {
    // clear listener so user doesn't accidentally drop pins on map
    analyticsService.canceled('Territory Markers');

    google.maps.event.clearListeners(MODEL.map, 'click');
    MODEL.TerritoriesService.terrListener = undefined;

    if (MODEL.TerritoriesService.isEditingTerr) {
      service.clearTerritory(); // replace terr being edited with the original one
      MODEL.TerritoriesService.isEditingTerr = false;
    } else {
      service.clearTerritory(true);
    }

    if (MODEL.currentSubPage === 'list') {
      $('#allterr').show();
      $('#map').hide();
    }
    $('#map').removeClass('mmc-territories__top-offset').css({height: '100%'});
    $('#subheader').show();
    $('#add-terr-subheader').hide();
  };

  // clear territory entirely
  service.clearTerritory = function (removePolygon) {
    const territory = MODEL.territories.find(({id}) => id === MODEL.TerritoriesService.currentTerrId);

    if (removePolygon && territory && territory.polygon) {
      territory.polygon.setMap(null);
    } else if (MODEL.TerritoriesService.isEditingTerr && territory && territory.polygon && MODEL.TerritoriesService.originalVisibility) {
      territory.polygon.setMap(MODEL.map);
      territory.polygonLabel.setMap(MODEL.map);
    }

    // clear polyline if it exists (from creating new terr)
    if (MODEL.TerritoriesService.poly) {
      MODEL.TerritoriesService.poly.setPath([]);
    }

    // remove editing markers
    (MODEL.TerritoriesService.editedTerritoryMarkers || []).forEach(marker => {
      marker.setMap(null);
    });
    MODEL.TerritoriesService.editedTerritoryMarkers = [];

    // clear markers
    MODEL.TerritoriesService.territoryMarkers.forEach(marker => {
      marker.setMap(null);
    });
    MODEL.TerritoriesService.territoryMarkers = [];

    MODEL.TerritoriesService.clearedTerr = true;
  };

  // edit a territories path
  service.editTerritoryPath = (territoryId) => {
    MODEL.TerritoriesService.currentTerrId = territoryId;
    const territory = MODEL.territories.find(({id}) => id === territoryId);
    if (!territory || !territory.polygon) {
      return;
    }

    // $(".mmc-edit-bounds-subheader__clear-button").hide();

    // store original path
    const pathPoints = territory.polygon.getPath().getArray();
    MODEL.TerritoriesService.originalVisibility = territory.territoryDetail.hidden;
    if (!territory.territoryDetail.hidden) {
      territory.polygon.setMap(null);
      territory.polygonLabel.setMap(null);
    }

    // highlight territory being edited
    MODEL.TerritoriesService.poly = new google.maps.Polygon({
      strokeColor: '#3063E4',
      fillColor: '#3063E4',
      strokeOpacity: 1.0,
      strokeWeight: 3,
      path: pathPoints,
    });
    MODEL.TerritoriesService.poly.setMap(MODEL.map);
    MAN.allMapContent.push(MODEL.TerritoriesService.poly);
    MAN.addedContentMessage('polyline added');

    MODEL.bounds = new google.maps.LatLngBounds();
    MODEL.TerritoriesService.editedTerritoryMarkers = pathPoints.map((position, index) => {
      MODEL.bounds.extend(position);
      const marker = new google.maps.Marker({
        position,
        title: '',
        index,
        draggable: true,
        map: MODEL.map,
      });
      MAN.allMapContent.push(marker);
      MAN.addedContentMessage('added edit polygon marker');

      google.maps.event.addListener(marker, 'dragend', (event) => {
        const path = MODEL.TerritoriesService.poly.getPath();
        path.removeAt(marker.index);
        path.insertAt(marker.index, event.latLng);
        MODEL.TerritoriesService.editedTerrByDragging = true;
      });

      return marker;
    });

    MAN.fitBounds(MODEL.bounds);
  };

  // handles click events on a map for territories page -- and adds a new point to the Polyline
  service.addMarkerToTerritory = (event) => {
    if (MODEL.TerritoriesService.isEditingTerr) {
      return;
    }

    const path = MODEL.TerritoriesService.poly.getPath();
    path.push(event.latLng);

    // Add a new marker at the new plotted point on the polyline.
    const marker = new google.maps.Marker({
      position: event.latLng,
      title: `#${path.getLength()}`,
      map: MODEL.map,
    });
    MAN.allMapContent.push(marker);
    MAN.addedContentMessage('added a marker to a territory');
    MODEL.TerritoriesService.territoryMarkers.push(marker);
  };

  service.updateTerritoriesVisibilityOnMap = (show) => {
    MODEL.territories.forEach(territory => {
      console.log('updateTerritoriesVisibilityOnMap', show, territory.name);
      if (territory.polygon && !territory.territoryDetail.hidden) {
        territory.polygon.setMap(show ? MODEL.map : null);
        territory.polygonLabel.setMap(show ? MODEL.map : null);
      }
    });
  };

  service.showZipsPopup = (territoryId, zips) => {
    MODEL.TerritoriesService.showEditZipsPopup = true;
    MODEL.TerritoriesService.currentTerrId = territoryId;
    MODEL.TerritoriesService.territoryZips = zips;
    $timeout(() => {
      $('.mmc-edit-zips-popup__zips-input').focus();
    });
  };

  service.createTerritoryWithZips = async (name, zipsString) => {
    const postalCodes = zipsString
      .split(' ')
      .map(zip => zip.trim())
      .filter(zip => zip.length)
      .map(zip => ({
        postalCode: zip,
        _action_: 'create',
      }));

    if (!postalCodes.length) {
      swal('Uh-Oh!', 'Please add some postal codes.', 'error');
      return Promise.reject();
    }

    const payload = {
      name,
      user: {
        id: safeLocalStorage.currentUser.id,
      },
      color: 'blue', // default color
      territoryDetail: {
        hidden: false,
        opacity: 0.75, // default opacity
      },
      postalCodes,
    };
    await TerrNetworkService.createTerritory(payload);
    analyticsService.entityAdded('Territory', payload);
    swal('Success!', 'The territory has been saved.', 'success');
    return Promise.resolve();
  };

  service.updateTerritoryZips = async (territoryId, zipsString) => {
    const territoryIndex = MODEL.territories.findIndex(({id}) => id === territoryId);
    if (territoryIndex < 0) {
      return Promise.reject();
    }

    const newPostalCodes = zipsString.split(' ').map(zip => zip.trim()).filter(zip => zip.length);
    if (!newPostalCodes.length) {
      swal('Uh-Oh!', 'Please add some postal codes.', 'error');
      return Promise.reject();
    }

    const
      oldPostalCodes = MODEL.territories[territoryIndex].postalCodes.map(({postalCode}) => postalCode);
    const postalCodes = [].concat(
      difference(newPostalCodes, oldPostalCodes).map(postalCode => ({postalCode, _action_: 'create'})),
      difference(oldPostalCodes, newPostalCodes).map(postalCode => ({postalCode, _action_: 'delete'})),
    );

    const {...territory} = MODEL.territories[territoryIndex];
    const payload = {
      ...territory,
      territoryDetail: {
        ...territory.territoryDetail,
      },
      postalCodes,
    };
    MODEL.territories[territoryIndex] = await TerrNetworkService.updateTerritory(payload);
    window.refreshDom({territories: MODEL.territories});
    swal('Success!', 'The territory has been saved.', 'success');
    return Promise.resolve();
  };

  return service;
}

TerritoriesService.$inject = [
  'TerrNetworkService', 'FetchCRMDataService', '$timeout', 'MappingService',
];
