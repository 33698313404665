import moment from 'moment';
import _get from 'lodash/get';
import startCase from 'lodash/startCase';
import mmcConst from '../constants';

/**
 * @param {string} entityType entity type
 * @param {boolean} [plural] convert to singular or plural
 * @param {boolean} [lowercase] convert to be all lowercase or capitalized, lowercase by default
 * @returns {string}
 */
export const getEntityDisplayName = (entityType, plural, lowercase = true) => {
  const dictionary = plural ? mmcConst.pluralNameByEntityType : mmcConst.nameByEntityType;
  let displayName = dictionary[entityType] || entityType;
  if (lowercase) {
    displayName = displayName.toLowerCase();
  } else {
    displayName = startCase(displayName);
  }
  return displayName;
};

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

export const dateFormatter = (entity, date, useUtc = true) => {
  const momentDate = moment(date);
  // checking both date and moment.isValid because date could be undefined which is though a valid moment
  // object (equal to moment.now). But we should obviously format such date as an empty string
  return date && momentDate.isValid() ? momentDate.utc(useUtc).format('LL') : '';
};
export const dateFormatterNoUtc = (entity, date) => dateFormatter(entity, date, false);

export const timeFormatter = (entity, date) => {
  const momentDate = moment(date);
  return date && momentDate.isValid() ? momentDate.utc().format('LT') : '';
};
export const currencyFormatter = (entity, value) => currencyFormat.format(value);
export const booleanFormatter = (entity, value) => (value ? 'Yes' : 'No');
export const locationFormatter = (entity, coordinates) => (Array.isArray(coordinates) ? `${coordinates[0]}, ${coordinates[1]}` : undefined);
export const listFormatter = (path) => (entity, values) => (Array.isArray(values) ? values.map(value => _get(value, path)) : undefined);
