import template from './record-limit-warning-modal.html';
import mapActions from '../../store/store-helpers';
import './record-limit-warning-modal.scss';

class RecordLimitWarningModalController {
  constructor($window) {
    Object.assign(this, {
      $window,
      MODEL: $window.DataModel,
    });

    mapActions(this, ['modals']);
  }

  hideModal() {
    this.modalsActions.hideModal('recordLimitWarningModal');
  }
}

RecordLimitWarningModalController.$inject = ['$window'];

const recordLimitWarningModal = {
  bindings: {},
  controller: 'RecordLimitWarningModalController',
  template,
};

export {RecordLimitWarningModalController, recordLimitWarningModal};
