import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import WebNotification from '../react/type/webNotification/webNotification';
import NotificationListResponse from '../react/type/webNotification/NotificationListResponse';

class NotificationNetworkService {
  _getEndpoint(id?: WebNotification['id'], extra?: string) {
    const orgId = safeLocalStorage.currentUser!.organization.id;
    let endpoint = `organizations/${orgId}/notifications`;

    if (id) {
      endpoint += `/${id}`;
    }

    if (extra) {
      endpoint += `/${extra}`;
    }

    return endpoint;
  }

  list(): Promise<NotificationListResponse<WebNotification>> {
    if (!safeLocalStorage.currentUser?.organization?.id) {
      // @ts-ignore
      return Promise.resolve({unreadTotal: 0, data: []});
    }
    return BaseNetworkServiceWithPromises.read(this._getEndpoint());
  }

}

export default new NotificationNetworkService();
