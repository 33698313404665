import React, {memo, useCallback, useState} from 'react';
import invariant from 'invariant';
import {useTranslation} from 'react-i18next';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {StripeCardElementOptions} from '@stripe/stripe-js';
import DialogContainer from 'react-md/lib/Dialogs/DialogContainer';
import CircularProgress from 'react-md/lib/Progress/CircularProgress';
import styled from 'styled-components';
import Price from '../../../type/plans/Price';
import upgradeNetworkService from '../../../../network-services/upgrade-network-service';
import Tier from '../../../type/plans/Tier';
import useBoolean from '../../../hook/useBoolean';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';

const ErrorMessage = styled.div`
  margin: 16px 0;
  color: #bb4e57;
  text-align: center;
`;

const Container = styled(DialogContainer)`
  & > .md-dialog {
    min-width: 400px;
    & > .md-dialog-content {
      min-width: 400px;
      
      & .StripeElement {
        width: 100%;
        padding: 11px 15px 11px;
        background-color: #3063E4;
        box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
      }
    }
  }
`;

const CARD_OPTIONS: StripeCardElementOptions = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      fontWeight: '500',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {color: '#fce883'},
      '::placeholder': {color: '#87bbfd'},
    },
    invalid: {
      iconColor: '#bb4e57',
      color: '#bb4e57',
    },
  },
};

interface Props {
  amount: Price,
  licenses: number,
  onCompleteCheckout?: () => void,
  onHide?: () => void,
  selectedTier: Tier,
  visible: boolean,
}

const CheckoutDialog = memo<Props>(({
  amount,
  licenses,
  onCompleteCheckout,
  onHide,
  selectedTier,
  visible,
}) => {
  const {t} = useTranslation(['billing']);
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [successVisible, showSuccessDialog, hideSuccessDialog] = useBoolean();

  const closeDialog = useCallback(
    () => {
      onHide && onHide();
      setError(undefined);
      setLoading(false);
    },
    [onHide, setError],
  );

  const handleCloseSuccessfully = useCallback(
    () => {
      hideSuccessDialog();
      closeDialog();
      onCompleteCheckout && onCompleteCheckout();
    },
    [hideSuccessDialog],
  );

  const handleManageUsersClick = useCallback(() => { window.location.href = '/#/dashboard/teams'; }, []);

  const handlePayClick = useCallback(
    async () => {
      if (!stripe || !elements) {
        return;
      }

      setError(undefined);
      setLoading(true);
      try {
        const cardElement = elements.getElement(CardElement);
        invariant(!!cardElement, 'CardElement not found');

        const {error, token} = await stripe.createToken(cardElement, {});
        if (error) {
          setError(error.message);
          setLoading(false);
          return;
        }
        invariant(!!token, 'Token not defined');

        await upgradeNetworkService.upgradeOrg(token, selectedTier.id, licenses);
        setLoading(false);
        showSuccessDialog();
      } catch (e) {
        let message = t('Unknown Error');
        if (e && Array.isArray(e.validationErrors) && e.validationErrors.length) {
          message = e.validationErrors[0].message;
        } else if (e && e.message) {
          message = e.message;
        }

        setError(t('Failed to upgrade plan', {message}));
        setLoading(false);
      }
    },
    [stripe, elements, selectedTier, licenses, onHide],
  );

  return (
    <>
      <Container
        actions={[
          {
            children: t('Cancel Checkout'),
            disabled: loading,
            onClick: closeDialog,
            secondary: true,
          },
          {
            children: t('Pay', {price: amount}),
            disabled: !stripe || loading,
            onClick: handlePayClick,
            primary: true,
          },
        ]}
        id="checkout-dialog"
        focusOnMount={false}
        key="checkout-dialog"
        modal
        title={t('Checkout')}
        onHide={onHide}
        visible={visible}
      >
        <CardElement options={CARD_OPTIONS} />
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {loading && <CircularProgress centered id="billing-page-loading" scale={2} />}
      </Container>
      <ConfirmationDialog
        cancelButtonLabel={t('Manage Users')}
        confirmButtonLabel={t('Close')}
        focusOnConfirm
        id="success-dialog"
        key="success-dialog"
        onConfirm={handleCloseSuccessfully}
        onCancel={handleManageUsersClick}
        onHide={handleCloseSuccessfully}
        title={t('Success')}
        text={t('Your purchase has been completed successfully')}
        visible={successVisible}
      />
    </>
  );
});

export default CheckoutDialog;
