import safeLocalStorage from '../../shared-services/safe-local-storage-service';
import template from './preview-pane.html';
import EntityType from '../../react/type/EntityType';
import randomId from '../../react/util/randomId';

const getNewAppUrl = (path) => `${window.__env.baseNewAppUrl}${path}?mmc_token=${safeLocalStorage.accessToken}&mmc_editpane`;

class PreviewPaneController {
  constructor($window, MappingService, MainService) {
    Object.assign(this, {
      MODEL: $window.DataModel,
      MAN: $window.mmcUtils.MAN,
      MappingService,
      MainService,
      paneId: randomId(),
    });

    window.addEventListener('message', (e) => {
      if (e.data === 'closeRecordPane') {
        this.closePreviewPane();
      }
    });
  }

  closePreviewPane() {
    const paneSelector = `#preview-pane-${this.paneId}`;
    if ($(paneSelector).length) {

      this.MODEL.MappingService.singlePinData = undefined;
      window.refreshDom({'MappingService.singlePinData': this.MODEL.MappingService.singlePinData});

      this.MODEL.NavBar.entityId = undefined;
      window.refreshDom({'NavBar.entityId': this.MODEL.NavBar.entityId});

      $(paneSelector).css({display: 'none'});
      $(`${paneSelector} > iframe`)[0].src = '';
      if (this.mapView) {
        $('#map-toolbar > div.tool-box-div').css({right: '2%'});
        if (this.MODEL.MappingService.currentPopOverData && this.MODEL.MappingService.currentPopOverData.div) {
          this.MODEL.MappingService.currentPopOverData.div.style.backgroundImage = this.MODEL.MappingService.previousPinColor;
        }
      }

      window.zE('webWidget', 'show');
    }
  }

  $onChanges() {
    // console.log('$onChanges', this.entityType, this.entityId);

    if (!this.entityId) {
      this.closePreviewPane();
      return;
    }

    let url = '';
    if (this.entityType === EntityType.COMPANY) {
      url = `/web1/company/${this.entityId}`;
    } else if (this.entityType === EntityType.PERSON) {
      url = `/web1/people/${this.entityId}`;
    } else if (this.entityType === EntityType.DEAL) {
      url = `/web1/deals/${this.entityId}`;
    } else {
      return;
    }

    // close pin annotation
    if (this.mapView) {
      this.MODEL.MappingService.popovers.forEach((popover) => {
        popover.remove();
      });
      this.MODEL.MappingService.popovers = [];
    }

    // this timeout is needed because id of the preview pane is set dynamically, and sometimes
    // it is still not set at this point. So selectors would return no results. That's why we
    // let angular to refresh dom and then we run the following logic.
    setTimeout(() => {
      const paneSelector = `#preview-pane-${this.paneId}`;
      $(paneSelector).css({display: 'block'});
      document.querySelector(`${paneSelector} > iframe`).src = getNewAppUrl(url);
      if (this.mapView) {
        $('#map-toolbar > div.tool-box-div').css({right: 'calc(512px + 2%)'});
      }

      window.zE('webWidget', 'hide');
    }, 0);
  }
}

PreviewPaneController.$inject = ['$window', 'MappingService', 'MainService'];

const previewPane = {
  bindings: {
    className: '@',
    entityType: '<?',
    entityId: '<?',
    mapView: '<?',
    pinDiv: '<?',
  },
  controller: 'PreviewPaneController',
  template,
};


export {PreviewPaneController, previewPane};
