import trim from 'lodash/trim';
import template from './quick-create-contact.html';
import countryList from '../../common/field-model/country-list.json';
import getFullName from '../../react/util/formatter/getFullName';

class QuickCreateContactController {
  constructor($window) {
    Object.assign(this, {
      $window,
      MODEL: $window.DataModel,
    });

    this.addressTouched = false;
  }

  $onInit() {
    this.contacts.quickNameAdd = '';
    this.contacts.quickFirstNameAdd = '';
    this.contacts.quickLastNameAdd = '';
    this.contacts.quickAddressAdd = '';
    this.contacts.quickCityAdd = '';
    this.contacts.quickStateAdd = '';
    this.contacts.quickCountryAdd = '';
    this.contacts.quickPostalCodeAdd = '';
    this.countryList = countryList;
  }

  inheritAddress() {
    if (!this.forEntity) {
      return;
    }
    this.contacts.quickAddressAdd = this.forEntity.address;
    this.contacts.quickCityAdd = this.forEntity.city;
    this.contacts.quickStateAdd = this.forEntity.region;
    this.contacts.quickCountryAdd = this.forEntity.country;
    this.contacts.quickPostalCodeAdd = this.forEntity.postalCode;
  }

  updateAddress() {
    if (!this.addressTouched) {
      this.addressTouched = true;
      this.contacts.quickCountryAdd = 'United States';
    }
  }

  updateName() {
    this.contacts.quickNameAdd = getFullName(this.contacts.quickFirstNameAdd, this.contacts.quickLastNameAdd);
  }

  isValidQuickAddDialog() {
    const hasName = !!trim(this.contacts.quickNameAdd);
    const hasAddress = !!trim(this.contacts.quickAddressAdd);
    const hasCountry = !!trim(this.contacts.quickCountryAdd);
    const hasEnoughInformationToGeocode =
            !hasAddress || (hasAddress && hasCountry);

    if (!this.MODEL.quickCreateCustomFields.length) {
      return hasName && hasEnoughInformationToGeocode;
    }

    const customFieldValues = Object.values(
      this.MODEL.quickCreateCustomFieldValues,
    );
    const areAllCustomFieldValuesSet = customFieldValues.every(({value}) => Array.isArray(value) && value.length && value[0] !== undefined);

    return (
      hasName && hasEnoughInformationToGeocode && areAllCustomFieldValuesSet
    );
  }
}

QuickCreateContactController.$inject = ['$window'];

const quickCreateContact = {
  bindings: {
    forEntity: '<',
    contacts: '=',
    cancelQuickCreate: '&',
    saveQuickCreate: '&',
  },
  controller: 'QuickCreateContactController',
  template,
};

export {QuickCreateContactController, quickCreateContact};
