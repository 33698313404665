import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';

export default function ActivityNetworkService() {
// service to return
  const service = {};

  service.fetchActivities = () => BaseNetworkServiceWithPromises.read('activities');

  // fetch all activity data
  service.fetchPlatformActivities = (params) => {
    const orgId = safeLocalStorage.currentUser.organization.id;
    return BaseNetworkServiceWithPromises.read(`organizations/${orgId}/platform-activities`, params);
  };

  /**
     * @return {Integer} activity table count
     */
  service.getActivityCount = () => {
    throw new Error('Implement me!');
  };

  // get updates for user (function only for enterprise)
  service.getActivityForUser = () => {
    throw new Error('Implement me!');
  };

  // fetch activity data for non enterprise
  service.getUpdates = () => {
    throw new Error('Implement me!');
  };

  return service;
}
