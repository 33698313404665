import React from 'react';
import cn from 'classnames';
import safeLocalStorage from '../../../../shared-services/safe-local-storage-service';
import FontAwesomeIcon from './FontAwesomeIcon';
import analyticsService from '../../../../shared-services/analytics-service';
import {getVersion, Version} from '../../../../common/versions';
import styles from './MenuItemTitle.module.scss';

const getNewAppUrl = (path: string): string =>
  `${window.__env.baseNewAppUrl}${path}?mmc_token=${safeLocalStorage.accessToken}`;

interface Props {
  active?: boolean;
  children?: React.ReactNode;
  hasSubmenu?: boolean;
  icon?: string;
  iconType?: string,
  legacyPath?: string;
  onClick?: () => void,
  path?: string;
  pathVersions?: Version[],
}

const MenuItemTitle: React.FC<Props> = ({active, children, hasSubmenu, icon, iconType, legacyPath, onClick, path, pathVersions = [Version.V2, Version.V2_BETA]}) => {
  if (!pathVersions.includes(getVersion())) {
    path = undefined; // remove path and only keep legacy path
  } else if (path) {
    legacyPath = undefined; // else remove legacy path
  }

  const handleClick = () => {
    analyticsService.clicked(['Navbar', children?.toString() ?? 'settings']);

    // hive preview pane if there was any
    window.DataModel.MappingService.singlePinData = undefined;
    window.DataModel.NavBar.entityId = undefined;

    if (onClick) {
      onClick();
    } else if (legacyPath) {
      window.location.hash = legacyPath;
    } else if (path) {
      window.location.href = getNewAppUrl(path);
    }
  };

  return (
    <span className={cn(styles.container, {[styles.active]: active})}>
      {legacyPath || path || onClick ? (
        <a className={styles.link} onClick={handleClick}>
          {icon ? <FontAwesomeIcon className={styles.icon} icon={icon} type={iconType} /> : null}
          {children && <span>{children}</span>}
        </a>
      ) : null}
      {!path && !legacyPath && !onClick && (children || icon) ? (
        <span>
          {icon ? <FontAwesomeIcon className={styles.icon} icon={icon} type={iconType} /> : null}
          {children && <span>{children}</span>}
        </span>
      ): null}
      {hasSubmenu ? (
        <span className={styles.chevron}>
          <FontAwesomeIcon type="fas" icon="fa-chevron-down" />
        </span>
      ) : null}
    </span>
  );
};

export default MenuItemTitle;
