import template from './crm-activities-header.html';
import './crm-activities-header.scss';
import analyticsService from '../../shared-services/analytics-service';
import safeLocalStorage from '../../shared-services/safe-local-storage-service';


const FilterType = Object.freeze({
  createdAtOldestFirst: {column: 'createdAt', ascending: true},
  createdAtNewestFirst: {column: 'createdAt', ascending: false},
  startedAtOldestFirst: {column: 'startAt', ascending: true},
  startedAtFutureFirst: {column: 'startAt', ascending: false},
});

class CrmActivitiesHeaderController {
  constructor($window, CacheService, CrmActivitiesService, BaseController) {
    Object.assign(this, {
      $window,
      UTILS: $window.mmcUtils,
      MODEL: $window.DataModel,
      localStorage: safeLocalStorage,
      CacheService,
      CrmActivitiesService,
      filterByUser: BaseController.filterByUser,
      buildFilters: BaseController.buildFilters,
    });

    this.showViewByUser = this.UTILS.setViewByUserBasedOnRole(safeLocalStorage);
  }

  async filterCrmActivitiesByStatus(map, status) {
    this.CacheService.cacheCurrentView(this.MODEL.currentLeadsView, this.page, {status});
    this.MODEL.show.loader = true;
    const {page, column, ascending} = this.MODEL.cachedState.crmActivities;
    await this.CrmActivitiesService.setViewBasedOnType(map, this.buildFilters(), page, column, ascending);
    analyticsService.clicked(['Activities', 'status filter'], {status});
    this.MODEL.show.loader = false;
  }

  async filterCrmActivitiesByCrmType(map, crmObjectType) {
    this.CacheService.cacheCurrentView(this.MODEL.currentLeadsView, this.page, {crmObjectType});
    this.MODEL.show.loader = true;
    await this.CrmActivitiesService.setViewBasedOnType(map, this.filters, this.page, this.column, this.ascending);
    analyticsService.clicked(['Activities', 'type filter'], {crmObjectType});
    this.MODEL.show.loader = false;
  }

  async sortCrmActivities() {
    Object.assign(this, FilterType[this.filterType]);
    this.CacheService.cacheCurrentView(this.MODEL.currentLeadsView, this.page, {column: this.column});
    this.MODEL.show.loader = true;
    await this.CrmActivitiesService.setViewBasedOnType(false, this.filters, this.page, this.column, this.ascending);
    analyticsService.clicked(['Activities', 'sort'], {
      column: this.column,
      ascending: this.ascending,
      page: this.page,
    });

    this.MODEL.show.loader = false;
  }
}

CrmActivitiesHeaderController.$inject = [
  '$window', 'CacheService', 'CrmActivitiesService', 'BaseController',
];

const crmActivitiesHeader = {
  bindings: {
    filters: '<',
    page: '<',
    column: '=',
    ascending: '<',
    viewFilterOptions: '<',
  },
  controller: 'CrmActivitiesHeaderController',
  template,
};

export {CrmActivitiesHeaderController, crmActivitiesHeader};
