import BaseNetworkServiceWithPromises from './base-network-service/base-network-service-with-promises';
import safeLocalStorage from '../shared-services/safe-local-storage-service';
import Activity from '../react/type/Activity';
import Company from '../react/type/Company';
import Person from '../react/type/Person';
import Deal from '../react/type/Deal';
import User from '../react/type/User';
import EntityType from '../react/type/EntityType';
import DataModel from '../common/data-model';

type Params = {
  $order: string,
  $limit: number,
  $offset: number,
  $filters: string,
}

export interface FetchFilters {
  accountId?: Company['id'],
  assigneeId?: number | object,
  contactId?: Person['id'],
  crmActivityTypeId?: {$in: Array<Activity['id']>},
  crmObjectType?: EntityType | 'all',
  dealId?: Deal['id'],
  startAt?: string | object,
  viewAs?: User['username'],
  userId?: number | object,
  mapped?: boolean,
  completed?: boolean,
}

export class CrmActivitiesNetworkService {
  _getCrmActivitiesEndpoint = (id?: number) => {
    const org = safeLocalStorage.currentUser!.organization.id;
    let endpoint = `organizations/${org}/crm-activities`;
    if (id) {
      endpoint += `/${id}`;
    }
    return endpoint;
  };

  fetchCrmActivitiesCount = async () => (
    (await BaseNetworkServiceWithPromises.read(this._getCrmActivitiesEndpoint(), {$limit: 0})).total
  );

  // fetch all activities data
  fetchAllCrmActivities = (map: boolean, filters: FetchFilters, page = 1, column = 'startAt', ascending = false, noLimit = false) => {
    map = map || false;
    filters = filters || {};
    let params: Partial<Params> = {
      $order: ascending ? column : `-${column}`,
    };
    if (map) {
      filters = {...filters, mapped: true};
      params.$limit = DataModel.recordsUpperLimit;
    } else {
      if (page) {
        params.$offset = (page - 1) * DataModel.pageSize;
      }
      params.$limit = DataModel.pageSize;
    }
    if (noLimit) {
      params.$limit = 999999999;
    }

    if (Object.keys(filters).length) {
      params = Object.assign(params, {$filters: JSON.stringify(filters)});
    }
    return BaseNetworkServiceWithPromises.read(this._getCrmActivitiesEndpoint(), params);
  };

  fetchCrmActivity = (id: Activity['id']) => BaseNetworkServiceWithPromises.read(this._getCrmActivitiesEndpoint(id));
  createCrmActivity = (payload: any) => BaseNetworkServiceWithPromises.create(this._getCrmActivitiesEndpoint(), payload);
  updateCrmActivity = (payload: any) => BaseNetworkServiceWithPromises.update(
    this._getCrmActivitiesEndpoint(payload.id),
    payload,
  );
  deleteCrmActivity = (activityId: Activity['id']) => BaseNetworkServiceWithPromises.delete(
    this._getCrmActivitiesEndpoint(activityId),
  );
}

export default new CrmActivitiesNetworkService();
