import accountsNetworkService from '../network-services/accounts-network-service';
import TextSearchNetworkService from '../network-services/textsearch-network-service';
import mapActions from '../store/store-helpers';

export default class AccountsService {
  constructor(
    $window, FetchCRMDataService, MappingService,
  ) {
    Object.assign(this, {
      $window,
      MODEL: $window.DataModel,
      FetchCRMDataService,
      MappingService,
    });

    mapActions(this, ['modals']);
  }

  // fetch accounts data based on filters and the current caching structure
  async fetchAccounts(map, filters, page, column, ascending, columns, limit) {
    // set default current values if not sent
    page = page || this.MODEL.cachedState.accounts.page;
    column = column || this.MODEL.cachedState.accounts.column;
    ascending = ascending || this.MODEL.cachedState.accounts.ascending;
    this.MODEL.selectedRecords = {};
    this.MODEL.selectedEntities = [];
    this.MODEL.allRecordsSelected = false;
    this.$window.refreshDom({selectedRecords: this.MODEL.selectedRecords});

    if (map && filters.includeGroups === undefined) {
      filters.includeGroups = true;
    }

    try {
      const data = await accountsNetworkService.fetchAllAccounts(map, filters, page, column, ascending, limit, columns);
      const accountsData = data.data;
      this.MODEL.accountsCount = data.total;

      if (!data.total && !this.MODEL.currentLeadsView && !this.MODEL.FilterService.filtersSelected.length) {
        this.modalsActions.showModal('noAccountsModal');
      } else {
        this.modalsActions.resetVisibility('noAccountsModal');
      }

      this.parseAccounts(accountsData);
      this.$window.refreshDom({accounts: this.MODEL.accounts});
    } catch (error) {
      throw error;
    }
  }

  async refreshAccountsTotalCount() {
    const accountsTotalCount = await accountsNetworkService.getAccountsCount();
    this.$window.refreshDom({accountsTotalCount});
  }

  // parse accounts data as per required by the UI
  parseAccounts(accountsData) {
    this.MODEL.accounts = accountsData.map(record => {
      record.longitude = 0;
      record.latitude = 0;
      record.unmapped = !record.geoPoint || !Array.isArray(record.geoPoint.coordinates);
      if (!record.unmapped) {
        record.longitude = record.geoPoint.coordinates[0];
        record.latitude = record.geoPoint.coordinates[1];
      }
      return record;
    });
  }

  // set the map view
  async setMapView(nukeMap) {
    const shouldNukeMap = nukeMap !== undefined ? nukeMap : (!this.MODEL.MappingService.findNewCustomers || (this.MODEL.MappingService.findNewCustomers && !this.MODEL.MappingService.showOtherPins));
    this.MappingService.parseMappingObjects(this.MODEL.accounts, 'accounts', shouldNukeMap);
  }

  // set the view headers
  setViewHeaders(map) {
    const title = map ? 'Mapped Companies' : 'All Companies';
    const count = this.MODEL.accountsCount;
    const btn = 'Add Company';
    const showFilter = map;
    const showAdd = false;
    this.FetchCRMDataService.setCurrentPageVariable(title, count, btn, showFilter, showAdd);
  }

  // ---> search all accounts (customersListArray)
  // this can be called directly from submit on companies page search bar,
  // or it will execute if there is a q url query param.
  async searchFromAccounts(searchVal) {
    let searchString;
    if (this.$window.getParameterByName('q')) {
      this.MODEL.searchString = this.$window.getParameterByName('q');
      searchString = this.MODEL.searchString;
    } else {
      // was used when we had search on map page.
      this.MODEL.routingSearchString = searchVal;
      searchString = this.MODEL.routingSearchString;
    }
    searchString = searchString.toLowerCase().trim();

    // look for accounts using global search feature
    return (await TextSearchNetworkService.findEntities(searchString, 'accounts')).data;
  }
}

AccountsService.$inject = [
  '$window', 'FetchCRMDataService', 'MappingService',
];
