import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import moment from 'moment';
import styled from 'styled-components';
import BillingInfo from './BillingInfo';
import {Interval} from '../../../type/plans/Interval';
import LinkButton from '../../../components/LinkButton/LinkButton';
import Tier from '../../../type/plans/Tier';
import CurrentPlan from '../../../type/plans/CurrentPlan';
import showSupportModal from '../../../util/showSupportModal';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const BillingButtonLink = styled(LinkButton)`
  margin-top: 8px;
`;

const FullWidthBillingInfo = styled(BillingInfo)`
  flex-grow: 1;
`;

interface Props {
  currentPlan: CurrentPlan,
  onSwitchToYearlyBillingClick?: () => void,
  selectedTier: Tier,
}

const BillingInformation: React.FC<Props> = ({
  currentPlan,
  onSwitchToYearlyBillingClick,
  selectedTier,
}) => {
  const {t} = useTranslation(['billing']);
  const shouldShowGoToAnnualLabel = currentPlan.tier.id === selectedTier.id
    && currentPlan.tier.interval === Interval.MONTHLY && !currentPlan.tier.isTrial;

  const currentPrice = {...currentPlan.tier.price, value: currentPlan.tier.price.value * currentPlan.licenses};
  return (
    <Container>
      {!currentPlan.tier.isTrial && (
        <BillingInfo
          title={t('Your Licenses')}
          text={currentPlan.licenses}
        />
      )}

      {!currentPlan.isOldPlan && (
        <BillingInfo
          title={t('Current Rate')}
          text={
            currentPlan.tier.isTrial
              ? t('Free Trial')
              : t('license', {price: currentPrice, interval: currentPlan.tier.interval})
          }
        >
          {shouldShowGoToAnnualLabel && (
            <BillingButtonLink onClick={onSwitchToYearlyBillingClick}>
              {t('Switch to yearly billing and save')}
            </BillingButtonLink>
          )}
        </BillingInfo>
      )}

      {!currentPlan.isOldPlan && (
        <BillingInfo
          title={currentPlan.tier.isTrial ? t('Trial End Date') : t('Next Payment Due')}
          text={moment(currentPlan.nextPaymentDate).format('LL')}
        >
          <LinkButton onClick={showSupportModal}>
            Billing questions?
          </LinkButton>
        </BillingInfo>
      )}

      {currentPlan.isOldPlan && (
        <FullWidthBillingInfo>
          <Trans i18nKey="ContactSupportToUpgradeToNewPlan" t={t}>
            It looks like you are on an old pricing plan, please
            <LinkButton onClick={showSupportModal}>contact customer support</LinkButton>
            to upgrade to a new plan.
          </Trans>
        </FullWidthBillingInfo>
      )}
    </Container>
  );
};

export default BillingInformation;
